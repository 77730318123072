(function (module) {

    var npcAddReviewerModalCtrl = function (organizationId, commissionId, programReview, reviewTeamId, npc, programChange, volunteerSvc, teamMemberTypeIds, teamMemberTypeNames, teamMemberTypeId, $scope, $q, $uibModalInstance, helperSvc, codeSvc, programSvc, programReviewSvc, npcChangeTypeIds, npcSvc, alertSvc, tcAssignmentSvc, volunteerSvc) {
        var model = $scope;

        model.teamMemberName = teamMemberTypeIds[teamMemberTypeId]
        model.title = 'Add ' + model.teamMemberName + ' for ' + programReview.programName;
        model.isSaving = false;
        model.isValid = false;
        model.errors = [];
        model.teamMemberTypeId = teamMemberTypeId;
        model.newReviewer = {
            person: {}
        };

        model.onSelectReviewer = function (e) {
            model.errors = [];

            const selectedPerson = e.data;
            if (!selectedPerson) return;
            // Reviewer assigned once per program change
            const disallowedTeamMemberTypeIds = teamMemberTypeId === teamMemberTypeNames.NPCREVIEWER ? [teamMemberTypeNames.NPCREVIEWER] : [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR, teamMemberTypeNames.PEV, teamMemberTypeNames.NPCFVREVIEWER];

            if (model.teamMemberTypeId === teamMemberTypeNames.ADJUNCT) {
                model.isValid = true;
                return;
            }


            if (programChange.npcProgramChangeReviewerDtos.some(reviewer =>
                reviewer.personId === selectedPerson.personId &&
                disallowedTeamMemberTypeIds.includes(reviewer.teamMemberTypeId)
            )) {
                model.isValid = false;
                model.errors = ["This person has already been assigned."];
                return;
            }
            // Reviewer must be a volunteer
            volunteerSvc.getVolunteerIdByPersonId(selectedPerson.personId).then(volunteerIds => {
                if (!volunteerIds || !volunteerIds.length) {
                    model.isValid = false;
                    model.errors = ["This person is not a volunteer."];
                    return;
                }
                // Reviewer must not have conflict of interest
                const volunteerId = volunteerIds[0].volunteerId;
                if (reviewTeamId) {
                    tcAssignmentSvc.hasTCCOI(volunteerId, reviewTeamId, model.teamMemberTypeId).then(tcoiData => {
                        const hasTCCOI = helperSvc.getValue(tcoiData);
                        if (hasTCCOI?.length) {
                            model.isValid = false;
                            var errorMsg = `This person has conflict(s) of interest: ${hasTCCOI.join(', ')}.`;
                            model.errors = [errorMsg];
                            return;
                        }
                        // Reviewer is valid
                        model.isValid = true;
                        model.errors = [];
                        // Check for warnings (data still valid but admin may want to make changes)
                        model.isNPCReviewer = teamMemberTypeId !== teamMemberTypeNames.NPCREVIEWER &&
                            npc.npcProgramDtos.some(program =>
                                program.npcProgramChangeDtos.some(change =>
                                    change.npcProgramChangeReviewerDtos.some(reviewer =>
                                        reviewer.personId === selectedPerson.personId && reviewer.teamMemberTypeId === teamMemberTypeNames.NPCREVIEWER
                                    )
                                )
                            );
                    });
                }
               
                model.isValid = true;
            });
        }

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.clearReviewer = function () {
            reset();
        }

        model.save = function () {
            model.isSaving = true;

            var npcReviewerDto = {
                npcProgramChangeReviewerId: 0,
                npcProgramChangeId: programChange.npcProgramChangeId,
                personId: model.newReviewer.person.personId,
                teamMemberTypeId: model.teamMemberTypeId
            }

            npcSvc.addReviewer(npcReviewerDto)
                .then(() => {
                    $uibModalInstance.close();
                })
                .catch((error) => {
                    console.log(error);
                    model.isSaving = false;
                    alertSvc.addAlertWarning('Reviewer could not be created at this time.');
                });
         }

        function reset() {
            model.isValid = false;
            model.errors = [];
            model.newReviewer = {
                person: {}
            };
        };

        function activate() {
            reset();
            model.isDataReady = true;
        }

        activate();
    };
    module.controller('npcAddReviewerModalCtrl', npcAddReviewerModalCtrl);

}(angular.module('npc')));