// programLinkSvc allows retrieving and updating the ProgramDtoLight entity on the server.
// ProgramDtoLight is a stripped down program object that includes the
// IsAppmComplied flag and a collection of web address links in ProgramInformationDtos.
//
// Create, delete handled by working on program object using programSvc. 
// Information links are created by adding new item to ProgramInformationDtos
// and deleted by setting the item's isDeleted flag to false.

(function (module) {

    var programLinkSvc = function ($http, $q, helperSvc, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/ProgramInformation({0})';
        var filterBase = '?$filter=';
        var includeProgramInformationDtos = '&$expand=programInformationDtos';
        var factory = {};

        var onSuccess = function (response) {
            if (response.status === 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        var buildFilter = helperSvc.buildFilter;

        var buildProgramIdFilter = function (parameter) {
            return buildFilter("programId eq", parameter);
        };

        factory.getProgramLinksByProgramId = function (id) {
            return $http.get(apiPath.format(null) + filterBase + buildProgramIdFilter(id) + includeProgramInformationDtos).then(onSuccess, onFailure);
        };

        factory.update = function (programLinks) {
            return $http.put(apiPath.format(programLinks.programId), programLinks).then(onSuccess, onFailure);
        };

        return {
            getProgramLinksByProgramId: factory.getProgramLinksByProgramId,
            update: factory.update
        };
    };

    module.factory('programLinkSvc', programLinkSvc);

})(angular.module('program'));