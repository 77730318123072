(function (module) {

    var otherConflictSvc = function (odataServiceFactory) {
        var config = {
            apiPath: '/VolunteerOtherConflict',
            keyName: 'volunteerOtherConflictId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'otherConflicts',
            getByForeignKeyPath: '/GetVolunteerOtherConflictByVolunteerId',
            expandables: ['collegeDirectoryDto', 'collegeVerificationDto']
        };

        var factory = new odataServiceFactory(config);

        return {
            data: factory.data,
            getByVolunteerId: factory.getByForeignKey,
            create: factory.create,
            delete: factory.delete
        };
    };

    module.factory('otherConflictSvc', otherConflictSvc);

})(angular.module('volunteer'));
