(function (module) {
    'use strict';

    var programInfoHelperSvc = function (rfrProgramRequestTypes, programSummarySvc, programReviewTypeIds, typeConstSvc, terminationOptions) {
        var factory = {};

        factory.onlyOneProgram = function (review) {
            if (typeof review.jsonContents !== 'string')
                return review.jsonContents.programs.length === 1;

            return false;
        }

        factory.getDegreeLevel = function (degreeLevelCode) {
            return typeConstSvc.getDegreeLevel(degreeLevelCode);
        }

        factory.isNewProgram = function (program) {
            return (typeof (program.programId) == 'undefined' || !program.programId || program.programReviewTypeCode === programReviewTypeIds.INITIALACCREDIATION);
        }

        factory.getProgramCampus = function (program) {
            return programSummarySvc.getProgramCampusFromRFRProgram(program);
        }

        factory.getProgramRequestType = function (program, terminationOption) {
            if (program && factory.isNewProgram(program)) {
                return rfrProgramRequestTypes.NEWPROGRAM;
            } else if (terminationOption || program.requestToTerminate) {
                var termOpt = terminationOption ? terminationOption : program.requestToTerminate.terminationOption;
                switch (termOpt) {
                    case terminationOptions.EXTENSION:
                        return rfrProgramRequestTypes.TERMINATEOPTION1;
                        break;
                    case terminationOptions.PHASEOUT:
                        return rfrProgramRequestTypes.TERMINATEOPTION2;
                        break;
                    case terminationOptions.LAPSE:
                        return rfrProgramRequestTypes.TERMINATEOPTION3;
                        break;
                    case terminationOptions.PROGRAMTERMINATION:
                        return rfrProgramRequestTypes.TERMINATEPROGRAM;
                        break;
                    case terminationOptions.ACCREDITATIONTERMINATION:
                        return rfrProgramRequestTypes.TERMINATEACCREDITATION;
                        break;
                    default:
                        return rfrProgramRequestTypes.TERMINATION;
                        break;
                }
            } else if (program.nameChange) {
                return rfrProgramRequestTypes.CHANGENAME;
            } else {
                return rfrProgramRequestTypes.RENEWAL;
            }
        }

        factory.getProgramTitle = function (program) {
            var title = program.programName + " (" + program.degreeCode + ")";

            if (program.programReviewTypeCode) {
                title += " - " + typeConstSvc.getProgramReviewName(program.programReviewTypeCode);
            }

            return title;
        };

        return {
            onlyOneProgram: factory.onlyOneProgram,
            getDegreeLevel: factory.getDegreeLevel,
            isNewProgram: factory.isNewProgram,
            getProgramCampus: factory.getProgramCampus,
            getProgramRequestType: factory.getProgramRequestType,
            getProgramTitle: factory.getProgramTitle
        };
    };

    module.factory('programInfoHelperSvc', programInfoHelperSvc);

})(angular.module('common'));