(function (module) {

    module.filter('filterBySocieties', function (oauth) {
        return function (apps, societies) {

            if (!(angular.isArray(apps) && angular.isArray(societies))) {
                return apps;
            } else {

                var filteredApps = [];

                if (angular.isArray(societies)) {
                    societies.forEach(function (society) {
                        filteredApps = filteredApps.concat(apps.filter(function (app) {
                            return app.societyId == society.organizationId;
                        }));
                    });
                    // Add applications (waiting for submission) where user hasn't yet entered society info
                    if (oauth.isAdmin()) 
                        filteredApps = filteredApps.concat(apps.filter(function (app) {
                            return app.societyId == null || app.societyId == 0;
                        }));
                    return filteredApps;
                }
                return apps;
            }
        }
    });

    module.filter('appManagerBySocietyFilter', function(pevAppSvc, helperSvc){
        return function(items){
            if (!(angular.isArray(items))) {
                return items;
            } else {           
                if(!pevAppSvc.isAppManager()){
                    return items;
                }else{               
                    var arrayOfItems = [];
                    var pevAppManagerSocieties = pevAppSvc.getPevAppManagerSocieties();
                    angular.forEach(pevAppManagerSocieties, function (society) {
                        arrayOfItems = arrayOfItems.concat(items.filter(function (item) {
                            return society.organizationId === item.societyId
                        }));
                    });
                    return arrayOfItems;
                }
            }        
        }
    });


    module.filter('omitCsab', ['orgConstants', function (orgConstants) {
        return function (items) {
            var filteredList = [];

            items.forEach(function (item) {
                if (item.organizationId !== orgConstants.CSAB) {
                    filteredList.push(item);
                }
            });

            return filteredList;
        }
    }]);

}(angular.module('pevApp')));