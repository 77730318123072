(function () {
    var app = angular.module('review', [
        //dependencies
        'common',
        //'organization',
        'ngRoute',
        'ui.bootstrap',
        'ui.unique',
        'ngSanitize'
    ]);
}());