(function (module) {

    var statementBoilerPlate = {
        BODY_DRAFT: "<p>The statement that follows consists of two parts:  the first addresses the institution and its overall educational unit, and the second addresses the individual programs.</p>" +
              "<p>A program's accreditation action will be based upon the findings summarized in this statement. Actions will depend on the program's range of compliance or non-compliance with the criteria. This range can be construed from the following terminology:</p>",
        BODY: "<p>The statement that follows consists of two parts:  the first addresses the institution and its overall educational unit, and the second addresses the individual programs.</p>" +
                    "<p>A program's accreditation action is based upon the findings summarized in this statement. Actions depend on the program's range of compliance or non-compliance with the criteria. This range can be construed from the following terminology:</p>",
        UL: "<ul>" +
                "<li><label>Deficiency</label> A deficiency indicates that a criterion, policy, or procedure is not satisfied. Therefore, the program is not in compliance with the criterion, policy, or procedure.</li>" +
                "<li><label>Weakness</label> A weakness indicates that a program lacks the strength of compliance with a criterion, policy, or procedure to ensure that the quality of the program will not be compromised.  Therefore, remedial action is required to strengthen compliance with the criterion, policy, or procedure prior to the next review.</li>" +
                "<li><label>Concern</label> A concern indicates that a program currently satisfies a criterion, policy, or procedure; however, the potential exists for the situation to change such that the criterion, policy, or procedure may not be satisfied.</li>" +
                "<li><label>Observation</label> An observation is a comment or suggestion that does not relate directly to the current accreditation action but is offered to assist the institution in its continuing efforts to improve its programs.</li>" +
            "</ul>"
    };
    module.constant('statementBoilerPlate', statementBoilerPlate);

}(angular.module("statement")));