(function (module) {
    var dashboardCtrl = function (currentUser, currentUserHelper, alertSvc, $state, environmentSvc, oauth, eventSvc) {

        var model = this;

        model.isDataReady = false;
        model.openCOIPDF = function () { alertSvc.openPDFModal('/Content/files/ABET-COI-Policy.pdf', 'Updated Conflict of Interest Policy'); };
        model.isDev = environmentSvc.isDev();

        init();

        model.testNpcEditor = function () {
            $state.go('npcEditorTool');
        }

        function init() {
            model.personId = parseInt(currentUser.profile.personId);
            model.welcomeMsg = 'Welcome, ' + currentUser.profile.firstName + '!';
            model.loggedInMsg = 'You are logged in as ' + currentUser.profile.username;
            model.isOnlyInstitutionUser = currentUserHelper.isOnlyInstitutionUser();
            model.isDataReady = true;
        }
        eventSvc.listen(function () {
            init();
        }, 'refreshDashboardPersonInfo');

        var activate = function () {
            if (currentUser.profile.personId) { // Check if user is logged in
                model.personId = currentUser.profile.personId;
                model.isDataReady = true;
                return;
            }
        }();
    };
    
    module.controller('dashboardCtrl', dashboardCtrl);

}(angular.module('dashboard')));