(function (module) {

    var changeHistoryEditCtrl = function (parent, $scope, $stateParams, $uibModalInstance, isProgramReview, changesOrReview, helperSvc, eventSvc, codeSvc, changeSvc, alertSvc, changeTypeCategories, organizationSvc, programReviewSvc) {
        var model = $scope;

        model.title = 'History of Accreditation Changes';
        model.parent = parent;
        model.isEmpty = helperSvc.isEmpty;
        model.formatDate = helperSvc.formatDate;
        model.isProgramReview = isProgramReview;
        model.reviewTeamId = parseInt($stateParams.reviewTeamId);
        model.addresses = [];
        model.currentProgramPreview = {};
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        model.reviewPrograms = programReviewSvc.data;
        resetCurrentChange();

        //Check to see if the edit is being called from the program summarry or on the individual module in reviews
        if (model.isProgramReview) {
            assignProgramReviewData(changesOrReview);
        }
        else{
            changeSvc.getChangeHistoryByReviewTeamIdOdata(model.reviewTeamId).then(function (data) {
                model.changeHistory = data;
            });                            
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.addRow = function () {
            resetCurrentChange();
            model.isInsertMode = true;
            model.isCreateMode = true;
            model.isUpdateMode = false;
        };

        model.cancel = function () {
            resetCurrentChange();
            model.isInsertMode = false;
        };

        model.loadCampusAddresses = function (change) {
            if (model.isCampusLocationChange(change)) {
                // programs are not currently refreshing here, so if a campus address change is created or updated,
                // those changes will not be reflected in any new create made in that same modal session (refresh program data when available)
                for (var i = 0; i < model.reviewPrograms.programs.length; i++) {

                    if (model.reviewPrograms.programs[i].programReviewId === change.programReviewChangeDtos[0].programReviewId) {
                        model.review = model.reviewPrograms.programs[i];
                        if (model.isCreateMode) {
                            var mostRecentChangeId = getMostRecentChangeId(model.review.programReviewCampusDtos);
                            var mostRecentChangeAddresses = [];

                            angular.forEach(model.review.programReviewCampusDtos, function (address, key) {
                                if (address.changeId === mostRecentChangeId) {
                                    mostRecentChangeAddresses.push(address);
                                }
                            });
                            model.addresses = mostRecentChangeAddresses;
                        } else {
                            model.addresses = change.programReviewCampusDtos;
                        }
                        break;
                    }
                }

                codeSvc.getCountries().then(function (data) {
                    model.countryOptions = data.value;
                });

                codeSvc.getStates().then(function (data) {
                    model.stateOptions = data.value;
                });
            }
        };

        model.delete = function (change) {
            alertSvc.confirmDelete(change.changeTypeName, deleteFunc, change);

            function deleteFunc() {
                changeSvc.delete(change.changeId).then(function (data) {
                    updateProgramReviewData();
                    resetCurrentChange();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Change successfully deleted.");
                });
            }
        };

        model.save = function (change) {

            if (model.isCampusLocationChange(change)) {
                var campuses = [];
                angular.forEach(model.addresses, function (address, key) {
                    delete address.isOutsideUS; //todovarner this causes the display to flicker/change the state/provice field. Need to fix

                    if (model.isCreateMode || address.changeId === null) {
                        address.addressId = 0;
                        address.changeId = change.changeId;
                        address.programReviewCampusId = 0;
                        address.programReviewId = change.programReviewChangeDtos[0].programReviewId || 0;
                    }
                    if (address.isDeleted === false) {
                        campuses.push(address);
                    }
                });

                change.programReviewCampusDtos = (campuses.length > 0) ? campuses : model.addresses;
            } else {
                delete change.programReviewCampusDtos;
            }

            //clean the program data so it fits to the DTO 
            if (!model.isProgramReview) {
              cleanChangeData(change);
            }

            if (model.isCreateMode) {

                changeSvc.create(change).then(function () {
                    updateProgramReviewData();
                    resetCurrentChange();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Change successfully added.");


                });
            } else if (model.isUpdateMode) {
                changeSvc.update(change).then(function () {
                    updateProgramReviewData();
                    resetCurrentChange();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Change successfully updated.");              
                });
            }
        }

        model.select = function (change) {
            var useUndefined = true;

            changeSvc.getChangeByChangeId(change.changeId).then(function (data) {
                model.currentChange = helperSvc.getItem(data);
                model.currentChange.programName = change.programName;
                model.addresses = model.currentChange.programReviewCampusDtos || [];
                model.currentChange.effectiveDate = helperSvc.formatDate(model.currentChange.effectiveDate, useUndefined);
                if (model.currentChange.programReviewChangeDtos) {
                    model.currentChange.programReviewChangeDtos[0].accreditationStartDate = helperSvc.formatDate(
                        model.currentChange.programReviewChangeDtos[0].accreditationStartDate,
                        useUndefined
                    );
                    model.currentChange.programReviewChangeDtos[0].accreditationEndDate = helperSvc.formatDate(
                        model.currentChange.programReviewChangeDtos[0].accreditationEndDate,
                        useUndefined
                    );
                }

                model.isInsertMode = true;
                model.isCreateMode = false;
                model.isUpdateMode = true;

                model.loadCampusAddresses(model.currentChange);
            });
        };

        model.setProgramReviewId = function(program){
            model.currentChange.programReviewChangeDtos[0].programReviewId = program.programReviewId;
        }

        model.isAccreditationDatesChange = function (change) {
            return changeTypeSelection(change, changeTypeCategories.ACCREDITATIONDATES);
        };

        model.isDistanceLearningChange = function (change) {
            return changeTypeSelection(change, changeTypeCategories.DISTANCELEARNING);
        };

        model.isCampusLocationChange = function (change) {
            return changeTypeSelection(change, changeTypeCategories.CAMPUSLOCATION);
        };

        codeSvc.getAccreditationChangeTypes().then(function (data) {
            model.changeTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getDistanceLearningTypes().then(function (data) {
            model.distanceLearningTypeOptions = helperSvc.getResults(data);
        });

        function getMostRecentChangeId (addresses) {
            var mostRecentChangeId = null;
            angular.forEach(addresses, function (address, key) {
                if (address.changeId > mostRecentChangeId) {
                    mostRecentChangeId = address.changeId;
                }
            });
            return mostRecentChangeId;
        };

        function changeTypeSelection(change, key) {
            if (model.changeTypeOptions) {
                for (var i = 0; i < model.changeTypeOptions.length; i++) {
                    if (model.changeTypeOptions[i].changeTypeId === change.changeTypeId) {
                        if (model.changeTypeOptions[i].changeTypeCategoryId === key) {
                            model.currentChange.changeTypeCategoryId = model.changeTypeOptions[i].changeTypeCategoryId;
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        function resetCurrentChange () {
            model.currentChange = {
                changeId: 0,
                reviewTeamId: model.reviewTeamId,
                programReviewChangeDtos: []
            };

            model.currentChange.programReviewChangeDtos.push({
                programReviewChangeId: 0,
                programReviewId: model.programReviewId,
                changeId: 0
            });
        }

        function updateProgramReviewData() {
            var programsDataSource = {
                dataHolder: programReviewSvc,
                dataLocationName: 'data.programs',
                svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [model.reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true
            }

            if (model.isProgramReview) {

                helperSvc.getData([programsDataSource]).then(function () {
                    var programReview = programReviewSvc.getProgramReviewByProgramReviewId(model.programReviewId);
                    assignProgramReviewData(programReview);
                });

                changeSvc.getChangeHistoryByReviewTeamIdOdata(model.reviewTeamId);
            } else {                
                helperSvc.getData([programsDataSource]).then(function(){

                    changeSvc.getChangeHistoryByReviewTeamIdOdata(model.reviewTeamId).then(function (data) {
                        model.changeHistory = data;
                    });
                });
            }
        }

        function cleanChangeData(change) {
            delete change.program;
            delete change.programName;
        }

        function assignProgramReviewData(programReview) {
            model.changeHistory = programReview.programReviewChangeDtos;
            model.programName = programReview.programDto.programDetailDto.programName;
            model.degreeCode = programReview.programDto.programDetailDto.degreeCode;
            model.reviewTeamId = programReview.reviewTeamId;
            model.programReviewId = programReview.programReviewId;
        }

    };

    module.controller('changeHistoryEditCtrl', changeHistoryEditCtrl);

}(angular.module('common')));