(function (module) {

    var optInOutEditCtrl = function ($scope, $uibModalInstance, alertSvc, volunteerSvc, helperSvc) {
        var useUndefined = true;
        var model = $scope;
        model.data = angular.copy(volunteerSvc.data);
        model.currentInformation = model.data.volunteer;
        model.currentInformation.volunteerEndDate = helperSvc.formatDate(model.currentInformation.volunteerEndDate, useUndefined);
        model.title = 'Opt Out';

        model.save = function () {
            volunteerSvc.update(model.currentInformation).then(function () {
                alertSvc.addAlertSuccess("Opt out information successfully updated.");
                model.cancel();
            },
            function () {
                alertSvc.addAlertWarning('Opt out information could not be saved at this time.');
            });
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('optInOutEditCtrl', optInOutEditCtrl);

}(angular.module('volunteer')));