(function (module) {
    'use strict';

    function odataSvc($q, $odataresource, amsConst) {
        var odataSvc = function () {

            var rootPath = amsConst.webApiUrl + '/odata';
            var deferred = $q.defer();

            var getDeferred = function () {
                return deferred;
            };

            var getPathWithParameter = function (path, parameterName, parameterValue) {
                return path + '(' + parameterName + '=' + parameterValue + ')';
            };

            var getPathWithParameters = function (path, param1Name, param1Value, param2Name, param2Value) {
                return path + '(' + param1Name + '=' + param1Value + ',' + param2Name + '=' + param2Value + ')';
            };

            var getPathWithStringParameter = function (path, parameterName, parameterValue) {
                // Escape single quotes inside of string parameter value.
                parameterValue = parameterValue.replace("'", "''");
                // Enclose string with single quotes for WebAPI controller to parse.
                return path + '(' + parameterName + '=\'' + parameterValue + '\')';
            };

            var getSource = function (apiPath, keyName) {                
                return $odataresource(rootPath + apiPath, {}, {}, {
                    odatakey: keyName,
                    isodatav4: true
                });
            };

            var instantiate = function (apiPath, keyName, model) {
                if (model) {
                    // Passing in model with a promise to angular.merge will cause a stack overflow
                    delete model.$promise;
                    delete model.$resolved;
                }
                var Resource = getSource(apiPath, keyName);
                return angular.merge(new Resource, model);
            };

            var onSuccess = function (data) {
                deferred.resolve(data);
            };

            var onArrayToSingleSuccess = function (data) {
                var singledData = {};

                if (data.length > 0) {
                    singledData = data[0];
                }

                deferred.resolve(singledData);
            }

            var onFailure = function (error) {
                deferred.resolve(error);
                console.log(error);
            };

            var onRfrSearchSuccess = function (data) {
                var rootId = 'rfrId';
                var subId = 'commissionId';

                onSearchSuccess(data, rootId, subId, function (groupedItems, subId) {
                    return convertSearchItems(groupedItems, subId);
                });
            };

            var onVolunteerSearchSuccess = function (data) {
                var rootId = 'volunteerId';
                var subId = 'disciplineId';

                onSearchSuccess(data, rootId, subId, function (groupedItems, subId) {
                    return convertSearchItems(groupedItems, subId);
                });
            };

            function onSearchSuccess(data, rootId, subId, convertCallback) {
                var dataLength = data ? data.length : 0;
                var newDataArray = {};

                //maps all the objects with the same rootId to a corresponding dataArray
                for (var i = 0; i < dataLength; i++) {
                    var obj = data[i];

                    if (newDataArray[obj[rootId]] === undefined)
                        newDataArray[obj[rootId]] = [];

                    newDataArray[obj[rootId]].push(obj);
                }

                var combinedData = convertCallback.call(null, newDataArray, subId);
                data = combinedData;
                deferred.resolve(data);
            }

            function convertSearchItems(groupedItems, subId) {
                var combinedItems = [];
                var pluralSubId = subId + 's';

                angular.forEach(groupedItems, function (value) {
                    var newOption = value[0];
                    newOption[pluralSubId] = [];

                    for (var i = 0; i < value.length; i++) {
                        newOption[pluralSubId].push(value[i][subId]);
                    }

                    combinedItems.push(newOption);
                });

                return combinedItems;
            }

            return {
                getDeferred: getDeferred,
                getSource: getSource,
                onSuccess: onSuccess,
                onFailure: onFailure,
                instantiate: instantiate,
                onRfrSearchSuccess: onRfrSearchSuccess,
                onVolunteerSearchSuccess: onVolunteerSearchSuccess,
                onArrayToSingleSuccess: onArrayToSingleSuccess,
                getPathWithParameter: getPathWithParameter,
                getPathWithParameters: getPathWithParameters,
                getPathWithStringParameter: getPathWithStringParameter
            }
        };

        return {
            get: function () {
                return new odataSvc();
            }
        };
    }
    
    module.factory('odataSvc', odataSvc);

})(angular.module('common'));