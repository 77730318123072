(function (module) {

    var programHistoryCtrl = function ($scope, $uibModal, helperSvc, programDetailSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'History of Program Name Changes';
        model.data = programDetailSvc.data;

        model.editProgramNameChanges = function (changes) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/programHistoryEdit.html',
                size: 'lg',
                controller: 'programHistoryEditCtrl',
                resolve: {
                    program: function () {
                        return model.parent.selectedProgram;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.isEmpty = helperSvc.isEmpty;
        model.formatDate = helperSvc.formatDate;
    };

    module.controller('programHistoryCtrl', programHistoryCtrl);

}(angular.module('program')));