(function (module) {

    var personSignatureSvc = function ($http, $q, amsConst, odataSvc, $odata, documentSvc) {
        var factory = {};
        var queryBase = '?$filter=';
        
        /**********************************
        *REFACTORED
        **********************************/
        var key = 'personId';
        var memberKey = 'personSignatureId';
        var api = '/PersonSignature';
        factory.signature = { data: null };

        factory.getPersonSignatureByPersonId = function (id, noStoredData) {
       
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonSignaturesByPersonId', 'personId', id)

            var od = oSvc.getSource(path, memberKey).odata();

            od.single()
                .$promise
                .then(function (response) {
                    if (!response.stream_id) {
                        response = null;
                    }

                    return response;
                })
                .then(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.signature.data = data;
                });
            }

            return oSvc.getDeferred().promise;
        }

        factory.getPersonSignatureByPersonIdFromMedia = function (id, noStoredData) {

            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonSignaturesByPersonIdFromMedia', 'personId', id)

            var od = oSvc.getSource(path, memberKey).odata();

            od.single()
                .$promise
                .then(function (response) {
                    if (!response.stream_id) {
                        response = null;
                    }

                    return response;
                })
                .then(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.signature.data = data;
                });
            }

            return oSvc.getDeferred().promise;
        }

        factory.getSignatureImageByPersonId = function (id) {
            return factory.getPersonSignatureByPersonId(id, true).then(function (data) {
                if (data && data.stream_id) {
                    return documentSvc.downloadDocumentBase64(data.stream_id);
                } else {
                    var deferred = $q.defer();
                    deferred.resolve(null);
                    return deferred.promise;
                }
            });
        };

        /*New process - Use this cycle year 2023 */
        factory.getSignatureImageByPersonIdFromMedia = function (id) {
            return factory.getPersonSignatureByPersonId(id, true).then(function (data) {
                if (data && data.stream_id) {
                    return documentSvc.downloadDocumentBase64(data.stream_id);
                } else {
                    var deferred = $q.defer();
                    deferred.resolve(null);
                    return deferred.promise;
                }
            });
        };

        factory.create = function (newPersonSignature) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, newPersonSignature);
            delete newPersonSignature.fileName;
            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            refreshSignature(oSvc, newPersonSignature.personId);

            return oSvc.getDeferred().promise;
        };

        function refreshSignature(oSvc, personId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getPersonSignatureByPersonId(personId);
            });
        }

        factory.update = function (newPersonSignature) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, memberKey, newPersonSignature);
            delete newPersonSignature.fileName;
            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            refreshSignature(oSvc, newPersonSignature.personId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (newPersonSignature) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, memberKey, { personSignatureId: newPersonSignature.personSignatureId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            refreshSignature(oSvc, newPersonSignature.personId);

            return oSvc.getDeferred().promise;
        };

        return {
            signature: factory.signature,
            getPersonSignatureByPersonId: factory.getPersonSignatureByPersonId,
            getSignatureImageByPersonId: factory.getSignatureImageByPersonId,
            update: factory.update,
            create: factory.create,
            delete: factory.delete
        };
    };
    module.factory('personSignatureSvc', personSignatureSvc);

})(angular.module('review'));