(function (module) {

    const manageTCsEditCtrl = ['$uibModalInstance', 'teamMembers', 'programAuditAccessData', function ($uibModalInstance, teamMembers, programAuditAccessData) {
        const model = this;

        // properties
        model.teamMembers = teamMembers;
        model.programAuditAccessData = angular.copy(programAuditAccessData);

        // methods
        model.save = save;
        model.cancel = cancel;

        function save() {
            $uibModalInstance.close(model.programAuditAccessData);
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    }];

    module.controller('manageTCsEditCtrl', manageTCsEditCtrl);

}(angular.module('programAudit')));