(function (module) {

    var confirmCtrl = function (title, func, args, $scope, $uibModalInstance, cancelFunc) {
        var model = $scope;

        model.title = title;

        model.proceed = function () {
            $uibModalInstance.close();

            if (args) {
                func.apply(this, args);
            } else {
                func();
            }
        };

        model.close = function () {
            $uibModalInstance.close();

            if (cancelFunc) cancelFunc();
        };
    };

    module.controller('confirmCtrl', confirmCtrl);

}(angular.module('common')));