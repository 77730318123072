(function (module) {

    var changeSvc = function ($http, $q, helperSvc, amsConst, odataSvc) {
        var odataRoot = amsConst.webApiUrl + '/odata/';
        var apiPath = odataRoot + 'Change({0})';
        var searchApiPath = odataRoot + 'GetChangeSearch({0})';
        var filterBase = '?$filter=';
        var expandBase = '&$expand=';
        var includeProgramReviewChangeDtos = 'programReviewChangeDtos';
        var includeProgramReviewCampusDtos = ',programReviewCampusDtos($expand=addressDto)';
        var factory = {};

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            return $q.reject(response);
        };


        /*************************
        *REFACTORING BELOW
        ***************************/
        factory.data = { changeHistory: null, programChangeHistory: null };
        var searchApi = '/GetChangeSearch';
        var api = '/Change'
        var key = 'reviewTeamId';
        var changeKey = 'changeId';

        factory.getChangeHistoryByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            oSvc.getSource(searchApi, key).odata()
            .filter('reviewTeamId', id)
            .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.changeHistory = data;
                });
            }

            return oSvc.getDeferred().promise;
        }

        factory.getChangeHistoryByProgramReviewId = function (id) {
            var oSvc = odataSvc.get();

            oSvc.getSource(searchApi, key).odata()
            .filter('programReviewId', id)
            .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.programChangeHistory = data;
                });
            }

            return oSvc.getDeferred().promise;
        }

        /**********************************************************/

        factory.getChangeByChangeIdOdata = function (id) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, changeKey).odata()
            .expand('programReviewChangeDtos', 'programReviewDto')
            .expand('programReviewCampusDtos', 'addressDto')
            .filter('changeId', id)
            .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }



        factory.getChangeHistoryByReviewTeamId = function (id) {
            return $http.get(searchApiPath.format(null) + filterBase + buildReviewTeamIdFilter(id)).then(onSuccess, onFailure);
        };

        factory.getChangeByChangeId = function (id) {
            return $http.get(apiPath.format(null) + filterBase + buildChangeIdFilter(id) + expandBase + includeProgramReviewChangeDtos + includeProgramReviewCampusDtos).then(onSuccess, onFailure);
        };

        factory.create = function (newChange) {
            return $http.post(apiPath.format(null), newChange).then(onSuccess, onFailure);
        };

        factory.update = function (change) {
            return $http.put(apiPath.format(change.changeId), change).then(onSuccess, onFailure);
        };

        factory.delete = function (changeId) {
            return $http.delete(apiPath.format(changeId)).then(onSuccess, onFailure);
        };

        var buildFilter = helperSvc.buildFilter;

        var buildChangeIdFilter = function (id) {
            return buildFilter('changeId eq', id);
        };

        var buildChangeTypeIdFilter = function (id) {
            return buildFilter('changeTypeId eq', id);
        };

        var buildReviewTeamIdFilter = function (id) {
            return buildFilter('reviewTeamId eq', id);
        };

        var buildProgramIdFilter = function (id) {
            return buildFilter('programId eq', id);
        };

        function resolveChangeHistoryData(oSvc) {

        }

        return {
            getChangeHistoryByReviewTeamId: factory.getChangeHistoryByReviewTeamId,
            getChangeByChangeId: factory.getChangeByChangeId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            getChangeHistoryByReviewTeamIdOdata: factory.getChangeHistoryByReviewTeamIdOdata,
            data: factory.data
        };
    };
    module.factory('changeSvc', changeSvc);

})(angular.module('common'));