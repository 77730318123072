(function (module) {

    var appraisalMgmtCtrl = function (volunteer, $q, $scope, $uibModalInstance, helperSvc, userReviewSvc, appraisalSvc, alertSvc, codeSvc) {
        var model = $scope;

        model.title = 'Manage Appraisals';
        model.appraiseeName = volunteer.firstName + ' ' + volunteer.lastName;
        model.subtitle = model.appraiseeName;
        model.volunteer = volunteer;
        model.formatDate = helperSvc.formatDate;
        model.isDataReady = false;
        model.close = function () {
            $uibModalInstance.close();
            return false;
        };

        model.delete = function (appraisal) {
            appraisalSvc.delete(appraisal).then(function () {
                alertSvc.addAlertSuccess("Appraisal successfully deleted.");
                $uibModalInstance.close();
            })
        };

        var activate = function () {
            //q.all it
            var appraisalDataSourceArray = [
                 {
                     storeData: false,  
                     svcCallback: [appraisalSvc.getAppraisalsForAdmin],
                     svcCallbackArguments: [volunteer.volunteerId, userReviewSvc.data.currentReviewTeam.reviewTeamId],
                 },
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getAppraisalTypes],
                    dataLocationName: 'appraisalTypes',
                    helperCallback: helperSvc.getValue
                }
            ]

            helperSvc.getData(appraisalDataSourceArray).then(function () {
                model.data = appraisalSvc.data;
            });
            
        }();

        model.getAppraisalType = function(appraisalTypeId){
            //treat as hashMap where Id is the key
            return model.appraisalTypes[appraisalTypeId -1].codeName;
        }

    };

    module.controller('appraisalMgmtCtrl', appraisalMgmtCtrl);

}(angular.module('userReview')));