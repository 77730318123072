(function (module) {
    var npcAdminCtrl = function ($scope, $uibModal, $state, $timeout, $window, helperSvc, barSvc, alertSvc, npcSvc, codeSvc, eventSvc) {
        var model = this;
        var dataSourceArray = [];

        model.titles = 'Notifications of Program Changes';
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.isDataReady = false;
        model.searchOptions = {};
        model.npcs = [];

        model.getColorClass = npcSvc.getColorClass;

        eventSvc.listen(
            () => {
                if (!model.selectedOrganizationId) return;

                model.isDataReady = false;
                barSvc.showSpinner();
                model.retrieve();
            },
            'refreshNpcData',
            $scope
        );


        model.openNpcAdmin = function (npc) {
            model.selectedOrganizationId = npc.organizationId;
            $state.go('npc.detail', { npcId: npc.npcId, organizationId: model.selectedOrganizationId }); // change npcId value to request.npcId
        };

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.resultMsg = null;
            barSvc.showSpinner();

            npcSvc.advancedSearch(searchOpts).then(function (data) {
                barSvc.hideSpinner();
                model.npcs = data;

                if (model.npcs.length === 0) {
                    model.resultMsg = "No results found";
                }
                model.isDataReady = true;
            });
        }

        model.deleteNpc = function (npc) {

            alertSvc.confirmDelete(npc.organizationName + " " + npc.programName + " (" + npc.degreeCode + ") for " + npc.npcChangeTypeName, deleteFunc, npc);


            function deleteFunc() {
                npcSvc.deleteNpcProgramChange(npc).then(function () {
                    alertSvc.addAlertSuccess('NPC succesfully deleted.');
                    model.retrieve();
                }, function () {
                        alertSvc.addAlertWarning('NPC could not be deleted at this time.');
                });
            }
        }

        model.reset = function () {
            reset();
        }

        function getYear() {
            //get years from start year to current year
            var STARTYEAR = 2019;
            var currentYear = new Date().getFullYear();

            var numberOfYearsToAdd = (currentYear - STARTYEAR) + 1;
            var years = [];

            for (var i = numberOfYearsToAdd; i > 0; i--) {
                years.push(currentYear - (numberOfYearsToAdd - i));
            }

            return years;
        }

        function reset() {
            model.searchOptions = {
                submissionYear: null,
                statusId: null,
                organizationName: null,
                typeChangeId: null,
                commissions: []
            };
            model.resultMsg = model.defaultSearchMsg;
            model.npcs = [];
        }

        var statusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'npcStatuses',
            svcCallback: codeSvc.getNpcStatuses,
            helperCallback: helperSvc.getResults
        };

        var changeTypeOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'npcChangeTypes',
            svcCallback: codeSvc.getNpcChangeTypes,
            helperCallback: helperSvc.getResults
        };

        var commissionOptionsDataSource = {
            dataHolder: model,
            svcCallback: [codeSvc.getCommissions],
            dataLocationName: 'commissions',
            helperCallback: helperSvc.getValue
        };

        var activate = function () {
            barSvc.showSpinner();

            model.submissionYears = getYear();
            reset();
            
            dataSourceArray.push(statusOptionsDataSource);
            dataSourceArray.push(changeTypeOptionsDataSource);
            dataSourceArray.push(commissionOptionsDataSource);

            helperSvc.getData(dataSourceArray).then(function () {
                barSvc.hideSpinner();
            });


            model.isDataReady = true;
            
        }();
    }
    module.controller('npcAdminCtrl', npcAdminCtrl);

}(angular.module('npc')));