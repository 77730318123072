(function (module) {
    var npcFocusedVisitTabCtrl = function ($scope, $state, $stateParams, helperSvc, barSvc, alertSvc, $uibModal, oauth, npcSvc, eventSvc, teamMemberTypeNames, teamMemberTypeIds, currentUser,
        npcStatusIds, npcReportTypes, npcReportTemplateSvc, npcProgramChangeReportSvc, npcReportTypes, npcSectionTypes, npcFinalActionTypeNames) {
        var model = this;
        model.parent = $scope.$parent.$parent.$parent.model;
        model.currentPersonId = parseInt(currentUser.profile.personId);
        model.teamMemberTypeNames = teamMemberTypeNames;
        model.teamMemberTypeIds = teamMemberTypeIds; 
        model.npcReportTypes = npcReportTypes;
        model.isDataReady = false;
        model.slug = 'focused'; //pevAppSvc.slugs.SUBMIT;
        model.formatDate = helperSvc.formatDate;
        model.isAdmin = oauth.isAdmin();
        model.isAdjunct = oauth.isAdjunct();
        model.isTeamchair = model.parent.isVisitTeamchair;
        model.npcFinalActionTypeNames = npcFinalActionTypeNames;
        model.filterByTeamMemberType = function (reviewer) {
            return  reviewer.teamMemberTypeId === teamMemberTypeNames.NPCFVREVIEWER || reviewer.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || 
                reviewer.teamMemberTypeId === teamMemberTypeNames.NPCFVADJ;
        }

        function refreshData() {
            eventSvc.broadcast('refreshNpcData');
            return npcSvc.getNPCById(model.npcData.npcId).then(
                data => {
                    model.npcData = data;
                    model.parent.npcData = data;
                }
            );
        }

        model.viewNote = function (note) {
            alertSvc.information(note, "View Note")
        }

        model.removeReviewer = function (reviewer) {
            npcSvc.removeReviewer(reviewer).then(function () {
                refreshData();
            });
        }
        model.changeHasVisit = function (change) {
            return change.npcProgramChangeProgressDtos.some(x => x.npcStatusId === npcStatusIds.FOCUSEDVISIT);
        }

        model.addReviewer = function (program, change, teamMemberType) {
            var programReview = model.parent.programReviews.find(x => x.programId === program.programId);
            var reviewTeamId = programReview.reviewTeamId;

            //we use the program review reviewteam id.
            //if the first program review does not have one try to find one for COI
            //if none have it then ignore COI
            if (!reviewTeamId) {
                var reviewWithReviewTeamId = model.parent.programReviews.find(x => x.reviewTeamId != null);
                if (reviewWithReviewTeamId)
                    reviewTeamId = reviewWithReviewTeamId.reviewTeamId;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcAddReviewer.html',
                size: 'md',
                controller: 'npcAddReviewerModalCtrl',
                resolve: {
                    organizationId: () => model.npcData.organizationId,
                    commissionId: () => model.npcData.commissionId,
                    programReview: () => programReview,
                    npc: () => model.npcData,
                    programChange: () => change,
                    teamMemberTypeId: () => teamMemberType,
                    reviewTeamId: () => reviewTeamId
                }
            });

            modalInstance.result.then(() => {
                refreshData();
            });
        }
        model.isReadyEditFVExcomDecision = function (change) {
            return model.npcData.npcProgramDtos.some(t => t.npcProgramChangeDtos
                .some(s => s.npcProgramChangeId === change.npcProgramChangeId &&
                    (s.npcProgramChangeReportDtos.some(p => p.submittedDate && p.reportType == 'Statement' && p.teamMemberTypeId === model.teamMemberTypeNames.NPCFVADJ))))
        }

        model.editExcomFVDecision = function (program, programChange, change) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcExcomDecisionEdit.html',
                size: 'md',
                controller: 'npcExcomDecisionEditModalCtrl',
                resolve: {
                    program: () => program,
                    npc: () => model.npcData,
                    programChange: () => programChange,
                    changeJson: () => change
                }
            });

            modalInstance.result.then(() => {
                refreshData();
            });

        }

        model.getProgramChangeReportByPersonAndChangeId = function (programId, reviewerId, changeId) {
            var result = "Pending Submission";
            var program = model.npcData.npcProgramDtos.find(x => x.programId === programId);

            if (program) {
                var programChange = program.npcProgramChangeDtos.find(x => x.npcProgramChangeId === changeId);

                if (programChange) {
                    var report = programChange.npcProgramChangeReportDtos.find(x => x.npcProgramChangeReviewerId === reviewerId && x.reportType === npcReportTypes.STATEMENT);

                    if (report && report.submittedDate) {
                        var submittedDate = new Date(report.submittedDate);
                        var month = submittedDate.getMonth() + 1;
                        var day = submittedDate.getDate();
                        var year = submittedDate.getFullYear();
                        var dateString = month + "/" + day + "/" + year;
                        var raObj = report.reportJson.find(x => x.npcSectionTypeId === npcSectionTypes.RECOMMENDATION);
                        var recommendedAction = report.teamMemberTypeId !== model.teamMemberTypeNames.NPCFVADJ && raObj ? raObj.recommendationTypeName : 'N/A';

                        result = "Submitted on " + dateString + "   RA: " + recommendedAction;
                        //TODO NEED TO ADD JSON REPORT For THE RA
                    }
                    else {
                        result = '';
                    }
                }
            }

            return result;
        }

        model.canEditStatement = function (programId, changeId) {

            var result = false;

            if (model.parent.isVisitTeamchair) {
                var program = model.npcData.npcProgramDtos.find(x => x.programId === programId);
                if (program) {
                    var programChange = program.npcProgramChangeDtos.find(x => x.programChangeId === changeId);

                    if (programChange) {
                        var report = programChange.npcProgramChangeReportDtos.find(x => x.submittedByPersonId === model.currentPersonId && x.reportType === "Statement");

                        if (report && report.submittedDate) {
                            return false;
                        }
                    }
                }
                return true;
            }

            return result;
        }


        model.changeVisitDates = function (program, change) {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcEditProgramVisitDates.html',
                size: 'md',
                controller: 'npcEditProgramVisitDatesModalCtrl',
                resolve: {
                    programChange: () => change,
                    program: () => program
                }
            });

            modalInstance.result.then(result => {
                refreshData();
            });

        }

        model.getLastProgramProgressStatus = npcSvc.getLastProgramProgressStatus;

        model.openStatement = function (statement) {
            const npcProgramDto = model.npcData.npcProgramDtos.find(npcProgramDto =>
                npcProgramDto.npcProgramChangeDtos.some(npcProgramChangeDto =>
                    npcProgramChangeDto.npcProgramChangeId === statement.npcProgramChangeId
                )
            );
            const programReview = model.parent.programReviews.find(programReview => programReview.programId === npcProgramDto.programId && programReview.isCurrent);

            $state.go('npcEditorTool', {
                npcId: model.npcData.npcId,
                npcProgramChangeReportId: statement.npcProgramChangeReportId,
                returnStateName: $state.current.name,
                returnStateParams: {
                    npcId: model.npcData.npcId,
                    organizationId: model.npcData.organizationId,
                    view: $stateParams.view
                },
                npc: model.npcData,
                organization: model.parent.organizationData,
                programReview: programReview
            });
        }

        model.createStatement = function (change) {
            const reviewer = change.npcProgramChangeReviewerDtos.find(reviewer => reviewer.personId === model.currentPersonId &&
                            (model.isTeamchair ? model.teamMemberTypeNames.TEAMCHAIR : model.teamMemberTypeNames.NPCFVADJ) === reviewer.teamMemberTypeId);
            const program = model.npcData.npcProgramDtos.find(npcProgram => npcProgram.npcProgramId === change.npcProgramId)?.programDetailDto;
            const blankStatement = npcReportTemplateSvc.createEmptyTemplate(change, reviewer, npcReportTypes.STATEMENT, program);

            if (model.isTeamchair) {
                npcProgramChangeReportSvc.create(blankStatement).then(statement => {
                    refreshData().then(() => {
                        model.openStatement(statement);
                    });
                });
            }
            else {
                const tcStatement = change.npcProgramChangeReportDtos.find(y => y.reportType == 'Statement' && y.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR && y.submittedDate);

                npcProgramChangeReportSvc.adjunctCreateReport(tcStatement.npcProgramChangeReportId, reviewer.npcProgramChangeReviewerId)
                    .then(newStatement => {
                        refreshData().then(() => {
                            model.openStatement(newStatement);
                        });
                });
            }
        }

        var activate = function () {
            model.npcData = model.parent.npcData;

            model.isDataReady = true;

            
        }();
    };
    module.controller('npcFocusedVisitTabCtrl', npcFocusedVisitTabCtrl);

}(angular.module('npc')));