(function (module) {

    var templateRoot = '/apps/rfr/templates/';

    module.directive('institutionalReviewOptions', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrInstitutionalReviewOptions.html',
            scope: {
                isPreviewMode: '<?'
            }
        };
    });

    module.directive('commissionReviewOptions', function() {
        return{
            restrict: 'E',
            templateUrl: templateRoot + 'rfrCommissionReviewOptions.html',
            scope: {
                isPreviewMode: '<?',
                isSubmitMode: '<?'
            }
        };
    });

    module.directive('programInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrProgramInfo.html',
            scope: {
                isPreviewMode: '<?'
            }
        };
    });

    module.directive('rfrSummary', function (rfrSvc, organizationSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrSummary.html',
            scope: {
                rfr: '=',
                isSubmitMode: '<?'
            },
            link: function (scope, elem, attrs) {
                scope.rfrHasCommissionDtos = rfrSvc.rfrHasCommissionDtos;
                scope.institutionName = '';
                scope.reviewCycleYearSpan = (scope.rfr.reviewYear - 1) + '–' + scope.rfr.reviewYear;
                organizationSvc.getOrganizationById(scope.rfr.organizationId).then(function (data) {
                    scope.institutionName = data.currentOrganizationDetailDto.organizationName;
                });
            }
        };
    });

    module.directive('locationDetails', ['codeSvc', 'rfrSvc', function (codeSvc, rfrSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrLocationDetails.html',
            scope: {
                locationData: '=?'
            }, 
            link: function (scope, element, attrs) {
                if (!scope.locationData) {
                    codeSvc.getCountries().then(function (data) {
                        scope.countryList = data.value;
                        
                        scope.locationData = {};
                        scope.locationData.locations = rfrSvc.getCampusLocations().campusLocations;

                        for (var i = 0; i < scope.locationData.locations.length; i++) {
                            scope.locationData.locations[i].addressDto.countryName = getCountryName(scope.locationData.locations[i]);
                        }
                        
                    });

                }
                
                function getCountryName(address) {
                    var countryCode = address.addressDto.countryCode;

                    var selectedCountry = scope.countryList.find(function (country) {
                        return country.codeKey === countryCode;
                    });

                    return selectedCountry ? selectedCountry.codeName : '';
                }

            }
        };
    }]);

    module.directive('rfrCommissionCompare', function (typeConstSvc, programSummarySvc, helperSvc, rfrSvc, deepObjectCompareSvc, programInfoHelperSvc, commissionIds) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrCommissionCompare.html',
            scope: {
                originalJsonObject: '<',
                comparedJsonObject: '<'
            },
            link: function (scope, element, attrs) {
                scope.commissionsCompared = [];
                scope.commissionOriginals = [];
                scope.commissionIds = commissionIds;
                scope.getProgramRequestType = programInfoHelperSvc.getProgramRequestType;

                var originalJsonObject = angular.copy(scope.originalJsonObject);
                var comparedJsonObject = angular.copy(scope.comparedJsonObject);

                //map compared rfr commission dtos to eachother... they need to have the same amount of commissions!!!
                if (originalJsonObject && originalJsonObject.rfrCommissionDtos && originalJsonObject.rfrCommissionDtos.length === comparedJsonObject.rfrCommissionDtos.length) {

                    var originalConvertedCommissionDtos = convertJsonAndSort(originalJsonObject.rfrCommissionDtos);
                    var comparedConvertedCommissionDtos = convertJsonAndSort(comparedJsonObject.rfrCommissionDtos);

                    for (var i = 0; i < originalConvertedCommissionDtos.length; i++) {
                        scope.commissionOriginals.push(originalConvertedCommissionDtos[i]);

                        var diffedObj;
                        var convertedObj;

                        //list of unique array property ids to compare on
                        diffedObj = deepObjectCompareSvc.deepDiff(originalConvertedCommissionDtos[i].jsonContents, comparedConvertedCommissionDtos[i].jsonContents, [['programName', 'programId'], 'disciplineId', 'campusName', 'webpageUrl']);
                        convertedObj = deepObjectCompareSvc.convertDiffedObject(diffedObj);

                        comparedConvertedCommissionDtos[i].jsonContents = convertedObj;

                        //just added the jsoncontents to the commission itself and not just have it on its own
                        scope.commissionsCompared.push(comparedConvertedCommissionDtos[i]);

                    }
                }

                function convertJsonAndSort(commissionDtos) {

                    angular.forEach(commissionDtos, function (value, key) {
                        commissionDtos[key].jsonContents = angular.fromJson(value.jsonContents);
                        if (commissionDtos[key].jsonContents.programs)
                            commissionDtos[key].jsonContents.programs.sort(function (a, b) { return (a.programName > b.programName) ? 1 : ((b.programName > a.programName) ? -1 : 0); });
                    });

                    return commissionDtos
                }

                //helper functions
                scope.getProgramReviewTypes = function (type) {
                    if (type) {
                        if (type.value) {
                            return typeConstSvc.getProgramReviewName(type.value);
                        }
                        return typeConstSvc.getProgramReviewName(type);
                    }
                    return null;
                }
                scope.getProgramCampus = function (program) {
                    return programSummarySvc.getProgramCampusFromRFRProgram(program);
                }
                scope.checkIfObject = function (property) {
                    return typeof property === 'object';
                }
                scope.formatUrl = helperSvc.formatUrl;
                scope.formatEmptyObject = helperSvc.formatEmptyObject;
                scope.formatDate = helperSvc.formatDate;
                scope.getBooleanText = helperSvc.getBooleanText;

            }
        };
    });

    module.directive('programListing', function ($uibModal, $state, rfrSvc, rfrValidationSvc, programSummarySvc, alertSvc, helperSvc, currentUserHelper, typeConstSvc, terminationOptions, programReviewTypeIds, programInfoHelperSvc, oauth, readinessSvc, readinessValidationSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programListing.html',
            replace: 'true',
            scope: {
                programs: '=',
                npcs: '=',
                commission: '=?',
                rfe: '@?',
                readiness: '@?',
                preview: '<?',
                hideBottomBtns: '<?'
            },
            link: function (scope, elem, attrs) {
                scope.data = null;
                scope.formatUrl = helperSvc.formatUrl;
                scope.formatDate = helperSvc.formatDate;
                scope.mode = { rfe: false, readiness: false, preview: false };
                scope.btnsAreVisible = false;
                scope.isAdmin = oauth.isAdmin();
                scope.getBooleanText = helperSvc.getBooleanText;
                scope.isInterim = rfrSvc.isInterim;
                scope.getProgramRequestType = programInfoHelperSvc.getProgramRequestType;

                var activate = function () {
                    scope.mode = {
                        rfe: attrs.rfe ? (scope.rfe.toLowerCase() == 'true') : false,
                        readiness: attrs.readiness ? (scope.readiness.toLowerCase() == 'true') : false,
                        preview: scope.preview ? scope.preview : false
                    };

                    if (scope.mode.rfe) {
                        scope.data = rfrSvc.data;
                        scope.btnsAreVisible = !scope.mode.preview && (!scope.commission.submittedTimestamp && rfrSvc.isAuthorizedToEditRFE(scope.commission) || scope.isAdmin) ;
                    } else if (scope.mode.readiness) {
                        scope.data = readinessSvc.data;
                        scope.btnsAreVisible = !scope.mode.preview;
                    } else {
                        scope.btnsAreVisible = !scope.mode.preview;
                    }
                }();

                scope.hasNpcTermination = function (program) {
                    return scope.npcs?.some(npc => npc.programId === program.programId && npc.npcType === 'Termination');
                };

                scope.hasNpcData = function (program) {
                    return scope.npcs?.find(function (npc) { return npc.programId == program.programId; }) ? true : false;
                };

                scope.getNpcTypeName = function (program) {
                    var npc = scope.npcs.find(function (npc) { return npc.programId == program.programId; });
                    var changeTypeArr = npc.changeJson.map(function (change) { return change.npcTypeName; });
                    return changeTypeArr.join(" / ");
                };

                scope.openNpc = function (program) {
                    alertSvc.confirm(
                        "As of the <strong>2023–24 RFE cycle</strong>, program change requests are handled through the <strong>Program Change</strong> process. You are being redirected to the Notification of Program Change page to manage your change request.",
                        function () {
                            const npc = scope.npcs?.find(function (npc) { return npc.programId == program.programId; });
                            if (npc)
                                $state.go(scope.isAdmin ? 'npc.detail' : 'npcUser.detail', { npcId: npc.npcId, organizationId: scope.data.rfr.organizationId });
                            else
                                $state.go(scope.isAdmin ? 'npc' : 'npcUser');
                        }
                    );

                    //var modalInstance = $uibModal.open({
                    //    animation: true,
                    //    templateUrl: '/apps/rfr/templates/rfrTerminateProgramEdit.html',
                    //    size: 'lg',
                    //    controller: 'rfrTerminateProgramEditCtrl',
                    //    resolve: {
                    //        program: function () {
                    //            return program;
                    //        }
                    //    }
                    //})
                };
                
                scope.getCommission = function(program){
                    return program.commissionName;
                }

                scope.isAbetChooses = function(program) {
                    if(program.disciplines && Array.isArray(program.disciplines) && !scope.mode.preview && rfrSvc.isAuthorizedToEditRFE(scope.commission)){
                        return helperSvc.arrayContainsByPropertyValue(program.disciplines, 'disciplineId', 0);         
                    }
                    return false;
                }

                scope.hasSingleProgram = function () {
                    return Array.isArray(scope.programs) && scope.programs.length == 1;
                };

                scope.edit = function (program, isChangeNameRequest) {
                    rfrSvc.addEditProgram(program, scope.commission, isChangeNameRequest, scope.mode);
                };

                scope.requestTermination = function (program) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/apps/rfr/templates/rfrTerminateProgramEdit.html',
                        size: 'lg',
                        controller: 'rfrTerminateProgramEditCtrl',
                        resolve: {
                            program: function () {
                                return program;
                            }
                        }
                    });
                };

                scope.delete = function (program) {
                    alertSvc.confirmDelete(scope.getTitle(program), deleteFunc);

                    function deleteFunc() {
                        if (scope.mode.rfe) {
                            // Delete new program from RFE data
                            var data = angular.copy(rfrSvc.data);
                            var commissionIndex = rfrSvc.data.rfr.rfrCommissionDtos.indexOf(scope.commission);
                            var programIndex = rfrSvc.data.rfr.rfrCommissionDtos[commissionIndex].jsonContents.programs.indexOf(program);

                            // Revert review type if it was modified as a result of adding this program.
                            if (program.originalReviewTypeCode) {
                                data.rfr.rfrCommissionDtos[commissionIndex].reviewTypeCode = program.originalReviewTypeCode;
                            }

                            data.rfr.rfrCommissionDtos[commissionIndex].jsonContents.programs.splice(programIndex, 1);

                            rfrSvc.update(data.rfr).then(function () {
                                alertSvc.addAlertSuccess("Program succesfully deleted.");
                                rfrValidationSvc.invokeValidation();
                            });
                        } else if (scope.mode.readiness) {
                            // Delete new program from scope.programs
                            var index = scope.programs.findIndex(function (p) {
                                return program === p;
                            });

                            if (angular.isNumber(index)) {
                                var data = angular.copy(readinessSvc.data);
                                data.selectedRR.programs.splice(index, 1);

                                readinessSvc.update(data.selectedRR).then(function () {
                                    alertSvc.addAlertSuccess("Program succesfully deleted.");
                                    readinessValidationSvc.invokeValidation();
                                });
                            }
                        }
                        else {
                            // Delete new program from scope.programs
                            var index = scope.programs.findIndex(function (p) {
                                return program === p;
                            });

                            if (angular.isNumber(index)) {
                                scope.programs.splice(index, 1);
                            }
                        }
                    }
                };

                scope.isNewProgram = function (program) {
                    return !program.programId || (typeof program.programId == 'undefined') || (program.programReviewTypeCode === programReviewTypeIds.INITIALACCREDIATION);
                };

                scope.getTitle = function (program) {
                    var title = null;

                    if (program.programName) title = program.programName + " (" + program.degreeCode + ")";
                    if (title && program.programReviewTypeCode) {
                        if ((program.programReviewTypeCode !== programReviewTypeIds.TERMINATIONVISIT) &&
                            (program.programReviewTypeCode !== programReviewTypeIds.TERMINATIONREPORT) &&
                            (program.programReviewTypeCode !== programReviewTypeIds.TERMINATION)) {
                            title += "—" + typeConstSvc.getProgramReviewName(program.programReviewTypeCode);
                        }
                    }

                    return title;
                };

                scope.getTerminateText = function (program) {
                    var text = 'Request Termination';
                    if (program.requestToTerminate) text = 'Edit Termination Request';
                    return text;
                };

                scope.getDegreeLevel = function (degreeLevelCode) {
                    return typeConstSvc.getDegreeLevel(degreeLevelCode);
                };

                scope.getErrorIndex = function (program) {
                    if (scope.mode.readiness) {
                        return readinessSvc.getProgramKey(program);
                    } else if (scope.commission) {
                        return scope.commission.commissionId + program.programName + program.degreeCode;
                    }
                };

                scope.getProgramCampuses = function (program) {
                    return programSummarySvc.getProgramCampusListFromRFRProgram(program);
                };

                scope.getNumberOfPevs = function (program) {
                    var num = 0;
                    if (program.requestToTerminate) {
                        return num;
                    } else if (program.disciplines) {
                        program.disciplines.forEach(function (discipline) {
                            if (discipline.pevsRequired !== undefined) num += discipline.pevsRequired;
                        });
                    }
                    return num;
                };
            }
        };
    });

    module.directive('commissionTerms', function (helperSvc, $filter, rfrSvc, onsiteVisitExpectations,htmlTemplateSvc,alertSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrCommissionTerms.html',
            scope: {
                rfr: '=?',
                isPreviewMode: '<?',
                isSubmitMode: '<?',
                commissionInfo: '='
            },
            link: function (scope, elem, attrs) {
                scope.formatDate = helperSvc.formatDate;
                scope.academicYear = helperSvc.getAcademicYear();
                scope.isDataReady = false;
                scope.reviewYearSpan = function () {
                    return scope.academicYear + '-' + (scope.academicYear + 1).toString().substring(2,4);
                }
                const RFR_TERM_TEMPLATEID = 1;

                if (scope.isSubmitMode) {
                    scope.commissionInfo.jsonContents.onsiteVisitExpectation = { selection: null, webpage: null, reason: null }
                    scope.onsiteVisitExpectations = onsiteVisitExpectations;
                }

                if (!scope.rfr) scope.rfr = rfrSvc.data.rfr;

                scope.anyTermsAccepted = scope.rfr &&
                    scope.rfr.rfrCommissionDtos &&
                    scope.rfr.rfrCommissionDtos.some(function (commission) {
                        return commission.termsAcceptedTimestamp;   
                    });  

                if (scope.rfr && scope.rfr.rfrCommissionDtos) {
                    var termsAcceptByCommissions = scope.rfr.rfrCommissionDtos.filter(function (commission) {
                        return commission.termsAcceptedTimestamp;
                    });
                }

                if (termsAcceptByCommissions.length) {
                    var termsData = {};

                    termsAcceptByCommissions.forEach(function (commission) {
                        var commissionData = {
                            names: [commission.commissionAbbreviatedName],
                            date: $filter('date')(helperSvc.formatDate(commission.termsAcceptedTimestamp), "MM/dd/yyyy", "UTC")
                        };

                        if (!termsData[commission.termsAcceptedByUserId]) {
                            termsData[commission.termsAcceptedByUserId] = {
                                name: commission.termsAcceptedByProfessionalName,
                                commissions: [commissionData]
                            };
                        } else {
                            var unsavedDate = commissionData.date;
                            var matchingDateFound = false;

                            termsData[commission.termsAcceptedByUserId].commissions = termsData[commission.termsAcceptedByUserId].commissions.map(function (currentCommission) {
                                // If the date that terms were accepted by a user matches the date terms were accepted by the same user for a different commission
                                // add the new commission name to the existing entry with the same date
                                if (unsavedDate == currentCommission.date) {
                                    currentCommission.names.push(commission.commissionAbbreviatedName);
                                    matchingDateFound = true;
                                    return currentCommission;
                                } else {
                                    return currentCommission;
                                }
                            });

                            if (!matchingDateFound) termsData[commission.termsAcceptedByUserId].commissions.push(commissionData);
                        }
                    });

                    var termsArr = [];
                    for (var key in termsData) {
                        if (termsData.hasOwnProperty(key)) {
                            var user = termsData[key];
                            var commissionStrArr = user.commissions.map(function (commission) {
                                commission.names = addAndToLastElement(commission.names);
                                return commission.names.join(commission.names.length > 2 ? ', ' : '') + ' on ' + commission.date;
                            });
                            commissionStrArr = addAndToLastElement(commissionStrArr);
                            var str = user.name + ' for ' + commissionStrArr.join(commissionStrArr.length > 2 ? ', ' : '');
                            termsArr.push(str);
                        }
                    }

                    if (termsArr.length > 1) {
                        scope.termsAcceptedBy = '<ul>' + termsArr.map(function (term) {
                            return '<li>' + term + '</li>';
                        }).join('') + '</ul>';
                    } else {
                        scope.termsAcceptedBy = termsArr.join('');
                    }

                    function addAndToLastElement(arr) {
                        if (arr.length > 1) {
                            arr[arr.length - 1] = ' and ' + arr[arr.length - 1]
                        }
                        return arr;
                    }
                }

                var templateDate = helperSvc.getISODate(new Date(scope.academicYear - 1, 11, 31));
                htmlTemplateSvc.getHtmlTemplate(RFR_TERM_TEMPLATEID,templateDate).then(function (data) {
                    scope.rfrTermTemplate = JSON.parse(data.value[0].templateJson);
                    scope.isDataReady = true;
                });
            }
        };
    });

    module.directive('signature', function (helperSvc, organizationSvc, rfrSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'rfrSignature.html',
            scope: {
                rfr: '=?',
                cao: '=?',
                isPreviewMode: '<?'
            },
            link: function (scope, elem, attrs) {
                scope.formatDate = helperSvc.formatDate;

                if (!scope.rfr) scope.rfr = rfrSvc.data.rfr;
                if (!scope.cao) scope.cao = rfrSvc.data.cao;

                const { name, title } = rfrSvc.getCAOLabel(scope.rfr, scope.cao);
                scope.caoName = name;
                scope.caoTitle = title;

                organizationSvc.getOrgByIdOdata(scope.rfr.organizationId).then(function (data) {
                    scope.organizationName = data.currentOrganizationDetailDto.organizationName; 
                });
            }
        };
    });

}(angular.module('rfr')));