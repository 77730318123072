(function (module) {

    /* COMMON filters includes the following:
        - breakEmailAddress
        - classify
        - isRoleOnly
        - spaceAfterForwardSlash
        - teamMemberTypeFilter
        - truncate
        - truncateMiddle
        - uniqueStringProps
        - url
    */

    module.filter('breakEmailAddress', function () {
        return function (input) {
            // \u200B is unicode character "ZERO WIDTH SPACE", allowing for linebreaks
            return (typeof input === 'string') ? input.replace(/\@/g, '@\u200B') : input;
        }
    });

    module.filter('classify', function () {
        return function (input) {
            return (typeof input === 'string') ? input.replace(' ', '-').toLowerCase() : input;
        }
    });

    module.filter('isRoleOnly', function () {
        return function (items, isRoleOnly) {
            var matches = [];
            if (isRoleOnly) {
                for (var i = 0; i < items.length; i++) {
                    if (items[i].isRoleOnly === true)
                        matches.push(items[i]);
                }
            } else {
                matches = items;
            }
            return matches;
        }
    });

    module.filter('spaceAfterForwardSlash', function () {
        return function (input) {
            return (typeof input === 'string') ? input.replace(/\//g, '/ ') : input;
        }
    });

    module.filter('teamMemberTypeFilter', function (values) {
        return function (input) {
            if (input.reviewTeamMemberDto) {
                angular.forEach(values, function (value) {
                    return teamGroupMemberDto.reviewTeamMemberDto.teamMemberTypeId == value;
                });
            }
            return false;
        }
    });

    //Truncates a string with an elipsis after a given number of characters - default is 10
    module.filter('truncate', function () {
        return function (text, length, hideEllipsis) {
            if (isNaN(length))
                length = 10;
            
            var end = hideEllipsis ? '' : '...';

            if (length < 0 || text.length <= length || text.length - end.length <= length) {
                return text;
            }
            else {
                return String(text).substring(0, length-end.length) + end;
            }
        };
    });

    module.filter('truncateMiddle', function () {
        return function (input, maxLength) {
            var separator = "…", // ellipsis (…) is a single character
                totalDisplayLength,
                frontLength,
                backLength;
            // maxLength defaults to 20
            if (!maxLength) {
                maxLength = 20;
            }
            // if input length is less than maxLength, or if maxLength can not 
            // accomodate separator with one character on each side, do nothing
            if (!input || input.length <= maxLength || maxLength < separator.length + 2) {
                return input;
            }
            totalDisplayLength = maxLength - separator.length;
            frontLength = Math.ceil(totalDisplayLength / 2);
            backLength = totalDisplayLength - frontLength;
            return input.substr(0, frontLength) + separator + input.substr(input.length - backLength);
        }
    });
    
    module.filter('uniqueStringProps', function () {
        return function (items, filterOn) {

            if (!(angular.isArray(items) && angular.isArray(filterOn))) {
                return items;
            }

            if (angular.isArray(filterOn)) {

                var filteredItems = [];
                var extractValueToCompare = function (item) {
                    if (angular.isObject(item)) {
                        var itemConcat = '';
                        angular.forEach(filterOn, function (filter) {
                            if (angular.isString(filter)) itemConcat += item[filter];
                        })
                        return itemConcat == '' ? item : itemConcat;
                    } else {
                        return item;
                    }
                };

                angular.forEach(items, function (item) {
                    var isDuplicate = false;

                    for (var i = 0; i < filteredItems.length; i++) {
                        if (angular.equals(extractValueToCompare(filteredItems[i]), extractValueToCompare(item))) {
                            isDuplicate = true;
                            break;
                        }
                    }
                    if (!isDuplicate) filteredItems.push(item);
                });
            }
            return filteredItems;
        }
    });

    module.filter('url', function () {
        return function (input) {
            var http = 'http://';
            var website = input;

            if (!website) {
                return;
            }

            var partial = website.substring(0, 7);

            return (http === partial) ? website : http + website;
        }
    });
    module.filter('convertStringToHtml', function ($sce) {
        return function (str) {
            str = str.replaceAll('&quot;', '\'').replaceAll('&spos;','\'');
            var aaa = $sce.trustAsHtml(str);
            return aaa;
        }
    });

}(angular.module('common')));