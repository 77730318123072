(function (module) {

    var generalCtrl = function ($uibModal, eventSvc, $location, $scope, oauth, organizationMdl, organizationTypes, collegeDirectorySvc, $state, helperSvc, organizationSvc, typeConstSvc) {
        var model = this;
        var parent = $scope.$parent.model;
        model.title = "General";
        model.isAdmin = oauth.isAdmin();
        model.organizationTypes = organizationTypes;

        model.collegeDirectory = null;
        model.collegeVerification = null;
        model.organizationId = 0;
        //activate();

        model.getURL = function () {
            if (model.data && model.data.organization) {
                var http = 'http://';
                var website = model.data.organization.currentOrganizationDetailDto.website;

                if (!website) {
                    return;
                }

                var partial = website.substring(0, 7);

                return (http === partial) ? website : http + website;
            }
        };

        model.openOrganization = function (organizationId) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/organization/templates/generalEdit.html',
                size: 'lg',
                controller: 'generalEditCtrl',
                resolve: {
                    id: function () {
                        return organizationId;
                    }
                }
            });
        };

        function getCollegeDirectoryById() {
            collegeDirectorySvc.getCollegeDiretoryById(model.organization.currentOrganizationDetailDto.collegeDirectoryId).then(function (data) {
                model.collegeDirectory = data;
                model.collegeVerification = null;
            });
        };

        function getPendingCollege() {
                collegeDirectorySvc.getCollegeVerificationByOrganizationDetailId(model.organization.currentOrganizationDetailDto.organizationDetailId).then(function (data) {
                    model.collegeVerification = helperSvc.getFirstArrayItem(data);
                    model.collegeDirectory = null;
                });
        };

        var generalDataSource = {
            dataHolder: organizationSvc,
            dataLocationName: 'data.organization',
            svcCallback: organizationSvc.getOrganizationById,
            svcCallbackArguments: [parent.organizationId, noStoredData = true],
            odataResource: true
        }

        initialOrganizationLoad();
        function initialOrganizationLoad() {
            var dataSourceArray = [
                generalDataSource
            ];
            
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                organizationSvc.data.organizationMdl = new organizationMdl(organizationSvc.data.organization, true);
                model.data = organizationSvc.data;
                model.organization = organizationSvc.data.organization;
                model.organizationType = typeConstSvc.getOrganizationName(model.data.organization.organizationTypeId);

                activate();
            });

        }


        function activate() {

            if (model.organization && model.organization.organizationTypeId === model.organizationTypes.INSTITUTION) {
                if (model.organization.currentOrganizationDetailDto.collegeDirectoryId) {
                    getCollegeDirectoryById();
                } else {
                    if (model.isAdmin) {
                        getPendingCollege();
                    }
                }
                
            }
            model.organizationId = model.data.organization.organizationId;
        }
        
       
    };

    module.controller('generalCtrl', generalCtrl);

}(angular.module('organization')));