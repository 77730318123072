(function (module) {

    var teamGroupsCtrl = function ($scope, $uibModal, helperSvc, typeConstSvc, reviewTeamGroupSvc, reviewTeamSvc) {
        var model = this;
        model.parent = $scope.$parent.model;
        model.title = 'Team Groups';
        model.noMemberMsg = 'None selected';
        model.isEmpty = helperSvc.isEmpty;
        model.teamMemberTypes = typeConstSvc.getTeamMemberTypes();
        model.data = reviewTeamGroupSvc.data;
        model.reviewTeam = reviewTeamSvc.data;

        model.editTeamGroups = function (teamGroups) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/teamGroupsEdit.html',
                size: 'lg',
                controller: 'teamGroupsEditCtrl',
                resolve: {
                    review: function () {
                        return model.reviewTeam.reviewTeam;
                    },
                    parent: function () {
                        return model;
                    }
                }

            })
        }




    };

    module.controller('teamGroupsCtrl', teamGroupsCtrl);

}(angular.module('review')));