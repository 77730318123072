(function (module) {

    var templateRoot = "/Apps/organization/templates/";

    module.directive('general', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'general.html'
        };
    }]);

    module.directive('userGroupsAndRoles', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'userGroupsAndRoles.html'
        };
    }]);

    module.directive('regionalAgencies', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'regionalAgencies.html'
        };
    }]);

    module.directive('commissionsAcctAndNgrYear', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'commissionsAcctAndNgrYear.html'
        };
    }]);

    module.directive('organizationHistory', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'organizationHistory.html'
        };
    }]);

    module.directive('currentPrograms', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'currentPrograms.html'

        };
    }]);

    module.directive('currentReviews', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'currentReviews.html'

        };
    }]);

    module.directive('organizationLink', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'organizationLink.html'

        };
    }]);

    module.directive('institutionSearch', function ($q, codeSvc, organizationSvc, programSvc, organizationRecognitionSvc, helperSvc, eventSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'institutionSearch.html',
            scope: {
                institution: '=',
                onSelectCallback: '&?',  // Func will have access to 1 argument: programs (arr) NEED TO change this to 2 arguments, to include the recognizing agency
                onDeselectCallback: '&?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.isInstitutionSelected = (scope.institution && !angular.equals(scope.institution, {})) ? true : false;
                scope.isNewInstitution = false;
                scope.noResults = false;

                scope.newSearch = function () {
                    if (scope.onDeselectCallback) scope.onDeselectCallback();

                    scope.institution = null;
                    scope.isInstitutionSelected = false;
                    scope.isNewInstitution = true;
                };

                eventSvc.listen(scope.newSearch, 'resetInstitutionSearch');

                scope.searchInstitution = function (searchText) {
                    return organizationSvc.quickSearchInstitution(searchText).then(function (data) {
                        scope.noResults = data.value.length > 0 ? false : true;
                        return data.value;
                    });
                };

                scope.onSelect = function (item) {
                    if (!item.organizationId) {
                        scope.institution = null;
                    } else {
                        programSvc.searchProgramsByOrganizationId(item.organizationId, true).then(function (data) {
                            scope.currentPrograms = helperSvc.getResults(data);
                            scope.isInstitutionSelected = true;
                            scope.isNewInstitution = false;
                        });

                        var programPromise = programSvc.searchProgramsByOrganizationId(item.organizationId, true);
                        var recognitionPromise = organizationRecognitionSvc.getRecognitionByOrganizationId(item.organizationId);

                        $q.all([programPromise, recognitionPromise]).then(function (data) {

                            var programData = helperSvc.getResults(data[0]);
                            var recognitionData = data[1];

                           
                            if (recognitionData) {
                                recognitionData = recognitionData.map(function (org) {
                                    return {
                                        recognitionOrganizationId: org.recognizingOrganizationId,
                                        organizationName: org.recognizingOrganizationName
                                    };
                                });
                            }
                            scope.institution.organizationRecognition = recognitionData;
                            if (scope.onSelectCallback) scope.onSelectCallback({ programs: programData, recognizingAgencies: recognitionData });
                            scope.isInstitutionSelected = true;
                            scope.isNewInstitution = false;
                        });
                    }
                };
            }
        };
    });

}(angular.module('organization')));