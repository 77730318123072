(function (module) {

    var addFindingCtrl = function ($scope, $uibModalInstance, sections, alertSvc, currentSection, statementTemplateSvc, statementStorageSvc, localStorage, criteriaIncluded, readinessSvc, statementFindingTypes, reviewTypeIds, programReviews, programReviewTypeIds) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Add Finding';
        model.numbers = [1, 2, 3, 4, 5];
        model.numMsg = '<strong>You must check the box on the left next to the finding you want to include in the template before editing the number of findings of that type</strong>. i.e. If there are 3 strengths for the program, check <strong>strength</strong> then select <strong>3</strong>.';
        model.sections = angular.copy(sections).sort(statementStorageSvc.statementDetailComparer);
        model.currentSection = angular.copy(currentSection);
        model.getProgramName = statementStorageSvc.getProgramName;
        model.getCriteriaFullName = readinessSvc.getCriteriaFullName;
        model.useCriterionType = statementStorageSvc.useCriterionType;
        model.criteriaIncluded = criteriaIncluded;
        model.programReviews = programReviews;
        model.statementFindingTypes = statementFindingTypes;

        model.setCurrentSection = function (section) {
            //need to reset all the IS and Program sections
            model.currentSection = section;

            if (!model.currentSection) return;

            if(statementStorageSvc.isTerminationProgram(model.currentSection)){
                model.isTerminationProgram = true;
                if(model.currentSection.statementJson.length > 1){
                    model.disabledTerminationSection = true;
                }else{
                    model.selectedFindingTypes = [];
                    model.selectedFindingTypes.push(statementTemplateSvc.getTerminationPlanFindingTypeTemplate());
                }
            }   
            else{
                model.disabledTerminationSection = false;
                model.selectedFindingTypes = model.currentSection.programId === null ? model.institutionSections : model.programSections
                model.isTerminationProgram = false;            
            }     
        }

        model.addFindings = function (addNormalToIR) {
            if (model.isSubmitting) return;
            model.isSubmitting = true;
            if(model.isInterimVisit){
                if(model.currentSection.programId){
                    if(!statementStorageSvc.isProgramInterimReview(model.currentSection.programId)){
                        model.irFinding =  { addNormalFinding: true };
                    }
                }
            }

            model.currentSection = statementTemplateSvc.addFinding(model.currentSection, model.selectedFindingTypes, model.irFinding);         
            //save the updated section
           var updatedStatement = angular.copy(statementStorageSvc.data.statement);
           var currentSectionIndex = statementStorageSvc.getSectionIndex(updatedStatement, model.currentSection);
           updatedStatement.statementDetailDtos[currentSectionIndex] = model.currentSection;

           statementStorageSvc.save(updatedStatement).then(function () {
                    alertSvc.addAlertSuccess("Finding successfully added.");
                    $uibModalInstance.close();
           }).catch(function (error) {
               console.log('Error while adding finding:', error);
               alertSvc.addModalAlertWarning("Finding could not be added at this time.");
               $uibModalInstance.close();
           });

        }

        model.isCriteriaNeeded = function (findingType) {
            if (findingType.value === true) {
                return true;
            }
            return false;
        }

        model.allFindingTypesValidated = function () {
            if (model.selectedFindingTypes) {
                var atleastOneSelected = false;
                for (var i = 0; i < model.selectedFindingTypes.length; i++) {
                    var findingType = model.selectedFindingTypes[i];
                    if (findingType.value) {
                        atleastOneSelected = true;
                        if ((!findingType.criteria && (findingType.number === 0 || findingType.number === null || findingType.number === undefined)) || (findingType.criteria && findingType.criteria.length === 0))
                            return false;
                    }
                }
                if (atleastOneSelected)
                    return true;
            }
            return false;
        }

        model.setNumAndValue = function (item) {
            item.number = item.value ? 1 : 0;
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.getAvailableCriteriaTypes = function (criterion, findingTypeId) {

            var finalCriterion = [];
            //get the findingTypeSection
            var findingTypeSectionIndex = statementStorageSvc.getFindingIndex(model.currentSection.statementJson, findingTypeId);
            var findingTypeSection = model.currentSection.statementJson[findingTypeSectionIndex];
            for (var x = 0; x < criterion.length; x++) {
                var criteriaNeeded = true;
                if (findingTypeSection && findingTypeSection.findings) {
                    for (var i = 0; i < findingTypeSection.findings.length; i++) {
                        var criteriaFinding = findingTypeSection.findings[i];
                        if (criterion[x].criteriaId === criteriaFinding.criteria.criteriaId) {
                            criteriaNeeded = false;
                            break;
                        }
                    }
                }
            
                if (criteriaNeeded)
                    finalCriterion.push(criterion[x]);
            }
            return finalCriterion;
        }

        var activate = function () {
            model.codes = statementStorageSvc.codes;
            model.institutionSections = angular.copy(statementTemplateSvc.data.institutionSectionsTemplate);
            model.programSections = angular.copy(statementTemplateSvc.data.programSectionsTemplate);

            
            model.isInterimReport = statementStorageSvc.data.currentReviewTeam.reviewTypeCode == reviewTypeIds.INTERIMREPORT;
            model.isInterimVisit = statementStorageSvc.data.currentReviewTeam.reviewTypeCode == reviewTypeIds.INTERIMVISIT;
            if(model.isInterimReport || model.isInterimVisit){
                model.irFinding = {addNormalFinding: false };
            }

            model.setCurrentSection(model.currentSection);

            model.dataIsReady = true;     
        }();
    };

    module.controller('addFindingCtrl', addFindingCtrl);

}(angular.module('statement')));