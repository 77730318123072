(function (module) {

    var indexCtrl = function (currentUser, $scope, $window, $location, helperSvc, offlineSvc, oauth, environmentSvc, eventSvc,menuSvc) {

        var model = this;
        var pathRootAdmin = '#/admin';
        var pathRootUser = '#/user';
        var pathRootCommon = '#/common';

        model.menu = [];
        model.myABETUrl = 'https://amsapp.abet.org/#/sso/abetpublic';
        model.isStage = environmentSvc.isStage();
        model.isProd = environmentSvc.isProd();
        model.isIE = helperSvc.isIE();
        model.isIE10 = helperSvc.isIE10();
        model.isIE11 = helperSvc.isIE11();
        model.signOut = oauth.logout;
        model.TSUrl = function () {
                         
            if (model.isProd) {
                return 'https://amspub.abet.org/teamspace/my-teamspaces'
            }
            else if (model.isStage) {
                return 'https://stagepub.abet.org/teamspace/my-teamspaces';
            }
            else {
                return 'http://localhost:4200/team-space/my-teamspaces';
            }
        }();
        model.CalendarUrl = function () {
            if (model.isProd) {
                return 'https://amspub.abet.org/coop/my-calendar'
            }
            else if (model.isStage) {
                return 'https://stagepub.abet.org/coop/my-calendar';
            }
            else {
                return 'http://localhost:4200/coop/my-calendar';
            }
        }();

        model.download = {
            chrome: 'https://www.google.com/chrome/browser/desktop/',
            firefox: 'https://www.mozilla.org/en-US/firefox/new/'
        };

        model.isAppOffline = offlineSvc.isAppOffline;

        $scope.$on('$stateChangeSuccess', function () {
            // Allows Google Analytics to track state/url changes on a single page app
            if (model.isAppOffline() || !navigator.onLine) return;
            if ($window.ga) {
                $window.ga('send', 'pageview', $location.path());
            } else {
                // Note: this code is duplicated in index.html; extract into a standlone library?
                (function (i, s, o, g, r, a, m) {
                    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                        (i[r].q = i[r].q || []).push(arguments)
                    }, i[r].l = 1 * new Date(); a = s.createElement(o),
                        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
                })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
                ga('create', 'UA-89322625-1', 'auto');
                ga('send', 'pageview', $location.path());
            }
        });

        eventSvc.listen(function () { init();
        }, 'refreshIndexCurrentInfo');

        function init() {

            model.user = currentUser.profile;
            model.hasUserData = model.user.hasOwnProperty('personId');
            model.isAdmin = oauth.isAdmin();
            model.paramValues = { personId: null, volunteerId: null };

            model.paramValues.personId = model.user.personId;
            model.paramValues.volunteerId = model.user.volunteerId;

            var roleArr = [...new Set(model.user.userRoles.map(t => t.roleId))];
            var roleStr = roleArr.join(",");

            menuSvc.getMenuByRoles(roleStr).then(function (data) {
                if (!data || data.status == 401) {
                    model.signOut();
                }
                model.menu = menuSvc.buildMenu(data, model.paramValues);
            });
        }

        var activate = function () {

            if (currentUser.profile.personId) { // Check if user is logged in
                init();
            }
        }();
    };

    module.controller('indexCtrl', indexCtrl);

}(angular.module('root')));
