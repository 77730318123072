(function (module) {
    var pevcTrainingCtrl = function ($uibModal, contactRoleTypes, $state, pevAppSvc, trainingStatusIds, reviewTeamMemberSvc, alertSvc, helperSvc, codeSvc, oauth,
        trainingSvc, $stateParams, volunteerHelperSvc, commissionAbbreviations, pevApplicationSocietyStatuses, tabSvc, appraisalPDFSvc, appraisalResultSvc) {

        var model = this;
        var selectedId = null;

        if ($stateParams.training == null) {
            return $state.go('training');
        }

        model.training = $stateParams.training;
        model.pageTitle = model.training.trainingName;
        model.isAdmin = oauth.isAdmin();
        model.isDataReady = false;
        model.commissionNames = commissionAbbreviations;
        model.searchOptions = { trainingYear: model.training.trainingYear, trainingId: model.training.trainingId, societyId: null, trainingSessionId: null }
        model.trainingStatusIds = trainingStatusIds;
        model.showSpinnerExport = false;
        model.societyStatuses = pevApplicationSocietyStatuses;
        model.years = [];
        model.openInstructions = trainingSvc.openInstructions;
          

        var path = '/Apps/training/templates/';
        //store slugs in svc
        model.navigation = [
            tabSvc.createTab('Manage Trainees', 'summary', path + 'pevcTrainingModule.html'),
            tabSvc.createTab('Manage Mentors', 'mentors', path + 'manageMentorsModule.html'),
            tabSvc.createTab('Manage Facilitators', 'facilitator', path + 'manageFacilitatorsModule.html'),
            tabSvc.createTab('Manage Observers', 'observers', path + 'manageObserversModule.html')
        ];

        if (!model.isAdmin)
            model.navigation.splice(2, 2);

        model.assignMentor = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/assignMentor.html',
                size: 'md',
                controller: 'assignMentorCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    pevcName: function () {
                        return pevc.firstName + " " + pevc.lastName;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };


        model.assignFacilitator = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/assignFacilitator.html',
                size: 'md',
                controller: 'assignFacilitatorCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    pevcName: function () {
                        return pevc.firstName + " " + pevc.lastName;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    },
                    isObserver: function () { return false;}
                }
            });
        };
        model.changeSession = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/changeSession.html',
                size: 'md',
                controller: 'changeSessionCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.openEvaluation = function (pevc) {
            var pevcName = pevc.firstName + ' ' + pevc.lastName;
            appraisalResultSvc.getTrainingAppraisalByPevc(pevc).then(function (data) {
                appraisalPDFSvc.openAppraisalModal(data, pevcName);
            });
        };

        model.openBio = function (pevc) {
            pevAppSvc.getSingleAppSearchViewModel(pevc.volunteerApplicationId).then(function (data) {
                var app = data;
                $state.go($state.current.name, { view: 'summary' }, { notify: false });
                pevAppSvc.openAppSummaryModal(app);
            });
        };

        model.removeMentor = function (pevc) {
            alertSvc.confirmDelete(pevc.trainingMentorName + " (Mentor for " + pevc.firstName + " " + pevc.lastName + ")", deleteMentor);

            function deleteMentor() {
                trainingSvc.deleteMentee(pevc.trainingMenteeId).then(function () {
                    model.filterPevcs();
                    alertSvc.addAlertSuccess("Mentor succesfully removed.");
                });
            }
        };

        model.removeFacilitator = function (pevc) {
            alertSvc.confirmDelete(pevc.trainingFacilitatorName + " (Facilitator for " + pevc.firstName + " " + pevc.lastName + ")", deleteFacilitator);

            function deleteFacilitator() {
                trainingSvc.deletePersonFacilitator(pevc.trainingPersonFacilitatorId).then(function () {
                    model.filterPevcs();
                    alertSvc.addAlertSuccess("Facilitator succesfully removed.");
                });
            }
        };

        model.removePevcSession = function (pevc) {

            if (pevc.trainingMentorId)
                return alertSvc.addAlertWarning("You must remove the mentor from the pevc first");

            alertSvc.confirmDelete("training session for " + pevc.firstName + " " + pevc.lastName, deleteSession);

            function deleteSession() {
                var copiedPevc = angular.copy(pevc);
                copiedPevc.trainingSessionId = null;
                copiedPevc.trainingStatusId = model.trainingStatusIds.INVITED;

                trainingSvc.updateTrainingSession(copiedPevc).then(function () {
                    model.filterPevcs();
                    alertSvc.addAlertSuccess("Session succesfully removed.");
                });
            }
        };

        model.updateNote = function (pevc) {
            var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);

            trainingSvc.updateTrainingSession(pevcDto).then(function () {
                model.filterPevcs();
                alertSvc.addAlertSuccess("Note Updated");
            });
        };

        model.registerAsAdmin = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/registerAsAdmin.html',
                size: 'md',
                controller: 'registerAsAdminCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.changeYear = function () {
            trainingSvc.getPEVCTrainingSessionsAdmin(trainingSvc.data.trainingSessions[0].trainingId).then(function (sessions) {
                model.trainingSessions = sessions;
            });
        };

        model.changeStatus = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/changeTrainingStatus.html',
                size: 'md',
                controller: 'changeTrainingStatusCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    },
                     isObserver: function () {
                        return false;
                    }
                }
            });
        };
            
        model.changeSociety = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/changeSociety.html',
                size: 'md',
                controller: 'changeSocietyCtrl',
                resolve: {
                    pevc: function () {
                        return pevc;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.changeCommission = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/changeCommission.html',
                size: 'md',
                controller: 'changeCommissionCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };
            
        model.canChangeStatus = function (pevc) {
            return model.isAdmin && pevc.trainingStatusId < model.trainingStatusIds.ATTENDED;
        };

        model.csvHeaders = ["First Name", "Last Name", "Email", "Society Name", "Session", "Mentor Name","Facilitator Name", "Status", "Training Year", "Commission Name", "Country","Province/State","Background","Gender"];
        model.csvColumns = ["firstName", "lastName", "email", "societyName", "sessionName", "trainingMentorName","trainingFacilitatorName","trainingStatusName", "trainingYear", "commissionName","countryName","province","backgroundTypeName","gender"];
        model.export = function () {
            model.showSpinnerExport = true;
            var exportData = [];
            var exportingPevcs = angular.copy(model.data.pevcs);
            var finalList = [];
            angular.forEach(exportingPevcs, function (pevc) {
                var tempPevc = {
                    firstName: pevc.firstName,
                    lastName: pevc.lastName,
                    email: pevc.email,
                    societyName: pevc.societyAbbreviation,
                    sessionName: pevc.sessionName,
                    trainingMentorName: pevc.trainingMentorName || 'N/A',
                    trainingFacilitatorName: pevc.trainingFacilitatorName || 'N/A',
                    trainingStatusName: pevc.trainingStatusName,
                    trainingYear: pevc.trainingYear,
                    commissionName: model.commissionNames[pevc.commissionId],
                    countryName: pevc.countryName,
                    province: pevc.province,
                    backgroundTypeName: pevc.backgroundTypeName,
                    gender: pevc.gender
                };
                finalList.push(tempPevc);
            });

            model.showSpinnerExport = false;
            return finalList;
        };

        model.filterPevcs = function () {
            trainingSvc.getPEVCTrainingSearch(model.searchOptions);
        };

        model.isMySociety = function (societyId) {
            if (model.mySocieties.indexOf(societyId) > -1)
                return true;
            return false;
        };

        model.removeVisitApproval = function (pevc) {

            if (!model.isAdmin)
                alertSvc.information("Not Allowed: Please contact ABET.");
            else {
                var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                trainingSvc.deleteVisitApproval(pevcDto).then(function () {

                });
            }
        };

        model.openAttendedModal = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/pevcAttended.html',
                size: 'lg',
                controller: 'pevcAttendedCtrl',
                resolve: {
                    searchOptions: function () {
                        return model.searchOptions
                    }
                }
            });
        };

        model.approvePevVist = function (pevc, societyId) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/approvePev.html',
                size: 'lg',
                controller: 'approvePevCtrl',
                resolve: {
                    pevc: function () {
                        var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(pevc);
                        return pevcDto;
                    },
                    societyId: function () {
                        return societyId;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }   
                }
            });
        };
        
        var activate = function () {
            var dataArray = [];
            var societyOptions = {
                dataHolder: model,
                dataLocationName: 'societyOptions',
                svcCallback: codeSvc.getMemberSocieties,
                svcCallbackArguments: [],
                helperCallback: helperSvc.getValue
            };
            dataArray.push(societyOptions);

            
            var getTrainingSessionData = {
                dataHolder: model,
                dataLocationName: 'trainingSessions',
                svcCallback: trainingSvc.getPEVCTrainingSessionsAdmin,
                svcCallbackArguments: [model.training.trainingId],
                odataResource: true
            };
            dataArray.push(getTrainingSessionData);
            
              
            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;

                if(!model.isAdmin){
                    model.mySocieties = trainingSvc.getMyTrainingRoles();                    

                    model.societyOptions = helperSvc.getFilteredArray(model.societyOptions, 'codeKey', model.mySocieties, true);
                    if(model.societyOptions.length === 1){
                        model.searchOptions.societyId = model.societyOptions[0].codeKey;            
                    }
                } else {
                    model.mySocieties = model.societyOptions.map(function (society) { return society.codeKey; });
                }
                trainingSvc.getPEVCTrainingSearch(model.searchOptions).then(function (data) {
                    model.isDataReady = true;
                });
            });
        }();
    };
    module.controller('pevcTrainingCtrl', pevcTrainingCtrl);

}(angular.module('training')));