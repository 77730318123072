(function (module) {

    var covidPolicyEditCtrl = function ($scope, $stateParams, $uibModalInstance, volunteerHealthSafetySvc, alertSvc) {
        var model = $scope;
        var covid = volunteerHealthSafetySvc.getAgreedHealthSafety();

        model.volunteerId = $stateParams.volunteerId;
        model.title = 'Assumption of Risk/Liability Waiver';
        model.hasAcknowledged = (covid) ? true : false; 
        model.covid = covid || {};

        model.save = function () {
            model.covid.volunteerId = model.volunteerId;

            volunteerHealthSafetySvc.create(model.covid).then(function () {
                alertSvc.removePersistentAlert(alert);
                alertSvc.addAlertSuccess("Assumption Of Risk/Liability Waiver successfully acknowledged.");
                model.close();
            });
        };

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('covidPolicyEditCtrl', covidPolicyEditCtrl);

}(angular.module('volunteer')));