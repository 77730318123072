(function (module) {

    // This service is a facade layer over existing team groups and assignment services
    var teamGroupSvc = function (tcAssignmentSvc, reviewTeamGroupSvc) {
        var factory = {};

        factory.data = tcAssignmentSvc.data;

        factory.getReviewTeams = function () {
            var promise = tcAssignmentSvc.loadAllData();

            promise = promise.then(function () {
                return { value: tcAssignmentSvc.data.reviewTeams };
            });

            return promise;
        };

        factory.createTeamGroup = function (teamGroup) {
            return reviewTeamGroupSvc.createOdata(teamGroup, true).then(function () {
                return tcAssignmentSvc.refreshReviewTeams();
            });
        }

        factory.updateTeamGroup = function (teamGroup) {
            return reviewTeamGroupSvc.updateOdata(teamGroup, true).then(function () {
                return tcAssignmentSvc.refreshReviewTeams();
            });
        }

        factory.deleteTeamGroup = function (teamGroup) {
            return reviewTeamGroupSvc.deleteOdata(teamGroup, true).then(function () {
                return tcAssignmentSvc.refreshReviewTeams();
            });
        }

        return {
            data: factory.data,
            getReviewTeams: factory.getReviewTeams,
            deleteTeamGroup: factory.deleteTeamGroup,
            createTeamGroup: factory.createTeamGroup,
            updateTeamGroup: factory.updateTeamGroup
        };
    };

    module.factory('teamGroupSvc', teamGroupSvc);

})(angular.module('assignment'));