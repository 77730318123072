(function (module) {

    var appraisalFormCtrl = function (teamMemberTypeNames, alertSvc, surveyTemplateSvc, appraisalSvc, userReviewSvc, helperSvc) {
        var model = this;
        model.title = 'Appraisal';
        model.survey = null;
        model.key = '';
        model.isSubmitted = false;
        model.appraiseeName = '';
        model.existingAppraisal = false;

        model.submit = function () {
            var appraisal = {
                appraisalId: model.appraisal.appraisalId,
                reviewTeamId: model.appraisal.reviewTeamId,
                appraisalTypeId: model.appraisal.appraisalTypeId,
                reviewYear: 0, //server will find the review year.
                appraiserUserId: null,
                appraiseeVolunteerId: model.appraisal.appraiseeVolunteerId,
                surveyJson: JSON.stringify(model.survey),
                encryptedLink: model.key,
                surveyTemplateId: null
            }
            appraisalSvc.createAppraisalForPublic(appraisal).then(function (data) {
                model.isSubmitted = true;
            });
        };

        var activate = function () {
            model.key = helperSvc.getQueryStringVariable('key');
            appraisalSvc.getAppraisalForPublic(model.key).then(function (data) {
                model.appraisal = data;
                loadSurveyTemplate(model.appraisal.appraisalTypeId);
                model.existingAppraisal = model.appraisal.appraisalId > 0;
            });

        }();

        function loadSurveyTemplate(id) {
            surveyTemplateSvc.getSurveyTemplateById(id).then(function (data) {
                model.survey = JSON.parse(data.templateJson);
            });
        }
    };

    module.controller('appraisalFormCtrl', appraisalFormCtrl);

}(angular.module('userReview')));