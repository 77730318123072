(function (module) {
    'use strict';

    var tabSvc = function ($state) {
        var factory = {};

        factory.createTab = function (name, slug, url, isLocked, warningMessages, lockMsg) {
            var tab = {};

            tab.name = name;
            tab.slug = slug;
            tab.url = url;
            tab.isActive = false;
            tab.isLocked = isLocked || false;
            tab.warningMessages = warningMessages || [];
            tab.lockMsg = lockMsg;

            return tab;
        };
        
        return {
            createTab: factory.createTab
        };
    };

    module.factory('tabSvc', tabSvc);

})(angular.module('common'));