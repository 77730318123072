(function (module) {

    var upcomingAvailabilitySvc = function ($http, $odataresource, $q, odataSvc, helperSvc, amsConst) {
        var apiPathCountry = '/VolunteerAvailabilityCountry';
        var apiPathDate = '/VolunteerAvailabilityDate'
        var countryKey = 'volunteerAvailabilityCountryId';
        var dateKey = 'volunteerAvailabilityDateId';
        var factory = {};

        factory.data = {dateData: null, countryData: null}

        factory.currentYear = getCurrentYearCycle();
        factory.countries = [];

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return {};
        };

        factory.getAllCountriesForOrganizations = function () {
            var path = amsConst.webApiUrl + '/odata/GetOrganizationSearch({0})';
            return $http.get(path.format(null) + '?$apply=groupby((countryCode,countryName))&$orderby=countryName&$filter=countryCode ne null').then(onSuccess, onFailure);
        }


        factory.getVolunteerAvailabilityCountryByVolunteerId = function (volunteerId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetVolunteerAvailabilityCountryByVolunteerId', 'volunteerId', volunteerId)

            oSvc.getSource(path, countryKey).odata()
                .expand('volunteerAvailabilityCountryListDtos')
                .filter('year', factory.currentYear)
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.countryData =  data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.getVolunteerAvailabilityDateByVolunteerId = function (volunteerId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetVolunteerAvailabilityDateByVolunteerId', 'volunteerId', volunteerId)

            oSvc.getSource(path, dateKey).odata()
                .expand('volunteerAvailabilityDateListDtos')
                .filter('year', factory.currentYear)
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.dateData = data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.createCountryAvailability = function (volunteerAvailabilityCountry) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPathCountry, countryKey, volunteerAvailabilityCountry);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            resolveCountryAvailabilityData(oSvc, volunteerAvailabilityCountry.volunteerId);

            return oSvc.getDeferred().promise;
        }

        factory.updateCountryAvailability = function (volunteerAvailabilityCountry) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPathCountry, countryKey, volunteerAvailabilityCountry);
            
            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            resolveCountryAvailabilityData(oSvc, volunteerAvailabilityCountry.volunteerId);

            return oSvc.getDeferred().promise;
        }

        factory.createDateUnavailability = function (volunteerAvailabilityDate) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPathDate, dateKey, volunteerAvailabilityDate);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            resolveDateAvailabilityData(oSvc, volunteerAvailabilityDate.volunteerId);

            return oSvc.getDeferred().promise;
        }

        factory.updateDateUnavailability = function (volunteerAvailabilityDate) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPathDate, dateKey, volunteerAvailabilityDate);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            resolveDateAvailabilityData(oSvc, volunteerAvailabilityDate.volunteerId);

            return oSvc.getDeferred().promise;
        }

        function getCurrentYearCycle() {
            var currentYearCycle = new Date();
            var currentYear = currentYearCycle.getFullYear();

            //if past october 1 go to next year cycle
            if (currentYearCycle.getMonth() >= 9) {
                currentYear++;
            }

            return currentYear;
        }

        function resolveCountryAvailabilityData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getVolunteerAvailabilityCountryByVolunteerId(volunteerId);
            });
        }

        function resolveDateAvailabilityData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getVolunteerAvailabilityDateByVolunteerId(volunteerId);
            });
        }

        return {
            data: factory.data,
            getVolunteerAvailabilityCountryByVolunteerId: factory.getVolunteerAvailabilityCountryByVolunteerId,
            getVolunteerAvailabilityDateByVolunteerId: factory.getVolunteerAvailabilityDateByVolunteerId,
            createCountryAvailability: factory.createCountryAvailability,
            updateCountryAvailability: factory.updateCountryAvailability,
            createDateUnavailability: factory.createDateUnavailability,
            updateDateUnavailability: factory.updateDateUnavailability,
            currentYear: factory.currentYear,
            getAllCountriesForOrganizations: factory.getAllCountriesForOrganizations,
            countries: factory.countries
        };
    };
    module.factory('upcomingAvailabilitySvc', upcomingAvailabilitySvc);

})(angular.module('volunteer'));