(function (module) {
    'use strict';

    module.factory('programAuditSvc', [
        'odataSvc', '$http', 'alertSvc', 'helperSvc',
        function (odataSvc, $http, alertSvc, helperSvc) {
            return {
                getProgramAuditById,
                getProgramAuditByReviewTeamId,
                updateProgramAudit,
                updateProgramAuditDetail,
                lockProgramAudit,
                unlockProgramAudit,
                convertToJson,
                openMasterInstructions,
                openEditrorInstructions
            };

            function getProgramAuditById(id) {
                const oSvc = odataSvc.get();
                const apiPath = `/ProgramAudit`;

                oSvc.getSource(apiPath, 'programAuditId')
                    .odata()
                    .get(id)
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise;
            };

            /**
             * Gets all current ProgramAudits associated with a review team ID.
             * @param {Number} reviewTeamId The review team ID.
             * @returns {Promise} Promise that resolves to a ProgramAudit
             */
            function getProgramAuditByReviewTeamId(reviewTeamId) {
                const oSvc = odataSvc.get();
                const apiPath = `/GetProgramAuditByReviewTeamId(reviewTeamId=${reviewTeamId})?$expand=programAuditDetailDtos($expand=programAuditDetailEvaluatorDtos),programAuditInstitutionalSummaryDtos`;

                oSvc.getSource(apiPath)
                    .odata()
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise;
            }

            /**
             * Updates a ProgramAudit.
             * @param {any} programAuditDto ProgramAudit object data.
             * @returns {Promise} Promise indicating success or failure.
             */
            function updateProgramAudit(programAuditDto) {
                // clean up the dto so the backend doesn't throw a wobbly
                delete programAuditDto.$$hashkey;
                delete programAuditDto.programAuditDetailDtos;

                return $http.put(`/webapi/odata/ProgramAudit(programAuditId=${programAuditDto.programAuditId})`, programAuditDto);
            }

            function updateProgramAuditDetail(programAuditDetailDto) {
                // clean up the dto so the backend doesn't throw a wobbly
                delete programAuditDetailDto.$$hashkey;
                delete programAuditDetailDto.programAuditDetailEvaluatorDtos;

                return $http.put(`/webapi/odata/ProgramAuditDetail(programAuditDetailId=${programAuditDetailDto.programAuditDetailId})`, programAuditDetailDto);
            }

            function lockProgramAudit(programAuditDto) {
                return $http.post('/webapi/odata/LockdownProgramAudit', angular.toJson({ Value: programAuditDto }));
            }

            function unlockProgramAudit(programAuditDto) {
                return $http.post('/webapi/odata/UnlockProgramAudit', angular.toJson({ Value: programAuditDto }));
            }

            function convertToJson(summaryJson) {
                summaryJson = helperSvc.removeODataContext(summaryJson);
                summaryJson.institutionalSummaryJson = angular.fromJson(summaryJson.institutionalSummaryJson);
                return summaryJson;
            }

            // PDF Modal Funcs

            function openMasterInstructions() {
                alertSvc.openPDFModal('/Content/files/Program-Audit-Instructions.pdf', 'Program Audit Instructions');
            }

            function openEditrorInstructions() {
                alertSvc.openPDFModal('/Content/files/Program-Audit-Instructions.pdf', 'Program Audit Editor Instructions', 8);
            };

        }]);
})(angular.module('programAudit'));