(function (module) {

    var personDetailsCtrl = function ($stateParams, $location, $uibModal, personSvc, personMdl, eventSvc, barSvc, helperSvc, membershipSvc, noteTypes, noteSvc, personUserSvc, volunteerActivitiesSvc,
        volunteerSvc, currentUser, codeSvc, personEmailSvc, personSignatureSvc, contactRoleTypes, alertSvc, oauth) {
        var model = this;
        var getFilteredArray = helperSvc.getFilteredArray;

        model.title = null;
        model.isDataReady = false;
        model.personId = parseInt($stateParams.personId);
        model.volunteerId = null;
        model.isVolunteer = false;
        model.noteTypes = noteTypes;
        model.canImpersonate = oauth.canImpersonate();

        barSvc.showBar();

        eventSvc.listen(refreshUserGroup, 'refreshUserGroup');
        eventSvc.listen(refreshVolunteerActivities, 'refreshVolunteerActivities');
        eventSvc.listen(refreshPersonNotes, 'refreshPersonNotes');
        eventSvc.listen(refreshPersonUser, 'refreshPersonUser');

        model.impersonate = personSvc.impersonate;

        model.addPersonAsVolunteer = function (id) {
            volunteerSvc.createDefaultVolunteerFromPerson(id).then(function (data) {
                alertSvc.addAlertSuccess("Volunteer successfully added.");
            },
            function () {
                alertSvc.addAlertWarning('Volunteer already exists or could not be added at this time.');
            });
        }

        model.deletePerson = function (id) {
            personSvc.delete(id).then(function (data) {
                $location.path('/admin/person');
            });
        };
        
        var personObjDataSource = {
            dataHolder: model,
            dataLocationName: 'personObj',
            svcCallback: personSvc.getPersonMdlById,
            svcCallbackArguments: [model.personId, model, excludeNullContactTypeIds = true],
            optionalCallback: function (model) {
                //remove personObj from model because it was only used as a placeholder
                delete model.personObj;
            },
            optionalCallbackArguments: [model]
        };

        var personDataSource = {
            dataHolder: model,
            dataLocationName: 'personData',
            svcCallback: personSvc.getPersonSummary,
            svcCallbackArguments: [model.personId],
            helperCallback: function(data) {
                if (data.length > 0) {
                    var personData = data[0];

                    return personData;
                }
            },
            optionalCallback: function(model) {
                var personData = model.personData;

                //place all data in final locations
                model.personName = personData.firstName + " " + personData.lastName;
                model.userId = personData.userPersonDtos[0].userId;

                //remove personData because it was only used as a placeholder
                delete model.personData;
            },
            optionalCallbackArguments: [model]
        };

        var volunteerActivitiesDataSource = {
            dataHolder: model,
            dataLocationName: 'activities',
            svcCallback: volunteerActivitiesSvc.getAllCurrentByPersonId,
            svcCallbackArguments: [model.personId],
            odataResource: true
        };

      

        var personNotesDataSource = {
            dataHolder: model,
            dataLocationName: 'noteList',
            svcCallback: noteSvc.getNotesByReferenceId,
            svcCallbackArguments: [model.personId, noteTypes.PERSON],
            odataResource: true
        };

        var userDataSource = {
            dataHolder: model,
            dataLocationName: 'organizationUsers',
            svcCallback: membershipSvc.getByUserId,
            svcCallbackArguments: [model.userId, true], //userId is undefined here, be careful
            helperCallback: function (data) {
                var unfilteredContacts = helperSvc.getResults(data);
                var excludedRoles = [contactRoleTypes.PROGRAMEVALUATOR,
                                     contactRoleTypes.PROGRAMOBSERVER,
                                     contactRoleTypes.TEAMCHAIR,
                                     contactRoleTypes.COTEAMCHAIR,
                                     contactRoleTypes.EDITOR1,
                                     contactRoleTypes.EDITOR2,
                                     contactRoleTypes.VISITOBSERVER,
                                     contactRoleTypes.MEMBERPROGRAMEVALUATOR,
                                     contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE,
                                     contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATEINACTIVE
                                ];
                if (unfilteredContacts)
                    return getFilteredArray(unfilteredContacts, "roleId", excludedRoles);
                else return [];

               // return helperSvc.getResults(data)
            }
        };

        var personUserDataSource = {
            dataHolder: model,
            dataLocationName: 'user',
            svcCallback: personUserSvc.getUserByPersonId,
            svcCallbackArguments: [model.personId],
            helperCallback: function (data) {
                return data;
            }
        };

        var contactTypeDataSource = {
            dataHolder: model,
            dataLocationName: 'contactTypeOptions',
            svcCallback: codeSvc.getContactTypes,
            helperCallback: function (data) {
                return data;
            }
        };

        // Refresh Functions

        //function refreshPersonGeneral() {
        //    var dataSourceArray = [personObjDataSource];
        //    var promise = helperSvc.getData(dataSourceArray);

        //    promise.then(function () {
        //        broadcastLoadPersonGeneralMsg();
        //    });
        //}

        function refreshPersonUser() {
            var dataSourceArray = [personUserDataSource];
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadPersonGeneralMsg();
            });
        }

        function refreshUserGroup() {
            var dataSourceArray = [userDataSource];
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadOrganizationUsersMsg();
            });
        }

        function refreshVolunteerActivities() {
            var dataSourceArray = [volunteerActivitiesDataSource];
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadPersonNotesMsg();
            });
        }

        function refreshPersonNotes() {
            var dataSourceArray = [personNotesDataSource];
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadPersonNotesMsg();
            });
        }

        // Broadcasts

        function broadcastLoadPersonGeneralMsg() {
            eventSvc.broadcast('loadPersonGeneral');
        }

        function broadcastLoadOrganizationUsersMsg() {
            eventSvc.broadcast('loadOrganizationUsers');
        }

        function broadcastLoadPersonNotesMsg() {
            eventSvc.broadcast('loadPersonNotes');
        }

        var activate = function () {
            //setup for first round data fetch
            var dataSourceArray = [
                personObjDataSource,
                personDataSource,
                volunteerActivitiesDataSource,
                personNotesDataSource,
                personUserDataSource,
                contactTypeDataSource
            ];

            //get first round of data
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                //define userId now that we have it
                userDataSource.svcCallbackArguments = [model.userId, true];

                //setup for second round of data
                var userDataSourceArray = [
                    userDataSource
                ];

                //grab data that relies on userId, which is returned within first round data
                var secondPromise = helperSvc.getData(userDataSourceArray);

                //send notification that all data is ready
                secondPromise.then(function () {
                    broadcastLoadPersonGeneralMsg();
                    broadcastLoadOrganizationUsersMsg();
                    broadcastLoadPersonNotesMsg();

                    //get volunteerId to be used for volunteer activities widget
                    var finalPromise = volunteerSvc.getVolunteerIdByPersonId(model.personId);

                    finalPromise.then(function (data) {
                        if (data.length > 0) {
                            model.volunteerId = data[0].volunteerId;
                            model.isVolunteer = true;
                        }

                        barSvc.hideBar();
                        model.isDataReady = true;
                    });
                });
            });
        }();
    };

    module.controller('personDetailsCtrl', personDetailsCtrl);

}(angular.module('person')));