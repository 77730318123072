(function (module) {

    var organizationSvc = function ($http, $q, organizationMdl, codeSvc, odataSvc, amsConst, organizationTypes, odataServiceFactory, organizationHistorySvc, typeConstSvc) {
        var config = {
            apiPath: '/Organization',
            keyName: 'organizationId',
            dataItemName: 'organization',
            expandables: ['currentOrganizationAddressDto', 'currentOrganizationDetailDto']
        };

        var apiPath = amsConst.webApiUrl + '/odata/Organization({0})';
        var searchApiPath = amsConst.webApiUrl + '/odata/GetOrganizationSearch({0})';
        var currentViewApiPath = amsConst.webApiUrl + '/odata/GetOrganizationCurrentView({0})';
        var ipedApiPath = amsConst.webApiUrl + '/odata/GetIpeds({0})';
        var userAccessApiPath = amsConst.webApiUrl + '/odata/GetOrganizationByUser({0})';

        //var historyApiPath = amsConst.webApiUrl + '/odata/GetOrganizationHistorySearch({0})';
        //var factory = {};
        var queryBase = '?$filter=';

        var factory = new odataServiceFactory(config);

        factory.data = {
            organization: null,
            organizationMdl: {}
        };
        var api = '/Organization';
        var key = 'organizationId';

        factory.getOrgByIdOdata = function (id) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
            .expand('currentOrganizationAddressDto')
            .expand('currentOrganizationDetailDto')
            .get(id)
            .$promise
            .then(oSvc.onSuccess, oSvc.onFailure);

            resolveOrganizationData(oSvc);

            return oSvc.getDeferred().promise;
        }

        //should combine onSuccess and onSearchSuccess? Just making things work for now
        var onSearchSuccess = function (response) {
            if (response.status == 200) {
                var organizations = response.data.value;
                var organizationMdls = new Array();

                for (var i = 0; i < organizations.length; i++) {
                    var organization = new organizationMdl(organizations[i]);
                    organizationMdls.push(organization);
                }

                return organizationMdls;
            }
        };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.create = function (organization) {
            return $http.post(apiPath.format(null), organization).then(onSuccess, onFailure);
        };

        //factory.update = function (organization) {
        //    return $http.put(apiPath.format(organization.organizationId), organization).then(onSuccess, onFailure);
        //};

        factory.update = function (organization, noStoredData) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(config.apiPath, 'organizationId', organization);
            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                promise.then(function (data) {
                    factory.refreshData(data);
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.refreshData = function (org) {
            factory.data.organization = org;
            factory.data.organization.organizationTypeName = typeConstSvc.getOrganizationName(org.organizationTypeId);
            factory.data.organizationMdl = new organizationMdl(org, true);
            organizationHistorySvc.updateLatestOrganizationHistory(org.organizationId);
        };

        factory.delete = function (organizationId) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(organizationId)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        factory.advancedSearch = function (searchOptions) {
            if (searchOptions) {
                var query = '';
                var orgName = searchOptions.organizationName;
                var orgType = searchOptions.organizationType;
                var orgCountry = searchOptions.organizationCountry;
                var orgState = searchOptions.organizationState;
                var logicalAnd = ' and ';

                var conditionalAnd = function (text) {
                    if (text != '') {
                        text += ' and ';
                    }

                    return text;
                };

                if (orgName) {
                    query += buildOrgNameFilter(orgName);
                }

                if (orgType) {
                    query = conditionalAnd(query);
                    query += buildOrgTypeIdFilter(orgType);
                }

                if (orgCountry) {
                    query = conditionalAnd(query);
                    query += buildCountryCodeFilter(orgCountry);
                }

                if (orgState) {
                    query = conditionalAnd(query);
                    query += buildStateCodeFilter(orgState);
                }
            }

            return $http.get(searchApiPath.format(null) + queryBase + query).then(onSearchSuccess, onFailure);
        };




        factory.getOrganizationById = function (id, noStoredData) {
            var promise = factory.get(id, noStoredData);

            promise.then(function () {
                
            });
        };

        factory.getOrganizationByUser = function (orgId) {
            return $http.get(userAccessApiPath.format('organizationId=' + orgId) + '?' + '$expand=currentOrganizationAddressDto,currentOrganizationDetailDto').then(onSuccess, onFailure);
        };


        factory.getRecognizingAgencies = function () {
            return $http.get(apiPath.format(null) + queryBase + 'organizationTypeId eq ' + organizationTypes.USREGIONALAGENCY + ' or organizationTypeId eq ' + organizationTypes.NONUSREGIONALAGENCY).then(onSuccess, onFailure);
        };

        factory.getUSRecognizingAgencies = function () {
            return $http.get(apiPath.format(null) + queryBase + 'organizationTypeId eq ' + organizationTypes.USREGIONALAGENCY).then(onSuccess, onFailure);
        };

        factory.getNonUSRecognizingAgencies = function () {
            return $http.get(apiPath.format(null) + queryBase + 'organizationTypeId eq ' + organizationTypes.NONUSREGIONALAGENCY).then(onSuccess, onFailure);
        };

        factory.getMemberSocieties = function () {
            return $http.get(searchApiPath.format(null) + queryBase + 'organizationTypeId eq ' + organizationTypes.MEMBERSOCIETY).then(onSuccess, onFailure);
        };

        factory.getMemberAndSupportingSocieties = function () {
            // Note: this uses OrganizationCurrentView instead of OrganizationCurrentDetailView because the latter lists societies more than once.
            // OrganizationCurrentView has similar issues for institutions but not for member and supporting societies.
            // Bug 2497 created to address these issues and fully refactor both views and fix any problems elsewhere in the application.
            return $http.get(currentViewApiPath.format(null) + queryBase +
                             'organizationTypeId eq ' + organizationTypes.MEMBERSOCIETY + ' or ' +
                             'organizationTypeId eq ' + organizationTypes.SUPPORTINGSOCIETY)
                .then(onSuccess, onFailure);
        };

        factory.getOrgsbyOrgIds = function (orgIds) {
            var orgsByIdsPath = amsConst.webApiUrl + '/odata/GetOrganizationsByIds';

            var data = {
                "orgIds": orgIds
            }
            return $http.post(orgsByIdsPath, data);
        };

        factory.searchRecognizingAgencies = function (name) {
            var field = 'organizationName';
            var query = buildSearchQuery(field, name, searchApiPath);
            query += ' and (' + buildOrgTypeIdFilter(organizationTypes.USREGIONALAGENCY);
            query += ' or ' + buildOrgTypeIdFilter(organizationTypes.NONUSREGIONALAGENCY) + ')';

            return $http.get(query).then(onSuccess, onFailure);
        };


        factory.getOrganizationsByType = function (type) {
            return $http.get(apiPath.format(null) + queryBase + buildOrgTypeIdFilter(type) + '&$expand=currentOrganizationDetailDto')
        }

        factory.getOrganizationsByName = function (name) {
            var field = 'organizationName';
            var altField = 'abbreviatedName';
            var query = buildSearchQuery(field, name, searchApiPath, altField);

            return $http.get(query).then(onSuccess, onFailure);
        };

        factory.getOrganizationsByIpedName = function (name) {
            var field = 'name';
            var query = buildSearchQuery(field, name, ipedApiPath);

            return $http.get(query).then(onSuccess, onFailure);
        };

        factory.quickSearchUS = function (name) {
            var orgTypeId = organizationTypes.USREGIONALAGENCY;
            
            return quickSearch(name, orgTypeId);
        };

        factory.quickSearchNonUS = function (name) {
            var orgTypeId = organizationTypes.NONUSREGIONALAGENCY;

            return quickSearch(name, orgTypeId);
        };

        factory.quickSearchInstitution = function (name) {
            var orgTypeId = organizationTypes.INSTITUTION;

            return quickSearch(name, orgTypeId);
        };

      

        var quickSearch = function (name, orgTypeId) {
            var field = 'organizationName';
            var query = buildSearchQuery(field, name, searchApiPath);

            if (orgTypeId) {
                query += ' and ' + buildOrgTypeIdFilter(orgTypeId);
            }

            return $http.get(query).then(onSuccess, onFailure);
        };

        var buildSearchQuery = function (fieldName, text, apiPath, altFieldName) {
            var parts = text.split(' ');
            var query = apiPath.format(null) + queryBase;
            
            //doing this instead of repeatedly checking in loop
            if (parts.length > 0) {
                query += createContainsLine(parts[0], fieldName, altFieldName);
            }

            //if there are anymore search terms add them here
            for (var i = 1; i < parts.length; i++) {
                query += ' and ' + createContainsLine(parts[i], fieldName, altFieldName);
            }

            return query;
        };

        var createContainsLine = function (parameter, property, altProperty) {
            var text = '';

            parameter = encodeURIComponent(parameter);

            if (altProperty) {
                text =  "(contains(" + property + ", '" + parameter + "') or contains(" + altProperty + ", '" + parameter + "'))";
            }
            else {
                text = "(contains(" + property + ", '" + parameter + "'))";
            }

            return text;
        };

        var buildOrgIdFilter = function (parameter) {
            return "organizationId eq " + parameter;
        };

        var buildOrgNameFilter = function (parameter) {
            parameter = encodeURIComponent(parameter);
            return "(contains(organizationName, '" + parameter + "')" + " or " + "contains(abbreviatedName, '" + parameter + "'))";
        };

        var buildIpedNameFilter = function (parameter) {
            parameter = encodeURIComponent(parameter);
            return "(contains(name, '" + parameter + "'))";
        };

        var buildOrgTypeIdFilter = function (parameter) {
            const parameters = Array.isArray(parameter) ? parameter : [parameter];
            const filters = parameters.map(parameter => 'organizationTypeId eq ' + parameter);
            return '(' + filters.join(' or ') + ')';
        };

        var buildCountryCodeFilter = function (parameter) {
            return "(countryCode eq '" + parameter + "')";
        };

        var buildStateCodeFilter = function (parameter) {
            return "stateCode eq '" + parameter + "'";
        };

        var buildDetailandAddressDtoOption = function () {
            return "&$expand=currentOrganizationAddressDto,currentOrganizationDetailDto";
        };

        var builUserDtoOption = function () {
            return "&$expand=organizationUserDtos";
        };

        var codes = {};

        codeSvc.getOrganizationTypes().then(function (data) {
            codes.organizationTypes = data.value;
        });

        codeSvc.getCountries().then(function (data) {
            codes.countries = data.value;
        });

        codeSvc.getStates().then(function (data) {
            codes.states = data.value;
        });

        factory.codes = codes;

        function resolveOrganizationData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.organization = data;
            });
        }

        return {
            getByPersonId: factory.getByPersonId,
            codes: factory.codes,
            getOrganizationsByType: factory.getOrganizationsByType,
            getOrganizationsByName: factory.getOrganizationsByName,
            getRecognizingAgencies: factory.getRecognizingAgencies,
            getUSRecognizingAgencies: factory.getUSRecognizingAgencies,
            getNonUSRecognizingAgencies: factory.getNonUSRecognizingAgencies,
            getMemberSocieties: factory.getMemberSocieties,
            getMemberAndSupportingSocieties: factory.getMemberAndSupportingSocieties,
            getOrganizationsByIpedName: factory.getOrganizationsByIpedName,
            advancedSearch: factory.advancedSearch,
            getOrganizationById: factory.get,
            getOrganizationHistoryById: factory.getOrganizationHistoryById,
            getOrganizationByUser: factory.getOrganizationByUser,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            quickSearchUS: factory.quickSearchUS,
            quickSearchNonUS: factory.quickSearchNonUS,
            quickSearch: quickSearch,
            searchRecognizingAgencies: factory.searchRecognizingAgencies,
            quickSearchInstitution: factory.quickSearchInstitution,
            getOrgByIdOdata: factory.getOrgByIdOdata,
            data: factory.data,
            getOrgsbyOrgIds: factory.getOrgsbyOrgIds
        };
    };
    module.factory('organizationSvc', organizationSvc);

})(angular.module('organization'));