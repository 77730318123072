(function (module) {

    var signatureEditCtrl = function (personId, signature, $uibModalInstance, personUserSvc, alertSvc, documentSvc, helperSvc, personSignatureSvc) {
        var model = this;

        model.title = 'Add Signature';
        model.controllerName = 'signatureEditCtrl';
        if (signature) {
            signature.validFrom = new Date(signature.validFrom);
            signature.validTo = new Date(signature.validTo);
        }
        model.newSignature = signature ? angular.copy(signature) : { validFrom: null, validTo: null, personId: personId, fileName: null };
        model.newMode = !signature;
        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            model.uploadSignature();
        };

        model.viewFile = function () {
            documentSvc.downloadDocument(model.newSignature.stream_id);
        };

        model.uploadSignature = function () {
            var saveSignature = function () {
                signatureToSave = {
                    personSignatureId: model.newSignature.personSignatureId,
                    validTo: model.newSignature.validTo,
                    validFrom: model.newSignature.validFrom,
                    personId: model.newSignature.personId,
                    fileName: model.newSignature.fileName,
                    stream_id: model.newSignature.stream_id

                };

                if (model.newMode) {
                    personSignatureSvc.create(signatureToSave).then(function () {
                        alertSvc.addAlertSuccess("Signature uploaded.");
                        $uibModalInstance.close();
                    });
                } else {
                    personSignatureSvc.update(signatureToSave).then(function () {
                        alertSvc.addAlertSuccess("Signature uploaded.");
                        $uibModalInstance.close();
                    });
                }
            };
            var fileToUpload = model.signatureFile;
            if (fileToUpload && !model.newSignature.fileName) {
                documentSvc.uploadFile(fileToUpload).then(function (data) {
                    model.newSignature.stream_id = helperSvc.getValue(data.data);
                    saveSignature();
                });
            } else {
                saveSignature();
            }
        };

       
    };

    module.controller('signatureEditCtrl', signatureEditCtrl);

}(angular.module('person')));