(function (module) {
    'use strict';

    var programAuditPDFSvc = function (helperSvc, teamMemberTypeNames, commissionTypes, monthNames, statementFindingTypes) {

        var factory = { };

        var EMPTY_SECTION = {
            NOSHORTCOMING: {
                text: 'No shortcomings relative to this criterion at this time.\n\n',
                style: ['small', 'camptonMedium', 'addLineSpacing'],
                color: '#aaa'
            },
            BLANK: {
                text: 'No text has been added to this section\n\n',
                style: ['small', 'body', 'paragraph', 'italic']
            }
        };
        var PROGRAM_AUDIT_DATA;
        var IS_EXIT_STATEMENT;
        var IS_SINGLE_PROGRAM;
        var IS_INST_SUMMARY;
        var SHOW_INST_SUMMARY;

        //data

        var criteria = [
            //Intentionally excludes program observations and strengths
            { statementFindingTypeId: statementFindingTypes.PROGRAMINTRODUCTION, criteriaName: "Introduction" }
        ];

        var definitionOfTerms = [
            {
                name: 'Concern',
                definition: 'A concern indicates that a program currently satisfies a criterion, policy, or procedure; however, the potential exists for the situation to change such that the criterion, policy, or procedure may not be satisfied.'
            },
            {
                name: 'Weakness',
                definition: 'A weakness indicates that a program lacks the strength of compliance with a criterion, policy, or procedure to ensure that the quality of the program will not be compromised. Therefore, remedial action is requierd to strengthen compliance with the criterion, policy, or procedure prior to the next evaluation.'
            },
            {
                name: 'Deficiency',
                definition: 'A deficiency indicates that a criterion, policy, or procedure is not satisfied. Therefore, the program is not in compliance with the criterion, policy, or procedure.'
            }
        ];

        var dueProcessSteps = [
            {
                name: 'Seven-Day Response',
                description: 'Each program has seven days to respond to the Team Chair in case of errors of fact. Only factual errors will be considered in this portion of the review process. Please log on to MyABET and upload the seven-day response to the ABET Accreditation Management System (AMS). Additional material (beyond errors of fact) included with the seven-day response will be considered with the due-process response. If no errors are noted, no seven-day response is required. Please notify your Team Chair and indicate in the AMS if you will NOT be submitting a response.\n\n'
            },
            {
                name: 'Draft Statement',
                description: 'The Team Chair, working in collaboration with the visit team members, incorporates your seven-day response, if any, into a Draft Statement that is edited and reviewed by two editors, each of whom is a member of the commission\'s executive committee. Following a final editing step by ABET Headquarters, a letter of notification is sent to your institution. Please log on to MyABET to download the Draft Statement from AMS. We stress that the institution may immediately begin addressing shortcomings and need not wait for receipt of the Draft Statement.\n\n'
            },
            {
                name: 'Due-Process Response',
                description: 'You have 30 days after the receipt of the Draft Statement to reply to the Team Chair with your response to the team\'s findings.The response normally will include documentation of actions taken to correct shortcomings identified in the Draft Statement.Copies of your due- process response should be uploaded to AMS by logging on to MyABET.You are not required to submit a due-process response.Please notify your Team Chair and indicate in the AMS if you will NOT be submitting a response.\n\n'
            },
            {
                name: 'Final Statement',
                description: 'The Team Chair consults with visit team members as necessary and incorporates the due-process response into the Final Statement. The statement is again reviewed by the editors and sent to ABET Headquarters for final processing.\n\n'
            },
            {
                name: 'Final Action',
                description: 'At its annual meeting in July, the full commission reviews all Final Statements and recommended actions. Following discussion, a vote of the Commissioners is taken for each program at each institution.\n\n'
            },
            {
                name: 'Notification of Final Action',
                description: 'In August, ABET sends the Final Statement and transmittal letter informing institutions of the official accreditation actions for your programs.'
            }
        ];

        var explanationOfPAF = [
            'The attached Program Audit Form (PAF) summarizes the visit team’s initial assessment of each program being considered for accreditation or extension of accreditation by ABET.',
            'The PAF has two parts. The first part summarizes the team’s identification of shortcomings with respect to criteria and policies. Shortcomings are shown as a Deficiency (D), Weakness (W), or Concern (C). Definitions  are on the next page. The second part of the PAF is a detailed description of any identified shortcomings.',
            'The due-process period begins with the departure of the visit team. Due process is a critical part of the accreditation effort and consists of the following steps:'
        ];


        //styles

        var tableLayout = {
            paddingLeft: function () { return 0; }
        }

        var paddedTable = {
            paddingLeft: function () { return 5; },
            paddingRight: function () { return 5; },
            paddingTop: function () { return 5; },
            paddingBottom: function () { return 5; }
        }

        var styles = {
            paragraph: {
                lineHeight: 1.3,
                alignment: 'justify'
            },
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            medium: {
                fontSize: 12
            },
            large: {
                fontSize: 16
            },
            larger: {
                fontSize: 18
            },
            jumbo: {
                fontSize: 27
            },
            italics: {
                italics: true
            },
            bold: {
                bold: true
            },
            // Fonts and images are defined in vfs_fonts.js
            campton: {
                font: 'campton'
            },
            camptonBook: {
                font: 'camptonBook'
            },
            camptonExtraBold: {
                font: 'camptonExtraBold'
            },
            camptonExtraLight: {
                font: 'camptonExtraLight'
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            camptonSemiBold: {
                font: 'camptonSemiBold'
            },
            camptonThin: {
                font: 'camptonThin'
            },
            italic: {
                italics: true
            },
            body: {
                font: 'egyptienne'
            },
            orange: {
                color: '#FF6C2C'
            },
            addLineSpacing: {
                lineHeight: 1.8
            }
        };


        //unused 

        function substituteRegularHyphen(str) {
            // Replace non-traditional hyphen character with regular hyphen
            // Non-traditional hyphen doesn't show up
            return str.replace(/‐/g, '\u2010');
        }

        function substituteNonBreakingHyphen(str) {
            // Replace regular hyphen with non-breaking hyphen to remove space after hyphen caused by justified text
            return str.replace(/-/g, '\u2011');
        }

        function substituteBullets(str) {
            // Replace pseudo-bullets done with asterisks with proper bullets and non-breaking spaces.
            return str.replace(/((^|\n|\\n)\*\s{1,2})(?=\w)/g, '$2\u2022\u00A0');
        }

        function htmlToElement(html) {
            var template = document.createElement('template');
            html = html.trim();
            template.innerHTML = html;
            // our boy Internet Explorer doesn't do template.content
            if (template.content) return template.content.firstChild;
            else return template.firstChild;
        }

        function formatFindingTypeName(findingType) {
            function getPluralNoun(noun) {
                switch (noun.charAt(noun.length - 1)) {
                    case 's':
                        return noun + 'es';
                    case 'y':
                        return noun.substring(0, noun.length - 1) + 'ies';
                    default:
                        return noun + 's';
                }
            }

            var name = statementFindingTypeNames[findingType.statementFindingTypeId];

            if (findingType.findings.length > 1) name = getPluralNoun(name);

            return name ? name.toUpperCase() : '';
        }

        function splitByTag(html) {
            //takes in html and splits by tag, returning array of html string
            return html ? html.match(/<(.*?)>.*?<\/\1>/g) : html;
        }

        function sortByProperty(arr, prop) {
            return arr.sort(function (a, b) {
                if (angular.isArray(prop)) {
                    //sorts by the first property found
                    for (var i = 0; i < prop.length; i++) {
                        var aVal = helperSvc.getObjectPropertyValueByString(a, prop[i]);
                        var bVal = helperSvc.getObjectPropertyValueByString(b, prop[i]);
                        if (aVal && bVal) return aVal - bVal;
                    }
                } else {
                    return a[prop] - b[prop];
                }

            });
        }


        //useful

        function getVisitDates(year, isLongDate) {
            var start = parseInt(year) - 1;
            return isLongDate ? start + '-' + year : start + '-' + (year % 100);
        }

        function getAccreditationCycle(year) {
            var start = parseInt(year) - 1;
            return start + '–' + (year);
        }

        function getCriteriaCycle(isLongDate) {
            var arr = [];
            if (!statementStorageSvc.data.statementProgramReviews) return '';
            var criteriaCycleYears = statementStorageSvc.data.statementProgramReviews
                .filter(programReview => !'WC'.includes(programReview.actionCode))
                .map(programReview => programReview.criteriaCycleYear);

            for (var i = 0; i < criteriaCycleYears.length; i++) {
                if (criteriaCycleYears[i] && Number.isInteger(criteriaCycleYears[i])) {
                    var cycle = getVisitDates(criteriaCycleYears[i], isLongDate);
                    if (arr.indexOf(cycle) === -1) {
                        arr.push(getVisitDates(criteriaCycleYears[i], isLongDate));
                    }
                }
            }
            return arr.join(', ');
        }

        function getDateRange(date1, date2) {
            if (date1 && date2) {
                var startDate = new Date(date1),
                    endDate = new Date(date2),
                    startMonth = startDate.getUTCMonth(),
                    endMonth = endDate.getUTCMonth(),
                    startYear = startDate.getUTCFullYear(),
                    endYear = endDate.getUTCFullYear(),
                    startDay = startDate.getUTCDate(),
                    endDay = endDate.getUTCDate();

                if (startYear === endYear) {
                    if (startMonth === endMonth) {
                        return '{0} {1}–{2}, {3}'.format(monthNames[startMonth], startDay, endDay, startYear);
                    } else {
                        return '{0} {1} – {2} {3}, {4}'.format(monthNames[startMonth], startDay, monthNames[endMonth], endDay, startYear);
                    }
                } else {
                    return '{0} {1}, {2} - {3} {4}, {5}'.format(monthNames[startMonth], startDay, startYear, monthNames[endMonth], endDay, endYear);
                }
            }
            return null;
        }

        function getVisitDateText(date1, date2) {
            var dateRange = getDateRange(date1, date2);
            if (dateRange) {
                return "Visit held {0}".format(dateRange);
            } else {
                return "[Visit dates unconfirmed]";
            }
        };

        function htmlToElementArray(html) {
            //takes in html string splits by tag, and returns array of element objects
            var elms = [];
            var split = splitByTag(html);
            if (!split) return elms;
            for (var i = 0; i < split.length; i++) {
                elms.push(htmlToElement(split[i]));
            }
            return elms;
        }

        function getCommissionFullName(id) {
            return commissionTypes.find(function (c) { return c.id === id }).name;
        }

        function getLocationInfo(reviewTeam) {
            var text = '';
            if (reviewTeam.city && reviewTeam.city !== '') text += reviewTeam.city + ", ";

            if (reviewTeam.countryCode === 'US') {
                if (reviewTeam.stateCode && reviewTeam.stateCode !== '') text += reviewTeam.stateCode + ", ";
            } else {
                if (reviewTeam.province && reviewTeam.province !== '') text += reviewTeam.province + ", ";
            }
            text += reviewTeam.countryName.trim();
            return text;
        }

        function makeSubstitutions(str) {
            // Substitute certain strings and characters with more appropriate, PDF-friendly characters.
            var substitutedStr = str;
            //substitutedStr = substituteNonBreakingHyphen(substitutedStr);
            substitutedStr = substituteRegularHyphen(substitutedStr);
            substitutedStr = substituteBullets(substitutedStr);
            return substitutedStr;
        }

        function getElementText(elm) {
            var text = elm.textContent || elm.innerText || '';
            text = text.toLowerCase().trim() === 'click to add text...' ? '' : text.replace(/\s+/g, ' ').trim();
            return makeSubstitutions(text);
        }

        function formatEmptyStr(str) {
            return str ? (str.trim() === '' ? emptySectionText : str) : emptySectionText;
        }

        function formatEmptyElm(elm) {
            return formatEmptyStr(getElementText(elm));
        }


        // new functions

        function getInstitutionHeaderContent(isInstSummaryHeader) {

            return {
                pageBreak: isInstSummaryHeader ? '' : 'before',
                table: {
                    body: [
                        [
                            {
                                text: [
                                    {
                                        pageBreak: 'before',
                                        text: PROGRAM_AUDIT_DATA.organization.organizationName.toUpperCase() + '\n',
                                        style: ['campton', 'larger'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 0, 0, 6]
                            }
                        ],
                        [
                            {
                                text: '{0}\n'.format(isInstSummaryHeader ? 'Institution Details' : 'Program Audit Form').toUpperCase(),
                                style: ['camptonSemiBold', 'medium'],
                                alignment: 'left',
                                border: [false, false, false, false],
                                margin: [0, 6, 0, 0]
                            }
                        ],
                        [
                            {
                                text: [
                                    {
                                        text: 'ABET ' + getCommissionFullName(PROGRAM_AUDIT_DATA.programReviews[0].programDto.commissionId).toUpperCase() + '\n',
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: 'Accreditation Cycle Criteria: {0}'.format(getAccreditationCycle(PROGRAM_AUDIT_DATA.programReviews[0].reviewTeamDto.reviewDto.reviewYear)).toUpperCase(),
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    },
                                ],
                                border: [false, false, false, false]
                            }
                        ]
                    ]
                },
                layout: tableLayout
            }
        }

        function getNamesByProgramIdAndTeamMemberTypeId(teamMemberTypeId, programId) {
            var names = PROGRAM_AUDIT_DATA.reviewTeamMembers.filter(function (teamMember) {
                return teamMember.teamMemberTypeId == teamMemberTypeId &&
                    (!teamMember.endDate || new Date(teamMember.endDate) >= new Date()) &&
                    (programId ? teamMember.programId == programId : true);
            }).map(function (name) {
                return '{0} {1}'.format(name.firstName, name.lastName);
            });
            return names.join(', ');
        }

        function getProgramHeaderContent(programId, programIndex) {
            var tcName = getNamesByProgramIdAndTeamMemberTypeId(teamMemberTypeNames.TEAMCHAIR);
            var pevNames = getNamesByProgramIdAndTeamMemberTypeId(teamMemberTypeNames.PEV, programId);
            var program = PROGRAM_AUDIT_DATA.programReviews.find(function (review) { return review.programId == programId; });
            //var isFirstProgramOfExitStatement = IS_EXIT_STATEMENT && programIndex == 0;

            return {
                pageBreak: IS_SINGLE_PROGRAM && !SHOW_INST_SUMMARY ? '' : 'before',
                table: {
                    //widths: [300],
                    body: [
                        [
                            {
                                text: [
                                    {
                                        pageBreak: 'before',
                                        text: program.programDto.programDetailDto.programName + '\n',
                                        style: ['campton', 'larger'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: program.programReviewDisciplineDtos[0].criteriaAreaName ? [
                                            {
                                                text: 'Used Program Criteria ',
                                                style: ['camptonMedium', 'medium']
                                            },
                                            {
                                                text: program.programReviewDisciplineDtos[0].criteriaAreaName,
                                                style: ['camptonMedium', 'medium','italics']
                                            }
                                        ] : [{
                                                text: 'Used General Criteria',
                                                style: ['camptonMedium', 'medium']
                                            }]
                                    }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 0, 0, 6]
                            }
                        ],
                        [
                            {
                                text: [
                                    {
                                        text: '{0}\n'.format(getVisitDateText(PROGRAM_AUDIT_DATA.programReviews[0].reviewTeamDto.visitStartDate, PROGRAM_AUDIT_DATA.programReviews[0].reviewTeamDto.visitEndDate)),
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: 'Team Chair: {0}'.format(tcName) + '\n',
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: 'Program Evaluator(s): {0}'.format(pevNames),
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, false]
                            }
                        ]
                    ]
                },
                layout: tableLayout
            }
        }

        function getExplanationOfPAF() {
            var details = [];
            explanationOfPAF.forEach(function (paragraph) {
                details.push(getBodyText('{0}\n\n'.format(paragraph)));
            });
            return details;
        }

        function getDueProcessSteps() {
            var details = []

            dueProcessSteps.forEach(function (step) {
                details.push({
                    text: [
                        {
                            text: '{0}  '.format(step.name),
                            style: ['small', 'camptonMedium', 'paragraph']
                        },
                        getBodyText('{0}'.format(step.description))
                    ]
                });
            });

            return {
                ul: details
            }
        }

        function getH2(text) {
            return {
                text: text.toUpperCase(),
                style: ['medium', 'orange', 'camptonSemiBold', 'addLineSpacing']
            };
        }

        function getH3(text) {
            return {
                text: text,
                style: ['small', 'camptonMedium', 'addLineSpacing']
            }
        }

        function getBodyText(text) {
            return {
                text: text,
                style: ['small', 'body', 'paragraph']
            }
        }

        function getDefinitionOfTerms() {
            return {
                layout: {
                    hLineWidth: function (i, node) {
                        return 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    paddingLeft: function (i, node) { return 15; },
                    paddingRight: function (i, node) { return 15; },
                    paddingTop: function (i, node) { return 15; },
                    paddingBottom: function (i, node) { return 0; },
                    fillColor: function (i, node) { return '#eeeeee'; }
                },
                margin: [0, 0, 0, 30],
                table: {
                    body: [
                        [
                            {
                                text: getDefinitionOfTermsDetails()
                            }
                        ]
                    ]
                }
            }

            function getDefinitionOfTermsDetails() {
                var details = []

                definitionOfTerms.forEach(function (def, i) {
                    var lineBreaks = i == definitionOfTerms.length - 1 ? '\n\n\n' : '\n\n';
                    details.push({
                        text: [
                            {
                                text: '{0}  '.format(def.name.charAt(0).toUpperCase()),
                                style: ['smaller', 'orange', 'camptonSemiBold']
                            },
                            {
                                text: '({0})  '.format(def.name),
                                style: ['smaller', 'camptonSemiBold']
                            },
                            {
                                text: '{0}{1}'.format(def.definition, lineBreaks),
                                style: ['smaller', 'body', 'paragraph']
                            }
                        ]
                    })
                });
                return details;
            }
        }

        function getProgramAuditSummary(currentAudit) {
            return {
                layout: 'lightHorizontalLines',
                table: {
                    layout: paddedTable,
                    widths: ['*', 100, 100],
                    body: getProgramAuditSummaryDetails()
                }
            };

            function getProgramAuditSummaryDetails() {
                var hasPreviousShortcomings = false;
                var hasCurrentShortcomings = false;
                var details = [
                    [
                        '',
                        {
                            text: 'Previous Review',
                            style: ['small', 'camptonSemiBold'],
                            alignment: 'center'
                        },
                        {
                            text: 'Current Review',
                            style: ['small', 'camptonSemiBold'],
                            alignment: 'center'
                        }
                    ]
                ];
                if (currentAudit.programAuditJson.auditSummary) {
                    currentAudit.programAuditJson.auditSummary.criteria.forEach(function (criteria) {
                        if (!hasPreviousShortcomings) hasPreviousShortcomings = criteria.previousFindingTypes.join('').includes('D') || criteria.previousFindingTypes.join('').includes('W');
                        if (!hasCurrentShortcomings) hasCurrentShortcomings = criteria.currentFindingTypes.join('').includes('D') || criteria.currentFindingTypes.join('').includes('W');

                        details.push([
                            {
                                text: trimCriteriaName(criteria.criteriaName).split(' ').map(function (word) {
                                    return word.replace(word[0], word[0].toUpperCase());
                                }).join(' '),
                                style: ['small', 'camptonSemiBold'],
                                margin: [0, 2],
                            },
                            {
                                text: criteria.previousFindingTypes.join(', '),
                                style: ['small', 'orange', 'camptonSemiBold'],
                                margin: [0, 2],
                                alignment: 'center'
                            },
                            {
                                text: criteria.currentFindingTypes.join(', '),
                                style: ['small', 'orange', 'camptonSemiBold'],
                                margin: [0, 2],
                                alignment: 'center'
                            }
                        ]);
                    });
                }

                if (!hasPreviousShortcomings || !hasCurrentShortcomings) {
                    var isInitialReview = currentAudit.programAuditJson.auditSummary.initialAccreditation;
                    details.splice(1, 0, [
                        {
                            text: 'No deficiences or weaknesses',
                            style: ['small', 'camptonLight','italics'],
                            margin: [5,5],
                            fillColor: '#eeeeee'
                        },
                        {
                            text: '{0}'.format(!hasPreviousShortcomings && !isInitialReview ? 'X' : ''),
                            style: ['small', 'camptonSemiBold'],
                            margin: [0,5],
                            alignment: 'center',
                            fillColor: '#eeeeee'
                        },
                        {
                            text: '{0}'.format(!hasCurrentShortcomings ? 'X' : ''),
                            style: ['small', 'camptonSemiBold'],
                            margin: [0,5],
                            alignment: 'center',
                            fillColor: '#eeeeee'
                        }
                    ])
                }

                return details;

                function trimCriteriaName(text) {
                    return text.toLowerCase().replace('criterion ', '').replace(':', '.').replace(/(^ms)(\d\.)/, 'MS$2');
                }
            }
        }

        function getFindings(currentAudit, displayInstSummary) {
            var details = [];

            if (!displayInstSummary) {
                var observationTexts = [];
                var hasObservations = false;
                var currentCriteria = criteria.concat(currentAudit.programAuditJson.auditSummary.criteria);

                currentCriteria.forEach(function (currentCriterion, i) {
                    // Only output non-criteria sections (i.e. Introduction) in the Exit Statement
                    // For the Exit Statement, don't output criteria that have no findings

                    if ((!IS_EXIT_STATEMENT && !currentCriterion.statementFindingTypeId) || (IS_EXIT_STATEMENT && getFindingText(currentCriterion))) {
                        details.push({
                            text: [
                                getH3('{0}\n'.format(currentCriterion.criteriaName)),
                                getFindingText(currentCriterion)
                            ]
                        });
                    }
                });

                // Output strengths and observations separately because there can be multiple findings of those types
                if (IS_EXIT_STATEMENT && currentAudit.programAuditJson.auditDetails) {
                    //strengths
                    var strengths = [];
                    for (var i = 0; i < currentAudit.programAuditJson.auditDetails.length; i++) {
                        var detail = currentAudit.programAuditJson.auditDetails[i];
                        if (detail.statementFindingTypeId == statementFindingTypes.PROGRAMSTRENGTH) {
                            detail.findings.forEach(function (finding) {
                                strengths.push({
                                    text: [
                                        getH3('Program Strength\n'),
                                        getFormattedFindingText(finding.criteria.text)
                                    ]
                                })
                            });
                            break;
                        }
                    }
                    details.splice(1, 0, strengths); // add strengths after first element (assumes first element is introduction)

                    //observations
                    for (var i = 0; i < currentAudit.programAuditJson.auditDetails.length; i++) {
                        var detail = currentAudit.programAuditJson.auditDetails[i];
                        if (detail.statementFindingTypeId == statementFindingTypes.PROGRAMOBSERVATION) {
                            detail.findings.forEach(function (finding) {
                                // add observations after other elements
                                details.push({
                                    text: [
                                        getH3('Program Observation\n'),
                                        getFormattedFindingText(finding.criteria.text)
                                    ]
                                })
                            });
                            break;
                        }
                    }
                }
            } else {
                // For institutional summmary, output everything in institutionalSummaryJson
                if (currentAudit.institutionalSummaryJson) {
                    currentAudit.institutionalSummaryJson.forEach(function (currentFinding, i) {
                        currentFinding.findings.forEach(function (finding, j) {
                            details.push({
                                text: [
                                    showHideInstSummaryH3(currentFinding, i, j),
                                    getFormattedFindingText(finding.criteria.text)
                                ]
                            })
                        });
                    });
                }
            }

            return details;

            function showHideInstSummaryH3(finding, summaryIndex, findingIndex) {
                var name = finding.statementFindingTypeName;
                if (finding.statementFindingTypeId == statementFindingTypes.INSTITUTIONSTRENGTH) {
                    // Only output "Institutional Strength" label for first strength
                    if (findingIndex > 0) {
                        return '';
                    } else if (finding.findings.length > 2) {
                        // Output "Institutional Strength(s)" if there is more than 1 strength
                        name = '{0}s'.format(name);
                    }
                }
                return getH3('{0}\n'.format((summaryIndex == 0 ? '\n' : '') + name));
            }

            function getFindingText(currentCriterion) {
                var findings = [];

                if (currentAudit.programAuditJson.auditDetails) {
                    currentAudit.programAuditJson.auditDetails.forEach(function (detail) {
                        detail.findings.find(function (finding) {
                            if (detail.statementFindingTypeId == currentCriterion.statementFindingTypeId || (currentCriterion.hasOwnProperty('criteriaId') && finding.criteria.criteriaId == currentCriterion.criteriaId)) {
                                findings.push(finding);
                            }
                        });
                    });
                }

                if (findings.length > 1) {
                    // This should only occur for CAC
                    return findings.map(function (finding) { return getFormattedFindingText(finding.criteria.text); });
                } else if (findings.length == 1) {
                    return getFormattedFindingText(findings[0].criteria.text);
                } else if (IS_EXIT_STATEMENT) {
                    // Don't show no shortcoming message for empty criterion on Exit Statement
                    return currentCriterion.statementFindingTypeId == statementFindingTypes.PROGRAMINTRODUCTION ? EMPTY_SECTION.BLANK : null;
                } else {
                    return EMPTY_SECTION.NOSHORTCOMING;
                }
            }

            function getFormattedFindingText(text) {
                var elements = htmlToElementArray(text)
                var findings = [];

                elements.forEach(function (elm, index) {
                    var hasComments = false;

                    // Check if childNodes contain text that has a comment and is wrapped in <mark> tags
                    elm.childNodes.forEach(function (node) {
                        if (node.nodeName.toLowerCase() == "mark") hasComments = true;
                    });

                    if (hasComments) {
                        // If text contains comments do NOT output each childNode as <mark> tags added around comments cause them to be treated as separate nodes
                        // This causes linebreaks to be added around commented text even if its in the middle of a paragraph
                        // Instead output text from parent element; this ignores mark tags and outputs an entire paragraph together
                        findings.push(getBodyText('{0}\n\n'.format(getElementText(elements[index]))));

                    } else {
                        // If text contains no comments, output each childNode
                        elm.childNodes.forEach(function (node) {
                            findings.push(getBodyText('{0}\n\n'.format(getElementText(node))));
                        });
                    }
                });

                if (findings.length < 1) {
                    findings.push(EMPTY_SECTION.BLANK);
                }
                findings.push('\n');

                return findings;
            }
        }

        function getInstSummaryFindings(currentAudit) {
            var isInstSummaryHeader = true;

            return [
                getInstitutionHeaderContent(isInstSummaryHeader),
                getFindings(currentAudit, true)
            ];
        }

        factory.generateDoc = function (currentProgramAuditData, isExitStatement, isSingleProgram, isInstitutionalSummary, isPreview, showInstitutionalSummary) {

            // set global variables
            PROGRAM_AUDIT_DATA = currentProgramAuditData;
            IS_EXIT_STATEMENT = isExitStatement;
            IS_SINGLE_PROGRAM = isSingleProgram;
            IS_INST_SUMMARY = isInstitutionalSummary;
            SHOW_INST_SUMMARY = showInstitutionalSummary;

            var docTitle;
            var content = [];
            var isNameLong = PROGRAM_AUDIT_DATA.organization.organizationName.length > 50;
            var watermarkText;
            var displayInstSummary = true;

            if (IS_INST_SUMMARY) {
                docTitle = 'Program Audit Institutional Summary Preview';
                watermarkText = '   INSTITUTIONAL SUMMARY PREVIEW   ';
                content = [
                    getInstSummaryFindings(PROGRAM_AUDIT_DATA.institutionalSummary)
                ];
            } else {
                var programAudits = [];

                docTitle = 'PAF Preview';
                watermarkText = isPreview ? (IS_EXIT_STATEMENT ? '   FINDINGS PREVIEW   ' : '   PAF  PREVIEW   ') : IS_EXIT_STATEMENT ? '   FOR ABET USE ONLY   ' : '';

                if (!IS_SINGLE_PROGRAM && !IS_EXIT_STATEMENT) {
                    var titlePage = [
                        {
                            text: '\n\n\n\n\n{0}'.format(isNameLong ? '' : '\n\n')
                        },
                        {
                            image: 'ABET_CMYK.png',
                            width: 150,
                            alignment: 'center'
                        },
                        {
                            text: '\n{0}\n\n\n\n{1}'.format(getCommissionFullName(PROGRAM_AUDIT_DATA.programReviews[0].programDto.commissionId).toUpperCase(), isNameLong ? '' : '\n\n\n'),
                            style: ['larger', 'camptonLight'],
                            alignment: 'center'
                        },

                        {
                            text: 'PROGRAM AUDIT FORM\n',//PROGRAM_AUDIT_DATA.organization.organizationName.toUpperCase() + '\n',
                            style: ['jumbo', 'camptonExtraBold'],
                            alignment: 'center'
                        },
                        {
                            text: 'FOR {0} VISITS \n\n'.format(getAccreditationCycle(PROGRAM_AUDIT_DATA.programReviews[0].reviewTeamDto.reviewDto.reviewYear)).toUpperCase(),
                            style: ['large', 'camptonMedium'],
                            alignment: 'center'
                        },
                        {
                            text: '{0}\n'.format(PROGRAM_AUDIT_DATA.organization.organizationName).toUpperCase(),
                            style: ['large', 'camptonExtraBold'],
                            alignment: 'center'
                        },
                        {
                            text: getVisitDateText(PROGRAM_AUDIT_DATA.programReviews[0].reviewTeamDto.visitStartDate, PROGRAM_AUDIT_DATA.programReviews[0].reviewTeamDto.visitEndDate),
                            style: ['medium', 'camptonMedium'],
                            alignment: 'center'
                        }
                    ];

                    var descriptionPage = [
                        getInstitutionHeaderContent(),
                        getH2('\nExplanation of Program Audit Form'),
                        getExplanationOfPAF(),
                        getDueProcessSteps()
                    ];

                    content = content.concat(titlePage);
                    content = content.concat(descriptionPage);
                }

                // Only use programAuditDetailDtos where isCurrent == true or, for shared/published audits, the most recent version available
                PROGRAM_AUDIT_DATA.programAudit.programAuditDetailDtos = PROGRAM_AUDIT_DATA.programAudit.programAuditDetailDtos.filter(function (detail) {
                    return detail.isCurrent ||
                        !PROGRAM_AUDIT_DATA.programAudit.programAuditDetailDtos.find(detail2 =>
                            detail2.programId === detail.programId &&
                            detail2.programAuditDetailId !== detail.programAuditDetailId &&
                            (detail2.isCurrent || detail2.insertedTimestamp > detail.insertedTimestamp)
                        );
                });

                // Alphabetize programAuditDetailDtos by program name
                PROGRAM_AUDIT_DATA.programAudit.programAuditDetailDtos.sort(function (audit1, audit2) {
                    var programName1 = getProgramName(audit1.programId).toLowerCase();
                    var programName2 = getProgramName(audit2.programId).toLowerCase();

                    return programName1 < programName2 ? -1 : (programName1 > programName2 ? 1 : 0);

                    function getProgramName(programId) {
                        return PROGRAM_AUDIT_DATA.programReviews.find(function (review) { return review.programId == programId; }).programDto.programDetailDto.programName;
                    }
                });

                // Add each program to programAudits array
                PROGRAM_AUDIT_DATA.programAudit.programAuditDetailDtos.forEach(function (currentAudit, i) {
                    var programAudit = [
                        getProgramHeaderContent(currentAudit.programId, i)
                    ];

                    if (!IS_EXIT_STATEMENT) {
                        programAudit.push(
                            getH2('\nProgram Audit Summary'),
                            getH3('Definition of Terms\n'),
                            getDefinitionOfTerms(),
                            currentAudit.programAuditJson ? getProgramAuditSummary(currentAudit) : {}
                        );
                    }

                    programAudit.push(
                        getH2('\nDetailed Explanation of {0}'.format(IS_EXIT_STATEMENT ? 'Findings' : 'Shortcomings')),
                        getFindings(currentAudit)
                    );

                    programAudits.push(programAudit);
                });

                // Add institutional summary to the beginning of exit statement unless showInstitutionalSummary = false
                if (IS_EXIT_STATEMENT && SHOW_INST_SUMMARY) {
                    content = content.concat(getInstSummaryFindings(PROGRAM_AUDIT_DATA.institutionalSummary));
                }
                content = content.concat(programAudits);
            }

            return getDocDefinition(docTitle, content, watermarkText);
        };

        function getDocDefinition(docTitle, content, watermarkText) {
            var docDefintion = {
                info: {
                    title: docTitle
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 70, 70, 70],
                watermark: {
                    text: watermarkText || '',
                    opacity: 0.06,
                    font: 'calibri'
                },
                footer: function (currentPage, pageCount) {
                    if (currentPage === 1 && !IS_SINGLE_PROGRAM && !IS_EXIT_STATEMENT && !IS_INST_SUMMARY) {
                        return {};
                    } else {
                        return {
                            columns: [
                                {
                                    text: [
                                        'PAGE ',
                                        { text: currentPage.toString(), style: 'campton' },
                                        ' OF ',
                                        { text: pageCount, style: 'campton' }
                                    ],
                                    alignment: 'left',
                                    style: ['smaller', 'camptonLight'],
                                    width: '*'
                                },
                                {
                                    text: [
                                        { text: 'PROGRAM AUDIT FORM ', style: 'camptonLight' },
                                        { text: PROGRAM_AUDIT_DATA.organization.organizationName.toUpperCase(), style: 'campton' }
                                    ],
                                    alignment: 'center',
                                    style: 'smaller',
                                    width: 260
                                },
                                {
                                    text: '{0} {1} EST'.format(new Date().toLocaleDateString('en-US', { year:'2-digit', month: 'numeric', day: 'numeric'}), new Date().toLocaleTimeString()),
                                    alignment: 'right',
                                    style: ['smaller', 'camptonLight'],
                                    width: '*'
                                }
                            ],
                            margin: [70, 44, 70, 0]
                        };
                    }
                }
            };

            return docDefintion;
        };

        return {
            generateDoc: factory.generateDoc
        };
    };

    module.factory('programAuditPDFSvc', programAuditPDFSvc);

})(angular.module('programAudit'));