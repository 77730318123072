(function (module) {

    var createProgramAuditDetailCtrl = function (program, programAudit, reviewTeamMembers, $scope, $uibModalInstance, alertSvc, readinessSvc, programAuditDetailTemplateSvc,
        programAuditDetailEvaluatorSvc, programAuditDetailSvc) {

        var model = $scope;

        model.title = 'Create Program Audit Template';
        model.program = program;
        model.programAudit = programAudit;
        model.reviewTeamMembers = reviewTeamMembers;
        model.criteria = null;
        model.findingTypeOptions = null;
        model.isDataReady = false;

        model.getCriteriaFullName = readinessSvc.getCriteriaFullName;

        model.isShortcoming = programAuditDetailTemplateSvc.isShortcoming;

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            const programAuditDetail = programAuditDetailTemplateSvc.createTemplate(
                model.program.commissionId,
                model.programAudit.programAuditId,
                model.program.programId, model.criteria,
                model.findingTypeOptions
            );
            // Note: should let parent controller do CRUD but want to be consistent with statement tool and avoid UI updating flicker
            model.isSaving = true;
            programAuditDetailSvc.create(programAuditDetail).then(programAuditDetail => {
                programAuditDetailEvaluatorSvc.initializeProgramAuditRecommendedActions(programAuditDetail, model.reviewTeamMembers).then(programAuditDetailEvaluators => {
                    programAuditDetail.programAuditEvaluatorDtos = programAuditDetailEvaluators;
                    $uibModalInstance.close(programAuditDetail);
                }).catch(onError);
            }).catch(onError);

            function onError(error) {
                console.log(error);
                model.isSaving = false;
                alertSvc.addModalAlertWarning('Program audit creation failed.')
            }
        };

        model.isCriteriaNeeded = function (findingTypeOption) {
            if (findingTypeOption.value === true) {
                return true;
            }
            return false;
        }

        model.isValid = function () {
            return programAuditDetailTemplateSvc.validateFindingTypeOptions(model.findingTypeOptions, true);
        }

        activate();

        function activate() {
            programAuditDetailTemplateSvc.getAllProgramAuditTemplateData(model.program).then((data) => {
                Object.assign(model, data);
                model.isDataReady = true;
            });
        };

    };

    module.controller('createProgramAuditDetailCtrl', createProgramAuditDetailCtrl);

}(angular.module('programAudit')));