(function (module) {

    var programAreaSvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerDiscipline',
            keyName: 'volunteerDisciplineId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'volunteerProgramAreas',
            getByForeignKeyPath: '/GetVolunteerDisciplineByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        factory.data.commissionOptions = [];
        factory.data.volunteerDisciplineStatusOptions = [];

        factory.getDisciplineSociety = function (commissionId, societyId, disciplineId) {
            var oSvc = odataSvc.get();
            var disciplineApiPath = '/DisciplineSociety';
            var filter = ''
            var id = 0;

            if (commissionId) {
                filter = 'commissionId';
                id = commissionId;
            }
            if (societyId) {
                filter = 'societyId'
                id = societyId;
            }
            if(disciplineId){
                filter = 'disciplineId'
                id = disciplineId;
            }

            oSvc.getSource(disciplineApiPath, key).odata()
                .filter(filter, id)
                .orderBy('disciplineName')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getProgramAreaText = function (programArea) {
            return (programArea.societyAbbreviatedName || programArea.societyName) + ' | ' + programArea.disciplineName;
        };

        //DELETE WHEN NEW CONFIRMATION DIALOG IS READY
        var apiPath = '/VolunteerDiscipline';
        var key = 'volunteerDisciplineId';

        factory.delete = function (programArea) {
            var oSvc = odataSvc.get();

            //confirmation breaks protractor test disabling for now
            //if ($window("Are you sure you want to delete this record?")) {

            var resource = oSvc.instantiate(apiPath, key, { volunteerDisciplineId: programArea.volunteerDisciplineId });
            resource.$delete(oSvc.onSuccess, oSvc.onFailure);

            oldLoadData(oSvc, programArea.volunteerId);

            return oSvc.getDeferred().promise;
            //}
            //else {
            //    return $q.reject('user cancelled');
            //}
        };

        function oldLoadData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getByForeignKey(volunteerId);
            });
        }

        return {
            data: factory.data,
            getDisciplineSociety: factory.getDisciplineSociety,
            getProgramAreasByVolunteerId: factory.getByForeignKey,
            getProgramAreaText: factory.getProgramAreaText,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('programAreaSvc', programAreaSvc);

})(angular.module('volunteer'));