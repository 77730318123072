(function (module) {

    var pevAppSearchCtrl = function ($scope, $state, $q, $filter, $uibModal, alertSvc, barSvc, documentSvc, helperSvc, codeSvc, pevAppSvc, programAreaSvc, organizationSvc, oauth, currentUser, currentUserHelper, pevApplicationStatuses, contactRoleTypes, pevAppExportPDFSvc, pevAppPDFSvc) {
        var model = this;
        var defaultData = {
            society: null,
            allProgramAreaOptions: null
        };

        model.formatDate = helperSvc.formatDate;
        model.resultsTitle = "Results";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.isExportingCSV = false;
        model.isExportingPDF = false;
        model.isDataReady = false;
        model.showSpinner = false;
        model.searchOptions = {};
        model.applications = [];
        model.isSelectAll = false;
        model.isSelectionLimited = false;
        model.isSelectionLimitReached = false;
        model.selectionLimit = 20
        model.selectedList = [];
        model.isAdmin = pevAppSvc.isAdmin();
        model.isViceOp = oauth.getOrganizationsByUserRoleId(contactRoleTypes.VICECHAIROFOPERATIONS).length > 0;
        model.openMasterInstructions = function () { pevAppSvc.openMasterInstructions(true); }
        model.showExportSelection = false;
        model.selectAllState = false;
        model.programAreaOptions = [];

        model.openPevAppPreview = function (app) {
            pevAppPDFSvc.openApplicationModal(app.volunteerApplicationId);
        };

        model.openPevResume = function (app) {
            var title = '{0} {1} Resume'.format(app.firstName, app.lastName);
            if (app.stream_id) {
                documentSvc.downloadDocumentBase64(app.stream_id).then(function (pdfData) {
                    alertSvc.openPDFModal(pdfData, title);
                });
            } else {
                alertSvc.openModalAlert("{0} {1} hasn't uploaded a resume.".format(app.firstName, app.lastName), title);
            };
        };

        model.csvHeaders = ['Last Name', 'First Name', 'Middle Name', 'Email', 'Telephone Number', "Country",
            'Most Recent Employer', 'Employer Type', 'Disciplines', 'Society', 'Application Status', 'Society Status', 'Membership Number', 'Submitted Date','Recent Training'];
        model.csvColumns =["lastName", "firstName", "middleName", "emailAddress", "phone", "countryName",
            'mostRecentEmployer', 'mostRecentEmployerTypeName', 'disciplineNames', 'societyCode', 'volunteerApplicationStatusName', 'volunteerApplicationSocietyStatusName', 'membershipNumber', 'submittedDate','trainingYear'];

        model.toggleSelection = function (volunteerApplicationId) {
            var position = model.selectedList.indexOf(volunteerApplicationId);
            if (position > -1)
                model.selectedList.splice(position, 1);
            else
                model.selectedList.push(volunteerApplicationId);

            updateSelectAllState();
        };

        model.selectAllForExport = function (e) {
            if (model.selectedList.length > 0) {
                model.selectedList = [];
            } else {
                model.selectedList = model.applications.map(function (app) { return app.volunteerApplicationId });
            }
            e.target.checked = updateSelectAllState();
        };

        var updateSelectAllState = function () {
            model.isSelectAll = model.selectedList.length && model.selectedList.length === model.applications.length;
            model.isSelectionLimited = model.applications.length >= model.selectionLimit * 2;
            model.isSelectionLimitReached = model.selectedList.length >= model.selectionLimit;

            if (model.selectedList.length === 0) {
                model.selectAllState = false;
            } else if (model.isSelectAll || model.isSelectionLimited && model.isSelectionLimitReached ) {
                model.selectAllState = true;
            } else {
                model.selectAllState = "indeterminate"
            }
            return model.selectAllState;
        };

        model.handleExportCancel = function () {
            if (model.selectedList.length > 0) {
                alertSvc.confirm("This will clear your current selections. Do you want to proceed?", clearSelections);
            } else {
                clearSelections();
            }
        };

        var clearSelections = function () {
            model.showExportSelection = false;
            model.selectedList = [];
            updateSelectAllState();
        };
        
        model.reset = function () {
            model.searchOptions = {
                society: defaultData.societies,
                statusId: null,
                personName: null,
                submissionRange: {
                    startDate: null,
                    endDate: null
                },
                programAreas: []
            };
            model.resultMsg = model.defaultSearchMsg;
            model.applications = [];
            model.programAreaOptions = defaultData.allProgramAreaOptions;
            clearSelections();
        };

        model.isSearchEnabled = function () {
            return model.searchOptions.statusId || model.searchOptions.programAreas.length || model.searchOptions.societyStatusId || model.searchOptions.personName || model.searchOptions.submissionRange.startDate || model.searchOptions.submissionRange.endDate;
        }

        model.retrieve = function () {
            if (model.selectedList.length > 0) {
                alertSvc.confirm("Filtering the list of applications will clear your current selections. Do you want to proceed?", doRetrieve);
            } else {
                doRetrieve();
            }

            function doRetrieve() {
                var searchOpts = model.searchOptions; 
                
                updateProgramAreas();

                if (!model.isAdmin){
                    searchOpts.statusId = pevApplicationStatuses.SUBMITTED
                }

                model.resultMsg = null;
                model.showSpinner = true;
                clearSelections();

                pevAppSvc.advancedSearch(searchOpts).then(function (data) {
                    model.showSpinner = false;
                    var societiesFilter = $filter('filterBySocieties');
                    model.applications = societiesFilter(data, model.societies);
                    updateSelectAllState();
                    if (model.applications.length === 0) {
                        model.resultMsg = "No results found";
                    }
                    });
                }
            };

        model.exportPDF = function () {
            // Break up into batches
            const batches = [];
            const selections = [...model.selectedList];
            const size = model.isSelectionLimited ? model.selectionLimit : selections.length;
            while (selections.length > 0) {
                batches.push(selections.splice(0, size));
            }
            // Prompt before proceeding if a large number are selected for download (i.e. selection limit is reached)
            if (model.isSelectionLimitReached) {
                const msg =
                    'Downloading this many applications may take several minutes. ' +
                    `${(batches.length > 1) ? `Applications will be downloaded in multiple batches of ${model.selectionLimit} applications each. Your browser may prompt you for permission to download multiple files. ` : ''}` +
                    'Do you want to proceed?';

                alertSvc.confirm(msg, doExportPDF);
            } else
                doExportPDF();

            async function doExportPDF() {
                model.showSpinnerExport = true;
                model.isExportingPDF = true;
                model.showSpinner = true;

                try {
                    const batchNumberMask = '0'.repeat((batches.length + '').length); // e.g. '000'; IEEE, ASEE, and CSAB could have several hundred applications
                    for (let index = 0; index < batches.length; index++) {
                        const batchNumberSuffix = batches.length === 1 ? '' : '_' + (batchNumberMask + index).slice(-1 * batchNumberMask.length); // e.g. '012'
                        const filename = `pev_applications${batchNumberSuffix}`;
                        await pevAppExportPDFSvc.exportPDF(batches[index], filename);
                    }
                    alertSvc.addAlertSuccess('Applications successfully exported.');                  
                } catch (ex) {
                    console.log('Error exporting applications', ex);
                    alertSvc.addAlertWarning('Applications could not be exported at this time. Please try again later or try exporting in smaller batches.');
                } finally {
                    clearExportFlags();
                }
            }
        };

        model.exportCSV = function () {
            var defer = $q.defer();
            model.showSpinnerExport = true;
            model.isExportingCSV = true;

            pevAppSvc.getApplicationExportList(model.applications, model.societies).then(function (data) {
                var list = data.data.value.map(function (app) {
                    var newApp = app;
                    if (newApp.phone && RegExp('^[+-]?\\d+$').test(newApp.phone)) {
                        newApp.phone = helperSvc.getFormattedPhone(newApp.phone);
                    }

                    if (newApp.membershipNumber) {
                        newApp.membershipNumber = "#" + newApp.membershipNumber;
                    }

                    if (newApp.submittedDate) {
                        var date = new Date(newApp.submittedDate);
                        if (date != NaN)
                            newApp.submittedDate = date.toLocaleDateString('en-US', { timeZone: 'UTC' }).replace(/\u200E/g, ''); // IE 11 adds LTR characters -- remove them.
                    }
                    return newApp;
                });
                defer.resolve(resolveExport(list));
            });

            return defer.promise;
        };

        function resolveExport (data) {
            clearExportFlags();
            return data;
        }

        function clearExportFlags () {
            model.showSpinnerExport = false;
            model.isExportingCSV = false;
            model.isExportingPDF = false;
            model.showSpinner = false;
        } 

        model.getSocietyStatusName = function (id) {
            var currentStatus = model.societyStatuses.find(function (status) {
                return status.codeKey == id;
            });
            return currentStatus ? currentStatus.codeName : '';
        };

        model.getStatusName = function (id) {
            var currentStatus = model.statuses.find(function (status) {
                return status.codeKey == id;
            });
            return currentStatus ? currentStatus.codeName : 'Waiting for Submission';
        };

        var societyOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'societies',
            svcCallback: organizationSvc.getMemberSocieties,
            helperCallback: helperSvc.getResults
        };

        var statusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'statuses',
            svcCallback: codeSvc.getVolunteerAppStatuses,
            helperCallback: helperSvc.getResults
        };

        var societyStatusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'societyStatuses',
            svcCallback: codeSvc.getVolunteerAppSocietyStatuses,
            helperCallback: helperSvc.getResults
        };

        var programAreaOptionsDataSource =  {
            dataHolder: defaultData,
            dataLocationName: 'allProgramAreaOptions',
            svcCallback: [codeSvc.getSocietyDiscpiplineTypes],
            helperCallback: helperSvc.getValue,
        }

        var preFilterPossibleProgramAreaOptions = function () {
            [resultArray, nonMatchedArray] = helperSvc.getArrayByFieldValue(defaultData.allProgramAreaOptions, 'societyId', model.societies.map((org) => org.organizationId));
            defaultData.allProgramAreaOptions = resultArray;
            model.programAreaOptions = resultArray;
        };


        var activate = function () {
            var dataSourceArray = [
                statusOptionsDataSource,
                societyStatusOptionsDataSource,
                societyOptionsDataSource,
                programAreaOptionsDataSource
            ];

            model.reset();
            barSvc.showBar();

            if (!model.isAdmin) {
                var userOrgs = oauth.getOrganizationsByUserRoleId(contactRoleTypes.EDUCATIONLIASON);

                userOrgs = userOrgs.concat(oauth.getOrganizationsByUserRoleId(contactRoleTypes.TRAININGMANAGER));
                userOrgs = userOrgs.concat(oauth.getOrganizationsByUserRoleId(contactRoleTypes.PEVRECRUITINGMANAGER));
            }         

            helperSvc.getData(dataSourceArray).then(function () {
                //if (model.isAdmin) {
                //    var item = { codeKey: 0, codeName: 'Waiting for Submission' };
                //    model.statuses.unshift(item);
                //}
                if (!model.isAdmin) {
                    model.societies = getUsersSocieties(model.societies, userOrgs);
                    preFilterPossibleProgramAreaOptions();
                    // if only one society in dropdown, preselect it
                    if (model.societies && model.societies.length === 1) {
                        defaultData.societies = model.searchOptions.society = model.societies[0].organizationId;
                        model.retrieve(); // Should we wait until user submits search criteria?
                    }
                } else {
                    model.programAreaOptions = defaultData.allProgramAreaOptions;
                }

                model.isDataReady = true;
                barSvc.hideBar();
            });
        }();

        function getUsersSocieties (societies, orgs) {
            var orgIds = [];
            
            angular.forEach(orgs, function (org) {
                orgIds.push(org.organizationId);
            });

            var userSocieties = helperSvc.getFilteredArray(societies, 'organizationId', orgIds, true);

            return userSocieties;      
        }

        model.formatDisciplines = function (disciplines) {
            var disciplineString = disciplines.map((discipline) => {
                return '{0} [{1}]'.format(discipline.DisciplineName, discipline.SocietyName)
            }).join(', ');
            return disciplineString;
        }

        model.onSocietyChange = function () {
            // deselect PAs when society changes, since the PA choices will change
            model.searchOptions.programAreas = [];
            model.retrieve()
        };

        function updateProgramAreas() { // this should only concern itself with filtering PAs based on other user inputs
            if (!model.searchOptions.society) {
                model.programAreaOptions = defaultData.allProgramAreaOptions;
                return
            }
            var programAreaOptions = [];

            for (var i = 0; i < defaultData.allProgramAreaOptions.length; i++) {
                var programArea = defaultData.allProgramAreaOptions[i];

                if (programArea.societyId == model.searchOptions.society
                        && !programAreaOptions.includes(programArea)) {
                    var item = { disciplineId: programArea.disciplineId, disciplineName: programArea.disciplineName };
                    programAreaOptions.push(item);
                }
            }
            model.programAreaOptions = programAreaOptions;
            return programAreaOptions;
        }
    };

    

    module.controller('pevAppSearchCtrl', pevAppSearchCtrl);

}(angular.module('pevApp')));