(function () {
    'use strict';

    var app = angular.module('security', ['common', 'ngIdle']);

    app.config(function (IdleProvider, TitleProvider) {
        IdleProvider.idle(60*60); //60 min
        IdleProvider.timeout(30); //30 sec
        TitleProvider.enabled(false);
    });

    app.run(function ($rootScope, $location, $state, $uibModal, $interval, eventSvc, $uibModalStack, Idle, oauth, loginRedirect, currentUser, localStorage, helperSvc, offlineSvc, environmentSvc) {

        if (!Idle.running())
            Idle.watch();

        $rootScope.$on('IdleStart', function () {
            if (!offlineSvc.isAppOffline() && oauth.isAuthenticated()) {
                console.log('Session time out in 30 sec...');
                closeModals();

                $rootScope.warning = $uibModal.open({
                    templateUrl: '/apps/security/templates/warningDialog.html',
                    windowClass: 'modal-danger'
                });
            }
        });

        $rootScope.$on('IdleTimeout', function () {
            closeModals();

            //if (offlineSvc.isAppOffline() || environmentSvc.isDev()) {
            //    Idle.watch();
            //    return;
            //}

            var errorMsg = 'You have been logged out due to inactivity.';

            if (!currentUser.profile.impersonateUsername) {
                // If impersonating at timeout don't return to the last path upon login
                // User may not have permissions for the path with their own account
                loginRedirect.setLastPath($location.url());
            } else {
                errorMsg += ' Your impersonation of <em>' + currentUser.profile.firstName + ' ' + currentUser.profile.lastName + '</em> has expired.';
            }

            oauth.logout();
            helperSvc.removeBodyClass('assignment-tool');
            $rootScope.isWatchOn = false;
            Idle.unwatch();
            $uibModalStack.dismissAll();

            $state.go('root', {
                loginStatus: null,
                successMsg: null,
                errorMsg: errorMsg
            });
        });

        $rootScope.$on('IdleEnd', function () {
            closeModals();
        });

        $rootScope.$on('reset', function () {
            Idle.watch();
        });
                
        function closeModals() {
            if ($rootScope.warning) {               
                $rootScope.warning.close();
                $rootScope.warning = null;
            }
        }
    });

}());



