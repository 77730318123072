(function (module) {

    var addTrainingCtrl = function ($scope, $uibModalInstance, alertSvc, trainingSvc, selectedTraining, searchOptions, oauth, codeSvc, helperSvc, contactRoleTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Add Training';
        model.trainingDto = {};
        model.isAdmin = oauth.isAdmin();

        if (!selectedTraining) {
            model.trainingDto.trainingName = null;
            model.trainingTypeId = null;
            model.trainingYear = null;
        }
        else {
            model.trainingDto = selectedTraining;
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.saveTraining = function () {
            if (selectedTraining) {
                trainingSvc.updateTraining(model.trainingDto).then(function () {
                    trainingSvc.getTrainings(searchOptions);
                    alertSvc.addModalAlertSuccess("Training succesfully updated.");
                    $uibModalInstance.close();
                })
            }
            else {
                trainingSvc.addTraining(model.trainingDto).then(function () {
                    trainingSvc.getTrainings(searchOptions);
                    alertSvc.addModalAlertSuccess("Training succesfully added.");
                    $uibModalInstance.close();
                })
            }
        }
    
        var activate = function () {

            codeSvc.getTrainingTypes().then(function (data) {
                model.trainingTypes = helperSvc.getResults(data);

            });

            model.dataIsReady = true;
        }();
    };

    module.controller('addTrainingCtrl', addTrainingCtrl);

}(angular.module('training')));