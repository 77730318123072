(function (module) {
    'use strict'; function initializeReport(change, reviewer, reportType, program) {
        const teamMemberTypeId = teamMemberTypeNames.NPCREVIEWER;
        const changeReportSectionIds = [npcSectionTypes.INTRODUCTION, npcSectionTypes.SUMMARYOFCHANGES, npcSectionTypes.MATERIALSREVIEWED, npcSectionTypes.FINDINGS, npcSectionTypes.RECOMMENDATION];
        const terminationReportSectionIds = [npcSectionTypes.INTRODUCTION, npcSectionTypes.TERMINATIONPLAN, npcSectionTypes.RECOMMENDATION];
        const statementSectionIds = [npcSectionTypes.INSTITUTION, npcSectionTypes.PROGRAM, ...(program?.commissionId === commissionIds.CAC && [npcSectionTypes.SUMMARY] || [])];
        const reportSectionIds = change.npcType === npcTypes.CHANGE ? changeReportSectionIds : terminationReportSectionIds;
        const institutionFindingTypes = [statementFindingTypes.INSTITUTIONINTRODUCTION, (program?.commissionId === commissionIds.CAC && statementFindingTypes.REVIEWTEAM), null, statementFindingTypes.INSTITUTIONSUMMARY];
        const programFindingTypes = [statementFindingTypes.PROGRAMINTRODUCTION, (change.npcType === npcTypes.TERMINATION && statementFindingTypes.TERMINATIONPLAN)];
        const summaryFindingTypes = [statementFindingTypes.PROGRAMSUMMARY];

        let findingTypes;

        const reportJson = reportSectionIds.map((npcSectionTypeId, index) => {
            // Init generic properties
            const section = {
                npcSectionTypeId,
                npcSectionTypeName: npcSectionTypeNames[npcSectionTypeId],
                orderId: index + 1
            };

            // Init report/statement specific properties
            if (reportType === npcReportTypes.REVIEWREPORT || reportType === npcReportTypes.STATEMENT) {
                section.text = null;
            } else {
                switch (npcSectionTypeId) {
                    case npcSectionTypes.INSTITUTION:
                        section.statementCategoryId = statementCategories.INSTITUTION;
                        findingTypes = institutionFindingTypes;
                        break;
                    case npcSectionTypes.PROGRAM:
                        section.statementCategoryId = statementCategories.PROGRAM;
                        section.programId = program.programId;
                        section.programName = program.programName;
                        section.degreeLevelCode = program.degreeLevelCode;
                        section.degreeCode = program.degreeCode;
                        section.alternateName = program.alternateName;
                        section.alternateDegreeCode = program.alternateDegreeCode;
                        findingTypes = programFindingTypes;
                        break;
                    case npcSectionTypes.SUMMARY:
                        section.statementCategoryId = statementCategories.SUMMARY;
                        findingTypes = summaryFindingTypes;
                        break;
                }

                section.statementJson = [];
                findingTypes.forEach((findingType, index) => {
                    if (!findingType) return; // ignore placeholders for finding types that don't apply to focused visit statements

                    section.statementJson.push(
                        {
                            statementFindingTypeId: findingType,
                            statementFindingTypeName: statementFindingTypeNames[findingType],
                            orderNumber: index + 1,
                            findings: [
                                {
                                    criteria: {
                                        text: null
                                    },
                                    key: helperSvc.getNewKey() // for conssistency, optional future use in comparisons
                                }
                            ]
                        });
                });
            }

            // Init recommended action and effective termination/start dates as needed
            if (npcSectionTypeId === npcSectionTypes.RECOMMENDATION || npcSectionTypeId === npcSectionTypes.PROGRAM) {
                section.recommendationTypeId = null;
                section.recommendationTypeName = null;
                if (change.npcType === npcTypes.TERMINATION)
                    section.recommendedTerminationDate = null;
                else if (npcSectionTypeId === npcSectionTypes.PROGRAM && change.changeJson.some(changeJson => changeJson.npcChangeTypeId === npcChangeTypeIds.NAME))
                    section.newProgramNameStartDate = null;
            }

            return section;
        });

        return {
            npcProgramChangeId: change.npcProgramChangeId,
            reportType,
            reportJson,
            npcProgramChangeReviewerId: reviewer.npcProgramChangeReviewerId,
            submittedDate: null,
            teamMemberTypeId
        };
    }

    module.factory('npcProgramChangeReportSvc', [
        'odataSvc', '$http', 'alertSvc', 'amsConst', '$odata', 'npcSvc', 'organizationSvc', 'npcReportTypes', 'npcTypes', 'teamMemberTypeNames', 'npcSectionTypes', 'npcSectionTypeNames', '$q', 'programSvc', 'programReviewSvc', 'helperSvc',
        function (odataSvc, $http, alertSvc, amsConst, $odata, npcSvc, organizationSvc, npcReportTypes, npcTypes, teamMemberTypeNames, npcSectionTypes, npcSectionTypeNames, $q, programSvc, programReviewSvc, helperSvc) {

            const apiPath = '/NpcProgramChangeReport';
            const keyName = 'npcProgramChangeReportId';

            function getByNPCProgramChangeId(npcProgramChangeId) {
                const programChangeId = parseInt(npcProgramChangeId);
                const oSvc = odataSvc.get();

                oSvc.getSource(apiPath, keyName).odata()
                    .filter('npcProgramChangeId', programChangeId)
                    .query(oSvc.onSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise.then(data => {
                    deserializeJson(data);

                    return data;
                });

            }

            function getById(npcProgramChangeReportId) {
                const id = parseInt(npcProgramChangeReportId);
                const oSvc = odataSvc.get();

                oSvc.getSource(apiPath, keyName).odata()
                    .get(id, oSvc.onSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise.then(data => {
                    deserializeJson(data);

                    return data;
                });
            }

            function create(npcProgramChangeReport) {
                const oSvc = odataSvc.get();
                const serializedNpcProgramChangeReport = serializeJson(npcProgramChangeReport);
                const resource = oSvc.instantiate(apiPath, keyName, serializedNpcProgramChangeReport);

                return resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(data => {
                    deserializeJson(data)
                    return data;
                });
            }

            function update(npcProgramChangeReport) {
                const oSvc = odataSvc.get();
                const serializedNpcProgramChangeReport = serializeJson(npcProgramChangeReport);
                const resource = oSvc.instantiate(apiPath, keyName, serializedNpcProgramChangeReport);

                return resource.$update(null, oSvc.onSuccess, oSvc.onFailure);
            }

            function deleteNPCProgramChangeReport(npcProgramChangeReport) {
                const oSvc = odataSvc.get();
                const resource = oSvc.instantiate(apiPath, keyName, npcProgramChangeReport);

                return resource.$delete(oSvc.onSuccess, oSvc.onFailure);
            }

            function deserializeJson(npcProgramChangeReport) {
                if (!npcProgramChangeReport) return;

                const data = Array.isArray(npcProgramChangeReport) ? npcProgramChangeReport : [npcProgramChangeReport];

                angular.forEach(data, npcProgramChangeReport => {
                    npcProgramChangeReport.reportJson = angular.fromJson(npcProgramChangeReport.reportJson);
                    npcProgramChangeReport.reportJson.forEach(helperSvc.deserializeDateFields);
                });
            }

            function serializeJson(npcProgramChangeReport) {
                if (!npcProgramChangeReport) return npcProgramChangeReport;

                const serializedNpcProgramChangeReport = angular.copy(npcProgramChangeReport);
                //const sections = npcProgramChangeReport.reportType === npcReportTypes.REVIEWREPORT ?
                  //  serializedNpcProgramChangeReport.reportJson :
                    //serializedNpcProgramChangeReport.reportJson.flatMap(reportJson => reportJson.statementJson.flatMap(statementJson => statementJson.findings));

                const sections = serializedNpcProgramChangeReport.reportJson;

                sections.forEach(section => {
                    delete section.isAutosaving;
                    delete section.delayElapsed;
                    delete section.saveComplete;
                    //const textContainer = npcProgramChangeReport.reportType === npcReportTypes.REVIEWREPORT ? section : section.criteria;
                    const textContainer = section;
                    if (textContainer.text?.length > 0 && !hasText(textContainer.text)) textContainer.text = null;
                });

                serializedNpcProgramChangeReport.reportJson = angular.toJson(serializedNpcProgramChangeReport.reportJson);

                return serializedNpcProgramChangeReport;
            }

            function getAllNPCToolData(npcId, npcProgramChangeReportId, npc, organization, programReview) {
                const npcToolData = {};

                return $q.when(npc || npcSvc.getNPCById(npcId)).then(npcData => {
                    npcToolData.npc = npcData;
 
                    return $q.when(organizationSvc.getOrgByIdOdata(npcToolData.npc.organizationId)).then(organizationData => {

                        npcToolData.organizationDetailDto = organizationData.currentOrganizationDetailDto;
                        npcToolData.organizationAddressDto = organizationData.currentOrganizationAddressDto;

                        var promises = [];
                        var reviewArr = [];

                        npcToolData.npc.npcProgramDtos.forEach(function (pgm) {
                            var prom = getLatestProgramReview(pgm.programId).then(function (data) {
                                return data;
                            });

                            promises.push(prom);
                        });

                        return $q.all(promises).then(function (data) {
                            for (var i = 0; i < data.length; i++)
                                npcToolData.npc.npcProgramDtos[i].programReview = data[i];

                            return npcToolData;
                        });
                    });
                })
            }

            const placeholderText = 'Click to add text...';

            function hasText (text) {
                // markup is added whenever anything is entered, even if all the text was deleted
                return !(!text || text === '' || text === '<p>&nbsp;</p>' || text === placeholderText || text === '<p>' + placeholderText + '</p>');
            }

            function validateTerminationDate(terminationDate, programReview) {
                if (!Date.parse(terminationDate)) return false;

                terminationDate = new Date(terminationDate);
                const lastReviewYear = parseInt(programReview.lastAction);
                const accreditationEndYear = parseInt(programReview.accreditationDates.slice(-4));
                const terminationYear = terminationDate.getUTCMonth() < 9 ? terminationDate.getUTCFullYear() : terminationDate.getUTCFullYear() + 1;

                return (terminationYear >= lastReviewYear || !lastReviewYear) && terminationYear <= accreditationEndYear + 3; //merged or new programs does not have last action
            }

            function getLatestProgramReview(programId) {

                return programReviewSvc.getReviewsByProgramId(programId, true).then(data => {
                        const latestProgramReview = data?.value?.length ? helperSvc.getResults(data)[0] : {};

                        latestProgramReview.disciplines = latestProgramReview?.programReviewDisciplineDtos && latestProgramReview.programReviewDisciplineDtos
                            .map(programReviewDisciplineDto => programReviewDisciplineDto.disciplineName)
                            .sort()
                            .join(', ');

                        return latestProgramReview;
                });

            }

            function adjunctCreateReport(changeReportId, changeReviewerId) {

                var path = amsConst.webApiUrl + '/odata/NpcAdjunctCreateReport';
                var data = { "npcProgramChangeReportId": changeReportId, "npcProgramChangeReviewerId": changeReviewerId };

                return $http.post(path, data).then(result => {
                    deserializeJson(result.data)
                    return result.data;
                });
            }

            function adjunctSubmitReport(changeReport) {
                const serializedNpcProgramChangeReport = serializeJson(changeReport);
                var path = amsConst.webApiUrl + '/odata/AdjunctSubmitStatement';

                var data = { "npcProgramChangeReportDto": serializedNpcProgramChangeReport };

                return $http.post(path, data);

            }

            return {
                getByNPCProgramChangeId,
                getById,
                create,
                update,
                delete: deleteNPCProgramChangeReport,
                getAllNPCToolData,
                placeholderText,
                hasText,
                validateTerminationDate,
                adjunctCreateReport,
                adjunctSubmitReport
            };

        }
    ]);


}(angular.module('npc')));