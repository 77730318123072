(function (module) {

    var confirmDateCtrl = function (suggestedDate, selectedDates, callback, $scope, $uibModalInstance, helperSvc) {
        var model = $scope;

        model.title = 'Edit Visit Dates';
        model.suggestedDate = suggestedDate;
        model.dates = {};
        model.dates.startDate = selectedDates.startDate || suggestedDate;
        model.dates.endDate = selectedDates.endDate || null;
        model.formatDate = helperSvc.formatDate;

        model.isValid = function () {
            return model.dates.startDate && model.dates.endDate  && model.dates.startDate <= model.dates.endDate;
        }

        model.confirmDates = function () {
            callback(model.dates);
            $uibModalInstance.close();
            return true;
        };

        model.close = function () {
            $uibModalInstance.close();
            return false;
        };
    };

    module.controller('confirmDateCtrl', confirmDateCtrl);

}(angular.module('common')));