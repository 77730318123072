(function (module) {

    var rfrProgramInformationCtrl = function ($scope, rfrSvc, oauth, rfrProcessStatuses, npcSvc, npcChangeTypeIds) {
        var model = this;

        model.openProgramInstructions = rfrSvc.openProgramInstructions;
        model.npcChangeTypeIds = npcChangeTypeIds;
        model.getColorClass = npcSvc.getColorClass;
        model.npcData = [];

        model.getBorderClass = function (npcChangeTypeId) {
            return 'border ' + model.getColorClass(npcChangeTypeId);
        };

        model.addProgram = function (commission) {
            var program = null;
            var isChangeNameRequest = false;
            var mode = { rfe: true };

            rfrSvc.addEditProgram(program, commission, isChangeNameRequest, mode);
        };

        model.isAuthorizedToEditRFE = rfrSvc.isAuthorizedToEditRFE;

        model.rfrHasCommissionDtos = function () {
            return model.data && model.data.rfr.rfrCommissionDtos;
        };

        var activate = function () {
            model.isAdmin = oauth.isAdmin();
            model.data = rfrSvc.data;

            //get npcs that could be this review year and has these programs
            var reviewYear = model.data.rfr.reviewYear;
            var programIds = [];
            var commissionIds = [];
            model.data.rfr.rfrCommissionDtos.forEach(function (x) {
                commissionIds.push({ codeKey: x.commissionId, codeName: x.commissionName });
            });

            npcSvc.getNpcProgramChangesForRfr(model.data.rfr.rfrId).then(function (data) {
                model.npcData = data.reduce((npcData, program) => {
                    const commissionId = program.commissionId;
                    npcData[commissionId] = npcData[commissionId] || [];
                    npcData[commissionId].push(program);
                    return npcData;
                }, []);
            });

            model.npcRfrs;
            model.hideBtns = rfrSvc.data.rfr.rfrProcessStatusId == rfrProcessStatuses.ABETACCEPTED || (rfrSvc.data.rfr.rfrProcessStatusId == rfrProcessStatuses.CANCELLED);
        }();

    };

    module.controller('rfrProgramInformationCtrl', rfrProgramInformationCtrl);

}(angular.module('rfr')));