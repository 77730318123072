(function (module) {

    var personUserGroupsAndRolesEditCtrl = function ($q, $scope, $uibModalInstance, roleSvc, organizationSvc, organizationTypes, membershipSvc, userId, personName, eventSvc, alertSvc, helperSvc, contactRoleTypes) {

        var model = $scope;
        var parent = $scope.$parent;
        var getFilteredArray = helperSvc.getFilteredArray;
        var excludedRoles = [contactRoleTypes.PROGRAMEVALUATOR,
                             contactRoleTypes.PROGRAMOBSERVER,
                             contactRoleTypes.TEAMCHAIR,
                             contactRoleTypes.COTEAMCHAIR,
                             contactRoleTypes.EDITOR1,
                             contactRoleTypes.EDITOR2,
                             contactRoleTypes.VISITOBSERVER,
                             contactRoleTypes.MEMBERPROGRAMEVALUATOR,
                             contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE];

        model.title = 'User Groups and Roles';
        model.userId = userId;
        model.personName = personName;
        model.orgData = {};
        model.isInsertMode = false;
        model.showHistory = false;
        //needs a . because of angular scoping issues with primitives for the quicksearch
        model.selectedOrg = {};
        model.selectedOrg.sortName = "";
        model.roles = undefined;
        model.designations = undefined;
        model.selectedRoleItem = undefined;
        model.selectedDesignationIds = undefined;
        model.selectedOrganizationId = 0;
        model.selectedOrganizationName = "";
        model.selectedDesignationNames = "";
        model.positionTerms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        model.newModel = {};
        
        getMembershipList(userId);

        model.getHistoricalRoles = function (userId) {
            model.showHistory = true;
            getMembershipList(model.userId, model.showHistory);
        }

        model.hideHistoricalRoles = function () {
            model.showHistory = false;
            getMembershipList(model.userId, model.showHistory);
        }

        model.searchOrganization = function (val) {
            if (val.length < 3)
                return;

            var promise = organizationSvc.getOrganizationsByName(val).then(function (data) {
                return data.value;
            });

            return promise;
        }

        model.onSelectOrganization = function ($item) {
            model.selectedOrganizationName = $item.organizationName;
            model.selectedOrganizationId = $item.organizationId;
            model.selectedOrganizationTypeId = $item.organizationTypeId;
            var societies = null;
            var roles = roleSvc.getRolesByOrganizationType($item.organizationTypeName);
            if (model.isSocietyPosition()) {
                societies = organizationSvc.getOrganizationsByType([organizationTypes.ASSOCIATESOCIETY, organizationTypes.MEMBERSOCIETY]);
            }

            $q.all([roles, societies]).then(function (data) {          
                roles = data.shift();
                societies = data.shift();

                if (roles) {
                    roles = helperSvc.getValue(roles);
                    model.roles = getFilteredArray(roles, "roleId", excludedRoles);
                }
                if (societies) {
                    model.societies = helperSvc.getValue(societies.data);
                }

            });
        }

        model.onSelectRole = function () {
            model.newModel.designationTypeName = getDesignationTypeName(model.newModel.roleId);
            model.newModel.chosenDesignations = [];
            getDesignations();
        }

        model.edit = function (userRole) {
            model.isInsertMode = true;
            model.isEditMode = true;
            var newEdit = angular.copy(userRole);
            resetNewRole();

            model.selectedOrganizationId = newEdit.organizationId;
            model.selectedOrg.sortName = newEdit.organizationName;
            model.selectedOrganizationTypeId = newEdit.organizationTypeId;

            var roles = roleSvc.getRolesByOrganizationType(userRole.organizationTypeName);
            var societies = null;

            if (model.isSocietyPosition()) {
                societies = organizationSvc.getOrganizationsByType([organizationTypes.ASSOCIATESOCIETY, organizationTypes.MEMBERSOCIETY]);
            }

            $q.all([roles, societies]).then(function (data) {
                roles = data.shift();
                societies = data.shift();

                if (roles) {
                    roles = helperSvc.getValue(roles);
                    model.roles = getFilteredArray(roles, "roleId", excludedRoles);
                    getDesignations();
                }
                if (societies) {
                    model.societies = helperSvc.getValue(societies.data);
                }

                model.newModel = {
                    organizationUserId: newEdit.organizationUserId,
                    isRoleOnly: newEdit.isRoleOnly,
                    selectedUserId: newEdit.userId,
                    roleId: newEdit.roleId,
                    designationTypeName: newEdit.designationTypeName,
                    chosenDesignations: newEdit.designationViewModels,
                    startDate: newEdit.startDate ? new Date(newEdit.startDate) : undefined,
                    endDate: newEdit.endDate ? new Date(newEdit.endDate) : undefined,
                    note: newEdit.note,
                    positionTerm: newEdit.positionTerm,
                    society: newEdit.societyId,
                    replacedOrganizationUserId: newEdit.replacedOrganizationUserId
                }

                getDesignations();

            });
                     
        }

        model.setDesignationSelection = function (id) {

            var index = model.selectedDesignationIds.indexOf(id);

            if (index > -1) {
                model.selectedDesignationIds.splice(index,1);
            } else {
                model.selectedDesignationIds.push(id);
            }

            return false;
        }

        model.isChecked = function (id) {
            if ((model.selectedDesignationIds !== undefined) && (model.selectedDesignationIds.indexOf(id) > -1)) {
                return 'fa fa-check pull-right';
            }
            return false;
        };

        model.addNew = function () {
            model.isInsertMode = true;
            resetNewRole();
        };


        model.closeInsert = function() {
            reset();
        };

        model.formatDate = function (date) {
            if (date == null)
                return '?';

            var dateOut = new Date(date);
            return dateOut;
        };

        var refreshUserGroup = function () {
            eventSvc.broadcast('refreshUserGroup');
        };

        model.saveRow = function () {           
            model.isInsertMode = true;

            var newDesignations = [];

            for (var i = 0; i < model.newModel.chosenDesignations.length; i++) {
                newDesignations.push({ organizationUserId: 0, designationId: model.newModel.chosenDesignations[i].designationId });
            }

            var newRole = {
                organizationUserId: model.newModel.organizationUserId,
                organizationId: model.selectedOrganizationId,
                userId: model.userId,
                roleId: model.newModel.roleId,
                startDate: model.newModel.startDate,
                endDate: model.newModel.endDate,
                isRoleOnly: model.newModel.isRoleOnly,
                note: model.newModel.note,
                positionTerm: model.newModel.positionTerm,
                societyId: model.newModel.society ? model.newModel.society : null,
                replacedOrganizationUserId: model.newModel.replacedOrganizationUserId,
                organizationUserDesignationDtos: newDesignations,
                isDeleted: false
            };

            if (model.isEditMode) {
                membershipSvc.update(newRole).then(function (data) {
                    getMembershipList(model.userId, model.showHistory);
                    reset();
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully updated.");
                });
            } else {
                membershipSvc.insert(newRole).then(function (data) {
                    getMembershipList(model.userId, model.showHistory);
                    reset();
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully created.");
                });
            }


        };

        model.deleteRow = function (role) {
            alertSvc.confirmDelete(role.organizationName, deleteFunc);

            function deleteFunc() {
                membershipSvc.delete(role.organizationUserId).then(function (data) {
                    getMembershipList(model.userId, model.showHistory);
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully deleted.");
                });
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        }

        model.isSocietyPosition = function()
        {
            if (model.selectedOrganizationTypeId == organizationTypes.BOARDOFDIRECTORS || model.selectedOrganizationTypeId == organizationTypes.COMMISSION || model.selectedOrganizationTypeId == organizationTypes.COMMITTEE)
                return true;
            return false;
        }

        function getDesignationTypeName(roleId) {
            if (model.roles) {
                for (var i = 0; i < model.roles.length; i++) {
                    if (model.roles[i].roleId === roleId) {
                        return model.roles[i].designationTypeName;
                    }
                }
            }
        }

        function getMembershipList(userId, showHistory) {
            membershipSvc.getByUserId(userId, !showHistory).then(function (data) {
                var userRoles = helperSvc.getValue(data);
                model.userRoles = getFilteredArray(userRoles, "roleId", excludedRoles);
                //model.userRoles = helperSvc.getValue(data);
            });
        };

        function reset() {
            model.selectedDesignationIds = undefined;
            model.selectedRoleItem = undefined;
            model.isInsertMode = false;
            model.isEditMode = false;
            model.designations = [];
            model.selectedOrg.sortName = "";
            model.selectedOrganizationTypeId = null;
            model.otherOptionsAvailable = false;
            model.societies = [];
            resetNewRole();
        }

        function getDesignations() {
            if (model.newModel.designationTypeName != null) {
                roleSvc.getDesignationsByTypeName(model.newModel.designationTypeName, model.selectedOrganizationId).then(function (data) {
                    model.designations = data.value;
                });
            } else {
                model.designations = [];
                model.newModel.chosenDesignations = [];
            }
        }

        function resetNewRole() {
            model.orgData = {};
            model.selectedOrg.sortName = "";
            model.newModel = {
                organizationUserId: 0,
                isRoleOnly: false,
                selectedUserId: null,
                roleId: null,
                designationTypeName: null,
                chosenDesignations: [],
                startDate: null,
                endDate: null,
                note: null,
                replacedOrganizationUserId: null
            }
        }
    };

    module.controller('personUserGroupsAndRolesEditCtrl', personUserGroupsAndRolesEditCtrl);

}(angular.module('person')));