'use strict';

(function (module) {
    var usernameEditCtrl = function (userId, $uibModalInstance, personUserSvc, eventSvc, alertSvc) {
        var model = this;

        model.title = 'Reset Username';
        model.controllerName = 'usernameEditCtrl';

        personUserSvc.getUser(userId).then(function (data) {

            model.user = data;
        });

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            model.user.userName = model.newUserName;

            personUserSvc.updateUser(model.user).then(function (data) {
                eventSvc.broadcast('loadPersonGeneral');
                alertSvc.addAlertSuccess("Username successfully updated.");
                $uibModalInstance.close();
            });
        };
    };

    module.controller('usernameEditCtrl', usernameEditCtrl);

}(angular.module('person')));