(function (module) {

    var organizationDetailsCtrl = function ($stateParams, $scope, $location, $q, organizationMdl, reviewTeamSvc, organizationRecognitionSvc, noteSvc, noteTypes,
        organizationCommissionSvc, organizationSvc, typeConstSvc, barSvc, eventSvc, helperSvc, membershipSvc, organizationHistorySvc, programSvc, contactRoleTypes,
        documentCategories, documentSvc, $uibModal, documentTypes) {

        var model = this;
        var getFilteredArray = helperSvc.getFilteredArray;

        model.title = null;
        model.organization = null;
        model.organizationType = null;
        model.organizationId = parseInt($stateParams.organizationId);
        model.documentCategories = documentCategories;
        model.noteTypes = noteTypes;
        model.isDataReady = false;
       
        model.documentTypes = documentTypes;

        var organizationTypes = typeConstSvc.getOrganizationTypes();
        var templateRoot = 'Apps/organization/templates/';

        model.organizationTemplates = {
            institution: templateRoot + 'institutionDetail.html',
            default: templateRoot + 'organizationDefaultDetail.html'
        };

        barSvc.showBar();

        eventSvc.listen(refreshOrganizationNotes, 'refreshOrganizationNotes', $scope);

        model.deleteOrganization = function (organizationId) {
            organizationSvc.delete(organizationId).then(function (data) {
                $location.path('/organization');
                alertSvc.addAlertSuccess("Organization successfully deleted.");
            });
        };

        var generalDataSource = {
            dataHolder: organizationSvc,
            dataLocationName: 'data.organization',
            svcCallback: organizationSvc.getOrganizationById,
            svcCallbackArguments: [model.organizationId, noStoredData = true],
            odataResource: true
        }

        membershipSvc.data.excludedRoles = [contactRoleTypes.PROGRAMEVALUATOR,
                                     contactRoleTypes.PROGRAMOBSERVER,
                                     contactRoleTypes.TEAMCHAIR,
                                     contactRoleTypes.COTEAMCHAIR,
                                     contactRoleTypes.EDITOR1,
                                     contactRoleTypes.EDITOR2,
                                     contactRoleTypes.VISITOBSERVER,
                                     contactRoleTypes.MEMBERPROGRAMEVALUATOR,
                                     contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE,
                                     contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATEINACTIVE,
                                     contactRoleTypes.READINESSREVIEWER,
                                     contactRoleTypes.NPCREVIEWER,
                                     contactRoleTypes.NPCTEAMCHAIR,
                                     contactRoleTypes.NPCEXCOMEDITOR
                                     ];

        var userGroupsAndRolesDataSource = {
            dataHolder: model,
            dataLocationName: 'userGroupsAndRoles',
            svcCallback: membershipSvc.getByOrganizationId,
            svcCallbackArguments: [model.organizationId, true]
        }

        var organizationHistoryDataSource = {
            dataHolder: organizationHistorySvc,
            dataLocationName: 'data.historyInfo',
            svcCallback: organizationHistorySvc.getOrganizationHistoryById,
            svcCallbackArguments: [model.organizationId, noStoredData = true],
            odataResource: true
        }

        var currentProgramsDataSource = {
            dataHolder: model,
            dataLocationName: 'currentPrograms',
            svcCallback: programSvc.searchProgramsByOrganizationId,
            svcCallbackArguments: [model.organizationId, true]
        }

        var commissionsAcctAndNgrYearDataSource = {
            dataHolder: organizationCommissionSvc,
            dataLocationName: 'data.organizationCommissions',
            svcCallback: organizationCommissionSvc.getCommissionsByOrganizationId,
            svcCallbackArguments: [model.organizationId, noStoredData = true],
            odataResource: true
        }

        var currentReviewDataSource = {
            dataHolder: model,
            dataLocationName: 'reviews',
            svcCallback: reviewTeamSvc.searchCurrentReviewTeamsByOrganizationId,
            svcCallbackArguments: [model.organizationId]
        }

        var regionalAgenciesDataSource = {
            dataHolder: organizationRecognitionSvc,
            dataLocationName: 'data.recognizingAgencies',
            svcCallback: organizationRecognitionSvc.getRecognitionByOrganizationId,
            svcCallbackArguments: [model.organizationId, noStoredData = true],
            odataResource: true
        }

        var notesDataSource = {
            dataHolder: model,
            dataLocationName: 'noteList',
            svcCallback: noteSvc.getNotesByReferenceId,
            svcCallbackArguments: [model.organizationId, noteTypes.ORGANIZATION],
            odataResource: true
        }

        function refreshOrganizationNotes() {
            var dataSourceArray = [
                notesDataSource
            ];

            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadNotes();
            });
        }
        
        function broadcastLoadNotes() {
            eventSvc.broadcast('loadOrganizationNotes');
        }

        initialOrganizationLoad();
        function initialOrganizationLoad() {
            var dataSourceArray = [
                generalDataSource
            ];

            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                organizationSvc.data.organizationMdl = new organizationMdl(organizationSvc.data.organization, true);
                model.data = organizationSvc.data;
                model.organization = organizationSvc.data.organization;
                model.organizationType = typeConstSvc.getOrganizationName(model.data.organization.organizationTypeId);

                activate();
            });

        }

        var documentsDataSource = {
            dataHolder: documentSvc,
            dataLocationName: 'data.documents',
            helperCallback: helperSvc.getValue,
            svcCallback: documentSvc.getDocumentsByCategoryForEntity,
            svcCallbackArguments: [documentCategories.ORGANIZATION, model.organizationId, noStoredData = true]
        };

        function activate() {

            var dataSourceArray = [
                userGroupsAndRolesDataSource,
                organizationHistoryDataSource
            ];

            if (typeConstSvc.getOrganizationId(model.organizationType) === organizationTypes.INSTITUTION) {
                dataSourceArray.push(currentProgramsDataSource);
                dataSourceArray.push(commissionsAcctAndNgrYearDataSource);
                dataSourceArray.push(currentReviewDataSource);
                dataSourceArray.push(regionalAgenciesDataSource);
                dataSourceArray.push(notesDataSource);
                dataSourceArray.push(documentsDataSource);
            } 

            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadNotes();

                model.isDataReady = true;
                barSvc.hideBar();
            });
        }
    };

    module.controller('organizationDetailsCtrl', organizationDetailsCtrl);

}(angular.module('organization')));