(function (module) {

    var changeCommissionCtrl = function ($scope, $uibModalInstance, searchOptions, alertSvc, trainingSvc, pevc, commissionTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Change Commission';
        model.pevc = pevc;
        model.data = { changedCommission: null, commissionOptions: commissionTypes }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.data.changedCommission === null)
                return alertSvc.addAlertWarning("You must select a commission or cancel.")

            var changeText = "You are about to change this pevc's commission.";
            alertSvc.confirm(changeText, confirm);

            function confirm() {
                model.pevc.commissionId = parseInt(model.data.changedCommission);

                trainingSvc.updateTrainingSession(model.pevc).then(function () {
                    trainingSvc.getPEVCTrainingSearch(searchOptions);
                    alertSvc.addAlertSuccess("Pev commission successfully changed.")
                    $uibModalInstance.close();
                });

            }
        }
    };

    module.controller('changeCommissionCtrl', changeCommissionCtrl);

}(angular.module('training')));