(function (module) {

    var roleSvc = function ($http, $q, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/GetRoles()';
        var factory = {};

         var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getRolesByOrganizationType = function (typeName) {
            // odata/GetRoles()?$filter=organizationTypeName eq  'Institution'&$select=roleName
            return $http.get(apiPath + "?$filter=organizationTypeName eq '" + typeName + "'" + "&$select=roleName,roleId,designationTypeName").then(onSuccess, onFailure);
        };
        factory.getDesignationsByTypeName = function (typeName, id) {
            id = (id) ? id : 0;
            // odata/GetDesignations(typeName = 'Commission') -- by designationTypeName
            return $http.get(amsConst.webApiUrl + "/odata/GetDesignations(typeName = '" + typeName + "', id = " + id + ")").then(onSuccess, onFailure);
        };


        factory.getRolesByOrganizationTypeId = function (type) {

            return $http.get(amsConst.webApiUrl + "/odata/GetRolesByOrganization(organizationTypeId = " + type + ")").then(onSuccess, onFailure);

        }

        return {
            getRolesByOrganizationType: factory.getRolesByOrganizationType,
            getDesignationsByTypeName: factory.getDesignationsByTypeName,
            getRolesByOrganizationTypeId: factory.getRolesByOrganizationTypeId

        };
    };
    module.factory('roleSvc', roleSvc);

})(angular.module('common'));