(function (module) {

    var programReviewCtrl = function ($scope, $uibModal, $http, helperSvc, codeSvc, eventSvc, programReviewSvc, reviewTeamSvc, alertSvc) {
        var model = this;
        model.parent = $scope.$parent.model;
        model.data = model.parent.data;
        model.title = 'Current and Most Recent Reviews';

        model.editReview = function (review) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/programReviewEdit.html',
                size: 'lg',
                controller: 'programReviewEditCtrl',
                resolve: {
                    review: function () {
                        return review;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.deleteReview = function (review) {
            var recordName = model.getYearSpan(review.reviewTeamDto.reviewDto.reviewYear) + ' ' + review.programReviewTypeName;

            alertSvc.confirmDelete(recordName, deleteFunc);

            function deleteFunc() {
                programReviewSvc.delete(review.programReviewId).then(function () {
                    eventSvc.broadcast('refreshSelectedProgramData');
                    alertSvc.addAlertSuccess("Program review successfully deleted.");
                });
            }
        };

        // TO BE REPLACED WITH MOREBUTTONSVC
        model.clicked = false;
        model.expandBtnDisabled = false;

        model.numOfReviews = function (clicked) {
            // return a value or undefined to limitTo filter -- an undefined limit will display all records
            var defaultNum = 2;
            return (clicked) ? void defaultNum : defaultNum;
        };

        model.toggleExpandBtn = function (clicked) {
            var numOfHiddenReviews = 0;
            if (model.data.reviews) {
                if (model.data.reviews.length > 1) {
                    numOfHiddenReviews = model.data.reviews.length - 2;
                }
            }
            model.expandBtnDisabled = (numOfHiddenReviews === 0) ? true : false;
            return (clicked) ? "Less" :  "More (" + numOfHiddenReviews + ")";
        };

        model.getYearSpan = helperSvc.getYearSpan;

        //load values for use in programReviewEditCtrl
        reviewTeamSvc.searchAllReviewTeamsByOrganizationId(model.parent.organizationId).then(function (data) {
            model.reviewOptions = helperSvc.getResults(data);
        });

        codeSvc.getDistanceLearningTypes().then(function (data) {
            model.distanceLearningTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getDisciplineTypes().then(function (data) {
            model.disciplineTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getMemberSocieties().then(function (data) {
            model.memberSocietyOptions = helperSvc.getResults(data);
        });

        codeSvc.getActions().then(function (data) {
            model.actionOptions = helperSvc.getResults(data);
        });

        codeSvc.getProgramReviewTypes().then(function (data) {
            model.programReviewTypeOptions = helperSvc.getResults(data);
        });
        //end values for use in programReviewEditCtrl
    };

    module.controller('programReviewCtrl', programReviewCtrl);

}(angular.module('program')));