(function (module) {

    var otherContactsCtrl = function ($scope, $uibModal, contactRoleTypes, currentOrganization, organizationTypes) {
        var model = this;

        model.parent = $scope.$parent.$parent.model;
        model.isPreviewMode = $scope.$parent.$parent.$parent.$parent.isPreviewMode; //Quick fix, should pass in
        model.title = 'Other Contacts';
        model.otherTitle = 'Other Contacts';
        model.liasonTitle = 'Liaison Contacts';
        model.type = 'other';
        model.helpText = {
            other: 'Other contacts may include, as examples, institution staff whom the institution requires to be informed of elements of ABET interaction, like assistants to primary and secondary contacts and the institution’s Registrar.',
            //liaison: '',
            //other: ''
        }

        model.editOtherContacts = function (isCreateMode, isReplaceMode, currentContact, editView, type, title) {
            var roleId = null;
            var organizationTypeId = currentOrganization.profile.organizationTypeId;

            if (organizationTypeId === organizationTypes.INSTITUTION) {
                roleId = contactRoleTypes.STAFF;
            }
            else {
                if (title === model.title) {
                    roleId = contactRoleTypes.STAFF;
                }
                else if (title === model.liasonTitle) {
                    switch (organizationTypeId) {
                        case organizationTypes.MEMBERSOCIETY:
                            roleId = contactRoleTypes.EDUCATIONLIASON;
                            break;
                        //IS THIS REALLY WHAT HWAN WANTS? CAN COMBINE CASES IF SO
                        case organizationTypes.ASSOCIATESOCIETY:
                            roleId = contactRoleTypes.EDUCATIONLIASON;
                            break;
                        case organizationTypes.STATEBOARD:
                            roleId = contactRoleTypes.STATEBOARDLIAISON;
                            break;
                        default:

                    }
                }
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/contacts/templates/contactsEdit.html',
                size: 'lg',
                controller: 'contactsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    contacts: function () {
                        return model.parent.otherContacts;
                    },
                    roleId: function () {
                        return roleId;
                    },
                    isCreateMode: function () {
                        return isCreateMode;
                    },
                    isReplaceMode: function () {
                        return isReplaceMode;
                    },
                    currentContact: function () {
                        return currentContact;
                    },
                    editView: function () {
                        return editView;
                    },
                    type: function () {
                        return model.type;
                    }
                }
            });
        };
    };

    module.controller('otherContactsCtrl', otherContactsCtrl);

}(angular.module('contacts')));