(function (module) {

    var professionalLicenseCtrl = function ($scope, $uibModal, professionalLicenseSvc, helperSvc, volunteerHelperSvc, pevAppSvc, componentHelperSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Professional License & Certification';
        model.addSpace = helperSvc.addSpace;
        var checkIsVolunteer = true;
        model.applicationMode = pevAppSvc.isApplicationMode(checkIsVolunteer);
        model.isButtonHidden = model.applicationMode ? pevAppSvc.isEditButtonHiddenForGeneralModules() : pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;



        function returnFalse() {
            return false;
        } 

        if (model.applicationMode) {
            model.data = pevAppSvc.volunteerLicenseData;
        } else {
            model.data = professionalLicenseSvc.data;
        }

        componentHelperSvc.invokeOnRendered('professionalLicense', $scope);

        model.editProfessionalLicense = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/professionalLicenseEdit.html',
                size: 'lg',
                controller: 'professionalLicenseEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('professionalLicenseCtrl', professionalLicenseCtrl);

}(angular.module('volunteer')));