(function (module) {

    var editProgramAuditDetailSummaryCtrl = function ($scope, $uibModalInstance, program, programAuditDetail, shortcomingTypeCodes, commissionIds, alertSvc, programAuditDetailSvc) {
        var model = $scope;

        model.title = 'Edit Program Audit Summary';
        model.program = program;
        model.programAuditDetail = angular.copy(programAuditDetail);
        model.auditSummary = angular.copy(model.programAuditDetail.programAuditJson.auditSummary);

        model.isValid = function () {
            // Form is valid if changed and either no preivous findings exist or at least one finding is entered.
            if (model.auditSummary.noPreviousFindings)
                return !model.programAuditDetail.programAuditJson.auditSummary.noPreviousFindings;

            const results = getAuditSummaryCriteria().reduce((results, criterion, index) => {
                const originalValue = model.programAuditDetail.programAuditJson.auditSummary.criteria[index].previousFindingTypes.join();
                const newValue = criterion.previousFindingTypes.join();
                results.isDirty = results.isDirty || originalValue !== newValue;
                results.hasPreviousFindings = results.hasPreviousFindings || newValue !== '';
                return results;
            }, { "isDirty": false, "hasPreviousFindings": false });

            return results.isDirty && results.hasPreviousFindings;
        }

        model.save = function () {
            model.programAuditDetail.programAuditJson.auditSummary.noPreviousFindings = !!model.auditSummary.noPreviousFindings;
            model.programAuditDetail.programAuditJson.auditSummary.criteria = getAuditSummaryCriteria();

            // Note: should do CRUD from modal opener but want to avoid UI flicker and stay consistent with statement editor
            model.isSaving = true;
            programAuditDetailSvc.update(model.programAuditDetail).then((programAuditDetail) => {
                alertSvc.addAlertSuccess('Program audit summary saved.');
                $uibModalInstance.close(programAuditDetail);
            }).catch(error => {
                console.log(error);
                model.isSaving = false;
                alertSvc.addModalAlertWarning('Saving program audit summary failed.')
            });
        }

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        activate();

        function activate() {
            model.shortcomingTypes = Object.keys(shortcomingTypeCodes).map(key =>
                ({
                    codeKey: shortcomingTypeCodes[key],
                    codeName: key.charAt(0) + key.slice(1).toLowerCase()
                })  
            );
            model.allowMultipleShortcomings = model.program.commissionId === commissionIds.CAC;
            model.auditSummary.criteria.forEach(criterion => {
                if (model.allowMultipleShortcomings) {
                    criterion.previousFindingTypes = criterion.previousFindingTypes.map(previousFindingType =>
                        ({ "codeKey": previousFindingType })
                    );
                } else {
                    criterion.mostSevereFindingType = criterion.previousFindingTypes.length ?
                        criterion.previousFindingTypes[0] :
                        null;
                }
            });
        }

        function getAuditSummaryCriteria() {
            return model.auditSummary.criteria.map(editedCriterion => {
                const criterion = angular.copy(editedCriterion);
                if (model.auditSummary.noPreviousFindings) 
                    criterion.previousFindingTypes = [];
                else if (model.allowMultipleShortcomings)
                    criterion.previousFindingTypes = criterion.previousFindingTypes.map(previousFindingType =>
                        previousFindingType.codeKey
                    ).sort((a, b) => {
                        const indexA = model.shortcomingTypes.findIndex(shortcoming => shortcoming.codeKey === a);    
                        const indexB = model.shortcomingTypes.findIndex(shortcoming => shortcoming.codeKey === b);    
                        return indexA < indexB ? -1 : indexA > indexB ? 1 : 0;
                    });
                else
                    criterion.previousFindingTypes = criterion.mostSevereFindingType && [criterion.mostSevereFindingType] || [];
                    
                delete criterion.mostSevereFindingType;

                return criterion;
            });
        }
    };

    module.controller('editProgramAuditDetailSummaryCtrl', editProgramAuditDetailSummaryCtrl);

}(angular.module('programAudit')));