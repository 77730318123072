(function (module) {

    /* PDF directives includes the following:
		- render-pdf
        - generate-embedded-pdf
        - download-embedded-pdf
    */

    var templateRoot = '/apps/common/templates/misc/';

    // Render a PDF document inline (for display or printing)
    module.directive('renderPdf', function (documentSvc, pdfSvc, $timeout) {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div id="pdf" class="print-pdf"></div>',
            scope: {
                streamId: '@'
            },
            link: function (scope, element, attrs, ctrl) {
                var documentUrl = documentSvc.getFileUrl(scope.streamId);

                doRenderPdf(documentUrl);

                var doRenderPdf = function (url) {
                    pdfSvc.renderPDF(url, document.getElementById('pdf')); // element);
                }
            }
        }
    });

    //generates a pdf using pdfmake and displays it in an iframe, if IE pdf will automatically download
    module.directive('generateEmbeddedPdf', function (documentSvc, pdfSvc, $timeout, $sce, helperSvc) {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: templateRoot + 'convertToPdf.html',
            scope: {
                documentDefinition: '=',
                onLoadCallback: '&?',
                defaultFilename: '@?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.iframeReady = false;
                scope.isIE = helperSvc.isIE();

                var loadUrl = function (url) {
                    scope.url = $sce.trustAsResourceUrl(url);
                    scope.iframeReady = true;
                    scope.$apply();
                    if (scope.onLoadCallback) scope.onLoadCallback();
                }

                if (scope.isIE) {
                    pdfSvc.downloadPDF(scope.documentDefinition, null, scope.onLoadCallback, scope.defaultFilename || 'download.pdf');
                } else {
                    pdfSvc.generatePDFDataUrl(scope.documentDefinition, null, loadUrl);
                }
            }
        }
    });

    // Download a PDF blob and display inline inside an iframe. Download if IE.
    module.directive('downloadEmbeddedPdf', function (documentSvc, pdfSvc, $timeout, $sce, helperSvc) {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: templateRoot + 'convertToPdf.html',
            scope: {
                streamId: '@',
                onLoadCallback: '&?',
                defaultFilename: '@?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.iframeReady = false;
                scope.isIE = helperSvc.isIE();

                var loadUrl = function (url) {
                    scope.url = $sce.trustAsResourceUrl(url);
                    scope.iframeReady = true;
                    scope.$apply();
                    if (scope.onLoadCallback) scope.onLoadCallback();
                }

                function activate() {
                    if (!scope.streamId) return;

                    documentSvc.downloadDocumentBlob(scope.streamId).then(function (data) {
                        if (scope.isIE) {
                            // download PDF and save
                            navigator.msSaveBlob(data.blob, data.filename || scope.defaultFilename || 'download.pdf');
                            if (scope.onLoadCallback) scope.onLoadCallback();
                        } else {
                            // download PDF and convert to dataUrl
                            var reader = new FileReader();

                            reader.addEventListener("load", function () {
                                loadUrl(reader.result);
                            }, false);

                            reader.readAsDataURL(data.blob);
                        }
                    });
                }

                activate();
            }
        }
    });

}(angular.module('common')));