(function (module) {

    var personMdl = function () {
        var nameFields = ['namePrefix', 'firstName', 'middleName', 'lastName', 'nameSuffix', 'professionalSuffix'];
        var telephoneFields = ['telephoneNumber', 'extension'];
        var emailFields = ['emailAddress'];
        var userFields = ['userName'];

        function personMdl(data) {
            this.data = data;
        }

        var addressFieldsUS = ['addressTitle', 'address1', 'address2', 'address3', 'city', 'stateCode', 'postalCode'];
        var addressFieldsIntl = ['addressTitle', 'address1', 'address2', 'address3', 'city', 'province', 'postalCode', 'countryName'];

        function getAddressFields(address) {
            return address.countryCode === "US" ? addressFieldsUS : addressFieldsIntl;
        }

        personMdl.prototype.getName = function () {
            //need to change
            return this.data.firstName + " " + this.data.lastName;
        };

        personMdl.prototype.getFullName = function () {
            var fullName = '';

            return fullName.buildString(this.data, nameFields);
        };

        personMdl.prototype.getNamePrefix = function () {
            if (this.data.namePrefix) {
                return propOrEmpty(this.data.namePrefix);
            }
            else {
                return '';
            }
        };

        personMdl.prototype.getNameSuffix = function () {
            if (this.data.nameSuffix) {
                return propOrEmpty(this.data.nameSuffix);
            }
            else {
                return '';
            }
        };

        personMdl.prototype.getProfessionalSuffix = function () {
            if (this.data.professionalSuffix) {
                return propOrEmpty(this.data.professionalSuffix);
            }
            else {
                return '';
            }
        };

        personMdl.prototype.getEmail = function () {
            var defaultText = 'No email address';

            return getReadableProperty(defaultText, this.data, emailFields);
        };

        personMdl.prototype.getAddress = function () {
            var defaultText = 'No address';
            var addressFields = getAddressFields(this.data);
            return getReadableProperty(defaultText, this.data, addressFields);
        };

        personMdl.prototype.getTelephone = function () {
            var defaultText = 'No telephone';

            return getReadableProperty(defaultText, this.data, telephoneFields);
        };

        var getArray = function (context, name, excludeNullContactTypeIds) {
            var results = [];
            var array = context.data[name];

            if (array) {
                if (array.length > 0) {
                    if (excludeNullContactTypeIds === true) {
                        for (var i = 0; i < array.length; i++) {
                            var entry = array[i];

                            if (entry.contactTypeId !== null) {
                                results.push(entry);
                            }
                        }
                    }
                    else {
                        results = array;
                    }
                }
            }

            return results;
        };

        personMdl.prototype.getEmailAddresses = function (excludeNullContactTypeIds) {
            return getArray(this, 'personEmailDtos', excludeNullContactTypeIds);
        };

        personMdl.prototype.getTelephones = function (excludeNullContactTypeIds) {
            return getArray(this, 'personTelephoneDtos', excludeNullContactTypeIds);
        };

        personMdl.prototype.getAddresses = function (excludeNullContactTypeIds) {
            return getArray(this, 'personAddressDtos', excludeNullContactTypeIds);
        };

        personMdl.prototype.getAddressText = function (address) {
            var addressText = '';

            if (address) {
                var addressFields = getAddressFields(address);
                addressText = addressText.buildString(address, addressFields);
            }

            return addressText;
        };

        personMdl.prototype.getUserName = function () {
            var userName = 'No user name';

            if (this.data.userPersonDtos) {

                if (this.data.userPersonDtos.length > 0) {
                    var userPersonsDtos = this.data.userPersonDtos;

                    for (var i = 0; i < userPersonsDtos.length; i++) {
                        userName = userName.buildString(userPersonsDtos[i], userFields);
                    }
                }
            }

            return userName;
        };

        //returns text, or combined string of properties
        var getReadableProperty = function (text, data, fieldArray) {
            var output = text;
            var builtString = output.buildString(data, fieldArray);

            if (builtString) {
                output = builtString;
            }

            return output;
        };

        return personMdl;
    };

    module.factory('personMdl', personMdl);

}(angular.module('common')));
