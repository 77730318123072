(function (module) {

    var contactsEditCtrl = function ($stateParams, $state, $scope, $uibModalInstance, parent, contacts, roleId, isCreateMode, isReplaceMode, currentContact, type, editView, currentUser,
                                     helperSvc, contactSvc, eventSvc, alertSvc, contactRoleTypes, designationTypes, commissionTypes, rfrSvc, programSvc, codeSvc, telephoneTypes, addressTypes,
                                     oauth, rfrValidationSvc, currentUserHelper, currentOrganization, roleSvc, organizationTypes, contactTypes, commissionIndexes, taskTypes) {
        var model = $scope;
        var user = currentUser.profile;
        var organizationId = parseInt($stateParams.organizationId) || null;
        var organizationTypeId = currentOrganization.profile.organizationTypeId;
        var newProgramContactAdded = false;
        var INSTITUTIONCONTACTONLY = contactTypes.INSTITUTIONCONTACTONLY;
        var hasContactLimit = (roleId === contactRoleTypes.PRIMARY || roleId === contactRoleTypes.SECONDARY || roleId === contactRoleTypes.BILLING) ? true : false;
        var allCommissionOptions = null;

        model.newContactTypeId = null;
        model.parent = parent;

        if (roleId === contactRoleTypes.EDUCATIONLIASON || roleId === contactRoleTypes.STATEBOARDLIAISON)
            model.title = (isCreateMode) ? 'Add ' + parent.liasonTitle : currentContact.firstName + ' ' + currentContact.lastName;
        else
            model.title = (isCreateMode) ? 'Add ' + parent.title : currentContact.firstName + ' ' + currentContact.lastName;

        model.isRfrMode = $state.current.name.indexOf('rfr') > -1 || $state.current.name.indexOf('rfe') > -1;
        model.roleId = roleId;
        model.isCreateMode = isCreateMode;
        model.isReplaceMode = isReplaceMode;
        model.isUpdateMode = !isCreateMode;
        model.isDelegate = false;
        model.delete = model.parent.parent.delete;

        model.contactContainer = {};
        model.selectedRoleContainer = {};
        model.contactContainer.currentContact = null;
        model.hasSearched = false;
        model.personSelected = false;
        model.noResults;
        model.excludedRoleOptions = contactSvc.data.excludedRoleOptions;
        model.commissionOptions = [];
        model.programOptions = [];
        model.roleOptions = [];
        model.errors = [];

        if (organizationTypeId === organizationTypes.INSTITUTION) {
            model.newContactTypeId = contactTypes.INSTITUTIONCONTACTONLY;
        }
        else if (organizationTypeId === organizationTypes.MEMBERSOCIETY || organizationTypeId === organizationTypes.ASSOCIATESOCIETY) {
            model.newContactTypeId = contactTypes.SOCIETYCONTACTONLY;
        }
        else if (organizationTypeId === organizationTypes.STATEBOARD) {
            model.newContactTypeId = contactTypes.ALLABETACTIVITIES;
        }

        if (contacts) {
            model.contacts = angular.copy(contacts);
        }

        if (editView) {
            model.editView = angular.copy(editView);
        }

        if (model.isCreateMode) {
            prepareDesignationInfo();
            getCommissionOptions();
            getRoleOptions();
            reset();
        }

        model.formElems = {
            SEARCH: 'search',
            COMMISSION: 'commission',
            PROGRAM: 'program',
            NAME: 'name',
            EMAIL: 'email',
            TELEPHONE: 'telephone',
            ADDRESS: 'address'
        };

        model.close = function (contact) {
            $uibModalInstance.close();
        };

        model.save = function (contact) {
            if (model.isCreateMode) contact = addContactDesignationAndOrgUserInfo(contact);

            if (contact && contact.personDto && contact.personDto.personAddressDto && contact.personDto.personAddressDto.hasOwnProperty("isOutsideUS")) delete contact.personDto.personAddressDto.isOutsideUS;

            if (newProgramContactAdded) {
                contactSvc.create(contact).then(function (personId) {
                    // For program contacts check if program is new (from rfr) or existing (from database). 
                    // An existing program wont have an Id of 0 or null
                    var updatedRfr = null;
                    for (var i = 0; i < model.selectedPrograms.length; i++) {
                        if (!model.selectedPrograms[i].programId || model.selectedPrograms[i].programId === 0) {
                            updatedRfr = rfrSvc.addNewProgramContact(personId, model.selectedPrograms[i], updatedRfr);
                        }
                    }
                    // Update RFE in one action to avoid optimistic concurrency errors in WebAPI caused by multiple, asynchronous calls from client.
                    if (updatedRfr !== null) {
                        rfrSvc.update(updatedRfr);
                    }
                    refreshAndClose('create');
                });
            }

            if (model.isCreateMode && !isReplaceMode && !newProgramContactAdded) {
                contactSvc.create(contact).then(function () {
                    refreshAndClose('create');
                });
            } else if (model.isUpdateMode && !newProgramContactAdded) {
                contact.personDto.userPersonDtos = [{
                    organizationUserDtos: [{
                        organizationId: contact.organizationId
                    }]
                }];

                contactSvc.update(contact).then(function () {
                    refreshAndClose('update');
                });
            } else if (isReplaceMode) {
                contactSvc.replace(contact, currentContact.organizationUserId).then(function () {
                    refreshAndClose('replace');
                })
            }

            if (model.isRfrMode) rfrValidationSvc.invokeValidation();
        };

        model.isVisible = function (elemName, viewName) {
            if (viewName === 'all') {
                return (
                    elemName === model.formElems.EMAIL ||
                    elemName === model.formElems.TELEPHONE ||
                    elemName === model.formElems.ADDRESS
                ) ? true : false;
            } else {
                return (!viewName) ? true : elemName === viewName;
            }
        };
        
        model.commissionRequired = function(roleId){
            if(roleId === contactRoleTypes.PEVASSIGNOR){
                model.hasCommissionSelection = true;
            }else{
                model.hasCommissionSelection = false;
                model.selectedCommissions = [];
            }
        }

        model.newSearch = function () {
            reset();
            eventSvc.broadcast("deletePersonSelectedName");
        };

        model.clearPerson = function () {
            reset();
            model.personSelected = true;
            //model.editView = 'all';
        };

        model.addNewPerson = function () {
            model.clearPerson();
        };

        model.updatePerson = function () {
            model.personSelected = true;
            //model.editView = 'all';
        };

        if (hasContactLimit) {
            $scope.$watchCollection('selectedCommissions', function (newSelections, oldSelections) {
                checkContactLimit();
            });
        }

        if (currentContact && model.isUpdateMode) {
            model.contactContainer.currentContact = angular.copy(currentContact);
            var personDto = model.contactContainer.currentContact.personDto;
            var personId = personDto.personId;

            var setupData = function (personDto, personId, property, callback) {
                if (!personDto[property]) {
                    var subDto = callback();
                    subDto.personId = personId;
                    personDto[property] = subDto;
                }
            };

            if (model.isVisible(model.formElems.TELEPHONE, editView)) {
                setupData(personDto, personId, 'personTelephoneDto', createPersonTelephoneDto);
            }

            if (model.isVisible(model.formElems.EMAIL, editView)) {
                setupData(personDto, personId, 'personEmailDto', createPersonEmailDto);
            }

            if (model.isVisible(model.formElems.ADDRESS, editView)) {
                setupData(personDto, personId, 'personAddressDto', createPersonAddressDto);
            }
        }

        function checkContactLimit() {
            if (hasContactLimit) {
                model.errors = [];
                var contacts = model.contacts;
                var commissionContacts = [ [], [], [], [] ];
                var contactLimit = 2;
                var ANSAC_INDEX = commissionIndexes.ANSAC;
                var CAC_INDEX = commissionIndexes.CAC;
                var EAC_INDEX = commissionIndexes.EAC;
                var ETAC_INDEX = commissionIndexes.ETAC;

                //group contacts by commissions into commissionContacts array
                for (var i = 0; i < contacts.length; i++) {
                    var commContact = contacts[i];

                    if (commContact.endDate) {
                        continue;
                    }

                    //only use commission contacts
                    if (commContact.designationTypeId === designationTypes.COMMISSION) {
                        var designations = commContact.designationViewModels;

                        //identify designation of contact and add to matching group
                        for (var j = 0; j < designations.length; j++) {
                            var currentDesignation = designations[j];
                            var currentDesignationId = currentDesignation.designationId;
                            var index = null;

                            switch (currentDesignationId) {
                                case commissionTypes[ANSAC_INDEX].id:
                                    index = ANSAC_INDEX;
                                    break;
                                case commissionTypes[CAC_INDEX].id:
                                    index = CAC_INDEX;
                                    break;
                                case commissionTypes[EAC_INDEX].id:
                                    index = EAC_INDEX;
                                    break;
                                case commissionTypes[ETAC_INDEX].id:
                                    index = ETAC_INDEX;
                                    break;
                                default:
                                    //do nothing, error condition?
                            }

                            commissionContacts[index].push(commContact);
                        }
                    }
                }

                //check grouped contacts to determine if new contact will exceed the limit for a group
                for (var i = 0; i < commissionContacts.length; i++) {
                    var currentCommission = commissionContacts[i];
                    var isCurrCommSelected = isCommissionSelected(commissionTypes[i].id);
                    var isCurrContactFound = (model.isReplaceMode) ? isContactFound(currentCommission, currentContact) : isContactFound(currentCommission, model.contactContainer.currentContact);
                    var contactOffset = (isCurrContactFound) ? -1 : 0;      //if contact is already on a commission count will not be increasing, use -1 otherwise 0
                    var commissionOffset = (isCurrCommSelected) ? 1 : 0;    //if commission is selected count will be increasing, use 1 otherwise 0
                    var oldCount = currentCommission.length;
                    var newCount = null;
                    var isCountIncreasing = null;

                    //calculate the projected number of contacts
                    if (!model.isReplaceMode) {
                        //if we are not replacing a contact we are adding one, and so the projected number of contacts increases by one
                        newCount = currentCommission.length + commissionOffset;
                    }
                    else if (model.isReplaceMode) {
                        //if we are replacing a contact the projected number depends on if the contact being replaced is within the current group, so I use offsets to account for that
                        newCount = currentCommission.length + contactOffset + commissionOffset;
                    }

                    //determine if the number of contacts on a commission is increasing
                    isCountIncreasing = (newCount > oldCount) ? true : false;

                    //when number of contacts is over the limit and trying to add yet another contact, determine if error message should be added
                    if (newCount > contactLimit && isCurrCommSelected) {
                        var addError = false;

                        //if we are in add mode add error
                        if (!model.isReplaceMode) {
                            addError = true;
                        }

                        //if we are in replace mode and the total number of contacts would increase add error
                        //i.e. replacing legacy entries that exceed contact limit is allowed, but adding another contact past the limit is not
                        if (model.isReplaceMode && isCountIncreasing) {
                            addError = true;
                        }

                        if (addError) {
                            model.errors.push(commissionTypes[i].abrv + " can only have 2 primary, 2 secondary, and 2 billing contacts.");
                        }
                    }
                }
            }
        }

        function isContactFound(arr, contact) {
            var found = false;

            for (var i = 0; i < arr.length; i++) {
                var currentContact = arr[i];
                if (currentContact.personId === contact.personId) {
                    found = true;
                }
            }

            return found;
        }

        function isCommissionSelected(commissionId) {
            var commissions = model.selectedCommissions;
            if (commissions) {
                for (var k = 0; k < commissions.length; k++) {
                    var commission = commissions[k];

                    if (commission.codeKey === commissionId) {
                        return true;
                    }
                }

                return false;
            }
        }

        function refreshAndClose (type) {
            broadcastRefreshMsg();
            alertSvc.addAlertSuccess('Contact successfully ' + type + 'd.');
            if (model.isRfrMode) {
                rfrValidationSvc.invokeValidation();
            }
            model.close();
        }

        function getCommissionOptions() {
            if (oauth.isAdmin() || oauth.hasTask(taskTypes.CONTACTS)) {
                codeSvc.getCommissions().then(function (data) {
                    var commissions = helperSvc.getResults(data);

                    for (var i = 0; i < commissions.length; i++) {
                        var commission = commissions[i];

                        model.commissionOptions.push({
                            codeKey: commission.codeKey,
                            codeName: commission.codeName
                        });
                    }
                });
            }
            else {
                for (var i = 0; i < user.userRoles.length; i++) {

                    if (user.userRoles[i].organizationId === organizationId && user.userRoles[i].designationTypeId === designationTypes.COMMISSION) {

                        for (var j = 0; j < user.userRoles[i].oAuthOrganizationUserDesignations.length; j++) {

                            for (var k = 0; k < commissionTypes.length; k++) {

                                if (commissionTypes[k].id === user.userRoles[i].oAuthOrganizationUserDesignations[j].designationId) {

                                    var newitem = {
                                        codeKey: commissionTypes[k].id,
                                        codeName: commissionTypes[k].abrv
                                    };

                                    if (model.commissionOptions.filter(function (e) { return e.codeKey === newitem.codeKey; }).length === 0) {
                                        model.commissionOptions.push(newitem);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (oauth.hasRole(organizationId, contactRoleTypes.DELEGATEFORPRIMARY)) {
                for (var i = 0; i < commissionTypes.length; i++) {
                    if (helperSvc.arrContains(currentOrganization.profile.delegateCommissions, commissionTypes[i].id)) {

                        var newitem = {
                            codeKey: commissionTypes[i].id,
                            codeName: commissionTypes[i].abrv
                        };

                        if (model.commissionOptions.filter(function (e) { return e.codeKey === newitem.codeKey; }).length === 0) {
                            model.commissionOptions.push(newitem);
                        }
                    }
                }
            }
        }

        function getRoleOptions() {
            roleSvc.getRolesByOrganizationTypeId(organizationTypeId).then(function (data) {
                data = helperSvc.getResults(data);
                var roles = [];

                for (var i = 0; i < data.length; i++) {
                    var role = data[i];

                    if (!(helperSvc.arrContains(model.excludedRoleOptions, role.roleId)) && role.roleId !== contactRoleTypes.EDUCATIONLIASON && role.roleId !== contactRoleTypes.STATEBOARDLIAISON) {
                        roles.push(role);
                    }
                }

                model.roleOptions = roles;
            });
        }

        function prepareDesignationInfo() {

            if (model.isCreateMode && currentContact && !isReplaceMode) {
                model.isDelegate = true;

                if (model.roleId === contactRoleTypes.PRIMARY) {
                    model.roleId = contactRoleTypes.DELEGATEFORPRIMARY;
                } else if (model.roleId === contactRoleTypes.PRESIDENT) {
                    model.roleId = contactRoleTypes.DELEGATEFORPRESIDENT;
                }
            }

            if (model.roleId === contactRoleTypes.PROGRAM) {
                model.hasProgramSelection = true;
                model.selectedPrograms = [];

                if (model.isRfrMode) {
                    model.programOptions = rfrSvc.getAllPrograms();
                    model.programOptions.forEach(e => e.programDisplayName=e.programName);
                } else {
                    programSvc.getProgramsByOrganizationId($stateParams.organizationId, true).then(function (data) {
                        model.programOptions = data.value.map(program => ({
                            ...program,
                            programDisplayName: data.value.some(p => p.programId !== program.programId && p.programName === program.programName) ?
                                `${program.programName} (${program.degreeCode})` :
                                program.programName
                        })).sort((a, b) => {
                            let nameA = `${a.programName} (${a.degreeCode})`;
                            let nameB = `${b.programName} (${b.degreeCode})`;
                            return nameA < nameB ? -1 : (nameA === nameB ? 0 : 1);
                        });
                    });
                }
            } else if (model.roleId === contactRoleTypes.PRIMARY || model.roleId === contactRoleTypes.SECONDARY || model.roleId === contactRoleTypes.BILLING) {
                model.hasCommissionSelection = true;
                model.selectedCommissions = [];
            }

            if (model.roleId === contactRoleTypes.STAFF) {
                model.hasRoleSelection = true;
            }
        }

        function addContactDesignationAndOrgUserInfo(contact) {
            var roleId = model.selectedRoleContainer.roleId || model.roleId;

            contact.userPersonDtos[0].personId = contact.personId;
            contact.userPersonDtos[0].organizationUserDtos.push({
                organizationUserId: 0,
                organizationId: model.parent.parent.organizationId,
                userId: contact.userPersonDtos[0].userId,
                roleId: roleId
            });

            if (model.isCreateMode && model.hasProgramSelection) {
                for (var i = 0; i < model.selectedPrograms.length; i++) {
                    if (!model.selectedPrograms[i].programId || model.selectedPrograms[i].programId === 0) {
                        setDesignations(contact, null)
                        newProgramContactAdded = true;
                    } else {
                        setDesignations(contact, model.selectedPrograms[i].programId);
                    }
                }
            } else if (model.isCreateMode && model.hasCommissionSelection) {

                setDesignations(contact, model.selectedCommissions);

            } else if (model.isCreateMode && model.isDelegate) {

                setDesignations(contact, currentContact.personId);
            }

            if (contact.personAddressDto.isOutsideUS !== undefined) {
                delete contact.personAddressDto.isOutsideUS;
            }

            return contact;
        }

        function setDesignations(contact, value) {
            if (Array.isArray(value)) {
                // Add commission designations
                for (var i = 0; i < value.length; i++) {
                    contact.userPersonDtos[0].organizationUserDesignationDtos.push({
                        organizationUserDesignationId: 0,
                        organizationUserId: 0,
                        designationId: value[i].codeKey,
                        isDeleted: false
                    });
                }
            } else {
                // Add program or delegate designation
                contact.userPersonDtos[0].organizationUserDesignationDtos.push({
                    organizationUserDesignationId: 0,
                    organizationUserId: 0,
                    designationId: value,
                    isDeleted: false
                });
            }
        }

        function reset() {
            model.hasSearched = false;
            model.personSelected = false;
            model.selectedPrograms = [];
            model.selectedCommissions = [];
            model.selectedRoleContainer = {};
            
            // contactContainer is necessary for currentContact
            // to be recognized by the person search directive
            model.contactContainer.currentContact = {
                personId: 0,
                isDeleted: false,
                firstName: "",
                lastName: "",
                middleName: "",
                namePrefixCode: null,
                nameSuffixCode: null,
                preferredName: null,
                professionalSuffixCode: null,
                userPersonDtos: [{
                    userPersonId: 0,
                    userId: 0,
                    personId: 0,
                    isDeleted: false,
                    organizationUserDtos: [],
                    organizationUserDesignationDtos: []
                }],
                personEmailDto: createPersonEmailDto(),
                personTelephoneDto: createPersonTelephoneDto(),
                personAddressDto: createPersonAddressDto()
            };
        }

        function createPersonEmailDto() {
            return {
                personEmailId: 0,
                personId: 0,
                emailId: 0,
                isDeleted: false,
                contactTypeId: model.newContactTypeId
            };
        }

        function createPersonTelephoneDto () {
            return {
                personTelephoneId: 0,
                personId: 0,
                telephoneId: 0,
                telephoneTypeId: telephoneTypes.WORK,
                isDeleted: false,
                contactTypeId: model.newContactTypeId
            };
        }

        function createPersonAddressDto() {
            return {
                personAddressId: 0,
                personId: 0,
                addressId: 0,
                addressTypeId: addressTypes.WORK,
                isDeleted: false,
                contactTypeId: model.newContactTypeId
            };
        }

        function broadcastRefreshMsg() {
            var broadcastMsg = model.parent.parent.generateContactBroadcastMsg(type);
            // generateContactBroadcastMsg will generate messages to create whichever of the following broadcasts is appropriate...
                // eventSvc.broadcast('refreshPrimaryContactsAndDelegates');
                // eventSvc.broadcast('refreshPresidentContactsAndDelegates');
                // eventSvc.broadcast('refreshProgramContacts');
                // eventSvc.broadcast('refreshSecondaryContacts');
                // eventSvc.broadcast('refreshOtherContacts');
            eventSvc.broadcast(broadcastMsg);
        };
    };

    module.controller('contactsEditCtrl', contactsEditCtrl);

}(angular.module('contacts')));