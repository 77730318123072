(function (module) {

    var changeSocietyCtrl = function ($scope, $uibModalInstance, searchOptions, alertSvc, trainingSvc, pevc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Change Training Society';
        model.pevc = pevc;
        model.data = { changedSociety: null }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.data.changedSociety === null)
                return alertSvc.addAlertWarning("You must select a society or cancel.")

            var changeText = "You are about to change this pevc's training society.";
            alertSvc.confirm(changeText, confirm);

            function confirm() {
                model.pevc.societyId = parseInt(model.data.changedSociety);
                var pevcDto = trainingSvc.convertTrainingPersonViewModelToDto(model.pevc);

                trainingSvc.updateTrainingSession(pevcDto).then(function () {
                    trainingSvc.getPEVCTrainingSearch(searchOptions);
                    alertSvc.addAlertSuccess("Pev society successfully changed.")
                    $uibModalInstance.close();
                });

            }
        }

        var activate = function () {
        }();
    };

    module.controller('changeSocietyCtrl', changeSocietyCtrl);

}(angular.module('training')));