(function (module) {

    var eventSvc = function ($rootScope) {
        var factory = {};

        factory.broadcast = function (message, ...data) {
            $rootScope.$broadcast(message, ...data);
        };

        factory.listen = function (callback, message, scope) {
            var unregisterFunc;
            if (scope) {
                unregisterFunc = scope.$on(message, callback);
            } else {
                unregisterFunc = $rootScope.$on(message, callback);
            };
            return unregisterFunc;
        };

        return {
            broadcast: factory.broadcast,
            listen: factory.listen
        };
    };
    module.service('eventSvc', eventSvc);
})(angular.module('common'));