(function (module) {

    var templateRoot = '/Apps/person/templates/';

    module.directive('personInfo', function ($q, $state, $stateParams, $uibModal, $uibModalStack, oauth, currentUser, personSvc, personUserSvc, alertSvc, helperSvc, eventSvc, volunteerSvc, documentSvc, personMediaSvc, componentHelperSvc, personMediaTypes) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personInfo.html',
            scope: {
                personId: '<?',
                preview: '@?',
                hideLogin: '@?',
                person: '=?',
                user: '<?', 
            },
            link: function (scope, elem, attrs) {

                var personId = null;

                scope.isAdmin = oauth.isAdmin();
                var isVolunteer = volunteerSvc.data.isVolunteer;

                scope.showGoToProfile = false;

                scope.options = {
                    preview: attrs.preview ? (attrs.preview.toLowerCase() == 'true') : false,
                    hideLogin: attrs.hideLogin ? (attrs.hideLogin.toLowerCase() == 'true') : false
                };

                scope.viewFile = function (mediaFile) {
                    documentSvc.downloadDocument(mediaFile.stream_id);
                };
                                
                scope.title = scope.options.preview ? 'General Information' : 'General';
                scope.getFormattedPhone = helperSvc.getFormattedPhone;
                scope.person = scope.person || {};
                scope.user = null;
                scope.isAdminOrThisUser = oauth.isAdmin() || scope.personId == currentUser.profile.personId

                // Set person data
                if (scope.personId) {
                    personId = scope.personId;
                    load();
                } else if (scope.person && scope.person.fullName) {
                    personId = scope.person.personId;
                } else {
                    personId = $stateParams.personId ? parseInt($stateParams.personId) : parseInt(currentUser.profile.personId);
                    load();
                }

                // Preview funcs

                if (scope.options.preview) {
                
                    scope.openEditPage = function () {
                        $uibModalStack.dismissAll();
                        var state = oauth.isAdmin() ? 'person.single' : 'profile';
                        $state.go(state, { personId: personId });
                    };
                    
                // Edit funcs

                } else {
                    loadPersonMedia();
                
                    scope.editPerson = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/person/templates/personEdit.html',
                            size: 'md',
                            controller: 'personEditCtrl',
                            resolve: {
                                id: function () { return scope.personId; },
                                parent: function () {  return scope.person; }
                            }
                        });
                    };
                    
                    scope.editAddress = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/person/templates/addressEdit.html',
                            size: 'lg',
                            controller: 'addressEditCtrl',
                            resolve: {
                                id: function () { return scope.personId; },
                                name: function () { return scope.person.fullName; }
                            }
                        });
                    };

                    scope.editTelephone = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/person/templates/telephoneEdit.html',
                            size: 'md',
                            controller: 'telephoneEditCtrl',
                            resolve: {
                                id: function () { return scope.personId; },
                                name: function () { return scope.person.fullName; }
                            }
                        });
                    };

                    scope.editEmail = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/person/templates/emailEdit.html',
                            size: 'md',
                            controller: 'emailEditCtrl',
                            resolve: {
                                id: function () { return scope.personId; },
                                name: function () { return scope.person.fullName; }
                            }
                        });
                    };

                    scope.editUsername = function () {
                        if (scope.user) {
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: '/Apps/person/templates/usernameEdit.html',
                                size: 'md',
                                controller: 'usernameEditCtrl',
                                //todovarner using controller as syntax so view can reference controller by name, cant get values otherwise. Fix this later?
                                controllerAs: 'ctrl',
                                resolve: {
                                    userId: function () { return scope.user.userId; }
                                }
                            });
                        }
                    };

                    scope.editPersonMedia = function (mediaFile, mediaType) {
                        if (scope.user) {
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: '/Apps/person/templates/personMediaEdit.html',
                                size: 'md',
                                controller: 'personMediaEditCtrl',
                                controllerAs: 'ctrl',
                                resolve: {
                                    personId: function () { return personId; },
                                    personMedia: function () { return mediaFile; },
                                    mediaTypeId: function () { return mediaType; }
                                }
                            });
                        }
                    };

                    scope.viewPersonMedia = function (personMedia) {
                        if (scope.user) {
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: '/Apps/person/templates/personMediaPreview.html',
                                size: 'md',
                                controller: 'personMediaViewCtrl',
                                controllerAs: 'model',
                                resolve: {
                                    personMedia: function () { return personMedia; }
                                }
                            });
                        }
                    };

                    scope.deletePersonMedia = function (personMedia) {

                        alertSvc.confirmDelete(personMedia.fileName, deleteFunc, personMedia);

                        function deleteFunc(mediaFile) {
                            personMediaSvc.delete(mediaFile).then(function (data) {
                                loadPersonMedia();
                                alertSvc.addModalAlertSuccess('File successfully removed.');
                            });
                        }
                    };

                    scope.editPassword = function () {
                        if (scope.user) {
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: '/Apps/person/templates/passwordEdit.html',
                                size: 'md',
                                controller: 'passwordEditCtrl',
                                controllerAs: 'ctrl',
                                resolve: {
                                    userId: function () { return scope.user.userId; }
                                }
                            });
                        }
                    };

                    scope.unlockUser = function () {
                        alertSvc.confirm("Are you sure you want to unlock this person's account?", unlock);

                        function unlock() {
                            if (scope.user) {
                                var personUpdatePromise = personUserSvc.updateUser;
                                var personPromise = personUserSvc.getUser(scope.user.userId).then(function (data) {
                                    var user = data;
                                    user.lockedOutTimestamp = null;
                                    return personUpdatePromise(user);
                                });

                                $q.all([personPromise]).then(function (data) {
                                    eventSvc.broadcast('refreshPersonUser');
                                    alertSvc.addAlertSuccess("User successfully unlocked.");
                                });
                            }
                        }
                    }
                }

                // Refreshes

                eventSvc.listen(loadPersonGeneral, 'loadPersonGeneral', scope);
                eventSvc.listen(loadPersonMedia, 'loadPersonMedia', scope);

                function load() {
                    scope.showSpinner = true;
                    scope.person = {};
                    personSvc.getPersonMdlById(personId, scope.person, true).then(function () {
                        
                        scope.showSpinner = false;
                        scope.showGoToProfile = scope.isAdmin || isVolunteer || false;
                        componentHelperSvc.invokeOnRendered('personInfo', scope);
                    });
                }

                function loadPersonGeneral() {
                    var id = scope.person.person ? scope.person.person.personId : scope.person.personId;
                    if (personId == id) {
                        load();
                    }
                }

                function loadPersonMedia() {
                    personMediaSvc.getPersonMediaByPersonId(personId).then(function (data) {
                        scope.personSignature = personMediaSvc.signature.data;
                        scope.personPhoto = personMediaSvc.photo.data;
                    });
                }
            }
        };
    });

    module.directive('personGeneral', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'general.html'

        };
    });

    module.directive('personGeneralDisplay', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'generalDisplay.html'

        };
    });

    module.directive('personVolunteerActivities', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'volunteerActivities.html'

        };
    });

    module.directive('personInstitutionView', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personInstitutionView.html'

        };
    });

    module.directive('personUserGroupsAndRoles', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'userGroupsAndRoles.html'

        };
    });

    module.directive('personName', function (codeSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personName.html',
            scope: {
                person: '='
            },
            link: function (scope) {
                codeSvc.getNamePrefixes().then(function (data) {
                    var prefixes = data.value;
                    scope.namePrefixes = prefixes.map(function (obj) { return obj.codeKey });
                });
                codeSvc.getNameSuffixes().then(function (data) {
                    var suffixes = data.value;
                    scope.nameSuffixes = suffixes.map(function (obj) { return obj.codeKey });
                });

                scope.person = scope.person || {};
            }
        }
    });

    module.directive('personEmail', function (helperSvc, personEmailSvc, codeSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personEmail.html',
            scope: {
                personId: '<',
                emails: '=',        // array of emailAddressDtos or the preffered emailAddressDto Object
                useCollection: '<', // to use the full array of emails or not. If set to false use the the preffered emailAddressDto object
                form: '=?'
            },
            link: function (scope) {
                var personId = scope.personId || null;
                var newEmail = {
                    personEmailId: 0,
                    personId: personId,
                    emailId: 0,
                    note: null,
                    isDeleted: false
                };

                if (scope.useCollection) {
                    scope.insertRow = function () {
                        scope.emails.push(newEmail);
                    };
                }

                if (scope.emails === undefined) {
                    scope.emails = [];
                    scope.emails.push(newEmail);
                }

                scope.changePrimary = function (email) {
                    angular.forEach(scope.emails, function (currentEmail) {
                        currentEmail.isPreferred = (currentEmail === email);
                    });
                };

                codeSvc.getContactTypes().then(function (data) {
                    scope.contactTypeOptions = data.value;
                });
            }
        };
    });

    module.directive('personEmailForm', function (personEmailSvc, codeSvc) {
        return {
            restrict: 'E',
            templateUrl: 'personEmailForm.html',
            scope: {
                email: '=',
                personId: "<",
                useCollection: '<?',
                form: '=?'
            },
            link: function (scope) {
                if (scope.useCollection) {
                    scope.deleteRow = function (email) {
                        email.isDeleted = true;
                    };
                }

                codeSvc.getContactTypes().then(function (data) {
                    scope.contactTypeOptions = data.value;
                });
            }
        };
    });

    module.directive('personTelephone', function (helperSvc, telephoneTypes, codeSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personTelephone.html',
            scope: {
                telephones: '=',
                personId: '<',
                useCollection: '<',
                hideAddBtn: '@?'
            },
            link: {
                pre: function (scope, element, attrs) {
                    if (scope.hideAddBtn) {
                        scope.hideAddBtn = (scope.hideAddBtn.toLowerCase() === 'true') ? true : false;
                    } else {
                        scope.hideAddBtn = false;
                    }
                },
                post: function (scope, element, attrs) {

                    if (scope.useCollection) {
                        scope.insertRow = function () {
                            var personId = (attrs.personId) ? scope.personId : null;
                            // console.log(attrs.personId, scope.personId, personId);
                            var newTelephone = {
                                personTelephoneId: 0,
                                personId: personId,
                                telephoneId: 0,
                                telephoneTypeId: 0,
                                note: null,
                                isDeleted: false
                            };

                            scope.telephones.push(newTelephone);
                        };
                    }
                    else {
                        scope.telephones.telephoneTypeId = telephoneTypes.WORK;
                    }

                    scope.changePrimary = function (telephone) {
                        angular.forEach(scope.telephones, function (currentTelephone) {
                            currentTelephone.isPreferred = (currentTelephone === telephone);
                        });
                    };

                    codeSvc.getContactTypes().then(function (data) {
                        scope.contactTypeOptions = data.value;
                    });
                }
            }
        };
    });

    module.directive('personTelephoneForm', function (codeSvc) {
        return {
            restrict: 'E',
            templateUrl: 'personTelephoneForm.html',
            scope: {
                telephone: '=',
                useCollection: '<?'
            },
            link: function (scope) {

                if (scope.useCollection) {
                    scope.deleteRow = function (telephone) {
                        telephone.isDeleted = true;
                    };
                    scope.changePrimary = scope.$parent.changePrimary;
                }

                codeSvc.getTelephoneTypes().then(function (data) {
                    scope.telephoneTypeList = data.value;
                });

                codeSvc.getContactTypes().then(function (data) {
                    scope.contactTypeOptions = data.value;
                });
            }
        };
    });

    module.directive('personAddress', function (helperSvc, addressTypes, codeSvc) {
        // The person-address directive does NOT currently
        // work with an addressDto formatted address
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personAddress.html',
            scope: {
                addresses: '=',
                personId: '<',
                useCollection: '<'
            },
            link: function (scope, element, attrs) {
                if (scope.useCollection) {
                    scope.insertRow = function () {
                        var personId = (attrs.personid) ? scope.personId : null;

                        var newAddress = {
                            personAddressId: 0,
                            personId: personId,
                            addressId: 0,
                            isDeleted: false
                        };

                        scope.addresses.push(newAddress);
                    };
                } else {
                    scope.addresses.isShipping = true;
                    scope.addresses.isMailing = true;
                    scope.addresses.addressTypeId = addressTypes.WORK;
                }

                scope.changePrimary = function (address) {
                    angular.forEach(scope.addresses, function (currentAddress) {
                        currentAddress.isPreferred = (currentAddress === address);
                    });
                };

                codeSvc.getContactTypes().then(function (data) {
                    scope.contactTypeOptions = data.value;
                });
            }
        };
    });

    module.directive('personAddressForm', function (codeSvc) {
        return {
            restrict: 'E',
            templateUrl: 'personAddressForm.html',
            scope: {
                address: '=',
                useCollection: '<?'
            },
            link: function (scope) {
                if (scope.useCollection) {
                    scope.deleteRow = function (address) {
                        address.isDeleted = true;
                    };
                }

                codeSvc.getAddressTypes().then(function (data) {
                    scope.addressTypeList = data.value;
                });

                scope.changeUS = function (address) {
                    if (address.isOutsideUS)
                        address.countryCode = '';
                    else
                        address.countryCode = 'US';
                };

                scope.changeCountry = function (address) {
                    if (address.countryCode == 'US') {
                        address.isOutsideUS = false;
                    }
                    else {
                        address.isOutsideUS = true;
                    }
                };

                codeSvc.getCountries().then(function (data) {
                    scope.countryOptions = data.value;
                });

                codeSvc.getStates().then(function (data) {
                    scope.stateOptions = data.value;
                });

                codeSvc.getContactTypes().then(function (data) {
                    scope.contactTypeOptions = data.value;
                });

                if (scope.address.countryCode)
                    scope.changeCountry(scope.address);
            }
        };
    });

    module.directive('personSearch', function (personSvc, helperSvc, eventSvc, contactTypes) {
        // person object must be nested at least one level for two way binding to work i.e. attribute in 
        // view should use person="model.personData.person" or person="personData.person"
        // hoping to find a way to avoid this in the future
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personSearch.html',
            require: '^?form',
            scope: {
                userId: '=?',
                personId: '=?',
                person: '=?',
                required: '@?',
                isPreferredMode: '=?',
                hasSearched: '=?',
                updateParent: '&?',
                noResults: '=?',
                noResultsMsg: '@?',
                noResultsFunc: '&?',
                onSelectFunc: '&?onSelect',
                preload: '@?',
                contactTypeId: '<?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.container = {};
                scope.hasExternalSelectedPersonName = attrs.selectedPersonName ? true : false;
                scope.noResults = false;

                var validId = function () {
                    var hasId = false;

                    if (scope.userId > 0) {
                        hasId = true;
                    }

                    if (scope.personId > 0) {
                        hasId = true;
                    }

                    return hasId;
                };

                var castToPreferredDto = function (obj, dtosArrayName, dtoObjectName, dtoObjectIdFieldName, dtoChildObjectIdFieldName) {
                    var dtosArray = obj[dtosArrayName];
                    var preferredObj = null;
                    var selectedContactTypeId = (scope.contactTypeId) ? scope.contactTypeId : contactTypes.INSTITUTIONCONTACTONLY;

                    for (var i = 0; i < dtosArray.length; i++) {
                        var current = dtosArray[i];
                        if (current.contactTypeId === contactTypes.ALLABETACTIVITIES || current.contactTypeId === selectedContactTypeId) {
                            preferredObj = current;
                        }
                    }

                    if (preferredObj === null) {
                        // Create an empty DTO 
                        preferredObj = {
                            personId: obj.personId,
                            isDeleted: false,
                            contactTypeId: selectedContactTypeId
                        };
                        preferredObj[dtoObjectIdFieldName] = 0;
                        preferredObj[dtoChildObjectIdFieldName] = 0;
                    }

                    delete obj[dtosArrayName];
                    obj[dtoObjectName] = preferredObj;
                };

                scope.onSelectPerson = function (item, model, label) {

                    personSvc.getPersonSummaryWithDesignation(model.personId).then(function (data) {
                        if (scope.updateParent != undefined) {
                            scope.updateParent();
                        }
                        scope.userId = model.userId;
                        scope.personId = model.personId;
                        //this has to remain here otherwise it triggers validity without being valid because of ID validation
                        scope.container.selectedPersonName = model.personName;

                        if (data) {
                            var personData = data;
                            delete personData['@odata.context'];

                            personData.userPersonDtos[0].organizationUserDtos = [];
                            personData.userPersonDtos[0].organizationUserDesignationDtos = [];

                            if (scope.isPreferredMode === true) {
                                castToPreferredDto(personData, 'personEmailDtos', 'personEmailDto', 'personEmailId', 'emailId');
                                castToPreferredDto(personData, 'personTelephoneDtos', 'personTelephoneDto', 'personTelephoneId', 'telephoneId');
                                castToPreferredDto(personData, 'personAddressDtos', 'personAddressDto', 'personAddressId', 'addressId');
                            }

                            scope.person = personData;
                            if (scope.onSelectFunc)
                                scope.onSelectFunc({ $event: { data: personData } });
                        }
                    });
                };

                scope.searchPerson = function (val) {
                    scope.resetIds();

                    if (val.length < 3) {
                        scope.hasSearched = false;
                        return;
                    }

                    return personSvc.quickSearch(val, true).then(function (data) {
                        scope.hasSearched = true;
                        var results = personSvc.castToPersonMdl(data);
                        scope.noResults = results.length > 0 ? false : true;

                        return results;
                    });
                };

                scope.isRequired = function () {
                    return scope.required === 'true';
                }

                scope.shouldPreload = function () {
                    return scope.preload === 'true';
                }

                scope.resetLocalSelectedPersonName = function () {
                    if ((attrs.userId && (scope.userId === null || scope.userId === undefined)) || (attrs.personId && (scope.personId === null || scope.personId === undefined))) {
                        scope.container.selectedPersonName = null;
                    }
                    if (scope.shouldPreload() && scope.person.personName) {
                        setPreloadedName();
                    }
                };

                scope.resetIds = function () {
                    if (scope.userId) {
                        scope.userId = -1;
                    }

                    if (scope.personId) {
                        scope.personId = -1;
                    }

                    scope.noResults = false;
                };

                scope.resetNoResults = function () {
                    if (!scope.container.selectedPersonName) {
                        scope.noResults = false;
                    }
                };

                function setPreloadedName() {
                    scope.container.selectedPersonName = scope.person.personName;
                }

                function updateLocalSelectedPersonName() {
                    scope.container.selectedPersonName = scope.externalSelectedPersonName;
                };

                if (scope.shouldPreload()) {
                    setPreloadedName();
                }

                scope.validate = function () {
                    if (scope.isRequired() && validId()) {
                        ctrl.$setValidity('personSearch', true);
                    }
                    else {
                        ctrl.$setValidity('personSearch', false);
                    }
                };

                if (ctrl && scope.isRequired()) {
                    scope.validate();
                    scope.$watch('container.selectedPersonName', scope.validate);
                }

                scope.$watchGroup(['personId', 'userId'], scope.resetLocalSelectedPersonName);
                scope.$watch('container.selectedPersonName', scope.resetNoResults);
                eventSvc.listen(updateLocalSelectedPersonName, "deletePersonSelectedName");
            }
        };
    });

    module.directive('personTypeahead', function (personSvc) {
        // person object must be nested at least one level for two way binding to work i.e. attribute in 
        // view should use person="model.personData.person" or person="personData.person"
        // hoping to find a way to avoid this in the future
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'personTypeahead.html',
            scope: {
                person: '=?',
                required: '@?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.container = {};
                scope.hasExternalSelectedPersonName = attrs.selectedPersonName ? true : false;
                scope.noResults = false;
                
                scope.searchPerson = function (val) {
                    return personSvc.quickSearch(val, true).then(function (data) {
                        var results = personSvc.castToPersonMdl(data);
                        
                        return results;
                    });
                };

                scope.onSelectPerson = function (item, model, label) {
                    scope.person = model;
                    scope.container.selectedPersonName = model.personName;
                };
                
                scope.container.selectedPersonName = scope.person.personName;
            }
        };
    });

    module.directive('awardsInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'awardsInfo.html',
            scope: {
                readonly: '@?'
            },
        };
    });
}(angular.module('person')));