(function (module) {
    'use strict';

   var messageSvc = function (odataSvc, $odata, helperSvc, currentUser, Upload, amsConst, personEmailSvc, $q, contactTypes) {
        var key = 'messageId';
        var factory = {};

        factory.getMessagesByPersonId = function (personId, limit, searchOptions) {
            var recipientPersonId = personId && !isNaN(personId) ? parseInt(personId) : currentUser.profile.personId;
            var messagesLimit = limit && !isNaN(limit) ? parseInt(limit) : 10;
            var searchText = searchOptions && searchOptions.searchText ?  searchOptions.searchText : null;

            var apiPath = '/GetMessagesByPersonId(personId=' + recipientPersonId + ',' +
                                                 'searchText=' + (searchText ? '\'' + searchText.replace('\'', '\'\'') + '\'' : 'null') + ')';

            // Add filters for search options
            var predicates = [];
            var combinedPredicate;
            var searchDateRange = getSearchDateRange(searchOptions);
            predicates.push(new $odata.Predicate('sentDateTime', 'ge', searchDateRange.startDate));
            if (searchDateRange.endDate) {
                predicates.push(new $odata.Predicate('sentDateTime', 'lt', searchDateRange.endDate));
            }
            combinedPredicate = $odata.Predicate.and(predicates);

            var oSvc = odataSvc.get();
            oSvc.getSource(apiPath, key).odata()
                .filter(combinedPredicate)
                .orderBy("sentDateTime", "desc")
                .take(messagesLimit)
                .expand("messageTemplateDto")
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
         }

        function getSearchDateRange(searchOptions) {           
            // Always limit to just messages from past year
            var startDate = getOneYearAgo();
            if (searchOptions && searchOptions.startDate) {
                var searchStartDate = new Date(searchOptions.startDate.toISOString());
                if (searchStartDate > startDate) {
                    startDate = searchStartDate;
                }
            }

            var endDate = searchOptions && searchOptions.endDate ? new Date(searchOptions.endDate.toISOString()) : null;
            if (endDate) {
                endDate.setDate(endDate.getDate() + 1);
            }

            return {
                startDate: startDate,
                endDate: endDate
            } 
        }
 
        function getOneYearAgo() {
            var oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
            oneYearAgo.setHours(0)
            oneYearAgo.setMinutes(0)
            oneYearAgo.setSeconds(0);
            return oneYearAgo;
        }

        //deprecated for old emailing system used by RFE
        factory.createMessage = function (messageTemplateId, subject, body, recipient, recipientCopy, messageParamaters) {
            var message = {
                messageId: 0,
                messageTemplateId: messageTemplateId,
                title: subject,
                body: body,
                recipient: recipient,
                recipientCopy: recipientCopy,
                messageParmeterDtos: messageParamaters
            };

            return message;
        };

        factory.sendInstantMessage = function (message) {
            var apiPath = '/SendInstantMessage';
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, message);
            resource.$save(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        factory.sendMessage = function (message) {
            var apiPath = '/SendMessage';
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, message);
            resource.$save(oSvc.onSuccess, oSvc.onFailure);
            console.log(resource);
            return oSvc.getDeferred().promise;
        }

        factory.enqueueMessage = function (message) {
            var apiPath = '/Message';
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, message);
            resource.$save(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        //recipients and ccRecipients could be personId array or email address array
        factory.buildAndSendMessage = function (primaryRecipients, ccdRecipients, messageTemplateId, messageParameters, emailContactType, body, title, attachmentParameters) {
            var hasAttachments = attachmentParameters ? true : false;

            var messageParamDtoArray = createMessageParameters(messageParameters);

            var message = createNewMessage(messageTemplateId, primaryRecipients, ccdRecipients, messageParamDtoArray, body, title);

            var promise = hasAttachments ? factory.sendMessageWithAttachment(message, attachmentParameters) : factory.sendMessage(message)
            return promise;
        }

        factory.sendMessageWithAttachment = function (message, document) {
            var messageJson = angular.toJson(message);
            var uploadOptions = {
                url: amsConst.webApiUrl + '/odata/SendMessageWithAttachment',
                data: {
                    messageJson: messageJson,
                    title: document.documentTitle ? document.documentTitle : null,
                    file: document.file
                }
            };

            return Upload.upload(uploadOptions);
        }


        //DEPRECATED BUT USED FOR SENDING EMAILS FROM THE RFE PROCESS
        //function to build recipient list based on personIds and message info
        //(personIds of Primary recipients, personIds of CCd people, templateId, Subject, body, messageParameters, emailContactType)
        //message parameters have to correlate to the template id && if there are none set to undefined
        factory.buildRecipientListAndSendMessages = function (primaryRecipients, ccdRecipients, messageTemplateId, subject, body, messageParameters, emailContactType) {
            var emailAddresses = [];
            var ccdAddresses = [];
            var promises = [];

            //build primary recipients email list
            for (var i = 0; i < primaryRecipients.length; i++) {
                var currentPersonId = Number(primaryRecipients[i]) ? Number(primaryRecipients[i]) : undefined;

                //should be actual email address passed in
                if (currentPersonId === undefined) {
                    if (!helperSvc.arrContains(emailAddresses, primaryRecipients[i])) {
                        emailAddresses.push(primaryRecipients[i]);
                    }

                } else {
                    var promise = personEmailSvc.getByPersonId(currentPersonId);

                    promises.push(promise);

                    promise.then(function (emailData) {
                        
                        var emailAddress = filterEmailsByContactType(emailData, emailContactType);

                        if (!helperSvc.arrContains(emailAddresses, emailAddress)) {
                            emailAddresses.push(emailAddress);
                        }
                    });
                }               
            }

            //build ccd recipients email list
            for (var i = 0; i < ccdRecipients.length; i++) {
                var currentPersonId = Number(ccdRecipients[i]) ? Number(ccdRecipients[i]) : undefined;

                //should be actual email address passed in
                if (currentPersonId === undefined) {
                    if (!helperSvc.arrContains(ccdAddresses, ccdRecipients[i])) {
                        ccdAddresses.push(ccdRecipients[i]);
                    }
                } else {
                    var promise = personEmailSvc.getByPersonId(currentPersonId);

                    promises.push(promise);

                    promise.then(function (emailData) {                       
                        var emailAddress = filterEmailsByContactType(emailData, emailContactType);

                        if (emailAddress !== '' && !helperSvc.arrContains(ccdAddresses, emailAddress)) {
                            ccdAddresses.push(emailAddress);
                        }
                    });
                }
            }

            //send email and show alert
            $q.all(promises).then(function () {
                var addresses = emailAddresses.join(',');
                var ccd = ccdAddresses.join(',');
                var message = factory.createMessage(
                        messageTemplateId,
                        subject, 
                        body,
                        addresses,
                        ccd,
                        messageParameters
                    );

                return factory.sendInstantMessage(message);
            });
        }


        function filterEmailsByContactType(emailData, emailContactType) {
            var emailAddress = "";

            if (emailData.length > 0) {
                var dto;

                //check for matching types
                if (emailContactType) {
                    for (var i = 0; i < emailData.length; i++) {
                        if (emailData[i].contactTypeId === emailContactType) {
                            dto = emailData[i];
                            break;
                        }
                    }
                }
                if (!dto) {
                    //if no matches for the contact type use global
                    for (var i = 0; i < emailData.length; i++) {
                        if (emailData[i].contactTypeId === contactTypes.ALLABETACTIVITIES) {
                            dto = emailData[i];
                            break;
                        }
                    }               
                } else {
                    //revert to whatever email is available... should almost never occur
                    dto = helperSvc.getFirstArrayItem(emailData);
                }

                if(!dto)
                    dto.emailAddress = '';

                emailAddress = dto.emailAddress;
            }           

            return emailAddress;
        }

        function createNewMessage(messageTemplateId, recipientPersonIds, ccdPersonIds, messageParamaters, body, title) {
            var recipientDtos = factory.createRecipientDtos(recipientPersonIds);
            var ccdRecipientDtos = factory.createRecipientDtos(ccdPersonIds);

            var messageDto = {
                messageId: 0,
                messageTemplateId: messageTemplateId,
                recipientDtos: recipientDtos,
                recipientCopyDtos: ccdRecipientDtos,
                messageParmeterDtos: messageParamaters,
                body: body,
                title: title
            };

            return messageDto;
        }

       //personIdArray allows email address being input
       factory.createRecipientDtos = function (personIdArray, optionalContactTypeId) {
            var recipientDtos = [];

            for (var i = 0; i < personIdArray.length; i++) {

                if (personIdArray[i])
                {
                    if (typeof personIdArray[i] === 'object') {
                        recipientDtos.push({
                            personId: personIdArray[i].personId || 0,
                            emailAddress: personIdArray[i].emailAddress || null,
                            contactTypeId: personIdArray[i].contactTypeId
                        });
                        continue;
                    }

                    var emailAddress = null;
                    var emailPersonId = 0;

                    if (!Number(personIdArray[i]))
                        emailAddress = personIdArray[i];
                    else
                        emailPersonId = personIdArray[i];

                    var recipientDto = {
                        personId: emailPersonId,
                        emailAddress: emailAddress,
                        contactTypeId: optionalContactTypeId
                    }
                    recipientDtos.push(recipientDto);
                }
            }
            return recipientDtos;
        }
        
        //takes in array of key value pairs
        function createMessageParameters(messageParameters) {
            var paramArray = [];

            angular.forEach(messageParameters, function (param) {
                var temp = {
                    messageParameterName: param.messageParameterName,
                    messageParameterKey: param.messageParameterKey || '',
                    messageParameterValue: param.messageParameterValue || ''
                }
                paramArray.push(temp);
            });
           
            return paramArray;
        }

        return {
            getMessagesByPersonId: factory.getMessagesByPersonId,
            createMessage: factory.createMessage,
            sendInstantMessage: factory.sendInstantMessage,
            sendMessage: factory.sendMessage,
            enqueueMessage: factory.enqueueMessage,
            buildRecipientListAndSendMessages: factory.buildRecipientListAndSendMessages,
            buildAndSendMessage: factory.buildAndSendMessage,
            createRecipientDtos: factory.createRecipientDtos
        };
    };

    module.factory('messageSvc', messageSvc);

})(angular.module('common'));