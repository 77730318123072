(function (module) {

    var organizationMdl = function () {
        var organizationMainFields = ['organizationId', 'organizationTypeId', 'organizationTypeName'];
        var organizationFields = ['organizationDetailId', 'organizationId', 'organizationName', 'sortName', 'alternateName', 'abbreviatedName', 'website', 'startDate', 'endDate', 'formerOrganizationId', 'isCurrent', 'formerOrganizationId', 'note'];
        var addressFields = ['addressId', 'address1', 'address2', 'address3', 'city', 'stateCode', 'province', 'postalCode', 'countryCode'];

        function organizationMdl(data, useDtos) {
            addProperties(this, organizationMainFields, data);

            //use dto if requested otherwise add properties directly to current object
            //need to clean this up, remove repeated condition checking
            if (useDtos) {
                this.useDtos = true;
                this.currentOrganizationAddressDto = {};
                this.currentOrganizationDetailDto = {};

                if (data.currentOrganizationAddressDto) {
                    this.currentOrganizationAddressDto = data.currentOrganizationAddressDto;
                }
                else {
                    insertAddress(this.currentOrganizationAddressDto, addressFields, data);
                }

                if (data.currentOrganizationDetailDto) {
                    addProperties(this.currentOrganizationDetailDto, organizationFields, data.currentOrganizationDetailDto);
                }
                else {
                    addProperties(this.currentOrganizationDetailDto, organizationFields, data);
                }
            }
            else {
                this.useDtos = false;

                if (data.currentOrganizationAddressDto) {
                    addProperties(this, addressFields, data.currentOrganizationAddressDto);
                }
                else {
                    addProperties(this, addressFields, data);
                }

                if (data.currentOrganizationDetailDto) {
                    addProperties(this, organizationFields, data.currentOrganizationDetailDto);
                }
                else {
                    addProperties(this, organizationFields, data);
                }
            }
        }

        //iterate over control array and add matching properties to object
        var addProperties = function (obj, fields, data) {
            for (var i = 0; i < fields.length; i++) {
                if (data[fields[i]]) {
                    obj[fields[i]] = data[fields[i]];
                }
            }
        };

        //iterate over control array and remove matching properties from object
        var removeProperties = function (obj, fields) {
            for (var i = 0; i < fields.length; i++) {
                if (obj[fields[i]]) {
                    delete obj[fields[i]];
                }
            }
        };

        //creates an address object and adds it to this objects address array
        var insertAddress = function (obj, fields, data) {
            var address = {};

            addProperties(address, fields, data);

            obj.currentOrganizationAddressDto = address;
        };

        organizationMdl.prototype.getAddress = function () {
            var defaultText = 'No address';
            //dont include ID field in output
            var shortAddresses = addressFields.slice(1, addressFields.length);

            if (this.useDtos) {
                return getReadableProperty(defaultText, this.currentOrganizationAddressDto, shortAddresses);
            }
            else {
                return getReadableProperty(defaultText, this, shortAddresses);
            }
        };

        //returns text, or combined string of properties
        var getReadableProperty = function (text, data, fieldArray) {
            var output = text;
            var builtString = output.buildString(data, fieldArray);

            if (builtString) {
                output = builtString;
            }

            return output;
        };

        //returns text, or combined string of properties from first item in array
        var getReadablePropertyArr = function (text, dataArr, fieldArr) {
            var output = text;

            if (dataArr && dataArr.length > 0) {
                output = getReadableProperty(text, dataArr[0], fieldArr);
            }

            return output;
        };

        return organizationMdl;
    };

    module.factory('organizationMdl', organizationMdl);

}(angular.module('organization')));
