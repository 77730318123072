(function (module) {

    var rrAccountNumberEditCtrl = function ($scope, $uibModalInstance, readinessReview, selectedCommission, alertSvc, readinessSvc, eventSvc) {
        var model = $scope;

        model.title = 'Edit Account Number';
        model.readinessReview = angular.copy(readinessReview);
        model.selectedCommission = angular.copy(selectedCommission);
       

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            var updatedReadiness = angular.copy(readinessReview);
            
            var commissionIndex = readinessReview.organizationJson.organizationCommissions.findIndex(function (record) {
                return record.commissionId === selectedCommission.commissionId;
            });

            updatedReadiness.organizationJson.organizationCommissions[commissionIndex] = model.selectedCommission;
               
            readinessSvc.updateAccountNumber(model.selectedCommission).then(function (data) {
                readinessSvc.update(updatedReadiness).then(function (data) {
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Account number successfully updated.");
                    eventSvc.broadcast('refreshReadinessData');
                });
            });
           
        };
    };

    module.controller('rrAccountNumberEditCtrl', rrAccountNumberEditCtrl);

}(angular.module('readinessReview')));