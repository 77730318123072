(function (module) {
    var npcManageStatusTabCtrl = function ($scope, $state, $uibModal, helperSvc, barSvc, alertSvc, npcSvc, eventSvc, npcStatusNames, npcStatusIds) {
        var model = this;

        model.parent = $scope.$parent.$parent.$parent.model;
        model.isDataReady = false;
        model.slug = 'status'; //pevAppSvc.slugs.SUBMIT;
        model.getColorClass = npcSvc.getColorClass;
        model.npcStatusNames = npcStatusNames;
        model.isSaving = false;

        model.accpetNPC = function (programChange) {
            model.isSaving = true;

            var now = new Date();
            
            var newProgressDto = {
                npcProgramChangeId: programChange.npcProgramChangeId,
                npcStatusId: npcStatusIds.ACCEPTEDNOTIFICATION,
                npcStatusUpdatedDate: now,
                note: ''
            }

            npcSvc.manageStatus(newProgressDto).then(function () {
                alertSvc.addAlertSuccess(`NPC Sucesfully Accepted.`);
                refreshData();

            }, function () {
                alertSvc.addAlertWarning('Status could not be updated at this time.');
                model.isSaving = false;
            });


        }


        model.manageStatus = function (program, programChange) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcManageStatus.html',
                size: 'md',
                controller: 'npcManageStatusModalCtrl',
                resolve: {
                    npc: () => model.npcData,
                    program: () => program,
                    programChange: () => programChange                   
                }
            });

            modalInstance.result.then(() => {
                refreshData();
               
            });
        }

        model.progressSorterFunc = function (progress) {
            return parseInt(progress.npcProgramChangeProgressId);
        };

        model.viewNote = function (progress) {
            alertSvc.information(progress.note, "View Note")
        }

        model.npcWaitingForAcceptance = function(programChange) {
                if(programChange.npcProgramChangeProgressDtos && programChange.npcProgramChangeProgressDtos.length > 0 &&
                    programChange.npcProgramChangeProgressDtos.sort((a, b) => a.npcStatusUpdatedDate > b.npcStatusUpdatedDate ? 1 : -1)[programChange.npcProgramChangeProgressDtos.length - 1].npcStatusId === npcStatusIds.NOTIFICATIONSUBMITTED)
                    return true;

            return false;
        }

        function refreshData() {
            eventSvc.broadcast('refreshNpcData');
            return npcSvc.getNPCById(model.npcData.npcId).then(data => {
                model.npcData = data;
                model.parent.npcData = data;
                model.parent.setUserAccess(true);
                model.isSaving = false;
            });
        }

        var activate = function () {
            model.npcData = model.parent.npcData.npc ? model.parent.npcData.npc : model.parent.npcData ;
            model.isDataReady = true;
           
        }();
    };
    module.controller('npcManageStatusTabCtrl', npcManageStatusTabCtrl);

}(angular.module('npc')));