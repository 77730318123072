(function (module) {

    var previewCtrl = function ($scope, $window, oauth, $http, $uibModalInstance, inparams, reportName) {
        var model = $scope;
        var urlAmsRptAPI = "/webapi/odata/RequestSsoAmsRpt";
        var urlAmsRptServer = "https://amsrpt.abet.org/";

        model.title = reportName;
        model.token = null;
        model.reportViewer = null;

        oauth.requestSso(urlAmsRptAPI).then(function (data) {
            if (data) {
                model.token = data.data;

                var viewer = GrapeCity.ActiveReports.Viewer(
                    {
                        element: '#viewerContainer',
                        report: {
                            id: reportName,
                            parameters: inparams
                        },
                        reportService: {
                            url: urlAmsRptServer + '/api/',
                            securityToken: model.token,
                            resourceHandler : urlAmsRptServer + 'cache/' 
                        },
                        uiType: 'desktop',
                        documentLoaded: function reportLoaded() {
                                                    },
                        reportLoaded: function (reportInfo) {
                        },
                        error: function (error) {
                            console.log("error");
                        }
                    });
            };
        });
 
        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('previewCtrl', previewCtrl);

}(angular.module('report')));