(function (module) {

    var otherConflictsCtrl = function ($scope, $uibModal, otherConflictSvc, volunteerDataAccessTypes, volunteerHelperSvc) {
        var model = this;
        var volunteerDataAccessType = volunteerDataAccessTypes.VOLUNTEERANDADMIN;

        model.allowEdit = volunteerHelperSvc.allowEdit(volunteerDataAccessType);
        model.parent = $scope.$parent.model;
        model.title = 'Other Conflicts of Interest';
        model.data = otherConflictSvc.data;
        model.tooltipText = "Includes <strong>other conflicts not given elsewhere in this profile</strong>, i.e.<ul><li>A relative attending a school</li><li>Having been turned down for a job at a school</li><li>Etc.</li></ul>Excludes:<ul><li>Education</li><li>Employment</li><li>Previous ABET visits</li></ul>";
        
        model.editOtherConflicts = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/otherConflictsEdit.html',
                size: 'lg',
                controller: 'otherConflictsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('otherConflictsCtrl', otherConflictsCtrl);

}(angular.module('volunteer')));