(function (module) {

    var travelWaiverModalCtrl = function ($scope, $stateParams, $uibModalInstance, alertSvc, helperSvc, userReviewSvc, review, travelWaiverPDFSvc, teamMemberStatusTypes) {
        var model = $scope;
        model.agreement = review.agreement; 
        model.existingAgreement = userReviewSvc.data.reviewTeamMemberTravelAgreement;
        model.agreementOnFile = !(!!model.existingAgreement);
        
        model.review = review; //
        model.volunteerId = $stateParams.volunteerId;
        model.title = 'Travel Waiver and Release Agreement';

        model.assignmentApproved = review.teamMemberStatusId == teamMemberStatusTypes.ASSIGNMENTAPPROVED;

        model.formatDate = helperSvc.formatDate;

        model.twoDaysBeforeStartDate = function() {
            var date = new Date(model.review.visitStartDate);
            return date - (1000 * 60 * 60 * 24 * 2);
        };

        model.save = function () {
            userReviewSvc.postTravelWaiver(review.reviewTeamMemberId, model.agreement.typedName).then(function () {
                alertSvc.addAlertSuccess('Travel waiver submitted, thank you.');
                $uibModalInstance.close('completed');
            });
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        var activate = function () {
            model.doc = travelWaiverPDFSvc.generateTravelWaiver(review, model.agreement);
        }();
    };

    module.controller('travelWaiverModalCtrl', travelWaiverModalCtrl);

}(angular.module('userReview')));
