(function (module) {

    var notesEditCtrl = function (parent, $scope, $uibModalInstance, noteSvc, eventSvc, alertSvc, typeConstSvc, helperSvc, currentUser, membershipSvc) {
        var model = $scope;
        var user = currentUser.profile;
        var organizationTypes = typeConstSvc.getOrganizationTypes();

        var societiesDataSource = {
            dataHolder: model,
            dataLocationName: 'societies',
            svcCallback: membershipSvc.getByUserIdWithoutDesignation,
            svcCallbackArguments: [user.userId, true], 
            odataResource: true
        };

        model.parent = parent;
        model.title = 'Edit ' + parent.title;
        model.notes;
        model.societies = [];
        model.defaultSocietyId = null;
        model.defaultSocietyName = null;

        model.showSocietySelection = function (note) {
            return (model.parent.limitToSociety && note.noteId === 0 && model.societies.length > 1);
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (note, isLastItem) {
            if (isLastItem) model.isBlankMode = true;
            note.isDeleted = true;
        };

        model.add = function () {
            var newNote = {
                noteId: 0,
                noteTypeId: model.parent.noteType,
                noteCategoryId: model.parent.noteCategory,
                referenceId: model.parent.referenceId,
                societyId: model.defaultSocietyId,
                societyName: model.defaultSocietyName,
                isDeleted: false
            };

            model.notes.push(newNote);
            model.isBlankMode = false;
        };

        model.save = function () {
            var updatedNotes = [];

            angular.forEach(model.notes, function (note) {
                // Treat updating a note by setting it to blank the same as a delete.
                note.noteText = note.noteText ? note.noteText.trim() : '';
                if (note.noteText.length === 0) {
                    note.isDeleted = true;
                };
                // Only send updates or new notes--no need to delete an unsaved note.
                if (note.noteId || !note.isDeleted) {
                    updatedNotes.push(note);
                };
            });

            model.notes = updatedNotes;
            
            noteSvc.saveNotes(model.notes).then(function () {
                broadcastRefreshMsg();
                model.close();
                alertSvc.addAlertSuccess("Notes successfully saved.");
            });
        };

        activate();

        function activate() {
            model.notes = angular.copy(model.parent.notes) || [];

            model.isBlankMode = (model.notes.length === 0);

            if (model.parent.limitToSociety) {
                var promise = getSocieties();
                promise.then(function () {
                    model.societies = helperSvc.getValue(model.societies);
                    model.societies = $.grep(model.societies, function (society) {
                        return society.organizationTypeId === organizationTypes.MEMBERSOCIETY;
                    });
                    model.societies = helperSvc.uniqueArrayByKey(model.societies, 'organizationId');

                    if (model.societies.length === 1) {
                        model.defaultSocietyId = model.societies[0].organizationId;
                        model.defaultSocietyName = model.societies[0].organizationName;
                    }

                    if (model.isBlankMode) {
                        model.add();
                    };
                });
            } else if (model.isBlankMode) {
                model.add();
            }
        }

        function broadcastRefreshMsg() {
            eventSvc.broadcast(model.parent.refreshName);
        }

        function getSocieties() {
            var dataSourceArray = [
                societiesDataSource
            ];

            return helperSvc.getData(dataSourceArray);
        }
    };

    module.controller('notesEditCtrl', notesEditCtrl);

}(angular.module('common')));