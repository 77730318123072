(function (module) {
    var personEditCtrl = function (id, parent, personSvc, $stateParams, $location, $scope, $uibModalInstance, eventSvc, alertSvc) {

        var model = $scope;
        model.currentPerson = {};
        model.personId = id || $stateParams.personId;

        if (id === 0) {
            model.title = "Create Person";
        }
        else {
            model.title = "Edit Person";
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            var person = model.currentPerson;

            if (person.personId === 0 || person.personId === undefined) {
                person.personId = 0;
                personSvc.create(person).then(function (data) {
                    model.currentPerson = {};
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Person successfully created.");
                });
            }
            else {
                personSvc.update(person).then(function (data) {
                    eventSvc.broadcast('loadPersonGeneral');
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Person successfully updated.");
                });
            }
        };

        activate();

        function activate() {
            if (model.personId != null && model.personId != 0) {
                personSvc.get(model.personId).then(function (data) {
                    model.currentPerson = data;
                });
            }

            var namePrefixes = personSvc.codes.namePrefixes;
            var nameSuffixes = personSvc.codes.nameSuffixes;

            model.namePrefixes = namePrefixes.map(function (obj) { return obj.codeKey });
            model.nameSuffixes = nameSuffixes.map(function (obj) { return obj.codeKey });
        }

        //function getPerson(id) {
        //    personSvc.get(id).then(function (data) {
        //        model.currentPerson = data;
        //    });
        //}
    };
    module.controller('personEditCtrl', personEditCtrl);
}(angular.module('person')));