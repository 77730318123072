(function (module) {

    var npcViewExcomMembersModalCtrl = function (npcData, latestGeneralReview, $scope, $q, $uibModalInstance, helperSvc, getOrganizationUserSvc) {
        var model = $scope;
        var excomRoles = [300, 301, 302, 305, 306, 307, 308];

        model.title = 'Excom Members';
        model.isDataReady = false;

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        function activate() {
            getOrganizationUserSvc.getExcomMembersWithConflicts(npcData.commissionId, excomRoles, latestGeneralReview[0].reviewTeamId).then(function (data) {
                model.excomMembers = helperSvc.getResults(data);
                model.isDataReady = true;
                console.log("model.excomMembers", model.excomMembers);
            })
        };

        activate();
    };
    module.controller('npcViewExcomMembersModalCtrl', npcViewExcomMembersModalCtrl);

}(angular.module('npc')));