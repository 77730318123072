(function (module) {

    var reviewJointOptionSvc = function ($http, $q, helperSvc, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/ReviewJointOption({0})';
        var programsPath = amsConst.webApiUrl + '/odata/GetProgramByReviewId({0})';
        var factory = {};
        var filterBase = '?$filter=';
        var expandBase = '&$expand=';

        var onSuccess = function (response) {
            if (response.status === 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getProgramsByReviewId = function (id) {
            return $http.get(programsPath.format('reviewId=' + id) + '?' + expandBase + 'programDetailDto').then(onSuccess, onFailure);
        };

        factory.create = function (newjointOption) {
            return $http.post(apiPath.format(null), newjointOption).then(onSuccess, onFailure);
        };

        factory.update = function (jointOption) {
            return $http.put(apiPath.format(jointOption.reviewJointOptionId), jointOption).then(onSuccess, onFailure);
        };

        factory.delete = function (jointOption) {
            return $http.delete(apiPath.format(jointOption.reviewJointOptionId)).then(onSuccess, onFailure);
        };

        var buildFilter = helperSvc.buildFilter;

        var buildReviewIdFilter = function (parameter) {
            return buildFilter("reviewId eq", parameter);
        };

        return {
            getProgramsByReviewId: factory.getProgramsByReviewId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };
    module.factory('reviewJointOptionSvc', reviewJointOptionSvc);

})(angular.module('review'));