(function (module) {

    var ckeditCommentsSvc = function (helperSvc, statementTemplateSvc, statementStorageSvc, $timeout) {

        var factory = {};

        //TODO: Make more generic
        factory.data = {
            statement: {},
            currentSection: {},
            selectedFindingType: {},
            selectedFinding: {},
            selectedFindingIndex: 0,
            comments: []
        };

        factory.setStatementData = function (statement) {
            factory.data.statement = statement;
        }

        factory.setDraftFinalStatementCommentData = function (data, comments) {
            factory.data.currentSection = data.currentSection;
            factory.data.selectedFindingType = data.selectedFindingType;
            factory.data.selectedFinding = data.selectedFinding;
            factory.data.selectedFindingIndex = data.selectedFindingIndex;
            //factory.setComments(comments);
        }

        factory.setComments = function (comments) {
            factory.data.comments = comments ? comments : [];
        }

        factory.addComment = function (newComment) {
            factory.data.comments.push(newComment);
        }

        var autosaving = false;

        var autoSave = function (finding, comment, saveFunc) {
            if (autosaving) $timeout.cancel(autosaving);

            if (!saveFunc) {
                var statement = angular.copy(statementStorageSvc.data.statement);
                statement.statementDetailDtos = angular.copy(statementStorageSvc.data.currentStatementDetailDtos);
                saveFunc = () => {
                    return statementStorageSvc.save(statement, true);
                };
            }

            statementStorageSvc.removeHighlightsByFinding(finding, comment);
            statementStorageSvc.highlightSingleComment(comment);

            autosaving = $timeout(function () {
                autosaving = null;
                finding.saveComplete = false;
                finding.threeSecondsElapsed = false;
                saveFunc().then(function (fg) {
                    return function () {
                        if (fg.threeSecondsElapsed) {
                            fg.isAutosaving = false;
                        } else {
                            fg.saveComplete = true;
                        }
                    }
                }(finding));

                $timeout(function (fg) {
                    return function () {
                        fg.threeSecondsElapsed = true;
                        if (fg.saveComplete) {
                            
                            fg.isAutosaving = false;
                        }
                    }
                }(finding), 0);

                finding.isAutosaving = true;

            }, 0);
        };

        factory.updateDraftFinalStatementComments = function (comment, text, saveFunc) {
            factory.data.selectedFinding.criteria.comments.push(comment);
            autoSave(factory.data.selectedFinding, comment, saveFunc);
            //statementStorageSvc.save()
            //statementStorageSvc.getStatementById(statementStorageSvc.data.statement.statementId).then(function () {

            //});
            //var statement = statementStorageSvc.data.statement;
            //console.log(statement);
            //var currentSectionIndex = statement.statementDetailDtos.findIndex(function (section) {
            //    return section.statementDetailId === factory.data.currentSection.statementDetailId;
            //});
            //var currentFindingTypeIndex = statement.statementDetailDtos[currentSectionIndex].statementJson.findIndex(function (findingType) {
            //    return findingType.statementFindingTypeId === factory.data.selectedFindingType.statementFindingTypeId;
            //});
            //var currentFindingIndex = statement.statementDetailDtos[currentSectionIndex].statementJson[currentFindingTypeIndex].findings.findIndex(function (finding) {
            //    if (factory.data.selectedFinding.key && factory.data.selectedFinding.key > 0) {
            //        return finding.key === factory.data.selectedFinding.key;
            //    } 
            //    if (factory.data.selectedFinding.criteria.criteriaId) {
            //        finding.criteria.criteriaId === factory.data.selectedFinding.criteria.criteriaId;
            //    }
            //    return true;
            //});

            //var currentFinding = statement.statementDetailDtos[currentSectionIndex].statementJson[currentFindingTypeIndex].findings[currentFindingIndex];
            ////if (!currentFinding.criteria.comments) currentFinding.criteria.comments = [];
            //currentFinding.criteria.comments.push(comment);
            //currentFinding.criteria.text = text;
            //statement.statementDetailDtos[currentSectionIndex].statementJson[currentFindingTypeIndex].findings[currentFindingIndex] = currentFinding;

            ////statementStorageSvc.save(statement).then(function () {
                
                
            ////});

            //statementStorageSvc.save(statement).then(function () {
            //    statementStorageSvc.data.statement = statement;

            //    statementStorageSvc.removeHighlightsByFinding(currentFinding, comment);

            //    $timeout(function () {
            //        statementStorageSvc.highlightSingleComment(comment);
            //    }, 100)
            //});
        }
        

        var activate = function () {
            

        }();

        

        return {
            data: factory.data,
            setDraftFinalStatementCommentData: factory.setDraftFinalStatementCommentData,
            setStatementData: factory.setStatementData,
            setComments: factory.setComments,
            addComment: factory.addComment,
            updateDraftFinalStatementComments: factory.updateDraftFinalStatementComments
        };
    };

    module.service('ckeditCommentsSvc', ckeditCommentsSvc);

})(angular.module('common'));