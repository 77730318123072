(function (module) {

    var teamChairsAndEditorsCtrl = function ($scope, $uibModal, helperSvc, reviewTeamMemberSvc, reviewTeamSvc, oauth, personSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Teamchairs and Editors';
        model.formatDate = helperSvc.formatDate;
        model.isEmpty = helperSvc.isEmpty;
        model.data = reviewTeamMemberSvc.data;
        model.reviewTeam = reviewTeamSvc.data;
        model.isAdmin = oauth.isAdmin();
        model.impersonate = personSvc.impersonateVolunteer;
        model.canImpersonate = oauth.canImpersonate();
        
        model.editTeamChairsAndEditors = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/teamChairsAndEditorsEdit.html',
                size: 'lg',
                controller: 'teamChairsAndEditorsEditCtrl',
                resolve: {
                    review: function () {
                        return model.reviewTeam.reviewTeam;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('teamChairsAndEditorsCtrl', teamChairsAndEditorsCtrl);

}(angular.module('review')));