(function (module) {

    var rfrLocationDetailsCtrl = function (rfrSvc, codeSvc) {
        var model = this;
        model.title = 'Location Details';

        model.getDistanceMiles = function (address) {
            var text = 'Distance from Main Location not entered'

            if (address.distanceMiles >= 0) {
                text = address.distanceMiles + ' miles from Main Location';
            }

            return text;
        };

    };

    module.controller('rfrLocationDetailsCtrl', rfrLocationDetailsCtrl);

}(angular.module('rfr')));