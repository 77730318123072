(function (module) {

    var reviewTeamMemberSvc = function ($http, $q, amsConst, odataSvc, $odata) {
        var apiPath = amsConst.webApiUrl + '/odata/ReviewTeamMember({0})';
        var factory = {};
        var queryBase = '?$filter=';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        /**********************************
        *REFACTORED
        **********************************/
        var key = 'reviewTeamId';
        var memberKey = 'reviewTeamMemberId';
        var api = '/ReviewTeamMember';
        factory.data = { teamChairsAndEditors: null, pevs: null };

        //ADMIN ONLY
        factory.getTeamChairsAndEditorsByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            var predicate = new $odata.Predicate("reviewTeamId", id);
            var predType1 = new $odata.Predicate('teamMemberTypeId', '!=', 1);
            var predType2 = new $odata.Predicate('teamMemberTypeId', '!=', 7);
            var predType3 = new $odata.Predicate('teamMemberTypeId', '!=', 8);
            var predType4 = new $odata.Predicate('teamMemberTypeId', '!=', 9);


            var combinedOr = $odata.Predicate.and([predType1, predType2, predType3, predType4]);
            var combinedFinal = $odata.Predicate.and([predicate, combinedOr]);

            oSvc.getSource(api, key).odata()
                .filter(combinedFinal)               
                .query(oSvc.onSuccess, oSvc.onFailure);


            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.teamChairsAndEditors = data;
                })
            }

            return oSvc.getDeferred().promise;
        }

        //ADMIN ONLY
        factory.getPevsByReviewTeamIdOData = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            var predicate = new $odata.Predicate("reviewTeamId", id);
            var predType1 = new $odata.Predicate('teamMemberTypeId', 1);
            var predType2 = new $odata.Predicate('teamMemberTypeId', 7);

            var combinedOr = $odata.Predicate.or([predType1, predType2]);
            var combinedFinal = $odata.Predicate.and([predicate, combinedOr]);

            oSvc.getSource(api, key).odata()
                .filter(combinedFinal)
                .query(oSvc.onSuccess, oSvc.onFailure);



            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.pevs = data;
                })
            }

            return oSvc.getDeferred().promise;
        }



        factory.getTeamChairsAndEditorsByReviewTeamId = function (id) {
            var teamChairsAndEditorsFilter = ' and teamMemberTypeId ne 1 and teamMemberTypeId ne 7';
            return $http.get(apiPath.format(null) + queryBase + buildReviewTeamIdFilter(id) + teamChairsAndEditorsFilter).then(onSuccess, onFailure);
        };

        factory.getTeamMembersByReviewTeamId = function (id) {
            return $http.get(apiPath.format(null) + queryBase + buildReviewTeamIdFilter(id)).then(onSuccess, onFailure);
        };

        factory.getPevsByReviewTeamId = function (id) {
            var pevFilter = ' and (teamMemberTypeId eq 1 or teamMemberTypeId eq 7)';
            return $http.get(apiPath.format(null) + queryBase + buildReviewTeamIdFilter(id) + pevFilter).then(onSuccess, onFailure);
        };

        factory.create = function (newReviewTeamMember) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, newReviewTeamMember);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamMemberData(oSvc, newReviewTeamMember.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.update = function (reviewTeamMember) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, reviewTeamMember);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamMemberData(oSvc, reviewTeamMember.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (reviewTeamMember) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, memberKey, { reviewTeamMemberId: reviewTeamMember.reviewTeamMemberId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamMemberData(oSvc, reviewTeamMember.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.changeTeamMemberStatus = function(teamMember){
            var path = amsConst.webApiUrl + '/odata/ChangeTeamMemberStatus';

            var data = {
                reviewTeamMemberId: teamMember.reviewTeamMemberId,
                teamMemberStatusId: teamMember.teamMemberStatusId,
                rejectComment: teamMember.rejectComment,
                startDate: teamMember.startDate,
                endDate: teamMember.endDate
            }
           
            return $http.post(path, data).then(function (response) {
                return response;
            });

        }

        factory.getMyReviewTeamMembers = function (reviewTeamId) {
            var myReviewTeamApi = '/GetMyReviewTeamMembers'
            var oSvc = odataSvc.get();
            var api = oSvc.getPathWithParameter(myReviewTeamApi, 'reviewTeamId', reviewTeamId);

            oSvc.getSource(api, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.getReviewTeamMembers = function (reviewTeamId) {
            var reviewTeamApi = '/GetReviewTeamMembers'
            var oSvc = odataSvc.get();
            var api = oSvc.getPathWithParameter(reviewTeamApi, 'reviewTeamId', reviewTeamId);

            oSvc.getSource(api, key).odata()
                //.expand('ProgramReviewDisciplineTeamMemberDto')
                //.expand('VolunteerDto')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.updateTeamMemberReviewedOssStatus = function (reviewTeamMember) {
            var path = amsConst.webApiUrl + '/odata/UpdateReviewTeamMemberReviewedOssStatus';

            var data = {
                reviewTeamMemberId: reviewTeamMember.reviewTeamMemberId,
                hasReviewedOss: reviewTeamMember.hasReviewedOss
            }

            return $http.post(path, data).then(function (response) {
                return response;
            });
        }

        var buildFilter = function (property, parameter) {
            return property + ' ' + parameter;
        };

        var buildReviewTeamIdFilter = function (parameter) {
            return buildFilter("reviewTeamId eq", parameter);
        };

        function resolveReviewTeamMemberData(oSvc, reviewTeamId){
            oSvc.getDeferred().promise.then(function () {
                factory.getTeamChairsAndEditorsByReviewTeamIdOdata(reviewTeamId);
                factory.getPevsByReviewTeamIdOData(reviewTeamId)
            });
        }
        return {
            getTeamChairsAndEditorsByReviewTeamId: factory.getTeamChairsAndEditorsByReviewTeamId,
            getTeamMembersByReviewTeamId: factory.getTeamMembersByReviewTeamId,
            getPevsByReviewTeamId: factory.getPevsByReviewTeamId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            data: factory.data,
            getTeamChairsAndEditorsByReviewTeamIdOdata: factory.getTeamChairsAndEditorsByReviewTeamIdOdata,
            changeTeamMemberStatus: factory.changeTeamMemberStatus,
            getMyReviewTeamMembers: factory.getMyReviewTeamMembers,
            updateTeamMemberReviewedOssStatus: factory.updateTeamMemberReviewedOssStatus,
            getReviewTeamMembers: factory.getReviewTeamMembers
        };
    };
    module.factory('reviewTeamMemberSvc', reviewTeamMemberSvc);

})(angular.module('review'));