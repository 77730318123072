(function (module) {
    var pevReferenceCtrl = function (barSvc, surveyTemplateSvc, pevAppSvc, helperSvc, messageTemplateTypes, surveyTemplateTypes, messageSvc, messageTemplateParameters) {
        var model = this;

        model.title = 'PEV Reference';
        model.isDataReady = false;
        model.isSubmitted = false;
        model.isSurveyCompleted = false;
        model.referer = {};

        model.save = function () {
            model.referrer.surveyQuestion = JSON.stringify(model.survey);
            pevAppSvc.submitReferenceSurvey(model.application.volunteerApplicationId, model.referrer).then(function () {
                model.isSubmitted = true;
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
        };

        var activate = function () {
            var dataSourceArray = [];
            var templateId = surveyTemplateTypes.PEVREFERENCE; //PEV Reference id
            var key = helperSvc.getQueryStringVariable('key');

            barSvc.showBar();

            var referenceDataSource = {
                dataHolder: model,
                dataLocationName: 'referenceDetails',
                svcCallback: pevAppSvc.getReferenceDetails,
                svcCallbackArguments: [key]
            };
            dataSourceArray.push(referenceDataSource);

            var templateDataSource = {
                dataHolder: model,
                dataLocationName: 'surveyTemplate',
                svcCallback: surveyTemplateSvc.getSurveyTemplateById,
                svcCallbackArguments: [templateId]
            };
            dataSourceArray.push(templateDataSource);

            helperSvc.getData(dataSourceArray).then(function () {
                
                model.application = model.referenceDetails[0].application;
                model.referrer = model.referenceDetails[0].reference;
                model.isDataReady = true;
                model.isSurveyCompleted = checkSurvey();
                model.survey = model.isSurveyCompleted ? model.referenceDetails[0].reference.surveyQuestion : angular.fromJson(model.surveyTemplate[0].templateJson);
               
                barSvc.hideBar();
            });

            
        }();

        function checkSurvey() {
            return model.referrer.surveyQuestion !== undefined ||
                angular.equals(model.referrer.surveyQuestion, {}) ||
                (model.referrer.surveyQuestion && model.referrer.surveyQuestion.groups && model.referrer.surveyQuestion.groups.length);
        }
    };
    module.controller('pevReferenceCtrl', pevReferenceCtrl);

}(angular.module('pevApp')));