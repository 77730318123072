(function (module) {
    'use strict';

    var statementAdminSvc = function ($http, $q, odataSvc, amsConst, $odata, statementStatuses, statementTypeNames, statementTypeIds, statementStorageSvc, statementPDFSvc,
        pdfSvc, documentSvc, commissionTypes, helperSvc, evaluatorReportSvc, supplementalStatementPDFSvc, contactSvc, contactRoleTypes, programReviewFinalActionSvc,
        orgConstants, personSignatureSvc, personMediaSvc, FileSaver) {

        var factory = {};
        var searchApi = '/GetStatementSearch';
        var agendaBookApi = '/GetStatementAgendaBookSearch';
        var exportApi = '/GetStatementExportView';

        factory.advancedSearch = function (searchOptions, isExport, isAgendaBook) {
            var api = isExport ? exportApi : isAgendaBook ? agendaBookApi :searchApi;
            var oSvc = odataSvc.get();

            var hasReviewYear = searchOptions.reviewYear ? true : false;
            var hasStatusId = searchOptions.statusId ? true : false;
            var hasCommissions = searchOptions.commissions ? searchOptions.commissions.length > 0 : false;
            var hasReviewType = searchOptions.reviewType ? true : false;
            var hasLocation = searchOptions.location ? true : false;
            var hasOrganizationName = searchOptions.organizationName ? true : false;
            var hasStatementType = searchOptions.statementTypeId ? true : false;

            var hasSearchOptions = hasReviewYear || hasStatusId || hasLocation || hasOrganizationName;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;

                if (hasReviewYear)
                    predicates.push(new $odata.Predicate('reviewYear', '=', searchOptions.reviewYear));
                if (hasStatusId){
                    if(parseInt(searchOptions.statusId) === statementStatuses.WAITINGFORTCSUBMIT){
                        var combinedStatusPredicate = [];
                        combinedStatusPredicate.push(new $odata.Predicate('statementStatusId', '=', parseInt(searchOptions.statusId)));
                        combinedStatusPredicate.push(new $odata.Predicate('statementStatusId', '=', null));
                        predicates.push($odata.Predicate.or(combinedStatusPredicate))
                    }else
                        predicates.push(new $odata.Predicate('statementStatusId', '=', parseInt(searchOptions.statusId)));
                }
                if (hasOrganizationName){
                    if(!isExport)   
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                    else
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'sortName', searchOptions.organizationName)), '>', -1));
                }
                if (hasStatementType)
                    predicates.push(new $odata.Predicate('statementTypeId', '=', parseInt(searchOptions.statementTypeId)));
                if (hasReviewType)
                    predicates.push(new $odata.Predicate('reviewType', 'eq', searchOptions.reviewType));
                if (hasCommissions) {
                    var combinedCommissionPredicate = [];

                    for(var i=0; i < searchOptions.commissions.length; i++){
                        combinedCommissionPredicate.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissions[i].id)))
                    }

                    if (combinedCommissionPredicate.length > 0) {
                        predicates.push($odata.Predicate.or(combinedCommissionPredicate))
                    }
                }

                if (hasLocation) {
                    if (parseInt(searchOptions.location) === 0) {
                        predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                    } else {
                        predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                    }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }
                
                oSvc.getSource(api).odata()
                    .filter(combinedPredicate)
                    .orderBy("sortName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(api).odata()
                    .orderBy("sortName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        factory.sendDraftStatements = function (statementIds) {
            var path = amsConst.webApiUrl + '/odata/';
            var data = { "Value": statementIds };

            // Sending the emails server side (and updating statement status, which is reflected in PDF rendering)
            return $http.post(path + 'SendDraftStatement', data);
        };

        factory.createDraftStatementDocuments = function (selectedStatements) {
            // Some draft statements in dev were missing statement IDs; should probably prevent them up front from being selected for sending.
            var statements = selectedStatements.filter(function (s) { return s.statementId; });

            return factory.getCommissionChairs(null,statements[0].reviewYear).then(function () {
                return doCreateDraftStatementDocuments(statements);
            });

            function doCreateDraftStatementDocuments(statements) {
                // Lockdown the current version of the statement by saving as PDF.
                if (!statements.length) {
                    var deferred = $q.defer();
                    deferred.resolve(true);
                    return deferred.promise;
                }

                var statement = statements.pop();

                return statementStorageSvc.getAllStatementToolData(statement.statementId).then(function (data) {
                    return generateStatementPDF().then(async function (data) {
                        var doc = {
                            referenceId: statement.statementId,
                            documentTypeId: 3, //Draft Statement - Sent to Institution
                            documentTitle: getStatementTitle(statement, true)
                        };
                        doc.file = new File([data], helperSvc.formatFilename(doc.documentTitle, 'pdf'));

                        try {
                            await documentSvc.uploadDocument(doc);
                        } catch (error) {
                            if (!error?.data?.duplicateFile) throw error;
                        }

                        return generateDeanDraftLetterPDF().then(async function (data) {
                            var doc = {
                                referenceId: statement.statementId,
                                documentTypeId: 5, // Draft Statement - Deans Letter Attachment
                                documentTitle: 'DraftStatementAttachment_' + statement.statementId + '_Letter to Dean'
                            };
                            doc.file = new File([data], helperSvc.formatFilename(doc.documentTitle, 'pdf'));

                            try {
                                await documentSvc.uploadDocument(doc);
                            } catch (error) {
                                if (!error?.data?.duplicateFile) throw error;
                            }

                            // Continue processing
                            return doCreateDraftStatementDocuments(statements);
                        });
                    });
                });
            }
        };

        factory.sendFinalStatements = function (statementIds) {
            var path = amsConst.webApiUrl + '/odata/';
            var data = { "Value": statementIds };
            // Sending the emails server side (and updating statement status, which is reflected in PDF rendering)
            return $http.post(path + 'SendFinalStatement', data);
        };

        factory.createFinalStatementDocuments = function (selectedStatements) {
            var statements = selectedStatements.slice(0);

            return factory.getCommissionChairs(null,statements[0].reviewYear).then(function () {
                return factory.getABETPresident(statements[0].reviewYear).then(function () {
                    return doCreateFinalStatementDocuments(statements);
                });
            });

            function doCreateFinalStatementDocuments(statements) {
                // Lockdown the current version of the statement by saving as PDF.
                if (!statements.length) {
                    var deferred = $q.defer();
                    deferred.resolve(true);
                    return deferred.promise;
                }

                var statement = statements.pop();

                return statementStorageSvc.getAllStatementToolData(statement.statementId).then(function (data) {
                    var programsDataSource = {
                        dataHolder: statementStorageSvc,
                        dataLocationName: 'data.programs',
                        svcCallback: programReviewFinalActionSvc.getReviewsByReviewTeamId,
                        svcCallbackArguments: [statement.reviewTeamId],
                        odataResource: true
                    };
                    return helperSvc.getData([programsDataSource]).then(function (data) {
                        return generateStatementPDF().then(async function (data) {
                            var doc = {
                                referenceId: statement.statementId,
                                documentTypeId: 4, // Final Statement - Sent to Institution
                                documentTitle: getStatementTitle(statement, true)
                            };
                            doc.file = new File([data], helperSvc.formatFilename(doc.documentTitle, 'pdf'));

                            try {
                                await documentSvc.uploadDocument(doc);
                            } catch (error) {
                                if (!error?.data?.duplicateFile) throw error;
                            }

                            return generatePresidentLetterPDF().then(async function (data) {
                                var doc = {
                                    referenceId: statement.statementId,
                                    documentTypeId: 6, //Final Statement - President Letter Attachment
                                    documentTitle: 'FinalStatementAttachment_' + statement.statementId + '_Letter to President'
                                };
                                doc.file = new File([data], helperSvc.formatFilename(doc.documentTitle, 'pdf'));

                                try {
                                    await documentSvc.uploadDocument(doc);
                                } catch (error) {
                                    if (!error?.data?.duplicateFile) throw error;
                                }

                                // Continue processing
                                return doCreateFinalStatementDocuments(statements);
                            });
                        });
                    });
                });
            }
        };

        factory.downloadActionSummaries = function (statements) {
            var commissions = commissionTypes.slice();

            return doDownloadActionsSummary(commissions);

            function doDownloadActionsSummary (commissions) {
                var commission = commissions.shift();

                if (!commission) {
                    var deferred = $q.defer();
                    deferred.resolve(true);
                    return deferred.promise;
                }

                var commissionStatements = statements.filter(function (s) {
                    return s.statementId && s.commissionId === commission.id;
                });

                if (!commissionStatements.length) {
                    // Continue processing...
                    return doDownloadActionsSummary(commissions);
                }

                var content = [];

                return doDownloadActionsSummaryByCommission(commissionStatements, content).then(function (data) {
                    if (!data || !data.length) {
                        var deferred = $q.defer();
                        deferred.resolve(true);
                        return deferred.promise;
                    }
                    // Generate PDF and download
                    var currentReviewYear = helperSvc.getAcademicYear();
                    var docTitle = 'Summary of Accreditation Actions ' + (currentReviewYear - 1) + '-' + ('' + currentReviewYear).substring(2) + ' ' + commission.abrv;
                    data.pop();
                    var actionsSummaryDoc = statementPDFSvc.createDoc(docTitle, data);
                    return getGeneratePDFPromise(actionsSummaryDoc).then(function (data) {
                        FileSaver.saveAs(data, docTitle + '.pdf');
                        // Continue processing
                        return doDownloadActionsSummary(commissions);
                    });
                });
            }
             
            function doDownloadActionsSummaryByCommission (statements, content) {
                if (!statements.length) {
                    return content;
                }

                var currentStatement = statements.shift();

                return statementStorageSvc.getAllStatementToolData(currentStatement.statementId).then(function (data) {
                    var programsDataSource = {
                        dataHolder: statementStorageSvc,
                        dataLocationName: 'data.programs',
                        svcCallback: programReviewFinalActionSvc.getReviewsByReviewTeamId,
                        svcCallbackArguments: [currentStatement.reviewTeamId],
                        odataResource: true
                    };
                    return helperSvc.getData([programsDataSource]).then(function (data) {
                        var summaryOfActions = statementPDFSvc.getSummaryOfActions(currentStatement);
                        content = content.concat(summaryOfActions);
                        // Continue processing
                        return doDownloadActionsSummaryByCommission(statements, content);
                    });
                });
            }
        };

        function getStatementTitle (statement, includeStatementTypeName) {
            var organizationName = statement.sortName || statement.organizationName;
            var reviewYear = statement.reviewYear;
            var commission = commissionTypes.find(function (c) {
                return c.id === statement.commissionId
            });

            var title = organizationName + ' - ' + reviewYear + ' - '  + commission.abrv;

            if (includeStatementTypeName) {
                var statementTypeName = factory.getStatementTypeName(statement);
                if (statementTypeName.length > 1) title += ' - ' + statementTypeName.toUpperCase();
            }

            return title;
        }

        function generateStatementPDF () {
            var statement = statementStorageSvc.data.statement;
            var doc = statementPDFSvc.generateDoc(statement);
            return getGeneratePDFPromise(doc);
        }

        function generatePresidentLetterPDF() {
            return factory.getInstitutionPresident().then(function () {
                var doc = supplementalStatementPDFSvc.generatePresidentLetter(statementStorageSvc.data.statement);
                return getGeneratePDFPromise(doc);
            });
        }

        function generateDeanDraftLetterPDF () {
            var doc = supplementalStatementPDFSvc.generateDeanDraftLetter(statementStorageSvc.data.statement);
            return getGeneratePDFPromise(doc);
        }

        factory.getInstitutionPresident = function () {
            var presidentContactsDataSource = {
                dataHolder: statementStorageSvc,
                dataLocationName: 'data.president',
                svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
                svcCallbackArguments: [statementStorageSvc.data.currentReviewTeam.organizationId, contactRoleTypes.PRESIDENT, false, false, true],
                helperCallback: helperSvc.getValue,
                orderByProperty: 'organizationUserId'
            };

            return helperSvc.getData([presidentContactsDataSource]);
        };

        factory.getABETPresident = function (reviewYear) {
            return contactSvc.getContactsWithPerson(orgConstants.ABET_BOD, contactRoleTypes.ABETPRESIDENT, false, false, true).then(function (data) {
                statementStorageSvc.data.ABETPresident = data.value && data.value.length && data.value[0] || null;

                /* PersonMedia table is created to handle person portrait uploading */
                if (reviewYear && reviewYear > 2022) {
                    return personMediaSvc.getMediaImageByPersonId(statementStorageSvc.data.ABETPresident.personDto.personId, 1).then(function (data) {
                        statementStorageSvc.data.ABETPresident.signature = data;
                    });
                }
                else {
                    return personSignatureSvc.getSignatureImageByPersonId(statementStorageSvc.data.ABETPresident.personDto.personId).then(function (data) {
                        statementStorageSvc.data.ABETPresident.signature = data;
                    });
                }
            });
        };

        factory.getCommissionChairs = function (commissionId, reviewYear) {
            var promises = [];

            var commissions = commissionId ? commissionTypes.filter(function (comm) { return comm.id === commissionId; }) : commissionTypes;

            commissions.forEach(function (commission) {
                promises.push(getChair(commission));
                promises.push(getChair(commission, true));
            });

            return $q.all(promises);

            function getChair(commission, getPastChair) {
                var roleId = getPastChair ? contactRoleTypes.PASTCHAIR : contactRoleTypes.CHAIR;
                var chairPropertyNameSuffix = getPastChair ? 'PastChair' : 'Chair';
                return contactSvc.getContactsWithPerson(commission.id, roleId, false, false, true).then(function (data) {
                    var chairPropertyName = commission.abrv + chairPropertyNameSuffix;
                    statementStorageSvc.data[chairPropertyName] = data.value && data.value.length && data.value[0] || null;

                    /* PersonMedia table is created to handle person portrait uploading */
                    if (reviewYear && reviewYear > 2022) {
                        return personMediaSvc.getMediaImageByPersonId(statementStorageSvc.data[chairPropertyName].personDto.personId, 1).then(function (data) {
                            statementStorageSvc.data[chairPropertyName].signature = data;
                        });
                    }
                    else {
                        return personSignatureSvc.getSignatureImageByPersonId(statementStorageSvc.data[chairPropertyName].personDto.personId).then(function (data) {
                            statementStorageSvc.data[chairPropertyName].signature = data;
                        });
                    }
                });
            }
        };

        function getGeneratePDFPromise (doc) {
            var deferred = $q.defer();

            pdfSvc.generatePDFBlob(doc, null, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise;
        }

        factory.downloadAgendaBook = function (statements) {
            // Download documents needed to create consent and non-consent agenda books for July Commissioners Meeting
            var commissions = commissionTypes.slice();

            return doDownloadAgendaBook(commissions);

            function doDownloadAgendaBook(commissions) {
                var commission = commissions.shift();

                if (!commission) {
                    var deferred = $q.defer(); 
                    deferred.resolve(true);
                    return deferred.promise;
                }

                var commissionStatements = statements.filter(function (s) {
                    return s.statementId && s.commissionId === commission.id
                });

                if (!commissionStatements.length) {
                    // Continue processing...
                    return doDownloadAgendaBook(commissions); 
                }


                var reviewYear = commissionStatements[0].reviewYear; // For now, UI require selection of a single review year  
                var commissionAbbrv = commission.abrv;
                var zipFilename = 'Agenda Book - ' + reviewYear + ' - ' + commissionAbbrv + '.zip';

                var zipFile = new JSZip();

                return doDownloadAgendaBookByCommission(commissionStatements, zipFile).then(function () {
                    return zipFile.generateAsync({ type: "blob" }).then(
                        function (blob) {
                            FileSaver.saveAs(blob, zipFilename);
                            return doDownloadAgendaBook(commissions);
                        });
                });
            }

            function doDownloadAgendaBookByCommission(statements, zipFile) {
                var statement = statements.shift(); // statements will, in practice, already be sorted by org sort name desc

                if (!statement) return true;

                return statementStorageSvc.getAllStatementToolData(statement.statementId).then(function () {
                    // Skip if withdrawn/cancelled or if there are not program reviews (a side effect of some manual fixes)
                    if (!statementStorageSvc.data.statementProgramReviews ||
                        !statementStorageSvc.data.statementProgramReviews.length ||
                        statementStorageSvc.data.statementProgramReviews.every(function (programReview) {
                            return programReview.actionCode === 'W' || programReview.actionCode === 'C';
                        })) {
                        // Continue processing
                        return doDownloadAgendaBookByCommission(statements, zipFile);
                    }

                    // Generate statement PDF
                    return generateStatementPDF().then(function (data) {
                        // Save statement PDF
                        var statementTitle = getStatementTitle(statement);
                        var statementFilename = helperSvc.formatFilename(statementTitle, 'pdf');
                        zipFile.file(statementFilename, data, { binary: true });
                        // Download and save PAF (if present--not required for reports)
                        if (evaluatorReportSvc.data.evaluatorReport.stream_id) {
                            var pafFilename = helperSvc.formatFilename(statementTitle + ' - PAF');
                            return documentSvc.downloadDocumentBlob(evaluatorReportSvc.data.evaluatorReport.stream_id, pafFilename).then(function (data) {
                                zipFile.file(data.filename, data.blob, { binary: true });
                                // Continue processing
                                return doDownloadAgendaBookByCommission(statements, zipFile);
                            });
                        } else {
                            // Continue processing
                            return doDownloadAgendaBookByCommission(statements, zipFile);
                        }
                    })
                });
            }
        }
         
        factory.getStatementTypeName = function (statement) {
            if (statement.statementStatusId) {
                if (statement.statementTypeId === statementTypeIds.DRAFT) {
                    return statementTypeNames.DRAFT;
                }
                else if (statement.statementTypeId === statementTypeIds.FINAL) {
                    return statementTypeNames.FINAL;
                }
                else if (statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                    return statementTypeNames.POST30DAY;
                }
                else if (statement.statementTypeId === statementTypeIds.COMMISSIONEDITING) {
                    return statementTypeNames.COMMISSIONEDITING;
                }
            }

            return '';
        };

        return {
            advancedSearch: factory.advancedSearch,
            sendDraftStatements: factory.sendDraftStatements,
            sendFinalStatements: factory.sendFinalStatements,
            createDraftStatementDocuments: factory.createDraftStatementDocuments,
            downloadAgendaBook: factory.downloadAgendaBook,
            getStatementTypeName: factory.getStatementTypeName,
            createFinalStatementDocuments: factory.createFinalStatementDocuments,
            downloadActionSummaries: factory.downloadActionSummaries,
            getInstitutionPresident: factory.getInstitutionPresident,
            getABETPresident: factory.getABETPresident,
            getCommissionChairs: factory.getCommissionChairs
        };
    };

    module.factory('statementAdminSvc', statementAdminSvc);

})(angular.module('statement'));