(function (module) {

    var messageOpenCtrl = function ($scope, $uibModalInstance, message) {
        var model = $scope;

        model.message = angular.copy(message);
        model.title = model.message.title;

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('messageOpenCtrl', messageOpenCtrl);

}(angular.module('messages')));