//Deep copy these constants to use them otherwise the variables will be overwritten/ appended on each use and possibly cause unwanted results
//Example: var myNewTemplate = angular.copy(messageTemplateParameters.xyz);
(function (module) {

    var messageTemplateParameters = {
        FORGOTUSERNAME: { messageParmeterDtos: [{ messageParameterName: 'username', messageParameterValue: '' }] },
        REQUESTUSERPASSWORD: { messageParmeterDtos: [{ messageParameterName: 'password', messageParameterValue: '' }] },
        RFRPREREVIEWREQUEST: [{ messageParameterName: 'InstitutionName', messageParameterValue: '' }, { messageParameterName: 'CommissionCodes', messageParameterValue: '' }, { messageParameterName: 'CycleYearCurrent', messageParameterValue: '' }],
        RFRREQUESTPRESIDENTSIGNATURE: [{ messageParameterName: 'InstitutionName', messageParameterValue: '' }, { messageParameterName: 'PresidentLastName', messageParameterValue: '' }, { messageParameterName: 'ReviewCycleYear', messageParameterValue: '' }],
        RFRREQUESTABETACCEPTANCE: [{ messageParameterName: 'InstitutionName', messageParameterValue: '' }],
        RFRACCEPTED: [{ messageParameterName: 'InstitutionName', messageParameterValue: '' }, { messageParameterName: 'ReviewCycleYear', messageParameterValue: '' }, { messageParameterName: 'CommissionCodes', messageParameterValue: '' }, { messageParameterName: 'CurrentCycleYear', messageParameterValue: '' }],
        RFRRETURNEDTOCOMMISSION: [{ messageParameterName: 'InstitutionName', messageParameterValue: '' }, { messageParameterName: 'PresidentLastName', messageParameterValue: '' }, { messageParameterName: 'ReviewCycleYear', messageParameterValue: '' }],
        RFRCREATEDRFEFORNEWINSTITUTIONCOMMISSION: [{ messageParameterName: 'PersonLastName', messageParameterValue: '' }, { messageParameterName: 'AcademicYear', messageParameterValue: '' }, { messageParameterName: 'ReviewYear', messageParameterValue: '' }],
        PEVAPPSOCIETYNOTIFICATION: [{ messageParameterName: 'PersonFirstName', messageParameterValue: '' }, { messageParameterName: 'PersonLastName', messageParameterValue: '' }, { messageParameterName: 'VolunteerEmailAddress', messageParameterValue: '' }],
        PEVAPPCONFIRMSUBMISSION: [{ messageParameterName: 'PersonFirstName', messageParameterValue: '' }, { messageParameterName: 'PersonLastName', messageParameterValue: '' }],
        PEVAPPREFERENCERESPONSENOTIFICATION: [{ messageParameterName: 'PersonFirstName', messageParameterValue: '' }, { messageParameterName: 'PersonLastName', messageParameterValue: '' }, { messageParameterName: 'ReferencePersonName', messageParameterValue: '' }]
    }
    module.constant('messageTemplateParameters', messageTemplateParameters);

}(angular.module("common")));