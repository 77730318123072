(function (module) {

    var npcManageStatusModalCtrl = function (npc, program, programChange, $scope, $q, $uibModalInstance, helperSvc, codeSvc, programReviewSvc,  npcSvc, alertSvc) {
        var model = $scope;

        model.title = 'Add Status for ' + program.programDetailDto.programName;
        model.isSaving = false;
        model.isDataReady = false;
        model.data = {
            npcStatuses: [],
            npcStatus: 0,
            note: ""
        };
       

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;

            var now = new Date();
            var newProgressDto = {
                npcProgramChangeId: programChange.npcProgramChangeId,
                npcStatusId: model.data.npcStatus, 
                npcStatusUpdatedDate: now,
                note: model.data.note
            }

            npcSvc.manageStatus(newProgressDto).then(function () {
                alertSvc.addAlertSuccess(`Status Sucesfully updated.`);
                $uibModalInstance.close();
            }, function () {
                    alertSvc.addModalAlertWarning('Status could not be updated at this time.');
                    model.isSaving = false;
            });

        }

        function activate() {

            codeSvc.getNpcStatuses().then(function (data) {
                model.data.npcStatuses = data.value;

                model.isDataReady = true;
            })
        }

        activate();
    };
    module.controller('npcManageStatusModalCtrl', npcManageStatusModalCtrl);

}(angular.module('npc')));