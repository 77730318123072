(function (module) {

    var npcSendFinalActionModalCtrl = function (npc, npcPdf,isFocusedVisit, programsSelected, $scope, $q, $uibModalInstance, helperSvc, codeSvc, programReviewSvc, npcSvc, alertSvc, npcStatusNames, npcStatusIds, npcDocumentTypes) {
        var model = $scope;

        model.title = 'Send Final Action';
        model.isSaving = false;
        model.isDataReady = false;
        model.npcData = npc;
        model.npcPdf = npcPdf;
        model.data = {
            npcActions: [],
            finalActionTypeId: 0,
            note: "",
            sendAutomatedEmail: false,
            selectedProgramChanges: programsSelected,
            allProgramChangeOptions: [],
            emailText: ""
        };

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;

            var now = new Date();
            var programChangeFinalActions = []
            //UPDATE THE PROGRAM CHANGES PROGRESS Dtos
            var isTermination = false;
            var terminationStreamId = null;
            var breakSave = false;

            model.data.selectedProgramChanges.forEach(function (change) {
                //create the changeFinalActions for later

                var pcFinalAction = {
                    npcFinalActionProgramChangeId: 0,
                    npcFinalActionId: 0,
                    npcProgramChangeId: change.npcProgramChangeId
                };
                programChangeFinalActions.push(pcFinalAction);

                var newProgressDto = {
                    npcProgramChangeProgressId: 0,
                    npcProgramChangeId: change.npcProgramChangeId,
                    npcStatusId: npcStatusIds.SENTFINALACTIONAFTERCOMMISSION, //sent ackowledgement
                    npcStatusName: npcStatusNames[npcStatusIds.SENTFINALACTIONAFTERCOMMISSION],
                    npcStatusUpdatedDate: now,
                    note: model.data.note
                };

                //get the change based on the ids from the npc
                const programIndex = model.npcData.npcProgramDtos.findIndex(item => item.npcProgramId === change.npcProgramId);

                const changeIndex = model.npcData.npcProgramDtos[programIndex].npcProgramChangeDtos.findIndex(item => item.npcProgramChangeId === change.npcProgramChangeId);

                //check if the excome decision was added
                var theProgramChange = model.npcData.npcProgramDtos[programIndex].npcProgramChangeDtos[changeIndex]
                if (!theProgramChange.excomDecisionJson) {
                    breakSave = true;
                    alertSvc.addModalAlertWarning('Final Action not sent. The excom decision has not been added.');
                    return;
                }

                //add to the Npc
                model.npcData.npcProgramDtos[programIndex].npcProgramChangeDtos[changeIndex].npcProgramChangeProgressDtos.push(newProgressDto);

                if (model.npcData.npcProgramDtos[programIndex].npcProgramChangeDtos[changeIndex].npcType === 'Termination') {
                    isTermination = true;
                }

            });

            if (breakSave) {
                model.isSaving = false;
                return;
            }
                

            //CREATE AND UPDATE THE FINAL ACTION 
            var newFinalAction = {
                npcFinalActionId: 0,
                npcId: model.npcData.npcId,
                npcActionTypeId: model.data.finalActionTypeId,
                npcActionTypeName: model.data.npcActions.find(x => x.codeKey === model.data.finalActionTypeId).codeName,
                sentEmail: model.data.emailText,
                sentDate: now,
                note: model.data.note,
                npcFinalActionProgramChangeListDtos: programChangeFinalActions,
            }

            for (var i = 0; i < model.npcData.npcProgramDtos.length; i++) {
                for (var j = 0; j < model.npcData.npcProgramDtos[i].npcProgramChangeDtos.length; j++)
                    delete model.npcData.npcProgramDtos[i].npcProgramChangeDtos[j].selected;
            }
            npcSvc.submitFinalAction(newFinalAction, model.npcData).then(function (newId) {
                var newNpcFinalActionId = newId.data.value;

                npcSvc.createFinalStatement(model.npcPdf, newNpcFinalActionId, isFocusedVisit, programsSelected).then(function () {
                }, function () {
                    alertSvc.addModalAlertWarning('Error while generating document');
                    model.isSaving = false;
                });

                alertSvc.addAlertSuccess(`Action Sucesfully updated.`);
                $uibModalInstance.close();

            }, function () {
                alertSvc.addModalAlertWarning('Action could not be updated at this time.');
                model.isSaving = false;
            });
        }

        function activate() {

            codeSvc.getNpcActionTypes().then(function (data) {
                model.data.npcActions = data.value;

                //reformat all programs and their changes into a multiselect object
                model.data.allProgramChangeOptions = npcSvc.getProgramChangesForMultiSelect(model.npcData)
                
                model.isDataReady = true;
            })
        }

        activate();
    };
    module.controller('npcSendFinalActionModalCtrl', npcSendFinalActionModalCtrl);

}(angular.module('npc')));