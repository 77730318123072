(function (module) {
    'use strict';

    var personGeneralCtrl = function ($q, $stateParams, $location, $uibModal, personSvc, personTelephoneSvc, personEmailSvc, personAddressSvc,
                                      personMdl, personUserSvc, eventSvc, $scope, alertSvc, helperSvc) {
        var model = this;
        var parent = $scope.$parent.model;

        model.person = null;
        model.title = "General";
        model.isDisplayMode = false;
        model.formatDate = helperSvc.formatDate;
        model.getFormattedPhone = helperSvc.getFormattedPhone;


    };

    module.controller('personGeneralCtrl', personGeneralCtrl);

}(angular.module('person')));