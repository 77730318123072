(function (module) {

    var volunteerCtrl = function (codeSvc, barSvc, volunteerSvc, helperSvc, upcomingAvailabilitySvc, availabilityTypes, currentUser, oauth, $rootScope, $q, contactRoleTypes, commissionTypes) {
        var model = this;
        var isLoadingConflicts = false;
        var defaultData = { societyCode: null, programAreaOptions: null, cachedProgramAreaOptions: null };
        var arrContains = helperSvc.arrContains;
        var strContains = helperSvc.strContains;
        var arrayContainsByPropertyValue = helperSvc.arrayContainsByPropertyValue

        model.isDataReady = false;
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.notEnteredMsg = "Not Entered";
        model.showSpinner = false;
        model.showSpinnerExport = false;
        model.showSpinnerSearch = true;
        model.isAdmin = oauth.isAdmin();
        model.viceOpOrg = oauth.getOrganizationsByUserRoleId(contactRoleTypes.VICECHAIROFOPERATIONS);
        model.formatDate = helperSvc.formatDate;
        model.commissions = commissionTypes;
        model.csvHeaders = ['Last Name', 'First Name', 'Middle Name', 'Preferred Name', 'Email', 'TelephoneNumber', "AddressType", 
            'Address1', 'Address2', 'Address3', 'City', 'StateCode', 'Province', 'PostalCode', 'CountryName', 'YearsOfPEVExperience', 'YearsOfTCExperience',
            'LastVisitCycleYear', 'Affiliation','CurrentEmploymentType', 'BackgroundTypeName', 'CommissionName', 'RoleName', 'LastRefresherTrainingYear','PEVTrainingYear',
            'Society', 'Society Status', 'Program Area', 'Program Area Status', 'Terms Agreement', 'Health and Safety Agreement', 'Date Availability Type', 'Unavailabe Dates', 'Country Availability Type', 'Unavailable Countries', 'Travel Availability', "Gender", "Ethnicity", "Birth Year", ];
        model.csvColumns = ["lastName", "firstName", "middleName", "preferredName", "emailAddress", "telephoneNumber", "addressTypeName",
            'address1', 'address2', 'address3', 'city', 'stateCode', 'province', 'postalCode', 'countryName', 'yearsOfPEVExperience', 'yearsOfTCExperience',
            'lastVisitCycleYear','mostRecentEmployment', 'currentEmploymentType', 'backgroundTypeName', 'commissionName', 'roleName', 'lastRefresherTrainingYear','pevTrainingYear',
            'societyCode', 'societyStatus', 'disciplineName', 'volunteerDisciplineStatusName', "termsAgreement", "healthSafetyAgreement", "volunteerAvailabilityDateTypeName", 'unavailableDates', "volunteerAvailabilityCountryTypeName", 'unavailableCountries','volunteerAvailabilityTravelStatus',"gender", "ethnicityTypeName", "yearOfBirth"];

        model.societyStatusOptions = [
            { codeKey: 'Approved', codeName: 'Approved' },
            { codeKey: 'Disapproved/Inactive/Pending', codeName: 'Disapproved/Inactive/Pending' }
        ];

        model.conflictInformation = {
            volunteerId: null,
            countryConflicts: [],
            dateConflicts: []
        };

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        model.reset = function () {
            model.searchOptions = { programAreas: [], societyCode: defaultData.societyCode };
            model.programAreaOptions = defaultData.programAreaOptions;
            model.volunteers = null;
            model.resultMsg = model.defaultSearchMsg;
            model.totalCount = 0;
        };

        model.reset();

        model.retrieve = function () {
            var searchOpts = model.searchOptions;
            saveSearchOptions();
            model.volunteers = null;
            model.resultMsg = null;
            barSvc.showSpinner();

            volunteerSvc.advancedSearch(searchOpts).then(function (data) {
                model.volunteers = data;
                volunteerSvc.data.volunteers = data;

                if (model.volunteers.length === 0) {
                    model.resultMsg = "No results found";
                }

                volunteerSvc.data.resultMsg = model.resultMsg;

                barSvc.hideSpinner();
            });
        };

        model.getVolunteersExport = function () {
            var defer = $q.defer();
            model.showSpinnerExport = true;
            volunteerSvc.getVolunteerSearchDetailWithOption(model.searchOptions, true).then(function (data) {
                defer.resolve(resolveExport(data));
            });

            return defer.promise;
        }

        function resolveExport(data) {
            model.showSpinnerExport = false;
            return helperSvc.getResults(data) || [];

            //10/8/2021 : Allow duplicate persons with multiple program areas
            //var filteredData = helperSvc.uniqueArrayByKey(results.sort(function (a, b) {
            //    model.showSpinnerExport = false;
            //    return (a.lastName).localeCompare(b.lastName);
            //}), 'personId');
            //return filteredData
        }

        model.changedVariableForProgramAreas = function () {
            model.searchOptions.programAreas = [];
            model.programAreaOptions = filterProgramAreas();
            model.retrieve();
        };

        var conflictDataSourceArray = [
            {
                dataHolder: model.conflictInformation,
                svcCallback: [upcomingAvailabilitySvc.getVolunteerAvailabilityCountryByVolunteerId],
                dataLocationName: 'countryConflicts',
                helperCallback: function (volunteer) {
                    return volunteer.volunteerAvailabilityCountryListDtos;
                }
            },
            {
                dataHolder: model.conflictInformation,
                svcCallback: [upcomingAvailabilitySvc.getVolunteerAvailabilityDateByVolunteerId],
                dataLocationName: 'dateConflicts',
                helperCallback: function (volunteer) {
                    return volunteer.volunteerAvailabilityDateListDtos;
                }
            }
        ];

        model.loadConflicts = function (volunteer) {
            var isCached = (model.conflictInformation.volunteerId === volunteer.volunteerId);

            if (!isCached && !isLoadingConflicts) {
                model.isLoadingConflicts = true;
                var volunteerIdArg = [volunteer.volunteerId];

                for (var i = 0; i < conflictDataSourceArray.length; i++) {
                    conflictDataSourceArray[i].svcCallbackArguments = volunteerIdArg;
                }

                helperSvc.getData(conflictDataSourceArray).then(function () {
                    model.conflictInformation.volunteerId = volunteer.volunteerId;
                    model.isLoadingConflicts = false;
                });
            }
        };

        model.showPopover = function (availabilityTypeId) {
            var usesPopover = (availabilityTypeId === availabilityTypes.NONE || availabilityTypeId === availabilityTypes.SOME) ? true : false;
            return usesPopover;
        };



        function filterProgramAreas() {
            var programAreaOptions = [];

            for (var i = 0; i < defaultData.cachedProgramAreaOptions.length; i++) {
                var programArea = defaultData.cachedProgramAreaOptions[i];

                if (!model.isAdmin && (programArea.societyId == model.searchOptions.societyCode || (programArea.commissionId === model.searchOptions.commissionId) && !arrayContainsByPropertyValue(programAreaOptions, 'codeKey', programArea.disciplineId))) {
                    var item = { codeKey: programArea.disciplineId, codeName: programArea.disciplineName };
                    programAreaOptions.push(item);
                } else if (model.isAdmin && (!model.searchOptions.societyCode || programArea.societyId == model.searchOptions.societyCode) && (!model.searchOptions.commissionId || programArea.commissionId === model.searchOptions.commissionId) && !arrayContainsByPropertyValue(programAreaOptions, 'codeKey', programArea.disciplineId)) {
                    var item = { codeKey: programArea.disciplineId, codeName: programArea.disciplineName };
                    programAreaOptions.push(item);
                }
            }

            return programAreaOptions;
        }

        function saveSearchOptions() {
            var programAreas = [];

            if (volunteerSvc.data.searchOptions && volunteerSvc.data.searchOptions.programAreas) {
                for (var i = 0; i < volunteerSvc.data.searchOptions.programAreas.length; i++) {
                    var area = volunteerSvc.data.searchOptions.programAreas[i];
                    var newArea = { codeKey: area.codeKey, codeName: area.codeName };
                    programAreas.push(newArea);
                }

            }
            
            volunteerSvc.data.searchOptions = model.searchOptions;
            volunteerSvc.data.searchOptions.programAreas = programAreas;            
        }

        if (!volunteerSvc.data.volunteerSearchDeregistration) {
            var listener = $rootScope.$on('$stateChangeStart', function (event, next, current) {
                var url = next.url;
                var containsCommon = strContains(url, 'common');
                var containsVolunteer = strContains(url, 'volunteer');

                if (!(containsCommon && containsVolunteer)) {
                    volunteerSvc.data.searchOptions = null;
                }
            });

            volunteerSvc.data.volunteerSearchDeregistration = listener;
        }

        //need to create activate function???
        activate();
        function activate() {
            var optionsDataSourceArray = [
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getMemberSocieties],
                    dataLocationName: 'societyOptions',
                    helperCallback: helperSvc.getValue
                },
                {
                    dataHolder: defaultData,
                    svcCallback: [codeSvc.getSocietyDiscpiplineTypes],
                    dataLocationName: 'cachedProgramAreaOptions',
                    helperCallback: helperSvc.getValue
                }
            ];

            helperSvc.getData(optionsDataSourceArray).then(function () {
                var programAreaOptions = null;

                if (model.viceOpOrg.length > 0) {
                    model.isViceOp = true;
                    var societyOptions = volunteerSvc.getSocietyOptionsByCurrentUser(model.societyOptions);
                    model.societyOptions = societyOptions;
                    model.searchOptions.societyCode = societyOptions[0].codeKey;
                    model.searchOptions.commissionId = model.viceOpOrg[0].organizationId;
                    model.commissions = helperSvc.getFilteredArray(model.commissions, 'id', model.searchOptions.commissionId, true);
                }
                else if (!model.isAdmin) {
                    var societyOptions = volunteerSvc.getSocietyOptionsByCurrentUser(model.societyOptions);
                    model.societyOptions = societyOptions;
                    model.searchOptions.societyCode = societyOptions[0].codeKey;
                    defaultData.societyCode = societyOptions[0].codeKey;
                }

                programAreaOptions = filterProgramAreas();
                model.programAreaOptions = programAreaOptions;
                defaultData.programAreaOptions = programAreaOptions;
                model.showSpinnerSearch = false;

                if (volunteerSvc.data.searchOptions) {
                    model.searchOptions = volunteerSvc.data.searchOptions;
                    model.programAreaOptions = filterProgramAreas();

                    model.volunteers = volunteerSvc.data.volunteers;
                    model.resultMsg = volunteerSvc.data.resultMsg;
                }

                model.isDataReady = true;

            });

        }
    };

    module.controller('volunteerCtrl', volunteerCtrl);

}(angular.module('volunteer')));