(function (module) {

    var programSvc = function ($http, $q, $filter, helperSvc, amsConst, oauth) {
        var apiPath = amsConst.webApiUrl + '/odata/Program({0})';
        var apiSearchPath = amsConst.webApiUrl + '/odata/GetProgramSearch({0})';
        var apiProgramDetailPath = amsConst.webApiUrl + '/odata/ProgramDetail({0})';
        var apiProgramNamePath = amsConst.webApiUrl + '/odata/GetProgramName({0})';
        var factory = {};

        var errorMsgs = {
            CAMPUS_TYPE_REQUIRED: "Program Campus Type selection is required.",
            ONLINE_LEARNING_REQUIRED: "Online learning availability is required.",
            PROGRAM_LINK_REQUIRED: "At least one program information link is required.",
            NATIVE_PRORAM_NAME_REQUIRED: "Native program name is required for programs outside of the US.",
            NATIVE_DEGREE_REQUIRED: "Native degree designation is required for programs outside of the US.",
            ABET_CHOOSES_DISCIPLINE: "Requested ABET to choose discipline.",
            ABET_CHOOSES_SOCIETY: "Requested ABET to choose society."
        };

        factory.errorMsgs = errorMsgs;
        
        factory.data = {
            currentPrograms: null
        }

        var queryBase = '?$filter=';
        var sortByProgramName = '&$orderby=programDetailDto/programName';
        var includeCurrentPrograms = '';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getProgramsByOrganizationId = function (id, isCurrent) {
            return $http.get(apiSearchPath.format(null) + queryBase + buildOrgIdFilter(id) + buildCurrentFilter(isCurrent)).then(onSuccess, onFailure);
        };

        factory.checkForExistingProgramInOrganization = function (orgId, programName, degreeCode, commissionId, currentProgramId) {
            var commissionIds = Array.isArray(commissionId) ? commissionId : [commissionId];
            currentProgramId = currentProgramId || 0;
            var checkFuncApiTemplate = amsConst.webApiUrl + "/odata/CheckForExistingProgramInOrganization(orgId={0},programName='{1}',degreeCode='{2}',commissionIds=[{3}],currentProgramId={4})";
            var checkFuncApiPath = checkFuncApiTemplate.format(orgId, factory.escapeProgramName(programName), factory.escapeProgramName(degreeCode), commissionIds, currentProgramId);
            return $http.get(checkFuncApiPath).then(onSuccess, onFailure);
        };

        factory.getProgramById = function (id, isCurrent) {
            return $http.get(apiSearchPath.format(null) + queryBase + buildProgramIdFilter(id) + buildCurrentFilter(isCurrent)).then(onSuccess, onFailure);
        };

        factory.getProgramByDetailId = function (id, isCurrent) {
            var tempFilter = '';

            if (isCurrent !== undefined) {
                tempFilter = ' and isCurrent eq ' + isCurrent;
            }

            return $http.get(apiProgramDetailPath.format(null) + queryBase + buildProgramDetailIdFilter(id) + tempFilter).then(onSuccess, onFailure);
        };

        factory.searchProgramsByOrganizationId = function (id, getCurrentOnly) {
            var onSuccessLocal = function (response) {
                if (response.status == 200) {
                    var items = response.data.value;
                    var programs = [];

                    for (var i = 0; i < items.length; i++) {
                        var isProgramAdded = false;
                        var program = items[i];
                        var dateRange = program.accreditationDates;

                        if ((program.lastAction === 'NEW' || program.lastAction == 'Not Found') && program.isCurrent === true && program.accreditationDates === null) {
                            program.isCurrentProgram = true;
                            programs.push(program);
                            isProgramAdded = true;
                        }
                        else if (dateRange) {
                            var dates = dateRange.split('-');
                            var endDateParts = dates[dates.length - 1].split('/');

                            if (endDateParts.length === 3) {
                                var cycleStartDate = helperSvc.getAcademicYearStartDate();
                                var accEndDate = new Date(endDateParts[2], endDateParts[0] - 1, endDateParts[1]);

                                if (accEndDate >= cycleStartDate && program.isCurrent === true) {
                                    program.isCurrentProgram = true;
                                    programs.push(program);
                                    isProgramAdded = true;
                                }
                            }
                        }

                        if (!isProgramAdded) {
                            program.isCurrentProgram = false;
                            programs.push(program);
                            isProgramAdded = true;
                        }
                    }

                    response.data.value = programs;

                    return response.data;
                }
            };

           
            var promise = $http.get(apiSearchPath.format(null) + queryBase + buildOrgIdFilter(id) + buildCurrentFilter(getCurrentOnly)).then(onSuccessLocal, onFailure);

            promise.then(function (data) {
                factory.data.currentPrograms = $filter('isCurrentProgram')(data.value);
            });
            

            return promise;
        };

        factory.searchProgramNames = function (searchText, commissionIds, maxResults) {

            // this option is moved to server
            //if (typeof maxResults == "undefined") {
            //    maxResults = 16;
            //}
            //var tempFilter = '?$top=' + maxResults;


            commissionIdsCSV = Array.isArray(commissionIds) ? commissionIds.join(',') : commissionIds;
            searchText = factory.escapeProgramName(searchText);
            return $http.get(apiProgramNamePath.format('searchText=\'' + searchText + '\',commissionIdsCSV=\'' + commissionIdsCSV + '\'')).then(onSuccess, onFailure);
        };

        factory.create = function (newProgram) {
            return $http.post(apiPath.format(null), newProgram).then(onSuccess, onFailure);
        };

        factory.update = function (program) {
            return $http.put(apiPath.format(program.programId), program).then(onSuccess, onFailure);
        };

        factory.delete = function (programId) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(programId)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        var buildFilter = function (property, parameter) {
            return property + ' ' + parameter;
        };

        var buildOrgIdFilter = function (parameter) {
            return buildFilter("organizationId eq", parameter);
        };

        var buildProgramIdFilter = function (parameter) {
            return buildFilter("programId eq", parameter);
        };

        var buildProgramDetailIdFilter = function (parameter) {
            return buildFilter("programDetailId eq", parameter);
        };

        var buildCurrentFilter = function (parameter) {
            if (parameter == undefined) {
                return '';
            }

            return buildFilter(" and isCurrent eq", parameter);
        };

        factory.validateProgram = function (program, outsideUS) {
            errors = [];

            if (!program.requestToTerminate) {
                if (!program.programCampusTypeId) {
                    errors.push(errorMsgs.CAMPUS_TYPE_REQUIRED);
                }
                if (!program.distanceLearningId || program.hasAllOnlineOption === undefined || program.hasAllOnlineOption === null) {
                    errors.push(errorMsgs.ONLINE_LEARNING_REQUIRED);
                }
                if (!program.programLinks || program.programLinks.length === 0) {
                    errors.push(errorMsgs.PROGRAM_LINK_REQUIRED);
                }
            }
            if (outsideUS && (!program.alternateName)) {
                errors.push(errorMsgs.NATIVE_PRORAM_NAME_REQUIRED);
            }
            if (outsideUS && (!program.alternateDegreeCode)) {
                errors.push(errorMsgs.NATIVE_DEGREE_REQUIRED);
            }

            //admin validation
            if (oauth.isAdmin()) {
                var requestedABETChooseDiscipline = false;
                var requestedABETChooseSociety = false;

                if (program.disciplines === undefined || program.disciplines === null || program.disciplines.length === 0) {
                    requestedABETChooseDiscipline = true;
                    requestedABETChooseSociety = true;
                } else {
                    for (var j = 0; j < program.disciplines.length; j++) {
                        var discipline = program.disciplines[j];

                        if (!discipline.disciplineId) {
                            requestedABETChooseDiscipline = true;
                            break;
                        }
                        if (!discipline.societyId) {
                            requestedABETChooseSociety = true;
                            break;
                        }
                    }
                }
                if (requestedABETChooseDiscipline) {
                    errors.push(errorMsgs.ABET_CHOOSES_DISCIPLINE);
                }
                if (requestedABETChooseSociety) {
                    errors.push(errorMsgs.ABET_CHOOSES_SOCIETY);
                }
            }

            return errors;
        }

        factory.escapeProgramName = function (programName) {
            // Allow &, : in program name but ignore other characters which are also forbidden in URLS by default.
            var newProgramName = programName.trim();

            newProgramName = newProgramName.replace(/&/g, '~');
            newProgramName = newProgramName.replace(/:/g, '`');
            newProgramName = newProgramName.replace(/\//g, '_');
            newProgramName = newProgramName.replace(/\'/g, '^');
            newProgramName = newProgramName.replace(/[<>*%#\\]/g, '');
            
            return newProgramName;
        }

        return {
            errorMsgs: factory.errorMsgs,
            getProgramsByOrganizationId: factory.getProgramsByOrganizationId,
            getProgramById: factory.getProgramById,
            getProgramByDetailId: factory.getProgramByDetailId,
            searchProgramsByOrganizationId: factory.searchProgramsByOrganizationId,
            searchProgramNames: factory.searchProgramNames,
            checkForExistingProgramInOrganization : factory.checkForExistingProgramInOrganization,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            validateProgram: factory.validateProgram,
            escapeProgramName: factory.escapeProgramName,
            data: factory.data
        };
    };
    module.factory('programSvc', programSvc);

})(angular.module('common'));