(function (module) {

    var documentsEditCtrl = function ($scope, $uibModalInstance, parent, $stateParams, helperSvc, alertSvc, documentSvc, typeConstSvc, documentTypes) {
        var model = $scope;

        model.parent = parent;
        model.title = 'Edit ' + (model.parent.title || 'Documents');
        model.showType = parent.showType;
        model.referenceId = parent.referenceId;
        model.categoryId = parent.categoryId;
        model.documentTypes = typeConstSvc.getDocumentTypesByCategory(model.categoryId);
        model.data = documentSvc.data;

        model.isInsertMode = false;

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleInsertMode = function () {
            model.currentInformation = getNewDocument();
            model.uploadProgress = -1;
            model.isInsertMode = !model.isInsertMode;
        };

        function getNewDocument() {
            var newDoc = { referenceId: model.referenceId };
            if (model.documentTypes && model.documentTypes.length === 1) {
                newDoc.documentTypeId = model.documentTypes[0].documentTypeId;
            }
            return newDoc;
        }

        model.save = function () {
            documentSvc.uploadDocument(model.currentInformation).then(
                function (response) {
                    $scope.$evalAsync(function ($scope) {
                        model.toggleInsertMode();
                        alertSvc.addModalAlertWarning("Document successfully uploaded.");
                    });
                },
                function (response) {
                    alertSvc.addModalAlertWarning("Document could not be uploaded at this time.");
                },
                function (evt) {
                    // Math.min is to fix IE which reports 200% sometimes
                    model.uploadProgress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }
            );
        };
    };

    module.controller('documentsEditCtrl', documentsEditCtrl);

}(angular.module('common')));