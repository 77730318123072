(function (module) {

    var getOrganizationUserSvc = function ($http, $q, amsConst, organizationTypes) {
        var apiPath = amsConst.webApiUrl + '/odata/OrganizationUser({0})';
        var apiViewPath = amsConst.webApiUrl + '/odata/GetOrganizationUserView({0})';
        var apiDesignationPath = amsConst.webApiUrl + '/odata/GetOrganizationUserViewWithDesignation({0})';
        var apiLimittedAccessPath = amsConst.webApiUrl + '/odata/GetOrganizationUserViewByUserId';
        var factory = {};
        var queryBase = '?$filter=';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getOrganizationUserByUserId = function () {
            var orgTypes = [organizationTypes.INSTITUTION, organizationTypes.MEMBERSOCIETY, organizationTypes.ASSOCIATESOCIETY, organizationTypes.STATEBOARD];
            var orgTypeFilter = orgTypes.join(' or ').replace(/[0-9]/g, "organizationTypeId eq $&");
            orgTypeFilter = orgTypeFilter ? "(" + orgTypeFilter + ")" : orgTypeFilter;
            var isCurrentFilter = "isActive eq true " + (orgTypeFilter ? "and " : "");
            return $http.get(apiLimittedAccessPath.format(null) + queryBase + isCurrentFilter + orgTypeFilter + '&$select=organizationId,organizationName,organizationTypeId,organizationTypeName,roleId').then(onSuccess, onFailure);
        };

        factory.getContactsByOrgAndRole = function (orgId, roleId) {
            return $http.get(apiViewPath.format('organizationId=' + orgId) + queryBase + 'organizationId eq ' + orgId + ' and roleId eq ' + roleId + '&$expand=personDto($expand=personEmailDto,personTelephoneDto,personAddressDto)').then(onSuccess, onFailure);
        };

        factory.getExcomMembers = function (orgId, roleIds, reviewTeamId) {
            var userPath = amsConst.webApiUrl + '/odata/GetExcomMembers(organizationId={0}, reviewTeamId={1})';
            return $http.get(userPath.format(orgId, reviewTeamId)).then(onSuccess, onFailure);
        }

        factory.getExcomMembersWithConflicts = function (orgId, roleIds, reviewTeamId) {
            //apiViewPath
            var userPath = amsConst.webApiUrl + '/odata/GetExcomMembersWithConflicts(organizationId={0}, reviewTeamId={1})';

            //var isActive = true;
            

            //if (roleIds && roleIds.length > 0) {
            //    var rolesText = ''
            //    for (var i = 0; i < roleIds.length; i++) {
            //        if (i === 0)
            //            rolesText = roleIds[i];
            //        else {
            //            rolesText += ' or roleId eq ' + roleIds[i];
            //        }
            //    }

            //    roleFilter = ' and (roleId eq ' + rolesText + ')';
            //}

           // return $http.get(userPath.format(orgId, reviewTeamId) + '?$filter=isActive eq true' + roleFilter).then(onSuccess, onFailure);
            return $http.get(userPath.format(orgId, reviewTeamId)).then(onSuccess, onFailure);
        }

        factory.getContactsDesignationViewByOrgAndRole = function (orgId, roleId) {
            return $http.get(apiDesignationPath.format('organizationId=' + orgId) + queryBase + 'organizationId eq ' + orgId + ' and roleId eq ' + roleId + '&$expand=designationViewModels').then(onSuccess, onFailure);
        };

        factory.create = function (newContact) {
            return $http.post(apiPath.format(null), newContact).then(onSuccess, onFailure);
        };

        factory.update = function (contact) {
            return $http.put(apiPath.format(contact.organizationUserId), contact).then(onSuccess, onFailure);
        };

        factory.delete = function (contact) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(contact.organizationUserId)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        return {
            getContactsByOrgAndRole: factory.getContactsByOrgAndRole,
            getContactsDesignationViewByOrgAndRole: factory.getContactsDesignationViewByOrgAndRole,
            getOrganizationUserByUserId: factory.getOrganizationUserByUserId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            getExcomMembersWithConflicts: factory.getExcomMembersWithConflicts,
            getExcomMembers: factory.getExcomMembers
        };
    };
    module.factory('getOrganizationUserSvc', getOrganizationUserSvc);

})(angular.module('contacts'));