(function () {
    var app =
            angular.module('program', [
                //dependencies
                'common',
                //'organization',
                'ngRoute',
                'ui.bootstrap',
                'ui.unique',
                'ngSanitize'
            ]);
}());