(function (module) {

    var userGroupsAndRolesCtrl = function (membershipSvc, $uibModal, organizationSvc, eventSvc, $scope, $q, $state, personSvc, oauth) {
        var model = this;
        var parent = $scope.$parent.model;
        model.title = "User Groups and Roles";
        model.organization = parent.organization;
        model.organizationId = parent.organizationId;
        model.canImpersonate = oauth.canImpersonate();

        model.editUserGroupsAndRoles = function (personId) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/organization/templates/userGroupsAndRolesEdit.html',
                size: 'lg',
                controller: 'userGroupsAndRolesEditCtrl',
                resolve: {
                    organization: function () {
                        return model.organization;
                    }
                }
            });
        };

        model.goToPerson = function (personId) {
            $state.go('person.single', { personId: personId });
        }

        model.impersonate = personSvc.impersonate;
        
        model.data = membershipSvc.data;
    };

    module.controller('userGroupsAndRolesCtrl', userGroupsAndRolesCtrl);

}(angular.module('organization')));