(function (module) {

    var accordionCtrl = function (helperSvc) {
        var model = this;
        model.isOpen = [];          //contains boolean status of each accordion group
        model.allExpanded = false;  //corresponds to master toggle for all accordion groups
        model.openSingleAccordion = false;

        model.setOpenVal = function (arr, i) {
            model.isOpen[0] = (i === 0 && arr.length === 1) ? true : model.isOpen[i];
        };

        model.toggleExpand = function (arr) {
            if (arr) {
                model.allExpanded = !model.allExpanded;
                for (var i = 0; i < arr.length; i++) {
                    model.isOpen[i] = model.allExpanded;
                }
            }
        };

        model.toggleIcon = function () {
            var trueCase = 'fa fa-minus-square',
                falseCase = 'fa fa-plus-square';

            return toggle(trueCase, falseCase);
        };

        model.toggleMsg = function () {
            var trueCase = 'Collapse All',
                falseCase = 'Expand All';

            return toggle(trueCase, falseCase);
        };
        
        var toggle = function (trueCaseVal, falseCaseVal) {
            return (model.allExpanded) ? trueCaseVal : falseCaseVal;
        };
    };

    module.controller('accordionCtrl', accordionCtrl);

}(angular.module('common')));