(function (module) {

    var filter = function (helperSvc) {
        return function (items, filters) {
            var matches = [];
            var filteredMatches = [];
            var ALL = 'all';
            var EMPTY = '';
            var ASSIGNED = 'assigned';
            var UNASSIGNED = 'unassigned';
            var NULL = null;

            var expMin = null;
            var expMax = null;

            var useAssignmentFilter = false;
            var useProgramAreaFilter = false;
            var useExperienceFilter = false;
            var useAvailablePevsFilter = false;
            var useNonUSVisitFilter = false;
            var useExcludeCommissionersFilter = false;
            var useExcludeVisitLastYearFilter = false;
            var useExcludeVisit2YearsFilter = false;
            var excludeUnconfirmedTerms = false;
            var useExcludeRTRequired = false;
            var useTravelAvailabilityFilter = false;
            var useVirtualAvailabilityFilter = false;

            var setMatchFalse = function () {
                match = false;
            };

            if (filters.assignment && filters.assignment !== ALL) {
                useAssignmentFilter = true;
            }

            if (filters.programAreas && filters.programAreas.length > 0) {
                useProgramAreaFilter = true;
            }

            if (filters.experienceRange) {
                var expMin = filters.experienceRange.firstVal;
                var expMax = filters.experienceRange.secondVal;

                if (Number.isInteger(expMin) || Number.isInteger(expMax)) {
                    useExperienceFilter = true;

                    if (expMin && expMax && expMin > expMax) {
                        var oldExpMin = expMin;
                        expMin = expMax;
                        expMax = oldExpMin;
                    }
                }
            }

            if (filters.rangeStartDate || filters.rangeEndDate) {
                useAvailablePevsFilter = true;
            }

            if (filters.hasHadNonUsVisit) {
                useNonUSVisitFilter = true;
            }

            if (filters.excludeCommissioners) {
                useExcludeCommissionersFilter = true;
            }

            if (filters.excludeHadVisitLastYear) {
                useExcludeVisitLastYearFilter = true;
            }

            if (filters.excludeHadVisitTwoYearsAgo) {
                useExcludeVisit2YearsFilter = true;
            }

            if (filters.excludeUnconfirmedTerms) {
                excludeUnconfirmedTerms = true;
            }

            if (filters.excludeRTRequired) {
                useExcludeRTRequired = true;
            }

            //filter array based on specified properties
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var match = true;

                if (useExperienceFilter) {
                    if ((Number.isInteger(expMin) && item.totalVisitExp < expMin) || (Number.isInteger(expMax) && item.totalVisitExp > expMax)) {
                        setMatchFalse();
                    }
                }

                if (useAvailablePevsFilter) {
                    //get the pevs that  have no conlfict with dates in the range
                    var pevDateConflicts = [];

                    if (item.availabilityDate && item.availabilityDate[0].unavailableDates && item.availabilityDate[0].unavailableDates.length > 0) {
                        angular.forEach(item.availabilityDate[0].unavailableDates, function (date) {
                            pevDateConflicts.push({ start: date.startDate, end: date.endDate })
                        });
                    }

                    for (var x = 0; x < pevDateConflicts.length; x++) {
                        var conflictStart = new Date(pevDateConflicts[x].start);
                        var conflictEnd = new Date(pevDateConflicts[x].end);
                        var rangeStart = new Date(filters.rangeStartDate);
                        var rangeEnd = new Date(filters.rangeEndDate);
                        if (rangeEnd < rangeStart) {
                            var temp = rangeStart;
                            rangeStart = rangeEnd;
                            rangeEnd = temp;
                        }

                        //starts at beggining or after start range date and ends after or on the end date
                        if (conflictStart <= rangeEnd && conflictEnd >= rangeStart) {
                            setMatchFalse();
                        }
                    }
                }

                if (useProgramAreaFilter) {
                    var matchedWithAny = false;
                    //keep track of if any of the program areas ara a match. 
                    //if any then continue
                    for (var y = 0; y < filters.programAreas.length; y++) {
                        if (item.disciplineId === filters.programAreas[y].disciplineId) {
                            matchedWithAny = true;
                        }
                    }

                    if (!matchedWithAny) {
                        setMatchFalse();
                    }

                }

                if (useAssignmentFilter) {
                    if (item.assignedReviews === NULL && filters.assignment === ASSIGNED) {
                        setMatchFalse();
                    }

                    if (item.assignedReviews !== NULL && filters.assignment === UNASSIGNED) {
                        setMatchFalse();
                    }
                }

                if (useNonUSVisitFilter) {
                    if (item.nonUsVisitExp <= 0) {
                        setMatchFalse();
                    }
                }

                if (useExcludeCommissionersFilter) {
                    if (item.isCommissioner === true) {
                        setMatchFalse();
                    }
                }

                if (useExcludeVisitLastYearFilter) {
                    if (item.hasLastYearVisit === true) {
                        setMatchFalse();
                    }
                }

                if (useExcludeVisit2YearsFilter) {
                    if (item.hasVisitTwoYearsAgo === true) {
                        setMatchFalse();
                    }
                }

                if (excludeUnconfirmedTerms) {
                    if (!item.isConfirmedTermsAgreement) {
                        setMatchFalse();
                    }
                }

                if (useExcludeRTRequired) {
                    if (item.isRecertificationTrainingRequired === true) {
                        setMatchFalse();
                    }
                }

                if (match) {
                    matches.push(item);
                }
            }

            //filter out duplicate people before sending list back
            filteredMatches = helperSvc.uniqueArrayByKey(matches, 'personId');
            matches = filteredMatches;
            return matches;
        };
    };

    module.filter('volunteers', filter);

}(angular.module('assignment')));



