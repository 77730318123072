(function (module) {

    var programReviewCampusSvc = function ($http, $q, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/ProgramReviewCampus()';
        var filterBase = '?$filter=';
        var includeCampusAddressDto = '&$expand=addressDto';
        var factory = {};

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getCampusByProgramReviewId = function (id) {
            return $http.get(apiPath + queryBase + buildProgramReviewIdFilter(id) + includeCampusAddressDto).then(onSuccess, onFailure);
        };

        factory.getCampusesByProgramIdAndReviewTeamId = function (programId, reviewTeamId) {
            return $http.get(apiPath + filterBase + 'programId eq ' + programId + ' and reviewTeamId eq ' + reviewTeamId).then(onSuccess, onFailure);
        };

        factory.create = function (newCampus) {
            return $http.post(apiPath.format(null), newCampus).then(onSuccess, onFailure);
        };

        factory.update = function (campus) {
            return $http.put(apiPath.format(campus.addressId), campus).then(onSuccess, onFailure);
        };

        factory.delete = function (campusId) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(campusId)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        var buildFilter = function (property, parameter) {
            return property + ' ' + parameter;
        };

        var buildProgramReviewIdFilter = function (parameter) {
            return buildFilter("programReviewId eq", parameter);
        };

        return {
            getCampusByProgramReviewId: factory.getCampusByProgramReviewId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };
    module.factory('programReviewCampusSvc', programReviewCampusSvc);

})(angular.module('program'));