(function (module) {

    var impersonateCtrl = function (impersonateId, impersonateUsername, $uibModalInstance, currentUser, alertSvc, oauth, helperSvc, $timeout) {
        var model = this;
        model.title = 'Impersonate';
        model.isImpersonate = !(currentUser.profile.impersonateUsername == null || currentUser.profile.impersonateUsername == '');
        model.startOrStop = model.isImpersonate ? 'end your' : 'start an';
        model.btnTxt = model.isImpersonate ? 'End Session' : 'Start session';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.returnToAdmin = function () {
            alert('aaa');
        };

        model.confirm = function () {
            if (impersonateUsername) {
                oauth.impersonateReturn(impersonateUsername, model.password).then(
                    closeAndRedirect('admin'),
                    sendError()
                )
            } else {
                oauth.impersonate(currentUser.profile.username, model.password, impersonateId).then(
                    closeAndRedirect('user'),
                    sendError()
                )
            }
        };

        function closeAndRedirect(page) {
            return helperSvc.isFirefox() ?
                function () {
                    $uibModalInstance.close();
                    $timeout(() => {
                        location.assign('/#/' + page);
                        $timeout(() => {
                            location.reload();
                        }, 300);
                    });
                } :
                function () {
                    $uibModalInstance.close();
                    location.assign('/#/' + page);
                    location.reload();
                }
        }

        function sendError() {
            return function (error) {
                console.error(error);
            }
        }
    };

    module.controller('impersonateCtrl', impersonateCtrl);

}(angular.module('security')));