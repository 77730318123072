(function (module) {
    var profileCtrl = function ($stateParams, personSvc, personUserSvc, eventSvc, barSvc, $state, $q) {
        var model = this;

        model.isDataReady = false;
        model.personId = parseInt($stateParams.personId);
        model.person = {};
        barSvc.showBar();

        var loadData = function() {
            var personPomise = personSvc.getPersonMdlById(model.personId);
            var userPromise = personUserSvc.getUserByPersonId(model.personId);

            $q.all([userPromise, personPomise]).then(function (data) {
                var personMdlObj = data.pop();
                var excludeNullContactTypeIds = true;

                model.user = data.pop();
                personSvc.copyPersonMdlData(model.person, personMdlObj, excludeNullContactTypeIds);

                eventSvc.broadcast('loadPersonGeneral');
                barSvc.hideBar();
                model.isDataReady = true;
            });
        }();
    };
    module.controller('profileCtrl', profileCtrl);

} (angular.module('user')));