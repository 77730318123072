(function (module) {

    var templateRoot = "/Apps/report/templates/";

    module.directive('reportView', function (oauth) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reportView.html',
            scope: {
                reportName: '=',
                parameters: '=?'
            },
            link: function (scope, element, attrs, ctrl) {
                var ELEMENT_ID = '#viewerContainer';
                var urlAmsRptAPI = "/webapi/odata/RequestSsoAmsRpt";
                scope.token = null;
                console.log('reportName', scope.reportName);
                console.log('parameters', scope.parameters);

                oauth.requestSso(urlAmsRptAPI).then(function (data) {
                    if (data) {
                        scope.token = data.data;

                        var viewer = GrapeCity.ActiveReports.Viewer(
                            {
                                element: ELEMENT_ID,
                                report: {
                                    id: "Statement Preview JC",
                                    parameters: [
                                        {
                                            name: 'StatementId',
                                            value: 14
                                        }
                                    ]
                                },
                                reportService: {
                                    url: 'http://amsrpt.abet.org/api/',
                                    securityToken: scope.token
                                },
                                uiType: 'desktop',
                                documentLoaded: function reportLoaded() {
                                },
                                reportLoaded: function (reportInfo) {
                                },
                                error: function (error) {
                                    console.log(error);
                                }
                            });
                    };
                });

            }
        };
    });

}(angular.module('report')));