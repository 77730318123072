(function (module) {

    var reviewCtrl = function ($stateParams, $state, $uibModal, $q, $scope, barSvc, reviewSvc, organizationSvc, helperSvc, reviewTeamSvc, dictionary,
            reviewCommissionFilter, codeSvc, eventSvc, programReviewSvc, reviewTeamMemberSvc, reviewTeamVisitObserverSvc, reviewTeamGroupSvc, changeSvc, noteSvc, noteTypes,
            reviewTeamProcessTrackingSvc, reviewSimultaneousOptionSvc, reviewJointOptionSvc, typeConstSvc) {

        var model = this;
        
        setDataNotReady();

        model.isCurrent = true;
        model.reviewGroups = [];
        model.currentReviewGroups = [];
        model.pastReviewGroups = [];
        model.noteTypes = noteTypes;
        //$stateParams only captures url params that apply to the state where the controller is defined.
        //the child state reviews.detail had an url param /:reviewTeamId that needed to be captured, but the controller was in the parent state.
        //link to the bug/discussion https://github.com/angular-ui/ui-router/issues/136
        model.preSelectedReviewTeamId = $stateParams.reviewTeamId ? parseInt($stateParams.reviewTeamId) : parseInt($state.params.reviewTeamId); 
        model.selectedReviewTeamId;
        model.noCurrentReviewsMsg = 'No ongoing reviews';
        model.noPastReviewsMsg = 'No past reviews';
        model.noFilterResultsMsg = 'No reviews match this criteria';
        model.organizationId = parseInt($stateParams.organizationId);
        model.waitingMsg = 'Select a review';
        model.showSpinner = false;

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });        

        eventSvc.listen(refreshReviewNotes, 'refreshReviewNotes', $scope);
        eventSvc.listen(refreshCurrentReviews, 'refreshAllReviews', $scope);

        setNoSelectedReview();

        model.addReview = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/reviewEdit.html',
                size: 'lg',
                controller: 'reviewEditCtrl',
                resolve: {
                    review: function () {
                        return undefined;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.editJointOptions = function (reviewJointOptionDtos) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/jointOptionsEdit.html',
                size: 'md',
                controller: 'jointOptionsEditCtrl',
                resolve: {
                    reviewJointOptionDtos: function () {
                        return reviewJointOptionDtos;
                    },
                    parent: function () {
                        return model;
                    }
                }
            })

        }

        model.editSimultaneousVisits = function (reviewSimultaneousOptionDtos) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/simultaneousVisitsEdit.html',
                size: 'md',
                controller: 'simultaneousVisitsEditCtrl',
                resolve: {
                    reviewSimultaneousOptionDtos: function () {
                        return reviewSimultaneousOptionDtos;
                    },
                    commissions: function () {
                        return model.commissions;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };



        model.selectReview = function (review) {
            if (review.reviewTeamId) {
                model.selectedReviewTeamId = review.reviewTeamId;
                model.isReviewSelected = false;
                model.waitingMsg = null;
                $state.go('reviews.detail', { organizationId: model.organizationId, reviewTeamId: model.selectedReviewTeamId });
                                            
                barSvc.showSpinner();

                var reviewNotesDataSource = {
                    dataHolder: model,
                    dataLocationName: 'notes',
                    svcCallback: noteSvc.getNotesByReferenceId,
                    svcCallbackArguments: [review.reviewTeamId, noteTypes.REVIEW],
                    odataResource: true
                }

                var reviewTeamDataSource = {
                    dataHolder: reviewTeamSvc,
                    dataLocationName: 'data.reviewTeam',
                    svcCallback: reviewTeamSvc.getReviewTeamByReviewTeamIdOdata,
                    svcCallbackArguments: [review.reviewTeamId, true],
                    optionalCallback: function (model) {
                        if (model.selectedReview != null || model.selectedReview != undefined) {
                            model.isReviewSelected = true;
                        }
                    },
                    optionalCallbackArguments: [model],                
                    odataResource: true
                }

                var programsDataSource = {
                    dataHolder: programReviewSvc,
                    dataLocationName: 'data.programs',
                    svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                    svcCallbackArguments: [review.reviewTeamId],                   
                    orderByProperty: 'programReviewId',
                    odataResource: true 
                }

                var teamChairsAndEditorsDataSource = {
                    dataHolder: reviewTeamMemberSvc,
                    dataLocationName: 'data.teamChairsAndEditors',
                    svcCallback: reviewTeamMemberSvc.getTeamChairsAndEditorsByReviewTeamIdOdata,
                    svcCallbackArguments: [review.reviewTeamId],
                    odataResource: true
                }

                var visitObserversDataSource = {
                    dataHolder: reviewTeamVisitObserverSvc,
                    dataLocationName: 'data.visitObservers',
                    svcCallback: reviewTeamVisitObserverSvc.getVisitObserversByReviewTeamIdOdata,
                    svcCallbackArguments: [review.reviewTeamId],
                    odataResource: true
                }

                var changeHistoryDataSource = {
                    dataHolder: changeSvc,
                    dataLocationName: 'data.changeHistory',
                    svcCallback: changeSvc.getChangeHistoryByReviewTeamIdOdata,
                    svcCallbackArguments: [review.reviewTeamId, true],
                    odataResource: true

                };

                var reviewStatusDataSource = {
                    dataHolder: reviewTeamProcessTrackingSvc,
                    dataLocationName: 'data.proccessTracking',
                    svcCallback: reviewTeamProcessTrackingSvc.getReviewStatusesByReviewTeamIdOdata,
                    svcCallbackArguments: [review.reviewTeamId],
                    odataResource: true
                }

                var reviewTeamGroupDataSource = {
                    dataHolder: reviewTeamGroupSvc,
                    dataLocationName: 'data.teamGroups',
                    svcCallback: reviewTeamGroupSvc.getReviewTeamGroupsByReviewTeamIdOdata,
                    svcCallbackArguments: [review.reviewTeamId],
                    odataResource: true
                }

                var dataSource = [
                    changeHistoryDataSource,
                    reviewTeamDataSource,
                    programsDataSource,
                    teamChairsAndEditorsDataSource,
                    visitObserversDataSource,
                    reviewStatusDataSource,
                    reviewTeamGroupDataSource,
                    reviewNotesDataSource
                ];

                var selectedPromise = helperSvc.getData(dataSource);
                selectedPromise.then(function (data) {
                    model.selectedReview = reviewTeamSvc.data.reviewTeam;
                    barSvc.hideSpinner();
                    model.isReviewSelected = true;
                    model.isDataReady = true;
                });
            }
        };

        model.isSelected = function (review) {
            return review.reviewTeamId === model.selectedReviewTeamId;
        };

        model.jointOptionsExist = function (group) {
            var itemExists = false;
            var reviewJointOptionDtos = group.reviewJointOptionDtos;

            if (reviewJointOptionDtos) {
                if (reviewJointOptionDtos.length > 0) {
                    itemExists = true;
                }
            }

            return itemExists;
        };

        model.simultaneousOptionsExist = function (group) {
            var itemExists = false;
            var reviewSimultaneousOptionDtos = group.reviewSimultaneousOptionDtos;

            if (reviewSimultaneousOptionDtos) {
                if (reviewSimultaneousOptionDtos.length > 0) {
                    itemExists = true;
                }
            }

            return itemExists;
        };

        model.showReviews = function (isCurrent) {
            model.isCurrent = isCurrent;

            if (model.isCurrent) {
                model.reviewGroups = model.currentReviewGroups;
                model.noPastReviews = false;
                if (model.reviewGroups.length < 1) {
                    model.noCurrentReviews = true;
                }
            }
            else {
                model.reviewGroups = model.pastReviewGroups;
                model.noCurrentReviews = false;
                if (model.reviewGroups.length < 1) {
                    model.noPastReviews = true;
                }
            }
        };

        model.buildCommissionFilter = function (commissions) {
            var filters = [];

            //add selected filters
            for (var i = 0; i < commissions.length; i++) {
                var currentCommission = commissions[i];

                if (currentCommission.isSelected == true) {
                    filters.push(currentCommission.codeKey);
                }
            }

            if (filters.length !== 0) {
                //filter current and past reviews
                reviewCommissionFilter(model.currentReviewGroups, filters);
                reviewCommissionFilter(model.pastReviewGroups, filters);
            }
            else {
                //show all current and past reviews
                showAllReviews(model.currentReviewGroups);
                showAllReviews(model.pastReviewGroups);
            }
        };

        model.getYearSpan = helperSvc.getYearSpan;
        model.isEmpty = helperSvc.isEmpty;
        model.getCommissionAbbreviationById = typeConstSvc.getCommissionAbbreviationById;

        var showAllReviews = function (reviewGroups) {

            model.hasNoResults = false;
            reviewGroups.hasNoResults = false;

            for (var i = 0; i < reviewGroups.length; i++) {
                reviewGroups[i].hasNoResults = false;
                var reviewTeams = reviewGroups[i].reviewTeamDtos;

                for (var k = 0; k < reviewTeams.length; k++) {
                    var review = reviewTeams[k];
                    review.isHidden = false;
                }
            }
        };      

        function setNoSelectedReview() {
            model.selectedReview = {};
            model.isReviewSelected = false;
        }

        function setDataNotReady() {
            model.isDataReady = false;
            barSvc.showBar();
        }

        function getReviewTeamIdArg() {
             var args = [model.selectedReview.reviewTeamId] 

            return args;
        }

        activate();

        function activate() {
            model.currentReviewGroups = [];
            model.pastReviewGroups = [];
            var isCurrent;

            var codeSourceArray = [
                {
                    dataHolder: model,
                    svcCallback: codeSvc.getCommissions,
                    dataLocationName: 'commissions',
                    helperCallback: helperSvc.getResults,
                    optionalCallback: function (model) {
                        for (var i = 0; i < model.commissions.length; i++) {
                            model.commissions[i].isSelected = false;
                        }
                    },
                    optionalCallbackArguments: [model]
                },
                {
                    dataHolder: model,
                    svcCallback: codeSvc.getReviewTypes,
                    dataLocationName: 'reviewTypes',
                    helperCallback: helperSvc.getResults
                }

            ];


            helperSvc.getData(codeSourceArray);

            var organizationDataSource = {

                dataHolder: organizationSvc,
                dataLocationName: 'data.organization',
                svcCallback: organizationSvc.getOrgByIdOdata,
                svcCallbackArguments: [model.organizationId],
                odataResource: true

            }

            var reviewDataSource = {
                dataHolder: reviewSvc,
                dataLocationName: 'data.currentReviews',
                svcCallback: reviewSvc.getReviewsByOrganizationId,
                svcCallbackArguments: [model.organizationId, isCurrent = true],
                odataResource: true
            }

            var pastReviewsDataSource = {
                dataHolder: reviewSvc,
                dataLocationName: 'data.pastReviews',
                svcCallback: reviewSvc.getReviewsByOrganizationId,
                svcCallbackArguments: [model.organizationId, isCurrent = false],
                odataResource: true
            }

            var dataSource = [organizationDataSource, reviewDataSource, pastReviewsDataSource];
            var dataPromise = helperSvc.getData(dataSource);
            dataPromise.then(function () {
                //show current or past reviews in main list section
                model.showReviews(model.isCurrent);
                model.organization = organizationSvc.data.organization;
                model.title = model.organization.currentOrganizationDetailDto.organizationName;
                model.currentReviewGroups = reviewSvc.data.currentReviews;
                model.pastReviewGroups = reviewSvc.data.pastReviews;
                model.reviewGroups = model.currentReviewGroups;
                if (isNaN(model.preSelectedReviewTeamId)) {
                        barSvc.hideSpinner();
                        model.isReviewSelected = false;
                        model.isDataReady = true;

                } else {
                    var review = { reviewTeamId: model.preSelectedReviewTeamId };
                    model.selectReview(review);
                }

                barSvc.hideBar();
            });
        }

        function refreshCurrentReviews() {
            reviewSvc.getReviewsByOrganizationId(model.organizationId, model.isCurrent).then(function () {
                model.refreshReviewGroups();
                eventSvc.broadcast('refreshJointOptions');
            });
            
        }

        model.refreshReviewGroups = function () {
            model.currentReviewGroups = reviewSvc.data.currentReviews;
            model.pastReviewGroups = reviewSvc.data.pastReviews;

            if(model.isCurrent){
                model.reviewGroups = model.currentReviewGroups;

            }else{
                model.reviewGroups = model.pastReviewGroups;
            }
        }

        function refreshReviewNotes() {
            //have to have the notedatasource in here instead on the global scope beacuse model.selectedReviewTeamId will be NaN or undefined 
            //which causes errors in the html the way the notes directive is called which is different from the rest of the directives. 
            //Difference from above data source object is it calls the model.selectedReviewTeamId
            var reviewNotesDataSource = {
                dataHolder: model,
                dataLocationName: 'notes',
                svcCallback: noteSvc.getNotesByReferenceId,
                svcCallbackArguments: [model.selectedReviewTeamId, noteTypes.REVIEW],
                odataResource: true
            }

            var dataSourceArray = [reviewNotesDataSource];
            helperSvc.getData(dataSourceArray);
        }

    };

    module.controller('reviewCtrl', reviewCtrl);

}(angular.module('review')));

//todovarner move this and give a better name?
angular.module('review').filter('reviewCommission', function (dictionary, helperSvc) {
    return function (reviewGroups, criteria) {
        //merge criteria simplified searching
        var text = criteria.join(' ');

        if (reviewGroups) {
            reviewGroups.hasNoResults = true;

            //loop through array of groups
            for (var i = 0; i < reviewGroups.length; i++) {
                reviewGroups[i].hasNoResults = true;
                var reviewTeams = reviewGroups[i].reviewTeamDtos;

                //loop through reviewTeams associated with each group
                for (var k = 0; k < reviewTeams.length; k++) {
                    var review = reviewTeams[k];
                    review.isHidden = true;

                    //if match found show item, otherwise hide item
                    if ( helperSvc.strContains(text, review.commissionId) ) {
                        review.isHidden = false;
                        reviewGroups[i].hasNoResults = false;
                        reviewGroups.hasNoResults = false;
                    }
                }
            }
        }
    };
});