(function (module) {

    var upcomingDateAvailabilityEditCtrl = function ($scope, $stateParams, $uibModalInstance, helperSvc, parent, upcomingAvailabilitySvc, alertSvc, availabilityTypes) {
        var model = $scope;

        model.parent = parent;
        model.volunteerId = parseInt($stateParams.volunteerId);
        model.currentYear = parent.currentYear;
        model.title = 'Edit Date Availability';
        model.formatDate = helperSvc.formatDate;
        model.availabilityTypes = availabilityTypes;

        model.tempDates = [];

        model.save = function () {
            helperSvc.validateForm(validateDateAvailability);

            if (model.errors.length > 0) {
                return;
            }

            updateDateAvailability();
            resetErrors();
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.add = function () {
            newDateReset();
            model.isInsertMode = true;
        };

        model.addNewDate = function () {
            helperSvc.validateForm(validateDateRanges);

            if (model.errors.length > 0) {
                return;
            }

            model.selectedInformation.volunteerAvailabilityDateListDtos.push(model.newDate);
            //model.tempDates.push(model.newDate); Gives duplicate entries
            newDateReset();
            model.isInsertMode = false;
        };

        model.cancel = function () {
            newDateReset();
            resetErrors();
            model.isInsertMode = false;
        };

        model.delete = function (date) {
            var index = model.selectedInformation.volunteerAvailabilityDateListDtos.indexOf(date);
            model.selectedInformation.volunteerAvailabilityDateListDtos.splice(index, 1);
            model.tempDates.splice(index, 1);
        };

        model.selectAll = function () {
            model.isInsertMode = false;
            model.selectedInformation.volunteerAvailabilityDateListDtos = [];
            newDateReset();
            resetErrors();
        };

        model.selectSome = function () {
            if (model.tempDates !== null && model.tempDates.length > 0) {
                model.selectedInformation.volunteerAvailabilityDateListDtos = angular.copy(model.tempDates);
            } else {
                model.selectedInformation.volunteerAvailabilityDateListDtos = [];
            }

            
            model.isInsertMode = true;
            newDateReset();
            resetErrors();
        };

        model.selectNone = function () {
            model.isInsertMode = false;
            model.selectedInformation.volunteerAvailabilityDateListDtos = [];

            newDateReset();
            model.newDate.startDate = new Date(Date.UTC(model.currentYear, 8, 1));
            model.newDate.endDate = new Date(Date.UTC(model.currentYear, 11, 31)); 

            model.selectedInformation.volunteerAvailabilityDateListDtos.push(model.newDate);
            resetErrors();
        }

        model.displayAddNew = function () {
            return model.selectedInformation.availabilityTypeId === model.availabilityTypes.SOME;
        }

        activate();

        function activate() {
           
            reset();
            resetErrors();
        }
      
        function updateDateAvailability() {

            var dateAvailabilityDto = {
                volunteerAvailabilityDateId: model.dateAvailabilityId,
                year: model.currentYear,
                volunteerId: model.volunteerId,
                availabilityTypeId: model.selectedInformation.availabilityTypeId,
                volunteerAvailabilityDateListDtos: model.selectedInformation.volunteerAvailabilityDateListDtos
            };

            if (model.dateAvailabilityId === 0) {
                upcomingAvailabilitySvc.createDateUnavailability(dateAvailabilityDto).then(function () {
                    alertSvc.addAlertSuccess('Date availability updated.');
                    model.close();
                });
            } else {
                upcomingAvailabilitySvc.updateDateUnavailability(dateAvailabilityDto).then(function () {
                    alertSvc.addAlertSuccess('Date availability updated.');
                    model.close();
                });
            }
        }

        function validateDateAvailability() {

            resetErrors();

            if (model.selectedInformation.availabilityTypeId === 0) {
                model.errors.push('Please select availability.');
            }
            if (model.selectedInformation.availabilityTypeId === model.availabilityTypes.SOME && model.selectedInformation.volunteerAvailabilityDateListDtos.length === 0) {
                model.errors.push('Please create a list of unavailable dates.');
            }
        }

        function validateDateRanges() {

            resetErrors();
            
            var minDate = new Date(Date.UTC(upcomingAvailabilitySvc.currentYear, 8, 1));
            var maxDate = new Date(Date.UTC(upcomingAvailabilitySvc.currentYear, 11, 31));
            //include the 31?
            maxDate.setDate(maxDate.getDate() + 1);
            var newStartDate = new Date(model.newDate.startDate);
            var newEndDate = new Date(model.newDate.endDate);

            //because IE...
            newStartDate = newStartDate.toUTCString();
            newEndDate = newEndDate.toUTCString();
            newStartDate = new Date(newStartDate);
            newEndDate = new Date(newEndDate);

            if (!(newStartDate >= minDate) || !(newStartDate < maxDate)) {
                model.errors.push('The start date is not in range of the current/upcoming cycle.');
            }
            if (!(newEndDate > minDate) || !(newEndDate <= maxDate)) {
                model.errors.push('The end date is not in range of the current/upcoming cycle.');
            }
            if (newStartDate > newEndDate || newEndDate < newStartDate) {
                model.errors.push('Start date must occur before end date.');
            }
        }

        function newDateReset() {

            model.newDate = {
                startDate: null,
                endDate: null,
                volunteerAvailabilityDateId: model.dateAvailabilityId
            };
        }

        function reset() {

            model.isInsertMode = false;
            model.selectedInformation = {};
            model.startDate = getSeptemberDate();

            if (upcomingAvailabilitySvc.data.dateData) {
                var dateData = upcomingAvailabilitySvc.data.dateData;

                model.selectedInformation.availabilityTypeId = dateData.availabilityTypeId || 0;
                model.selectedInformation.volunteerAvailabilityDateListDtos = angular.copy(dateData.volunteerAvailabilityDateListDtos) || [];
                model.tempDates = model.selectedInformation.volunteerAvailabilityDateListDtos;
            } else {
                model.selectedInformation.availabilityTypeId = 0;
                model.selectedInformation.volunteerAvailabilityDateListDtos = [];
            }

            model.dateAvailabilityId = upcomingAvailabilitySvc.data.dateData.volunteerAvailabilityDateId || 0;
        }

        function getSeptemberDate() {
            var currentDate = new Date();
            var septDate;
            if (currentDate.getMonth() > 8)
                septDate = new Date(currentDate.getFullYear() + 1, 8, 1);
            else 
                septDate = new Date(currentDate.getFullYear(), 8, 1);
            return septDate;           
        }

        function resetErrors(){
            model.errors = [];
        }
    };

    module.controller('upcomingDateAvailabilityEditCtrl', upcomingDateAvailabilityEditCtrl);

}(angular.module('volunteer')));