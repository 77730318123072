(function (module) {

    var programDetailSvc = function (odataServiceFactory) {
        var config = {
            apiPath: '/ProgramDetail',
            keyName: 'programDetailId',
            foreignKeyName: 'programId',
            dataCollectionName: 'programNameChanges'
        };

        var factory = new odataServiceFactory(config);

        return {
            data: factory.data,
            getProgramDetailsByProgramId: factory.getByForeignKey,
            getProgramDetailByProgramDetailId: factory.get,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('programDetailSvc', programDetailSvc);

})(angular.module('program'));