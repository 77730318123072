(function (module) {

    var userGroupsAndRolesEditCtrl = function (organization, $scope, $uibModalInstance, 
        membershipSvc, personSvc, organizationSvc, roleSvc, eventSvc, helperSvc, alertSvc, typeConstSvc, barSvc, $q, contactRoleTypes) {

        var model = $scope;
        var getFilteredArray = helperSvc.getFilteredArray;
        var excludedRoles = [contactRoleTypes.MEMBERPROGRAMEVALUATOR,
                             contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE];

        model.title = 'User Groups and Roles';
        model.formatDate = helperSvc.formatDate;
        model.organizationTypes = typeConstSvc.getOrganizationTypes();
        model.organizationId = organization.organizationId;
        model.organizationTypeId = organization.organizationTypeId;
        model.organizationTypeName = organization.organizationTypeName;
        model.positionTerms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        model.societies = [];
        model.isInsertMode = false;
        model.otherOptionsAvailable = false;
        model.showHistory = true;
        model.roles = undefined;
        model.selectedUserId = 0;

        model.data = membershipSvc.data;

        activate();

        model.showHistoricalUsers = function () {
            model.showHistory = false;       
        }

        model.hideHistoricalUsers = function () {
            model.showHistory = true;
        }

        model.onSelectRole = function () {
            model.newModel.designationTypeName = getDesignationTypeName(model.newModel.roleId);
            model.newModel.chosenDesignations = [];
            getDesignations();
        };

        model.edit = function (userRole) {
            var newEdit = angular.copy(userRole);

            resetNewUser();

            model.personData = {
                personName: newEdit.firstName + " " + newEdit.lastName
            }

            model.newModel = {
                organizationUserId: newEdit.organizationUserId,
                isRoleOnly: newEdit.isRoleOnly,
                selectedUserId: newEdit.userId,
                roleId: newEdit.roleId,
                designationTypeName: newEdit.designationTypeName,
                chosenDesignations: newEdit.designationViewModels,
                startDate: new Date(newEdit.startDate),
                endDate: newEdit.endDate ? new Date(newEdit.endDate) : undefined,
                note: newEdit.note,
                positionTerm: newEdit.positionTerm,
                society: newEdit.societyId,
                replacedOrganizationUserId: newEdit.replacedOrganizationUserId
            }

            getDesignations();

            model.isEditMode = true;
            model.isInsertMode = true;
        }

        model.addNew = function () {
            model.isInsertMode = true;
            model.isEditMode = false;
            resetNewUser();
        };

        model.closeInsert = function () {
            reset();
        };       

        model.saveRow = function () {
          
            var newDesignations = [];

            for (var i = 0; i < model.newModel.chosenDesignations.length; i++) {
                newDesignations.push({ organizationUserId: 0, designationId: model.newModel.chosenDesignations[i].designationId });
            }

            var newRole = {
                organizationUserId: model.newModel.organizationUserId,
                organizationId: model.organizationId,
                userId: model.newModel.selectedUserId,
                roleId: model.newModel.roleId,
                startDate: model.newModel.startDate,
                endDate: model.newModel.endDate,
                isRoleOnly: model.newModel.isRoleOnly,
                note: model.newModel.note,
                positionTerm: model.newModel.positionTerm,
                societyId: model.newModel.society ? model.newModel.society : null,
                replacedOrganizationUserId: model.newModel.replacedOrganizationUserId,
                organizationUserDesignationDtos: newDesignations,
                isDeleted: false
            };

            if (model.isEditMode) {
                membershipSvc.update(newRole).then(function (data) {
                    model.closeInsert();
                    alertSvc.addModalAlertSuccess("User role successfully updated.");
                });
            } else {
                membershipSvc.insert(newRole).then(function (data) {
                    //refreshMembershipList(model.organizationId);
                    model.closeInsert();
                    //updateTimestamp();
                    alertSvc.addModalAlertSuccess("User role successfully created.");
                });
            }


        };

        model.deleteRow = function (orgUser) {
            alertSvc.confirmDelete(orgUser.lastName + ', ' + orgUser.firstName, deleteFunc);

            function deleteFunc() {
                membershipSvc.delete(orgUser.organizationUserId).then(function (data) {
                    alertSvc.addModalAlertSuccess("User role successfully deleted.");
                });
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        function activate() {

            reset();

            var historicalMembers = membershipSvc.getByOrganizationIdHistorical(model.organizationId);
            //var memlist = membershipSvc.getByOrganizationId(model.organizationId, false);
            var roles = roleSvc.getRolesByOrganizationTypeId(model.organizationTypeId);
            var societies = null;

            if (model.organizationTypeId == model.organizationTypes.BOARDOFDIRECTORS || model.organizationTypeId == model.organizationTypes.COMMISSION || model.organizationTypeId == model.organizationTypes.COMMITTEE)
            {
                societies = organizationSvc.getOrganizationsByType([organizationTypes.ASSOCIATESOCIETY, organizationTypes.MEMBERSOCIETY]);
                model.otherOptionsAvailable = true;
            }

            $q.all([roles, societies, historicalMembers]).then(function (data) {

                roles = data.shift();
                societies = data.shift();
                if (roles) {
                    roles = helperSvc.getValue(roles);
                    model.roles = getFilteredArray(roles, "roleId", excludedRoles);
                }
                if (societies) {
                    model.societies = helperSvc.getValue(societies.data);
                }

            });
        }

        function getDesignations() {
            if (model.newModel.designationTypeName != null) {
                roleSvc.getDesignationsByTypeName(model.newModel.designationTypeName, model.organizationId).then(function (data) {
                    model.designations = data.value;
                });
            }else{
                model.designations = [];
                model.newModel.chosenDesignations = [];
            }
        }

        function getDesignationTypeName(roleId) {
            if (model.roles) {
                for (var i = 0; i < model.roles.length; i++) {
                    if (model.roles[i].roleId === roleId) {
                        return model.roles[i].designationTypeName;
                    }
                }
            }
        }

        function reset() {
            model.isInsertMode = false;
            model.isEditMode = false;
            model.designations = [];
            resetNewUser();

        }

        function resetNewUser() {
            model.personData = {};
            model.newModel = {
                organizationUserId: 0,
                isRoleOnly: false,
                selectedUserId: null,
                roleId: null,
                designationTypeName: null,
                chosenDesignations: [],
                startDate: null,
                endDate: null,
                positionTerm: null,
                society: null,
                replacedOrganizationUserId: null
            }
        }

    };



    module.controller('userGroupsAndRolesEditCtrl', userGroupsAndRolesEditCtrl);

}(angular.module('organization')));