(function (module) {
    var loginCtrl = function ($state, $stateParams, $document, oauth, currentUser, loginRedirect, requestResetSvc, Idle, barSvc, environmentSvc) {

        var model = this;
        var myAbetUrl = 'https://amsapp.abet.org/#/sso/abetpublic';

        model.title = 'Accreditation Management System';
        model.welcomeTitle = 'Welcome';
        model.acctTitle = 'Don\'t Have an Account?';
        model.loginTitle = 'Login';
        model.user = currentUser.profile;
        model.username = model.user.username || '';
        model.password = '';
        model.emailContainer = {};
        model.passwordContainer = {};
        model.previousUrl = null;
        model.errorMsg = null;
        model.successMsg = null;
        model.showSpinner = false;
        model.isStage = environmentSvc.isStage();

        model.isRequiredReset = function () {
            return new Date() > new Date(2022, 11, 11);
        }


        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        model.view = {
            loggedIn: false,
            loggedOut: false,
            requestReset: false,
            resetPassword: false
        };

        model.links = [
            { name: 'abet.org', url: 'https://www.abet.org/' },
            { name: 'myabet', url: 'https://amsapp.abet.org/#/sso/abetpublic' }
        ];

        model.accordionPanels = [
            { title: 'Institution Users', templateUrl: 'institutionUser.html' },
            { title: 'PEV Candidates', templateUrl: 'pevCandidate.html' },
            { title: 'Other Users', templateUrl: 'otherUser.html' }
        ];

        model.btnPressed = {
            reset: false,
            request: false
        };

        model.open = function (status, successMsg, errorMsg) {
            $state.go('root', {
                loginStatus: status,
                successMsg: successMsg,
                errorMsg: errorMsg,
                
            });
        };

        model.openDashboard = function () {
            var state = oauth.isAdmin() ? 'admin' : 'user';
            $state.transitionTo(state);
        };

        model.signOut = function () {
            oauth.logout().then(
                function () {
                    setView('loggedOut');
                });
        };

        model.login = function (form) {
            model.errorMsg = null;
            model.successMsg = null;

            barSvc.showSpinner();

            if (form.$valid) {
                oauth.login(model.username, model.password)
                    .then(function () {
                        loginRedirect.setLastPath(model.previousUrl);
                        model.previousUrl = null;

                        setView('loggedIn');
                        loginRedirect.redirectPreLogin(currentUser);
                        barSvc.hideSpinner();
                     })
                     .catch(function (error) {
                         if (error.status === 400) {
                             model.errorMsg = error.data.error_description;
                         } else {
                             var errorStatus = error.status ? ' (' + error.status + ')' : '';
                             model.errorMsg = 'Exception error while authenticating' + errorStatus;
                         }

                         barSvc.hideSpinner();
                     });

                model.password = '';

                if (!Idle.running()) Idle.watch();
            }
        };

        model.requestUsername = function () {
            model.btnPressed.request = true;
            requestResetSvc.requestUserName(model.emailContainer).then(function () {
                reset('Your username has been sent as requested.','request');
            }).catch(function (error) {
                model.btnPressed.request = false;
                handleEmailError(error);
            });
        };

        model.resetPassword = function () {
            model.btnPressed.reset = true;
            requestResetSvc.requestPassword(model.emailContainer).then(function () {
                reset('<p>A temporary password has been sent from info@abet.org. If you don\'t receive an email within 10 minutes please contact ABET.</p><p>Enter the password without any leading or trailing spaces.</p>','request');
            }).catch(function (error) {
                model.btnPressed.reset = false;
                handleEmailError(error);
            });
        };

        model.savePassword = function () {

            if (currentUser.profile.loggedIn) {
                requestResetSvc.resetPassword(model.passwordContainer).then(function (data) {
                    //model.signOut();
                    oauth.logout().then(
                        function () {
                            setView('loggedOut');
                            model.open('resetComplete', 'Your password has been successfully reset.');
                        });

                    //model.open('resetComplete', 'Your password has been successfully reset.');
                    //reset('Your password has been successfully reset.');
                }, function(error)
                    {
                        model.errorMsg = error.data.error.innererror.message;
                    }
                )
                .catch(function (error) { model.errorMsg = 'Exception error (' + error.status + ')' });
            } else {
                model.open('request', null, 'You are not logged in. Please enter your email address and reset password before proceeding.');
            }
        };

        var activate = function () {
            var status = $stateParams.loginStatus;
            var loginView = model.user.loggedIn ? 'loggedIn' : 'loggedOut';

            model.errorMsg = $stateParams.errorMsg ? $stateParams.errorMsg : null;
            model.successMsg = $stateParams.successMsg ? $stateParams.successMsg : null;

            if (status) {
                if (status == 'request') {
                    model.loginTitle = 'Retrieve Login';
                    setView('requestReset');
                } else if (status == 'reset') {
                    model.loginTitle = 'Reset Password';
                    setView('resetPassword');
                } else if (status == 'logout' || status == 'resetComplete') {
                    model.signOut();
                } else {
                    model.open();
                }
            } else {
                setView(loginView);
            }

            checkLoginStatus(status);
        }();

        function reset (msg, status) {
            model.emailContainer = {};
            model.passwordContainer = {};
            model.errorMsg = null;
            model.open(status, msg);
        }

        function handleEmailError(error) {
            model.errorMsg = (error.status === 521) ? 'Email address not found.' : 'Exception error (' + error.status + ')';
        }

        function checkLoginStatus(status) {
            model.previousUrl = $document[0].referrer;

            var cameFromPublicSite = contains(model.previousUrl, 'abet.org') && !contains(model.previousUrl, 'admin.') && (status != 'reset') ;

            if (model.user.loggedIn && cameFromPublicSite) {
                window.location.href = myAbetUrl;
            }
        }

        function contains(string, key) {
            return string.search(key) > -1;
        }

        function setView (view) {
            for (var property in model.view) {
                model.view[property] = (view == property);
            }
        }
    };

    module.controller('loginCtrl', loginCtrl);

} (angular.module('root')));