(function (module) {

    var societySeatSvc = function (odataSvc, helperSvc) {
        var factory = {};
        var key = 'commissionerSocietySeatId';
        var apiPath = '/CommissionerSocietySeat';


        factory.data = {
            societySeats: []
        }

        factory.getCurrentSocietySeats = function (societyId, noStoredData) {
            var nominationYear = helperSvc.getCommissionerNominationYear();
            if (societyId && typeof societyId === 'string') {
                societyId = parseInt(societyId);
            }
            var oSvc = odataSvc.get();
            var odata = oSvc.getSource(apiPath, key).odata();
            odata.filter('cycleYear', nominationYear);
            if (societyId) {
                odata.filter('societyId', societyId);
            }
            odata.query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc);
            }

            return oSvc.getDeferred().promise;
        };

        function resolveData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.societySeats = data;
            });
        }
 
        return {
            data: factory.data,
            getCurrentSocietySeats: factory.getCurrentSocietySeats
        };
    };

    module.factory('societySeatSvc', societySeatSvc);

})(angular.module('nomination'));