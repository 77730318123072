(function (module) {

    var programMdl = function () {
        var programMainFields = ['programId', 'organizationId', 'commissionId', 'retroactiveDate'];
        var programDetailFields = ['programDetailId', 'programId', 'programName', 'alternateName', 'isCurrent', 'startDate', 'endDate', 'degreeCode', 'degreeLevelCode', 'alternateDegreeCode', 'degreeLevelName', 'note'];

        function programMdl(data, useDtos) {
            addProperties(this, programMainFields, data);

            //use dto if requested otherwise add properties directly to current object
            if (useDtos) {
                this.programDetailDto = {};

                if (data.programDetailDto) {
                    this.programDetailDto = data.programDetailDto;
                }
                else {
                    addProperties(this.programDetailDto, programDetailFields, data);
                }
            }
            else {

                if (data.programDetailDto) {
                    addProperties(this, programDetailFields, data.programDetailDto);
                }
                else {
                    addProperties(this, programDetailFields, data);
                }
            }
        }

        //iterate over control array and add matching properties to object
        var addProperties = function (obj, fields, data) {
            for (var i = 0; i < fields.length; i++) {
                if (data.hasOwnProperty(fields[i])) {
                    obj[fields[i]] = data[fields[i]];
                }
            }
        };

        //iterate over control array and remove matching properties from object
        var removeProperties = function (obj, fields) {
            for (var i = 0; i < fields.length; i++) {
                if (obj.hasOwnProperty(fields[i])) {
                    delete obj[fields[i]];
                }
            }
        };

        //returns text, or combined string of properties
        var getReadableProperty = function (text, data, fieldArray) {
            var output = text;
            var builtString = output.buildString(data, fieldArray);

            if (builtString) {
                output = builtString;
            }

            return output;
        };

        //returns text, or combined string of properties from first item in array
        var getReadablePropertyArr = function (text, dataArr, fieldArr) {
            var output = text;

            if (dataArr && dataArr.length > 0) {
                output = getReadableProperty(text, dataArr[0], fieldArr);
            }

            return output;
        };

        return programMdl;
    };

    module.factory('programMdl', programMdl);

}(angular.module('program')));
