(function (module) {

    var loginRedirect = function () {

        var loginUrl = '/';
        var lastPath = '';
        var stateName = null;
        var stateParams = null;
        var myAbetUrl = 'https://amsapp.abet.org/#/sso/abetpublic';
        var stagingDomain = 'stage.abet.org';

        this.setLoginUrl = function (value) {
            loginUrl = value;
        };

        this.$get = function ($q, $location, $injector) {
            return {

                responseError: function (response) {
                    if (response.status == 401) {
                        var state = $injector.get('$state');

                        lastPath = $location.path();
                        state.go('root');
                    }
                    return $q.reject(response);
                },

                redirectPreLogin: function (currentUser) {
                    var state = $injector.get('$state');
                    var environmentSvc = $injector.get('environmentSvc');

                    if (currentUser.profile.isLocked == "true" || currentUser.profile.isPasswordUpdated == 'false') {
                        state.go('root', {
                            loginStatus: 'reset',
                            successMsg: null,
                            errorMsg: currentUser.profile.isPasswordUpdated == 'false' ? 'Please change your password so that it contains at least 12 characters.' : null
                        })
                    } else if (state.current.name || state.current.params) {
                        stateParams = state.current.params || 'root';
                        state.go(state.current.name, stateParams);
                        stateName = null;
                        stateParams = null;
                    } else if (lastPath && lastPath.startsWith('http')) {
                        if (lastPath.search('abet.boardeffect.com') > -1) {

                            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent))
                                window.location.href = 'abet.boardeffect.com';
                            else
                                window.location.href = 'https://amsapp.abet.org/#/sso/boardeffect';
                        }
                        else if (lastPath.search('www.abet.org') > -1) {
                            window.location.href = myAbetUrl;
                        }
                        else {
                            window.location.href = lastPath;
                        }

                        lastPath = "";
                    } else if (environmentSvc.isProd()) {
                        window.location.href = myAbetUrl;
                    }

                },

                setProperties: function (state, params) {
                    lastPath = state.url;
                    stateName = state.name;
                    stateParams = params;
                },

                setLastPath: function (value) {
                    lastPath = value;
                }
            };
        };
    };

    module.provider("loginRedirect", loginRedirect);

    module.config(function ($httpProvider) {
        $httpProvider.interceptors.push("loginRedirect");
    });

}(angular.module("root")));