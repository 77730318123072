(function (module) {

    var npcEditProgramVisitDatesModalCtrl = function (program, programChange, $scope, $q, $uibModalInstance, helperSvc, codeSvc, alertSvc, npcSvc) {
        var model = $scope;

        model.title = 'Edit Visit Dates for ' + program.programDetailDto.programName;
        model.isSaving = false;
        model.formatDate = helperSvc.formatDate;
        model.newDates = {
            startDate: helperSvc.formatDate(programChange.visitStartDate, true),
            endDate: helperSvc.formatDate(programChange.visitEndDate, true)
        }
        

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;

            programChange.visitStartDate = model.newDates.startDate;
            programChange.visitEndDate = model.newDates.endDate;

            npcSvc.updateNpcProgramChange(programChange).then(function () {
                $uibModalInstance.close();
            });

        }

        function activate() {
            model.isDataReady = true;
        }

        activate();
    };
    module.controller('npcEditProgramVisitDatesModalCtrl', npcEditProgramVisitDatesModalCtrl);

}(angular.module('npc')));