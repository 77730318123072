(function (module) {

    var educationInfoEditCtrl = function ($scope, $uibModalInstance, parent, helperSvc, volunteerHelperSvc, pevAppSvc, pevAppValidationSvc, alertSvc, educationInfoSvc, typeConstSvc) {
        var model = $scope;
        var originalRecord = null;
        var checkIsVolunteer = true;
        var isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.volunteerId = volunteerHelperSvc.getVolunteerId();
        model.isInsertMode = false;
        model.isEditMode = false;
        model.noResults;
        model.collegeVerificationTypes = typeConstSvc.getCollegeVerificationTypes();
        model.currentInformation = {};

        if (isApplication) {         
            model.data = angular.copy(pevAppSvc.volunteerEducationData);
        } else {
            model.data = educationInfoSvc.data;
        }

        model.getSaveButtonLabel = function () {
            return model.isEditMode ? 'Update Education' : 'Add Education';
        };

        model.edit = function (education) {
            helperSvc.scrollToModalTop();
            originalRecord = angular.copy(education);

            model.currentInformation = angular.copy(education);
            model.isEditMode = true;
            model.isInsertMode = true;
        };

        model.save = function () {
            updateModel();
            helperSvc.validateForm(validateEducationInfo);

            if (model.errors.length > 0) return;

            delete model.currentInformation.endYear;

            if (isApplication) {
                if (model.isEditMode) {
                    model.data.educationInfo[getEducationIndex(originalRecord)] = model.currentInformation;
                } else {
                    model.data.educationInfo.push(model.currentInformation);
                }

                var application = angular.copy(pevAppSvc.data.application);
                application.applicationJson.volunteerEducation = model.data.educationInfo;

                pevAppSvc.update(application).then(onSuccess, onFailure);
                
            } else if (!model.currentInformation.volunteerEducationId) {
                educationInfoSvc.create(model.currentInformation).then(onSuccess, onFailure);
            } else {
                educationInfoSvc.update(model.currentInformation).then(onSuccess, onFailure);
            }

            function onSuccess () {
                pevAppValidationSvc.invokeValidation();
                model.toggleInsertMode();
                alertSvc.addModalAlertSuccess("Education information successfuly saved.");
            }
            function onFailure () {
                alertSvc.addModalAlertWarning('Education information could not be saved at this time.');
            }
        };

        model.delete = function (item) {
            alertSvc.confirmDelete((item.major + ' (' + item.degreeName  + ')'), deleteFunc, item)

            function deleteFunc() {
                if (isApplication) {
                    var index = getEducationIndex(item);

                    if (index > -1) {
                        var application = angular.copy(pevAppSvc.data.application);
                        model.data.educationInfo.splice(index, 1);
                        application.applicationJson.volunteerEducation = model.data.educationInfo;
                        pevAppSvc.update(application).then(onSuccess,onFailure);
                    } else {
                        alertSvc.addModalAlertWarning("The selected language could not be found.");
                    }
                } else {
                    educationInfoSvc.delete(item).then(onSuccess,onFailure);
                }
            }
            function onSuccess() {
                pevAppValidationSvc.invokeValidation();
                alertSvc.addModalAlertSuccess('Education information deleted.');
            }
            function onFailure (reason) {
                if (reason === 'user cancelled') {
                    alertSvc.addModalAlertInfo('Delete cancelled.');
                } else {
                    alertSvc.addModalAlertWarning('Education information could not be deleted at this time.');
                }
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleInsertMode = function () {
            volunteerHelperSvc.toggleInsertMode(model);
        };

        var activate = function () {
            volunteerHelperSvc.setErrors(model);
        }();

        function getEducationIndex(currentRecord) {
            return model.data.educationInfo.findIndex(function (record) {
                return record.collegeDirectoryId == currentRecord.collegeDirectoryId &&
                       record.degreeName == currentRecord.degreeName;
            });
        }
        
        function validateEducationInfo() {
            var errors = [];

            if (isDuplicate()) {
                errors.push('The edcuation information entered is already listed.');
            };

            model.errors = errors;
        }

        function isDuplicate() {
            var duplicate = model.data.educationInfo.find(function (item) {
                var isInstitutionMatched = volunteerHelperSvc.isInstitutionMatched(item, model.currentInformation);

                var isDegreeNameMatched = (item.degreeName.toLowerCase().trim() === model.currentInformation.degreeName.toLowerCase().trim());

                var isMajorMatched = (item.major.toLowerCase().trim() === model.currentInformation.major.toLowerCase().trim());

                var isYearMatched = (item.degreeYear === model.currentInformation.degreeYear);

                return isInstitutionMatched && isDegreeNameMatched && isMajorMatched && isYearMatched;
            });

            var duplicated = (duplicate !== undefined && duplicate !== null);

            return duplicated;
        }

        // Note: not sure if this works with ampersands in name, e.g. 'Missouri School of Barbering & Hairstyling-St. Louis'
        function updateModel() { 
            if (!isApplication) model.currentInformation.volunteerId = model.volunteerId;
            if (model.currentInformation.collegeDirectoryDto) {
                // Using college directory entry; set collegeDirectoryId at parent level.
                model.currentInformation.collegeDirectoryId = model.currentInformation.collegeDirectoryDto.collegeDirectoryId;
            } else {
                // Using new college entry; update collegeVerificationDto's referenceId and set type.
                model.currentInformation.collegeDirectoryId = null;
            }
        }
    };

    module.controller('educationInfoEditCtrl', educationInfoEditCtrl);

}(angular.module('volunteer')));