(function (module) {

    var volunteerHelperSvc = function (helperSvc, $uibModal, $state, $stateParams, volunteerSvc, volunteerDataAccessTypes, pevAppSvc, oauth) {
        var factory = {};

        var emptyRecord = { endYear: null };

        factory.toggleModes = function (model, activeItemName) {
            model.isInsertMode = !model.isInsertMode;

            if (model.isInsertMode) {
                model.isCreateMode = true;
                model.isUpdateMode = false;

                setActiveItem(model, activeItemName);
                factory.setErrors(model);
                setFormPristine(model);

            } else if (!model.isInsertMode) {
                model.isCreateMode = false;
                model.isUpdateMode = false;
            }
        };

        factory.copyDataAndSetModes = function (model, activeItemName, itemToCopy) {
            helperSvc.scrollToModalTop();

            model[activeItemName] = angular.copy(itemToCopy);
            model.isInsertMode = true
            model.isCreateMode = false;
            model.isUpdateMode = true;
        };

        factory.toggleInsertMode = function (model) {
            model.isInsertMode = !model.isInsertMode;

            if (!model.isInsertMode) {
                model.isEditMode = false;
                factory.setCurrentInformation(model);
                factory.setErrors(model);
                setFormPristine(model);
            }
        };

        factory.setCurrentInformation = function (model) {
            model.currentInformation = angular.copy(emptyRecord);
        };

        factory.setErrors = function (model) {
            // Not all volunteer modals have an errors collection but we can assume it is named "errors".
            if (model.errors) {
                model.errors = [];
            }
        };

        factory.isInstitutionMatched = function (item1, item2) {
            var isInstitutionMatched = false;

            if (item1.collegeDirectoryId && item2.collegeDirectoryId) {
                isInstitutionMatched = (item1.collegeDirectoryId === item2.collegeDirectoryId);
            } else if (item1.collegeVerificationDto && item2.collegeVerificationDto) {
                // Compare name, city, country, and province/state.
                var existingName = item1.collegeVerificationDto.name ? item1.collegeVerificationDto.name.toLowerCase().trim() : null;
                var newName = item2.collegeVerificationDto.name ? item2.collegeVerificationDto.name.toLowerCase().trim() : null;

                var existingCity = item1.collegeVerificationDto.city ? item1.collegeVerificationDto.city.toLowerCase().trim() : null;
                var newCity = item2.collegeVerificationDto.city ? item2.collegeVerificationDto.city.toLowerCase().trim() : null;

                var existingCountryCode = item1.collegeVerificationDto.countryCode;
                var newCountryCode = item2.collegeVerificationDto.countryCode;

                var existingStateCode = item1.collegeVerificationDto.stateCode;
                var newStateCode = item2.collegeVerificationDto.stateCode;

                var existingProvince = item1.collegeVerificationDto.province ? item1.collegeVerificationDto.province.toLowerCase().trim() : null;
                var newProvince = item2.collegeVerificationDto.province ? item2.collegeVerificationDto.province.toLowerCase().trim() : null;

                if (existingName === newName && existingCity === newCity && existingCountryCode === newCountryCode &&
                    ((newCountryCode === 'US' && existingStateCode === newStateCode) ||
                        (newCountryCode !== 'US' && existingProvince === newProvince))) {

                    isInstitutionMatched = true;
                }
            }

            return isInstitutionMatched;
        }

        factory.openBioModal = function (volunteer, useLongBio) {
            var bioParam = useLongBio ? 'full-bio="true"' : 'short-bio="true"';
            var body = '<volunteer-page volunteer="volunteer" ' + bioParam + 'preview="true"></volunteer-page>';
            var modalInstance = $uibModal.open({
                animation: true,
                template: '<modal hide-validation-key="true" print-button="true">' +
                            '<modal-body>' + body + '</modal-body>' +
                          '</modal>',
                windowClass: 'page-color',
                size: 'xl',
                controller: 'volunteerBioPreviewCtrl',
                resolve: {
                    volunteer: function () { return volunteer; }
                }
            });
        };

        factory.allowEdit = function (volunteerDataAccessType) {
            var isAdmin = oauth.isAdmin();
            var isApplicant = pevAppSvc.isApplicationMode();
            var isVolunteer = volunteerSvc.data.isVolunteer;
            var isSocietyManager = volunteerSvc.data.isSocietyManager;
            var isSocietyLiaison = volunteerSvc.data.isSocietyLiaison;
            var isTrainingManager = volunteerSvc.data.isTrainingManager;

            if (isSocietyManager && (volunteerDataAccessType === volunteerDataAccessTypes.SOCIETYANDADMIN)) {
                return true;
            } else if (isSocietyManager && isSocietyLiaison && (volunteerDataAccessType === volunteerDataAccessTypes.SOCIETYLIAISONANDADMIN)) {
                return true;
            } else if (isSocietyManager && (isSocietyLiaison || isTrainingManager) && (volunteerDataAccessType === volunteerDataAccessTypes.SOCIETYLIASONANDTRNMGRANDADMIN)) {
                return true;
            } else if (isVolunteer && (volunteerDataAccessType === volunteerDataAccessTypes.VOLUNTEERANDADMIN)) {
                return true;
            }
            return isAdmin || isApplicant;
        };

        factory.isReadOnly = function (isPreview) {
            return !pevAppSvc.isEditButtonHidden() && (!factory.allowEdit(volunteerDataAccessTypes.VOLUNTEERANDADMIN) || isPreview);
        };

        factory.getVolunteerId = function () {
            // Look to state params
            let volunteerId = $stateParams.volunteerId;
            // Look to previously loaded PEV application or volunteer data
            // Note: Not sure if enough current users are using a current web browser that supports ?.
            volunteerId = volunteerId !== undefined ? volunteerId : pevAppSvc.isApplicationMode(true) ?
                pevAppSvc.data.application && pevAppSvc.data.application.applicationJson.volunteer && pevAppSvc.data.application.applicationJson.volunteer.volunteerId :
                volunteerSvc.data.volunteer && volunteerSvc.data.volunteer.volunteerId;
            // Look to current user (unless this is an admin user who may be viewing another volunteer's PEV application)
            volunteerId = volunteerId || volunteerId === 0 ? volunteerId : !$state.current.name.toLowerCase().includes('admin') && currentUser.profile.volunteerId;
            // Default to 0
            return volunteerId || 0;
        }

        function setActiveItem(model, activeItemName) {
            model[activeItemName] = {};
        };

        function setFormPristine(model) {
            if (model.form) {
                model.form.$setPristine();
            }
        }

        return {
            openBioModal: factory.openBioModal,
            allowEdit: factory.allowEdit,
            isReadOnly: factory.isReadOnly,
            getVolunteerId: factory.getVolunteerId,

            // NEW
            toggleModes: factory.toggleModes,
            copyDataAndSetModes: factory.copyDataAndSetModes,
            setErrors: factory.setErrors,
            isInstitutionMatched: factory.isInstitutionMatched,

            // OLD, can be removed after refactor to existing ctrls
            toggleInsertMode: factory.toggleInsertMode,
            setCurrentInformation: factory.setCurrentInformation

        };
    };

    module.factory('volunteerHelperSvc', volunteerHelperSvc);

})(angular.module('volunteer'));