(function (module) {
    'use strict';

    var pdfSvc = function ($q, $document, helperSvc) {
        var factory = {};

        factory.data = {
            pdfDataURL : ""
        }

        var defaultOptions = {
            scale: 1.1,
            useSVG: true
        };

        factory.renderPDF = function (url, container, options) {
            // url - address for loading PDF document
            // container - DOM element to render PDF within
            // options - collection of settings to control rendering:
            //               scale  - scale to render PDF at
            //               useSVG - flag to use SVG, if available, to render PDF (sharper than canvas but sometimes buggy) 

            var options = options || defaultOptions;

            if (container.innerHTML && container.innerHTML.length > 0) container.innerHTML = "";

            function renderPageByCanvas(page) {
                var canvas = document.createElement('canvas');
                var div = document.createElement('div');
                var viewport = page.getViewport(options.scale);
                div.style.width = viewport.width + 'px';
                div.style.height = viewport.height + 'px';
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                container.append(div);
                div.append(canvas);
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                container.append(canvas);
                var ctx = canvas.getContext('2d');
                var renderContext = {
                    canvasContext: ctx,
                    viewport: viewport
                };
                var promise = page.render(renderContext);
                return promise;
            }

            function renderPageBySvg(page) {
                var div = document.createElement('div');
                var viewport = page.getViewport(options.scale);
                div.style.width = viewport.width + 'px';
                div.style.height = viewport.height + 'px';
                container.append(div);
                var promise = page.getOperatorList();
                promise.then(function (opList) {
                    var svgGfx = new PDFJS.SVGGraphics(page.commonObjs, page.objs);
                    var svg = svgGfx.getSVG(opList, viewport);
                    return svg;
                }).then(function (svg) {
                    div.append(svg);
                });
                return promise;
            }

            function renderPages(pdfDoc) {
                var promises = [];
                var renderPage = helperSvc.isIE() ? renderPageByCanvas : (options.useSVG ? renderPageBySvg : renderPageByCanvas);
                for (var num = 1; num <= pdfDoc.numPages; num++) {
                    var promise = pdfDoc.getPage(num).then(renderPage);
                    promises.push(promise);
                }
                return $q.all(promises);
            }

            var renderPDFPromise = PDFJS.getDocument(url).then(
                function (pdfDoc) {
                    var renderPagesPromise = renderPages(pdfDoc);
                    return renderPagesPromise;
                }
            );

            return renderPDFPromise;
        }

        factory.defaultFonts = {
            campton: {
                normal: 'Campton Bold.ttf',
                bold: 'Campton Black.ttf',
                italics: 'Campton BlackItalic.ttf',
                bolditalics: 'Campton BoldItalic.ttf'
            },
            camptonBook: {
                normal: 'Campton Book.ttf',
                italics: 'Campton BookItalic.ttf',
            },
            camptonExtraBold: {
                normal: 'Campton ExtraBold.ttf',
                italics: 'Campton ExtraBoldItalic.ttf',
            },
            camptonExtraLight: {
                normal: 'Campton ExtraLight.ttf',
                italics: 'Campton ExtraLightItalic.ttf',
            },
            camptonLight: {
                normal: 'Campton Light.ttf',
                italics: 'Campton LightItalic.ttf',
            },
            camptonMedium: {
                normal: 'Campton Medium.ttf',
                italics: 'Campton MediumItalic.ttf',
            },
            camptonSemiBold: {
                normal: 'Campton SemiBold.ttf',
                italics: 'Campton SemiBoldItalic.ttf',
            },
            camptonThin: {
                normal: 'Campton Thin.ttf',
                italics: 'Campton ThinItalic.ttf',
            },
            calibri: {
                normal: 'Calibri.ttf',
            },
            egyptienne: {
                normal: 'EgyptienneFLT-Roman.ttf',
                bold: 'EgyptienneFLT-Bold.ttf',
                italics: 'EgyptienneFLT-Italic.ttf',
            },
            times: {
                normal: 'times.ttf'
            }
        };

        function createPDF(docDefinition, fonts) {         
            pdfMake.fonts = fonts || factory.defaultFonts;

            var pdf = pdfMake.createPdf(docDefinition);

            return pdf;
        }

        factory.downloadPDF = function (docDefinition, fonts, callbackFn, filename) {
            const pdf = createPDF(docDefinition, fonts);
            pdf.download(filename, callbackFn);
        }
        
        factory.generatePDFDataUrl = function (docDefinition, fonts, callbackFn) {
            var pdf = createPDF(docDefinition, fonts);
            pdf.getDataUrl(callbackFn);
        };

        factory.generatePDFBase64 = function (docDefinition, fonts, callbackFn) {
            var pdf = createPDF(docDefinition, fonts);
            pdf.getBase64(callbackFn);
        };

        factory.generatePDFBuffer = function (docDefinition, fonts, callbackFn) {
            var pdf = createPDF(docDefinition, fonts);
            pdf.getBuffer(callbackFn);
        };

        factory.generatePDFBlob = function (docDefinition, fonts, callbackFn) {
            var pdf = createPDF(docDefinition, fonts);
            pdf.getBlob(callbackFn);
        };

         
        //(function activate () {
        //    //used for viewing pdfs in the broswer
        //    helperSvc.loadScript("lib/pdfjs/pdfjs-1.8.188-dist/build/pdf.js");
        //})();

        return {
            data: factory.data,
            renderPDF: factory.renderPDF,
            defaultFonts: factory.defaultFonts,
            downloadPDF: factory.downloadPDF,
            generatePDFDataUrl: factory.generatePDFDataUrl,
            generatePDFBase64: factory.generatePDFBase64,
            generatePDFBuffer: factory.generatePDFBuffer,
            generatePDFBlob: factory.generatePDFBlob,
        };
    };

    module.factory('pdfSvc', pdfSvc);

})(angular.module('common'));