(function(module) {
    var currentUser = function ($window, tokenSvc) {

        var set = function (token) {
            var userData = tokenSvc.parseJwt(token);

            profile.token = token;
            profile.username = userData.userName;
            profile.namePrefix = userData.namePrefix;
            profile.firstName = userData.firstName;
            profile.lastName = userData.lastName;
            profile.personId = userData.primarysid;
            profile.userId = userData.userId;
            profile.userTasks = userData.userTasks.split(',');
            profile.userReadOnlyTasks = userData.userReadOnlyTasks.split(',');
            profile.userRoles = userData.userRoles == null ? null : (restoreUserRoleFieldNames(JSON.parse(userData.userRoles)));
            profile.impersonateUsername = userData.impersonateUsername; //admin user name
            profile.isLocked = userData.isLocked; //admin user name
            profile.volunteerId = userData.volunteerId;
            profile.isPasswordUpdated = userData.isPasswordUpdated;
            profile.failedAttempt = userData.failedAttempt;
            profile.failedAttemptStartTimestamp = userData.failedAttemptStartTimestamp;
            var issuedDate = Date.parse(userData['.issued']);
            profile.issued = isNaN(issuedDate) ? null : issuedDate;
            var expiresDate = Date.parse(userData['.expires']);
            profile.expires = isNaN(expiresDate) ? null : expiresDate;
        }

        //Due to cookie size limit(4096) some field names are reduced from server.
        var restoreUserRoleFieldNames = function (userRoles) {
            return userRoles.map((t) => {
                return {
                    organizationUserId: t.ouId,
                    personId: t.prId,
                    organizationId: t.orId,
                    roleId: t.rlId,
                    userId: t.urId,
                    designationTypeId: t.dtId,
                    oAuthOrganizationUserDesignations: t.oaDens.map((s) => { return { organizationUserDesignationId: s.od, designationId: s.d } })
                }
            });
        }

        var initialize = function () {
            var user = {
                username: "",
                token: "",
                get loggedIn() {
                    return this.token ? true : false;
                }
            };
            return user;
        };

        var profile = initialize();

        return {
            set: set,
            profile: profile
        };
    };

    module.factory("currentUser", currentUser);

}(angular.module("security")));