(function (module) {

    var refreshSiteSvc = function ($window, $timeout, helperSvc, $state) {

        var factory = {};

        factory.refresh = function () {
            // Avoid bug in older versions of Safari where location.refresh() clobbers local storage and breaks impersonation.
            if (helperSvc.isSafari10())
                return false;

            // Force refresh after a number of views to work around memory leak in UI Router caching.
            // Going back and forth between top-level routes causes all assets to be refreshed and cached.
            // E.g. going from program audit detail to tool and back.
            const refreshCounterKey = 'refreshCounter'; 
            const maxRefreshCount = 7;
            refreshCount = +$window.sessionStorage.getItem(refreshCounterKey) + 1;

            if (refreshCount <= maxRefreshCount) {
                // Not time to refresh yet.
                $window.sessionStorage.setItem(refreshCounterKey, refreshCount);
                return false;
            }

            // Reset counters, refresh site to clear ui-router cache.
            $window.sessionStorage.removeItem(refreshCounterKey);
            // Refreshing won't stop calling page from continuing to execute; schedule refresh and notify caller of result .
            $timeout(() => {
                $window.location.reload();
            }, 0);

            // Return true so caller knows to skip initialization or not display loaded data, saving time and reducing screen flickering.
            return true;
        }

        return {
            refresh: factory.refresh,
        };
    };
    module.service('refreshSiteSvc', refreshSiteSvc);
})(angular.module('common'));