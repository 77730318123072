(function (module)   {
    var reviewDetailCtrl = function ($q, $stateParams, $state, $scope, $location, barSvc, tabSvc, userReviewSvc, helperSvc, programReviewSvc,
        reviewValidationSvc, statementStorageSvc, evaluatorReportSvc, currentOrganizationHelper, oauth, currentOrganization, currentUserHelper,
        environmentSvc, reviewTypeIds, programAuditDetailSvc, programAuditSvc) {

        var model = this;
        var reviewTeamId = $stateParams.reviewTeamId;
        var programAuditIsCompleteOrDisabled = false;
        var programAuditData = [];

        model.title = null;
        model.isDataReady = false;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.isAdjunct = oauth.isAdjunct();

        function setTabs() {
            var path = 'apps/userReview/templates/';

            // Lock self study if review year is older than the current review cylce
            // For now, only locking 2017-2018 reviews
            // NOTE: hard coding currentReviewYear to 2019 looks bad but is only used (so far) to lock self study and statement tool for reviews from before 2018-19 (when those modules weren't live)
            // 2/28/2020: Restrict past reviews being accessed. 2019 Review Cycle is that oss started.

            var currentReviewYear = helperSvc.getAcademicYear() < 2019 ? 2019 : helperSvc.getAcademicYear(); //TODO: update to get current reivew cylce or filter out my review list to only show current reviews
            var userReviewYear = userReviewSvc.data.currentReview ? userReviewSvc.data.currentReview.reviewYear : 0;
            var isReviewInCurrentCycle = userReviewYear >= currentReviewYear;
            var isBeforeProgramAuditLaunch = userReviewYear < 2021;
            var isInterimReport = userReviewSvc.data.currentReviewTeam.reviewTypeCode == reviewTypeIds.INTERIMREPORT;
            var isTeamChair = userReviewSvc.isTeamChair(userReviewSvc.data.currentUserReviewTeam);
            var isPEV = userReviewSvc.isPEV(userReviewSvc.data.currentUserReviewTeam);
            var isPevOrProgramObserver = userReviewSvc.isPevOrProgramObserver(userReviewSvc.data.currentUserReviewTeam);
            var isProgramObserver = isPevOrProgramObserver && !isPEV;
            var isInstitutionUser = userReviewSvc.isInstitutionUser(userReviewSvc.data.currentUserReviewTeam);
            var isSubmitted = userReviewSvc.data.currentReviewTeam.selfStudySubmittedTimestamp != null;
            var isEditor = userReviewSvc.isEditor(userReviewSvc.data.currentUserReviewTeam);
            var isOSSSubmitted = userReviewSvc.data.currentReviewTeam && userReviewSvc.data.currentReviewTeam.selfStudySubmittedTimestamp != null;;

            var hasVisiblePrograms = programReviewSvc.data.programs && programReviewSvc.data.programs.some(function (programReview) {
                return (programReview.programReviewTypeCode.toLowerCase() != 'tr' || programReview.accreditationEndDate != null) &&
                       (!programReview.actionCode || (programReview.actionCode.toLowerCase() != 'c' && programReview.actionCode.toLowerCase() != 'w'));
            });
            var hasAuditablePrograms = programReviewSvc.data.programs && programReviewSvc.data.programs.some(function (programReview) {
                return programAuditDetailSvc.isAuditable(programReview);
            });
            var isCancelledOrWithdrawn = programReviewSvc.data.programs && programReviewSvc.data.programs.every(function (programReview) {
                return programReview.actionCode && (programReview.actionCode.toLowerCase() == 'c' || programReview.actionCode.toLowerCase() == 'w');
            });

            // Self Study
            var showSelfStudy = !isCancelledOrWithdrawn && isReviewInCurrentCycle && (isSubmitted || model.isAdmin || model.isPrimaryContact || model.isDelegateForPrimary || model.isSecondary || model.isProgramContact) && !isEditor;
            var isSelfStudyLocked = !showSelfStudy || (!hasVisiblePrograms && (isTeamChair || isPEV));
            var selfStudyLockMsg = null;

            if (isSelfStudyLocked && isCancelledOrWithdrawn) {
                selfStudyLockMsg = "This tool is not available for cancelled or withdrawn reviews.";
            } else if (isSelfStudyLocked && !hasVisiblePrograms) {
                selfStudyLockMsg = "This tool is not available for any programs on this review.";
            } else if (isSelfStudyLocked && !isEditor) {
                selfStudyLockMsg = isReviewInCurrentCycle ? "This self study has not been submitted." : "This tool is not available for the selected review cycle."
            } else if (isSelfStudyLocked) {
                selfStudyLockMsg = "This tool is not available for editors.";
            }

            // Program Audit
            var programAuditTemplateURL = 'apps/programAudit/templates/programAuditModule.html';
            var isProgramAuditTabLocked = isBeforeProgramAuditLaunch || isInstitutionUser || isInterimReport || !hasVisiblePrograms || !hasAuditablePrograms || isCancelledOrWithdrawn || !isOSSSubmitted;
            var programAuditLockMsg = function () {
                var msg = null;
                if (isBeforeProgramAuditLaunch) {
                    msg = 'This tool is not available for the selected review cycle.';
                } else if (isInstitutionUser) {
                    msg = 'This tool is only available to Progam Evaluators and Team Chairs.';
                } else if (isCancelledOrWithdrawn) {
                    msg = 'This tool is not available for cancelled or withdrawn reviews.';
                } else if (isInterimReport) {
                    msg = 'This tool is not available for interim reports.'
                } else if (!hasAuditablePrograms) {
                    msg = 'This tool is not available for any programs on this review.';
                } else if (!isOSSSubmitted) {
                    msg = 'This tool is not available until a Self Study has been submitted.';
                } else if (isProgramAuditTabLocked) {
                    msg = 'This tool is not currently available.'
                }
                return msg;
            }();

            // Manage Statement
            var statementTemplateURL = isInstitutionUser ? 'apps/statement/templates/statementInstitutionModule.html' : 'apps/statement/templates/statementVolunteerModule.html';
            var manageStatementLabel = isInstitutionUser ? 'Review Process' : 'Manage Statement';

            // Find if Program Audit is required/exists for current review and if it is complete
            var index = programAuditData.findIndex(function (audit) { return audit.isCurrent; });

            if (isBeforeProgramAuditLaunch || isInterimReport) {
                programAuditIsCompleteOrDisabled = true;
            } else {
                programAuditIsCompleteOrDisabled = !hasAuditablePrograms || index > -1 ? programAuditData[index].isLocked : false;
            }

            var isStatementTabLocked = !programAuditIsCompleteOrDisabled || (isPevOrProgramObserver || (!isReviewInCurrentCycle && !isInstitutionUser) || !hasVisiblePrograms || isCancelledOrWithdrawn);
            var statementLockMsg = null;

            if (isStatementTabLocked && isPEV) {
                statementLockMsg = "This tool is not available to program evaluators.";
            } else if (isStatementTabLocked && !isReviewInCurrentCycle && !isInstitutionUser) {
                statementLockMsg = "This tool is not available for the selected review cycle.";
            } else if (isStatementTabLocked && isCancelledOrWithdrawn) {
                statementLockMsg = "This tool is not available for cancelled or withdrawn reviews.";
            } else if (isStatementTabLocked && !hasVisiblePrograms) {
                statementLockMsg = "This tool is not available for any programs on this review.";
            } else if (isStatementTabLocked && !programAuditIsCompleteOrDisabled) {
                statementLockMsg = "This tool is not available until Program Audits are completed."
            }

            model.navigation = [
                tabSvc.createTab('Review Status', userReviewSvc.slugs.STATUS, path + 'reviewStatusModule.html'),
                tabSvc.createTab('Review Team', userReviewSvc.slugs.TEAM, path + 'reviewTeamModule.html'),
                tabSvc.createTab('Self Study/Report', userReviewSvc.slugs.SELFSTUDY, path + 'ossModule.html', isSelfStudyLocked, null, selfStudyLockMsg),
                tabSvc.createTab(manageStatementLabel, userReviewSvc.slugs.STATEMENT, statementTemplateURL, isStatementTabLocked, null, statementLockMsg)
            ];

            // TODO: Remove environment check when program audit is ready for production
            if (!isInstitutionUser) {
                model.navigation.splice(-1, 0,
                    tabSvc.createTab('Program Audit', userReviewSvc.slugs.PROGRAMAUDIT, programAuditTemplateURL, isProgramAuditTabLocked, null, programAuditLockMsg));
            }
        }

        var validationCallback = function (event, programAudit, programAuditAccess) {
            reviewValidationSvc.populateWarnings(model.navigation, programAudit, programAuditAccess);
        };

        reviewValidationSvc.listenToValidate(validationCallback, $scope);

        var activate = function () {
            barSvc.showBar();
            var currentReviewTeamPromise = null;

            // This is just in case current user review team hasn't been loaded... which shouldn't happen now that it is resolved in routing
            if (!userReviewSvc.currentUserReviewTeam || userReviewSvc.currentUserReviewTeam.reviewTeamId != reviewTeamId) {
                currentReviewTeamPromise = oauth.isAdmin() ?
                    userReviewSvc.getCurrentReviewTeamAdmin(reviewTeamId) :
                    userReviewSvc.getCurrentReviewTeam(reviewTeamId);
            }

            var currentReviewDataPromise = userReviewSvc.getAllDataForCurrentReview(reviewTeamId);

            var programReviewsDataPromise = programReviewSvc.loadProgramReviewsByReviewTeamId(reviewTeamId);

            var programAuditDataPromise = programAuditSvc.getProgramAuditByReviewTeamId(reviewTeamId);


            $q.all([currentReviewTeamPromise, currentReviewDataPromise, programReviewsDataPromise, programAuditDataPromise]).then(function () {
                var currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                var programs = programReviewSvc.data;

                // Use review data to load delegated commissions, for determining if user is a delegate for the primary
                model.organizationId = userReviewSvc.data.currentReview.organizationDto.currentOrganizationDetailDto.organizationId;
                model.commissionId = userReviewSvc.data.currentReviewTeam.commissionId;
                currentOrganizationHelper.loadDelegatedCommissions(model.organizationId).then(function (data) {
 
                    currentOrganization.save();
                    model.isPrimaryContact = currentUserHelper.isPrimary(model.organizationId, model.commissionId);
                    model.isDelegateForPrimary = currentUserHelper.isDelegateForPrimary(model.organizationId, model.commissionId);
                    model.isSecondary = currentUserHelper.isSecondary(model.organizationId, model.commissionId);
                    var programs = programReviewSvc.data.programs;
                    model.isProgramContact = currentUserHelper.isProgramContact(programs);

                    programAuditDataPromise.then(function (data) {
                        programAuditData = data;

                        // Load statement data as needed, then continue with activation
                        if (currentUserReviewTeam.teamMemberTypeName !== 'Institution Contact') {
                            var statementPromise = statementStorageSvc.getByReviewTeamId(reviewTeamId);
                            var evaluatorReportPromise = evaluatorReportSvc.getEvaluatorReportByReviewTeamId(reviewTeamId);
                            $q.all([statementPromise, evaluatorReportPromise]).then(function () {
                                finishActivate(currentUserReviewTeam);
                            });
                        } else {
                            finishActivate(currentUserReviewTeam);
                        }
                    });
                });
            });
        }();

        function finishActivate(currentUserReviewTeam){
            setTabs();
            model.title = model.isAdjunct ? userReviewSvc.data.currentReview.organizationDto.currentOrganizationDetailDto.organizationName : currentUserReviewTeam.organizationName;
            model.subtitle = model.isAdjunct ? userReviewSvc.data.currentReview.reviewYear + " " + userReviewSvc.data.currentReviewTeam.reviewTypeCode : currentUserReviewTeam.reviewYear + " " + currentUserReviewTeam.reviewTypeName;
            // Prevent redundant validation (and data loading) by skipping for child states that invoke validation on their own.
            if (!$stateParams.view || !['selfstudy', 'programaudit', 'statement'].includes($stateParams.view.toLowerCase()))
                validationCallback.apply();
            barSvc.hideBar();
            model.isDataReady = true;
        };
    };
    module.controller('reviewDetailCtrl', reviewDetailCtrl);

}(angular.module('userReview')));