(function(module) {

    var STORAGEKEY = "crOrganization";

    var currentOrganization = function (localStorage) {

        var saveOrganization = function () {
            localStorage.add(STORAGEKEY, profile);
        };

        var removeOrganization = function () {
            localStorage.remove(STORAGEKEY);
        };

        var exists = function () {
            return localStorage.get(STORAGEKEY) ? true : false;
        };

        var initialize = function () {
            var org = {
                organizationId: 0,
                organizationName: "",
                organizationTypeId: 0,
                delegateCommissions: []
            };

            var localOrganization = localStorage.get(STORAGEKEY);
            if (localOrganization) {
                org.organizationId = localOrganization.organizationId;
                org.organizationName = localOrganization.organizationName;
                org.organizationTypeId = localOrganization.organizationTypeId;
                org.delegateCommissions = localOrganization.delegateCommissions;
            }
            return org;
        };

        var profile = initialize();

        return {
            save: saveOrganization,
            remove: removeOrganization,
            exists: exists,
            profile: profile
        };
    };

    module.factory("currentOrganization", currentOrganization);

}(angular.module("security")));