(function (module) {

    var pevCtrl = function ($scope, $stateParams, $uibModal, eventSvc, helperSvc, codeSvc, reviewTeamMemberSvc, teamMemberStatusesFor) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Programs and PEVs';
        model.noProgramsMsg = 'No programs on record';
        model.isEmpty = helperSvc.isEmpty;
        model.reviewTeamId = parseInt($stateParams.reviewTeamId);

        model.editPevs = function (review) {           

            reviewTeamMemberSvc.getPevsByReviewTeamId(review.reviewTeamId).then(function (data) {
               

                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/common/templates/misc/pevEdit.html',
                    size: 'lg',
                    controller: 'pevEditCtrl',
                    resolve: {
                        review: function () {
                            return review;
                        },
                        parent: function () {
                            return model;
                        }
                    }
                });
            });
        };

        codeSvc.getDistanceLearningTypes().then(function (data) {
            model.distanceLearningTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getDisciplineTypes().then(function (data) {
            model.disciplineTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getMemberAndSupportingSocieties().then(function (data) {
            model.memberSocietyOptions = helperSvc.getResults(data);
        });

        codeSvc.getActions().then(function (data) {
            model.actionOptions = helperSvc.getResults(data);
        });

        codeSvc.getProgramReviewTypes().then(function (data) {
            model.programReviewTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getPevTypes().then(function (data) {
            model.pevTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getTeamMemberStatuses().then(function (data) {
            var statusArr = helperSvc.getResults(data);
            model.teamMemberStatuses = statusArr.filter(function (status) { return teamMemberStatusesFor.Pev.indexOf(status.codeKey) > -1 });
        });
     
    };

    module.controller('pevCtrl', pevCtrl);

}(angular.module('common')));