(function (module) {

    var demographicInfoSvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerDiscipline',
            keyName: 'volunteerDisciplineId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'volunteerProgramAreas',
            getByForeignKeyPath: '/GetVolunteerDisciplineByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        //factory.data.commissionOptions = [];
        //factory.data.volunteerDisciplineStatusOptions = [];

        //factory.getDisciplineSociety = function (commissionId) {
        //    var oSvc = odataSvc.get();
        //    var disciplineApiPath = '/DisciplineSociety';

        //    oSvc.getSource(disciplineApiPath, key).odata()
        //        .filter('commissionId', commissionId)
        //        .query(oSvc.onSuccess, oSvc.onFailure);

        //    return oSvc.getDeferred().promise;
        //};

        //factory.getProgramAreaText = function (programArea) {
        //    return programArea.societyAbbreviatedName + ' | ' + programArea.disciplineName;
        //};

        ////DELETE WHEN NEW CONFIRMATION DIALOG IS READY
        //var apiPath = '/VolunteerDiscipline';
        //var key = 'volunteerDisciplineId';

        //factory.delete = function (programArea) {
        //    var oSvc = odataSvc.get();

        //    //confirmation breaks protractor test disabling for now
        //    //if ($window("Are you sure you want to delete this record?")) {

        //    var resource = oSvc.instantiate(apiPath, key, { volunteerDisciplineId: programArea.volunteerDisciplineId });
        //    resource.$delete(oSvc.onSuccess, oSvc.onFailure);

        //    oldLoadData(oSvc, programArea.volunteerId);

        //    return oSvc.getDeferred().promise;
        //    //}
        //    //else {
        //    //    return $q.reject('user cancelled');
        //    //}
        //};

        //function oldLoadData(oSvc, volunteerId) {
        //    oSvc.getDeferred().promise.then(function () {
        //        factory.getByForeignKey(volunteerId);
        //    });
        //}

        return {
            data: factory.data,
            //getDisciplineSociety: factory.getDisciplineSociety,
            //getProgramAreasByVolunteerId: factory.getByForeignKey,
            //getProgramAreaText: factory.getProgramAreaText,
            //create: factory.create,
            //update: factory.update,
            //delete: factory.delete
        };
    };

    module.factory('demographicInfoSvc', demographicInfoSvc);

})(angular.module('volunteer'));