(function (module) {


    var rfrDeleteNewProgramCtrl = function (program, commission, $scope, $uibModalInstance, rfrSvc, alertSvc, helperSvc,rfrValidationSvc) {
        var model = $scope;
        model.title = 'Delete New Program';
        model.program = program;
        model.commission = commission;
        model.data = {};

        model.cancel = function () {
            $uibModalInstance.close();
        }

        model.delete = function () {

            var commissionIndex = rfrSvc.data.rfr.rfrCommissionDtos.indexOf(model.commission);
            //splice the new program that is being deleted
            var programIndex = rfrSvc.data.rfr.rfrCommissionDtos[commissionIndex].jsonContents.programs.indexOf(model.program);
            model.data.rfr.rfrCommissionDtos[commissionIndex].jsonContents.programs.splice(programIndex, 1);

            rfrSvc.update(model.data.rfr).then(function () {
                alertSvc.addAlertSuccess("New program succesfully deleted.");
                rfrValidationSvc.invokeValidation();
            });

            $uibModalInstance.close();
        }

        activate();

        function activate() {
            model.data = angular.copy(rfrSvc.data);
        }
    };


    module.controller('rfrDeleteNewProgramCtrl', rfrDeleteNewProgramCtrl);

}(angular.module('rfr')));