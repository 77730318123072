(function (module) {
   
    var typeConstSvc = function (teamMemberTypes, organizationTypes, commissionTypes, programReviewTypes, reviewTypes,
                                 rfrProcessStatuses, programCampusTypes, programCampusTypeIds, degreeLevels, 
                                 collegeVerificationTypes, documentCategories, documentTypes, readinessStatuses, jointCommissions, commissionIndexes,
                                 reviewProcessStatuses, pevApplicationSocietyStatusName, pevApplicationDisciplineStatusName) {
        var factory = {};

        factory.isTeamChair = function (typeId) {
            return teamMemberTypes.TEAMCHAIRS.indexOf(typeId) > -1;
        };

        factory.isPev = function (typeId) {
            return teamMemberTypes.PEVS.indexOf(typeId) > -1;
        };

        factory.isObserver = function (typeId) {
            return teamMemberTypes.OBSERVERS.indexOf(typeId) > -1;
        };
        factory.isEditor = function (typeId) {
            return teamMemberTypes.EDITORS.indexOf(typeId) > -1;
        };
        factory.getTeamMemberTypes = function () {
            return teamMemberTypes;
        }
        factory.getCommissionTypes = function () {
            return commissionTypes;
        }
        factory.getCommissionAbbreviationById = function (commissionTypeId) {

            for (var i = 0; i < commissionTypes.length; i++) {
                if (commissionTypes[i].id === commissionTypeId) {
                    return commissionTypes[i].abrv;
                }
            }
            return '';
        };

        factory.getCommissionFullName = function(commissionTypeId){
            for (var i = 0; i < commissionTypes.length; i++) {
                if (commissionTypes[i].id === commissionTypeId) {
                    return commissionTypes[i].name;
                }
            }
            return '';
        }

        factory.getJointCommissionTypes = function () {
            var arr = [];
            angular.forEach(jointCommissions, function (commissions) {
                var ids = [], abrvs = [], names = [], jointCommission;
                angular.forEach(commissions, function (commission) {
                    var index = commissionIndexes[commission];
                    ids.push(commissionTypes[index].id);
                    abrvs.push(commissionTypes[index].abrv);
                    names.push(commissionTypes[index].name);
                });
                jointCommission = {
                    ids: ids,
                    abrv: abrvs.join(' and '),
                    name: names.join(' and ')
                };
                arr.push(jointCommission);
            });
            return arr;
        };
        factory.getCommissionWithJointTypes = function () {
            var allCommissions = [];
            var jointCommissionTypes = factory.getJointCommissionTypes();
            var commissions = commissionTypes.map(function (c) {
                return {
                    ids: [c.id],
                    abrv: c.abrv,
                    name: c.name
                }
            });
            allCommissions.push.apply(allCommissions, commissions);;
            allCommissions.push.apply(allCommissions, jointCommissionTypes);;
            return allCommissions;
        };
        factory.getOrganizationTypes = function () {
            return organizationTypes;
        }
        
        factory.getOrganizationId = function(typeName) {
            for (var org in organizationTypes) {
                if (org === typeName) {
                    return organizationTypes[org];
                }
            }

            return null;
        }

        factory.getOrganizationName = function (id) {            
            for (var org in organizationTypes) {
                if (organizationTypes[org] === id) {
                    return org;
                }
            }
            return null;
        }

        factory.getProgramReviewTypes = function() {
            return programReviewTypes;
        }

        factory.getProgramReviewName = function (code) {
            for (var type in programReviewTypes) {
                if (programReviewTypes.hasOwnProperty(type) && code === type) {
                    return programReviewTypes[type];
                }
            }
            return null;
        }

        factory.getProgramReviewCode = function (name) {
            for (var type in programReviewTypes) {
                if (programReviewTypes.hasOwnProperty(type) && programReviewTypes[type] === name) {
                    return type;
                }
            }
            return null;
        }

        factory.getProgramReviewTypeCodes = function () {
            var programReviewTypeCodes = {};
            for (var code in programReviewTypes) {
                if (programReviewTypes.hasOwnProperty(code)) {
                    programReviewTypeCodes[code] = code;
                }
            }
            return programReviewTypeCodes;
        }

        factory.getReviewTypes = function () {
            return reviewTypes;
        }

        factory.getReviewTypeCode = function (name) {
            for (var reviewTypeCode in reviewTypes) {
                if (reviewTypes.hasOwnProperty(reviewTypeCode) && reviewTypes[reviewTypeCode] === name) {
                    return reviewTypeCode;
                }
            }
            return null;
        }

        factory.getReviewTypeName = function (code) {
            var reviewTypeCode = reviewTypes[code];
            return reviewTypeCode ? reviewTypeCode : null;
        }

        factory.getRfrProcessStatuses = function () {
            return rfrProcessStatuses;
        }

        factory.getProgramCampusTypes = function () {
            return programCampusTypes;
        }

        factory.getProgramCampusTypeIds = function () {
            return programCampusTypeIds;
        }

        factory.getDegreeLevel = function (code) {
            for (var type in degreeLevels) {
                if (degreeLevels.hasOwnProperty(type) && code === type) {
                    return degreeLevels[type];
                }
            }
            return null;
        }

        factory.getCollegeVerificationTypes = function () {
            return collegeVerificationTypes;
        }

        factory.getDocumentCategories = function () {
            return documentCategories;
        }

        factory.getDocumentTypes = function () {
            return documentTypes;
        }

        factory.getDocumentTypeById = function (id) {
            for (var documentType in documentTypes) {
                if (documentTypes.hasOwnProperty(documentType) && documentTypes[documentType].documentTypeId === id) {
                    return documentTypes[documentType];
                }
            }
            return null;
        }

        factory.getDocumentTypesByCategory = function(categoryId) {
            var filteredDocumentTypes = [];

            for (var documentType in documentTypes) {
                if (documentTypes.hasOwnProperty(documentType) && documentTypes[documentType].documentCategoryId === categoryId) {
                    filteredDocumentTypes.push(documentTypes[documentType]);
                }
            }

            return filteredDocumentTypes;
        }

        factory.getReadinessStatuses = function () {
            return readinessStatuses;
        }

        factory.getReviewProcessStatusName = function(reviewStatusId){
            //for (var i = 0; i < reviewProcessStatuses.length; i++) {
            //    if (reviewStatusId === reviewProcessStatuses[i].reviewProcessStatusId)
            //            return reviewProcessStatuses[i].statusName;
            //}
            //return 'Not Entered';

            //WORKAROUND FOR THE unnecesary looping required... saves alot of computation during the later status' I.E statusid 22;
            if (reviewStatusId && Number.isInteger(reviewStatusId) && reviewStatusId <= reviewProcessStatuses.length) {
                return reviewProcessStatuses[reviewStatusId - 1].statusName;
            } else {
                return "Not Entered";
            }
        }

        factory.getPevAppSocietyStatusName = function(statusId){
         if (statusId && Number.isInteger(statusId) && statusId <= pevApplicationSocietyStatusName.length) {
                return pevApplicationSocietyStatusName[statusId - 1].statusName;
            }
        }

        factory.getPevAppDisciplineStatusName = function(statusId){
         if (statusId && Number.isInteger(statusId) && statusId <= pevApplicationDisciplineStatusName.length) {
                return pevApplicationDisciplineStatusName[statusId - 1].statusName;
            }
        }

        
        return {
            isTeamChair: factory.isTeamChair,
            isPev: factory.isPev,
            isObserver: factory.isObserver,
            isEditor: factory.isEditor,
            getCommissionTypes: factory.getCommissionTypes,
            getTeamMemberTypes: factory.getTeamMemberTypes,
            getCommissionAbbreviationById: factory.getCommissionAbbreviationById,
            getJointCommissionTypes: factory.getJointCommissionTypes,
            getCommissionWithJointTypes: factory.getCommissionWithJointTypes,
            getCommissionFullName: factory.getCommissionFullName,
            getOrganizationTypes: factory.getOrganizationTypes,
            getOrganizationName: factory.getOrganizationName,
            getOrganizationId: factory.getOrganizationId,
            getProgramReviewTypes: factory.getProgramReviewTypes,
            getProgramReviewName: factory.getProgramReviewName,
            getProgramReviewCode: factory.getProgramReviewCode,
            getProgramReviewTypeCodes: factory.getProgramReviewTypeCodes,
            getReviewTypes: factory.getReviewTypes,
            getReviewTypeName: factory.getReviewTypeName,
            getReviewTypeCode: factory.getReviewTypeCode,
            getRfrProcessStatuses: factory.getRfrProcessStatuses,
            getProgramCampusTypes: factory.getProgramCampusTypes,
            getProgramCampusTypeIds: factory.getProgramCampusTypeIds,
            getDegreeLevel: factory.getDegreeLevel,
            getCollegeVerificationTypes: factory.getCollegeVerificationTypes,
            getDocumentCategories: factory.getDocumentCategories,
            getDocumentTypes: factory.getDocumentTypes,
            getDocumentTypeById: factory.getDocumentTypeById,
            getDocumentTypesByCategory: factory.getDocumentTypesByCategory,
            getReadinessStatuses: factory.getReadinessStatuses,
            getReviewProcessStatusName: factory.getReviewProcessStatusName,
            getPevAppSocietyStatusName: factory.getPevAppSocietyStatusName,
            getPevAppDisciplineStatusName: factory.getPevAppDisciplineStatusName
    };

    }
    module.factory('typeConstSvc', typeConstSvc);

}(angular.module("common")));