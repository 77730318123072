(function (module) {

    var programAuditInstitutionalSummaryToolCtrl = function ($q, $state, $stateParams, $uibModal, $timeout, $scope, $filter, $window, helperSvc, oauth, alertSvc, currentUser,
        statementFindingTypes, teamMemberTypeAbbrvIds, reviewTeamSvc,programAuditSvc, programAuditDetailSvc, programAuditInstitutionalSummarySvc, organizationSvc,
        statementStorageSvc, programReviewTypes) {

        const model = this;

        // State Params
        model.programAuditId = parseInt($stateParams.programAuditId);
        model.returnStateName = $stateParams.returnStateName;
        model.returnStateParams = $stateParams.returnStateParams;
        model.programAuditInstitutionalSummaryId = parseInt($stateParams.programAuditInstitutionalSummaryId) || 0;

        // Type Constants
        model.statementFindingTypes = statementFindingTypes;

        // Model Data
        model.editorOptions = {
            toolbar: ['heading', '|', 'undo', 'redo'],
            removePlugins: ['Autoformat'], //disables ckeditor's autoformats i.e. typing "1. " creates a numbered list and "> " creates a blockquote
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                ]
            }
        };
        model.organization = null;
        model.reviewTeam = null;

        // Flags
        model.isAdmin = false;
        model.isTeamChair = false;
        model.isReadOnly = false;
        model.isDataReady = false;

        model.openInstructions = function () {
            alertSvc.openPDFModal(
                '/Content/files/Draft-Final-Statement-Instructions.pdf',
                'Statement Editor Instructions'
            );
        };

        model.isVisible = {
            comments: false,
            changes: false,
            changeMenu: false,
            //submissionMenu: false,
            footerMenu: true,
            inPAF: function (findingType) {
                if (findingType) {
                    return findingType.statementFindingTypeId !== statementFindingTypes.PROGRAMINTRODUCTION &&
                        findingType.statementFindingTypeId !== statementFindingTypes.PROGRAMSTRENGTH &&
                        findingType.statementFindingTypeId !== statementFindingTypes.PROGRAMOBSERVATION;
                }
                return true;
            },
            inExitStatement: function (findingType) {
                return findingType ? true : false;
            }
        };

        model.getReviewTypeName = function (reviewTypeCode) {
            return programReviewTypes[reviewTypeCode] || null;
        };

        model.openInstitutionalSummaryPreview = function () {
            programAuditDetailSvc.getAllProgramAuditToolData(model.programAuditId).then(function (dataForPAF) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/programAudit/templates/modal/viewPAFPreview.html',
                    size: 'xl',
                    controller: 'viewPAFPreviewCtrl',
                    resolve: {
                        currentProgramAuditData: function () { return dataForPAF; },
                        isExitStatement: function () { return false; },
                        isSingleProgram: function () { return false; },
                        isInstitutionalSummary: function () { return true; },
                        isPreview: function () { return true; },
                        showInstitutionalSummary: function () { return true; }
                    }
                });
            });
        };

        model.goBack = function () {
            // Go to the route specified under previous route or return to program audit tab under review details.
            $state.go(model.returnStateName, model.returnStateParams).then(function () {
                document.body.classList.remove("editor-tool");
                document.documentElement.classList.remove('editor-tool-html');
            });
        };

        model.addFindings = function () {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/apps/programAudit/templates/modal/addProgramAuditInstitutionalSummaryFindings.html',
                size: 'md',
                controllerAs: 'model',
                controller: 'addProgramAuditInstitutionalSummaryFindingsCtrl',
                resolve: {
                    programAuditInstitutionalSummary: () => model.programAuditInstitutionalSummary,
                }
            });

            modalInstance.result.then(paIS =>
                refreshData(paIS)
            );
        };

        model.isSectionPermanent = function (findingType) {
            // For now, program introduction is the only type allowed on program audit that is permanent;
            // no other allowed sections that can't be changed or deleted.
            return findingType.statementFindingTypeId === statementFindingTypes.INSTITUTIONSUMMARY;
        }

        model.hasText = programAuditInstitutionalSummarySvc.hasText;

        model.deleteFinding = function (findingType, finding) {
            const findingName = `${findingType.statementFindingTypeName}${finding.criteria.criteriaName ? ` (${finding.criteria.criteriaName})` : ''}`;
            alertSvc.confirmDelete(findingName, deleteFunc);
            let institutionalSummary = null;

            function deleteFunc() {
               institutionalSummary = angular.copy(model.programAuditInstitutionalSummary);
               programAuditInstitutionalSummarySvc.deleteFinding(institutionalSummary, finding).then(onSuccess, onError);
            }

            function onSuccess(data) {
                refreshData(institutionalSummary);
                alertSvc.addAlertSuccess("Strength successfully deleted.")
            }

            function onError(error) {
                console.log(error);
                if (error.status !== 409) alertSvc.addAlertWarning('Strength could not be deleted at this time.') // httpResponseInterceptor handles conflict messages 
            }
        };

        let autosaving = null;

        model.autoSave = function (finding) {
            if (model.isReadOnly)
                return;

            finding.lastUpdatedTimestamp = new Date().toISOString();
            if (autosaving)
                $timeout.cancel(autosaving);
            // Delay auto-save to prevent too-many auto-saves being fired...
            autosaving = $timeout(() => {
                autosaving = null;
                finding.saveComplete = false;
                finding.delayElapsed = false;
                programAuditInstitutionalSummarySvc.updateProgramAuditInstitutionalSummary(model.programAuditInstitutionalSummary).then(() => {
                    if (finding.delayElapsed)
                        finding.isAutosaving = false;
                    else
                        finding.saveComplete = true;
                    model.programAuditInstitutionalSummary.lastUpdatedTimestamp = new Date().toISOString();
                    //doValidation();
                }).catch(error => {
                    finding.saveComplete = true;
                    finding.isAutosaving = false;
                    console.log(error);
                    if (error.status !== 409) alertSvc.addAlertWarning('Saving summary section failed.'); // httpResponseInterceptor handles conflict messages
                });
                // Hide auto-saving message after a short delay or when save is complete--whichever occurs last...
                $timeout(() => {
                    finding.delayElapsed = true;
                    if (finding.saveComplete)
                        finding.isAutosaving = false;
                }, 2000);

                finding.isAutosaving = true;
            }, 2000);
        };

        activate();

        function activate() {
            const getProgramAuditInstitutionalSummaryPromise = programAuditInstitutionalSummarySvc.getProgramAuditInstitutionalSummaryById(model.programAuditInstitutionalSummaryId)
                .then(data => {
                    model.programAuditInstitutionalSummary = programAuditSvc.convertToJson(data);
                })

            const getProgramAuditPromise = programAuditSvc.getProgramAuditById(model.programAuditId).then(data => {
                model.reviewTeamId = data.reviewTeamId;

                return reviewTeamSvc.getSingleReviewTeamById(model.reviewTeamId)
                    .then(data => {
                        model.reviewTeam = data;
                        model.reviewTypeName = data.reviewTypeName;

                        model.returnStateName = $stateParams.returnStateName || (currentUser.profile.userTasks.indexOf("admin") >= 0 && !oauth.isAdjunct() ? 'userReviewAdmin.detail' : 'userReview.detail');
                        model.returnStateParams = $stateParams.returnStateName && $stateParams.returnStateParams || {
                            reviewTeamId: model.reviewTeam.reviewTeamId,
                            view: 'programaudit'
                        };

                        return organizationSvc.getOrganizationById(model.reviewTeam.reviewDto.organizationId)
                            .then(org => {
                                model.organization = org;
                            })
                    })
            })

            $q.all([
                getProgramAuditInstitutionalSummaryPromise,
                getProgramAuditPromise
            ]).then(() => {
                // Set event listener to make sure autosave finished when leaving tool
                $scope.$on("$destroy", () => {
                    if (autosaving) {
                        $timeout.cancel(autosaving);
                        programAuditInstitutionalSummarySvc.updateProgramAuditInstitutionalSummary(model.programAuditInstitutionalSummary);
                    }
                });
                // Finished loading and initializing
                model.isDataReady = true; // Don't need refreshSiteSvc here as this tool should be used sparingly

            });
        };

        function initialize() {
            // Reset accessibility and visibility flags
            setAccess();
        }

        function setAccess() {
            //const currentUserAccess = programAuditAccessSvc.getCurrentUserAccess(model.program.programId, model.programAuditAccess, model.reviewTeamMembers, model.programAuditDetail, model.isLocked);
            model.isAdmin = oauth.isAdmin();
            //model.isTeamChair = currentUserAccess.isTeamChair;
            //model.isReadOnly = currentUserAccess.isReadOnly;
        }

        function refreshData(paIS) {
            model.programAuditInstitutionalSummary = programAuditSvc.convertToJson(paIS);
           
            initialize();
        }
    }

    module.controller('programAuditInstitutionalSummaryToolCtrl', programAuditInstitutionalSummaryToolCtrl);

}(angular.module('programAudit')));