(function (module) {
    var personInfoModalCtrl = function (personId, personName, $uibModalInstance, $scope) {
        $scope.title = personName;
        $scope.personId = personId;

        $scope.close = function () {
            $uibModalInstance.close();
        };
    };
    module.controller('personInfoModalCtrl', personInfoModalCtrl);

}(angular.module('userReview')));