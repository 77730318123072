(function (module) {

    var rfrCommissionSubmissionCtrl = function ($scope, $uibModalInstance, commission, rfrSvc, alertSvc) {
        var model = $scope;
        model.title = 'Submit to ABET for Pre-Review';
        model.cancel = function () {
            $uibModalInstance.close();
        };
        model.commission = commission;

        model.submit = function () {
            var confirmationMsg = 'Are you sure you want to submit <em><strong>' + commission.commissionAbbreviatedName + '</strong></em> for pre-review? This procedure is irreversible. ' +
            'Please note that if multiple Commissions are involved with this Request for Evaluation (RFE), the RFE will be held until all appropriate Commission segments are submitted.';
        
            alertSvc.confirm(confirmationMsg, doSubmit, model.commission);
        
            function doSubmit() {


                try {
                    rfrSvc.submitForAbetPreReview(commission).then(
                        function (data) {
                            $uibModalInstance.close();
                            alertSvc.openModalAlert('Thank you for your RFE submission.<br/><br/>ABET requires an official transcript for one graduate from the most recent graduating class for each program requesting review. ' +
                                'Please mail the transcript(s) to the following address:<br/><br/><div class="text-center"><label>ABET<br/>c/o Accreditation<br/>415 North Charles Street<br/>Baltimore, MD 21201</label></div>',
                                'Success!');
                        },
                        handleError
                    );
                } catch (error) {
                    handleError(error);
                }
            }

            function handleError(error) {
                console.log(error);
                $uibModalInstance.close();
                alertSvc.addAlertWarning("RFE could not be submitted at this time.");
            }
        };
    };

    module.controller('rfrCommissionSubmissionCtrl', rfrCommissionSubmissionCtrl);

}(angular.module('rfr')));