(function (module) {

    var teamGroupingsCtrl = function ($scope, $uibModal, $state, teamGroupSvc, helperSvc, tcAssignmentSvc, assignmentSvc, typeConstSvc, alertSvc) {
        var model = this;
        
        model.parent = $scope.$parent.model;
        model.title = 'Team Groups';
        model.data = teamGroupSvc.data;
        model.getReviewTypeName = typeConstSvc.getReviewTypeName;
        model.getCommissionerName = assignmentSvc.getCommissionerName;
        model.helpText = 'Team Groups help to organize TC\'s and Co-TC\'s for large or complex visits, e.g. if an institution has multiple locations requiring teams to be sent across the country.';

        model.edit = function (team) {  
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/assignment/templates/tc/teamGroupingsEdit.html',
                size: 'lg',
                controller: 'teamGroupingsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    team: function () {
                        return team;
                    }
                }
            });
        };

        model.delete = function (teamGroup) {
            var recordName = teamGroup.groupName;
            alertSvc.confirmDelete(recordName, teamGroupSvc.deleteTeamGroup, teamGroup);
        }

        // Tried inline filter like {teamGroups: ''}, {teamGroups: '!!'}, and {teamGroups: '!null'} to no effect.
        model.hasTeamGroups = function (reviewTeam) {
            return !(reviewTeam.teamGroups === null || reviewTeam.teamGroups === undefined);
        }

        model.isReleased = function () {
            return tcAssignmentSvc.data.teamChairsReleased;
        }

        var reviewTeamsDataSource = {
            dataHolder: teamGroupSvc,
            dataLocationName: 'data.reviewTeams',
            svcCallback: teamGroupSvc.getReviewTeams,
            helperCallback: helperSvc.getValue
        }

        activate();

        function activate() {
            var dataSourceArray = [
				reviewTeamsDataSource
            ];

            helperSvc.getData(dataSourceArray);
        }
    };

    module.controller('teamGroupingsCtrl', teamGroupingsCtrl);

}(angular.module('assignment')));