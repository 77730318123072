(function (module) {

    var personTelephoneSvc = function ($http, $q, amsConst, $odataresource, odataSvc, contactTypes) {
        var apiPath = amsConst.webApiUrl + '/odata/PersonTelephone({0})';
        var factory = {};
        var keyName = 'personTelephoneId';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data.value;
            }
        };

        //should make the error output more useful or just remove?
        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        //invalid id causes 500 error to appear in console, way to stop?
        factory.getByPersonId = function (personId) {
            //return $http.get(apiPath.format(null) + '?$filter=personId eq ' + id).then(onSuccess, onFailure);
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonTelephonesByPersonId', 'personId', personId)

            oSvc.getSource(path, keyName).odata()
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getByPersonTelephoneId = function (id) {
            return $http.get(apiPath.format(null) + '?$filter=personTelephoneId eq ' + id).then(onSuccess, onFailure);
        };

        factory.getPreferredTelephone = function (id) {
            return $http.get(apiPath.format(null) + '?$filter=personId eq ' + id + ' and isPreferred eq true').then(onSuccess, onFailure);
        };

        factory.getAllABETActivitiesTelephone = function (id) {
            return $http.get(apiPath.format(null) + '?$filter=personId eq ' + id + ' and contactTypeId eq ' + contactTypes.ALLABETACTIVITIES).then(onSuccess, onFailure);
        };

        factory.getSome = function () {
            return $http.get(apiPath.format(null)).then(onSuccess, onFailure);
        };

        factory.create = function (personTelephone) {
            return $http.post(apiPath.format(null), personTelephone).then(onSuccess, onFailure);
        };

        factory.update = function (personTelephone) {
            return $http.put(apiPath.format(personTelephone.personTelephoneId), personTelephone).then(onSuccess, onFailure);
        };

        factory.delete = function (id) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(id)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        factory.getTelephoneType = function (id) {
            var telephoneType;

            for (var i = 0; i < codes.telephoneTypes.length; i++) {
                if(codes.telephoneTypes[i].telephoneTypeId == id) {
                    telephoneType = codes.telephoneTypes[i];
                }
            }

            return telephoneType;
        };

        factory.save = function (telephones) {

            var data = { "Value": telephones };

            return $http.post(apiPath.format(null) + '/Ams.SavePersonTelephones', data).then(onSuccess, onFailure);
        };

        //these should be pulled from the actual database
        var codes = {};
        codes.telephoneTypes = [{ telephoneType: "Home", telephoneTypeId: 1 }, { telephoneType: "Work", telephoneTypeId: 2 }, { telephoneType: "Mobile", telephoneTypeId: 3 }];

        factory.codes = codes;

        return {
            getByPersonId: factory.getByPersonId,
            getByPersonTelephoneId: factory.getByPersonTelephoneId,
            getPreferredTelephone: factory.getPreferredTelephone,
            getAllABETActivitiesTelephone: factory.getAllABETActivitiesTelephone,
            getTelephoneType : factory.getTelephoneType,
            getSome: factory.getSome,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            save: factory.save,
            codes: factory.codes
        };
    };
    module.factory('personTelephoneSvc', personTelephoneSvc);

})(angular.module('person'));