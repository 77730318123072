(function (module) {

    var verifyInstitutionEditCtrl = function (rr, $scope, $uibModalInstance, readinessSvc, alertSvc, organizationMdl, typeConstSvc, eventSvc) {
        var model = $scope;

        model.title = 'Verify Institution';
        model.rr = angular.copy(rr.selectedRR);
        model.isInstitutionSelected = false;
        model.useExistingInstitution = false;

        model.organizationTypes = typeConstSvc.getOrganizationTypes();

        model.close = function () {
            $uibModalInstance.close();
        };

        model.setUseExisting = function () {
            model.useExistingInstitution = !model.useExistingInstitution;
        };

        model.verify = function () {
            if (model.useExistingInstitution) {
                // Mark as verified
                var readinessDto = createReadinessDto();
                var organizationDto = createOrganizationDto();

                var params = {
                    readinessDto: readinessDto,
                    organizationDto: organizationDto,
                };

                readinessSvc.createOrganization(params).then(function (data) {
                    model.close();
                    alertSvc.addAlertSuccess("Institution successfully verified.");
                    eventSvc.broadcast('refreshReadinessData');
                });
            } else {
                readinessSvc.updateOrganization(model.rr).then(function (data) {
                    model.close();
                    alertSvc.addAlertSuccess("Institution successfully verified.");
                    eventSvc.broadcast('refreshReadinessData');
                });
            }
        };

        model.onSelect = function () {
            model.isInstitutionSelected = true;
        };

        model.onDeselect = function () {
            model.isInstitutionSelected = false;
            model.rr.organizationJson = null;
        };

        function createReadinessDto() {
            var readinessDto = angular.copy(rr.selectedRR);
            delete readinessDto["@odata.context"];
            delete readinessDto.$promise;
            delete readinessDto.$resolved;
            return readinessDto;
        }

        function createOrganizationDto() {
            var organizationModel = new organizationMdl(rr.selectedRR.organizationJson, true);
            organizationModel.currentOrganizationAddressDto = organizationModel.currentOrganizationAddressDto.currentOrganizationAddressDto;
            organizationModel.organizationTypeId = model.organizationTypes.INSTITUTION;
            delete organizationModel.useDtos;
            organizationModel.currentOrganizationDetailDto.isCurrent = true;
            return organizationModel;
        }

        var activate = function () {
            model.unverifiedInstitution = angular.copy(rr.selectedRR.organizationJson);
            model.onDeselect();
        }();
    };

    module.controller('verifyInstitutionEditCtrl', verifyInstitutionEditCtrl);

}(angular.module('readinessReview')));