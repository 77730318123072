(function (module) {
    'use strict';

    var noteSvc = function ($http, $odata, $q, odataSvc, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/Note';
        var api = '/Note';
        var key = 'noteId';
        var factory = {};

        factory.getNotesByReferenceId = function (referenceId, noteTypeId) {
            referenceId = parseInt(referenceId);

            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
                .filter('referenceId', referenceId)
                .filter('noteTypeId', noteTypeId)
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.create = function (note) {
            var oSvc = odataSvc.get();

            note.$save(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        factory.update = function (note) {
            var oSvc = odataSvc.get();

            note.$update(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        factory.delete = function (note) {
            var oSvc = odataSvc.get();

            if (confirm("Are you sure you want to delete this record?")) {
                note.$delete(oSvc.onSuccess, oSvc.onFailure);
                return oSvc.getDeferred().promise;
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        //should this work with odata resources? Server throws error when saving, Hwan putting notes items on back burner for now though...
        factory.saveNotes = function (notes) {
            var data = { "Value": notes };

            return $http.post(apiPath + '/Ams.SaveNotes', data).then(onSuccess, onFailure);
        };

        return {
            getNotesByReferenceId: factory.getNotesByReferenceId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            saveNotes: factory.saveNotes
        };
    };

    module.factory('noteSvc', noteSvc);

})(angular.module('common'));