(function (module) {

    var employmentInfoEditCtrl = function ($scope, $uibModalInstance, parent, employmentInfoSvc, volunteerHelperSvc, pevAppSvc, pevAppValidationSvc, codeSvc, helperSvc, alertSvc, typeConstSvc) {
        var model = $scope;
        var checkIsVolunteer = true;
        var isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);
        var originalRecord = null;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 
        model.isInsertMode = false;
        model.isEditMode = false;
        model.collegeVerificationTypes = typeConstSvc.getCollegeVerificationTypes();
        volunteerHelperSvc.setCurrentInformation(model);

        if (isApplication) {
            model.data = angular.copy(pevAppSvc.volunteerEmploymentData);
        } else {
            model.data = employmentInfoSvc.data;
        };

        if (model.currentInformation.endYear) {
            model.currentInformation.isCurrentEmployment = true;
        }
        else {
            model.currentInformation.isCurrentEmployment = false;
        }

        model.save = function () {
            updateModel();
            helperSvc.validateForm(validateEmploymentInfo);

            if (model.errors.length > 0) return;

            delete model.currentInformation.isCurrentEmployment;

            if (isApplication) {
                if (model.isEditMode) {
                    model.data.employmentInfo[model.data.employmentInfo.indexOf(originalRecord)] = model.currentInformation;
                } else {
                    model.data.employmentInfo.push(model.currentInformation);
                }

                var application = angular.copy(pevAppSvc.data.application);
                application.applicationJson.volunteerEmployment = model.data.employmentInfo;

                pevAppSvc.update(application).then(onSuccess, onFailure);
            } else {
                if (!model.currentInformation.volunteerEmploymentId) {
                    employmentInfoSvc.create(model.currentInformation).then(onSuccess, onFailure);
                } else {
                    employmentInfoSvc.update(model.currentInformation).then(onSuccess, onFailure);
                }
            }

            function onSuccess() {
                model.toggleInsertMode();
                alertSvc.addModalAlertSuccess("Employment information successfuly saved.");
                pevAppValidationSvc.invokeValidation();
            }

            function onFailure () {
                alertSvc.addModalAlertWarning('Employment information could not be saved at this time.');
            }
        };

        model.edit = function (employment) {
            helperSvc.scrollToModalTop();
            originalRecord = employment;
            model.currentInformation = angular.copy(originalRecord);
            model.isEditMode = true;
            model.isInsertMode = true;
        };

        model.delete = function (item) {
            alertSvc.confirmDelete((item.title + ' (' + item.startYear + ' – ' + (item.endYear ? item.endYear : 'Present') + ')'), deleteFunc, item);

            function deleteFunc() {
                if (isApplication) {
                    var index = getEmploymentIndex(item);

                    if (index > -1) {
                        model.data.employmentInfo.splice(index, 1);
                        var application = angular.copy(pevAppSvc.data.application);
                        application.applicationJson.volunteerEmployment = model.data.employmentInfo;

                        pevAppSvc.update(application).then(onSuccess, onFailure);
                    } else {
                        alertSvc.addModalAlertWarning("The selected record could not be found.");
                    }
                } else {
                    employmentInfoSvc.delete(item).then(onSuccess, onFailure);
                }
            }

            function onSuccess() {
                alertSvc.addModalAlertSuccess('Employment information deleted.');
                pevAppValidationSvc.invokeValidation();
            }

            function onFailure (reason) {
                if (reason === 'user cancelled') {
                    alertSvc.addModalAlertInfo('Delete cancelled.');
                } else {
                    alertSvc.addModalAlertWarning('Employment information could not be deleted at this time.');
                }
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleInsertMode = function () {
            volunteerHelperSvc.toggleInsertMode(model);
        };

        model.setEmploymentTypeName = function () {
            var type = model.employmentTypes.find(function (type) {
                return type.codeKey == model.currentInformation.employmentTypeId;
            });
            if (isApplication) model.currentInformation.employmentTypeName = type.codeName;
        };

        model.changeIsCurrentEmployment = function () {
            if (model.currentInformation.isCurrentEmployment) {
                model.currentInformation.endYear = null;
            }
        }
        
        var activate = function () {
            volunteerHelperSvc.setErrors(model);

            codeSvc.getEmploymentTypes().then(function (data) {
                model.employmentTypes = helperSvc.getValue(data);

                var academicEmploymentType = model.employmentTypes.find(function (code) {
                    return code.codeName.toLowerCase().startsWith('academi');
                });

                model.academicEmploymentTypeId = +(academicEmploymentType.codeKey); // convert from string to integer
            });
        }();

        function getEmploymentIndex(currentRecord) {
            return model.data.employmentInfo.findIndex(function (record) {
                return record.employmentTypeId == currentRecord.employmentTypeId;
            });
        }

        function validateEmploymentInfo() {
            var errors = [];

            if (isEndBeforeStart()) {
                errors.push('Start year cannot come after the end year.')
            };

            if (isDuplicate()) {
                errors.push('The employment information entered is already listed.');
            };

            if (isOverlapping()) {
                errors.push('The employment information entered overlaps with another entry for the same position.');
            };

            model.errors = errors;
        }

        function isEndBeforeStart() {
            var endYear = model.currentInformation.endYear || new Date().getFullYear();
            return endYear < model.currentInformation.startYear;
        }

        function isDuplicate() {
            var duplicate = model.data.employmentInfo.find(function (item) {
                return (item.volunteerEmploymentId !== model.currentInformation.volunteerEmploymentId &&
                       item.employmentTypeId === model.currentInformation.employmentTypeId &&
                       item.title === model.currentInformation.title &&
                       item.startYear === model.currentInformation.startYear &&
                       (((item.endYear === undefined || item.endYear === null) &&
                            (model.currentInformation.endYear === undefined || model.currentInformation.endYear === null)) ||
                        (item.endYear === model.currentInformation.endYear)) &&
                       ((model.currentInformation.employmentTypeId === model.academicEmploymentTypeId &&
                            volunteerHelperSvc.isInstitutionMatched(item, model.currentInformation)) ||
                        (model.currentInformation.employmentTypeId !== model.academicEmploymentTypeId &&
                            item.nonacademicName.toLowerCase().trim() === model.currentInformation.nonacademicName.toLowerCase().trim())));
            });

            var duplicated = (duplicate !== undefined && duplicate !== null);

            return duplicated;
        }

        function isOverlapping() {
            var duplicate = model.data.employmentInfo.find(function (item) {
                return item.volunteerEmploymentId !== model.currentInformation.volunteerEmploymentId &&
                       item.employmentTypeId === model.currentInformation.employmentTypeId &&
                       item.title === model.currentInformation.title &&
                       (item.startYear !== model.currentInformation.startYear ||
                        item.endYear !== model.currentInformation.endYear) &&
                       ((model.currentInformation.employmentTypeId === model.academicEmploymentTypeId &&
                            volunteerHelperSvc.isInstitutionMatched(item, model.currentInformation)) ||
                        (model.currentInformation.employmentTypeId !== model.academicEmploymentTypeId &&
                            item.nonacademicName.toLowerCase().trim() === model.currentInformation.nonacademicName.toLowerCase().trim())) &&
                        (model.currentInformation.endYear === undefined || model.currentInformation.endYear === null || item.startYear < model.currentInformation.endYear) &&
                        (item.endYear === undefined || item.endYear === null || item.endYear > model.currentInformation.startYear);
            });

            var duplicated = (duplicate !== undefined && duplicate !== null);

            return duplicated;
        }

        function updateModel() {
            if (!isApplication) model.currentInformation.volunteerId = model.volunteerId;

            if (model.currentInformation.employmentTypeId === model.academicEmploymentTypeId) {
                if (model.currentInformation.collegeDirectoryDto) {
                    model.currentInformation.collegeDirectoryId = model.currentInformation.collegeDirectoryDto.collegeDirectoryId;
                } else {
                    model.currentInformation.collegeDirectoryId = null;
                }
                model.currentInformation.nonacademicName = null;
            } else {
                model.currentInformation.collegeDirectoryId = null;
                model.currentInformation.collegeDirectoryDto = null;
                model.currentInformation.collegeVerificationDto = null;
            };
        }
    };

    module.controller('employmentInfoEditCtrl', employmentInfoEditCtrl);

}(angular.module('volunteer')));