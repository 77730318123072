(function (module) {

    const programAuditValidationSvc = function (eventSvc, $q, programAuditDetailSvc, userReviewSvc, teamMemberTypeNames) {
        const factory = {};

        const errorTypes = {
            "NOTCREATED": 'notcreated',
            "SUMMARY": 'SUMMARY',
            "FT": 'ft',
            "RA": 'ra',
            "NOTSUBMITTED": 'notsubmitted',
            "NOTREVIEWED": 'notreviewed',
            "NOTFINALIZED": 'notfinalized'
        }

        const hasText = programAuditDetailSvc.hasText;

        factory.invokeValidation = function (programAudit, programAuditAccess, programReviews) {
            eventSvc.broadcast('ValidateProgramAudit', programAudit, programAuditAccess, programReviews);
        };

        factory.listenToValidate = function (callback, scope) {
            eventSvc.listen(callback, 'ValidateProgramAudit', scope);
        };

        factory.validateProgramAuditBySection = function (programAudit, programAuditAccess, programReviews, currentReviewTeamMemberTypeId, currentReviewTeamMemberId)
        {
            const errorSections = [];

            if (!programAudit || !programAudit.programAuditDetailDtos ||
                !programAuditAccess || !programAuditAccess.length ||
                !currentReviewTeamMemberTypeId || !currentReviewTeamMemberId)
                return errorSections;

            const isTeamChair = currentReviewTeamMemberTypeId === teamMemberTypeNames.TEAMCHAIR;

            const programsToValidate = getProgramsToValidate(programAuditAccess, programReviews, currentReviewTeamMemberTypeId, currentReviewTeamMemberId);

            // Nothing to validate unless there are program reviews being audited by PEVS
            if (!programsToValidate.length)
                return errorSections;

            programsToValidate.forEach(program => {
                const currentUserAccess = programAuditAccess.filter(programAuditAccess =>
                    programAuditAccess.programId === program.programId && programAuditAccess.reviewTeamMemberId === currentReviewTeamMemberId
                ).map(programAuditAccess =>
                    programAuditAccess.accessType === 'PEV' ? teamMemberTypeNames.PEV : teamMemberTypeNames.TEAMCHAIR
                );
                const hasPEVAccess = currentUserAccess.includes(teamMemberTypeNames.PEV);
                const hasTeamChairAccess = currentUserAccess.includes(teamMemberTypeNames.TEAMCHAIR);

                const programAuditDetail = programAudit.programAuditDetailDtos.find(programAuditDetail =>
                    programAuditDetail.programId === program.programId && programAuditDetail.isCurrent
                );

                // Only validate if current user has access to this program detail
                if (!isTeamChair &&
                    (programAuditDetail && !currentUserAccess.includes(programAuditDetail.teamMemberTypeId) ||
                     !programAuditDetail && !hasPEVAccess))
                    return;

                const errorSection = factory.validateProgramAuditDetailSection(program.programFullName, programAuditDetail);

                if (!errorSection.errors.length && (hasPEVAccess || isTeamChair))
                    validateSubmitted(errorSection, program.programFullName, programAuditDetail);

                if (!errorSection.errors.length && (hasTeamChairAccess || isTeamChair))
                    validateReviewed(errorSection, program.programFullName, programAuditDetail);

                if (errorSection.errors.length)
                    errorSections.push(errorSection);
            });

            // Validate whether TC needs to finalize audit after all other steps completed
            if (!errorSections.length && isTeamChair) {
                const errorSection = validateProgramAuditSection(programAudit);
                if (errorSection.errors.length)
                    errorSections.push(errorSection);
            }
 
            return errorSections;
        }

        function getProgramsToValidate(programAuditAccess, programReviews) { 
            return programAuditAccess.filter((programAuditAccess) => {
                const program = (programReviews || []).find(program => program.programId === programAuditAccess.programId);

                return programAuditAccess.accessType === 'PEV' && programAuditDetailSvc.isAuditable(program);
            }).map(programAuditAccess => {
                // Use access records to get program reviews that require an audit
                const program = programAuditAccess.programDetailDto;
                program.programFullName = `${program.programName} (${program.degreeCode})`;

                return program;
            }).sort((a, b) => 
                 a.programFullName < b.programFullName ? -1 : a.programFullName > b.programFullName ? 1 : 0
            ); 
        }

        factory.validateProgramAuditDetailSection = function (programName, programAuditDetail) {
            const errorSection = { title: programName, errors: [] };
            validateProgramAuditDetailCreated(errorSection, programAuditDetail);

            if (errorSection.errors.length)
                return errorSection;

            validateSummary(errorSection, programName, programAuditDetail);
            validateFindings(errorSection, programName, programAuditDetail);
            validateRecommendedAction(errorSection, programName, programAuditDetail);

            return errorSection;
        }

        function validateProgramAuditDetailCreated(errorSection, programAuditDetail) {
            if (programAuditDetail && programAuditDetail.programAuditDetailId)
                return;

            const message = 'PEV has not yet started the audit for this program.';
            const shortMessage = 'Program audit not started'
            errorSection.errors.push({ "type": errorTypes.NOTCREATED, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
        }

        function validateSummary(errorSection, programName, programAuditDetail) {
            if (!programAuditDetail)
                return;

            if (programAuditDetail.programAuditJson.auditSummary.editable &&
                !programAuditDetail.programAuditJson.auditSummary.noPreviousFindings &&
                !programAuditDetail.programAuditJson.auditSummary.criteria.some(criterion =>
                    Array.isArray(criterion.previousFindingTypes) && criterion.previousFindingTypes.length
                )) {
                const message = `Previous findings not entered in program audit summary for ${programName}`;
                const shortMessage = 'Program audit summary missing previous findings';
                errorSection.errors.push({ "type": errorTypes.SUMMARY, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
            }
        }

        function validateFindings(errorSection, programName, programAuditDetail) {
            if (!programAuditDetail)
                return;

            programAuditDetail.programAuditJson.auditDetails.forEach(findingType => {
                if (!findingType.findings || !findingType.findings.length) {
                    const message = `No findings entered in finding type ${findingType.statementFindingTypeName} for ${programName}`;
                    const shortMessage = `Missing findings in ${findingType.statementFindingTypeName}`;
                    errorSection.errors.push({ "type": errorTypes.FT, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
                    return;
                }

                findingType.findings.forEach((finding, index, findings) => {
                    if (finding.criteria && hasText(finding.criteria.text))
                        return;

                    let baseMessage = findingType.statementFindingTypeName;
                    if (finding.criteria && finding.criteria.criteriaName)
                        baseMessage += ` in ${finding.criteria.criteriaName}`;
                    else if (findings.length > 1)
                        baseMessage += ` (${index + 1})`;
                    const message = `Finding text missing for finding in finding type ${baseMessage} for ${programName}`;
                    const shortMessage = `Text missing for ${baseMessage}`;
                    // Some criteria (e.g. APPM) allow multiple occurances of same finding type
                    if (!errorSection.errors.some(error => error.message === message))
                        errorSection.errors.push({ "type": errorTypes.FT, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
                });
            });
        }

        function validateRecommendedAction(errorSection, programName, programAuditDetail) {
            if (programAuditDetail && programAuditDetail.programAuditDetailEvaluatorDtos && programAuditDetail.programAuditDetailEvaluatorDtos.length &&
                programAuditDetail.programAuditDetailEvaluatorDtos.every(evaluator =>
                    evaluator.recommendedAction
                ))
                return;

            let message, shortMessage;
            if (programAuditDetail && programAuditDetail.programAuditDetailEvaluatorDtos && programAuditDetail.programAuditDetailEvaluatorDtos.length > 1) {
                message = `One or more recommended actions are missing for ${programName}`;
                shortMessage = 'Recommended action(s) are missing';
            }
            else {
                message = `Recommended action is missing for ${programName}`;
                shortMessage = 'Recommended action is missing';
            }
            errorSection.errors.push({ "type": errorTypes.RA, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
        }

        function validateSubmitted(errorSection, programName, programAuditDetail) {
            if (programAuditDetail && (programAuditDetail.submittedTimestamp || programAuditDetail.teamMemberTypeId !== teamMemberTypeNames.PEV))
                return;

            const message = `Program audit for ${programName} must be submitted by PEV`;
            const shortMessage = 'Program audit not submitted';
            errorSection.errors.push({ "type": errorTypes.NOTSUBMITTED, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
        }

        function validateReviewed(errorSection, programName, programAuditDetail) {
            if (programAuditDetail && (programAuditDetail.isReviewedByTeamChair || programAuditDetail.teamMemberTypeId !== teamMemberTypeNames.TEAMCHAIR))
                return;

            const message = `Program audit for ${programName} must be reviewed by TC`;
            const shortMessage = 'Program audit not reviewed';
            errorSection.errors.push({ "type": errorTypes.NOTREVIEWED, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
        }

        function validateProgramAuditSection(programAudit) {
            const errorSection = { title: 'Program Audit', errors: [] };
            validateFinalized(errorSection, programAudit);

            return errorSection;
        }

        function validateFinalized(errorSection, programAudit) {
            if (programAudit && programAudit.isLocked)
                return;

            const message = `Program audits must be finalized by TC after submission and review`;
            const shortMessage = 'Program audits not finalized';
            errorSection.errors.push({ "type": errorTypes.NOTFINALIZED, "message": message, "slug": userReviewSvc.slugs.PROGRAMAUDIT, "shortMessage": shortMessage });
        }
        
        return {
            errorTypes: errorTypes,
            invokeValidation: factory.invokeValidation,
            listenToValidate: factory.listenToValidate,
            validateProgramAuditBySection: factory.validateProgramAuditBySection,
            validateProgramAuditDetailSection: factory.validateProgramAuditDetailSection,
        };
    };

    module.factory('programAuditValidationSvc', programAuditValidationSvc);

})(angular.module('programAudit'));