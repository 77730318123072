(function (module) {

    /* MESSAGE directives includes the following:
        - alerts
        - errors
        - no-records
        - no-results
        - notice
    */

    var templateRoot = '/apps/common/templates/message/';

    module.directive('alerts', function (alertSvc) {
        return {
            restrict: 'AE',
            templateUrl: templateRoot + 'alerts.html',
            scope: {
                modal: '@?',
                persistent: '@?'
            },
            link: function (scope, elem, attrs) {
                scope.isModal = attrs.modal ? (scope.modal.toLowerCase() === 'true') : false;
                scope.isPersistent = attrs.persistent ? (scope.persistent.toLowerCase() === 'true') : false;
                scope.getIcon = function (alert) {
                    if (alert.type === 'warning' || alert.type === 'danger') {
                        return 'warning';
                    } else if (alert.type === 'success') {
                        return 'check-circle';
                    } else {
                        return 'info-circle';
                    }
                };

                if (scope.isPersistent) {
                    scope.containerId = 'alert-container-persistent';
                    scope.currentAlerts = alertSvc.currentPersistentAlerts;
                } else if (scope.isModal) {
                    scope.containerId = 'alert-container-modal';
                    scope.currentAlerts = alertSvc.currentModalAlerts;
                } else {
                    scope.containerId = 'alert-container';
                    scope.currentAlerts = alertSvc.currentAlerts;
                }

                scope.removeAlert = function (alert) {
                    alertSvc.removeAlert(alert, scope.isModal, scope.isPersistent);
                };
            }
        };
    });

    module.directive('errors', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'errors.html',
            scope: {
                errors: '=',
                title: '<?'
            },
            link: function (scope, element) {
                // Hide HTML title tooltip
                element[0].title = '';

                scope.title = scope.title || 'Please complete the following';
            }
        };
    });

    module.directive('noRecords', function () {
        return {
            restrict: 'E',
            template: '<h2 class="no-records">{{message}}</h2>',
            scope: {
                item: '@?for',
                custom: '@?customMessage'
            },
            link: function (scope, elem, attrs) {
                if (attrs.customMessage) {
                    scope.message = scope.custom;
                } else {
                    scope.message = attrs.for ? ('No ' + scope.item + ' on record') : 'No records found';
                }
            }
        };
    });

    module.directive('noResults', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'noResults.html',
            scope: {
                icon: '@?',
                title: '@?',
                message: '@?',
                link: '@?',
                linkText: '@?'
            },
            link: function (scope, elem, attrs) {
                scope.icon = attrs.icon ? scope.icon : 'binoculars';
                scope.title = attrs.title ? scope.title : 'Nothing Found!';
                scope.message = attrs.message ? scope.message : 'There\'s nothing to see here';
            }
        };
    });

    /* 
       EXAMPLE USAGE

       <notice type="help" label="This is my title">
           <p>This is my message</p>
       </notice>
    */

    module.directive('notice', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'notice.html',
            transclude: {
                'buttons': '?buttons'
            },
            scope: {
                label: '@?',
                type: '@?', //options: info, help, warning, success, error
                customIcon: '@?',
                customColor: '@?',
                buttonFunc: '&?button',
                buttonLabel: '@?buttonLabel',
                buttonIcon: '@?buttonIcon',
                fullWidth: '@?',
                slimAlert: '@?',
                helpText: '@?'
            },
            link: function (scope, element, attrs, ctrl, $transclude) {

                var types = {
                    info: { name: "info", icon: "info-circle" },
                    help: { name: "help", icon: "question-circle" },
                    warning: { name: "warning", icon: "warning" },
                    success: { name: "success", icon: "check" },
                    error: { name: "error", icon: "exclamation-circle" }
                }

                scope.type = scope.type ? (types[scope.type.toLowerCase()] ? scope.type.toLowerCase() : "info") : "info";
                scope.typeName = scope.customIcon && scope.customColor ? "custom" : types[scope.type].name;
                scope.typeIcon = scope.customIcon || types[scope.type].icon;
                scope.buttonLabel = scope.buttonLabel || "Open PDF";
                scope.hasButtons = $transclude.isSlotFilled('buttons') || scope.buttonFunc ? true : false;
                scope.isFullWidth = scope.fullWidth ? (scope.fullWidth.toLowerCase() == 'true' ? true : false) : false;
                scope.isSlimAlert = scope.slimAlert ? (scope.slimAlert.toLowerCase() == 'true' ? true : false) : false;
                scope.isHelpText = scope.helpText ? (scope.helpText.toLowerCase() == 'true' ? true : false) : false;
                scope.isCollapsed = false;

                scope.toggleisCollapsed = function () {
                    scope.isCollapsed = !scope.isCollapsed;
                };
            }
        }
    });

}(angular.module('common')));