(function (module) {

    var primaryContactsCtrl = function ($scope, $uibModal, contactRoleTypes) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Dean/Dean Equivalent and Delegate';
        model.type = 'primary';
        model.helpText = 'ABET defines the primary contact to be the dean (or equivalent). The Dean is responsible for submitting the Request For Evaluation and ensuring that all aspects of the requested review are handled from the institution’s perspective.<br/><br/><strong>Restricted to two (2) per Commission</strong>';

        model.editPrimaryContacts = function (isCreateMode, isReplaceMode, currentContact, editView, type) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/contacts/templates/contactsEdit.html',
                size: 'lg',
                controller: 'contactsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    contacts: function () {
                        return model.parent.primaryContacts;
                    },
                    roleId: function () {
                        return contactRoleTypes.PRIMARY;
                    },
                    isCreateMode: function () {
                        return isCreateMode;
                    },
                    isReplaceMode: function () {
                        return isReplaceMode;
                    },
                    currentContact: function () {
                        return currentContact;
                    },
                    editView: function () {
                        return editView;
                    },
                    type: function () {
                        return model.type;
                    }
                }
            });
        };
    };

    module.controller('primaryContactsCtrl', primaryContactsCtrl);

}(angular.module('contacts')));