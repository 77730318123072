(function (module) {

    var specializationCtrl = function ($scope, $uibModal, volunteerDataAccessTypes, volunteerHelperSvc, pevAppSvc, componentHelperSvc) {
        var model = this;
        var volunteerDataAccessType = volunteerDataAccessTypes.VOLUNTEERANDADMIN;
        var isApplication = pevAppSvc.isApplicationMode();

        model.title = 'Specializations (Optional)';
        model.parent = $scope.$parent.model;
        model.isButtonHidden = pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;

        if (isApplication) {
            model.data = pevAppSvc.volunteerSocietySpecializationData;
        }

        componentHelperSvc.invokeOnRendered('specialization', $scope);

        model.editSpecializations = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/specializationEdit.html',
                size: 'lg',
                controller: 'specializationEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('specializationCtrl', specializationCtrl);

}(angular.module('volunteer')));