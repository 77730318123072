(function (module) {

    var passwordEditCtrl = function (userId, $uibModalInstance, personUserSvc, alertSvc) {
        var model = this;

        model.title = 'Reset Password';
        model.controllerName = 'passwordEditCtrl';

        personUserSvc.getUser(userId).then(function (data) {
            model.user = data;
        });

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            model.user.password = model.newPassword;

            personUserSvc.updateUser(model.user).then(function (data) {
                alertSvc.addAlertSuccess("Password successfully updated.");
                $uibModalInstance.close();
            });
        };
    };

    module.controller('passwordEditCtrl', passwordEditCtrl);

}(angular.module('person')));