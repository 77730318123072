(function (module) {

    var regionalAgenciesCtrl = function (organizationRecognitionSvc, $uibModal, organizationSvc, eventSvc, $scope, $q) {
        var model = this;
        var parent = $scope.$parent.model;
        model.organizationId = parent.organization.organizationId;
        model.organization = parent.organization;
        model.data = organizationRecognitionSvc.data;

        model.title = "Regional Agencies";

        model.editRegionalAgencies = function (organizationId) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/organization/templates/regionalAgenciesEdit.html',
                size: 'lg',
                controller: 'regionalAgenciesEditCtrl',
                resolve: {
                    organization: function () {
                        return model.organization;
                    }
                }
            });
        };
    };

    module.controller('regionalAgenciesCtrl', regionalAgenciesCtrl);

}(angular.module('organization')));