(function (module) {

    var programHistoryEditCtrl = function (program, parent, $scope, $uibModalInstance, helperSvc, eventSvc, codeSvc, programDetailSvc, alertSvc) {
        var model = $scope;
        model.title = 'History of Program Name Changes';
        model.parent = parent;
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        model.data = programDetailSvc.data;
        model.labelStartDate = 'Start Date';
        model.labelEndDate = 'End Date';

        resetCurrentChange();

        if (program) {
            model.program = angular.copy(program);
        }

        model.preview = function (preview) {
            console.error('Not yet implemented');
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (change) {
            alertSvc.confirmDelete(change.programName, deleteFunc);

            function deleteFunc() {
                programDetailSvc.delete(change).then(function (data) {
                    broadcastRefreshMsg();
                    if (model.currentChange === change) {
                        model.isInsertMode = false;
                    }
                    alertSvc.addAlertSuccess("Change successfully deleted.");
                });
            }
        };

        model.addRow = function () {
            resetCurrentChange();
            model.currentChange.programName = model.parent.parent.selectedProgram.programName;
            model.currentChange.alternateName = model.parent.parent.selectedProgram.alternateName;
            model.isInsertMode = true;
            model.isCreateMode = true;
            model.isUpdateMode = false;
            model.labelStartDate = 'New Program Start Date';
            model.labelEndDate = 'Current Program End Date';
        };

        model.cancel = function () {
            resetCurrentChange();
            model.isInsertMode = false;
        }

        model.save = function (change) {
            console.log("On save:", change);
            if (model.isCreateMode) {
                programDetailSvc.create(change).then(function () {
                    resetCurrentChange();
                    broadcastRefreshMsg();
                    model.isInsertMode = false;
                    alertSvc.addAlertSuccess("Change successfully added.");
                });
            } else if (model.isUpdateMode) {
                change.endDate = change.currentProgramEndDate;
                programDetailSvc.update(change).then(function () {
                    resetCurrentChange();
                    broadcastRefreshMsg();
                    model.isInsertMode = false;
                    alertSvc.addAlertSuccess("Change successfully updated.");
                });
            }
        };

        model.select = function (change) {
            var useUndefined = true;
            programDetailSvc.getProgramDetailByProgramDetailId(change.programDetailId).then(function (data) {
                model.currentChange = data;
                model.currentChange.startDate = helperSvc.formatDate(model.currentChange.startDate, useUndefined);
                model.currentChange.currentProgramEndDate = helperSvc.formatDate(model.currentChange.endDate, useUndefined);
            });

            model.isInsertMode = true;
            model.isCreateMode = false;
            model.isUpdateMode = true;
            model.labelStartDate = 'Start Date';
            model.labelEndDate = 'End Date';
        };

        model.formatDate = helperSvc.formatDate;

        codeSvc.getDegreeLevels().then(function (data) {
            model.degreeLevels = helperSvc.getValue(data);
        });

        function resetCurrentChange () {
            model.currentChange = {
                programId: model.parent.parent.selectedProgram.programId,
                programDetailId: 0,
                commissionId: 0
            };
        };

        function broadcastRefreshMsg () {
            eventSvc.broadcast('refreshSelectedProgramData');
        };
    };

    module.controller('programHistoryEditCtrl', programHistoryEditCtrl);

}(angular.module('program')));