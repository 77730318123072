(function (module) {

    var currentActivitiesHistoryCtrl = function ($scope, $uibModalInstance, parent, volunteerActivitiesSvc) {
        var model = $scope;

        model.parent = parent;
        model.title = 'Evaluation History';
        model.activities = volunteerActivitiesSvc.data.activities;

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('currentActivitiesHistoryCtrl', currentActivitiesHistoryCtrl);

}(angular.module('volunteer')));