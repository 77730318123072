(function (module) {

    var emailEditCtrl = function ($scope, $uibModalInstance, $stateParams, id, personEmailSvc, name, eventSvc, alertSvc, codeSvc, oauth) {
        var model = $scope;

        model.personName = name;
        model.title = 'Email Addresses';
        model.subtitle = model.personName;
        model.isAdmin = oauth.isAdmin();
        model.personId = id || $stateParams.personId;

        personEmailSvc.getByPersonId(model.personId).then(function (data) {
            model.emails = data;
            if (model.emails.length === 0 || !model.emails.some(function (email) { !email.isDeleted })) {
                model.insertRow();
            }
        }); 

        model.insertRow = function () {
            var newEmail = {
                personEmailId: 0,
                personId: model.personId,
                emailId: 0,
                emailAddress: model.emailAddress,
                note: model.note,
                isDeleted: false
            };

            model.emails.push(newEmail);
        };

        model.deleteRow = function (email) {
            email.isDeleted = true;
        };

        model.changePrimary = function (email) {

            angular.forEach(model.emails, function (x) {
                x.isPreferred = (x === email);
            });
        };


        model.save = function () {

            var newArr = [];

            angular.forEach(model.emails, function (x) {

                if (x.hasOwnProperty('isDuplicate')) {
                    delete x.isDuplicate;
                }

                //exclude newly added line but deleted
                if (x.isDeleted === false || x.personEmailId !== 0)
                    newArr.push(x);
            });

            personEmailSvc.save(newArr).then(function (data) {
                model.emails = data.value;
                eventSvc.broadcast('loadPersonGeneral');
                alertSvc.addAlertSuccess("Email successfully updated.");
                $uibModalInstance.close();
            });
        };

        model.cancel = function () {
            $uibModalInstance.close();
        }

        codeSvc.getContactTypes().then(function (data) {
            model.contactTypeOptions = data.value;
        });
    };

    module.controller('emailEditCtrl', emailEditCtrl);

}(angular.module('person')));