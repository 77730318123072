(function (module) {

    var templateRoot = "/Apps/collegeDirectory/templates/";

    module.directive('collegeRecord', function ($state, $uibModalStack, collegeDirectorySvc, collegeVerificationTypes, personSvc, helperSvc) {
        return {
            restrict: 'E',
            template: '<h5>{{college.name}}</h5>' +
                      '{{getAddress(college)}}<br />' +
                      'Added by <a href="" ng-click="goToMasterRefPage(college)">{{college.referenceName}}</a> ' +
                      'for <em>{{college.collegeVerificationTypeName}}</em> ' +
                      'on {{college.lastUpdatedTimestamp | date:"MM/dd/yyyy"}}',
            scope: {
                college: '<'
            },
            link: function (scope) {
                scope.getAddress = collegeDirectorySvc.getFullAddress;

                scope.goToMasterRefPage = function (college) {
                    if (college.collegeVerificationTypeId === collegeVerificationTypes.INITIALACCREDITATION) {
                        // Initial Accreditation is added by the institution
                        $state.go('organization.single', { organizationId: college.masterId });
                    } else {
                        // All others are added by people
                        personSvc.getPersonIdByVolunteerId(college.masterId).then(function (data) {
                            var personIdObj = helperSvc.getFirstArrayItem(data);
                            var personId = personIdObj.personId;

                            $state.go('person.single', { personId: personId });
                        });
                    }
                    $uibModalStack.dismissAll();
                };
            }
        }
    });

    module.directive('collegeDirectorySearch', function (collegeDirectorySvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'collegeDirectorySearch.html',
            scope: {
                selection: '=',
                noResults: '=?',
                noResultsMsg: '@?',
                noResultsFunc: '&?',
                disabled: '=?',
                countryCode: '=?',
                stateCode: '=?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.noResults = false;

                scope.searchCollegeDirectory = function (searchText) {
                    return collegeDirectorySvc.searchCollegeDirectory(searchText, scope.countryCode, scope.stateCode).then(function (data) {
                        scope.noResults = data.length > 0 ? false : true;
                        return data;
                    });
                };

                scope.onSelect = function (item) {
                    if (!item.collegeDirectoryId) {
                        scope.selection = null;
                    }
                };
            }
        };
    });

    // Making this a directive so templates using college directory search can lay out their forms differently.
    module.directive('collegeDirectorySearchHelpPopover', function () {
        return {
            restrict: 'E',
            template:
                '<help-popover ' +
                    'title="Institution Search" ' +
                    'message="Enter search text in the text box and then select an institution from one of the search results in the dropdown list.">' +
                '</help-popover>'
        };
    });

    module.directive('collegeVerification', function (codeSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'collegeVerification.html',
            scope: {
                collegeVerificationDto: '='
            },
            link: function (scope, element, attrs, ctrl) {
                codeSvc.getCountries().then(function (data) {
                    scope.countryList = data.value;
                });

                codeSvc.getStates().then(function (data) {
                    scope.stateList = data.value;
                });

                scope.onCountryCodeChange = function () {
                    if (scope.collegeVerificationDto.countryCode === 'US') {
                        scope.collegeVerificationDto.province = null;
                    } else {
                        scope.collegeVerificationDto.stateCode = null;
                    }
                }
            }
        };
    });

    module.directive('college', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'college.html',
            scope: {
                // Separate properties for college-directory and college-verification led to strange nested scope issues.
                // Setting scope.collegeVerification to null broke 2-way binding with collgeVerication directive.
                currentInformation: '=',
                referenceId: '@',
                collegeVerificationTypeId: '@',
                noLabel: '@?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.hideLabel = scope.noLabel ? true : false;

                scope.isNewInstitution = scope.currentInformation.collegeVerificationDto ? true : false;

                scope.addNewInstitution = function () {
                    resetCollege(true);
                };

                scope.newSearch = function () {
                    resetCollege(false);
                };

                function resetCollege(isNewInstitution) {
                    scope.currentInformation.collegeDirectoryDto = null;
                    if (isNewInstitution) {
                        scope.currentInformation.collegeVerificationDto = {
                            referenceId: (scope.referenceId ? parseInt(scope.referenceId) : 0),
                            collegeVerificationTypeId: parseInt(scope.collegeVerificationTypeId)
                        };
                    } else {
                        scope.currentInformation.collegeVerificationDto = null;
                    }
                    scope.isNewInstitution = isNewInstitution;
                }
            }
        };
    });

    module.directive('collegeDirectoryFilters', function (collegeDirectorySvc, codeSvc, barSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'collegeDirectoryFilters.html',
            scope: {
                colleges: '=',
                message: '=?',
                pending: '@'
            },
            link: function (scope) {
                scope.stateList = [];
                scope.countryList = [];
                scope.collegeFilters = {
                    organizationName: null,
                    country: null,
                    state: null
                };

                scope.$watch(function () { return collegeDirectorySvc.getDefaultFilters(); }, function (newValue, oldValue) {
                    if (hasFilters(newValue)) {
                        scope.collegeFilters = newValue;
                        scope.retrieve();
                    }
                }, true);

                scope.retrieve = function () {
                    var searchOpts = scope.collegeFilters;
                    var pending = scope.pending ? true : false;
                    scope.message = null;

                    if (!hasFilters(searchOpts) && !pending) {
                        scope.colleges = [];
                        collegeDirectorySvc.data.colleges = [];
                        collegeDirectorySvc.data.pending = [];
                        scope.message = "Please Expand Your Search";
                        return;
                    }

                    barSvc.showSpinner();

                    collegeDirectorySvc.advancedSearch(searchOpts, pending).then(function (data) {
                        if ((collegeDirectorySvc.data.colleges.length === 0 && !pending) || collegeDirectorySvc.data.pending.length === 0 && pending) {
                            scope.message = "No results found";
                        }
                        collegeDirectorySvc.data.message = scope.message;

                        barSvc.hideSpinner();
                    });
                };
                
                scope.reset = function () {
                    scope.collegeFilters = null;
                    scope.colleges = [];
                    collegeDirectorySvc.data.colleges = [];
                    collegeDirectorySvc.data.pending = [];
                    scope.message = scope.$parent.model.defaultSearchMsg;
                };

                var hasFilters = function (filters) {
                    var hasNameOption = (filters.collegeName && filters.collegeName.length > 2) ? true : false;
                    var hasCountryOption = (filters.country) ? true : false;
                    var hasStateOption = (filters.state) ? true : false;
                    return  hasNameOption || hasCountryOption || hasStateOption || false;
                };

                var activate = function () {
                    codeSvc.getStates().then(function (data) {
                        scope.stateList = data.value;
                    });

                    codeSvc.getCountries().then(function (data) {
                        scope.countryList = data.value;
                    });
                }();
            }
        };
    });

}(angular.module('collegeDirectory')));