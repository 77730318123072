(function (module) {

    var userOrganizationCtrl = function ($state, $stateParams, organizationSvc, membershipSvc, currentUser, helperSvc, eventSvc, organizationTypes, contactRoleTypes) {
        var model = this;
        var profile = currentUser.profile;

        model.title = 'Organization';
        model.multiOrgTitle = 'Organizations';
        model.organizationSelected = false;
        model.showOrgBtn = false;
        model.showSpinner = true;
        model.isEmpty = helperSvc.isEmpty;

        var rolesAllowed = [
            contactRoleTypes.PRESIDENT,
            contactRoleTypes.PROVOST,
            contactRoleTypes.DELEGATEFORPRESIDENT,
            contactRoleTypes.PRIMARY,
            contactRoleTypes.SECONDARY,
            contactRoleTypes.DELEGATEFORPRIMARY,
            contactRoleTypes.EDUCATIONLIASON,
            contactRoleTypes.MEMBERPRESIDENT,
            contactRoleTypes.TRAININGMANAGER,
            contactRoleTypes.STATEBOARDLIAISON,
            //contactRoleTypes.ABETSTAFF,
            contactRoleTypes.PROGRAM

        ];

        model.organizations = [];

        eventSvc.listen(activate, 'refreshOrganizationGeneral');

        activate();

        function activate() {
            var getCurrentOrganizations = true;
            var orgTypesIncluded = [organizationTypes.INSTITUTION, organizationTypes.MEMBERSOCIETY, organizationTypes.ASSOCIATESOCIETY, organizationTypes.STATEBOARD];

            membershipSvc.getByUserIdWithoutDesignation(profile.userId, getCurrentOrganizations, null).then(function (data) {
                data = helperSvc.getResults(data);
                data = helperSvc.getFilteredArray(data, 'roleId', rolesAllowed, true);
                data = helperSvc.uniqueArrayByKey(data, 'organizationId');
                
                //filter on org types here;
                for (var i = 0; i < data.length; i++) {
                    if (orgTypesIncluded.indexOf(data[i].organizationTypeId) !== -1)
                        model.organizations.push(data[i]);
                }              
                if (model.organizations) {
                    if ($stateParams.organizationId) {
                        var organizationId = $stateParams.organizationId;
                        model.showOrgBtn = model.organizations.length > 1;
                        model.organizationSelected = true;

                        organizationSvc.getOrganizationByUser(organizationId).then(function (data) {
                            data = helperSvc.getItem(data);
                            model.organization = data;
                            model.organizationId = data.organizationId;
                            model.title = data.currentOrganizationDetailDto.organizationName;
                            eventSvc.broadcast('loadOrganizationGeneral');

                            model.showSpinner = false;
                        });
                    } else if (model.organizations.length === 1) {
                        $state.go('organization.detail', { organizationId: model.organizations[0].organizationId });
                    } else if (model.organizations.length > 1) {
                        model.organizationId = model.organizations[0].organizationId;
                        model.showSpinner = false;  
                    } else {
                        model.showSpinner = false;
                    }
                }
            });
        }
    };

    module.controller('userOrganizationCtrl', userOrganizationCtrl);

}(angular.module('organization')));