(function (module) {

    var editSessionCtrl = function ($scope, $uibModalInstance, $stateParams, helperSvc, trainingSvc, alertSvc, oauth, training,$filter) {
        var model = $scope;
        model.title = 'Add/Edit Training Session'
        model.isInsertMode = false;
        model.currentSession = {};
        model.training = training;

        model.save = function (session) {
            model.currentSession.trainingId = training.trainingId;

            if (model.currentSession.startDate) {
                var tempDate = model.currentSession.startDate.toLowerCase();
                var isPm = false;
                if (tempDate.indexOf('am') >= 0 || tempDate.indexOf('pm') >= 0) {
                    if (tempDate.indexOf('pm') >= 0)
                        isPm = true;
                    tempDate = tempDate.replace('am', '').replace('pm', '');
                }
                tempDate = new Date(tempDate);
                model.currentSession.startDate = new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(),
                    isPm ? tempDate.getHours() + (tempDate.getHours() == 12 ? 0 : 12) : tempDate.getHours(), tempDate.getMinutes(), 0
                ))
            }

            if (!model.currentSession.trainingSessionId) {
                trainingSvc.addSession(model.currentSession).then(function () {
                    alertSvc.addModalAlertSuccess("Training Session succesfully added.");
                    model.fetchSession();
                    model.toggleModes();
                });
            } else {
                trainingSvc.updateSession(model.currentSession).then(function () {
                    alertSvc.addModalAlertSuccess("Training Session succesfully updated.");
                    model.fetchSession();
                    model.toggleModes();
                });
            }
        };

        model.edit = function (session) {
            model.toggleModes(true);

            if (session.trainingSessionId) {
                model.currentSession = session;
                model.currentSession.trainingId = training.trainingId;
                model.currentSession.startDate = $filter('date')(model.currentSession.startDate, 'M/dd/yyyy h:mma', 'UTC');
                model.currentSession.endDate = helperSvc.formatDate(model.currentSession.endDate, true);
                model.currentSession.registrationEndDate = helperSvc.formatDate(model.currentSession.registrationEndDate, true);
            }
        };

        model.delete = function (session) {
            alertSvc.confirmDelete(session.sessionName, deleteFunc, session);

            function deleteFunc () {
                trainingSvc.deleteSession(session).then(function () {
                    alertSvc.addModalAlertSuccess("Session succesfully deleted.");
                    model.fetchSession();
                });
            }
        };

        model.fetchSession = function () {
            trainingSvc.getPEVCTrainingSessionsAdmin(training.trainingId).then(function (data) {
                model.sessions = data;
            });
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleModes = function (isEdit) {
            model.isInsertMode = !model.isInsertMode;

            if (!isEdit) {
                if (model.form) {
                    model.form.$setPristine();
                }
                model.currentSession = {};
            }
        };

        var activate = function () {
            model.fetchSession();
        }();
    };

    module.controller('editSessionCtrl', editSessionCtrl);

}(angular.module('training')));