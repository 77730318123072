(function (module) {

    var codeSvc = function ($http, $q, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/';
        var factory = {};

        var onSuccessParseInts = function (response) {
            if (response.status == 200) {
                var data = response.data;

                if (data.value && data.value.length > 0) {
                    for (var i = 0; i < data.value.length; i++) {
                        data.value[i].codeKey = parseInt(data.value[i].codeKey, 10);
                    }
                }

                return data;
            }
        };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        var getData = function (url, onSuccessFunc, onFailureFunc, dataItemName, noStoredData) {
            var promise;

            if (!noStoredData && factory.data[dataItemName]) {
                // Asynchronously return cached data
                var deferred = $q.defer();
                promise = deferred.promise;
                deferred.resolve(factory.data[dataItemName]);
            } else {
                // Get fresh data from API and cache unless otherwise indicated
                promise = $http.get(url).then(onSuccessFunc, onFailureFunc);
                if (!noStoredData) {
                    promise.then(function (data) { factory.data[dataItemName] = data; });
                }
            }

            return promise;
        }

        factory.data = {};

        // odata/GetNamePrefixes()?$orderby=codeKey
        factory.getNamePrefixes = function () {
            return $http.get(apiPath + "GetNamePrefixes()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        // odata/GetNameSuffixes()?$orderby=codeKey
        factory.getNameSuffixes = function () {
            return $http.get(apiPath + "GetNameSuffixes()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        // odata/GetProfessionalSuffixes()?$orderby=codeKey
        factory.getProfessionalSuffixes = function () {
            return $http.get(apiPath + "GetProfessionalSuffixes()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        // odata/GetCountries()?$orderby=codeKey
        factory.getCountries = function () {
            return $http.get(apiPath + "GetCountries()?$orderby=codeName").then(onSuccess, onFailure);
        }

        // odata/GetStates()?$orderby=codeKey
        factory.getStates = function () {
            return $http.get(apiPath + "GetStates()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        // odata/GetAddressTypes()?$orderby=codeKey
        factory.getAddressTypes = function () {
            return $http.get(apiPath + "GetAddressTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        }

        // odata/GetDesignationTypes()?$orderby=codeKey
        factory.getDesignationTypes = function () {
            return $http.get(apiPath + "GetDesignationTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        }

        // odata/GetRoleTypes()?$orderby=codeKey
        factory.getRoleTypes = function () {
            return $http.get(apiPath + "GetRoleTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        }

        // odata/GetTelephoneTypes()?$orderby=codeKey
        factory.getTelephoneTypes = function () {
            return $http.get(apiPath + "GetTelephoneTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        }

        // odata/GetOrganizationTypes()?$orderby=codeKey
        factory.getOrganizationTypes = function () {
            return $http.get(apiPath + "GetOrganizationTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        }
        
        factory.getCommissions = function (noStoredData) {
            return getData(apiPath + "GetCommissions()?$orderby=codeKey", onSuccessParseInts, onFailure, "commissions", noStoredData)
        };

        // odata/GetDegrees()?$orderby=codeKey
        factory.getDegrees = function () {
            return $http.get(apiPath + "GetDegrees()?$orderby=codeKey").then(onSuccess, onFailure);
        };

        // odata/GetDegreeLevels()?$orderby=codeKey
        factory.getDegreeLevels = function () {
            return $http.get(apiPath + "GetDegreeLevels()?$orderby=codeKey").then(onSuccess, onFailure);
        };

        // odata/GetMemberSocieties()?$orderby=codeKey
        factory.getMemberSocieties = function () {
            return $http.get(apiPath + "GetMemberSocieties()?$orderby=codeName").then(onSuccessParseInts, onFailure);
        }

        // odata/GetMemberAndSupportingSocieties()?$orderby=codeKey
        factory.getMemberAndSupportingSocieties = function () {
            return $http.get(apiPath + "GetMemberAndSupportingSocieties()?$orderby=codeName").then(onSuccessParseInts, onFailure);
        }

        // odata/GetDistanceLearningTypes()?$orderby=codeKey
        factory.getDistanceLearningTypes = function () {
            return $http.get(apiPath + "GetDistanceLearningTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetReviewTeams()?$orderby=codeKey
        factory.getReviewTeams = function () {
            return $http.get(apiPath + "GetReviewTeams()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetDisciplineTypes()?$orderby=codeKey
        factory.getDisciplineTypes = function () {
            return $http.get(apiPath + "GetDisciplineTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetActions()?$orderby=codeKey
        factory.getActions = function () {
            return $http.get(apiPath + "GetActions()?$orderby=codeKey").then(onSuccess, onFailure);
        };

        // odata/GetProgramReviewTypes()?$orderby=codeKey
        factory.getProgramReviewTypes = function (methodTypeCode) {
            var parameter = methodTypeCode ? "'" + methodTypeCode + "'": 'null';
            var fullApiPath = apiPath + "GetProgramReviewTypes(methodTypeCode={0})?$orderby=codeKey".format(parameter);
            return $http.get(fullApiPath).then(onSuccess, onFailure);
        };

        // odata/GetReviewTypes()?$orderby=codeKey
        factory.getReviewTypes = function () {
            return $http.get(apiPath + "GetReviewTypes()?$orderby=codeKey").then(onSuccess, onFailure);
        };

        // odata/GetTeamMemberTypes()?$orderby=codeKey
        factory.getTeamMemberTypes = function () {
            return $http.get(apiPath + "GetTeamMemberTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetVisitObserverTypes()?$orderby=codeKey
        factory.getVisitObserverTypes = function () {
            return $http.get(apiPath + "GetVisitObserverTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetPevTypes()?$orderby=codeKey
        //Use Edm.String - Or it gives an error to wifi connected clients
        factory.getPevTypes = function () {
            //var pevFilter = "&$filter=codeKey eq '1' or codeKey eq '7'";
            var pevFilter = "&$filter=codeKey eq cast(1,'Edm.String') or codeKey eq cast(7,'Edm.String')";

            return $http.get(apiPath + "GetTeamMemberTypes()?$orderby=codeKey" + pevFilter).then(onSuccessParseInts, onFailure);
        };

        // odata/GetTeamMemberTypes()?$filter=codeKey ne '1' and codeKey ne '7'&$orderby=codeKey
        //Use Edm.String - Or it gives an error to wifi connected clients
        factory.getTeamChairAndEditorTypes = function () {
            return $http.get(apiPath + "GetTeamMemberTypes()?$filter=codeKey ne cast(1,'Edm.String') and codeKey ne cast(7,'Edm.String')&$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetEvaluatorAssignmentStatuses()?$orderby=codeKey
        //factory.getEvaluatorAssignmentStatuses = function () {
        //    return $http.get(apiPath + "GetEvaluatorAssignmentStatuses()?$orderby=codeKey").then(onSuccess, onFailure);
        //};

        //// odata/GetEvaluatorAssignmentRejectReasons()?$orderby=codeKey
        //factory.getEvaluatorAssignmentRejectReasons = function () {
        //    return $http.get(apiPath + "GetEvaluatorAssignmentRejectReasons()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        //};

        factory.getTeamMemberStatuses = function () {
            return $http.get(apiPath + "GetTeamMemberStatuses()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        };

        // odata/GetChangeTypes()
        factory.getChangeTypes = function () {
            return $http.get(apiPath + "GetChangeTypes()").then(onSuccessParseInts, onFailure);
        }

        // odata/GetChangeTypes()?$filter=changeTypeId ne [programTerminationId] and changeTypeId ne [degreeDesignationId]
        // USE TYPECONST
        factory.getAccreditationChangeTypes = function () {
            return $http.get(apiPath + "GetChangeTypes()?$filter=changeTypeId ne 8").then(onSuccessParseInts, onFailure);
        }

        // odata/GetReviewProcessStatuses()
        factory.getReviewProcessStatuses = function () {
            return $http.get(apiPath + "GetReviewProcessStatuses()").then(onSuccessParseInts, onFailure);
        }

        factory.getRfrProcessStatuses = function () {
            return $http.get(apiPath + "GetRfrProcessStatuses()").then(onSuccessParseInts, onFailure);
        };

        factory.getReadinessStatuses = function () {
            return $http.get(apiPath + "GetReadinessStatuses()").then(onSuccessParseInts, onFailure);
        };

        factory.getRfrTranscriptStatuses = function () {
            return $http.get(apiPath + "GetRfrTranscriptStatuses()").then(onSuccessParseInts, onFailure);
        };

        // odata/GetVolunteerDisciplineStatuses()
        factory.getVolunteerDisciplineStatuses = function () {
            return $http.get(apiPath + "GetVolunteerDisciplineStatuses()").then(onSuccessParseInts, onFailure);
        };

        // odata/GetLicenses()
        factory.getLicenses = function () {
            return $http.get(apiPath + "GetLicenses()?$orderby=codeKey").then(onSuccess, onFailure);
        };

        // odata/GetAvailabilityTypes()
        factory.getAvailabilityTypes = function () {
            return $http.get(apiPath + "GetAvailabilityTypes()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        factory.getLanguages = function () {
            return $http.get(apiPath + "GetLanguages()?$orderby=codeName").then(onSuccess, onFailure);
        }

        factory.getLanguageLevels = function () {
            return $http.get(apiPath + "GetLanguageLevels()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        factory.getBackgroundTypes = function () {
            return $http.get(apiPath + "GetBackgroundTypes()?$orderby=codeKey").then(onSuccessParseInts, onFailure);
        }

        factory.getAcademicTypes = function () {
            return $http.get(apiPath + "GetAcademicTypes()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        factory.getEmploymentTypes = function () {
            return $http.get(apiPath + "GetEmploymentTypes()?$orderby=codeKey").then(onSuccess, onFailure);
        }

        factory.getContactTypes = function () {
            return $http.get(apiPath + "GetContactTypes()?$orderby=codeName").then(onSuccessParseInts, onFailure);
        }

        factory.getEthnicityTypes = function () {
            return $http.get(apiPath + "GetEthnicityTypes()?$orderby=codeName").then(onSuccessParseInts, onFailure);
        }

        factory.getSocietyDiscpiplineTypes = function () {
            return $http.get(apiPath + "DisciplineSociety()?$orderby=disciplineName").then(onSuccess, onFailure);
        }

        factory.getProgramAreaSociety = function () {
            return $http.get(apiPath + "GetProgramAreaSociety()?$orderby=disciplineName").then(onSuccess, onFailure);
        };

        factory.getReadinessResponseTypes = function () {
            return $http.get(apiPath + "GetReadinessResponseTypes()").then(onSuccessParseInts, onFailure);
        };

        factory.getAppraisalTypes = function(){
            return $http.get(apiPath + "GetAppraisalTypes()").then(onSuccess, onFailure);
        }

        factory.getVolunteerAppSocietyStatuses = function () {
            return $http.get(apiPath + "GetVolunteerAppSocietyStatuses()").then(onSuccess, onFailure);
        }

        factory.getVolunteerAppStatuses = function () {
            return $http.get(apiPath + "GetVolunteerAppStatuses()").then(onSuccess, onFailure);
        }

        factory.getStatementTypes = function () {
            return $http.get(apiPath + "GetStatementTypes()").then(onSuccess, onFailure);
        }

         factory.getCriterion = function () {
            return $http.get(apiPath + "GetCriterion()").then(onSuccess, onFailure);
        }

        factory.getStatementStatuses = function(){
            return $http.get(apiPath + "GetStatementStatuses()").then(onSuccess, onFailure);
         }

        factory.getCommissionerNominationStatuses = function () {
            return getData(apiPath + "GetCommissionerNominationStatuses()?$orderby=codeKey", onSuccessParseInts, onFailure, "commissionerNominationStatuses", noStoredData)
        }

        //getTrainingTypes

        factory.getTrainingTypes = function () {
            return $http.get(apiPath + "GetTrainingTypes()").then(onSuccess, onFailure);
        }

        factory.getTrainingObserverTypes = function () {
            return $http.get(apiPath + "GetTrainingObserverTypes()").then(onSuccess, onFailure);
        }

        factory.getNpcStatuses = function () {
            return $http.get(apiPath + "GetNpcStatuses()").then(onSuccessParseInts, onFailure);
        }

        factory.getNpcChangeTypes = function () {
            return $http.get(apiPath + "GetNpcChangeTypes()").then(onSuccessParseInts, onFailure);
        }

        factory.getNpcActionTypes = function () {
            return $http.get(apiPath + "GetNpcActionTypes()").then(onSuccessParseInts, onFailure);
        }
        factory.getOnsiteVisitRestrictionStatuses = function () {
            return $http.get(apiPath + "GetOnsiteVisitRestrictionStatuses()").then(onSuccessParseInts, onFailure);
        }
        return {
            data: factory.data,
            getNamePrefixes: factory.getNamePrefixes,
            getNameSuffixes: factory.getNameSuffixes,
            getProfessionalSuffixes: factory.getProfessionalSuffixes,
            getCountries: factory.getCountries,
            getStates: factory.getStates,
            getAddressTypes: factory.getAddressTypes,
            getDesignationTypes: factory.getDesignationTypes,
            getRoleTypes: factory.getRoleTypes,
            getTelephoneTypes: factory.getTelephoneTypes,
            getOrganizationTypes: factory.getOrganizationTypes,
            getCommissions: factory.getCommissions,
            getDegrees: factory.getDegrees,
            getDegreeLevels: factory.getDegreeLevels,
            getMemberSocieties: factory.getMemberSocieties,
            getMemberAndSupportingSocieties: factory.getMemberAndSupportingSocieties,
            getDistanceLearningTypes: factory.getDistanceLearningTypes,
            getReviewTeams: factory.getReviewTeams,
            getDisciplineTypes: factory.getDisciplineTypes,
            getActions: factory.getActions,
            getProgramReviewTypes: factory.getProgramReviewTypes,
            getReviewTypes: factory.getReviewTypes,
            getTeamChairAndEditorTypes: factory.getTeamChairAndEditorTypes,
            getVisitObserverTypes: factory.getVisitObserverTypes,
            getTeamMemberTypes: factory.getTeamMemberTypes,
            getPevTypes: factory.getPevTypes,
            getTeamMemberStatuses: factory.getTeamMemberStatuses,
            getChangeTypes: factory.getChangeTypes,
            getAccreditationChangeTypes: factory.getAccreditationChangeTypes,
            getReviewProcessStatuses: factory.getReviewProcessStatuses,
            getRfrProcessStatuses: factory.getRfrProcessStatuses,
            getReadinessStatuses: factory.getReadinessStatuses,
            getRfrTranscriptStatuses: factory.getRfrTranscriptStatuses,
            getVolunteerDisciplineStatuses: factory.getVolunteerDisciplineStatuses,
            getLicenses: factory.getLicenses,
            getAvailabilityTypes: factory.getAvailabilityTypes,
            getLanguages: factory.getLanguages,
            getLanguageLevels: factory.getLanguageLevels,
            getBackgroundTypes: factory.getBackgroundTypes,
            getAcademicTypes: factory.getAcademicTypes,
            getEmploymentTypes: factory.getEmploymentTypes,
            getContactTypes: factory.getContactTypes,
            getEthnicityTypes: factory.getEthnicityTypes,
            getSocietyDiscpiplineTypes: factory.getSocietyDiscpiplineTypes,
            getProgramAreaSociety: factory.getProgramAreaSociety,
            getReadinessResponseTypes: factory.getReadinessResponseTypes,
            getAppraisalTypes: factory.getAppraisalTypes,
            getVolunteerAppSocietyStatuses: factory.getVolunteerAppSocietyStatuses,
            getVolunteerAppStatuses: factory.getVolunteerAppStatuses,
            getStatementTypes: factory.getStatementTypes,
            getCriterion: factory.getCriterion,
            getStatementStatuses: factory.getStatementStatuses,
            getCommissionerNominationStatuses: factory.getCommissionerNominationStatuses,
            getTrainingTypes: factory.getTrainingTypes,
            getTrainingObserverTypes: factory.getTrainingObserverTypes,
            getNpcStatuses: factory.getNpcStatuses,
            getNpcChangeTypes: factory.getNpcChangeTypes,
            getNpcActionTypes: factory.getNpcActionTypes,
            getOnsiteVisitRestrictionStatuses: factory.getOnsiteVisitRestrictionStatuses
        };
    };
    module.factory('codeSvc', codeSvc);

})(angular.module('common'));