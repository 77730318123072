(function (module) {

    var campusEditCtrl = function (parent, review, $scope, $uibModalInstance, codeSvc, helperSvc, eventSvc, programReviewSvc, alertSvc) {
        var model = $scope;

        model.title = 'Edit Campus Location(s)';
        model.review = angular.copy(review);
        model.reviewTeamId = model.review.reviewTeamId;
        model.addresses = [];
        model.isOriginalRecord = true;
        // model.noCampusesEntered = false;

        if (review.programReviewCampusDtos.length > 0) {
            model.addresses = model.review.programReviewCampusDtos;
            angular.forEach(model.addresses, function (item, key) {
                item.isOutsideUS = (item.addressDto.countryCode !== 'US');
            });
        } else {
            // model.noCampusesEntered = true;
        }

        model.save = function () {
            var newCampusArr = [];

            angular.forEach(model.addresses, function (address, key) {
                //todovarner this causes the display to flicker/change the state/provice field. Need to fix
                delete address.isOutsideUS;
                delete address.isNew;

                if (address.isDeleted === false || address.programReviewCampusId !== 0)
                    newCampusArr.push(address);
            });

            model.review.programReviewCampusDtos = newCampusArr;

            programReviewSvc.update(model.review).then(function (data) {
                updateDataAndClose();
                alertSvc.addAlertSuccess("Address successfully updated.");
            });
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        function updateDataAndClose() {
            var programsDataSource = {
                dataHolder: programReviewSvc,
                dataLocationName: 'data.programs',
                svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [model.reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true
            }

            var reviewsDataSource = {
                dataHolder: programReviewSvc,
                svcCallback: [programReviewSvc.getReviewsByProgramId, programReviewSvc.getReviewsChildrenByReviewTeamId],
                svcCallbackArguments: [model.review.programId], 
                dataLocationName: 'data.reviews',
                helperCallback: helperSvc.getValue,
                orderByProperty: 'programReviewId'
            };

            helperSvc.getData([programsDataSource, reviewsDataSource]);
            $uibModalInstance.close();
        }
    };

    module.controller('campusEditCtrl', campusEditCtrl);

}(angular.module('common')));