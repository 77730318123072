(function (module) {

    var offlineSvc = function ($state, $stateParams, serviceWorkerCommands, localStorage) {
        var factory = {
            data: {
                selectedReviews: []
            }
        };

        var OFFLINE_FLAG = "appOffline";

        factory.isBrowserOffline = function () {
            return !window.navigator.onLine;
        }

        factory.isAppOffline = function () {
            return localStorage.get(OFFLINE_FLAG) || false;
        }

        factory.select = function (review) {
            if (review.isSelected) {
                factory.data.selectedReviews.push(review);
            } else {
                var index = factory.data.selectedReviews.findIndex(function (currentReview) {
                    return currentReview.id == review.id;
                });
                if (index > -1) factory.data.selectedReviews.splice(index, 1);
            }
        };

        factory.goOffline = function (state, stateParams) {
            try {
                factory.setAppOffline(true);
                $state.go(state, stateParams);
            } catch (error) {
                // Don't leave user trapped in offline state
                try {
                    factory.setAppOffline(false);
                } finally {
                    // Rethrow so caller can handle
                    throw error;
                }
            }
        };

        factory.goOnline = function (state, stateParams) {
            try {
                factory.setAppOffline(false);
            } finally {
                $state.go(state, stateParams);
            }
        }

        factory.setAppOffline = function (status) {
            if (status) {
                navigator.serviceWorker.controller.postMessage({ command: serviceWorkerCommands.GO_OFFLINE });
                localStorage.add(OFFLINE_FLAG, true);
            } else {
                localStorage.remove(OFFLINE_FLAG);
                navigator.serviceWorker.controller.postMessage({ command: serviceWorkerCommands.GO_ONLINE });
            }
        }

        factory.getSelectedReviews = function () {
            return factory.data.selectedReviews;
        };

        factory.offlineSupported = function () {
            // Offline mode is supported but not necessarily available
            return 'serviceWorker' in navigator && typeof caches !== 'undefined';
        };

        factory.offlineAvailable = function () {
            // Offline mode is supported and available
            return factory.offlineSupported() && navigator.serviceWorker.controller;
        }

        return {
            isBrowserOffline: factory.isBrowserOffline,
            isAppOffline: factory.isAppOffline,
            goOffline: factory.goOffline,
            goOnline: factory.goOnline,
            setAppOffline: factory.setAppOffline,
            getSelectedReviews: factory.getSelectedReviews,
            select: factory.select,
            offlineSupported: factory.offlineSupported,
            offlineAvailable: factory.offlineAvailable
        };
    };

    module.factory('offlineSvc', offlineSvc);

})(angular.module('common'));