(function (module) {

    var pevAppSummaryModalCtrl = function (application, $scope, $uibModalInstance, pevAppSvc, documentSvc, pdfSvc) {
        var model = $scope;

        model.title = application.firstName + " " + (angular.isString(application.middleName) && application.middleName != "" ? application.middleName + " " : "") + application.lastName;
        model.subtitle = "Application Summary";
        model.isDataReady = false;

        pevAppSvc.data.application = application;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.printModalWithResume = function () {
            var contentToPrint = document.getElementById("modal-body").innerHTML;
            var elem = document.createElement("div");
            var isAssignmentTool = document.body.className.contains("assignment-tool");

            // Hide normal page content then add new element with print content
            setNodeVisibilityTo("hidden");
            elem.className = "modal-print-contents";
            elem.innerHTML = contentToPrint;
            document.body.appendChild(elem);

            if (model.resumeStreamId) {
                // Render PDF of resume/cv and append to print-able contents
                var pdfElem = document.createElement("div");
                pdfElem.id = "pdf";
                pdfElem.className = "print-only print-pdf";
                // Insert resume/cv BEFORE application  
                //elem.insertBefore(pdfElem, elem.childNodes[0]);
                // Insert resume/cv AFTER application
                elem.insertBefore(pdfElem, null);
                var documentUrl = documentSvc.getFileUrl(model.resumeStreamId);
                var pdf = document.getElementById('pdf');
                pdfSvc.renderPDF(documentUrl, pdf).then(doPrint);
            } else {
                // Print as normal without PDF content
                doPrint();
            }

            function setNodeVisibilityTo(visibility) {
                for (var i = 0; i < document.body.children.length; i++) {
                    var node = document.body.children[i];
                    if (node.style) {
                        node.style.visibility = visibility;
                        if (visibility == "hidden" && !node.style.display) {
                            node.style.display = "none";
                        } else if (node.style.display == "none") {
                            node.style.display = "";
                        }
                    }
                }
            }

            function doPrint() {
                // Remove assignment tool page formatting
                if (isAssignmentTool) document.body.classList.remove("assignment-tool");

                window.print();

                // Restore assignment tool page formatting
                if (isAssignmentTool) document.body.classList.add("assignment-tool");

                // Remove element with print content and show normal page content
                document.body.removeChild(elem);
                setNodeVisibilityTo("visible");
            }
        };

        var activate = function () {
            pevAppSvc.getApplicationByVolunteerApplicationId(application.volunteerApplicationId).then(function (data) {
                model.resumeStreamId = pevAppSvc.data.application.stream_id;
                model.isDataReady = true;
            })
        }();
    };

    module.controller('pevAppSummaryModalCtrl', pevAppSummaryModalCtrl);

}(angular.module('volunteer')));