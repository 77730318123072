(function (module) {

    var pevcAttendedCtrl = function ($scope, oauth, alertSvc, commissionAbbreviations, helperSvc, searchOptions, $uibModalInstance,
        trainingSvc, trainingStatusIds, trainingStatusNames
    ) {
        var model = $scope;

        model.dataIsReady = false;
        model.isAdmin = oauth.isAdmin();
        model.title = 'PEVC Attendance';
        model.searchOptions = { trainingYear: searchOptions.trainingYear, societyId: searchOptions.societyId, trainingSessionId: searchOptions.trainingSessionId };
        model.commissionNames = commissionAbbreviations;
        model.trainingStatusIds = trainingStatusIds;
        model.trainingStatusNames = trainingStatusNames;
        model.attendedPevcs = [];
        model.notAttendedPevcs = [];
        model.resetAttendancePevcs = [];

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.cancelPevAttendance = function (pevc) {
            if (model.isInAttended(pevc)) {
                var index = model.attendedPevcs.indexOf(pevc.trainingPersonId);
                model.attendedPevcs.splice(index, 1);
            }
            if (model.isInAttended(pevc, true)) {
                var index = model.notAttendedPevcs.indexOf(pevc.trainingPersonId);
                model.notAttendedPevcs.splice(index, 1);
            }
           
            pevc.attended = null;            
            model.resetAttendancePevcs.push(pevc.trainingPersonId);
            pevc.trainingStatusId = model.trainingStatusIds.REGISTERED;
        }

        model.save = function () {
            trainingSvc.sendBatchAttendedIds(model.attendedPevcs, model.notAttendedPevcs, model.resetAttendancePevcs).then(function () {
                alertSvc.addAlertSuccess("Pevcs attendance successfully recorded");
                trainingSvc.getPEVCTrainingSearch(searchOptions).then(function () {
                    model.data = angular.copy(trainingSvc.data);
                    assignAttendance();
                });
                $uibModalInstance.close();
            });
        }

        model.isInAttended = function (pevc, notAttended) {
            if (notAttended) {
                return model.notAttendedPevcs.indexOf(pevc.trainingPersonId) > -1
            } else {
                return model.attendedPevcs.indexOf(pevc.trainingPersonId) > -1
            }
        }

        model.addAttended = function(pevc, notAttended){
            //check if in reset first
            var indexOfReset = model.resetAttendancePevcs.indexOf(pevc.trainingPersonId);
            if(indexOfReset > -1){
                model.resetAttendancePevcs.splice(indexOfReset, 1);
            }

            if (notAttended) {
                if (!model.isInAttended(pevc, notAttended)) {
                    model.notAttendedPevcs.push(pevc.trainingPersonId)
                    pevc.attended = false;
                }
            } else {
                if (!model.isInAttended(pevc)) {
                    model.attendedPevcs.push(pevc.trainingPersonId)      
                    pevc.attended = true;
                }            
            }
        }

        model.filterPevcs = function () {
            var tempSearchOptions = model.searchOptions;
            tempSearchOptions.societyId = null;

            trainingSvc.getPEVCTrainingSearch(tempSearchOptions,true).then(function (data) {
                var mergedSolution = [];
                data.forEach(function (item) {
                    var existing = mergedSolution.filter(function (v, i) {
                        return v.trainingPersonId === item.trainingPersonId;
                    })
                    if (existing.length === 0) {
                        mergedSolution.push(item);
                    }
                })
                angular.forEach(mergedSolution, function (pevc) {
                    pevc.volunteerApplicationSocieties = angular.fromJson(pevc.volunteerApplicationSocieties);
                });
                model.data.pevcs = mergedSolution;

                assignAttendance();
            });
        }

        function assignAttendance() {
            angular.forEach(model.data.pevcs, function (pevc) {
                if (pevc.trainingStatusId === model.trainingStatusIds.ATTENDED)
                    pevc.attended = true;
                if (pevc.trainingStatusId === model.trainingStatusIds.NOTATTENDED)
                    pevc.attended = false;

            })
        }

        var activate = function () {
            var dataArray = []

            var getTrainingSessionData = {
                dataHolder: model,
                dataLocationName: 'trainingSessions',
                svcCallback: trainingSvc.getPEVCTrainingSessionsAdmin,
                svcCallbackArguments: [trainingSvc.data.trainingSessions[0].trainingId],
                odataResource: true
            }
            dataArray.push(getTrainingSessionData);

            helperSvc.getData(dataArray).then(function () {

                model.data = angular.copy(trainingSvc.data);
                assignAttendance();

                model.dataIsReady = true;

            });           
            
        }();
    };

    module.controller('pevcAttendedCtrl', pevcAttendedCtrl);

}(angular.module('training')));