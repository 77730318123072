(function (module) {

    var changeHistoryCtrl = function ($scope, $uibModal, helperSvc, changeSvc) {
        var model = this;
        model.parent = $scope.$parent.model;
        model.title = 'History of Accreditation Changes';
        model.data = changeSvc.data;

        model.editChangeHistory = function (changesOrReview, isProgramReview) {
            var isProgramReview = (isProgramReview === 'true');

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/common/templates/misc/changeHistoryEdit.html',
                size: 'lg',
                controller: 'changeHistoryEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    isProgramReview: function () {
                        return isProgramReview;
                    },
                    changesOrReview: function () {
                        return changesOrReview;
                    }
                }
            });

        };

        model.isEmpty = helperSvc.isEmpty;
        model.formatDate = helperSvc.formatDate;
    };

    module.controller('changeHistoryCtrl', changeHistoryCtrl);

}(angular.module('common')));