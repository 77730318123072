(function (module) {

    var employmentInfoSvc = function (odataServiceFactory) {
        var config = {
            apiPath: '/VolunteerEmployment',
            keyName: 'volunteerEmploymentId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'employmentInfo',
            getByForeignKeyPath: '/GetVolunteerEmploymentByVolunteerId',
            expandables: ['collegeDirectoryDto', 'collegeVerificationDto']
        };

        var factory = new odataServiceFactory(config);

        return {
            data: factory.data,
            getByVolunteerId: factory.getByForeignKey,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('employmentInfoSvc', employmentInfoSvc);

})(angular.module('volunteer'));
