(function (module) {

    var changeProgramAuditDetailFindingCtrl = function (program, programAuditDetail, findingType, finding, $scope, $uibModalInstance, alertSvc,
        criteriaTypes, programAuditDetailTemplateSvc, programAuditDetailEvaluatorSvc, programAuditDetailSvc) {

        var model = $scope;

        model.title = 'Change Finding';
        model.isValid = false;
        model.program = program;
        model.programAuditDetail = angular.copy(programAuditDetail);
        model.findingType = findingType;
        model.finding = finding;
        model.newFindingType = null;
        model.findingTypes = null;
        model.isDataReady = false;

        const APPM_CRITERIA_ID = 26;

        model.isShortcoming = programAuditDetailTemplateSvc.isShortcoming;

        model.selectType = function (newFindingType) {
            model.newFindingType = newFindingType;
        }

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            programAuditDetailTemplateSvc.changeFindingType(model.program.commissionId, model.programAuditDetail, model.finding, model.newFindingType);
            model.isSaving = true;
            // Note: CRUD makes more sense in parent controller, not in a modal dialog but want to be consistent and avoid UI refresh flicker
            programAuditDetailSvc.update(model.programAuditDetail).then((programAuditDetail) => {
                if (programAuditDetailTemplateSvc.isShortcoming(model.newFindingType.statementFindingTypeId))
                    programAuditDetailEvaluatorSvc.resetProgramAuditRecommendedActions(programAuditDetail.programAuditDetailEvaluatorDtos).then((programAuditDetailEvaluators) => {
                        programAuditDetail.programAuditDetailEvaluatorDtos = programAuditDetailEvaluators;
                        onSuccess(programAuditDetail);
                    }).catch(onError);
                else
                    onSuccess(programAuditDetail);
            }).catch(onError);

            function onSuccess(programAuditDetail) {
                alertSvc.addAlertSuccess('Finding type changed.');
                $uibModalInstance.close(programAuditDetail);
            }

            function onError(error) {
                console.log(error);
                model.isSaving = false;
                alertSvc.addModalAlertWarning('Changing finding type failed.')
            }
        };
 
        activate();

        function activate() {
            programAuditDetailTemplateSvc.getFindingTypeOptions().then((findingTypeOptions) => {
                const currentFindingTypeIsShortcoming = programAuditDetailTemplateSvc.isShortcoming(model.findingType.statementFindingTypeId);
                const unavailableFindingTypes = !currentFindingTypeIsShortcoming || model.finding.criteria.criteriaId === APPM_CRITERIA_ID ?
                    [] :
                    model.programAuditDetail.programAuditJson.auditDetails.filter(findingType =>
                        findingType.findings && findingType.findings.some(finding =>
                            finding.criteria.criteriaId === model.finding.criteria.criteriaId
                        )
                    ).map(findingType => findingType.statementFindingTypeId);
                model.findingTypeOptions = findingTypeOptions.filter(findingType => 
                    findingType.statementFindingTypeId !== model.findingType.statementFindingTypeId &&
                    programAuditDetailTemplateSvc.isShortcoming(findingType.statementFindingTypeId) === currentFindingTypeIsShortcoming &&
                    !unavailableFindingTypes.includes(findingType.statementFindingTypeId)
                );
                if (model.findingTypeOptions.length === 1)
                    model.selectType(model.findingTypeOptions[0]);
                model.isDataReady = true;
            });
        };

    };

    module.controller('changeProgramAuditDetailFindingCtrl', changeProgramAuditDetailFindingCtrl);

}(angular.module('programAudit')));