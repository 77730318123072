(function (module) {
    var programRecommendedActionsCtrl = function ($q, $scope, programId, evaluatorReportSvc, $uibModalInstance, statementStorageSvc, helperSvc, teamMemberTypeNames) {
        var model = $scope;
        model.title = 'Program Recommended Action History';
        model.formatDate = helperSvc.formatDate;
        model.isDataReady = false;
        model.teamMemberTypeNames = teamMemberTypeNames;


        model.close = function () {
            $uibModalInstance.close();
        }

        function getLatestRecommendedActions() {
            var recommendedActionsArray = [];
            var statementArray = [];
            var teamMemberTypeArray = [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR, teamMemberTypeNames.EDITOR1, teamMemberTypeNames.EDITOR2]

            recommendedActionsArray.push({ role: "PEV", recommendedAction: statementStorageSvc.getPEVRA(programId) });
            if (model.data.statementHistory) {
                angular.forEach(teamMemberTypeArray, function (type) {
                    var statementReturned = helperSvc.getFirstMatch(model.data.statementHistory, 'teamMemberTypeId', type);

                    if (statementReturned)
                        statementArray.push(statementReturned);

                })

                if (statementArray && statementArray.length > 0) {
                    angular.forEach(statementArray, function (statement) {
                        var program = helperSvc.getFirstMatch(statement.statementDetailDtos, 'programId', programId)

                        if (program) {
                            recommendedActionsArray.push({ role: statement.teamMemberTypeName, recommendedAction: program.recommendedAction })
                        }
                    })
                }
            }
            return recommendedActionsArray;
        }

        var activate = function () {
            model.data = statementStorageSvc.data;
            model.recommendedActionsArray = getLatestRecommendedActions();
            evaluatorReportSvc.getEvaluatorReportByReviewTeamId(model.data.statement.reviewTeamId).then(function () {
                model.isDataReady = true;
            });
        }();

    };
    module.controller('programRecommendedActionsCtrl', programRecommendedActionsCtrl);

}(angular.module('statement')));