(function (module) {
    module.factory('programAuditAccessSvc', [
        'odataSvc', '$http', 'teamMemberTypeNames', 'currentUser', 'oauth',
        function (odataSvc, $http, teamMemberTypeNames, currentUser, oauth) {
            return {
                getProgramAuditAccessByReviewTeamId,
                updateProgramAuditAccess,
                getCurrentUserAccess,
                sortHistory
            };

            function getProgramAuditAccessByReviewTeamId(reviewTeamId) {
                const oSvc = odataSvc.get();
                const apiPath = `/GetProgramAuditAccessByReviewTeamId(reviewTeamId=${reviewTeamId})?$expand=programDetailDto`;

                oSvc.getSource(apiPath)
                    .odata()
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise;
            }

            function updateProgramAuditAccess(programAuditAccessObjects) {
                programAuditAccessObjects = programAuditAccessObjects.map(p => {
                    delete p.$$hashkey;
                    delete p.programDetailDto;
                    return p;
                });

                return $http.post(`/webapi/odata/SaveProgramAuditAccess`, { Value: programAuditAccessObjects });
            }

            function getCurrentUserAccess(programId, programAuditAccess, reviewTeamMembers, programAuditDetails, programAuditIsLocked) {
                programAuditAccess = Array.isArray(programAuditAccess) ? programAuditAccess : programAuditAccess ? [programAuditAccess] : [];
                programAuditDetails = Array.isArray(programAuditDetails) ? [...programAuditDetails].sort(sortHistory) : programAuditDetails ? [programAuditDetails] : [];

                const currentUserAccess = {
                    "isAdmin": oauth.isAdmin(),
                    "isTeamChair": false,
                    "isPEV": false,
                    "isReadOnly": true,
                    "programAuditDetail": null
                };

                // Find current user's access type for given program audit
                const currentReviewTeamMemberships = reviewTeamMembers.filter(reviewTeamMember =>
                    reviewTeamMember.volunteerId === parseInt(currentUser.profile.volunteerId) &&
                    (!reviewTeamMember.endDate || new Date(reviewTeamMember.endDate) >= new Date())
                );

                programAuditAccess.forEach(programAuditAccess => {
                    if (programAuditAccess.programId === programId && currentReviewTeamMemberships.some(membership => membership.reviewTeamMemberId == programAuditAccess.reviewTeamMemberId)) {
                        currentUserAccess.isTeamChair = currentUserAccess.isTeamChair || programAuditAccess.accessType === 'TC';
                        currentUserAccess.isPEV = currentUserAccess.isPEV || programAuditAccess.accessType === 'PEV';
                    }
                });

                // Find most recent version of program audit detail that current user is assigned access to
                if (currentUserAccess.isTeamChair) {
                    // TC can always see most current version and can edit if it is assigned to them or they also have PEV access
                    currentUserAccess.programAuditDetail = programAuditDetails.length && programAuditDetails[0] || null;
                    currentUserAccess.isReadOnly = programAuditIsLocked ||
                        (currentUserAccess.programAuditDetail && !currentUserAccess.programAuditDetail.isCurrent) ||
                        (currentUserAccess.programAuditDetail && currentUserAccess.programAuditDetail.isReviewedByTeamChair) ||
                        (!currentUserAccess.isPEV && (!currentUserAccess.programAuditDetail || currentUserAccess.programAuditDetail.teamMemberTypeId !== teamMemberTypeNames.TEAMCHAIR));
                } else if (currentUserAccess.isPEV) {
                    // PEV can see most recent version reviewed by TC or assigned to them and can edit current version assigned to them or create first version
                    currentUserAccess.programAuditDetail = programAuditDetails.find(programAuditDetail =>
                        programAuditDetail.isReviewedByTeamChair || programAuditDetail.teamMemberTypeId === teamMemberTypeNames.PEV
                    ) || null;
                    currentUserAccess.isReadOnly = currentUserAccess.programAuditDetail ?
                        currentUserAccess.programAuditDetail.isReviewedByTeamChair || !currentUserAccess.programAuditDetail.isCurrent :
                        programAuditDetails.length > 0; 
                } else if (currentUserAccess.isAdmin) {
                    // Admin can see read-only view of most recent entry; must impersonate TC/PEV to edit
                    currentUserAccess.programAuditDetail = programAuditDetails.length && programAuditDetails[0] || null;
                    currentUserAccess.isReadOnly = true;
                } else {
                    // Others can see most recently submitted to TC, published, or reviewed version but cannot edit
                    currentUserAccess.programAuditDetail = programAuditDetails.find(programAuditDetail =>
                        programAuditDetail.isReviewedByTeamChair || programAuditDetail.publishedTimestamp || (programAuditDetail.submittedTimestamp && programAuditDetail.teamMemberTypeId === teamMemberTypeNames.PEV)
                    ) || null;
                }

                return currentUserAccess;
            }

            function sortHistory(a, b) {
                // Sort history with most recently created versions first (lastUpdatedTimestamp not precise enough to sort by).
                return a.insertedTimestamp < b.insertedTimestamp ? 1 : a.insertedTimestamp > b.insertedTimestamp ? -1 : 0;
            }

        }]);
})(angular.module('programAudit'));