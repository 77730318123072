(function (module) {
    'use strict';

    var travelWaiverPDFSvc = function ($filter, currentUser) {
        var factory = { };

        function loadConstants() {
            factory.letterHead = [
                logo,
                {
                    text: '\n\n\n {0} \n {1} \n\n\n\n'.format('415 North Charles Street Baltimore, MD 21201', '+1.410.347.7700 www.abet.org'),
                    style: ['smaller', 'camptonLight'],
                    alignment: 'center'
                }
            ];

            factory.APPM_SECTIONS = {
                PublicRelease: 'I.A.',
                ChangesDuringAccreditation: 'I.F.',
                InstitutionCatalogs: 'I.A.6.',
                Appeals: 'II.D.',
                DueProcess: 'I.E.9.-10'
            };
        }

        var styles = {
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            medium: {
                fontSize: 12
            },
            large: {
                fontSize: 16
            },
            larger: {
                fontSize: 18
            },
            campton: {
                font: 'campton'
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            italics: {
                italics: true
            },
            orange: {
                color: '#FF6C2C'
            }
        };

        var logo = {
            image: 'ABET_CMYK.png',
            width: 50,
            alignment: 'center'
        };

        function getDocDefinition(content, title) {
            return {
                info: {
                    title: title
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 40, 70, 70],
            };
        }

        factory.generateTravelWaiver = function (currentReview, agreement) {
            loadConstants();

            var content = [];
            var infoFields = [
                ['Traveler', currentUser.profile.firstName + ' ' + currentUser.profile.lastName],
                ['Visit Country', currentReview.countryName],
                ['Visit Institution', currentReview.organizationName]
            ];
            if (currentReview.visitStartDate !== null) {
                infoFields.push(
                    [
                        'Visit Start Date',
                        $filter('date')(currentReview.visitStartDate, 'MM/dd/yyyy', 'UTC')
                    ]
                );
            }
            if (currentReview.visitEndDate !== null) {
                infoFields.push(
                    [
                        'Visit End Date',
                        $filter('date')(currentReview.visitEndDate, 'MM/dd/yyyy', 'UTC')
                    ]);
            }
            
            var infoSection = infoFields.map(([label, data]) => (
                        {
                        text: [
                            { text: label + ': ', style: ['small', 'camptonMedium'] },
                            {
                                text: data,
                                style: ['small', 'camptonLight']
                            },
                        ]
                }
            ))
            var mainText = 'I have reviewed and understand {0} and have volunteered to travel to the destination listed above (“destination”) as a member of the ABET (Accreditation Board for Engineering and Technology, Inc.) visiting team or on other ABET business. I understand that ABET is not requiring me to travel, and I can decline to travel, without prejudice. \n\n ' +
                'I acknowledge that there are risks associated with travel to the destination, and that it is my responsibility to assess such risks and to decide whether I want to proceed with the travel. \n\n ' +
                'I will review travel advisories posted on the {1} website and any additional sources that I feel appropriate. \n\n ' +
                'I will review health information for travelers to countries in my destination’s region or continent, such as the {2}, as well as the {3}. \n\n ' +
                'I will not hold ABET liable for my exposure to and/or contracting of any communicable disease, including but not limited to COVID-19/SARS that occurred during my travel and participation in an ABET event.  I understand that data on any of the resources listed above could change daily and that it is my responsibility to have the most recent information available when making a decision to travel for ABET after evaluating the health and safety risks. \n\n ' +
                'I understand that it is my responsibility to inform my ABET team chair immediately if I am unable to travel. As a Team Chair, I will notify both ABET HQ (Senior Director of Accreditation Operations) and my Commission Vice Chair of Operations. I will also inform my ABET team chair immediately if during the trip I become ill or for any other reason I believe that I should not or cannot continue with the trip in full compliance with all applicable guidelines. \n\n ' +
                'I understand that ABET’s medical and security provider requires that travelers who are or will be 75 years of age or older when traveling outside the U.S. must complete an {4} and submit it directly to ABET’s security provider during the 90-day period before the scheduled travel. I understand that it is my responsibility to inform ABET immediately if I decline to meet this requirement. \n\n ' +
                'I understand that ABET provides business travel accident insurance coverage for its volunteers and staff traveling on ABET business, domestic and abroad. The coverage provided by this insurance includes, but is not limited to, indemnity against business travel hazards, illness or medical emergencies while on travel, accidental death and dismemberment, if there is an accident with bodily injury, medical evacuation and repatriation. In addition to the insurance protection, the coverage includes travel assistance program services that include emergency medical assistance, guarantee of medical payment to help with upfront medical charges in the event the insured party does not have access to sufficient funds, emergency travel, and pre- trip information. Information on business travel accident insurance program and the Travel Assistance Program card is available for on the Travel Insurance and Assistance page on MyABET. I understand that it is my responsibility to print and keep a copy of the Travel Assistance Program card before travel. \n\n ' +
                'I assume full responsibility for any loss or risk of loss, liability, damage, costs, or injury, including damage to property, bodily injury, disease, or death that I may sustain as a result of my traveling to, from, and while at this destination. \n\n ' +
                'On behalf of myself, my family, heirs, assigns, and personal representatives, I release, indemnify, and hold harmless ABET, its officers, directors, employees, member societies, and agents from any and all liability, claims, demands, actions, and causes of actions arising out of or relating to any loss liability, damage, costs, or injury, including damage to property, bodily injury, disease, and death, that I may sustain as a result of traveling to, from, and while at this destination. This indemnification shall in no way limit the ability to collect on claims made against the worldwide travel accident insurance policy cited above or any other insurance policy that may cover me. \n\n ' +
                'I understand and voluntarily accept this agreement.';
            var linkInserts = [
                { text: 'ABET’s Travel Policy', link: 'https://www.abet.org/travel-reimbursement/travel-policy-and-procedures-manual/' },
                { text: 'U.S. State Department', link: 'http://travel.state.gov/content/passports/en/alertswarnings.html' },
                { text: 'Centers for Disease Control and Prevention website', link: 'http://wwwnc.cdc.gov/travel/' },
                { text: 'World Health Organization’s information on travel to the destination', link: 'http://www.who.int/countries/en/' },
                { text: 'Extended Plan Application form', link: 'https://form.jotform.com/232263967537466' }
            ];
            var combinedTravelSection = '\nI understand that if I plan to combine non-ABET travel with ABET travel, I must keep ABET informed (and copy my ABET Team Chair) by sending a completed {0} to {1} in advance, and wait for approval, prior to booking travel. I will also include a copy of the form and approval in my expense report to ABET for the reimbursement.';
            var combinedTravelInserts = [
                { text: 'request form', link: 'https://www.abet.org/request-for-combining-non-abet-travel-with-abet-travel-3-20/' },
                { text: 'CombinedTravel@abet.org', link: 'mailto:CombinedTravel@abet.org' }
            ];
               
            function styleLinks(linkList) {
                return linkList.map(linkObj => {
                    linkObj.style = { color: '#FF6C2C', decoration: 'underline' };
                    return linkObj;
                });
            }
            function insertLinks(text, linkList) {
                var splitText = text.split(/[\{\}]/);
                linkList.forEach(
                    (linkObj, i) => {
                        splitText[i * 2 + 1] = linkObj;
                    }
                );
                return splitText;
            }

            linkInserts = styleLinks(linkInserts);


            var letter = [
                    {
                        text: 'Travel Waiver and Release Agreement\n\n',
                        style: ['larger', 'campton', 'orange'],
                        alignment: 'center',
                }
            ].concat(infoSection,
                [
                {
                    text: '\n\nIn connection with my trip to the above-referenced destination:\n\n',
                    style: ['small', 'camptonMedium']
                },
                {
                    text: insertLinks(mainText, linkInserts),
                    style: ['small', 'camptonLight']
                },
                {
                    text: insertLinks(combinedTravelSection, styleLinks(combinedTravelInserts)),
                    style: ['small', 'camptonMedium']
                },
                {
                    text: '\n\n\nAgreed to by {0} on {1}'.format(agreement.typedName, $filter('date')(agreement.answeredDateTime, 'MM/dd/yyyy', 'UTC')),
                    style: ['small', 'camptonLight', 'italics'],
                    alignment: 'center',
                }
            ]);
            letter = factory.letterHead.concat(letter);
            content.push(letter);

            return getDocDefinition(content, 'Travel Waiver');
        };


        return {
            generateTravelWaiver: factory.generateTravelWaiver
        };
    };

    module.factory('travelWaiverPDFSvc', travelWaiverPDFSvc);

})(angular.module('userReview'));
