(function (module) {

    var programAuditDetailTemplateSvc = function ($q, teamMemberTypeNames, criteriaTypes, statementFindingTypes, statementFindingTypeNames,
        statementCategories, shortcomingTypeCodes, commissionIds, helperSvc, readinessSvc, statementSvc) {

        const factory = {};

        factory.getCriteria = function (program) {
            let criteriaTypeIds;
            const bachelorsCriteriaTypeIds = [criteriaTypes.CRITERIA, criteriaTypes.APPM];
            const mastersCriteriaTypeIds = [criteriaTypes.MASTERSGENERALCRITERIA, criteriaTypes.APPM];
            const integratedMastersCriteriaTypeIds = [criteriaTypes.CRITERIA, criteriaTypes.MASTERSGENERALCRITERIA, criteriaTypes.APPM];

            if (program.degreeLevelCode === 'M')
                if (program.isMasterDegreeIntegrated)
                    criteriaTypeIds = integratedMastersCriteriaTypeIds;
                else
                    criteriaTypeIds = mastersCriteriaTypeIds;
            else
                criteriaTypeIds = bachelorsCriteriaTypeIds;

            return readinessSvc.getCriteria(criteriaTypeIds);
        };

        factory.getFindingTypeOptions = function () {
            return statementSvc.getStatementFindingTypes().then(findingTypes => {
                if (!findingTypes) return null;
                const systemGeneratedTypes = [statementFindingTypes.PROGRAMINTRODUCTION, statementFindingTypes.PROGRAMSUMMARY, statementFindingTypes.TERMINATIONPLAN];
                return findingTypes.filter(findingType =>
                    findingType.statementCategoryId === statementCategories.PROGRAM && !systemGeneratedTypes.includes(findingType.statementFindingTypeId)
                ).map(findingType =>
                    Object.assign(findingType, {
                        number: 0,
                        criteria: []
                    })
                );
            });
        };

        factory.validateFindingTypeOptions = function (findingTypeOptions, allowEmpty) {
            allowEmpty = !!allowEmpty;

            if (!findingTypeOptions)
                return allowEmpty;

            const selectedFindingTypeOptions = findingTypeOptions.filter(findingTypeOption =>
                findingTypeOption.value
            );

            return selectedFindingTypeOptions.length ?
                selectedFindingTypeOptions.every(findingTypeOption => 
                    findingTypeOption.number || findingTypeOption.criteria && findingTypeOption.criteria.length
                ) : 
                allowEmpty;
        }

        factory.getAllProgramAuditTemplateData = function (program) {
            return $q.all([
                factory.getCriteria(program),
                factory.getFindingTypeOptions()
            ]).then(([criteria, findingTypeOptions]) => {
                const data = {};
                data.criteria = criteria;
                data.findingTypeOptions = findingTypeOptions;

                return data;
            });

        };

        factory.isShortcoming = function (statementFindingTypeId) {
            const shortcomingFindingTypeIds = [statementFindingTypes.PROGRAMDEFICIENCY, statementFindingTypes.PROGRAMWEAKNESS, statementFindingTypes.PROGRAMCONCERN];

            return shortcomingFindingTypeIds.includes(statementFindingTypeId);
        };

        factory.createEmptyTemplate = function (programAuditId, programId) {
            return {
                "programAuditDetailId": 0,
                "programAuditId": programAuditId,
                "programId": programId,
                "isCurrent": true,
                "teamMemberTypeId": teamMemberTypeNames.PEV,
                "submittedTimestamp": null,
                "submittedByVolunteerId": null,
                "isReturnToEvaluator": false,
                "publishedTimestamp": null,
                "isReviewedByTeamChair": false,
                "programAuditJson": {
                    "auditSummary": {},
                    "auditDetails": []
                },
                "note": null,
                "programAuditDetailEvaluatorDtos": []
            };
        };

        factory.createTemplate = function (commissionId, programAuditId, programId, criteria, findingTypeOptions) {
            const template = factory.createEmptyTemplate(programAuditId, programId);

            template.programAuditJson.auditSummary = createAuditSummaryTemplate(criteria);
            template.programAuditJson.auditDetails = createAuditDetailsTemplate(findingTypeOptions);
            updateProgramAuditSummary(commissionId, template.programAuditJson);
            sortProgramAuditDetails(template.programAuditJson);

            return template;
        }; 

        factory.addFindings = function (commissionId, programAuditDetail, findingTypeOptions) {
            const newSections = createSections(findingTypeOptions);

            newSections.forEach(section => {
                const existingSection = programAuditDetail.programAuditJson.auditDetails.find(auditDetail =>
                    auditDetail.statementFindingTypeId === section.statementFindingTypeId
                );
                if (existingSection) {
                    existingSection.findings.push(...section.findings);
                } else {
                    programAuditDetail.programAuditJson.auditDetails.push(section);
                }
            });

            updateProgramAuditSummary(commissionId, programAuditDetail.programAuditJson);
            sortProgramAuditDetails(programAuditDetail.programAuditJson);
        }

        factory.changeFindingType = function (commissionId, programAuditDetail, finding, newFindingType) {
            // Add to new finding type
            const updatedFinding = angular.copy(finding);
            updatedFinding.key = helperSvc.getNewKey();
            updatedFinding.insertedTimestamp = new Date();
            updatedFinding.lastUpdatedTimestamp = new Date();
            const newFindingTypeIndex = programAuditDetail.programAuditJson.auditDetails.findIndex(findingType =>
                findingType.statementFindingTypeId === newFindingType.statementFindingTypeId
            );
            if (newFindingTypeIndex >= 0)
                programAuditDetail.programAuditJson.auditDetails[newFindingTypeIndex].findings.push(updatedFinding);
            else {
                const newSection = createFindingTypeTemplate(newFindingType, [updatedFinding])
                programAuditDetail.programAuditJson.auditDetails.push(newSection);
            }
            // Remove from original finding type
            let oldFindingTypeIndex = -1;
            let oldFindingIndex = -1;
            programAuditDetail.programAuditJson.auditDetails.some((oldFindingType, index) => {
                oldFindingIndex = oldFindingType.findings.findIndex(oldFinding => oldFinding.key === finding.key);
                if (oldFindingIndex >= 0) {
                    oldFindingTypeIndex = index;
                    return true;
                }
            });
            if (programAuditDetail.programAuditJson.auditDetails[oldFindingTypeIndex].findings.length === 1)
                programAuditDetail.programAuditJson.auditDetails.splice(oldFindingTypeIndex, 1);
            else
                programAuditDetail.programAuditJson.auditDetails[oldFindingTypeIndex].findings.splice(oldFindingIndex, 1)
            // Format and clean up
            updateProgramAuditSummary(commissionId, programAuditDetail.programAuditJson);
            sortProgramAuditDetails(programAuditDetail.programAuditJson);
        }

        factory.deleteFinding = function (commissionId, programAuditDetail, findingType, finding) {
            const currentFindingTypeIndex = programAuditDetail.programAuditJson.auditDetails.findIndex(currentFindingType =>
                currentFindingType.statementFindingTypeId === findingType.statementFindingTypeId &&
                currentFindingType.findings.some(currentFinding =>
                    currentFinding.key === finding.key
                )
            );
            const currentFindingType = programAuditDetail.programAuditJson.auditDetails[currentFindingTypeIndex];

            if (!currentFindingType || !currentFindingType.findings || !currentFindingType.findings.length)
                return;

            if (currentFindingType.findings.length === 1) {
                programAuditDetail.programAuditJson.auditDetails.splice(currentFindingTypeIndex, 1);
            } else {
                const currentFindingIndex = currentFindingType.findings.findIndex((currentFinding) =>
                    currentFinding.key === finding.key
                );
                currentFindingType.findings.splice(currentFindingIndex, 1);
            }

            updateProgramAuditSummary(commissionId, programAuditDetail.programAuditJson);
        }

        function createAuditSummaryTemplate(criteria) {
            return {
                "editable": true,
                "criteria": criteria.map(criterion => ({
                    "criteriaId": criterion.criteriaId,
                    "criteriaName": getCriteriaFullName(criterion),
                    "previousFindingTypes": [],
                    "currentFindingTypes": []
                }))
            }
        }

        function createAuditDetailsTemplate(findingTypeOptions) {
            const timestamp = new Date();

            const programIntroSection = createFindingTypeTemplate({
                "statementFindingTypeId": statementFindingTypes.PROGRAMINTRODUCTION,
                "typeName": statementFindingTypeNames[statementFindingTypes.PROGRAMINTRODUCTION],
                "orderNumber": 1
            },
                [createFindingTemplate(null, timestamp)]
            );

            const sections = createSections(findingTypeOptions, timestamp);

            return [programIntroSection, ...sections];
        }

        function createFindingTypeTemplate(findingType, findings) {
            return {
                "statementFindingTypeId": findingType.statementFindingTypeId,
                "statementFindingTypeName": findingType.typeName,
                "orderNumber": findingType.orderNumber,
                "findings": findings
            }
        }

        function createSections(findingTypeOptions, timestamp) {
            timestamp = timestamp || new Date();

            return findingTypeOptions.filter(findingType =>
                findingType.value && (findingType.number || findingType.criteria && findingType.criteria.length)
            ).map(findingType =>
                createFindingTypeTemplate(
                    findingType,
                    findingType.number ?
                        // 1...number findings
                        Array(findingType.number).fill(null).map((currentValue, index) =>
                            createFindingTemplate(null, new Date(timestamp.getTime() + index))
                        ) :
                        // finding per criteria
                        findingType.criteria.map(criterion =>
                            createFindingTemplate(criterion, timestamp)
                        )
                )
            );
        }

        function createFindingTemplate(criterion, timestamp) {
            timestamp = timestamp || new Date();
            const finding = {
                "criteria": {
                    "criteriaId": criterion && criterion.criteriaId || 0,
                    "criteriaName": criterion && getCriteriaFullName(criterion) || null,
                    "text": null,
                    "comments": []
                },
                "key": helperSvc.getNewKey(),
                "insertedTimestamp": timestamp.toISOString(),
                "lastUpdatedTimestamp": timestamp.toISOString()
            };

            if (!criterion) {
                // Create then delete if not neded so that JSON is more readable and consistent with statement detail JSON.
                delete finding.criteria.criteriaId;
                delete finding.criteria.criteriaName;
            }

            return finding;
        }

        function getCriteriaFullName(criterion) {
            // Current following pattern used for statement tool of "Criterion #. Full Description"
            return criterion.criteriaDescription ? criterion.criteriaName + '. ' + criterion.criteriaDescription : criterion.criteriaName
        }

        function updateProgramAuditSummary(commissionId, programAuditJson) {
            const criteriaFindings = programAuditJson.auditDetails.filter(findingType =>
                factory.isShortcoming(findingType.statementFindingTypeId)
            ).flatMap(findingType =>
                findingType.findings.map(finding =>
                    ({
                        "criteriaId": finding.criteria.criteriaId,
                        "statementFindingTypeId": findingType.statementFindingTypeId
                    }))
            ).reduce((accumulator, currentValue) => {
                if (accumulator[currentValue.criteriaId]) {
                    if (!accumulator[currentValue.criteriaId].includes(currentValue.statementFindingTypeId))
                        accumulator[currentValue.criteriaId].push(currentValue.statementFindingTypeId);
                } else
                    accumulator[currentValue.criteriaId] = [currentValue.statementFindingTypeId];

                return accumulator;
            }, {});

            programAuditJson.auditSummary.criteria.forEach(criterion => {
                criterion.currentFindingTypes = criteriaFindings[criterion.criteriaId] ?
                    criteriaFindings[criterion.criteriaId].sort()
                        .map(statementFindingTypeId => {
                            switch (statementFindingTypeId) {
                                case statementFindingTypes.PROGRAMDEFICIENCY:
                                    return shortcomingTypeCodes.DEFICIENCY;

                                case statementFindingTypes.PROGRAMWEAKNESS:
                                    return shortcomingTypeCodes.WEAKNESS;

                                case statementFindingTypes.PROGRAMCONCERN:
                                    return shortcomingTypeCodes.CONCERN;
                            }
                        }) :
                    [];

                // For CAC, list all shortcomings; for other commissions, only show the most severe in the summary.
                if (commissionId !== commissionIds.CAC && criterion.currentFindingTypes.length > 1) {
                    criterion.currentFindingTypes = [criterion.currentFindingTypes[0]]; // already sorted by severity, descending
                }
            });
        }

        function sortProgramAuditDetails(programAuditJson) {
            programAuditJson.auditDetails.sort(compareFindingTypes);
            let compareFindings = getFindingsComparer(programAuditJson);
            programAuditJson.auditDetails.forEach(section =>
                section.findings.sort(compareFindings)
            );
        }

        function compareFindingTypes(a, b) {           
            return a.orderNumber < b.orderNumber ?
                -1 :
                a.orderNumber > b.orderNumber ?
                    1 :
                    a.statementFindingTypeId < b.statementFindingTypeId ?
                        -1 :
                        a.statementFindingTypeId > b.statementFindingTypeId ?
                            1 :
                            0;        
        }

        function getFindingsComparer(programAuditJson) {
            return (a, b) => {
                let x = 0, y = 0;

                if (a.criteria.criteriaId && b.criteria.criteriaId) {
                    if (programAuditJson?.auditSummary?.criteria) {
                        x = programAuditJson.auditSummary.criteria.findIndex(criteria => criteria.criteriaId == a.criteria.criteriaId);
                        y = programAuditJson.auditSummary.criteria.findIndex(criteria => criteria.criteriaId == b.criteria.criteriaId);
                    } else {
                        x = a.criteria.criteriaId;
                        y = b.criteria.criteriaId;
                    }
                    return x < y ? - 1 : (x > y ? 1 : 0);
                } else if (a.criteria.insertedTimestamp && b.criteria.insertedTimestamp) {
                    x = a.criteria.insertedTimestamp;
                    y = b.criteria.insertedTimestamp;
                }

                return x < y ? - 1 : (x > y ? 1 : 0);
            }
        }

        return {
            getCriteria: factory.getCriteria,
            getFindingTypeOptions: factory.getFindingTypeOptions,
            validateFindingTypeOptions: factory.validateFindingTypeOptions,
            getAllProgramAuditTemplateData: factory.getAllProgramAuditTemplateData,
            isShortcoming: factory.isShortcoming,
            createEmptyTemplate : factory.createEmptyTemplate,
            createTemplate: factory.createTemplate,
            addFindings: factory.addFindings,
            changeFindingType: factory.changeFindingType,
            deleteFinding: factory.deleteFinding
        };
    };

    module.factory('programAuditDetailTemplateSvc', programAuditDetailTemplateSvc);

})(angular.module('programAudit'));
