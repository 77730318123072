(function (module) {

    var addFacilitatorCtrl = function ($scope, $uibModalInstance, alertSvc, trainingSvc, trainingTypeIds, societyOptions, searchOptions, currentFacilitator, oauth, codeSvc, helperSvc, contactRoleTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Add Facilitator';
        model.currentInformation = {};
        model.trainingSessions = trainingSvc.data.trainingSessions;
        model.isAdmin = oauth.isAdmin();
        model.currentFacilitator = currentFacilitator;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.saveFacilitator = function () {
            if (!model.currentFacilitator && alreadyFacilitatorForThisTraining())
                return alertSvc.addModalAlertWarning("Facilitator already assigned to this training");

            var facilitatorDto = {
                trainingSessionId: model.currentInformation.trainingSessionId,
                personId: model.currentInformation.facilitator.personId,
                facilitatorType: model.currentInformation.facilitatorType,
                commissionId: model.currentInformation.commissionId,
                trainingId: trainingSvc.data.trainingSessions[0].trainingId
            }


            if (model.currentFacilitator) {
                facilitatorDto.trainingFacilitatorId = model.currentFacilitator.trainingFacilitatorId;
                trainingSvc.updateTrainingFacilitator(facilitatorDto).then(function () {
                    trainingSvc.getTrainingFacilitators(searchOptions);
                    alertSvc.addModalAlertSuccess("Facilitator succesfully updated.");
                    $uibModalInstance.close();
                })
            }
            else {
                trainingSvc.addTrainingFacilitator(facilitatorDto).then(function () {
                    trainingSvc.getTrainingFacilitators(searchOptions);
                    alertSvc.addModalAlertSuccess("Facilitator succesfully added.");
                    $uibModalInstance.close();
                })
            }
        }
    
        function alreadyFacilitatorForThisTraining(){
            var filteredTrainings = helperSvc.getFilteredArray(trainingSvc.data.trainingFacilitatorList, 'trainingSessionId', model.currentInformation.trainingSessionId, true);

            return helperSvc.getFirstMatch(filteredTrainings, 'personId', model.currentInformation.facilitator.personId) !== null;
        }

        var activate = function () {
            if (model.currentFacilitator) {
                model.currentInformation.facilitatorType = model.currentFacilitator.facilitatorType;
                model.currentInformation.facilitator = model.currentFacilitator;
                model.currentInformation.trainingSessionId = model.currentFacilitator.trainingSessionId;
                model.currentInformation.commissionId = model.currentFacilitator.commissionId;
                model.currentInformation.trainingId = model.currentFacilitator.trainingId;
            }
            else {
                model.currentInformation.facilitatorType = null;
                model.currentInformation.facilitator = null;
                model.currentInformation.trainingSessionId = null;
                model.currentInformation.commissionId = null;
                model.currentInformation.traingId = trainingSvc.data.trainingSessions[0].trainingId;
            }

            codeSvc.getCommissions().then(function (data) {
                model.trainingCommissions = helperSvc.getResults(data);
            });

            model.dataIsReady = true;
        }();
    };

    module.controller('addFacilitatorCtrl', addFacilitatorCtrl);

}(angular.module('training')));