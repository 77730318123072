(function (module) {

    var addProgramAuditInstitutionalSummaryFindingsCtrl = function (programAuditInstitutionalSummary, $scope, $uibModalInstance, alertSvc, 
            programAuditInstitutionalSummarySvc) {

        var model = $scope;
        model.title = 'Add Strength';
        model.programAuditInstitutionalSummary = angular.copy(programAuditInstitutionalSummary);
        model.isDataReady = false;
        model.isSaving = false;
        model.numberOfStrength = 0;
        model.cancel = function () {
           $uibModalInstance.dismiss();
        };
        model.onChange = function () {
            model.numberOfStrength = this.numberOfStrength;
        };
        model.save = function () {

            model.isSaving = true;
 
            programAuditInstitutionalSummarySvc.addFindings(model.programAuditInstitutionalSummary, model.numberOfStrength);
            programAuditInstitutionalSummarySvc.updateProgramAuditInstitutionalSummary(model.programAuditInstitutionalSummary).then(onSuccess, onError);

            function onSuccess(programAuditDetail) {
                alertSvc.addAlertSuccess('Strength(s) added.');
                $uibModalInstance.close(model.programAuditInstitutionalSummary);
            }

            function onError(error) {
                console.log(error);
                model.isSaving = false;
                alertSvc.addModalAlertWarning('Add strengths failed.')
            }
        };

        activate();

        function activate() {
            model.isDataReady = true;
            //nothing to load
        };
    };

    module.controller('addProgramAuditInstitutionalSummaryFindingsCtrl', addProgramAuditInstitutionalSummaryFindingsCtrl);

}(angular.module('programAudit')));