(function (module) {

    var membershipSvc = function ($http, $q, amsConst, $odata, currentOrganization, organizationSvc, organizationTypes, helperSvc, odataSvc, odataServiceFactory, contactRoleTypes) {
        var entityApiPath = amsConst.webApiUrl + '/odata/OrganizationUser({0})'
        //var entityWebApiPath = amsConst.webApiUrl + '/api/OrganizationUser/'
        var api = '/OrganizationUser';
        var key = 'organizationUserId';
        var abetAdminApi = '/GetAbetAdmins';
        var abetCAOApi = '/GetAbetCAOs';

        var factory = {};
        factory.data = {
            userGroupsAndRoles: null,
            historicalUsers: null,
            excludedRoles: [],
            organizationId: 0
        };


         var onSuccess = function (response) {
             if (response.status == 200) {
                 return response.data;
            }
         };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getByUserIdWithoutDesignation = function (userId, getCurrentOnly, rolesIncluded, rolesExcluded, designationsIncluded) {
            var userPath = amsConst.webApiUrl + '/odata/GetOrganizationUserViewByUserId';

            var currentOnlyFilter = '';
            var roleFilter = '';
            var designationExpansion = '';
            if (getCurrentOnly)
                currentOnlyFilter = ' and isActive eq ' + getCurrentOnly;

            if (rolesIncluded && rolesIncluded.length > 0) {
                roleFilter += ' and (';
                roleFilter += rolesIncluded.map(function (roleId) { return 'roleId eq ' + roleId; }).join(' or ');
                roleFilter += ')';
            } else if (rolesExcluded && rolesExcluded.length > 0) {
                roleFilter += ' and ';
                roleFilter += rolesExcluded.map(function (roleId) { return 'roleId ne ' + roleId; }).join(' and ');
            }
            if (designationsIncluded) {
                designationExpansion = '&$expand=designationViewModels';
            }

            return $http.get(userPath.format(null) + '?$filter=userId eq ' + userId + currentOnlyFilter + roleFilter + designationExpansion).then(onSuccess, onFailure);
        };

        factory.getByUserId = function (userId, getCurrentOnly) {
            if (getCurrentOnly) {
                var userPath = amsConst.webApiUrl + '/odata/GetOrganizationUserViewWithDesignation(organizationId={0})';
            } else {
                var userPath = amsConst.webApiUrl + '/odata/GetHistoricalOrganizationUserViewWithDesignation(organizationId={0})';
            }
            return $http.get(userPath.format(currentOrganization.profile.organizationId) + '?$filter=userId eq ' + userId + '&$expand=designationViewModels').then(onSuccess, onFailure);
        };

        factory.getByOrganizationId = function (organizationId, getCurrentOnly, personId) {

            var currentOnlyFilter = '';
            var personFilter = '';
            var parmOrganizationId = organizationId;

            if ((currentOrganization) && (currentOrganization.profile.organizationId > 0))
                parmOrganizationId = currentOrganization.profile.organizationId;

            if (getCurrentOnly)
                currentOnlyFilter = ' and isActive eq ' + getCurrentOnly + ' ';

            if (personId)
                personFilter = ' and personId eq ' + personId + ' ';

            var organizationPath = amsConst.webApiUrl + '/odata/GetOrganizationUserViewWithDesignation(organizationId={0})';
            var promise = $http.get(organizationPath.format(parmOrganizationId) + '?$filter=organizationId eq ' + organizationId + currentOnlyFilter + personFilter + '&$expand=designationViewModels').then(onSuccess, onFailure);

            promise.then(function (data) {
                factory.data.organizationId = organizationId;
                filterRoles(data.value, 'userGroupsAndRoles');
            });

            return promise;
        };

        factory.getByOrganizationIdHistorical = function (organizationId, personId) {
            var personFilter = '';            

            if (personId)
                personFilter = ' and personId eq ' + personId + ' ';

            var organizationPath = amsConst.webApiUrl + '/odata/GetHistoricalOrganizationUserViewWithDesignation(organizationId={0})';

            promise = $http.get(organizationPath.format(currentOrganization.profile.organizationId) + '?$filter=organizationId eq ' + organizationId + personFilter + '&$expand=designationViewModels').then(onSuccess, onFailure);

            promise.then(function (data) {
                filterRoles(data.value, 'historicalUsers');
            });

            return promise;
        };


        factory.quickSearchOrganizationUsers = function (organizationId, searchText) {
            var nameFilter = "";

            if (searchText) {
                nameFilter = " and ((indexof(firstName, '" + searchText + "') gt -1) or (indexof(lastName, '" + searchText + "') gt -1) or (indexof(preferredName, '" + searchText + "') gt -1))";
            }         

            var organizationPath = amsConst.webApiUrl + '/odata/GetHistoricalOrganizationUserViewWithDesignation(organizationId={0})';
            return $http.get(organizationPath.format(organizationId) + '?$filter=organizationId eq ' + organizationId + nameFilter + '&$expand=designationViewModels').then(onSuccess, onFailure);
        }


        factory.getAbetAdmins = function () {
            var oSvc = odataSvc.get();

            oSvc.getSource(abetAdminApi, key).odata()
                .expand('personDto', 'personEmailDto')
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.getAbetCAOs = function (effectiveDate) {
            effectiveDate = effectiveDate && new Date(effectiveDate) || new Date();

            var predicates = [];

            var startDatePredicate = [];
            startDatePredicate.push(new $odata.Predicate('startDate', 'eq', null));
            startDatePredicate.push(new $odata.Predicate('startDate', '<=', effectiveDate));
            predicates.push($odata.Predicate.or(startDatePredicate));

            var endDatePredicate = [];
            endDatePredicate.push(new $odata.Predicate('endDate', 'eq', null));
            endDatePredicate.push(new $odata.Predicate('endDate', '>=', effectiveDate));
            predicates.push($odata.Predicate.or(endDatePredicate));

            var combinedPredicate = $odata.Predicate.and(predicates);

            var oSvc = odataSvc.get();

            oSvc.getSource(abetCAOApi, key).odata()
                .filter(combinedPredicate)
                .orderBy('startDate', 'desc')
                .expand('personDto', 'personEmailDto')
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.getInstitutionUserCommissionByPersonIds = function (personIds) {
            var institutionUserCommissionPath = amsConst.webApiUrl + '/odata/GetInstitutionUserCommissionView';

            var data = {
                "personIds": personIds
            }
            return $http.post(institutionUserCommissionPath, data);
        }

        factory.getOAuthOrganizationUsersByOrganizationIds = function (orgIds, optionalRoles) {
            var organizationPath = amsConst.webApiUrl + '/odata/GetOAuthOrganizationUsers';
            var roleFilter = "";
            var orgFilter = "";
            var designationFilter = "";

            if (optionalRoles && optionalRoles.length > 0) {
                var rolesText = ''
                for (var i = 0; i < optionalRoles.length; i++) {
                    if (i === 0)
                        rolesText = optionalRoles[i];
                    else {
                        rolesText += ' or roleId eq ' + optionalRoles[i];
                    }
                }

                roleFilter = ' and (roleId eq ' + rolesText + ')';
            }

            if (Array.isArray(orgIds)) {
                var orgIdText = ''
                for (var i = 0; i < orgIds.length; i++) {
                    if (i === 0)
                        orgIdText = orgIds[i];
                    else {
                        orgIdText += ' or organizationId eq ' + orgIds[i];
                    }
                }

                orgFilter = '(organizationId eq ' + orgIdText + ')';
            }



            designationFilter = '&$expand=oAuthOrganizationUserDesignations';//&$filter=oAuthOrganizationUserDesignations/all\(o: o/designationId eq ' + designationsText + '\)';


            return $http.get(organizationPath.format(null) + '?$filter=' + orgFilter + roleFilter + designationFilter).then(onSuccess, onFailure);
        }

        factory.getOAuthOrganizationUsersByOrganizationId = function (orgId, optionalRoles) {
            var organizationPath = amsConst.webApiUrl + '/odata/GetOAuthOrganizationUsers';
            var roleFilter = "";
            var designationFilter = "";

            if (optionalRoles && optionalRoles.length > 0) {
                var rolesText = ''
                for (var i = 0; i < optionalRoles.length; i++) {
                    if (i === 0)
                        rolesText = optionalRoles[i];
                    else {
                        rolesText += ' or roleId eq ' + optionalRoles[i];
                    }
                }

                var roleFilter = ' and (roleId eq ' + rolesText + ')';
            }         

            

            designationFilter = '&$expand=oAuthOrganizationUserDesignations';//&$filter=oAuthOrganizationUserDesignations/all\(o: o/designationId eq ' + designationsText + '\)';
            
            
            return $http.get(organizationPath.format(null) + '?$filter=organizationId eq ' + orgId + roleFilter + designationFilter).then(onSuccess, onFailure);
        }

         factory.getTrainingManagers = function (societyIds) {
            var roleIds = [contactRoleTypes.PEVRECRUITINGMANAGER];
            return factory.getSocietyContacts(societyIds, roleIds);
        }

        factory.getSocietyContacts = function (societyIds, roleIds) {
            var oSvc = odataSvc.get();
            var path = '/GetSocietyContacts';
            var predicates = [];
            // Create 'or' clause for organizations (societies)
            if (societyIds) {
                var orgPredicates = [];
                var orgPredicate = null;
                for (var i = 0; i < societyIds.length; i++) {
                    orgPredicates.push(new $odata.Predicate('organizationId', '=', parseInt(societyIds[i])));
                }
                if (orgPredicates.length === 1) {
                    orgPredicate = orgPredicates[0];
                } else if (orgPredicates.length > 1) {
                    orgPredicate = new $odata.Predicate.or(orgPredicates);
                }
                if (orgPredicate) {
                    predicates.push(orgPredicate);
                }
            }
            // Create 'or' clause for roles ids for contacts
            if (roleIds) {
                var rolePredicates = [];
                var rolePredicate = null;
                for (var i = 0; i < roleIds.length; i++) {
                    rolePredicates.push(new $odata.Predicate('roleId', '=', parseInt(roleIds[i])));
                }
                if (rolePredicates.length === 1) {
                    rolePredicate = rolePredicates[0];
                } else if (rolePredicates.length > 1) {
                    rolePredicate = new $odata.Predicate.or(rolePredicates);
                }
                if (rolePredicate) {
                    predicates.push(rolePredicate);
                }
            }
            // Combine clauses as necessary
            var predicate = null;
            if (predicates.length === 1) {
                predicate = predicates[0];
            } else if (predicates.length > 1) {
                predicate = new $odata.Predicate.and(predicates);
            }
            // Make web API call
            var odata = oSvc.getSource(path, key).odata();
            if (predicate) {
                odata.filter(predicate);
            }              
            odata.query()
                 .$promise
                 .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.insert = function (userRole) {
            var data = { "Value": userRole };

            var promise = $http.post(entityApiPath.format(null), userRole).then(onSuccess, onFailure);

            promise.then(function () {
                factory.getByOrganizationId(userRole.organizationId);
            });
            return promise;
        };

        factory.update = function (organizationUser) {
            var promise = $http.put(entityApiPath.format(organizationUser.organizationUserId), organizationUser).then(onSuccess, onFailure);

            promise.then(function () {
                factory.getByOrganizationId(organizationUser.organizationId);
            });
            return promise;
        };

        factory.replace = function(newOrgUser, replacedOrgUserId){
            var data = { "newOrganizationUser": newOrgUser, 'replacedOrganizationUserId': replacedOrgUserId };
            var path = amsConst.webApiUrl + '/odata/ReplaceOrganizationUser';

            var promise = $http.post(path, data).then(onSuccess, onFailure);

            promise.then(function () {
                factory.getByOrganizationId(newOrgUser.organizationId);
            });
            return promise;

        }

        factory.delete = function (id) {

            var promise = $http.delete(entityApiPath.format(id)).then(onSuccess, onFailure);

            promise.then(function () {
                factory.getByOrganizationId(factory.data.organizationId);
            });
            return promise;

        };

        //used to delete commissioners added with role only to becom a TC
        factory.deleteRoleOnly = function (orgId, personId, roleId) {
            var userPath = amsConst.webApiUrl + '/odata/GetOrganizationUserView(organizationId={0})';
            var roleFilter = ' and roleId eq ' + roleId;
            var isRoleOnly = ' and isRoleOnly eq ' + true;
            var date = helperSvc.getCurrentReviewCycleStartDate(true);
            var dateFilter = ' and startDate eq ' + date;

            //get the orgUserIdFirst
            return $http.get(userPath.format(orgId) + '?$filter=personId eq ' + personId + roleFilter + isRoleOnly + dateFilter).then(function (data) {
                var orgUser = helperSvc.getFirstArrayItem(data.data.value);

                return factory.delete(orgUser.organizationUserId).then(onSuccess, onFailure);
            });
        };

        factory.isRole = function (commissioner, teamMemberTypeId) {
            if (commissioner.assignedReviews) {
                return commissioner.assignedReviews.find(function (review) { return review.teamMemberTypeId === teamMemberTypeId; }) ? true : false;
            }
            return false;
        };

        function filterRoles(roles, collectionName) {
            var filteredRoles = helperSvc.getFilteredArray(roles, "roleId", factory.data.excludedRoles);
            factory.data[collectionName] = filteredRoles;
        }

        return {
            getByUserId: factory.getByUserId,
            getByUserIdWithoutDesignation: factory.getByUserIdWithoutDesignation,
            getByOrganizationId: factory.getByOrganizationId,
            getByOrganizationIdHistorical: factory.getByOrganizationIdHistorical,
            getAbetAdmins: factory.getAbetAdmins,
            getAbetCAOs: factory.getAbetCAOs,
            deleteRoleOnly: factory.deleteRoleOnly,
            quickSearchOrganizationUsers: factory.quickSearchOrganizationUsers,
            getOAuthOrganizationUsersByOrganizationId: factory.getOAuthOrganizationUsersByOrganizationId,
            getOAuthOrganizationUsersByOrganizationIds: factory.getOAuthOrganizationUsersByOrganizationIds,
            getTrainingManagers: factory.getTrainingManagers,
            getSocietyContacts: factory.getSocietyContacts,
            insert: factory.insert,
            update: factory.update,
            replace: factory.replace,
            delete: factory.delete,
            isRole: factory.isRole,
            data: factory.data,
            getInstitutionUserCommissionByPersonIds: factory.getInstitutionUserCommissionByPersonIds
         };
    };

    module.factory('membershipSvc', membershipSvc);

})(angular.module('common'));