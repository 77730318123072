(function (module) {

    var personCompactMdl = function () {

        var personId = 0;
        var userId = 0;
        var personName = "";

        return {
            getPersonId: function () { return personId; },
            getUserId: function () { return userId; },
            getPersonName: function () { return personName; },

            setPersonId: function (value) { personId = value; },
            setUserId: function (value) { userId = value; },
            setPersonName: function (value) { personName = value; }
        };
    };
    module.factory('personCompactMdl', personCompactMdl);

})(angular.module('person'));