(function (module) {

    var referenceModalPreviewCtrl = function (reference, $scope, $uibModalInstance) {
        var model = $scope;

        model.title = 'Reference';
        model.subtitle = 'from ' + reference.personName;
        model.subtitle += reference.title ? ', ' + reference.title : '';
        model.reference = angular.copy(reference);

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('referenceModalPreviewCtrl', referenceModalPreviewCtrl);

}(angular.module('pevApp')));