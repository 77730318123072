(function (module) {

    var viewInstitutionStatementCtrl = function (streamId, $scope, $uibModalInstance,helperSvc ) {
        var model = $scope;

        model.title = 'View Action Statement';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        }

        var activate = function () {
            model.stream_id = streamId;
        }();
    };

    module.controller('viewInstitutionStatementCtrl', viewInstitutionStatementCtrl);

}(angular.module('npc')));