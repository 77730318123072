(function (module) {

    var reportDetailCtrl = function ($window, oauth, $http, $state, $stateParams, currentUser, reportSvc) {
        var model = this;
        var urlAmsRptAPI = "/webapi/odata/RequestSsoAmsRpt";
        var urlAmsRptServer = "https://amsrpt.abet.org/";
        var reportName = $stateParams.reportName;

        model.title = reportName;
        model.token = null;
        model.reportViewer = null;
        model.openInstructions = reportSvc.openInstructions;

        oauth.requestSso(urlAmsRptAPI).then(function (data) {
            if (data) {
                model.token = data.data;

                var viewer = GrapeCity.ActiveReports.Viewer(
                    {
                        element: '#reportContainer',
                        report: {
                            id: reportName,
                            parameters: [{
                                name: 'ParamPersonId',
                                value: currentUser.profile.personId
                            }]
                        },
                        reportService: {
                            url: urlAmsRptServer + '/api/',
                            securityToken: model.token,
                            resourceHandler : urlAmsRptServer + 'cache/' 
                        },
                        uiType: 'desktop',
                        documentLoaded: function reportLoaded() {
                                                    },
                        reportLoaded: function (reportInfo) {
                        },
                        error: function (error) {
                            console.log("error");
                        }
                    }
                );
            };
        });

        model.back = function () {
            $state.go('myReports');
        }
    };

    module.controller('reportDetailCtrl', reportDetailCtrl);

}(angular.module('report')));