(function (module) {
    var barCtrl = function (barSvc) {
        var model = this;
        model.showBar = false;

        barSvc.listenForMainShow( function () {
            model.showBar = true;
        });
        barSvc.listenForMainHide( function () {
            model.showBar = false;
        });
    };

    module.controller('barCtrl', barCtrl);

}(angular.module('common')));