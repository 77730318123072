    (function (module) {
        'use strict';

        var appraisalResultSvc = function (odataSvc, helperSvc) {
            var factory = {};
            var key = 'appraisalid';

            factory.data = {
                appraisal: []
            }

            factory.getTrainingAppraisalByPevc = function (pevc, noStoredData) {
                var oSvc = odataSvc.get();
                var apiPath = oSvc.getPathWithParameter('/GetTrainingAppraisalByPEVC', 'trainingPersonFacilitatorId', pevc.trainingPersonFacilitatorId)

                oSvc.getSource(apiPath).odata()
                    .query(oSvc.onSuccess, oSvc.onFailure);

                if (!noStoredData) {
                    resolveData(oSvc);
                }

                return oSvc.getDeferred().promise;
            };

            function resolveData(oSvc) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.appraisal = data;
                });
            }

            return {
                data: factory.data,
                getTrainingAppraisalByPevc: factory.getTrainingAppraisalByPevc
            };
        };

        module.factory('appraisalResultSvc', appraisalResultSvc);

    })(angular.module('evaluatorFollowUp'));