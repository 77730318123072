(function (module) {

    var languageProficiencySvc = function (odataServiceFactory) {
        var config = {
            apiPath: '/VolunteerLanguage',
            keyName: 'volunteerLanguageId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'languageInfo',
            getByForeignKeyPath: '/GetVolunteerLanguageByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        return {
            data: factory.data,
            getByVolunteerId: factory.getByForeignKey,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('languageProficiencySvc', languageProficiencySvc);

})(angular.module('volunteer'));