(function (module) {

    var impersonateReturnCtrl = function ($uibModal, currentUser, eventSvc) {
        var model = this;

        model.isImpersonate = !(currentUser.profile.impersonateUsername == null || currentUser.profile.impersonateUsername == '');

        model.returnToAdmin = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/security/templates/impersonate.html',
                size: 'md',
                controller: 'impersonateCtrl',
                controllerAs: 'ctrl',
                resolve: {
                    impersonateId: function () {
                        return null;
                    },
                    impersonateUsername: function () {
                        return currentUser.profile.impersonateUsername;
                    }
                }
            });
        };
        eventSvc.listen(function () {
            model.isImpersonate = !(currentUser.profile.impersonateUsername == null || currentUser.profile.impersonateUsername == '');
        }, 'refreshImpersonateFlag');
    };

    module.controller('impersonateReturnCtrl', impersonateReturnCtrl);

}(angular.module('security')));