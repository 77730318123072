(function () {
    const module = angular.module('common');

    module.component('dragDropUpload', {
        require: { ngModelCtrl: 'ngModel' },
        bindings: {
            title: '@?', // Default is 'Upload Files'.
            disableUpload: '<?', // If true, do not allow uploads.
            disableDelete: '<?', // If true, files may not be deleted once uploaded.
            mimeTypes: '@?', // ex. application/pdf or application/pdf,image/*
            ngModel: '=', // Two-way binding for file data. (Two-way b/c form validation.)
            required: '<?', // Whether or not an upload is required.
            minFiles: '<?', // Minimum number of files to upload.
            maxFiles: '<?', // Maximum number of files allowed.
            onDelete: '&?' // Callback function reference from parent component/controller.
        },
        templateUrl: '/Apps/common/templates/misc/dragDropUpload.html',
        controllerAs: 'model',
        controller: ['$scope', 'alertSvc', function ($scope, alertSvc) {
            const model = this;

            model.multipleFiles = !model.maxFiles || (model.maxFiles && +model.maxFiles > 1);

            model.$onInit = init;
            model.onFileSelect = onFileSelect;
            model.deleteFile = deleteFile;

            function init() {
                model.ngModelCtrl.$isEmpty = function () {
                    return !model.ngModelCtrl.$modelValue || model.ngModelCtrl.$modelValue.length === 0;
                };

                model.ngModelCtrl.$validators.minFilesValidator = function (modelValue, viewValue) {
                    const value = modelValue || viewValue;
                    return !model.minFiles || (Array.isArray(value) && value.filter(v => !v.remove).length >= +model.minFiles);
                }
                model.ngModelCtrl.$validators.maxFilesValidator = function (modelValue, viewValue) {
                    const value = modelValue || viewValue;
                    return !model.maxFiles || (Array.isArray(value) && value.filter(v => !v.remove).length <= +model.maxFiles);
                }
            }

            /**
             * Queues files for upload when parent controller handles form submission.
             * @param {File[]} files Files to upload
             */
            function onFileSelect(files) {
                files.forEach(file => {
                    if (model.ngModelCtrl.$modelValue)
                        model.ngModelCtrl.$modelValue.push(file);
                    else
                        model.$setViewValue = file;
                });
                model.ngModelCtrl.$validate();
                model.ngModelCtrl.$setDirty();
                model.ngModelCtrl.$setTouched();
            }

            /**
             * Confirms deletion of a file, and emits 'dragDropDeleteFile' event containing the file data.
             * 
             * @param {File} file File to delete
             */
            function deleteFile(file) {
                alertSvc.booleanConfirm(`Are you sure you want to delete file <strong>${file.filename || file.name}</strong>?`, 'Delete', undefined, 'Confirm File Delete')
                    .then(() => {
                        if (file) {
                            model.onDelete({ file });
                            model.ngModelCtrl.$validate();
                            model.ngModelCtrl.$setDirty();
                            model.ngModelCtrl.$setTouched();
                        }
                    });
                    // Clicking cancel button in confirm dialog doesn't change anything about the form state, 
                    // so just ignore it. No catch() block needed.
            }
        }]
    });

})();