(function () {

    var module = angular.module('common', [
        //dependencies
        'angular.filter',
        'angular-complexify',
        'ngCookies',
        'ngFileUpload',
        'ngCsv',
        'ngMessages',
        'ngRoute',
        'ngSanitize',
        'ODataResources',
        'security',
        'ui.bootstrap',
        'ui.unique',
        'ui.utils'
    ]);
    
  //  module.config(function ($tooltipProvider) {
  //      $tooltipProvider.options({
  //          appendToBody: true
  //      });
  //  });

    //requires ngAnimate but can be used to allow tagging of items to be animated instead of having global animation be turned on when including ngAnimate
    //module.config(function ($animateProvider) {
    //    $animateProvider.classNameFilter(/angular-animate/);
    //});

}());