(function (module) {

    var languageProficiencyEditCtrl = function (parent, $scope, $uibModalInstance, alertSvc, languageProficiencySvc, pevAppSvc, codeSvc, helperSvc, volunteerHelperSvc) {
        var model = $scope;
        var checkIsVolunteer = true;
        var isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);
        var originalLanguage = null;

        model.parent = parent;
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 
        model.title = 'Edit ' + model.parent.title;
        model.isInsertMode = false;
        model.isUpdateMode = false;
        model.errors = [];

        if (isApplication) {
            model.data = angular.copy(pevAppSvc.volunteerLanguageData);
        } else {
            model.data = languageProficiencySvc.data;
        }

        model.save = function () {
            helperSvc.validateForm(validateNewLanguage);

            if (model.errors.length > 0) return;

            if (isApplication) {
                if (model.isUpdateMode) {
                    model.data.languageInfo[getLanguageIndex(originalLanguage)] = model.newLanguage;
                } else {
                    model.data.languageInfo.push(model.newLanguage);
                }

                 var application = angular.copy(pevAppSvc.data.application);
                 application.applicationJson.volunteerLanguage = model.data.languageInfo;

                pevAppSvc.update(application).then(onSuccess, onFailure);

            } else if (model.isUpdateMode) {
                languageProficiencySvc.update(model.newLanguage).then(onSuccess, onFailure);
            } else {
                languageProficiencySvc.create(model.newLanguage).then(onSuccess, onFailure);
            }

            resetErrors();

            function onSuccess () {
                reset();
                alertSvc.addModalAlertSuccess("Language information successfuly saved.");
            }

            function onFailure () {
                alertSvc.addModalAlertWarning('Language information could not be saved at this time.');
            }
        };

        model.add = function () {
            resetNewLanguage();
            model.isCreateMode = true;
            model.isInsertMode = true;
        };

        model.edit = function (language) {
            resetNewLanguage();
            originalLanguage = angular.copy(language);

            if (isApplication) {
                model.newLanguage = {
                    languageId: String(language.languageId),
                    languageName: String(language.languageName),
                    readingLevelId: String(language.readingLevelId),
                    readingLevelName: String(language.readingLevelName),
                    speakingLevelId: String(language.speakingLevelId),
                    speakingLevelName: String(language.speakingLevelName),
                    volunteerLanguageId: language.volunteerLanguageId
                }
            } else {

                model.newLanguage = {
                    languageId: String(language.languageId),
                    readingLevelId: String(language.readingLevelId),
                    speakingLevelId: String(language.speakingLevelId),
                    volunteerLanguageId: language.volunteerLanguageId
                };
            }

            if (!isApplication) model.newLanguage.volunteerId = model.volunteerId;

            model.isInsertMode = true;
            model.isUpdateMode = true;
        };

        model.delete = function (language) {
            alertSvc.confirmDelete(language.languageName, deleteFunc, language);

            function deleteFunc() {
                if (isApplication) {
                    var index = getLanguageIndex(language);

                    if (index > -1) {                      
                        var application = angular.copy(pevAppSvc.data.application);
                        model.data.languageInfo.splice(index, 1);
                        application.applicationJson.volunteerLanguage = model.data.languageInfo;
                        pevAppSvc.update(application).then(onSuccess, onFailure);
                    } else {
                        alertSvc.addModalAlertWarning("The selected language could not be found.");
                    }
                } else {
                    languageProficiencySvc.delete(language).then(onSuccess, onFailure);
                }

                function onSuccess() {
                    if (model.isInsertMode) reset();
                    alertSvc.addModalAlertSuccess('Language information deleted.');
                }

                function onFailure (reason) {
                    if (reason === 'user cancelled') {
                        alertSvc.addModalAlertInfo('Delete cancelled.');
                    } else {
                        alertSvc.addModalAlertWarning('Language information could not be deleted at this time.');
                    }
                }
            }
        }

        model.cancel = function () {
            model.isInsertMode = false;
            model.isUpdateMode = false;
            resetErrors();
            resetNewLanguage();
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.setLanguageName = function () {
            var language = model.languages.find(function (language) {
                return language.codeKey == model.newLanguage.languageId;
            });
            if (isApplication) model.newLanguage.languageName = language.codeName;
        };

        model.setReadingLevelName = function () {
            var level = model.languageLevels.find(function (level) {
                return level.codeKey == model.newLanguage.readingLevelId;
            });
            if (isApplication) model.newLanguage.readingLevelName = level.codeName;
        };

        model.setSpeakingLevelName = function (name) {
            var level = model.languageLevels.find(function (level) {
                return level.codeKey == model.newLanguage.speakingLevelId;
            });
            if (isApplication) model.newLanguage.speakingLevelName = level.codeName;
        };

        var activate = function () {
            codeSvc.getLanguages().then(function (data) {
                model.languages = helperSvc.getValue(data);
            });

            codeSvc.getLanguageLevels().then(function (data) {
                model.languageLevels = helperSvc.getValue(data);
            });

            reset();
        }();

        function getLanguageIndex(currentLanguage) {
            return model.data.languageInfo.findIndex(function (language) {
                return language.languageId == currentLanguage.languageId;
            });
        }

        function validateNewLanguage() {
            resetErrors();
        
            if (!noDuplicates() && !model.isUpdateMode) {
                model.errors.push('That language has already been added.');
            }
        }

        function noDuplicates() {
            if (model.data.languageInfo) {
                for (var i = 0; i < model.data.languageInfo.length; i++) {
                    if (parseInt(model.data.languageInfo[i].languageId) === parseInt(model.newLanguage.languageId))
                        return false;
                }
            }

            return true;
        }

        function resetNewLanguage() {
            model.newLanguage = {
                languageId: null,
                volunteerId: model.volunteerId,
                readingLevelId: null,
                speakingLevelId: null
            }
        }

        function resetErrors() {
            model.errors = [];
        }

        function reset() {
            model.isInsertMode = false;
            model.isUpdateMode = false;
            resetErrors();
            resetNewLanguage();
        }

    };

    module.controller('languageProficiencyEditCtrl', languageProficiencyEditCtrl);

}(angular.module('volunteer')));