(function (module) {
    var pevAppDetailCtrl = function ($state, $stateParams, $scope, pevAppSvc, pevAppValidationSvc, personSvc, currentUser, tabSvc, barSvc,
                                     pevAppPDFSvc, alertSvc, eventSvc, documentSvc, pdfSvc, oauth) {

        var model = this;

        model.isPreviewMode = pevAppSvc.isPreviewMode();
        model.isAdmin = oauth.isAdmin();
        model.isAppManager = pevAppSvc.isAppManager();
        model.title = 'Pev Application';
        model.subtitle = pevAppSvc.data.application.submittedDate && !(model.isAdmin || model.isAppManager) ? ' Summary' : null;
        model.openMasterInstructions = function () { pevAppSvc.openMasterInstructions(model.isAdmin); }
        model.isDataReady = false;
        model.application = null;
        
        var path = 'apps/pevApp/templates/';
        var tabs = {
            intro: tabSvc.createTab('Introduction', 'intro', path + 'pevIntroModule.html'),
            manage: tabSvc.createTab('Manage Status', 'status', path + 'pevStatusModule.html'),
            personal: tabSvc.createTab('Personal Info', pevAppSvc.slugs.INFO, path + 'pevPersonalModule.html'),
            societies: tabSvc.createTab('Societies', pevAppSvc.slugs.SOCIETY, path + 'pevSocietyModule.html'),
            questionnaire: tabSvc.createTab('Questionnaire', pevAppSvc.slugs.QUESTIONNAIRE, path + 'pevQuestionnaireModule.html'),
            references: tabSvc.createTab('References', pevAppSvc.slugs.REFERENCES, path + 'pevRefModule.html'),
            submit: tabSvc.createTab((model.isAdmin ? 'CV / R\xE9sum\xE9' : 'Submit'), pevAppSvc.slugs.SUBMIT, path + 'pevSubmitModule.html'),
            summary: tabSvc.createTab('Summary', 'summary', path + 'pevAppSummary.html')
        };

        var standardTabs = [tabs.personal, tabs.societies, tabs.questionnaire, tabs.references, tabs.submit];
        
        if (model.isAdmin) {
            model.navigation = [tabs.manage, tabs.summary].concat(standardTabs);
        } else if (model.isAppManager) {
            model.navigation = [tabs.manage, tabs.summary];
        } else {
            model.navigation = [tabs.intro].concat(standardTabs);
        }

        var validationCallback = function () {
            pevAppValidationSvc.populateWarnings(model.navigation);
        };

        pevAppValidationSvc.listenToValidate(validationCallback, $scope);

        eventSvc.listen(validationCallback, 'loadPersonGeneral', $scope);

        model.openPevApp = function () {
            pevAppPDFSvc.openApplicationModal(model.application.volunteerApplicationId);
        };

        var activate = function () {
            var volunteerApplicationId = parseInt($stateParams.volunteerApplicationId) || 0;

            barSvc.showBar();

            if (volunteerApplicationId === 0) {
                // Start new application
                load();
            } else {
                pevAppSvc.getApplicationByVolunteerApplicationId(volunteerApplicationId).then(function (data) {
                    pevAppSvc.data.application = model.application = data;
                    model.isPreviewMode = pevAppSvc.isPreviewMode();

                    var volunteer = pevAppSvc.volunteerData.volunteer;
                    var noVolunteerInfoSaved = volunteer ? 
                                              !(volunteer.firstName || volunteer.lastName || volunteer.emailAddress || volunteer.phone) :
                                              true;
                    if (noVolunteerInfoSaved) {
                        // Load person data for Account Info widget
                        personSvc.getPersonMdlById(currentUser.profile.personId).then(function (data) {
                            var person = data.data;
                            var info = pevAppSvc.volunteerData.volunteer; 
                            var application = angular.copy(pevAppSvc.data.application);

                            info.firstName = person.firstName;
                            info.lastName = person.lastName;
                            info.emailAddress = person.personEmailDtos && person.personEmailDtos.length > 0 ? person.personEmailDtos[0].emailAddress : null;
                            info.phone = person.personTelephoneDtos && person.personTelephoneDtos.length > 0 ? person.personTelephoneDtos[0].number : null;

                            application.applicationJson.volunteer = info;
                            pevAppSvc.update(application).then(function () {
                                load();
                            });
                        });
                    } else {
                        load();
                    }
                });

            }

            function load() {
                validationCallback.apply(); 
                barSvc.hideBar();
                model.isDataReady = true;
            }
            
        }();
    };
    module.controller('pevAppDetailCtrl', pevAppDetailCtrl);

}(angular.module('pevApp')));