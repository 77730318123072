(function (module) {

    var oauth = function () {

        var url = "/webapi/tokenGrant";
        var urlAuth = "/webapi/odata/IsAuthenticated";
        var amsClientId = "amsWebappMain";
        
        this.setUrl = function (newUrl) {
            url = newUrl;
        };

        this.$get = function ($http, formEncode, currentUser, currentOrganization, helperSvc, eventSvc, offlineSvc, $q, tokenSvc, $location) {
            var loggingOutEventTimer = null;

            var unregisterLoggingOutEvent = function () {
                if (loggingOutEventTimer) {
                    clearTimeout(loggingOutEventTimer);
                }
            }

            var registerLoggingOutEvent = function () {
                unregisterLoggingOutEvent();

                if (!currentUser || !currentUser.profile || !currentUser.profile.loggedIn) return;

                var notice = 120000;
                var now = new Date();
                var delay = currentUser.profile.expires - now - notice;
                loggingOutEventTimer = setTimeout(function () {
                    eventSvc.broadcast('loggingOut');
                }, delay);
            }

            // Register timeout here for when user is returning after having previously logged in.
            registerLoggingOutEvent();

            var processToken = function (username) {

                return function (response) {
                    currentUser.set(response.data.access_token);
                    registerLoggingOutEvent();

                    return username;
                };
            };

            var login = function (username, password) {

                var configuration = {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                };

                var data = formEncode({
                    username: username,
                    password: password,
                    client_id: amsClientId,
                    grant_type: "password"
                });

                
                return $http.post(url, data, configuration).then(processToken(username));
            };

            var impersonate = function (username, password, impersonateUserId) {

                var configuration = {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                };

                var data = formEncode({
                    username: username,
                    password: password,
                    client_id: amsClientId,
                    grant_type: "password",
                    impersonateId: impersonateUserId
                });

                return $http.post(url, data, configuration).then(processToken(username));
            };

            var impersonateReturn = function (username, password) {
                logout();
                return login(username, password);
            };


            var logout = function () {

                console.log('loggingOut...');

                return removeToken().then(function () {
                    if (currentUser) {
                        currentUser.profile.personId = null;
                        currentUser.profile.username = "";
                        currentUser.profile.token = "";
                    }

                    if (currentOrganization) {
                        currentOrganization.profile.organizationId = 0;
                        currentOrganization.profile.organizationName = "";
                        currentOrganization.profile.organizationTypeId = 0;
                        currentOrganization.profile.organizationTypeName = "";
                        currentOrganization.remove();
                        currentOrganization = null;
                    }
                    unregisterLoggingOutEvent();
                });
            };

            var isAuthenticated = function () {
                if (currentUser.profile.token) {
                    var deferred = $q.defer();
                    deferred.resolve({ data: { value: true } } );
                    return deferred.promise;
                }

                if (!offlineSvc.isAppOffline()) {
                    return $http.get(urlAuth);
                } else {
                    var authenticated = currentUser.profile.loggedIn;
                    var deferred = $q.defer();
                    deferred.resolve({ data: { value: authenticated } });
                    return deferred.promise;
                }
            };

            var retrieveToken = function () {
                if (currentUser.profile.token) {
                    var deferred = $q.defer();
                    deferred.resolve(currentUser.profile.token);
                    return deferred.promise;
                }

                return $http.get("/webapi/odata/RetrieveToken").then(function (response) {
                        return response.data.access_token;
                    });
            }

            var removeToken = function () {
 
                return $http.post("/webapi/odata/RemoveToken").then(function (response) {
                    return response.data.access_token;
                });
            }

            var isPermitted = function (tasksStr) {
                if (tasksStr) {
                    var taskArr = currentUser.profile && currentUser.profile.userTasks || [];
                    if (typeof taskArr === 'string')
                        taskArr = taskArr.split(',');

                    for (var i = 0; i < taskArr.length; i++) {
                        if (taskArr[i] && tasksStr.indexOf(taskArr[i].toLowerCase()) > -1) {
                            return true;
                        }
                    }
                    return false;
                }
                return true;
            };

            var isReadOnlyPermitted = function (tasksStr) {
                if (tasksStr) {
                    var taskArr = currentUser.profile && currentUser.profile.userReadOnlyTasks || [];
                    if (typeof taskArr === 'string')
                        taskArr = taskArr.split(',');

                    for (i = 0; i < taskArr.length; i++) {
                        if (taskArr[i] && tasksStr.indexOf(taskArr[i].toLowerCase()) > -1) {
                            return true;
                        }
                    }
                    return false;
                }
                return true;
            };

            var isDesignated = function (organizationId, designationId) {

                if (currentUser.profile.userRoles) {
                    var orgRoles = getUserRolesByOrganizationId(organizationId);

                    for (var i = 0; i < orgRoles.length; i++) {
                        var oAuthDesignations = orgRoles[i].oAuthOrganizationUserDesignations || [];
                        for (var j = 0; j < oAuthDesignations.length; j++) {
                            if (oAuthDesignations[j].designationId === parseInt(designationId)) {
                                return true;
                            }
                        }
                    }
                }

                return false;
            };

            var isAdmin = function () {
                if (currentUser.profile.userRoles) {

                    for (var i = 0; i < currentUser.profile.userRoles.length; i++) {

                        if (currentUser.profile.userRoles[i].roleId > 0 && currentUser.profile.userRoles[i].roleId < 100)
                            return true;                       
                    }
                }
                return false;
            };

            var isAdjunct = function () {
                return isPermitted('adjunct');
            };

            var canImpersonate = function () {
                return isPermitted('impersonate');
            };

            var hasRole = function (organizationId, roleIds) {
                if (currentUser.profile.userRoles) {
                    var orgRoles = getUserRolesByOrganizationId(organizationId);
                    var rolesToCheck = [];
                    if (!Array.isArray(roleIds))
                        rolesToCheck.push(roleIds);
                    else
                        rolesToCheck = roleIds;
                    if (helperSvc.arrayContainsByPropertyValue(orgRoles, 'roleId', rolesToCheck))
                        return true;
                }

                return false;
            };

            var hasRoleRange = function (roleId, organizationId) {
                var roleIdStr = roleId.toString();
                var digits = roleIdStr.length - 1;
                var firstDigit = roleIdStr[0];
                var lowerLimit = addDigits(firstDigit, '0', digits);
                var upperLimit = addDigits(firstDigit, '9', digits);

                function addDigits(str, digit, count) {
                    var result = str;
                    count = Number(count);
                    for (var i = 0; i < count; i++) {
                        result += digit;
                    }
                    return Number(result);
                }

                if (currentUser.profile.userRoles) {
                    var orgRoles = (organizationId) ? getUserRolesByOrganizationId(organizationId) : currentUser.profile.userRoles;

                    for (var i = 0; i < orgRoles.length; i++) {
                        if (orgRoles[i].roleId <= upperLimit && orgRoles[i].roleId >= lowerLimit) return true;
                    }
                }

                return false;
            };

            var hasTask = function (task) {
                if (currentUser.profile.userTasks) {
                    var userTasks = currentUser.profile.userTasks;
                    if (typeof userTasks === 'string')
                        userTasks = userTasks.split(',');

                    for (var i = 0; i < userTasks.length; i++) {
                        var currentTask = userTasks[i];

                        if (currentTask === task) {
                            return true;
                        }
                    }
                }

                return false;
            }

            var requestSso = function (url, parm) {
                return $http.post(url, parm).then(function (response) {
                    return response;
                });
            };

            function getUserInfo() {
                return currentUser.profile;
            }

            function getUserRolesByOrganizationId(organizationId) {
                if (!currentUser.profile.userRoles) return false;

                return $.grep(currentUser.profile.userRoles, function (role) {
                    return role.organizationId === parseInt(organizationId);
                });
            }

            function getOrganizationsByUserRoleId(roleId) {
                if (!currentUser.profile.userRoles) return false;

                return $.grep(currentUser.profile.userRoles, function (role) {
                    return role.roleId === parseInt(roleId);
                });
            }

            function getDesignationIdsByRoleId(roleId) {
                var designationIds = [];
                var roles = currentUser.profile.userRoles
                    .filter(function (role) { return role.roleId === roleId; });
                roles.forEach(function (role) {
                    role.oAuthOrganizationUserDesignations.forEach(function (designation) {
                        if (designationIds.indexOf(designation.designationId === -1)) {
                            designationIds.push(designation.designationId);
                        }
                    });
                });
                return designationIds;
            }

            return {
                login: login,
                logout: logout,
                isAuthenticated: isAuthenticated,
                isPermitted: isPermitted,
                isReadOnlyPermitted: isReadOnlyPermitted,
                isDesignated: isDesignated,
                isAdmin: isAdmin,
                isAdjunct: isAdjunct,
                canImpersonate: canImpersonate,
                hasRole: hasRole,
                hasRoleRange: hasRoleRange,
                hasTask: hasTask,
                impersonate: impersonate,
                impersonateReturn: impersonateReturn,
                requestSso: requestSso,
                getUserInfo: getUserInfo,
                getUserRolesByOrganizationId: getUserRolesByOrganizationId,
                getOrganizationsByUserRoleId: getOrganizationsByUserRoleId,
                getDesignationIdsByRoleId: getDesignationIdsByRoleId,
                retrieveToken: retrieveToken
            };
        };
    };

    module.config(function ($provide) {
        $provide.provider("oauth", oauth);
    });

}(angular.module("security")));