(function (module) {

    var educationInfoCtrl = function ($scope, $uibModal, educationInfoSvc, volunteerHelperSvc, pevAppSvc, componentHelperSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Education Information';
        var checkIsVolunteer = true;
        model.applicationMode = pevAppSvc.isApplicationMode(checkIsVolunteer);
        model.isButtonHidden = model.applicationMode ? pevAppSvc.isEditButtonHiddenForGeneralModules() : pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;

        

        if (model.applicationMode ) {
            model.data = pevAppSvc.volunteerEducationData;
        } else {
            model.data = educationInfoSvc.data;
        }

        componentHelperSvc.invokeOnRendered('educationInfo', $scope);

        model.editEducationInfo = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/educationInfoEdit.html',
                size: 'lg',
                controller: 'educationInfoEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('educationInfoCtrl', educationInfoCtrl);

}(angular.module('volunteer')));