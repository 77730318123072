(function (module) {
    var sidebarCtrl = function ($scope, $cookies, $state) {
        var model = this;
        var isNavCollapsed = true;
        var isSidebarCollapsed = false;

        // Get elements from DOM
        var sidebar = angular.element(document.querySelector('#main-sidebar'));
        var sbExpandIcon = angular.element(document.querySelector('#sb-expand-arrows'));
        var sbCollapseIcon = angular.element(document.querySelector('#sb-collapse-arrows'));

        var nav = angular.element(document.querySelector('#main-nav'));
        var navExpandIcon = angular.element(document.querySelector('#nav-expand'));
        var navCollapseIcon = angular.element(document.querySelector('#nav-collapse'));

        model.toggleSidebar = function () {
            (isSidebarCollapsed) ? sidebar.removeClass('sb-compressed') : sidebar.addClass('sb-compressed');
            (isSidebarCollapsed) ? sbCollapseIcon.removeClass('hide') : sbCollapseIcon.addClass('hide');
            (isSidebarCollapsed) ? sbExpandIcon.addClass('hide') : sbExpandIcon.removeClass('hide');

            isSidebarCollapsed = !isSidebarCollapsed;
            $cookies.putObject('sidebarState', isSidebarCollapsed);
        };

        model.toggleNav = function () {
            (isNavCollapsed) ? nav.addClass('nav-expand') : nav.removeClass('nav-expand');
            (isNavCollapsed) ? navExpandIcon.addClass('hide') : navExpandIcon.removeClass('hide');
            (isNavCollapsed) ? navCollapseIcon.removeClass('hide') : navCollapseIcon.addClass('hide');

            isNavCollapsed = !isNavCollapsed;
        };

        model.getId = function (item) {
            return item.title.replace(' ', '').toLowerCase() + '-submenu';
        };

        model.openSubmenu = function (item) {
            if (item && item.submenu) {
                var elem = document.getElementById(model.getId(item));
                var sb = document.getElementById('main-sidebar');

                elem.classList.add('open');
                sb.style.overflow = '';
            }
        };

        model.closeSubmenu = function (item) {
            if (item && item.submenu) {
                var elem = document.getElementById(model.getId(item));
                var sb = document.getElementById('main-sidebar');

                elem.classList.remove('open');
                sb.style.overflow = 'hidden';
            }
        };

        model.openSidebarLink = function (item) {
            if (item.stateName) {
                var stateParams = item.stateParams || null;
                $state.go(item.stateName, stateParams, { reload: true });
                collapseNav();
            } else if (item.url) {
                window.location.href = item.url;
            }
        };

        function collapseNav () {
            nav.removeClass('nav-expand');
            navExpandIcon.removeClass('hide');
            navCollapseIcon.addClass('hide');
        }

        var activate = function () {
            $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

                var leavingDashboard = (fromState.name === 'admin' && toState.name !== 'admin') || (fromState.name === 'user' && toState.name !== 'user');

                // Expand sidebar if on the admin or user dashboard
                if (isSidebarCollapsed && (toState.name === 'admin' || toState.name === 'user')) {
                    model.toggleSidebar();

                // Collapse sidebar when leaving the admin or user dashboard (if expanded)
                } else if (!isSidebarCollapsed && leavingDashboard) {
                    model.toggleSidebar();
                }
            });
        }();
    };

    module.controller('sidebarCtrl', sidebarCtrl);

}(angular.module('root')));