(function (module) {

    var searchFromOtherSocietiesCtrl = function ($scope, $uibModalInstance, $stateParams, assignmentSvc, pevAssignmentSvc, $filter, codeSvc, helperSvc) {

        var model = $scope;

        model.title = 'Search PEVs from Other Societies';

        model.searchOptions = {
            selectedSocieties: [], selectedReview: null, selectedProgramAreas: []
        }
        model.isDataReady = false;
        
        model.close = function () {
            $uibModalInstance.close();
        };

        model.searchVolunteers = function () {
            var societyIds = model.searchOptions.selectedSocieties.map(function (society) {
                return society.codeKey;
            }).toString();
            var disciplineIds = model.searchOptions.selectedProgramAreas.map(function (discipline) {
                return discipline.disciplineId;
            }).toString();

            return pevAssignmentSvc.searchFromOtherSocieties(model.searchOptions.selectedReview.reviewTeamId, societyIds, disciplineIds).then(function (data) { //going to need to create a new db function that takes in an array of disciplines and societies. and the program?
                model.data.results = data.data.value;
            });
            
        };

        model.getName = function (review) {
            return review.organizationName + ' - ' + review.programDetail[0].disciplineName + ' (' + review.programDetail[0].degreeCode + ')';
        }

        model.allFieldsNotFilled = function(){
            return model.searchOptions.selectedSocieties.length <= 0 || model.searchOptions.selectedReview === null || model.searchOptions.selectedReview === undefined || model.searchOptions.selectedProgramAreas.length <= 0;
        }

        activate();

        function activate() {
            model.data = pevAssignmentSvc.data;
            model.data.results = [];
            model.reviewOptions = model.data.reviewPrograms;
            model.programAreaOptions = model.data.programAreaOptions;

            var dataArray = [];
            var societyOptionsDataArray = {
                dataHolder: model,
                svcCallback: [codeSvc.getMemberSocieties],
                dataLocationName: 'data.societyOptions',
                helperCallback: helperSvc.getValue
            }
            dataArray.push(societyOptionsDataArray);

            helperSvc.getData(dataArray).then(function () {
                model.isDataReady = true;
            });
        }

    };

    module.controller('searchFromOtherSocietiesCtrl', searchFromOtherSocietiesCtrl);

}(angular.module('assignment')));