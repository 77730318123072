(function (module) {
    var trainingCtrl = function ($uibModal, contactRoleTypes, $state, pevAppSvc, trainingStatusIds, reviewTeamMemberSvc, alertSvc, helperSvc, codeSvc, oauth, trainingSvc, $stateParams, volunteerHelperSvc, commissionAbbreviations, pevApplicationSocietyStatuses) {

        var model = this;
        var selectedId = null;

        model.isAdmin = oauth.isAdmin();
        model.isDataReady = false;
        model.commissionNames = commissionAbbreviations;
        model.searchOptions = { year: helperSvc.getAcademicYear(), typeId: null }
        model.trainingStatusIds = trainingStatusIds;
        model.showSpinnerExport = false;
        model.societyStatuses = pevApplicationSocietyStatuses;
        model.years = [];
        model.openInstructions = trainingSvc.openInstructions;

        model.addNewTraining = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/addTraining.html',
                size: 'md',
                controller: 'addTrainingCtrl',
                resolve: {
                    searchOptions: function () {
                        return model.searchOptions;
                    },
                    selectedTraining: null
                }
            });
        };

        model.editTraining = function (trainingDto) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/addTraining.html',
                size: 'md',
                controller: 'addTrainingCtrl',
                resolve: {
                    searchOptions: function () {
                        return model.searchOptions;
                    },
                    selectedTraining: trainingDto
                }
            });
        };


        model.deleteTraining = function (training) {
            alertSvc.confirmDelete(training.trainingName, deleteTrainingRecord);

            function deleteTrainingRecord() {
                trainingSvc.deleteTraining(training).then(function () {
                    alertSvc.addAlertSuccess("Training succesfully deleted.");
                    model.fetchTrainings();
                });
            }
        };

        model.editSession = function (training) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/editSession.html',
                size: 'lg',
                controller: 'editSessionCtrl',
                resolve: {
                    training: training
                }
            });
        };

        model.fetchTrainings = function () {
            trainingSvc.getTrainings(model.searchOptions).then(function (data) {
            });
        };

        model.assignMentor = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/assignMentor.html',
                size: 'md',
                controller: 'assignMentorCtrl',
                resolve: {
                    pevc: function () {
                        return pevc;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.changeSession = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/changeSession.html',
                size: 'md',
                controller: 'changeSessionCtrl',
                resolve: {
                    pevc: function () {
                        return pevc;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.openEvaluation = function (pevc) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/evaluation.html',
                size: 'lg',
                controller: 'evaluationCtrl',
                resolve: {
                    pevc: function () {
                        return pevc;
                    }
                }
            });
        };

        model.openPEVCTraining = function (training) {
            $state.go('training.pevc', { training: training });
        };

        model.openOtherTraining = function (training) {
            $state.go('training.other', { training: training});
        };
   
        function getYearSelection() {
            var startYear = 2018;
            var currentYear = new Date().getFullYear();
            var years = [];

            for (var i = 0; i <= (currentYear - startYear + 1); i++) {
                years.push(startYear + i);
            }
            return years;
        };

        var activate = function () {
            model.years = getYearSelection();

            codeSvc.getTrainingTypes().then(function (data) {
                model.trainingTypes = helperSvc.getResults(data);
                model.fetchTrainings();
                model.isDataReady = true;
                model.data = trainingSvc.data;
            });
        }();
    };
    module.controller('trainingCtrl', trainingCtrl);

}(angular.module('training')));