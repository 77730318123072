(function (module) {

    var rfrTranscriptEditCtrl = function (rfr, $scope, $uibModalInstance, codeSvc, helperSvc, rfrSvc, alertSvc) {
        var model = $scope;
        model.title = 'Edit Transcript Status';
        model.rfr = angular.copy(rfr);

        model.cancel = function () {
            $uibModalInstance.close();
        }

        model.save = function () {
            rfrSvc.update(model.rfr).then(function () {
                alertSvc.addAlertSuccess("Transcript status successfully updated.");
                $uibModalInstance.close();
            });
        };

        var activate = function () {
            codeSvc.getRfrTranscriptStatuses().then(function (data) {
                model.rfrTranscriptStatusList = helperSvc.getResults(data);
            });
        }();
    };

    module.controller('rfrTranscriptEditCtrl', rfrTranscriptEditCtrl);

}(angular.module('rfr')));