(function (module) {

    var templateRoot = "/Apps/volunteer/templates/";

    module.directive('currentActivities', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'currentActivities.html'

        };
    });

    module.directive('upcomingVisitAvailability', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'upcomingAvailability.html',
            scope: {
                isComplete: '=?'
            }
        };
    });

    module.directive('travelAvailability', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'travelAvailability.html',
            scope: {
                isComplete: '=?'
            }
        };
    });

    module.directive('programAreas', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programAreas.html'

        };
    });

    module.directive('societyMembershipInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'societyMembership.html'
        };
    });

    module.directive('trainingInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'trainingInfo.html'

        };
    });

    module.directive('educationInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'educationInfo.html'

        };
    });

    module.directive('employmentInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'employmentInfo.html'

        };
    });

    module.directive('otherConflicts', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'otherConflicts.html'

        };
    });

    module.directive('professionalLicense', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'professionalLicense.html'

        };
    });

    module.directive('languageProficiency', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'languageProficiency.html'

        };
    });

    module.directive('collegeName', function () {
        return {
            restrict: 'E',
            scope: {
                currentInformation: "="
            },
            template: "{{currentInformation.collegeDirectoryDto.collegeName || currentInformation.collegeVerificationDto.name || 'Not entered'}}"
        };
    });

    module.directive('demographicInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'demographicInfo.html'

        };
    });

    module.directive('specialization', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'specialization.html'

        };
    });

    module.directive('onlineEducationExperience', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'onlineEducation.html'

        };
    });

    module.directive('volunteerPage', function ($stateParams, $uibModal, personSvc, alertSvc, noteSvc, eventSvc, barSvc, helperSvc, noteTypes, volunteerActivitiesSvc, societyMembershipSvc, codeSvc,
        programAreaSvc, otherConflictSvc, educationInfoSvc, employmentInfoSvc, volunteerSvc, volunteerHelperSvc, professionalLicenseSvc, upcomingAvailabilitySvc, travelAvailabilitySvc,
                                                trainingSvc, trainingInfoSvc, languageProficiencySvc, volunteerTermsSvc, volunteerHealthSafetySvc, oauth, currentUser, taskTypes, volunteerDataAccessTypes, contactRoleTypes,
                                                commissionTypes, citizenshipSvc, onlineEducationSvc, organizationSvc, pevAppSvc, personUserSvc, componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'volunteerDetails.html',
            scope: {
                volunteer: '=?',
                shortBio: '@?',
                fullBio: '@?',
                preview: '@?',
                appPersonal: '@?applicationPersonal',
                appSociety: '@?applicationSociety',
                isVolunteer: '=?'
            },
            link: function (scope, ctrl, attrs) {
                scope.options = {
                    shortBio: scope.shortBio ? (scope.shortBio.toLowerCase() == "true") : false,
                    fullBio: scope.fullBio ? (scope.fullBio.toLowerCase() == "true") : false,
                    appPersonal: scope.appPersonal ? (scope.appPersonal.toLowerCase() == "true") : false,
                    appSociety: scope.appSociety ? (scope.appSociety.toLowerCase() == "true") : false,
                    appPersonalVolunteer: scope.isVolunteer && scope.appPersonal,
                    isApplication: scope.appPersonal || scope.appSociety,
                    preview: null
                };

                scope.print = function () {
                    window.print();
                };

                // Default to full bio
                if (!(scope.shortBio || scope.appPersonal || scope.appSociety)) scope.options.fullBio = true;

                // Default to preview for short bio
                if (scope.options.shortBio) {
                    scope.options.preview = true;
                } else {
                    scope.options.preview = scope.preview ? (scope.preview.toLowerCase() == "true") : false;
                }

                // Preview option assumes content is displayed in a modal
                // Show in-page spinner for modal and full page spinner otherwise
                if (scope.options.preview || scope.options.isApplication) {
                    scope.showSpinner = true;
                } else {
                    scope.showSpinner = false;
                    barSvc.showBar();
                }


                scope.name = '';
                scope.noteTitle = 'Notes for ABET and Society';
                scope.isDataReady = false;
                personSvc.data.person = {};
                scope.noteTypes = noteTypes;
                scope.formatDate = helperSvc.formatDate;
                scope.personId = scope.options.isApplication ? pevAppSvc.data.application.personId : getId('personId');
                scope.volunteerId = scope.options.isApplication ? pevAppSvc.data.application.applicationJson.volunteer.volunteerId : getId('volunteerId');

                personUserSvc.getUserByPersonId(scope.personId).then(function (data) {
                    scope.user = data;
                });

                function getId(propName) {
                    return scope.volunteer ? scope.volunteer[propName] : ($stateParams[propName] ? parseInt($stateParams[propName]) : currentUser.profile[propName]);
                }

                // Determine access values for current user
                volunteerSvc.data.isAdmin = oauth.isAdmin();
                volunteerSvc.data.isSocietyManager = (oauth.hasTask(taskTypes.MANAGEVOLUNTEER)) ? true : false
                volunteerSvc.data.isVolunteer = (scope.volunteerId == currentUser.profile.volunteerId) ? true : false;
                volunteerSvc.data.isSocietyLiaison = (oauth.getOrganizationsByUserRoleId(contactRoleTypes.EDUCATIONLIASON).length > 0) ? true : false;
                volunteerSvc.data.isTrainingManager = (oauth.getOrganizationsByUserRoleId(contactRoleTypes.TRAININGMANAGER).length > 0) ? true : false;

                // Access values used within view
                scope.isAdmin = volunteerSvc.data.isAdmin;
                scope.isSocietyManager = volunteerSvc.data.isSocietyManager;
                scope.showFirstPersonMessage = volunteerSvc.data.isVolunteer;
                scope.isVolunteer = scope.isVolunteer || volunteerSvc.data.isVolunteer;
                scope.allowComplyEdit = volunteerHelperSvc.allowEdit(volunteerDataAccessTypes.VOLUNTEERANDADMIN);
                scope.showNotes = !volunteerSvc.data.isVolunteer && volunteerHelperSvc.allowEdit(volunteerDataAccessTypes.SOCIETYANDADMIN); // Hide notes about the user, even if they are an admin

                // Volunteer Page Only (Non-Application)
                if (!scope.options.isApplication) {
                    scope.switchBioView = function () {
                        if (scope.options.shortBio) {
                            scope.options.shortBio = false;
                            scope.options.fullBio = true;
                        } else {
                            scope.options.shortBio = true;
                            scope.options.fullBio = false;
                        }
                    };

                    scope.getBtnTitle = function () {
                        return scope.options.shortBio ? "View Full Bio" : "View Short Bio";
                    };

                    scope.hasComplied = function () {
                        var agreedTerm = volunteerTermsSvc.getAgreedTerm();
                        var hasComplied = false;

                        if (agreedTerm) {
                            hasComplied = true;
                            scope.agreedTerm = helperSvc.formatDate(agreedTerm.answeredDateTime);
                        }

                        return hasComplied;
                    };

                    scope.hasAgreedHealthSafety = function () {
                        var agreedHealthSafety = volunteerHealthSafetySvc.getAgreedHealthSafety();
                        var agreed = false;

                        if (agreedHealthSafety) {
                            agreed = true;
                            scope.agreedHealthSafety = helperSvc.formatDate(agreedHealthSafety.answeredDateTime);
                        }

                        return agreed;
                    };

                    scope.hasEnteredTravelAvailability = function () {
                        var travelRecord = travelAvailabilitySvc.data.travelAvailability.volunteerAvailabilityTravelId;
                        var hasEntered = false;

                        if (travelRecord) {
                            // Check departure country in case it refers to a country that was removed when addresses were updated.
                            if (travelAvailabilitySvc.data.travelAvailability.volunteerTravelAvailability == 4 || travelAvailabilitySvc.data.travelAvailability.departureCountryCode) {
                                hasEntered = true;
                            } else {
                                hasEntered = false;
                            }
                        }

                        return hasEntered;
                    };

                    scope.hasEnteredDateAvailability = function () {
                        var dateRecord = upcomingAvailabilitySvc.data.dateData.volunteerAvailabilityDateId;
                        var hasEntered = false;

                        if (dateRecord) {
                            hasEntered = true;
                        }

                        return hasEntered;
                    };

                    scope.editVolunteerTerms = function (alert) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/volunteer/templates/conductEdit.html',
                            size: 'lg',
                            controller: 'conductEditCtrl',
                            resolve: {
                                alert: function () {
                                    return alert;
                                }
                            }
                        });
                    };

                    scope.editCovidPolicy = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/volunteer/templates/covidPolicyEdit.html',
                            size: 'lg',
                            controller: 'covidPolicyEditCtrl'
                        });
                    };

                    scope.optOut = function () {
                        if (!scope.data.volunteer.volunteerEndDate) {
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: '/Apps/volunteer/templates/optInOutEdit.html',
                                size: 'lg',
                                controller: 'optInOutEditCtrl'
                            });
                        } else {
                            var dataToSave = angular.copy(scope.data.volunteer);
                            dataToSave.volunteerEndDate = null;
                            dataToSave.volunteerEndReason = null;
                            volunteerSvc.update(dataToSave).then(function () {
                                alertSvc.addAlertSuccess("Opt in information successfully updated.");
                            },
                                function () {
                                    alertSvc.addAlertWarning('Opt in information could not be saved at this time.');
                                });
                        }
                    };
                } // End Volunteer Page Only

                scope.getLimit = function () {
                    return scope.options.fullBio || scope.options.isApplication ? null : 1;
                };

                scope.getLimitBegin = function (currentJobs) {
                    if (scope.options.fullBio || scope.options.isApplication) {
                        return 0;
                    } else {
                        // If user is retired exclude their "retirement" job from current employment
                        var isRetired = currentJobs.find(function (job) {
                            return job.employmentTypeName.toLowerCase().contains("retired");
                        });
                        return isRetired ? 1 : 0;
                    }
                };

                // Data used for Non-Application && Volunteer View (Application Personal)

                if (scope.options.shortBio || scope.options.fullBio || scope.options.appPersonalVolunteer) {
                    var citizenshipDataSource = {
                        dataHolder: citizenshipSvc,
                        dataLocationName: 'data.citizenships',
                        svcCallback: citizenshipSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId],
                        odataResource: true
                    };

                    var educationInfoDataSource = {
                        dataHolder: educationInfoSvc,
                        dataLocationName: 'data.educationInfo',
                        svcCallback: educationInfoSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var employmentInfoDataSource = {
                        dataHolder: employmentInfoSvc,
                        dataLocationName: 'data.employmentInfo',
                        svcCallback: employmentInfoSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var personObjDataSource = {
                        dataHolder: scope,
                        dataLocationName: 'personObj',
                        svcCallback: personSvc.getPersonMdlById,
                        svcCallbackArguments: [scope.personId, scope.person, excludeNullContactTypeIds = true],
                        optionalCallback: function (scope) {
                            //remove personObj from scope because it was only used as a placeholder
                            delete scope.personObj;
                        },
                        optionalCallbackArguments: [scope]
                    };

                    var professionalLicenseDataSource = {
                        dataHolder: professionalLicenseSvc,
                        dataLocationName: 'data.professionalLicenses',
                        svcCallback: professionalLicenseSvc.getVolunteerLicensesByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    // Needed in Short Bio for employmentInfoCtrl
                    var volunteerDataSource = {
                        dataHolder: volunteerSvc,
                        dataLocationName: 'data.volunteer',
                        svcCallback: volunteerSvc.get,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var onlineEducationDataSource = {
                        dataHolder: onlineEducationSvc,
                        dataLocationName: 'data.onlineEducation',
                        svcCallback: onlineEducationSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };
                }

                // Data used for Non-Application Only

                if (scope.options.shortBio || scope.options.fullBio) {
                    var volunteerActivitiesDataSource = {
                        dataHolder: volunteerActivitiesSvc,
                        dataLocationName: 'data.activities',
                        svcCallback: volunteerActivitiesSvc.getAllHistoricalByPersonId,
                        svcCallbackArguments: [scope.personId],
                        odataResource: true
                    };
                }

                // Data used for Full Bio && Volunteer View (Application Personal)

                if (scope.options.fullBio || scope.options.appPersonalVolunteer) {
                    var languageProficiencyDataSource = {
                        dataHolder: languageProficiencySvc,
                        dataLocationName: 'data.languageInfo',
                        svcCallback: languageProficiencySvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };
                }

                // Data used for Full Bio Only

                if (scope.options.fullBio) {
                    var countriesDataSource = {
                        dataHolder: upcomingAvailabilitySvc,
                        dataLocationName: 'countries',
                        svcCallback: upcomingAvailabilitySvc.getAllCountriesForOrganizations,
                        helperCallback: helperSvc.getResults
                    };

                    var otherConflictsDataSource = {
                        dataHolder: otherConflictSvc,
                        dataLocationName: 'data.otherConflicts',
                        svcCallback: otherConflictSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var programAreaSocietyDataSource = {
                        dataHolder: scope,
                        dataLocationName: 'programAreaSocietyOptions',
                        svcCallback: codeSvc.getProgramAreaSociety,
                        helperCallback: helperSvc.getResults
                    };

                    var societyMembershipDataSource = {
                        dataHolder: societyMembershipSvc,
                        dataLocationName: 'data.societyMemberships',
                        svcCallback: societyMembershipSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var trainingInfoDataSource = {
                        dataHolder: trainingInfoSvc,
                        dataLocationName: 'data.trainings',
                        svcCallback: trainingInfoSvc.getByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId],
                        odataResource: true
                    };

                    var pevcTrainingInfoDataSource = {
                        dataHolder: scope,
                        dataLocationName: 'pevcTrainings',
                        svcCallback: trainingSvc.getPevcTrainingsByPersonId,
                        svcCallbackArguments: [scope.personId, true],
                        odataResource: true
                    };

                    var unavailableCountriesDataSource = {
                        dataHolder: upcomingAvailabilitySvc,
                        dataLocationName: 'data.countryData',
                        svcCallback: upcomingAvailabilitySvc.getVolunteerAvailabilityCountryByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true,
                    };

                    var unavailableDatesDataSource = {
                        dataHolder: upcomingAvailabilitySvc,
                        dataLocationName: 'data.dateData',
                        svcCallback: upcomingAvailabilitySvc.getVolunteerAvailabilityDateByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var volunteerAvailabilityTravelDataSource = {
                        dataHolder: travelAvailabilitySvc,
                        dataLocationName: 'data.travelAvailability',
                        svcCallback: travelAvailabilitySvc.getVolunteerAvailabilityTravelByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var volunteerNotesDataSource = {
                        dataHolder: scope,
                        dataLocationName: 'notes',
                        svcCallback: noteSvc.getNotesByReferenceId,
                        svcCallbackArguments: [scope.volunteerId, noteTypes.VOLUNTEER],
                        odataResource: true
                    };

                    var volunteerProgramAreaDataSource = {
                        dataHolder: programAreaSvc,
                        dataLocationName: 'data.volunteerProgramAreas',
                        svcCallback: programAreaSvc.getProgramAreasByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var volunteerTermsDataSource = {
                        dataHolder: volunteerTermsSvc,
                        dataLocationName: 'data.volunteerTerms',
                        svcCallback: volunteerTermsSvc.getVolunteerTermsByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    var volunteerHealthSafetyDataSource = {
                        dataHolder: volunteerHealthSafetySvc,
                        dataLocationName: 'data.volunteerHealthSafety',
                        svcCallback: volunteerHealthSafetySvc.getVolunteerHealthSafetyByVolunteerId,
                        svcCallbackArguments: [scope.volunteerId, noStoredData = true],
                        odataResource: true
                    };

                    function refreshVolunteerNotes() {
                        var dataSourceArray = [volunteerNotesDataSource];
                        var promise = helperSvc.getData(dataSourceArray);

                        promise.then(function () {
                            eventSvc.broadcast('loadVolunteerNotes');
                        });
                    }

                    eventSvc.listen(refreshVolunteerNotes, 'refreshVolunteerNotes', scope);
                }

                // Option data

                if (scope.options.isApplication || scope.options.fullBio) {
                    var commissionOptionsDataSource = {
                        dataHolder: programAreaSvc,
                        dataLocationName: 'data.commissionOptions',
                        svcCallback: codeSvc.getCommissions,
                        helperCallback: helperSvc.getResults
                    };

                    var ethnicityOptionsDataSource = {
                        dataHolder: volunteerSvc,
                        dataLocationName: 'data.ethnicityTypeOptions',
                        svcCallback: codeSvc.getEthnicityTypes,
                        helperCallback: helperSvc.getResults
                    };

                    var licenseOptionsDataSource = {
                        dataHolder: professionalLicenseSvc,
                        dataLocationName: 'data.licenseOptions',
                        svcCallback: codeSvc.getLicenses,
                        helperCallback: helperSvc.getResults
                    };

                    var memberSocietyOptionsDataSource = {
                        dataHolder: societyMembershipSvc,
                        dataLocationName: 'data.memberSocietyOptions',
                        svcCallback: organizationSvc.getMemberAndSupportingSocieties,
                        helperCallback: helperSvc.getResults
                    };

                    var stateOptionDataSource = {
                        dataHolder: professionalLicenseSvc,
                        dataLocationName: 'data.stateOptions',
                        svcCallback: codeSvc.getStates,
                        helperCallback: helperSvc.getResults
                    };

                    var volunteerStatusOptionsDataSource = {
                        dataHolder: programAreaSvc,
                        dataLocationName: 'data.volunteerDisciplineStatusOptions',
                        svcCallback: codeSvc.getVolunteerDisciplineStatuses,
                        helperCallback: helperSvc.getResults
                    };
                }

                var activate = function () {
                    // Get person name for title
                    var firstPromise = personSvc.getPersonSummaryWithDesignation(scope.personId);

                    firstPromise.then(function (data) {
                        var person = data;
                        var dataSourceArray = [];

                        // Set person name for title
                        if (person.firstName) scope.name += person.firstName + ' ';
                        if (person.middleName) scope.name += person.middleName + ' ';
                        if (person.lastName) scope.name += person.lastName;

                        // Data used for Non-Application && Volunteer View (Application Personal)
                        if (scope.options.shortBio || scope.options.fullBio || scope.options.appPersonalVolunteer) {
                            dataSourceArray = dataSourceArray.concat([
                                citizenshipDataSource,
                                educationInfoDataSource,
                                employmentInfoDataSource,
                                personObjDataSource,
                                professionalLicenseDataSource,
                                volunteerDataSource,
                                onlineEducationDataSource
                            ]);
                        }

                        // Data for Non-Application
                        if (scope.options.shortBio || scope.options.fullBio) {
                            dataSourceArray = dataSourceArray.concat([
                                volunteerActivitiesDataSource
                            ]);
                        }

                        // Data used for Full Bio && Volunteer View (Application Personal)
                        if (scope.options.fullBio || scope.options.appPersonalVolunteer) {
                            dataSourceArray = dataSourceArray.concat([
                                languageProficiencyDataSource
                            ]);
                        }

                        // Data used in Full Bio Only
                        if (scope.options.fullBio) {
                            dataSourceArray = dataSourceArray.concat([
                                countriesDataSource,
                                licenseOptionsDataSource,
                                otherConflictsDataSource,
                                programAreaSocietyDataSource,
                                societyMembershipDataSource,
                                stateOptionDataSource,
                                trainingInfoDataSource,
                                pevcTrainingInfoDataSource,
                                unavailableCountriesDataSource,
                                unavailableDatesDataSource,
                                volunteerNotesDataSource,
                                volunteerProgramAreaDataSource,
                                volunteerTermsDataSource,
                                volunteerHealthSafetyDataSource,
                                volunteerAvailabilityTravelDataSource
                            ]);
                        }

                        // Options
                        if (scope.options.isApplication || scope.options.fullBio) {
                            dataSourceArray = dataSourceArray.concat([
                                commissionOptionsDataSource,
                                ethnicityOptionsDataSource,
                                licenseOptionsDataSource,
                                memberSocietyOptionsDataSource,
                                stateOptionDataSource,
                                volunteerStatusOptionsDataSource
                            ]);
                        }

                        // Load page data
                        if (Number.isInteger(scope.volunteerId)) {
                            var secondPromise = helperSvc.getData(dataSourceArray);

                            secondPromise.then(function () {
                                if (!(scope.options.preview || volunteerSvc.data.isVolunteer || volunteerSvc.data.isAdmin)) {
                                    var societyOptions = volunteerSvc.getSocietyOptionsByCurrentUser(societyMembershipSvc.data.memberSocietyOptions);
                                    var roles = currentUser.profile.userRoles;
                                    var societyRole = null;
                                    var allowedProgramAreas = [];
                                    var allowedCommissions = [];
                                    var arrContains = helperSvc.arrContains;

                                    for (var i = 0; i < societyOptions.length; i++) {
                                        var currentSociety = societyOptions[i];

                                        if (scope.programAreaSocietyOptions) {
                                            for (var j = 0; j < scope.programAreaSocietyOptions.length; j++) {
                                                var programAreaSociety = scope.programAreaSocietyOptions[j];
                                                programAreaSociety.societyAbbreviatedName = programAreaSociety.societyName;
                                                delete programAreaSociety.societyName;

                                                if (currentSociety.codeKey === programAreaSociety.societyId && !arrContains(allowedProgramAreas, programAreaSociety)) {
                                                    allowedProgramAreas.push(programAreaSociety);
                                                }
                                            }
                                        }
                                    }

                                    for (var i = 0; i < allowedProgramAreas.length; i++) {
                                        var currentProgramArea = allowedProgramAreas[i];
                                        var commissionType = commissionTypes[currentProgramArea.commissionId - 1];
                                        var commissionOption = { codeKey: commissionType.id, codeName: commissionType.abrv };

                                        if (!arrContains(allowedCommissions, commissionOption)) {
                                            allowedCommissions.push(commissionOption);
                                        }
                                    }

                                    volunteerSvc.data.allowedProgramAreas = allowedProgramAreas;
                                    volunteerSvc.data.allowedCommissions = allowedCommissions;
                                }

                                if (scope.options.preview) {
                                    scope.showSpinner = false;
                                } else {
                                    barSvc.hideBar();
                                };

                                //need to combine the pevc trainings with the ones manually added and alter there data structure/naming
                                trainingInfoSvc.addPevcTrainingsToTrainings(scope.pevcTrainings);

                                scope.data = volunteerSvc.data;
                                scope.isDataReady = true;
                                componentHelperSvc.invokeOnRendered('volunteerPage', scope);

                                //Just warning box handle this along with travel availability and date availability
                                //if (!scope.options.preview && !scope.options.isApplication && !scope.hasComplied() && scope.allowComplyEdit) {
                                //    var msg = scope.showFirstPersonMessage ? "Please comply with ABET's Code of Conduct for this year" : "This volunteer has not complied with ABET's Code of Conduct";
                                //    var btnName = "Comply"
                                //    var alert = alertSvc.getPersistentAlert(msg, btnName);
                                //    var func = function () { scope.editVolunteerTerms(alert); };
                                //    alertSvc.addPersistentAlertWarning(msg, func, btnName);
                                //}
                            });
                        } else {
                            if (scope.options.isApplication && !pevAppSvc.data.application.applicationJson.volunteer.hasOwnProperty('volunteerId')) {
                                console.error("This is an outdated pev application that does not have a volunteerId; create a new application and try again.");
                            } else {
                                console.error("No volunteerId defined in volunteerDirectives.js");
                            }
                            componentHelperSvc.invokeOnRendered('volunteerPage', scope);
                        }
                    });
                }();

                // Update travel availability if addresses are edited.
/*                eventSvc.listen(() => helperSvc.getData([volunteerAvailabilityTravelDataSource]), 'loadPersonGeneral', scope);*/
            }
        };
    });

}(angular.module('volunteer')));