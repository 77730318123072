(function (module) {
    var rrDetailCtrl = function ($state, $stateParams, $uibModal, $scope, helperSvc, tabSvc, barSvc, alertSvc, readinessSvc, readinessValidationSvc) {

        var model = this;

        model.title = null;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openMasterInstructions = readinessSvc.openMasterInstructions;

        model.isDataReady = false;

        var path = 'apps/readinessReview/templates/';

        model.nav = [
            tabSvc.createTab('General Information', readinessSvc.slugs.INFO, path + 'rrGeneralModule.html'),
            tabSvc.createTab('Program Information', readinessSvc.slugs.PROGRAMS, path + 'rrProgramModule.html'),
            tabSvc.createTab('Readiness Review', readinessSvc.slugs.READINESS, path + 'rrReadinessModule.html'),
            tabSvc.createTab('Submit', readinessSvc.slugs.SUBMIT, path + 'rrSubmitModule.html')
        ];

        if (model.isAdmin) {
            model.nav.splice(0, 0, tabSvc.createTab('Manage Status', readinessSvc.slugs.STATUS, path + 'manageStatusModule.html'));
        }

        if (model.isReadOnly) model.nav.pop(); // Remove submit tab for read-only view

        var validationCallback = function () {
            readinessValidationSvc.populateWarnings(model.nav);
        };

        readinessValidationSvc.listenToValidate(validationCallback, $scope);

        var activate = function () {
            barSvc.showBar();

            readinessSvc.getRRById(parseInt($stateParams.readinessId)).then(function (rr) {
                readinessSvc.setSelectedRR(rr);
                model.isReadOnly = readinessSvc.isReviewer();
                model.data = readinessSvc.data;
                model.title = model.data.selectedRR.organizationJson.organizationName;
                validationCallback.apply();
                barSvc.hideBar();
                model.isDataReady = true;
            });

        }();
    };
    module.controller('rrDetailCtrl', rrDetailCtrl);

}(angular.module('readinessReview')));