(function (module) {

    var onlineEducationCtrl = function ($scope, $uibModal, pevAppSvc, oauth, volunteerHelperSvc, onlineEducationSvc, componentHelperSvc) {
        var model = this;
        var isAdmin = oauth.isAdmin();
        var checkIsVolunteer = true;

        model.parent = $scope.$parent.model;
        model.title = 'Online Education Experience';
        model.isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);
        model.isButtonHidden = model.applicationMode ? pevAppSvc.isEditButtonHiddenForGeneralModules() : pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;

        if (model.isApplication) {
            model.data = pevAppSvc.volunteerOnlineEducation;
        } else {
            model.data = onlineEducationSvc.data;
        }

        componentHelperSvc.invokeOnRendered('onlineEducationExperience', $scope);

        model.editOnlineEducation = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/onlineEducationEdit.html',
                size: 'lg',
                controller: 'onlineEducationEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('onlineEducationCtrl', onlineEducationCtrl);

}(angular.module('volunteer')));