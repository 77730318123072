(function (module) {
    var reviewTeamCtrl = function ($uibModal, $state, helperSvc, alertSvc, userReviewSvc, teamMemberStatusTypes, reviewTeamMemberSvc, currentUser,
        membershipSvc, messageSvc, messageTemplateTypes, contactRoleTypes, designationTypes, appraisalSvc, teamMemberTypeNames,
        volunteerHelperSvc, globalConstantSvc, reviewTeamVisitObserverSvc, personSvc, personUserSvc, commissionIds, rfrSvc, oauth, contactTypes) {

        var model = this;

        model.isDataReady = false;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.data = userReviewSvc.data;
        model.appraisalData = appraisalSvc.data;
        model.somePevsVisible = false;
        model.someObsVisible = false;
        model.appraiserType = null;
        model.impersonate = personSvc.impersonateVolunteer;
        model.commissionIds = commissionIds;
        model.isInterim = rfrSvc.isInterim;
        model.approvingPEV = false;
        model.canImpersonate = oauth.canImpersonate();

        model.pevAssignmentStatus = function (program) {
            if (program.pevsRequired && program.pevsRequired >= 1)
                return "None assigned";
            else
                return "Not required";
        }

        model.isVisible = function (program, volunteer, isPEV) {
            var id = isPEV ? teamMemberTypeNames.PEV : teamMemberTypeNames.PROGRAMOBSERVER;

            if (volunteer) {
                return isVisible(volunteer);
            } else {
                return model.pevMembers.some(function (pev) {
                    return isVisible(pev);
                });
            }
            
            function isVisible(person) {
                if (person) {
                    var isChair = userReviewSvc.isChairPerson(model.data.currentUserReviewTeam);
                    var isApproved = person.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED;
                    var isPendingInst = person.teamMemberStatusId === teamMemberStatusTypes.PENDINGPEVASSIGNMENTINSTITUTION ||
                                        person.teamMemberStatusId === teamMemberStatusTypes.APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT;
                    var isAssigned = (person.teamMemberTypeId === id) &&
                                     (program.programName === person.programName) &&
                                     (program.disciplineName === person.disciplineName) &&
                                     (program.degreeCode === person.degreeCode);

                    return isAssigned && (isApproved || isChair || model.isTeamChair || (model.isInstitutionUser && isPendingInst));
                }
                return false;
            }
        };

        model.openPevDeclineModal = function () {
            openDeclineModal('Visit Team Chair', model.teamChairName, model.teamChairEmail, true);
        };

        model.openTCDeclineModal = function () {
            // show only if this TC has not been accepted already
            openDeclineModal('Director, Accreditation Operations', '(410) 347-7736', 'accreditation@abet.org');
        };

        model.openTeamMemberInfo = function () {
            var parameters = [{ name: 'UserPersonId', value: currentUser.profile.personId }, { name: 'ReviewTeamId', value: model.data.currentReviewTeam.reviewTeamId }];

            $uibModal.open({
                animation: true,
                templateUrl: '/Apps/report/templates/preview.html',
                size: 'xl',
                controller: 'previewCtrl',
                resolve: {
                    reportName: function () { return "Review Team - Team Member Contact Information"; },
                    inparams: function () { return parameters; }
                }
            });
        }

        function openDeclineModal(title, info, email, isPEV) {
            var declineTitle = 'Decline an Assignment';
            var pevItem = isPEV ? '<li>Program name</li>' : '';
            var declineMsg = '<p>To ensure minimal disruption to the assignment process you must contact the party below to coordinate declining an assignment. Your email should include:</p>' +
                             '<ul>' +
                                '<li>Institution name</li>' + pevItem +
                                '<li>Detailed reason for declining the assignment</li>' +
                                '<li>Your contact information</li>' +
                             '</ul>' +
                             '<hr/>';

            var body = '<div class="text-center">' +
                            '<ul class="no-style">' +
                                '<li><h4 class="no-padding">' + title + '</h4></li>' +
                                '<li>' + info + '</li>' +
                                '<li>' + email + '</li>' +
                            '</ul>' +
                        '</div>';
            alertSvc.openModalAlert(declineMsg + body, declineTitle);
        }

        model.openDeclinePevAsTcModal = function (pev) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/userReview/templates/rejectPevModal.html',
                size: 'md',
                controller: 'rejectPevModalCtrl',
                resolve: {
                    pev: function () {
                        return pev;
                    },
                    callback: function () {
                        return teamChairDeclinePev;
                    }
                }
            });

            function teamChairDeclinePev(reason, pev) {
                var updatedPev = { reviewTeamMemberId: pev.reviewTeamMemberId, teamMemberStatusId: teamMemberStatusTypes.DECLINEDBYTC, rejectComment: reason }
                userReviewSvc.updateReviewTeamMemberStatus(updatedPev, true).then(function () {
                    var tcPersonId = model.data.currentUserReviewTeam.personId;
                    var roles = [contactRoleTypes.EDUCATIONLIASON, contactRoleTypes.PEVASSIGNOR]
                    membershipSvc.getOAuthOrganizationUsersByOrganizationId(pev.leadSocietyId, roles).then(function (users) {
                        var orgUsers = helperSvc.getValue(users);
                        var personIds = [];

                        //send email to education liaisions and pevassignosr who are only resposible for the commission.
                        angular.forEach(orgUsers, function (person) {
                            if (person.roleId === contactRoleTypes.EDUCATIONLIASON ||
                                (person.roleId === contactRoleTypes.PEVASSIGNOR && person.oAuthOrganizationUserDesignations &&
                                    helperSvc.arrayContainsByPropertyValue(person.oAuthOrganizationUserDesignations, 'designationId',
                                        model.data.currentReviewTeamPrograms[0].commissionId))) {
                                personIds.push(person.personId);
                            }
                        });
                        var declineMessageParameters = [{ messageParameterName: 'OrganizationName', messageParameterKey: model.data.currentUserReviewTeam.organizationId.toString() },
                            { messageParameterName: 'RejectComment', messageParameterValue: reason },
                            { messageParameterName: 'PEVName', messageParameterValue: pev.firstName + ' ' +  pev.lastName }];

                        var primaryRecipients = messageSvc.createRecipientDtos(personIds, contactTypes.SOCIETYCONTACTONLY);
                        var ccdRecipients = messageSvc.createRecipientDtos([tcPersonId], contactTypes.VOLUNTEERACTIVITYONLY);

                        messageSvc.buildAndSendMessage(primaryRecipients, ccdRecipients, messageTemplateTypes.PEVASSIGNMENTREJECTEDBYTC, declineMessageParameters)
                    });

                    model.pevMembers = userReviewSvc.getTeamPrograms();
                    alertSvc.addAlertWarning("PEV has been successfully rejected.");
                });
            }
        };

        model.openBioModal = function (volunteer) {
            var useFullBio = false;
            // if current user is TC and volunteer is PEV   useFullBio = true;
            if (model.isTeamChair && volunteer.teamMemberTypeId === teamMemberTypeNames.PEV)
                useFullBio = true;            

            volunteerHelperSvc.openBioModal(volunteer, useFullBio);
        };

        model.openPersonModal = function (person) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/userReview/templates/personInfoModal.html',
                controller: 'personInfoModalCtrl',
                size: 'lg',
                resolve: {
                    personId: function () {
                        return person.personId;
                    },
                    personName: function () {
                        return person.normalizedName;
                    }
                }
            });
        }

        model.openAppraisalModal = function (volunteer) {
            if (model.isAdmin) {
                // Open manage appraisal modal
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/userReview/templates/appraisalMgmt.html',
                    size: 'lg',
                    controller: 'appraisalMgmtCtrl',
                    backdrop: 'static',
                    resolve: {
                        volunteer: function () { return volunteer; }
                    }
                });
            } else {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/userReview/templates/appraisalEdit.html',
                    size: 'lg',
                    controller: 'appraisalEditCtrl',
                    backdrop: 'static',
                    resolve: {
                        appraisee: function () { return volunteer; },
                        appraiserType: function () { return getAppraiserType(); },
                        currentUserReviewTeam: function () { return model.data.currentUserReviewTeam; }
                    }
                });
            }
        };

        model.showAppraisal = function (volunteer) {
            if (volunteer && volunteer.volunteerId) {

                var todaysDate = new Date();
                var endDate = new Date(model.data.currentReviewTeam.visitEndDate);
                var isObserver = volunteer.teamMemberTypeId == teamMemberTypeNames.PROGRAMOBSERVER;
                var isTrainingSite = model.data.currentUserReviewTeam.organizationName.indexOf("Upper State University") > -1;

                if (isTrainingSite)
                    return true;

                //auto return paths
                if (isObserver)
                    return false;

                if (model.isAdmin)
                    return true;

                if (endDate > todaysDate)
                    return false;

                if (volunteer.reviewYear < helperSvc.getAcademicYear())
                    return false;

                
                if (volunteer.volunteerId !== parseInt(currentUser.profile.volunteerId)) {

                    if (model.isPEV || model.isTeamChair || model.isCoTeamChair || model.isReportTeamChair) {
                        //volunteers are in the same teamGroup

                        if (volunteer.teamMemberTypeId == teamMemberTypeNames.EDITOR1 || volunteer.teamMemberTypeId == teamMemberTypeNames.EDITOR2)
                            return false;

                        if (model.isCoTeamChair && volunteer.teamMemberTypeId == teamMemberTypeNames.COTEAMCHAIR)
                            return false;

                        if (model.data.myReviewTeamProgram.reviewTeamGroupId !== null) {
                            if (volunteer.reviewTeamGroupId !== null && volunteer.reviewTeamGroupId === model.data.myReviewTeamProgram.reviewTeamGroupId) {
                                return true;
                            }
                        } else {
                            if (volunteer.reviewTeamGroupId === null)
                                return true;
                        }
                    }
                    if (model.isInstitutionUser) {
                        var roles = helperSvc.getFilteredArray(currentUser.profile.userRoles, 'organizationId', [model.data.currentUserReviewTeam.organizationId], true);

                        //if president has appraisal access to all tcs and pevs
                        if (helperSvc.arrayContainsByPropertyValue(roles, 'roleId', contactRoleTypes.PRESIDENT))
                        {                           
                            model.appraiserType = contactRoleTypes.PRESIDENT;
                            return true;                          
                        }

                        //primary contact for commission has access to all pevs and tcs
                        if (helperSvc.arrayContainsByPropertyValue(roles, 'roleId', [contactRoleTypes.PRIMARY, contactRoleTypes.SECONDARY])) {
                            
                            var rolesArray = helperSvc.getFilteredArray(roles, 'roleId', [contactRoleTypes.PRIMARY, contactRoleTypes.SECONDARY], true);
                           
                            for (var i = 0; i < rolesArray.length; i++) {
                                if (rolesArray[i].designationTypeId === designationTypes.COMMISSION) {//for the designation Type of commission
                                    for (var j = 0; j < rolesArray[i].oAuthOrganizationUserDesignations.length; j++) {
                                        if (rolesArray[i].oAuthOrganizationUserDesignations[j].designationId === model.data.currentUserReviewTeam.commissionId)
                                        {  
                                            model.appraiserType = contactRoleTypes.PRIMARY;
                                            return true;
                                        }
                                    }
                                }
                            }                
                        }

                        //program contact can appraise the pev for that program
                        if (helperSvc.arrayContainsByPropertyValue(roles, 'roleId', contactRoleTypes.PROGRAM)) {
                            if(volunteer.programId){
                                var rolesArray = helperSvc.getFilteredArray(roles, 'roleId', [contactRoleTypes.PROGRAM], true);
                                                 
                                for (var i = 0; i < rolesArray.length; i++) {
                                    if (rolesArray[i].designationTypeId === designationTypes.PROGRAM) {//for the designation Type of commission
                                        for (var j = 0; j < rolesArray[i].oAuthOrganizationUserDesignations.length; j++) {
                                            if (rolesArray[i].oAuthOrganizationUserDesignations[j].designationId === volunteer.programId)
                                            {    
                                                model.appraiserType = contactRoleTypes.PROGRAM;
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                                  
                    if (userReviewSvc.isTeamMemberType([teamMemberTypeNames.EDITOR1], model.data.currentUserReviewTeam) && (volunteer.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || volunteer.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR)){
                        return true;
                    }              
                }
            }
            return false;           
        };

        model.appraisalCompleted = function (volunteer) {
            if(!model.isAdmin){
                if (appraisalExists(volunteer))
                    return true;
            }
            return false;
        }

        //Hide bio information if it is a past review. If need to enable this view, should fix the user role enddate. 
        model.showBio = function (volunteer) {
            if (model.isAdmin) {
                return true;
            }
            if (!volunteer || volunteer.reviewYear < helperSvc.getAcademicYear())
                return false;
            return true;
        };

        model.pendingInstitutionAcceptance = function (volunteer) {
            return volunteer.teamMemberStatusId === teamMemberStatusTypes.PENDINGTCASSIGNMENT || volunteer.teamMemberStatusId === teamMemberStatusTypes.PENDINGPEVASSIGNMENTINSTITUTION;
        };

        model.pendingTCAcceptance = function (volunteer) {
            return volunteer.teamMemberStatusId === teamMemberStatusTypes.PENDINGPEVASSIGNMENTTCACCEPT;
        };

        model.canDeclinePev = function(volunteer){
            return volunteer.teamMemberStatusId > teamMemberStatusTypes.PENDINGTCASSIGNMENT && volunteer.teamMemberStatusId < teamMemberStatusTypes.DECLINEDBYTC;
        }

        model.approveTeamChair = function (volunteer) {
            alertSvc.confirm("You are about to approve <strong><em>" + volunteer.firstName + " " + volunteer.lastName + "</strong></em> as a Team Chair. Do you want to proceed?", approve);

            function approve() {
                var updatedTeamChair = { reviewTeamMemberId: volunteer.reviewTeamMemberId, teamMemberStatusId: teamMemberStatusTypes.APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT, rejectComment: null }
                //commissionId
                var approveTCMessageParams = [{ messageParameterName: 'OrganizationName', messageParameterKey: model.data.currentUserReviewTeam.commissionId.toString() }];

                userReviewSvc.updateReviewTeamMemberStatus(updatedTeamChair, true).then(function () {
                    var primaryRecipients = messageSvc.createRecipientDtos([volunteer.personId], contactTypes.VOLUNTEERACTIVITYONLY);
                    messageSvc.buildAndSendMessage(primaryRecipients, [], messageTemplateTypes.TCASSIGNMENTAPPROVED, approveTCMessageParams);
                    model.chairMembers = userReviewSvc.getChairMembers();
                    alertSvc.addAlertSuccess("Team Chair has been successfully approved.");
                });
            }
        };

        model.approvePEV = function (volunteer) {

            alertSvc.confirm('You are about to approve this program evalauator. Do you want to proceed?', confirmedFunc);

            function confirmedFunc() {
                //if IU ,else tc settings
                model.approvingPEV = true;
                var status;
                var primaryRecipientPersonIds = [];
                var ccdRecipientPersonIds = [];
                var promise;

                if (model.isInstitutionUser && volunteer.teamMemberStatusId === teamMemberStatusTypes.PENDINGPEVASSIGNMENTINSTITUTION) {
                    status = teamMemberStatusTypes.APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT;
                    primaryRecipientPersonIds.push(volunteer.personId);
                    //primary recipient is volunteer. CC the TC
                    for (var i = 0; i < model.chairMembers.length; i++) {
                        if (model.chairMembers[i].teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR)
                            ccdRecipientPersonIds.push(model.chairMembers[i].personId);
                    }
                } else {
                    status = teamMemberStatusTypes.PENDINGPEVASSIGNMENTINSTITUTION;
                    //get IU primary and secondary contacts for messages, and CC TC
                    var roles = [contactRoleTypes.PRIMARY, contactRoleTypes.SECONDARY, contactRoleTypes.DELEGATEFORPRIMARY];
                    promise = membershipSvc.getOAuthOrganizationUsersByOrganizationId(volunteer.organizationId, roles);
                    ccdRecipientPersonIds.push(model.data.currentUserReviewTeam.personId);
                }

                var updatedPEV = { reviewTeamMemberId: volunteer.reviewTeamMemberId, teamMemberStatusId: status, rejectComment: null }

                userReviewSvc.updateReviewTeamMemberStatus(updatedPEV, true).then(function () {
                    if (model.isInstitutionUser) {
                        var approvePEVMessageParameters = buildApprovePEVByIUParameters(volunteer);
                        var primaryRecipients = messageSvc.createRecipientDtos(primaryRecipientPersonIds, contactTypes.VOLUNTEERACTIVITYONLY);
                        var ccdRecipients = messageSvc.createRecipientDtos(ccdRecipientPersonIds, contactTypes.VOLUNTEERACTIVITYONLY);

                        messageSvc.buildAndSendMessage(primaryRecipients, ccdRecipients, messageTemplateTypes.PEVASSIGNMENTAPPROVEDBYIU, approvePEVMessageParameters);
                    }
                    else {
                        promise.then(function (users) {
                            var orgUsers = helperSvc.getValue(users);
                            angular.forEach(orgUsers, function (person) {
                                //check that the person is part of the current commission
                                for (var i = 0; i < person.oAuthOrganizationUserDesignations.length; i++) {
                                    var designation = person.oAuthOrganizationUserDesignations[i];
                                    if (designation.designationId === model.data.currentUserReviewTeam.commissionId) {
                                        primaryRecipientPersonIds.push(person.personId);
                                        break;
                                    }
                                }
                            });

                            var primaryRecipients = messageSvc.createRecipientDtos(primaryRecipientPersonIds, contactTypes.INSTITUTIONCONTACTONLY);
                            var ccdRecipients = messageSvc.createRecipientDtos(ccdRecipientPersonIds, contactTypes.VOLUNTEERACTIVITYONLY);

                            messageSvc.buildAndSendMessage(primaryRecipients, ccdRecipients, messageTemplateTypes.PEVASSIGNMENTAPPROVEDBYTC);
                        });
                    }

                    model.pevMembers = userReviewSvc.getTeamPrograms();
                    alertSvc.addAlertSuccess("PEV has been succesfully approved.");
                });
                model.approvingPEV = false;
            }

        };

        model.showIRAppraisalMessage = function(){
            return alertSvc.openModalAlert("No performance evaluation for interim report team chairs");
        }

        model.openAppraisersModal = function () {
            var programs = [];
            angular.forEach(model.data.currentReviewTeamPrograms, function (program) {
                if (program.programId !== null && program.programName !== null) {
                    var newProgram = { programId: program.programId, programName: program.programName, reviewTeamId: program.reviewTeamId };
                    if (!helperSvc.arrayContainsByPropertyValue(programs, 'programId', newProgram.programId)) {
                        programs.push(newProgram);
                    }
                }
            });
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/userReview/templates/assignAppraisersModal.html',
                size: 'lg',
                controller: 'assignAppraisersModalCtrl',
                resolve: {
                    programs: function () {
                        return programs;
                    },
                    reviewTeamId: function () {
                        return model.data.currentReviewTeamPrograms.length ? model.data.currentReviewTeamPrograms[0].reviewTeamId : 0;
                    }
                }
            });
        };

        var activate = function () {
            if (model.isAdmin) {
                model.isInstitutionUser = true;
                model.isTeamChair = true;
            } else {
                model.isInstitutionUser = userReviewSvc.isInstitutionUser(model.data.currentUserReviewTeam);
                model.isTeamChair = userReviewSvc.isTeamChair(model.data.currentUserReviewTeam);
                model.isCoTeamChair = userReviewSvc.isTeamMemberType([teamMemberTypeNames.COTEAMCHAIR], model.data.currentUserReviewTeam);
                model.isReportTeamChair = userReviewSvc.isTeamMemberType([teamMemberTypeNames.REPORTTEAMCHAIR], model.data.currentUserReviewTeam);
                model.isPEV = userReviewSvc.isPEV(model.data.currentUserReviewTeam);
                model.isEditor = userReviewSvc.isEditor(model.data.currentUserReviewTeam);
            }

            //if user is TC add editors for get chairmembers call is true, and if user is IU users dont have to be approved
            model.chairMembers = userReviewSvc.getChairMembers((!model.isInstitutionUser && !model.isPEV) || model.isAdmin, !model.isInstitutionUser);
            model.pevMembers = userReviewSvc.getTeamPrograms();
            model.tcTitle = model.isTeamChair ? "Team Chairs (TC) and Editors" : "Team Chairs (TC)";
            model.voTitle = "Visit Observers";
            model.simultaneousReview = model.data.currentReview.reviewSimultaneousOptionDtos && model.data.currentReview.reviewSimultaneousOptionDtos.length > 0;
            model.simulTCTitle = "Other Commission Team Chairs (TC)";
            model.pevTitle = "Program Evaluators (PEV)";
            model.isInterimReport = (model.data.currentUserReviewTeam.reviewTypeCode == 'IR') ? true : false;

            if(model.data.currentReviewTeamPrograms && model.data.currentReviewTeamPrograms.length > 0){
                reviewTeamVisitObserverSvc.getVisitObserversByReviewTeamIdOdata(model.data.currentReviewTeamPrograms[0].reviewTeamId).then(function (data) {
                    model.visitObservers = data;
                });

            }
                
            var dataSourceArray = [];
            if (!model.isAdmin) {
                
                appraisalDataSource = {
                    storeData: false,
                    svcCallback: [appraisalSvc.getAppraisalsForCurrentUser],
                    svcCallbackArguments: [model.data.currentReviewTeam.reviewTeamId, model.data.currentUserReviewTeam],
                };
                dataSourceArray.push(appraisalDataSource);
            }
            

            //only get other comission TCS if current user is TC or Co-TC
            if (model.simultaneousReview && (model.isTeamChair || model.isCoTeamChair))
                getSimultaneousTeamChairs();

            if (model.pevMembers) {

                //determine the teamchairs email to possibly decline pevs
                if (model.isInstitutionUser) {
                    for (var i = 0; i < model.chairMembers.length; i++) {
                        var chair = model.chairMembers[i];
                        if (chair.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || chair.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR) {
                            model.teamChairName = chair.firstName + ' ' + chair.lastName;
                            //get teamchair email
                            teamChairEmailDataSource = {
                                dataHolder: model,
                                dataLocationName: 'teamChairEmail',
                                svcCallback: globalConstantSvc.getGlobalVariable,
                                svcCallbackArguments: [globalConstantSvc.nameConstants.personVolunteerEmail, chair.personId],
                                helperCallback: helperSvc.getValue
                            }
                            dataSourceArray.push(teamChairEmailDataSource);
                        }
                    }
                }
            }

            helperSvc.getData(dataSourceArray).then(function () {
                model.isDataReady = true;
            });

        }();

        function getSimultaneousTeamChairs() {
            var currentCommissionId = model.data.currentUserReviewTeam.commissionId;
            model.simultaneousTeamChairs = [];
            //this check is needed cuz review could be a simul review but this commission might actually not be part of it.
            model.isPartOfASimultaneousReview = false;
            //this needs to loop to make sure this is the right commission
            angular.forEach(model.data.currentReview.reviewSimultaneousOptionDtos, function (simulReviewOption) {
                var simultaneousReview = simulReviewOption;
                var simultaneousCommissionIds = simultaneousReview.reviewSimultaneousOptionCommissionDtos.map(function (e) { return e.commissionId });

                //checks that the current commission is a part of this simultaneous commission dto
                if (simultaneousCommissionIds.indexOf(currentCommissionId) > -1) {
                    model.isPartOfASimultaneousReview = true;
                    var simultaneousReviewTeams = [];
                    for (var i = 0; i < simultaneousCommissionIds.length; i++) {
                        if (simultaneousCommissionIds[i] !== currentCommissionId) {
                            //get review team for this commission
                            var simultaneousReviewTeam = helperSvc.getArrayByFieldValue(model.data.currentReview.reviewTeamDtos, 'commissionId', simultaneousCommissionIds[i])
                            simultaneousReviewTeams.push(simultaneousReviewTeam[0][0]);
                        }
                    }

                    //get the right review teamMembers
                    angular.forEach(simultaneousReviewTeams, function (team) {
                        reviewTeamMemberSvc.getMyReviewTeamMembers(team.reviewTeamId, true).then(function (members) {
                            var allMembers = helperSvc.getFilteredArray(members, 'teamMemberTypeId', [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR], true);
                            var finalMembers = helperSvc.getArrayByFieldValue(allMembers, 'teamMemberStatusId', teamMemberStatusTypes.ASSIGNMENTAPPROVED);
                            model.simultaneousTeamChairs = model.simultaneousTeamChairs.concat(finalMembers[0]);
                        });
                    });
                }
            });
        }

        function buildApprovePEVByIUParameters(volunteer) {
            var approvePEVMessageParameters = [];

            var institutionName = model.data.currentUserReviewTeam.organizationName;
            var orgAddress = ''
            if (model.data.currentReview.organizationDto.currentOrganizationAddressDto.stateCode)
                orgAddress = model.data.currentReview.organizationDto.currentOrganizationAddressDto.stateCode + ', ' + model.data.currentReview.organizationDto.currentOrganizationAddressDto.countryCode;
            else
                orgAddress = model.data.currentReview.organizationDto.currentOrganizationAddressDto.province ? model.data.currentReview.organizationDto.currentOrganizationAddressDto.province + ', ' + model.data.currentReview.organizationDto.currentOrganizationAddressDto.countryCode : model.data.currentReview.organizationDto.currentOrganizationAddressDto.countryCode;
           
            var dateOptions = {year: 'numeric', month: 'long', day: 'numeric'}
            var visitStartDate = helperSvc.formatDate(model.data.currentReviewTeam.visitStartDate, false, true).toLocaleDateString('en-US', dateOptions);
            var teamChairName = '';
            for (var i = 0; i < model.chairMembers.length; i++) {
                var chair = model.chairMembers[i];
                if (chair.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR) {
                    teamChairName = chair.firstName + ' ' + chair.lastName;
                    break;
                }
            }

            approvePEVMessageParameters.push({ messageParameterName: 'OrganizationName', messageParameterKey: model.data.currentUserReviewTeam.commissionId.toString() },
                { messageParameterName: 'OrganizationAbbreviation', messageParameterKey: model.data.currentUserReviewTeam.commissionId.toString() },
                { messageParameterName: 'TeamMemberTypeName', messageParameterValue: volunteer.teamMemberTypeName },
                { messageParameterName: 'InstitutionName', messageParameterValue: institutionName },
                { messageParameterName: 'OrganizationAddress', messageParameterValue: orgAddress },
                { messageParameterName: 'VisitStartDate', messageParameterValue: visitStartDate },
                { messageParameterName: 'TeamChairName', messageParameterValue: teamChairName });

            return approvePEVMessageParameters;
        }

        function appraisalExists(volunteer) {
            if (model.appraisalData && model.appraisalData.appraisals && model.appraisalData.appraisals.length > 0) {
                for (var i = 0; i < model.appraisalData.appraisals.length; i++) {
                    if (model.appraisalData.appraisals[i].appraiseeVolunteerId === volunteer.volunteerId)
                        return true;
                }
            }
            return false;
        }

        function getAppraiserType() {
            if (model.isInstitutionUser) {
                return model.appraiserType;
            }
            else {
                return model.data.myReviewTeamProgram.teamMemberTypeId;
            }
        }

    };
    module.controller('reviewTeamCtrl', reviewTeamCtrl);

}(angular.module('userReview')));