(function (module) {

    var groupsAndRolesEditCtrl = function (searchOpts, groupsAndRolesSvc, organization, user, userId, personName, mode, $q, $scope,
        $uibModalInstance, roleSvc, organizationTypes, organizationSvc, membershipSvc, eventSvc, alertSvc, helperSvc, contactRoleTypes
    ) {
        var model = $scope;
        var parent = $scope.$parent;
        var getFilteredArray = helperSvc.getFilteredArray;
        var excludedRoles = [contactRoleTypes.MEMBERPROGRAMEVALUATOR,
                             contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE];

        model.title = 'User Groups and Roles';
        model.currentOrganizationId = organization ? organization.organizationId : null;
        model.currentPersonId = userId;
        model.isAddMode = mode === 'add';
        model.isEditMode = mode === 'edit';
        model.isReplaceMode = mode === 'replace';

        model.userId = userId;
        model.personName = personName;
        model.organization = organization;
        model.dataIsReady = false;
        model.data = {};
        model.orgData = {};
        model.isInsertMode = false;
        model.showHistory = false;
        model.organizationTypes = organizationTypes;
        //needs a . because of angular scoping issues with primitives for the quicksearch
        model.selectedOrg = {};
        model.selectedOrg.sortName = "";
        model.roles = undefined;
        model.designations = undefined;
        model.selectedRoleItem = undefined;
        model.selectedDesignationIds = undefined;
        model.selectedOrganizationId = 0;
        model.selectedOrganizationName = "";
        model.selectedDesignationNames = "";
        
        model.cycleYears = [] // go to 2000, and then this year + 1
        var currentYear = new Date().getFullYear();
        for(var i = 2000; i < currentYear + 1; i++){
            model.cycleYears.push(i);
        }

        model.positionTerms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] //arbitrary terms here need more info
        model.newModel = {};

        getMembershipList(userId);

        model.getHistoricalRoles = function (userId) {
            model.showHistory = true;
            getMembershipList(model.userId, model.showHistory);
        }

        model.hideHistoricalRoles = function () {
            model.showHistory = false;
            getMembershipList(model.userId, model.showHistory);
        }

        model.searchOrganization = function (val) {
            if (val.length < 3)
                return;

            var promise = organizationSvc.getOrganizationsByName(val).then(function (data) {
                return data.value;
            });

            return promise;
        }

        model.onSelectOrganization = function ($item) {
            model.selectedOrganizationName = $item.organizationName;
            model.selectedOrganizationId = $item.organizationId;
            model.selectedOrganizationTypeId = $item.organizationTypeId;
            var societies = null;
            var roles = roleSvc.getRolesByOrganizationType($item.organizationTypeName);
            if (model.isSocietyPosition()) {
                societies = organizationSvc.getOrganizationsByType([organizationTypes.ASSOCIATESOCIETY, organizationTypes.MEMBERSOCIETY]);
            }

            $q.all([roles, societies]).then(function (data) {
                roles = data.shift();
                societies = data.shift();

                if (roles) {
                    roles = helperSvc.getValue(roles);
                    model.roles = getFilteredArray(roles, "roleId", excludedRoles);
                }
                if (societies) {
                    model.societies = helperSvc.getValue(societies.data);
                }

            });
        }

        model.onSelectRole = function () {
            model.newModel.designationTypeName = getDesignationTypeName(model.newModel.roleId);
            model.newModel.chosenDesignations = [];
            getDesignations();
        }

        model.setDesignationSelection = function (id) {

            var index = model.selectedDesignationIds.indexOf(id);

            if (index > -1) {
                model.selectedDesignationIds.splice(index, 1);
            } else {
                model.selectedDesignationIds.push(id);
            }

            return false;
        }

        model.isChecked = function (id) {
            if ((model.selectedDesignationIds !== undefined) && (model.selectedDesignationIds.indexOf(id) > -1)) {
                return 'fa fa-check pull-right';
            }
            return false;
        };

        model.addNew = function () {
            model.isInsertMode = true;
            resetNewRole();
        };


        model.closeInsert = function () {
            reset();
        };

        model.formatDate = function (date) {
            if (date == null)
                return '?';

            var dateOut = new Date(date);
            return dateOut;
        };

        function refreshUserGroup(){
            if(model.currentOrganizationId)
                groupsAndRolesSvc.getGroupsAndRolesSearch(model.currentOrganizationId, searchOpts)
        }

        model.saveRow = function () {
            model.isInsertMode = true;

            var newDesignations = [];

            for (var i = 0; i < model.newModel.chosenDesignations.length; i++) {
                newDesignations.push({ organizationUserId: 0, designationId: model.newModel.chosenDesignations[i].designationId });
            }

            var newRole = {
                organizationUserId: model.newModel.organizationUserId,
                organizationId: model.selectedOrganizationId,                      
                userId: !model.isEditMode ? model.newModel.selectedNewUserId : model.newModel.userId, //person does not have to be a user of the system
                firstName: !model.isEditMode  ? model.newModel.person.firstName : model.newModel.firstName,
                lastName: !model.isEditMode ? model.newModel.person.lastName : model.newModel.lastName,
                personId: !model.isEditMode ? model.newModel.person.personId : model.newModel.personId,
                roleId: model.newModel.roleId,
                startDate: model.newModel.startDate,
                endDate: model.newModel.endDate,
                isRoleOnly: model.newModel.isRoleOnly,
                note: model.newModel.note,
                positionTerm: model.newModel.positionTerm,
                societyId: model.newModel.societyId ? model.newModel.societyId : null,
                replacedOrganizationUserId: model.isReplaceMode ? model.newModel.replacedOrganizationUserId : null,
                organizationUserDesignationDtos: newDesignations,
                isDeleted: false
            };

            if(model.isReplaceMode){

                membershipSvc.replace(newRole, newRole.replacedOrganizationUserId).then(function(data){
                    getMembershipList(model.userId, model.showHistory);
                    reset();
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully replaced.");
                    $uibModalInstance.close();
                });

            }
            else if (model.isEditMode) {

                membershipSvc.update(newRole).then(function (data) {
                    getMembershipList(model.userId, model.showHistory);
                    reset();
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully updated.");
                    $uibModalInstance.close();
                });
            } else {
                membershipSvc.insert(newRole).then(function (data) {
                    getMembershipList(model.userId, model.showHistory);
                    reset();
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully created.");
                    $uibModalInstance.close();
                });
            }


        };

        model.deleteRow = function (role) {
            alertSvc.confirmDelete(role.organizationName, deleteFunc);

            function deleteFunc() {
                membershipSvc.delete(role.organizationUserId).then(function (data) {
                    getMembershipList(model.userId, model.showHistory);
                    refreshUserGroup();
                    alertSvc.addModalAlertSuccess("User role successfully deleted.");
                });
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        }

        model.isSocietyPosition = function () {
            if (model.selectedOrganizationTypeId == organizationTypes.BOARDOFDIRECTORS || model.selectedOrganizationTypeId == organizationTypes.COMMISSION || model.selectedOrganizationTypeId == organizationTypes.COMMITTEE)
                return true;
            return false;
        }

        function getDesignationTypeName(roleId) {
            if (model.roles) {
                for (var i = 0; i < model.roles.length; i++) {
                    if (model.roles[i].roleId === roleId) {
                        return model.roles[i].designationTypeName;
                    }
                }
            }
        }

        function getMembershipList(userId, showHistory) {
            membershipSvc.getByUserId(userId, !showHistory).then(function (data) {
                var userRoles = helperSvc.getValue(data);
                model.userRoles = getFilteredArray(userRoles, "roleId", excludedRoles);
            });
        };

        function reset() {
            model.selectedDesignationIds = undefined;
            model.selectedRoleItem = undefined;
            model.isInsertMode = false;
            model.isEditMode = false;
            model.designations = [];
            model.selectedOrg.sortName = "";
            model.selectedOrganizationTypeId = null;
            model.otherOptionsAvailable = false;
            model.societies = [];
            resetNewRole();
        }

        function getDesignations() {
            if (model.newModel.designationTypeName != null) {//some reason this is not including programs???
                roleSvc.getDesignationsByTypeName(model.newModel.designationTypeName, model.selectedOrganizationId).then(function (data) {
                    model.data.designations = data.value;
                });
            } else {
                model.data.designations = [];
                model.newModel.chosenDesignations = [];
            }
        }

        function resetNewRole() {
            model.orgData = {};
            model.selectedOrg.sortName = "";
            model.newModel = {
                organizationUserId: 0,
                isRoleOnly: false,
                selectedUserId: null,
                roleId: null,
                designationTypeName: null,
                chosenDesignations: [],
                startDate: null,
                endDate: null,
                note: null,
                replacedOrganizationUserId: null
            }
        }

        function getAndSetRolesAndSocietiesForOrg(organization){
           
            //var historicalMembers = membershipSvc.getByOrganizationIdHistorical(model.organizationId);
            var roles = roleSvc.getRolesByOrganizationTypeId(organization.organizationTypeId);
            var societies = null;
            if (organization.organizationTypeId == model.organizationTypes.BOARDOFDIRECTORS || organization.organizationTypeId == model.organizationTypes.COMMISSION || organization.organizationTypeId == model.organizationTypes.COMMITTEE)
            {
                societies = organizationSvc.getOrganizationsByType([organizationTypes.ASSOCIATESOCIETY, organizationTypes.MEMBERSOCIETY]);
                model.otherOptionsAvailable = true;
            }

            return $q.all([roles, societies]).then(function (data) {

                roles = data.shift();
                societies = data.shift();
                if (roles) {
                    roles = helperSvc.getValue(roles);
                    model.roles = getFilteredArray(roles, "roleId", excludedRoles);
                }
                if (societies) {
                    model.societies = helperSvc.getValue(societies.data);
                }               
            });
        }


            
        var activate = function () {
            resetNewRole(); 

            if(model.isReplaceMode){
                model.userBeingReplaced = angular.copy(user);
                model.newModel.roleId = user.roleId;
                model.newModel.person = null;
                model.newModel.organizationId = user.organizationId;
                model.newModel.replacedOrganizationUserId = user.organizationUserId;

                if(organization){
                    model.selectedOrg.sortName = (organization.currentOrganizationDetailDto.sortName) ? organization.currentOrganizationDetailDto.sortName : organization.currentOrganizationDetailDto.organizationName;
                    model.selectedOrganizationTypeId = organization.organizationTypeId;
                    model.selectedOrganizationId = organization.organizationId;
                    getAndSetRolesAndSocietiesForOrg(organization).then(function(){
                        model.onSelectRole();
                        model.newModel.chosenDesignations = user.designationViewModels;
                        model.dataIsReady = true;
                    });
                }
            }
               
            if(model.isEditMode){
                model.newModel = angular.copy(user);            
                model.newModel.startDate = user.startDate ? new Date(user.startDate) : null;
                model.newModel.endDate = user.endDate ? new Date(user.endDate): null;
                

                if(organization){
                    model.selectedOrg.sortName = (organization.currentOrganizationDetailDto.sortName) ? organization.currentOrganizationDetailDto.sortName : organization.currentOrganizationDetailDto.organizationName;;
                    model.selectedOrganizationTypeId = organization.organizationTypeId;
                    model.selectedOrganizationId = organization.organizationId;
                    getAndSetRolesAndSocietiesForOrg(organization).then(function(){
                        model.onSelectRole();
                        model.newModel.chosenDesignations = user.designationViewModels;
                        model.dataIsReady = true;
                    });
                }
            }

            if(model.isAddMode){
               
                if(user){
                    model.newModel = angular.copy(user);
                   
                }
                if(organization){
                    model.selectedOrg.sortName = (organization.currentOrganizationDetailDto.sortName) ? organization.currentOrganizationDetailDto.sortName : organization.currentOrganizationDetailDto.organizationName;
                    model.newModel.organizationId = organization.organizationId;
                    model.selectedOrganizationTypeId = organization.organizationTypeId;
                    model.selectedOrganizationId = organization.organizationId;
                    getAndSetRolesAndSocietiesForOrg(organization).then(function(){
                        model.onSelectRole();

                        model.dataIsReady = true;
                    });
                }
            }   
        }();
    };

    module.controller('groupsAndRolesEditCtrl', groupsAndRolesEditCtrl);

}(angular.module('groupsAndRoles')));