(function (module) {

    var travelAvailabilityEditCtrl = function (parent, $scope, $uibModalInstance, $state, alertSvc, volunteerSvc, codeSvc, travelAvailabilitySvc, volunteerHelperSvc, helperSvc, personSvc) {
        var model = $scope;
        var checkIsVolunteer = true;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.currentInfo = {};
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 

        if (travelAvailabilitySvc.data.travelAvailability.volunteerAvailabilityTravelId) {
            model.currentInfo.travelAvailability = angular.copy(travelAvailabilitySvc.data.travelAvailability);
        } else {
            model.currentInfo.travelAvailability = travelAvailabilitySvc.data.travelAvailability.volunteerAvailabilityTravelId ? angular.copy(travelAvailabilitySvc.data.travelAvailability) :
                {
                    volunteerId: model.volunteerId,
                    year: travelAvailabilitySvc.currentYear,
                    volunteerTravelAvailability: null,
                    volunteerVirtualAvailability: null,
                    departureCountryCode: null,
                    willFulfilABETRequirement: null,
                    willFulfilInstitutionRequirement: null
                };
        }

        model.save = function () {

            if (model.currentInfo.travelAvailability.volunteerAvailabilityTravelId) {
                travelAvailabilitySvc.updateTravelAvailability(model.currentInfo.travelAvailability).then(onSuccess, onFailure);
            }
            else {
                travelAvailabilitySvc.createTravelAvailability(model.currentInfo.travelAvailability).then(onSuccess, onFailure);
            }

            function onSuccess() {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Travel Availability information successfully saved.");
            }
            function onFailure() {
                alertSvc.addModalAlertWarning('Travel Availability  information could not be saved at this time.');
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        var activate = function () {
            codeSvc.getCountries().then(function (data) {
                model.countryList = data.value;
            });
        }();
    };

    module.controller('travelAvailabilityEditCtrl', travelAvailabilityEditCtrl);

}(angular.module('volunteer')));