(function (module) {

    var reviewTeamSvc = function ($q, $http, helperSvc, amsConst, odataSvc, teamMemberTypeNames) {
        var odataRoot = amsConst.webApiUrl + '/odata/';
        var searchApiPath = odataRoot + 'GetReviewTeamSearch({0})';
        var apiPath = odataRoot + 'ReviewTeam({0})';
        var baseQuery = '?$filter=organizationId eq ';
        var factory = {};

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        /*************************************************
        *Refactor starts here
        **************************************************/
        var key = 'reviewTeamId';
        var api = '/ReviewTeam';
        factory.data = {
            reviewTeam: null,
            reviews: null
        };


        factory.getReviewTeamByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
                .expand('reviewDto')
                .filter('reviewTeamId', id)
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.reviewTeam = data;
                })
            }

            return oSvc.getDeferred().promise;
        }

        factory.getSingleReviewTeamById = function (id) {
            var oSvc = odataSvc.get();
            

            oSvc.getSource(api, key).odata()
                .expand('reviewDto')
                .get(id)
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);      

            return oSvc.getDeferred().promise;
        }

        factory.update = function (reviewTeam, noStoredData) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, reviewTeam);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            if(!noStoredData)
                resolveReviewTeamData(oSvc, reviewTeam.reviewTeamId);

            return oSvc.getDeferred().promise;
        }

        factory.deleteOdata = function (reviewTeam) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, { reviewTeamId: reviewTeam.reviewTeamId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.searchAllReviewTeamsByOrganizationId = function (organizationId) {
            return $http.get(searchApiPath.format(null) + baseQuery + organizationId).then(onSuccess, onFailure);
        };

        factory.searchCurrentReviewTeamsByOrganizationId = function (organizationId, noStoredData) {
            var promise = $http.get(searchApiPath.format(null) + baseQuery + organizationId + createAfterNGRYearFilter()).then(onSuccess, onFailure);

            if (!noStoredData) {
                promise.then(function (data) {
                    factory.data.reviews = data.value;
                });
            }
           
            return promise;
        };
        
        factory.searchPastReviewTeamsByOrganizationId = function (organizationId) {
            return $http.get(searchApiPath.format(null) + baseQuery + organizationId + createBeforeNGRYearFilter()).then(onSuccess, onFailure);
        };

        factory.getReviewByReviewTeamId = function (reviewTeamId) {
            return $http.get(apiPath.format(null) + '?$filter=reviewTeamId eq ' + reviewTeamId + '&$expand=reviewDto').then(onSuccess, onFailure);
        };

        factory.create = function (reviewTeam) {
            return $http.post(apiPath.format(null), reviewTeam).then(onSuccess, onFailure);
        };

        //factory.update = function (reviewTeam) {
        //    return $http.put(apiPath.format(reviewTeam.reviewTeamId), reviewTeam).then(onSuccess, onFailure);
        //};

        factory.delete = function (reviewTeamId) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(reviewTeamId)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        factory.getTeamReviewsByCommission = function (teamReviews, commissionId) {
            var filtered = [];

            if (teamReviews) {
                for (var i = 0; i < teamReviews.length; i++) {
                    var teamReview = teamReviews[i];

                    if (teamReview.commissionId == commissionId) {
                        teamReview.codeKey = teamReview.reviewTeamId;
                        teamReview.codeName = teamReview.commissionName + " | " + teamReview.reviewYear + ' ' + teamReview.reviewTypeName;

                        filtered.push(teamReview);
                    }
                }
            }

            return filtered;
        }

        var createAfterNGRYearFilter = function () {
            var currentAcademicYear = helperSvc.getAcademicYear();
            var ngrYearFilter = ' and (ngrYear ne null and ((ngrYear gt ' + currentAcademicYear + ' and reviewYear ge (ngrYear sub 6)) or (ngrYear le ' + currentAcademicYear + ' and reviewYear ge ngrYear)))';
            return ngrYearFilter;
        };

        var createBeforeNGRYearFilter = function () {
            var currentAcademicYear = helperSvc.getAcademicYear();
            var ngrYearFilter = ' and (ngrYear eq null or ((ngrYear gt ' + currentAcademicYear + ' and reviewYear lt (ngrYear sub 6)) or (ngrYear le ' + currentAcademicYear + ' and reviewYear lt ngrYear)))';
            return ngrYearFilter;
        };

        function createCommissionFilter(commissionId) {
            var commFilter = ' and commissionId eq ' + commissionId;

            return commFilter;
        }


        function resolveReviewTeamData(oSvc, reviewTeamId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getReviewTeamByReviewTeamIdOdata(reviewTeamId);
            });
        }

        factory.getTeamMemberTypeAbbrv = function (teamMemberTypeId) {
            if (!teamMemberTypeId) return 'ADM';

            switch (teamMemberTypeId) {
                case teamMemberTypeNames.PEV:
                    return "PEV";
                case teamMemberTypeNames.TEAMCHAIR:
                    return "TC";
                case teamMemberTypeNames.COTEAMCHAIR:
                    return "CO-TC";
                case teamMemberTypeNames.REPORTTEAMCHAIR:
                    return "RTC";
                case teamMemberTypeNames.EDITOR1:
                    return "ED1";
                case teamMemberTypeNames.EDITOR2:
                    return "ED2";
                case teamMemberTypeNames.PROGRAMOBSERVER:
                case teamMemberTypeNames.VISITOBSERVER:
                case teamMemberTypeNames.VISITSTATEOBSERVER:
                    return "OBSV";
                case teamMemberTypeNames.ADJUNCT:
                    return "ADJ";
                case teamMemberTypeNames.ADMIN:
                    return "ADM";
            }
        }

        factory.getTeamMemberTypeStyle = function (teamMemberTypeId) {
            if (!teamMemberTypeId) return 'admin';

            switch (teamMemberTypeId) {
                case teamMemberTypeNames.PEV:
                    return "pev";
                case teamMemberTypeNames.TEAMCHAIR:
                case teamMemberTypeNames.COTEAMCHAIR:
                case teamMemberTypeNames.REPORTTEAMCHAIR:
                    return "tc";
                case teamMemberTypeNames.EDITOR1:
                    return "editor-1";
                case teamMemberTypeNames.EDITOR2:
                    return "editor-2";
                case teamMemberTypeNames.PROGRAMOBSERVER:
                case teamMemberTypeNames.VISITOBSERVER:
                case teamMemberTypeNames.VISITSTATEOBSERVER:
                    return "observer";
                case teamMemberTypeNames.ADJUNCT:
                    return "adjunct";
                default:
                    return "";
            }
        }

        factory.getTeamMemberTypeName = function (teamMemberTypeId) {
            if (!teamMemberTypeId) return 'Admin';

            switch (teamMemberTypeId) {
                case teamMemberTypeNames.PEV:
                    return "PEV";
                case teamMemberTypeNames.TEAMCHAIR:
                    return "TC";
                case teamMemberTypeNames.COTEAMCHAIR:
                    return "Co-TC";
                case teamMemberTypeNames.REPORTTEAMCHAIR:
                    return "Report TC";
                case teamMemberTypeNames.EDITOR1:
                    return "Editor 1";
                case teamMemberTypeNames.EDITOR2:
                    return "Editor 2";
                case teamMemberTypeNames.PROGRAMOBSERVER:
                case teamMemberTypeNames.VISITOBSERVER:
                case teamMemberTypeNames.VISITSTATEOBSERVER:
                    return "Observer";
                case teamMemberTypeNames.ADJUNCT:
                    return "Adjunct";
                case teamMemberTypeNames.ADMIN:
                    return "Admin";
                default:
                    return "Team Member";
            }
        }

        factory.getLatestGeneralReviewTeam = function (organizationId, commissionId) {
            var thePath = amsConst.webApiUrl + '/odata/GetLatestGeneralReviewTeam(organizationId={0}, commissionId={1})';

            return $http.get(thePath.format(organizationId, commissionId)).then(onSuccess, onFailure);
            //var commissionFilter = ' and commissionId eq ' + commissionId;
            //var typeFilter = ' and (reviewTypeCode eq GR or reviewTypeCode eq INIT)'

            //return $http.get(searchApiPath.format(null) + baseQuery + organizationId + commissionFilter + typeFilter).then(onSuccess, onFailure);
        }


        return {
            searchAllReviewTeamsByOrganizationId: factory.searchAllReviewTeamsByOrganizationId,
            searchCurrentReviewTeamsByOrganizationId: factory.searchCurrentReviewTeamsByOrganizationId,
            searchPastReviewTeamsByOrganizationId: factory.searchPastReviewTeamsByOrganizationId,
            getReviewByReviewTeamId: factory.getReviewByReviewTeamId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            getTeamReviewsByCommission: factory.getTeamReviewsByCommission,
            getReviewTeamByReviewTeamIdOdata: factory.getReviewTeamByReviewTeamIdOdata,
            data: factory.data,
            deleteOdata: factory.deleteOdata,
            getSingleReviewTeamById: factory.getSingleReviewTeamById,
            getTeamMemberTypeAbbrv: factory.getTeamMemberTypeAbbrv,
            getTeamMemberTypeStyle: factory.getTeamMemberTypeStyle,
            getTeamMemberTypeName: factory.getTeamMemberTypeName,
            getLatestGeneralReviewTeam: factory.getLatestGeneralReviewTeam
        };
    };
    module.factory('reviewTeamSvc', reviewTeamSvc);

})(angular.module('common'));