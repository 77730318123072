(function (module) {

    var viewAppraisalPDFCtrl = function ($scope, $uibModalInstance, appraisalPDFSvc, helperSvc, currentAppraisalData, appraiseeName) {
        var model = $scope;

        model.title = 'View Performance Appraisal';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        }

        function activate() {
            model.doc = appraisalPDFSvc.generateDoc(currentAppraisalData, appraiseeName);
        }

        activate();
    };

    module.controller('viewAppraisalPDFCtrl', viewAppraisalPDFCtrl);

}(angular.module('evaluatorFollowUp')));