(function (module) {

    var personMediaSvc = function ($http, $q, amsConst, odataSvc, $odata, documentSvc) {
        var factory = {};
        var queryBase = '?$filter=';
        
        /**********************************
        *REFACTORED
        **********************************/
        var key = 'personId';
        var memberKey = 'personMediaId';
        var api = '/PersonMedia';
        factory.signature = { data: null };
        factory.photo = { data: null };

        factory.getPersonMediaByPersonId = function (id, noStoredData) {
       
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonMediasByPersonId', 'personId', id);

            oSvc.getSource(path, memberKey).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {

                    var filteredSignature = data.filter(t => t.personMediaTypeId == 1);
                    if (filteredSignature)
                        factory.signature.data = filteredSignature[0];

                    var filteredPhoto = data.filter(t => t.personMediaTypeId == 2);
                    if (filteredPhoto)
                        factory.photo.data = filteredPhoto[0];
                });
            }

            return oSvc.getDeferred().promise;
        }

        factory.getMediaImageByPersonId = function (id, mediaTypeId) {
            return factory.getPersonMediaByPersonId(id, true).then(function (data) {
                var singlefile = data.filter(t => t.personMediaTypeId == mediaTypeId);

                if (singlefile && singlefile.length > 0) {
                    return documentSvc.downloadDocumentBase64(singlefile[0].stream_id);
                } else {
                    var deferred = $q.defer();
                    deferred.resolve(null);
                    return deferred.promise;
                }
            });
        };

        factory.create = function (newPersonMedia) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, newPersonMedia);
            delete newPersonMedia.fileName;
            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            refreshMedia(oSvc, newPersonMedia.personId);

            return oSvc.getDeferred().promise;
        };

        function refreshMedia(oSvc, personId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getPersonMediaByPersonId(personId);
            });
        }

        factory.update = function (newPersonMedia) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, memberKey, newPersonMedia);
            delete newPersonMedia.fileName;
            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            refreshMedia(oSvc, newPersonMedia.personId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (personMedia) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, memberKey, { personMediaId: personMedia.personMediaId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            refreshMedia(oSvc, personMedia.personId);

            return oSvc.getDeferred().promise;
        };

        return {
            signature: factory.signature,
            photo: factory.photo,
            getPersonMediaByPersonId: factory.getPersonMediaByPersonId,
            getMediaImageByPersonId: factory.getMediaImageByPersonId,
            update: factory.update,
            create: factory.create,
            delete: factory.delete
        };
    };
    module.factory('personMediaSvc', personMediaSvc);

})(angular.module('review'));