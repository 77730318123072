(function (module) {
    var currentUserCtrl = function ($scope, $state, currentUser, oauth, messageHelperSvc) {
        var model = this;
        var dropdown = angular.element(document.querySelector('#user-dropdown'));
        var isDropdownClosed = true;

        model.user = currentUser.profile;
        
        model.signOut = function () {
            oauth.logout().then(function () {
                $state.go('logout');
            });
            
        }
        model.openInbox = messageHelperSvc.openInbox;

        model.toggleDropdown = function (isOnBlur) {
            if (!isOnBlur) {
                (isDropdownClosed) ? dropdown.addClass('open') : dropdown.removeClass('open');
                isDropdownClosed = !isDropdownClosed;
            } else if (isOnBlur && !isDropdownClosed) {
                dropdown.removeClass('open');
                isDropdownClosed = !isDropdownClosed;
            }
        };
    };

    module.controller('currentUserCtrl', currentUserCtrl);

}(angular.module('common')));