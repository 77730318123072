(function (module) {

    var volunteerBioPreviewCtrl = function ($scope, $uibModalInstance, volunteer) {
        var model = $scope;

        model.title = volunteer.firstName + " " + (angular.isString(volunteer.middleName) && volunteer.middleName != "" ? volunteer.middleName + " " : "") + volunteer.lastName;
        model.subtitle = "Volunteer Bio";
        model.volunteer = volunteer;

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('volunteerBioPreviewCtrl', volunteerBioPreviewCtrl);

}(angular.module('volunteer')));