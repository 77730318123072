(function (module) {

    var demographicInfoEditCtrl = function (parent, $scope, $uibModalInstance, $state, alertSvc, volunteerSvc, pevAppSvc, codeSvc, citizenshipSvc, helperSvc) {
        var model = $scope;
        var checkIsVolunteer = true;
        var isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.data = angular.copy(volunteerSvc.data);
        model.isDataReady = false;

        if (isApplication) {
            model.currentInformation = angular.copy(pevAppSvc.volunteerData.volunteer);
            model.currentInformation.citizenship = angular.copy(pevAppSvc.volunteerData.volunteer.citizenship);
        } else {
            model.currentInformation = angular.copy(model.data.volunteer);
        }

        model.save = function () {
            setEthnicityTypeName();

            if (isApplication) {
               var pevApp = angular.copy(pevAppSvc.data.application);

               pevApp.applicationJson.volunteer.gender = model.currentInformation.gender;
               pevApp.applicationJson.volunteer.ethnicityTypeId = model.currentInformation.ethnicityTypeId;
               pevApp.applicationJson.volunteer.ethnicityTypeName = model.currentInformation.ethnicityTypeName;
               pevApp.applicationJson.volunteer.yearOfBirth = model.currentInformation.yearOfBirth;

               var citizenshipCodes = [];
                if(model.currentInformation.citizenship){
                    for (var i = 0; i < model.currentInformation.citizenship.length; i++) {             
                        citizenshipCodes.push({countryCode: model.currentInformation.citizenship[i].codeKey});
                    }
                }

                pevApp.applicationJson.volunteer.citizenship = citizenshipCodes;

                pevAppSvc.update(pevApp).then(onSuccess, onFailure);
            } else {
                var citizenshipDtos = [];
                angular.forEach(model.currentInformation.citizenship, function (item) {
                    if(item.codeKey && item.codeKey !== ''){
                        citizenshipDtos.push( {
                            volunteerId: model.currentInformation.volunteerId,
                            countryCode: item.codeKey
                        });
                    }
                });

                delete model.currentInformation.citizenship;
             
               citizenshipSvc.update(citizenshipDtos, model.currentInformation.volunteerId).then(function () {
                   volunteerSvc.update(model.currentInformation).then(onSuccess, onFailure);           
                });

            }
            
            function onSuccess () {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Demographical information successfully saved.");
            }
            function onFailure () {
                alertSvc.addModalAlertWarning('Demographical information could not be saved at this time.');
            }
       
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        function setEthnicityTypeName() {
            //set ethnicity type name for data refresh
            var selectedEthnicity = model.data.ethnicityTypeOptions.find(function (item) {
                return item.codeKey === model.currentInformation.ethnicityTypeId;
            });

            if (selectedEthnicity) model.currentInformation.ethnicityTypeName = selectedEthnicity.codeName;
        }

        var activate = function () {

           var dataSource = [
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getCountries],
                    dataLocationName: 'countryOptions',
                    helperCallback: helperSvc.getValue
                } 
            ];

            if (!isApplication){
                var citizenshipData = {
                    dataHolder: model,
                    svcCallback: [citizenshipSvc.getByVolunteerId],
                    svcCallbackArguments: [model.currentInformation.volunteerId],
                    dataLocationName: 'currentInformation.citizenship',
                    odataResource: true
                }
                
                dataSource.push(citizenshipData);
            }
        

           helperSvc.getData(dataSource).then(function () {
                if (model.currentInformation.citizenship) {
                   angular.forEach(model.currentInformation.citizenship, function (item) {
                       item.codeKey = item.countryCode;
                   });
                }else{
                    model.currentInformation.citizenship = [];
                }

                model.isDataReady = true;
            });
        }();
    };

    module.controller('demographicInfoEditCtrl', demographicInfoEditCtrl);

}(angular.module('volunteer')));