(function (module) {

    var templateRoot = '/apps/readinessReview/templates/';

    module.directive('contactInfoEdit', function (codeSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'contactInfoEdit.html',
            scope: {
                rr: '='
            },
            link: function (scope, elem, attrs) {
                activate()

                function activate() {
                    codeSvc.getNamePrefixes().then(function (data) {
                        scope.namePrefixes = data.value.map(function (obj) { return obj.codeKey });
                    });
                }
            }
        };
    });

}(angular.module('readinessReview')));