(function (module) {

    var societyMembershipSvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerSocietyMembership',
            keyName: 'volunteerSocietyMembershipId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'societyMemberships',
            getByForeignKeyPath: '/GetVolunteerSocietyMembershipByVolunteerId'
        };

        var factory = new odataServiceFactory(config);
        factory.data.memberSocietyOptions = [];

        //DELETE WHEN NEW CONFIRMATION DIALOG IS READY
        var apiPath = '/VolunteerSocietyMembership';
        var key = 'volunteerSocietyMembershipId';

        factory.delete = function (societyMembership) {
            var oSvc = odataSvc.get();

            //confirmation breaks protractor test disabling for now
            //if ($window("Are you sure you want to delete this record?")) {

                var resource = oSvc.instantiate(apiPath, key, { volunteerSocietyMembershipId: societyMembership.volunteerSocietyMembershipId });
                resource.$delete(oSvc.onSuccess, oSvc.onFailure);

                oldLoadData(oSvc, societyMembership.volunteerId);

                return oSvc.getDeferred().promise;
            //}
            //else {
            //    return $q.reject('user cancelled');
            //}
        };

        function oldLoadData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getByForeignKey(volunteerId);
            });
        }

        return {
            data: factory.data,
            getByVolunteerId: factory.getByForeignKey,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('societyMembershipSvc', societyMembershipSvc);

})(angular.module('volunteer'));