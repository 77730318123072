(function (module) {

    var personCtrl = function (personSvc, $uibModal, $scope, $location, barSvc, personMdl) {
        var model = this;

        model.people = null;
        model.resultTitle = "Results";
        model.searchTitle = "Find A Person";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.totalCount = 0;
        model.showSpinner = false;

        barSvc.listenForShow(function() { model.showSpinner = true;  });
        barSvc.listenForHide(function() { model.showSpinner = false; });

        model.retrieve = function () {

            model.people = null;
            model.resultMsg = null;
            barSvc.showSpinner();

            if (model.searchOptions) {
                var searchOpts = model.searchOptions;

                var isFnameDefined = isTextDefined(searchOpts.firstName);
                var isLnameDefined = isTextDefined(searchOpts.lastName);
                var isPhoneDefined = isTextDefined(searchOpts.telephoneNumber);
                var isEmailDefined = isTextDefined(searchOpts.emailAddress);
                var isUserNameDefined = isTextDefined(searchOpts.userName);

                var isFnameEmpty = isTextEmpty(searchOpts.firstName);
                var isLnameEmpty = isTextEmpty(searchOpts.lastName);
                var isPhoneEmpty = isTextEmpty(searchOpts.telephoneNumber);
                var isEmailEmpty = isTextEmpty(searchOpts.emailAddress);
                var isUserNameEmpty = isTextEmpty(searchOpts.userName);

                if (isFnameDefined || isLnameDefined || isPhoneDefined || isEmailDefined || isUserNameDefined) {

                    personSvc.advancedSearch(model.searchOptions).then(function (data) {

                        barSvc.hideSpinner();

                        model.people = personSvc.castToPersonMdl(data)
                        model.totalCount = model.people.length;

                        if (model.people)
                            model.resultMsg = null;

                        if (model.totalCount == 0)
                            model.resultMsg = "No results found";
                    });
                } else if (isFnameEmpty && isLnameEmpty && isPhoneEmpty && isEmailEmpty && isUserNameEmpty) {
                    softReset(model.defaultSearchMsg);
                } else {
                    softReset("Please expand your search");
                }         
            }
        };

        model.reset = function () {
            model.searchOptions = null;
            model.people = null;
            model.resultMsg = model.defaultSearchMsg;
        };

        model.openPerson = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/person/templates/personEdit.html',
                size: 'md',
                controller: 'personEditCtrl',
                resolve: {
                    id: function () {
                        return 0;
                    },
                    parent: function() {
                        return model;
                    }
                }
            });
        };

        var softReset = function (message) {
            barSvc.hideSpinner();
            model.people = null;
            model.resultMsg = message;
        };

        var isTextDefined = function (property) {
            return property !== undefined && property.trim().length > 2;
        };

        var isTextEmpty = function (property) {
            return property === undefined || (property !== undefined && property.trim().length < 1);
        };

    };
    module.controller('personCtrl', personCtrl);

}(angular.module('person')));

(function (module) {
    var collapseCtrl = function () {
        this.isCollapsed = true;
    };
    module.controller('collapseCtrl', collapseCtrl);
}(angular.module('person')));