(function (module) {

    var assignmentSummaryEditCtrl = function (mode, selectedReviewTeams, selectedVolunteers, $scope, $uibModalInstance, tcAssignmentSvc, pevAssignmentSvc) {
        var model = $scope;

        model.tcMode = mode ? mode.toLowerCase().contains('tc') : false;
        model.title = 'Preview ' + (model.tcMode ? 'Team Chairs and Editors' : 'PEVs and Observers');
        model.data = model.tcMode ? tcAssignmentSvc.data : pevAssignmentSvc.data;
        model.selectedReviewTeams = selectedReviewTeams;
        model.selectedVolunteers = selectedVolunteers;

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('assignmentSummaryEditCtrl', assignmentSummaryEditCtrl);

}(angular.module('assignment')));