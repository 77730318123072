(function (module) {

    var verifyContactEditCtrl = function (rr, $scope, $uibModalInstance, readinessSvc, contactTypes, telephoneTypes, $q, personEmailSvc, personTelephoneSvc, helperSvc, alertSvc, eventSvc) {
        var model = $scope;

        model.title = 'Verify Contact';
        model.rr = angular.copy(rr.selectedRR);
        model.useExistingContact = false;

        model.close = function () {
            $uibModalInstance.close();
        };

        model.verify = function () {
            if (model.useExistingContact) {
                // Mark as verified
                var personDto = createPersonDto();
                var personEmailDto = createPersonEmailDto();
                var personTelephoneDto = createPersonTelephoneDto();
                var readinessDto = createReadinessDto();

                var params = {
                    personDto: personDto,
                    personEmailDto: personEmailDto,
                    personTelephoneDto: personTelephoneDto,
                    readinessDto: readinessDto
                };

                personEmailSvc.checkForDuplicates(0, model.rr.contactPersonJson.email, false).then(function (result) {
                    var hasDuplicates = helperSvc.getValue(result);
                    var doCreate = true;

                    if (hasDuplicates)
                        doCreate = confirm('An existing account with the same email address as the current contact has been found. The contact will be updated to match the existing account. Do you want to continue?');

                    if (doCreate) {
                        readinessSvc.createContactPerson(params).then(function (data) {
                            model.close();
                            alertSvc.addAlertSuccess("Contact successfully verified.");
                            eventSvc.broadcast('refreshReadinessData');
                        });
                    }
                    
                });
            } else {
                // Replace RR contact with selected contact
                buildBuildContactPersonObj(model.selectedContact.personId, model.selectedContact.data).then(function (person) {
                    if (person.email === null) {
                        alertSvc.addModalAlertWarning("The selected user does not have an email on record. Please add an email to verify this contact.");
                        return;
                    }
                    readinessSvc.updateContactPerson(model.rr, model.selectedContact.personId, person).then(function (data) {
                        model.close();
                        alertSvc.addAlertSuccess("Contact successfully verified.");
                        eventSvc.broadcast('refreshReadinessData');
                    });
                });
            }
        };

        //model.canVerify = function (invalid) {
        //    var valid = invalid ? false : true;
            
        //    if (model.newContact.checked) return true;

        //    return valid;
        //};

        model.setUseExisting = function () {
            model.useExistingContact = !model.useExistingContact;
        };

        function getPersonEmailDto(personId) {
            var allABETActivitiesPromise = personEmailSvc.getAllABETActivitiesEmail(personId);
            var institutionOnlyPromise = personEmailSvc.getEmailByContactType(personId, contactTypes.INSTITUTIONCONTACTONLY);
            return $q.all([allABETActivitiesPromise, institutionOnlyPromise]).then(function (data) {
                var abetEmail = helperSvc.getItem(data.shift());
                if (abetEmail) {
                    return abetEmail;
                } else {
                    var intitutionEmail = helperSvc.getItem(data.shift());
                    if (intitutionEmail) return intitutionEmail;
                    return null;
                }
            });
        }

        function buildBuildContactPersonObj(personId, contact) {
            
            var emailPromise = getPersonEmailDto(personId);
            var telephonePromise = personTelephoneSvc.getAllABETActivitiesTelephone(personId);

            return $q.all([emailPromise, telephonePromise]).then(function (data) {
                var personEmail = data.shift();
                var personTelephone = data.shift();

                personTelephone = personTelephone[0];

                var emailAddress = personEmail ? personEmail.emailAddress : null;
                var telephoneNumber = personTelephone ? personTelephone.telephoneNumber : null;

                var person = {
                    namePrefixCode: contact.namePrefixCode,
                    firstName: contact.firstName,
                    middleName: contact.middleName,
                    lastName: contact.lastName,
                    telephone: telephoneNumber,
                    email: emailAddress
                };

                return person
            });
        }

        function createReadinessDto() {
            var readinessDto = angular.copy(model.rr);
            delete readinessDto["@odata.context"];
            delete readinessDto.$promise;
            delete readinessDto.$resolved;
            return readinessDto;
        }

        function createPersonDto() {
            return {
                personId: 0,
                firstName: model.rr.contactPersonJson.firstName,
                middleName: model.rr.contactPersonJson.middleName,
                lastName: model.rr.contactPersonJson.lastName
            };
        }

        function createPersonEmailDto() {
            return {
                personEmailId: 0,
                personId: 0,
                emailAddress: model.rr.contactPersonJson.email,
                contactTypeId: contactTypes.ALLABETACTIVITIES
            };
        }

        function createPersonTelephoneDto() {
            return {
                personTelephoneId: 0,
                personId: 0,
                telephoneTypeId: telephoneTypes.WORK,
                number: model.rr.contactPersonJson.telephone,
                contactTypeId: contactTypes.ALLABETACTIVITIES
            };
        }

        var activate = function () {
            model.currentContact = angular.copy(rr.selectedRR.contactPersonJson)
            model.selectedContact = {
                personId: 0,
                data: {}
            };
        }();
    };

    module.controller('verifyContactEditCtrl', verifyContactEditCtrl);

}(angular.module('readinessReview')));