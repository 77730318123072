(function (module) {
    var rrGeneralCtrl = function ($uibModal, $state, helperSvc, alertSvc, readinessSvc, readinessStatuses, readinessStatusNames) {
        var model = this;

        model.formatDate = helperSvc.formatDate;
        model.formatUrl = helperSvc.formatUrl;
        model.date = new Date();
        model.readinessStatuses = readinessStatuses;
        model.isReadOnly = readinessSvc.isReadOnly();
        model.isReviewer = readinessSvc.isReviewer();
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openGeneralInstructions = readinessSvc.openGeneralInstructions;
        model.readOnlyText = model.isReadOnly ? (model.isReviewer ? 'Reviewers cannot edit institution info.' : 'Institution info cannot be edited after submission.') : '';

        model.editInstitutionInfo = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/readinessReview/templates/rrGeneralEdit.html',
                size: 'lg',
                controller: 'rrGeneralEditCtrl',
                resolve: {
                    rr: function () {
                        return model.data.selectedRR
                    }
                }
            });
        };

        model.getReadinessStatusName = function(readinessStatusId){
            var status = helperSvc.getFirstMatch(readinessStatusNames, 'readinessStatusId', readinessStatusId);
            return status.statusName;
        };

        var activate = function () {
            model.data = readinessSvc.data;
        }();
    };
    module.controller('rrGeneralCtrl', rrGeneralCtrl);

}(angular.module('readinessReview')));