(function (module) {
    module.filter('isCurrentProgram', ['helperSvc', function (helperSvc) {
        return function (programs) {
            var matches = [];


            if (programs && programs.length > 0) {
                programs.forEach(function (program) {
                    if ((program.lastAction === 'NEW' || program.lastAction == 'Not Found') && program.isCurrent === true && program.accreditationDates === null) {
                        matches.push(program);
                        return;
                    }

                    if (program.accreditationDates !== null) {
                        var dates = program.accreditationDates.split('-');
                        var endDateParts = dates[dates.length - 1].split('/');
                        if (endDateParts.length === 3) {
                            var cycleStartDate = helperSvc.getAcademicYearStartDate();
                            var accEndDate = new Date(endDateParts[2], endDateParts[0] - 1, endDateParts[1]);

                            if (accEndDate >= cycleStartDate && program.isCurrent === true) {
                                matches.push(program);
                                return;
                            }
                        }
                    }

                });
            }

            return matches;
        }
    }]);

    module.filter('notCancelledProgram', ['programReviewSvc', function (programReviewSvc) {
        return function (programs) {
            var matches = [];
            
            if (programs && programs.length > 0) {
                programs.forEach(function (program) {
                    if (!programReviewSvc.isCancelled(program.programId)) {
                        matches.push(program);
                    }
                });
            }
            return matches;
        }
    }]);
}(angular.module('organization')));