(function (module) {

    var templateRoot = "/Apps/review/templates/";

    module.directive('reviewInformation', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reviewInformation.html'

        };
    }]);

    module.directive('programsAndPevs', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programsAndPevs.html'

        };
    }]);

    module.directive('teamChairsAndEditors', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'teamChairsAndEditors.html'

        };
    }]);

    module.directive('teamVisitObservers', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'teamVisitObservers.html'

        };
    }]);

    module.directive('teamGroups', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'teamGroups.html'

        };
    }]);

    module.directive('reviewStatusTracking', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reviewStatusTracking.html'

        };
    }]);

    module.directive('changeHistory', [function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'changeHistory.html'

        };
    }]);

}(angular.module('review')));