(function (module) {

    var presidentContactsCtrl = function ($scope, $uibModal, contactRoleTypes) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'President and Delegate';
        model.type = 'president';
        model.helpText = 'An ABET accreditation review is undertaken by ABET only at the official request of the institution’s Chief Executive Officer.  This indivdual typically carries the title of President but may also be a Chancellor, Rector, or equivalent.';

        model.editPresidentContacts = function (isCreateMode, isReplaceMode, currentContact, editView, type) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/contacts/templates/contactsEdit.html',
                size: 'lg',
                controller: 'contactsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    contacts: function () {
                        return model.parent.presidentContacts;
                    },
                    roleId: function () {
                        return contactRoleTypes.PRESIDENT;
                    },
                    isCreateMode: function () {
                        return isCreateMode;
                    },
                    isReplaceMode: function () {
                        return isReplaceMode;
                    },
                    currentContact: function () {
                        return currentContact;
                    },
                    editView: function () {
                        return editView;
                    },
                    type: function () {
                        return model.type;
                    }
                }
            });
        };
    };

    module.controller('presidentContactsCtrl', presidentContactsCtrl);

}(angular.module('contacts')));