(function (module) {

    var teamGroupingsEditCtrl = function ($scope, $uibModalInstance, $stateParams, parent, team, helperSvc, alertSvc, typeConstSvc, tcAssignmentSvc, assignmentSvc, teamGroupSvc) {
        var model = $scope;

        model.parent = parent;
        model.title = (team ? 'Edit' : 'Add') + ' Team Group';
        model.data = {
            currentReviewTeam: null,
            currentTeamGroup: null,
            chosenTeamMembers: [],
            teamMembers: []
        };

        model.save = function (teamGroup) {
            //Set up the reviewteamgroupmemberdto object for each added member
            //remove all current members and then push all the selected team members to the teamgroup object
            teamGroup.reviewTeamGroupMemberDtos = convertToTeamGroupMemberDto(model.data.chosenTeamMembers)
            var promise;
            if (model.isCreateMode) {
                teamGroup.reviewTeamId = model.data.currentReviewTeam.reviewTeamId;
                promise = teamGroupSvc.createTeamGroup(teamGroup);

            } else {
                promise = teamGroupSvc.updateTeamGroup(teamGroup);
            }
            promise.then(function () {
                model.close();
            });
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.getCommissionerName = assignmentSvc.getCommissionerName;

        model.getProgramReviewName = typeConstSvc.getProgramReviewName;

        model.searchReviewTeams = function (searchText) {
            var searchTeamName = searchText.trim().toLowerCase();
            var results = model.reviewTeams.filter(function (reviewTeam) {
                var reviewTeamName = reviewTeam.sortName.toLowerCase();
                return reviewTeamName.indexOf(searchTeamName) >= 0;
            });
            return results;
        }

        model.setTeamMembers = function () {
            model.data.teamMembers.length = 0;
            Array.prototype.push.apply(model.data.teamMembers, model.data.currentReviewTeam.teamChairs);
            Array.prototype.push.apply(model.data.teamMembers, model.data.currentReviewTeam.coTeamChairs);
        }

        activate();

        function activate() {
            model.reviewTeams = teamGroupSvc.data.reviewTeams;
            // Initialize team data
            if (team) {
                // Copy review team group passed in for editing.
                model.data.currentTeamGroup = angular.copy(team);
                // Find current review object that this team group belongs to.
                model.data.currentReviewTeam = model.reviewTeams.find(function (reviewTeam) {
                    return reviewTeam.reviewTeamId === model.data.currentTeamGroup.reviewTeamId
                });
                // Populate team members with union of team chairs and co-teamchairs.
                model.setTeamMembers();
                // Set up chosen team members collection for databinding with multi-select dropdown.  
                if (model.data.currentTeamGroup.reviewTeamGroupMemberDtos) {
                    // Populate with team chairs and co-team chairs who are already in this group.
                    var existingReviewTeamMemberIds = model.data.currentTeamGroup.reviewTeamGroupMemberDtos.map(function (teamMember) {
                        return teamMember.reviewTeamMemberId;
                    });
                    var chosenTeamMembers = model.data.teamMembers.filter(function (teamMember) {
                        return existingReviewTeamMemberIds.indexOf(teamMember.reviewTeamMemberId) > -1;
                    });
                    Array.prototype.push.apply(model.data.chosenTeamMembers, chosenTeamMembers);
                }
                model.isCreateMode = false;
                model.isUpdateMode = true;
            } else {
                model.isCreateMode = true;
                model.isUpdateMode = false;
            }
        }

        function convertToTeamGroupMemberDto(teamMembers) {
            var teamGroupMemberDtos = teamMembers.map(function (teamGroupMember) {
                var teamGroupMemberDto = {
                    reviewTeamGroupMemberId: teamGroupMember.reviewTeamGroupMemberId || 0,
                    reviewTeamGroupId: model.data.currentTeamGroup.reviewTeamGroupId || 0,
                    reviewTeamMemberId: teamGroupMember.reviewTeamMemberId
                };
                return teamGroupMemberDto;
            });
            return teamGroupMemberDtos;
        }
         
    };

    module.controller('teamGroupingsEditCtrl', teamGroupingsEditCtrl);

}(angular.module('assignment')));