(function (module) {

    var changeSessionCtrl = function ($scope, alertSvc, helperSvc, pevc, searchOptions, $uibModalInstance, trainingSvc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Change Training Session';
        model.searchOptions = { trainingYear: searchOptions.trainingYear, societyId: pevc.societyId };
        model.currentPevc = pevc;
        model.currentInformation = { trainingSessionId : null}

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            var trainingPersonDto = model.currentPevc;
            trainingPersonDto.trainingSessionId = model.currentInformation.trainingSessionId;

            trainingSvc.updateTrainingSession(trainingPersonDto).then(function () {
                trainingSvc.getPEVCTrainingSearch(searchOptions);
                alertSvc.addAlertSuccess("Session succesfully changed.");
                $uibModalInstance.close();
            });
        }

        var activate = function () {
            var dataSourceArray = [];

            var getTrainingSessionData = {
                dataHolder: model,
                dataLocationName: 'trainingSessions',
                svcCallback: trainingSvc.getPEVCTrainingSessionsAdmin,
                svcCallbackArguments: [trainingSvc.data.trainingSessions[0].trainingId],
                odataResource: true
            }
            dataSourceArray.push(getTrainingSessionData);

            helperSvc.getData(dataSourceArray).then(function () {
                model.data = trainingSvc.data;
                model.currentInformation.trainingSessionId = pevc.trainingSessionId
                model.dataIsReady = true;
            });
        }();
    };

    module.controller('changeSessionCtrl', changeSessionCtrl);

}(angular.module('training')));