(function (module) {

    var travelAvailabilityCtrl = function ($scope, $uibModal, volunteerSvc, travelAvailabilitySvc, helperSvc,personSvc, eventSvc,componentHelperSvc, volunteerHelperSvc, volunteerDataAccessTypes) {
        var model = this;
        var volunteerDataAccessType = volunteerDataAccessTypes.VOLUNTEERANDADMIN;

        model.parent = $scope.$parent.model;
        model.currentYear = travelAvailabilitySvc.currentYear;
        model.title = 'Travel'; 
        model.allowEdit = volunteerHelperSvc.allowEdit(volunteerDataAccessType);
        model.formatDate = helperSvc.formatDate;

        componentHelperSvc.invokeOnRendered('travelAvailability', $scope);

        model.getAdddressDepartureCountry = function () {
            return personSvc.data.person?.personAddressDtos
                .filter((personAddress, index, self) =>
                    personAddress.countryCode != null &&
                    index === self.findIndex(t => t.countryCode === personAddress.countryCode)
                )
                .map(personAddress => ({ countryCode: personAddress.countryCode, countryName: personAddress.countryName.trim() }))
                .sort((a, b) => a.countryName.localeCompare(b.countryName)) || [];
        }

        model.editTravelAvailability = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/travelAvailabilityEdit.html',
                size: 'md',
                controller: 'travelAvailabilityEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
        model.data = travelAvailabilitySvc.data;

        eventSvc.listen(model.getAdddressDepartureCountry, 'loadPersonGeneral', $scope);
    };

    module.controller('travelAvailabilityCtrl', travelAvailabilityCtrl);

}(angular.module('volunteer')));