(function (module) {
    var surveyTemplateSvc = function (odataSvc, amsConst) {
        var factory = {};
        var key = 'surveyTemplateId';
        var apiPath = '/SurveyTemplate';

        factory.questionTypes = {
            TEXTAREA: 'TextArea',
            MULTISINGLE: 'MultipleChoiceSingleAnswer',
            MULTIMULTIPLE: 'MultipleChoiceMultipleAnswers'
        };

        factory.getSurveyTemplateById = function(templateId){
            var oSvc = odataSvc.get();
            var path = apiPath.format(templateId);

            oSvc.getSource(path, key).odata()
                .get(templateId, oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.validateSurvey = function (survey) {
            var errors = [];
            var surveyJsonGroups = survey ? survey.groups : null;
            var allFieldsRequired = true;
            var isStarted = false;
            var questionTypes = factory.questionTypes;

            angular.forEach(surveyJsonGroups, function (group) {
                if (group.questions && group.questions) {
                    angular.forEach(group.questions, function (question) {
                        var minLength = question.commentMinLength == null ? 0 : question.commentMinLength;
                        var thisAnswerIsRequired = question.isRequired;
                        var thisIsAnswered = question.userAnswer != null && (question.questionType !== questionTypes.TEXTAREA || question.userAnswer.length > minLength);
                        var thisCommentIsRequired = question.questionType !== questionTypes.TEXTAREA && question.isCommentRequired;
                        var thisIsCommented = question.comment != null && question.comment.length > minLength;
                        if (thisAnswerIsRequired && !thisIsAnswered) {
                            errors.push('Answer is required for the question "' + question.question + '"');
                        }
                        if (thisCommentIsRequired && !thisIsCommented) {
                            errors.push('Comment is required for the question "' + question.question + '"');
                        }
                        allFieldsRequired = allFieldsRequired && (thisAnswerIsRequired || thisCommentIsRequired);
                        isStarted = thisIsAnswered || thisIsCommented;
                    });
                }
            });
            // Summarize errors if all fields are required or all are missing.
            if (errors.length > 0) {
                if (allFieldsRequired) {
                    errors = ['All fields are required.'];
                } else if (!isStarted) {
                    errors = ['Questionnaire has not been started.'];
                }
            }

            return errors;
        }

        return {
            questionTypes: factory.questionTypes,
            getSurveyTemplateById: factory.getSurveyTemplateById,
            validateSurvey: factory.validateSurvey
        };

    };
    module.factory('surveyTemplateSvc', surveyTemplateSvc);

})(angular.module('common'));