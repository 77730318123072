(function (module) {

    var programAuditDetailDifferenceSvc = function (deepObjectDifferenceSvc, differenceServiceFactory, programAuditDetailSvc) {

        const comparisonTypes = deepObjectDifferenceSvc.comparisonTypes;

        const placeholderText = programAuditDetailSvc.placeholderText;

        const options = [{
            property: 'programAuditJson', comparisonType: comparisonTypes.OBJECT, options: [{
                property: 'auditDetails', comparisonType: comparisonTypes.OBJECTARRAY, key: 'statementFindingTypeId', options: [{
                    property: 'findings', comparisonType: comparisonTypes.OBJECTARRAY, key: 'key', options: [{
                        property: 'criteria', comparisonType: comparisonTypes.OBJECT, options: [{
                            property: 'text', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText
                        }]
                    }]
                }]
            }]
        }];

        return new differenceServiceFactory({ "objectDifferenceOptions": options});
    };

    module.service('programAuditDetailDifferenceSvc', programAuditDetailDifferenceSvc);

})(angular.module('programAudit'));