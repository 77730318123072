(function (module) {
    var templateRoot = '/apps/dashboard/templates/';

    module.directive('dashboardLinks', function (currentUser) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'dashboardLinks.html',
            link: function (scope) {
                var urlRoot = '/Content/images/links/';
                var links = [
                    {
                        name: 'Training',
                        info: null,
                        imageUrl: urlRoot + 'D2L-brightspace.png',
                        url: '#/sso/d2l',
                        tasks: null
                    },
                    {
                        name: 'Finances',
                        info: null,
                        imageUrl: urlRoot + 'concur.png',
                        url: '#/sso/concur',
                        tasks: ['board', 'admin', 'volunteer']
                    },
                    {
                        name: 'Reporting',
                        info: null,
                        imageUrl: urlRoot + 'AR.png',
                        url: 'https://amsapp.abet.org/#/sso/amsrpt',
                        tasks: ['admin']
                    },
                    {
                        name: 'Board ',
                        info: 'ABET Board member collaboration',
                        imageUrl: urlRoot + 'BE.jpg',
                        url: 'https://abet.boardeffect.com/',
                        tasks: ['board', 'admin']
                    }
                ];

                scope.activeLinks = [];

                links.forEach(function (link) {
                    if (link.tasks) {
                        var matchingTasksFound = false;
                        for (var i = 0; i < link.tasks.length; i++) {
                            for (var j = 0; j < currentUser.profile.userTasks.length; j++) {
                                if (link.tasks[i] == currentUser.profile.userTasks[j]) {
                                    matchingTasksFound = true;
                                    scope.activeLinks.push(link);
                                    break;
                                }
                            }
                            if (matchingTasksFound) break;
                        }
                    } else {
                        scope.activeLinks.push(link);
                    }
                });
            }
        };
    });

}(angular.module('dashboard')));