(function (module) {

    var personMediaEditCtrl = function ($uibModalInstance, personId, personMedia, mediaTypeId, personUserSvc, alertSvc, eventSvc, documentSvc, helperSvc, personMediaSvc,personMediaTypeNames) {
        var model = this;

        model.title = "Edit " + personMediaTypeNames[mediaTypeId];
        model.controllerName = 'personMediaEditCtrl';

        if (personMedia) {
            if (personMedia.validFrom)
                personMedia.validFrom = new Date(personMedia.validFrom);
            if (personMedia.validTo)
            personMedia.validTo = new Date(personMedia.validTo);
        }
        model.newMedia = personMedia ? angular.copy(personMedia) : { validFrom: null, validTo: null, personId: personId, personMediaTypeId: mediaTypeId, fileName: null };
        model.newMode = !personMedia;
        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            model.uploadMedia();
        };

        model.viewFile = function () {
            documentSvc.downloadDocument(model.newMedia.stream_id);
        };

        model.uploadMedia = function () {
            var saveMedia = function () {
                mediaToSave = {
                    personMediaId: model.newMedia.personMediaId,
                    validTo: model.newMedia.validTo,
                    validFrom: model.newMedia.validFrom,
                    personId: model.newMedia.personId,
                    fileName: model.newMedia.fileName,
                    stream_id: model.newMedia.stream_id,
                    personMediaTypeId: model.newMedia.personMediaTypeId,
                    note: model.newMedia.note
                };

                if (model.newMode) {
                    personMediaSvc.create(mediaToSave).then(function () {
                        eventSvc.broadcast('loadPersonMedia');
                        alertSvc.addAlertSuccess("File uploaded.");
                        $uibModalInstance.close();
                    });
                } else {
                    personMediaSvc.update(mediaToSave).then(function () {
                        eventSvc.broadcast('loadPersonMedia');
                        alertSvc.addAlertSuccess("File uploaded.");
                        $uibModalInstance.close();
                    });
                }
            };
            var fileToUpload = model.mediaFile;
            if (fileToUpload && !model.newMedia.fileName) {
                documentSvc.uploadFile(fileToUpload).then(function (data) {
                    model.newMedia.stream_id = helperSvc.getValue(data.data);
                    saveMedia();
                });
            } else {
                saveMedia();
            }
        };
    };

    module.controller('personMediaEditCtrl', personMediaEditCtrl);

}(angular.module('person')));