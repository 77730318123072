(function (module) {

    /* PAGE ELEM directives includes the following:
     *  - editor-tool
     *  - editor-tool-section
        - environment-badge
        - modal
        - notes
        - page-header
        - search-page
        - spinner
        - tabs
        - widget
    */

    var templateRoot = '/apps/common/templates/pageElem/';

    module.directive('editorTool', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'editorTool.html',
            transclude: {
                'globalWarning': '?editorToolGlobalWarning',
                'sidebarLeft': '?editorToolSidebarLeft',
                'sidebarRight': '?editorToolSidebarRight',
                'headerRight': '?editorToolHeaderRight',
                'headerSubtitle': '?editorToolHeaderSubtitle',
                'headerTitle': '?editorToolHeaderTitle',
                'customBtns': '?editorToolCustomBtns',
                'customBtnsFooter': '?editorToolCustomBtnsFooter',
                'body': '?editorToolBody',
                'footer': '?editorToolFooter',
                'errors': '?editorToolErrors'
            },
            scope: {
                title: '=?',
                subtitle: '=?',
                visibilityChanged: '&?',
                addFinding: '&?',
                someFunc: '&?',
                addFindingBtnText: '=?',
                openPreview: '&?',
                previewBtnText: '=?',
                hideCommentsChanges: '=?',
                backBtn: '&?',
                readOnly: '@?',
                isVisible: '='
            },
            link: function (scope, elem, attrs, ctrl, $transclude) {
                scope.isReadOnly = attrs.readOnly === 'true' ? true : false;
                scope.commentsChangesHidden = attrs.hideCommentsChanges === 'true' ? true : false;
                scope.addFinding = scope.addFinding || (() => console.log('addFinding not implemented'));
                scope.isVisible.hideAddFinding = scope.isVisible.hideAddFinding || !scope.addFinding;
                scope.isVisible.errors = $transclude.isSlotFilled('errors');
                scope.isVisible.footer = $transclude.isSlotFilled('footer');
                scope.isVisible.sidebarLeft = $transclude.isSlotFilled('sidebarLeft');
                scope.isVisible.sidebarRight = $transclude.isSlotFilled('sidebarRight');
                scope.isVisible.globalWarning = $transclude.isSlotFilled('globalWarning');

                attrs.$observe('readOnly', function (value) {
                    scope.isReadOnly = value === 'true' ? true : false;
                });

                scope.accessibility = {
                    contrast: false,
                    magnify: false
                };

                scope.toggle = function (propName) {
                    scope.isVisible[propName] = !scope.isVisible[propName];
                    if (propName === 'changeMenu' && scope.isVisible[propName]) {
                        // Hide change history (sidebar)
                        scope.isVisible.changes = false;
                    }
                    //if (scope.isVisible[propName]) {
                    //    if (propName === 'changeMenu') {
                    //        // Show change history (sidebar)
                    //        scope.isVisible.comments = false;
                    //    }

                    //    if (propName === 'comments') {
                    //        // Show comments
                    //        scope.isVisible.changeMenu = false;
                    //        scope.isVisible.changes = false;
                    //    }
                    //} else {
                    //    if (propName === 'changeMenu') {
                    //        // Hide change history (sidebar)
                    //        scope.isVisible.changes = false;
                    //    }
                    //}
                    // Do additional clean-up/formatting required by container.
                    scope.visibilityChanged && scope.visibilityChanged();
                };
            }
        };
    });

    module.directive('editorToolSection', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'editorToolSection.html',
            transclude: {
                'sectionHeader': '?editorToolSectionHeader',
                'recommendedAction': '?editorToolSectionRecommendedAction'
            },
            scope: {
                icon: '@?',
                isVisible: '=',
                abcd: '@?'
            },
            link: function (scope, elem, attrs, ctrl, $transclude) {
                scope.iconName = attrs.icon ? scope.icon : 'graduation-cap';
                scope.isVisible.sectionHeader = $transclude.isSlotFilled('sectionHeader');
                scope.isVisible.recommendedAction = $transclude.isSlotFilled('recommendedAction');
            }
        };
    });

    module.directive('editorToolSubSection', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'editorToolSubSection.html',
            transclude: {
                'body': '?editorToolSubSectionBody'
            },
            scope: {
                title: '=?',
                subtitle: '=?'
            },
            link: function (scope, elem, attrs, ctrl, $transclude) {
                scope.isVisible = { // TODO: set based on something
                    changes: false,
                    comments: false
                };
            }
        };
    });

    module.directive('environmentBadge', function (environmentSvc) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'environmentBadge.html',
            scope: {
                popoverPlacement: '@?'
            },
            link: function (scope) {
                scope.showDevBadge = environmentSvc.isDev();
                scope.showStageBadge = environmentSvc.isStage();
                scope.templateString = scope.showDevBadge ? 'Dev' : 'Stage';
                scope.templateClass = scope.showDevBadge ? 'badge-dev' : 'badge-stage';
                scope.popoverMsg = "You are viewing AMS from the {0} environment. No changes made here will affect the data in the live version of AMS.".format(scope.showDevBadge ? "development" : "staging");
            }
        };
    });

    module.directive('modal', function (personSvc, helperSvc) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'modal.html',
            transclude: {
                'header': '?modalHeader',
                'body': '?modalBody',
                'footer': '?modalFooter',
                'footerLeft': '?modalFooterLeft',
                'footerFull': '?modalFooterFull',
                'button': '?modalButton'
            },
            scope: {
                title: '<?',
                close: '&?',
                errors: '=?',
                subtitle: '<?',
                lastEditLocation: '<?',
                addBtnFunc: '&?addBtn',
                addBtnDisabled: '<?',
                addBtnHidden: '<?',
                cmdBtnFunc: '&?cmdBtn',
                cmdBtnDisabled: '<?',
                cmdBtnHidden: '<?',
                cmdBtnLabel: '@?',
                cmdBtnIcon: '@?',
                printButton: '@?',
                printFunc: '&?printFunc',
                alertsDisabled: '@?',
                hideValidationKey: '@?',
                short: '@?',
                disableKeyCondition1: '=?hideValidationKeyCondition',
                disableKeyCondition2: '=?hideValidationKeyCondition2',
                overflow: '@?',
                overflowVisible: '@?'
            },
            link: function (scope, elem, attrs, ctrl, $transclude) {
                var ctrlTitle = scope.$parent.ctrl ? scope.$parent.ctrl.title : null;
                var hasFooterLeft = $transclude.isSlotFilled('footerLeft');

                scope.title = scope.title || scope.$parent.title || ctrlTitle || "Alert";
                scope.close = scope.close || scope.$parent.close || scope.$parent.cancel;
                scope.subtitle = scope.subtitle || scope.$parent.subtitle || null;
                scope.hasName = scope.lastEditLocation ? (scope.lastEditLocation.lastUpdatedByUserId !== null) : false;
                scope.hasFooterFull = $transclude.isSlotFilled('footerFull');
                scope.hasTimestamp = scope.lastEditLocation ? (scope.lastEditLocation.lastUpdatedTimestamp !== null) : false;
                scope.showPrintBtn = attrs.printButton ? (attrs.printButton.toLowerCase() === 'true' ? true : false) : false;
                scope.alertsDisabled = attrs.alertsDisabled ? (attrs.alertsDisabled.toLowerCase() === 'true' ? true : false) : false;
                scope.isShort = attrs.short ? (attrs.short.toLowerCase() === 'true' ? true : false) : false;
                scope.overflowVisible = attrs.overflowVisible ? (attrs.overflowVisible.toLowerCase() === 'true' ? true : false) : false;

                if (attrs.hideValidationKey) {
                    if (!attrs.disableKeyCondition) {
                        scope.keyIsDisabled = attrs.hideValidationKey.toLowerCase() === 'true' ? true : false;
                    }
                }

                if (attrs.hideValidationKeyCondition && !attrs.hideValidationKeyCondition2) {
                    scope.disableKeyCondition2 = true;
                }

                scope.keyIsVisible = !(scope.keyIsDisabled || (scope.disableKeyCondition1 && scope.disableKeyCondition2) || hasFooterLeft);
                scope.showLeftFooterColumn = scope.keyIsVisible || hasFooterLeft;

                if (scope.addBtnFunc) {
                    scope.addBtnFuncWithScroll = function () {
                        helperSvc.scrollToModalTop();
                        scope.addBtnFunc();
                    }
                }

                if (scope.hasName) {
                    personSvc.getPersonNameByUserId(scope.lastEditLocation.lastUpdatedByUserId).then(function (data) {
                        scope.name = helperSvc.getValue(data);
                    })
                }

                scope.printModal = scope.printFunc || function () {
                    var contentToPrint = document.getElementById("modal-body").innerHTML;
                    var elem = document.createElement("div");
                    var isAssignmentTool = document.body.className.contains("assignment-tool");

                    // Hide normal page content then add new element with print content
                    setNodeVisibilityTo("hidden");
                    elem.className = "modal-print-contents";
                    elem.innerHTML = contentToPrint;
                    document.body.appendChild(elem);

                    // Remove assignment tool page formatting
                    if (isAssignmentTool) document.body.classList.remove("assignment-tool");

                    window.print();

                    // Restore assignment tool page formatting
                    if (isAssignmentTool) document.body.classList.add("assignment-tool");

                    // Remove element with print content and show normal page content
                    document.body.removeChild(elem);
                    setNodeVisibilityTo("visible");

                    function setNodeVisibilityTo(visibility) {
                        for (var i = 0; i < document.body.children.length; i++) {
                            var node = document.body.children[i];
                            if (node.style) {
                                node.style.visibility = visibility;
                                if (visibility == "hidden" && !node.style.display) {
                                    node.style.display = "none";
                                } else if (node.style.display == "none") {
                                    node.style.display = "";
                                }
                            }
                        }
                    }
                };
            }
        };
    });

    module.directive('notes', function ($uibModal, helperSvc, noteCategories) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'notes.html',
            scope: {
                notes: '=',
                noteType: '<',
                referenceId: '<',
                refreshName: '@',
                noteCategory: '<?',
                title: '<?',
                limitToSociety: '@?',
                readOnly: '@?',
                readOnlyMsg: '@?readOnly',
                readOnlyCondition: '<?'
            },
            link: function (scope) {
                scope.title = scope.title || 'Notes';
                scope.noteCategory = scope.noteCategory || noteCategories.INFO;
                scope.isEmpty = helperSvc.isEmpty;

                scope.editNotes = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: templateRoot + 'notesEdit.html',
                        size: 'lg',
                        controller: 'notesEditCtrl',
                        resolve: {
                            parent: function () {
                                return scope;
                            }
                        }
                    });
                };
            }
        };
    });

    module.directive('pageHeader', function (componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pageHeader.html',
            transclude: {
                'category': '?pageCategory',
                'title': '?pageTitle',
                'subtitle': '?pageSubtitle',
                'buttons': '?buttons'
            },
            scope: {
                buttonFunc1: '&?button',
                buttonText1: '@?buttonText',
                buttonIcon1: '@?buttonIcon',
                buttonState1: '@?buttonState',
                buttonDisabled1: '=?buttonDisabled',
                buttonFunc2: '&?button2',
                buttonText2: '@?',
                buttonIcon2: '@?',
                buttonState2: '@?',
                buttonDisabled2: '=?',
                buttonFunc3: '&?button3',
                buttonText3: '@?',
                buttonIcon3: '@?',
                buttonState3: '@?',
                buttonDisabled3: '=?',
                showCondition1: '<?showCondition',
                showCondition2: '<?',
                showCondition3: '<?',
                printButton: '@?'
            },
            link: function (scope, elem, attrs, ctrl, $transclude,oauth) {
                var button1IsVisible = attrs.showCondition ? scope.showCondition1 : true;
                var button2IsVisible = attrs.showCondition2 ? scope.showCondition2 : true;
                var button3IsVisible = attrs.showCondition3 ? scope.showCondition3 : true;

                scope.print = function () {
                    window.print();
                }

                //if (!oauth.canImpersonate())
                //    button1IsVisible = false;

                scope.hasButton1 = attrs.button && button1IsVisible;
                scope.hasButton2 = attrs.button2 && button2IsVisible;
                scope.hasButton3 = attrs.button3 && button3IsVisible;

                scope.hasPrintButton = attrs.printButton ? (attrs.printButton.toLowerCase() === 'true' ? true : false) : false;
                scope.hasButtons = (scope.hasButton1 || scope.hasButton2 || scope.hasPrintButton || $transclude.isSlotFilled('buttons')) ? true : false;
                scope.hasCategory = $transclude.isSlotFilled('category');
                scope.hasSubsections = $transclude.isSlotFilled('title') || $transclude.isSlotFilled('subtitle') || scope.hasCategory;

                componentHelperSvc.invokeOnRendered('pageHeader', scope);
            }
        };
    });

    module.directive('searchPage', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'searchPage.html',
            transclude: {
                'searchTitle': '?searchTitle',
                'mobileCallToActionBtn': '?mobileCallToActionBtn',
                'filters': '?searchFilters',
                'reset': '?resetBtn',
                'callToAction': '?callToAction',
                'callToActionBtn': '?callToActionBtn',
                'resultTitle': '?resultTitle',
                'resultBtn': '?resultBtn',
                'addNew': '?searchAddNew',
                'resultMessage': '?resultMessage',
                'resultSpinner': '?resultSpinner',
                'results': '?searchResults'
            }
        };
    });

    module.directive('spinner', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'spinner.html',
            scope: {
                fullPage: '@'
            },
            link: function (scope, ctrl, attrs) {
                scope.fullPage = attrs.fullPage ? attrs.fullPage.toLowerCase() : "false";
                scope.fullPage = scope.fullPage === 'true' ? true : false;
            }
        };
    });

    module.directive('tabs', function ($state, $stateParams, barSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'tabs.html',
            scope: {
                navigation: '<?nav',
                hideBottomNav: '@?'
            },
            link: function (scope) {
                scope.showBottomNav = scope.hideBottomNav ? (scope.hideBottomNav.toLowerCase() == 'true' ? false : true) : true;

                scope.loadTab = function (item, index) {
                    if (!item.isLocked) {
                        setActive(item, index);
                    }
                };

                scope.executeTab = function () {
                    barSvc.hideSpinner();
                };

                var setActive = function (item, index) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;

                    for (var i = 0; i < scope.navigation.length; i++) {
                        var currentItem = scope.navigation[i];

                        if (currentItem.isActive) currentItem.isActive = false;
                        if (currentItem === item) currentItem.isActive = true;
                    }

                    scope.currentTabUrl = item.url;

                    if (scope.showBottomNav) {
                        var tempNextItem = null;
                        var tempPreviousItem = null;

                        scope.nextIndex = null;
                        scope.nextItem = null;
                        scope.previousIndex = null;
                        scope.previousItem = null;

                        for (var i = index + 1; i < scope.navigation.length; i++) {
                            tempNextItem = scope.navigation[i];
                            if (tempNextItem && !tempNextItem.isLocked) {
                                scope.nextIndex = i;
                                scope.nextItem = scope.navigation[i];
                                break;
                            }
                        }

                        for (var j = index - 1; j >= 0; j--) {
                            tempPreviousItem = scope.navigation[j];
                            if (tempPreviousItem && !tempPreviousItem.isLocked) {
                                scope.previousIndex = j;
                                scope.previousItem = scope.navigation[j];
                                break;
                            }
                        }
                    }

                    $stateParams.view = item.slug;
                    $state.go(
                        $state.current.name,
                        $stateParams,
                        { notify: false }
                    );
                };

                var activate = function () {
                    var selectedItem = scope.navigation[0];
                    var index = 0;

                    for (var i = 0; i < scope.navigation.length; i++) {
                        var item = scope.navigation[i];

                        if (item.slug == $stateParams.view) {
                            selectedItem = item;
                            index = i;
                            break;
                        }
                    }

                    setActive(selectedItem, index);
                }();
            }
        };
    });

    module.directive('widget', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'widget.html',
            transclude: {
                'buttons': '?widgetButtons',
                'posttitle': '?widgetPosttitle'
            },
            scope: {
                dashboard: '@?',
                category: '=?',
                title: '=?',
                subtitle: '=?',
                printTitle: '=?',
                hideHeader: '@?',
                hideButtons: '@?',
                isPreviewMode: '<?',
                isComplete: '=?',
                moreArr: '=?more',
                moreCount: '@?',
                addFunc: '&?add',
                addCondition: '<?',
                editFunc: '&?edit',
                editCondition: '<?',
                detailsFunc: '&?details',
                removeFunc: '&?remove',
                deleteFunc: '&?delete',
                readOnlyMsg: '@?readOnly',
                readOnlyCondition: '<?',
                info: '@?'
            },
            link: function (scope, element, attrs, ctrl, $transclude) {
                var parentTitle = scope.$parent.model ? scope.$parent.model.title : scope.$parent.title;

                // Hide HTML title tooltip
                element[0].title = '';

                scope.title = scope.title || parentTitle || null;
                // Show add and edit buttons unless condition for showing is specified and evaluates to false.
                scope.addCondition = attrs.addCondition ? scope.addCondition : true;
                scope.editCondition = attrs.editCondition ? scope.editCondition : true;

                function updateCompleteStatus(isCompleteVal) {
                    return (attrs.isComplete) ? !isCompleteVal : false;
                }

                scope.options = {
                    header: (attrs.hideHeader === 'true') ? false : true,
                    dashboard: (attrs.dashboard === 'true') ? true : false,
                    readOnly: (attrs.readOnly) ? true : false,
                    printTitle: (attrs.printTitle) ? true : false,
                    showIncompleteFlag: updateCompleteStatus(scope.isComplete),
                    more: (attrs.more) ? true : false,
                    add: (attrs.add && scope.addCondition && !scope.isPreviewMode) ? true : false,
                    edit: (attrs.edit && scope.editCondition && !scope.isPreviewMode) ? true : false,
                    details: (attrs.details) ? true : false,
                    remove: (attrs.remove) ? true : false,
                    delete: (attrs.delete) ? true : false,
                    hasButtons: null
                };

                // Update incomplete status
                // ASSUMES TWO LAYERS OF SCOPE, UPDATE LATER
                if (scope.$parent.$parent.hasOwnProperty('isComplete')) {
                    scope.$watch('$parent.$parent.isComplete', function (val) {
                        scope.options.showIncompleteFlag = updateCompleteStatus(val);
                    });
                }

                scope.options.hasButtons = !(attrs.hideButtons == 'true') && (scope.readOnlyCondition || scope.options.more || scope.options.add || 
                                           scope.options.edit || scope.options.details || scope.options.remove || scope.options.delete ||
                                           $transclude.isSlotFilled('buttons'));

                // More Button
                var defaultLimit = attrs.moreCount || 3;
                scope.limit = defaultLimit;
                scope.expandBtn = {
                    clicked: false,
                    disabled: false,
                    getText: function () {
                        var hiddenItemsCount = 0;
                        if (scope.moreArr) {
                            if (scope.moreArr.length >= defaultLimit) {
                                hiddenItemsCount = scope.moreArr.length - defaultLimit;
                            }
                        }
                        scope.expandBtn.disabled = (hiddenItemsCount === 0);
                        return scope.expandBtn.clicked ? 'Less' : 'More (' + hiddenItemsCount + ')';
                    },
                    toggle: function () {
                        scope.expandBtn.clicked = !scope.expandBtn.clicked;
                        scope.limit = scope.expandBtn.clicked ? void defaultLimit : defaultLimit;
                        // set limit (argument for limitTo filter) to a value or undefined
                        // an undefined limit will display all records
                    }
                };
            }
        };
    });

}(angular.module('common')));