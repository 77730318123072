(function (module) {
    'use strict';

    var pevAppPDFSvc = function ($filter, $uibModal, helperSvc, surveyTemplateSvc, pevAppSvc, addressTypes, contactTypes) {

        var factory = { };

        function loadConstants(appData) {
            factory.app = appData.application.applicationJson;
            factory.person = appData.person;
            factory.questionnaire = appData.application.surveyJson ? appData.application.surveyJson.groups[0] : null;
            factory.references = appData.application.referenceJson;
            factory.societyContacts = appData.contacts;
            factory.submissionDate = $filter('date')(appData.application.submittedDate, 'MMMM d, yyyy') || null;
            factory.currentDate = $filter('date')(new Date(), 'MMMM d, yyyy');
            factory.watermarkText = appData.application.submittedDate ? null : '   UNSUBMITTED   ';
        }

        var styles = {
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            medium: {
                fontSize: 12
            },
            larger: {
                fontSize: 18
            },
            campton: {
                font: 'campton'
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonSemiBold: {
                font: 'camptonSemiBold'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            orange: {
                color: '#FF6C2C'
            },
            addLineHeight: {
                lineHeight: 1.8
            }
        };

        var getTableLayout = function (rowBorderWidth, columnBorderWidth) {
            return {
                hLineWidth: function (i, node) { return rowBorderWidth; },
                vLineWidth: function (i, node) { return columnBorderWidth; },
                paddingLeft: function (i, node) { return i === 0 ? 0 : 15; },
                paddingRight: function (i, node) { return i === node.table.body.length ? 0 : 15; },
                paddingTop: function (i, node) { return 10; },
                paddingBottom: function (i, node) { return 10; }
            }
        };

        var customNoBorderLayout = getTableLayout(0, 0);

        var customRowBorderLayout = getTableLayout(1, 0);

        var logo = {
            image: 'ABET_CMYK.png',
            width: 50,
            alignment: 'center'
        };

        function getHeader(title, hidePageBreak, subtitle) {
            return {
                pageBreak: hidePageBreak ? null : 'before',
                table: {
                    body: [
                        [
                            {
                                text: [
                                    {
                                        text: '{0}\n'.format(title).toUpperCase(),
                                        style: ['campton', 'larger'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 0, 0, 6]
                            }
                        ],
                        [
                            {
                                text: subtitle ? subtitle : 'PEV Application for {0}'.format(factory.person.name),
                                style: ['camptonLight', 'small'],
                                alignment: 'left',
                                border: [false, false, false, false],
                                margin: [0, 6, 0, 0]
                            }
                        ]
                    ]
                },
                layout: {
                    paddingLeft: function (i, node) { return 0; }
                }
            };
        }

        function getSectionHeader(title) {
            return {
                text: '\n' + title,
                style: ['medium', 'orange', 'camptonSemiBold', 'addLineHeight']
            }
        }

        function getEmptySectionText(sectionName) {
            return [
                getLabel('No {0} on record'.format(sectionName))
            ]
        }

        function getLabel(label) {
            return {
                text: label,
                style: ['camptonMedium', 'small'],
                margin: [0, 0, 0, 6]
            }
        }

        function getColumnLabel(label, width) {
            var newLabel = getLabel(label);

            newLabel.width = width ? width :  65;

            return newLabel;
        }

        function getBodyText(text, customMsg) {
            text = text ? text : (customMsg ? customMsg : "Not entered");
            return {
                text: text,
                style: ['camptonLight', 'small']
            }
        }

        function getColumnBodyText(text, width) {
            var newBodyText = getBodyText(text);

            newBodyText.width = width ? width : '*';
            newBodyText.margin = [0, 0, 0, 6];

            return newBodyText;
        }

        function formatParagraphs(str, func) {
            func = func ? func : getLabel;
            if (typeof str === 'string') {

                var paragraphs = str.match(/<p>.*?<\/p>/g);

                if (paragraphs && paragraphs.length > 0) {
                    return paragraphs.map(function (p, i) {
                        var linebreak = i > 0 ? '\n\n' : '';
                        // Strip HTML tags from paragraph
                        return func(linebreak + p.replace(/(<([^>]+)>)/ig, ""));
                    });
                }
            }
            return str;
        }

        function getLineBreak() {
            return { text: '\n\n' };
        }

        function getQuestion(q) {
            // Hide old incorrect description for PEV App Questionnaire question "Ability to Commit"
            // If the description is different than the incorrect one, display it
            var hideDesc = q.questionId == 3 && q.questionDescription.contains('want to become a PEV');
            var bodyText = getBodyText(q.questionType == surveyTemplateSvc.questionTypes.MULTISINGLE ? JSON.parse(q.userAnswer).option : q.userAnswer);

            bodyText.margin = [0, hideDesc ? 0 : 10, 0, 0];            

            var question = [
                getSectionHeader(q.question),
                bodyText
            ];

            if (!hideDesc) question.splice(1, 0, getLabel(formatParagraphs(q.questionDescription)));

            return question;
        }

        function getGroup(g) {
            return [
                getSectionHeader(g.groupTitle),
                getLabel(g.groupDescription)
            ]
        }

        function getDataFromArray(arr, sectionName, getDataFunc, showHeaderIfArrEmpty, headerTitle) {
            if (arr && arr.length > 0) {
                return getDataFunc();
            } else {
                if (showHeaderIfArrEmpty) {
                    return [
                        getSectionHeader(headerTitle),
                        getEmptySectionText(sectionName)
                    ];
                }
                return getEmptySectionText(sectionName);
            }
        }

        function getGeneralInformation() {
            return [
                getSectionHeader('General Information'),
                {
                    columns: [
                        getColumnLabel('Email'),
                        getColumnBodyText(getEmails())
                    ]
                },
                {
                    columns: [
                        getColumnLabel('Phone'),
                        getColumnBodyText(getPhones())
                    ]
                },
                {
                    columns: [
                        getColumnLabel('Address'),
                        getColumnBodyText(getAddress())
                    ]
                }
            ];

            function getEmails() {
                if (factory.person.emails.length > 0) {
                    factory.person.emails = factory.person.emails.filter(function (e) { return e.contactTypeId == contactTypes.ALLABETACTIVITIES || e.contactTypeId == contactTypes.VOLUNTEERACTIVITYONLY; });
                    factory.person.emails = $filter('orderBy')(factory.person.emails, 'contactTypeId');

                    var emailStr = '';

                    factory.person.emails.forEach(function (e, i) {
                        if (!e.isDeleted) {
                            var lineBreak = i > 0 ? '\n\n' : '';
                            emailStr += lineBreak;
                            emailStr += e.contactTypeName ? '{0}\n'.format(e.contactTypeName) : '';
                            emailStr += e.emailAddress;
                        }
                    });
                    return emailStr;
                } else if (factory.app.volunteer.emailAddress) {
                    return factory.app.volunteer.emailAddress;
                };
                return null;
            }

            function getPhones() {
                if (factory.person.phones.length > 0) {
                    factory.person.phones = factory.person.phones.filter(function (p) { return p.contactTypeId == contactTypes.ALLABETACTIVITIES || p.contactTypeId == contactTypes.VOLUNTEERACTIVITYONLY; });
                    factory.person.phones = $filter('orderBy')(factory.person.phones, 'telephoneTypeId');

                    var phoneStr = '';

                    factory.person.phones.forEach(function (p, i) {
                        if (!p.isDeleted) {
                            var lineBreak = i > 0 ? '\n\n' : '';
                            phoneStr += '{0}{1}{2}'.format(lineBreak, formatType(p.telephoneType, p.contactTypeName), helperSvc.getFormattedPhone(p.number));
                            if (p.extension) phoneStr += ' x{0}'.format(p.extension);
                        }
                    });
                    return phoneStr;
                } else if (factory.app.volunteer.phone) {
                    return helperSvc.getFormattedPhone(factory.app.volunteer.phone) + (p.extension ? ' x{0}'.format(p.extension) : '');
                };
                return null;
            }

            function getAddress() {
                if (factory.person.addresses.length > 0) {
                    factory.person.addresses = factory.person.addresses.filter(function (a) { return a.contactTypeId == contactTypes.ALLABETACTIVITIES || a.contactTypeId == contactTypes.VOLUNTEERACTIVITYONLY; });
                    factory.person.addresses = $filter('orderBy')(factory.person.addresses, 'addressTypeId');

                    return factory.person.addresses.map(function (a, i) {
                        var result = i > 0 ? '\n\n' : '';

                        result += formatType(a.addressTypeName, a.contactTypeName);

                        result += formatText(a.addressTitle, '{0}\n');
                        result += formatText(a.address1, '{0}\n');
                        result += formatText(a.address2, '{0} ');
                        result += formatText(a.address3, '{0}\n');
                        result += formatText(a.city, '{0}, ');
                        result += formatText(a.province, '{0}, ');
                        result += formatText(a.countryName, '{0}');
                        result += formatText(a.postalCode, ', {0}');

                        return result;
                    });

                    function formatText(str, val) {
                        return str ? val.format(str) : '';
                    }
                }
                return null;
            }

            function formatType(a, b) {
                var val = '';
                if (a && b) {
                    val += '{0} ({1})\n'.format(a, b);
                } else if (a || b) {
                    val += a ? a : '' + b ? b : '';
                    val += '\n';
                }
                return val;
            }
        }

        function getDemographicInformation() {
            return [
                getSectionHeader('Demographic Information'),
                {
                    columns: [
                        getColumnLabel('Gender'),
                        getColumnBodyText(factory.app.volunteer.gender)
                    ]
                },
                {
                    columns: [
                        getColumnLabel('Ethnicity'),
                        getColumnBodyText(factory.app.volunteer.ethnicityTypeName)
                    ]
                },
                {
                    columns: [
                        getColumnLabel('Birth Year'),
                        getColumnBodyText(factory.app.volunteer.yearOfBirth)
                    ]
                },
                {
                    columns: [
                        getColumnLabel('Citizenship'),
                        getColumnBodyText(factory.app.volunteer.citizenship ? factory.app.volunteer.citizenship.map(function (val) { return val.countryCode; }).join(', ') : null)
                    ]
                }
            ];
        }

        function getEmploymentInformation() {
            var colWidth = 120;
            return [
                getSectionHeader('Employment Information'),
                {
                    columns: [
                        getColumnLabel('Background', colWidth),
                        getColumnBodyText(pevAppSvc.getBackgroundStr(factory.app.volunteer))
                    ]
                },
                {
                    columns: [
                        getColumnLabel('Current Employment', colWidth),
                        getColumnBodyText(pevAppSvc.getCurrentEmploymentStr(factory.app.volunteerEmployment))
                    ]
                },
                getLineBreak(),
                getDataFromArray(factory.app.volunteerEmployment, 'employment history', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', 100],
                        body: getEmploymentTableBody(factory.app.volunteerEmployment)
                    },
                    layout: customNoBorderLayout
                }];

                function getEmploymentTableBody(arr) {
                    var result = [
                        [getLabel('Employment History'), getLabel('Title'), getLabel('Years')]
                    ];

                    arr.sort(function (a, b) {
                        return getYear(b.endYear) - getYear(a.endYear);

                        function getYear(year) {
                            return year ? year : new Date().getFullYear();
                        }
                    })

                    arr.forEach(function (row) {
                        //TODO: Get current employment info
                        result.push([
                            getBodyText(row.nonacademicName ? row.nonacademicName : (row.collegeDirectoryDto ? row.collegeDirectoryDto.collegeName : row.collegeVerificationDto.name)),
                            getBodyText(row.title),
                            getBodyText('{0} – {1}'.format(row.startYear, row.endYear ? row.endYear : 'Present'))
                        ]);
                    });
                
                    return result;
                }
            }
        }

        function getEducationInformation() {
            return [
                getSectionHeader('Education Information'),
                getDataFromArray(factory.app.volunteerEducation, 'education information', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', 'auto', 'auto'],
                        body: getEducationTableBody(factory.app.volunteerEducation)
                    },
                    layout: customNoBorderLayout
                }];

                function getEducationTableBody(arr) {
                    var result = [
                        [getLabel('College'), getLabel('Major'), getLabel('Degree'), getLabel('Year')]
                    ];

                    arr = $filter('orderBy')(arr, '-degreeYear');

                    arr.forEach(function (row) {
                        result.push([
                            getBodyText(row.collegeDirectoryDto ? row.collegeDirectoryDto.collegeName : row.collegeVerificationDto.name),
                            getBodyText(row.major),
                            getBodyText(row.degreeName),
                            getBodyText(row.degreeYear),
                        ]);
                    });

                    return result;
                }
            }
        }

        function getLicenseInformation() {
            return [
                getSectionHeader('Professional License & Certification'),
                getDataFromArray(factory.app.volunteerLicense, 'professional licenses', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto'],
                        body: getLicenseTableBody(factory.app.volunteerLicense)
                    },
                    layout: customNoBorderLayout
                }];

                function getLicenseTableBody(arr) {
                    var result = [
                        [getLabel('License'), getLabel('State/Other Name')]
                    ];

                    arr = $filter('orderBy')(arr, 'licenseCode');

                    arr.forEach(function (row) {
                        result.push([
                            getBodyText(row.licenseCode),
                            getBodyText(helperSvc.addSpace(row.licenseStateList || row.licenseOther))
                        ]);
                    });

                    return result;
                }
            }
        }

        function getLanguageInformation() {
            return [
                getSectionHeader('Language Proficiency'),
                getDataFromArray(factory.app.volunteerLanguage, 'language information', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto'],
                        body: getLanguageTableBody(factory.app.volunteerLanguage)
                    },
                    layout: customNoBorderLayout
                }];

                function getLanguageTableBody(arr) {
                    var result = [
                        [getLabel('Language'), getLabel('Reading'), getLabel('Speaking')]
                    ];

                    arr = $filter('orderBy')(arr, 'languageName');

                    arr.forEach(function (row) {
                        result.push([
                            getBodyText(row.languageName),
                            getBodyText(row.readingLevelName),
                            getBodyText(row.speakingLevelName)
                        ]);
                    });

                    return result;
                }
            }
        }
        
        function getOnlineExperience() {
            var body = getBodyText(factory.app.volunteerOnlineEducation ? factory.app.volunteerOnlineEducation.experienceText : null);
            
            return [
                getSectionHeader('Online Education Experience'),
                factory.app.volunteerOnlineEducation ? body : getEmptySectionText('online education information')
            ]
        }

        function getProgramAreas() {
            return [
                getSectionHeader('Program Areas'),
                getDataFromArray(factory.app.volunteerDiscipline.disciplines, 'program areas', getData)
            ];

            function getData() {
                return [{
                    table: {
                        widths: ['*'],
                        body: getProgramAreaTableBody(factory.app.volunteerDiscipline.disciplines)
                    },
                    layout: {
                        hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 0 : 0.5 },
                        vLineWidth: function (i, node) { return 0; },
                        paddingLeft: function (i, node) { return 0; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return i === 0 ? 0 : 10; },
                        paddingBottom: function (i, node) { return i === node.table.body.length ? 0 : 10; }
                    }
                }];

                function getProgramAreaTableBody(arr) {
                    var result = [];

                    arr = $filter('unique')(arr, 'disciplineId');
                    arr = $filter('orderBy')(arr, 'disciplineName');
                    arr.forEach(function (item) {
                        result.push([
                            {
                                table: {
                                    widths: ['auto', '*'],
                                    body: [
                                        [{ text: getLabel('Program Area'), noWrap: true }, getBodyText(item.disciplineName)],
                                        [getLabel('Society'), getBodyText(pevAppSvc.getSocietyNamesForDiscipline(item, factory.app))],
                                        [getLabel('Commission'), getBodyText(item.commissionName)],
                                        [getLabel('Qualification'), getBodyText(item.qualificationComment)],
                                        [getLabel('Requirements'), getBodyText(item.societyGeneralComment)],
                                        [getLabel('Status'), getBodyText(item.volunteerDisciplineStatusName)]
                                    ]
                                },
                                layout: 'noBorders'
                            }
                        ]);
                    });

                    return result;
                }
            }
        }

        function getSpecializations() {
            return [
                getSectionHeader('Specializations (Optional)'),
                getDataFromArray(factory.app.volunteerSocietySpecialization, 'specializations', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto'],
                        body: getSpecializationTableBody(factory.app.volunteerSocietySpecialization)
                    },
                    layout: customNoBorderLayout
                }];

                function getSpecializationTableBody(arr) {
                    var result = [
                        [getLabel('Society'), getLabel('Specialization')]
                    ];

                    arr = $filter('orderBy')(arr, 'societyAbbreviatedName');

                    arr.forEach(function (row) {
                        result.push([
                            getBodyText(row.societyAbbreviatedName),
                            getBodyText(row.specializationName || row.societySpecializationOther)
                        ]);
                    });

                    return result;
                }
            }
        }

        function getSocietyMembership() {
            return [
                getSectionHeader('Society Membership Information'),
                getDataFromArray(factory.app.volunteerSocietyMembership, 'society memberships', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto', 'auto'],
                        body: getMembershipTableBody(factory.app.volunteerSocietyMembership)
                    },
                    layout: customNoBorderLayout
                }];

                function getMembershipTableBody(arr) {
                    var result = [
                        [getLabel('Society'), getLabel('Member#'), getLabel('Service'), getLabel('Note')]
                    ];

                    arr = $filter('orderBy')(arr, 'societyAbbreviatedName');

                    arr.forEach(function (row) {
                        result.push([
                            getBodyText(row.societyAbbreviatedName),
                            getBodyText(row.membershipNumber),
                            getBodyText(row.societyService),
                            getBodyText(row.note)
                        ]);
                    });

                    return result;
                }
            }
        }

        function getQuestionnaire() {
            var showHeaderIfArrEmpty = true;
            return [
                getDataFromArray(factory.questionnaire ? factory.questionnaire.questions : [], 'questionnaire answers', getQuestions, showHeaderIfArrEmpty, 'Questionnaire')
            ];

            function getQuestions() {
                var questionnaire = [];
                factory.questionnaire.questions.forEach(function (q) {
                    questionnaire.push(getQuestion(q));
                });

                return questionnaire;
            }
        }

        function getReferences() {
            var showHeaderIfArrEmpty = true;
            var result = [
                getDataFromArray(factory.references, 'references', getEachReference, showHeaderIfArrEmpty, 'References')
            ];

            if (factory.references.length < 1) result.splice(0, 0, getHeader('References'));

            return result;

            function getEachReference() {
                var references = [];
                var hidePageBreak = false;

                factory.references.forEach(function (r) {
                    var referenceDetails = '';
                    if (!r.personName) {
                        referenceDetails = 'Incomplete referencer details on file';
                    } else {
                        // TODO: check all details
                        referenceDetails = 'Given by {0} ({1})\n{2} at {3}'.format(r.personName, r.email, r.title, r.employer);

                    }
                    references.push([
                        getHeader('Reference', hidePageBreak, referenceDetails),
                        getSurvey()
                    ]);

                    function getSurvey() {
                        if (r.surveyQuestion) {
                            var questions = [];

                            r.surveyQuestion.groups.forEach(function (group) {
                                if (group.groupTitle) questions.push(getGroup(group));

                                group.questions.forEach(function (q) {
                                    questions.push(getQuestion(q));
                                });
                            });
                            return questions;
                        } else {
                            return [
                                getSectionHeader('Reference'),
                                getLabel('Reference survey not completed')
                            ];
                        }
                    }
                });

                return references;
            }
        }

        function getSocietyContacts() {
            return [
                getSectionHeader('Society Contacts'),
                getDataFromArray(factory.societyContacts, 'society contacts', getData)
            ];

            function getData() {
                return [{
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto', 'auto'],
                        body: getContactsTableBody(factory.societyContacts)
                    },
                    layout: customNoBorderLayout
                }];

                function getContactsTableBody(arr) {
                    var result = [
                        [getLabel('Society'), getLabel('Name'), getLabel('Email'), getLabel('Phone')]
                    ];

                    arr = $filter('orderBy')(arr, ['abbreviatedName', 'firstName']);

                    arr.forEach(function (row) {
                        result.push([
                            getBodyText(row.abbreviatedName),
                            getBodyText('{0} {1}'.format(row.firstName, row.lastName)),
                            getBodyText(row.emailAddress),
                            getBodyText(row.number)
                        ]);
                    });

                    return result;
                }
            }
        }

        factory.generateDoc = function (currentPevAppData) {
            // currentPevAppData should be stored in pevAppSvc.data
            // Call pevAppSvc.getAllDataForApplication to populate this data

            var hidePageBreak = true;

            loadConstants(currentPevAppData);

            var content = [[
                getHeader('Personal Information', hidePageBreak),
                getGeneralInformation(),
                getDemographicInformation(),
                getEmploymentInformation(),
                getEducationInformation(),
                getLicenseInformation(),
                getLanguageInformation(),
                getOnlineExperience(),
                getHeader('Societies'),
                getProgramAreas(),
                getSocietyMembership(),
                getSpecializations(),
                getHeader('ABET Competency Questionnaire'),
                getQuestionnaire(),
                getReferences(),
                getHeader('Society Contacts'),
                getSocietyContacts()
            ]];

            return {
                info: {
                    title: 'PEV Application / {0}'.format(factory.person.name)
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 40, 70, 50],
                watermark: {
                    text: factory.watermarkText || '',
                    opacity: 0.06,
                    font: 'calibri'
                },
                footer: function () {
                    return {
                        table: {
                            widths: [260, 50, 260],
                            heights: [5, '*'],
                            margin: [0, 40, 0, 0],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: ''
                                    },
                                    {
                                        border: [false, true, false, false],
                                        text: ''
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: ''
                                    }
                                ],
                                [
                                    {
                                        colSpan: 3,
                                        text: '415 North Charles St, Baltimore, MD 21201, USA',
                                        alignment: 'center',
                                        style: ['smaller', 'camptonLight']
                                    }
                                ]
                            ]
                        },
                        layout: {
                            defaultBorder: false,
                            hLineColor: function (i, node) { return '#FF6C2C'; }
                        }
                    };
                }
            };
        };

        factory.openApplicationModal = function (volunteerApplicationId) {
            pevAppSvc.getAllDataForApplication(volunteerApplicationId).then(function (data) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/pevApp/templates/modals/viewPevAppPreview.html',
                    size: 'xl',
                    controller: 'viewPevAppPreviewCtrl',
                    resolve: {
                        currentPevAppData: function () { return data; }
                    }
                });
            });
        };

        return {
            generateDoc: factory.generateDoc,
            openApplicationModal: factory.openApplicationModal
        };
    };

    module.factory('pevAppPDFSvc', pevAppPDFSvc);

})(angular.module('pevApp'));