(function (module) {

    var volunteerActivitiesCtrl = function ($scope, $state, $stateParams, eventSvc, helperSvc, volunteerSvc) {
        var model = this;
        var parent = $scope.$parent.model;
        var personId = $stateParams.personId;
        var volunteerId = parent.volunteerId;

        model.title = "Volunteer Activities";
        model.activities = [];
        model.getYearSpan = helperSvc.getYearSpan;
        model.isVolunteer = false;

        model.openVolunteerPage = function () {
            $state.go('volunteer', {
                personId: personId,
                volunteerId: volunteerId
            });
        };

        activate();

        function activate() {
            model.activities = parent.activities;
        }
    };

    module.controller('volunteerActivitiesCtrl', volunteerActivitiesCtrl);

}(angular.module('person')));