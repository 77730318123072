(function (module) {

    var rfrSummaryCtrl = function (rfrSvc) {
        var model = this;

        model.rfr = rfrSvc.data.rfr;
    };

    module.controller('rfrSummaryCtrl', rfrSummaryCtrl);

}(angular.module('rfr')));