(function (module) {

    /* POPOVER directives includes the following:
        - button-popover
        - help-popover
        - note-popover
        - read-only
        - tooltip
    */

    var templateRoot = '/apps/common/templates/popover/';

    module.directive('buttonPopover', function () {
        return {
            restrict: 'E',
            template: '<div class="help-popover">'
                    + '<button uib-popover-template="\'popoverTemplate.html\'" popover-placement="{{placement}}" popover-trigger="\'mouseenter\'" type="button" class="btn btn-sm btn-default">{{label}}</button>'
                    + '</div>'
                    + '<script id="popoverTemplate.html" type="text/ng-template"><div class="help-popover-message">{{message}}</div></script>',
            scope: {
                label: '@',
                message: '@',
                placement: '@?'
            },
            link: function (scope) {
                scope.placement = scope.placement || 'top';
            }
        };
    });

    module.directive('helpPopover', function () {
        return {
            restrict: 'E',
            template: '<tooltip title="{{title}}" placement="{{placement}}" icon="{{icon}}" color="{{color}}" message="{{message}}"></tooltip>',
            scope: {
                message: '@',
                title: '@?',
                icon: '@?',
                color: '@?',
                placement: '@?',
                trigger: '@?' // Not used in template, left here in so that places where it might be used don't generate errors
            },                // To-do: search for its uses and remove so that this param can be deleted
            link: function (scope) {
                scope.icon = scope.icon || 'fa-question-circle';
                scope.color = scope.color || 'auto';
                scope.placement = scope.placement || 'left';
            }
        };
    });

    module.directive('notePopover', function () {
        return {
            restrict: 'E',
            template: '<div class="help-popover">'
                    + '<button uib-popover-template="\'note.html\'" popover-title="{{title}}" popover-placement="{{placement}}" popover-trigger="\'click\'" type="button" class="btn btn-sm btn-nostyle" ng-class="{\'has-popover\' : model}"><i class="fa fa-file-text-o"></i></button>'
                    + '</div>'
                    + '<script id="note.html" type="text/ng-template"><div class="popover-wide">'
                    + '<textarea ng-model="$parent.model" rows="2" class="form-control input-sm" placeholder="Type a note..." ng-maxlength="200" />'
                    + '</div></script>',
            scope: {
                model: '=ngModel',
                placement: '@?',
                title: '@?'
            },
            link: function (scope) {
                scope.placement = scope.placement || 'left';
            }
        };
    });

    module.directive('readOnly', function () {
        return {
            restrict: 'E',
            template: '<div class="help-popover">'
                    + '<button uib-popover-template="\'popoverTemplate.html\'" popover-placement="{{placement}}" popover-trigger="\'mouseenter\'" type="button" class="btn btn-sm btn-default">Read-only</button>'
                    + '</div>'
                    + '<script id="popoverTemplate.html" type="text/ng-template"><div class="help-popover-message">{{message}}</div></script>',
            scope: {
                message: '@',
                placement: '@?'
            },
            link: function (scope) {
                scope.placement = scope.placement || 'top';
            }
        };
    });

    module.directive('tooltip', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'tooltip.html',
            scope: {
                message: '@?',
                messageList: '<?',
                title: '@?',
                label: '@?',
                icon: '@?',
                color: '@?',
                placement: '@?',
                warning: '@?',
                trigger: '@?' // Not used in template, left here in so that places where it might be used don't generate errors
            },                // To-do: search for its uses and remove so that this param can be deleted
            link: function (scope, elem, attrs) {
                scope.placement = scope.placement || 'top';
            }
        };
    });
    
}(angular.module('common')));