(function (module) {

    var statementSvc = function (odataSvc, helperSvc, alertSvc, teamMemberTypeIds, statementTypeIds, statementTypeNamesById, teamMemberTypeNames, reviewTypeIds, statementStatuses, dueResponsePropNamesByTypeId) {
       var factory = {};
       var findingTypeApi = '/GetStatementFindingTypes';
       var findingTypeKey = 'statementFindingTypeId'


        factory.getStatementFindingTypes = function () {
            var oSvc = odataSvc.get();
            oSvc.getSource(findingTypeApi).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }
       
       factory.getNextDueDateText = function (reviewTeam, isStatement) {
           //we need to check if they are of the teamMemberType
           if (reviewTeam && reviewTeam.statementTypeId === statementTypeIds.DRAFT) {
               var visitEndDate = reviewTeam.visitEndDate;
               //IR clock starts on August 15.
               var currentDaysDifference = (reviewTeam.reviewTypeId === reviewTypeIds.INTERIMREPORT || reviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT || reviewTeam.reviewType === reviewTypeIds.INTERIMREPORT) ? helperSvc.getDateDiff(new Date(reviewTeam.reviewYear-1, 8, 15), new Date(), "days") : helperSvc.getDateDiff(visitEndDate, new Date(), "days");

                var teamMember;
                if (!isStatement && reviewTeam.statementTeamMemberTypeId){
                    teamMember = teamMemberTypeIds[reviewTeam.statementTeamMemberTypeId];//need to fix the caps here db side
                }
                else if (isStatement && reviewTeam.submittedToTeamMemberTypeId){
                    teamMember = teamMemberTypeIds[reviewTeam.submittedToTeamMemberTypeId];
                }
                else if (isStatement && reviewTeam.teamMemberTypeId){
                    teamMember = teamMemberTypeIds[reviewTeam.teamMemberTypeId];
                }
                else{
                    teamMember = teamMemberTypeIds[teamMemberTypeNames.TEAMCHAIR];
                }
                   
                responseStartOnTime = teamMember + " has ";
                responseStartOverDue = teamMember + " is "
              
               var daysAway = 0;

               if (reviewTeam.statementStatusId === statementStatuses.WAITINGFORTCSUBMIT || reviewTeam.statementStatusId === null) {
                   if (currentDaysDifference <= 30 && currentDaysDifference >= 0) {
                       daysAway = 30 - currentDaysDifference;
                       return responseStartOnTime + daysAway + " days to submit"
                   }
                   else if (currentDaysDifference > 30 && visitEndDate !== null) {
                       daysAway = currentDaysDifference - 30;
                       return responseStartOverDue + daysAway + " days overdue";
                   } else {
                       return (reviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT || reviewTeam.reviewType === reviewTypeIds.INTERIMREPORT) ? 'Interim Report timelines begin September 15 for Team Chairs' :'Visit Not Ended'
                   }
               }
               else if (reviewTeam.statementStatusId === statementStatuses.WAITINGFORED1SUBMIT) {
                   if (currentDaysDifference <= 45 && currentDaysDifference >= 0) {
                       daysAway = 45 - currentDaysDifference;
                       return responseStartOnTime + daysAway + " days to submit"
                   }
                   else if (currentDaysDifference > 45) {
                       daysAway = currentDaysDifference - 45;
                       return responseStartOverDue + daysAway + " days overdue";
                   }
                   else {
                        return (reviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT || reviewTeam.reviewType === reviewTypeIds.INTERIMREPORT) ? 'Interim Report timelines begin September 15 for Team Chairs' :'Visit Not Ended'
                   }

               } else if (reviewTeam.statementStatusId === statementStatuses.WAITINGFORED2SUBMIT) {
                   if (currentDaysDifference <= 60 && currentDaysDifference >= 0) {
                       daysAway = 60 - currentDaysDifference;
                       return responseStartOnTime + daysAway + " days to submit"
                   }
                   else if (currentDaysDifference > 60) {
                       daysAway = currentDaysDifference - 60;
                       return responseStartOverDue + daysAway + " days overdue";
                   }else{
                       return (reviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT || reviewTeam.reviewType === reviewTypeIds.INTERIMREPORT) ? 'Interim Report timelines begin September 15 for Team Chairs' :'Visit Not Ended'
                    }


               } else if (reviewTeam.statementStatusId === statementStatuses.WAITINGFORADJSUBMIT) {
                   if (currentDaysDifference <= 67 && currentDaysDifference >= 0) {
                       daysAway = 67 - currentDaysDifference;
                       return responseStartOnTime + daysAway + " days to submit"
                   }
                   else if (currentDaysDifference > 67) {
                       daysAway = currentDaysDifference - 67;
                       return responseStartOverDue + daysAway + " days overdue";
                   }
                   else{
                       return (reviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT || reviewTeam.reviewType === reviewTypeIds.INTERIMREPORT) ? 'Interim Report timelines begin September 15 for Team Chairs' :'Visit Not Ended'
                   }
               } else if (reviewTeam.statementStatusId === statementStatuses.SENTTOINSTITUTION) {
                    var nextStatementTypeId = reviewTeam.statementTypeId <= statementTypeIds.POSTTHIRTYDAY ? reviewTeam.statementTypeId + 1 : statementTypeIds.POSTTHIRTYDAY.COMMISSIONEDITING; //need to check business logic here
                    return 'Waiting on ' + teamMemberTypeIds[reviewTeam.submittedToTeamMemberTypeId] + ' to start the ' + statementTypeNamesById[nextStatementTypeId] + ' Statement.';
               }
           }
           return null
        }

        factory.organizeResponseTypes = function(responses){
            var sortedResponses = [];
            var keys = Object.keys(responses);
            var responseOrderValues = Object.values(dueResponsePropNamesByTypeId);
    
            for(var i = 0; i < responseOrderValues.length; i++){
                var value = responseOrderValues[i];
                for(var j=0; j < keys.length; j++){
                    var key = keys[j];
                    if(value === key){
                        sortedResponses.push(responses[key]);
                    }
                }
            }
            return sortedResponses;
        }

        // Deprecated now that we are generated dean draft letters dynamically 
        factory.openLetterToTheDean = function (commissionName) {
            var pdfPath = '/Content/files/Letter_to_the_Dean-{0}.pdf'.format(commissionName);
            alertSvc.openPDFModal(pdfPath, 'Letter to the Dean');
        };

        return {
            getStatementFindingTypes: factory.getStatementFindingTypes,
            getNextDueDateText: factory.getNextDueDateText,
            organizeResponseTypes: factory.organizeResponseTypes
        };
    };

    module.factory('statementSvc', statementSvc);

})(angular.module('statement'));