(function (module) {

    var pevEditCtrl = function ($scope, $uibModalInstance, parent, review, personSvc, helperSvc, reviewTeamMemberSvc, eventSvc, alertSvc) {
        var model = $scope;

        model.title = 'Assign PEVs';
        model.parent = parent;
        model.isInsertMode = false;
        model.isEdit = false;
        model.isAdd = false;
        model.reviewTeamId = review.reviewTeamId;
        reset();

        eventSvc.listen(loadPevData, 'refreshPevData');

        model.programName = review.programDto.programDetailDto.programName;
        model.disciplineTypeOptions = review.programReviewDisciplineDtos;
        model.pevTypeOptions = parent.pevTypeOptions;
        model.teamMemberStatuses = parent.teamMemberStatuses;

        if (review) {
            var programReviewDisciplines = review.programReviewDisciplineDtos;
            var pevsRequired = 0;

            if (programReviewDisciplines) {
                for (var i = 0; i < programReviewDisciplines.length; i++) {
                    pevsRequired += programReviewDisciplines[i].pevsRequired;
                }
            }
            model.pevsRequired = pevsRequired;
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.deletePev = function (teamMember) {
            reviewTeamMemberSvc.delete(teamMember.reviewTeamMemberId).then(function () {
                broadcastRefreshMsg();
                alertSvc.addAlertSuccess("PEV successfully deleted.");
            });
        };

        model.addPev = function () {
            model.isInsertMode = true;
            model.isAdd = true;
            model.isEdit = false;
        };

        model.cancel = function () {
            reset();
            model.isInsertMode = false;
        };

        model.save = function (teamMember) {
            if (model.isAdd) {
                reviewTeamMemberSvc.create(teamMember).then(function () {
                    reset();
                    model.isInsertMode = false;
                    broadcastRefreshMsg();
                    alertSvc.addAlertSuccess("PEV successfully created.");
                });
            }
            else if (model.isEdit) {
                reviewTeamMemberSvc.update(teamMember).then(function () {
                    reset();
                    model.isInsertMode = false;
                    broadcastRefreshMsg();
                    alertSvc.addAlertSuccess("PEV successfully updated.");
                });
            }
        };

        model.selectTeamMember = function (teamMember) {
            var currentTeamMember = angular.copy(teamMember);
            var useUndefined = true;

            currentTeamMember.startDate = helperSvc.formatDate(currentTeamMember.startDate, useUndefined);
            currentTeamMember.endDate = helperSvc.formatDate(currentTeamMember.endDate, useUndefined);
            model.teamMemberName = teamMember.personName;

            model.currentTeamMember = currentTeamMember;
            model.isInsertMode = true;
            model.isAdd = false;
            model.isEdit = true;
        };

        model.formatDate = helperSvc.formatDate;

        function reset() {
            model.currentTeamMember = { reviewTeamId: model.reviewTeamId };
            model.teamMemberName = undefined;
        };

        function loadPevData() {
            reviewTeamMemberSvc.getPevsByReviewTeamId(model.reviewTeamId).then(function (data) {
                eventSvc.broadcast('refreshPevData');
                eventSvc.broadcast('refreshPrograms');
                model.pevs = getValidPevs(review, data);
            });
        };

        //look through searchData for pevs that match the programReviewDisciplineId of model.currentReview
        function getValidPevs(currentReview, searchData) {
            var matchingProgramReviewDisciplineIds = [];        //array to hold valid programReviewDisciplineIds
            var allPevs = helperSvc.getResults(searchData);     //array to search for matching pevs
            var matchingPevs = [];                              //array to hold pevs with matching programReviewDisciplineIds

            //find valid programReviewDisciplineId values to use as a filter on pevs
            for (var i = 0; i < currentReview.programReviewDisciplineDtos.length; i++) {
                var currentDto = currentReview.programReviewDisciplineDtos[i];
                matchingProgramReviewDisciplineIds.push(currentDto.programReviewDisciplineId.toString());
            }

            //if there are pevs, try and find matches
            if (allPevs) {
                //merge search criteria for simplified searching
                var pevFilter = matchingProgramReviewDisciplineIds.join(' ');

                //find all pevs with correct programDisciplineId values
                for (var i = 0; i < allPevs.length; i++) {
                    if (helperSvc.strContains(pevFilter, allPevs[i].programReviewDisciplineId.toString())) {
                        matchingPevs.push(allPevs[i]);
                    }
                }
            }
            return matchingPevs;
        }

        function broadcastRefreshMsg() {
            eventSvc.broadcast('refreshPevData');
        };
    };

    module.controller('pevEditCtrl', pevEditCtrl);

}(angular.module('review')));