(function (module) {

    var rfrOnsiteRestrictionEditCtrl = function (rfr, rfrCommissionIndex, $scope, $uibModalInstance, codeSvc, helperSvc, rfrSvc, alertSvc, rfrProcessStatuses, $stateParams, messageSvc, messageTemplateTypes, currentUser, personEmailSvc, membershipSvc, contactRoleTypes, $q, contactTypes) {
        var model = $scope;
        model.title = 'Edit Onsite Visit Restriction';
        model.organizationId = $stateParams.organizationId;
        model.messageData = {};
        model.rfr = angular.copy(rfr);
        model.rfrCommissionIndex = rfrCommissionIndex;
        model.rfr.rfrCommissionDtos = model.rfr.rfrCommissionDtos.filter(t => t.reviewTypeCode != 'IR');
        
        model.cancel = function () {
            $uibModalInstance.close();
        }

        model.save = function () {
            rfrSvc.update(model.rfr).then(function () {
                showAlertAndCloseModal()
            });
        }

        codeSvc.getOnsiteVisitRestrictionStatuses().then(function (data) {
            model.restrictionStatusList = helperSvc.getResults(data);
        });

        function showAlertAndCloseModal() {
            alertSvc.addAlertSuccess("RFR Onsite Restriction successfully updated.");
            $uibModalInstance.close();
        }
    };

    module.controller('rfrOnsiteRestrictionEditCtrl', rfrOnsiteRestrictionEditCtrl);

}(angular.module('rfr')));