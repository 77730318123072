(function (module) {

    var addMentorCtrl = function ($scope, $uibModalInstance, alertSvc, trainingSvc, trainingTypeIds, societyOptions, searchOptions, oauth, codeSvc, helperSvc, contactRoleTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Add Mentor';
        model.currentInformation = { mySocieties: []};
        model.isAdmin = oauth.isAdmin();

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.addMentor = function () {
            if(alreadyMentorForThisTraining())
                return alertSvc.addModalAlertWarning("Mentor already assigned to this training");

            var mentorDto = {
                trainingId: model.currentInformation.trainingId,
                personId: model.currentInformation.mentor.personId,
                societyId: model.currentInformation.currentSociety.codeKey
            }

            trainingSvc.addTrainingMentor(mentorDto).then(function () {
                trainingSvc.getTrainingMentors(searchOptions);
                alertSvc.addModalAlertSuccess("Mentor succesfully added.");
                $uibModalInstance.close();
            })

        }
    
        function alreadyMentorForThisTraining(){
            var filteredTrainings = helperSvc.getFilteredArray(trainingSvc.data.trainingMentorList, 'trainingId', model.currentInformation.trainingId, true);

            return helperSvc.getFirstMatch(filteredTrainings, 'personId', model.currentInformation.mentor.personId) !== null;
        }

        var activate = function () {
            model.currentInformation.currentSociety = null;
            model.currentInformation.mentor = null;
            model.currentInformation.trainingId = trainingSvc.data.trainingSessions[0].trainingId;
            model.societyOptions = societyOptions;

            var dataArray = [];
            var societyOptionsDataArray = {
                dataHolder: model,
                svcCallback: [codeSvc.getMemberSocieties],
                dataLocationName: 'societyOptions',
                helperCallback: helperSvc.getValue
            }
            dataArray.push(societyOptionsDataArray);

            helperSvc.getData(dataArray).then(function () {
                if (model.isAdmin) {
                    model.currentInformation.mySocietyOptions = model.societyOptions
                }               
                if (!model.isAdmin) {
                    model.mySocieties = trainingSvc.getMyTrainingRoles(); 

                    model.currentInformation.mySocietyOptions = helperSvc.getFilteredArray(model.societyOptions, 'codeKey', model.mySocieties, true);
                     if(model.currentInformation.mySocietyOptions.length === 1){
                         model.currentInformation.currentSociety = model.currentInformation.mySocietyOptions[0];               
                    }
       
                }   
                model.dataIsReady = false;   
            });
        }();
    };

    module.controller('addMentorCtrl', addMentorCtrl);

}(angular.module('training')));