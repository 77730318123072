(function (module) {

    var contactsCtrl = function ($stateParams, $state, $scope, getOrganizationUserSvc, barSvc, helperSvc, currentOrganization, currentUser, membershipSvc, contactRoleTypes) {
        var model = this;
        var profile = currentUser.profile;
        var getCurrentOrganizations = true;
        var getFilteredArray = helperSvc.getFilteredArray;
        var useIncludeMode = true;
        var rolesAllowed = [
            contactRoleTypes.PRESIDENT,
            contactRoleTypes.PROVOST,
            contactRoleTypes.DELEGATEFORPRESIDENT,
            contactRoleTypes.PRIMARY,
            contactRoleTypes.SECONDARY,
            contactRoleTypes.DELEGATEFORPRIMARY,
            contactRoleTypes.PROGRAM,
            contactRoleTypes.SECONDARYPROGRAM,
            contactRoleTypes.EDUCATIONLIASON,
            contactRoleTypes.MEMBERPRESIDENT,
            contactRoleTypes.TRAININGMANAGER,
            contactRoleTypes.STATEBOARDLIAISON
        ];

        model.title = 'Manage Contacts';
        model.multiOrgTitle = 'Organizations';
        model.isEmpty = helperSvc.isEmpty;
        model.isDataReady = false;

        barSvc.showBar();
        
        model.openSelectedOrganization = function (org) {
            currentOrganization.profile.organizationId = org.organizationId;
            currentOrganization.profile.organizationName = org.organizationName;
            currentOrganization.profile.organizationTypeId = org.organizationTypeId;
            currentOrganization.profile.organizationTypeName = org.organizationTypeName;
            currentOrganization.save();

            $state.go('contacts.detail', { organizationId: org.organizationId });
        };

        var organizationsDataSource = {
            dataHolder: model,
            dataLocationName: 'organizations',
            svcCallback: getOrganizationUserSvc.getOrganizationUserByUserId,
            svcCallbackArguments: null,
            helperCallback: helperSvc.getValue
        };

        activate();

        function activate() {
            var dataSourceArray = [organizationsDataSource];
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                var isRedirecting = false;
                if (model.organizations) {

                    var filteredOrganizations = getFilteredArray(model.organizations, 'roleId', rolesAllowed, useIncludeMode);
                    model.organizations = helperSvc.uniqueArrayByKey(filteredOrganizations, 'organizationId');

                    if (model.organizations.length === 1) {
                        isRedirecting = true;
                        model.openSelectedOrganization(model.organizations[0]);
                        // barSvc.hideBar() takes place after redirect upon data load in manageContactsCtrl
                    }
                };

                if (!isRedirecting) {
                    barSvc.hideBar();
                    model.isDataReady = true;
                }
            });
        }
    };

    module.controller('contactsCtrl', contactsCtrl);

}(angular.module('contacts')));