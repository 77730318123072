(function (module) {

    var rfrPreviewCtrl = function ($scope, $uibModal, helperSvc, rfrSvc) {
        var model = this;

        model.rfr = null;
        model.parent = $scope.$parent.model;
        model.openSubmissionInstructions = rfrSvc.openSubmissionInstructions;

        model.print = function () {
            window.print();
        };

        activate();

        function activate() {
            model.rfr = rfrSvc.data.rfr;
        }
    };

    module.controller('rfrPreviewCtrl', rfrPreviewCtrl);

}(angular.module('rfr')));