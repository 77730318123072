(function (module) {
    var nominationSocietyCtrl = function ($scope, $state, $stateParams, $uibModal, $q, helperSvc, alertSvc, nominationSvc) {
        var model = this;

        model.isDataReady = false;
        model.isCommittee = false;
        model.currentSociety = null;

        model.exportEligibleCommissioners = function () {
            model.isExporting = true;

            var defer = $q.defer();

            nominationSvc.exportEligibleCommissioners(nominationSvc.data.currentSocietyId).then(function (data) {
                defer.resolve(data);
                model.isExporting = false;
            });

            return defer.promise;
        }

        model.csvHeaders = nominationSvc.csvHeaders;

        model.csvColumns = nominationSvc.csvColumns;

        var activate = function () {
            // Do something... or not.  I'm not the boss of you.
        }();
    };
    module.controller('nominationSocietyCtrl', nominationSocietyCtrl);

}(angular.module('nomination')));