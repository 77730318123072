(function (module) {
    var statementAdminCtrl = function (statementStorageSvc, alertSvc, offlineSvc) {

        var model = this;

        model.isDataReady = false;


        activate();

        function activate(noSync) {
            // Most admin won't have any data to sync but we should sync anyway just in case an adjunct is using this page
            if (!offlineSvc.isAppOffline() && !noSync) {
                statementStorageSvc.syncOfflineData().catch(function (error) {
                    console.log('Sync Offline Data Error', error);
                });
            }
        }
    };
    module.controller('statementAdminCtrl', statementAdminCtrl);

}(angular.module('statement')));