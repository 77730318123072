(function (module) {

    var rfrPreviewRevisionCtrl = function ($scope, $uibModalInstance, rfr) {
        var model = $scope;

        model.title = "Revision";
        model.subtitle = rfr.rfrProcessStatusName;
        model.rfr = rfr;

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('rfrPreviewRevisionCtrl', rfrPreviewRevisionCtrl);

}(angular.module('rfr')));