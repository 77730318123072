(function (module) {

    var reviewTeamGroupSvc = function ($http, $q, helperSvc, amsConst, odataSvc) {
        var apiPath = amsConst.webApiUrl + '/odata/ReviewTeamGroup({0})';
        var factory = {};
        var filterBase = '?$filter=';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        /*************************************************
        *Refactor starts here
        **************************************************/
        var key = 'reviewTeamId';
        var groupKey = 'reviewTeamGroupId';
        var api = '/ReviewTeamGroup';
        factory.data = { teamGroups: null };

        factory.getReviewTeamGroupsByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
                .expand('reviewTeamGroupMemberDtos', 'reviewTeamMemberDto')
                .filter('reviewTeamId', id)
                .query(oSvc.onSuccess, oSvc.onFailure);


            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.teamGroups = data;
                });
            }

            return oSvc.getDeferred().promise;
        }
           
        factory.deleteOdata = function (teamGroup, noRefresh) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, groupKey, { reviewTeamGroupId: teamGroup.reviewTeamGroupId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            if (!noRefresh) {
                resolveReviewTeamGroupData(oSvc, teamGroup.reviewTeamId);
            }

            return oSvc.getDeferred().promise;
        }

        factory.updateOdata = function (reviewTeamGroup, noRefresh) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, groupKey, reviewTeamGroup);
          
            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            if (!noRefresh) {
                resolveReviewTeamGroupData(oSvc, reviewTeamGroup.reviewTeamId);
            }

            return oSvc.getDeferred().promise;
        }

        factory.createOdata = function (reviewTeamGroup, noRefresh) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, groupKey, reviewTeamGroup);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            if (!noRefresh) {
                resolveReviewTeamGroupData(oSvc, reviewTeamGroup.reviewTeamId);
            }

            return oSvc.getDeferred().promise;
        }


        factory.getReviewTeamGroupsByReviewTeamId = function (id) {
            return $http.get(apiPath.format(null) + filterBase + buildReviewTeamIdFilter(id) + '&$expand=reviewTeamGroupMemberDtos($expand=reviewTeamMemberDto)').then(onSuccess, onFailure);
        };

        factory.create = function (newTeamGroup) {
            return $http.post(apiPath.format(null), newTeamGroup).then(onSuccess, onFailure);
        };

        factory.update = function (teamGroup) {
            return $http.put(apiPath.format(teamGroup.reviewTeamGroupId), teamGroup).then(onSuccess, onFailure);
        };

        factory.delete = function (teamGroupId) {
            if (confirm("Are you sure you want to delete this record?")) {
                return $http.delete(apiPath.format(teamGroupId)).then(onSuccess, onFailure);
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        var buildFilter = helperSvc.buildFilter;

        var buildReviewTeamIdFilter = function (parameter) {
            return buildFilter("reviewTeamId eq", parameter);
        };

        function resolveReviewTeamGroupData(oSvc, id) {
            oSvc.getDeferred().promise.then(function () {
                factory.getReviewTeamGroupsByReviewTeamIdOdata(id);
            });
        }


        return {
            getReviewTeamGroupsByReviewTeamIdOdata: factory.getReviewTeamGroupsByReviewTeamIdOdata,
            getReviewTeamGroupsByReviewTeamId: factory.getReviewTeamGroupsByReviewTeamId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            deleteOdata: factory.deleteOdata,
            updateOdata: factory.updateOdata,
            createOdata: factory.createOdata,
            data: factory.data
        };
    }

    module.factory('reviewTeamGroupSvc', reviewTeamGroupSvc);

})(angular.module('review'));