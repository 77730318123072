(function (module) {

    var reviewTeamProcessTrackingSvc = function ($http, $q, helperSvc, amsConst, odataSvc) {
        var apiPath = amsConst.webApiUrl + '/odata/ReviewTeamProcessTracking({0})';
        var factory = {};
        var filterBase = '?$filter=';
        var expandReviewProcessStatusDto = '&$expand=reviewProcessStatusDto';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };


        /*************************************************
        *Refactor starts here
        **************************************************/
        var key = 'reviewTeamId';
        var trackingKey = 'reviewTeamProcessTrackingId';
        var api = '/ReviewTeamProcessTracking';
        factory.data = { reviewStatuses: null };

        factory.getReviewStatusesByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
                .expand('reviewProcessStatusDto')
                .filter('reviewTeamId', id)
                .query(oSvc.onSuccess, oSvc.onFailure);


            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.reviewStatuses = data;
                });
            }

            return oSvc.getDeferred().promise;
        }


        factory.getReviewStatusesByReviewTeamId = function (id) {
            return $http.get(apiPath.format(null) + filterBase + buildReviewTeamIdFilter(id) + expandReviewProcessStatusDto).then(onSuccess, onFailure);
        };

        factory.create = function (newReviewStatus) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, newReviewStatus);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamProcessTrackingData(oSvc, newReviewStatus.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.update = function (reviewStatus) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, reviewStatus);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamProcessTrackingData(oSvc, reviewStatus.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (reviewStatus) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, trackingKey, { reviewTeamProcessTrackingId: reviewStatus.reviewTeamProcessTrackingId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamProcessTrackingData(oSvc, reviewStatus.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        var buildFilter = helperSvc.buildFilter;

        var buildReviewTeamIdFilter = function (parameter) {
            return buildFilter("reviewTeamId eq", parameter);
        };

        function resolveReviewTeamProcessTrackingData(oSvc, reviewTeamId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getReviewStatusesByReviewTeamIdOdata(reviewTeamId);
            });
        }


        return {
            getReviewStatusesByReviewTeamIdOdata: factory.getReviewStatusesByReviewTeamIdOdata,
            getReviewStatusesByReviewTeamId: factory.getReviewStatusesByReviewTeamId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            data: factory.data
        };
    };
    module.factory('reviewTeamProcessTrackingSvc', reviewTeamProcessTrackingSvc);

})(angular.module('review'));