(function (module) {

    var professionalLicenseEditCtrl = function (parent, $scope, $uibModalInstance, volunteerHelperSvc, professionalLicenseSvc, pevAppSvc, alertSvc, helperSvc) {
        var model = $scope;
        var checkIsVolunteer = true;
        var isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);
        var originalLicense = null;
        var isUpdateMode = false;
        
        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.isInsertMode = false;
        model.showOtherFields = false;
        model.addSpace = helperSvc.addSpace;
        model.selectedStates = [];
        model.volunteerId = volunteerHelperSvc.getVolunteerId();
        model.currentInformation = {};
        model.stateNeeded = true;

        if (isApplication) {
            model.data = angular.copy(pevAppSvc.volunteerLicenseData);
            model.data.stateOptions = professionalLicenseSvc.data.stateOptions;
            model.data.licenseOptions = professionalLicenseSvc.data.licenseOptions;      
        } else {
            model.data = professionalLicenseSvc.data;
        }

        model.save = function () {
            if (!isApplication) model.currentInformation.volunteerId = model.volunteerId;

            model.currentInformation.licenseStateList = getStatesString(model.selectedStates);

            if (isApplication) {
                if (isUpdateMode) {
                    model.data.professionalLicenses[getLicenseIndex(originalLicense)] = model.currentInformation;
                } else {
                    model.data.professionalLicenses.push(model.currentInformation);
                }

                var application = angular.copy(pevAppSvc.data.application);
                application.applicationJson.volunteerLicense = model.data.professionalLicenses;

                pevAppSvc.update(application).then(onSuccess, onFailure);
            } else if (model.currentInformation.volunteerLicenseId) {
                delete model.currentInformation.endYear
                professionalLicenseSvc.update(model.currentInformation).then(onSuccess, onFailure);
            } else {
                delete model.currentInformation.endYear
                professionalLicenseSvc.create(model.currentInformation).then(onSuccess, onFailure);
            }


            function onSuccess () {
                if (isUpdateMode) isUpdateMode = false;
                alertSvc.addModalAlertSuccess("Professional license succesfully saved.");
                model.toggleInsertMode();
            }

            function onFailure () {
                alertSvc.addModalAlertWarning('Professional license could not be saved at this time.');
            }
        };

        model.edit = function (professionalLicense) {
            isUpdateMode = true;
            originalLicense = angular.copy(professionalLicense);

            model.currentInformation = angular.copy(professionalLicense);
            model.selectedStates = getStatesArray(professionalLicense.licenseStateList);
            model.isInsertMode = true;
        };

        model.delete = function (professionalLicense) {
            alertSvc.confirmDelete(professionalLicense.licenseCode, deleteFunc, professionalLicense);

            function deleteFunc() {
                if (isApplication) {
                    var index = getLicenseIndex(professionalLicense);

                    if (index > -1) {
                        var application = angular.copy(pevAppSvc.data.application);
                        model.data.professionalLicenses.splice(index, 1);
                        application.applicationJson.volunteerLicense = model.data.professionalLicenses;
                        pevAppSvc.update(application).then(onSuccess, onFailure);
                    } else {
                        alertSvc.addModalAlertWarning("The selected license could not be found.");
                    }
                } else {
                    professionalLicenseSvc.delete(professionalLicense).then(onSuccess, onFailure);
                }

                function onSuccess () {
                    if (model.isInsertMode) model.toggleInsertMode();
                    alertSvc.addModalAlertSuccess("Professional license succesfully deleted.");
                }
                function onFailure(reason) {
                    if (reason === 'user cancelled') {
                        alertSvc.addModalAlertInfo('Delete cancelled.');
                    } else {
                        alertSvc.addModalAlertWarning('Professional license could not be deleted at this time.');
                    }
                }                
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleInsertMode = function () {
            volunteerHelperSvc.toggleInsertMode(model);
            if (!model.isInsertMode) {
                model.selectedStates = [];
            }
        };

        model.checkOther = function () {
            model.showOtherFields = model.currentInformation.licenseCode.toLowerCase() == 'other';
           //reset after a change of license
            model.selectedStates = [];
            model.currentInformation.licenseOther = undefined;
        };

        model.stateRequired = function(){
            if(model.currentInformation.licenseCode && model.currentInformation.licenseCode.toLowerCase() == 'other')
                return false;
            else
                return true;
        }

        model.sortOtherLast = function (license) {
            //takes current order of list and puts 'other' at the end
            if (license.codeName.trim().toLowerCase() === 'other') return 'zzother';
            return license.codeName;
        }

        function getLicenseIndex(professionalLicense) {
            return model.data.professionalLicenses.findIndex(function (license) {
                if (license.licenseCode.trim().toLowerCase() === 'other') {
                    return license.licenseOther == professionalLicense.licenseOther;
                }
                return license.licenseCode == professionalLicense.licenseCode &&
                       license.licenseStateList == professionalLicense.licenseStateList;
            });
        }

        function getStatesArray(stateList) {
            var states = stateList ? stateList.split(',') : [];
            var stateObjects = [];
            for (var i = 0; i < states.length; i++) {
                var state = convertState(states[i]);
                stateObjects.push(state);
            }
            return stateObjects;
        }

        function getStatesString(statesArray) {
            var stateCodes = statesArray.map(function (state) { return state.codeKey; });
            return stateCodes.join(',');
        }

        function convertState(state) {
            switch (typeof (state)) {
                case 'object':
                    return state.codeKey;
                case 'string':
                    return createStateObj(state);
                default:
                    return null;
            }
        }

        function createStateObj(stateCodeKey) {
            for (var i = 0; i < model.data.stateOptions.length; i++) {
                var state = model.data.stateOptions[i];
                if (stateCodeKey === state.codeKey) {
                    return state;
                }
            }
        }
    };

    module.controller('professionalLicenseEditCtrl', professionalLicenseEditCtrl);

}(angular.module('volunteer')));


