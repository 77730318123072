(function (module) {

    module.factory('programSummarySvc', function (eventSvc, helperSvc, $uibModal, typeConstSvc) {

        var factory = {},
            currentReview,
            defaultText = 'Not entered';

        factory.getProgramReviewDisciplineAreas = function (review) {
            return helperSvc.getPropertyString(review.programReviewDisciplineDtos, 'disciplineName', defaultText);
        };

        factory.getDistanceLearningTypeName = function (review) {
            var text = defaultText;

            if (review.distanceLearningId) {
                text = review.distanceLearningTypeName;
            }

            return text;
        };
        //Moved this in because 4 different controller scopes were being called in the html
        factory.editChangeHistory = function (review, isProgramReview) {
            var isProgramReview = (isProgramReview === 'true');

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/common/templates/misc/changeHistoryEdit.html',
                size: 'lg',
                controller: 'changeHistoryEditCtrl',
                resolve: {
                    parent: function () {
                        return null;
                    },
                    isProgramReview: function () {
                        return isProgramReview;
                    },
                    changesOrReview: function () {
                        return review;
                    }
                }
            });

        };

        factory.getProgramCampusFromRFRProgram = function (program) {
            return getCampuses(program, false);
        };

        factory.getProgramCampusListFromRFRProgram = function (program) {
            return getCampusList(program);
        };

        factory.getProgramReviewCampuses = function (review) {
            return getCampuses(review, true);
        };

        factory.getNPCCampuses = function (change) {
            return getCampuses(change, false);
        }

        //pass in the program object and if the campus info is a reviewCampusDto or not
        function getCampuses(programObject, isReviewCampusDto) {
            var programCampusIds = typeConstSvc.getProgramCampusTypeIds();

            if (hasCampuses(programObject, isReviewCampusDto)) {
                var result = '',
                    isMain = true,
                    isOther = true;

                if (programObject.programCampusTypeId === programCampusIds.MAINLOCATIONONLY) {
                    if (isReviewCampusDto) {
                        var mainResult = getCampus(programObject.programReviewCampusDtos, isMain);
                    }
                    else {
                        var mainResult = getCampus(programObject.campuses, isMain);
                    }
                    result = createCampusText(mainResult, 'Main location only');
                }
                else if (programObject.programCampusTypeId === programCampusIds.MAINANDOTHERLOCATIONS) {
                    var campuses = [];
                    if (isReviewCampusDto) {
                        var mainResult = getCampus(programObject.programReviewCampusDtos, isMain);
                        var otherResult = getCampus(programObject.programReviewCampusDtos, !isMain);
                    } else {
                        var mainResult = getCampus(programObject.campuses, isMain);
                        var otherResult = getCampus(programObject.campuses, !isMain);
                    }

                    campuses.push(createCampusText(mainResult, 'Main'));
                    campuses.push(createCampusText(otherResult, 'and Other location(s)', isOther));

                    result = campuses.join(' ');
                }
                else if (programObject.programCampusTypeId === programCampusIds.OTHERLOCATIONSONLY) {
                    if (isReviewCampusDto) {
                        var otherResult = getCampus(programObject.programReviewCampusDtos, !isMain);
                    }
                    else {
                        var otherResult = getCampus(programObject.campuses, !isMain);

                    }
                    result = createCampusText(otherResult, 'Other location(s) only', isOther);

                } else if (programObject.programCampusTypeId === programCampusIds.ONLINEONLY) {
                    if (isReviewCampusDto) {
                        var otherResult = getCampus(programObject.programReviewCampusDtos, !isMain);
                    } else {
                        var otherResult = getCampus(programObject.campuses, !isMain);
                    }

                    result = createCampusText(otherResult, 'Online only', isOther);
                }

                return result;
            }
            else if (programObject.programCampusTypeId === programCampusIds.ONLINEONLY) {
                return 'Online only';
            } else {
                return defaultText;
            }
        }

        function getCampusList(programObject) {
            var programCampusIds = typeConstSvc.getProgramCampusTypeIds();

            if (hasCampuses(programObject, false)) {
                var result = '',
                    isMain = true,
                    isOther = true;

                if (programObject.programCampusTypeId === programCampusIds.MAINLOCATIONONLY) {
                    var mainResult = getCampusNoTruncation(programObject.campuses, isMain);
                    result = createCampusText(mainResult, 'Main location only');
                }
                else if (programObject.programCampusTypeId === programCampusIds.MAINANDOTHERLOCATIONS) {
                    var campuses = [];
                    var mainResult = getCampusNoTruncation(programObject.campuses, isMain);
                    var otherResult = getCampusNoTruncation(programObject.campuses, !isMain);
                    
                    campuses.push(createCampusText(mainResult, 'Main'));
                    campuses.push(createCampusText(otherResult, 'and Other location(s)', isOther));

                    result = campuses.join(' ');
                }
                else if (programObject.programCampusTypeId === programCampusIds.OTHERLOCATIONSONLY) {
                    var otherResult = getCampusNoTruncation(programObject.campuses, !isMain);

                    result = createCampusText(otherResult, 'Other location(s) only', isOther);

                } else if (programObject.programCampusTypeId === programCampusIds.ONLINEONLY) {
                    var otherResult = getCampusNoTruncation(programObject.campuses, !isMain);
                    result = createCampusText(otherResult, 'Online only', isOther);
                }

                return result;
            }
            else if (programObject.programCampusTypeId === programCampusIds.ONLINEONLY) {
                return 'Online only';
            } else {
                return defaultText;
            }
        }

        function hasCampuses(programObject, isReviewCampusDto) {
            var hasCampuses = programObject.programCampusTypeId !== undefined &&
                programObject.programCampusTypeId !== null;

            if (isReviewCampusDto) {
                hasCampuses = hasCampuses &&
                    (programObject.programReviewCampusDtos !== undefined && programObject.programReviewCampusDtos !== null);
            } else {
                hasCampuses = hasCampuses &&
                    (programObject.campuses !== undefined && programObject.campuses !== null);
            };

            return hasCampuses;
        }

        function createCampusText(text, campusName) {
            return (text) ? campusName + ' (' + text + ') ' : campusName;
        }

        function getCampus (programReviewCampuses, isMainCampus) {
            var text = '',
                campuses = [],
                additionalCampuses = 0;

            // Main Only: store main campus name
            // Main and Other(s): store main or first other campus name
            // Other Only: store first two other campus names then the number of remaining campuses (i.e. Campus Name, 3 more)

            if (programReviewCampuses.length > 0) {
                for (var i = 0; i < programReviewCampuses.length; i++) {
                    if (programReviewCampuses[i].isMainCampus === isMainCampus && programReviewCampuses[i].campusName && i < 2) {
                        campuses.push(programReviewCampuses[i].campusName);
                    } else if (programReviewCampuses[i].isMainCampus === isMainCampus && !programReviewCampuses[i].changeId && isMainCampus === false && campuses.length > 0) {
                        additionalCampuses++;
                    }
                }
            }
            if (additionalCampuses > 0) campuses.push(additionalCampuses + ' more');

            text = campuses.join(', ');

            return text;
        }

        function getCampusNoTruncation(programReviewCampuses, isMainCampus) {
            var text = '',
                campuses = [],
                additionalCampuses = 0;

            // Store all campus names
            if (programReviewCampuses.length > 0) {
                for (var i = 0; i < programReviewCampuses.length; i++) {
                    if (programReviewCampuses[i].isMainCampus === isMainCampus && programReviewCampuses[i].campusName) {
                        campuses.push(programReviewCampuses[i].campusName);
                    }
                }
            }
            
            text = campuses.join(', ');

            return text;
        }

        return {
            getProgramReviewDisciplineAreas: factory.getProgramReviewDisciplineAreas,
            getDistanceLearningTypeName: factory.getDistanceLearningTypeName,
            getProgramReviewCampuses: factory.getProgramReviewCampuses,
            getNPCCampuses: factory.getNPCCampuses,
            getProgramCampusFromRFRProgram: factory.getProgramCampusFromRFRProgram,
            getProgramCampusListFromRFRProgram: factory.getProgramCampusListFromRFRProgram,
            editChangeHistory: factory.editChangeHistory
        };
    });

    module.factory('campusAddressComponentSvc', function (codeSvc) {

        var factory = {};

        factory.add = function (addresses, review, isMain) {
            var newAddress = {
                addressDto: {
                    addressId: 0,
                    isDeleted: false
                },
                addressId: 0,
                changeId: null,
                isDeleted: false,
                isMainCampus: (isMain) ? true : false,
                programReviewCampusId: 0,
                programReviewId: review.programReviewId
            };

            if (isMain) {
                addresses.splice(0, 0, newAddress);
            } else {
                addresses.push(newAddress);
            }
        };

        factory.campusTypeIsSelected = function (review) {
            return (review.programCampusTypeId !== null);
        }

        factory.changeCountry = function (address) {
            if (address.countryCode == 'US') {
                address.isOutsideUS = false;
            }
            else {
                address.isOutsideUS = true;
            }
        };

        factory.changeUS = function (address) {
            if (address.isOutsideUS)
                address.countryCode = '';
            else
                address.countryCode = 'US';
        };

        factory.delete = function (address) {
            address.isDeleted = true;
        };

        factory.isDeletable = function (review, addresses, index) {
            var mainOnlyConditions = review.programCampusTypeId === 1;                          // main address cannot be deleted
            var mainAndOtherConditions = (review.programCampusTypeId === 2 && addresses[index].isMainCampus === true)      // main address and first other address cannot be deleted
                                        || (review.programCampusTypeId === 2 & addresses.length < 3);
            var otherOnlyConditions = review.programCampusTypeId === 3 && addresses.length < 2;  // first other address cannot be deleted

            return (mainOnlyConditions || mainAndOtherConditions || otherOnlyConditions) ? false : true;
        };

        factory.insertMainOnly = function (addresses, review, isOriginalRecord) {
            var hasMain = false,
                hasOriginalMainRecord = false;

            angular.forEach(addresses, function (address, key) {
                if (address.isMainCampus && !address.isDeleted) {
                    hasMain = true;
                    if (address.changeId === null) hasOriginalMainRecord = true;
                } else {
                    address.isDeleted = true;
                }
            });

            if ((isOriginalRecord && !hasOriginalMainRecord) || !hasMain) {
                var isMain = true;
                factory.add(addresses, review, isMain);
            }
        };

        factory.insertMainAndOther = function (addresses, review, isOriginalRecord) {
            var hasMain = false,
                hasOther = false,
                hasOriginalMainRecord = false,
                hasOriginalOtherRecord = false;

            angular.forEach(addresses, function (address, key) {
                if (address.isMainCampus && !address.isDeleted) {
                    hasMain = true;
                    if (address.changeId === null) hasOriginalMainRecord = true;
                } else if (!address.isMainCampus && !address.isDeleted) {
                    hasOther = true;
                    if (address.changeId === null) hasOriginalOtherRecord = true;
                }
            });

            if ((isOriginalRecord && !hasOriginalMainRecord) || !hasMain) {
                var isMain = true;
                factory.add(addresses, review, isMain);
            }

            if ((isOriginalRecord && !hasOriginalOtherRecord) || !hasOther) {
                factory.add(addresses, review);
            }
        };

        factory.insertOtherOnly = function (addresses, review, isOriginalRecord) {
            var hasOther = false,
                hasOriginalOtherRecord = false;

            angular.forEach(addresses, function (address, key) {
                if (address.isMainCampus && !address.isDeleted) {
                    // may not work, for change records need to delete only records with current changeId
                    if ((isOriginalRecord && address.changeId === null) || (!isOriginalRecord && address.changeId !== null)) {
                        address.isDeleted = true;
                    }
                }
                else if (!address.isMainCampus && !address.isDeleted) {
                    hasOther = true;
                    if (address.changeId === null) hasOriginalOtherRecord = true;
                }
            });

            if (!hasOther) {
                factory.add(addresses, review);
            }
        };

        factory.isMainCampusOnly = function (review) {
            var mainCampusOnlyId = 1;
            return !review.programCampusTypeId || review.programCampusTypeId === mainCampusOnlyId;
        };

        factory.getChangeIdVal = function (isCreateMode, isUpdateMode, addresses) {
            if (isCreateMode) {
                return '!null';
            } else if (isUpdateMode) {
                return '!null';
            } else {
                return null;
            }
        }

        factory.getMostRecentChangeId = function (addresses) {
            var mostRecentChangeId = null;
            angular.forEach(addresses, function (address, key) {
                if (address.changeId > mostRecentChangeId) {
                    mostRecentChangeId = address.changeId;
                }
            });
            return mostRecentChangeId;
        };

        return {
            add: factory.add,
            campusTypeIsSelected: factory.campusTypeIsSelected,
            changeCountry: factory.changeCountry,
            changeUS: factory.changeUS,
            delete: factory.delete,
            insertMainOnly: factory.insertMainOnly,
            insertMainAndOther: factory.insertMainAndOther,
            insertOtherOnly: factory.insertOtherOnly,
            isDeletable: factory.isDeletable,
            isMainCampusOnly: factory.isMainCampusOnly,
            getChangeIdVal: factory.getChangeIdVal,
            getMostRecentChangeId: factory.getMostRecentChangeId,
            confirmDatesModal: factory.confirmDatesModal
        };
    });

    module.factory('confirmDatesSvc', function (eventSvc, helperSvc, $uibModal, typeConstSvc) {
        var factory = {};

        factory.confirmDatesModal = function (suggestedDate, currentlySelectedDates, callback) {
            var selectedDates = { startDate: currentlySelectedDates.startDate, endDate: currentlySelectedDates.endDate };
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/common/templates/misc/confirmDate.html',
                size: 'md',
                controller: 'confirmDateCtrl',
                resolve: {
                    suggestedDate: function () { return suggestedDate; },
                    selectedDates: function () { return selectedDates; },
                    callback: function () { return callback; }
                }
            });
        }

        return {
            confirmDatesModal: factory.confirmDatesModal
        };
    });

    module.factory('componentHelperSvc', function (eventSvc) {
        var factory = {};

        factory.invokeOnRendered = function (componentName, scope) {
            var data = { componentName: componentName };
            if (scope && scope.$applyAsync) {
                scope.$applyAsync(function () {
                    eventSvc.broadcast('OnRendered', data);
                });
            } else {
                eventSvc.broadcast('OnRendered', data);
            }
        };

        factory.listenToOnRendered = function (callback, scope) {
            return eventSvc.listen(callback, 'OnRendered', scope);
        };

        factory.createOnRenderedListener = function (childComponentNames, callback, scope) {
            var childComponents = childComponentNames.slice();

            return factory.listenToOnRendered(
                function (event, args) {
                    var position = childComponents.indexOf(args.componentName);
                    if (position > -1) {
                        childComponents.splice(position, 1);
                        if (!childComponents.length)
                            callback();
                    }
                },
                scope
            );
        }

        return {
            invokeOnRendered: factory.invokeOnRendered,
            listenToOnRendered: factory.listenToOnRendered,
            createOnRenderedListener: factory.createOnRenderedListener
        };
    });

}(angular.module('common')));