(function (module) {

    var rfrTerminateProgramEditCtrl = function (program, $scope, $uibModalInstance, $filter, rfrSvc, alertSvc, helperSvc, rfrValidationSvc, programSvc, programReviewTypeIds, terminationOptions) {
        var model = $scope;
        model.title = 'Terminate Accreditation';
        model.data = angular.copy(rfrSvc.data);
        model.program = angular.copy(rfrSvc.getProgramById(program.programId));
        model.isDataReady = false;
        model.errors = [];
 
        model.canDelete = program.requestToTerminate != null;

        model.terminationOptions = terminationOptions;
        model.validateDates = function () {
            var errors = [];
            var accreditationEndDate = model.accreditationEndDate;

            if (!accreditationEndDate) {
                errors.push('Program may not be terminated because it does not have a valid accreditation expiration date.');
            } else if (model.program.requestToTerminate.terminationOption === terminationOptions.PROGRAMTERMINATION) {
                var nextAccreditationEndDate = angular.copy(accreditationEndDate).setFullYear(accreditationEndDate.getFullYear() + 3);
                var lastGraduateDate = new Date(model.program.requestToTerminate.lastGraduateDate);
                if (lastGraduateDate > nextAccreditationEndDate) {
                    errors.push('No extension of accreditation is allowable beyond ' + $filter('date')(nextAccreditationEndDate, "MM/dd/yyyy") + ' for Program Termination (Option 1)');
                }
            } else if (model.program.requestToTerminate.terminationOption === terminationOptions.ACCREDITATIONTERMINATION) {
                var accreditationTerminationDate = new Date(model.program.requestToTerminate.accreditationTerminationDate);
                if (accreditationTerminationDate > accreditationEndDate) {
                    errors.push('No extension of accreditation is allowable beyond the expiration date of ' + model.accreditationEndDateText + ' for Accreditation Termination (Option 2)');
                }
            }

            model.errors = errors;
        };

        model.save = function () {
            helperSvc.validateForm(model.validateDates);
           
            if (model.errors.length > 0) return;

            var useDeleteMode = false;
            updateRequestToTerminate(useDeleteMode);
            updateRfr('Request to terminate successfully updated');
        };

        model.cancel = function () {
            closeModal();
        };

        model.delete = function () {
            alertSvc.confirmDelete(null, func);

            function func() {
                var useDeleteMode = true;
                updateRequestToTerminate(useDeleteMode);
                updateRfr('Request to terminate successfully deleted');
            }
        };  

        function updateRfr(alertMessage) {
            var tempRfr = angular.copy(model.data.rfr);
            rfrSvc.update(tempRfr).then(function (data) {
                alertSvc.addAlertSuccess(alertMessage);
                rfrValidationSvc.invokeValidation();
                closeModal();
            });
            
        }
 
        function updateRequestToTerminate(deleteMode) {
            // Update request to terminate based on selected termination option
            if (model.program.requestToTerminate.terminationOption == model.terminationOptions.ACCREDITATIONTERMINATION) {
                model.program.requestToTerminate.lastGraduateDate = null;
            } else if (model.program.requestToTerminate.terminationOption == model.terminationOptions.PROGRAMTERMINATION) {
                model.program.requestToTerminate.accreditationTerminationDate = null;
            }

            // Set review type, PEVs based on selected termination option or delete request as needed.
            var tempProgram = angular.copy(rfrSvc.getProgramById(model.program.programId));
            tempProgram.requestToTerminate = model.program.requestToTerminate;
            var rfrCommissionDtos = model.data.rfr.rfrCommissionDtos;

            for (var i = 0; i < rfrCommissionDtos.length; i++) {
                var programs = rfrCommissionDtos[i].jsonContents.programs;

                for (var j = 0; j < programs.length; j++) {
                    if (programs[j].programId === tempProgram.programId) {
                        var program = programs[j];
                        if (deleteMode) {
                            delete program.requestToTerminate;
                            // Reset orginal values that were modified as part of termination request
                            if (!program.originalProgramReviewTypeCode) continue;
                            program.programReviewTypeCode = program.originalProgramReviewTypeCode;
                            delete program.originalProgramReviewTypeCode;
                            for (var k = 0; program.disciplines != null && k < program.disciplines.length; k++) {
                                if (!program.disciplines[k].originalPevsRequired) continue;
                                program.disciplines[k].pevsRequired = program.disciplines[k].originalPevsRequired;
                                delete program.disciplines[k].originalPevsRequired;
                            }
                        }
                        else {
                            program.requestToTerminate = tempProgram.requestToTerminate;
                            // Update program review type and number of PEVs when creating termination request
                            if (program.originalProgramReviewTypeCode === undefined) {
                                program.originalProgramReviewTypeCode = program.programReviewTypeCode;
                            }

                            program.programReviewTypeCode = programReviewTypeIds.TERMINATION;
                            //if (model.program.requestToTerminate.terminationOption === model.terminationOptions.PROGRAMTERMINATION) {
                            //    if ((program.requestToTerminate.lastGraduateDate > model.accreditationEndDate) && program.originalProgramReviewTypeCode === programReviewTypeIds.COMPREHENSIVEVISIT) {
                            //        // If the last graduate date is after the accreditation end date and originalProgramReviewTypeCode is Comprehensive Visit, change to Termination Visit
                            //        // NO IGR because an IGR should never have a termination, it would just be cancelled
                            //        program.programReviewTypeCode = programReviewTypeIds.TERMINATIONVISIT;
                            //    } else {
                            //        program.programReviewTypeCode = programReviewTypeIds.TERMINATIONREPORT;
                            //    }
                                
                            //}

                            // Old logic using previous termination options

                            //if (model.program.requestToTerminate.terminationOption === model.terminationOptions.EXTENSION) {
                            //    if (program.originalProgramReviewTypeCode === programReviewTypeIds.COMPREHENSIVEVISIT) {//NO igr because an igr should never have a termination... it would just be cancelled

                            //        program.programReviewTypeCode = programReviewTypeIds.TERMINATIONVISIT;
                            //    } else {
                            //        program.programReviewTypeCode = programReviewTypeIds.TERMINATIONREPORT;
                            //    }
                            //}
                            //if (model.program.requestToTerminate.terminationOption === model.terminationOptions.PHASEOUT) {
                            //    program.programReviewTypeCode = programReviewTypeIds.TERMINATIONREPORT;
                            //}

                            for (var k = 0; program.disciplines != null && k < program.disciplines.length; k++) {
                                if (program.disciplines[k].originalPevsRequired === undefined) {
                                    program.disciplines[k].originalPevsRequired = program.disciplines[k].pevsRequired;
                                }
                                program.disciplines[k].pevsRequired = 0;
                            }
                        }
                    }
                }
            }
        }

        var closeModal = function () {
            $uibModalInstance.close();
        };

        activate();

        function activate() {
            loadAccreditationEndDate();
            if (model.program.requestToTerminate && model.program.requestToTerminate.lastGraduateDate) {
                model.program.requestToTerminate.lastGraduateDate = helperSvc.formatDate(model.program.requestToTerminate.lastGraduateDate) || undefined;
            } else if (model.program.requestToTerminate && model.program.requestToTerminate.accreditationTerminationDate) {
                model.program.requestToTerminate.accreditationTerminationDate = helperSvc.formatDate(model.program.requestToTerminate.accreditationTerminationDate) || undefined;
            }
        }

        function loadAccreditationEndDate() {
            model.accreditationEndDate = null;
            programSvc.getProgramById(model.program.programId, true).then(function (data) {
                var programSearchViewModel = helperSvc.getResults(data);
                programSearchViewModel = programSearchViewModel[0];
                var accreditationDates = programSearchViewModel.accreditationDates;

                if (accreditationDates != null && accreditationDates.length > 9) {
                    var endDateText = accreditationDates.slice(-10);
                    model.accreditationEndDate = new Date(endDateText);
                    model.accreditationEndDateText = endDateText;
                }
                model.isDataReady = true;
            });
        }
         
    };

    module.controller('rfrTerminateProgramEditCtrl', rfrTerminateProgramEditCtrl);

}(angular.module('rfr')));