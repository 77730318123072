(function (module) {

    var currentActivitiesCtrl = function ($scope, $uibModal, volunteerActivitiesSvc, helperSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Reviews (Current Cycle)';
        model.expTitle = 'Volunteer Experience';
        model.printTitle = 'Volunteer Evaluations';
        model.formatDate = helperSvc.formatDate;
        model.academicYear = helperSvc.getAcademicYear();
        model.hasCurrentActivities = false;

        $scope.filterCurrentActivities = (item) => item.reviewYear >= model.academicYear ? true : false;

        activate();

        model.viewHistory = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/currentActivitiesHistory.html',
                size: 'lg',
                controller: 'currentActivitiesHistoryCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        function activate() {
            model.data = volunteerActivitiesSvc.data;
        }
    };

    module.controller('currentActivitiesCtrl', currentActivitiesCtrl);

}(angular.module('volunteer')));