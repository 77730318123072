(function (module) {

    var npcReportTemplateSvc = function ($q, helperSvc, programAuditDetailTemplateSvc, npcChangeTypeIds, statementFindingTypes, statementFindingTypeNames, commissionIds, statementCategories,
        statementSvc, readinessSvc, codeSvc, criteriaTypes, teamMemberTypeNames, npcTypes, npcSectionTypes, npcSectionTypeNames, npcReportTypes) {

        const factory = {};

        factory.getCriteria = programAuditDetailTemplateSvc.getCriteria;

        factory.getFindingTypeOptions = function () {
            return statementSvc.getStatementFindingTypes().then(findingTypes => {
                if (!findingTypes) return null;
                const systemGeneratedTypes = [statementFindingTypes.PROGRAMINTRODUCTION, statementFindingTypes.PROGRAMSUMMARY, statementFindingTypes.TERMINATIONPLAN];
                return findingTypes.filter(findingType =>
                    findingType.statementFindingTypeId === statementFindingTypes.INSTITUTIONSTRENGTH ||
                    findingType.statementCategoryId === statementCategories.PROGRAM && !systemGeneratedTypes.includes(findingType.statementFindingTypeId)
                ).map(findingType =>
                    Object.assign(findingType, {
                        number: 0,
                        criteria: []
                    })
                );
            });
        };

        factory.validateFindingTypeOptions = programAuditDetailTemplateSvc.validateFindingTypeOptions;

        factory.isShortcoming = programAuditDetailTemplateSvc.isShortcoming;

        factory.getAllNPCStatementTemplateData = function (program) {
            return $q.all([
                factory.getCriteria(program),
                factory.getFindingTypeOptions()
            ]).then(([criteria, findingTypeOptions]) =>
                ({ criteria, findingTypeOptions })
            );
        };
         
        factory.createEmptyTemplate = function (change, reviewer, reportType, program) {
            const teamMemberTypeId = reportType === 'Review' ? teamMemberTypeNames.NPCREVIEWER : teamMemberTypeNames.TEAMCHAIR;
            const changeReportSectionIds = [npcSectionTypes.INTRODUCTION, npcSectionTypes.SUMMARYOFCHANGES, npcSectionTypes.MATERIALSREVIEWED, npcSectionTypes.FINDINGS, npcSectionTypes.RECOMMENDATION];
            const terminationReportSectionIds = [npcSectionTypes.INTRODUCTION, npcSectionTypes.TERMINATIONPLAN, npcSectionTypes.RECOMMENDATION];
            const statementSectionIds = [npcSectionTypes.INSTITUTION, npcSectionTypes.PROGRAM, ...(program?.commissionId === commissionIds.CAC && [npcSectionTypes.SUMMARY] || [])];
            //const reportSectionIds = reportType === npcReportTypes.REVIEWREPORT ? (change.npcType === npcTypes.CHANGE ? changeReportSectionIds : terminationReportSectionIds) : statementSectionIds;
            const reportSectionIds = change.npcType === npcTypes.CHANGE ? changeReportSectionIds : terminationReportSectionIds;

            const institutionFindingTypes = [statementFindingTypes.INSTITUTIONINTRODUCTION, (program?.commissionId === commissionIds.CAC && statementFindingTypes.REVIEWTEAM), null, statementFindingTypes.INSTITUTIONSUMMARY];
            const programFindingTypes = [statementFindingTypes.PROGRAMINTRODUCTION, (change.npcType === npcTypes.TERMINATION && statementFindingTypes.TERMINATIONPLAN)];
            const summaryFindingTypes = [statementFindingTypes.PROGRAMSUMMARY];

            let findingTypes;

            const reportJson = reportSectionIds.map((npcSectionTypeId, index) => {
                // Init generic properties
                const section = {
                    npcSectionTypeId,
                    npcSectionTypeName: npcSectionTypeNames[npcSectionTypeId],
                    orderId: index + 1
                };

                // Init report/statement specific properties
                //11/29/2021 : Make the statement same as report
                if (reportType === npcReportTypes.REVIEWREPORT || reportType === npcReportTypes.STATEMENT) {
                    section.text = null;
                } else {
                    switch (npcSectionTypeId) {
                        case npcSectionTypes.INSTITUTION:
                            section.statementCategoryId = statementCategories.INSTITUTION;
                            findingTypes = institutionFindingTypes;
                            break;
                        case npcSectionTypes.PROGRAM:
                            section.statementCategoryId = statementCategories.PROGRAM;
                            section.programId = program.programId;
                            section.programName = program.programName;
                            section.degreeLevelCode = program.degreeLevelCode;
                            section.degreeCode = program.degreeCode;
                            section.alternateName = program.alternateName;
                            section.alternateDegreeCode = program.alternateDegreeCode;
                            findingTypes = programFindingTypes;
                            break;
                        case npcSectionTypes.SUMMARY:
                            section.statementCategoryId = statementCategories.SUMMARY;
                            findingTypes = summaryFindingTypes;
                            break;
                    }

                    section.statementJson = [];
                    findingTypes.forEach((findingType, index) => {
                        if (!findingType) return; // ignore placeholders for finding types that don't apply to focused visit statements

                        section.statementJson.push(
                            {
                                statementFindingTypeId: findingType,
                                statementFindingTypeName: statementFindingTypeNames[findingType],
                                orderNumber: index + 1,
                                findings: [
                                    {
                                        criteria: {
                                            text: null
                                        },
                                        key: helperSvc.getNewKey() // for conssistency, optional future use in comparisons
                                    }
                                ]
                            });
                    });
                }

                // Init recommended action and effective termination/start dates as needed
                if (npcSectionTypeId === npcSectionTypes.RECOMMENDATION || npcSectionTypeId === npcSectionTypes.PROGRAM) {
                    section.recommendationTypeId = null;
                    section.recommendationTypeName = null;
                    if (change.npcType === npcTypes.TERMINATION)
                        section.recommendedTerminationDate = null;
                    else if (npcSectionTypeId === npcSectionTypes.PROGRAM && change.changeJson.some(changeJson => changeJson.npcChangeTypeId === npcChangeTypeIds.NAME))
                        section.newProgramNameStartDate = null;
                }

                return section;
            });

            return {
                npcProgramChangeId: change.npcProgramChangeId,
                reportType,
                reportJson,
                npcProgramChangeReviewerId: reviewer.npcProgramChangeReviewerId,
                submittedDate: null,
                teamMemberTypeId
            };
        }

        factory.addFindings = function (commissionId, npcProgramChangeReportDto, findingTypeOptions) {
            // Pass commission ID in case we need to detect CAC, e.g. to add CAC-only generated section

            const institutionSection = npcProgramChangeReportDto.reportJson.find(section => section.npcSectionTypeId === npcSectionTypes.INSTITUTION);
            const programSection = npcProgramChangeReportDto.reportJson.find(section => section.npcSectionTypeId === npcSectionTypes.PROGRAM);

            findingTypeOptions
                .filter(findingTypeOption => findingTypeOption.value)
                .forEach(findingTypeOption => {
                    if (!findingTypeOption.value) return;

                    const section = findingTypeOption.statementCategoryId === statementCategories.INSTITUTION ? institutionSection : programSection;

                    let findingType = section.statementJson.find(findingType => findingType.statementFindingTypeId === findingTypeOption.statementFindingTypeId);
                    if (!findingType) {
                        findingType = {
                            statementFindingTypeId: findingTypeOption.statementFindingTypeId,
                            statementFindingTypeName: findingTypeOption.typeName,
                            orderNumber: findingTypeOption.orderNumber,
                            findings: []
                        };
                        section.statementJson.push(findingType);
                    }

                    const criteria = findingTypeOption.number ? (new Array(findingTypeOption.number)).fill(null) : findingTypeOption.criteria || [];
                    const findings = criteria.map(criterion => createFindingTemplate(criterion));
                    findingType.findings.push(...findings);
                });

            sortStatement(npcProgramChangeReportDto);
        }

        factory.resetRecommendedAction = function (npcProgramChangeReportDto) {
            // Reset recommended action based on current findings; for now clear out action to force user to re-enter
        };

        factory.deleteFinding = function (commissionId, npcProgramChangeReportDto, findingType, finding) {
            // Pass commission ID in case we need to detect CAC, e.g. to add CAC-only generated section

            let found = false;

            findFinding:
            for (var sectionIndex = 0; sectionIndex < npcProgramChangeReportDto.reportJson.length; sectionIndex++) {
                for (var findingTypeIndex = 0; findingTypeIndex < npcProgramChangeReportDto.reportJson[sectionIndex].statementJson.length; findingTypeIndex++) {
                    for (var findingIndex = 0; findingIndex < npcProgramChangeReportDto.reportJson[sectionIndex].statementJson[findingTypeIndex].findings.length; findingIndex++) {
                        if (npcProgramChangeReportDto.reportJson[sectionIndex].statementJson[findingTypeIndex].findings[findingIndex].key === finding.key) {
                            found = true;
                            break findFinding;
                        }
                    }
                }
            }

            if (found) {
                npcProgramChangeReportDto.reportJson[sectionIndex].statementJson[findingTypeIndex].findings.splice(findingIndex, 1);
                if (!npcProgramChangeReportDto.reportJson[sectionIndex].statementJson[findingTypeIndex].findings.length)
                    npcProgramChangeReportDto.reportJson[sectionIndex].statementJson.splice(findingTypeIndex, 1);
            }
        }

        function createFindingTemplate(criterion) {
            return {
                criteria: {
                    ...(criterion && { criteriaId: criterion.criteriaId, criteriaName: getCriteriaFullName(criterion) }),
                    text: null
                },
                key: helperSvc.getNewKey()
            };
        }

        function getCriteriaFullName(criterion) {
            return criterion && `${criterion.criteriaName}${criterion.criteriaDescription ? '. ' : ''}${criterion.criteriaDescription}` || '';
        }

        function sortStatement(npcProgramChangeReportDto) {
            npcProgramChangeReportDto.reportJson.sort((a, b) => a.orderId - b.orderId);
            npcProgramChangeReportDto.reportJson.forEach(section => {
                section.statementJson.sort((a, b) => a.orderNumber - b.orderNumber);
                section.statementJson.forEach(findingType => {
                    findingType.findings.sort((a, b) => {
                        if (!a.criteriaId || !b.criteriaId) return 0;
                        // Old masters criteria should come after new, APPM findings should come after other criteria.
                        [x, y] = [a, b].map(c => c.criteriaId > 19 && c.criteriaId < 27 ? c.criteriaId + 100 : c.criteriaId);

                        return x - y;
                    });
                });
            });
        }

        return {
            getCriteria: factory.getCriteria,
            getFindingTypeOptions: factory.getFindingTypeOptions,
            validateFindingTypeOptions: factory.validateFindingTypeOptions,
            isShortcoming: factory.isShortcoming,
            getAllNPCStatementTemplateData: factory.getAllNPCStatementTemplateData,
            createEmptyTemplate: factory.createEmptyTemplate,
            addFindings: factory.addFindings,
            resetRecommendedAction: factory.resetRecommendedAction,
            deleteFinding: factory.deleteFinding
        };
    };

    module.factory('npcReportTemplateSvc', npcReportTemplateSvc);

})(angular.module('npc'));
