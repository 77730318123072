(function (module) {
    var pevQuestionnaireCtrl = function (pevAppSvc, surveyTemplateSvc, surveyTemplateTypes, helperSvc, alertSvc, pevAppValidationSvc, $scope, componentHelperSvc) {
        
        var model = this;

        model.slug = pevAppSvc.slugs.QUESTIONNAIRE;
        model.data = pevAppSvc.data;
        model.isPreviewMode = pevAppSvc.isPreviewMode();
        model.openQuestionnaireInstructions = pevAppSvc.openQuestionnaireInstructions;

        model.save = function (isSaveBtn) {
            model.data.application.surveyJson = model.survey;
            pevAppSvc.update(model.data.application).then(function () {
                if (isSaveBtn) alertSvc.addAlertSuccess("Suvey answers have been saved.");
                pevAppValidationSvc.invokeValidation();
            });
        };

        var activate = function () {
            if (model.data.application.surveyJson) {
                model.survey = model.data.application.surveyJson;
                componentHelperSvc.invokeOnRendered('appQuestionnaire', $scope);
            } else {
                surveyTemplateSvc.getSurveyTemplateById(surveyTemplateTypes.PEVAPPLICATION).then(function (data) {
                    model.surveyTemplate = data;
                    model.survey = angular.fromJson(data.templateJson);
                    model.data.application.surveyJson = angular.copy(model.survey);
                    pevAppValidationSvc.invokeValidation();
                    componentHelperSvc.invokeOnRendered('appQuestionnaire', $scope);
                });
            }          
        }();
    };
    module.controller('pevQuestionnaireCtrl', pevQuestionnaireCtrl);

}(angular.module('pevApp')));