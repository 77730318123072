(function (module) {
    var statementInstitutionCtrl = function ($q, $state, $stateParams, $uibModal, reviewTeamMemberSvc, programReviewSvc, commissionIds, alertSvc, monthNames,
                                             statementSvc, statementStorageSvc, userReviewSvc, helperSvc, oauth, statementStatuses, programReviewFinalActionSvc, 
                                             statementAdminSvc) {

        var model = this;
        var selectedId = null;
        
        model.errors = [];
        model.reviewTeamId = parseInt($stateParams.reviewTeamId);
        // Deprecated now that we are generated dean draft letters dynamically 
        //model.openLetterToTheDean = statementSvc.openLetterToTheDean;
        model.reviewId = null;
        model.isDataReady = false;

        model.openLetterToTheDean = function (commissionName) {
            var pdfUrl = '/Content/files/Letter_to_the_Dean-{0}.pdf'.format(commissionName);
            alertSvc.openPDFModal(pdfUrl, 'Letter to the Dean');
        };

        model.openStatementPreview = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                size: 'xl',
                controller: 'viewStatementPreviewCtrl',
                resolve: {
                    currentStatement: function () { return model.statementSubmittedToInstitution; }
                }
            });
        };

        model.openStatementLetter = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/viewDeanLetterPreview.html',
                size: 'xl',
                controller: 'viewDeanLetterPreviewCtrl',
                resolve: {
                    currentStatement: function () { return model.statementSubmittedToInstitution; }
                }
            });
        };

        model.openFinalStatementPreview = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                size: 'xl',
                controller: 'viewStatementPreviewCtrl',
                resolve: {
                    currentStatement: function () { return model.finalStatementSubmittedToInstitution; }
                }
            });
        };

        model.openFinalStatementLetter = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/viewDeanLetterPreview.html',
                size: 'xl',
                controller: 'viewDeanLetterPreviewCtrl',
                resolve: {
                    currentStatement: function () { return model.finalStatementSubmittedToInstitution; }
                }
            });
        };

        var activate = function () {
            var dataArray = [];

            var getStatementData = { //this might not be nec any more either
                dataHolder: model,
                dataLocationName: 'statement',
                svcCallback: statementStorageSvc.getByReviewTeamId,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true
            }
            dataArray.push(getStatementData);

            var userReview;//as is this
            if (oauth.isAdmin()) {
                userReview = {
                    dataHolder: statementStorageSvc,
                    dataLocationName: 'data.currentReviewTeam',
                    svcCallback: userReviewSvc.getCurrentReviewTeamAdmin,
                    svcCallbackArguments: [model.reviewTeamId],
                    odataResource: true
                }
                dataArray.push(userReview);

            } else {
                userReview = {
                    dataHolder: statementStorageSvc,
                    dataLocationName: 'data.currentReviewTeam',
                    svcCallback: userReviewSvc.getCurrentReviewTeam,
                    svcCallbackArguments: [model.reviewTeamId],
                    odataResource: true
                }
                dataArray.push(userReview);
            }
            var programReviewsDataSource = {
                dataHolder: statementStorageSvc,
                dataLocationName: 'data.statementProgramReviews',
                svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [model.reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true
            }
            dataArray.push(programReviewsDataSource);

            var programsDataSource = {
                dataHolder: statementStorageSvc,
                dataLocationName: 'data.programs',
                svcCallback: programReviewFinalActionSvc.getReviewsByReviewTeamId,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true
            }
            dataArray.push(programsDataSource);

            helperSvc.getData(dataArray).then(function () {
                statementAdminSvc.getCommissionChairs(statementStorageSvc.data.currentReviewTeam.commissionId, statementStorageSvc.data.currentReviewTeam.reviewYear).then(function () {
                    model.data = statementStorageSvc.data;
                    model.statementSubmittedToInstitution = statementStorageSvc.getStatementSubmittedToInstitution();
                    model.finalStatementSubmittedToInstitution = statementStorageSvc.getFinalStatementSubmittedToInstitution();
                    model.draftSubmittedDate = model.statementSubmittedToInstitution ? getDate(model.statementSubmittedToInstitution.submittedTimestamp) : null;
                    model.finalSubmittedDate = model.finalStatementSubmittedToInstitution ? getDate(model.finalStatementSubmittedToInstitution.submittedTimestamp) : null;
                    model.programs = programReviewSvc.data.programs;

                    if (model.data.currentReviewTeam.commissionId === commissionIds.CAC) {
                        var reviewTeamMemberDataSource = {
                            dataHolder: statementStorageSvc,
                            dataLocationName: 'data.reviewTeamMembers',
                            svcCallback: [reviewTeamMemberSvc.getReviewTeamMembers],
                            svcCallbackArguments: [model.reviewTeamId],
                            orderByProperty: 'teamMemberTypeId',
                            odataResource: true
                        }
                        helperSvc.getData([reviewTeamMemberDataSource]).then(function () {
                            model.isDataReady = true;
                        })
                    } else {
                        model.isDataReady = true;
                    }
                });

                function getDate(date) {
                    date = new Date(date);
                    return monthNames[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear();
                }
            });
        }();
    };
    module.controller('statementInstitutionCtrl', statementInstitutionCtrl);

}(angular.module('statement')));