(function (module) {
    'use strict';

    var personGeneralDisplayCtrl = function ($stateParams, $state, currentUser, personSvc, oauth, volunteerSvc, helperSvc) {
        var model = this;
        var isAdmin = oauth.isAdmin();
        var isVolunteer = volunteerSvc.data.isVolunteer;
        var isDashboard = $state.current.name == 'user' || $state.current.name === 'admin';

        model.title = 'General Information';
        model.personId = isDashboard ? parseInt(currentUser.profile.personId) : $stateParams.personId;

        if (!model.personId && personSvc.data.person) model.personId = personSvc.data.person.person ? personSvc.data.person.person.personId : null;

        model.openEditPage = function () {
            var state = isAdmin ? 'person.single' : 'profile';
            $state.go(state, { personId: model.personId });
        };
        
        var activate = function () {
            if (model.personId) {
                personSvc.getPersonMdlById(model.personId, personSvc.data.person).then(function () {
                    model.person = personSvc.data.person || {};
                    setValues(model.person);
                });
            }
        }();

        function setValues(person) {
            
            model.isDisplayMode = true;
            model.volunteerId = parseInt($stateParams.volunteerId);
            model.fullName = person.fullName;
            model.addresses = person.addresses;
            model.getAddressText = person.getAddressText;
            model.telephones = person.telephones;
            model.emailAddresses = person.emailAddresses;
            model.showGoEditButton = isAdmin || isVolunteer || isDashboard || false;
            model.getFormattedPhone = helperSvc.getFormattedPhone;
        }
    };

    module.controller('personGeneralDisplayCtrl', personGeneralDisplayCtrl);

}(angular.module('person')));