(function (module) {
    var pevAppSummaryCtrl = function (alertSvc, pevAppSvc, componentHelperSvc, $scope, documentSvc, pdfSvc, currentUser, helperSvc) {
        var model = this;

        model.data = pevAppSvc.data;
        //model.slug = pevAppSvc.slugs.INFO;
        model.isPreviewMode = false;//pevAppSvc.isPreviewMode();
        model.isAppManager = pevAppSvc.isAppManager();

        model.print = function () {
            if (model.data.application.stream_id != null) {
                var documentUrl = documentSvc.getFileUrl(model.data.application.stream_id);
                var element = document.getElementById('pdf');
                pdfSvc.renderPDF(documentUrl, element).then(
                    function () {
                        window.print();
                    },
                    function () {
                        alertSvc.addAlertWarning('An error occurred while preparing the document for printing.');
                    });
            } else {
                window.print();
            }
        }

        var activate = function () {
            componentHelperSvc.invokeOnRendered('applicationSummary', $scope);

            var reviewVariables = pevAppSvc.setReviewedVariables(model.data.application);

            model.isReviewedCheck = reviewVariables.isReviewedCheck;
            model.societyIds = reviewVariables.societyIds;
            model.societyNameReviewing = reviewVariables.societyNameReviewing;
            model.reviewedWithinUsersSociety = reviewVariables.reviewedWithinUsersSociety;

            model.isReviewedCheckDisabled = !model.reviewedWithinUsersSociety && model.isReviewedCheck;
        }();
    };
    module.controller('pevAppSummaryCtrl', pevAppSummaryCtrl);

}(angular.module('pevApp')));