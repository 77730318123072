(function (module) {
    var npcUserCtrl = function ($uibModal, $state, $timeout, $window, npcStatusNames, contactRoleTypes, currentUser, helperSvc,
        barSvc, alertSvc, membershipSvc, codeSvc, organizationSvc, messageSvc, npcSvc, commissionAbbreviations, currentUserHelper,
        oauth, $q, programSvc, programReviewSvc, eventSvc, $scope, $stateParams) {

        var model = this;
        var dataSourceArray = [];

        model.title = 'Notification of Program Change';
        model.isDataReady = false;
        model.npcRequests = [];
        model.organizationName = "";
        model.commissionAbbreviations = commissionAbbreviations;
        model.userHasMultipleOrgs = false;
        model.showSpinner = false;
        model.showSpinnerSearch = false;
        model.getLastProgramProgressStatus = npcSvc.getLastProgramProgressStatus;

        eventSvc.listen(
            () => {
                if (!model.selectedOrganizationId) return;

                model.isDataReady = false;
                barSvc.showSpinner();
                npcSvc.getNpcsForOranizationUser(model.selectedOrganizationId).then(function (data) {
                    model.npcRequests = data;
                    barSvc.hideSpinner();
                    model.isDataReady = true;
                });
            },
            'refreshNpcData',
            $scope
        );

        barSvc.listenForShow(function () {
            model.showSpinner = true;
        });

        barSvc.listenForHide(function () {
            model.showSpinner = false;
        });

        model.openNPC = function (npc) {
            model.selectedOrganizationId = npc.organizationId;
            $state.go('npcUser.detail', { npcId: npc.npcId, organizationId: model.selectedOrganizationId, isInstitutionUser: true }); 
        };

        model.selectInstitution = function (organization) {
            barSvc.showSpinner();
            organizationSvc.getOrgByIdOdata(organization.organizationId).then(function (orgData) {
                model.organizationData = orgData;
                model.organizationName = model.organizationData.currentOrganizationDetailDto.organizationName;
                model.selectedOrganizationId = model.organizationData.organizationId;
                $q.all([
                    npcSvc.getNpcsForOranizationUser(model.selectedOrganizationId),
                    loadCurrentPrograms()
                ]).then(([npcs, programs]) => {
                    barSvc.hideSpinner();
                    model.npcRequests = npcs;
                    model.programs = programs;
                    model.orgSelected = true;
                    primeServer();
                    $state.go('npcUser.programchanges', { organizationId: model.selectedOrganizationId }, { notify: false })
                });
            });
        }

        model.submitNewRequest = function () {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcAddNotification.html',
                size: 'md',
                controller: 'npcAddNotificationModalCtrl',
                resolve: {
                    organization: () => model.organizationData,
                    programs: () => model.programs,
                    npc: () => model.npcRequests.filter(npc => !npc.submittedDate),
                    change: () => null,
                    onSave: () => (() => {
                        // Refresh local data for when/if user navigates back here (e.g. via breadcrumbs)
                        return npcSvc.getNpcsForOranizationUser(model.selectedOrganizationId).then(function (data) {
                            model.npcRequests = data;
                        });
                    })
                }
            });

            modalInstance.result.then(result => {
                model.openNPC(result);
            });
        };

        function getUsersOrgsFromRoles() {
            var organizationIds = [];

            var rolesAllowed = [
                contactRoleTypes.PRESIDENT,
                contactRoleTypes.PROVOST,
                contactRoleTypes.DELEGATEFORPRESIDENT,
                contactRoleTypes.PRIMARY,
                contactRoleTypes.SECONDARY,
                contactRoleTypes.DELEGATEFORPRIMARY
            ];
            var organizationIds = [];

            var userInfo = oauth.getUserInfo();
            var roles = helperSvc.getFilteredArray(userInfo.userRoles, 'roleId', rolesAllowed, true);

            for (var x = 0; x < userInfo.userRoles.length; x++) {
                var role = userInfo.userRoles[x];
                if (helperSvc.arrContains(rolesAllowed, role.roleId)) {
                    if (!organizationIds.some(function (arrVal) {
                        return role.organizationId === arrVal;
                    })) {
                        organizationIds.push(role.organizationId);
                    }
                }
            }

            return organizationIds;
        }

        var activate = function () {
            barSvc.showSpinner();
            var organizationIds = getUsersOrgsFromRoles();

            if ($stateParams.organizationId) {
                if (organizationIds.indexOf(parseInt($stateParams.organizationId)) > -1) {
                    model.selectedOrganizationId = $stateParams.organizationId;
                } 
            }

            if (organizationIds.length === 1 || model.selectedOrganizationId > 0) {

                if (!model.selectedOrganizationId)
                    model.selectedOrganizationId = organizationIds[0];
                
                model.orgSelected = true;

                organizationSvc.getOrgByIdOdata(model.selectedOrganizationId).then(function (orgData) {
                    model.organizationData = orgData;
                    model.organizationName = model.organizationData.currentOrganizationDetailDto.organizationName;
                    model.programs = [];

                    $q.all([
                        npcSvc.getNpcsForOranizationUser(model.selectedOrganizationId),
                        loadCurrentPrograms()
                    ]).then(([npcs, programs]) => {
                        model.npcRequests = npcs;
                        model.programs = programs;
                        barSvc.hideSpinner();
                        model.isDataReady = true;
                        model.orgSelected = true;
                        primeServer();
                    });

                });

            } else if (organizationIds.length > 1) {
                organizationSvc.getOrgsbyOrgIds(organizationIds).then(function (data) {
                    model.userInstitutions = data.data.value;
                    barSvc.hideSpinner();
                    model.isDataReady = true;
                });
                model.userHasMultipleOrgs = true;
                model.orgSelected = false;
            } else {
                barSvc.hideSpinner();
                model.isDataReady = true;
                model.orgSelected = true;
            }
            
        }();

        function loadCurrentPrograms() {
            return npcSvc.getEligiblePrograms(model.selectedOrganizationId);
        }

        function primeServer() {
            // Prime the server-side caches
            const cutoffDate = (new Date((new Date()).getFullYear() - 1, 0, 0)).toISOString();
            model.npcRequests
                ?.filter(npc => !npc.submittedDate || npc.submittedDate >= cutoffDate)
                .forEach(npc => npcSvc.getNPCById(npc.npcId));
        }

    };
    module.controller('npcUserCtrl', npcUserCtrl);

}(angular.module('npc')));