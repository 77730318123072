(function (module) {

    var manageContactsCtrl = function ($stateParams, $state, $scope, contactSvc, contactRoleTypes, barSvc, eventSvc, helperSvc, alertSvc, rfrSvc, oauth,
                                       currentOrganization, currentOrganizationHelper, organizationTypes, organizationSvc, rfrValidationSvc, currentUser) {
        var model = this;
        var organizationTypeId = currentOrganization.profile.organizationTypeId;

        model.organizationName = currentOrganization.profile.organizationName;
        model.isCreateMode = true;
        model.isRfrMode = $state.current.name.contains('rfe') || $state.current.name.contains('rfr');
        model.isUserReview = $state.current.name.toLowerCase().contains('userreview');
        model.organizationId = parseInt($stateParams.organizationId) || null;

        if (model.organizationId && !$scope.isPreviewMode) {
            $scope.isPreviewMode = oauth.hasRole(model.organizationId, contactRoleTypes.PROGRAM) &&
                !oauth.hasRole(model.organizationId, contactRoleTypes.PRESIDENT) &&
                !oauth.hasRole(model.organizationId, contactRoleTypes.DELEGATEFORPRESIDENT) &&
                !oauth.hasRole(model.organizationId, contactRoleTypes.PRIMARY) &&
                !oauth.hasRole(model.organizationId, contactRoleTypes.DELEGATEFORPRIMARY) &&
                !oauth.hasRole(model.organizationId, contactRoleTypes.SECONDARY);
        }

        function activate() {
            // Determine if the org is an institution or not
            model.isInstitution = (organizationTypeId === organizationTypes.INSTITUTION);

            if (model.isInstitution) currentOrganizationHelper.loadDelegatedCommissions(model.organizationId).then(function () {
                currentOrganization.save();
            });
        }

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        if (model.isRfrMode) {
            model.isDataReady = true;
            model.isChildDataReady = false;
            barSvc.showSpinner();
        } else {
            model.isDataReady = false;
            model.isChildDataReady = true;
            barSvc.showBar();
        }

        eventSvc.listen(refreshPrimaryContactsAndDelegates, 'refreshPrimaryContactsAndDelegates', $scope);
        eventSvc.listen(refreshPresidentContactsAndDelegates, 'refreshPresidentContactsAndDelegates', $scope);
        eventSvc.listen(refreshBillingContacts, 'refreshBillingContacts', $scope);
        eventSvc.listen(refreshProgramContacts, 'refreshProgramContacts', $scope);
        eventSvc.listen(refreshSecondaryContacts, 'refreshSecondaryContacts', $scope);
        eventSvc.listen(refreshOtherContacts, 'refreshOtherContacts', $scope);

        model.generateContactBroadcastMsg = function (type) {
            type = type.charAt(0).toUpperCase() + type.slice(1);
            var typeName = 'refresh' + type + 'Contacts';

            if (type === 'Primary' || type === 'President') {
                typeName += 'AndDelegates';
            }

            return typeName;
        };

        model.delete = function (contact, type, delegates) {
            alertSvc.confirmDelete((contact.firstName + ' ' + contact.lastName), deleteFunc, contact, type, delegates);

            function deleteFunc() {
                var broadcastType = model.generateContactBroadcastMsg(type);

                if (delegates) {
                    for (var i = 0; i < delegates.length; i++) {
                        for (var j = 0; j < delegates[i].designationViewModels.length; j++) {
                            if ( (delegates[i].designationViewModels[j].designationId === contact.personId) && (delegates[i].endDate === null) ) {
                                contactSvc.delete(delegates[i]).then(function (data) {
                                    // do nothing
                                });
                            }
                        }
                    }
                }

                contactSvc.delete(contact, model.isRfrMode).then(function (data) {
                    if (model.isRfrMode) {
                        rfrValidationSvc.invokeValidation();
                        if (contact.roleId === contactRoleTypes.PROGRAM) {
                            rfrSvc.deleteNewProgramContact(contact);
                        }
                    }

                    // generateContactBroadcastMsg will generate messages to create whichever of the following broadcasts is appropriate...
                        // eventSvc.broadcast('refreshPrimaryContactsAndDelegates');
                        // eventSvc.broadcast('refreshPresidentContactsAndDelegates');
                        // eventSvc.broadcast('refreshProgramContacts');
                        // eventSvc.broadcast('refreshSecondaryContacts');
                        // eventSvc.broadcast('refreshOtherContacts');
                    eventSvc.broadcast(broadcastType);
                    alertSvc.addAlertSuccess('Contact successfully deleted.');
                });
            }
        };

        var primaryContactsDataSource = {
            dataHolder: model,
            dataLocationName: 'primaryContacts',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.PRIMARY],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        var programContactsDataSource = {
            dataHolder: model,
            dataLocationName: 'programContacts',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.PROGRAM],
            helperCallback: helperSvc.getValue,
            optionalCallback: function () {
                var newProgramContacts = rfrSvc.getNewProgramContacts();
                for (var i = 0; i < newProgramContacts.length; i++) {                 
                   
                    var newprogramContact = newProgramContacts[i];
                    
                    for(var j =0; j < model.programContacts.length; j++){
                        if (newprogramContact.personId === model.programContacts[j].personId) {
                            var programContact = model.programContacts[j];

                            for (var k = 0; k < programContact.designationViewModels.length; k++) {
                                //Cant save this programContact
                                if (programContact.designationViewModels[k].designationId === null && programContact.designationViewModels[k].designationName === null) {
                                    programContact.designationViewModels[k].designationId = 0;
                                    programContact.designationViewModels[k].designationName = newprogramContact.programName;
                                    programContact.designationViewModels[k].designationTypeId = contactRoleTypes.PROGRAM;
                                    break;
                                }
                            }
                        }
                    }                                       
                }
                // Remove stray designationViewModels left over when a new program with contacts is deleted 
                // (and its contacts are removed from RFR but not from OrganizationUserDesignation table).
                for (var i = 0; i < model.programContacts.length; i++) {
                    var designations = model.programContacts[i].designationViewModels;
                    for (var j = designations.length - 1; j >= 0; j--) {
                        if (designations[j].designationId === null) {
                            designations.splice(j, 1);
                        }
                    }
                }
                // Now remove contacts that aren't contacts for any existing or new programs.
                for (var i = model.programContacts.length - 1; i >= 0; i--) {
                    if (model.programContacts[i].designationViewModels.length === 0) {
                        model.programContacts.splice(i, 1);
                    }
                }
            },
            optionalCallbackArguments: [],
            orderByProperty: 'organizationUserId'
        };

        var secondaryContactsDataSource = {
            dataHolder: model,
            dataLocationName: 'secondaryContacts',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.SECONDARY],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        var presidentContactsDataSource = {
            dataHolder: model,
            dataLocationName: 'presidentContacts',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.PRESIDENT],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        var billingContactsDataSource = {
            dataHolder: model,
            dataLocationName: 'billingContacts',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.BILLING],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        var otherContactsDataSource = {
            dataHolder: model,
            dataLocationName: 'otherContacts',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, null, useExclusions = true],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        var primaryDelegatesDataSource = {
            dataHolder: model,
            dataLocationName: 'primaryDelegates',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.DELEGATEFORPRIMARY],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        var presidentDelegatesDataSource = {
            dataHolder: model,
            dataLocationName: 'presidentDelegates',
            svcCallback: [contactSvc.getContactsWithPerson, contactSvc.getContactsWithDesignations],
            svcCallbackArguments: [model.organizationId, contactRoleTypes.DELEGATEFORPRESIDENT],
            helperCallback: helperSvc.getValue,
            orderByProperty: 'organizationUserId'
        };

        if (!organizationTypeId) {
            organizationSvc.getOrgByIdOdata(model.organizationId).then(function (data) {
                organizationTypeId = data.organizationTypeId;
                currentOrganization.profile.organizationTypeId = data.organizationTypeId;
                currentOrganization.profile.organizationTypeName = data.organizationTypeName;
                currentOrganization.save();
                activate();
                loadData();
            });
        }
        else {
            activate();
            loadData();
        }

        function refreshPrimaryContactsAndDelegates() {
            var dataSourceArray = [
                primaryContactsDataSource,
                primaryDelegatesDataSource
            ];

            helperSvc.getData(dataSourceArray);
        }

        function refreshPresidentContactsAndDelegates() {
            var dataSourceArray = [
                presidentContactsDataSource,
                presidentDelegatesDataSource
            ];

            helperSvc.getData(dataSourceArray);
        }

        function refreshBillingContacts() {
            var dataSourceArray = [billingContactsDataSource];

            helperSvc.getData(dataSourceArray);
        }

        function refreshProgramContacts() {
            var dataSourceArray = [programContactsDataSource];

            helperSvc.getData(dataSourceArray);
        }

        function refreshSecondaryContacts() {
            var dataSourceArray = [secondaryContactsDataSource];

            helperSvc.getData(dataSourceArray);
        }

        function refreshOtherContacts() {
            var dataSourceArray = [otherContactsDataSource];

            helperSvc.getData(dataSourceArray);
        }

        function loadData() {
            var dataSourceArray = [otherContactsDataSource];

            if (model.isInstitution) {
                dataSourceArray.push(
                    primaryContactsDataSource,
                    primaryDelegatesDataSource,
                    programContactsDataSource,
                    secondaryContactsDataSource,
                    presidentContactsDataSource,
                    billingContactsDataSource,
                    presidentDelegatesDataSource
                );
            }

            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                if (model.isRfrMode) {
                    model.isChildDataReady = true;
                    barSvc.hideSpinner();
                } else {
                    model.isDataReady = true;
                    barSvc.hideBar();
                }
            });
        }
    };

    module.controller('manageContactsCtrl', manageContactsCtrl);

}(angular.module('contacts')));