(function (module) {

    var volunteerSvc = function ($q, $http, $odata, amsConst, odataServiceFactory, odataSvc, currentUser, helperSvc) {
        var config = {
            apiPath: '/Volunteer',
            keyName: 'volunteerId',
            dataItemName: 'volunteer'
        };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        var factory = new odataServiceFactory(config);

        factory.data = {
            isAdmin: null,
            isVolunteer: null,
            isSocietyManager: null,
            resultMsg: null,
            volunteers: null,
            searchOptions: null,
            ethnicityTypeOptions: [],
            volunteerSearchDeregistration: null
        };

        factory.update = function (volunteer, noStoredData) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(config.apiPath, 'volunteerId', volunteer);
            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                promise.then(function (data) {
                    factory.data.volunteer = data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.getVolunteerIdByPersonId = function (personId) {
            var oSvc = factory.getOdataSvc();
            personId = parseInt(personId);

            oSvc.getSource(config.apiPath, config.keyName).odata()
                .filter('personId', personId)
                .select('volunteerId')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getPersonIdByVolunteerId = function (volunteerId) {
            var oSvc = odataSvc.get();
            volunteerId = parseInt(volunteerId);

            oSvc.getSource(config.apiPath, config.keyName).odata()
                .filter('volunteerId', volunteerId)
                .select('personId')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };


        factory.advancedSearch = function (searchOptions, exportSearch) {
            var searchApi = '/GetVolunteerSearch';
            var orderBy = "personName";
            if (exportSearch === true) {
                searchApi = '/GetVolunteerSearchDetail';
                orderBy = "lastName";
            }

            var oSvc = factory.getOdataSvc();
            var societyId = (searchOptions.societyCode) ? searchOptions.societyCode : null;
            var path = oSvc.getPathWithParameter(searchApi, 'societyId', societyId);
            var hasDisciplineOption = (searchOptions.societyStatus) ? true : false;
            var hasNameOption = (searchOptions.personName) ? true : false;
            var hasCommissionOption = (searchOptions.commissionId) ? true : false;
            var hasProgramAreaOption = (searchOptions.programAreas && searchOptions.programAreas.length > 0) ? true : false;
            var usePredicates = hasDisciplineOption || hasNameOption || hasProgramAreaOption || hasCommissionOption || false;

            if (usePredicates) {
                var predicates = [];
                var combinedPredicate;

                if (hasDisciplineOption) {
                    predicates.push(new $odata.Predicate('societyStatus', '=', searchOptions.societyStatus));
                }

                if (hasCommissionOption) {
                    var commissionId = parseInt(searchOptions.commissionId);
                    predicates.push(new $odata.Predicate('commissionId', '=', searchOptions.commissionId));
                }

                if (hasNameOption) {
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'personName', searchOptions.personName)), '>', -1));
                }

                if (hasProgramAreaOption) {
                    var combinedProgramAreasPredicate = [];

                    for (var i = 0; i < searchOptions.programAreas.length; i++) {
                        combinedProgramAreasPredicate.push(new $odata.Predicate('x99', '=', parseInt(searchOptions.programAreas[i].codeKey)))
                    }

                    if (combinedProgramAreasPredicate.length > 0) {
                        predicates.push($odata.Predicate.or(combinedProgramAreasPredicate))
                    }
                }

                //not sure if using 'and' for one predicate is bad, need to check
                if (predicates.length >= 1) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }
                
                oSvc.getSource(path).odata()
                  .filter(combinedPredicate)
                  .orderBy(orderBy)
                  .query()
                  .$promise
                  .then(oSvc.onVolunteerSearchSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(path).odata()
                    .orderBy(orderBy)
                    .query()
                    .$promise
                    .then(oSvc.onVolunteerSearchSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        factory.getSocietyOptionsByCurrentUser = function (societyOptions) {
            var arrContains = helperSvc.arrContains;
            var allowedSocietyOptions = [];

            for (var i = 0; i < societyOptions.length; i++) {
                var society = societyOptions[i];

                for (var j = 0; j < currentUser.profile.userRoles.length; j++) {
                    var role = currentUser.profile.userRoles[j];

                    //societyOptions object could be codeKey object or organization object
                    if ((role.organizationId == society.organizationId || role.organizationId == society.codeKey) &&
                            !arrContains(allowedSocietyOptions, society)) {
                        allowedSocietyOptions.push(society);
                    }
                }
            }

            return allowedSocietyOptions;
        };

        factory.getVolunteerSearchDetailWithOption = function (searchOptions) {
            var societyId, societyStatus, personName, disciplineIds = [];

            societyId = searchOptions.societyCode;
            societyStatus = searchOptions.societyStatus || '';
            personName = searchOptions.personName || '';
            angular.forEach(searchOptions.programAreas, function (programArea) {
                disciplineIds.push(programArea.codeKey);
            });
            disciplineIds = disciplineIds.toString();

            var path = amsConst.webApiUrl + '/odata/GetVolunteerSearchDetailWithOption(societyId={0}, societyStatus=\'{1}\', personName=\'{2}\', disciplineIds=\'{3}\')';

            // Remove forward slashes (e.g. as in 'Disapproves/Inactive/Pending') so that they don't break the URL (can't double escape with current .NET server)
            societyStatus = societyStatus.replace(/\//g, '~');
            // Replace null with 'null'
            societyId = societyId || 'null';

            return $http.get(path.format(societyId, societyStatus, personName, disciplineIds)).then(onSuccess, onFailure);
        };

        factory.createDefaultVolunteerFromPerson = function (personId) {
        
            var api = '/webapi/odata/VolunteerFromPersonInsert';

            var data = { personId: personId };

            return $http.post(api, data);
        }
     
        return {
            data: factory.data,
            get: factory.get,
            update: factory.update,
            // create, delete, getByForeignKey unimplemented
            getVolunteerIdByPersonId: factory.getVolunteerIdByPersonId,
            advancedSearch: factory.advancedSearch,
            getSocietyOptionsByCurrentUser: factory.getSocietyOptionsByCurrentUser,
            getVolunteerSearchDetailWithOption: factory.getVolunteerSearchDetailWithOption,
            getPersonIdByVolunteerId: factory.getPersonIdByVolunteerId,
            createDefaultVolunteerFromPerson: factory.createDefaultVolunteerFromPerson
        };
    };
    module.factory('volunteerSvc', volunteerSvc);

})(angular.module('volunteer'));