(function (module) {

    var reviewInformationCtrl = function ($scope, $uibModal, helperSvc, $filter, codeSvc, reviewTeamSvc, reviewSvc, alertSvc) {
        var model = this;
        model.title = 'Review Information';
        model.parent = $scope.$parent.model;
        model.defaultText = 'Not entered';
        model.data = reviewTeamSvc.data;

        model.editReview = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/reviewEdit.html',
                size: 'lg',
                controller: 'reviewEditCtrl',
                resolve: {
                    parent: function () {
                        return model.parent;
                    },
                    review: function () {
                        return model.data.reviewTeam
                    }
                }
            });
        };

        model.deleteReview = function () {
            alertSvc.confirmDelete((model.data.reviewTeam.reviewDto.reviewYear + ' ' + model.data.reviewTeam.commissionName + ' ' + model.data.reviewTeam.reviewTypeName), deleteFunc);

            function deleteFunc() {
                reviewTeamSvc.deleteOdata(model.data.reviewTeam).then(function () {
                    model.parent.isReviewSelected = false;               
                    alertSvc.addAlertSuccess("Review successfully deleted.");
                    updateReviewData();
                });
            }
        };

        model.hasReviewDate = function (review) {
            var hasDates = false;

            if (review) {

                if (review.visitStartDate || review.visitEndDate) {
                    hasDates = true;
                }
            }

            return hasDates;
        };

        model.formatDate = helperSvc.formatDate;
        model.getBooleanText = helperSvc.getBooleanText;

        function updateReviewData() {

            reviewSvc.getReviewsByOrganizationId(model.parent.organizationId, true).then(function () {
                model.parent.refreshReviewGroups();
            });
            reviewSvc.getReviewsByOrganizationId(model.parent.organizationId, false).then(function () {
                model.parent.refreshReviewGroups();
            });
        }

    };

    module.controller('reviewInformationCtrl', reviewInformationCtrl);

}(angular.module('review')));