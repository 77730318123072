(function (module) {
    var pevSocietyCtrl = function (helperSvc, alertSvc, pevAppSvc) {
        var model = this;

        model.data = pevAppSvc.data;
        model.slug = pevAppSvc.slugs.SOCIETY;
        model.isPreviewMode = pevAppSvc.isPreviewMode();
        model.openSocietyInstructions = pevAppSvc.openSocietyInstructions;
    };
    module.controller('pevSocietyCtrl', pevSocietyCtrl);

}(angular.module('pevApp')));