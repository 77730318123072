(function (module) {
    var manageObserversCtrl = function ($uibModal, helperSvc, codeSvc, volunteerSvc, alertSvc, oauth, trainingSvc, trainingStatusIds, commissionAbbreviations) {

        var model = this;
        var selectedId = null;
        model.trainingStatusIds = trainingStatusIds;
        model.isDataReady = false;
        model.isAdmin = oauth.isAdmin();
        model.commissionNames = commissionAbbreviations;

        model.searchOptions = { trainingYear: trainingSvc.data.trainingList[0].startDate}

        model.addObserver = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/addObserver.html',
                size: 'md',
                controller: 'addObserverCtrl',
                resolve: {
                    societyOptions: function () {
                        return model.societyOptions;
                    },
                    searchOptions: function(){
                        return model.searchOptions;
                    }
                }
            });
        };

        model.changeStatus = function (observer) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/changeTrainingStatus.html',
                size: 'md',
                controller: 'changeTrainingStatusCtrl',
                resolve: {
                    pevc: function () {
                        return observer;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    },
                    isObserver: function () {
                        return true;
                    }
                }
            });
        };

        model.canChangeStatus = function (obs) {
            return model.isAdmin && obs.trainingStatusId < model.trainingStatusIds.ATTENDED;
        };

        model.deleteObserver = function (observer) {
            alertSvc.confirmDelete(observer.firstName + " " + observer.lastName + " (Observer)", deleteObserver);

            function deleteObserver() {
                trainingSvc.deleteTrainingObserver(observer.trainingPersonId).then(function () {
                    alertSvc.addAlertSuccess("Observer succesfully deleted.");
                    trainingSvc.getTrainingObservers(model.searchOptions);
                });
            }
        };

        model.assignFacilitator = function (observer) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/assignFacilitator.html',
                size: 'md',
                controller: 'assignFacilitatorCtrl',
                resolve: {
                    pevc: function () {
                        return observer;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    },
                    isObserver: function () {
                        return true;
                    }
                }
            });
        };

        model.removeFacilitator = function (observer) {
            alertSvc.confirmDelete(observer.trainingFacilitatorName + " (Facilitator for " + observer.firstName + " " + observer.lastName + ")", deleteFacilitator);

            function deleteFacilitator() {
                trainingSvc.deletePersonFacilitator(observer.trainingPersonFacilitatorId).then(function () {
                    trainingSvc.getTrainingObservers(model.searchOptions);
                    alertSvc.addAlertSuccess("Facilitator succesfully removed.");
                });
            }
        };

        model.getCommissionName = function (commissionId) {
            var commission = helperSvc.getFirstMatch(model.trainingCommissions, 'codeKey', commissionId);
            return commission.codeName;
        }

        model.getTrainingSessionName = function (trainingSessionId) {

            if (trainingSessionId != null) {
                var trainingSession = helperSvc.getFirstMatch(model.data.trainingSessions, 'trainingSessionId', trainingSessionId);
                return trainingSession.sessionName;
            }
            
            return "N/A"
        }

        model.csvHeaders = ["First Name", "Last Name", "Email", "Session", "Facilitator Name", "Commission Name"];
        model.csvColumns = ["firstName", "lastName", "email", "sessionName","trainingFacilitatorName", "commissionName"];
        model.export = function () {
            model.showSpinnerExport = true;
            var exportData = [];
            var exportingObservers = angular.copy(model.data.trainingObserverList);
            var finalList = [];
            
            angular.forEach(exportingObservers, function (pevc) {
                var tempPevc = {
                    firstName: pevc.firstName,
                    lastName: pevc.lastName,
                    email: pevc.email,
                    sessionName: model.getTrainingSessionName(pevc.trainingSessionId),
                    trainingFacilitatorName: pevc.trainingFacilitatorName || 'N/A',
                    commissionName: model.commissionNames[pevc.commissionId]
                };
                finalList.push(tempPevc);
            });

            model.showSpinnerExport = false;
            return finalList;
        };

        var activate = function () {
            var dataArray = [];

            //var trainingYear = new Date(trainingSvc.data.trainingSessions[0].startDate).getFullYear();
            model.searchOptions.trainingId = trainingSvc.data.trainingSessions[0].trainingId;

            var getCommissionData = {
                dataHolder: model,
                dataLocationName: 'trainingCommissions',
                svcCallback: codeSvc.getCommissions,
                helperCallback: helperSvc.getResults
            };
            dataArray.push(getCommissionData);
            
            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;

                trainingSvc.getTrainingObservers(model.searchOptions).then(function (data) {
                    model.isDataReady = true;
                });
            });
        }();
    };
    module.controller('manageObserversCtrl', manageObserversCtrl);

}(angular.module('training')));