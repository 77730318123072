(function (module) {

    var editProgramReviewCtrl = function ($q, $scope, $uibModalInstance, alertSvc, programReview, filters, actionSvc, programReviewSvc, programReviewFinalActionSvc, helperSvc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Edit Program Review';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.programReviewModel.accreditationStartDate > model.programReviewModel.accreditationEndDate) {
                alertSvc.addModalAlertWarning('Start cannot be after end date.');
                return;
            }

            model.programReview.actionCode = model.programReviewModel.actionCode;
            model.programReview.accreditationStartDate = model.programReviewModel.accreditationStartDate;
            model.programReview.accreditationEndDate = model.programReviewModel.accreditationEndDate;
            model.programReview.finalActionNote = model.programReviewModel.finalActionNote;

            programReviewSvc.update(model.programReview).then(
                function onSuccess() {
                    programReviewFinalActionSvc.getProgramReviewsByReviewId(model.programReviewModel.reviewId, filters, 'programReviewVotedActionDtos').then(function (data) {
                        $uibModalInstance.close();
                        alertSvc.addAlertSuccess('Program Review was successfully saved.');
                    });
                },
                function onFailure() {
                    alertSvc.addAlertWarning('Program Review could not be saved at this time.');
                });
        };

        model.prepopulateDates = function (actionCode) {
            var startYear, endYear, resetDates = false;
            
            switch (actionCode) {
                case 'NGR':
                    startYear = model.programReviewModel.reviewYear;
                    if (model.programReviewModel.lastAction === 'NEW') {
                        var ngrYear = model.programReviewModel.ngrYear || model.programReviewModel.reviewYear
                        endYear = ngrYear > model.programReviewModel.reviewYear ? ngrYear : ngrYear + 6 ;
                    } else {
                        endYear = model.programReviewModel.reviewYear + 6;
                    }
                    break;
                case 'IR':
                case 'IV':
                case 'SCR':
                case 'SCV':
                    startYear = model.programReviewModel.reviewYear;
                    endYear = model.programReviewModel.reviewYear + 2;
                    break;
                case 'RE':
                case 'VE':
                    startYear = model.programReviewModel.reviewYear;
                    endYear = model.programReviewModel.ngrYear;
                    break;
                default:
                    resetDates = true;
                    break;
            }

            if (resetDates) {
                model.programReviewModel.accreditationStartDate = (model.programReviewModel.lastAction === 'NEW' && model.programReviewModel.actionCode !== 'NA'  && model.programReviewModel.newProgramRetroactiveDate) ? model.programReviewModel.newProgramRetroactiveDate : null;
                model.programReviewModel.accreditationEndDate = null;
            } else {
                var startDate = (model.programReviewModel.lastAction === 'NEW') ?
                (model.programReviewModel.newProgramRetroactiveDate ? model.programReviewModel.newProgramRetroactiveDate : '10/1/' + startYear) : '10/1/' + startYear;
                var endDate = '9/30/' + endYear;
                model.programReviewModel.accreditationStartDate = new Date(startDate);
                model.programReviewModel.accreditationEndDate = new Date(endDate);
            }
            
        };
        
        model.isValid = function () {
            return model.programReviewModel.actionCode === 'NA' || (model.programReviewModel.accreditationStartDate && model.programReviewModel.accreditationEndDate);
        };

        var activate = function () {
            model.programReviewModel = angular.copy(programReview);

            if (model.programReviewModel.accreditationStartDate) {
                model.programReviewModel.accreditationStartDate = new Date(model.programReviewModel.accreditationStartDate);
            } else {
                if (model.programReviewModel.lastAction === 'NEW' && model.programReviewModel.newProgramRetroactiveDate) {
                    model.programReviewModel.accreditationStartDate = new Date(model.programReviewModel.newProgramRetroactiveDate);
                }
            }

            model.programReviewModel.accreditationEndDate = model.programReviewModel.accreditationEndDate ?
                new Date(model.programReviewModel.accreditationEndDate) : model.programReviewModel.accreditationEndDate;

            var filterActions = actionSvc.getPossibleActions(model.programReviewModel.programReviewTypeCode === 'IGR' ? 'NEW' : model.programReviewModel.lastAction,
                model.programReviewModel.reviewYear == model.programReviewModel.ngrYear - 6);
            var actionsPromise = actionSvc.getActions(filterActions);
            var programReviewPromise = programReviewSvc.getByProgramReviewId(programReview.programReviewId);
            
            $q.all([actionsPromise, programReviewPromise]).then(
                function onSuccess(data) {
                    model.programReview = data[1];
                    model.possibleActions = data[0];
                    model.dataIsReady = true;
                   
                },
                function onFailure() {
                    alertSvc.addAlertWarning('Program Review could not be loaded at this time.');
                }
            );
        }();
    };

    module.controller('editProgramReviewCtrl', editProgramReviewCtrl);

}(angular.module('statement')));