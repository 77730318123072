(function (module) {

    var rfrLocationCountEditCtrl = function ($scope, $uibModalInstance, rfrSvc, alertSvc, rfrValidationSvc, helperSvc) {
        var model = $scope;
        model.title = 'Institution Locations';
        model.data = {};
        model.numberPattern = '\\d+';

        model.save = function () {
            rfrSvc.update(model.rfr).then(function () {
                alertSvc.addAlertSuccess("Review options successfully updated.");
                rfrValidationSvc.invokeValidation();
                closeModal();
            });
        };

        model.cancel = function () {
            closeModal();
        }

        var closeModal = function () {
            $uibModalInstance.close();
        };

        activate();

        function activate() {
            angular.copy(rfrSvc.data, model.data);
            model.rfr = model.data.rfr;
        }
    };

    module.controller('rfrLocationCountEditCtrl', rfrLocationCountEditCtrl);

}(angular.module('rfr')));