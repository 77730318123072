(function (module) {
    var groupsAndRolesCtrl = function ($filter, $state, $stateParams, $scope, $uibModal, personSvc, groupsAndRolesSvc, barSvc, codeSvc, helperSvc, organizationSvc, roleSvc, commissionAbbreviations, alertSvc, membershipSvc, currentUser, organizationTypes) {

        var model = this;

        model.title = 'User Groups and Roles';
        model.users = [];
        model.resultMsg = 'Waiting for Search...';
        model.showSpinner = true;
        model.impersonate = personSvc.impersonate;
        model.searchOptions = { roles: null, designation: null, term: null, cycleYear: null, asofDate:null, personName: '' };
        model.dataIsReady = false;
        model.showSpinnerExport = false;
        model.isCycleYearFilter = false;

        model.csvHeaders = ["First Name", "Last Name", "Organization Name", "Organization Type Name", "Society Name", "Role Name","PositionTerm","Designations", "Start Date", "End Date", "Email Address","Note"];
        model.csvColumns = ['firstName', 'lastName', 'organizationName', 'organizationTypeName', 'societyName', 'roleName','positionTerm','designations','startDate', 'endDate', 'emailAddress','note'];
        model.exportCSV = function () {
            model.showSpinnerExport = true;
            var groupsAndRolesExport = angular.copy(model.data.userGroupsAndRoles);

            angular.forEach(groupsAndRolesExport, function (role) {
                
                role.startDate = role.startDate ? helperSvc.formatDate(role.startDate, null, true).toLocaleDateString().replace(/\u200E/g, '') : '';
                role.endDate = role.endDate ? helperSvc.formatDate(role.endDate, null, true).toLocaleDateString().replace(/\u200E/g, '') : '';

                role.designations = '';
                if (role.designationViewModels && role.designationViewModels.length > 0) {
                    role.designations = role.designationViewModels.reduce((mergeStr, des) => {
                        if (mergeStr !== '')
                            mergeStr = mergeStr + ',';
                        mergeStr = mergeStr + des.designationName;
                        return mergeStr;
                    }, ''); //designations
                }
            })

            groupsAndRolesExport = $filter('orderBy')(groupsAndRolesExport, 'lastName');

            model.showSpinnerExport = false;
            return groupsAndRolesExport;
        };

        model.getHistoryReport = function (person) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/report/templates/preview.html',
                size: 'xl',
                controller: 'previewCtrl',
                resolve: {
                    reportName: function () { return 'Person - ABET Role and Activity History' },
                    inparams: function () {
                        return [{ name: 'ParamPersonId', value: currentUser.profile.personId },{ name: 'SearchLastName', value: person.lastName }];
                    }
                }
            });
        };

        model.add = function () {
            addEditModal(null, 'add');
        };

        model.edit = function (user) {
            addEditModal(user, 'edit');
        };

        model.replace = function(user){
            addEditModal(user, 'replace');
        }

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.resultMsg = null;
            barSvc.showSpinner();

            if (searchOpts.asofDate)
                searchOpts.asofDate = helperSvc.formatDate(searchOpts.asofDate, null, true).toLocaleDateString();

            groupsAndRolesSvc.getGroupsAndRolesSearch(model.organizationId, searchOpts).then(function (data) {
                barSvc.hideSpinner();

                if (model.data.userGroupsAndRoles.length === 0) {
                    model.resultMsg = "No results found";
                }
            });
        };

        var addEditModal = function (user, mode) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/groupsAndRoles/templates/modal/groupsAndRolesEdit.html',
                size: 'lg',
                controller: 'groupsAndRolesEditCtrl',
                resolve: {
                    userId: function () { return user ? user.userId : null; },
                    user: function(){ return user; },
                    personName: function () { return user ? user.firstName + ' ' + user.lastName : null },
                    mode: function () { return mode; },
                    organization: function() { return organizationSvc.data.organization; },
                    searchOpts: function(){ return model.searchOptions; }
                }
            });
        };

        model.delete = function (orgUser) {
            alertSvc.confirmDelete(getOrgUserRoleName(orgUser), deleteFunc);

            function deleteFunc() {
                membershipSvc.delete(orgUser.organizationUserId).then(function (data) {
                    alertSvc.addAlertSuccess("User role successfully deleted.");
                    model.retrieve();
                });
            }
        }

        model.emailShownUsers = function () {
            var emailList = model.data.userGroupsAndRoles.map(user => user.emailAddress);
            var emailListUnique = emailList.filter((value, index, self) => self.indexOf(value) === index);
            var mail = document.createElement("a");
            mail.href = 'mailto:' + emailListUnique.join(';');
            mail.click();
        };

        var getOrgUserRoleName = function (orgUser) {
            return orgUser.roleName + ' –  ' + orgUser.lastName + ', ' + orgUser.firstName;
        }

        var activate = function () {
            model.organizationId = parseInt($stateParams.organizationId)


            organizationSvc.getOrgByIdOdata(model.organizationId).then(function () {
                model.organizationTypeId = organizationSvc.data.organization ? organizationSvc.data.organization.organizationTypeId : 0;

                var dataArray = [];
                var roleOptions = {
                    dataHolder: model,
                    dataLocationName: 'roleTypes',
                    svcCallback: roleSvc.getRolesByOrganizationTypeId,
                    svcCallbackArguments: [model.organizationTypeId],
                    helperCallback: helperSvc.getValue
                }
                dataArray.push(roleOptions);

                var commissionOptions = {
                    dataHolder: model,
                    dataLocationName: 'designationTypes',
                    svcCallback: codeSvc.getCommissions,
                    svcCallbackArguments: [],
                    helperCallback: helperSvc.getValue
                }
                dataArray.push(commissionOptions);


                model.cycleYears = [] // go to 2000, and then this year + 1
                var currentYear = new Date().getFullYear();
                for (var i = 2000; i < currentYear + 2; i++) {
                    model.cycleYears.push(i);
                }

                model.terms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] //arbitrary terms here need more info

                helperSvc.getData(dataArray).then(function () {
                    model.dataIsReady = true;
                });

                groupsAndRolesSvc.data.userGroupsAndRoles = [];
                model.data = groupsAndRolesSvc.data;

                model.showSpinner = false;
                model.resultMsg = null;

                var cycleYearFilterOptionOrganizationTypes = [organizationTypes.BOARDOFDIRECTORS, organizationTypes.COMMISSION];
                model.isCycleYearFilter = cycleYearFilterOptionOrganizationTypes.find(t => t === model.organizationTypeId) ? true : false;
            });
        }();
    };
    module.controller('groupsAndRolesCtrl', groupsAndRolesCtrl);

}(angular.module('groupsAndRoles')));