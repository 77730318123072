(function (module) {
    var nominationCommitteeCtrl = function ($scope, $state, $stateParams, $uibModal, $q, helperSvc, alertSvc, nominationSvc) {
        var model = this;

        model.isDataReady = false;
        model.isCommittee = true;
        model.currentSociety = null;

        var activate = function () {
            // Do something... hopefully something you wouldn't have done in nominationSocietyCtrl
        }();
    };
    module.controller('nominationCommitteeCtrl', nominationCommitteeCtrl);

}(angular.module('nomination')));