(function (module) {

    var viewNPCPreviewCtrl = function (currentNPCData, isPreview, isFinalAction, isFV, npcProgramChangeReportId, programsSelected,$q, $scope, $uibModalInstance, npcPDFSvc, helperSvc, pdfSvc, npcProgramChangeReportSvc) {
        var model = $scope;

        model.title = 'View NPC Preview';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        };

        model.downloadNPC = function () {
            const filename = getFilename();
            pdfSvc.downloadPDF(model.doc, null, null, filename);
        };

        function getFilename() {
            const org = currentNPCData.organization.organizationName;
            //const program = isSingleProgram ? `-${currentProgramAuditData.programReviews[0].programDto.programDetailDto.programName} (${currentProgramAuditData.programReviews[0].programDto.programDetailDto.degreeCode})` : '';
            const title = `${org}-NPC`;

            return helperSvc.formatFilename(title, 'pdf');
        }

        model.activate = function () {

            model.doc = npcPDFSvc.generateDoc(currentNPCData, isPreview, isFinalAction, isFV, npcProgramChangeReportId, programsSelected);
        }(); 
    };

    module.controller('viewNPCPreviewCtrl', viewNPCPreviewCtrl);

}(angular.module('npc')));