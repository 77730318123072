(function (module) {

    var specializationSvc = function (odataSvc) {
        var apiPath = '/SocietySpecialization'
        var key = "societySpecializationId"
        var factory = {};

        factory.data = {societySpecializations: null}

        factory.getBySocietyId = function (societyId, noStoredData) {
            var oSvc = odataSvc.get();

            oSvc.getSource(apiPath, key).odata()
                .filter("societyId", societyId)
                .expand('specializationDto')
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.societySpecializations =  data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        return {
            data: factory.data,
            getBySocietyId: factory.getBySocietyId,
        };
    };

    module.factory('specializationSvc', specializationSvc);

})(angular.module('volunteer'));