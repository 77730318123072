(function (module) {

    var templateRoot = "/Apps/assignment/templates/pev/";

    module.directive('reviewProgramFilters', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reviewProgramFilters.html'
        };
    });

    module.directive('volunteerFilters', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'volunteerFilters.html'
        };
    });

    module.directive('reviewProgramSummary', function ($uibModal, pevAssignmentSvc, assignmentSvc, alertSvc, programCampusTypeIds, helperSvc, teamMemberTypeNames) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reviewProgramSummary.html',
            scope: {
                programs: '=',
                preview: '@',
                selectionMode: '@?',
                selectedReviews: '=?',
                selectedVolunteers: '=?'
            },
            link: function (scope, elem, attrs) {
                scope.formatDate = helperSvc.formatDate;
                scope.assignmentStatusHtml = '<ul class="semi-style">' +
                                                    '<li><label class="no-margin">N/A</label>: Does not require PEVs</li>' +
                                                    '<li><label class="no-margin">Incomplete</label>: PEVs not assigned <em>or</em> approval pending</li>' +
                                                    '<li><label class="no-margin">Approved</label>: PEVs assigned and approved</li>' +
                                                '</ul>';
                scope.pevHelpTxt = 'The ratio X/Y shows the number of PEVs assigned (X) from the number of PEVs required for the program (Y)';
                scope.getReviewTypeHtml = pevAssignmentSvc.getReviewTypeHtml;

                scope.teamMemberTypeNames = teamMemberTypeNames;
 
                ///// Duplicate code from reviewTeamSummary / commissionerSummary / volunteerSummary
                    scope.allSelected = { checked: false };

                    var defaultSort = 'organizationName';

                    scope.setSortFilter = function () {
                        scope.sortFilter = defaultSort;
                    };

                    scope.sortBy = function (property) {
                        scope.sortFilter = assignmentSvc.setSortBy(defaultSort, scope.sortFilter, property);
                    };

                    if (attrs.selectionMode && attrs.selectionMode.toLowerCase() === 'true') {
                        scope.selectionMode = true;
                        scope.previewMode = false;
                    } else {
                        scope.selectionMode = false;
                        scope.previewMode = true;
                    }

                    scope.formatUrl = function (s) {
                        url = helperSvc.formatUrl(s);
                        return url;
                    } 

                    scope.$watch('programs', function () {
                        assignmentSvc.clearIndex(scope.programs);
                        scope.selectAll(false);
                        /* scope.setSortFilter();  need to retain sort option*/
                    });

                ////// End duplicate code

                scope.meetsProgramReqs = function (program, isDisabled) {
                    var isVisible = pevAssignmentSvc.releasePending || scope.selectionMode;
                    if (program) {
                        if (pevAssignmentSvc.releasingObservers) {
                            var meetsReqs = pevReqs.hasStartDate(program) && program.observersAssigned && !pevReqs.observersReleased(program);
                        } else {
                            var hasAssignees = scope.selectionMode ? true : pevReqs.hasAssignees(program);
                            var isReleased = pevAssignmentSvc.releasePending ? pevReqs.pevsReleased(program) : false;
                            var meetsReqs = pevReqs.hasStartDate(program) && hasAssignees && !isReleased;
                        }
                        return isVisible && (isDisabled ? !meetsReqs : meetsReqs);
                    }
                    return isVisible;
                };

                scope.selectAll = function (selectAll) {
                    if (!scope.programs) return;
                    var tempCounter = 0;

                    scope.allSelected.checked = (selectAll === undefined) ? scope.allSelected.checked : selectAll;

                    scope.programs.forEach(function (program) {
                        if (scope.meetsProgramReqs(program)) {
                            if (scope.allSelected.checked) ++tempCounter;
                            program.selected = scope.allSelected.checked;
                        }
                    });

                    pevAssignmentSvc.programSelectionCounter = tempCounter;
                };

                scope.incrementCounter = function (program) {
                    if (program.selected) {
                        ++pevAssignmentSvc.programSelectionCounter;
                    } else {
                        --pevAssignmentSvc.programSelectionCounter;
                    }
                };

                var pevReqs = pevAssignmentSvc.pevReqs;

                scope.pevsOnlyReleased = function (program) {
                    return pevReqs.pevsReleased(program) && !pevReqs.observersReleased(program);
                };

                scope.observersOnlyReleased = function (program) {
                    return pevReqs.observersReleased(program) && !pevReqs.pevsReleased(program);
                };

                scope.fullRelease = function (program) {
                    return pevReqs.pevsReleased(program) && pevReqs.observersReleased(program);
                };

                scope.releasePending = function () {
                    return pevAssignmentSvc.releasePending;
                };

                scope.getDisabledSelectionTitle = function () {
                    var assignmentTitle = "Unavailable for Selection";
                    var releaseTitle = "Unavailable for Release";

                    return pevAssignmentSvc.releasePending ? releaseTitle : assignmentTitle;
                };

                scope.getDisabledSelectionMsg = function (program) {
                    var releaseMsg = "";
                    var assignmentMsg = "PEVs and Observers cannnot be assigned to a visit without a confirmed date. Once the date has been confirmed the program will be available for selection.";

                    if (!pevReqs.requiresPevs(program)) {
                        releaseMsg = "This visit does not require PEVs or Observers.";
                    } else if (!pevReqs.hasStartDate(program)) {
                        releaseMsg = "The visit start date must be confirmed and assignments must be made before release.";
                    } else if (pevAssignmentSvc.releasingPEVs && !pevReqs.hasAssignees(program)) {
                        releaseMsg = "All required PEVs must be assigned to this program before assignments can be released.";
                    } else if (pevAssignmentSvc.releasingPEVs && pevReqs.pevsReleased(program)) {
                        releaseMsg = assignmentMsg = "PEV assignments have already been released for this program."
                    } else if (pevAssignmentSvc.releasingObservers && !program.observersAssigned) {
                        releaseMsg = assignmentMsg = "This program has no observers assigned."
                    } else if (pevAssignmentSvc.releasingObservers && pevReqs.observersReleased(program)) {
                        releaseMsg = assignmentMsg = "Observer assignments have already been released for this program."
                    }
                    return pevAssignmentSvc.releasePending ? releaseMsg : assignmentMsg;
                };

                var defaultText = 'Not Entered';
                var defaultShortText = 'N/A';

                scope.getProgramName = function (program) {
                    var programName = getProgramDetailPropertyValue(program, 'programName', defaultText);
                    var degreeCode = getProgramDetailPropertyValue(program, 'degreeCode', null);
                    programName += degreeCode ? ' (' + degreeCode + ')' : '';

                    return programName;
                };

                scope.getDisciplineName = function (program) {
                    return getProgramDetailPropertyValue(program, 'disciplineName', defaultText);
                };

                scope.getReviewType = function (program) {
                    return program.programReviewTypeCode ? program.programReviewTypeCode : defaultShortText;
                };

                scope.getPEVsRatio = pevAssignmentSvc.getPEVsRatio;

                scope.getLastAction = function (program) {
                    return getProgramDetailPropertyValue(program, 'lastAction', defaultText);
                };

                scope.getMultipleCampus = function (program) {
                    var programCampusTypeId = getProgramDetailPropertyValue(program, 'programCampusTypeId', defaultText);
                    if (programCampusTypeId === programCampusTypeIds.MAINANDOTHERLOCATIONS || programCampusTypeId === programCampusTypeIds.OTHERLOCATIONSONLY) {
                        return "Yes";
                    } else {
                        return "No";
                    }
                };

                scope.getLocation = pevAssignmentSvc.getLocation;

                scope.getDistanceLearning = function (program) {
                    return getProgramDetailPropertyValue(program, 'distanceLearningType', defaultShortText);
                };

                scope.getPopoverVisibility = function (program) {
                    var hasPevsAssigned = program.pevsAssigned && program.pevsAssigned.length > 0 ? true : false;

                    if (pevReqs.requiresPevs(program)) {
                        return scope.previewMode ? true : hasPevsAssigned;
                    } else {
                        return false;
                    }
                };

                scope.addPevIsVisible = function (program) {
                    return scope.previewMode && !pevReqs.hasAssignees(program) && !pevReqs.pevsReleased(program);
                };

                scope.delete = function (volunteer, teamMemberTypeId, reviewProgram) {
                    if (scope.selectedReviews && scope.selectedVolunteers && pevAssignmentSvc.data.matrix) {
                        // Summary is called from contect of assignment tool; need to call unassign instead of simply deleting.
                        // Need to look up actual volunteer object that has assignments to pass into unassign (commissioner doesn't cut it)
                        var selectedReviewProgram = scope.selectedReviews.find(function (item) { return item.programReviewDisciplineId === reviewProgram.programReviewDisciplineId });
                        var selectedMatrixRow = pevAssignmentSvc.data.matrix.find(function (item) { return item.programReviewDisciplineId === selectedReviewProgram.programReviewDisciplineId; });
                        var selectedVolunteer = selectedMatrixRow.volunteers ? 
                                                    selectedMatrixRow.volunteers.find(function (item) { return item.volunteerId === volunteer.volunteerId; }) :
                                                    null;

                        if (selectedVolunteer) {
                            assignmentSvc.unassign(selectedVolunteer, teamMemberTypeId, selectedReviewProgram, scope.selectedVolunteers);
                            return;
                        }
                    }

                    // Volunteer not in matrix, delete as usual.
                    var tcMode;
                    alertSvc.confirmDelete(volunteer.lastName + ', ' + volunteer.firstName, assignmentSvc.delete, volunteer, tcMode = false);
                };

                scope.replace = function (pev, program, isAddMode, isObserver) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/assignment/templates/pev/replacePev.html',
                        size: 'md',
                        controller: 'replacePevCtrl',
                        resolve: {
                            pev: function () { return pev; },
                            program: function () { return program; },
                            add: function () { return isAddMode; },
                            isObserver: function () { return isObserver; }
                        }
                    });
                };

                scope.add = function (pev, program, isObserver) {
                    scope.replace(pev, program, true, isObserver);
                };

                scope.getPersonName = assignmentSvc.getCommissionerName;
                scope.setSortFilter();
                function getProgramDetailPropertyValue (program, propertyName, defaultValue) {
                    if (!program.programDetail || program.programDetail.length === 0) {
                        return defaultValue;
                    }

                    var programDetail = program.programDetail[0];
                    return programDetail[propertyName] || defaultValue;
                }
            }

        };
    });

    module.directive('volunteerSummary', function (assignmentSvc, pevAssignmentSvc, alertSvc, helperSvc, volunteerHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'volunteerSummary.html',
            scope: {
                volunteers: '='
            },
            link: function (scope, elem, attrs) {
                ///// Duplicate code from reviewTeamSummary / reviewProgramSummary / commissionerSummary
                scope.allSelected = { checked: false };
                scope.getPersonName = assignmentSvc.getName;
                scope.getDateConflictText = assignmentSvc.getDateConflictText;
                scope.getDateConflictTypeName = assignmentSvc.getDateConflictTypeName;
                scope.getConflictDates = assignmentSvc.getConflictDates;
                scope.formatDate = helperSvc.formatDate;
                scope.getCountryConflictText = assignmentSvc.getCountryConflictText;
                scope.getConflictCountries = assignmentSvc.getConflictCountries;
                scope.getCountryConflictTypeName = assignmentSvc.getCountryConflictTypeName;
                scope.noAvailableCountriesMsg = "Volunteer is not available for any visits.";
                scope.openBioModal = volunteerHelperSvc.openBioModal;

                var defaultSort = 'lastName';
                scope.setSortFilter = function () { scope.sortFilter = defaultSort; };

                scope.sortBy = function (property) {
                    if (typeof property === 'string') {
                        scope.sortFilter = assignmentSvc.setSortBy(defaultSort, scope.sortFilter, property);
                    }
                };

                scope.meetsReqs = function (volunteer) {
                    return volunteer.isConfirmedTermsAgreement && !volunteer.isRecertificationTrainingRequired;
                };

                scope.selectAll = function (selectAll) {
                    if (!scope.volunteers) return;
                    var tempCounter = 0;

                    scope.allSelected.checked = (selectAll === undefined) ? scope.allSelected.checked : selectAll;

                    scope.volunteers.forEach(function (volunteer) {
                        if (scope.meetsReqs(volunteer)) {
                            if (scope.allSelected.checked) ++tempCounter;
                            volunteer.selected = scope.allSelected.checked;
                        }
                    });

                    pevAssignmentSvc.volunteerSelectionCounter = tempCounter;
                };

                scope.incrementCounter = function (volunteer) {
                    if (volunteer.selected) {
                        ++pevAssignmentSvc.volunteerSelectionCounter;
                    } else {
                        --pevAssignmentSvc.volunteerSelectionCounter;
                    }
                };

                scope.getPEVDisabledMsg = assignmentSvc.getPEVDisabledMsg;

                scope.$watch('volunteers', function () {
                    assignmentSvc.clearIndex(scope.volunteers);
                    scope.selectAll(false);
                    scope.setSortFilter();
                });

                ////// End duplicate code
            }

        };
    });

}(angular.module('assignment')));