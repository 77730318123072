(function (module) {

    var teamChairsAndEditorsEditCtrl = function (review, parent, $scope, $uibModalInstance, helperSvc, eventSvc, codeSvc, personSvc, volunteerSvc, reviewTeamMemberSvc, alertSvc, teamMemberStatusesFor) {
        var model = $scope;

        model.title = 'Team Chairs And Editors';
        model.parent = parent;
        model.isInsertMode = false;
        model.isEdit = false;
        model.isAdd = false;
        model.formatDate = helperSvc.formatDate;
        model.isEmpty = helperSvc.isEmpty;
        model.data = reviewTeamMemberSvc.data;
        model.errors = [];

        if (review) {
            model.review = angular.copy(review);
            model.reviewTeamId = model.review.reviewTeamId;
            model.subtitle = review.reviewDto.reviewYear + ' ' + review.commissionName + ' ' + review.reviewTypeName;

            resetTeamMember();
        } else {
            resetTeamMember();
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (teamMember) {
            alertSvc.confirmDelete(teamMember.personName, deleteFunc, teamMember);

            function deleteFunc() {
                reviewTeamMemberSvc.delete(teamMember).then(function (data) {
                    alertSvc.addModalAlertSuccess('Team member successfully deleted.');
                    resetTeamMember();
                    resetErrors();
                });
            }
        };

        model.addRow = function () {
            resetTeamMember();
            model.isInsertMode = true;
            model.isAdd = true;
            model.isEdit = false;
        };

        model.cancel = function () {
            resetTeamMember();
            model.isInsertMode = false;
            resetErrors();
        }

        model.save = function (teamMember) {
            resetErrors();

            var teamMemberDto = {
                reviewTeamId: model.reviewTeamId,
                reviewTeamMemberId: teamMember.reviewTeamMemberId,
                volunteerId: teamMember.volunteerId,
                teamMemberTypeId: teamMember.teamMemberTypeId,
                programReviewDisciplineId: teamMember.programReviewDisciplineId,
                startDate: teamMember.startDate,
                endDate: teamMember.endDate,
                evaluatorAssignmentStatusCode: teamMember.evaluatorAssignmentStatusCode,
                hasReviewedOss: teamMember.hasReviewedOss,
                teamMemberStatusId: teamMember.teamMemberStatusId,
                rejectComment: teamMember.rejectComment,
                note: teamMember.note
            }

            if (!teamMember.volunteerId || model.isAdd) {
                //attempt to get and if fails throw errror
                volunteerSvc.getVolunteerIdByPersonId(teamMember.personId).then(function (data) {
                    var volunteerobj = helperSvc.getFirstArrayItem(data);
                    if (volunteerobj === undefined) {
                        model.errors.push('This person is not a volunteer.');
                        return;
                    }
                    teamMemberDto.volunteerId = volunteerobj.volunteerId;


                    //need to check if there is already a TC or ED1/2
                    if (checkForTCEDExists(teamMemberDto)){
                        return alertSvc.addModalAlertWarning("You are attempting to add a TC or ED role that is already filled. Please End Date the previous role first")
                    }

                    reviewTeamMemberSvc.create(teamMemberDto).then(function () {
                        resetTeamMember();
                        model.isInsertMode = false;
                        alertSvc.addModalAlertSuccess('Team member successfully created.');
                    });
                });
            }
            else if (model.isEdit) {
                reviewTeamMemberSvc.update(teamMemberDto).then(function () {
                    resetTeamMember();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess('Team member successfully updated.');
                });
            }
        };

        model.select = function (person) {
            resetTeamMember();
            var useUndefined = true;
            resetErrors();

            model.currentTeamMember = angular.copy(person);
            model.currentTeamMember.startDate = helperSvc.formatDate(person.startDate, useUndefined);
            model.currentTeamMember.endDate = helperSvc.formatDate(person.endDate, useUndefined);
            model.currentTeamMember.note = person.note;

            volunteerSvc.getPersonIdByVolunteerId(model.currentTeamMember.volunteerId).then(function (data) {
                var personIdObj = helperSvc.getFirstArrayItem(data);
                model.currentTeamMember['personId'] = personIdObj.personId;
                model.isInsertMode = true;
                model.isAdd = false;
                model.isEdit = true;
            });
        };

        codeSvc.getTeamChairAndEditorTypes().then(function (data) {
            model.teamChairAndEditorTypeOptions = helperSvc.getResults(data);
        });

        codeSvc.getTeamMemberStatuses().then(function (data) {
            var statusArr = helperSvc.getResults(data);
            model.teamMemberStatuses = statusArr.filter(function (status) { return teamMemberStatusesFor.TeamChair.indexOf(status.codeKey) > -1 });
        });

        function resetTeamMember() {
            model.currentTeamMember = {};
            model.currentTeamMember.reviewTeamId = model.review ? model.reviewTeamId : null;
            model.teamMemberName = undefined;
        };

        function checkForTCEDExists(newTeamMember) {
            var result = false;
            var theTeamMembers = model.data.teamChairsAndEditors;

            var theNewTeamMemberType = newTeamMember.teamMemberTypeId;

            var allTeamMembersOfType = helperSvc.getFilteredArray(theTeamMembers, 'teamMemberTypeId', theNewTeamMemberType, true);

            angular.forEach(allTeamMembersOfType, function (member) {
                if (member.endDate == null || !member.endDate) {//all members must be enddated in order to add another of the same type.
                    result = true;
                }
            });
            return result;
        }

        function resetErrors() {
            model.errors = [];
        };

    };

    module.controller('teamChairsAndEditorsEditCtrl', teamChairsAndEditorsEditCtrl);

}(angular.module('review')));