(function (module) {

    var omnisearchCtrl = function (searchSvc, $location, $window) {
        var model = this;
        var noResultsType = 'No Results';
        model.title = 'Omnisearch';
        model.searchQueryAbandoned = false;

        model.getResults = function (text) {
            return searchSvc.getOmnisearch(text).then(function (data) {
                model.searchQueryAbandoned = data.value[0];
                //typeahead-no-results attribute doesnt exist in version .10
                //wont need this code when we upgrade to ui boostrap .13 instead of .10
                if (data.value.length === 0) {
                    model.searchQueryAbandoned = false;
                    data.value = [{
                        'name': text,
                        'typeName': noResultsType
                    }];
                }
                return data.value;
            });
        };

        model.viewDetails = function ($item, $model, $label) {
            if ($item.typeName === 'Person') {
                $window.location.href = '/#/admin/person/' + $item.keyId;
            } else if (($item.typeName === noResultsType) || !$item.typeName) {
                $window.location.href = '#';
            } else {
                $window.location.href = '/#/admin/organization/' + $item.keyId;
            }
        };
    };

    module.controller('omnisearchCtrl', omnisearchCtrl);

}(angular.module('common')));