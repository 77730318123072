(function (module) {

    var organizationHistorySvc = function ($http, $q, amsConst, odataServiceFactory) {
        var config = {
            apiPath: '/OrganizationDetail',
            keyName: 'organizationDetailId',
            foreignKeyName: 'organizationId',
            dataCollectionName: 'historyInfo',
            getByForeignKeyPath: '/GetOrganizationDetailByUser',
            expandables: ['addressDto']
        };

        var factory = new odataServiceFactory(config);

        var apiPathSave = amsConst.webApiUrl + '/odata/SaveOrganizationNameChange';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getCurrentOrganizationDetail = function (orgId) {
            var oSvc = factory.getOdataSvc();
            orgId = parseInt(orgId);

            oSvc.getSource(config.apiPath, config.keyName).odata()
                .expand('addressDto')
                .filter('organizationId', orgId)
                .filter('isCurrent', true)
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.saveOrganizationNameChange = function (org) {
            var data = { "Value": org };
            var orgId = org.organizationId;

            var promise = $http.post(apiPathSave, data).then(onSuccess, onFailure);

            promise.then(function () {
                //organizationSvc.data.organization = org;
                factory.getByForeignKey(orgId);
            });

            return promise;
        };

        factory.updateLatestOrganizationHistory = function (orgId) {
            factory.getByForeignKey(orgId).then(function (data) {
                if (data && data[0] && data[0].organizationDetailId) {
                    factory.update(data[0]);
                }
            });
        };

        return {
            data: factory.data,
            getOrganizationHistoryById: factory.getByForeignKey,
            getOrganizationDetailById: factory.get,
            getCurrentOrganizationDetail: factory.getCurrentOrganizationDetail,
            deleteHistory: factory.delete,
            updateOrganizationDetail: factory.update,
            updateLatestOrganizationHistory: factory.updateLatestOrganizationHistory,
            saveOrganizationNameChange: factory.saveOrganizationNameChange
        };
    };
    module.factory('organizationHistorySvc', organizationHistorySvc);

})(angular.module('organization'));