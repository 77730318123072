(function (module) {

    var verifyCtrl = function (college, $scope, $uibModalInstance, collegeDirectorySvc, alertSvc, codeSvc) {
        var model = $scope;

        model.title = 'Verify College Record';
        model.activeCollege = angular.copy(college);
        model.stateList = [];
        model.countryList = [];
        model.selection = {
            // College needs to wrapped in object to bind properly
            college: null,
            countryCode: null,
            stateCode: null
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.add = function () {
            collegeDirectorySvc.editCollegeInfo(null, 'pending', model.activeCollege);
            model.close();
        };

        model.verify = function () {
            collegeDirectorySvc.verify(model.activeCollege.collegeVerificationId, model.selection.college.collegeDirectoryId).then(function () {
                alertSvc.addAlertSuccess("College successfully verified.");
                model.close();
            });
        };

        activate()
        function activate() {
            codeSvc.getCountries().then(function (data) {
                model.countryList = data.value;
            });

            codeSvc.getStates().then(function (data) {
                model.stateList = data.value;
            });
        }
    };

    module.controller('verifyCtrl', verifyCtrl);

}(angular.module('collegeDirectory')));