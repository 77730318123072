(function (module) {

    var professionalLicenseSvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerLicense',
            keyName: 'volunteerLicenseId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'professionalLicenses',
            getByForeignKeyPath: '/GetVolunteerLicenseByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        factory.data.licenseOptions = [];
        factory.data.stateOptions = [];

        //DELETE WHEN NEW CONFIRMATION DIALOG IS READY
        var apiPath = '/VolunteerLicense';
        var key = 'volunteerLicenseId';

        factory.delete = function (professionalLicense) {
            var oSvc = odataSvc.get();

            //if ($window("Are you sure you want to delete this record?")) {

            var resource = oSvc.instantiate(apiPath, key, { volunteerLicenseId: professionalLicense.volunteerLicenseId });
                resource.$delete(oSvc.onSuccess, oSvc.onFailure);

                oldLoadData(oSvc, professionalLicense.volunteerId);

                return oSvc.getDeferred().promise;
            //}
            //else {
            //    return $q.reject('user cancelled');
            //}
        };

        function oldLoadData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getByForeignKey(volunteerId);
            });
        }

        return {
            data: factory.data,
            getVolunteerLicensesByVolunteerId: factory.getByForeignKey,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('professionalLicenseSvc', professionalLicenseSvc);

})(angular.module('volunteer'));