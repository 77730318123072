(function (module) {
    var pevStatusCtrl = function ($uibModal, helperSvc, alertSvc, pevAppSvc, currentUser, typeConstSvc, oauth) {
        var model = this;

        model.data = pevAppSvc.data;
        model.isAppManager = pevAppSvc.isAppManager() && !oauth.isAdmin();
        model.volunteerDisipline = pevAppSvc.volunteerDisciplineData;

        model.toggleReviewed = function () {

            var currentSocietyToUpdateIndex = helperSvc.getFirstMatch(model.data.application.applicationJson.volunteerDiscipline.societies, 'societyId', model.societyIds, true)
            currentSocietyToUpdate = model.data.application.applicationJson.volunteerDiscipline.societies[currentSocietyToUpdateIndex];
            //model.data.application.applicationJson.volunteerDiscipline.societies

            if (currentSocietyToUpdate.isReviewed) {
                currentSocietyToUpdate.isReviewed = false;
            } else {
                currentSocietyToUpdate.isReviewed = true;
            }
            var newDate = new Date();
            currentSocietyToUpdate.reviewedDate = newDate.toJSON();
            currentSocietyToUpdate.reviewedByPersonId = currentUser.profile.personId;

            model.data.application.applicationJson.volunteerDiscipline.societies[currentSocietyToUpdateIndex] = currentSocietyToUpdate;

            pevAppSvc.update(model.data.application)
        }

        model.getProgramAreasBySocietyId = function(societyId) {
            return model.volunteerDisipline.volunteerProgramAreas.disciplines.filter(function (discipline) {
                return discipline.societyId == societyId;
            });               
        };

        model.editStatus = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/pevApp/templates/pevStatusEdit.html',
                size: 'lg',
                controller: 'pevStatusEditCtrl',
                resolve: {
                    parent: function () { return model; }               
                }
            });
        };

        model.canEdit = function (societyIds) {
            if (pevAppSvc.isAdmin()) return true;
            if (angular.isArray(societyIds)) {
                return currentUser.profile && currentUser.profile.organizationId && societyIds.indexOf(parseInt(currentUser.profile.organizationId)) > -1;
            } else {
                return currentUser.profile && currentUser.profile.organizationId && currentUser.profile.organizationId === parseInt(societyIds);
            }
        }

        model.addNewProgramArea = function(){
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/pevApp/templates/pevAppManagerAddNewProgramArea.html',
                size: 'lg',
                controller: 'pevAppManagerAddNewProgramAreaCtrl',
            });
        }

        model.getDisciplineStatusName = function(disciplineStatusId){
            if (!disciplineStatusId)
                return 'No Status';

           return typeConstSvc.getPevAppDisciplineStatusName(disciplineStatusId);
        }

        model.getSocietyStatusName = function(societyStatusId){
            if (!societyStatusId)
                return 'No Status';

            return typeConstSvc.getPevAppSocietyStatusName(societyStatusId);
        }

        var activate = function () {
            // MOVE TO PEV APP SVC, duplicated in pevAppSummaryCtrl
            var reviewVariables = pevAppSvc.setReviewedVariables(model.data.application);

            model.isReviewedCheck = reviewVariables.isReviewedCheck;
            model.societyIds = reviewVariables.societyIds;
            model.societyNameReviewing = reviewVariables.societyNameReviewing;
            model.reviewedWithinUsersSociety = reviewVariables.reviewedWithinUsersSociety;

            model.isReviewedCheckDisabled = !model.reviewedWithinUsersSociety && model.isReviewedCheck;
        }();

        
    }
    module.controller('pevStatusCtrl', pevStatusCtrl);

}(angular.module('pevApp')));