(function (module) {

    var rfrAcceptCtrl = function ($scope, $stateParams, $filter, helperSvc, $uibModal, rfrSvc, alertSvc, rfrProcessStatuses, rfrValidationSvc, typeConstSvc, membershipSvc, contactRoleTypes,
                             currentUser, messageSvc, $state, contactTypes, messageTemplateTypes, programSvc,codeSvc) {
        var model = this;
        
        // Values assigned in activate function
        model.data,
        model.note,
        model.documentTypes,
        model.rejectedCommissions;
        model.rfrProcessStatuses = rfrProcessStatuses;

        // RFE Revisions
        model.optionsDisplayText = "rfr as rfr.rfrProcessStatusName + (rfr.submittedTimestamp !== null ? ' (' + model.formatDateNeat(rfr.submittedTimestamp) + ')' : '') for rfr in model.rfrs";
        model.optionsDisplayTextSansProjected = "rfr as rfr.rfrProcessStatusName + (rfr.submittedTimestamp !== null ? ' (' + model.formatDateNeat(rfr.submittedTimestamp) + ')' : '') for rfr in model.rfrsSansProjected";
        model.formatDate = helperSvc.formatDate;
        model.selectedRfr1 = null;
        model.selectedRfr2 = null;
        model.currentCommission = null;
        model.openRfrPreviewModal = rfrSvc.openRfrPreviewModal;

        model.getRfrs = function () {
            if (Array.isArray(model.data.rfr))
                model.data.rfr = model.data.rfr[0];

            rfrSvc.getRfrSubmissionsByOrganizationId(model.data.rfr.organizationId).then(function (data) {
                model.rfrs = [];
                angular.forEach(data, function (currentRfr) {
                    if (model.data.rfr.reviewYear === currentRfr.reviewYear) {
                        model.rfrs.push(currentRfr);
                    }
                });

                model.rfrsSansProjected = model.rfrs.filter(function (obj) {
                    return obj.rfrProcessStatusName !== 'Projected RFE';
                })
            });
        };
        //model.getOnsiteRestrictionName = function (commission) {
        //    if (commission.jsonContents.onsiteVisitRestriction) {
        //        if (model.restrictionStatusList)
        //            return model.restrictionStatusList.find(t => t.codeKey == commission.jsonContents.onsiteVisitRestriction.selection).codeName;
        //    }
        //    return null;
        //}

        model.formatDateNeat = function (date) {
            return $filter('date')(date, "MM/dd/yyyy");
        };
        
        $scope.$watchCollection('model.data.rfr', function (newValue, oldValue) {
            model.getRfrs();
        });
        
        var templateRoot = '/Apps/rfr/templates/';

        model.compareRfrs = function () {
            if (model.selectedRfr1 && model.selectedRfr2) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: templateRoot + 'rfrCompare.html',
                    size: 'xl',
                    controller: 'rfrCompareCtrl',
                    resolve: {
                        selectedRfr1: function () {
                            return model.selectedRfr1;
                        },
                        selectedRfr2: function () {
                            return model.selectedRfr2;
                        }
                    }
                });
            } else {
                alertSvc.openModalAlert('Two RFE\'s must be selected for a comparison.');
            }
        };

        model.editRfr = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templateRoot + 'rfrStatusEdit.html',
                size: 'md',
                controller: 'rfrStatusEditCtrl',
                resolve: {
                    rfr: function () {
                        return model.data.rfr;
                    }
                }
            });
        };

        model.editTranscript = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templateRoot + 'rfrTranscriptEdit.html',
                size: 'md',
                controller: 'rfrTranscriptEditCtrl',
                resolve: {
                    rfr: function () {
                        return model.data.rfr;
                    }
                }
            });
        };

        //model.editOnsiteRestriction = function (rowIdx) {

        //    var modalInstance = $uibModal.open({
        //        animation: true,
        //        templateUrl: templateRoot + 'rfrOnsiteRestrictionEdit.html',
        //        size: 'md',
        //        controller: 'rfrOnsiteRestrictionEditCtrl',
        //        resolve: {
        //            rfr: function () {
        //                return model.data.rfr;
        //            },
        //            rfrCommissionIndex: function () {
        //                return rowIdx;
        //            }
        //        }
        //    });
        //};

        model.deleteRFE = function(){
            alertSvc.confirmDelete('RFE', deleteFunc);

            function deleteFunc(){
                rfrSvc.delete(model.data.rfr).then(function(){
                    $state.go("rfrAdmin");
                });
            }
        }

        model.acceptRfr = function () {
            rfrValidationSvc.validate(model.data.rfr).then(function (results) {
                if (results.length === 0) {
                    alertSvc.confirm('Are you sure you want to accept this RFE?', accept);

                    function accept() {
                        rfrSvc.accept(model.data.rfr).then(function () {
                            alertSvc.addAlertSuccess('RFE successfully accepted.');
                        });
                    }
                } else {
                    alertSvc.openModalAlert('The RFE you are trying to accept is not valid. Please fix the errors noted in each tab at the top of the page.');
                }
            });
        };

        model.acceptRfrPreReview = function () {
            rfrValidationSvc.validate(model.data.rfr).then(function (results) {
                if (results.length === 0) {
                    alertSvc.confirm('Are you sure you want to accept this pre-review?', accept);
                } else {
                    if (allowPreReviewAcceptance(results)) {
                        alertSvc.confirm('One or more programs require ABET to choose discipline and/or society. ' +
                                        'Are you sure you want to accept this pre-review?', accept);
                    } else {
                        alertSvc.openModalAlert('The RFE you are trying to confirm for pre-review is not valid. Please fix the errors noted in each tab at the top of the page.');
                    }
                }

                function accept() {
                    rfrSvc.acceptRfrPreReview(model.data.rfr).then(function () {
                        alertSvc.addAlertSuccess('RFE Pre-review successfully accepted.');
                        resetRejectionModule();
                    });
                }
            });
        }

        function allowPreReviewAcceptance(results) {
            // Allow acceptance when only error is program(s) have "ABET Chooses" for discipline/society
            // Note: checking error message strings to avoid re-implementing/re-evaluating validation
            // logic to explicitly check that there are no errors except "ABET Chooses" on programs.
            if (results && results.some(function (result) {
                return result.slug !== rfrSvc.slugs.PROGRAMS;
            })) {
                // Errors exist in other tabs
                return false;
            }
            var programErrors = model.data.programErrors;
            if (programErrors && Object.keys(programErrors).some(function (property) {
                return programErrors.hasOwnProperty(property) &&
                       Array.isArray(programErrors[property]) &&
                       programErrors[property].some(function (error) {
                           return error !== programSvc.errorMsgs.ABET_CHOOSES_DISCIPLINE &&
                                  error !== programSvc.errorMsgs.ABET_CHOOSES_SOCIETY;
                       });
            })) {
                // Errors besides "ABET Chooses" exist on programs
                return false;
            }
            // No other validation errors besides the ones we allow for pre-review.
            return true;
        }

        model.rejectCommissionRfr = function () {
            alertSvc.confirm('Are you sure you want to reject the selected commissions?', reject);

            function reject() {
                rfrSvc.rejectCommissionRfr(model.rejectedCommissions, model.data.rfr).then(function () {
                    alertSvc.addAlertSuccess('Commissions successfully rejected.');

                    membershipSvc.getByOrganizationId($stateParams.organizationId, true).then(function (data) {
                        var members = helperSvc.getResults(data);
                        var selectedContacts = [];
                        var primaryAddresses = [];
                        var ccdAddresses = [];
                        var commissionNameString = '';
                        var member;
                        var role;

                        //Get all the primary addresses for those people whose commissions were rejected

                        for (var j = 0; j < model.rejectedCommissions.length; j++) {

                            if (j === model.rejectedCommissions.length - 1) {
                                commissionNameString += " " + model.rejectedCommissions[j].commissionAbbreviatedName + ".";
                            } else {
                                commissionNameString += " " + model.rejectedCommissions[j].commissionAbbreviatedName + ",";
                            }

                            for (var i = 0; i < members.length; i++) {
                                member = members[i];
                                role = member.roleId;

                                if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {
                                    selectedContacts.push(member.personId);

                                    for (var k = 0; k < member.designationViewModels.length; k++) {

                                        if (!helperSvc.arrContains(primaryAddresses, member.personId) && member.designationViewModels[k].designationId === model.rejectedCommissions[j].commissionId) {
                                            primaryAddresses.push(member.personId);
                                        }
                                    }
                                }
                            }
                        }

                        //get all the contacts addresses for those need to be ccd but commissions are acceptable
                        for (var i = 0; i < selectedContacts.length; i++) {
                            if (!helperSvc.arrContains(primaryAddresses, selectedContacts[i])) {
                                ccdAddresses.push(selectedContacts[i]);
                            }
                        }

                        var bodyMessage = "The following commissions have been returned by ABET:" + commissionNameString;
                        if (model.rejectText !== '') {
                            bodyMessage += '\n ABET\'s return message:\n\t' + model.rejectText;
                        }

                        //push the admins id onto ccAddresses
                        ccdAddresses.push(currentUser.profile.personId);

                        messageSvc.buildRecipientListAndSendMessages(primaryAddresses, ccdAddresses, messageTemplateTypes.RFRSTATECHANGE, null, bodyMessage, [], contactTypes.INSTITUTIONCONTACTONLY)
                        resetRejectionModule();
                    });

                });
            }
        };

        model.isRfrAccepted = function () {
            return (model.data && model.data.rfr) ? model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.ABETACCEPTED : false;
        };

        model.isRfrSubmitted = function () {
            return (model.data && model.data.rfr) ? model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORABETACCEPTANCE : false;
        };

        model.showRejectModule = function () {
            return model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORABETPREREVIEW;
        }

        function resetRejectionModule() {
            model.rejectText = '';
            model.rejectedCommissions = [];
        }

        var activate = function () {
            model.data = rfrSvc.data;
            model.note = (model.data && model.data.rfr && model.data.rfr.note) ? model.data.rfr.note : null;
            model.documentTypes = typeConstSvc.getDocumentTypes();
            model.getRfrs();

            //possible race condition, if rfr is not loaded in time this will fail
            rfrValidationSvc.validate(model.data.rfr).then(function (data) {
                model.isValidRfr = (data.length == 0);
            });

            model.rfrCommissions = rfrSvc.getCommissionDtos(model.data.rfr);

            codeSvc.getOnsiteVisitRestrictionStatuses().then(function (data) {
                model.restrictionStatusList = helperSvc.getResults(data);
            });

            resetRejectionModule();
        }();
    };

    module.controller('rfrAcceptCtrl', rfrAcceptCtrl);

}(angular.module('rfr')));