(function (module) {

    var onlineEducationSvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerOnlineExperience',
            keyName: 'volunteerOnlineExperienceId',
            foreignKeyName: 'volunteerId',
            dataItemName: 'onlineEducation',
            getByForeignKeyPath: '/GetVolunteerOnlineExperienceByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        factory.getByVolunteerId = function (volunteerId, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = oSvc.getPathWithParameter(config.getByForeignKeyPath, config.foreignKeyName, volunteerId);

            oSvc.getSource(apiPath, config.foreignKeyName).odata()
                //.filter(config.foreignKeyName, volunteerApplicationId)
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData)
                resolveData(oSvc);

            return oSvc.getDeferred().promise;
        };

        factory.update = function (onlineEducation, noStoredData) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(config.apiPath, config.keyName, onlineEducation);

            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                return factory.getByVolunteerId(onlineEducation.volunteerId).then(function (data) {
                    resolveData(oSvc);
                })
            });

            return promise;
        };

        factory.create = function (onlineEducation) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(config.apiPath, config.keyName, onlineEducation);

            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                return factory.getByVolunteerId(onlineEducation.volunteerId).then(function (data) {
                    resolveData(oSvc);
                })
            });
            
            return promise;
        };

        function resolveData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.onlineEducation = data;
            });
        }

        return {
            data: factory.data,
            getByVolunteerId: factory.getByVolunteerId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('onlineEducationSvc', onlineEducationSvc);

})(angular.module('volunteer'));