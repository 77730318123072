(function (module) {

    var npcSubmitModalCtrl = function (organizationName, npc, currentUser, $scope, $q, $uibModalInstance, helperSvc, codeSvc, alertSvc, npcSvc, rfrSvc) {
        var model = $scope;

        model.organizationName = organizationName
        model.title = 'Submit NPC for ' + model.organizationName;
        model.isSaving = false;
        model.formatDate = helperSvc.formatDate;
        model.signedInUser = currentUser.profile.firstName + " " + currentUser.profile.lastName
        model.submissionData = {
            confirmTermsAcceptedCheck: false,
            signCheck: false,
            presidentSignature: "",
            isSaving: false
        }
        model.npc = npc;

        model.submitToAbet = function () {
            model.isSaving = true;
            var now = new Date();
            model.npc.submittedDate = now;
            model.npc.submittedByPersonId = currentUser.profile.personId;
            model.npc.signedText = model.submissionData.presidentSignature;           

            for (var i = 0; i < model.npc.npcProgramDtos.length; i++) {
                var program = model.npc.npcProgramDtos[i];

                for (var x = 0; x < program.npcProgramChangeDtos.length; x++) {
                    programChange = program.npcProgramChangeDtos[x];

                    var newProgressDto = {
                        npcProgramChangeId: programChange.npcProgramChangeId,
                        npcStatusId: 2, //notification submitted
                        npcStatusUpdatedDate: now
                    }

                    model.npc.npcProgramDtos[i].npcProgramChangeDtos[x].npcProgramChangeProgressDtos.push(newProgressDto);

                }
            }

            if (model.hasProgramInRfe) {

                npcSvc.update(model.npc).then(function () {
                    //remove programs after update
                        
                    var updatedRfr = model.latestRfr;
                    rfrCommissionIndex = updatedRfr.rfrCommissionDtos.findIndex(x => x.commissionId === npc.commissionId);
                    updatedRfr.rfrCommissionDtos[rfrCommissionIndex] = model.matchingCommissionDto;

                    rfrSvc.updateWithNPC(updatedRfr).then(function () {
                        alertSvc.addAlertSuccess(`Notification of Program Change Submitted to Abet`);
                        $uibModalInstance.close();
                    });

                        

                }, function () {
                    model.isSaving = false;
                    alertSvc.addAlertWarning('Notification of Program Change could not be Submitted at this time.');
                });
            }
            else {
                npcSvc.update(model.npc).then(function (data) {
                    alertSvc.addAlertSuccess(`Notification of Program Change Submitted to Abet`);

                    $uibModalInstance.close();
                    

                }, function () {
                    model.isSaving = false;
                    alertSvc.addAlertWarning('Notification of Program Change could not be Submitted at this time.');
                });

             }
            
        }

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        function activate() {
            

            rfrSvc.getRfrSubmissionsByOrganizationId(model.npc.organizationId).then(function (data) {
                model.rfrData = data;
                model.currentReviewYear = helperSvc.getAcademicYear(true)//GET THE CURRENT REVIEW Year
                model.latestRfr = model.rfrData[0]
                model.matchingCommissionDto = model.latestRfr.rfrCommissionDtos.find(x => x.commissionId === model.npc.commissionId);
                if (model.matchingCommissionDto) {
                    model.matchingCommissionDto.jsonContents = angular.fromJson(model.matchingCommissionDto.jsonContents);
                }
                
                model.hasProgramInRfe = false;

                for (var i = 0; i < model.npc.npcProgramDtos.length; i++) {
                    var program = model.npc.npcProgramDtos[i];


                    if (model.latestRfr.reviewYear === model.currentReviewYear) {

                        if (model.matchingCommissionDto) {
                            //check if the programs being submitted are in the json contents
                            if (model.matchingCommissionDto.jsonContents.programs && model.matchingCommissionDto.jsonContents.programs.length > 0) {
                                matchingProgramIndex = model.matchingCommissionDto.jsonContents.programs.findIndex(x => x.programId == program.programId)
                                model.hasProgramInRfe = true;
                                //we need to show that upon submit it will be removed from rfr
                                model.matchingCommissionDto.jsonContents.programs.splice(matchingProgramIndex, 1);
                            }
                        }
                    }
                }


                model.isDataReady = true;
            })
        }

        activate();
    };
    module.controller('npcSubmitModalCtrl', npcSubmitModalCtrl);

}(angular.module('npc')));