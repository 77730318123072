(function (module) {

    var programLinksEditCtrl = function (parent, $scope, $uibModalInstance, programLinkSvc, eventSvc, alertSvc, helperSvc) {
        var model = $scope;
        model.parent = parent;
        model.title = 'Edit ' + parent.title;
        model.activeStatuses = [
            {
                name: 'Active',
                value: true
            },
            {
                name: 'Not Active',
                value: false
            },
            {
                name: 'Not Checked',
                value: null
            }
        ];

        model.delete = function (programInformationLink, isLastItem) {
            if (isLastItem) model.isBlankMode = true;
            programInformationLink.isDeleted = true;
        };

        model.add = function () {

            var newProgramInformationLink = {
                programInformationId: 0,
                programId: model.programLinks.programId,
                webpageUrl: null,
                isActive: true,
                note: null,
                isDeleted: false
            };

            model.programLinks.programInformationDtos.push(newProgramInformationLink);
            model.isBlankMode = false;
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            cleanData();
            programLinkSvc.update(model.programLinks).then(function (data) {
                eventSvc.broadcast('refreshSelectedProgramData');
                model.close();
                alertSvc.addAlertSuccess("Program information links successfully updated.");
            });
        };

        function formatURLs(programLinks) {
            if (!programLinks || !programLinks.programInformationDtos) return;

            programLinks.programInformationDtos.forEach(function (programLink) {
                programLink.webpageUrl = helperSvc.formatUrl(programLink.webpageUrl);
            });
        }

        activate();

        function activate() {
            model.programLinks = angular.copy(parent.parent.programLinks) || [];
            model.isBlankMode = (model.programLinks.programInformationDtos.length === 0);

            if (model.programLinks.programInformationDtos.length === 0) {
                model.add();
            };
        }

        function cleanData() {
            var index = 0;
            while (index < model.programLinks.programInformationDtos.length) {
                var programInformationLink = model.programLinks.programInformationDtos[index];
                var removeItem = false;
                if (programInformationLink.programInformationId === 0 && programInformationLink.isDeleted === true) {
                    // Do not try to save links that the user has already deleted.
                    removeItem = true;
                } else if ((!programInformationLink.webpageUrl || programInformationLink.webpageUrl.trim().length === 0) &&
                           (!programInformationLink.note || programInformationLink.note.trim().length === 0)) {
                    // Do not try to save new links where web address URL and note are either null or blank.
                    removeItem = true;
                }
                if (removeItem) {
                    model.programLinks.programInformationDtos.splice(index, 1);
                } else {
                    index++;
                }
            }
            formatURLs(model.programLinks);
        };
    };

    module.controller('programLinksEditCtrl', programLinksEditCtrl);

}(angular.module('program')));