(function (module) {

    var selfStudySubmitCtrl = function (parent, $scope, $uibModalInstance, alertSvc, userReviewSvc, readinessValidationSvc, currentUser, messageSvc, messageTemplateTypes, contactTypes) {
        var model = $scope;

        model.parent = parent;
        model.title = 'Submit Self Studies';
        model.currentInformation = { signedText: "" };
        model.hasSubmitPermission = parent.hasSubmitPermission;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.submit = function () {
            userReviewSvc.submitSelfStudies(parent.reviewTeamId, model.currentInformation.signedText).then(
                onSuccess,
                onFailure
            );

            function onSuccess () {
                if (!parent.isAdmin) {
                    parent.isReadOnly = true;
                    parent.institutionView = !parent.isReadOnly;
                    parent.setDisplayedCriteria();
                }
                parent.setSubmissionDetails();
                readinessValidationSvc.invokeValidation();
                // Send message to user who submitted the self study.
                var selfStudySubmittedMessageParams = [{ messageParameterName: 'PersonLastName', messageParameterValue: currentUser.profile.lastName }, { messageParameterName: 'PersonPrefixName', messageParameterValue: currentUser.profile.namePrefix }];
                var recipients = messageSvc.createRecipientDtos([currentUser.profile.personId], contactTypes.INSTITUTIONCONTACTONLY);
                messageSvc.buildAndSendMessage(recipients, [], messageTemplateTypes.SELFSTUDYSUBMITTED, selfStudySubmittedMessageParams);
                // Close modal
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Self studies successfully submitted.");
            }

            function onFailure () {
                alertSvc.addAlertWarning("Self study response could not be submitted at this time.");
            }
        };
    };

    module.controller('selfStudySubmitCtrl', selfStudySubmitCtrl);

}(angular.module('oss')));