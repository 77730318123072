(function (module) {

    var searchSvc = function ($http, $q, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/';
        var factory = {};

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        // odata/GetNamePrefixes(searchString='abc')
        factory.getOmnisearch = function (searchStr) {
            // Replace & with _ to pass .NET request path validation
            searchStr = searchStr.replace('&', '~');
            searchStr = searchStr.replace('\'', '\'\'');
            return $http.get(apiPath + "GetOmnisearch(searchString='" + searchStr + "')").then(onSuccess, onFailure);
        }
        return {
            getOmnisearch: factory.getOmnisearch
        };
    };
    module.factory('searchSvc', searchSvc);

})(angular.module('common'));