(function (module) {

    var differenceServiceFactory = function (deepObjectDifferenceSvc) {

        return function (config) {
            const options = config.objectDifferenceOptions;

            this.deepDiff = function (originalObject, revisedObject) {
                const diffedObject = deepObjectDifferenceSvc.deepDiff(originalObject, revisedObject, options);

                return diffedObject;
            };

            this.convertDiffedObject = function (diffedObject) {
                const convertedDiffedObject = deepObjectDifferenceSvc.convertDiffedObject(diffedObject);

                return convertedDiffedObject;
            };

            this.getConvertedDiffedObject = function (originalObject, revisedObject) {
                const diffedObject = this.deepDiff(originalObject, revisedObject);
                const convertedDiffedObject = deepObjectDifferenceSvc.convertDiffedObject(diffedObject);

                return convertedDiffedObject;
            };
        };
    }

    module.factory('differenceServiceFactory', differenceServiceFactory);

})(angular.module('common'));