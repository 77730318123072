(function (module) {

    var programReviewFinalActionSvc = function (odataSvc, $odata) {
        var factory = {};
        var apiPath = '/GetProgramReviewFinalAction';

        factory.data = { programReviews: null };

        factory.getReviews = function () {
            var oSvc = odataSvc.get();
            oSvc.getSource(apiPath).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.getReviewsByReviewTeamId = function (reviewTeamId) {
            var oSvc = odataSvc.get();
            oSvc.getSource(apiPath).odata()
                .filter('reviewTeamId', reviewTeamId)
                .orderBy("programName")
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.getProgramReviewsByReviewId = function (id, searchOptions, expansion, noStoredData) {
            var oSvc = odataSvc.get();
            var oData = oSvc.getSource(apiPath).odata();
            var predicate = buildFilters(searchOptions);

            if (predicate) {
                oData = oData.filter(predicate);
            }

            oData = oData.filter('reviewId', id);

            if (expansion) {
                oData = oData.expand(expansion);
            }
                
            oData = oData.orderBy("programName")
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.programReviews = data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.advancedSearch = function (searchOptions) {
            var oSvc = odataSvc.get();

            var predicate = buildFilters(searchOptions);

            if (predicate) {
                oSvc.getSource(apiPath).odata()
                    .filter(predicate)
                    .orderBy("organizationSortName")
                    .orderBy("programName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(apiPath).odata()
                    .orderBy("organizationSortName")
                    .orderBy("programName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        function buildFilters(searchOptions) {
            var hasReviewYear = searchOptions.reviewYear ? true : false;
            var hasCommissionId = searchOptions.commissionId ? true : false;
            var hasLocation = searchOptions.location ? true : false;
            var hasOrganizationName = searchOptions.organizationName ? true : false;
            var hasReviewType = searchOptions.reviewType ? true : false;

            var hasSearchOptions = hasReviewYear  || hasCommissionId || hasLocation || hasOrganizationName || hasReviewType;
            var combinedPredicate;

            if (hasSearchOptions) {
                var predicates = [];

                if (hasReviewYear)
                    predicates.push(new $odata.Predicate('reviewYear', '=', searchOptions.reviewYear));
                if (hasOrganizationName)
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                if (hasCommissionId) {
                    predicates.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissionId)));
                }
                if (hasLocation) {
                    if (parseInt(searchOptions.location) === 0) {
                        predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                    } else {
                        predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                    }
                }
                if (hasReviewType) {
                    var reviewTypePredicate;

                    if (searchOptions.reviewType === 'visit') {
                        var neIR = new $odata.Predicate('programReviewTypeCode', 'ne', 'IR');
                        var neSCR = new $odata.Predicate('programReviewTypeCode', 'ne', 'SCR');

                        reviewTypePredicate = $odata.Predicate.and([neIR, neSCR]);
                        predicates.push(reviewTypePredicate);
                    }
                    else if (searchOptions.reviewType === 'report') {
                        var eqIR = new $odata.Predicate('programReviewTypeCode', 'eq', 'IR');
                        var eqSCR = new $odata.Predicate('programReviewTypeCode', 'eq', 'SCR');

                        reviewTypePredicate = $odata.Predicate.or([eqIR, eqSCR]);
                        predicates.push(reviewTypePredicate);
                    }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }
            }
            return combinedPredicate;
        }

        return {
            data: factory.data,
            getReviews: factory.getReviews,
            getReviewsByReviewTeamId: factory.getReviewsByReviewTeamId,
            advancedSearch: factory.advancedSearch,
            getProgramReviewsByReviewId: factory.getProgramReviewsByReviewId
        };
    };

    module.factory('programReviewFinalActionSvc', programReviewFinalActionSvc);

})(angular.module('common'));