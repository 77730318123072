(function (module) {

    var reviewSimultaneousOptionSvc = function ($http, $q, helperSvc, amsConst) {
        var apiPath = amsConst.webApiUrl + '/odata/ReviewSimultaneousOption({0})';
        var factory = {};
        var filterBase = '?$filter=';
        var expandBase = '&$expand='

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getSimultaneousVisitsByReviewId = function (id) {
            return $http.get(apiPath.format(null) + filterBase + buildReviewIdFilter(id) + expandBase + 'reviewSimultaneousOptionCommissionDtos').then(onSuccess, onFailure);
        };

        factory.create = function (newSimultaneousVisit) {
            return $http.post(apiPath.format(null), newSimultaneousVisit).then(onSuccess, onFailure);
        };

        factory.update = function (simultaneousVisit) {
            return $http.put(apiPath.format(simultaneousVisit.reviewSimultaneousOptionId), simultaneousVisit).then(onSuccess, onFailure);
        };

        factory.delete = function (simultaneousVisit) {
            return $http.delete(apiPath.format(simultaneousVisit.reviewSimultaneousOptionId)).then(onSuccess, onFailure);
        };

        var buildFilter = helperSvc.buildFilter;

        var buildReviewIdFilter = function (parameter) {
            return buildFilter("reviewId eq", parameter);
        };

        return {
            getSimultaneousVisitsByReviewId: factory.getSimultaneousVisitsByReviewId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };
    module.factory('reviewSimultaneousOptionSvc', reviewSimultaneousOptionSvc);

})(angular.module('review'));