(function (module) {

    var messageHelperSvc = function ($http, $odataresource, $q, $state, $uibModal, $filter, odataSvc, amsConst, helperSvc, currentUser) {
        var factory = {};

        factory.open = function (message) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/message/templates/messageOpen.html',
                size: 'lg',
                controller: 'messageOpenCtrl',
                resolve: {
                    message: function () { return message; }
                }
            });
        };

        factory.openInbox = function () {
            $state.go('messages', { personId: currentUser.profile.personId });
        };

        factory.getDateText = function (date) {
            var text = '';
            var thisDate = new Date(date);

            if (isToday(thisDate)) {
                text = '<em>Today</em>';
            } else if (isToday(thisDate, -1)) {
                text = '<em>Yesterday</em>';
            } else {
                text = $filter('date')(thisDate, "MM/dd/yy");
            }
            return text;

            function isToday(date, num) {
                if (!num) num = 0;
                var today = new Date();
                // At present, message service records date sent in local time (Eastern)
                return date.getDate() == today.getDate() + num &&
                       date.getMonth() == today.getMonth() &&
                       date.getFullYear() == today.getFullYear();
            }
        };

        // FOR TESTING PURPOSES ONLY

        factory.getTestMessages = function (num) {
            if (!num) num = 1;

            var testBody = '<p>If you have already updated your bio form for 2017, please delete this message now.</p>' +
                             '<p>The assignment of team chairs (TCs) and program evaluators (PEVs) for fall 2017 visits has already started.</p>' +
                             '<p>We need your help! In order to have a complete cadre of available volunteers for 2017-18, each volunteer, whether TC or PEV, must update his/her ABET volunteer profile as soon as possible. This includes updating contact information and new conflicts of interest, agreeing to abide by the provisions of the ABET Code of Conduct, identifying unavailability dates, and noting countries where a volunteer would prefer not to be assigned. Please consider completing this task as soon as you finish reading this letter.</p>' +
                             '<p>You can access your volunteer profile by logging into the ABET AMS system. We recommend using Chrome as your browser.</p>';

            var messageDupes = [];
            var messages = [
                    {
                        date: new Date(),
                        subject: "TC approval pending",
                        sender: "info@abet.org",
                        body: testBody,
                        attachment: null
                    },
                    {
                        date: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
                        subject: "Request to update PEV bio",
                        sender: "info@abet.org",
                        body: testBody,
                        attachment: null
                    },
                    {
                        date: new Date(new Date().setUTCDate(new Date().getUTCDate() - 2)),
                        subject: "Request to update PEV bio",
                        sender: "info@abet.org",
                        body: testBody,
                        attachment: {
                            name: 'myTestAttachment.jpg'
                        }
                    },
                    {
                        date: new Date(new Date().setUTCDate(new Date().getUTCDate() - 3)),
                        subject: "Request to update PEV bio",
                        sender: "info@abet.org",
                        body: testBody,
                        attachment: null
                    },
                    {
                        date: new Date(new Date().setUTCDate(new Date().getUTCDate() - 4)),
                        subject: "Request to approve PEV",
                        sender: "info@abet.org",
                        body: testBody,
                        attachment: {
                            name: 'myTestAttachment.pdf'
                        }
                    },
                    {
                        date: new Date(new Date().setUTCDate(new Date().getUTCDate() - 5)),
                        subject: "Request to update PEV bio",
                        sender: "info@abet.org",
                        body: testBody
                    }
            ];

            for (var i = 0; i < num; i++) {
                messageDupes = messageDupes.concat(messages);
            }

            return messageDupes;
        }

        return {
            open: factory.open,
            openInbox: factory.openInbox,
            getDateText: factory.getDateText,

            // Testing Funcs
            getTestMessages: factory.getTestMessages
        };
    };
    module.factory('messageHelperSvc', messageHelperSvc);

})(angular.module('messages'));