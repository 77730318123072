(function (module) {

    var rfrStatusEditCtrl = function (rfr, $scope, $uibModalInstance, codeSvc, helperSvc, rfrSvc, alertSvc, rfrProcessStatuses, $stateParams, messageSvc, messageTemplateTypes, currentUser, personEmailSvc, membershipSvc, contactRoleTypes, $q, contactTypes) {
        var model = $scope;
        model.title = 'Edit RFE Status';
        model.rfr = angular.copy(rfr);
        model.organizationId = $stateParams.organizationId;
        model.messageData = {};

        model.cancel = function () {
            $uibModalInstance.close();
        }

        model.save = function () {
            var statusId = model.rfr.rfrProcessStatusId;

            if (statusId === rfrProcessStatuses.CANCELLED && model.rfr.revisedSeq > 1) {
                rfrSvc.getRfrSubmissionsByOrganizationId(model.organizationId).then(function (data) {
                    var previousRfr = findPreviousRfr(data, model.rfr);
                    model.rfr.isCurrent = false;

                    //load editable version of previous RFR, modify and save it
                    rfrSvc.getRfrSubmissionAndCommissions(previousRfr.rfrId).then(function (newCurrentRfr) {
                        newCurrentRfr.isCurrent = true;
                        return rfrSvc.update(newCurrentRfr);
                    })
                    //save cancelled rfr
                    .then(function () {
                        return rfrSvc.update(model.rfr);
                    })
                    .then(function (data) {
                        showAlertAndCloseModal();
                    });
                });
            }
            else if (statusId === rfrProcessStatuses.WAITINGFORSUBMISSIONTOABET) {
                model.rfr.signedText = null;
                model.rfr.signedTimestamp = null;
                model.rfr.signedByUserId = null;
                model.rfr.submittedTimestamp = null;
                model.rfr.submittedByUserId = null;

                var commissionsRejected = rfrSvc.getCommissionDtos(model.rfr);

                rfrSvc.rejectCommissionRfr(commissionsRejected, model.rfr).then(function () {
                    showAlertAndCloseModal();
                });
            }
            else {
                if (statusId === rfrProcessStatuses.WAITINGFORREVISEDRFRSUBMISSION) {
                    var rfrCommissionDtos = rfrSvc.getCommissionDtos(model.rfr);

                    for (var i = 0; i < rfrCommissionDtos.length; i++) {
                        var currentCommission = rfrCommissionDtos[i];
                        currentCommission.submittedTimestamp = null;
                        currentCommission.submittedByUserId = null;
                    }
                } else if (statusId === rfrProcessStatuses.WAITINGFORPRESIDENTSIGNATURE) {
                    model.rfr.signedText = null;
                    model.rfr.signedTimestamp = null;
                    model.rfr.signedByUserId = null;
                    model.rfr.submittedTimestamp = null;
                    model.rfr.submittedByUserId = null;
                }

                rfrSvc.update(model.rfr).then(function () {
                    showAlertAndCloseModal();
                });
            }
        }

        codeSvc.getRfrProcessStatuses().then(function (data) {
            var statuses = helperSvc.getResults(data) ?? [];
            statuses = statuses.filter(status =>
                ![rfrProcessStatuses.ABETACCEPTED, rfrProcessStatuses.WAITINGFORREVISEDRFRSUBMISSION, rfrProcessStatuses.PROJECTEDRFR].includes(status.codeKey) &&
                (status.codeKey === rfrProcessStatuses.CANCELLED || status.codeKey < model.rfr.rfrProcessStatusId)
            );
            model.rfrStatusList = statuses;
        });

        function showAlertAndCloseModal() {
            if (model.messageData.sendMessage) {
                var adminPersonId = currentUser.profile.personId;
                var getCurrentOnly = true;
                var getResults = helperSvc.getResults;
                var personIds = [];

                //fetch contacts for organization
                membershipSvc.getByOrganizationId(model.organizationId, getCurrentOnly).then(function (memberData) {
                    var members = getResults(memberData);
                    var emailAddresses = [];
                    var promises = [];
                    var currentUserEmailAddress = null;

                    for (var i = 0; i < members.length; i++) {
                        var member = members[i];
                        var role = member.roleId;

                        //keep only primary and secondary contacts
                        if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY) {
                            if (!helperSvc.arrContains(personIds, member.personId)) {
                                personIds.push(member.personId);
                            }
                        }
                    }                   

                    messageSvc.buildRecipientListAndSendMessages(personIds, [adminPersonId], messageTemplateTypes.RFRSTATECHANGE, null, model.messageData.text, [], contactTypes.INSTITUTIONCONTACTONLY);
                });
            }
            alertSvc.addAlertSuccess("RFR status successfully updated.");
            $uibModalInstance.close();
        }

        function findPreviousRfr(rfrs, rfr) {
            var latestRfr = { revisedSeq: 0 };

            for (var i = 1; i < rfrs.length; i++) {
                var currentRfr = rfrs[i];

                if (currentRfr.revisedSeq >= latestRfr.revisedSeq && currentRfr.revisedSeq !== rfr.revisedSeq) {
                    latestRfr = currentRfr;
                }
            }

            return latestRfr;
        }
    };

    module.controller('rfrStatusEditCtrl', rfrStatusEditCtrl);

}(angular.module('rfr')));