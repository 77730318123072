(function (module) {
    var programAuditCtrl = [
        '$state', '$stateParams', '$uibModal', '$q', '$filter', 'programReviewSvc', 'userReviewSvc', 'reviewTeamMemberSvc', 'reviewSvc', 'reviewSimultaneousOptionSvc', 'reviewJointOptionSvc', 'teamMemberTypeNames', 'teamMemberStatusTypes', 'commissionAbbreviations', 'programAuditSvc', 'programAuditAccessSvc', 'programAuditDetailSvc', 'oauth', 'alertSvc', 'programAuditValidationSvc', 'teamMemberStatusTypes',
        function ($state, $stateParams, $uibModal, $q, $filter, programReviewSvc, userReviewSvc, reviewTeamMemberSvc, reviewSvc, reviewSimultaneousOptionSvc, reviewJointOptionSvc, teamMemberTypeNames, teamMemberStatusTypes, commissionAbbreviations, programAuditSvc, programAuditAccessSvc, programAuditDetailSvc, oauth, alertSvc, programAuditValidationSvc, teamMemberStatusTypes) {
            // private variables
            const { reviewId, commissionId: reviewTeamCommissionId, commissionName: reviewTeamCommissionName, reviewTeamId } = userReviewSvc.data.currentReviewTeam;
            const currentReview = userReviewSvc.data.currentReview;
            const userProfile = oauth.getUserInfo();
            const personId = +userProfile.personId;
            const volunteerId = +userProfile.volunteerId;

            // These are set inside the activate function.
            let reviewTeamMemberId;
            let reviewProgramAudits;
            let allReviewPrograms; // includes programs being reviewed jointly by other commissions
            let programReview; // More detailed data of programs in this review.

            const model = this;

            // public properties
            model.isDataReady = false;
            model.reviewDetails = userReviewSvc.data.currentUserReviewTeam;
            model.unlockable = false; // whether or not the audit can be unlocked after it's finalized. Set inside the activate function
            model.isInstSummaryAvailable = model.reviewDetails.ngrYear > 2021;

            // Find the simultaneous visit commissions, if any.
            if (userReviewSvc.data.currentReview.reviewSimultaneousOptionDtos && userReviewSvc.data.currentReview.reviewSimultaneousOptionDtos.length > 0) {
                const commissionIdSet = new Set();

                // Not sure if reviewSimultaneousOptionDtos.length is ever > 1.
                // Loop through it and its reviewSimultaneousOptionCommissionDtos anyway,
                // extracting a set of unique commissionIds.
                userReviewSvc.data.currentReview.reviewSimultaneousOptionDtos.forEach(dto => {
                    if (dto.reviewSimultaneousOptionCommissionDtos && dto.reviewSimultaneousOptionCommissionDtos.length > 0) {
                        dto.reviewSimultaneousOptionCommissionDtos.forEach(commissionDto => {
                            commissionIdSet.add(commissionDto.commissionId);
                        });
                    }
                });

                model.simultaneousVisitCommissions = Array.from(commissionIdSet).map(commissionId => commissionAbbreviations[commissionId]).sort().join(', ');
            } else {
                model.simultaneousVisitCommissions = 'N/A';
            }

            model.commissionAbbreviations = commissionAbbreviations;
            model.teamMemberTypeNames = teamMemberTypeNames;
            
            // public functions
            model.openInstructions = programAuditSvc.openMasterInstructions;
            model.openVideoDemo = openVideoDemo;
            model.openProgramAudit = openProgramAudit;
            model.openJointReviewProgramAudit = openJointReviewProgramAudit;
            model.openPAF = openPAF;
            model.openExitStatement = openExitStatement;
            model.openAllExitStatements = openAllExitStatements
            model.getFormattedDate = getFormattedDate;
            model.getDisciplineName = getDisciplineName;
            model.getLastUpdatedTimestamp = getLastUpdatedTimestamp;
            model.isJointProgram = isJointProgram;
            model.getLabel = getLabel;
            model.isReviewedByTC = isReviewedByTC;
            model.manageTCs = manageTCs;
            model.manageRoles = manageRoles;
            model.lockAudit = lockAudit;
            model.unlockAudit = unlockAudit;
            model.getProgramPevs = getProgramPevs;
            model.getProgramAuditHistory = getProgramAuditHistory;
            model.programIsAuditable = programIsAuditable;
            model.programIsSubmitted = programIsSubmitted;
            model.isLeadPevForProgram = isLeadPevForProgram;
            model.getPevProgramRecommendedAction = getPevProgramRecommendedAction;
            model.getTeamChairNameForProgram = getTeamChairNameForProgram;
            model.isTeamChairForProgram = isTeamChairForProgram;
            model.setTeamChairReviewStatus = setTeamChairReviewStatus;
            model.getNumPendingPrograms = getNumPendingPrograms;
            model.getCurrentUserAccess = programAuditAccessSvc.getCurrentUserAccess;
            model.openInstitutionalSummary = openInstitutionalSummary;

            // initialize
            activate();

            function activate() {
                $q.all([
                    programReviewSvc.data.programs ?
                        $q.when(programReviewSvc.data.programs) :
                        programReviewSvc.loadProgramReviewsByReviewTeamId(reviewTeamId).then(() => programReviewSvc.data.programs),
                    reviewTeamMemberSvc.getMyReviewTeamMembers(reviewTeamId),
                    reviewJointOptionSvc.getProgramsByReviewId(reviewId),
                    programAuditSvc.getProgramAuditByReviewTeamId(reviewTeamId),
                    programAuditAccessSvc.getProgramAuditAccessByReviewTeamId(reviewTeamId),
                    getJointReviewPrograms()
                ])
                    .then(([programReviewResult, reviewTeamMembers, programs, programAudits, programAuditAccess, jointReviewPrograms]) => {
                        programReview = programReviewResult;

                        reviewProgramAudits = programAudits;
                        model.currentProgramAudit = programAudits.find(pa => pa.isCurrent);

                        // reviewTeamMembers may contain unapproved team members in the list
                        // Filter those out right away, and only use model.reviewTeam from now on
                        model.reviewTeam = reviewTeamMembers.filter(teamMember => teamMember.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED);
                        model.reviewTeamMember = model.reviewTeam.find(t => t.personId === personId);
                        if (model.reviewTeamMember) reviewTeamMemberId = model.reviewTeamMember.reviewTeamMemberId;

                        const teamChairs = model.reviewTeam
                            .filter(member => member.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED && (member.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || member.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR))
                            .sort((a, b) => {
                                if (a.endDate === null && b.endDate === null) return 0;
                                if (a.endDate === null) return -1;
                                if (b.endDate === null) return 1;
                                return new Date(b.endDate) - new Date(a.endDate);
                            });
                        model.teamChair = teamChairs.length ? teamChairs[0] : null;

                        // Whether or not the current logged-in user is the team chair.
                        model.isAdmin = oauth.isAdmin();
                        model.isTeamChair = model.teamChair && model.teamChair.personId === personId;
                        model.isCoTeamChair = model.reviewTeamMember ? model.reviewTeamMember.teamMemberTypeId === teamMemberTypeNames.COTEAMCHAIR : false;
                        model.isPEVForProgram = model.reviewTeamMember ? model.reviewTeamMember.teamMemberTypeId === teamMemberTypeNames.PEV : false;
                        model.coTeamChairs = model.reviewTeam.filter(member =>
                            member.teamMemberTypeId === teamMemberTypeNames.COTEAMCHAIR
                        );

                        allReviewPrograms = programs.value
                            .map(p => p.programDetailDto)
                            .map(pd => {
                                pd.programAuditDetails = model.currentProgramAudit && model.currentProgramAudit
                                    .programAuditDetailDtos
                                    .filter(d => d.programId === pd.programId)
                                    .sort((a, b) => {
                                        if (a.isCurrent || a.lastUpdatedTimestamp > b.lastUpdatedTimestamp) return -1;
                                        else if (!a.isCurrent || a.lastUpdatedTimestamp < b.lastUpdatedTimestamp) return 1;
                                        else return 0;
                                    }) || [];
                                return pd;
                            });

                        model.reviewPrograms = allReviewPrograms
                            .filter(pd => pd.commissionId === reviewTeamCommissionId && programIsDisplayable(pd));

                        model.programAuditAccess = programAuditAccess
                            .sort((a, b) => {
                                if (a.programDetailDto.programName < b.programDetailDto.programName) return -1;
                                else if (a.programDetailDto.programName > b.programDetailDto.programName) return 1;
                                else return 0;
                            });

                        const lockedProgramAudits = programAudits.filter(pa => pa.isLocked);
                        const lastLock = lockedProgramAudits.map(p => p.statusChangedTimestamp).sort()[lockedProgramAudits.length - 1];
                        const finalizeCount = lockedProgramAudits.length;
                        model.unlockable = finalizeCount === 0 ||
                            finalizeCount === 1 && moment.utc(lastLock).diff(moment.utc(), 'hours') <= 24;

                        model.jointReviewPrograms = jointReviewPrograms;
                        model.programAuditInstitutionalSummary = reviewProgramAudits.find(pa => pa.isCurrent).programAuditInstitutionalSummaryDtos[0];

                        doValidation();

                        model.isDataReady = true;
                    });
            }

            /**
             * Opens Program Audit Tool
             * @param {Object} program Program we're about to work on
             * @param {Object} programAuditDetail Program audit detail associated with program
             */
            function openProgramAudit(program, programAuditDetail) {
                if (programAuditDetail)
                    goToProgramAuditTool(programAuditDetail);
                else {
                    const modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/apps/programAudit/templates/modal/createProgramAuditDetail.html',
                        size: 'lg',
                        controllerAs: 'model',
                        controller: 'createProgramAuditDetailCtrl',
                        resolve: {
                            program: () => program,
                            programAudit: () => model.currentProgramAudit,
                            reviewTeamMembers: () => model.reviewTeam
                        }
                    });

                    modalInstance.result.then(programAuditDetail => {
                        if (programAuditDetail)
                            goToProgramAuditTool(programAuditDetail);
                    });
                }
            }

            function openInstitutionalSummary() {
                 goToProgramAuditInstitutionalSummaryTool();
            }

            function openJointReviewProgramAudit(jointReviewProgram) {
                goToProgramAuditTool(jointReviewProgram);
            }

            function goToProgramAuditTool(programAuditDetail) {
                $state.go('programAuditTool', {
                    programAuditId: programAuditDetail.programAuditId,
                    programId: programAuditDetail.programId,
                    programAuditDetailId: programAuditDetail.programAuditDetailId,
                    returnStateName: $state.current.name,
                    returnStateParams: {
                        reviewTeamId: reviewTeamId,
                        view: $stateParams.view
                    }
                });
            }

            function goToProgramAuditInstitutionalSummaryTool(p) {
                //pop up alert if !model.programAuditInstitutionalSummary
                $state.go('programAuditInstitutionalSummaryTool', {
                    programAuditId: model.programAuditInstitutionalSummary.programAuditId,
                    programAuditInstitutionalSummaryId: model.programAuditInstitutionalSummary.programAuditInstitutionalSummaryId,
                    returnStateName: $state.current.name,
                    returnStateParams: {
                        reviewTeamId: reviewTeamId,
                        view: $stateParams.view
                    }
                });
            }

            /**
             * Opens Program Audit Form PDF
             * @param {Boolean} isPreview Whether the PDF is a preview or final deliverable
             */
            function openPAF(isPreview) {
                programAuditDetailSvc.getAllProgramAuditToolData(model.currentProgramAudit.programAuditId).then(function (data) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/programAudit/templates/modal/viewPAFPreview.html',
                        size: 'xl',
                        controller: 'viewPAFPreviewCtrl',
                        resolve: {
                            currentProgramAuditData: function () { return data; },
                            isExitStatement: function () { return false; },
                            isSingleProgram: function () { return false; },
                            isInstitutionalSummary: function () { return false; },
                            isPreview: function () { return isPreview; },
                            showInstitutionalSummary: function () { return false; }
                        }
                    });
                });
            }


            /**
             * Opens Exit Statement PDF
             * @param {Object} program The program being displayed in the exit statement
             */
            function openExitStatement(program) {
                programAuditDetailSvc.getAllProgramAuditToolData(model.currentProgramAudit.programAuditId, program.programId, program.programAuditDetailID).then(function (data) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/programAudit/templates/modal/viewPAFPreview.html',
                        size: 'xl',
                        controller: 'viewPAFPreviewCtrl',
                        resolve: {
                            currentProgramAuditData: function () { return data; },
                            isExitStatement: function () { return true; },
                            isSingleProgram: function () { return true; },
                            isInstitutionalSummary: function () { return false; },
                            isPreview: function () { return false; },
                            showInstitutionalSummary: function () { return model.isTeamChair || model.isAdmin; }
                        }
                    });
                });
            }

            /**
             * Opens master PDF of all exit statements
             */
            function openAllExitStatements() {
                programAuditDetailSvc.getAllProgramAuditToolData(model.currentProgramAudit.programAuditId).then(function (data) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/programAudit/templates/modal/viewPAFPreview.html',
                        size: 'xl',
                        controller: 'viewPAFPreviewCtrl',
                        resolve: {
                            currentProgramAuditData: function () { return data; },
                            isExitStatement: function () { return true; },
                            isSingleProgram: function () { return false; },
                            isInstitutionalSummary: function () { return false; },
                            isPreview: function () { return true; },
                            showInstitutionalSummary: function () { return model.isTeamChair || model.isAdmin; }
                        }
                    });
                });
            }

            /**
             * Opens modal containing video instructions for program audit.
             */
            function openVideoDemo(openPevVideo) {
                var pevVideoId = 'pBdyawh21FA';
                var tcVideoId = 'zfpD83R1lSk';
                var videoId = (model.isPEVForProgram || openPevVideo) ? pevVideoId : tcVideoId;

                alertSvc.openVideoModal(videoId, 'How to Use the Program Audit Tool');
            }

            function isReviewedByTC(program) {
                return program.programAuditDetails && program.programAuditDetails.length > 0 && program.programAuditDetails[0].isReviewedByTeamChair;
            }


            /**
             * Get data for program status label
             * @param {Object} currentUserAccess Current user priviliges
             * @param {Object} program Program label is describing
             * @returns {string} The status label.
             */
            function getLabel(currentUserAccess, program) {
                var label = {
                    name: '',
                    desc: '',
                    colorName: ''
                };

                if (program.programAuditDetails.length < 1) {
                    if (programIsAuditable(program)) {
                        label.name = 'Not Started';
                        label.desc = 'This program audit has not been started. The PEV with primary access can begin the program audit.';

                    } else {
                        label.name = 'Not Editable';
                        label.desc = 'This program does not require a program audit for this review.';
                    }

                } else if (model.currentProgramAudit.isLocked) {
                    label.name = 'Finalized';
                    label.desc = 'This program audit is complete and a final PAF has been generated.';
                    label.colorName = 'purple';

                } else if (isReviewedByTC(program)) {
                    label.name = 'Reviewed by TC';
                    label.desc = 'This program audit has been reviewed. Review status can be changed by the Team Chair from the audit editor.';
                    label.colorName = 'green';

                } else if (!model.currentProgramAudit.isLocked && model.currentProgramAudit.statusChangedTimestamp !== null) {
                    label.name = 'Unlocked';
                    label.desc = 'This program audit has been unlocked after previously being finalized. The program aduit can be finalized by the Team Chair.';
                    label.colorName = 'yellow';

                } else {
                    var currentVersion = program.programAuditDetails.find(function (detail) { return detail.isCurrent; });
                    if (currentVersion.teamMemberTypeId == teamMemberTypeNames.PEV) {
                        label.name = 'Waiting for PEV Submission';
                        label.desc = 'This program audit is currently being worked on by a PEV.';
                        label.colorName = 'yellow';
                    } else {
                        //model.currentProgramAudit.submittedTimestamp && !program.programAuditDetails[0].isReviewedByTeamChair) {
                        label.name = 'Waiting for TC Review';
                        label.desc = 'This program audit has not been reviewed. The Team Chair can mark this item as complete from the audit editor.';
                        label.colorName = 'blue';
                    }

                }
                return label;
            }

            /**
             * Returns formatted date 
             * @param {object} date Any date
             * @returns {string} Formatted date string.
             */
            function doGetFormattedDate(date) {
                var newDate = $filter("amTimezone")(date, "America/New_York");
                return $filter("amDateFormat")(newDate, "MM/DD/YY hh:mm A") + " ET";
            }

            /**
             * Returns formatted date specific to a programAuditDetail.
             * @param {object} change An individual programAuditDetail change.
             * @returns {string} Formatted date string.
             */
            function getFormattedDate(change) {
                if (change.publishedTimestamp && !change.isReturnToEvaluator && !change.isReviewedByTeamChair) {
                    return doGetFormattedDate(change.publishedTimestamp);
                } else {
                    return doGetFormattedDate(change.submittedTimestamp);
                }
            }

            /**
             * Gets all program disciplines associated with a program and aggregates them into one string.
             *
             * @param {Number} programId The programId for which to find a discipline.
             * @returns {string} The discipline name.
             */
            function getDisciplineName(programId) {
                const programData = programReview.find(p => p.programId === programId);
                if (programData && programData.programReviewDisciplineDtos && programData.programReviewDisciplineDtos.length > 0) {
                    return programData.programReviewDisciplineDtos.map(p => p.disciplineName).join(', ');
                } else {
                    return "";
                }
            }

            /**
             * Returns lastUpdateTimestamp for a programAuditDetail as a formatted date string
             * @param {object} program A program in a programAudit
             * @returns {string} Formatted date string.
             */

            function getLastUpdatedTimestamp(program) {
                var currentVersion = program.programAuditDetails.find(function (detail) { return detail.isCurrent; });
                var changeHistory = model.getProgramAuditHistory(program);

                // Don't output last edit if it's the same time as the last item in the change history
                if ((!changeHistory.length) || currentVersion && doGetFormattedDate(currentVersion.lastUpdatedTimestamp) !== getFormattedDate(changeHistory[0])) {
                    return doGetFormattedDate(currentVersion.lastUpdatedTimestamp)
                }
                return null;
            }

            /**
             * Returns boolean value indicating joint program status
             * @param {object} program An individual program in a review/program audit
             * @returns {boolean} Whether or not this is a "joint" program
             */
            function isJointProgram(program) {
                return model.jointReviewPrograms && model.jointReviewPrograms.some(jointReviewProgram =>
                    jointReviewProgram.jointReviewProgramId === program.programId
                );
            }

            /**
             * Opens modal to manage team chair and co-team chair assignments
             */
            function manageTCs() {
                const teamChairs = [model.teamChair, ...model.coTeamChairs];

                const modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/apps/programAudit/templates/modal/manageTCs.html',
                    size: 'lg',
                    controllerAs: 'model',
                    controller: 'manageTCsEditCtrl',
                    resolve: {
                        teamMembers: () => teamChairs,
                        // create a copy of the program audit access data so we can safely work on it in the modal
                        programAuditAccessData: () => angular.copy(model.programAuditAccess.filter(p => p.accessType === 'TC'))
                    }
                });

                modalInstance.result.then(programAuditAccess => {
                    programAuditAccessSvc.updateProgramAuditAccess(programAuditAccess)
                        .then(() => {
                            activate();
                            alertSvc.addAlertSuccess('Updated Team Chair audit access.');
                        })
                        .catch(() => {
                            alertSvc.addAlertWarning('Failed to update Team Chair audit access.');
                        });
                });
            }

            /**
             * Opens modal to manage PEV program assignments.
             */
            function manageRoles() {
                const modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/apps/programAudit/templates/modal/manageRoles.html',
                    size: 'lg',
                    controllerAs: 'model',
                    controller: 'manageRolesEditCtrl',
                    resolve: {
                        teamMembers: () => model.reviewTeam,
                        programAuditAccessData: () => model.programAuditAccess.filter(p => p.accessType === 'PEV')
                    }
                });

                modalInstance.result.then(programAuditAccess => {
                    programAuditAccessSvc.updateProgramAuditAccess(programAuditAccess)
                        .then(() => {
                            activate();
                            alertSvc.addAlertSuccess('Updated PEV audit access.');
                        })
                        .catch(() => {
                            alertSvc.addAlertWarning('Failed to update PEV audit access.');
                        });
                });
            }

            /** 
             *  Locks the program audit.
             */
            async function lockAudit() {
                const confirmed = await alertSvc.booleanConfirm('Please check that you have finalized all components of the program audit and will not need to make any additional changes before you proceed. Are you sure you want to finalize this audit?');
                if (!confirmed) return;

                const programAuditDto = angular.copy(model.currentProgramAudit);
                programAuditDto.note = null;

                try {
                    const response = await programAuditSvc.lockProgramAudit(programAuditDto);
                    activate();
                    alertSvc.addAlertSuccess('Program audit has been successfully finalized');
                    $state.reload('userReview.detail');
                } catch (e) {
                    if (e.status !== 409) alertSvc.addAlertWarning('Failed to finalize audit'); // httpResponseInterceptor handles conflict messages
                }
            }

            /**
             * Opens modal which, if confirmed, will unlock the audit.
             */
            async function unlockAudit() {
                const programAuditDto = angular.copy(model.currentProgramAudit);

                try {
                    const note = await alertSvc.confirmWithNote('<p>Program audits may only be unlocked once.</p><p>Please enter your reason for unlocking the program audit in the text area below.</p>', true, 'Unlock Program Audit');
                    programAuditDto.note = note;

                    try {
                        const response = await programAuditSvc.unlockProgramAudit(programAuditDto);
                        activate();
                        window.location.reload(true);
                        alertSvc.addAlertSuccess('The audit was successfully unlocked.');
                    } catch (e) {
                        if (e.status !== 409) alertSvc.addAlertWarning('Failed to unlock audit'); // httpResponseInterceptor handles conflict messages
                    }
                } catch (e) {
                    console.log('Canceled unlock');
                }
            }

            /**
             * @returns {Number} The number of programs that have not been reviewed by team chair.
             */
            function getNumPendingPrograms() {
                return model.reviewPrograms
                    .filter(p =>
                        (
                            (
                                !p.programAuditDetails ||
                                p.programAuditDetails.length === 0
                            ) &&
                            programIsAuditable(p)
                        ) ||
                        (
                            p.programAuditDetails &&
                            p.programAuditDetails.length > 0 &&
                            !p.programAuditDetails[0].isReviewedByTeamChair
                        )
                    )
                    .length;
            }

            function getProgramPevs(program) {
                return model.reviewTeam
                    .filter(rt =>
                        rt.programId === program.programId &&
                        rt.teamMemberTypeId === teamMemberTypeNames.PEV
                    );
            }

            function getProgramAuditHistory(program, currentCommission = true) {
                return reviewProgramAudits
                    .map(r => r.programAuditDetailDtos)
                    .flat()
                    .filter(p => p.submittedTimestamp || p.publishedTimestamp)
                    .filter(p => p.programId === program.programId || (p.programName === program.programName && p.degreeLevel === program.degreeLevel))
                    .filter(p => (p.commissionId === model.reviewCommissionId) === currentCommission)
                    .sort((a, b) => {
                        if (a.isCurrent || a.lastUpdatedTimestamp > b.lastUpdatedTimestamp) return -1;
                        else if (a.lastUpdatedTimestamp < b.lastUpdatedTimestamp) return 1;
                        else return 0;
                    });
            }

            function programIsDisplayable(program) {
                const programReviewData = programReview.find(pr => pr.programId === program.programId);
                return programAuditDetailSvc.isDisplayable(programReviewData);
            }

            function programIsAuditable(program) {
                const programReviewData = programReview.find(pr => pr.programId === program.programId);
                return programAuditDetailSvc.isAuditable(programReviewData);
            }

            function programIsSubmitted(program) {
                return program.programAuditDetails &&
                    program.programAuditDetails.length > 0 &&
                    program.programAuditDetails[0].submittedTimestamp &&
                    !program.programAuditDetails[0].isReturnToEvaluator ? true : false;
            }

            function isLeadPevForProgram(program, pev) {
                if (!program || !pev) return false;
                return model.programAuditAccess
                    .filter(pa => pa.programId === program.programId && pa.accessType === 'PEV')
                    .find(pa => pa.reviewTeamMemberId === pev.reviewTeamMemberId) ? true : false;
            }

            function getPevProgramRecommendedAction(pev) {
                const reviewProgram = model.reviewPrograms.find(rp => rp.programId === pev.programId);
                // Sanity check.
                if (!reviewProgram || !reviewProgram.programAuditDetails || !reviewProgram.programAuditDetails.length) {
                    return 'Not submitted';
                }

                // Find the most recent PEV submissions, if any.
                const submissions = reviewProgram.programAuditDetails.filter(pad => pad.submittedTimestamp);
                // Extract the programAuditDetailsEvaluatorDtos.
                const evaluatorDtos = submissions.map(s => s.programAuditDetailEvaluatorDtos).flat();
                // Find the most recent programAuditDetailsEvaluatorDto that belongs to PEV.
                const programAuditDetailEvaluatorDto = evaluatorDtos.find(e => e.volunteerId === pev.volunteerId);
                if (programAuditDetailEvaluatorDto && programAuditDetailEvaluatorDto.recommendedAction) {
                    return programAuditDetailEvaluatorDto.recommendedAction;
                } else {
                    return 'Not submitted';
                }
            }

            function getTeamChairNameForProgram(program) {
                let reviewTeamMember = model.programAuditAccess && model.programAuditAccess.length > 0 && model.programAuditAccess.find(p => p.accessType === 'TC' && p.programId === program.programId);
                let reviewTeamMemberId;
                let programChair;

                if (reviewTeamMember) {
                    reviewTeamMemberId = reviewTeamMember.reviewTeamMemberId;
                    programChair = reviewTeamMemberId && [model.teamChair, ...model.coTeamChairs].find(t => t.reviewTeamMemberId === reviewTeamMemberId);
                }

                if (programChair) {
                    return `${programChair.firstName} ${programChair.lastName}`;
                } else {
                    return '';
                }
            }

            function isTeamChairForProgram(program) {
                return model.programAuditAccess.find(p => p.accessType === 'TC' && p.reviewTeamMemberId === reviewTeamMemberId) ? true : false;
            }

            /**
             * 
             * @param {any} program The program to mark or unmark reviewed
             */
            function setTeamChairReviewStatus(program) {
                const programAuditDetailDto = angular.copy(program.programAuditDetails[0]);
                programAuditDetailSvc.markAsReviewed(programAuditDetailDto);
            }

            function doValidation() {
                // Optionally set errors here before invoking validation event so tab container refreshes warnings.
                programAuditValidationSvc.invokeValidation(model.currentProgramAudit, model.programAuditAccess, programReview);
            }

            function getJointReviewPrograms() {
                const jointReviewPrograms = findJointReviewPrograms();
                // If no joint review programs found, exit early...
                if (!jointReviewPrograms.length)
                    return $q.when([]);

                return getJointReviewData(jointReviewPrograms).then(data => {
                    // Update joint review programs with additional data
                    jointReviewPrograms.forEach(jointReviewProgram => {
                        const { jointProgramAudits, jointProgramAuditAccess, jointReviewTeamMembers} = data.get(jointReviewProgram.reviewTeamId);
                        setProgramAuditData(jointReviewProgram, jointProgramAudits, jointProgramAuditAccess, jointReviewTeamMembers);
                        setTeamMembers(jointReviewProgram, jointProgramAuditAccess, jointReviewTeamMembers);
                    });

                    return jointReviewPrograms;
                })

                function findJointReviewPrograms() {
                    // Find all jointly reviewed programs
                    return userReviewSvc.data.currentReview.reviewJointOptionDtos
                        .map(reviewJointOption =>
                            reviewJointOption.reviewJointOptionProgramDtos
                        ).reduce((acc, reviewJointOptionPrograms) => {
                            const currentReviewJointOptionProgram = reviewJointOptionPrograms.find(option => option.commissionId === reviewTeamCommissionId);
                            if (!currentReviewJointOptionProgram) return acc;
                            const jointReviewPrograms = reviewJointOptionPrograms
                                .filter(option =>
                                    option.commissionId !== reviewTeamCommissionId
                                ).map(option => {
                                    const jointReviewTeam = userReviewSvc.data.currentReview.reviewTeamDtos.find(reviewTeam => reviewTeam.commissionId === option.commissionId);
                                    const jointReviewTeamId = jointReviewTeam && jointReviewTeam.reviewTeamId || 0;

                                    return {
                                        jointReviewProgramId: currentReviewJointOptionProgram.programId,
                                        commissionId: option.commissionId,
                                        programId: option.programId,
                                        reviewTeamId: jointReviewTeamId,
                                        programName: option.programName
                                    };
                                });

                            acc.push(...jointReviewPrograms);
                            return acc;
                        }, []);
                }

                function getJointReviewData(jointReviewPrograms) {
                    // Get list of joint review programs' review teams
                    let jointReviewTeamIds = new Set();
                    jointReviewPrograms.forEach(jointReviewProgram => jointReviewTeamIds.add(jointReviewProgram.reviewTeamId));
                    jointReviewTeamIds = Array.from(jointReviewTeamIds);
                    // Load additional data for joint programs' review teams 
                    return $q.all(jointReviewTeamIds.map(reviewTeamId =>
                        $q.all([
                            programAuditSvc.getProgramAuditByReviewTeamId(reviewTeamId),
                            programAuditAccessSvc.getProgramAuditAccessByReviewTeamId(reviewTeamId),
                            reviewTeamMemberSvc.getMyReviewTeamMembers(reviewTeamId) 
                        ]).then(([jointProgramAudits, jointProgramAuditAccess, jointReviewTeamMembers]) => ({
                                reviewTeamId: reviewTeamId,
                                jointProgramAudits: jointProgramAudits,
                                jointProgramAuditAccess: jointProgramAuditAccess,
                                jointReviewTeamMembers: jointReviewTeamMembers
                            })
                        )
                    )).then(data =>
                        new Map(data.map(result => [result.reviewTeamId, result]))
                    );
                }

                function setProgramAuditData(jointReviewProgram, jointProgramAudits, jointProgramAuditAccess, jointReviewTeamMembers) {
                    // Update joint review program with program audit information from review team data
                    const currentJointProgramAudit = jointProgramAudits.find(programAudit => programAudit.isCurrent);
                    const currentJointProgramAuditDetails = currentJointProgramAudit.programAuditDetailDtos.filter(programAuditDetail => programAuditDetail.programId === jointReviewProgram.programId);
                    jointReviewProgram.isProgramAuditDetailStarted = currentJointProgramAuditDetails.length;
                    const currentUserAccess = programAuditAccessSvc.getCurrentUserAccess(
                        jointReviewProgram.programId,
                        jointProgramAuditAccess,
                        jointReviewTeamMembers,
                        currentJointProgramAuditDetails,
                        currentJointProgramAudit.isLocked);
                    if (!currentUserAccess.programAuditDetail) return;
                    jointReviewProgram.programAuditId = currentJointProgramAudit.programAuditId;
                    jointReviewProgram.programAuditDetailId = currentUserAccess.programAuditDetail.programAuditDetailId;
                    jointReviewProgram.programAuditDetailEvaluators = currentUserAccess.programAuditDetail.programAuditDetailEvaluatorDtos;
                }

                function setTeamMembers(jointReviewProgram, jointProgramAuditAccess, jointReviewTeamMembers) {
                    // Update joint review program with team chair and pev information from review team data
                    const teamMembers = jointReviewTeamMembers.filter(reviewTeamMember =>
                        reviewTeamMember.teamMemberStatusId === 5 &&
                        (reviewTeamMember.teamMemberTypeId === 1 && reviewTeamMember.programId === jointReviewProgram.programId ||
                            reviewTeamMember.teamMemberTypeId === 2 ||
                            reviewTeamMember.teamMemberTypeId === 3)
                    ).map(reviewTeamMember => {
                        const teamMember = {
                            reviewTeamMemberId: reviewTeamMember.reviewTeamMemberId,
                            volunteerId: reviewTeamMember.volunteerId,
                            firstName: reviewTeamMember.firstName,
                            middleName: reviewTeamMember.middleName,
                            lastName: reviewTeamMember.lastName,
                            teamMemberTypeId: reviewTeamMember.teamMemberTypeId,
                            hasAccess: jointProgramAuditAccess.some(programAuditAccess =>
                                programAuditAccess.programId === jointReviewProgram.programId &&
                                programAuditAccess.reviewTeamMemberId === reviewTeamMember.reviewTeamMemberId &&
                                programAuditAccess.accessType === (reviewTeamMember.teamMemberTypeId === 1 ? 'PEV' : 'TC')
                            )
                        };
                        // Find recommended action
                        if (reviewTeamMember.teamMemberTypeId === 1) {
                            const programAuditDetailEvaluator = jointReviewProgram.programAuditDetailEvaluators &&
                                jointReviewProgram.programAuditDetailEvaluators.find(programAuditDetailEvaluator =>
                                    programAuditDetailEvaluator.volunteerId === reviewTeamMember.volunteerId
                                );
                            teamMember.recommendedAction = programAuditDetailEvaluator && programAuditDetailEvaluator.recommendedAction || 'Not submitted';
                        }

                        return teamMember;
                    });
                    jointReviewProgram.pevs = teamMembers.filter(teamMember => teamMember.teamMemberTypeId === 1);
                    jointReviewProgram.teamChairs = teamMembers.filter(teamMember => teamMember.teamMemberTypeId === 2 || teamMember.teamMemberTypeId === 3);
                    // No longer need program audit detail evaluator records
                    delete jointReviewProgram.programAuditDetailEvaluators;
                }
            }
        }
    ];
    module.controller('programAuditCtrl', programAuditCtrl);

}(angular.module('programAudit')));