(function (module) {

    var reviewTeamVisitObserverSvc = function ($http, $q, amsConst, odataSvc, $odata) {
        var apiPath = amsConst.webApiUrl + '/odata/ReviewTeamMember({0})';
        var factory = {};
        var queryBase = '?$filter=';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        /**********************************
        *REFACTORED
        **********************************/
        var key = 'reviewTeamId';
        var memberKey = 'reviewTeamMemberId';
        var api = '/ReviewTeamVisitObserver';
        factory.data = { visitObservers: null, pevs: null };

        factory.getVisitObserversByReviewTeamIdOdata = function (id, noStoredData) {

            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetReviewTeamVisitObserversByReviewTeamId', 'reviewTeamId', id)

            oSvc.getSource(path, memberKey).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);


            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.visitObservers = data;
                })
            }

            return oSvc.getDeferred().promise;
        }
        
        factory.create = function (newReviewTeamMember) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, newReviewTeamMember);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamVisitObserverData(oSvc, newReviewTeamMember.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.update = function (reviewTeamMember) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, reviewTeamMember);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamVisitObserverData(oSvc, reviewTeamMember.reviewTeamId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (reviewTeamMember) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, memberKey, { reviewTeamMemberId: reviewTeamMember.reviewTeamVisitObserverId });

            resource.$delete(null, oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamVisitObserverData(oSvc, reviewTeamMember.reviewTeamId);

            return oSvc.getDeferred().promise;
        };
        
        var buildFilter = function (property, parameter) {
            return property + ' ' + parameter;
        };

        var buildReviewTeamIdFilter = function (parameter) {
            return buildFilter("reviewTeamId eq", parameter);
        };

        function resolveReviewTeamVisitObserverData(oSvc, reviewTeamId){
            oSvc.getDeferred().promise.then(function () {
                factory.getVisitObserversByReviewTeamIdOdata(reviewTeamId);
            });
        }
        return {
            data: factory.data,
            getVisitObserversByReviewTeamIdOdata: factory.getVisitObserversByReviewTeamIdOdata,
            update: factory.update,
            create: factory.create,
            delete: factory.delete
        };
    };
    module.factory('reviewTeamVisitObserverSvc', reviewTeamVisitObserverSvc);

})(angular.module('review'));