(function (module) {

    var programAuditDetailEvaluatorSvc = function (amsConst, odataSvc, $http, $q, teamMemberTypeNames, statementFindingTypes,
        programReviewTypeIds, actionCodes, teamMemberStatusTypes) {

        const apiPath = amsConst.webApiUrl + '/odata/ProgramAuditDetailEvaluator';
        const factory = {};

        factory.getById = function (programAuditDetailEvaluatorId) {
            // Get individual program audit detail evaluator record; admin only access
            const oSvc = odataSvc.get();
            oSvc.getSource(apiPath).odata()
                .filter('ProgramAuditDetailEvaluatorId', parseInt(programAuditDetailEvaluatorId))
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.saveProgramAuditRecommendedActions = function (programAuditDetailEvaluators) {
            // Save a list of program audit recommnded actions for a given program audit detail.
            if (!programAuditDetailEvaluators || !programAuditDetailEvaluators.length)
                return $q.when(programAuditDetailEvaluators);

            const evaluators = angular.copy(programAuditDetailEvaluators);

            return saveProgramAuditDetailEvaluators(evaluators);
        };

        factory.deleteProgramAuditRecommendedActions = function (programAuditDetailEvaluators) {
            if (!programAuditDetailEvaluators || !programAuditDetailEvaluators.length)
                return $q.when([]);

            const evaluators = angular.copy(programAuditDetailEvaluators);
            evaluators.forEach(evaluator =>
                evaluator.isDeleted = true
            );

            return saveProgramAuditDetailEvaluators(evaluators);
        }

        factory.resetProgramAuditRecommendedActions = function (programAuditDetailEvaluators) {
            if (!programAuditDetailEvaluators || !programAuditDetailEvaluators.length)
                return $q.when([]);

            const evaluators = angular.copy(programAuditDetailEvaluators);

            evaluators.forEach(evaluator =>
                evaluator.recommendedAction = null
            );
            // Only update recommended action on previously saved records
            existingEvaluators = evaluators.filter(evaluator =>
                evaluator.programAuditDetailEvaluatorId
            );

            return saveProgramAuditDetailEvaluators(existingEvaluators).then(() =>
                evaluators
            );
        }

        factory.initializeProgramAuditRecommendedActions = function (programAuditDetail, reviewTeamMembers) {
            const now = new Date().getUTCDate();
            const pevs = reviewTeamMembers.filter(reviewTeamMember =>
                reviewTeamMember.teamMemberTypeId === teamMemberTypeNames.PEV &&
                reviewTeamMember.programId === programAuditDetail.programId &&
                reviewTeamMember.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED &&
                (reviewTeamMember.endDate === null || new Date(reviewTeamMember.endDate) >= now)
            );

            const currentEvaluators = programAuditDetail.programAuditDetailEvaluatorDtos || [];

            const newEvaluators = pevs.filter(pev =>
                !currentEvaluators.some(evaluator =>
                    evaluator.volunteerId === pev.volunteerId
                )
            ).map(pev =>
                ({
                    "programAuditDetailEvaluatorId": 0,
                    "programAuditDetailId": programAuditDetail.programAuditDetailId,
                    "volunteerId": pev.volunteerId,
                    "recommendedAction": null
                })
            );

            const formerEvaluators = angular.copy(
                currentEvaluators.filter(evaluator =>
                    !pevs.some(pev =>
                        pev.volunteerId === evaluator.volunteerId
                    )
                )
            );
            formerEvaluators.forEach(evaluator =>
                evaluator.isDeleted = true
            );

            return newEvaluators.length || formerEvaluators.length ?
                saveProgramAuditDetailEvaluators([...newEvaluators, ...formerEvaluators]).then(programAuditDetailEvaluators =>
                    programAuditDetailEvaluators
                ) :
                $q.when(currentEvaluators);
        }

        factory.getRecommendedActionOptions = function (actions, programReviewTypeCode, programAuditDetail) {
            // todo: abstract this to a service shared with statement too and replace code in editRecommendedActionCtrl.js
            let programShortcomings = programAuditDetail.programAuditJson && programAuditDetail.programAuditJson.auditDetails ?
                programAuditDetail.programAuditJson.auditDetails.reduce((shortcomings, findingType) => {
                    switch (findingType.statementFindingTypeId) {
                        case statementFindingTypes.PROGRAMDEFICIENCY:
                            shortcomings.deficiency = true;
                            break;
                        case statementFindingTypes.PROGRAMWEAKNESS:
                            shortcomings.weakness = true;
                            break;
                        case statementFindingTypes.PROGRAMCONCERN:
                            shortcomings.concern = true;
                            break;
                    }
                    return shortcomings;
                }, {}) :
                {};
            programShortcomings.none = !programShortcomings.concern && !programShortcomings.weakness && !programShortcomings.deficiency;

            let actionCodeOptions = [];
            switch (programReviewTypeCode) {
                case programReviewTypeIds.INITIALACCREDIATION:
                    if (programShortcomings.deficiency)
                        actionCodeOptions = [actionCodes.NA];
                    else if (programShortcomings.weakness)
                        actionCodeOptions = [actionCodes.IR, actionCodes.IV];
                    else
                        actionCodeOptions = [actionCodes.NGR];
                    break;

                case programReviewTypeIds.COMPREHENSIVEVISIT:
                    if (programShortcomings.deficiency)
                        actionCodeOptions = [actionCodes.SCR, actionCodes.SCV];
                    else if (programShortcomings.weakness)
                        actionCodeOptions = [actionCodes.IR, actionCodes.IV];
                    else
                        actionCodeOptions = [actionCodes.NGR];
                    break;

                case programReviewTypeIds.FOCUSEDVISIT:
                    if (programShortcomings.deficiency)
                        actionCodeOptions = [actionCodes.SCR, actionCodes.SCV];
                    else if (programShortcomings.weakness)
                        // Program audit for interim reports/visit should allow RE/VE option when there are weaknesses (leaving statement tool alone for now).
                        actionCodeOptions = [actionCodes.IR, actionCodes.IV, actionCodes.VE];
                    else
                        actionCodeOptions = [actionCodes.VE];
                    break;

                case programReviewTypeIds.FOCUSEDREPORT:
                    if (programShortcomings.deficiency)
                        actionCodeOptions = [actionCodes.SCR, actionCodes.SCV];
                    else if (programShortcomings.weakness)
                        // Program audit for interim reports/visit should allow RE/VE option when there are weaknesses (leaving statement tool alone for now).
                        actionCodeOptions = [actionCodes.IR, actionCodes.IV, actionCodes.RE];
                    else
                        actionCodeOptions = [actionCodes.RE];
                    break;

                case programReviewTypeIds.SHOWCAUSEVISIT:
                case programReviewTypeIds.SHOWCAUSEREPORT:
                    if (programShortcomings.deficiency)
                        actionCodeOptions = [actionCodes.SCR, actionCodes.SCV, actionCodes.NA];
                    else if (programShortcomings.weakness)
                        actionCodeOptions = [actionCodes.IR, actionCodes.IV];
                    else
                        actionCodeOptions = [actionCodes.SE];
                    break;
            }

            return actions.filter(action =>
                actionCodeOptions.includes(action.actionCode)
            );
        }

        function saveProgramAuditDetailEvaluators(programAuditDetailEvaluators) {
            // Note: Should stay consistent by using $odataresource but not sure about OData action support.
            return $http.post(
                amsConst.webApiUrl + '/odata/SaveProgramAuditRecommendedAction',
                {
                    Value: programAuditDetailEvaluators
                }
            ).then(response =>
                response.data.value
            );
        }

        return {
            getById: factory.getById,
            saveProgramAuditRecommendedActions: factory.saveProgramAuditRecommendedActions,
            deleteProgramAuditRecommendedActions: factory.deleteProgramAuditRecommendedActions,
            resetProgramAuditRecommendedActions: factory.resetProgramAuditRecommendedActions,
            initializeProgramAuditRecommendedActions: factory.initializeProgramAuditRecommendedActions,
            getRecommendedActionOptions: factory.getRecommendedActionOptions
        };
    };

    module.factory('programAuditDetailEvaluatorSvc', programAuditDetailEvaluatorSvc);

})(angular.module('programAudit'));
