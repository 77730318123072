(function (module) {
    var rrProgramCtrl = function ($uibModal, $state, helperSvc, alertSvc, $stateParams, readinessSvc, rfrSvc) {

        var model = this;

        model.isReadOnly = readinessSvc.isReadOnly();
        model.isReviewer = readinessSvc.isReviewer();
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openProgramInfoInstructions = readinessSvc.openProgramInfoInstructions;
        model.readOnlyText = model.isReadOnly ? (model.isReviewer ? 'Reviewers cannot edit programs.' : 'Programs cannot be edited after submission.') : '';

        model.edit = function (program) {
            var program = null;
            var commission = null;
            var isChangeNameRequest = false;
            var mode = { readiness: true };

            rfrSvc.addEditProgram(program, commission, isChangeNameRequest, mode);
        };

        var activate = function () {
            model.data = readinessSvc.data;
        }();
    };
    module.controller('rrProgramCtrl', rrProgramCtrl);

}(angular.module('readinessReview')));