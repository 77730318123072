(function (module) {

    var rejectPevModalCtrl = function (pev, callback, $scope, $uibModalInstance) {
        var model = $scope;

        //must be an object because scoping in angular is dumb sometimes
        model.reason = { reason: '' };
        model.title = 'Reject PEV';
        model.subtitle = pev.firstName + ' ' + pev.lastName;
        model.rejectMsg = 'You are about to reject a program evaluator, <em>' + pev.firstName + ' ' + pev.lastName + '</em>, who has been selected by their commission. Please provide a reason for your rejection.'

        model.rejectPev = function () {
            callback(model.reason.reason, pev);
            $uibModalInstance.close();
            return true;
        };

        model.close = function () {
            $uibModalInstance.close();
            return false;
        };
    };

    module.controller('rejectPevModalCtrl', rejectPevModalCtrl);

}(angular.module('userReview')));