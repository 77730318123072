(function (module) {
    var npcPreviousStatementTabCtrl = function ($state, $scope, $uibModal, oauth, helperSvc, barSvc, alertSvc, codeSvc, alertSvc, npcSvc, eventSvc, commissionAbbreviations, documentSvc, documentTypes) {
        var model = this;

        model.parent = $scope.$parent.$parent.$parent.model;
        model.isDataReady = false;
        model.slug = 'statement'; //pevAppSvc.slugs.SUBMIT;
        model.isAdmin = oauth.isAdmin();
        model.previousStatements = [];
        model.historicalFinalStatements = []
        model.data = {
            documents: null
        }     


        var activate = function () {
            model.npcData = model.parent.npcData.npc ? model.parent.npcData.npc : model.parent.npcData;
            model.currentCommission = commissionAbbreviations[model.npcData.commissionId];
            var currentDate = new Date();
            var currentYear = currentDate.getFullYear();
            var dataSourceArray = [];

            //var historicalFinalStatementsDataSource = {
            //    dataHolder: model,
            //    dataLocationName: 'historicalFinalStatements',
            //    svcCallback: npcSvc.getHistoricalFinalStatementsByOrganizationId,
            //    svcCallbackArguments: [model.reviewTeamId],
            //    odataResource: true
            //};

            //dataSourceArray.push(historicalFinalStatementsDataSource);

            var documentsDataSource = {
                dataHolder: documentSvc,
                dataLocationName: 'data.documents',
                helperCallback: helperSvc.getValue,
                svcCallback: documentSvc.getDocumentsByTypeForEntity,
                svcCallbackArguments: [documentTypes.FINAL_STATEMENT.documentTypeId, model.npcData.organizationId, noStoredData = true]
            };
            dataSourceArray.push(documentsDataSource);

            var sinceCurrentCycleStatementsDataSource = {
                dataHolder: model,
                dataLocationName: 'previousStatements',
                helperCallback: helperSvc.getValue,
                svcCallback: npcSvc.getPreviousFinalStatementsSinceCurrentCycle,
                svcCallbackArguments: [model.npcData.organizationId, model.npcData.commissionId, currentYear],
                //odataResource: false
            };
            dataSourceArray.push(sinceCurrentCycleStatementsDataSource);

            helperSvc.getData(dataSourceArray).then(function () {

                model.isDataReady = true;
            })

            //npcSvc.getPreviousFinalStatementsSinceCurrentCycle(model.npcData.organizationId, model.npcData.commissionId, currentYear).then(function (data) {
            //    model.previousStatements = data;
                
            //})

        }();
    };
    module.controller('npcPreviousStatementTabCtrl', npcPreviousStatementTabCtrl);

}(angular.module('npc')));