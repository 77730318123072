(function (module) {
    var statementFinalActionCtrl = function (helperSvc, barSvc, codeSvc, programReviewFinalActionSvc, oauth, abetRoles, orgConstants, commissionTypes, alertSvc, $filter, $uibModal, $http) {
        // private fields
        var defaultSearchMsg = "Waiting on search...";
        var adminRecommendedActionAuditDesignations = oauth.getDesignationIdsByRoleId(abetRoles.ADMINRECOMMENDEDACTIONAUDIT);
        var adjunctRecommendedActionAuditDesignations = oauth.getDesignationIdsByRoleId(abetRoles.ADJUNCTRECOMMENDEDACTIONAUDIT);

        var model = this;

        // public fields
        model.isDataReady = false;
        model.resultMsg = defaultSearchMsg;
        model.selectedPrograms = [];
        model.showSpinnerSearch = false;
        model.commissions = commissionTypes;
        model.selectedInstitution = '';
        model.userFullName = oauth.getUserInfo().firstName + " " + oauth.getUserInfo().lastName;

        model.reset = function () {
            model.reviews = null;
            model.resultMsg = defaultSearchMsg;
            model.searchOptions = {
                reviewYear: null,
                commissionId: 0
            };
        };

        barSvc.listenForShow(function () {
            model.showSpinnerSearch = true;
        });

        barSvc.listenForHide(function () {
            model.showSpinnerSearch = false;
        });

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.reviews = null;
            barSvc.showSpinner();

            programReviewFinalActionSvc.advancedSearch(searchOpts).then(function (data) {
                barSvc.hideSpinner();
                model.reviews = getReviews(data);
                if (data.length === 0) {
                    model.resultMsg = "No results found";
                    programReviewFinalActionSvc.data.programReviews = [];
                } else {
                    model.resultMsg = null;
                    model.selectInstitution(model.reviews[0]);
                }
            });
        };

        model.selectInstitution = function (institution) {
            model.selectedInstitution = institution;
            model.reviews.forEach(function (item) {
                item.isSelected = false;
            });
            institution.isSelected = true;
            programReviewFinalActionSvc.getProgramReviewsByReviewId(institution.reviewId, model.searchOptions, 'programReviewVotedActionDtos').then(function () {
            });
        };

        model.userCanEditRecommendation = function (program) {
            // if user has userRoles object.roleId of 51 or 52 AND oAuthOrganizationUserDesignations object.designationId equal to the program.commissionId
            return oauth.getUserInfo().userRoles
                .filter(function (role) {
                    return role.roleId == abetRoles.ADMINRECOMMENDEDACTIONAUDIT ||
                        (role.roleId == abetRoles.ADJUNCTRECOMMENDEDACTIONAUDIT && programMatchesCommission(role));
                }).length > 0;

            function programMatchesCommission(role) {
                return role.oAuthOrganizationUserDesignations.filter(function (orgUserDesignation) {
                    return orgUserDesignation.designationId == program.commissionId;
                }).length > 0;
            }
        };

        model.getUserProgramRole = function (program) {
            if (adjunctRecommendedActionAuditDesignations.length > 0) {
                return "ADJ";
            } else {
                return "ADM";
            }
        };

        function findUserProgramVotedActions(program) {
            return program.programReviewVotedActionDtos.filter(function (recommendedAction) {
                return recommendedAction.userId == oauth.getUserInfo().userId;
            });
        }

        model.editProgram = function (program) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/editProgramReview.html',
                size: 'lg',
                controller: 'editProgramReviewCtrl',
                resolve: {
                    programReview: function () { return program; },
                    filters: function () { return model.searchOptions; }
                }
            });
        };

        model.editVotedAction = function (program) {
            var existingUserVotedAction = model.getUserProgramVotedAction(program);
            if (existingUserVotedAction && existingUserVotedAction.effectiveDate) existingUserVotedAction.effectiveDate = helperSvc.formatDate(existingUserVotedAction.effectiveDate);
            var possibleActions = [];
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/editVotedAction.html',
                size: 'md',
                controller: 'editVotedActionCtrl as model',
                resolve: {
                    lastProgramAction: function () { return program.programReviewTypeCode === 'IGR' ? 'NEW' : getLastAction(program); },
                    existingUserActionData: function () {
                        if (existingUserVotedAction) {
                            return {
                                actionCode: existingUserVotedAction.actionCode,
                                effectiveDate: existingUserVotedAction.effectiveDate,
                                note: existingUserVotedAction.note
                            };
                        } else {
                            return {
                                actionCode: '',
                                effectiveDate: '',
                                note: ''
                            };
                        }
                    }
                }
            });

            modalInstance.result.then(function (newUserAction) {
                var requestBody = {
                    actionCode: newUserAction.actionCode,
                    effectiveDate: newUserAction.effectiveDate,
                    programReviewId: program.programReviewId,
                    note: newUserAction.note
                };

                // Remove unneeded request body members entirely.
                if (!requestBody.effectiveDate) delete requestBody.effectiveDate;
                if (!requestBody.note) delete requestBody.note;

                if (newUserAction.effectiveDate) {
                    newUserAction.effectiveDate = newUserAction.effectiveDate.toISOString();
                } else {
                    delete(newUserAction.effectiveDate);
                }

                $http.post(
                    '/webapi/odata/SetProgramReviewVotedAction',
                    requestBody
                )
                    .success(function (response) {
                        var userVotedActions = findUserProgramVotedActions(program);
                        if (userVotedActions.length > 0) {
                            userVotedActions.forEach(function (votedAction) {
                                votedAction.actionCode = response.actionCode;
                                votedAction.effectiveDate = response.effectiveDate;
                                votedAction.note = response.note;
                            });
                        } else {
                            program.programReviewVotedActionDtos.push(response);
                        }
                    })
                    .error(function onFailure(error) {
                        console.error('Error saving recommended action', error);
                        alertSvc.addAlertWarning('Failed to update recommended action');
                    });
            });
        };

        model.getUserProgramVotedAction = function (program) {
            var userVotedActions = findUserProgramVotedActions(program);

            if (userVotedActions.length > 0) {
                return userVotedActions[0];
            } else {
                return null;
            }
        };

        function formatDate(date) {
            return date.toISOString().substring(0, 10);
        }

        model.getNextAccreditationDateRange = function (program) { 
            if (!program.accreditationStartDate || !program.accreditationEndDate) return 'Not entered';
            var startDate = new Date(program.accreditationStartDate);
            var endDate = new Date(program.accreditationEndDate);
            return formatDate(startDate) + ' - ' + formatDate(endDate);
        };

        model.getCurrentlyAccreditationDateRange = function (program) {
            if (!program.previousAccreditationStartDate || !program.previousAccreditationEndDate) return 'Not entered';
            var startDate = new Date(program.previousAccreditationStartDate);
            var endDate = new Date(program.previousAccreditationEndDate);
            return formatDate(startDate) + ' - ' + formatDate(endDate);
        };

        model.getFinalAction = function (program) {
            if (!program.actionCode || !program.actionCode === "") return 'Not entered';
            return program.actionCode + ' (' + program.reviewYear + ')';
        };

        model.getLastActionWithReviewCycle = function (program) {
            if (!program.lastAction || !program.lastAction === "") return 'Not entered';
            return program.lastActionReviewCycle ? program.lastAction + ' (' + program.lastActionReviewCycle + ')' : program.lastAction;
        };

        function getLastAction(program) {
            if (!program.lastAction) return 'Not entered';
            else return program.lastAction;
        }

        function getReviews(programReviews) {
            var reviews = [];
            angular.forEach(programReviews, function (programReview) {
                var found = reviews.some(function (item) {
                    return item.reviewId === programReview.reviewId;
                });
                if (!found) {
                    var review = {
                        reviewId: programReview.reviewId,
                        organizationName: programReview.organizationName,
                        organizationSortName: programReview.organizationSortName,
                        commission: programReview.commission,
                        reviewYear: programReview.reviewYear,
                        reviewTypeCode: programReview.reviewTypeCode,
                        ngrYear: programReview.ngrYear,
                        address: programReview.address,
                        city: programReview.city,
                        countryCode: programReview.countryCode,
                        stateCode: programReview.stateCode,
                        province: programReview.province
                    };

                    reviews.push(review);
                }
            });
            return reviews;
        }

        var activate = function () {
            model.reset();
            model.data = programReviewFinalActionSvc.data;

            barSvc.showSpinner();
            model.years = getYearSpans();
            var dataSourceArray = [
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getReviewProcessStatuses],
                    svcCallbackArguments: [],
                    dataLocationName: 'reviewStatuses',
                    helperCallback: helperSvc.getValue
                }
            ];


            helperSvc.getData(dataSourceArray).then(function () {
                barSvc.hideSpinner();
            });

            // If designations are found because the user has role Adjunct Recommended Action Audit,
            // update model.commissions to only have the user's commission(s)
            if (adjunctRecommendedActionAuditDesignations.length > 0) {
                model.commissions = model.commissions.filter(function (commission) {
                    return adjunctRecommendedActionAuditDesignations.indexOf(commission.id) > -1;
                });
            }
            // If we only have one designated commission, set the search option to it
            if (model.commissions.length === 1) {
                model.searchOptions.commissionId = model.commissions[0].id;
            }
        }();

        var softReset = function (message) {
            barSvc.hideSpinner();
            model.rfrs = null;
            model.resultMsg = message;
        };

        function getYearSpans() {
            var startYear = 2016;   //rfrs started being tracked for this year, the cycle begins year 2018
            var currentYear = new Date().getFullYear();
            var numberOfYearsToAdd = 1 + currentYear - startYear;   //+1 because we need to loop atleast once for the year 2016
            var years = [];

            for (var i = 0; i < numberOfYearsToAdd ; i++) {
                //+2 because the year cycle begins in 2018
                years.push({ year: startYear + i + 2, yearSpan: helperSvc.getYearSpan(startYear + i + 2) });
            }

            return years;
        }
    };
    module.controller('statementFinalActionCtrl', statementFinalActionCtrl);

}(angular.module('statement')));