(function (module) {

    var documentFilesUploadCtrl = function (referenceId, documentTypeId, $scope, $uibModalInstance, codeSvc, helperSvc, rfrSvc, alertSvc, typeConstSvc, Upload, $timeout, documentSvc) {
        var model = $scope;
        model.referenceId = parseInt(referenceId);
        model.documentTypeId = parseInt(documentTypeId);
        model.documentTypeName = typeConstSvc.getDocumentTypeById(model.documentTypeId).documentTypeName;
        model.title = 'Upload ' + model.documentTypeName + ' File(s)';
        model.uploading = false;
        model.stream_id = null;

        model.data = {
            id: model.referenceId,
            typeId: model.documentTypeId,
            title: null,
            file: null
        };

        model.reset = function () {
            model.data.documentTitle = null;
            model.data.file = null;
            model.uploadProgress = -1;
        };

        model.uploadDocument = function () {
            model.uploading = true;

            var id = model.referenceId;
            var documentType = model.documentTypeId;
            var documentTitle = model.data.documentTitle;
            var file = model.data.file;

            var doc = {
                referenceId: id,
                documentTypeId: documentType,
                documentTitle: documentTitle || null,
                file: file
            };

            documentSvc.uploadDocument(doc).then(
                function (response) {
                    model.stream_id = response.data.value;

                    $timeout(function () {
                        model.reset();
                        alertSvc.addAlertSuccess("Document successfully uploaded.");
                        model.cancel();
                        model.uploading = false;
                    });
                },
                function (response) {
                    if (response.status > 0) {
                        model.uploadErrorMsg = response.data;
                    }
                    alertSvc.addModalAlertWarning("Document could not be uploaded at this time.");
                    model.uploading = false;
                }, function (evt) {
                    // Not currently being used
                    // Math.min is to fix IE which reports 200% sometimes
                    model.uploadProgress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }
            );
        };

        model.cancel = function () {
            $uibModalInstance.close({
                stream_id: model.stream_id
            });
        };
    };

    module.controller('documentFilesUploadCtrl', documentFilesUploadCtrl);

}(angular.module('common')));