(function (module) {

    var collegeDirectoryEditCtrl = function (college, mode, selectedCollegeVerification, $scope, $uibModalInstance, collegeDirectorySvc, codeSvc, alertSvc) {
        var model = $scope;

        model.title = "Edit College Information";
        model.college = {};
        model.stateList = [];
        model.countryList = [];

        activate();

        if (college) {
            model.isCreateMode = false;
            model.isUpdateMode = true;
            model.college = angular.copy(college);
        } else {
            model.isCreateMode = true;
            model.isUpdateMode = false;
        }

        model.pendingMode = mode ? mode.toLowerCase().contains('pending') : false;

        if (selectedCollegeVerification) {
            mapVerificationToCollegeDirectory(selectedCollegeVerification);
        }

        model.save = function () {
            var college = model.college;

            if (college.collegeDirectoryId === 0 || college.collegeDirectoryId === undefined) {
                college.collegeDirectoryId = 0;
                if (model.pendingMode) {
                    collegeDirectorySvc.verify(selectedCollegeVerification.collegeVerificationId, 0, college).then(function (data) {
                        model.college = {};
                        $uibModalInstance.close();
                        alertSvc.addAlertSuccess("College successfully created and verified.");
                    });
                } else {
                    collegeDirectorySvc.create(college).then(function (data) {
                        model.college = {};

                        $uibModalInstance.close();
                        alertSvc.addAlertSuccess("College successfully created.");
                    });
                }
            }
            else {
                collegeDirectorySvc.update(college).then(function (data) {
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("College successfully updated.");
                });
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.resetState = function (college) {
            if (college.countryCode != 'US') {
                college.stateCode = '';
            }
        };

        function mapVerificationToCollegeDirectory(collegeVerification) {
            model.college.collegeName = collegeVerification.name;
            model.college.street = collegeVerification.address1;
            model.college.city = collegeVerification.city;
            model.college.stateCode = collegeVerification.stateCode;
            model.college.province = collegeVerification.province;
            model.college.postalCode = collegeVerification.postalCode;
            model.college.countryCode = collegeVerification.countryCode;
            model.college.website = collegeVerification.website;
        }

        function activate() {
            codeSvc.getStates().then(function (data) {
                model.stateList = data.value;
            });
            codeSvc.getCountries().then(function (data) {
                model.countryList = data.value;
            });
        }
    };

    module.controller('collegeDirectoryEditCtrl', collegeDirectoryEditCtrl);

}(angular.module('collegeDirectory')));