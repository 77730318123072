(function (module) {

    var rfrSubmitCtrl = function ($state, $scope, rfrSvc, currentUser, alertSvc, helperSvc, rfrProcessStatuses, typeConstSvc, contactRoleTypes) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.presidentName = currentUser.profile.firstName + " " + currentUser.profile.lastName;
        model.presidentSignature = undefined;
        model.signCheck = false;
        model.rfr = null;
        model.data = rfrSvc.data;
        model.rfrProcessStatuses = rfrProcessStatuses;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openSignInstructions = rfrSvc.openSignInstructions;

        model.formatDate = helperSvc.formatDate;

        model.PRESIDENT = contactRoleTypes.PRESIDENT;

        model.getSubmittedCommissionsText = function () {
            return getSubmittedCommissionText(true);
        };

        model.getNonSubmittedCommissionsText = function () {
            return getSubmittedCommissionText(false);
        };

        model.submitToAbet = function () {
            model.rfr.rfrProcessStatusId = rfrProcessStatuses.WAITINGFORABETACCEPTANCE;
            model.rfr.signedText = model.presidentSignature;
            var now = new Date();
            model.rfr.signedTimestamp = now;
            model.rfr.signedByUserId = currentUser.profile.userId;
            model.rfr.submittedTimestamp = now;
            model.rfr.submittedByUserId = currentUser.profile.userId;

            rfrSvc.presidentSubmitToAbet(model.rfr).then(function () {
                alertSvc.addAlertSuccess("RFE succesfully submitted to ABET.");

                $state.go('rfeSummary', {
                    rfrId: model.rfr.rfrId,
                    organizationId: model.rfr.organizationId
                });
            });
        };

        function rfrSubmittedToAbet() {
            return rfrSvc.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORABETACCEPTANCE;
        }

        function getSubmittedCommissionText(getSubmitted) {
            var commissions = "";

            if (rfrSvc.rfrHasCommissionDtos()) {
                angular.forEach(rfrSvc.data.rfr.rfrCommissionDtos, function (value) {
                    if (getSubmitted && value.submittedTimestamp) {
                        if (commissions === "") {
                            commissions = typeConstSvc.getCommissionAbbreviationById(value.commissionId)
                        } else {
                            commissions += " " + typeConstSvc.getCommissionAbbreviationById(value.commissionId);
                        }
                    } else if (!getSubmitted && !value.submittedTimestamp) {
                        if (commissions === "") {
                            commissions = typeConstSvc.getCommissionAbbreviationById(value.commissionId)
                        } else {
                            commissions += " " + typeConstSvc.getCommissionAbbreviationById(value.commissionId);
                        }
                    }
                });
            }

            return commissions;
        }

        var activate = function () {
            model.rfr = angular.copy(rfrSvc.data.rfr);
        }();
    };

    module.controller('rfrSubmitCtrl', rfrSubmitCtrl);

}(angular.module('rfr')));