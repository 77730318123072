(function (module) {

    var employmentInfoCtrl = function ($scope, $uibModal, employmentInfoSvc, volunteerSvc, volunteerHelperSvc, pevAppSvc, componentHelperSvc) {
        var model = this;

        model.title = 'Employment Information';
        model.parent = $scope.$parent.model;
        var checkIsVolunteer = true;
        model.applicationMode = pevAppSvc.isApplicationMode(checkIsVolunteer);
        model.isButtonHidden = model.applicationMode ? pevAppSvc.isEditButtonHiddenForGeneralModules() : pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;


        if (model.applicationMode) {
            model.volunteerData = pevAppSvc.volunteerData;
            model.employmentData = pevAppSvc.volunteerEmploymentData;
        } else {
            model.volunteerData = volunteerSvc.data;
            model.employmentData = employmentInfoSvc.data;
        }

        componentHelperSvc.invokeOnRendered('employmentInfo', $scope);

        model.getCurrentEmploymentStr = pevAppSvc.getCurrentEmploymentStr;

        model.getBackgroundInfoText = function () {
            return model.volunteerData ? pevAppSvc.getBackgroundStr(model.volunteerData.volunteer) : '';
        };

        model.editBackground = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/employmentBackgroundEdit.html',
                size: 'md',
                controller: 'employmentBackgroundEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.editEmploymentInfo = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/employmentInfoEdit.html',
                size: 'lg',
                controller: 'employmentInfoEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('employmentInfoCtrl', employmentInfoCtrl);

}(angular.module('volunteer')));