(function (module) {
    var otherTrainingCtrl = function ($uibModal, contactRoleTypes, $stateParams, pevAppSvc, trainingStatusIds, reviewTeamMemberSvc, alertSvc, helperSvc, codeSvc, oauth, trainingSvc, $stateParams, volunteerHelperSvc, commissionAbbreviations, pevApplicationSocietyStatuses,barSvc) {

        var model = this;
        var selectedId = null;
        model.training = $stateParams.training;
        model.title = model.training.trainingName;
        model.isAdmin = oauth.isAdmin();
        model.isDataReady = false;
        model.commissionNames = commissionAbbreviations;
        model.searchOptions = { }
        model.trainingStatusIds = trainingStatusIds;
        model.showSpinnerExport = false;

        model.enroll = function (pevc) {

            var msg = "You are about to enroll all unenrolled users. This may take several minutes. Do you want to proceed?";

            alertSvc.confirm(msg, doEnroll);

            function doEnroll() {
                barSvc.showSpinner();

                trainingSvc.enrollUsers(model.training.trainingId).then(function (data) {
                        barSvc.hideSpinner();
                        retriveData();
                        alertSvc.addAlertSuccess("Enrollment completed.");
                     },
                    function (error) {
                        console.log(error);
                        barSvc.hideSpinner();
                        retriveData();
                        alertSvc.addAlertWarning('Finished requsted. However, some records have not been saved correctly. Please check and re-run.');
                    }
                );
            };
        };
        function retriveData() {
            model.data = trainingSvc.data;

            trainingSvc.getOtherTrainingPersons(model.searchOptions).then(function (data) {
                model.isDataReady = true;
            });
        }

        var activate = function () {
            var dataArray = [];

            model.searchOptions.trainingId = $stateParams.training.trainingId;
            var getCommissionData = {
                dataHolder: model,
                dataLocationName: 'trainingCommissions',
                svcCallback: codeSvc.getCommissions,
                helperCallback: helperSvc.getResults
            };
            dataArray.push(getCommissionData);

            helperSvc.getData(dataArray).then(function () {
                retriveData();
            });
        }();
    };
    module.controller('otherTrainingCtrl', otherTrainingCtrl);

}(angular.module('training')));