(function (module) {

    var rrGeneralEditCtrl = function (rr, $scope, $uibModalInstance, readinessSvc, codeSvc, helperSvc, organizationSvc) {
        var model = $scope;

        model.title = 'Edit Institution Info';
        model.rr = angular.copy(rr);
        model.isDataReady = false;
        model.data = {};
        model.data.recognitionData = []

        model.save = function () {
            model.rr.organizationJson.countryName = getCountryName(model.rr.organizationJson.countryCode)

            copyAgenciesToRR()
            readinessSvc.update(model.rr).then(function () {
                $uibModalInstance.close();
            })
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        //digest cycle doesnt happen until after the click has completed it seems 
        //so that the model.isOutsideUS is not properly set until after. having the second
        //bool param is a workaround
        model.changeUS = function (address, isOutside) {
            if (isOutside) {
                address.countryCode = '';
                address.stateCode = '';
                model.selectedRecognitionList = 'nonUSList';
            }
            else {
                address.countryCode = 'US';
                address.province = '';
                model.selectedRecognitionList = 'usList';
            }
        };

        model.changeCountry = function (address) {
            if (address.countryCode == 'US') {
                model.isOutsideUS = false;
                address.province = '';
                model.selectedRecognitionList = 'usList';
            }
            else {
                model.isOutsideUS = true;
                address.stateCode = '';
                model.selectedRecognitionList = 'nonUSList';
            }
        };

        function getCountryName(countryKey) {
            var country = helperSvc.getFirstMatch(model.countries, 'codeKey', countryKey);
            return country.codeName;
        }


        //Recognizing Agency stuff
        model.data.agencies = model.rr.organizationJson.organizationRecognition;


        model.addAgency = function () {

            var newAgencyToAdd = model.data.newAgency;

            model.data.agencies.push(newAgencyToAdd)

            model.addNewAgencyHidden = true;
        };

        model.addNewAgency = function () {
            if (model.addNewAgencyHidden) {
                model.addNewAgencyHidden = false;
            } else {
                model.addNewAgencyHidden = true;
            }
        };

        model.cancelNewAgency = function () {
            model.addNewAgencyHidden = true;
            model.data.newAgency = null;
        }

        model.deleteAgency = function (agency) {
            var agencyToBeDeletedIndex = 0;

            for (var x = 0; x < model.data.agencies.length; x++) {
                var theAgency = model.data.agencies[x];

                if (theAgency.organizationName == agency.organizationName) {
                    agencyToBeDeletedIndex = x;
                    break;
                }
            }
            model.data.agencies.splice(agencyToBeDeletedIndex, 1);

        };

        var copyAgenciesToRR = function () {
            var recognitionAgencies = [];

            model.data.agencies.forEach(function (agency) {
                var orgId, orgName;

                if (agency.organizationId === 'OTHER') {
                    orgId = 0;
                    orgName = agency.writeIn
                } else {
                    orgId = agency.organizationId ? agency.organizationId : agency.recognitionOrganizationId;
                    orgName = agency.organizationName
                }

                var newAgency = {
                    recognitionOrganizationId: orgId,
                    organizationName: orgName
                };

                recognitionAgencies.push(newAgency);
            });
            model.rr.organizationJson.organizationRecognition = recognitionAgencies;
        };


        var activate = function () {
            var states = {
                dataHolder: model,
                dataLocationName: 'states',
                svcCallback: codeSvc.getStates,
                helperCallback: helperSvc.getValue
            };

            var countries = {
                dataHolder: model,
                dataLocationName: 'countries',
                svcCallback: codeSvc.getCountries,
                helperCallback: helperSvc.getValue
            };

            helperSvc.getData([states, countries]).then(function () {

                organizationSvc.quickSearchUS('').then(function (data) {
                    model.data.recognitionData.usList = data.value;
                });

                organizationSvc.quickSearchNonUS('').then(function (data) {
                    model.data.recognitionData.nonUSList = data.value;
                    model.data.recognitionData.nonUSList.push({ organizationId: "OTHER", organizationName: "Other" });
                });

                if (model.rr.organizationJson.countryCode === 'US') {
                    model.isOutsideUS = false;
                    model.selectedRecognitionList = 'usList';
                }
                else {
                    model.isOutsideUS = true;
                    model.selectedRecognitionList = 'nonUSList';
                }

                model.addNewAgencyHidden = true;
                model.data.newAgency = null;
                model.isDataReady = true;
            });
        }();
    };

    module.controller('rrGeneralEditCtrl', rrGeneralEditCtrl);

}(angular.module('readinessReview')));