(function (module) {

    var adminEditConfirmCtrl = function ($scope, $state, $uibModalInstance, statement, statementStorageSvc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'View Statement Tool';


        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.openEditNew = function () {
            var dfStatement = null;

            if (statement.statementTypeId === 1)
                dfStatement = angular.fromJson(statement.draftStatementSent);
            else if (statement.statementTypeId === 4)
                dfStatement = angular.fromJson(statement.finalStatementSent);
            else
                $uibModalInstance.close();

            statementStorageSvc.getByStatementId(dfStatement[0].statementId).then(function () {
                statementStorageSvc.createNewStatementForResending(statementStorageSvc.data.statement).then(function () {
                    var url = $state.href('statementTool', { statementId: statementStorageSvc.data.statement.statementId });
                    window.open(url, '_blank');
                    $uibModalInstance.close();
                });
            });
        };

        model.openReadOnly = function () {
            var url = $state.href('statementTool', { statementId: statement.statementId });
            window.open(url, '_blank');
            $uibModalInstance.close();
        };
    };

    module.controller('adminEditConfirmCtrl', adminEditConfirmCtrl);

}(angular.module('statement')));