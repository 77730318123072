(function (module) {

    var pevAppExportPDFSvc = function (documentSvc, $compile, $q, $state, pevAppSvc, pevAppPDFSvc, componentHelperSvc, pdfSvc, FileSaver) {
        var factory = { };

        // entrypoint: pevAppExportPDFSvc.exportPDF(model.selectedList);
        factory.exportPDF = function (selectedIds, filename) {
            const volunteerApplicationIds = Array.isArray(selectedIds) ? selectedIds.slice() : [];            
            const applicationsZip = new JSZip();
            return exportApplications(volunteerApplicationIds, applicationsZip, filename);
        };

        function exportApplications(volunteerApplicationIds, applicationsZip, filename) {
            if (!volunteerApplicationIds.length)
                return saveApplicationsZip(applicationsZip, filename);

            const volunteerApplicationId = volunteerApplicationIds.pop(); 

            return pevAppSvc.getAllDataForApplication(volunteerApplicationId).then(function (data) {
                const promises = [downloadResume(data), generateApplicationPDF(data)]; // start resume downloading before beginning other, time consuming tasks

                return $q.all(promises).then(([resumePDF, applicationPDF]) => {
                    const filenameRoot = getFilenameRoot(data);
                    applicationsZip.file(`${filenameRoot}_application.pdf`, applicationPDF, { binary: true });
                    if (resumePDF)
                        applicationsZip.file(`${filenameRoot}_cv-resume.pdf`, resumePDF, { binary: true });
                    // Proceed with processing next application
                    return exportApplications(volunteerApplicationIds, applicationsZip, filename);
                });
             }); 
        }

        function saveApplicationsZip(applicationsZip, filename) {
            const zipFilename = filename ? `${filename}${filename.endsWith('.zip') ? '' : '.zip'}` : 'pev_applications.zip';
            return applicationsZip.generateAsync({ type: "blob" }).then(blob => {
                FileSaver.saveAs(blob, zipFilename);
            });
        }

        function generateApplicationPDF(data) {
            const documentDefinition = pevAppPDFSvc.generateDoc(data);
            return getGeneratePDFPromise(documentDefinition);
        }

        function downloadResume(data) {
            const stream_id = data.application && data.application.stream_id;
            if (!stream_id) return null;
            return documentSvc.downloadDocumentBlob(stream_id).then(data => data.blob);
        }

        function getFilenameRoot(pevAppData) {
            const volunteer = pevAppData.application.applicationJson.volunteer;
            return `${volunteer.lastName}_${volunteer.firstName}`.replace(' ', '_').toLowerCase();
        }

        // returns Promise of Blob
        function getGeneratePDFPromise(doc) {
            var deferred = $q.defer();

            pdfSvc.generatePDFBlob(doc, null, function (data) {
                deferred.resolve(data);
            });

            return deferred.promise;
        }

        return {
            exportPDF: factory.exportPDF,
        };
    };

    module.factory('pevAppExportPDFSvc', pevAppExportPDFSvc);

})(angular.module('pevApp'));
