(function (module) {

    var viewRecommendedActionCtrl = function ($scope, $uibModalInstance, statement, statementStorageSvc, reviewTeamSvc, evaluatorReportSvc, teamMemberTypeNames) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'View Recommended Action';
        model.statement = statement;
        model.getProgramName = statementStorageSvc.getProgramName;

        model.statementDetailComparator = statementStorageSvc.statementDetailComparator; 

        model.getPEVRA = statementStorageSvc.getPEVRA;
        model.getTeamMemberTypeName = reviewTeamSvc.getTeamMemberTypeName;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        var activate = function () {
            if (statement.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR) {
                model.isTeamChair = true;

                evaluatorReportSvc.getEvaluatorReportByReviewTeamId(model.statement.reviewTeamId).then(function () {
                    model.dataIsReady = true;
                });
            } else {
                model.isTeamChair = false;
            }
        }();
    };

    module.controller('viewRecommendedActionCtrl', viewRecommendedActionCtrl);

}(angular.module('statement')));