(function (module) {

    var deepObjectDifferenceSvc = function (differenceSvc) {
        var factory = {};

        factory.comparisonTypes = {
            IGNORE: 0, 
            SIMPLE: 1,
            DATETIME: 2,
            TEXT: 3,
            OBJECT: 4,
            OBJECTARRAY: 5
        }

        factory.differenceTypes = {
            SAME: 0,  
            DIFFERENT: 1,
            INSERTED: 2,
            DELETED: 3,
        }

        factory.deepDiff = function (originalObject, revisedObject, options) {
            if (!(originalObject || revisedObject) && !options) return null;

            var diffedObject;

            if (originalObject && !revisedObject) {
                // No revisesd object? Original must have been deleted.
                diffedObject = angular.copy(originalObject);
                diffedObject.$difference = factory.differenceTypes.DELETED
            } else if (!originalObject && revisedObject) {
                // No original object? Revised object must be an addition.
                diffedObject = angular.copy(revisedObject);
                diffedObject.$difference = factory.differenceTypes.INSERTED;
            } else {
                diffedObject = angular.copy(revisedObject);
                setObjectDifference(originalObject, diffedObject, options);
            }

            return diffedObject;
        }

        function setObjectDifference(originalObject, diffedObject, options) {
            if ((!originalObject && !diffedObject) || !options) return;

            var differencesFound = false;

            options.forEach(function (option) {
                option.placeholder = option.placeholder ? option.placeholder.toLowerCase() : option.placeholder;
                var property = option.property;
                var original = originalObject[property];
                var revised = diffedObject[property];
                switch (option.comparisonType) {
                    case factory.comparisonTypes.SIMPLE:
                        differencesFound = setDifference(diffedObject, property, original) || differencesFound;
                        break;
                    case factory.comparisonTypes.DATETIME:
                        differencesFound = setDateDifference(diffedObject, property, original) || differencesFound;
                        break;
                    case factory.comparisonTypes.TEXT:
                        // Data field shouldn't be bound to placeholder text but sometimes it is with custom directives.
                        if (option.placeholder) {
                            original = (original && original.toLowerCase() === option.placeholder) ? '' : original;
                            diffedObject[property] = (diffedObject[property] && diffedObject[property].toLowerCase() === option.placeholder) ? '' : diffedObject[property];
                        }
                        differencesFound = setTextDifference(diffedObject, property, original) || differencesFound;
                        break;
                    case factory.comparisonTypes.OBJECT:
                        if (!original && revised) {
                            revised.$difference = factory.differenceTypes.INSERTED;
                            differencesFound = true;
                        } else if (original && !revised) {
                            diffedObject[property] = angular.copy(original);
                            diffedObject[property].$difference = factory.differenceTypes.DELETED;
                            differencesFound = true;
                        } else {                           
                            differencesFound = setObjectDifference(original, revised, option.options) || differencesFound;
                        }
                        break;
                    case factory.comparisonTypes.OBJECTARRAY:
                        var originalArray = original || [];
                        var revisedArray = diffedObject[property] = revised || [];
                        var key = option.key;
                        var subKey = option.subKey;
                        if (!Array.isArray(originalArray) || !Array.isArray(revisedArray)) return;
                        revisedArray.forEach(function (revisedObj, index) {
                            var originalObj = null;
                            // Look up object in revised array by key value or else by index 
                            if (revisedObj.hasOwnProperty(key)) {
                                originalObj = originalArray.find(function (originalObj) {
                                    return revisedObj[key] === originalObj[key] && (!subKey || revisedObj[subKey] === originalObj[subKey]);
                                });
                            } else if (originalArray.length > index) {
                                // Using index instead of key assumes each version's objects are in the same order,
                                // with additions/deletions coming at the end of the array.
                                originalObj = originalArray[index];
                            }
                            if (!originalObj) {
                                revisedObj.$difference = factory.differenceTypes.INSERTED;
                                differencesFound = true;
                            } else {
                                differencesFound = setObjectDifference(originalObj, revisedObj, option.options) || differencesFound;
                            }
                        });
                        originalArray.forEach(function (originalObj, index) {
                            var revisedObj = null;
                            // Look up object in revised array by key value or else by index 
                            if (originalObj.hasOwnProperty(key)) {
                                revisedObj = revisedArray.find(function (revisedObj) {
                                    return originalObj[key] === revisedObj[key] && (!subKey || originalObj[subKey] === revisedObj[subKey]);
                                });
                            } else if (revisedArray.length > index) {
                                // Using index instead of key assumes each version's objects are in the same order,
                                // with additions/deletions coming at the end of the array.
                                revisedObj = revisedArray[index];
                            }
                            if (!revisedObj) {
                                revisedObj = angular.copy(originalObj);
                                revisedObj.$difference = factory.differenceTypes.DELETED;
                                revisedArray.push(revisedObj);
                                differencesFound = true;
                            }
                        });
                        break;
                }
            });

            diffedObject.$difference = differencesFound ? factory.differenceTypes.DIFFERENT : factory.differenceTypes.SAME;

            return differencesFound;
        }

        function setDifference(diffedObject, property, original) {
            if (diffedObject[property] === original) return false;

            diffedObject.$originalValues = diffedObject.$originalValues || {};
            diffedObject.$originalValues[property] = original;
            return true;
        }

        function setDateDifference(diffedObject, property, original) {
            var revised = diffedObject[property];
            if ((!revised && !original) ||
                (revised && original && revised.toString() === original.toString()))
                return false;

            diffedObject.$originalValues = diffedObject.$originalValues || {};
            diffedObject.$originalValues[property] = original;
            return true;
        }

        function setTextDifference(diffedObject, property, original) {
            if (diffedObject[property] === original || (diffedObject[property] == null && original == null)) return false;
            // Find differences, if any
            const differences = differenceSvc.getDifferences(original, diffedObject[property]);
            if (differences && differences.length === 1 && differences[0][0] === factory.differenceTypes.SAME) return false;
            // Store original unmarked-up text (no ins, del tags) for easier binding
            diffedObject.$originalValues = diffedObject.$originalValues || {};
            diffedObject.$originalValues[property] = original;
            diffedObject.$textDifferences = diffedObject.$textDifferences || {};
            diffedObject.$textDifferences[property] = differenceSvc.formatDifferences(differences);
            return true;
        }

        factory.convertDiffedObject = function (diffedObject) {
            if (!diffedObject || typeof diffedObject !== "object") return;
            if (diffedObject.$originalValues) {
                var properties = Object.getOwnPropertyNames(diffedObject.$originalValues);
                properties.forEach(function (property) {
                    diffedObject['$' + property + "_original"] = diffedObject.$originalValues[property];
                });
            }
            if (diffedObject.$textDifferences) {
                var properties = Object.getOwnPropertyNames(diffedObject.$textDifferences);
                properties.forEach(function (property) {
                    // Yes, this is redundant since you can parse diff string to get original but binding is easier with unmarked-up original
                    diffedObject['$' + property + "_original"] = diffedObject.$originalValues[property];
                    // Not sure what the best way to store diffed text is: overwrite property or alongside changed property
                    //diffedObject[property + "_difference"] = diffedObject.$textDifferences[property];
                    diffedObject[property] = diffedObject.$textDifferences[property];
                });
            }
            // Don't need these any longer--original values and differences are stored "inline" as object properties
            delete diffedObject.$originalValues;
            delete diffedObject.$textDifferences;
            var properties = Object.getOwnPropertyNames(diffedObject);
            properties.forEach(function (property) {
                var propertyValue = diffedObject[property];
                if (typeof diffedObject[property] !== "object" && !Array.isArray(diffedObject[property])) return;
                if (Array.isArray(propertyValue)) {
                    propertyValue.forEach(function (item) {
                        factory.convertDiffedObject(item);
                    });
                } else if (propertyValue && typeof propertyValue === "object") {
                    factory.convertDiffedObject(propertyValue);
                }
            });

            return diffedObject;
        }

        return {
            comparisonTypes: factory.comparisonTypes,
            differenceTypes: factory.differenceTypes,
            deepDiff: factory.deepDiff,
            convertDiffedObject: factory.convertDiffedObject
        };      
    };

    module.service('deepObjectDifferenceSvc', deepObjectDifferenceSvc);

})(angular.module('common'));