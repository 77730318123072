(function (module) {
    var npcProgramTabCtrl = function ($stateParams, $scope, $state, $uibModal, oauth, helperSvc, barSvc, statementStorageSvc, userReviewSvc, programReviewSvc, alertSvc, npcSvc,
        statementAdminSvc, documentSvc, reviewSvc, programSvc, eventSvc, programSummarySvc, npcDocumentTypes, npcTypes, npcDocumentTypes) {

        var model = this;
        model.parent = $scope.$parent.$parent.$parent.model;
        model.isDataReady = false;
        model.slug = 'programs'; 
        model.getLastProgramProgressStatus = npcSvc.getLastProgramProgressStatus;
        model.npcDocument = null;
        model.currentlyUploading = false;
        model.npcDocumentTypes = npcDocumentTypes;

        model.isAdmin = oauth.isAdmin();
        model.enableUploadButton = function (change) {
            return oauth.isAdmin() || model.getLastProgramProgressStatus(change) == "Pending Submission";
        }

        model.getColorClass = npcSvc.getColorClass;

        model.getBorderClass = function (npcChangeTypeId) {
            return 'border ' + model.getColorClass(npcChangeTypeId);
        };

        model.openPDFModal = function (streamId, title) {
            var isInline = true;
            alertSvc.openPDFModal(documentSvc.getFileUrl(streamId, isInline), title);
        };

        model.deleteFile = function (file) {
            if (model.disableDelete) return;

            var deleteMsg = "You are about to remove <em><strong>" + file.fileName + "</strong></em>  Do you want to proceed?";
            alertSvc.confirm(deleteMsg, function () {
                doDelete(file.stream_id);
            });

            function doDelete(stream_id) {
                documentSvc.deleteDocument(stream_id, npcDocumentTypes.NPC).then(
                    refreshData,
                    function () {
                        alertSvc.addModalAlertWarning("Document could not be removed at this time.");
                    });
            }
        };

        model.onUpload = function (npcProgramChange) {
            model.currentlyUploading = true;

            var saveDocument = function () {
                var npcDocument = {
                    referenceId: npcProgramChange.npcProgramChangeId,
                    documentTypeId: npcDocumentTypes.NPC,
                    documentTitle: npcProgramChange.npcType,
                    file: model.newFile
                };

                documentSvc.uploadDocument(npcDocument).then(
                    refreshData,
                    function (response) {
                        alertSvc.addModalAlertWarning("File could not be uploaded at this time.");
                    }
                );
            };


            saveDocument();
        }

        model.downloadDocument = function (document) {
            documentSvc.downloadDocument(document.stream_id, document.name)
        }

        model.addNotification = function () {
            $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcAddNotification.html',
                size: 'md',
                controller: 'npcAddNotificationModalCtrl',
                resolve: {
                    organization: () => model.parent.organizationData,
                    programs: () => model.parent.programs,
                    npc: () => model.npcData,
                    change: () => null,
                    onSave: () => refreshData
                }
            });
        };

        model.edit = function (change) {
            $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcAddNotification.html',
                size: 'md',
                controller: 'npcAddNotificationModalCtrl',
                resolve: {
                    organization: () => model.parent.organizationData,
                    programs: () => model.parent.programs,
                    npc: () => model.npcData,
                    change: () => change,
                    onSave: () => refreshData
                }
            });
        };

        model.submitNpc = function (change) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcSubmit.html',
                size: 'md',
                controller: 'npcSubmitModalCtrl',
                resolve: {
                    organizationName: () => model.parent.organizationData.currentOrganizationDetailDto.organizationName,
                    npc: () => model.npcData
                }
            });

            modalInstance.result.then(() => {
                refreshData();
            });
        };



        model.delete = function (change) {
            const programChange = npcSvc.findProgramChange(model.npcData, change);
            const program = npcSvc.findProgram(model.npcData, programChange);

            alertSvc.confirmDelete(`'${change.npcTypeName}' for ${program.programDetailDto.programName} (${program.programDetailDto.degreeCode})`, deleteFunc);

            function deleteFunc() {
                model.isSaving = true;

                let promise;

                if (programChange.changeJson.length > 1) {
                    const programChangeData = angular.copy(programChange);
                    programChangeData.changeJson.splice(programChange.changeJson.indexOf(change), 1);
                    promise = npcSvc.updateNpcProgramChange(programChangeData);
                } else {
                    promise = npcSvc.deleteNpcProgramChange(programChange);
                }

                promise.then(
                    () => {
                        if (model.npcData?.npcProgramDtos.length === 1 &&
                            model.npcData.npcProgramDtos[0]?.npcProgramChangeDtos.length === 1 &&
                            model.npcData.npcProgramDtos[0].npcProgramChangeDtos[0]?.changeJson.length === 1) {
                            model.isSaving = false;
                            // Current NPC deleted, return to previous state
                            eventSvc.broadcast('refreshNpcData');
                            alertSvc.addAlertSuccess('Notification of program change successfully deleted.');
                            // Could also use $state.go with { reload: true } if org Id was part of state params (i.e. in URL)
                            $state.go(model.isAdmin ? 'npc' : 'npcUser');
                            if (model.isAdmin)
                                $state.go('npc');
                            else
                                $state.go('npcUser.programchanges', { organizationId: $stateParams.organizationId });
                        } else {
                            refreshData().then(() => {
                                model.isSaving = false;
                                alertSvc.addAlertSuccess('Program change successfully deleted.');
                            })
                        }
                    },
                    error => {
                        model.isSaving = false;
                        console.error(error);
                        alertSvc.addAlertWarning("Program change could not be deleted at this time.");
                    }
                );
            }
        }

        model.getLastAction = function (programId) {            
            var programReview = model.programReviews.find(x => x.programId === programId);

            return programReview ? programReview.lastAction : 'N/A';
        };

        model.showLastReviewDetails = function (programId) {
            var programReview = model.programReviews.find(x => x.programId === programId);
            var reviewTeamId = programReview.reviewTeamId;
            $stateParams.organizationId = model.npcData.organizationId;

            if (!programReview.accreditationDates) {
                return alertSvc.information("This program is new and does not have a last review.", "Last Review")
            }


            userReviewSvc.getFinalReview(reviewTeamId).then(function (review) {
                review.locationData = programReviewSvc.data;
                review.jsonContents = angular.fromJson(review.jsonContents);
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/userReview/templates/currentReviewModal.html',
                    windowClass: 'page-color',
                    size: 'xl',
                    controller: 'currentReviewModalCtrl',
                    resolve: {
                        review: function () {
                            return review;
                        }
                    }
                });
            });
        }
        
        model.openStatementPreview = function (programId) {
            var programReview = model.programReviews.find(x => x.programId === programId);
            var reviewTeamId = programReview.reviewTeamId;

            if (reviewTeamId) {
                statementStorageSvc.getByReviewTeamId(reviewTeamId).then(function (x) {

                    statementStorageSvc.getAllStatementToolData(statementStorageSvc.data.statement.statementId).then(function () {

                        var statement = statementStorageSvc.data.statement;

                        var loadModalInstance = function () {
                            var modalInstance = $uibModal.open({
                                animation: true,
                                templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                                size: 'xl',
                                controller: 'viewStatementPreviewCtrl',
                                resolve: {
                                    currentStatement: function () { return statement; }
                                }
                            });
                        }

                        loadModalInstance();

                    })
                    
                })
            }
           
        }

        model.getOngoingReview = function (commissionId) {
            //need to get the ongoing review if there is any
            var result = "No ongoing review";
            
            if (model.ongoingReviews && model.ongoingReviews.length > 0) {
                for (var x = 0; x < model.ongoingReviews.length; x++) {
                    mostRecentReview = model.ongoingReviews[x];
                    var commissionedOngoingReview = mostRecentReview.reviewTeamDtos.find(x => x.commissionId === commissionId && mostRecentReview.reviewYear >= helperSvc.getAcademicYear());
                    if (commissionedOngoingReview) {
                        result = mostRecentReview.reviewYear + " " + commissionedOngoingReview.commissionName + " " + commissionedOngoingReview.reviewTypeCode;
                    }
                    break;
                } 
            }
           
            return result;
        }

        model.getNPCCampuses = programSummarySvc.getNPCCampuses;


        function refreshData() {
            eventSvc.broadcast('refreshNpcData');
            return npcSvc.getNPCById(model.npcData.npcId).then(data => {
                model.npcData = data;
                model.parent.npcData = data;
                doValidation();
            });
        }

        function doValidation() {
            if (model.isAdmin) {
                // No validations yet for program tab for admin...
                model.isValid = true;
                return;
            }

            const errors = [];
            let missingTerminationPlan;
            model.npcData.npcProgramDtos.forEach(program => {
                program.npcProgramChangeDtos.forEach(programChange => {
                    // Termination plan required for each termination change request
                    if (programChange.npcType === npcTypes.TERMINATION) {
                        missingTerminationPlan = !programChange.npcProgramChangeDocumentDtos.some(document =>
                            document.documentTypeId === npcDocumentTypes.TERMINATIONPLAN
                        );
                    }
                });
            });
 
            if (missingTerminationPlan)
                errors.push('A termination plan is required for all termination change requests.');

            model.isValid = !missingTerminationPlan;
            model.errors = errors;
        }

        var activate = function () {
            model.npcData = model.parent.npcData.npc ? model.parent.npcData.npc : model.parent.npcData;
            model.programReviews = model.parent.programReviews;
            var dataArray = [];

            var ongoingReviewDataSource = {
                dataHolder: model,
                dataLocationName: 'ongoingReviews',
                svcCallback: reviewSvc.getReviewsByOrganizationIdNoStore,
                svcCallbackArguments: [model.npcData.organizationId, isCurrent = true],
                odataResource: true,
            };
            dataArray.push(ongoingReviewDataSource);
           

            helperSvc.getData(dataArray).then(function () {
                doValidation();
                 model.isDataReady = true;
            });
        }();
    };
    module.controller('npcProgramTabCtrl', npcProgramTabCtrl);

}(angular.module('npc')));