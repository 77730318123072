(function (module) {

    var pevEditCtrl = function ($scope, $uibModalInstance, parent, review, volunteerSvc, personSvc, helperSvc, reviewTeamMemberSvc, eventSvc, alertSvc, programReviewSvc, reviewTeamProcessTrackingSvc) {
        var model = $scope;

        model.title = 'Assign PEVs';
        model.parent = parent;
        model.isInsertMode = false;
        model.isEdit = false;
        model.isAdd = false;
        model.reviewTeamId = review.reviewTeamId;
        model.programReviewId = review.programReviewId;
        resetTeamMember();
        model.errors = [];
        model.pevs = [];

        model.programName = review.programDto.programDetailDto.programName;
        model.disciplineTypeOptions = review.programReviewDisciplineDtos;
        model.pevTypeOptions = parent.pevTypeOptions;
        model.teamMemberStatuses = parent.teamMemberStatuses;

        if (review) {
            var programReviewDisciplines = review.programReviewDisciplineDtos;

            var pevsRequired = 0;

            if (programReviewDisciplines) {
                for (var i = 0; i < programReviewDisciplines.length; i++) {
                    pevsRequired += programReviewDisciplines[i].pevsRequired;
                    if (review.programReviewDisciplineDtos[i].reviewTeamMemberDtos) {
                        angular.forEach(review.programReviewDisciplineDtos[i].reviewTeamMemberDtos, function (value) {
                            model.pevs.push(value);
                        });
                    }
                }
            }

            model.pevsRequired = pevsRequired;
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.deletePev = function (teamMember) {
            alertSvc.confirmDelete(teamMember.personName, deleteFunc, teamMember);

            function deleteFunc() {
                reviewTeamMemberSvc.delete(teamMember).then(function () {
                    updateProgramReviewData();
                    alertSvc.addModalAlertSuccess("PEV successfully deleted.");
                });
            }
        };

        model.addPev = function () {
            model.isInsertMode = true;
            model.isAdd = true;
            model.isEdit = false;
            resetErrors();
        };

        model.cancel = function () {
            resetTeamMember();
            model.isInsertMode = false;
            resetErrors();
        }

        model.save = function (teamMember) {
            resetErrors();

            var teamMemberDto = {
                reviewTeamId: model.reviewTeamId,
                reviewTeamMemberId: teamMember.reviewTeamMemberId,
                volunteerId: teamMember.volunteerId,
                teamMemberTypeId: teamMember.teamMemberTypeId,
                programReviewDisciplineId: teamMember.programReviewDisciplineId,
                startDate: teamMember.startDate,
                endDate: teamMember.endDate,
                teamMemberStatusId: teamMember.teamMemberStatusId,
                hasReviewedOss: teamMember.hasReviewedOss,
                rejectComment: teamMember.rejectComment,              
                note: teamMember.note
            }

            //must see if person is a volunteer & will not have a volunteerId if creating a new pev
            if (!teamMember.volunteerId || model.isAdd) {
                //attempt to get and if fails throw errror
                volunteerSvc.getVolunteerIdByPersonId(teamMember.personId).then(function (data) {
                    var volunteerobj = helperSvc.getFirstArrayItem(data);
                    if (volunteerobj === undefined) {
                        model.errors.push('This person is not a volunteer.');
                        return;
                    }
                    teamMemberDto.volunteerId = volunteerobj.volunteerId;

                    reviewTeamMemberSvc.create(teamMemberDto).then(function () {
                        updateProgramReviewData();
                        resetTeamMember();
                        model.isInsertMode = false;
                        alertSvc.addModalAlertSuccess("PEV successfully created.");
                    });
                });
            }

            if (model.isEdit) {
                reviewTeamMemberSvc.update(teamMemberDto).then(function () {
                    updateProgramReviewData();
                    resetTeamMember();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("PEV successfully updated.");                  
                });
            }
        };

        model.selectTeamMember = function (teamMember) {
            resetErrors();
            var currentTeamMember = angular.copy(teamMember);
            var useUndefined = true;

            currentTeamMember.startDate = helperSvc.formatDate(currentTeamMember.startDate, useUndefined);
            currentTeamMember.endDate = helperSvc.formatDate(currentTeamMember.endDate, useUndefined);
            model.teamMemberName = teamMember.personName;            

            model.currentTeamMember = currentTeamMember;
            volunteerSvc.getPersonIdByVolunteerId(model.currentTeamMember.volunteerId).then(function (data) {
                var personIdObj = helperSvc.getFirstArrayItem(data);
                model.currentTeamMember['personId'] = personIdObj.personId;
                model.isInsertMode = true;
                model.isAdd = false;
                model.isEdit = true;
            },
            function(reason){
                console.log(reason);
            });

        };

        model.formatDate = helperSvc.formatDate;

        function resetErrors() {
            model.errors = [];
        }

        function resetTeamMember() {
            model.currentTeamMember = { reviewTeamId: model.reviewTeamId };
            model.teamMemberName = undefined;
        };

        function updateProgramReviewData() {

            var programsDataSource = {
                dataHolder: programReviewSvc,
                dataLocationName: 'data.programs',
                svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [model.reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true
            }

            var processTrackingDataSource = {
                storeData: false,    
                svcCallback: reviewTeamProcessTrackingSvc.getReviewStatusesByReviewTeamIdOdata,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true

            }

            helperSvc.getData([programsDataSource, processTrackingDataSource]).then(function () {
               model.pevs = getValidPevs(model.programReviewId);               
            });
        }

        function getValidPevs(programReviewId) {
            var programs = programReviewSvc.data.programs;
            var pevs = [];

            for (var i = 0; i < programs.length; i++) {
                var program = programs[i];

                if (program.programReviewId === programReviewId) {
                    var programReviewDisciplines = program.programReviewDisciplineDtos;

                    if (programReviewDisciplines) {
                        for (var j = 0; j < programReviewDisciplines.length; j++) {                         
                            if (programReviewDisciplines[j].reviewTeamMemberDtos) {
                                angular.forEach(programReviewDisciplines[j].reviewTeamMemberDtos, function (value) {
                                   pevs.push(value);
                                });
                            }
                        }
                    }
                }
            }

            return pevs;
        }
    };

    module.controller('pevEditCtrl', pevEditCtrl);

}(angular.module('review')));