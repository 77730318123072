(function (module) {

    var visitObserversCtrl = function ($scope, $uibModal, helperSvc, reviewTeamVisitObserverSvc, reviewTeamSvc, codeSvc) {
        var model = this;
        model.parent = $scope.$parent.model;
        model.title = 'Visit Observers';
        model.formatDate = helperSvc.formatDate;
        model.isEmpty = helperSvc.isEmpty;
        model.data = reviewTeamVisitObserverSvc.data;
        model.reviewTeam = reviewTeamSvc.data;
        
        model.editVisitObservers = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/teamVisitObserversEdit.html',
                size: 'lg',
                controller: 'visitObserversEditCtrl',
                resolve: {
                    review: function () {
                        return model.reviewTeam.reviewTeam;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };



    };

    module.controller('visitObserversCtrl', visitObserversCtrl);

}(angular.module('review')));