(function (module) {

    var menuSvc = function (odataSvc, helperSvc) {
        var factory = {};

        factory.getMenuByRoles = function (roles) {

            var oSvc = odataSvc.get();
            
            var getApiPath = "/GetMenuByRoles(roles='" + roles + "')";

            oSvc.getSource(getApiPath).odata()
                .expand('menuRoleDtos')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.buildMenu = function (menuObj, paramValues) {
            let resultArr = menuObj.filter(t => t.parentId == null)
                .map(({ menuId, menuTitle, stateName, icon, url, description, stateParams, submenu }) => ({ menuId, title: menuTitle, stateName, icon, url, description, stateParams: addParams(stateParams, paramValues) , submenu }));

            resultArr.forEach(function (item) {
                item.submenu = menuObj.filter(t => item.menuId == t.parentId)
                    .map(({ menuId, menuTitle, stateName, icon, url, description, stateParams, submenu }) => ({ menuId, title: menuTitle, stateName, icon, url, description, stateParams: addParams(stateParams, paramValues), submenu }));
            });

            return resultArr;
        };

        function addParams(paramStr, paramValues) {
            if (!paramStr)
                return null;

            let params = {};

            pArr = paramStr.split(',');
            pArr.forEach(function (p) {
                if (p in paramValues)
                    params[p] = paramValues[p];
            });
            return params;
        };

        return {
            buildMenu: factory.buildMenu,
            getMenuByRoles: factory.getMenuByRoles
        };
    };
    module.factory('menuSvc', menuSvc);

})(angular.module('root'));