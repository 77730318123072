(function (module) {
    'use strict';

    var statementPDFSvc = function (statementStorageSvc, statementTypeIds, statementStatuses, helperSvc, statementFindingTypes, statementSvc, programReviewFinalActionSvc, actionCodes, programReviewTypeIds,
        statementFindingTypeNames, dueResponsePropNamesByTypeId, commissionTypes, findingStatusTypeIds, monthNames, statementBoilerPlate, statementTypeNames) {

        var factory = { };

        var emptySectionText = 'This section is blank.';
        var NO_LINE_BREAK_TEXT = 'NO_LINE_BREAK';

        var tableLayout = {
            paddingLeft: function () { return 0; }
        }
        var styles = {
            paragraph: {
                lineHeight: 1.3,
                alignment: 'justify'
            },
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            medium: {
                fontSize: 12
            },
            large: {
                fontSize: 16
            },
            larger: {
                fontSize: 18
            },
            jumbo: {
                fontSize: 27
            },
            italics: {
                italics: true
            },
            bold: {
                bold: true
            },
            // Fonts and images are defined in vfs_fonts.js
            campton: {
                font: 'campton'
            },
            camptonBook: {
                font: 'camptonBook'
            },
            camptonExtraBold: {
                font: 'camptonExtraBold'
            },
            camptonExtraLight: {
                font: 'camptonExtraLight'
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            camptonSemiBold: {
                font: 'camptonSemiBold'
            },
            camptonThin: {
                font: 'camptonThin'
            },
            body: {
                font: 'egyptienne'
            },
            orange: {
                color: '#FF6C2C'
            },
            addLineSpacing: {
                lineHeight: 1.8
            }
        };


        function getStatementTypeName(statementTypeName) {
            if (statementTypeName == statementTypeNames.POST30DAY || statementTypeName.toLowerCase() == "post 30 day" || statementTypeName == statementTypeNames.COMMISSIONEDITING) {
                return statementTypeNames.FINAL;
            } else {
                return statementTypeName;
            }
        }

        function getVisitDates(year, isLongDate) {
            var start = parseInt(year) - 1;
            return isLongDate ? start + '-' + year : start + '-' + (year % 100);
        }

        function getAccreditationCycle(year) {
            var start = parseInt(year) - 1;
            return start + '-' + (year % 100);
        }

        function getCriteriaCycle(isLongDate) {
            var arr = [];
            if (!statementStorageSvc.data.statementProgramReviews) return '';
            var criteriaCycleYears = statementStorageSvc.data.statementProgramReviews
                .filter(programReview => !'WC'.includes(programReview.actionCode))
                .map(programReview => programReview.criteriaCycleYear);

            for (var i = 0; i < criteriaCycleYears.length; i++) {
                if (criteriaCycleYears[i] && Number.isInteger(criteriaCycleYears[i])) {
                    var cycle = getVisitDates(criteriaCycleYears[i], isLongDate);
                    if (arr.indexOf(cycle) === -1) {
                        arr.push(getVisitDates(criteriaCycleYears[i], isLongDate));
                    }
                }
            }
            return arr.join(', ');
        }

        function getDateRange(date1, date2) {
            var startDate = new Date(date1),
                endDate = new Date(date2),
                startMonth = startDate.getUTCMonth(),
                endMonth = endDate.getUTCMonth(),
                startYear = startDate.getUTCFullYear(),
                endYear = endDate.getUTCFullYear(),
                startDay = startDate.getUTCDate(),
                endDay = endDate.getUTCDate();

            if (startYear === endYear) {
                if (startMonth === endMonth) {
                    return '{0} {1}-{2}, {3}'.format(monthNames[startMonth], startDay, endDay, startYear);
                } else {
                    return '{0} {1} - {2} {3}, {4}'.format(monthNames[startMonth], startDay, monthNames[endMonth], endDay, startYear);
                }
            } else {
                return '{0} {1}, {2} - {3} {4}, {5}'.format(monthNames[startMonth], startDay, startYear, monthNames[endMonth], endDay, endYear);
            }
        }

        function findAppeal(currentStatement) {
            return currentStatement.statementDetailDtos.find(sd => !sd.programId)?.statementJson.find(f => f.appeal)?.appeal;
        }

        function getSectionHeaderContent(section, currentStatement) {
            if (!section.programId) {
                // Add appeal information to final statements
                let appealText = [];

                try {
                    const appeal = findAppeal(currentStatement);

                    if (appeal?.visitStartDate && appeal?.visitEndDate) {
                        appealText.push({
                            text: '\nVisit Dates: {0} \n'.format(getDateRange(appeal.visitStartDate, appeal.visitEndDate)).toUpperCase(),
                            style: ['camptonLight', 'small'],
                            alignment: 'left'
                        });
                    }

                    if (appeal?.cycleYear) {
                        appealText.push({
                            text: 'Accreditation Cycle Criteria: {0}'.format(getVisitDates(appeal.cycleYear, true)).toUpperCase(),
                            style: ['camptonLight', 'small'],
                            alignment: 'left'
                        });
                    }
                } catch (e) {
                    console.log('Error attempting to add appeal information to section header', e);
                }

                return {
                    pageBreak: 'before',
                    table: {
                        body: [
                            [
                                {
                                    text: [
                                        {
                                            pageBreak: 'before',
                                            text: statementStorageSvc.data.currentReviewTeam.organizationName.toUpperCase() + '\n',
                                            style: ['campton', 'larger'],
                                            alignment: 'left'
                                        },
                                        {
                                            text: '{0} \n'.format(getLocationInfo(statementStorageSvc.data.currentReviewTeam)),
                                            style: ['camptonMedium', 'medium'],
                                            alignment: 'left'
                                        }
                                    ],
                                    border: [false, false, false, true],
                                    margin: [0, 0, 0, 6]
                                }
                            ],
                            [
                                {
                                    text: 'ABET ' + getCommissionFullName(statementStorageSvc.data.currentReviewTeam.commissionId).toUpperCase() + '\n',
                                    style: ['camptonLight', 'small'],
                                    alignment: 'left',
                                    border: [false, false, false, false],
                                    margin: [0, 6, 0, 0]
                                }
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: getStatementTypeName(currentStatement.statementTypeName).toUpperCase() + ' Statement\n'.toUpperCase(),
                                            style: ['camptonSemiBold', 'medium'],
                                            alignment: 'left'
                                        },
                                        {
                                            text: statementStorageSvc.data.currentReviewTeam.reviewTypeCode == "IR" ?
                                                'Report Submitted: 1 July {0} \n'.format(statementStorageSvc.data.currentReviewTeam.reviewYear - 1) :
                                                'Visit Dates: {0} \n'.format(getDateRange(statementStorageSvc.data.currentReviewTeam.visitStartDate, statementStorageSvc.data.currentReviewTeam.visitEndDate)).toUpperCase(),
                                            style: ['camptonLight', 'small'],
                                            alignment: 'left'
                                        },
                                        {
                                            text: 'Accreditation Cycle Criteria: {0}'.format(getCriteriaCycle(true)).toUpperCase(),
                                            style: ['camptonLight', 'small'],
                                            alignment: 'left'
                                        },
                                        ...appealText
                                    ],
                                    border: [false, false, false, false]
                                }
                            ]
                        ]
                    },
                    layout: tableLayout
                }
            } else {
                return {
                    pageBreak: 'before',
                    table: {
                        body: [
                            [
                                {
                                    text: [
                                        {
                                            text: statementStorageSvc.getProgramName(section.programId) + '\n',
                                            style: ['campton', 'larger'],
                                            alignment: 'left'
                                        },
                                        {
                                            text: statementStorageSvc.getProgramDegreeName(section.programId) + ' Program\n',
                                            style: ['camptonMedium', 'medium'],
                                            alignment: 'left'
                                        }
                                    ],
                                    border: [false, false, false, true],
                                    margin: [0, 0, 0, 6]
                                }
                            ],
                            [
                                {
                                    text: statementStorageSvc.getCriteriaAreaNames(section.programId) !== "No Applicable Program Criteria" ? 'Evaluated under {0} Program Criteria for {1}'.format(statementStorageSvc.data.currentReviewTeam.commissionName, statementStorageSvc.getCriteriaAreaNames(section.programId)) : "There were no applicable {0} program criteria.".format(statementStorageSvc.data.currentReviewTeam.commissionName),
                                    style: ['camptonLight', 'small'],
                                    alignment: 'left',
                                    border: [false, false, false, false],
                                    margin: [0, 6, 0, 0]
                                }
                            ]
                        ]
                    },
                    layout: tableLayout
                }
            }
        }

        function splitByTag(html) {
            //takes in html and splits by tag, returning array of html string
            return html ? html.match(/<(.*?)>.*?<\/\1>/g) : html;
        }

        function htmlToElementArray(html) {
            //takes in html string splits by tag, and returns array of element objects
            var elms = [];
            var split = splitByTag(html);
            if (!split) return elms;
            for (var i = 0; i < split.length; i++) {
                elms.push(htmlToElement(split[i]));
            }
            return elms;
        }

        function htmlToElement(html) {
            var template = document.createElement('template');
            html = html.trim();
            template.innerHTML = html;
            // our boy Internet Explorer doesn't do template.content
            if (template.content) return template.content.firstChild;
            else return template.firstChild;
        }

        function addMissingPeriod(name) {
            if (!name) return name;
            if (name.indexOf('.') >= 0) {
                return name;
            } else {
                return name.replace(/(\d)/g, "$1" + ".");
            }
        }

        function formatFindingTypeName(findingType) {
            function getPluralNoun(noun) {
                switch (noun.charAt(noun.length - 1)) {
                    case 's':
                        return noun + 'es';
                    case 'y':
                        return noun.substring(0, noun.length - 1) + 'ies';
                    default:
                        return noun + 's';
                }
            }

            var name = statementFindingTypeNames[findingType.statementFindingTypeId];

            if (findingType.findings.length > 1) name = getPluralNoun(name);

            return name ? name.toUpperCase() : '';
        }

        function getCommissionFullName(id) {
            return !statementStorageSvc.data.currentReviewTeam.commissionFullName ? commissionTypes.find(function (c) { return c.id === id }).name : statementStorageSvc.data.currentReviewTeam.commissionFullName;
        }

        function getLocationInfo(reviewTeam) {
            var text = '';
            if (reviewTeam.city && reviewTeam.city !== '') text += reviewTeam.city + ", ";

            if (reviewTeam.countryCode === 'US') {
                if (reviewTeam.stateCode && reviewTeam.stateCode !== '') text += reviewTeam.stateCode + ", ";
            } else {
                if (reviewTeam.province && reviewTeam.province !== '') text += reviewTeam.province + ", ";
            }
            text += reviewTeam.countryName.trim();
            return text;
        }

        function substituteRegularHyphen(str) {
            // Replace non-traditional hyphen character with regular hyphen
            // Non-traditional hyphen doesn't show up
            return str.replace(/‐/g, '\u2010');
        }

        function substituteNonBreakingHyphen(str) {
            // Replace regular hyphen with non-breaking hyphen to remove space after hyphen caused by justified text
            return str.replace(/-/g, '\u2011');
        }

        function substituteBullets(str) {
            // Replace pseudo-bullets done with asterisks with proper bullets and non-breaking spaces.
            return str.replace(/((^|\n|\\n)\*\s{1,2})(?=\w)/g, '$2\u2022\u00A0');
        }

        function makeSubstitutions(str) {
            // Substitute certain strings and characters with more appropriate, PDF-friendly characters.
            var fixed = str;
            //fixed = substituteNonBreakingHyphen(fixed);
            fixed = substituteRegularHyphen(fixed);
            fixed = substituteBullets(fixed);
            return fixed;
        }

        function getElementText(elm) {
            var text = elm.textContent || elm.innerText || '';
            text = text.replace(NO_LINE_BREAK_TEXT, '');
            text = text.toLowerCase().trim() === 'click to add text...' ? '' : text;
            return makeSubstitutions(text);
        }

        function formatEmptyStr(str) {
            return str ? (str.trim() === '' ? emptySectionText : str) : emptySectionText;
        }

        function formatEmptyElm(elm) {
            return formatEmptyStr(getElementText(elm));
        }

        function formatFindingText(childNodes, parentElm, options, isFirstElement, isLastElement, isInsideList, isLastInsideList, isOL, hasCriteriaName) {
            var texts = isInsideList || isFirstElement ? [] : ['\n'];
            var defaultStyle = options && options.italics === true ? ['small', 'body', 'italics', 'paragraph'] : ['small', 'body', 'paragraph'];
            var textAfterProgressLabelIndex = null;
            var hasComments = false;

            // Set display options
            if (options === undefined || options === null) {
                options = {
                    italics: false,
                    style: defaultStyle
                }
            } else {
                if (!options.style || options.style === undefined || options.style === null || options.style.length < 1) {
                    options.style = defaultStyle;
                }
            }

            // Check if childNodes contain text that has a comment and is wrapped in <mark> tags
            childNodes.forEach(function (node) {
                if (node.nodeName.toLowerCase() == "mark") hasComments = true;
            });

            if (hasComments) {

                // If text contains comments do NOT output each childNode as <mark> tags added around comments cause them to be treated as separate nodes
                // This causes linebreaks to be added around commented text even if its in the middle of a paragraph
                // Instead output text from parent element; this ignores mark tags and outputs an entire paragraph together

                formatParagraph(parentElm);

            } else {

                // If text contains no comments, output each childNode

                childNodes.forEach(function (node) {
                    // Only push elements that are not empty
                    if (formatEmptyElm(node) !== emptySectionText) {
                        if (node.nodeName === 'STRONG') {
                            texts.push({
                                text: getElementText(node), style: ['bold']
                            });
                        } else if (node.nodeName === 'LABEL') {
                            // Add hyphen to "due-process response"
                            var text = getElementText(node);
                            text = text.replace("Due Process Response", "Due-Process Response");
                            text = text.replace(" Day", "-Day");
                            texts.push({
                                text: text + ' ', style: ['small', 'camptonMedium']
                            });
                        } else {
                            // paragraphs
                            formatParagraph(node);
                        }
                    }

                    // Add space after text that preceeds boiler plate finding type definition
                    if (texts.length > 1) {
                        var substr = statementBoilerPlate.BODY.substr(statementBoilerPlate.BODY.length - 100, 96);
                        if (texts[1].text.contains(substr)) texts.push('\n\n');
                    }
                });
            }

            function formatParagraph(elm) {
                var textContent = elm.textContent || elm.innerHTML;

                if (textContent.indexOf(NO_LINE_BREAK_TEXT) >= 0) {
                    // Remove default line break from the beginning of text
                    if (texts.length > 0 && texts[0] == '\n') texts.splice(0, 1);
                }
                var lineBreakStart = isOL && (hasCriteriaName || !isFirstElement) ? '\n' : '';
                var lineBreakEnd = isInsideList && !isLastInsideList ? '\n\n' : '';

                // Put space above review team list for CAC
                if (textContent.contains("evaluated by the peer review team shown below")) lineBreakEnd += '\n\n';
                texts.push({
                    text: lineBreakStart + getElementText(elm) + lineBreakEnd, style: options.style
                });
            }

            // Remove linebreak if no text was added
            if (texts.length == 1 && texts[0] == '\n') texts = [];

            return {
                text: texts,
                style: options.style
            };
        }

        function buildfindingDefinition(elements, isItalic, isLastFinding, isOL, hasCriteriaName) {
            var arr = [];
            var options = {
                style: ['small', 'body', 'paragraph']
            }

            if (isItalic) options.style.push('italics');

            angular.forEach(elements, function (elm, index) {
                var isLastElement = isLastFinding && index == elements.length - 1;
                var isFirstElement = index == 0;
                if (elm.nodeName === 'UL' || elm.nodeName === 'OL') {
                    var list = [];
                    var isInsideList = true;
                    for (var i = 0; i < elm.children.length; i++) {
                        if (elm.children[i].nodeName === 'LI') {
                            var isLastInsideList = i == elm.children.length - 1;
                            list.push(formatFindingText(elm.children[i].childNodes, null, options, isFirstElement, isLastElement, isInsideList, isLastInsideList, isOL, hasCriteriaName));
                        }
                    }
                    if (elm.nodeName === 'UL') {
                        arr.push({ ul: list });
                    } else {
                        arr.push({ ol: list });
                    }
                } else if (elm.nodeName === 'LABEL') {
                    var op = angular.copy(options);
                    op.style = ['small', 'camptonMedium', 'addLineSpacing'];
                    arr.push(formatFindingText(elm.childNodes, null, op, isFirstElement, isLastElement, false, false, isOL, hasCriteriaName));
                } else if (elm.nodeName === 'P') {
                    arr.push(formatFindingText(elm.childNodes, elements[index], options, isFirstElement, isLastElement, false, false, isOL, hasCriteriaName));
                }
            });
            return arr;
        }

        function getFindingText(criteria, index, isList, findingType, isLastFinding) {
            var text = '';
            var findingDefinition = [];
            var elements = [];
            var lineBreak = isList ? '\n\n' : '\n';
            var lineBreakShort = isList ? '\n' : '';
            var hasCriteriaName = criteria.criteriaName ? true : false;

            var getHeadingSmallMedium = function (title, useLBShort, hideLB) {
                return { text: (hideLB ? '' : (useLBShort ? lineBreakShort : lineBreak)) + title, style: ['small', 'camptonMedium', 'addLineSpacing'] };
            };
            var getHeadingMediumLight = function (title) {
                return { text: lineBreak + title, style: ['orange', 'medium', 'camptonLight', 'addLineSpacing'] };
            };
            var getBodyText = function (text, hideLB) {
                text = makeSubstitutions(text);
                return { text: (hideLB ? '' : '\n') + text, style: ['small', 'body', 'paragraph'] };
            };
            var generateTextStr = function (elements) {
                var text = '';
                angular.forEach(elements, function (elm, i) {
                    var linebreak = i == 0 || getElementText(elm) === '' ? '' : '\n\n';
                    text += linebreak + getElementText(elm);
                });
                return formatEmptyStr(text);
            };

            if (criteria.text || findingType.statementFindingTypeId === statementFindingTypes.REVIEWTEAM) {

                if (findingType.statementFindingTypeId === statementFindingTypes.REVIEWTEAM) {
                    elements = htmlToElementArray(statementStorageSvc.getReviewTeamSectionText(statementStorageSvc.data.statementProgramReviews));
                } else if (!criteria.lastVisitText) {
                    elements = htmlToElementArray(criteria.text);
                }

                if (criteria.text2) {
                    elements = elements.concat(htmlToElementArray(criteria.text2));
                }

                if (criteria.lastVisitText) {
                    var lastVisitElements = [];
                    var addRemoveLineBreakCue = function (str) {
                        var i = str.indexOf(">") + 1; // Insert after opening tag, i.e. <p>[cue here]Example string</p>
                        return str.slice(0, i) + NO_LINE_BREAK_TEXT + str.slice(i, str.length);
                    };

                    lastVisitElements = lastVisitElements.concat(htmlToElementArray(criteria.lastVisitText));
                    lastVisitElements = lastVisitElements.concat(htmlToElementArray("<label>Progress Since Last Review</label>"));

                    var hasResponseSection = criteria.response ? true : false;

                    findingDefinition = buildfindingDefinition(lastVisitElements, true, false, isList, hasCriteriaName)
                                        .concat(buildfindingDefinition(htmlToElementArray(addRemoveLineBreakCue(criteria.text)), false, false, isList, hasCriteriaName))
                                        .concat(buildfindingDefinition(elements, false, !hasResponseSection, isList, hasCriteriaName));

                } else {
                    findingDefinition = buildfindingDefinition(elements, false, isLastFinding, isList, hasCriteriaName);
                }

                if (criteria.response) {
                    if (criteria.response.sevenDay && criteria.response.sevenDay.text) {
                        var elements = htmlToElementArray(criteria.response.sevenDay.text);
                        var text = generateTextStr(elements);

                        findingDefinition.push({
                            text: [
                                getHeadingMediumLight('Seven-Day Response'),
                                getBodyText(text)
                            ]
                        })
                    }
                    if (criteria.response.interimStatus) {
                        findingDefinition.push({
                            text: [
                                getHeadingSmallMedium('Status'),
                                getBodyText(getFindingStatusText(findingType, criteria.response.interimStatus, null))//this inputs null as
                            ]
                        });

                        if (criteria.response.interimStatus.nextReviewText) {
                            var nextReviewTextElems = htmlToElementArray(criteria.response.interimStatus.nextReviewText);
                            var nextReviewText = generateTextStr(nextReviewTextElems);
                            findingDefinition.push({
                                text: [
                                    getHeadingSmallMedium('Notes for Next Review'),
                                    getBodyText(nextReviewText)
                                ]
                            });
                        }

                    }
                    if (criteria.response.thirtyDay && criteria.response.thirtyDay.text) {
                        var elements = htmlToElementArray(criteria.response.thirtyDay.text);
                        var text = generateTextStr(elements);

                        findingDefinition.push({
                            text: [
                                getHeadingMediumLight('30-Day Due-Process Response'),
                                getBodyText(text)
                            ]
                        });
                        findingDefinition.push({
                            text: [
                                getHeadingSmallMedium('Status'),
                                getBodyText(getFindingStatusText(findingType, criteria.response.thirtyDay, criteria.response))
                            ]
                        });

                        if (criteria.response.thirtyDay.nextReviewText) {
                            var nextReviewTextElems = htmlToElementArray(criteria.response.thirtyDay.nextReviewText);
                            var nextReviewText = generateTextStr(nextReviewTextElems);
                            findingDefinition.push({
                                text: [
                                    getHeadingSmallMedium('Notes for Next Review'),
                                    getBodyText(nextReviewText)
                                ]
                            });
                        }
                    }
                    if (criteria.response.postThirtyDay && criteria.response.postThirtyDay.text) {
                        var elements = htmlToElementArray(criteria.response.postThirtyDay.text);
                        var text = generateTextStr(elements);

                        findingDefinition.push({
                            text: [
                                getHeadingMediumLight('Post-30-Day Due-Process Response'),
                                getBodyText(text)
                            ]
                        });
                        findingDefinition.push({
                            text: [
                                getHeadingSmallMedium('Status'),
                                getBodyText(getFindingStatusText(findingType, criteria.response.postThirtyDay, criteria.response))
                            ]
                        });
                        if (criteria.response.postThirtyDay.nextReviewText) {
                            var nextReviewTextElems = htmlToElementArray(criteria.response.postThirtyDay.nextReviewText);
                            var nextReviewText = generateTextStr(nextReviewTextElems);
                            findingDefinition.push({
                                text: [
                                    getHeadingSmallMedium('Notes for Next Review'),
                                    getBodyText(nextReviewText)
                                ]
                            });
                        }
                    }
                    if (criteria.response.appeal && criteria.response.appeal.text) {
                        var elements = htmlToElementArray(criteria.response.appeal.text);
                        var text = generateTextStr(elements);

                        findingDefinition.push({
                            text: [
                                getHeadingMediumLight('Subsequent Review'),
                                getBodyText(text)
                            ]
                        });
                        findingDefinition.push({
                            text: [
                                getHeadingSmallMedium('Status'),
                                getBodyText(getFindingStatusText(findingType, criteria.response.appeal, criteria.response))
                            ]
                        });
                        if (criteria.response.appeal.nextReviewText) {
                            var nextReviewTextElems = htmlToElementArray(criteria.response.appeal.nextReviewText);
                            var nextReviewText = generateTextStr(nextReviewTextElems);
                            findingDefinition.push({
                                text: [
                                    getHeadingSmallMedium('Notes for Next Review'),
                                    getBodyText(nextReviewText)
                                ]
                            });
                        }
                    }

                }

                //add criteria at beginning of finding
                if (criteria.criteriaName && findingDefinition[0] && findingDefinition[0].text) { //this shoulndt be italicized in IRS...
                    findingDefinition.unshift({
                        text: [
                            getHeadingSmallMedium(addMissingPeriod(criteria.criteriaName), null, true)
                        ]
                    });
                }

                if (isList) {
                    return {
                        ol: [{
                            text: findingDefinition,
                            counter: index + 1,
                            margin: [0, 15, 0, 0]
                        }]
                    };
                } else {
                    return findingDefinition;
                }
            } else {
                return [{
                    text: [
                        getBodyText(emptySectionText, true)
                    ]
                }];
            }
        }

        function getFindingStatusText(findingType, status, allResponses) { //need to add checks for all statement types and the most recent change finding type status
            var currentFindingTypeName = findingType.statementFindingTypeName.toLowerCase()

            if(allResponses){
                var lastChangedFindingType = null;

                lastChangedFindingType = getLatestChangedShortcomingStatus(status, allResponses);
                if(lastChangedFindingType){
                    currentFindingTypeName = lastChangedFindingType;
                }
            }

            if (status.findingStatusTypeId === findingStatusTypeIds.RESOLVED) {
                return 'The {0} has been resolved.'.format(currentFindingTypeName);
            } else if (status.findingStatusTypeId === findingStatusTypeIds.REMAINS) {
                var str = 'The {0} is unresolved.'.format(currentFindingTypeName);
                if (status && status.statusDesc) {
                    return addStatusDesc(str, status)
                } else {
                    return str;
                }
            } else if (status.findingStatusTypeId === findingStatusTypeIds.CHANGED) {
                if (!status.updatedFindingTypeName) {
                    var noUpdateString = 'The {0} has changed type.'.format(currentFindingTypeName);
                    return addStatusDesc(noUpdateString, status);
                } else {
                    var updateString = 'The {0} is now cited as a {1}.'.format(currentFindingTypeName, status.updatedFindingTypeName.toLowerCase());
                    return addStatusDesc(updateString, status);
                }
            } else {
                return "This section is blank.";
            }
        }

        function getLatestChangedShortcomingStatus(currentResponse, prevResponse) {//get the first previous response that has a changed finding tpye
            //need to sort the responses first...
            var mappedResponses = statementSvc.organizeResponseTypes(prevResponse);

            var lastUpdatedFindingType = null;
            var values = Object.values(mappedResponses);
            for(var i = 0; i < values.length; i++){
                var response = values[i];
                if(response !== null){
                    if(response === currentResponse ){
                        break;
                    }
                    else if(response.findingStatusTypeId === findingStatusTypeIds.CHANGED){
                        lastUpdatedFindingType = response.updatedFindingTypeName.toLowerCase();
                    }
                }
            }

            return lastUpdatedFindingType;
        }

        function addStatusDesc(initString, interimStatus) {
            var statusDescArr = htmlToElementArray(interimStatus.statusDesc);
            var statusDesc = '';
            statusDescArr.forEach(function (elm, i) {
                var linebreak = i == 0 || getElementText(elm) === '' ? '' : '\n\n';
                statusDesc += linebreak + getElementText(elm);
            });
            return initString + ' ' + statusDesc;
        }

        function sortByProperty(arr, prop) {
            return arr.sort(function (a, b) {
                if (angular.isArray(prop)) {
                    //sorts by the first property found
                    for (var i = 0; i < prop.length; i++) {
                        var aVal = helperSvc.getObjectPropertyValueByString(a, prop[i]);
                        var bVal = helperSvc.getObjectPropertyValueByString(b, prop[i]);
                        if (aVal && bVal) return aVal - bVal;
                    }
                } else {
                    return a[prop] - b[prop];
                }

            });
        }

        factory.getSummaryOfActions = function (currentStatement) {
            var ORG_NAME = statementStorageSvc.data.currentReviewTeam.organizationName;
            var NGR_YEAR = statementStorageSvc.data.currentReviewTeam.ngrYear;
            var YEAR_RANGE = (parseInt(statementStorageSvc.data.currentReviewTeam.reviewYear) - 1) + '–' + statementStorageSvc.data.currentReviewTeam.reviewYear;
            var COMMISSION_NAME = getCommissionFullName(statementStorageSvc.data.currentReviewTeam.commissionId).toUpperCase();

            var programs = statementStorageSvc.data.programs || [];
            var categorizedPrograms = {};
            var categorySections = [];

            var content = [
                {
                    image: 'ABET_CMYK.png',
                    width: 50,
                    alignment: 'center'
                },
                {
                    text: '\n\n{0}\n\n\n'.format(COMMISSION_NAME),
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                },
                {
                    text: 'Summary of Accreditation Actions\n',
                    style: ['medium', 'camptonSemiBold'],
                    alignment: 'center'
                },
                {
                    text: '{0} Accreditation Cycle\n\n'.format(YEAR_RANGE),
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                },
                {
                    text: ORG_NAME + '\n',
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                },
                {
                    text: '{0} \n\n\n\n'.format(getLocationInfo(statementStorageSvc.data.currentReviewTeam)),
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                }
            ];

            programs.forEach(function (program) {
                var lapseDate = new Date("September 30, " + (statementStorageSvc.data?.currentReviewTeam?.reviewYear || new Date().getUTCFullYear().toString())); // Should always have a review year but just in case
                var utcEndDate = program.accreditationEndDate ? new Date(program.accreditationEndDate.replace(/-/g, '\/').replace(/T.+/, '')) : null; //replace resets time zone

                // Hides withdrawn and cancelled programs as well as programs that have already lapsed (terminated programs without extensions) in summary of actions
                if (program.actionCode !== actionCodes.W && program.actionCode !== actionCodes.C && (!utcEndDate || utcEndDate > lapseDate) &&
                    program.programReviewTypeCode !== programReviewTypeIds.TERMINATIONREPORT && program.programReviewTypeCode !== programReviewTypeIds.TERMINATIONVISIT) {

                    var groupAction = 0;
                    var groupNew = 0;
                    var groupAdm = 0;
                    var endDate = program.accreditationEndDate ? new Date(program.accreditationEndDate).getUTCFullYear() : null;
                    var updatedNGRYear = program.ngrYear == program.reviewYear ? program.ngrYear + 6 : program.ngrYear;

                    // groupAction

                    if (program.actionCode == actionCodes.NGR || endDate == updatedNGRYear && (endDate - program.reviewYear > 0)) {
                        groupAction = 1;

                    } else if (program.actionCode == actionCodes.IR && (endDate !== updatedNGRYear)) {
                        groupAction = 2;

                    } else if (program.actionCode == actionCodes.IV && (endDate !== updatedNGRYear)) {
                        groupAction = 3

                    } else if ((program.actionCode == actionCodes.SC || program.actionCode == actionCodes.SCV) && (endDate !== updatedNGRYear)) {
                        groupAction = 4

                    } else if (program.actionCode == actionCodes.SCR && (endDate !== updatedNGRYear)) {
                        groupAction = 7

                    } else if (program.actionCode == actionCodes.T) {
                        groupAction = 5

                    } else if (program.actionCode == actionCodes.NA) {
                        groupAction = 6

                        // Use the code below to add text to the summary of actions for a withdrawn program
                        // You'll need to remove the the line  `if (program.actionCode !== actionCodes.W)` above

                        //} else if (program.actionCode == actionCodes.W) {
                        //    groupAction = 8;
                    }

                    // groupNew

                    if (!(program.actionCode == actionCodes.NA || program.actionCode == actionCodes.W) && (program.programReviewTypeCode == 'IGR')) {
                        groupNew = 1;
                    }

                    // groupAdm

                    if ((program.actionCode == actionCodes.IR || program.actionCode == actionCodes.IV || program.actionCode == actionCodes.SCR) && (endDate == updatedNGRYear)) {
                        groupAdm = 1

                    } else if ((program.actionCode == actionCodes.SC || program.actionCode == actionCodes.SCV) && (endDate == updatedNGRYear)) {
                        groupAdm = 2

                    } else if (program.actionCode == actionCodes.NA && endDate != null) {
                        groupAdm = 3

                    } else if (program.actionCode == actionCodes.NA && endDate == null) {
                        groupAdm = 4

                    }

                    var propName = 'action' + groupAction + 'New' + groupNew + 'Adm' + groupAdm;
                    // Need to separate new programs with different start, end dates when those dates are to be displayed in the summary
                    if ([1].indexOf(groupNew) > -1) {
                        propName += 'start' + new Date(program.accreditationStartDate).toISOString().slice(0, 10).replace('-', '');
                    }
                    if ([1, 2, 3, 4, 5, 7].indexOf(groupAction) > -1 || [3].indexOf(groupAdm) > -1) {
                        propName += 'end' + new Date(program.accreditationEndDate).toISOString().slice(0, 10).replace('-', '');
                    }

                    if (categorizedPrograms[propName]) {
                        // Category already exists
                        // Add this program to existing category

                        categorizedPrograms[propName].programNames.push(program.programName);

                    } else {
                        // Add new category
                        // Add this program to the newly added category
                        // Create text for category

                        categorizedPrograms[propName] = {
                            programNames: [program.programName],
                            groupAction: groupAction,
                            groupNew: groupNew,
                            groupAdm: groupAdm,
                            startDate: new Date(program.accreditationStartDate),
                            endDate: new Date(program.accreditationEndDate)
                        }
                    }
                }
            });

            var sections = [];
            for (var category in categorizedPrograms) {
                if (categorizedPrograms.hasOwnProperty(category)) {
                    var programCategory = categorizedPrograms[category];
                    var programList = [];
                    programCategory.programNames.forEach(function (name) {
                        programList.push({
                            text: name,
                            style: ['small', 'camptonMedium']
                        })
                    });

                    sections = sections.concat(programList);
                    sections.push({
                        text: '\n' + getCategoryText(programCategory) + '\n\n\n',
                        style: ['small', 'camptonLight'],
                    })
                }
            }

            content = content.concat(sections);
            content.push({
                text: '',
                pageBreak: 'after'
            })

            return content;

            function getCategoryText(programCategory) {
                var isPlural = programCategory.programNames.length > 1 ? 1 : 0;
                var groupAction = programCategory.groupAction;
                var groupNew = programCategory.groupNew;
                var groupAdm = programCategory.groupAdm;
                var startDate = programCategory.startDate;
                var endDate = programCategory.endDate;
                var endYear = endDate.getUTCFullYear();
                var formattedEndDate = '{0} {1}, {2}'.format(monthNames[endDate.getUTCMonth()], endDate.getUTCDate(), endDate.getUTCFullYear());
                var formattedStartDate = '{0} {1}, {2}'.format(monthNames[startDate.getUTCMonth()], startDate.getUTCDate(), startDate.getUTCFullYear());

                var paragraphs = {
                    groupAction: {
                        0: '',
                        1: 'Accredit to {0}. A request to ABET by January 31, {1} will be required to initiate a reaccreditation evaluation visit. In preparation for the visit, a Self-Study Report must be submitted to ABET by July 1, {1}. The reaccreditation evaluation will be a comprehensive general review.'.format(formattedEndDate, endYear - 1),
                        2: 'Accredit to {0}. A request to ABET by January 31, {1} will be required to initiate a reaccreditation report evaluation.  A report describing the actions taken to correct shortcomings identified in the attached final statement must be submitted to ABET by July 1, {1}. The reaccreditation evaluation will focus on these shortcomings. Please note that a visit is not required.'.format(formattedEndDate, endYear - 1),
                        3: 'Accredit to {0}. A request to ABET by January 31, {1} will be required to initiate a reaccreditation evaluation visit during Fall, {1}. In preparation for the visit, a report describing the actions taken to correct shortcomings identified in the attached final statement must be submitted to ABET by July 1, {1}. The reaccreditation evaluation will focus on these shortcomings.'.format(formattedEndDate, endYear - 1),
                        4: 'Accredit to {0}.  The institution must show cause why accreditation should not be terminated as of that date. Accreditation is continued until that date to allow time for shortcomings to be corrected or for the program to be terminated in an orderly manner. A request to ABET by January 31, {1} will be required to initiate a reaccreditation evaluation visit during Fall, {2}. In preparation for the visit, a report describing the actions taken to correct shortcomings identified in the final statement must be submitted to ABET by July 1, {1}. The reaccreditation evaluation will focus on these shortcomings. Nevertheless, reaccreditation should not be anticipated unless strengthened compliance with all criteria requirements has been achieved by the time of the visit.'.format(formattedEndDate, endYear - 1, endYear),
                        5: ['Accredit to {0}. This program has been placed on Termination Status. In order to extend the accreditation period, a reevaluation will be required. Please contact ABET Headquarters if accreditation of this program is desired beyond the period specified above.'.format(formattedEndDate),
                        'Accredit to {0}. These programs have been placed on Termination Status. In order to extend the accreditation period, a reevaluation will be required. Please contact ABET Headquarters if accreditation of one or more of these programs is desired beyond the period specified above.'.format(formattedEndDate)],
                        6: 'Not to accredit. Please refer to Section II.D. of the ABET Accreditation Policy and Procedure Manual for information about appeals, requests for reconsideration, and requests for immediate revisits.  Please note that the deadline to initiate these processes is 30 days following the receipt of this letter.',
                        7: 'Accredit to {0}. The institution must show cause why accreditation should not be terminated as of that date. Accreditation is continued until that date to allow time for shortcomings to be corrected or for the program to be terminated in an orderly manner. A request to ABET by January 31, {1} will be required to initiate a reaccreditation report evaluation. A report describing the actions taken to correct shortcomings identified in the attached final statement must be submitted to ABET by July 1, {1}. The reaccreditation evaluation will focus on these shortcomings. Please note that a visit is not required.'.format(formattedEndDate, endYear - 1),
                        8: 'WITHDRAWN TEXT PLACEHOLDER'
                    },
                    groupNew: {
                        0: '',
                        1: ['\n\nThis is a newly accredited program.  Please note that this accreditation action extends retroactively from {0}.'.format(formattedStartDate),
                        '\n\nThese are newly accredited programs.  Please note that this accreditation action extends retroactively from {0}.'.format(formattedStartDate)]
                    },
                    groupAdm: {
                        0: '',
                        1: '\n\nThe Self-Study Report must specifically include descriptions of the actions taken to correct shortcomings identified in the attached final statement.',
                        2: '\n\nThe institution must show cause why accreditation should not be terminated as of that date. Accreditation is continued until that date to allow time for shortcomings to be corrected or for the program to be terminated in an orderly manner. The Self-Study Report must specifically include descriptions of the actions taken to correct shortcomings identified in the attached final statement. Reaccreditation should not be anticipated unless strengthened compliance with all criteria requirements has been achieved by the time of the visit.',
                        3: ['\n\nIn accordance with Section I.E.12.i.(2) of the ABET Accreditation Policy and Procedure Manual, accreditation is extended to September 30, {0}. This action supersedes any accreditation status listing in ABET lists of accredited programs. Further, it is expected that your institution will formally notify students and faculty members affected by the termination of the program\'s accreditation status not later than {1}.'.format(endYear + 1, formattedEndDate),
                        '\n\nIn accordance with Section I.E.12.i.(2) of the ABET Accreditation Policy and Procedure Manual, accreditation is extended to September 30, {0}. This action supersedes any accreditation status listing in ABET lists of accredited programs. Further, it is expected that your institution will formally notify students and faculty members affected by the termination of these programs\' accreditation status not later than {1}.'.format(endYear + 1, formattedEndDate)],
                        4: ['\n\nIf it is desired to resubmit this program for a new evaluation, a Request for Evaluation should be submitted to this office by January 31 of the calendar year in which a visit is desired.',
                            '\n\nIf it is desired to resubmit one or more of these programs for a new evaluation, a Request for Evaluation should be submitted to this office by January 31 of the calendar year in which a visit is desired.']
                    }
                };

                var groupActionParagraph = Array.isArray(paragraphs.groupAction[groupAction]) ? paragraphs.groupAction[groupAction][isPlural] : paragraphs.groupAction[groupAction];
                var groupNewParagraph = Array.isArray(paragraphs.groupNew[groupNew]) ? paragraphs.groupNew[groupNew][isPlural] : paragraphs.groupNew[groupNew];
                var groupAdmParagraph = Array.isArray(paragraphs.groupAdm[groupAdm]) ? paragraphs.groupAdm[groupAdm][isPlural] : paragraphs.groupAdm[groupAdm];

                return groupActionParagraph + groupNewParagraph + groupAdmParagraph;
            }
        }

        factory.generateDoc = function (currentStatement) {
            var content = [], sectionContent = [];

            var isNameLong = statementStorageSvc.data.currentReviewTeam.organizationName.length > 50;

            var statementTypeName = getStatementTypeName(currentStatement.statementTypeName);
            var docTitle = '{0} Statement Preview'.format(statementTypeName);
            var draftSubmitted = statementStorageSvc.isStatementSubmittedToInstitution(currentStatement);
            var draftPendingSubmission = statementStorageSvc.isStatementPendingSubmissionToInstitution(currentStatement);
            var finalSubmitted = statementStorageSvc.isFinalStatementSubmittedToInstitution(currentStatement);
            var finalPendingSubmission = statementStorageSvc.isFinalStatementPendingSubmissionToInstitution(currentStatement);
            var watermarkText = '';

            if (draftSubmitted || draftPendingSubmission) {
                watermarkText = '   DRAFT TO INSTITUTION   ';
            } else if (!(finalSubmitted || finalPendingSubmission)) {
                watermarkText = '   {0} STATEMENT PREVIEW   '.format(currentStatement.statementTypeName).toUpperCase();
            }

            // Add Summary of Accreditation Actions to submitted final statements
            if (finalSubmitted || finalPendingSubmission) {
                var summaryOfActions = factory.getSummaryOfActions(currentStatement);
                content = content.concat(summaryOfActions);
            }

            var titlePage = [
                {
                    text: '\n\n\n\n\n{0}'.format(isNameLong ? '' : '\n\n')
                },
                {
                    image: 'ABET_CMYK.png',
                    width: 150,
                    alignment: 'center'
                },
                {
                    text: '\n{0}\n\n\n\n{1}'.format(getCommissionFullName(statementStorageSvc.data.currentReviewTeam.commissionId).toUpperCase(), isNameLong ? '' : '\n\n\n'),
                    style: ['larger', 'camptonLight'],
                    alignment: 'center'
                },

                {
                    text: statementStorageSvc.data.currentReviewTeam.organizationName.toUpperCase() + '\n',
                    style: ['jumbo', 'camptonExtraBold'],
                    alignment: 'center'
                },
                {
                    text: '{0} \n\n'.format(getLocationInfo(statementStorageSvc.data.currentReviewTeam)).toUpperCase(),
                    style: ['large', 'camptonMedium'],
                    alignment: 'center'
                },
                {
                    text: '{0} Statement of Accreditation\n'.format(statementTypeName).toUpperCase(),
                    style: ['large', 'camptonExtraBold'],
                    alignment: 'center'
                },
                {
                    text: '{0} Accreditation Cycle'.format(getAccreditationCycle(statementStorageSvc.data.currentReviewTeam.reviewYear)).toUpperCase(),
                    style: ['medium', 'camptonMedium'],
                    alignment: 'center'
                }
            ];

            // Add appeal information to final statements
            try {
                const appeal = findAppeal(currentStatement);

                if (appeal?.cycleYear) {
                    titlePage.push({
                        text: '{0} Accreditation Cycle'.format(getAccreditationCycle(appeal.cycleYear)).toUpperCase(),
                        style: ['medium', 'camptonMedium'],
                        alignment: 'center'
                    });
                }
            } catch (e) {
                console.log('Error attempting to add appeal information to title page', e);
            }

            content = content.concat(titlePage);

            currentStatement.statementDetailDtos = sortByProperty(currentStatement.statementDetailDtos, 'statementDetailId');//?? whats the point?
            currentStatement.statementDetailDtos = currentStatement.statementDetailDtos.sort(statementStorageSvc.statementDetailComparer);

            angular.forEach(currentStatement.statementDetailDtos, function (section, i) {

                //add program name or intro header
                sectionContent.push(getSectionHeaderContent(section, currentStatement));

                //sort finding types to correct order of severity
                section.statementJson = sortByProperty(section.statementJson, ['orderNumber', 'statementFindingTypeId']);

                var hasNoNegativeFindings = statementStorageSvc.hasNoNegativeFindings(section);

                angular.forEach(section.statementJson, function (findingType, index) {
                    var isLastFindingType = index == section.statementJson.length - 1;

                    // Do not show after visit information on draft statement...POSSIBLY HERE SHOW it for greate than final
                    if (findingType.statementFindingTypeId === statementFindingTypes.INSTITUTIONAFTERVISIT &&
                        statementStorageSvc.data.currentReviewTeam.reviewTypeCode == "IR" &&
                        currentStatement.statementTypeId < statementTypeIds.FINAL) {
                        return;
                    }
                    //add finding type header
                    var findingTypeName = formatFindingTypeName(findingType);
                    sectionContent.push({
                        text: '\n' + findingTypeName,
                        style: ['medium', 'orange', 'camptonSemiBold', 'addLineSpacing']
                    });

                    //HACK: move element up slightly to compensate for 1mm spacing issue caused by UL/OL elements
                    if (index > 0 && section.statementJson[index - 1].findings.length > 1) {
                        //sectionContent[sectionContent.length - 1].margin = [0, 2, 0, 0];
                    }

                    //order findings by finding type
                    findingType.findings.sort(statementStorageSvc.findingsComparer);

                    angular.forEach(findingType.findings, function (finding, index) {
                        var isLastFinding = isLastFindingType && index == findingType.findings.length - 1;

                        //finding text
                        sectionContent = sectionContent.concat(getFindingText(finding.criteria, index, findingType.findings.length > 1, findingType, isLastFinding)); // && (finding.criteria && !finding.criteria.criteriaName)
                    });

                    if (hasNoNegativeFindings && (index == section.statementJson.length - 1)) {
                        sectionContent.push({
                            text: '\nNo deficiencies, weaknesses, or concerns were found.',
                            style: ['small', 'body', 'camptonMedium']
                        });
                    }
                })
            });

            content = content.concat(sectionContent);

            return factory.createDoc(docTitle, content, watermarkText, statementTypeName);
        };

        factory.createDoc = function (docTitle, content, watermarkText, statementTypeName) {
            var docDefintion = {
                info: {
                    title: docTitle
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 70, 70, 70],
                watermark: {
                    text: watermarkText || '',
                    opacity: 0.06,
                    font: 'calibri'
                },
                footer: function (currentPage, pageCount) {
                    if (currentPage === 1) {
                        return {};
                    } else {
                        return {
                            columns: statementTypeName ?
                                [{
                                    text: [
                                        'PAGE ',
                                        { text: currentPage.toString(), style: 'campton' },
                                        ' OF ',
                                        { text: pageCount, style: 'campton' }
                                    ],
                                    alignment: 'left',
                                    style: ['smaller', 'camptonLight'],
                                    width: '*'
                                },
                                {
                                    text: [
                                        { text: '{0} STATEMENT '.format(statementTypeName).toUpperCase(), style: 'camptonLight' },
                                        { text: statementStorageSvc.data.currentReviewTeam.organizationName.toUpperCase(), style: 'campton' },
                                    ],
                                    alignment: 'right',
                                    style: 'smaller',
                                    width: 400
                                }] :
                                [{
                                    text: [
                                        'PAGE ',
                                        { text: currentPage.toString(), style: 'campton' },
                                        ' OF ',
                                        { text: pageCount, style: 'campton' }
                                    ],
                                    alignment: 'center',
                                    style: ['smaller', 'camptonLight'],
                                    width: '*'
                                }],
                            margin: [70, 44, 70, 0]
                        };
                    }
                }
            };

            return docDefintion;
        };

        return {
            generateDoc: factory.generateDoc,
            getSummaryOfActions: factory.getSummaryOfActions,
            createDoc: factory.createDoc
        };
    };

    module.factory('statementPDFSvc', statementPDFSvc);

})(angular.module('statement'));