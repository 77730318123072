(function (module) {

    var filter = function (helperSvc) {
        return function (items, reviewTeamFilters, assignmentType) {
            // E.g. reviewTeamFilters:
            //
            // myReviewTeamFilters = {
            //     assignment: 'assigned',
            //     reviewType: 'GR',
            //     noOfProgramsRange: null,
            //     country: 'US',
            //     simultaneousVisit: null,
            //     jointVisit: null,
            //     mainCampus: null
            // };
            //
            // E.g. assignmentType = 'teamChairs' | 'editor1' | 'editor2' | 'coTeamChairs'

            var matches = [];

            angular.forEach(items, function (reviewTeam) {
                // Assignment status
                if (reviewTeamFilters.assignment) {
                    switch (reviewTeamFilters.assignment) {
                        case 'assigned':
                            if (!reviewTeam[assignmentType] || Array.isArray(reviewTeam[assignmentType]) && reviewTeam[assignmentType].length === 0) {
                                return;
                            }
                            break;
                        case 'unassigned':
                            if (reviewTeam[assignmentType] && (!Array.isArray(reviewTeam[assignmentType]) || reviewTeam[assignmentType].length > 0)) {
                                return;
                            }
                            break;
                    }
                }

                // Number of Programs
                if (reviewTeamFilters.noOfProgramsRange) {
                    var minCount = reviewTeamFilters.noOfProgramsRange.firstVal;
                    var maxCount = reviewTeamFilters.noOfProgramsRange.secondVal;
                    if (minCount && maxCount && minCount > maxCount) {
                        var oldMinCount = minCount;
                        minCount = maxCount;
                        maxCount = oldMinCount;
                    }
                    if ((minCount && reviewTeam.totalPrograms < minCount) || (maxCount && reviewTeam.totalPrograms > maxCount)) {
                        return;
                    }
                }

                // Review Type
                if (reviewTeamFilters.reviewTypes && reviewTeamFilters.reviewTypes.length > 0) {
                    if(!helperSvc.arrayContainsByPropertyValue(reviewTeamFilters.reviewTypes, 'codeKey', reviewTeam.reviewTypeCode)){
                        return;
                    }
                }

                // Country
                if (reviewTeamFilters.country) {
                    switch (reviewTeamFilters.country) {
                        case 'US':
                            if (!reviewTeam.countryCode || reviewTeam.countryCode !== 'US')
                                return;
                            break;
                        case 'nonUS':
                            if (!reviewTeam.countryCode || reviewTeam.countryCode === 'US')
                                return;
                            break;
                    }
                }

                // Simultaneous Visit
                if (reviewTeamFilters.simultaneousVisit) {
                    if (!reviewTeam.simultaneousVisits || reviewTeam.simultaneousVisits.length === 0) {
                        return;
                    }
                }

                // Joint Visit
                if (reviewTeamFilters.jointVisit) {
                    if (!reviewTeam.jointPrograms || reviewTeam.jointPrograms.length === 0) {
                        return;
                    }
                }

                // Multi Campus Only
                if (reviewTeamFilters.multiCampus) {
                    if (!reviewTeam.multipleCampuses || reviewTeam.multipleCampuses.length === 0) {
                        return;
                    }
                }

                if (reviewTeamFilters.phaseInCriteria === "in" && !(reviewTeam.isPhaseInCriteria)) {
                    return;                    
                } else if (reviewTeamFilters.phaseInCriteria === "out" && reviewTeam.isPhaseInCriteria) {
                    return;
                }


                // Review team has passed every filter check; add it to the filtered matches collection.
                matches.push(reviewTeam);
            });

            return matches;
        };
    };

    module.filter('reviewTeams', filter);

}(angular.module('assignment')));

