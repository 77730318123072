(function (module) {
    var pevPersonalCtrl = function ($uibModal, helperSvc, alertSvc, pevAppSvc, currentUser, componentHelperSvc, $scope) {
        var model = this;

        model.data = pevAppSvc.data;
        model.slug = pevAppSvc.slugs.INFO;
        model.isPreviewMode = pevAppSvc.isPreviewMode();
        model.isVolunteer = false;
        model.openPersonalInstructions = pevAppSvc.openPersonalInstructions;

        var activate = function () {
            if (pevAppSvc.data.application.applicationJson.volunteer) {
                model.isVolunteer = pevAppSvc.data.application.applicationJson.volunteer.volunteerId != 0;
            }
            componentHelperSvc.invokeOnRendered('appPersonalInfo', $scope);
        }();
    };
    module.controller('pevPersonalCtrl', pevPersonalCtrl);

}(angular.module('pevApp')));