(function (module) {

    var simultaneousVisitsEditCtrl = function (parent, reviewSimultaneousOptionDtos, commissions, $scope, $uibModalInstance, helperSvc, eventSvc, alertSvc, reviewSimultaneousOptionSvc, reviewSvc) {
        var model = $scope;
        model.parent = parent;
        model.reviewSimultaneousOptionDtos = reviewSimultaneousOptionDtos;
        model.title = 'Edit Simultaneous Reviews';
        model.commissions = [];
        model.currentVisit = {};

        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        model.isReviewSelectMode = false;

        model.reviewId;

        init();

        model.isValid = function () {
            return model.currentVisit.reviewSimultaneousOptionCommissionDtos?.length > 1;
        }

        model.close = function () {
            if (model.isInsertMode) {
                alertSvc.addAlertWarning("Your pending changes have been lost.");
            }
            $uibModalInstance.close();
        };

        model.delete = function (visit) {
            alertSvc.confirmDelete(null, deleteFunc, visit);

            function deleteFunc() {
                reviewSimultaneousOptionSvc.delete(visit).then(function (data) {
                    updateReviewData(model.reviewId);
                    if (model.currentVisit === visit) {
                        model.isInsertMode = false;
                    }
                    alertSvc.addModalAlertSuccess("Review status successfully deleted.");
               
                });
            }
        };

        model.addRow = function () {
            resetCurrentVisit();
            model.isInsertMode = true;
            model.isCreateMode = true;
            model.isUpdateMode = false;
        };

        model.cancel = function () {
            resetCurrentVisit();
            model.isInsertMode = false;
        }

        model.save = function (visit) {
            angular.forEach(visit.reviewSimultaneousOptionCommissionDtos, function (commission) {
                if (!commission.reviewSimultaneousOptionId) {
                    commission.reviewSimultaneousOptionId = visit.reviewSimultaneousOptionId;
                    commission.reviewSimultaneousOptionCommissionId = 0;
                }
            });

            if (model.isCreateMode) {
                reviewSimultaneousOptionSvc.create(visit).then(function () {
                    resetCurrentVisit();
                    updateReviewData(model.reviewId);
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Review status successfully added.");
                });
            } else if (model.isUpdateMode) {
                reviewSimultaneousOptionSvc.update(visit).then(function () {
                    resetCurrentVisit();
                    updateReviewData(model.reviewId);
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Review status successfully updated.");
                });
            }
            
        };

        model.select = function (visit) {
            model.currentVisit = visit;
            model.isInsertMode = true;
            model.isCreateMode = false;
            model.isUpdateMode = true;
        };

        model.loadReviewGroupOptions = function (review) {
            model.reviewId = review.reviewId;
            resetCurrentVisit();
            model.reviewSimultaneousOptionDtos = review.reviewSimultaneousOptionDtos;
            model.isReviewSelectMode = false;
        };

        function init() {
            if (!model.reviewSimultaneousOptionDtos) {
                model.isReviewSelectMode = true;
                model.reviewGroups = parent.reviewGroups;
            } else {
                resetCurrentVisit();
                model.reviewId = model.reviewSimultaneousOptionDtos[0].reviewId;
            }

            // Convert commission id varaible names from codeKey to commissionId
            angular.forEach(commissions, function (commission, key) {
                model.commissions.push({
                    commissionId: commission.codeKey,
                    commissionName: commission.codeName
                })
            });
        }

        function resetCurrentVisit() {
            model.currentVisit = {
                reviewSimultaneousOptionId: reviewSimultaneousOptionDtos ? reviewSimultaneousOptionDtos[0].reviewSimultaneousOptionId : 0,
                reviewId: reviewSimultaneousOptionDtos ? reviewSimultaneousOptionDtos[0].reviewId : model.reviewId,
                reviewSimultaneousOptionCommissionDtos: []
            };
        };

        function updateReviewData() {
            reviewSimultaneousOptionSvc.getSimultaneousVisitsByReviewId(model.reviewId).then(function (data) {
                model.reviewSimultaneousOptionDtos = helperSvc.getResults(data);
            });

            reviewSvc.getReviewsByOrganizationId(parent.organizationId, true).then(function (){
                parent.refreshReviewGroups();
            });
            reviewSvc.getReviewsByOrganizationId(parent.organizationId, false).then(function () {
                parent.refreshReviewGroups();
            });
        }

    };

    module.controller('simultaneousVisitsEditCtrl', simultaneousVisitsEditCtrl);

}(angular.module('review')));