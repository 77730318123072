(function (module) {

    var collegeDirectoryDetailCtrl = function ($state, collegeDirectorySvc, alertSvc, barSvc, $stateParams) {
        var model = this;

        model.title = "";
        model.resultMsg = model.defaultSearchMsg;
        model.editCollegeInfo = collegeDirectorySvc.editCollegeInfo;
        model.collegeDirectoryId = parseInt($stateParams.collegeDirectoryId);
        model.college = {};
        model.addressFields = ['street', 'city', 'stateCode', 'province', 'postalCode', 'countryCode'];

        model.showSpinner = false;

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        model.getUrl = collegeDirectorySvc.getUrl;
        model.getFullAddress = collegeDirectorySvc.getFullAddress;

        model.delete = function (college) {
            alertSvc.confirmDelete(college.name, deleteFunc);

            function deleteFunc() {
                collegeDirectorySvc.delete(college).then(function (data) {
                    alertSvc.addAlertSuccess("College successfully deleted.");
                });
            }
        };

        model.getCollegeById = function () {
            collegeDirectorySvc.getCollegeDiretoryById(model.collegeDirectoryId).then(function (data) {
                model.college = data;
                model.title = model.college.collegeName;
            });
        };

        activate();

        function activate() {
            model.getCollegeById();
        }
    };

    module.controller('collegeDirectoryDetailCtrl', collegeDirectoryDetailCtrl);

}(angular.module('collegeDirectory')));