(function (module) {
    
    var converter = function () {
        return function(input) {
                return input.split(/\n/g);
            };
    };
   
    module.filter('newline', converter);

})(angular.module('person'));