(function (module) {

    var viewStatementPreviewCtrl = function ($scope, $uibModalInstance, statementPDFSvc, helperSvc, currentStatement, statementTypeIds) {
        var model = $scope;

        model.title = 'View Statement Preview';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        }

        function activate() {
            var stateDoc = null;

            //08122020 - Implemented new StatementDocument table and all documents should be from this table.
            if (currentStatement.statementDocumentDtos && currentStatement.statementDocumentDtos.length) {
                //draft or final statement pdf saved in Document table
                stateDoc = currentStatement.statementDocumentDtos.filter(t => t.documentTypeId === 3 || t.documentTypeId === 4);
                
                if (stateDoc && stateDoc.length > 0 && stateDoc[0].stream_id) {
                    model.stream_id = stateDoc[0].stream_id; //should be one record
                }
            }
            //08122020 - if any of stored documents not migrated to new StatementDocument table
            else if (currentStatement.stream_id) {
                model.stream_id = currentStatement.stream_id;
            } else {
                model.doc = statementPDFSvc.generateDoc(currentStatement);
            }
        }

        activate();
    };

    module.controller('viewStatementPreviewCtrl', viewStatementPreviewCtrl);

}(angular.module('statement')));