(function (module) {

    // Factory for creating odata services.  Service implementations call this factory and wrap the returned object
    // with an explicit interface, adding methods as needed for the special case.
    //
    // EXAMPLE config
    // var config = [
    //    {
    //        apiPath:                  '/VolunteerEducation',        // path to the RESTful web service
    //
    //        keyName:                  'volunteerEducationId',       // name of the key/id field on the entity 
    //
    //        dataItemName:             'currentVolunteer ,           // name for variable holding stored item--e.g. thisSvc.data.currentVolunteer
    //                                                                // (optional but needed if storing data in get())
    //
    //        dataCollectionName:       'volunteers',                 // name for variable holding list of stored items--e.g. thisSvc.data.volunteers[0]
    //                                                                // (optional but needed if storing data in getByForeignKey())
    //
    //        foreignKeyName:           'volunteerId',                // name of foreign key field used to get list of entities in getByForeignKey()
    //                                                                // (optional but needed for getByForeignKey to work)
    //
    //        getByForeignKeyPath:      '/GetItemsByContainerId'      // path to web service odata function for retrieving list of entities by foreign key value
    //                                                                // Specify foreignKeyName and omit getByForeignKeyPath and getByForeignKey function
    //                                                                // will use odata filter (e.g. $filter=foreignKeyName eq key) to get list of items.
    //
    //        expandables:              ['collegeDirectoryDto',       // optional list of nested entities to expand on the entity  
    //                                   ['container', 'nested']      // I.E. $expand=collegeDirectoryDto,container($expand=nested)
    //                                  ],
    //    };
    //
    // EXAMPLE usage
    // (function (module) {
    //     var educationInfoSvc = function (odataServiceFactory) {
    //         var config = { /* see above for config example... */ };
    //
    //         var factory = new odataServiceFactory(config);
    //
    //         return {
    //             data: factory.data,
    //             getByVolunteerId: factory.getByForeignKey,
    //             create: factory.create,
    //             update: factory.update,
    //             delete: factory.delete
    //         };
    //     };
    //
    //     module.factory('educationInfoSvc', educationInfoSvc);
    //
    // })(angular.module('volunteer'));
    var odataServiceFactory = function (odataSvc, $q) {

        return function (config) {
            // Configure factory from settings in config parameter
            var apiPath = config.apiPath;
            var keyName = config.keyName;
            // Optional configuration options:
            var expandables = config.expandables || null;
            var dataItemName = config.dataItemName || null;
            var dataCollectionName = config.dataCollectionName || null;
            var foreignKeyName = config.foreignKeyName || null;
            var getByForeignKeyPath = config.getByForeignKeyPath || null;

            // Private variables and methods

            // Store values used for last filtered query so we know how to reload data later.
            var filters = null;

            // Container for entity data.  
            var data = {};
            if (dataItemName) {
                data[dataItemName] = {};
            };
            if (dataCollectionName) {
                data[dataCollectionName] = [];
            };

            // Allow for exposing odata service object to factory consumers while also using internally
            // (this.getOdataSvc doesn't work outside factory because 'this' refers to browser window in that context)
            var getOdataSvc = function () {
                var oSvc = odataSvc.get();
                return oSvc;
            };

            // Remove cached reference to entity.
            var deleteDataItem = function (deletedItem) {
                // Delete data item if it is defined and was deleted on the server.
                if (dataItemName && data[dataItemName] && data[dataItemName][keyName] === deletedItem[keyName]) {
                    data[dataItemName] = null;
                };
            };

            // Refresh entity data to reflect changes.
            var refreshData = function (oSvc, changedItem) {
                oSvc.getDeferred().promise.then(function () {
                    // Update data item if defined and also updated on the server.
                    if (dataItemName && data[dataItemName] && data[dataItemName][keyName] === changedItem[keyName]) {
                        getFunc(changedItem[keyName]);
                    };
                    // Update data collection if defined to reflect results of create/update/delete.
                    if (dataCollectionName && data[dataCollectionName]) {
                        if (filters) {
                            getByFilterValuesFunc(filters);
                        } else {
                            getByForeignKeyFunc(changedItem[foreignKeyName]);
                        };
                    };
                });
            };

            var expandEntities = function (svc) {
                if (expandables) {
                    // Need to make a copy because if you give our odata resource an array to expand
                    // it will destructively pop off its members and then you know what to expand the next time.
                    // e.g. ['parent', 'child'] becomes [] after you create '$expand=parent($expand=child)
                    var expandList = angular.copy(expandables);
                    expandList.forEach(function (entityToExpand) {
                        svc.expand(entityToExpand);
                    });
                }
            }

            // Public properties and methods

            // Data container property
            this.data = data;

            // Get reference to the odata service to make adding odata methods easier for factory consumer.
            this.getOdataSvc = getOdataSvc;

            // Get item by key.  Set noStoredData to true to prevent caching of result in data[dataItemName]
            var getFunc = function (key, noStoredData) {
                var oSvc = getOdataSvc();
                var svc = oSvc.getSource(apiPath, keyName).odata();
                expandEntities(svc);
                svc.get(key, oSvc.onSuccess, oSvc.onFailure);

                if (dataItemName && !noStoredData) {
                    oSvc.getDeferred().promise.then(function (results) {
                        data[dataItemName] = results;
                    });
                }

                return oSvc.getDeferred().promise;
            };

            this.get = getFunc;

            // Get list of items by foreign key using OData function.  Set noStoredData to true to prevent caching of result in data[dataCollectionName]
            // Must define getByForeignKeyPath property for this method to work and make sense.
            var getByForeignKeyUsingFunction = function (foreignKey, noStoredData) {
                filters = null;

                var oSvc = getOdataSvc();
                var path = oSvc.getPathWithParameter(getByForeignKeyPath, foreignKeyName, foreignKey);

                var svc = oSvc.getSource(path, keyName).odata();
                expandEntities(svc);
                svc.query(oSvc.onSuccess, oSvc.onFailure);

                if (dataCollectionName && !noStoredData) {
                    oSvc.getDeferred().promise.then(function (results) {
                        data[dataCollectionName] = results;
                    });
                };

                return oSvc.getDeferred().promise;
            };

            // Get list of items by foreign key using OData filter.  Set noStoredData to true to prevent caching of result in data[dataCollectionName]
            // Must define foreignKeyName property for this method to work and make sense.
            var getByForeignKeyUsingFilter= function (foreignKey, noStoredData) {
                filters = null;

                var oSvc = getOdataSvc();

                var svc = oSvc.getSource(apiPath, keyName).odata()
                expandEntities(svc);
                svc.filter(foreignKeyName, foreignKey)
                   .query(oSvc.onSuccess, oSvc.onFailure);

                if (dataCollectionName && !noStoredData) {
                    oSvc.getDeferred().promise.then(function (results) {
                        data[dataCollectionName] = results;
                    });
                }

                return oSvc.getDeferred().promise;
            };

            var getByForeignKeyFunc;
            if (getByForeignKeyPath) {
                // Define function based on an OData function whose path is specified in getByForeignKeyPath 
                getByForeignKeyFunc = getByForeignKeyUsingFunction;
            } else {
                // Define function based on an OData filter by foreignKey
                getByForeignKeyFunc = getByForeignKeyUsingFilter;
            };

            this.getByForeignKey = getByForeignKeyFunc;

            // Get list of items by multiple filter values.  Set noStoredData to true to prevent caching of result in data[dataCollectionName]
            // E.g. filtersConfig = { filterName1: filterValue, filterName2: 'filter value' }
            var getByFilterValuesFunc = function (filtersConfig, noStoredData) {
                filters = filtersConfig;

                var oSvc = getOdataSvc();
                var svc = oSvc.getSource(apiPath, keyName).odata()
                expandEntities(svc);

                for (var property in filters) {
                    if (filters.hasOwnProperty(property) &&
                        (typeof filters[property] === 'number' || typeof filters[property] === 'string')) {
                        svc.filter(property, filters[property]);
                    };
                };

                svc.query(oSvc.onSuccess, oSvc.onFailure);

                if (dataCollectionName && !noStoredData) {
                    oSvc.getDeferred().promise.then(function (results) {
                        data[dataCollectionName] = results;
                    });
                }
            };

            this.getByFilterValuesFunc = getByFilterValuesFunc;

            // Create new item.  Refresh data[dataCollectionName] to reflect addition.
            this.create = function (item) {
                var oSvc = getOdataSvc();
                var resource = oSvc.instantiate(apiPath, keyName, item);
                var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                    refreshData(oSvc, item);
                });

                return promise;
            };

            // Update an exsiting item.  Refresh data[dataCollectionName] to reflect changes.
            this.update = function (item) {
                var oSvc = getOdataSvc();
                var resource = oSvc.instantiate(apiPath, keyName, item);
                var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                    refreshData(oSvc, item);
                });
                 
                return promise;
            };

            // Delete an exsiting item.  Refresh data[dataCollectionName] to reflect removal.
            this.delete = function (item) {
                var oSvc = getOdataSvc();
                var model = {};

                model[keyName] = item[keyName];

                var resource = oSvc.instantiate(apiPath, keyName, model);
                var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
                    deleteDataItem(item);
                    refreshData(oSvc, item);
                });

                return promise;
            };
        };
    };

    module.factory('odataServiceFactory', odataServiceFactory);

})(angular.module('common'));