(function (module) {

    var changeFindingTypeCtrl = function (currentSection, selectedFindingType, selectedFinding, responseType, $scope, $uibModalInstance, alertSvc, helperSvc, findingStatusTypeIds, statementTemplateSvc, statementStorageSvc, localStorage, dueResponsePropNames, teamMemberTypeNames) {
        var model = $scope;

        model.dataIsReady = false;
        model.responseType = responseType;
        model.title = model.responseType ? 'Select Finding Type Change' : 'Change Finding';
        model.currentSection = angular.copy(currentSection);        
        model.selectedFindingType = angular.copy(selectedFindingType);
        model.selectedFindingIndex = statementStorageSvc.getFindingIndexFromFindingType(model.selectedFindingType, selectedFinding);
        model.getProgramName = statementStorageSvc.getProgramName;

        model.changeFinding = function () {
            if (model.isSubmitting) return;
            model.isSubmitting = true;
            // Don't change back to itself 
            if (model.selectedFindingType.statementFindingTypeId === model.newFindingType.statementFindingTypeId && selectedFinding && selectedFinding.criteria && (selectedFinding.criteria.useChangedFindingTypeFromDraftIR === false || selectedFinding.criteria.useChangedFindingTypeFromFinal)) {
                alertSvc.addModalAlertSuccess("Finding type not changed.");
                $uibModalInstance.close();
                return;
            };
            var copiedFinding = angular.copy(model.selectedFindingType.findings[model.selectedFindingIndex]);
            var changedFinding = {
                statementFindingTypeId: model.newFindingType.statementFindingTypeId,
                statementFindingTypeName: model.newFindingType.statementFindingTypeName,
                orderNumber: model.newFindingType.orderNumber,
                findings: [copiedFinding]
            }

            if (model.responseType) {
                if (model.responseType === dueResponsePropNames.INTERIM) {//sets wheter or not to use the updated finding type for IRS
                    selectedFinding.criteria.useChangedFindingTypeFromDraftIR = true;
                } 
                if (model.responseType === dueResponsePropNames.THIRTYDAY) {//sets wheter or not to use the updated finding type 
                    selectedFinding.criteria.useChangedFindingTypeFromFinal = true;
                } 
                selectedFinding.criteria.response[model.responseType].updatedFindingTypeId = model.newFindingType.statementFindingTypeId;
                selectedFinding.criteria.response[model.responseType].updatedFindingTypeName = model.newFindingType.statementFindingTypeName;
                selectedFinding.criteria.response[model.responseType].statusDesc = null;

                model.selectedFindingType.findings[model.selectedFindingIndex] = selectedFinding;
                var findingTypeIndex = statementStorageSvc.getFindingIndex(model.currentSection.statementJson, model.selectedFindingType.statementFindingTypeId);
                model.currentSection.statementJson[findingTypeIndex] = model.selectedFindingType;
            } else {
                // Change Finding Type
                var existingFindingType = helperSvc.getFirstMatch(model.currentSection.statementJson, 'statementFindingTypeId', model.newFindingType.statementFindingTypeId) 

                if (existingFindingType !== null) {
                    model.selectedFindingType.findings[model.selectedFindingIndex].key = helperSvc.getNextKey();
                    
                    existingFindingType.findings.push(model.selectedFindingType.findings[model.selectedFindingIndex]);                       
                } else {
                    changedFinding.findings[0].key = helperSvc.getNextKey();
                    model.currentSection.statementJson.push(changedFinding);
                }
                //remove old finding
                statementTemplateSvc.removeFinding(model.currentSection, selectedFindingType, selectedFinding);              
            }

            if(statementTemplateSvc.checkForCriteriaNeeded(changedFinding)){
                model.currentSection.recommendedAction = null;
                if (model.currentSection.statementJson && model.currentSection.statementJson.length && statementStorageSvc.data.statement.teamMemberTypeId >= teamMemberTypeNames.ADJUNCT)
                    model.currentSection.statementJson[0].isRecommendedActionEditableByAdjunct = true;
            }

            var currentSectionIndex = statementStorageSvc.getSectionIndex(model.statement, model.currentSection);
            model.statement.statementDetailDtos[currentSectionIndex] = model.currentSection;

            statementStorageSvc.save(model.statement).then(function () {
                var ckelements = document.getElementsByClassName('ck-body');
                angular.element(ckelements).remove();

                alertSvc.addModalAlertSuccess("Finding successfully added.");
                $uibModalInstance.close();
            }).catch(function (error) {
                console.log('Error while changing finding type:', error);
                alertSvc.addModalAlertWarning("Finding type could not be changed at this time.");
                $uibModalInstance.close();
            });
        }

        model.selectType = function (findingType) {
            model.newFindingType = findingType;
        }

        model.cancel = function () {
            //may need to add what kind of status change it is... I.E. Interim, 30 day, 30 day post?
            if (model.responseType) {//change back to finding remains if so
                selectedFinding.criteria.response[model.responseType].findingStatusTypeId = findingStatusTypeIds.REMAINS;
                selectedFinding.criteria.response[model.responseType].updatedFindingTypeId = null;
                selectedFinding.criteria.response[model.responseType].updatedFindingTypeName = null;

                model.selectedFindingType.findings[model.selectedFindingIndex] = selectedFinding;
                var findingTypeIndex = statementStorageSvc.getFindingIndex(model.currentSection.statementJson, model.selectedFindingType.statementFindingTypeId);
                model.currentSection.statementJson[findingTypeIndex] = model.selectedFindingType;
                model.currentSection.recommendedAction = null;
                if (model.currentSection.statementJson && model.currentSection.statementJson.length && statementStorageSvc.data.statement.teamMemberTypeId >= teamMemberTypeNames.ADJUNCT)
                    model.currentSection.statementJson[0].isRecommendedActionEditableByAdjunct = true;

                var currentSectionIndex = statementStorageSvc.getSectionIndex(model.statement, model.currentSection);
                model.statement.statementDetailDtos[currentSectionIndex] = model.currentSection;

                statementStorageSvc.save(model.statement).then(function () {
                    $uibModalInstance.close();
                });
            } else {
                $uibModalInstance.close();
            }      
        };

        function getProgramSections() {
            var matchingProgramSections;
            if (!statementTemplateSvc.checkForCriteriaNeeded(model.selectedFindingType)) {
                matchingProgramSections = model.programSections.filter(filterProgramSection);
            } else {
                matchingProgramSections = model.programSections.filter(filterProgramSectionWithCriteria)
            }
            return matchingProgramSections;
        }

        function filterProgramSectionWithCriteria(section) {
            if (selectedFinding.criteria.useChangedFindingTypeFromDraftIR) {
                return section.criteria !== null && section.statementFindingTypeId !== selectedFinding.criteria.response[dueResponsePropNames.INTERIM].updatedFindingTypeId
            }
            if (selectedFinding.criteria.useChangedFindingTypeFromFinal) {
                return section.criteria !== null && section.statementFindingTypeId !== selectedFinding.criteria.response[dueResponsePropNames.THIRTYDAY].updatedFindingTypeId
            }
            return section.criteria !== null && section.statementFindingTypeId !== model.selectedFindingType.statementFindingTypeId;
        }
        function filterProgramSection(section){
            return section.criteria === null && section.statementFindingTypeId !== model.selectedFindingType.statementFindingTypeId;
        }

        var activate = function () {
            var codes = statementStorageSvc.codes;
            model.institutionSections = angular.copy(statementTemplateSvc.data.institutionSectionsTemplate);
            model.programSections = angular.copy(statementTemplateSvc.data.programSectionsTemplate);
            model.statement = angular.copy(statementStorageSvc.data.statement);
            model.selectedFindingTypes = model.currentSection.programId === null ? model.institutionSections : getProgramSections();
            model.newFindingType = null;
            model.dataIsReady = true;     
        }();
    };

    module.controller('changeFindingTypeCtrl', changeFindingTypeCtrl);

}(angular.module('statement')));