(function (module) {

    var statementValidationSvc = function (eventSvc, $q, statementStorageSvc, statementTypeIds, programReviewTypeIds, actionCodes, reviewTypeIds, helperSvc, programReviewSvc, userReviewSvc, teamMemberTypeNames, evaluatorReportSvc, statementFindingTypes, $filter, oauth) {

        var factory = {};
        factory.data = {hasBeenInvoked: false};
        factory.invokeValidation = function () {
            eventSvc.broadcast('ValidateStatement');
        };

        factory.listenToValidate = function (callback, scope) {
            eventSvc.listen(callback, 'ValidateStatement', scope);
        };

        factory.hasBeenInvoked = function () {
            return factory.data.hasBeenInvoked;
        };

        factory.validate = function (statement) {
            var results = []
            // Validate statement
            var statementResults = factory.validateStatementBySection(statement);
            results.push.apply(results, statementResults);

            // Wrap results in a promise for consistency with how other validation services work and to enable future asynchronous server-side validations.
            var deferred = $q.defer();
            promise = deferred.promise;
            deferred.resolve(results);
            return promise;
        };

        factory.validateStatementBySection = function (statement) {
            var errorSections = [];
            // Check that current teamMember can even see validation.
            if (!statement ||
                !statement.statementDetailDtos ||
                statement.statementDetailDtos.length === 0 ||
                (userReviewSvc.data.currentUserReviewTeam.teamMemberTypeId && userReviewSvc.data.currentUserReviewTeam.teamMemberTypeId !== statement.teamMemberTypeId) ||
                (!userReviewSvc.data.currentUserReviewTeam.teamMemberTypeId && statement.teamMemberTypeId !== teamMemberTypeNames.ADMIN && !oauth.isAdmin())
            )
                    return errorSections;
            // Indicate that validation has been performed (empty errors could mean valid *or* errors haven't been looked for yet).
            factory.data.hasBeenInvoked = true;
            // Perform statement-wide validations
            validatePAFUploaded(errorSections);
            // Perform per-section validations
            for (var i = 0; i < statement.statementDetailDtos.length; i++) {
                var section = statement.statementDetailDtos[i];
                var programName = statementStorageSvc.getProgramName(section.programId, true);
                var errorSection = { title: programName, errors: [] };
                var isProgramInterimReview = statementStorageSvc.isProgramInterimReview(section.programId);
                // Maintain same order of finding types as in statement tool
                section.statementJson = $filter('orderBy')(section.statementJson, ['orderNumber', 'statementFindingTypeId']);
                for (var j = 0; j < section.statementJson.length; j++) {
                    var findingType = section.statementJson[j];
                    if (findingType.statementFindingTypeId === statementFindingTypes.INSTITUTIONINTRODUCTION ||
                        findingType.statementFindingTypeId === statementFindingTypes.INSTITUTIONAFTERVISIT ||
                        findingType.statementFindingTypeId === statementFindingTypes.REVIEWTEAM ||
                        findingType.statementFindingTypeId === statementFindingTypes.INSTITUTIONSUMMARY) continue;
                    // Maintain same order of findings as in statement tool
                    findingType.findings = $filter('orderBy')(findingType.findings, 'criteria.criteriaId');
                    for (var k = 0; k < findingType.findings.length; k++) {
                        var finding = findingType.findings[k];

                        if (!finding.criteria) continue;
                        if (isProgramInterimReview &&
                            statementStorageSvc.isShortcoming(findingType.statementFindingTypeId) &&
                            finding.criteria.status.isNormalFindingForIR !== true) {

                            validateLastVisitText(errorSection, programName, findingType, finding);
                            validateProgressText(errorSection, programName, findingType, finding);
                            if (statement.statementTypeId !== statementTypeIds.COMMISSIONEDITING)
                                validateResponseStatus(errorSection, programName, findingType, finding);
                        } else {
                            validateFindingText(errorSection, programName, findingType, finding, k);
                        }
                        validateSevenDayResponseText(errorSection, programName, findingType, finding, k);
                        validateThirtyDayResponseText(errorSection, programName, findingType, finding, k);
                        validatePostThirtyDayResponseText(errorSection, programName, findingType, finding, k);
                    }
                }
                var programReview = helperSvc.getFirstMatch(programReviewSvc.data.programs, 'programId', section.programId);
                if (programReview) {
                    // If this is a termination review then skip recommended actions
                    if (programReview.programReviewTypeCode === programReviewTypeIds.TERMINATIONREPORT || programReview.programReviewTypeCode === programReviewTypeIds.TERMINATIONVISIT) {
                        validateTerminationDate(errorSection, programName, section);
                    } else {
                        validatePEVRecommendedAction(errorSection, programName, statement, section);                      
                        validateRecommendedAction(errorSection, programName, statement, section);
                        validateStartDate(errorSection, programName, programReview, section);
                    }
                }
                // Add to collection if any errors were found for this section
                if (errorSection.errors.length > 0 && !statement.noChangeOption) errorSections.push(errorSection);
            }
            // Return any error groups found.
            return errorSections;
        };

        function isTextEmpty(text) {
            // markup is added whenever anything is entered, even if all the text was deleted
            return !text || text === '' || text === '<p>&nbsp;</p>' || text === '<p>Click to add text...</p>';
        }

        function validatePAFUploaded(errorSections) {
            // Verify PAF uploaded before allowing submission.
            if (userReviewSvc.data.currentReviewTeam.reviewDto.reviewYear < 2021 && // Program audit tool used instead of upload 2020-21 and later
                userReviewSvc.data.currentUserReviewTeam.reviewTypeCode != reviewTypeIds.INTERIMREPORT &&
                evaluatorReportSvc.data.evaluatorReport &&
                evaluatorReportSvc.data.evaluatorReport.stream_id == null) {
                errorSections.push({
                    title: 'Review Info', errors: [
                        {
                            type: 'PAF',
                            message: 'PAF document has not been uploaded',
                            slug: userReviewSvc.slugs.STATEMENT,
                            shortMessage: 'PAF document has not been uploaded',
                        }
                    ]
                });
            }
        }

        function validateLastVisitText(errorSection, programName, findingType, finding) {
            // Validate interim review last visit text
            if (finding.criteria && isTextEmpty(finding.criteria.lastVisitText)) {
                var message = 'Last visit finding text missing for finding(s) in finding type ' + findingType.statementFindingTypeName + ' for ' + programName;
                var shortMessage = 'Text missing for ' + findingType.statementFindingTypeName + ' in ' + finding.criteria.criteriaName;
                errorSection.errors.push({ type: 'IR', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        function validateProgressText(errorSection, programName, findingType, finding) {
            // Validate interim review progress text
            if (finding.criteria && isTextEmpty(finding.criteria.text)) {
                var message = 'Progress since last review text missing for finding(s) in finding type ' + findingType.statementFindingTypeName + ' for ' + programName;
                var shortMessage = 'Progress since last review missing for ' + findingType.statementFindingTypeName + ' in ' + finding.criteria.criteriaName;
                errorSection.errors.push({ type: 'IR', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        function validateResponseStatus(errorSection, programName, findingType, finding) {
            // Validate interim review response status
            if (finding.criteria && finding.criteria.response && finding.criteria.response.interimStatus && !finding.criteria.response.interimStatus.findingStatusTypeId) {
                var message = 'Interim response status not selected for finding(s) in finding type ' + findingType.statementFindingTypeName + ' for ' + programName;
                var shortMessage = 'Interim response status not selected for ' + findingType.statementFindingTypeName + ' in ' + finding.criteria.criteriaName;
                errorSection.errors.push({ type: 'IR', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        function validateSevenDayResponseText(errorSection, programName, findingType, finding, findingIndex) {
            if (finding.criteria &&
                finding.criteria.response &&
                finding.criteria.response.sevenDay &&
                isTextEmpty(finding.criteria.response.sevenDay.text)) {

                var message = '7-day response text is missing for finding(s) in finding type ' + findingType.statementFindingTypeName;
                var shortMessage = '7-day response missing for ' + findingType.statementFindingTypeName;
                if (finding.criteria.criteriaName) {
                    message += ' in ' + finding.criteria.criteriaName;
                    shortMessage += ' in ' + finding.criteria.criteriaName;
                } else if (findingType.findings.length > 1) {
                    message += ' (' + (findingIndex + 1) + ')';
                    shortMessage += ' (' + (findingIndex + 1) + ')';
                }
                message += ' for ' + programName;
                errorSection.errors.push({
                    type: '7DAY',
                    slug: userReviewSvc.slugs.STATEMENT,
                    message: message,
                    shortMessage: shortMessage
                });
            }
        }

        function validateThirtyDayResponseText(errorSection, programName, findingType, finding, findingIndex) {
            var message = '';
            var shortMessage = '';
            if (finding.criteria && finding.criteria.response && finding.criteria.response.thirtyDay) {
                if (finding.criteria.response.thirtyDay && isTextEmpty(finding.criteria.response.thirtyDay.text)) {
                    message = '30-day due-process response text is missing for finding(s) in finding type' + findingType.statementFindingTypeName;
                    shortMessage = '30-day due process response missing for ' + findingType.statementFindingTypeName;
                    if (finding.criteria.criteriaName) {
                        message += ' in ' + finding.criteria.criteriaName;
                        shortMessage += ' in ' + finding.criteria.criteriaName;
                    } else if (findingType.findings.length > 1) {
                        message += ' (' + (findingIndex + 1) + ')';
                        shortMessage += ' (' + (findingIndex + 1) + ')';
                    }
                    message += ' for ' + programName;
                    errorSection.errors.push({
                        type: '30DAY',
                        slug: userReviewSvc.slugs.STATEMENT,
                        message: message,
                        shortMessage: shortMessage
                    });
                }
                if (finding.criteria.response.thirtyDay &&
                    finding.criteria.response.thirtyDay.nextReviewText &&
                    /Click to add text/.test(finding.criteria.response.thirtyDay.nextReviewText)) {
                    message = 'Notes for next review text is missing for finding(s) in finding type' + findingType.statementFindingTypeName;
                    shortMessage = 'Notes for next review missing for ' + findingType.statementFindingTypeName;
                    if (finding.criteria.criteriaName) {
                        message += ' in ' + finding.criteria.criteriaName;
                        shortMessage += ' in ' + finding.criteria.criteriaName;
                    } else if (findingType.findings.length > 1) {
                        message += ' (' + (findingIndex + 1) + ')';
                        shortMessage += ' (' + (findingIndex + 1) + ')';
                    }
                    message += ' for ' + programName;
                    errorSection.errors.push({
                        type: '30DAY',
                        slug: userReviewSvc.slugs.STATEMENT,
                        message: message,
                        shortMessage: shortMessage
                    });
                }
            }
        }

        function validatePostThirtyDayResponseText(errorSection, programName, findingType, finding, findingIndex) {
            var message = '';
            var shortMessage = '';
            if (finding.criteria && finding.criteria.response && finding.criteria.response.postThirtyDay) {
                if (finding.criteria.response.postThirtyDay && isTextEmpty(finding.criteria.response.postThirtyDay.text)) {
                    message = 'Post-30-day due-process response text is missing for finding(s) in finding type' + findingType.statementFindingTypeName;
                    shortMessage = 'Post-30-day due process response missing for ' + findingType.statementFindingTypeName;
                    if (finding.criteria.criteriaName) {
                        message += ' in ' + finding.criteria.criteriaName;
                        shortMessage += ' in ' + finding.criteria.criteriaName;
                    } else if (findingType.findings.length > 1) {
                        message += ' (' + (findingIndex + 1) + ')';
                        shortMessage += ' (' + (findingIndex + 1) + ')';
                    }
                    message += ' for ' + programName;
                    errorSection.errors.push({
                        type: 'POST30DAY',
                        slug: userReviewSvc.slugs.STATEMENT,
                        message: message,
                        shortMessage: shortMessage
                    });
                }
                if (finding.criteria.response.postThirtyDay &&
                    finding.criteria.response.postThirtyDay.nextReviewText &&
                    /Click to add text/.test(finding.criteria.response.postThirtyDay.nextReviewText)) {
                    message = 'Notes for next review text is missing for finding(s) in finding type' + findingType.statementFindingTypeName;
                    shortMessage = 'Notes for next review missing for ' + findingType.statementFindingTypeName;
                    if (finding.criteria.criteriaName) {
                        message += ' in ' + finding.criteria.criteriaName;
                        shortMessage += ' in ' + finding.criteria.criteriaName;
                    } else if (findingType.findings.length > 1) {
                        message += ' (' + (findingIndex + 1) + ')';
                        shortMessage += ' (' + (findingIndex + 1) + ')';
                    }
                    message += ' for ' + programName;
                    errorSection.errors.push({
                        type: 'POST30DAY',
                        slug: userReviewSvc.slugs.STATEMENT,
                        message: message,
                        shortMessage: shortMessage
                    });
                }
            }
        }

        function validateFindingText(errorSection, programName, findingType, finding, findingIndex) {
            // Validate finding text
            if (finding.criteria && isTextEmpty(finding.criteria.text)) {
                var message = 'Finding text missing for finding(s) in finding type ' + findingType.statementFindingTypeName;
                var shortMessage = 'Text missing for ' + findingType.statementFindingTypeName;
                if (finding.criteria.criteriaName) {
                    message += ' in ' + finding.criteria.criteriaName;
                    shortMessage += ' in ' + finding.criteria.criteriaName;
                } else if (findingType.findings.length > 1) {
                    message += ' (' + (findingIndex + 1) + ')';
                    shortMessage += ' (' + (findingIndex + 1) + ')';
                }
                message += ' for ' + programName;
                errorSection.errors.push({ type: 'FT', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        function validateTerminationDate(errorSection, programName, section) {
            // Validate accreditation end date for termination reviews
            if (!section.terminationDate) {
                var message = 'Recommended accreditation end date is missing for  ' + programName;
                var shortMessage = 'Recommended accreditation end date is missing';
                errorSection.errors.push({ type: 'TERM', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        function validatePEVRecommendedAction(errorSection, programName, statement, section) {
            // Validate PEV recommended action
            if (statement.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR && evaluatorReportSvc.data.evaluatorReport && evaluatorReportSvc.data.evaluatorReport.evaluatorReportDetailDtos && evaluatorReportSvc.data.evaluatorReport.evaluatorReportDetailDtos.length > 0) {
                var reportDetail = evaluatorReportSvc.data.evaluatorReport.evaluatorReportDetailDtos.find(function (detailDto) {
                    return section.programId === detailDto.programId;
                });
                if (reportDetail && reportDetail.recommendedAction === null) {
                    var message = 'PEV recommended action is missing for ' + programName;
                    var shortMessage = 'PEV recommended action is missing';
                    errorSection.errors.push({ type: 'PEVRA', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
                }
            }
        }

        function validateRecommendedAction(errorSection, programName, statement, section) {
            // Validate recommended action
            if (!section.recommendedAction) {
                var message = 'Recommended action is missing for ' + programName;
                var shortMessage = 'Recommended action is missing';
                errorSection.errors.push({ type: 'RA', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        function validateStartDate(errorSection, programName, programReview, section) {
            // Validate accreditation start date for new programs
            if (programReview.programReviewTypeCode === programReviewTypeIds.INITIALACCREDIATION && section.recommendedAction && section.recommendedAction !== actionCodes.NA && !section.newProgramStartDate) {
                var message = 'Recommended accreditation start date is missing for  ' + programName;
                var shortMessage = 'Recommended accreditation start date is missing';
                errorSection.errors.push({ type: 'START', message: message, slug: userReviewSvc.slugs.STATEMENT, shortMessage: shortMessage });
            }
        }

        factory.populateWarnings = function (navigation, isAdmin) {
            factory.validate(statementStorageSvc.data.statement).then(function (errorSections) {
                var results = [];
                errorSections.forEach(function (errorSection) {
                    results.push.apply(results, errorSection.errors);
                });
                for (var i = 0; i < navigation.length; i++) {
                    navigation[i].warningMessages = [];
                }

                for (var i = 0; i < results.length; i++) {
                    var slug = results[i].slug;

                    for (var j = 0; j < navigation.length; j++) {
                        var tab = navigation[j];

                        if (tab.slug === slug) {
                            tab.warningMessages.push(results[i].message);
                        }
                    }
                }
            });
        };

        return {
            invokeValidation: factory.invokeValidation,
            listenToValidate: factory.listenToValidate,
            validate: factory.validate,
            populateWarnings: factory.populateWarnings,
            validateStatement: factory.validateStatement,
            validateStatementBySection: factory.validateStatementBySection,
            hasBeenInvoked: factory.hasBeenInvoked
        };
    };

    module.factory('statementValidationSvc', statementValidationSvc);

})(angular.module('statement'));