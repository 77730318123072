(function (module) {

    var pendingVerificationCtrl = function ($state, $uibModal, collegeDirectorySvc, alertSvc, barSvc) {
        var model = this;
        
        model.title = "Pending Institution Names";
        model.verifyTitle = "Verify Institution";
        model.filterTitle = "Filters";

        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.collegeDirectory = collegeDirectorySvc.data;

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });
        
        model.openCollegeDirectory = function () {
            $state.go('collegeDirectory');
        };

        model.openVerification = function (college) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/collegeDirectory/templates/verify.html',
                size: 'lg',
                controller: 'verifyCtrl',
                resolve: {
                    college: function () { return college; }
                }
            });
        };
        model.deletePendingVerification = function (college) {
            alertSvc.confirmDelete(college.name, deleteFunc);

            function deleteFunc() {
                collegeDirectorySvc.deleteVerificationPending(college).then(function (data) {
                    alertSvc.addAlertSuccess("Pending record successfully deleted.");
                });
            }
        };

        var activate = function () {
            model.resultMsg = null;
            barSvc.showSpinner();

            collegeDirectorySvc.clearDefaultFilters();

            collegeDirectorySvc.advancedSearch(collegeDirectorySvc.getDefaultFilters(), true).then(function () {
                collegeDirectorySvc.getPendingCount().then(function (data) {
                    // This number doesn't refresh when colleges are verified
                    model.pendingNum = data.result;
                    model.title = model.pendingNum + " " + model.title;
                    barSvc.hideSpinner();
                });
            });
        }();
        
    };

    module.controller('pendingVerificationCtrl', pendingVerificationCtrl);

}(angular.module('collegeDirectory')));