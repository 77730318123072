(function (module) {

    var organizationHistoryCtrl = function ($stateParams, $uibModal, organizationHistorySvc, eventSvc, $scope, $q, helperSvc) {
        var model = this;
        var parent = $scope.$parent.model;
        model.organizationId = $stateParams.organizationId;
        model.formatDate = helperSvc.formatDate;
        model.data = organizationHistorySvc.data;

        model.title = "Organization History";

        model.editOrganizationHistory = function (organizationId) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/organization/templates/organizationHistoryEdit.html',
                size: 'lg',
                controller: 'organizationHistoryEditCtrl',
                resolve: {
                    organizationId: function () {
                        return model.organizationId;
                    }
                }
            });
        };

    };

    module.controller('organizationHistoryCtrl', organizationHistoryCtrl);

}(angular.module('organization')));