(function (module) {

    var barSvc = function (eventSvc) {
        var factory = {};
        var showBarMessage = 'showBar';
        var hideBarMessage = 'hideBar';
        var showMessage = 'showSpinner';
        var hideMessage = 'hideSpinner';

        factory.showBar = function () {
            eventSvc.broadcast(showBarMessage);
        };

        factory.hideBar = function () {
            eventSvc.broadcast(hideBarMessage);
        };

        factory.showSpinner = function () {
            eventSvc.broadcast(showMessage);
        }

        factory.hideSpinner = function () {
            eventSvc.broadcast(hideMessage);
        }

        factory.listenForMainShow = function (callback) {
            eventSvc.listen(callback, showBarMessage);
        };

        factory.listenForMainHide = function (callback) {
            eventSvc.listen(callback, hideBarMessage);
        };

        factory.listenForShow = function (callback) {
            eventSvc.listen(callback, showMessage);
        };

        factory.listenForHide = function (callback) {
            eventSvc.listen(callback, hideMessage);
        };

        return {
            showBar: factory.showBar,
            hideBar: factory.hideBar,
            showSpinner: factory.showSpinner,
            hideSpinner: factory.hideSpinner,
            listenForMainShow: factory.listenForMainShow,
            listenForMainHide: factory.listenForMainHide,
            listenForShow: factory.listenForShow,
            listenForHide: factory.listenForHide
        };
    };
    module.service('barSvc', barSvc);

})(angular.module('common'));