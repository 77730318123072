(function (module) {
    'use strict';

    var personSvc = function ($http, personMdl, $q, $odataresource, $odata, codeSvc, odataSvc, amsConst, helperSvc, $uibModal, alertSvc, personUserSvc) {

        var apiPath = amsConst.webApiUrl + '/odata/Person({0})';
        var personApi = '/Person';
        var searchApi = '/GetPersonSearch';
        var keyName = 'personId';
        var factory = {};

        factory.data = {
            person: null
        };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.get = function (id) {
            id = parseInt(id);
            
            var oSvc = odataSvc.get();
            oSvc.getSource(personApi, keyName)
                .odata()
                .get(id)
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        //fetch a personMdl by personId
        //data can be placed on model argument if present otherwise data is returned and that can be used instead
        factory.getPersonMdlById = function (id, model, excludeNullContactTypeIds) {
            var personData = {};

            if (model) {
                personData = model;
            }

            return factory.getPersonSummaryWithDesignation(id).then(function (data) {
                if (data) {
                    //convert to personMdl
                    var personMdlObj = new personMdl(data);

                    //copy properties from personMdlObj to personData
                    factory.copyPersonMdlData(personData, personMdlObj, excludeNullContactTypeIds);

                    return personMdlObj;
                }
            });
        };

        //copy personMdl properties into destinationObj
        factory.copyPersonMdlData = function (destinationObj, personMdlObj, excludeNullContactTypeIds) {
            destinationObj.title = personMdlObj.getFullName();
            destinationObj.person = personMdlObj.data;
            destinationObj.fullName = personMdlObj.getFullName();
            destinationObj.userName = personMdlObj.getUserName();
            destinationObj.getAddressText = personMdlObj.getAddressText;
            destinationObj.addresses = personMdlObj.getAddresses(excludeNullContactTypeIds);
            destinationObj.telephones = personMdlObj.getTelephones(excludeNullContactTypeIds);
            destinationObj.emailAddresses = personMdlObj.getEmailAddresses(excludeNullContactTypeIds);
        };

        factory.create = function (person) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(personApi, keyName, person);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;

            //return $http.post(apiPath.format(null), person).then(onSuccess, onFailure);
        };

        factory.update = function (person) {
            var oSvc = odataSvc.get();

            person.$update(null, oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;

            //return $http.put(apiPath.format(person.personId), person).then(onSuccess, onFailure);

        };

        factory.delete = function (id) {
            var oSvc = odataSvc.get();

            if (confirm("Are you sure you want to delete this record?")) {

                var resource = oSvc.instantiate(personApi, keyName, { 'personId': id });
                resource.$delete(oSvc.onSuccess, oSvc.onFailure);
                return oSvc.getDeferred().promise;

                //return $http.delete(apiPath.format(null), person).then(onSuccess, onFailure);

            }
            else {
                return $q.reject('user cancelled');
            }
        };

        factory.advancedSearch = function (searchOptions) {
            var oSvc = odataSvc.get();

            if (searchOptions) {

                var predicates = [];
                var combinedPredicate;

                if (searchOptions.firstName) {
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'firstName', searchOptions.firstName)), '>', -1));
                }
                if (searchOptions.lastName) {
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'lastName', searchOptions.lastName)), '>', -1));
                }
                if (searchOptions.emailAddress) {
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'emailAddress', searchOptions.emailAddress)), '>', -1));
                }
                if (searchOptions.telephoneNumber) {
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'telephoneNumber', searchOptions.telephoneNumber)), '>', -1));
                }
                if (searchOptions.userName) {
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'userName', searchOptions.userName)), '>', -1));
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(searchApi).odata()
                    .filter(combinedPredicate)
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }
            else {
                oSvc.getSource(searchApi).odata()
                    .filter()
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        factory.quickSearch = function (searchText, top) {
            var oSvc = odataSvc.get();
            var predicates = [];
            var combinedPredicate;
            var parts = searchText.split(' ');

            var personnameFuncs = [];
            var prefferedFuncs = [];

            for (var i = 0; i < parts.length; i++) {
                var partName = parts[i];

                var func1 = new $odata.Func('indexof', 'personName', partName);
                var func2 = new $odata.Func('indexof', 'preferredName', partName);

                var predicate1 = new $odata.Predicate(func1, '>', -1);
                var predicate2 = new $odata.Predicate(func2, '>', -1);

                predicates.push($odata.Predicate.or([predicate1, predicate2]));
            };

            if (predicates.length > 0) {
                combinedPredicate = $odata.Predicate.and(predicates);
            }

            oSvc.getSource(searchApi).odata()
                .filter(combinedPredicate)
                //.take(top ? 15: 100)  : Let server decide the max number
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getPersonSummary = function (id) {
            id = parseInt(id);

            var oSvc = odataSvc.get();

            oSvc.getSource(personApi, keyName).odata()
                .filter('personId', id)
                .expand('personEmailDtos')
                .expand('personTelephoneDtos')
                .expand('personAddressDtos')
                .expand('userPersonDtos')
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getPersonSummaryWithDesignation = function (id) {
            id = parseInt(id);
            var getPersonByIdAction = '/GetPersonById';
            var oSvc = odataSvc.get();
            var filter = 'personId=' + id;

            oSvc.getSource(getPersonByIdAction, keyName).odata()
                .expand('personEmailDtos')
                .expand('personTelephoneDtos')
                .expand('personAddressDtos')
                .expand('userPersonDtos')
                .get(filter)
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(data => {
                factory.data.person = data;
                return data;
            });
        };

        factory.getPersonNameByUserId = function (userId) {
            userId = parseInt(userId);

            var api = '/GetPersonNameByUserId';
            var oSvc = odataSvc.get();
            var filter = 'userId=' + userId;

            oSvc.getSource(api, keyName).odata()
                .get(filter)
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };


        factory.castToPersonMdl = function (people) {
            var peopleMdls = new Array();

            for (var i = 0; i < people.length; i++) {
                var person = new personMdl(people[i]);
                peopleMdls.push(person);
            }

            return peopleMdls;
        };

        factory.impersonate = function (user) {
            if (user) {
                if (!user.lockedOutTimestamp) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/security/templates/impersonate.html',
                        size: 'md',
                        controller: 'impersonateCtrl',
                        controllerAs: 'ctrl',
                        resolve: {
                            impersonateId: function () {
                                return user.userId;
                            },
                            impersonateUsername: function () {
                                return null;
                            }
                        }
                    });
                } else {
                    //user is locked out
                    alertSvc.addAlertWarning("User must be unlocked to impersonate.");
                }
            }
        };

        factory.impersonateVolunteer = function (volunteer) {
            personUserSvc.getUserByPersonId(volunteer.personId).then(function (user) {
                factory.impersonate(user);
            });
        };

        factory.publicSearch = function (person) {
            var api = '/webapi/odata/PublicPersonSearch';
            person.personId = 0;

            var data = { personSearchViewModel: person };

            return $http.post(api, data);
        }

        factory.publicInsert = function (person) {
            var api = '/webapi/odata/PublicPersonInsert';
            person.personId = 0;

            var data = { personSearchViewModel: person };

            return $http.post(api, data);
        }

        var codes = {};

        codeSvc.getNamePrefixes().then(function (data) {
            codes.namePrefixes = data.value;
        });

        codeSvc.getNameSuffixes().then(function (data) {
            codes.nameSuffixes = data.value;
        });

        codeSvc.getProfessionalSuffixes().then(function (data) {
            codes.professionalSuffixes = data.value;
        });

        factory.codes = codes;

        return {
            data: factory.data,
            get: factory.get,
            getPersonMdlById: factory.getPersonMdlById,
            getPersonIdByVolunteerId: factory.getPersonIdByVolunteerId,
            getSome: factory.getSome,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            codes: factory.codes,
            advancedSearch: factory.advancedSearch,
            quickSearch: factory.quickSearch,
            getPersonSummary: factory.getPersonSummary,
            getPersonSummaryWithDesignation: factory.getPersonSummaryWithDesignation,
            getPersonNameByUserId: factory.getPersonNameByUserId,
            castToPersonMdl: factory.castToPersonMdl,
            copyPersonMdlData: factory.copyPersonMdlData,
            impersonate: factory.impersonate,
            impersonateVolunteer: factory.impersonateVolunteer,
            publicSearch: factory.publicSearch,
            publicInsert: factory.publicInsert
        };
    };

    module.factory('personSvc', personSvc);

})(angular.module('common'));
