(function (module) {

    var npcDetailCtrl = function ($scope, $q, $state, $stateParams, oauth, helperSvc, tabSvc, currentUser, alertSvc, barSvc, npcSvc,eventSvc, organizationSvc, programSvc, teamMemberTypeNames, npcStatusIds ) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.npcId = parseInt($stateParams.npcId);
        model.showSpinner = false;
        model.organizationName = "";
        model.isAdmin = oauth.isAdmin();
        model.isAdjunct = oauth.isAdjunct();
        model.getLatestProgramStatuses = npcSvc.getLatestProgramStatuses;
        model.isInstitutionUser = $stateParams.isInstitutionUser;

        eventSvc.listen(
            () => {
                npcSvc.getNPCById(model.npcId).then(function (data) {
                    model.npcData = data;
                    model.setUserAccess();
                });
            },
            'refreshNpcData',
            $scope
        );

        model.openMasterInstructions = function () {
            var pdfPath = '/Content/files/NPC-TCAdjuntExcom-Instructions-03022022.pdf',
                title = 'NPC Instructions';

            if (model.isAdmin && !model.isAdjunct) {
                pdfPath = '/Content/files/NPC-Admin-Instructions-03022022.pdf';
                title = 'NPC Admin Instructions';
            } else if (model.isInstitutionUser) {
                pdfPath = '/Content/files/NPC-InstUser-Instructions-03022022.pdf';
                title = 'NPC Institution User Instructions';
            }

            alertSvc.openPDFModal(pdfPath, title);
        };

        model.setUserAccess = function () {
            //check if currentUser is excom
            var excomRoles = [300, 301, 302, 305, 306, 307, 308];
            model.usersReviewerProgramChanges = [];
            model.usersTCProgramChanges = [];
            model.isExcomMember = oauth.hasRole(model.npcData.commissionId, excomRoles);
            isReviewer()
            programChangeStatuses = model.getLatestProgramStatuses(model.npcData);
            setNavigation(programChangeStatuses)
        }

        var isReviewer = function () {
            if (model.npcData && model.npcData.npcProgramDtos && model.npcData.npcProgramDtos.length > 0) {
                for (var x = 0; x < model.npcData.npcProgramDtos.length; x++) {
                    var programDto = model.npcData.npcProgramDtos[x];

                    if (programDto && programDto.npcProgramChangeDtos && programDto.npcProgramChangeDtos.length > 0) {

                        for (var y = 0; y < programDto.npcProgramChangeDtos.length; y++) {
                            var programChange = programDto.npcProgramChangeDtos[y];

                            if (programChange.npcProgramChangeReviewerDtos && programChange.npcProgramChangeReviewerDtos.length > 0) {

                                var userNpcRoles = programChange.npcProgramChangeReviewerDtos.filter(t => t.personId == currentUser.profile.personId);

                                if (userNpcRoles && userNpcRoles.some(t => t.teamMemberTypeId === teamMemberTypeNames.NPCREVIEWER)) {
                                    model.currentUsersProgramChangeId = programChange.npcProgramChangeId
                                    model.isReviewer = true;
                                    model.usersReviewerProgramChanges = programChange.npcProgramChangeId
                                }

                                if (userNpcRoles && userNpcRoles.some(t => t.teamMemberTypeId === teamMemberTypeNames.NPCFVREVIEWER)) {
                                    model.currentUsersProgramChangeId = programChange.npcProgramChangeId
                                    model.isVisitReviewer = true;
                                    model.usersReviewerProgramChanges = programChange.npcProgramChangeId
                                }

                                if (userNpcRoles && userNpcRoles.some(t => t.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR)) {
                                    model.currentUsersProgramChangeId = programChange.npcProgramChangeId
                                    model.isVisitTeamchair = true;
                                    model.usersTCProgramChanges = programChange.npcProgramChangeId;
                                }
                            }
                        }
                    }
                }
            }
            
        }


        var path = 'apps/npc/templates/tabs/';


         function setNavigation (programChangeStatuses) {
            //default for IUs
            var finalActionAllowedStatuses = [npcStatusIds.SENTACKWITHOUTCOMMISSIONREVIEW, npcStatusIds.SENTFINALACTIONAFTERCOMMISSION, npcStatusIds.SENTFINALACTIONAFTERVISIT];
            var focusedVisitStatuses = [npcStatusIds.FOCUSEDVISIT, npcStatusIds.SENTFINALACTIONAFTERVISIT];
            var finalStatuses = programChangeStatuses['latest'];
            var allStatues = programChangeStatuses['all']

            model.isFinalActionLocked = !finalStatuses.some(r => finalActionAllowedStatuses.includes(r));

             model.includeFocusedVisitTab = allStatues.some(r => focusedVisitStatuses.includes(r));
             model.isAcceptedStatus = finalStatuses.some(r => r > npcStatusIds.NOTIFICATIONSUBMITTED);
            model.finalActionLockedMessage = "This tool is not available until the Final Action is submitted.";
             var isNotAcceptedLockMsg = "Tool is not avaialable until the NPC has been accepeted."

             model.navigation = [];

             if (model.isAdmin) {
                 model.navigation = [
                        tabSvc.createTab('Manage Status', 'status', path + 'npcManageStatusTab.html')
                     ]
             }

             if (model.navigation) {
                 model.navigation.push(tabSvc.createTab('Programs', 'programs', path + 'npcProgramTab.html'));
                 model.navigation.push(tabSvc.createTab('Previous Statement', 'statement', path + 'npcPreviousStatement.html'));
             }
             else {
                model.navigation = [
                    tabSvc.createTab('Programs', 'programs', path + 'npcProgramTab.html'),
                    tabSvc.createTab('Previous Statement', 'statement', path + 'npcPreviousStatement.html')
                ];
             }

             if (model.isAdmin || model.isAdjunct || model.isExcomMember || model.isVisitReviewer || model.isVisitTeamchair || model.isReviewer) {
                 model.navigation.push(tabSvc.createTab('Reviewers & Reports', 'reviewers', path + 'npcManageReviewersTab.html', !model.isAcceptedStatus, null, isNotAcceptedLockMsg));

                 if (model.includeFocusedVisitTab && (model.isAdmin || model.isAdjunct || model.isExcomMember || model.isVisitReviewer || model.isVisitTeamchair)) {
                     model.navigation.push(tabSvc.createTab('Focused Visit', 'focused', path + 'npcFocusedVisitTab.html'));
                 }
             } else {//for IU
                 model.navigation.splice(1, 1,
                     tabSvc.createTab('Final Action', 'action', path + 'npcFinalActionTab.html', model.isFinalActionLocked, null, model.finalActionLockedMessage)
                 );
             }

             if (model.isAdmin) {
                 model.navigation.push(tabSvc.createTab('Final Action', 'action', path + 'npcFinalActionTab.html', !model.isAcceptedStatus, null, isNotAcceptedLockMsg));
             }
        }

        var activate = function () {
            // Go back if organization isn't set (i.e. they bookmarked this page); might be better to include org in URL.
            model.organizationId = $stateParams.organizationId || model.parent?.selectedOrganizationId;
            if (!model.organizationId) {
                $state.go(model.isAdmin ? 'npc' : 'npcUser');
                return;
            }

            model.isDataReady = false;

            var dataArray = [];

            var npcDataSource = {
                dataHolder: model,
                dataLocationName: 'npcData',
                svcCallback: npcSvc.getNPCById,
                svcCallbackArguments: [model.npcId],
                odataResource: true,
            };
            dataArray.push(npcDataSource);

            if (model.parent.organizationData) {
                model.organizationData = model.parent.organizationData
            } else {
                var orgDataSource = {
                    dataHolder: model,
                    dataLocationName: 'organizationData',
                    svcCallback: organizationSvc.getOrgByIdOdata,
                    svcCallbackArguments: [model.organizationId],
                    odataResource: true,
                    optionalCallback: function () {
                        model.organizationName = model.organizationData.currentOrganizationDetailDto.organizationName;
                    }
                }
                dataArray.push(orgDataSource);
            }

            var programReviewsDataSource = {
                dataHolder: model,
                dataLocationName: 'programReviews',
                svcCallback: programSvc.searchProgramsByOrganizationId,
                svcCallbackArguments: [model.organizationId, isCurrent = true],
                helperCallback: helperSvc.getResults
            };
            dataArray.push(programReviewsDataSource);

            helperSvc.getData(dataArray).then(function () {
                model.setUserAccess();
                model.isDataReady = true;
                model.showSpinner = false;
            });

        }();
    };

    module.controller('npcDetailCtrl', npcDetailCtrl);

}(angular.module('npc')));