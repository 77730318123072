(function (module) {

    var rfrSearchCtrl = function ($scope, helperSvc, barSvc, typeConstSvc, rfrProcessStatuses, codeSvc, rfrAdminSvc) {
        var model = this;

        model.title = "Find an RFE";
        model.resultsTitle = "Results";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.showSpinner = false;
        model.showSpinnerSearch = false;
        model.firstLoad = true;


        model.formatEmptyObject = helperSvc.formatEmptyObject;

        barSvc.listenForShow(function () { 
            if (model.firstLoad) {
                model.showSpinnerSearch = true;
            }
            else{
                model.showSpinner = true;
            } 
        });
        barSvc.listenForHide(function () {
            if(model.firstLoad){
                model.showSpinnerSearch = false;
                model.firstLoad = false;
            }
            else{
                model.showSpinner = false; 
        }});

        activate();

        model.reset = function () {
            reset();
        }

        model.getCommissionName = function (commissionId) {
            return typeConstSvc.getCommissionAbbreviationById(commissionId);
        };

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.rfrs = null;
            model.resultMsg = null;
            barSvc.showSpinner();
            //replace full object with just the commissionId

            rfrAdminSvc.advancedSearch(searchOpts).then(function (data) {
                barSvc.hideSpinner();
                model.rfrs = data;

                if (model.rfrs.length === 0) {
                    model.resultMsg = "No results found";
                }
            });
        };

        function activate() {
            //Has to be model.rfrYears and then reset in that order to keep the current year as the default search option
            model.searchOptions = {};
            model.rfrYears = getYearSpans();
            reset();                    

            barSvc.showSpinner();

            var dataSourceArray = [
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getCommissions],
                    svcCallbackArguments: [],
                    dataLocationName: 'commissions',
                    helperCallback: helperSvc.getValue
                },
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getRfrProcessStatuses],
                    svcCallbackArguments: [],
                    dataLocationName: 'rfrStatuses',
                    helperCallback: helperSvc.getValue
                },
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getReviewTypes],
                    svcCallbackArguments: [],
                    dataLocationName: 'reviewTypes',
                    helperCallback: helperSvc.getValue
                },

            ];

            helperSvc.getData(dataSourceArray).then(function(){
                barSvc.hideSpinner();
            });

        }

        var softReset = function (message) {
            barSvc.hideSpinner();
            model.rfrs = null;
            model.resultMsg = message;
        };

        function reset() {
            model.rfrs = null;
            model.searchOptions = {};
            setInitialYearSearchOption();
            //initialize array for multiselect
            model.searchOptions.commissions = [];
            model.resultMsg = model.defaultSearchMsg;
        }

        function getYearSpans() {
            var startYear = 2016;   //rfrs started being tracked for this year, the cycle begins year 2018
            var currentYear = new Date().getFullYear();
            var numberOfYearsToAdd = 1 + currentYear - startYear;   //+1 because we need to loop atleast once for the year 2016
            var years = [];

            for (var i = 0; i < numberOfYearsToAdd ; i++) {
                //+2 because the year cycle begins in 2018
                years.push({ year: startYear + i + 2, yearSpan: helperSvc.getYearSpan(startYear + i + 2) });
            }
   
            setInitialYearSearchOption();
            return years;
        }

        function setInitialYearSearchOption() {
            //set start year, if current month is december then get next year, else this year. I.E.: its december 23, 2017, start on 2018
            var currentYear = new Date().getFullYear();
            var currentMonth = new Date().getMonth();
            model.searchOptions.reviewYear = currentMonth > 9 ? currentYear + 2 : currentYear + 1
        }

    };

    module.controller('rfrSearchCtrl', rfrSearchCtrl);

}(angular.module('rfr')));