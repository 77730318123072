(function (module) {

    var simultaneousVisitsCtrl = function ($scope, $uibModal, helperSvc) {
        var model = this;
        model.parent = $scope.$parent.model;
        model.title = 'Simultaneous Visits';
        model.noVisitsMsg = 'No simultaneous visits on record';

        model.editSimultaneousVisits = function (simultaneousVisits) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/review/templates/simultaneousVisitsEdit.html',
                size: 'lg',
                controller: 'simultaneousVisitsEditCtrl',
                resolve: {
                    review: function () {
                        return model.parent.selectedReview;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.isEmpty = helperSvc.isEmpty;
        model.formatDate = helperSvc.formatDate;

    };

    module.controller('simultaneousVisitsCtrl', simultaneousVisitsCtrl);

}(angular.module('review')));