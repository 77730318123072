(function (module) {
    var rrCtrl = function ($state, alertSvc, readinessSvc, helperSvc, readinessStatuses, commissionAbbreviations) {
        var model = this;

        model.title = 'Readiness Review';
        model.isDataReady = false;
        model.date = new Date();
        model.formatDate = helperSvc.formatDate;

        model.getCommissionAbbreviation = function (commissionIds) {
            var abbreviations = [];
            angular.forEach(commissionIds, function (commissionId) {
                abbreviations.push(commissionAbbreviations[commissionId]);
            });
            return abbreviations.join(', ');
        };

        model.openRR = function (review) {
            var id = review.readinessId;
            $state.go('rr.detail', {readinessId: id})
        };

        model.getSubmissionDate = function (trackings) {
            var match = trackings.filter(function (el) { return el.readinessStatusId == readinessStatuses.REVIEW_IN_PROGRESS; });
            
            if (match.length == 0)
                return null;

            return match[0].commitDate;
        };

        var activate = function () {
            readinessSvc.getReadinessByPerson().then(function () {
                model.data = readinessSvc.data;
                model.isDataReady = true;
            })         
        }();
    };

    module.controller('rrCtrl', rrCtrl);

}(angular.module('root')));