(function (module) {

    var programEditCtrl = function (program, organization, $scope, $uibModalInstance, codeSvc, programSvc, helperSvc, eventSvc, programMdl, alertSvc, commissionIds) {
        var model = $scope;
        var otherOption = { codeKey: 'Other', codeName: '[Create New Degree]' };

        model.organization = organization;
        model.currentProgram = { programId: 0 };
        model.currentProgram.programDetailDto = {};

        model.commissionIds = commissionIds;

        if (program) {
            var useUndefined = true;
            var useDto = true;
            model.title = 'Edit Program Information';
            model.currentProgram = new programMdl(program, useDto);
            model.currentProgram.programDetailDto.startDate = helperSvc.formatDate(model.currentProgram.programDetailDto.startDate, useUndefined);
            model.currentProgram.programDetailDto.endDate = helperSvc.formatDate(model.currentProgram.programDetailDto.endDate, useUndefined);
            model.currentProgram.retroactiveDate = helperSvc.formatDate(model.currentProgram.retroactiveDate, useUndefined);
        }
        else {
            model.title = 'Add Program Information';
            model.currentProgram.programDetailDto.isCurrent = true;
            model.currentProgram.organizationId = model.organization.organizationId;
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            // Remove degreeLevelName from submitted data
            delete model.currentProgram.programDetailDto.degreeLevelName;
            // Perform update or create.
            if (model.currentProgram.programId !== 0) {
                programSvc.update(model.currentProgram).then(function () {
                    broadcastRefreshMessage();
                    model.cancel();
                    alertSvc.addAlertSuccess("Program successfully updated.");
                });
            }
            else {
                programSvc.create(model.currentProgram).then(function () {
                    broadcastRefreshMessage();
                    model.cancel();
                    alertSvc.addAlertSuccess("Program successfully created.");
                });
            }
        };

        function broadcastRefreshMessage() {
            eventSvc.broadcast('refreshPrograms');
        };

        model.formatDate = helperSvc.formatDate;

        activate();

        function activate() {
            codeSvc.getDegreeLevels().then(function (data) {
                model.degreeLevels = helperSvc.getValue(data);
            });

            codeSvc.getCommissions().then(function (data) {
                model.commissions = helperSvc.getResults(data);
                helperSvc.keysToInt(model.commissions);
            });
        };
    };

    module.controller('programEditCtrl', programEditCtrl);

}(angular.module('program')));