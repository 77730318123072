(function (module) {
    var errorRedirectCtrl = function ($stateParams) {

        var model = this;

        model.errorCode = $stateParams.errorCode;
        model.errorMessage = $stateParams.errorMessage;
    };

    module.controller('errorRedirectCtrl', errorRedirectCtrl);

}(angular.module('common')));