(function (module) {

    var evaluationCtrl = function (pevc, $scope, oauth, helperSvc, $uibModalInstance, alertSvc, trainingSvc) {
        var model = $scope;

        model.title = 'Evaluation';
        model.subtitle = '{0} {1}{2}'.format(pevc.firstName, (pevc.middleName ? pevc.middleName + ' ' : ''), pevc.lastName);
        model.pevc = pevc;

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('evaluationCtrl', evaluationCtrl);

}(angular.module('training')));