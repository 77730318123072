(function (module) {

    var educationInfoSvc = function (odataServiceFactory) {
        var config = {
            apiPath: '/VolunteerEducation',
            keyName: 'volunteerEducationId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'educationInfo',
            getByForeignKeyPath: '/GetVolunteerEducationByVolunteerId',
            expandables: ['collegeDirectoryDto','collegeVerificationDto']
        };

        var factory = new odataServiceFactory(config);

        return {
            data: factory.data,
            getByVolunteerId: factory.getByForeignKey,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('educationInfoSvc', educationInfoSvc);

})(angular.module('volunteer'));