(function (module) {

    var pevStatusEditCtrl = function ($scope, $uibModalInstance, codeSvc, pevAppSvc, helperSvc, alertSvc, pevApplicationSocietyStatuses, pevDisciplineStatuses, pevApplicationDisciplineStatusName, commissionTypes) {
        var model = $scope;

        model.title = 'Edit Status';
        model.volunteerDiscipline = angular.copy(pevAppSvc.volunteerDisciplineData);
        model.isAdmin = pevAppSvc.isAdmin();
        model.changedSocietyStatus = null;
        model.editMode = false;
        model.pevApplicationSocietyStatuses = pevApplicationSocietyStatuses;
        model.isApprovedSelected = false;
        model.edit = function (society) {
            model.selectedSociety = angular.copy(society);
            model.selectedDisciplines = angular.copy(helperSvc.getFilteredArray(model.volunteerDiscipline.volunteerProgramAreas.disciplines, 'societyId', society.societyId, true));
            model.editMode = true;
            model.societyStatuses = getStatusOptionsByLastStatus(society.volunteerApplicationSocietyStatusId);

            if (model.possibleCommissions.length == 1) model.selectedSociety.trainingCommissionId = model.possibleCommissions[0].id;
        }

        model.setSocietyStatus = function(society){
            society.volunteerApplicationSocietyStatusName = getSocietyStatusName(society.volunteerApplicationSocietyStatusId);
        }

        model.getDisciplineStatusName = function (statusId) {
            return pevApplicationDisciplineStatusName.find(function (status) {
                return status.statusId === statusId;
            }).statusName;
        };

        model.setDisciplineStatus = function (discipline) {
            discipline.volunteerDisciplineStatusName = model.getDisciplineStatusName(discipline.volunteerDisciplineStatusId);           
        }

        model.setTrainingCommission = function(society){
            model.selectedSociety.trainingCommissionName = getCommissionName(society.trainingCommissionId);
        }

        function getStatusOptionsByLastStatus(statusId){
            if(!model.isAdmin){
                if(statusId === 1){
                    return [model.societyStatusOptions[1], model.societyStatusOptions[4], model.societyStatusOptions[8]];
                }else if(statusId = 2){
                    return [model.societyStatusOptions[1], model.societyStatusOptions[4]];
                }else if(statusId === 5){
                    return [model.societyStatusOptions[0], model.societyStatusOptions[1], model.societyStatusOptions[5], model.societyStatusOptions[4]];
                }
            }else{
                return model.societyStatusOptions;
            }
        }

        model.save = function () {
            if (validationErrors(model.selectedSociety, model.selectedDisciplines)) {
                return;
            }

            var societyIndex = getSocietyIndex(model.selectedSociety.societyId)
            model.volunteerDiscipline.volunteerProgramAreas.societies[societyIndex] = model.selectedSociety;

            angular.forEach(model.selectedDisciplines, function (discipline) {
                var index = getDisciplineIndex(discipline.disciplineId, discipline.societyId);
                model.volunteerDiscipline.volunteerProgramAreas.disciplines[index] = discipline;
            })

            var application = angular.copy(pevAppSvc.data.application);    
            application.applicationJson.volunteerDiscipline = model.volunteerDiscipline.volunteerProgramAreas;

            pevAppSvc.applicationApprovalChange(application, model.selectedSociety.societyId, model.selectedSociety.volunteerApplicationSocietyStatusId).then(onSuccess);
            function onSuccess(data){
                if(data === "Contact Abet"){
                    model.editMode = false;
                    model.volunteerDiscipline = angular.copy(pevAppSvc.volunteerDisciplineData)
                    alertSvc.addAlertSuccess("Not Allowed: Please contact ABET");
                }else{
                    model.editMode = false;
                    alertSvc.addAlertSuccess("Society status successfuly saved.");
                }         
            }
        };

        model.cancel = function () {
            model.editMode = false;
            model.selectedSociety = null;
        };

        model.close = function () {
           $uibModalInstance.close();
        };

        var societyStatusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'societyStatusOptions',
            svcCallback: codeSvc.getVolunteerAppSocietyStatuses,
            helperCallback: helperSvc.getResults
        };

        var programAreaStatusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'programAreaStatuses',
            svcCallback: codeSvc.getVolunteerDisciplineStatuses,
            helperCallback: helperSvc.getResults
        };

        var activate = function () {
            var dataSourceArray = [
                societyStatusOptionsDataSource,
                programAreaStatusOptionsDataSource
            ];

            helperSvc.getData(dataSourceArray).then(function () {            
                angular.forEach(model.societyStatusOptions, function (option) {
                    option.codeKey = parseInt(option.codeKey);
                });
                var discplinesByCommission = helperSvc.uniqueArrayByKey(model.volunteerDiscipline.volunteerProgramAreas.disciplines, 'commissionId');
                var commissionIds = discplinesByCommission.map(function (disc) { return disc.commissionId });
                model.possibleCommissions = helperSvc.getFilteredArray(commissionTypes, 'id', commissionIds, true);
            });
        }();

        function validationErrors(society, disciplines) {
            var noneApproved = true;

            if(society.volunteerApplicationSocietyStatusId !== pevApplicationSocietyStatuses.WAITING_FOR_SOCIETY_APPROVAL){
                for (var i = 0; i < disciplines.length; i++) {

                    //incorrect codes are commented.
                    //if(disciplines[i].volunteerDisciplineStatusId === pevDisciplineStatuses.PENDING){ //this is going to act like approved now...
                    //    noneApproved = true;
                    //}

                    if (disciplines[i].volunteerDisciplineStatusId === pevDisciplineStatuses.APPROVED) {
                        noneApproved = false;
                    }
                }
                if (society.volunteerApplicationSocietyStatusId === pevApplicationSocietyStatuses.APPROVED_FOR_TRAINING && noneApproved) {
                    alertSvc.addAlertWarning('Need at least one approved status for a program area in this society.');
                    return true;
                }
            }

            return false;
        }

        function getDisciplineIndex(disciplineId, societyId) {
            return model.volunteerDiscipline.volunteerProgramAreas.disciplines.findIndex(function (discipline) {
                return discipline.societyId === societyId && discipline.disciplineId === disciplineId;
            });
        }

        function getSocietyIndex(societyId) {
            return model.volunteerDiscipline.volunteerProgramAreas.societies.findIndex(function (society) {
                return society.societyId === societyId;
            });
        }

        function getCommissionName(commissionId){
            if(commissionId){
                return model.possibleCommissions.find(function(commission){
                    return commission.id === commissionId
                }).abrv;
            }
        }

        function getSocietyStatusName(statusId) {
            return model.societyStatusOptions.find(function (status) {
                return status.codeKey === statusId;
            }).codeName;
        }
    };

    module.controller('pevStatusEditCtrl', pevStatusEditCtrl);

}(angular.module('pevApp')));