(function(module) {

    var currentOrganizationHelper = function (currentUser, contactRoleTypes, membershipSvc, $q, helperSvc, currentOrganization, oauth) {

        var loadDelegatedCommissions = function (organizationId) {
            if (!oauth.hasRole(organizationId, contactRoleTypes.DELEGATEFORPRIMARY)) {
                // return empty promise
                var data = [];
                var deferred = $q.defer();
                deferred.resolve(data);
                return deferred.promise;
            }

            var userRoles = currentUser.profile.userRoles;
            var delegateCommissions = [];
            var promises = [];

            for (var i = 0; i < userRoles.length; i++) {
                var role = userRoles[i];

                if (role.roleId === contactRoleTypes.DELEGATEFORPRIMARY) {
                    if (role.oAuthOrganizationUserDesignations && role.oAuthOrganizationUserDesignations.length > 0) {
                        var personId = role.oAuthOrganizationUserDesignations[0].designationId;

                        var promise = membershipSvc.getByOrganizationId(organizationId, true, personId);

                        promises.push(promise);
                    }
                }
            }

            var queuedPromise = $q.all(promises);

            queuedPromise.then(function (data) {
                for (var i = 0; i < data.length; i++) {
                    var orgUsers = helperSvc.getResults(data[i]);


                    if (orgUsers) {
                        for (var j = 0; j < orgUsers.length; j++) {
                            var orgUser = orgUsers[j];

                            if (orgUser.personId === personId && orgUser.roleId === contactRoleTypes.PRIMARY && orgUser.designationViewModels && orgUser.designationViewModels.length > 0) {
                                for (var k = 0; k < orgUser.designationViewModels.length; k++) {
                                    var designation = orgUser.designationViewModels[k];

                                    if (!helperSvc.arrContains(delegateCommissions, designation.designationId)) {
                                        delegateCommissions.push(designation.designationId);
                                    }
                                }
                            }
                        }
                    }
                }

                currentOrganization.profile.delegateCommissions = delegateCommissions;
            });

            return queuedPromise;
        };

        return {
            loadDelegatedCommissions: loadDelegatedCommissions
        };
    };

    module.factory("currentOrganizationHelper", currentOrganizationHelper);

}(angular.module("security")));