(function (module) {

    var statementTemplateSvc = function (odataSvc, localStorage, helperSvc, statementStorageSvc, statementFindingTypes, statementFindingTypeNames, teamMemberTypeNames) {
       var PROGRAMCATEGORYTYPEID = 2;
       var INTROFINDINGTYPEID = 1;
       var INSTITUTIONFINDINGTYPECATEGORY = 1;
       var factory = {
           data: { programSectionsTemplate: [], institutionSectionsTemplate: [], responseTemplate: {findingStatusTypeId: null, updatedFindingTypeId: null, updatedFindingTypeName: null, text: null} }
       };
     
       factory.checkForCriteriaNeeded = function(finding) {
           return finding.statementFindingTypeId === statementFindingTypes.PROGRAMDEFICIENCY || finding.statementFindingTypeId === statementFindingTypes.PROGRAMWEAKNESS || finding.statementFindingTypeId === statementFindingTypes.PROGRAMCONCERN;
       }

       factory.setStatementFindingTypeTemplates = function () {
           //check here if the template already exists
           if (factory.data.programSectionsTemplate.length === 0) {
               if (statementStorageSvc.codes && statementStorageSvc.codes.statementFindingTypes) {

                   angular.forEach(statementStorageSvc.codes.statementFindingTypes, function (type) {

                       if (type.statementFindingTypeId === statementFindingTypes.INSTITUTIONINTRODUCTION
                           || type.statementFindingTypeId === statementFindingTypes.INSTITUTIONAFTERVISIT
                           || type.statementFindingTypeId === statementFindingTypes.INSTITUTIONSUMMARY
                           || type.statementFindingTypeId === statementFindingTypes.PROGRAMINTRODUCTION
                           || type.statementFindingTypeId === statementFindingTypes.PROGRAMSUMMARY
                           || type.statementFindingTypeId === statementFindingTypes.TERMINATIONPLAN
                           || type.statementFindingTypeId === statementFindingTypes.REVIEWTEAM
                          )
                           return;

                       var newType = {
                           statementCategoryId: type.statementCategoryId,
                           statementFindingTypeId: type.statementFindingTypeId,
                           statementFindingTypeName: type.typeName,
                           orderNumber: type.orderNumber,
                           value: false,
                           number: 0
                       };

                       if (type.statementCategoryId === INSTITUTIONFINDINGTYPECATEGORY) {
                           factory.data.institutionSectionsTemplate.push(newType);
                       }
                       else {
                           newType.criteria = factory.checkForCriteriaNeeded(type) ? [] : null;
                           factory.data.programSectionsTemplate.push(newType);
                       }
                   });
               }
           }
       }

       factory.addFinding = function (section, findings, interimReport) {
           angular.forEach(findings, function (finding) {
               if (finding.value === true) {
                   var existingFinding = helperSvc.getFirstMatch(section.statementJson, 'statementFindingTypeId', finding.statementFindingTypeId)


                   if (existingFinding === null) {
                       var formattedFinding = {
                           statementFindingTypeId: finding.statementFindingTypeId,
                           statementFindingTypeName: finding.statementFindingTypeName,
                           orderNumber: finding.orderNumber,
                           findings: []
                       }
                       factory.addFindings(formattedFinding, finding, interimReport);
                       section.statementJson.push(formattedFinding);
                   } else {
                       var findingIndex = statementStorageSvc.getFindingIndex(section.statementJson, finding.statementFindingTypeId);
                       factory.addFindings(existingFinding, finding, interimReport);

                       section.statementJson[findingIndex] = existingFinding;
                   }

                   if (factory.checkForCriteriaNeeded(finding)) {
                       section.recommendedAction = null;
                       if (section.statementJson && section.statementJson.length && statementStorageSvc.data.statement.teamMemberTypeId >= teamMemberTypeNames.ADJUNCT)
                           section.statementJson[0].isRecommendedActionEditableByAdjunct = true;
                   }
               }
           });

           return section;
       }

        factory.removeFinding = function (section, findingType, finding) {
            var findingTypeIndex = statementStorageSvc.getFindingIndex(section.statementJson, findingType.statementFindingTypeId);
            var findingIndex = statementStorageSvc.getFindingIndexFromFindingType(findingType, finding)

            if (findingTypeIndex === null || findingIndex === null) return; // couldn't find the thing we need to remove?

            if (findingType.findings.length === 1) {       
                section.statementJson.splice(findingTypeIndex, 1);
            }
            else {
                section.statementJson[findingTypeIndex].findings.splice(findingIndex, 1);
            }

        }

        factory.addFindings = function (findingType, finding, interimReport) {
           if (factory.checkForCriteriaNeeded(finding)) {
               //for each criteria in this finding type
               angular.forEach(finding.criteria, function (criteria) {
                   findingType.findings.push({
                       criteria: {
                           criteriaId: criteria.criteriaId,
                           criteriaName: criteria.criteriaDescription ? criteria.criteriaName + '. ' + criteria.criteriaDescription : criteria.criteriaName,
                           editable: true,
                           text: '',
                           comments: [],
                           progress: [],
                           response: { interimStatus: interimReport && !interimReport.addNormalFinding ? factory.data.responseTemplate : null, sevenDay: null, thirtyDay: null, postThirtyDay: null},
                           status: {
                               editable: true,
                               isNormalFindingForIR: interimReport && interimReport.addNormalFinding,
                               isResolved: false,
                               selectedOptionText: '',
                               detailText: null
                           }
                       },
                       key: helperSvc.getNextKey()
                   });
               });
           } else {        
               for (var i = 0; i < finding.number; i++) {
                   findingType.findings.push({ editable: true, criteria: { text: '', comments: [], response: { interimStatus: null , sevenDay: null, thirtyDay: null, postThirtyDay: null} }, key: helperSvc.getNextKey() });
               }
           }
        }

        factory.getTerminationPlanFindingTypeTemplate = function(){
            var terminationCode = helperSvc.getFirstMatch(statementStorageSvc.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.TERMINATIONPLAN);
            var terminationType = {//should always be true and 1. will then disable for no more additions
                statementCategoryId: terminationCode.statementCategoryId,
                statementFindingTypeId: statementFindingTypes.TERMINATIONPLAN,
                statementFindingTypeName: terminationCode.typeName,
                orderNumber: terminationCode.orderNumber,
                value: true,
                number: 1
            };
            return terminationType;
        }


       return {
           data: factory.data,
           checkForCriteriaNeeded: factory.checkForCriteriaNeeded,
           addFinding: factory.addFinding,
           addFindings: factory.addFindings,
           removeFinding: factory.removeFinding,
           setStatementFindingTypeTemplates: factory.setStatementFindingTypeTemplates,
           getTerminationPlanFindingTypeTemplate: factory.getTerminationPlanFindingTypeTemplate
        };
    };

    module.factory('statementTemplateSvc', statementTemplateSvc);

})(angular.module('statement'));