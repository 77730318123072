(function (module) {

    var societyMembershipEditCtrl = function (parent, $scope, $stateParams, $uibModalInstance, helperSvc, societyMembershipSvc, pevAppSvc, pevAppValidationSvc, alertSvc, volunteerHelperSvc) {
        var model = $scope;
        var isApplication = pevAppSvc.isApplicationMode();
        var originalRecord = null;
        var ieeeId;

        model.isIEEE = false;
        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.volunteerId = $stateParams.volunteerId;
        model.isInsertMode = false;
        model.isUpdateMode = false;
        model.isMembershiopNumberRequired = false;
        model.currentInformation = {}; 

        if (isApplication) {
            model.data = angular.copy(pevAppSvc.volunteerSocietyMembershipData);
            model.data.memberSocietyOptions = societyMembershipSvc.data.memberSocietyOptions;                      
        } else {
            model.data = societyMembershipSvc.data;
        }

        model.save = function () {
            if (!isApplication) model.currentInformation.volunteerId = model.volunteerId;

            if (isApplication) {

                if (model.isUpdateMode) {
                    model.data.societyMemberships[getMembershipIndex(originalRecord)] = model.currentInformation;
                } else {
                    model.data.societyMemberships.push(model.currentInformation);
                }

                var application = angular.copy(pevAppSvc.data.application);
                application.applicationJson.volunteerSocietyMembership = model.data.societyMemberships;

                pevAppSvc.update(application).then(onSuccess);

            } else if (!model.currentInformation.volunteerSocietyMembershipId) {
                societyMembershipSvc.create(model.currentInformation).then(onSuccess);
            } else {
                societyMembershipSvc.update(model.currentInformation).then(onSuccess);
            }

            function onSuccess() {
                pevAppValidationSvc.invokeValidation();
                alertSvc.addModalAlertSuccess("Society membership succesfully updated.");
                model.toggleInsertMode();
            }
        };

        model.edit = function (societyMembership) {
            helperSvc.scrollToModalTop();
            originalRecord = angular.copy(societyMembership);

            model.currentInformation = angular.copy(societyMembership);
            model.isInsertMode = true;
            model.isUpdateMode = true;
        };

        model.delete = function (societyMembership) {
            alertSvc.confirmDelete(societyMembership.societyAbbreviatedName, deleteFunc, societyMembership);

            function deleteFunc() {
                if (isApplication) {
                    var index = getMembershipIndex(societyMembership);

                    if (index > -1) {
                        model.data.societyMemberships.splice(index, 1);
                        var application = angular.copy(pevAppSvc.data.application);
                        application.applicationJson.volunteerSocietyMembership = model.data.societyMemberships;
                        pevAppSvc.update(application).then(onSuccess);
                    } else {
                        alertSvc.addModalAlertWarning("The selected membership could not be found.");
                    }
                } else {
                    societyMembershipSvc.delete(societyMembership).then(onSuccess);
                }
                
                function onSuccess() {
                    pevAppValidationSvc.invokeValidation();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Society membership succesfully deleted.");
                }
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleInsertMode = function () {
            if (model.isUpdateMode) model.isUpdateMode = false;
            volunteerHelperSvc.toggleInsertMode(model);
        };

        model.updateSocietyMembership = function () {
            // Set is membership number required
            model.isMembershiopNumberRequired = (model.currentInformation.societyId === ieeeId) ? true : false;
            model.isIEEE = model.currentInformation.societyId === ieeeId;
            // Update model as appropriate
            if (isApplication) {
                var society = model.data.memberSocietyOptions.find(function (society) {
                    return society.organizationId == model.currentInformation.societyId;
                });
                if (society) {
                    model.currentInformation.societyAbbreviatedName = society.abbreviatedName;
                    model.currentInformation.societyName = society.organizationName;
                }
            }
        };
        
        function getMembershipIndex(currentMembership) {
            return model.data.societyMemberships.findIndex(function (society) {
                return society.societyId == currentMembership.societyId;
            });
        }

        var activate = function () {
            //IEEE is the only society that requires the membership #, need to know when it is selected so form can be updated
            var results = helperSvc.getArrayByFieldValue(model.data.memberSocietyOptions, 'abbreviatedName', 'IEEE');

            if (results.length > 0 && results[0].length > 0) {
                ieeeId = results[0][0].organizationId;
            }
        }();
    };

    module.controller('societyMembershipEditCtrl', societyMembershipEditCtrl);

}(angular.module('volunteer')));