(function (module) {

    var programSplitMergeCtrl = function ($scope, $state, helperSvc, programDetailSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Merge, Split, and Transition History';
        model.formatDate = helperSvc.formatDate;

        model.goToProgram = function (programId, orgId) {
            programDetailSvc.getProgramDetailsByProgramId(programId).then(function (programDetails) {
                if (programDetails && programDetails.length > 0) {
                    $state.go('programs.detail', { organizationId: orgId, programDetailId: programDetails[0].programDetailId });
                }
            });
        };

        model.goToOrganization = function (orgId) {
            $state.go('organization.single', { organizationId: orgId });
        };
    };
    module.controller('programSplitMergeCtrl', programSplitMergeCtrl);

}(angular.module('program')));