(function (module) {

    var generalEditCtrl = function (id, $scope, $uibModalInstance, codeSvc, organizationSvc, organizationMdl, eventSvc, alertSvc, oauth) {
        var model = $scope;

        model.organizationId = id;
        model.isUS = true;
        model.isAdmin = oauth.isAdmin();
        model.popOverTitle = 'Organization Name';

        model.save = function () {
            var organization = model.currentOrganization;
            var organizationModel = new organizationMdl(organization, true);

            delete organizationModel.useDtos;

            if (organization.organizationId === 0 || !organization.organizationId) {
                organizationModel.currentOrganizationDetailDto.isCurrent = true;
                organizationSvc.create(organizationModel).then(function (data) {
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Organization successfully created.");
                });
            }
            else {
                organizationSvc.update(organizationModel).then(function (data) {
                    alertSvc.addAlertSuccess("Organization successfully updated.");
                    model.cancel();
                });
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.searchOrganization = function (text) {
            if (model.currentOrganization) {
                var organizationTypeId = String(model.currentOrganization.organizationTypeId);

                if (text.length < 3 || organizationTypeId !== '2') {
                    return
                }

                var promise = organizationSvc.getOrganizationsByIpedName(text).then(function (data) {
                    return data.value;
                });

                return promise;
            }
        };

        model.onSelectOrganization = function ($item) {
            model.isUS = true;
        };

        activate();

        function activate() {
            model.organizationTypeList = organizationSvc.codes.organizationTypes;
            model.countryList = organizationSvc.codes.countries;
            model.stateList = organizationSvc.codes.states;
        }

        model.updateIsUS = function () {
            if (model.currentOrganization.currentOrganizationAddressDto.countryCode == "US") {
                model.isUS = true;
                model.currentOrganization.currentOrganizationAddressDto.province = undefined;
            }
            else {
                model.isUS = false;
                model.currentOrganization.currentOrganizationAddressDto.stateCode = undefined;
            }
        };

        var loadOrgData = function () {
            var orgId = model.organizationId;
            model.currentOrganization = {};

            if (orgId === 0) {
                model.title = "Add Organization";
                model.currentOrganization.currentOrganizationAddressDto = {};
                model.currentOrganization.currentOrganizationAddressDto.countryCode = "US";
            }
            else {
                model.title = "Edit Organization";

                organizationSvc.getOrganizationById(orgId).then(function (data) {
                    model.currentOrganization = data;
                    if (!model.currentOrganization.currentOrganizationAddressDto) {
                        model.currentOrganization.currentOrganizationAddressDto = {};
                        model.currentOrganization.currentOrganizationAddressDto.countryCode = "US";
                    }

                    model.updateIsUS();
                });
            }
        };

        loadOrgData();

        model.toString = function (value) {
            return String(value);
        };
    };

    module.controller('generalEditCtrl', generalEditCtrl);

}(angular.module('organization')));