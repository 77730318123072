(function (module) {

    var httpErrorResponseInterceptor = function ($q, $location, $injector) {
        return {

            responseError: function error(response) {
                var state = $injector.get('$state');
                var alertSvc = $injector.get('alertSvc');

                switch (response.status) {
                    case 400:
                        alertSvc.addModalAlertSuccess("Unexpected error occurred (400 Bad Request)");
                        break;
                    case 401:
                        state.go('error', {
                            errorCode: 401,
                            errorMessage: 'Not authorized'
                        });
                        break;
                    case 404:
                        state.go('error', {
                            errorCode: 404,
                            errorMessage: 'Page Not Found'
                        });
                        break;
                    case 409:
                        alertSvc.openModalAlert(response.statusText);
                        break;
                    case 500:
                        alertSvc.addModalAlertSuccess("Unexpected error occurred (500 Internal Server Error)");
                        break;
                    case 501:
                        alertSvc.addModalAlertSuccess("Unexpected error occurred (501 Not Implemented)");
                        break;
                    case 521:
                        alertSvc.addModalAlertSuccess("Requested Email Not Found");
                        break;
                    case 0:
                    case -1:
                        // Request cancelled, usually when redirecting to external web site after SSO
                        console.log('Request Blocked', response);
                        break;
                    default:
                        state.go('error', {
                            errorCode: response.status,
                            errorMessage: null
                        });
                }
                return $q.reject(response);
            }
        };
    };

    var versionControlInterceptor = function ($q, $location, $injector, amsConst) {
        return {
            'request': function (config) {
                if (config.url.toLowerCase().indexOf('apps/') > -1)
                {
                    config.url = config.url + '?version=' + amsConst.versionNumber;
                }
                return config;
            }
        };
    };

    module.factory("httpErrorResponseInterceptor", httpErrorResponseInterceptor);
    module.factory("versionControlInterceptor", versionControlInterceptor);

    module.config(function ($httpProvider) {
        $httpProvider.interceptors.push("httpErrorResponseInterceptor");
        $httpProvider.interceptors.push("versionControlInterceptor");
    });

})(angular.module("common"));
