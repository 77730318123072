(function (module) {

    var volunteerActivitiesSvc = function ($http, $odata, $q, odataSvc, helperSvc, teamMemberStatusTypes) {
        var key = 'reviewTeamMemberId';
        var factory = {};

        factory.data = { activities: null };

        //recent reviews including not visited yet
        factory.getAllCurrentByPersonId = function (personId) {
            return getVolunteerActivitySvc(personId, true, true);
        };

        //Show all conducted reviews
        factory.getAllHistoricalByPersonId = function (personId) {
            return getVolunteerActivitySvc(personId);
        };

        //Show only recently conducted reviews
        factory.getCurrentByPersonId = function (personId) {
            return getVolunteerActivitySvc(personId, true, false);
        };

        function getVolunteerActivitySvc(personId, recentOnly, includeUpcomingVisit, ) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetVolunteerActivityByPersonId', 'personId', personId)
            var odata = oSvc.getSource(path, key).odata();
            // Filter out activity that didn't happen because the volunteer declined or was rejected
            odata.filter('teamMemberStatusId', '<=', teamMemberStatusTypes.ASSIGNMENTAPPROVED);
            // Filter by review year to get "current"
            if (recentOnly) {
                odata.filter('reviewYear', '>=', helperSvc.getAcademicYear());
            }
            if (!includeUpcomingVisit) {
                var pred1 = new $odata.Predicate('visitEndDate', '<', new Date());
                var pred2 = new $odata.Predicate('visitEndDate', null);
                var predComb = $odata.Predicate.or([pred1, pred2]);
                odata.filter(predComb);

                odata.filter('hasReviewedOss', '==', true);
            }
            // Make query and return promise
            odata.query(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        }

        return {
            data: factory.data,
            getCurrentByPersonId: factory.getCurrentByPersonId,
            getAllCurrentByPersonId: factory.getAllCurrentByPersonId,
            getAllHistoricalByPersonId: factory.getAllHistoricalByPersonId
        };
    };
    module.factory('volunteerActivitiesSvc', volunteerActivitiesSvc);

})(angular.module('volunteer'));