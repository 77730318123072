(function (module) {

    var addObserverCtrl = function ($scope, $uibModalInstance, alertSvc, trainingSvc, trainingTypeIds, societyOptions, searchOptions, oauth, codeSvc, helperSvc, contactRoleTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Add Observer';
        model.currentInformation = {};
        model.trainingSessions = trainingSvc.data.trainingSessions;
        model.isAdmin = oauth.isAdmin();

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.addObserver = function () {
            model.isSaving = true;
            if (alreadyObserverForThisTraining())
                return alertSvc.addModalAlertWarning("Observer already assigned to this training");

            

            var observerDto = { //this is really a trainingpersondto
                trainingSessionId: model.currentInformation.trainingSessionId,
                personId: model.currentInformation.observer.personId,
                commissionId: model.currentInformation.commissionId,
                trainingObserverTypeId: model.currentInformation.trainingObserverTypeId,
                isPaidByABET: model.currentInformation.isPaidByABET,
                note: model.currentInformation.note
            }

            trainingSvc.addTrainingObserver(observerDto).then(function () {
                trainingSvc.getTrainingObservers(searchOptions);
                alertSvc.addModalAlertSuccess("Observer succesfully added.");
                $uibModalInstance.close();
            }).catch(onError);

            function onError(error) {
                console.log(error);
                model.isSaving = false;
                alertSvc.addModalAlertWarning('Add Observer failed.')
            }

        }

        function alreadyObserverForThisTraining() {
            var filteredTrainings = helperSvc.getFilteredArray(trainingSvc.data.trainingObserverList, 'trainingSessionId', model.currentInformation.trainingSessionId, true);

            return helperSvc.getFirstMatch(filteredTrainings, 'personId', model.currentInformation.observer.personId) !== null;
        }

        var activate = function () {
            model.currentInformation.observer = null;
            model.currentInformation.observerTypeId = null;
            model.currentInformation.trainingSessionId = null;
            model.currentInformation.commissionId = null;
            model.currentInformation.isPaidByABET = null;

            var dataArray = [];

            var getCommissionData = {
                dataHolder: model,
                dataLocationName: 'trainingCommissions',
                svcCallback: codeSvc.getCommissions,
                helperCallback: helperSvc.getResults
            };
            dataArray.push(getCommissionData);

            var getTrainingObserverTypeData = {
                dataHolder: model,
                dataLocationName: 'trainingObserverTypes',
                svcCallback: codeSvc.getTrainingObserverTypes,
                helperCallback: helperSvc.getResults
            };
            dataArray.push(getTrainingObserverTypeData);

            helperSvc.getData(dataArray).then(function () {
                if (model.isAdmin) {
                    model.currentInformation.trainingCommissions = model.trainingCommissions;
                    model.currentInformation.trainingObserverTypes = model.trainingObserverTypes;
                }
                if (!model.isAdmin) {
                    model.currentInformation.trainingObserverTypes = model.trainingObserverTypes;
                }
                model.dataIsReady = false;
            });
        }();
    };

    module.controller('addObserverCtrl', addObserverCtrl);

}(angular.module('training')));