(function (module) {

    var regionalAgenciesEditCtrl = function (organization, organizationRecognitionSvc, $scope, $uibModalInstance, organizationSvc, eventSvc, organizationMdl, helperSvc, alertSvc) {
        var model = $scope;

        model.organization = new organizationMdl(organization, false);
        model.organization.organizationId = parseInt(organization.organizationId);
        model.title = "Regional Agencies";
        model.newModel = {};

        model.data = angular.copy(organizationRecognitionSvc.data);
        model.recognizingAgencies = model.data.recognizingAgencies;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.insertRow = function () {
            var newRecognizingAgency = {
                organizationRecognitionId: 0,
                organizationId: model.organization.organizationId,
                recognizingOrganizationId: 0,
                note: model.note,
                isDeleted: false
            };

            model.recognizingAgencies.push(newRecognizingAgency);
            model.isBlankMode = false;
        };

        model.deleteRow = function (agency, isLastItem) {
            if (isLastItem) model.isBlankMode = true;
            agency.isDeleted = true;
        };

        model.save = function () {

            var newArr = [];

            angular.forEach(model.recognizingAgencies, function (agency) {

                //exclude newly added line but deleted
                if (agency.isDeleted === false || agency.organizationRecognitionId !== 0) {
                    newArr.push(agency);
                }
            });

            organizationRecognitionSvc.save(newArr).then(function (data) {
                activate();
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Regional agencies successfully updated.");
            });

        };

        model.searchAgency = function (val) {
            if (val.length < 3)
                return;

            var promise;

            if (model.organization.countryCode == 'US') {
                promise = organizationSvc.quickSearchUS(val).then(function (data) {
                    return data.value;
                });
            }
            else {
                promise = organizationSvc.quickSearchNonUS(val).then(function (data) {
                    return data.value;
                });
            }

            return promise;
        };

        model.onSelectAgency = function ($item, $model, $label, agency) {
            agency.recognizingOrganizationId = $item.organizationId;
        };

        activate();

        function activate() {
            if (model.recognizingAgencies.length === 0) {
                model.insertRow();
            };
        }
    };

    module.controller('regionalAgenciesEditCtrl', regionalAgenciesEditCtrl);

}(angular.module('organization')));