(function (module) {

    var organizationHistoryEditCtrl = function (organizationId, $scope, $uibModalInstance, organizationHistorySvc, eventSvc, helperSvc, codeSvc, alertSvc) {

        var model = $scope;

        model.title = 'Edit Organization History';
        model.organizationId = organizationId;
        
        model.isUS = true;

        model.isInsertMode = false;
        model.isEditMode = false;
        model.newModel = {};

        model.formatDate = helperSvc.formatDate;

        model.data = organizationHistorySvc.data;

        model.addNew = function () {
            model.isInsertMode = true;
            model.newModel = {};
            getCurrentOrganizationDetail(model.organizationId);
        };

        model.closeUpdate = function () {
            model.isInsertMode = false;
            model.isEditMode = false;
            model.newModel = undefined;
        };

        model.saveRow = function () {
            model.newModel.organizationId = parseInt(model.organizationId);

            if (model.isInsertMode == true)
            {
                organizationHistorySvc.saveOrganizationNameChange(model.newModel).then(function (data) {
                    alertSvc.addAlertSuccess("Organization name successfully updated.");
                    model.closeUpdate();
                });
            }
            else if (model.isEditMode == true) {
                organizationHistorySvc.updateOrganizationDetail(model.newModel).then(function (data) {
                    alertSvc.addAlertSuccess("Organization information successfully updated.");
                    model.closeUpdate();
                });
            }

        };

        model.deleteRow = function (organization) {
            alertSvc.confirmDelete(organization.organizationName, deleteFunc);

            function deleteFunc() {
                organizationHistorySvc.deleteHistory(organization).then(function (data) {
                    alertSvc.addAlertSuccess("Organization history successfully deleted.");
                });
            }
        };

        model.updateRow = function (organization) {
            model.isEditMode = true;
            getOrganizationDetail(organization);
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        function getCurrentOrganizationDetail(orgId) {
            var orgId = parseInt(orgId);
            organizationHistorySvc.getCurrentOrganizationDetail(orgId).then(function (data) {
                var obj = angular.copy(data[0]);

                obj.organizationDetailId = 0;
                obj.startDate = undefined;
                obj.endDate = undefined;
                obj.currentOrganizationEndDate = undefined;

                model.newModel = obj;
            });
        };

        function getOrganizationDetail(org) {
            var data = org;
            data.startDate = (data.startDate != null) ? model.formatDate(data.startDate) : undefined;
            data.endDate = (data.endDate != null) ? model.formatDate(data.endDate) : undefined;

            model.newModel = angular.copy(data);
        };

        
        activate();

        function activate() {
            codeSvc.getCountries().then(function (data) {
                model.countryList = data.value;
            });

            codeSvc.getStates().then(function (data) {
                model.stateList = data.value;
            });
        }
    };

    module.controller('organizationHistoryEditCtrl', organizationHistoryEditCtrl);

}(angular.module('organization')));