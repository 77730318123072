(function (module) {

    const manageRolesEditCtrl = ['$uibModalInstance', 'teamMembers', 'programAuditAccessData', 'teamMemberTypeNames', function ($uibModalInstance, teamMembers, programAuditAccessData, teamMemberTypeNames) {
        const model = this;

        // properties
        model.programAuditAccessData = angular.copy(programAuditAccessData);

        // methods
        model.getTeamMembersForProgram = getTeamMembersForProgram;
        model.save = save;
        model.cancel = cancel;

        function getTeamMembersForProgram(program) {
            return teamMembers
                .filter(tm =>
                    (
                        tm.programId === program.programId &&
                        tm.teamMemberTypeId === teamMemberTypeNames.PEV
                    ) ||
                    (
                        tm.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR ||
                        tm.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR ||
                        tm.teamMemberTypeId === teamMemberTypeNames.COTEAMCHAIR
                    )
                )
                .sort((a, b) => {
                    // Set a sort priority order for display of team member names. 
                    const sortOrder = [
                        teamMemberTypeNames.PEV,
                        teamMemberTypeNames.TEAMCHAIR,
                        teamMemberTypeNames.REPORTTEAMCHAIR,
                        teamMemberTypeNames.COTEAMCHAIR
                    ];

                    if (sortOrder.indexOf(a.teamMemberTypeId) < sortOrder.indexOf(b.teamMemberTypeId)) return -1;
                    else if (sortOrder.indexOf(a.teamMemberTypeId) > sortOrder.indexOf(b.teamMemberTypeId)) return 1;
                    else return 0;
                });
        }

        function save() {
            $uibModalInstance.close(model.programAuditAccessData);
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    }];

    module.controller('manageRolesEditCtrl', manageRolesEditCtrl);

}(angular.module('programAudit')));