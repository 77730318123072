(function (module) {

    var programInformationCtrl = function ($scope, $uibModal, $state, programSvc, eventSvc, alertSvc, helperSvc) {
        var model = this;
        model.title = 'Program Information';
        model.parent = $scope.$parent.model;

        model.getBooleanText = helperSvc.getBooleanText;

        model.editProgram = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/programEdit.html',
                size: 'lg',
                controller: 'programEditCtrl',
                resolve: {
                    program: function () {
                        return model.parent.selectedProgram;
                    },
                    organization: function () {
                        return model.parent.organization;
                    }
                }
            });
        };

        model.deleteProgram = function () {
            alertSvc.confirmDelete(model.parent.selectedProgram.programName, deleteFunc);

            function deleteFunc() {
                if (model.parent.selectedProgram && model.parent.selectedProgram.programId) {
                    programSvc.delete(model.parent.selectedProgram.programId).then(function (data) {
                        // Patterned after personDetailsCt
                        $state.go('programs');
                        eventSvc.broadcast('refreshPrograms');
                    });
                }
            }
        };
    };

    module.controller('programInformationCtrl', programInformationCtrl);

}(angular.module('program')));