(function (module) {

    var addNPCFindingsCtrl = function (programDetailDto, npcProgramChangeDto, npcProgramChangeReportDto, $scope, $uibModalInstance, alertSvc, npcProgramChangeReportSvc, criteriaTypes,
        readinessSvc, npcReportTemplateSvc, statementCategories) {

        var model = $scope;

        model.title = 'Add Finding';
        model.programDetailDto = programDetailDto;
        model.npcProgramChangeDto = npcProgramChangeDto;
        model.npcProgramChangeReportDto = angular.copy(npcProgramChangeReportDto);
        model.criteria = null;
        model.findingTypeOptions = null;
        model.isDataReady = false;

        model.statementCategories = statementCategories;

        model.getCriteriaFullName = readinessSvc.getCriteriaFullName;

        model.isShortcoming = npcReportTemplateSvc.isShortcoming;

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;

            npcReportTemplateSvc.addFindings(model.programDetailDto.commissionId, model.npcProgramChangeReportDto, model.findingTypeOptions);

            if (model.findingTypeOptions.some(findingType => findingType.value && npcReportTemplateSvc.isShortcoming(findingType.statementFindingTypeId)))
                npcReportTemplateSvc.resetRecommendedAction(model.npcProgramChangeReportDto);

            npcProgramChangeReportSvc.update(model.npcProgramChangeReportDto)
                .then(() => {
                    alertSvc.addAlertSuccess('Finding(s) added.');
                    $uibModalInstance.close(model.npcProgramChangeReportDto);
                })
                .catch(error => {
                    console.log(error);
                    model.isSaving = false;
                    alertSvc.addModalAlertWarning('Add finding(s) failed.')
                });
        };

        model.getAvailableCriteria = function (statementFindingTypeId) {
            const existingCriteria = new Set(
                model.npcProgramChangeReportDto.reportJson.flatMap(section =>
                    section.statementJson
                ).filter(findingType =>
                    findingType.statementFindingTypeId === statementFindingTypeId
                ).flatMap(findingType =>
                    findingType.findings.map(finding =>
                        finding.criteria.criteriaId
                    )
                )
            );

            const availableCriteria = model.criteria.filter(criterion =>
                // One shortcoming type per criteria except APPM where shortcomings of same type can reference different sub-sections
                !existingCriteria.has(criterion.criteriaId) || criterion.criteriaTypeId === criteriaTypes.APPM
            );

            return availableCriteria;
        }

        model.isCriteriaNeeded = function (findingTypeOption) {
            return !!findingTypeOption.value;
        }

        model.isValid = function () {
            return npcReportTemplateSvc.validateFindingTypeOptions(model.findingTypeOptions);
        }

        activate();

        function activate() {
            npcReportTemplateSvc.getAllNPCStatementTemplateData(model.programDetailDto).then((data) => {
                Object.assign(model, data);
                model.isDataReady = true;
            });
        };
    };

    module.controller('addNPCFindingsCtrl', addNPCFindingsCtrl);

}(angular.module('npc')));