(function (module) {

    var programAuditDetailSvc = function (amsConst, odataSvc, $http, $q, organizationSvc, programAuditAccessSvc, programReviewSvc, reviewTeamMemberSvc,
                                          programAuditSvc)
    {
        const apiPath = '/ProgramAuditDetail';
        const keyName = 'programAuditDetailId'
        const factory = {};

        const nonDisplayingActionCodes = ['W', 'C', 'T'];
        const nonDisplayingProgramReviewTypeCodes = ['BR', 'IR', 'SCR', 'TR', 'TV', 'XR'];
        // Make distinction between non-displayable and non-editable to allow listing programs that don't have PAFs
        const nonEditableProgramReviewTypeCodes = ['BR', 'IR', 'SCR', 'XR'];

        factory.isDisplayable = function (programReview) {
            return programReview && !nonDisplayingActionCodes.includes(programReview.actionCode) && !nonDisplayingProgramReviewTypeCodes.includes(programReview.programReviewTypeCode);
        }

        factory.isAuditable = function (programReview) {
            return programReview && factory.isDisplayable(programReview) && !nonEditableProgramReviewTypeCodes.includes(programReview.programReviewTypeCode);
        }

        factory.getById = function (programAuditDetailId) {
            const id = parseInt(programAuditDetailId);
            const key = 'programAuditId';
            const oSvc = odataSvc.get();

            oSvc.getSource(apiPath, key).odata()
                .expand('programAuditDetailEvaluatorDtos')
                .get(id, oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(data => {
                deserializeJSON(data);
                return data;
            });
        };

        factory.getHistory = async function (programAuditId, programAuditDetailId) {
            // Get program audit detail records for given id and all other versions for its program audit.
            const oSvc = odataSvc.get();
            const path = oSvc.getPathWithParameters('/GetProgramAuditDetailHistory', 'programAuditId', programAuditId, 'programAuditDetailId', programAuditDetailId);
            oSvc.getSource(path).odata()
                .expand('programAuditDetailEvaluatorDtos')
                .orderBy('programAuditDetailId', 'desc')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(data => {
                if (Array.isArray(data)) {
                    data.forEach(programAuditDetail => {
                        deserializeJSON(programAuditDetail);
                    });
                }

                return data;
            });
        };
        
        factory.create = function (programAuditDetail) {
            // Create new program audit detail record
            var oSvc = odataSvc.get();
            var programAuditDetailData = serializeJSON(programAuditDetail);
            var resource = oSvc.instantiate(apiPath, keyName, programAuditDetailData);
            oSvc.getDeferred().promise.then(getResult);

            return resource.$save(null, oSvc.onSuccess, oSvc.onFailure);
        };

        factory.update = function (programAuditDetail) {
            // Save changes to program audit detail
            var oSvc = odataSvc.get();
            var programAuditDetailData = serializeJSON(programAuditDetail);
            var resource = oSvc.instantiate(apiPath, keyName, programAuditDetailData);
            oSvc.getDeferred().promise.then(getResult);

            return resource.$update(null, oSvc.onSuccess, oSvc.onFailure);
        };

        factory.share = function (programAuditDetail) {
            // Publish program audit detail for other PEVs to view
            return $http.post(
                amsConst.webApiUrl + '/odata/ShareProgramAudit()?$expand=programAuditDetailEvaluatorDtos',
                { Value: serializeJSON(programAuditDetail) }
            ).then(response => {
                return getActionResult(response);
            });
        };

        factory.submit = function (programAuditDetail) {
            // Submit program audit detail from PEV to TC.
            return $http.post(
                amsConst.webApiUrl + '/odata/SubmitProgramAudit()?$expand=programAuditDetailEvaluatorDtos',
                { Value: serializeJSON(programAuditDetail) }
            ).then(response => {
                return getActionResult(response);
            });
        };

        factory.return = function (programAuditDetail) {
            // Return program audit detail from TC back to PEV.
            return $http.post(
                amsConst.webApiUrl + '/odata/ReturnProgramAudit()?$expand=programAuditDetailEvaluatorDtos',
                { Value: serializeJSON(programAuditDetail) }
            ).then(response => {
                return getActionResult(response);
            });
        };

        factory.saveReviewedByTC = function (programAuditDetails) {
            // Save program audit details that have been reviewed by a team chair.
            // Note: Should stay consistent by using $odataresource but not sure about OData action support.
            if (!Array.isArray(programAuditDetails))
                programAuditDetails = [programAuditDetails];

            return $http.post(amsConst.webApiUrl + '/odata/SaveProgramAuditReviewedByTC()?$expand=programAuditDetailEvaluatorDtos',
                { Value: programAuditDetails.map(programAuditDetail => serializeJSON(programAuditDetail)) }
            ).then(response => {
                return getActionResult(response);
            });
        };

        factory.getProgramAudit = function (programAuditId, programId = 0, programAuditDetailId = 0, includeHistory = false) {

            const programFilter = programId ? `programId eq ${programId}` : '';

            const programAuditDetailFilter = programAuditDetailId ?
                `programAuditDetailId ${includeHistory ? 'le' : 'eq'} ${programAuditDetailId}` :
                includeHistory ? '' : 'isCurrent eq true';

            const filter = programFilter || programAuditDetailFilter ?
                `;$filter=${programFilter}${programFilter && programAuditDetailFilter ? ' and ' : ''}${programAuditDetailFilter}` :
                '';

            const programAuditPath = `${amsConst.webApiUrl}/odata/ProgramAudit(${programAuditId})?$expand=programAuditDetailDtos($expand=programAuditDetailEvaluatorDtos${filter})`;

            return $http.get(programAuditPath).then(response => {
                const data = response.data;
                data.programAuditDetailDtos = data.programAuditDetailDtos || [];
                data.programAuditDetailDtos.forEach(programAuditDetail =>
                    deserializeJSON(programAuditDetail));
                data.programAuditDetailDtos.sort(programAuditAccessSvc.sortHistory);
                return data;
            });
        }

        factory.getAllProgramAuditToolData = function (programAuditId, programId = 0, programAuditDetailId = 0, includeHistory = false) {
            let data = {};

            return factory.getProgramAudit(programAuditId, programId, programAuditDetailId, includeHistory).then(programAudit => {
                data.programAudit = programAudit;
                // Load data referencing reviewTeamId.
                return $q.all([
                    programAuditAccessSvc.getProgramAuditAccessByReviewTeamId(data.programAudit.reviewTeamId),
                    loadProgramReview(data.programAudit.reviewTeamId, programId),
                    reviewTeamMemberSvc.getMyReviewTeamMembers(data.programAudit.reviewTeamId)
                ]);
            }).then(([programAuditAccess, programReviews, reviewTeamMembers]) => {
                data.programAuditAccess = programAuditAccess;
                data.programReviews = programReviews;
                data.reviewTeamMembers = reviewTeamMembers;
                // Load data referencing organizationId.
                return organizationSvc.getOrgByIdOdata(programReviews.length ? programReviews[0].programDto.organizationId : 0);
            }).then(organization => {
                data.organization = organization.currentOrganizationDetailDto;
                return programAuditSvc.getProgramAuditByReviewTeamId(data.programAudit.reviewTeamId);
            }).then(programAuditWithInstitutionalSummary => {
                var institutionalSummaryArr = programAuditWithInstitutionalSummary.find(pa => pa.isCurrent)?.programAuditInstitutionalSummaryDtos
                data.institutionalSummary = institutionalSummaryArr?.length > 0 ? programAuditSvc.convertToJson(institutionalSummaryArr[0]) : [];
                return data;
            });

            function loadProgramReview(reviewTeamId, programId) {
                return programReviewSvc.loadProgramReviewsByReviewTeamId(reviewTeamId).then(() => {
                    const data = programReviewSvc.data.programs;
                    const programReviews = programId ? data.filter(programReview => programReview.programId === programId) : data;
                    return programReviews;
                })
            }
        };

        factory.placeholderText = 'Click to add text...';

        factory.hasText = function (text) {
            // markup is added whenever anything is entered, even if all the text was deleted
            return !(!text || text === '' || text === '<p>&nbsp;</p>' || text === factory.placeholderText || text === '<p>' + factory.placeholderText + '</p>');
        }

        function deserializeJSON(programAuditDetail) {
            if (!programAuditDetail || !programAuditDetail.programAuditJson) return;
            programAuditDetail.programAuditJson = angular.fromJson(programAuditDetail.programAuditJson);
        }

        function serializeJSON(programAuditDetail) {
            if (!programAuditDetail || !programAuditDetail.programAuditJson) return programAuditDetail;

            const serializedProgramAuditDetail = angular.copy(programAuditDetail);
            serializedProgramAuditDetail.programAuditJson.auditDetails.forEach(findingType =>
                findingType.findings.forEach(finding => {
                    delete finding.isAutosaving;
                    delete finding.delayElapsed;
                    delete finding.saveComplete;
                    if (!factory.hasText(finding.criteria.text))
                        finding.criteria.text = null;
                    if (finding.criteria.comments)
                        finding.criteria.comments.forEach(comment =>
                            delete comment.isSelected
                        );
                })
            );

            serializedProgramAuditDetail.programAuditJson = angular.toJson(serializedProgramAuditDetail.programAuditJson);

            return serializedProgramAuditDetail;
        }

        function getResult(programAuditDetail) {
            deserializeJSON(programAuditDetail);
            return programAuditDetail;
        }

        function getActionResult(response) {
            const programAuditDetails = response.data.value;
            programAuditDetails.forEach(programAuditDetail =>
                deserializeJSON(programAuditDetail)
            );

            return programAuditDetails;
        }

        return {
            isDisplayable: factory.isDisplayable,
            isAuditable: factory.isAuditable,
            getById: factory.getById,
            getHistory: factory.getHistory,
            create: factory.create,
            update: factory.update,
            share: factory.share,
            submit: factory.submit,
            return: factory.return,
            saveReviewedByTC: factory.saveReviewedByTC,
            getProgramAudit: factory.getProgramAudit,
            getAllProgramAuditToolData: factory.getAllProgramAuditToolData,
            hasText: factory.hasText
        };
    };

    module.factory('programAuditDetailSvc', programAuditDetailSvc);

})(angular.module('programAudit'));
