
//This is an obsolete service - No longer used, 2/11/2025

(function (module) {

    var volunteerHealthSafetySvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerHealthSafetyAgreement',
            keyName: 'volunteerHealthSafetyAgreementId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'volunteerHealthSafety',
            getByForeignKeyPath: '/GetVolunteerHealthSafetyAgreementByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        factory.getAgreedHealthSafety = function (volunteerHealthSafety) {
            var agrees = volunteerHealthSafety || factory.data.volunteerHealthSafety;

            for (var i = 0; i < agrees.length; i++) {
                var agree = agrees[i];
                var currentYear = new Date().getFullYear();
                var agreeYear = new Date(agree.answeredDateTime).getFullYear();

                if (agreeYear === currentYear && agree.answer) {
                    return agree;
                }
            }
            return null;
        };

        var apiPath = '/VolunteerHealthSafetyAgreement';
        var key = 'volunteerHealthSafetyAgreementId';

        factory.delete = function (volunteerHealthSafety) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, { volunteerHealthSafetyAgreementId: volunteerHealthSafety.volunteerHealthSafetyAgreementId });
            resource.$delete(oSvc.onSuccess, oSvc.onFailure);

            oldLoadData(oSvc, volunteerHealthSafety.volunteerId);

            return oSvc.getDeferred().promise;
        };

        function oldLoadData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getByForeignKey(volunteerId);
            });
        }

        return {
            data: factory.data,
            getVolunteerHealthSafetyByVolunteerId: factory.getByForeignKey,
            getAgreedHealthSafety: factory.getAgreedHealthSafety,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('volunteerHealthSafetySvc', volunteerHealthSafetySvc);

})(angular.module('volunteer'));