(function(module) {

    var currentUserHelper = function (oauth, contactRoleTypes, itRoles, currentOrganization, helperSvc, currentUser) {

        var hasRole = oauth.hasRole;
        var getOrgsByRole = oauth.getOrganizationsByUserRoleId;

        var hasRoleForCommission = function (roleId, organizationId, commissionId) {
            var orgs = getOrgsByRole(roleId);
            return orgs && orgs.some(function (org) {
                return org.organizationId == organizationId && org.oAuthOrganizationUserDesignations && org.oAuthOrganizationUserDesignations.some(function (designation) {
                    return designation.designationId == commissionId;
                })
            });
        }

        var isPresident = function (organizationId) {
            return hasRole(organizationId, contactRoleTypes.PRESIDENT);
        };

        var isDelegateForPresident = function (organizationId) {
            return hasRole(organizationId, contactRoleTypes.DELEGATEFORPRESIDENT);
        };

        var isProvost = function (organizationId) {
            return hasRole(organizationId, contactRoleTypes.PROVOST);
        };

        var isPrimary = function (organizationId, commissionId) {
            return hasRoleForCommission(contactRoleTypes.PRIMARY, organizationId, commissionId);
        }

        var isDelegateForPrimary = function (organizationId, commissionId) {
            return hasRole(organizationId, contactRoleTypes.DELEGATEFORPRIMARY) && helperSvc.arrContains(currentOrganization.profile.delegateCommissions, commissionId);
        };

        var isSecondary = function (organizationId, commissionId) {
            return hasRoleForCommission(contactRoleTypes.SECONDARY, organizationId, commissionId);
        }

        var isInstitutionUser = function (organizationId){
            return helperSvc.arrayContainsByPropertyValue(currentUser.profile.userRoles, 'roleId', [contactRoleTypes.DELEGATEFORPRIMARY, contactRoleTypes.PRIMARY, contactRoleTypes.DELEGATEFORPRESIDENT, contactRoleTypes.PROVOST, contactRoleTypes.PRESIDENT, contactRoleTypes.SECONDARY, contactRoleTypes.BILLING]);
        }

        var isOnlyInstitutionUser = function () {
            return helperSvc.arrayOnlyContainsByPropertyValue(currentUser.profile.userRoles, 'roleId', [contactRoleTypes.DELEGATEFORPRIMARY, contactRoleTypes.PRIMARY, contactRoleTypes.DELEGATEFORPRESIDENT, contactRoleTypes.PROVOST, contactRoleTypes.PRESIDENT, contactRoleTypes.SECONDARY, contactRoleTypes.BILLING]);
        }

        var isEducationLiasion = function (organizationId){
            return helperSvc.arrayContainsByPropertyValue(currentUser.profile.userRoles, 'roleId', [contactRoleTypes.EDUCATIONLIASON]);
        }

        var isProgramContact = function (programs) {
            if (!programs) return false;
            if (!Array.isArray(programs)) programs = [programs];
            var programIds = programs.map(function (program) { 
                return program.programId; 
            });
            return currentUser.profile.userRoles.some(function (role) {
                return role.roleId === contactRoleTypes.PROGRAM &&
                       role.oAuthOrganizationUserDesignations &&
                       role.oAuthOrganizationUserDesignations.some(function (designation) {
                           return programIds.indexOf(designation.designationId) > -1;
                       });
            });
        }

        var getViceOpsCommissionId = function () {
            var commissions = getOrgsByRole(contactRoleTypes.VICECHAIROFOPERATIONS);
            if (commissions && commissions.length > 0) {
                // Should only be vice ops for one commission if any
                return commissions[0].organizationId;
            } else {
                return null;
            }
        }

        var getSocietiesByPevManagerRole = function(){
            var societies = getOrgsByRole(contactRoleTypes.PEVRECRUITINGMANAGER);
            societies = societies.concat(getOrgsByRole(contactRoleTypes.TRAININGMANAGER));
            societies = societies.concat(getOrgsByRole(contactRoleTypes.EDUCATIONLIASON));

            if (societies){
                var results;
                results = helperSvc.uniqueArrayByKey(societies, 'organizationId');
                return results;
            }
            else
                return [];
        }

        var isITStaff = function () {
            // Note: since this sort of client-side check is easily bypassed, this convenience method shouldn't be relied on for security.
            return helperSvc.arrayContainsByPropertyValue(currentUser.profile.userRoles, 'roleId', [itRoles.ITDIRECTOR, itRoles.ITPERSONNEL]);
        }
        
        return {
            isPresident: isPresident,
            isDelegateForPresident: isDelegateForPresident,
            isProvost: isProvost,
            isPrimary: isPrimary,
            isDelegateForPrimary: isDelegateForPrimary,
            isSecondary: isSecondary,
            isProgramContact: isProgramContact,
            getViceOpsCommissionId: getViceOpsCommissionId,
            getSocietiesByPevManagerRole: getSocietiesByPevManagerRole,
            isInstitutionUser: isInstitutionUser,
            isOnlyInstitutionUser: isOnlyInstitutionUser,
            isEducationLiasion: isEducationLiasion,
            isITStaff: isITStaff
        };
    };

    module.factory("currentUserHelper", currentUserHelper);

}(angular.module("security")));