(function (module) {

    /* FORM directives includes the following:
        - auto-focus
        - check-dupliate-email
        - check-username
        - ckEditor
        - compare-to
        - convert-to-number
        - date-picker
        - datepicker-localdate
        - multiselect-dropdown
        - number-range
        - on-enter
        - on-finish-render
        - password-reset
        - selection-required
        - survey
    */

    var templateRoot = '/apps/common/templates/form/';

    module.directive('autoFocus', function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, elem) {
                $timeout(function () {
                    elem[0].focus();
                }, 0);
            }
        };
    });

    module.directive('checkDuplicateEmail', function ($q, $compile, personEmailSvc, helperSvc) {
        //EXAMPLE:  <input type="email" form-name="form" name="emailInput" ng-model="email.emailAddress" person-id="{{email.personId}}" email-array={{emails}} email-index={{$index}} check-duplicate-email/>
        return {
            restrict: 'A',
            require: 'ngModel',
            //scope: {
            //    formName: '@',
            //    name: '@',
            //    personId: '@?', // Without persondId you will create a new email rather than updating an existing one
            //    emailIndex: '@?',
            //    emailArray: '@'
            //},
            link: function (scope, elem, attr, ngModel) {

                if (attr.formName && attr.name) {
                    var errorMsg = '<div ng-show="' + attr.formName + '.' + attr.name + '.$error.duplicate" class="bg-danger" role="alert"><span>Email already in use.</span></div>' +
                                   '<div ng-show="' + attr.formName + '.' + attr.name + '.$error.duplicateInArray" class="bg-danger" role="alert"><span>Email already in list.</span></div>';
                    elem.parent().append($compile(errorMsg)(scope));
                }

                ngModel.$asyncValidators.checkDuplicateEmail = function (modelValue) {
                    //checks the db for duplicates 
                    var personId = attr.personId || 0;

                    return personEmailSvc.checkForDuplicates(parseInt(personId), modelValue, true).then(function (data) {
                        var duplicateEmails = data.value;

                        ngModel.$setValidity('duplicate', !duplicateEmails);
                        if (duplicateEmails) {
                            return $q.reject();
                        }
                        return true;
                    });
                };

                if (attr.emailArray) {
                    ngModel.$validators.checkDuplicatesInArray = function (modelValue) {
                        //checks for duplicates in array if is array
                        if (modelValue !== undefined) {
                            //get email array in object form and filter out any deleted email objects for index integrity
                            var emails = JSON.parse(attr.emailArray);
                            var emailIndex = parseInt(attr.emailIndex);
                            var isDuplicate = false;

                            //substitute the email objects email address of the given index for the modelValue because the email list is not updated until this digest cycle is completed
                            emails[emailIndex].emailAddress = modelValue;

                            for (var i = 0; i < emails.length; i++) {
                                if (emails[i].emailAddress === modelValue && emailIndex !== i) {
                                    isDuplicate = true;
                                    break;
                                }
                            }

                            if (isDuplicate) {
                                ngModel.$setValidity('duplicateInArray', false);
                                return false;
                            } else {
                                ngModel.$setValidity('duplicateInArray', true);
                                return true;
                            }
                        }
                        return false;
                    }
                }
            }
        };
    });

    module.directive('checkUsername', function (personUserSvc, $q, helperSvc, $compile) {
        //this attribute needs to be used with both the name attribute and a 'form-name' attribute that takes in the name of the form that the input is associated with
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {

                if (attrs.formName && attrs.name) {
                    var errorMsg = '<div ng-messages="' + attrs.formName + '.' + attrs.name + '.$error" class="bg-danger" role="alert"><div ng-message="checkUserName">Username already in use</div></div>';
                    elem.parent().append($compile(errorMsg)(scope));
                }

                ngModel.$asyncValidators.checkUserName = function (modelValue) {
                    return personUserSvc.checkDuplicateUserName(modelValue).then(function (data) {
                        var duplicateUserName = helperSvc.getValue(data.data);
                        if (duplicateUserName) {
                            return $q.reject();
                        }
                        return true;
                    });
                };
            }
        };
    });

    module.directive('ckEditor', function ($uibModal, ckeditCommentsSvc, differenceSvc, currentUser, reviewTeamSvc, alertSvc) {
        return {
            restrict: 'A',
            require: '?ngModel',
            scope: {
                customOptions: '<?ckEditor',
                comments: '=?',
                statementData: '=?',
                readOnly: '@?',
                saveComment: '&?'
            },
            link: function (scope, elem, attrs, ngModel) {

                if (!ngModel) return;

                elem.addClass("ck-editor");

                var options;

                if (scope.customOptions) {
                    options = scope.customOptions;
                } else {
                    options = {
                        toolbar: ['heading', 'italic', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'],
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                            ]
                        }
                    };
                }

                var changeCt = 0;

                var myEditor, releaseWatcher;

                InlineEditor.create(elem[0], options).then(function (editor) {
                    myEditor = editor;

                    // Populate editor with ngModel value
                    var defaultStr = 'Click to add text...';
                    var empty = '&nbsp;';

                    editor.setData(ngModel.$viewValue ? ngModel.$viewValue : '<p>' + defaultStr + '</p>');

                    editor.editing.view.document.on('change:isFocused', function (evt, name, value) {
                        var strippedVal = editor.getData() ? editor.getData()
                                                                .replace(/(<([^>]+)>)/ig, "")           // remove markup tags
                                                                .replace(new RegExp(empty, 'g'), "")    // remove &nbsp;
                                                                .trim() : editor.getData();             // remove leading and trailing white space

                        // Delete default text on focus
                        if (value && strippedVal.contains(defaultStr) && strippedVal.length <= defaultStr.length) {
                            editor.setData("");
                        }

                        // Put default text back when focus is lost if no text has been entered
                        if (!value && (strippedVal == empty || strippedVal == "")) {
                            editor.setData('<p>' + defaultStr + '</p>');
                        }
                    }, scope);

                    setReadOnly();

                    var editorHtml = editor.getData();

                    // Register callback to update ngModel value on change events
                    editor.model.document.on('change', function () {
                        scope.$apply(function () {
                            ngModel.$setViewValue(editor.getData());
                        });
                    }, scope);

                    releaseWatcher = scope.$watch('readOnly', function () {
                        setReadOnly();
                    });
                    
                    editor.addComment = function (commentText, id, isResponse) {
                        if (!commentText || commentText.trim() === '') return;

                        commentText = commentText.trim();
                        ckeditCommentsSvc.setDraftFinalStatementCommentData(scope.statementData, scope.comments);                     

                        //add more info about the comment for hiding/showing purposes
                        var comment = {
                            commentText: commentText,
                            markId: id,
                            commentVolunteerId: currentUser.profile.volunteerId,
                            commentVolunteerName: currentUser.profile.firstName + ' ' + currentUser.profile.lastName,
                            teamMemberTypeId: scope.statementData.teamMemberTypeId,
                            statementTypeName: scope.statementData.statementTypeName,
                            commentVolunteerRole: reviewTeamSvc.getTeamMemberTypeAbbrv(scope.statementData.teamMemberTypeId),
                            commentDate: new Date(Date.now()),
                            isSelected: true,
                            editableOptions: scope.statementData.responseType || null
                        };

                        //ckeditCommentsSvc.addComment(comment);
                        ckeditCommentsSvc.updateDraftFinalStatementComments(comment, editor.getData(), scope.saveComment);
                    }

                    editor.decorate('addComment');

                    var clipboardPlugin = editor.plugins.get('Clipboard');
                    var editingView = editor.editing.view;

                    //disallow pasting formatted HTML from clipboard.
                    //all HTML pasted will be stripped and wrapped around a <p> tag
                    editingView.document.on('clipboardInput', function (evt, data) {
                      
                        if (editor.isReadOnly) {
                            return;
                        }

                        if (data.targetRanges && data.targetRanges.length > 0 && data.targetRanges[0].start && data.targetRanges[0].start.parent && data.targetRanges[0].start.parent.parent._children) {
                            var textElements = data.targetRanges[0].start.parent.parent._children;

                            for (var x = 0; x < textElements.length; x++) {
                                var element = textElements[x];

                                if ((element.name || element.parent.name) && (element.name === 'mark' || element.parent.name === 'mark')) {

                                    alertSvc.information(`You may have pasted over a commented portion of text. Press ctrl-z to undo this if you so choose.`, 'Warning');
                                    break;
                                }
                            }
                        }
                        
                        var dataTransfer = data.dataTransfer;
                        var plainText = dataTransfer.getData('text/plain');
                        var content = plainTextToHtml(plainText);
                        
                        var paras = content.split("<p>");
                        var newLines, newParas = [];
                        paras.forEach(function (para) {
                            var lines = para.split("\n");
                            newLines = [];
                            lines.forEach(function (line) {
                                line = line.replace(/^(([a-zA-Z]{1}|[ivx]+|[IVX]+|\d+)([\)|\.]{1})|•|o)\s/g, "");
                                newLines.push(line);
                            });
                            newParas.push(newLines.join("\n"));
                        });
                        content = newParas.join("<p>");

                        content = clipboardPlugin._htmlDataProcessor.toView(content); 
                        
                        clipboardPlugin.fire('inputTransformation', { content: content });



                        editingView.scrollToTheSelection();

                        evt.stop();
                    }, scope);

                    function plainTextToHtml(text) {
                        text = text
                            // Encode <>.
                            .replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;')
                            // Creates paragraphs for double line breaks and change single line breaks to spaces.
                            // Single line breaks are also made to be paragraphs
                            .replace(/\n\n/g, '</p><p>')
                            .replace(/\n/g, '</p><p>')
                            // Replace unicode paragraph separator 
                            .replace(/\u2029/g, '</p><p>')
                            // Preserve trailing spaces (only the first and last one – the rest is handled below).
                            .replace(/^\s/, '&nbsp;')
                            .replace(/\s$/, '&nbsp;')
                            // Preserve other subsequent spaces now.
                            .replace(/\s\s/g, ' &nbsp;');

                        if (text.indexOf('</p><p>') > -1) {
                            // If we created paragraphs above, add the trailing ones.
                            text = '<p>' + text + '</p>'; // no ES6 in IE11... `<p>${text}</p>`;
                        }

                        //remove empty paragraphs
                        text = text.replace(/(<p>\s+<\/p>)/g, '');

                        return text;
                    }

                    function setReadOnly() {
                        editor.isReadOnly = scope.readOnly ? (scope.readOnly.toLowerCase() == 'true' ? true : false) : false;
                    }
                });

                scope.$on('$destroy', function handleDestroyEvent() {
                    if (releaseWatcher) {
                        releaseWatcher();
                    }
                    if (myEditor) {
                        myEditor.destroy();
                        myEditor = null;
                    }
                });

                var templatePath = '/apps/common/templates/misc/';

                scope.addComment = function (linkData) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: templatePath + 'ckEditCommentsEdit.html',
                        size: 'md',
                        controller: 'ckEditCommentsCtrl',
                        resolve: {
                            comments: function () { return scope.comments; },
                            editorLinkData: function () { return linkData; },
                            teamMemberTypeId: function () { return scope.statementData.teamMemberTypeId; }
                        }
                    });
                };

            }
        };
    });

    module.directive('compareTo', function () {
        return {
            require: 'ngModel',
            scope: {
                otherModelValue: '=compareTo'
            },
            link: function (scope, elem, attrs, ngModel) {

                ngModel.$validators.compareTo = function (modelValue) {
                    return modelValue == scope.otherModelValue;
                };

                scope.$watch('otherModelValue', function () {
                    ngModel.$validate();
                });
            }
        };
    });

    module.directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    });

    module.controller('datePickerCtrl', ['$scope', function ($scope) {
        var dateOptions = {
            startingDay: 0,
            showWeeks: false,
            formatMonth: 'MMMM'
        };

        if ($scope.initDate) {
            dateOptions.initDate = new Date($scope.initDate);
        }

        $scope.dateOptions = dateOptions;
    }])
    .directive('datePicker', function ($filter) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'datePicker.html',
            controller: 'datePickerCtrl',
            scope: {
                date: '=ngModel',
                required: '@?',
                blackoutHolidays: '=?',
                startDate: '=?',
                minDate: '=?',
                onChange: '&', 
                initDate: '=?',
                isDisabled: '=?'
            },
            link: function (scope, elem, attrs) {
                scope.isRequired = (attrs.ngRequired ? (attrs.ngRequired === 'true' ? true : false) : attrs.required);

                attrs.$observe('ngRequired', function (val) {
                    scope.isRequired = attrs.ngRequired === 'true' ? true : false;
                });

                scope.$watch("initDate", function (newValue, oldValue) {
                    if (newValue) {
                        console.log(newValue);
                        scope.dateOptions.initDate = new Date(newValue);
                    }
                }); 
                scope.disabled = function (date, mode) {
                    if (scope.blackoutHolidays) {
                        var holidays = scope.blackoutHolidays;

                        var isHoliday = false;
                        for (var i = 0; i < holidays.length ; i++) {
                            if (areDatesEqual(holidays[i], date)) {
                                isHoliday = true;
                            }
                        }

                        return (mode === 'day' && isHoliday);
                    }
                    return false;
                };

               // scope.isRequired = (attrs.required);

                scope.popup = {
                    isOpen: false
                };

                scope.open = function ($event) {
                    if (scope.startDate && !scope.date) {
                        scope.date = scope.startDate;
                    } else if (!scope.startDate) {
                        scope.checkMinDate();
                    }

                    $event.preventDefault();
                    $event.stopPropagation();
                    scope.popup.isOpen = !scope.popup.isOpen;
                };

                scope.checkMinDate = function () {
                    if (scope.minDate && !scope.date) {
                        scope.date = scope.minDate;
                    } else if (scope.minDate && scope.date) {
                        var min = new Date(scope.minDate);
                        var current = new Date(scope.date);
                        if (current < min) scope.date = scope.minDate;
                    }
                };
                
                scope.changeDate = function (date) {
                    scope.onChange(date);
                };

                function areDatesEqual(date1, date2) {
                    return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)
                }
            }
        };
    });
    
    module.directive('datepickerLocaldate', ['$parse', function ($parse) {
        // only needed for the datepicker. Same problem we were having, pulled from https://gist.github.com/weberste/354a3f0a9ea58e0ea0de
        return {
            restrict: 'A',
            require: ['ngModel'],
            link: function (scope, elem, attr, ctrl) {
                var ngModelController = ctrl[0];

                // called with a JavaScript Date object when picked from the datepicker
                // BECAUSE the datepicker directive has our custom change we must check for null or undefined values       
                ngModelController.$parsers.push(function (viewValue) {
                    if (viewValue !== null && viewValue !== undefined) {
                        // undo the timezone adjustment we did during the formatting
                        viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
                        // we just want a local date in ISO format
                        return viewValue.toISOString().substring(0, 10);
                    }
                    return viewValue;
                });

                // called with a 'yyyy-mm-dd' string to format
                ngModelController.$formatters.push(function (modelValue) {
                    if (!modelValue) {
                        return undefined;
                    }
                    // date constructor will apply timezone deviations from UTC (i.e. if locale is behind UTC 'dt' will be one day behind)
                    var dt = new Date(modelValue);
                    // 'undo' the timezone offset again (so we end up on the original date again)
                    dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                    return dt;
                });
            }
        };
    }]);
    
    module.directive('multiselectDropdown', function () {
        return {
            restrict: 'AE',
            templateUrl: templateRoot + 'multiselectDropdown.html',
            require: '^?form',
            scope: {
                selectedModel: '=', // reference to an array of "currently selected" objects
                options: '=?', // Array<object> the available options for selecting, which contain display name and ID fields, defined below as optionsDisplay and optionsId
                optionsFilterFunc: '&?',
                disabled: '=',
                events: '=',
                optionsDisplay: '@', // String, gets set to settings.displayProp in getDisplayName
                optionsDisplayFunc: '&?',
                optionsId: '@', // String, attribute name for "id" field in "option" objects
                changeFunc: '&?',
                closeFunc: '&?',
                buttonText: '@?',
                required: '@?',
                requiredFunction: '&',
                buttonMaxNumber: '<?', // a way to set "maxNumber" externally, which is the max number of selected labels that are displayed in the unexpanded dropdown
                buttonMaxTextLength: '<?',
                maxHeight: '@?',
                hideCheckAll: '@?',
                selectionText: '@?' // String, "-- SELECT {selectionText} --"
            },
            link: function (scope, elem, attrs, ctrl) {
                var $dropdownTrigger = elem.children()[0];

                scope.hideCheckAll = attrs.hideCheckAll ? (scope.hideCheckAll.toLowerCase() === 'true') : false;

                if (scope.optionsFilterFunc) {
                    scope.options = scope.optionsFilterFunc();
                }

                scope.toggleDropdown = function () {
                    if (scope.open && scope.closeFunc)
                        scope.closeFunc();

                    scope.open = !scope.open;
                };

                scope.closeDropdown = function () {
                    if (scope.open && scope.closeFunc)
                        scope.closeFunc();

                    scope.open = false;    
                };

                scope.checkboxClick = function ($event, id) {
                    scope.setSelectedItem(id);
                    $event.stopImmediatePropagation();
                };

                scope.externalEvents = {
                    onItemSelect: angular.noop,
                    onItemDeselect: angular.noop,
                    onSelectAll: angular.noop,
                    onDeselectAll: angular.noop,
                    onInitDone: angular.noop,
                    onMaxSelectionReached: angular.noop
                };

                scope.settings = {
                    dynamicTitle: true,
                    scrollable: false,
                    scrollableHeight: '300px',
                    displayProp: scope.optionsDisplay,
                    displayFunc: scope.optionsDisplayFunc,
                    idProp: scope.optionsId,
                    externalIdProp: 'id',
                    enableSearch: false,
                    selectionLimit: 0,
                    showCheckAll: !scope.hideCheckAll,
                    showUncheckAll: !scope.hideCheckAll,
                    closeOnSelect: false,
                    buttonClasses: 'btn btn-default',
                    closeOnDeselect: true,
                    groupBy: attrs.groupBy || undefined,
                    groupByTextProvider: null,
                    smartButtonMaxItems: 4, //picked for commission number
                    smartButtonTextConverter: angular.noop,
                    buttonMaxTextLength: 40,
                    maxHeight: scope.maxHeight ? scope.maxHeight : '300px'
                };

                scope.texts = {
                    checkAll: 'Check All',
                    uncheckAll: 'Uncheck All',
                    selectionCount: 'checked',
                    selectionOf: '/',
                    searchPlaceholder: 'Search...',
                    buttonDefaultText: '-- SELECT ' + (scope.selectionText ? scope.selectionText : '') + ' --',
                    dynamicButtonTextSuffix: 'checked'
                };

                scope.isRequired = function () {
                    if(scope.required === 'true')
                        return true;
                    if(scope.requiredFunction() === true)
                        return true;
                    return false;
                };

                scope.getDisplayName = function (option) {
                    var displayName;
                    if (scope.settings.displayFunc) {
                        displayName = scope.settings.displayFunc({ option: option });
                    } else {
                        displayName = scope.getPropertyForObject(option, scope.settings.displayProp);
                    }
                    return displayName;
                };

                scope.getButtonText = function () {
                    if (scope.buttonText) {
                        return scope.buttonText;
                    }

                    var maxNumber;
                    if (scope.buttonMaxNumber)
                        maxNumber = scope.buttonMaxNumber;
                    else
                        maxNumber = scope.settings.smartButtonMaxItems;

                    if (scope.settings.dynamicTitle && scope.selectedModel && (scope.selectedModel.length > 0)){

                        if (maxNumber > 0) {
                            var itemsText = [];

                            angular.forEach(scope.options, function (optionItem) {
                                if (scope.isChecked(scope.getPropertyForObject(optionItem, scope.settings.idProp))) {
                                    var displayText = scope.getDisplayName(optionItem);
                                    var converterResponse = scope.settings.smartButtonTextConverter(displayText, optionItem);

                                    itemsText.push(converterResponse ? converterResponse : displayText);
                                }
                            });

                            if (scope.selectedModel.length > maxNumber) {
                                itemsText = itemsText.slice(0, maxNumber);
                                itemsText.push('...');
                            }

                            var maxLength = scope.settings.buttonMaxTextLength;
                            if (scope.buttonMaxTextLength)
                                maxLength = scope.buttonMaxTextLength
                            //creates the button text, get only the length requied and then trims so that nothing is landing on a . or ,
                            var text = itemsText.join(', ');                        
                            var finalText = text.substring(0, maxLength);
                            if (text.length > finalText.length) {
                                while (finalText.endsWith(',') || finalText.endsWith('.')) {
                                    finalText = finalText.slice(0, finalText.length - 1);
                                }
                                finalText += "..."
                            }
                            return finalText;
                        }

                    }
                    return scope.texts.buttonDefaultText;
                };

                scope.getPropertyForObject = function (object, property) {
                    if (angular.isDefined(object) && object.hasOwnProperty(property)) {
                        return object[property];
                    } else if (angular.isDefined(object) && property && object.hasOwnProperty(property.split('.')[0])){                    
                        var result = findNestedProperty(object, property);
                 
                        return result;
                    }
                    return '';
                };

                function findNestedProperty(object, property) {

                    var nestedProperties = property.split('.');

                    if (nestedProperties.length == 1) {
                        return object[property];
                    }
                    else {
                        //get this properties object
                        var nestedObject = object[nestedProperties.shift()];
                        var propertyString = nestedProperties.join('.');

                        return findNestedProperty(nestedObject, propertyString);
                    }
                };

                scope.allSelected = false;

                scope.selectAll = function () {
                    scope.allSelected = !scope.allSelected;

                    if (scope.allSelected) {
                        scope.deselectAll(false, true);
                        scope.externalEvents.onSelectAll();

                        angular.forEach(scope.options, function (value) {
                            scope.setSelectedItem(value[scope.settings.idProp], true);
                        });

                        //must call after selecting all because otherwise it builds on each select call and calls the changeFunc iteratively
                        if (scope.changeFunc) {
                            scope.changeFunc();
                        }
                    } else {
                        scope.deselectAll(true);
                    }
                };

                scope.setSelectAll = function () {
                    scope.allSelected = !scope.allSelected;
                };

                scope.deselectAll = function (sendEvent, isSelectAll) {
                    sendEvent = sendEvent || true;

                    if (sendEvent) {
                        scope.externalEvents.onDeselectAll();
                    }

                    scope.selectedModel.splice(0, scope.selectedModel.length);

                    if (scope.changeFunc && !isSelectAll) {
                        scope.changeFunc();
                    }

                };

                 scope.setSelectedItem = function (id, isSelectAll) {
                     var item = findItem(id);
                     var exists = findItemIndexInSelected(id) !== null;

                     if (exists) {
                         scope.selectedModel.splice(findItemIndexInSelected(id), 1);
                         scope.externalEvents.onItemDeselect(item);

                     } else if (!exists) {
                         scope.selectedModel.push(item);
                         scope.externalEvents.onItemSelect(item);
                     }

                     if (scope.changeFunc && !isSelectAll) {
                         scope.changeFunc();
                     }
                 };

                 scope.isChecked = function (id) {
                     if (id !== "" && scope.selectedModel !== undefined && scope.selectedModel !== null ) {
                         for (var i = 0; i < scope.selectedModel.length; i++) {

                             var item = scope.selectedModel[i];
                             var itemId = scope.getPropertyForObject(item, scope.optionsId);

                             if (itemId === id) {
                                 return true;
                             }
                         }
                     }
                     return false;
                 };

                scope.externalEvents.onInitDone();

                function clearObject(object) {
                    for (var prop in object) {
                        delete object[prop];
                    }
                }

                function findItem(id) {
                    for (var i = 0; i < scope.options.length; i++) {
                        var item = scope.options[i];
                        var itemId = scope.getPropertyForObject(item, scope.optionsId);

                        if (itemId === id) {
                            return scope.options[i];
                        }
                    }
                    return null;
                }

                function findItemIndexInSelected(id) {
                    if (scope.selectedModel !== undefined) {
                        for (var i = 0; i < scope.selectedModel.length; i++) {

                            var item = scope.selectedModel[i];
                            var itemId = scope.getPropertyForObject(item, scope.optionsId);

                            if (itemId === id) {
                                return i;
                            }
                        }
                    }
                    return null;
                }
            
                if (scope.isRequired() && ctrl) {
                    scope.validate = function () {
                        var isValid;

                        if (scope.selectedModel.length > 0) {
                            isValid = true;
                            scope.requiredClass = 'valid';
                        } else {
                            isValid = false;
                            scope.requiredClass = 'invalid';                                       
                        }
                        ctrl.$setValidity('multiselectDropdown', isValid);

                    }

                    scope.$watch('selectedModel', scope.validate, true);
                }

                //could add if statemnet for only when dropdown is opened to bind to click to avoid performance hit if many of these multiselects
                //right now its always watching whenever a multiselect is instantiated
                $(document).bind('click', function (event) {
                    var isClickedElementChildOfPopup = elem
                        .find(event.target)
                        .length > 0;

                    if (isClickedElementChildOfPopup)
                        return;

                    scope.$apply(function () {
                        scope.closeDropdown();
                    });
                });
            }
        };
    });

    module.directive('numberRange', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'numberRange.html',
            scope: {
                range: '='
            }
        };
    });

    module.directive('onEnter', function () {
        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {
                elem.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$evalAsync(attrs.onEnter);
                        event.preventDefault();
                    }
                });
            }
        };
    });

    module.directive('onFinishRender', function () {
        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {
                if (scope.$last) {
                    scope.$evalAsync(attrs.onFinishRender);
                }
            }
        };
    });

    module.directive('passwordReset', function (Complexify) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'passwordReset.html',
            scope: {
                newPassword: '=',
                userName: '<'
            },
            link: function (scope) {
                scope.updateType = function () {
                    var complexity = Complexify(scope.newPassword).complexity;
                    var type;
                    console.log("reset");
                    if (complexity < 25) {
                        type = 'danger';
                    } else if (complexity < 50) {
                        type = 'warning';
                    } else if (complexity < 75) {
                        type = 'info';
                    } else {
                        type = 'success';
                    }

                    scope.progressBarColor = type;
                };
            }
        };
    });

    module.directive('selectionRequired', function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            priority: 10,
            link: function (scope, elem, attrs, ctrl) {

                if (!ctrl) return;
                var originalRender = ctrl.$render.bind(ctrl);

                ctrl.$render = function () {
                    originalRender();
                    if (ctrl.$modelValue == 0 || ctrl.$isEmpty(ctrl.$modelValue)) {
                        ctrl.$setViewValue(undefined);
                    }
                };
            }
        };
    });

    module.directive('survey', function ($timeout, surveyTemplateSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'survey.html',
            scope: {
                survey: '=',
                autosaveFunc: '&?autosave',
                preview: '@?',
                useWidgets: '@?'
            },
            link: function (scope, elem, attrs) {
                scope.questionTypes = surveyTemplateSvc.questionTypes;

                scope.options = {
                    preview: attrs.preview ? (scope.preview.toLowerCase() == 'true' ? true : false) : false,
                    hasWidgets: attrs.useWidgets ? (scope.useWidgets.toLowerCase() == 'true' ? true : false) : false,
                    disableAutosave: attrs.autosave ? false : true
                };

                scope.showSubgroup = function (question, subgroup) {
                    if (question.childGroups && question.childGroups.length > 0 && question.userAnswer) {
                        return subgroup.showConditionIds.some(function (id) {
                            if (angular.isArray(question.userAnswer)) {
                                return question.userAnswer.some(function (answer) {
                                    return id == answer.optionId;
                                });
                            } else {
                                try {
                                    return id == JSON.parse(question.userAnswer).optionId;
                                } catch (e) {
                                    return id == question.userAnswer.optionId;
                                }
                            }
                        });
                    }
                    return false;
                };

                scope.showMultiSelectComment = function (question, option) {
                    var isSelected = false;
                    if (angular.isArray(question.userAnswer)) {
                        isSelected = question.userAnswer.some(function (answer) {
                            return answer.optionId == option.optionId;
                        });
                    } else {
                        isSelected = question.userAnswer === undefined ? false : question.userAnswer.optionId == option.optionId;
                    }
                    return isSelected && option.isCommentRequired;
                };

                scope.doMultiSelect = function (question, option) {
                    var optionObj = {
                        optionId: option.optionId,
                        option: option.option
                    };

                    if (!question.userAnswer) {
                        question.userAnswer = [optionObj];
                    } else if (angular.isArray(question.userAnswer)) {
                        var duplicateIndex = question.userAnswer.findIndex(function (answer) {
                            return answer.optionId == optionObj.optionId;
                        });

                        if (duplicateIndex > -1) {
                            question.userAnswer.splice(duplicateIndex, 1);
                        } else {
                            question.userAnswer.push(optionObj);
                        }
                    }
                };

                scope.setLineBreaks = function (options) {
                    return options.length > 3 || options.some(function (option) { return option.option.length > 10; });
                };

                var autosaving = false;
                var currentQuestion = null;

                scope.showAutosave = function (question) {
                    return question == currentQuestion;
                };

                scope.autosave = function (question) {
                    if (autosaving) {
                        $timeout.cancel(autosaving);
                    }

                    autosaving = $timeout(function () {
                        currentQuestion = question;
                        scope.autosaveFunc();
                        $timeout(function () { currentQuestion = null; }, 2000);
                    }, 2000);
                };

                scope.getStringFromJson = function (json) {
                    return JSON.parse(json);
                }

                var MIN_PROP = 'commentMinLength';
                var MAX_PROP = 'commentMaxLength';

                scope.getMinLen = function (item, parent) {
                    var answerProp = parent ? 'comment' : 'userAnswer';
                    var length = item[answerProp] ? item[answerProp].length : 0;
                    return length + "/" + getProp(MIN_PROP, item, parent);
                };

                scope.getMaxLen = function (item, parent) {
                    var answerProp = parent ? 'comment' : 'userAnswer';
                    var length = item[answerProp] ? item[answerProp].length : 0;
                    var maxLength = getProp(MAX_PROP, item, parent);
                    //return item[answerProp] ? maxLength - length : maxLength;
                    return length + "/" + maxLength;
                };

                scope.getAnswerLen = function (item) {
                    return item ? item.length : 0;
                }

                scope.showCharMin = function (item, parent) {
                    var answerProp = parent ? 'comment' : 'userAnswer';
                    var minLength = getProp(MIN_PROP, item, parent);
                    return minLength && (item[answerProp] ? (item[answerProp].length < minLength) : true);
                };

                scope.showCharMax = function (item, parent) {
                    var answerProp = parent ? 'comment' : 'userAnswer';
                    var maxLength = getProp(MAX_PROP, item, parent);
                    return maxLength && (item[answerProp] ? (item[answerProp].length <= maxLength) : true);
                };

                function getProp(prop, item, parent) {
                    return item.hasOwnProperty(prop) ? item[prop] : parent[prop];
                }
            }
        };
    });

}(angular.module('common')));