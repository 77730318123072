(function (module) {
    var createAccountCtrl = function ($state, $uibModal, $stateParams, personSvc, personEmailSvc, helperSvc) {

        var model = this;

        model.title = 'Create New Account';
        model.isPevMode = $stateParams.type == 'pev';

        model.reset = function () {
            model.person = {};
            model.existingAccounts = [];
            model.isSubmitted = false;
        };

        model.reset();

        model.back = function () {
            $state.go('user');
        }

        model.confirmAccount = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/pevApp/templates/createAccountConfirm.html',
                size: 'lg',
                controller: 'createAccountConfirmCtrl',
                resolve: {
                    parent: function () { return model; }
                }
            });
        };

        model.submit = function () {
            var publicSearchPromise = {
                dataHolder: model,
                dataLocationName: 'existingAccounts',
                svcCallback: personSvc.publicSearch,
                svcCallbackArguments: [model.person],
                helperCallback: function (data) {
                    return data.data.value;
                }
            };

            var checkForDuplicatesPromise = {
                dataHolder: model,
                dataLocationName: 'hasDuplicateEmails',
                svcCallback: personEmailSvc.checkForDuplicates,
                svcCallbackArguments: [0, model.person.emailAddress, false],
                helperCallback: helperSvc.getValue
            };

            var promiseArray = [
                publicSearchPromise,
                checkForDuplicatesPromise
            ];

            helperSvc.getData(promiseArray).then(function () {
                if (model.existingAccounts.length > 0) {
                    model.confirmAccount();
                } else {
                    model.createNewAccount(false);
                }
            });
        };

        model.createNewAccount = function (checkforDuplicates) {
            if (checkforDuplicates) {
                personEmailSvc.checkForDuplicates(0, model.person.emailAddress, false).then(function (data) {
                    var hasDups = helperSvc.getValue(data);
                    if (!hasDups) {
                        insertPerson();
                    } else {
                        model.confirmAccount(); // this should not be reachable... is it?
                    }
                });
            } else {
                insertPerson();
            }
        };

        function insertPerson() {
            personSvc.publicInsert(model.person).then(function (data) {
                model.reset();
                model.isSubmitted = true;
            });
        }
    };

    module.controller('createAccountCtrl', createAccountCtrl);

}(angular.module('pevApp')));