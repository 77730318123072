(function (module) {
    'use strict';

    var rfrValidationSvc = function ($q, eventSvc, typeConstSvc, rfrSvc, programReviewTypeIds, organizationSvc, helperSvc, programSvc, institutionReviewOptionsHelperSvc) {
        var factory = {};
        var results = [];//{slug: 1, message: "..some messages.."}
        var rfr = null;
        var outsideUS = null;

        factory.invokeValidation = function () {
            eventSvc.broadcast('ValidateRfr');
        };

        factory.listenToValidate = function (callback) {
            eventSvc.listen(callback, 'ValidateRfr');
        };

        factory.validate = function (rfr, commissionId) {
            var resultFromServer = [];

            if (Array.isArray(rfr))
                rfr = rfr[0];

            var serverPromise = validateFromServer(rfr).then(function (response) {
                var serverMessages = response.data.validationResultItems;
                for (var i = 0; i < serverMessages.length; i++) {
                    resultFromServer.push({ slug: serverMessages[i].slug, message: serverMessages[i].message });
                }
            });

            var clientPromise = organizationSvc.getOrganizationByUser(rfr.organizationId).then(function (data) {
                var organization = helperSvc.getItem(data);
                // These checks for null and undefined may not be necessary but can't be sure at this point.
                if (organization !== null && organization !== undefined &&
                    organization.currentOrganizationAddressDto !== null && organization.currentOrganizationAddressDto !== undefined &&
                    organization.currentOrganizationAddressDto.countryCode !== undefined &&
                    organization.currentOrganizationAddressDto.countryCode === 'US') {
                    outsideUS = false;
                } else {
                    outsideUS = true;
                };

                var clientMessages = validateFromClient(rfr, commissionId);
                for (var i = 0; i < clientMessages.length; i++) {
                    resultFromServer.push({ slug: clientMessages[i].slug, message: clientMessages[i].message });
                }
            });

            return $q.all([serverPromise, clientPromise]).then(function () {
                return resultFromServer;
            });
        }

        factory.populateWarnings = function (navigation, isAdmin) {
            factory.validate(rfrSvc.data.rfr).then(function (results) {

                for (var i = 0; i < navigation.length; i++) {
                    navigation[i].warningMessages = [];
                }

                for (var i = 0; i < results.length; i++) {
                    var slug = results[i].slug;

                    for (var j = 0; j < navigation.length; j++) {
                        var tab = navigation[j];

                        if (tab.slug === slug) {
                            tab.warningMessages.push(results[i].message);
                        }
                    }
                }
            });
        };

        function validateFromClient(rfr, commissionId) {
            results = [];

            for (var i = 0; i < rfr.rfrCommissionDtos.length ; i++) {
                var rfrCommission = rfr.rfrCommissionDtos[i];

                if (commissionId) {
                    if (rfrCommission.commissionId === commissionId) {
                        completeAllClientChecks(rfrCommission);
                    }
                } else {
                    completeAllClientChecks(rfrCommission);
                }
            }
            totalCampus(rfr);

            return results;
        }

        function validateFromServer(data) {
            return rfrSvc.validate(data);
        }

        function completeAllClientChecks(rfrCommission) {
            observerOptions(rfrCommission);
            programValidation(rfrCommission);
        }

        function observerOptions(rfrCommission) {
            if (isAllTerminate(rfrCommission)) {
                rfrSvc.data.commissionErrors[rfrCommission.commissionId] = [];
                return;
            }

            if (!rfrSvc.isCommissionFocusedReport(rfrCommission) && institutionReviewOptionsHelperSvc.getRequiredPevs(rfrCommission.jsonContents) > 0) {
                //creating an attribute that needs to be deleted before any saving of the rfr... taken care of in the rfrSvc
                rfrSvc.data.commissionErrors[rfrCommission.commissionId] = [];

                if (rfrCommission.jsonContents.stateboardObserverFlag == null)
                    rfrSvc.data.commissionErrors[rfrCommission.commissionId].push("State Observer option selection is incomplete");

                if (rfrCommission.jsonContents.societyObserverFlag == null)
                    rfrSvc.data.commissionErrors[rfrCommission.commissionId].push("Society Observer option selection is incomplete");

                if (rfrCommission.jsonContents.abetObserverFlag == null)
                    rfrSvc.data.commissionErrors[rfrCommission.commissionId].push("ABET Observer option selection is incomplete");

                if (rfrCommission.jsonContents.suggestedVisitDate == null)
                    rfrSvc.data.commissionErrors[rfrCommission.commissionId].push("Review requires a suggested visit date.");

                if (rfrSvc.data.commissionErrors[rfrCommission.commissionId].length > 0)
                    results.push({ slug: rfrSvc.slugs.REVIEW, message: typeConstSvc.getCommissionAbbreviationById(rfrCommission.commissionId) + " review options contains errors." });
            }
        }

        function isAllTerminate(rfrCommission) {

            for (var i = 0; i < rfrCommission.jsonContents.programs.length; i++) {
                var program = rfrCommission.jsonContents.programs[i];

                if (!isTermination(program)) {
                    return false;
                }
            }
            return true;
        }

        function totalCampus(rfr) {
            rfrSvc.data.rfrErrors = [];

            if (!rfrSvc.checkForAllCommissionsAreFocusedReport(rfr)) {

                if (!rfr.noOfInstitutionLocations) {
                    rfrSvc.data.rfrErrors.push("The number of institution locations is required");
                }

                if (rfrSvc.data.rfrErrors.length > 0)
                    results.push({ slug: rfrSvc.slugs.REVIEW, message: "Institutional review options contains errors" });
            }
        }

        function programValidation(rfrCommission) {

            for (var i = 0; i < rfrCommission.jsonContents.programs.length ; i++) {
                var program = rfrCommission.jsonContents.programs[i];
                var programErrors = [];
                if (!isTermination(program))
                    programErrors = programSvc.validateProgram(program, outsideUS);

                rfrSvc.data.programErrors[rfrCommission.commissionId + program.programName + program.degreeCode] = programErrors;

                //only add it if the program contains errors
                if (programErrors.length > 0)
                    results.push({
                        slug: rfrSvc.slugs.PROGRAMS, message: rfrCommission.commissionAbbreviatedName + " " + 
                                                              program.programName + " (" + program.degreeCode + ")" +
                                                              " contains errors."
                    });
            }

            if (!rfrCommission.jsonContents.programs || rfrCommission.jsonContents.programs.length == 0) {
                results.push({ slug: rfrSvc.slugs.PROGRAMS, message: "No programs have been added to " + typeConstSvc.getCommissionAbbreviationById(rfrCommission.commissionId) + " review" });
            }
        }

        function isTermination(program) {
            return program.programReviewTypeCode === null
                || program.programReviewTypeCode === programReviewTypeIds.TERMINATIONREPORT
                || program.programReviewTypeCode === programReviewTypeIds.TERMINATIONVISIT
                || program.programReviewTypeCode === programReviewTypeIds.TERMINATION;
/*                || rfrSvc.data.npcData.some(commission => commission?.some(npc => npc.programId === program.programId && npc.npcType.toLowerCase() === "termination"));*/
        }

        return {
            validate: factory.validate,
            invokeValidation: factory.invokeValidation,
            listenToValidate: factory.listenToValidate,
            populateWarnings: factory.populateWarnings
        };
    };

    module.factory('rfrValidationSvc', rfrValidationSvc);

})(angular.module('rfr'));