(function (module) {

    var personEmailSvc = function ($http, $q, amsConst, $odataresource, odataSvc, contactTypes) {
        var apiPath = amsConst.webApiUrl + '/odata/PersonEmail';
        var factory = {};
        var keyName = 'personEmailId';

         var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getByPersonId = function (personId) {
            //// odata/PersonEmail?$filter=personId eq 1
            //return $http.get(apiPath + '?$filter=personId eq ' + personId).then(onSuccess, onFailure);

            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonEmailsByPersonId', 'personId', personId)

            oSvc.getSource(path, keyName).odata()
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };
        
        factory.save = function (emails) {
            var data = { "Value": emails };

            return $http.post(apiPath + '/Ams.SavePersonEmails', data).then(onSuccess, onFailure);
        };

        factory.getPreferredEmail = function (personId) {
            return $http.get(apiPath + '?$filter=personId eq ' + personId + ' and isPreferred eq true').then(onSuccess, onFailure);
        };

        factory.getAllABETActivitiesEmail = function (personId) {
            return $http.get(apiPath + '?$filter=personId eq ' + personId + ' and contactTypeId eq ' + contactTypes.ALLABETACTIVITIES).then(onSuccess, onFailure);
        };

        factory.getEmailByContactType = function (personId, contactType) {
            return $http.get(apiPath + '?$filter=personId eq ' + personId + ' and contactTypeId eq ' + contactType).then(onSuccess, onFailure);
        };

        factory.checkForDuplicates = function (personId, email, isUpdating) {

            var apiDuplicateEmailsPath = amsConst.webApiUrl + '/odata/CheckDuplicateEmails({0})';

            return $http.get(apiDuplicateEmailsPath.format('personId=' + personId + ',email=\'' + email + '\',isUpdating=' + isUpdating)).then(onSuccess, onFailure);

        };

        factory.getByEmailAddress = function (email) {
            return $http.get(apiPath + '?$filter=emailAddress eq ' + email).then(onSuccess, onFailure);
        };

        return {
            getByPersonId: factory.getByPersonId,
            save: factory.save,
            getPreferredEmail: factory.getPreferredEmail,
            getAllABETActivitiesEmail: factory.getAllABETActivitiesEmail,
            checkForDuplicates: factory.checkForDuplicates,
            getByEmailAddress: factory.getByEmailAddress,
            getEmailByContactType: factory.getEmailByContactType
        };
    };
    module.factory('personEmailSvc', personEmailSvc);

})(angular.module('person'));