(function (module) {

    var editorAssignmentToolCtrl = function ($scope, $stateParams, $filter, assignmentSvc, tcAssignmentSvc, alertSvc, teamMemberTypeNames) {
        var model = this;

        model.editorType = parseInt($stateParams.type);
        model.title = 'Editor ' + model.editorType + ' Assignment Tool';
        model.isDataReady = false;
        model.showMatrix = false;
        model.filteredReviewTeams = [];
        model.filteredCommissioners = [];
        model.hasResults = {
            left: false,
            right: false,
            all: false
        };

        model.reviewTeamFilters = model.editorType === 1 ? tcAssignmentSvc.filters.editor1ReviewTeamFilters : tcAssignmentSvc.filters.editor2ReviewTeamFilters;
        model.commissionerFilters = model.editorType === 1 ? tcAssignmentSvc.filters.editor1CommissionerFilters : tcAssignmentSvc.filters.editor2CommissionerFilters;

        model.getFilteredReviewTeams = function () {
            var reviewTeamsFilter = $filter('reviewTeams');

            model.filteredReviewTeams = reviewTeamsFilter(model.data.reviewTeams, model.reviewTeamFilters, 'editor' + model.editorType);
            model.hasResults.left = true;

            if (model.hasResults.left && model.hasResults.right) model.hasResults.all = true;
        };

        model.getFilteredCommissioners = function () {
            var commissionersFilter = $filter('commissioners');

            model.filteredCommissioners = commissionersFilter(model.data.commissioners, model.commissionerFilters, model.editorType === 1 ? teamMemberTypeNames.EDITOR1 : teamMemberTypeNames.EDITOR2);
            model.hasResults.right = true;

            if (model.hasResults.left && model.hasResults.right) model.hasResults.all = true;
        };

        model.assignEditor = function (commissioner, teamIndex, type) {
            var assignmentType = type === 1 ? assignmentSvc.assignmentTypes.EDITOR1 : assignmentSvc.assignmentTypes.EDITOR2;
            assignmentSvc.assign(commissioner, assignmentType, model.selectedReviewTeams[teamIndex], model.selectedCommissioners);
        };

        model.unassign = function (volunteer, teamMemberTypeId, teamIndex) {
            assignmentSvc.unassign(volunteer, teamMemberTypeId, model.selectedReviewTeams[teamIndex], model.selectedCommissioners);
        }

        activate();

        function activate() {

            tcAssignmentSvc.loadAllData().then(function () {
                assignmentSvc.addBodyClass();
                tcAssignmentSvc.resetFilters();
                model.data = tcAssignmentSvc.data;
                model.isDataReady = true;
            });
        }

        assignmentSvc.removeBodyClassOnPageChange($scope);
    };

    module.controller('editorAssignmentToolCtrl', editorAssignmentToolCtrl);

}(angular.module('assignment')));