(function (module) {

    var reportCtrl = function ($scope, $window) {
        var model = $scope;

        var options = new $window.Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.fullScreenMode = false;

        var designer = new $window.Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
        var report = new $window.Stimulsoft.Report.StiReport();

        report.loadFile('/SimpleList.mrt');
        designer.report = report;

        designer.renderHtml('designer');
    };

    module.controller('reportCtrl', reportCtrl);

}(angular.module('common')));