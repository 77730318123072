(function (module) {

    var addProgramAuditDetailFindingsCtrl = function (program, programAuditDetail, $scope, $uibModalInstance, alertSvc, readinessSvc,
        criteriaTypes, programAuditDetailTemplateSvc, programAuditDetailEvaluatorSvc, programAuditDetailSvc) {

        var model = $scope;

        model.title = 'Add Finding';
        model.program = program;
        model.programAuditDetail = angular.copy(programAuditDetail);
        model.criteria = null;
        model.findingTypeOptions = null;
        model.isDataReady = false;

        model.getCriteriaFullName = readinessSvc.getCriteriaFullName;

        model.isShortcoming = programAuditDetailTemplateSvc.isShortcoming;

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            programAuditDetailTemplateSvc.addFindings(model.program.commissionId, model.programAuditDetail, model.findingTypeOptions);

            model.isSaving = true;
            // Note: CRUD probably belongs in parent controller, not in a modal dialog; want to be consistent and avoid UI refresh flicker
            programAuditDetailSvc.update(model.programAuditDetail).then((programAuditDetail) => {
                const shortcomingsAdded = model.findingTypeOptions.some(findingType =>
                    findingType.value && programAuditDetailTemplateSvc.isShortcoming(findingType.statementFindingTypeId)
                );
                if (shortcomingsAdded)
                    programAuditDetailEvaluatorSvc.resetProgramAuditRecommendedActions(programAuditDetail.programAuditDetailEvaluatorDtos).then((programAuditDetailEvaluators) => {
                        programAuditDetail.programAuditDetailEvaluatorDtos = programAuditDetailEvaluators;
                        onSuccess(programAuditDetail);
                    }).catch(onError);
                else
                    onSuccess(programAuditDetail);
            }).catch(onError);

            function onSuccess(programAuditDetail) {
                alertSvc.addAlertSuccess('Finding(s) added.');
                $uibModalInstance.close(programAuditDetail);
            }

            function onError(error) {
                console.log(error);
                model.isSaving = false;
                alertSvc.addModalAlertWarning('Add finding(s) failed.')
            }
        };

        model.getAvailableCriteria = function (statementFindingTypeId) {
            const existingCriteria = new Set(
                model.programAuditDetail.programAuditJson.auditDetails.filter(auditDetail =>
                    auditDetail.statementFindingTypeId === statementFindingTypeId
                ).flatMap(auditDetail =>
                    auditDetail.findings.map(finding =>
                        finding.criteria.criteriaId
                    )
                )
            );

            const availableCriteria = model.criteria.filter(criterion =>
                // One shortcoming type per criteria except APPM where shortcomings of same type can reference different sub-sections
                !existingCriteria.has(criterion.criteriaId) || criterion.criteriaTypeId === criteriaTypes.APPM
            );

            return availableCriteria;
        }

        model.isCriteriaNeeded = function (findingTypeOption) {
            if (findingTypeOption.value === true) {
                return true;
            }
            return false;
        }

        model.isValid = function () {
            return programAuditDetailTemplateSvc.validateFindingTypeOptions(model.findingTypeOptions);
        }

        activate();

        function activate() {
            programAuditDetailTemplateSvc.getAllProgramAuditTemplateData(model.program).then((data) => {
                Object.assign(model, data);
                model.isDataReady = true;
            });
        };
    };

    module.controller('addProgramAuditDetailFindingsCtrl', addProgramAuditDetailFindingsCtrl);

}(angular.module('programAudit')));