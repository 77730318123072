(function (module) {

    var programAuditToolCtrl = function ($q, $state, $stateParams, $uibModal, $timeout, $scope, $filter, $window, helperSvc, oauth, alertSvc, currentUser,
        statementFindingTypes, teamMemberTypeNames, teamMemberTypeAbbrvIds, actionSvc, reviewTeamSvc, programAuditAccessSvc,
        programAuditDetailSvc, programAuditDetailTemplateSvc, programAuditDetailEvaluatorSvc, deepObjectDifferenceSvc,
        programAuditDetailDifferenceSvc, statementStorageSvc, programAuditValidationSvc, programReviewTypes, refreshSiteSvc) {

        const model = this;

        // State Params
        model.programAuditId = parseInt($stateParams.programAuditId) || 0;
        model.programId = parseInt($stateParams.programId) || 0;
        model.programAuditDetailId = parseInt($stateParams.programAuditDetailId) || 0;

        // Type Constants
        model.statementFindingTypes = statementFindingTypes;
        model.teamMemberTypeNames = teamMemberTypeNames;
        model.differenceTypes = deepObjectDifferenceSvc.differenceTypes;

        // Model Data
        model.dataForPAF = null;
        model.editorOptions = {
            toolbar: ['heading', '|', 'undo', 'redo', 'comment'],
            removePlugins: ['Autoformat'], //disables ckeditor's autoformats i.e. typing "1. " creates a numbered list and "> " creates a blockquote
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                ]
            }
        };
        model.organization = null;
        model.program = null;
        model.programAudit = null;
        model.programAuditAccess = [];
        model.programAuditDetail = null;
        model.programAuditDetailHistory = [];
        model.programReviews = [];
        model.reviewTeam = null;
        model.reviewTeamMembers = [];
        model.isVisible = {
            comments: true,
            changes: false,
            changeMenu: false,
            //submissionMenu: false,
            footerMenu: false,
            inPAF: function (findingType) {
                if (findingType) {
                    return findingType.statementFindingTypeId !== statementFindingTypes.PROGRAMINTRODUCTION &&
                           findingType.statementFindingTypeId !== statementFindingTypes.PROGRAMSTRENGTH &&
                           findingType.statementFindingTypeId !== statementFindingTypes.PROGRAMOBSERVATION;
                }
                return true;
            },
            inExitStatement: function (findingType) {
                return findingType ? true : false;
            }
        };

        // Flags
        model.isAdmin = false;
        model.isPEV = false;
        model.isTeamChair = false;
        model.isReadOnly = true;
        model.hasAccess = false;
        model.isCurrent = false;
        model.isPublished = false;
        model.isReturnedToPEV = false;
        model.isSubmittedToTC = false;
        model.isReviewedByTC = false;
        model.isLocked = false;
        model.isWithPEV = false;
        model.isWithTeamChair = false;
        model.isDataReady = false;

        model.openInstructions = function () {
            alertSvc.openPDFModal(
                '/Content/files/Draft-Final-Statement-Instructions.pdf',
                'Statement Editor Instructions'
            );
        };

        model.toggleComments = function () {
            model.isVisible.comments = !model.isVisible.comments;
            model.hideComments();
        };

        model.hideComments = function () {
            if (model.isVisible.comments) return;
            model.isHighlightMode = false;
            model.programAuditDetail.programAuditJson.auditDetails.forEach(findingType =>
                findingType.findings.forEach(finding => {
                    statementStorageSvc.removeHighlightsByFinding(finding);
                    if (finding.criteria.comments)
                        finding.criteria.comments.forEach(comment =>
                            comment.isSelected = false
                        );
                })
            );
        };

        model.trimCriteriaName = function (criteriaName) {
            var trimmedName = null;
            var textToRemove = "general criteria for master's level programs";

            if (criteriaName.toLowerCase().includes(textToRemove)) {
                //+2 accounts for a character (either "." or ":") and a space following the phrase being removed
                trimmedName = criteriaName.substr(textToRemove.length + 2, criteriaName.length - 1)
            }

            return trimmedName ? trimmedName : criteriaName;
        };

        model.getReviewTypeName = function (reviewTypeCode) {
            return programReviewTypes[reviewTypeCode] || null;
        };

        model.getCriterionLabel = function (criterion) {
            // Note: there should be a better way to handle this, perhaps adding abbreviation to Criteria table and using that
            const criteriaName = criterion.criteriaName;
            if (criteriaName.startsWith('Criterion MS')) {
                const criteriaNameParts = criteriaName.split(' ');
                return criteriaNameParts.length > 1 ? criteriaNameParts[1].replace('.', '') : 'MS';
            }
            else if (criteriaName.startsWith('Criterion'))
                return `C${criterion.criteriaId}`;
            else if (criteriaName.startsWith('Program Criteria'))
                return 'PC';
            else if (criteriaName.startsWith('Accreditation Policy and Procedure Manual'))
                return 'APPM';
            else if (criteriaName.startsWith('General Criteria for Master')) {
                if (criteriaName.includes('Students and Curriculum'))
                    return 'SC';
                else if (criteriaName.includes('Program Educational Objectives and Student Outcomes'))
                    return 'PEO/SO';
                else if (criteriaName.includes('Program Quality'))
                    return 'PQ';
                else if (criteriaName.includes('Faculty'))
                    return 'F';
                else if (criteriaName.includes('Facilities'))
                    return 'FS';
                else if (criteriaName.includes('Institutional Support'))
                    return 'IS';
                else
                    return 'MS';
            }
            else
                return 'C';
        }

        model.editProgramAuditSummary = function () {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/programAudit/templates/modal/editProgramAuditDetailSummary.html',
                size: 'lg',
                controller: 'editProgramAuditDetailSummaryCtrl',
                resolve: {
                    program: () => model.program,
                    programAuditDetail: () => model.programAuditDetail
                }
            });

            modalInstance.result.then(programAuditDetail =>
                refreshData(programAuditDetail)
            );
        };

        model.shareProgramAudit = function () {
            const title = 'Share Draft Program Audit';
            const message =
                `<label>You are about to share this version of the program audit</label>
                <div class="synopsis">
                    <p>
                        A PAF will be generated from the current version of the audit data and made available for PEVs and TCs to view.
                        You will be able to continue editing the program audit and other team members will not see your changes.
                        If you share a later version your previously shared PAF will be overwritten; only one shared version is available at a time.
                    </p>
                    <em>Note: Once the program audit has been shared it cannot be hidden unless it is replaced by a more recently shared version.</em>
                </div>
                <p>Enter an optional note below and click <em>confirm</em> if you wish to proceed.</p>`;
            const successMessage = 'Program audit successfully shared.';
            const failureMessage = 'Program audit could not be shared at this time.';

            doWorkflowStep(title, message, programAuditDetailSvc.share, true, false, successMessage, failureMessage);
        }

        model.submitProgramAudit = function () {
            const title = 'Submit Program Audit';
            const message =
                `<label>You are about to submit this program audit to the TC for review</label>
                <div class="synopsis">
                    <p>
                        Make sure you are completely done editing. 
                        You will not be able to make further changes unless the program audit is returned.
                    </p>
                </div>
                <p>Enter an optional note below and click <em>confirm</em> if you wish to proceed.</p>`;

            doWorkflowStep(title, message, programAuditDetailSvc.submit, true, true, null, "Program audit could not be submitted at this time.");
        }

        model.returnProgramAudit = function () {
            const title = 'Return Program Audit';
            const message =
                `<label>You are about to return this program audit to the assigned PEV</label>
                <div class="synopsis">
                    <p>
                        The PEV will be able to continue editing the audit.
                        They'll need to resubmit before the program audit may be marked as reviewed.
                    </p>
                </div>
                <p>Enter an optional note below and click <em>confirm</em> if you wish to proceed.</p>`;

            doWorkflowStep(title, message, programAuditDetailSvc.return, true, true, null, "Program audit could not be returned to the PEV at this time.");
        }

        model.toggleReviewed = function () {
            let title, message, exitProgramAuditTool, successMessage, failureMessage;
            if (model.programAuditDetail.isReviewedByTeamChair) {
                title = 'Clear Reviewed Status';
                message =
                    `You are about to set this program audit to having not been reviewed.
                    You will be able to make further edits but the program audit will need to be reviewed before
                    the final documents can be generated.`;
                exitProgramAuditTool = false;
                successMessage = 'Reviewed status successfully cleared.';
                failureMessage = 'Reviewed status could not be cleared at this time.';
            } else {
                title = 'Mark Program Audit as Reviewed';
                message =
                    `You are about to mark this program audit as reviewed.
                    Once all audits have been reviewed the final documents can be generated.`;
                exitProgramAuditTool = true;
                failureMessage = 'Reviewed status could not be updated at this time.';
            }

            const workflowFunc = (programAuditDetail) => {
                programAuditDetail.isReviewedByTeamChair = !programAuditDetail.isReviewedByTeamChair;
                return programAuditDetailSvc.saveReviewedByTC(programAuditDetail);
            }

            doWorkflowStep(title, message, workflowFunc, false, exitProgramAuditTool, successMessage, failureMessage);
        }

        function doWorkflowStep(title, confirmMessage, workflowFunc, askForNote = false, exitProgramAuditTool = false, successMessage = null, failureMessage = null) {
            const confirm = askForNote ?
                alertSvc.confirmWithNote(confirmMessage, false, title) :
                alertSvc.booleanConfirm(confirmMessage, undefined, undefined, title);

            successMessage = successMessage || 'Operation succeeded.';
            failureMessage = failureMessage || 'Operation failed';

            return confirm.then(note => {
                model.isSubmitting = true;
                const programAuditDetail = angular.copy(model.programAuditDetail);
                programAuditDetail.note = askForNote ? (note && note.length ? note : null) : programAuditDetail.note;

                return workflowFunc(programAuditDetail).then(programAuditDetails => {
                    if (exitProgramAuditTool)
                        model.goBack();
                    else {
                        refreshData(programAuditDetails);
                        model.isSubmitting = false;
                        alertSvc.addAlertSuccess(successMessage);
                    }
                }).catch(error => {
                    model.isSubmitting = false;
                    console.log(error);
                    if (error.status !== 409) alertSvc.addAlertWarning(failureMessage); // httpResponseInterceptor handles conflict messages
                });
            });
        }

        function openPreview (isExitStatement) {
            // Get latest changes
            model.dataForPAF.programAudit.programAuditDetailDtos = [model.programAuditDetail];

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/programAudit/templates/modal/viewPAFPreview.html',
                size: 'xl',
                controller: 'viewPAFPreviewCtrl',
                resolve: {
                    currentProgramAuditData: function () { return model.dataForPAF; },
                    isExitStatement: function () { return isExitStatement; },
                    isSingleProgram: function () { return true; },
                    isInstitutionalSummary: function () { return false; },
                    isPreview: function () { return true; },
                    showInstitutionalSummary: function () { return isExitStatement && (model.isTeamChair || model.isAdmin); }
                }
            });
        };

        model.openProgramAuditPreview = function () {
            openPreview();
        };

        model.openExitStatementPreview = function () {
            var isExitStatement = true;
            openPreview(isExitStatement);
        };

        model.goBack = function () {
            // Go to the route specified under previous route or return to program audit tab under review details.
            $state.go(model.returnStateName, model.returnStateParams).then(function () {
                document.body.classList.remove("editor-tool");
                document.documentElement.classList.remove('editor-tool-html');
            });
        };
         
        model.addFindings = function () {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/apps/programAudit/templates/modal/addProgramAuditDetailFindings.html',
                size: 'lg',
                controllerAs: 'model',
                controller: 'addProgramAuditDetailFindingsCtrl',
                resolve: {
                    program: () => model.program,
                    programAuditDetail: () => model.programAuditDetail,
                }
            });

            modalInstance.result.then(programAuditDetail =>
                refreshData(programAuditDetail)
            );
        }

        model.isSectionPermanent = function (findingType) {
            // For now, program introduction is the only type allowed on program audit that is permanent;
            // no other allowed sections that can't be changed or deleted.
            return findingType.statementFindingTypeId === statementFindingTypes.PROGRAMINTRODUCTION;
        }

        model.hasText = programAuditDetailSvc.hasText;

        model.changeFindingType = function (findingType, finding) {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/apps/programAudit/templates/modal/changeProgramAuditDetailFinding.html',
                size: 'md',
                controllerAs: 'model',
                controller: 'changeProgramAuditDetailFindingCtrl',
                resolve: {
                    program: () => model.program,
                    programAuditDetail: () => model.programAuditDetail,
                    findingType: () => findingType,
                    finding: () => finding,
                }
            });

            modalInstance.result.then(programAuditDetail => 
                refreshData(programAuditDetail)
            );
        }

        model.deleteFinding = function (findingType, finding) {
            const findingName = `${findingType.statementFindingTypeName}${finding.criteria.criteriaName ? ` (${finding.criteria.criteriaName})` : ''}`;
            alertSvc.confirmDelete(findingName, deleteFunc);

            function deleteFunc() {
                const programAuditDetail = angular.copy(model.programAuditDetail);
                programAuditDetailTemplateSvc.deleteFinding(model.program.commissionId, programAuditDetail, findingType, finding);
                programAuditDetailSvc.update(programAuditDetail).then((programAuditDetail) => {
                    if (programAuditDetailTemplateSvc.isShortcoming(findingType.statementFindingTypeId)) 
                        programAuditDetailEvaluatorSvc.resetProgramAuditRecommendedActions(programAuditDetail.programAuditDetailEvaluatorDtos).then((programAuditDetailEvaluators) => {
                            programAuditDetail.programAuditDetailEvaluatorDtos = programAuditDetailEvaluators;
                            onSuccess(programAuditDetail);
                        }).catch(onError);
                    else
                        onSuccess(programAuditDetail);
                }).catch(onError);
            }

            function onSuccess(programAuditDetail) {
                refreshData(programAuditDetail);
                alertSvc.addAlertSuccess("Finding successfully deleted.")
            }

            function onError(error) {
                console.log(error);
                if (error.status !== 409) alertSvc.addAlertWarning('Finding could not be deleted at this time.') // httpResponseInterceptor handles conflict messages 
            }
        };

        model.saveRecommendedAction = function (programAuditDetailEvaluator) {
            const message = programAuditDetailEvaluator.recommendedAction ? 'Recommended action saved.' : 'Recommended action cleared.';
            programAuditDetailEvaluatorSvc.saveProgramAuditRecommendedActions([programAuditDetailEvaluator]).then(evaluators => {
                model.programAuditDetail.programAuditDetailEvaluatorDtos = evaluators;
                model.programAuditDetail.lastUpdatedTimestamp =
                    evaluators && evaluators.length && evaluators[0].lastUpdatedTimestamp ||
                    new Date().toISOString();
                doValidation();
                alertSvc.addAlertSuccess(message);
            }).catch(error => {
                console.log(error);
                if (error.status !== 409) alertSvc.addAlertWarning('Saving recommended action failed.') // httpResponseInterceptor handles conflict messages
            });
        }

        let autosaving = null;

        model.autoSave = function (finding) {
            if (model.isReadOnly)
                return;

            finding.lastUpdatedTimestamp = new Date().toISOString();
            if (autosaving)
                $timeout.cancel(autosaving);
            // Delay auto-save to prevent too-many auto-saves being fired...
            autosaving = $timeout(() => {
                autosaving = null;
                finding.saveComplete = false;
                finding.delayElapsed = false;
                programAuditDetailSvc.update(model.programAuditDetail).then(() => {
                    if (finding.delayElapsed)
                        finding.isAutosaving = false;
                    else
                        finding.saveComplete = true;
                    model.programAuditDetail.lastUpdatedTimestamp = new Date().toISOString();
                    doValidation();
                }).catch(error => {
                    finding.saveComplete = true;
                    finding.isAutosaving = false;
                    console.log(error);
                    if (error.status !== 409) alertSvc.addAlertWarning('Saving finding text failed.'); // httpResponseInterceptor handles conflict messages
                });
                // Hide auto-saving message after a short delay or when save is complete--whichever occurs last...
                $timeout(() => {
                    finding.delayElapsed = true;
                    if (finding.saveComplete)
                        finding.isAutosaving = false;
                }, 2000);

                finding.isAutosaving = true;
            }, 2000);
        };

        model.isOwnComment = function (comment) {
            return currentUser.profile.volunteerId ?
                currentUser.profile.volunteerId === comment.commentVolunteerId :
                (currentUser.profile.firstName + ' ' + currentUser.profile.lastName) === comment.commentVolunteerName;
        };

        // Note: isHighlightMode may not be needed here like on statement tool, where saving reloads and rebinds, clearing out comment highlighting
        model.isHighlightMode = false;

        model.highlightComment = function (finding, comment) {
            statementStorageSvc.removeHighlightsByFinding(finding, comment);

            comment.isSelected = comment.isSelected ? !comment.isSelected : true;
            model.isHighlightMode = comment.isSelected;

            if (!model.isHighlightMode) return;

            if (!statementStorageSvc.highlightSingleComment(comment))
                comment.isSelected = false;
        };

        model.deleteComment = function (comment, parentComment, finding, isReply) {
            alertSvc.confirm('Are you sure you want to delete this comment? This procedure is irreversible.', deleteComment);

            function deleteComment() {
                if (isReply) {
                    //find parent comment index
                    var parentCommentIndex = finding.criteria.comments.findIndex(function (currentComment) {
                        return currentComment.markId === parentComment.markId;
                    });

                    //find child comment index
                    var childCommentIndex = finding.criteria.comments[parentCommentIndex].childComments.findIndex(function (currentComment) {
                        return JSON.stringify(currentComment) === JSON.stringify(comment);
                    });

                    //remove comment from parent
                    finding.criteria.comments[parentCommentIndex].childComments.splice(childCommentIndex, 1);
                } else {
                    //find comment index
                    var commentIndex = finding.criteria.comments.findIndex(function (currentComment) {
                        return currentComment.markId === comment.markId;
                    });

                    //remove comment
                    finding.criteria.comments.splice(commentIndex, 1);
                }

                // Clean up orphaned comments
                statementStorageSvc.removeOrphanedCommentsFromFinding(finding);

                model.saveComments().then(function (data) {
                    model.programAuditDetail = data;
                    alertSvc.addAlertSuccess("Comment successfully deleted.");
                });
            }
        };

        model.addReply = function (commentText, parentComment, finding) {
            var comment = {
                commentText: commentText,
                commentVolunteerId: currentUser.profile.volunteerId,
                commentVolunteerName: currentUser.profile.firstName + ' ' + currentUser.profile.lastName,
                teamMemberTypeId: model.programAuditDetail.teamMemberTypeId,
                commentVolunteerRole: model.getTeamMemberTypeAbbrv(model.programAuditDetail.teamMemberTypeId),
                commentDate: new Date(Date.now()).toLocaleDateString()
            };

            //find parent comment
            var parentCommentIndex = finding.criteria.comments.findIndex(function (currentComment) {
                return currentComment.markId === parentComment.markId;
            });

            //add new comment
            if (!finding.criteria.comments[parentCommentIndex].childComments)
                finding.criteria.comments[parentCommentIndex].childComments = [];

            finding.criteria.comments[parentCommentIndex].childComments.push(comment);

            model.saveComments().then(function () {
                alertSvc.addAlertSuccess("Reply successfully added.");
            });
        };

        model.saveComments = function () {
            return programAuditDetailSvc.update(model.programAuditDetail);
        }

        model.getVolunteerLabel = function (volunteerId, includeTeamMemberType) {
            const reviewTeamMember = model.reviewTeamMembers.find(reviewTeamMember =>
                reviewTeamMember.volunteerId === volunteerId
            );
            return reviewTeamMember ? `${reviewTeamMember.firstName} ${reviewTeamMember.lastName} ${includeTeamMemberType ? `(${teamMemberTypeAbbrvIds[reviewTeamMember.teamMemberTypeId]})` : ''}`: 'N/A';
        }

        model.getTeamMemberTypeAbbrv = reviewTeamSvc.getTeamMemberTypeAbbrv;
        model.getTeamMemberTypeStyle = reviewTeamSvc.getTeamMemberTypeStyle;
        model.getTeamMemberTypeName = reviewTeamSvc.getTeamMemberTypeName;

        model.formatDateTime = function (date) {
            return $filter('amDateFormat')(
                $filter('amTimezone')(
                    date,
                    "America/New_York"),
                "M/D h:mm A");
        }

        model.getVersionTitle = function (programAuditDetail, noStyling) {
            const teamMemberTypeStyle = model.getTeamMemberTypeStyle(programAuditDetail.teamMemberTypeId);
            const teamMemberTypeAbbrv = model.getTeamMemberTypeAbbrv(programAuditDetail.teamMemberTypeId);
            const teamMemberTypeName = model.getTeamMemberTypeName(programAuditDetail.teamMemberTypeId);
            const timestamp = programAuditDetail.submittedTimestamp ? model.formatDateTime(programAuditDetail.submittedTimestamp) : 'Current';

            return noStyling ? `${teamMemberTypeName} (${timestamp})` : `<span class="role ${teamMemberTypeStyle}">${teamMemberTypeAbbrv}</span> ${timestamp}`;
        }; 

        model.showChanges = function (mode) {
            if (mode) {
                var versions = getChangeTrackingVersions(mode);
                model.leftSide = versions.leftSide;
                model.rightSide = versions.rightSide;
                model.differences = programAuditDetailDifferenceSvc.getConvertedDiffedObject(versions.leftSide, versions.rightSide);
                if (model.differences.$difference === model.differenceTypes.SAME) {
                    var noChangesMessage = "<p>No changes were made from " + model.getVersionTitle(model.leftSide) + " to " + model.getVersionTitle(model.rightSide) + "</p>";
                    alertSvc.openModalAlert(noChangesMessage, "No Changes Found");
                    return;
                }
                model.isVisible.changes = true;
            } else {
                model.isVisible.changes = false;
            }
        }

        activate();

        function activate() {
            loadData().then(data => {
                Object.assign(model, data);
                if (!model.programAuditDetail) {
                    $state.go('error', { errorCode: 400, errorMessage: 'Invalid Program Audit' });
                    return;
                }
                initialize();
                if (!model.hasAccess) {
                    $state.go('error', { errorCode: 403, errorMessage: 'You do not have permission to view this program audit' });
                    return;
                }
                // Set event listener to make sure autosave finished when leaving tool
                $scope.$on("$destroy", () => {
                    if (autosaving) {
                        $timeout.cancel(autosaving);
                        programAuditDetailSvc.update(model.programAuditDetail);
                    }
                });
                // Finished loading and initializing
                model.isDataReady = !refreshSiteSvc.refresh(); // Clear ui-router cache after so many visits...
            });
        }

        function loadData() {
            return programAuditDetailSvc.getAllProgramAuditToolData(model.programAuditId, model.programId, model.programAuditDetailId, true) .then(data => {
                // Store data for PAF/Exit Statement
                model.dataForPAF = angular.copy(data);

                // Extract, transform data as needed...
                data.programAuditDetail = data.programAudit.programAuditDetailDtos.find(programAuditDetail =>
                    programAuditDetail.programAuditDetailId === model.programAuditDetailId &&
                    programAuditDetail.programId === model.programId) || null;
                data.programAuditDetailHistory = data.programAudit.programAuditDetailDtos;
                data.programReview = data.programReviews[0];
                data.program = data.programReview.programDto.programDetailDto;
                data.reviewTeam = data.programReview.reviewTeamDto;

                // Load additional data...
                return $q.all([
                    data,
                    actionSvc.getActions(),
                    programAuditDetailEvaluatorSvc.initializeProgramAuditRecommendedActions(data.programAuditDetail, data.reviewTeamMembers)              
                ]).then(([data, actionCodes, evaluators]) => {
                    data.actionCodes = actionCodes;
                    data.programAuditDetail.programAuditDetailEvaluatorDtos = evaluators;
                    // State information specified by parent/opening route to be used to return to when leaving program audit tool.
                    data.returnStateName = $stateParams.returnStateName || (currentUser.profile.userTasks.indexOf("admin") >= 0 && !oauth.isAdjunct() ? 'userReviewAdmin.detail' : 'userReview.detail');
                    data.returnStateParams = $stateParams.returnStateName && $stateParams.returnStateParams || {
                        reviewTeamId: data.reviewTeam.reviewTeamId,
                        view: 'programaudit'
                    };

                    return data;
                })
            });
        }

        function initialize() {
            // Recommended action options are a based on the current previous action and current shortcomings.
            model.recommendedActionOptions = programAuditDetailEvaluatorSvc.getRecommendedActionOptions(
                model.actionCodes, model.programReview.programReviewTypeCode, model.programAuditDetail
            );
            // Reset accessibility and visibility flags
            setAccess();
            // Perform validation given current program audit detail and evaluator data
            doValidation();
        }

        function setAccess() {
            const currentUserAccess = programAuditAccessSvc.getCurrentUserAccess(model.program.programId, model.programAuditAccess, model.reviewTeamMembers, model.programAuditDetail, model.isLocked);
            model.isAdmin = oauth.isAdmin();
            model.isPEV = currentUserAccess.isPEV;
            model.isTeamChair = currentUserAccess.isTeamChair;
            model.isReadOnly = currentUserAccess.isReadOnly;
            model.hasAccess = currentUserAccess.programAuditDetail || !model.isReadOnly; // user has access to an existing version or can create new 
            model.isCurrent = model.programAuditDetail.isCurrent;
            model.isPublished = model.programAuditDetail.publishedTimestamp ? true : false;
            model.isReturnedToPEV = model.programAuditDetail.isReturnToEvaluator;
            model.isSubmittedToTC = model.programAuditDetail.submittedTimestamp ? !model.isReturnedToPEV : false;
            model.isReviewedByTC = model.programAuditDetail.isReviewedByTeamChair;
            model.isLocked = model.programAudit.isLocked;
            model.isWithPEV = model.programAuditDetail.teamMemberTypeId === teamMemberTypeNames.PEV;
            model.isWithTeamChair = model.programAuditDetail.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR;
            //model.isVisible.submissionMenu = !model.isLocked && model.isCurrent && (model.isWithPEV && model.isPEV || model.isWithTeamChair && model.isTeamChair);
            model.isVisible.footerMenu = !model.isLocked && model.isCurrent && (model.isWithPEV && model.isPEV || model.isWithTeamChair && model.isTeamChair);
        }

        function doValidation() {
            if (model.isReadOnly)
                return;

            model.errors = programAuditValidationSvc.validateProgramAuditDetailSection(
                `${model.program.programName} (${model.program.degreeCode})`,
                model.programAuditDetail).errors.map(error =>
                    error.shortMessage
                );
            model.isValid = model.errors.length ? false : true;
        }

        function refreshData(programAuditDetails) {
            if (!Array.isArray(programAuditDetails)) programAuditDetails = programAuditDetails ? [programAuditDetails] : [];
            // Replace references to each returned programAuditDetail if it has changed to trigger rebinding.
            programAuditDetails.forEach(programAuditDetail => {
                const index = model.programAuditDetailHistory.findIndex(item =>
                    item.programAuditDetailId === programAuditDetail.programAuditDetailId
                );
                if (index < 0)
                    model.programAuditDetailHistory.unshift(programAuditDetail);
                else if (programAuditDetail.lastUpdatedTimestamp >= model.programAuditDetailHistory[index].lastUpdatedTimestamp &&
                    programAuditDetail !== model.programAuditDetailHistory[index])
                    model.programAuditDetailHistory.splice(index, 1, programAuditDetail);
                
                if (programAuditDetail.isCurrent && programAuditDetail !== model.programAuditDetail) {
                    model.programAuditDetail = programAuditDetail;
                    if (model.programAuditDetailId !== model.programAuditDetail.programAuditDetailId) {
                        model.programAuditDetailId = model.programAuditDetail.programAuditDetailId;
                        $state.go(
                            'programAuditTool',
                            {
                                programAuditId: model.programAuditDetail.programAuditId,
                                programId: model.programAuditDetail.programId,
                                programAuditDetailId: model.programAuditDetail.programAuditDetailId
                            },
                            { notify: false, location: 'replace' }
                        );
                    }
                }
            });
            // Re-initialize page based on current data
            initialize();
        }

        function getChangeTrackingVersions(mode) {
            const versions = {};
            const history = [...model.programAuditDetailHistory].sort(programAuditAccessSvc.sortHistory).reverse();
            if (mode === 'manual') {
                let leftSideId = model.manualLeftSideId;
                let rightSideId = model.manualRightSideId;
                if (leftSideId > rightSideId) {
                    const tempId = leftSideId;
                    leftSideId = rightSideId;
                    rightSideId = tempId;
                }
                versions.leftSide = history.find(programAuditDetail =>
                    programAuditDetail.programAuditDetailId === leftSideId
                );
                versions.rightSide = history.find(programAuditDetail =>
                    programAuditDetail.programAuditDetailId === rightSideId
                );
            } else {
                const leftSideIndex = history.findIndex(programAuditDetail =>
                    programAuditDetail.programAuditDetailId === model.quickViewLeftSideId
                );
                versions.leftSide = history[leftSideIndex];
                versions.rightSide = history[leftSideIndex + 1];
            }

            return versions;
        }
    }

    module.controller('programAuditToolCtrl', programAuditToolCtrl);

}(angular.module('programAudit')));