(function (module) {

    var globalConstantSvc = function ($q, amsConst, $http) {
        var factory = {};
        var odataRoot = amsConst.webApiUrl + '/odata/';

        factory.nameConstants = {
            personVolunteerEmail: 'PersonVolunteerEmailAddress',
            adjunctEmail: 'FirstAdjunctEmailAddress'
        }

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getGlobalVariable = function (variableName, variableId) {
            var api = odataRoot + 'GetGlobalVariable';
            var parameters = "(variableName='" + variableName + "',inArg='" + variableId.toString() + "')";

            return $http.get(api + parameters).then(onSuccess, onFailure);
        }

        return {
            nameConstants: factory.nameConstants,
            getGlobalVariable: factory.getGlobalVariable
        };
    };
    module.service('globalConstantSvc', globalConstantSvc);

})(angular.module('common'));