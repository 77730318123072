(function (module) {

    var rrStatusEditCtrl = function ($scope, $uibModalInstance, readinessReview, alertSvc, codeSvc, helperSvc, readinessSvc, eventSvc) {
        var model = $scope;

        model.title = 'Edit Status';
        model.readinessReview = angular.copy(readinessReview);
        model.statuses = [];

        
        
        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (angular.isNumber(model.newStatus.readinessStatusId)) {
                readinessReview.readinessProcessTrackingDtos.push(model.newStatus);
                readinessSvc.update(readinessReview).then(function (data) {
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Status successfully updated.");
                    eventSvc.broadcast('refreshReadinessData');
                });
            }
        };

        var activate = function () {
            model.newStatus = {
                readinessProcessTrackingId: 0,
                commitDate: new Date(),
                readinessId: readinessReview.readinessId,
                readinessStatusId: null
            };
            codeSvc.getReadinessStatuses().then(function (data) {
                model.statuses = helperSvc.getValue(data);
            });
        }();
    };

    module.controller('rrStatusEditCtrl', rrStatusEditCtrl);

}(angular.module('readinessReview')));