(function (module) {
    'use strict';

    var userReviewAdminSvc = function ($http, $q, odataSvc, amsConst, $odata) {
        var factory = {};
        var searchApi = '/GetReviewTeamSearchWithStatus';

        factory.advancedSearch = function (searchOptions) {
            var oSvc = odataSvc.get();

            var hasreviewYear = searchOptions.reviewYear ? true : false;
            var hasStatusId = searchOptions.statusId ? true : false;
            var hasCommissions = searchOptions.commissions ? searchOptions.commissions.length > 0 : false;
            var hasLocation = searchOptions.location ? true : false;
            var hasOrganizationName = searchOptions.organizationName ? true : false;

            var hasSearchOptions = hasreviewYear || hasStatusId || hasCommissions || hasLocation || hasOrganizationName;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;

                if (hasreviewYear)
                    predicates.push(new $odata.Predicate('reviewYear', '=', searchOptions.reviewYear));
                if (hasStatusId)
                    predicates.push(new $odata.Predicate('latestReviewProcessStatusId', '=', searchOptions.statusId));
                if (hasOrganizationName)
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                if (hasCommissions) {
                    var combinedCommissionPredicate = [];

                    for (var i = 0; i < searchOptions.commissions.length; i++) {
                        combinedCommissionPredicate.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissions[i].codeKey)))
                    }

                    if (combinedCommissionPredicate.length > 0) {
                        predicates.push($odata.Predicate.or(combinedCommissionPredicate))
                    }
                }
                if (hasLocation) {
                    if (parseInt(searchOptions.location) === 0) {
                        predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                    } else {
                        predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                    }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(searchApi).odata()
                    .filter(combinedPredicate)
                    .orderBy("organizationSortName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(searchApi).odata()
                    .orderBy("organizationSortName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        factory.statementSearch = function (searchOptions) {
            var oSvc = odataSvc.get();

            var hasReviewYear = searchOptions.reviewYear ? true : false;
            var hasStatusId = searchOptions.statusId ? true : false;
            var hasCommissionId = searchOptions.commissionId ? true : false;
            var hasLocation = searchOptions.location ? true : false;
            var hasOrganizationName = searchOptions.organizationName ? true : false;

            var hasSearchOptions = hasReviewYear || hasStatusId || hasCommissionId || hasLocation || hasOrganizationName;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;

                if (hasReviewYear)
                    predicates.push(new $odata.Predicate('reviewYear', '=', searchOptions.reviewYear));
                if (hasStatusId)
                    predicates.push(new $odata.Predicate('latestReviewProcessStatusId', '=', searchOptions.statusId));
                if (hasOrganizationName)
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                if (hasCommissionId) {
                    predicates.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissionId)));
                }
                if (hasLocation) {
                    if (parseInt(searchOptions.location) === 0) {
                        predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                    } else {
                        predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                    }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(searchApi).odata()
                    .filter(combinedPredicate)
                    .orderBy("organizationName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(searchApi).odata()
                    .orderBy("organizationName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        return {
            advancedSearch: factory.advancedSearch,
            statementSearch: factory.statementSearch
        };
    };

    module.factory('userReviewAdminSvc', userReviewAdminSvc);

})(angular.module('userReview'));