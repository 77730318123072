(function (module) {

    var assignPEVCsFacilitatorCtrl = function ($scope, alertSvc, commissionAbbreviations, helperSvc, facilitator, searchOptions, $uibModalInstance, trainingSvc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Assign PEVCs';
        model.searchOptions = {
            trainingYear: new Date(trainingSvc.data.trainingSessions[0].startDate).getFullYear(),
            trainingSessionId: facilitator.trainingSessionId,
            trainingId: searchOptions.trainingId
        };
        model.currentFacilitator = facilitator;
        model.commissionNames = commissionAbbreviations;
        model.selectedPersonFacilitators = [];
        model.firstSave = true;

        var pevcsData = {
            dataHolder: model,
            dataLocationName: 'pevcs',
            svcCallback: trainingSvc.getPEVCTrainingSearch,
            svcCallbackArguments: [model.searchOptions],
            odataResource: true
        }

        var observerData = {
            dataHolder: model,
            dataLocationName: 'observers',
            svcCallback: trainingSvc.getTrainingObservers,
            svcCallbackArguments: [model.searchOptions],
            odataResource: true
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.firstSave) {
                model.firstSave = false;
                trainingSvc.addPersonFacilitators(model.currentFacilitator.trainingFacilitatorId, model.selectedPersonFacilitators).then(function () {
                    trainingSvc.getTrainingFacilitators(searchOptions);
                    alertSvc.addAlertSuccess("PEVCs succesfully assigned.");
                    $uibModalInstance.close();
                });
            }
        }

        model.deletePersonFacilitator = function(pevc){        
            alertSvc.confirmDelete(model.currentFacilitator.personDto.firstName + " " + model.currentFacilitator.personDto.lastName + " (Facilitator)", deleteFacilitator);

            function deleteFacilitator(){
                trainingSvc.deletePersonFacilitator(pevc.trainingPersonFacilitatorId).then(function(){
                    var dataArray = [];        
                    dataArray.push(pevcsData);
                    dataArray.push(observerData);
                    var trainingFacilitators = {
                        dataHolder: model,
                        dataLocationName: 'facilitatorList',
                        svcCallback: trainingSvc.getTrainingFacilitators,
                        svcCallbackArguments: [searchOptions],
                        odataResource: true
                    }
                    dataArray.push(trainingFacilitators);
                    helperSvc.getData(dataArray).then(function(){
                        angular.forEach(model.data.pevcs, function (pevc) {
                            pevc.isIncluded = pevc.trainingFacilitatorId !== null;
                        });

                        angular.forEach(model.data.trainingObserverList, function (obs) {
                            obs.isIncluded = obs.trainingPersonFacilitatorId !== null;
                        });

                        model.data.allTrainingPersons = model.data.pevcs.concat(model.data.trainingObserverList);
                    });
                    alertSvc.addModalAlertSuccess("PEVC succesfully deleted.");
                });
            }
        }
 
        model.togglePersonFacilitator = function(personFacilitator){          
            var indexOfSelected = model.selectedPersonFacilitators.map(function (x) { return x; }).indexOf(personFacilitator.trainingPersonId);
            if(indexOfSelected > -1)
                model.selectedPersonFacilitators.splice(indexOfSelected, 1);
            else
                model.selectedPersonFacilitators.push(personFacilitator.trainingPersonId);
        }

        model.getTrainingSessionName = function (trainingSessionId) {

            if (trainingSessionId != null) {
                var trainingSession = helperSvc.getFirstMatch(model.data.trainingSessions, 'trainingSessionId', trainingSessionId);
                return trainingSession.sessionName;
            }

            return "Unknown"
        }
        
        var activate = function () {         
            var dataArray = [];        
            dataArray.push(pevcsData);
            dataArray.push(observerData);

            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;

                angular.forEach(model.data.pevcs, function(pevc){
                    pevc.isIncluded = pevc.trainingFacilitatorId !== null;
                });

                angular.forEach(model.data.trainingObserverList, function (obs) {
                    obs.isIncluded = obs.trainingPersonFacilitatorId !== null;
                })

                model.data.allTrainingPersons = model.data.pevcs.concat(model.data.trainingObserverList);
                model.dataIsReady = true;   
            })
        }();
    };

    module.controller('assignPEVCsFacilitatorCtrl', assignPEVCsFacilitatorCtrl);

}(angular.module('training')));