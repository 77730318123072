(function (module) {
    var offlineStatementListingCtrl = function ($scope, $window, $state, $stateParams, alertSvc, currentUser, helperSvc, offlineStatementSvc, offlineSvc, statementStorageSvc, $q, oauth, logClientSvc) {

        var model = this;

        $window.addEventListener("storage", onStorage);
        $window.addEventListener("beforeunload", onBeforeUnload);

        $scope.$on("$destroy", function () {
            $window.removeEventListener("storage", onStorage);
            $window.removeEventListener("beforeunload", onBeforeUnload);
        });

        function onStorage(ev) {
            if (ev.key && ev.key == "appOffline" && !ev.newValue) {
                // User went online from a different tab
                offlineStatementSvc.goToOnlineState();
            }
        }

        var downloadComplete = false;
        function onBeforeUnload(ev) {
            var ev = ev || window.event;

            if (!downloadComplete) {
                var dialogText = 'Downloading is in progress.  Please wait for downloading to complete before leaving.';
                if (ev) ev.returnValue = dialogText;
                return dialogText;
            }
        }

        model.isDataReady = false;

        model.fullName = currentUser.profile.firstName + " " + currentUser.profile.lastName;
        model.reviews = [];

        model.isAdmin = oauth.isAdmin() || (currentUser.profile.impersonateUsername && currentUser.profile.impersonateUsername.length); // only admin users can impersonate

        model.goOnline = function (force) {

            var message = force ? "<strong>Warning!</strong> You are about to force going back to online mode. <strong><em>If you proceed, all changes will be lost.</em></strong> Are you sure you want to continue?" :
                                  "You are about to leave Offline Mode and return to the full Accreditation Management System. Are you sure you want to proceed?";

            alertSvc.confirm(message, proceedOnline);

            function proceedOnline() {
                if (!window.navigator.onLine) {
                    var secondMessage = "You appear to be disconnected from the Internet. The full Accreditation Management System may not function correctly until you reconnect. Are you sure you want to proceed?";
                    alertSvc.confirm(secondMessage, doSyncAndGoOnline);
                } else {
                    doSyncAndGoOnline();
                }
            }

            function doSyncAndGoOnline() {
                // Sync data and route back to appropriate landing page in online mode.
                try {
                    statementStorageSvc.syncOfflineData().then(
                        offlineStatementSvc.goToOnlineState
                    ).catch(
                        onError
                    );
                } catch (error) {
                    onError(error);
                }
 
                function onError(error) {
                    console.log(error);
                    if (!$stateParams.statementId) 
                        alertSvc.addAlertDanger("Offline statement data could not be synchronized.");
                    // Go online anyway, upload later when user editors or submits...
                    // alertSvc.addAlertDanger("Synchronization failed.  Please try again later.");
                    offlineStatementSvc.goToOnlineState();
                }
            }
        };

        model.openStatementEditor = function (review) {
            $state.go('offlineStatementEditor', { statementId: review.statementId })
        };

        var activate = function () {
            // Load offline data, if needed.  
            // Note: needs to be done while physically online. Could load data before routing to here but there 
            // will still be a delay while downloading; loading here allows for a consistent wait screen experience.
            var promise;
            var reviews = offlineStatementSvc.getSelectedReviews();
            offlineStatementSvc.clearSelectedReviews();
            if (reviews && reviews.length > 0) {
                promise = statementStorageSvc.downloadOfflineData(reviews).then(function () {
                    var title = 'Offline Mode Ready';
                    var msg = '<p>Your data has been downloaded for offline use. ' +
                              'If you need to close your browser you can return to AMS by bookmarking this page.</p>' +
                              '<p>To return to this page</p>' +
                              '<ul>' +
                                '<li>Press <strong>Control+D</strong> or <strong>Command+D</strong> to bookmark, or</li>' +
                                '<li>Browse to <a href="' + helperSvc.getAmsUrl() + '">' + helperSvc.getAmsUrl() + '</a></li>' +
                              '</ul>';
                    alertSvc.information(msg, title);
                });
            } else {
                // No recently selected reviews?  Load the ones previously downloaded to local storage.
                reviews = statementStorageSvc.getOfflineReviews() || [];
                var deferred = $q.defer();
                deferred.resolve(true);
                promise = deferred.promise;
            }
            promise.then(function () {
                downloadComplete = true;
                if (reviews.length === 1) {
                    $state.go('offlineStatementEditor', { statementId: reviews[0].statementId });
                }
                model.reviews = reviews;
                model.isDataReady = true;
            }).catch(function (error) {
                downloadComplete = true; // no need for "download in progress" message if download failed
                console.log('Downlod Offline Data Error', error);
                logClientSvc.insert('offlineStatementListingCtrl', error.message, error.stack);
                alertSvc.addAlertDanger("Downloading offline data failed.  Please try again later.");
            });
        }();
        
    };
    module.controller('offlineStatementListingCtrl', offlineStatementListingCtrl);

}(angular.module('statement')));