
(function (module) {
    'use strict';

    var institutionReviewOptionsHelperSvc = function (programReviewTypeIds, rfrLogicSvc) {
        var factory = {};

        factory.isFocusedReport = function (review) {
            return review.reviewTypeCode === programReviewTypeIds.FOCUSEDREPORT;
        }

        factory.getRequiredPevs = function (reviewOptions) {
            if (typeof reviewOptions !== 'string')
                return rfrLogicSvc.getNumberOfPevsByCommission(reviewOptions);

            return 0;
        };

        return {
            isFocusedReport: factory.isFocusedReport,
            getRequiredPevs: factory.getRequiredPevs
        };
    };

    module.factory('institutionReviewOptionsHelperSvc', institutionReviewOptionsHelperSvc);

})(angular.module('common'));









