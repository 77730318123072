(function (module) {

    var otherConflictsEditCtrl = function ($scope, $uibModalInstance, parent, $stateParams, helperSvc, volunteerHelperSvc, alertSvc, otherConflictSvc, typeConstSvc, upcomingAvailabilitySvc, codeSvc) {
        var model = $scope;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.volunteerId = parseInt($stateParams.volunteerId);
        model.data = otherConflictSvc.data;

        model.countries = upcomingAvailabilitySvc.countries;

        model.checkUS = function(){
            if (model.currentInformation.countryCode !== 'US') {
                delete model.currentInformation.stateCode;
            }
        }

        model.save = function () {
            helperSvc.validateForm(validateCollegeDirectory);

            if (model.errors.length > 0) {
                return;
            }

            createOtherConflict();
        };

        model.delete = function (item) {
            alertSvc.confirmDelete(item.collegeDirectoryDto ? item.collegeDirectoryDto.collegeName : item.collegeVerificationDto.name , deleteFunc, item);

            function deleteFunc() {
                otherConflictSvc.delete(item).then(
                    function (data) {
                        alertSvc.addModalAlertSuccess('Other conflict deleted.');
                    },
                    function (reason) {
                        if (reason === 'user cancelled') {
                            alertSvc.addModalAlertInfo('Delete cancelled.');
                        } else {
                            alertSvc.addModalAlertWarning('Other conflict could not be deleted at this time.');
                        }
                    }
                );
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleInsertMode = function () {
            volunteerHelperSvc.toggleInsertMode(model);
        };

        model.collegeVerificationTypes = typeConstSvc.getCollegeVerificationTypes();

        activate();

        function validateCollegeDirectory() {
            var errors = [];

            if (isDuplicate()) {
                errors.push('Institution is already listed as a conflict.');
            };

            model.errors = errors;
        }

        function isDuplicate() {
            var duplicate = model.data.otherConflicts.find(function (item) {
                var isInstitutionMatched = false;

                if (model.currentInformation.collegeDirectoryDto &&
                    model.currentInformation.collegeDirectoryDto.collegeDirectoryId) {
                    isInstitutionMatched = (item.collegeDirectoryId === model.currentInformation.collegeDirectoryDto.collegeDirectoryId);
                } else if (model.currentInformation.collegeVerificationDto &&
                           item.collegeVerificationDto) {
                    // Compare name only--rest of college verification is not viewable/editable on this screen
                    var existingName = item.collegeVerificationDto.name ? item.collegeVerificationDto.name.toLowerCase().trim() : null;
                    var newName = model.currentInformation.collegeVerificationDto.name.toLowerCase().trim();

                    isInstitutionMatched = (existingName === newName);
                }

                return isInstitutionMatched;
            });

            var duplicated = (duplicate !== undefined && duplicate !== null);

            return duplicated;
        }

        function createOtherConflict() {
            model.currentInformation.volunteerId = model.volunteerId;
           

            if (model.currentInformation.collegeDirectoryDto) {
                // Using college directory entry; set collegeDirectoryId at parent level.
                model.currentInformation.collegeDirectoryId = model.currentInformation.collegeDirectoryDto.collegeDirectoryId;
            } else {
                // Using new college entry; update collegeVerificationDto's referenceId and set type.
                model.currentInformation.collegeDirectoryId = null;
            }

            delete model.currentInformation.endYear;

            var newConflict = angular.copy(model.currentInformation);
            delete newConflict.countryCode;
            delete newConflict.stateCode;
            otherConflictSvc.create(newConflict).then(
                function (data) {
                    model.toggleInsertMode();
                    alertSvc.addModalAlertSuccess("Other conflict successfuly saved.");
                },
                function (data) {
                    alertSvc.addModalAlertWarning('Other conflict could not be saved at this time.');
                });
        }

        function activate() {
            volunteerHelperSvc.setErrors(model);
            model.currentInformation = {};

            codeSvc.getStates().then(function (data) {
                model.stateList = data.value;
            });
        }
    };

    module.controller('otherConflictsEditCtrl', otherConflictsEditCtrl);

}(angular.module('volunteer')));