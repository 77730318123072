(function (module) {

    var programSplitMergeSvc = function (odataSvc) {
        var apiPath = '/ProgramLink';
        var apiPathSearchProgram = '/GetProgramLinkByProgramId';
        var key = 'programLinkId';
        var factory = {};

        factory.getProgramLinks = function (id) {
            var oSvc = odataSvc.get();         

            oSvc.getSource(apiPath, key).odata()
                .expand('programLinkDetailDtos')
                .expand('linkTypeDto')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getProgramLinksByProgramId = function (programId) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter(apiPathSearchProgram, 'programId', programId)

            oSvc.getSource(path, key).odata()
                .expand('programLinkDetailDtos')
                .expand('linkTypeDto')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        return {
            getProgramLinks: factory.getProgramLinks,
            getProgramLinksByProgramId: factory.getProgramLinksByProgramId
        };
    };
    module.factory('programSplitMergeSvc', programSplitMergeSvc);

})(angular.module('program'));