(function (module) {

    var collegeDirectoryCtrl = function ($state, collegeDirectorySvc, alertSvc, barSvc) {
        var model = this;

        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.editCollegeInfo = collegeDirectorySvc.editCollegeInfo;
        model.collegeDirectory = collegeDirectorySvc.data;
        model.isDataReady = false;
        model.showSpinner = false;
        model.addressFields = ['street', 'city', 'stateCode', 'province', 'postalCode', 'countryCode'];

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        model.getUrl = collegeDirectorySvc.getUrl;
        model.getFullAddress = collegeDirectorySvc.getFullAddress;

        model.openPending = function () {
            collegeDirectorySvc.clearDefaultFilters();
            $state.go('collegeDirectory.pending');
        };

        model.delete = function (college) {
            alertSvc.confirmDelete(college.collegeName, deleteFunc);

            function deleteFunc() {
                collegeDirectorySvc.delete(college).then(function (data) {
                    alertSvc.addAlertSuccess("College successfully deleted.");
                });
            }
        };

        model.getPendingCount = function () {
            collegeDirectorySvc.getPendingCount().then(function (data) {
                model.pendingNum = data.result;
                model.isDataReady = true;
            });
        };

        activate();

        function activate() {
            model.getPendingCount();
        }
    };

    module.controller('collegeDirectoryCtrl', collegeDirectoryCtrl);

}(angular.module('collegeDirectory')));