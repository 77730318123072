(function (module) {

    var teamGroupsEditCtrl = function ($q, review, parent, $scope, $uibModalInstance, reviewTeamMemberSvc, helperSvc, reviewTeamGroupSvc, eventSvc, alertSvc, typeConstSvc, reviewTeamSvc) {
        var model = $scope;
        model.title = 'Team Groups';
        model.parent = parent;
        model.reviewTeamId = review.reviewTeamId;
        model.data = reviewTeamGroupSvc.data;
        model.currentTeamGroup = {};        
        model.chosenTeamChairs = [];
        model.chosenPEVs = [];
        model.chosenObservers = [];
        model.availableTeamChairs = [];
        model.availablePevs = [];
        model.availableObservers = [];
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;        

        resetCurrentTeamGroup();       

        reviewTeamMemberSvc.getTeamMembersByReviewTeamId(review.reviewTeamId).then(function (data) {
            var teamMembers = helperSvc.getValue(data);        
            convertAvailableTeamMembers(teamMembers);         
        });

        if (review) {
            model.review = angular.copy(review);
            model.subtitle = review.reviewDto.reviewYear + ' ' + review.commissionName + ' ' + review.reviewTypeName;
        };

        model.addTeamGroup = function () {
            resetCurrentTeamGroup();
            model.isInsertMode = true;
            model.isCreateMode = true;
            model.isUpdateMode = false;
        };

        model.select = function (teamGroup) {                   
            model.currentTeamGroup = teamGroup;
            convertSelectedTeamMembers();
            model.isInsertMode = true;
            model.isUpdateMode = true;
            model.isCreateMode = false;
        };
        
        model.delete = function (teamGroup) {
            alertSvc.confirmDelete(teamGroup.groupName, deleteFunc, teamGroup);

            function deleteFunc() {
                model.currentTeamGroup = teamGroup;
                reviewTeamGroupSvc.deleteOdata(teamGroup).then(function () {
                    resetCurrentTeamGroup();             
                    alertSvc.addModalAlertSuccess('Team group successfully deleted.');
                });
            }
        };

        model.cancel = function () {
            resetCurrentTeamGroup();
        };

        model.close = function () {            
            $uibModalInstance.close();
        };

        model.save = function (teamGroup) {
            //Set up the reviewteamgroupmemberdto object for each added member
            //remove all current members and then push all the selected team members to the teamgroup object
            var groupMembers = []

            groupMembers.push.apply(groupMembers, model.chosenTeamChairs);
            groupMembers.push.apply(groupMembers, model.chosenPEVs);
            groupMembers.push.apply(groupMembers, model.chosenObservers);
            teamGroup.reviewTeamGroupMemberDtos = convertToTeamGroupMemberDto(groupMembers) || [];
            
            if (model.isCreateMode) {
                
                reviewTeamGroupSvc.createOdata(teamGroup).then(function () {
                    resetCurrentTeamGroup();                    
                    alertSvc.addModalAlertSuccess('Team group succesfully created.')
                });

            } else if (model.isUpdateMode) {
                reviewTeamGroupSvc.updateOdata(teamGroup).then(function () {
                    resetCurrentTeamGroup();                   
                    alertSvc.addModalAlertSuccess('Team group succesfully updated.')
                });
            }
        };


        function convertToTeamGroupMemberDto(teamMembers) {
            var result = [];

            angular.forEach(teamMembers, function (teamGroupMember) {

                var reviewTeamGroupMemeberDto = {
                    reviewTeamGroupMemberId: teamGroupMember.reviewTeamGroupMemberId || 0,
                    reviewTeamGroupId: model.currentTeamGroup.reviewTeamGroupId || 0,
                    reviewTeamMemberId: teamGroupMember.reviewTeamMemberId,
                }

                result.push(reviewTeamGroupMemeberDto);
            });

            return result;
        }

        function convertAvailableTeamMembers(teamMembers) {

            angular.forEach(teamMembers, function (teamMember) {
                if (typeConstSvc.isTeamChair(teamMember.teamMemberTypeId)) {
                    model.availableTeamChairs.push(teamMember);
                }
                else if (typeConstSvc.isPev(teamMember.teamMemberTypeId)) {
                    model.availablePevs.push(teamMember);
                }
                else if (typeConstSvc.isObserver(teamMember.teamMemberTypeId)) {
                    model.availableObservers.push(teamMember);
                }

            });
        }

        function convertSelectedTeamMembers() {
            model.chosenTeamChairs = [];
            model.chosenPEVs = [];
            model.chosenObservers = [];

            angular.forEach(model.currentTeamGroup.reviewTeamGroupMemberDtos, function (teamMember) {              
                if (typeConstSvc.isTeamChair(teamMember.reviewTeamMemberDto.teamMemberTypeId)) {
                    model.chosenTeamChairs.push(teamMember);
                }
                else if (typeConstSvc.isPev(teamMember.reviewTeamMemberDto.teamMemberTypeId)) {
                    model.chosenPEVs.push(teamMember);
                }
                else if (typeConstSvc.isObserver(teamMember.reviewTeamMemberDto.teamMemberTypeId)) {
                    model.chosenObservers.push(teamMember);
                }
            });
        }
       
        function resetCurrentTeamGroup() {
            model.isInsertMode = false;
            model.isCreateMode = false;
            model.isUpdateMode = false;
            model.currentTeamGroup = {
                reviewTeamId: model.reviewTeamId,
                groupName: "",
                groupDescription: "",
                reviewTeamGroupMemberDtos: []
            };
            model.chosenTeamChairs = [];
            model.chosenPEVs = [];
            model.chosenObservers = [];
        };

    };

    module.controller('teamGroupsEditCtrl', teamGroupsEditCtrl);

}(angular.module('review')));