(function (module) {

    'use strict';
     var teamMemberType = function () {
        
        return function (inputs, values) {

            var result = [];
            angular.forEach(inputs, function (input) {

                if (input.reviewTeamMemberDto) {
                    angular.forEach(values, function (value) {

                        if (input.reviewTeamMemberDto.teamMemberTypeId == value) {
                            result.push(input);
                        }
                    });
                }
                
            });

            return result;
        };
    };

    module.filter('teamMemberType', teamMemberType);

})(angular.module('review'));

