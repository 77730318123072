(function (module) {

    var npcEditorToolCtrl = function ($q, $state, $stateParams, $uibModal, $timeout, $scope, $window, helperSvc, oauth, alertSvc, currentUser,
        statementFindingTypes, npcSvc, npcProgramChangeReportSvc, organizationSvc, programSvc, programReviewSvc, npcReportTemplateSvc, npcReportTypes,
        npcChangeTypeIds, npcSectionTypes, npcFinalActionTypeIds, codeSvc, npcTypes, teamMemberTypeNames, statementStorageSvc) {

        const model = this;

        // State Params
        model.npcId = parseInt($stateParams.npcId) || 0;
        model.npcProgramChangeReportId = parseInt($stateParams.npcProgramChangeReportId) || 0;

        // Type Constants
        model.statementFindingTypes = statementFindingTypes;
        model.npcTypes = npcTypes;
        model.npcReportTypes = npcReportTypes;
        model.npcSectionTypes = npcSectionTypes;
        model.npcChangeTypeIds = npcChangeTypeIds;
        model.npcFinalActionTypeIds = npcFinalActionTypeIds;
        model.teamMemberTypeNames = teamMemberTypeNames;

        model.filterRAOptionsByType = function () {
            return function (code) {
                if(model.npcProgramChangeReportDto.reportType == 'Review') {
                    if ((model.npcProgramChangeDto.npcType === model.npcTypes.TERMINATION && code.codeKey === model.npcFinalActionTypeIds.TERMINATIONREQUIREMENTSMET) ||
                        (model.npcProgramChangeDto.npcType !== model.npcTypes.TERMINATION && code.codeKey === model.npcFinalActionTypeIds.CONTINUEACCREDITATION) ||
                        (code.codeKey === model.npcFinalActionTypeIds.CONDUCTAFOCUSEDVISIT)) {
                        return true;
                    }
                    else
                        return false;
                }
                else {
                    if (code.codeKey === model.npcFinalActionTypeIds.CONDUCTAFOCUSEDVISIT ||
                        (model.npcProgramChangeDto.npcType === model.npcTypes.TERMINATION && code.codeKey === model.npcFinalActionTypeIds.CONTINUEACCREDITATION) ||
                        (model.npcProgramChangeDto.npcType !== model.npcTypes.TERMINATION && code.codeKey === model.npcFinalActionTypeIds.TERMINATIONREQUIREMENTSMET)
                        )
                        return false;
                    else
                        return true;
                }

                //if ((model.isTeamChair || model.isAdjunct) && code.codeKey === model.npcFinalActionTypeIds.CONDUCTAFOCUSEDVISIT) {
                //    return false;
                //}
                //return true;
            }
        }

        // Model Data
        model.dataForPDF = null;
        model.editorOptions = {
            toolbar: ['heading', '|', 'undo', 'redo'],
            removePlugins: ['Autoformat'], //disables ckeditor's autoformats i.e. typing "1. " creates a numbered list and "> " creates a blockquote
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                ]
            }
        };

        model.hasText = npcProgramChangeReportSvc.hasText;

        model.hasNoFindings = statementStorageSvc.hasNoFindings;

        model.hideSubtitle = statementStorageSvc.hideFindingSubtitle;

        model.isVisible = {
            comments: false,
            changes: false,
            changeMenu: false,
            hideAddFinding: true,
            footerMenu: true
        };

        model.formatDateTime = helperSvc.formatDateTimeByFilter;

        model.formatDate = helperSvc.formatDateByFilter;

        model.formatUTCDate = helperSvc.formatDate;

        function findMatchingReportId() {
            for (var a = 0; a < model.npcPreviewData.npc.npcProgramDtos.length; a++) {

                for (var b = 0; b < model.npcPreviewData.npc.npcProgramDtos[a].npcProgramChangeDtos.length; b++) {

                    for (var c = 0; c < model.npcPreviewData.npc.npcProgramDtos[a].npcProgramChangeDtos[b].npcProgramChangeReportDtos.length; c++) {
                        if (model.npcPreviewData.npc.npcProgramDtos[a].npcProgramChangeDtos[b].npcProgramChangeReportDtos[c].npcProgramChangeReportId == model.npcProgramChangeReportId) {
                            {
                                model.npcPreviewData.npc.npcProgramDtos[a].npcProgramChangeDtos[b].selected = true;
                                return;
                            }
                        }
                    }
                }
            }

        }
        model.npcPreviewData = null;

        model.openNPCPreview = function () {
            // Get latest changes
            //model.dataForPAF.programAudit.programAuditDetailDtos = [model.programAuditDetail];
            $q.when(npcProgramChangeReportSvc.getAllNPCToolData(model.npcId)).then(function (npcData) {
                model.npcPreviewData = npcData;
                findMatchingReportId();
                
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/npc/templates/modal/viewNPCPreview.html',
                    size: 'xl',
                    controller: 'viewNPCPreviewCtrl',
                    resolve: {
                        currentNPCData: function () { return model.npcPreviewData; },
                        isPreview: function () { return true; },
                        isFinalAction: function () { return false; },
                        npcProgramChangeReportId: function () { return model.npcProgramChangeReportId; },
                        isFV: function () { return false; },
                        programsSelected: function () { return null;}
                    }
                })
            })
        };

        model.currentPersonId = parseInt(currentUser.profile.personId);
        model.isAdmin = oauth.isAdmin();
        model.isAdjunct = oauth.isAdjunct();

        //model.openInstructions = function () {
        //    alertSvc.openPDFModal(
        //        '/Content/files/Draft-Final-Statement-Instructions.pdf',
        //        'Statement Editor Instructions'
        //    );
        //};

        model.goBack = function () {
            // Go to the route specified under previous route or return to program audit tab under review details.
            $state.go(model.returnStateName, model.returnStateParams).then(function () {
                document.body.classList.remove("editor-tool");
                document.documentElement.classList.remove('editor-tool-html');
            });
        };

        model.addFindings = function () {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/apps/npc/templates/modal/addNPCFindings.html',
                size: 'lg',
                controllerAs: 'model',
                controller: 'addNPCFindingsCtrl',
                resolve: {
                    programDetailDto: () => model.programDetailDto,
                    npcProgramChangeDto: () => model.npcProgramChangeDto,
                    npcProgramChangeReportDto: () => model.npcProgramChangeReportDto,
                }
            });

            modalInstance.result.then(data => {
                refreshData(data);
                doValidation();
            });
        }

        model.deleteFinding = function (findingType, finding) {
            const findingName = `${findingType.statementFindingTypeName}${finding.criteria.criteriaName ? ` (${finding.criteria.criteriaName})` : ''}`;
            alertSvc.confirmDelete(findingName, deleteFunc);

            function deleteFunc() {
                model.isSaving = true;
                model.isVisible.hideAddFinding = true;

                const npcProgramChangeReportDto = angular.copy(model.npcProgramChangeReportDto);

                npcReportTemplateSvc.deleteFinding(model.npcProgramDto.commissionId, npcProgramChangeReportDto, findingType, finding);

                if (npcReportTemplateSvc.isShortcoming(findingType.statementFindingTypeId))
                    npcReportTemplateSvc.resetRecommendedAction(npcProgramChangeReportDto);

                npcProgramChangeReportSvc.update(npcProgramChangeReportDto)
                    .then(() => {
                        refreshData(npcProgramChangeReportDto);
                        doValidation();
                        model.isSaving = false;
                        model.isVisible.hideAddFinding = false;
                        alertSvc.addAlertSuccess("Finding successfully deleted.")
                    })
                    .catch(error => {
                        model.isSaving = false;
                        model.isVisible.hideAddFinding = false;
                        console.log(error);
                        if (error.status !== 409) alertSvc.addAlertWarning('Finding could not be deleted at this time.');
                    });
            }
        }

        model.isSectionPermanent = function (findingType) {
            const impermanentFindingTypes = [
                statementFindingTypes.INSTITUTIONSTRENGTH,
                statementFindingTypes.PROGRAMSTRENGTH,
                statementFindingTypes.PROGRAMDEFICIENCY,
                statementFindingTypes.PROGRAMWEAKNESS,
                statementFindingTypes.PROGRAMCONCERN,
                statementFindingTypes.PROGRAMOBSERVATION
            ];

            return !impermanentFindingTypes.includes(findingType.statementFindingTypeId);
        }

        //model.changeFindingType = function (findingType, finding) {
        //    const modalInstance = $uibModal.open({
        //        animation: true,
        //        templateUrl: '/apps/programAudit/templates/modal/changeProgramAuditDetailFinding.html',
        //        size: 'md',
        //        controllerAs: 'model',
        //        controller: 'changeProgramAuditDetailFindingCtrl',
        //        resolve: {
        //            program: () => model.program,
        //            programAuditDetail: () => model.programAuditDetail,
        //            findingType: () => findingType,
        //            finding: () => finding,
        //        }
        //    });

        //    modalInstance.result.then(programAuditDetail =>
        //        refreshData(programAuditDetail)
        //    );
        //};

        let autosaving = null;

        model.autoSave = function (section) {
            if (model.isReadOnly)
                return;

            if (autosaving)
                $timeout.cancel(autosaving);
            // Delay auto-save to prevent too-many auto-saves being fired...
            autosaving = $timeout(() => {
                autosaving = null;
                section.saveComplete = false;
                section.delayElapsed = false;

                npcProgramChangeReportSvc.update(model.npcProgramChangeReportDto).then(() => {
                    if (section.delayElapsed)
                        section.isAutosaving = false;
                    else
                        section.saveComplete = true;
                    doValidation();
                }).catch(error => {
                    section.saveComplete = true;
                    section.isAutosaving = false;
                    console.log(error);
                    if (error.status !== 409) alertSvc.addAlertWarning('Saving changes failed.'); // httpResponseInterceptor handles conflict messages
                });
                // Hide auto-saving message after a short delay or when save is complete--whichever occurs last...
                $timeout(() => {
                    section.delayElapsed = true;
                    if (section.saveComplete)
                        section.isAutosaving = false;
                }, 2000);

                section.isAutosaving = true;
            }, 2000);
        };

        model.isHighlightMode = false;

        model.saveRecommendationType = function (section) {
            const recommendation = model.npcActionTypes.find(action =>
                action.codeKey === section.recommendationTypeId
            );
            section.recommendationTypeId = section.recommendationTypeId || null;
            section.recommendationTypeName = recommendation?.codeName || null;
            $timeout.cancel(autosaving);
            npcProgramChangeReportSvc.update(model.npcProgramChangeReportDto).then(() => {
                doValidation();
                alertSvc.addAlertSuccess(`Recommended action ${section.recommendationTypeId ? "saved" : "cleared"}.`);
            }).catch(error => {
                console.log(error);
                if (error.status !== 409) alertSvc.addAlertWarning('Saving recommended action failed.') // httpResponseInterceptor handles conflict messages
            });
        }

        model.onRecommendedTerminationDateChange = function (date) {
            const section = model.npcProgramChangeReportDto.reportJson.find(section => section.npcSectionTypeId === model.npcSectionTypes.RECOMMENDATION);
            section.recommendedTerminationDate = date;

            doValidation();

            //$timeout.cancel(autosaving);
            //npcProgramChangeReportSvc.update(model.npcProgramChangeReportDto).then(() => {
           
            //    alertSvc.addAlertSuccess(`Recommended Termination Date Saved`);
            //}).catch(error => {
            //    console.log(error);
            //    if (error.status !== 409) alertSvc.addAlertWarning('Saving Termination Date Failed.') // httpResponseInterceptor handles conflict messages
            //});
        }

        model.submit = function () {
            model.isSaving = true;
            model.npcProgramChangeReportDto.submittedDate = new Date();
            if (model.isAdjunct) {
                var recommIdx = model.npcProgramChangeReportDto.reportJson.findIndex(section => section.npcSectionTypeId === model.npcSectionTypes.RECOMMENDATION);
                if (recommIdx > -1) {
                    model.npcProgramChangeReportDto.reportJson[recommIdx].recommendationTypeId = null;
                    model.npcProgramChangeReportDto.reportJson[recommIdx].recommendationTypeName = null;
                }
            }
            $timeout.cancel(autosaving);
            npcProgramChangeReportSvc.update(model.npcProgramChangeReportDto).then(() => {
                model.isSaving = false;
                alertSvc.addAlertSuccess(`${model.isReviewReport ? 'Review report' : 'Final action statement'} submitted.`);
                model.goBack();
            }).catch(error => {
                model.isSaving = false;
                console.log(error);
                if (error.status !== 409) alertSvc.addAlertWarning(`${model.isReviewReport ? 'Review report' : 'Final action statement'} could not be submitted at this time.`) // httpResponseInterceptor handles conflict messages
            });
        }

        activate();

        function activate() {
            loadData().then(data => {
                model.dataForPDF = data;

                Object.assign(model, data);
                if (!model.npcProgramChangeReportDto) {
                    $state.go('error', { errorCode: 400, errorMessage: 'Invalid NPC Report' });
                    return;
                }
                initialize();
                if (model.isDataReady && !model.hasAccess) {
                    $state.go('error', { errorCode: 403, errorMessage: 'You do not have permission to view this NPC Report' });
                    return;
                }
                // Set event listener to make sure autosave finished when leaving tool
                $scope.$on("$destroy", () => {
                    if (autosaving) {
                        $timeout.cancel(autosaving);
                        npcProgramChangeReportSvc.update(model.npcProgramChangeReportDto);
                    }
                });
            });
        }

        function loadData() {
            return $q.all([
                getEditorData(model.npcId),
                codeSvc.getNpcActionTypes().then(data => data.value)
            ]).then(([npcData, npcActionTypes]) => {
                model.dataForPDF = npcData;

                // Note: current options probably inadequate for focused visits. May need to "not to continue accreditation," schedule an interim report/visit, etc.
                npcActionTypes = npcActionTypes.filter(actionType =>
                    npcData.npcProgramChangeDto.npcType === model.npcTypes.TERMINATION ?
                        !actionType.codeName.toLowerCase().includes('continue') :
                        !actionType.codeName.toLowerCase().includes('terminat')
                );

                npcSvc.isExcomWithoutConflict(model.dataForPDF.npc.commissionId,
                                model.dataForPDF.programReview.reviewTeamId ?? 0, currentUser.profile.personId).then(isExcom => {
                    model.isExcomWithoutConflict = isExcom;
                    // Finished loading and initializing
                    setAccess();
                    model.isDataReady = true;
                })

                return {
                    ...npcData,
                    returnStateName: $stateParams.returnStateName || (model.isAdmin ? 'npc.detail' : 'npcUser.detail'),
                    returnStateParams: $stateParams.returnStateParams || {
                        npcId: npcData.npc.npcId,
                        organizationId: npcData.npc.organizationId,
                        view: npcData.npcProgramChangeReportDto.reportType === npcReportTypes.REVIEWREPORT ? 'reviewers' : 'focused'
                    },
                    npcActionTypes
                };
            });
        }

        function getEditorData(npcId) {
            const npcToolData = {};
            return $q.when(npcSvc.getNPCById(npcId)).then(npcData => {
                npcToolData.npc = npcData;
                npcToolData.npcProgramDto = npcToolData.npc.npcProgramDtos.find(program => {
                    const programChange = program.npcProgramChangeDtos.find(programChange => {
                        const programChangeReport = programChange.npcProgramChangeReportDtos.find(programChangeReport =>
                            programChangeReport.npcProgramChangeReportId === model.npcProgramChangeReportId
                        );

                        if (programChangeReport) {
                            npcToolData.npcProgramChangeReportDto = programChangeReport;
                            npcToolData.npcProgramChangeReviewerDto = programChange.npcProgramChangeReviewerDtos.find(reviewer =>
                                reviewer.npcProgramChangeReviewerId === npcToolData.npcProgramChangeReportDto.npcProgramChangeReviewerId
                            );

                            return programChange;
                        }
                    });

                    if (programChange) {
                        npcToolData.npcProgramChangeDto = programChange;
                        return program;
                    }
                });

                npcToolData.programDetailDto = npcToolData.npcProgramDto.programDetailDto;

                const getOrganizationData = $q.when(organizationSvc.getOrgByIdOdata(npcToolData.npc.organizationId));

                const getProgramReviewData = $q.when(programSvc.searchProgramsByOrganizationId(npcToolData.npc.organizationId, true).then(data =>
                    data.value.find(programReview => programReview.programId === npcToolData.programDetailDto.programId && programReview.isCurrent)
                )).then(programReview => {
                    // Get program disciplines
                    return programReviewSvc.getReviewsByProgramId(programReview.programId, true).then(data => {
                        const latestProgramReview = data?.value?.length ? helperSvc.getResults(data)[0] : {};

                        programReview.disciplines = latestProgramReview?.programReviewDisciplineDtos && latestProgramReview.programReviewDisciplineDtos
                            .map(programReviewDisciplineDto => programReviewDisciplineDto.disciplineName)
                            .sort()
                            .join(', ');

                        return programReview;
                    });
                });

                return $q.all([
                    getOrganizationData,
                    getProgramReviewData
                ]).then(([orgData, programReviewData]) => {
                    npcToolData.organizationDetailDto = orgData.currentOrganizationDetailDto;
                    npcToolData.organizationAddressDto = orgData.currentOrganizationAddressDto;
                    npcToolData.programReview = programReviewData;

                    return npcToolData;
                })
            }); 
        }

        function initialize() {
            model.isSubmitted = !!model.npcProgramChangeReportDto.submittedDate;
            model.isReviewReport = model.npcProgramChangeReportDto.reportType === npcReportTypes.REVIEWREPORT;
            model.isStatement = !model.isReviewReport;
            //model.isVisible.hideAddFinding = !model.isStatement;
            setAccess();
            doValidation();
        }

        function setAccess() {
            model.isReadOnly = model.isSubmitted;
            const teamMemberTypeIds = model.npcProgramChangeDto.npcProgramChangeReviewerDtos
                .filter(reviewer => reviewer.personId === model.currentPersonId)
                .map(reviewer => reviewer.teamMemberTypeId);
            model.isReviewer = teamMemberTypeIds.length;
            model.isNPCReviewer = teamMemberTypeIds.includes(model.teamMemberTypeNames.NPCREVIEWER);
            model.isTeamChair = teamMemberTypeIds.includes(model.teamMemberTypeNames.TEAMCHAIR);
            model.isFVReviewer = teamMemberTypeIds.includes(model.teamMemberTypeNames.NPCFVREVIEWER);

            model.isReportEditorAdj = model.npcProgramChangeReportDto.teamMemberTypeId == model.teamMemberTypeNames.ADJUNCT || model.npcProgramChangeReportDto.teamMemberTypeId == model.teamMemberTypeNames.NPCFVADJ;
            model.hasAccess = model.isReadOnly ?
                model.isAdmin || model.isAdjunct || model.isReviewer | model.isExcomWithoutConflict :              // admin, adjunct, and anyone assigned has read-only access to submitted report
                model.isReviewReport ? model.isNPCReviewer : model.isTeamChair;    // NPC reviewer, team chair have edit access to unsubmitted review/statement
        }

        function doValidation() {
            if (model.isReadOnly) {
                delete model.errors;
                model.isValid = true;
                return;
            }

            let errors = [], missingText, missingAction, missingTerminationDate, invalidTerminationDate
            model.npcProgramChangeReportDto.reportJson.forEach(section => {
                missingText = missingText || !model.hasText(section.text);

                if (section.npcSectionTypeId === model.npcSectionTypes.RECOMMENDATION || section.npcSectionTypeId === model.npcSectionTypes.PROGRAM) {
                    missingAction = missingAction || !section.recommendationTypeId;
                    missingTerminationDate = missingTerminationDate || (section.recommendationTypeId === model.npcFinalActionTypeIds.TERMINATIONREQUIREMENTSMET && !section.recommendedTerminationDate);
                    invalidTerminationDate = invalidTerminationDate || (section.recommendationTypeId === model.npcFinalActionTypeIds.TERMINATIONREQUIREMENTSMET && !npcProgramChangeReportSvc.validateTerminationDate(section.recommendedTerminationDate, model.programReview));
                }
            });

            if (model.isAdjunct) {
                missingAction = false;
                missingTerminationDate = false;
                invalidTerminationDate = false;
            }

            if (invalidTerminationDate && !missingTerminationDate)
                errors.push('Recommended accreditation end date must be during current accreditation period or within three years after.');

            model.errors = errors;
            model.isValid = !(missingText || missingAction || missingTerminationDate || invalidTerminationDate);
        }

        function refreshData(data) {
            // Replace references to stale report data
            let index = model.npcProgramChangeDto.npcProgramChangeReportDtos.findIndex(report => report.npcProgramChangeReportId === model.npcProgramChangeReportDto.npcProgramChangeReportId);
            model.npcProgramChangeDto.npcProgramChangeReportDtos.splice(index, 1, data);
            // Update report data
            model.npcProgramChangeReportDto = data;
        };
    }

    module.controller('npcEditorToolCtrl', npcEditorToolCtrl);

}(angular.module('npc')));