(function (module) {

    var citizenshipSvc = function ($http, $odataresource, $q, odataSvc, helperSvc, amsConst) {
        var apiPathByVolunteerId = '/GetVolunteerCitizenshipByVolunteerId(volunteerId={0})';
        var key = 'volunteerApplicationId';
        var factory = {};

        factory.data = {citizenships: null}

        factory.getByVolunteerId = function(volunteerId, noStoredData){
            var oSvc = odataSvc.get();
            var path = apiPathByVolunteerId.format(volunteerId);

            oSvc.getSource(path, key).odata()
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

           if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.citizenships =  data;
                });
            }

            return oSvc.getDeferred().promise;
        }

         factory.update = function (citizenshipDtos, volunteerId, noStoredData) {
           var path = amsConst.webApiUrl + '/odata/UpdateCitizenship';
           var data = {
                volunteerId: volunteerId,
                countryCodes: ''          
           };

           for (var i = 0; i < citizenshipDtos.length; i++) {
               if (i === citizenshipDtos.length - 1)
                   data.countryCodes += citizenshipDtos[i].countryCode;
               else
                data.countryCodes += citizenshipDtos[i].countryCode + ','
            }


           return $http.post(path, data).then(function (response) {
                factory.getByVolunteerId(data.volunteerId);
                return response;
            });

        };
     
   

        return {
            data: factory.data,
            getByVolunteerId: factory.getByVolunteerId,
            update: factory.update
        };
    };
    module.factory('citizenshipSvc', citizenshipSvc);

})(angular.module('volunteer'));