(function (module) {

    var currentReviewModalCtrl = function (review, helperSvc, rfrSvc, $scope, $uibModalInstance, programInfoHelperSvc, commissionAbbreviations, programSummarySvc, $filter) {
        var model = $scope;
        model.review = review;
        model.title = 'Current Review';
        model.formatDate = helperSvc.formatDate;
        model.formatUrl = helperSvc.formatUrl;
        model.formatEmptyObject = helperSvc.formatEmptyObject;
        model.getBooleanText = helperSvc.getBooleanText;
        model.onlyOneProgram = programInfoHelperSvc.onlyOneProgram;
        model.isFocusedReport = programInfoHelperSvc.isFocusedReport;
        model.getDegreeLevel = programInfoHelperSvc.getDegreeLevel;
        model.isNewProgram = programInfoHelperSvc.isNewProgram;
        model.getProgramCampus = programInfoHelperSvc.getProgramCampus;
        model.getProgramRequestType = programInfoHelperSvc.getProgramRequestType;
        model.getTitle = programInfoHelperSvc.getProgramTitle;
        model.reviewPrograms = $filter('notCancelledProgram')(review.jsonContents.programs);
        model.close = function () {
            $uibModalInstance.close();
        };

        model.getCommissionName = function (commissionId) {
            return commissionAbbreviations[commissionId];
        };

        model.getLocations = function () {
            return programSummarySvc.getProgramReviewCampuses(model.review);
        }
    };

    module.controller('currentReviewModalCtrl', currentReviewModalCtrl);

}(angular.module('userReview')));