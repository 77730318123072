(function (module) {

    var createTemplateCtrl = function (programs, $scope, $uibModalInstance, $state, $window, alertSvc, localStorage, statementStorageSvc, statementTemplateSvc, reviewTypeIds, readinessSvc, statementSvc, criteriaTypes, codeSvc, helperSvc, statementFindingTypes, criteriaIncluded, programReviewTypeIds) {
        var model = $scope;

        model.dataIsReady = false;
        model.getProgramName = statementStorageSvc.getProgramName;
        model.getCriteriaFullName = readinessSvc.getCriteriaFullName;
        model.useCriterionType = statementStorageSvc.useCriterionType;
        model.criteriaIncluded = criteriaIncluded;
        model.statementFindingTypes = statementFindingTypes;
        model.title = 'Create New Template';      
        model.programs = angular.copy(programs);
        model.numMsg = '<strong>You must check the box on the left next to the finding you want to include in the template before editing the number of findings of that type</strong>. i.e. If there are 3 strengths for the program, check <strong>strength</strong> then select <strong>3</strong>.';

        model.generate = function () {
            var statementCopy = angular.copy(model.statement);
            cleanStatementDetails(statementCopy.statementDetailDtos);
            
            statementStorageSvc.save(statementCopy).then(
                function onSuccess () {
                    alertSvc.addAlertSuccess("Statement details successfully created.");                
                    model.close();
                    $state.go('statementTool', { statementId: model.statement.statementId });
                },
                function onFailure () {
                    alertSvc.addModalAlertWarning('Statement details could not be saved at this time.');
                }
            );
        };

        model.setNumAndValue = function (item) {
            item.number = item.value ? 1 : 0;
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.getProgramSectionsContainer = function(index){
            return model.programSectionsContainer[index];
        }

        model.allFindingTypesValidated = function () {
            if (model.institutionSections) {
                for (var i = 0; i < model.institutionSections.length; i++) {
                    var findingType = model.institutionSections[i];
                    if (findingType.value) {
                        if ((!findingType.criteria && (findingType.number === 0 || findingType.number === null || findingType.number === undefined)) || (findingType.criteria && findingType.criteria.length === 0))
                            return false;
                    }
                }         
            }
            if(model.programSectionsContainer){
                //skips the institution section
                for (var x = 1; x < model.programSectionsContainer.length; x++) {
                    var sections = model.programSectionsContainer[x];

                    if (sections) {
                        for (var i = 0; i < sections.length; i++) {
                            var findingType = sections[i];
                            if (findingType.value) {
                                if ((!findingType.criteria && (findingType.number === 0 || findingType.number === null || findingType.number === undefined)) || (findingType.criteria && findingType.criteria.length === 0))
                                    return false;
                            }
                        }                      
                    }
                }
                return true;       
            }    
        }

        model.isCriteriaNeeded = function (findingType) {
            if (findingType.value === true) {
                return true;
            }
            return false;
        }

        function cleanStatementDetails(statementDetailDtos) {
            for(var i = 0; i < statementDetailDtos.length; i++) {
                var detail = statementDetailDtos[i];
                if (detail.statementCategoryId === 1) {
                    angular.forEach(model.institutionSections, function(section){
                        if(section.value === true){                   
                            var finding = {
                                statementFindingTypeId: section.statementFindingTypeId,
                                statementFindingTypeName: section.statementFindingTypeName,
                                orderNumber: section.orderNumber,
                                findings: []
                            };
                            for (var j = 0; j < section.number; j++) {
                                finding.findings.push({ editable: true, criteria: { text: '', comments: [], response: { sevenDay: null, interimStatus: null, thirtyDay: null, postThirtyDay: null  } }, key: helperSvc.getNextKey() });
                            }
                            detail.statementJson.push(finding);
                        }                    
                    });
                }
                else {
                    //this works because of the offset for Institution being the first statementDetailDto
                    var programSections = model.programSectionsContainer[i];
                    angular.forEach(programSections, function (section) {                        
                        if (section.value === true) { 
                            var finding = {
                                statementFindingTypeId: section.statementFindingTypeId,
                                statementFindingTypeName: section.statementFindingTypeName,
                                orderNumber: section.orderNumber,
                                findings: []
                            }
                            if (statementTemplateSvc.checkForCriteriaNeeded(section)) {
                                angular.forEach(section.criteria, function (criteria) {

                                    finding.findings.push({
                                        criteria: {
                                            criteriaId: criteria.criteriaId,
                                            criteriaName: criteria.criteriaDescription ? criteria.criteriaName + '. ' + criteria.criteriaDescription : criteria.criteriaName,
                                            editable: true,
                                            text: '',
                                            comments: [],
                                            progress: [],
                                            response: { sevenDay: null, interimStatus: statementStorageSvc.isProgramInterimReview(detail.programId) ? statementTemplateSvc.data.responseTemplate : null, thirtyDay: null, postThirtyDay: null},
                                            status: {
                                                editable: true,
                                                isResolved: false,
                                                selectedOptionText: '',
                                                detailText: null
                                            }
                                        },
                                        key: helperSvc.getNextKey()
                                    })
                                },detail);
                            } else {
                                for (var i = 0; i < section.number; i++) {
                                    finding.findings.push({ editable: true, criteria: { text: '', comments: [], response: { sevenDay: null, interimStatus: null, thirtyDay: null, postThirtyDay: null } }, key: helperSvc.getNextKey() });
                                }
                            }

                            detail.statementJson.push(finding);
                        }
                    });                    
                }
            }
        }

        var activate = function () {
            model.numbers = [1, 2, 3, 4, 5];
            var dataArray = [];

           var statementFindingTypes = {
                dataHolder: statementStorageSvc,
                dataLocationName: 'codes.statementFindingTypes',
                svcCallback: statementSvc.getStatementFindingTypes,
                odataResource: true
           }
           dataArray.push(statementFindingTypes);

           var criterion = {
               dataHolder: statementStorageSvc,
               dataLocationName: 'codes.criterion',
               svcCallback: readinessSvc.getCriteria,
               svcCallbackArguments: [[criteriaTypes.CRITERIA, criteriaTypes.MASTERSGENERALCRITERIA, criteriaTypes.APPM]],
               odataResource: true,
               optionalCallback: function () {
                    statementStorageSvc.codes.criterionNoMasters = helperSvc.getFilteredArray(statementStorageSvc.codes.criterion, 'criteriaTypeId', [criteriaTypes.CRITERIA, criteriaTypes.APPM], true);
                    statementStorageSvc.codes.mastersCriterion = helperSvc.getFilteredArray(statementStorageSvc.codes.criterion, 'criteriaTypeId', [criteriaTypes.MASTERSGENERALCRITERIA, criteriaTypes.APPM], true);
                }
           }
           dataArray.push(criterion);

           var statementTypes = {
               dataHolder: statementStorageSvc,
               dataLocationName: 'codes.statementTypes',
               svcCallback: codeSvc.getStatementTypes,
               helperCallback: helperSvc.getResults
           }
           dataArray.push(statementTypes);      

           helperSvc.getData(dataArray).then(function(){
                model.codes = statementStorageSvc.codes;
                //this needs to be called before setting the sections           
                statementTemplateSvc.setStatementFindingTypeTemplates();
                model.institutionSections = angular.copy(statementTemplateSvc.data.institutionSectionsTemplate);
                var programSections = angular.copy(statementTemplateSvc.data.programSectionsTemplate);
                model.statement = angular.copy(statementStorageSvc.data.statement);

                //Change: Need to check individual programs review type.
                //model.isInterimReport = statementStorageSvc.data.currentReviewTeam && statementStorageSvc.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT;

                //need to create a set of programSections for each program
                //and we'll go by index from sectionContainer to statementDetail
                // we need to add a blank institution detail copy to keep index consistency    
                model.programSectionsContainer = [];    
                for (var i = 0; i < model.statement.statementDetailDtos.length; i++) {
                    if (i === 0){
                        model.programSectionsContainer.push({});
                    }else if(statementStorageSvc.isTerminationProgram(model.statement.statementDetailDtos[i])){//if the program is a terminatino need to push a seciton with only 1 findingtype and disable it
                        model.programSectionsContainer.push([statementTemplateSvc.getTerminationPlanFindingTypeTemplate()]);//if we need to make it not required and added just set value to false
                    }
                    else{
                        model.programSectionsContainer.push(angular.copy(programSections));
                    }
                }

                model.dataIsReady = true;   
            });  
        }();
    };

    module.controller('createTemplateCtrl', createTemplateCtrl);

}(angular.module('statement')));