(function (module) {

    var personUserSvc = function ($http, $odataresource, $q, odataSvc) {
        var apiPath = '/User';
        var keyName = 'userId';
        var factory = {};

        factory.getUser = function (id) {

            var oSvc = odataSvc.get();
            oSvc.getSource(apiPath, keyName).odata().get(id, oSvc.onSuccess, oSvc.onFailure)

            return oSvc.getDeferred().promise;
        };

        factory.getUserByPersonId = function (personId) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetUserByPersonId', 'personId', personId)

            oSvc.getSource(path, keyName).odata()
                .single()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getUserByUserName = function (userName) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetUserByUserName', 'userName', userName)

            oSvc.getSource(path, keyName).odata()
                .single()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.checkDuplicateUserName = function (userName, userId) {
            userName = userName.replace('\'', '\'\'');
            var path = '/webapi/odata/CheckDuplicateUserName(userName=\'' + userName + '\')';
            return $http.get(path);
        }

        factory.updateUser = function (user) {
            var oSvc = odataSvc.get();

            user.$update(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        return {
            updateUser: factory.updateUser,
            getUser: factory.getUser,
            getUserByPersonId: factory.getUserByPersonId,
            getUserByUserName: factory.getUserByUserName,
            checkDuplicateUserName: factory.checkDuplicateUserName
        };
    };
    module.factory('personUserSvc', personUserSvc);

})(angular.module('person'));