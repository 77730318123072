(function (module) {

    var visitObserversEditCtrl = function (review, parent, $scope, $uibModalInstance, helperSvc, eventSvc, codeSvc, personSvc, volunteerSvc, reviewTeamVisitObserverSvc, alertSvc, organizationSvc) {
        var model = $scope;

        model.title = 'Visit Observers';
        model.parent = parent;
        model.isInsertMode = false;
        model.isEdit = false;
        model.isAdd = false;
        model.formatDate = helperSvc.formatDate;
        model.isEmpty = helperSvc.isEmpty;
        model.data = reviewTeamVisitObserverSvc.data;
        model.errors = [];

        if (review) {
            model.review = angular.copy(review);
            model.reviewTeamId = model.review.reviewTeamId;
            model.subtitle = review.reviewDto.reviewYear + ' ' + review.commissionName + ' ' + review.reviewTypeName;

            resetVisitObserver();
        } else {
            resetVisitObserver();
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (teamVisitObserver) {
            alertSvc.confirmDelete(teamVisitObserver.personName, deleteFunc, teamVisitObserver);

            function deleteFunc() {
                reviewTeamVisitObserverSvc.delete(teamVisitObserver).then(function (data) {
                    alertSvc.addModalAlertSuccess('Team member successfully deleted.');
                    resetVisitObserver();
                    resetErrors();
                });
            }
        };

        model.addRow = function () {
            resetVisitObserver();
            model.isInsertMode = true;
            model.isAdd = true;
            model.isEdit = false;
        };

        model.cancel = function () {
            resetVisitObserver();
            model.isInsertMode = false;
            resetErrors();
        }

        model.save = function (teamVisitObserver) {
            resetErrors();

            var teamVisitObserverDto = {
                reviewTeamId: model.reviewTeamId,
                reviewTeamVisitObserverId: teamVisitObserver.reviewTeamVisitObserverId,
                personId: teamVisitObserver.person.personId,
                visitObserverTypeId: teamVisitObserver.visitObserverTypeId,
                startDate: teamVisitObserver.startDate,
                endDate: teamVisitObserver.endDate,
                hasReviewedOss: teamVisitObserver.hasReviewedOss,
                note: teamVisitObserver.note,
                affiliationOrganizationId: teamVisitObserver.affiliationOrganizationId,
                affiliationOther: teamVisitObserver.affiliationOther
            }

            if (model.isAdd) {
                reviewTeamVisitObserverSvc.create(teamVisitObserverDto).then(function () {
                    resetVisitObserver();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess('Visit observer successfully created.');
                });
            }
            else if (model.isEdit) {
                reviewTeamVisitObserverSvc.update(teamVisitObserverDto).then(function () {
                    resetVisitObserver();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess('Visit observer successfully updated.');
                });
            }
        };

        model.select = function (person) {
            resetVisitObserver();
            var useUndefined = true;
            resetErrors();
            model.currentVisitObserver = angular.copy(person);
            model.currentVisitObserver.startDate = helperSvc.formatDate(person.startDate, useUndefined);
            model.currentVisitObserver.endDate = helperSvc.formatDate(person.endDate, useUndefined);
            model.currentVisitObserver.person = { personId: person.personId, personName: person.personName};
            model.currentVisitObserver.note = person.note;

            model.showOtherAffiliation = model.currentVisitObserver.affiliationOther ? true : false;
            model.isInsertMode = true;
            model.isAdd = false;
            model.isEdit = true;
        };

        model.searchOrgs = function (searchText) {
            return organizationSvc.quickSearch(searchText).then(function (data) {
                model.noResults = data.value.length > 0 ? false : true;
                data.value.push({ organizationId: -1, organizationName: 'Other' });
                return data.value;
            });
        };

        model.viewOrgSearch = function () {
            model.currentVisitObserver.affiliationOther = '';
            model.showOtherAffiliation = false;
        };
        
        model.onSelectOrg = function (item, obj, label) {
            // OTHER selected
            if (item.organizationId == -1) {
                model.currentVisitObserver.affiliationOrganizationId = null;
                model.showOtherAffiliation = true;
            } else {
                model.currentVisitObserver.affiliationOrganizationName = label;
                model.currentVisitObserver.affiliationOrganizationId = item.organizationId;
            }
        };
        

        codeSvc.getVisitObserverTypes().then(function (data) {
            model.visitObserverTypes = helperSvc.getResults(data);
        });

        function resetVisitObserver() {
            model.currentVisitObserver = {};
            model.currentVisitObserver.person = { personId: null, personName: '' };
        };

        function resetErrors() {
            model.errors = [];
        };

    };

    module.controller('visitObserversEditCtrl', visitObserversEditCtrl);

}(angular.module('review')));