(function (module) {

    var tcAssignmentCtrl = function ($stateParams, eventSvc, barSvc, helperSvc, tcAssignmentSvc) {
        var model = this;
        
        model.isDataReady = false;

        barSvc.showBar();

        (function activate() {
            tcAssignmentSvc.loadAllData().then(function () {
                barSvc.hideBar();
                model.isDataReady = true;
            });
        })();
    };

    module.controller('tcAssignmentCtrl', tcAssignmentCtrl);

}(angular.module('assignment')));