(function (module) {

    /* DOCUMENT directives includes the following:
        - document-files
        - documents
        - documents-list
        - download-document
        - download-document-button
        - merge-documents
		- single-file-upload
        - upload-document
        - upload-document-button
    */

    var templateRoot = '/apps/common/templates/documents/';

    module.directive('documentFiles', function (typeConstSvc, helperSvc, alertSvc, $uibModal, documentSvc) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'documentFiles.html',
            scope: {
                referenceId: '=',
                documentTypeId: '=',
                title: '@?',
                showLabel: '@?',
                showDelete: '@?',
                showUpload: '@?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.documentTypeName = typeConstSvc.getDocumentTypeById(scope.documentTypeId).documentTypeName;
                scope.title = scope.title || (scope.documentTypeName + ' Files');
                scope.showLabel = scope.showLabel || true;
                scope.showDelete = scope.showDelete || true;
                scope.showUpload = scope.showUpload || true;
                scope.missingDocumentsText = 'No ' + scope.documentTypeName + ' Files Uploaded';
                scope.files = [];
                scope.loadFiles = function loadFiles() {
                    var transcriptFilesDataSource = {
                        dataHolder: scope,
                        dataLocationName: 'files',
                        helperCallback: helperSvc.getValue,
                        svcCallback: documentSvc.getDocumentsByTypeForEntity,
                        svcCallbackArguments: [scope.documentTypeId, scope.referenceId]
                    };

                    var dataSourceArray = [
                        transcriptFilesDataSource
                    ];

                    helperSvc.getData(dataSourceArray);
                };

                scope.loadFiles();

                scope.deleteDocument = function (file) {
                    alertSvc.confirmDelete(file.filename, deleteFunc, file.stream_id, file.documentTypeId);

                    function deleteFunc() {
                        documentSvc.deleteDocument(file.stream_id, file.documentTypeId).then(
                            function () {
                                alertSvc.addAlertSuccess("Document successfully deleted.");
                                scope.loadFiles();
                            },
                            function () { alertSvc.addAlertWarning("Document could not be deleted at this time."); }
                        );
                    }
                };

                scope.uploadDocuments = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: templateRoot + 'documentFilesUpload.html',
                        size: 'md',
                        controller: 'documentFilesUploadCtrl',
                        resolve: {
                            referenceId: function () {
                                return scope.referenceId;
                            },
                            documentTypeId: function () {
                                return scope.documentTypeId;
                            }
                        }
                    });

                    modalInstance.result.then(function () {
                        scope.loadFiles();
                    });
                }
            }
        };
    });

    module.directive('documents', function ($uibModal) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'documents.html',
            scope: {
                title: '@?',
                showType: '@?',
                allowEdit: '@?',
                categoryId: '<?',
                referenceId: '<?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.title = scope.title || 'Documents';
                scope.showType = scope.showType ? (scope.showType.toLowerCase() === "true") : false;
                scope.allowEdit = scope.allowEdit ? (scope.allowEdit.toLowerCase() === "true") : false;

                scope.edit = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: templateRoot + 'documentsEdit.html',
                        size: 'lg',
                        controller: 'documentsEditCtrl',
                        resolve: {
                            parent: function () {
                                return scope;
                            }
                        }
                    });
                };
            }
        };
    });

    module.directive('documentsList', function (documentSvc, typeConstSvc, alertSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'documentsList.html',
            scope: {
                showType: '=?',
                showDelete: '@?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.showType = scope.showType ? (scope.showType.toLowerCase() == "true") : false;
                scope.showDelete = scope.showDelete ? (scope.showDelete.toLowerCase() == "true") : false;
                scope.data = documentSvc.data;

                scope.getDocumentTypeName = function (id) {
                    var documentType = typeConstSvc.getDocumentTypeById(id);
                    return documentType ? documentType.documentTypeName : '';
                }

                scope.delete = function (doc) {
                    alertSvc.confirmDelete(doc.documentTitle, deleteFunc, doc.stream_id, doc.documentTypeId);

                    function deleteFunc() {
                        documentSvc.deleteDocument(doc.stream_id, doc.documentTypeId).then(
                            function () {
                                alertSvc.addAlertSuccess("Document successfully deleted.");
                            },
                            function () {
                                alertSvc.addAlertWarning("Document could not be deleted at this time.");
                            }
                        );
                    }
                }
            }
        };
    });

    module.directive('downloadDocument', function (environmentSvc) {
        // May need to update in the future to call documentSvc.downloadDocument for auth check.
        // Note: onclick event handler is a bit of a hack to prevent clicking on download link inside file upload drag-n-drop box from open file dialog

        var hostUrl = (environmentSvc.isDev() ? environmentSvc.getDevApiUrl() : '')

        return {
            restrict: 'E',
            replace: 'true',
            template: `<a href="${hostUrl}/webapi/GetSingleDocument/{{streamId}}{{filenameParam}}" target="_blank" download onclick="event.stopPropagation();">{{linkText}}</a>`,
            scope: {
                streamId: '@',
                linkText: '@',
                filename: '@?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.filenameParam = attrs.filename ? '?newFileName=' + scope.filename : '';
            }
        };
    });

    module.directive('downloadDocumentButton', function () {
        return {
            restrict: 'E',
            replace: 'true',
            template: '<a href="/webapi/GetSingleDocument/{{streamId}}" target="_blank" download onclick="event.stopPropagation();" class="btn btn-sm btn-default">' +
                        '<i class="fa fa-download " aria-hidden="true">' +
                      '</a>',
            scope: {
                streamId: '@'
            }
        };
    });

    //merges the list of streamIds in order the ids are inputted. I.E: '1234xys', '3456yui' = 1234 doc and then 3456 doc
    module.directive('mergeDocuments', function (documentSvc, alertSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'documentMerge.html',
            scope: {
                streamids: '='
            },
            link: function (scope, element, attrs, ctrl) {
                scope.newMergeFileName = '';
                var streamIdList;

                scope.mergeFiles = function () {
                    if (scope.streamids) {
                        if (typeof scope.streamids === 'string') {
                            streamIdList = scope.streamids.split(',');
                        }
                        else if (Array.isArray(scope.streamids)) {
                            streamIdList = scope.streamids.join(',');
                        }
                    }

                    documentSvc.mergeDocuments(streamIdList, scope.newMergeFileName);
                }
            }

        }
    });

    module.directive('singleFileUpload', function (Upload) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'singleFileUpload.html',
            scope: {
                documentTitle: '=?',
                file: '=',
                required: '@?',
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.isRequired = (attrs.required);
                scope.includeTitle = (attrs.documentTitle);
            }
        };
    });

    module.directive('uploadDocument', function (documentSvc) {
        // May need to update in the future to call documentSvc.downloadDocument for auth check.
        // Note: onclick event handler is a bit of a hack to prevent clicking on download link inside file upload drag-n-drop box from open file dialog
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'uploadDocument.html',
            scope: {
                streamId: '@',
                onUpload: '&',
                onDownload: '&',
                filename: '@?',
                fileTypes: '<?',
                disableAction: '=?',
                restrictUploadFunction: '&?'
            },

            link: function (scope, element, attrs, ctrl) {

                scope.restrictUpload = function(){
                    if(scope.restrictUploadFunction && scope.restrictUploadFunction() === true)
                        return true;
                    else
                        return false;
                }

                scope.onMyUpload = function (streamId, file) {
                    scope.streamId = streamId;
                    scope.filename = file.name;
                    scope.onUpload({ streamId: streamId, file: file });
                }

                scope.onMyDownload = function (streamId) {
                    scope.onDownload({ streamId: streamId });
                }
                
                var activate = function () {
                    // Make sure  data is loaded
                    loadFilename();
                    scope.$watch('streamId', function () { loadFilename(true); });
                }();

                function loadFilename(reload) {
                    if (scope.streamId && (!scope.filename || reload)) {
                        documentSvc.getDocumentNameByStreamId(scope.streamId).then(function (data) {
                            scope.filename = data.value;
                        });
                    }
                }
            }
        };
    });

    module.directive('uploadDocumentButton', function (alertSvc, documentSvc) {
        // Allow user to upload a single document, potentially replacing an existing document.
        // Note: onclick event handler is a bit of a hack to prevent clicking on download link inside file upload drag-n-drop box from open file dialog
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'uploadDocumentButton.html',
            scope: {
                streamId: '@',
                filename: '@?',
                onUpload: '&',
                disableAction: '=?',
                fileTypes: '<?' // pdf, rtf, txt, doc, docx, xls, xlsx
            },
            link: function (scope, element, attrs, ctrl) {

                var activate = function () {
                    // Set accepted file types
                    if (scope.fileTypes) {
                        var mimeTypes = {
                            pdf: 'application/pdf',
                            rtf: 'application/rtf',
                            txt: 'text/plain',
                            doc: 'application/msword',
                            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            xls: 'application/vnd.ms-excel',
                            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            zip: 'application/x-zip-compressed'
                        };

                        var str = scope.fileTypes.map(function (fileType) {
                            if (mimeTypes[fileType.toLowerCase()]) return mimeTypes[fileType.toLowerCase()];
                        }).join(',');

                        scope.accept = str;
                        scope.pattern = "'" + str +"'";
                    }
                }();

                scope.upload = function (file, invalidFiles) {
                    if (file) {
                        if (scope.streamId) {
                            // Replace existing file
                            var replaceMsg = "You are about to replace " + getFilenameText() + " with <em><strong> " + file.name + " </strong></em>. Do you want to proceed?";
                            alertSvc.confirm(replaceMsg, function () {
                                scope.disableAction = true;
                                documentSvc.deleteFile(scope.streamId).then(function () {
                                    doUpload(file);
                                });
                            });
                        } else {
                            scope.disableAction = true;
                            doUpload(file);
                        }
                    } else if (invalidFiles && invalidFiles.length > 0) {
                        // Uploaded unacceptable file type

                        // input = [1,2,3,4]
                        // outut = "1, 2, 3 and 4"
                        var fileNames = scope.fileTypes.join(', ').replace(/,(?!.*,)/gmi, ', and');;

                        alertSvc.openModalAlert("Only <strong><em>" + fileNames + "</em></strong> files are accepted for upload. Please save your document as an accepted file type and try again.", "Wrong File Type");
                    }

                    function doUpload(file) {
                        documentSvc.uploadFile(file).then(
                            function (response) {
                                scope.$evalAsync(function ($scope) {
                                    scope.streamId = response.data.value;
                                    scope.filename = file.name;
                                    scope.onUpload({ streamId: response.data.value, file: file });
                                    scope.disableAction = false;
                                });
                            },
                            function (response) {
                                alertSvc.addModalAlertWarning("File could not be uploaded at this time.");
                            }
                        );
                    }
                };

                function getFilenameText() {
                    return scope.filename != null && scope.streamId != null ? "<em><strong>" + scope.filename + "</strong></em>" : "the original file";
                }
            }
        };
    });

}(angular.module('common')));