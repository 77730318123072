(function (module) {

    var secondaryContactsCtrl = function ($scope, $uibModal, contactRoleTypes) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Secondary Contacts';
        model.type = 'secondary';
        model.helpText = 'ABET defines a secondary contact for a given Commission as an individual whom the institution requires to be informed of elements of an accreditation review but does not have the authority to commit the institution or its programs to an accreditation review.  This individual typically assists the primary contact in submitting the Request for Evaluation and works closely with the primary contact to ensure that all aspects of the requested review are handled from the institution’s perspective.<br/><br/><strong>Restricted to two (2) per Commission</strong>';

        model.editSecondaryContacts = function (isCreateMode, isReplaceMode, currentContact, editView, type) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/contacts/templates/contactsEdit.html',
                size: 'lg',
                controller: 'contactsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    contacts: function () {
                        return model.parent.secondaryContacts;
                    },
                    roleId: function () {
                        return contactRoleTypes.SECONDARY;
                    },
                    isCreateMode: function () {
                        return isCreateMode;
                    },
                    isReplaceMode: function () {
                        return isReplaceMode;
                    },
                    currentContact: function () {
                        return currentContact;
                    },
                    editView: function () {
                        return editView;
                    },
                    type: function () {
                        return model.type;
                    }
                }
            });
        };
    };

    module.controller('secondaryContactsCtrl', secondaryContactsCtrl);

}(angular.module('contacts')));