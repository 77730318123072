(function (module) {

    var reviewStatusTrackingEditCtrl = function (review, parent, $scope, $uibModalInstance, helperSvc, eventSvc, codeSvc, alertSvc, reviewTeamProcessTrackingSvc) {
        var model = $scope;

        model.parent = parent;
        model.title = parent.title;
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        model.isEmpty = helperSvc.isEmpty;
        model.formatDate = helperSvc.formatDate;
        model.data = reviewTeamProcessTrackingSvc.data;

        

        if (review) {
            model.review = angular.copy(review);
           
        }
        resetCurrentReviewStatus();

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (reviewStatus) {
            alertSvc.confirmDelete(reviewStatus.reviewProcessStatusDto.statusName, deleteFunc, reviewStatus);

            function deleteFunc() {
                reviewTeamProcessTrackingSvc.delete(reviewStatus).then(function (data) {
                    if (model.currentReviewStatus === reviewStatus) {
                        model.isInsertMode = false;
                        alertSvc.addAlertSuccess("Review status successfully deleted.");
                    }
                });
            }
        };

        model.addRow = function () {
            resetCurrentReviewStatus();
            model.isInsertMode = true;
            model.isCreateMode = true;
            model.isUpdateMode = false;
        };

        model.cancel = function () {
            resetCurrentReviewStatus();
            model.isInsertMode = false;
        }

        model.save = function (reviewStatus) {
            if (model.isCreateMode) {
                reviewTeamProcessTrackingSvc.create(reviewStatus).then(function () {
                    resetCurrentReviewStatus();                   
                    model.isInsertMode = false;
                    alertSvc.addAlertSuccess("Review status successfully added.");
                });
            } else if (model.isUpdateMode) {
                reviewTeamProcessTrackingSvc.update(reviewStatus).then(function () {
                    resetCurrentReviewStatus();            
                    model.isInsertMode = false;
                    alertSvc.addAlertSuccess("Review status successfully updated.");
                });
            }
            
        };

        model.select = function (reviewStatus) {
            var useUndefined = true;
            model.currentReviewStatus = angular.copy(reviewStatus);
            model.currentReviewStatus.commitDate = helperSvc.formatDate(model.currentReviewStatus.commitDate, useUndefined);

            model.isInsertMode = true;
            model.isCreateMode = false;
            model.isUpdateMode = true;
        };

        codeSvc.getReviewProcessStatuses().then(function (data) {
            model.reviewStatusTypeOptions = helperSvc.getResults(data);
        });

        function resetCurrentReviewStatus () {
            model.currentReviewStatus = {
                reviewTeamId: review.reviewTeamId,
                reviewTeamProcessTrackingId: 0,
                reviewProcessStatusId: 0
                //reviewProcessStatusDto: {}
            };
        };
    };

    module.controller('reviewStatusTrackingEditCtrl', reviewStatusTrackingEditCtrl);

}(angular.module('review')));