(function (module) {

    var templateRoot = '/apps/contacts/templates/';

    module.directive('primaryContacts', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'primaryContacts.html'

        };
    });

    module.directive('secondaryContacts', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'secondaryContacts.html'

        };
    });

    module.directive('presidentContacts', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'presidentContacts.html'
        };
    });

    module.directive('billingContacts', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'billingContacts.html'
        };
    });

    module.directive('programContacts', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programContacts.html'
        };
    });

    module.directive('otherContacts', function ($document) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'otherContacts.html',
            scope: {
                institutionMode: '@?'
            }
        };
    });
    
    module.directive('manageContacts', function (helperSvc) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'manageContacts.html',
            scope: {
                isPreviewMode: '<?'
            }
        };
    });

    module.directive('contacts', function (helperSvc, oauth, $stateParams, currentUserHelper, contactRoleTypes, contactSvc, currentUser, personSvc) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'contactList.html',
            scope: {
                contactsArr: '=contacts',
                currentContact: '=contact',
                type: '@',
                editFunc: '&edit',
                deleteFunc: '&delete',
                delegatesArr: '=?delegates',
                isPreviewMode: '<?',
                isLiaisonContactsMode: '@?liaisonContacts',
                isOtherContactsMode: '@?otherContacts',
                isRfeMode: '@?rfeMode'
            },
            link: function (scope, elem, attrs) {
                scope.organizationId = $stateParams.organizationId;
                scope.impersonate = personSvc.impersonate;
                scope.isAdmin = oauth.isAdmin();

                scope.liaisonFilterExpression = function (contact) {
                    return (contact.roleName === 'Education Liaison' || contact.roleName === 'Liaison');
                };

                scope.otherFilterExpression = function (contact) {
                    // If you decide to filter out PEVs when billing/financial/other contacts are originally called
                    // you can remove contact.roleName !== 'Program Evaluator' from this filter
                    return (contact.roleName !== 'Program Evaluator' && contact.roleName !== 'Education Liaison' && contact.roleName !== 'Liaison');
                };

                scope.isCurrentFilterExpression = function (contact) {
                    return !contact.endDate || new Date(contact.endDate) >= new Date();
                }

                scope.newDesignationsContactFilterExpression = function (contact) {
                    if (contact.designationViewModels && contact.designationViewModels.length > 0) {
                        for (var i = 0; i < contact.designationViewModels.length; i++) {
                            if (contact.designationViewModels[i].designationId !== 0 && 
                                contact.designationViewModels[i].designationId !== null) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                scope.newDesignationsFilterExpression = function (designation) {
                    return (designation.designationId !== 0 && designation.designationId !== null);
                };

                if (scope.isLiaisonContactsMode || scope.isOtherContactsMode) scope.nonInstitutionTypeName = scope.isLiaisonContactsMode ? 'Liaison' : 'Other';

                scope.options = {
                    delegates: (attrs.delegates) ? true : false,
                    addDelegate: (attrs.adddelegate) ? true : false,
                    replaceDelegate: (attrs.replacedelegate) ? true : false,
                    deleteDelegate: (attrs.deletedelegate) ? true : false
                };

                scope.helpText = {
                    primaryDelegate: 'The primary contact’s delegate is an individual whom the primary contact allows to take action within ABET’s data management system on the primary contact’s behalf. There is no requirement for a primary contact to assign a delegate.',
                    presidentDelegate: 'The President’s delegate is an individual whom the President allows to take action within ABET’s data management system on the President’s behalf.  There is no requirement for the President to assign a delegate.'
                };

                scope.previewMode = {};
                scope.readOnlyMode = {};

                scope.setModes = function (contact) {
                    var loggedInUserCanEditContact = false;
                    for (var i = 0; i < contact.designationViewModels.length; i++) {
                        if (oauth.isDesignated(scope.organizationId, contact.designationViewModels[i].designationId) || currentUserHelper.isDelegateForPrimary(scope.organizationId, contact.designationViewModels[i].designationId)) {
                            loggedInUserCanEditContact = true;
                        }
                    }

                    if (oauth.isAdmin() || currentUserHelper.isPresident(scope.organizationId) || currentUserHelper.isDelegateForPresident(scope.organizationId)) {
                        loggedInUserCanEditContact = true;
                    }

                    if ((oauth.hasRole(scope.organizationId, contactRoleTypes.PRIMARY) || oauth.hasRole(scope.organizationId, contactRoleTypes.SECONDARY) || oauth.hasRole(scope.organizationId, contactRoleTypes.DELEGATEFORPRIMARY)) && 
                        (contact.roleId === contactRoleTypes.PRESIDENT || contact.roleId === contactRoleTypes.DELEGATEFORPRESIDENT || contact.roleId === contactRoleTypes.PROGRAM || contact.roleId === contactRoleTypes.BILLING)) {
                        loggedInUserCanEditContact = true;
                    }

                    if (!helperSvc.arrContains(contactSvc.data.excludedRoleOptions, contact.roleId)) {
                        loggedInUserCanEditContact = true;
                    }

                    scope.previewMode[contact.personId] = scope.isPreviewMode;
                    scope.readOnlyMode[contact.personId] = !loggedInUserCanEditContact;
                };
                
                var currentContact = {}

                scope.delegateFound = {};
                scope.isDeleted = true;

                scope.setDelegateFound = function (contact, designationVm, isDeleted) {
                    if (currentContact !== contact) {
                        currentContact = contact;
                        scope.delegateFound[contact.personId] = false;
                    }

                    scope.delegateFound[contact.personId] = ((designationVm.designationId === contact.personId) && !isDeleted);
                };

                scope.deleteDelegate = function (obj) {
                    scope.deleteFunc(obj);
                };

                scope.isEmpty = helperSvc.isEmpty;

                scope.getContactRoleText = function (contact) {
                    var excludedRoles = contactSvc.data.excludedRoleOptions;
                    var text = null;

                    if (!helperSvc.arrContains(excludedRoles, contact.roleId)) {
                        text = contact.roleName;
                    }

                    return text;
                };

                scope.currentContactHasDelegate = function (contact) {
                    if (contact && scope.delegatesArr && scope.delegatesArr.length > 0) {
                        for (var i = 0; i < scope.delegatesArr.length; i++){
                            var delegate = scope.delegatesArr[i];
                            var isDelegateForContact = helperSvc.getFilteredArray(delegate.designationViewModels, 'designationId', contact.personId, true);
                            if (isDelegateForContact.length > 0)
                                return true;
                        };
                    }
                    return false;
                }

                scope.canAddDelegate = function (contact) {
                    return currentUser.profile.personId == contact.personId;
                };
            }
        };
    });

}(angular.module('contacts')));