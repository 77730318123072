(function (module) {

    var personAddressSvc = function ($http, $q, codeSvc, amsConst, $odataresource, odataSvc) {
        var apiPath = amsConst.webApiUrl + '/odata/PersonAddress';
        var factory = {};
        var keyName = 'personAddressId';

         var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getByPersonId = function (personId) {
            //return $http.get(apiPath + '?$filter=personId eq ' + personId).then(onSuccess, onFailure);
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonAddressesByPersonId', 'personId', personId)

            oSvc.getSource(path, keyName).odata()
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };
        
        factory.save = function (addresses) {
            var data = { "Value": addresses };
            return $http.post(apiPath + '/Ams.SavePersonAddresses', data).then(onSuccess, onFailure);
        };

        factory.getHomeAddress = function (personId) {
            return $http.get(apiPath + "?$filter=personId eq " + personId + " and addressTypeName eq 'home'").then(onSuccess, onFailure);
        };

        factory.getWorkAddress = function (personId) {
            return $http.get(apiPath + "?$filter=personId eq " + personId + " and addressTypeName eq 'work'").then(onSuccess, onFailure);
        };

        return {
            getByPersonId: factory.getByPersonId,
            save: factory.save,
            getHomeAddress: factory.getHomeAddress,
            getWorkAddress: factory.getWorkAddress
        };
    };
    module.factory('personAddressSvc', personAddressSvc);

})(angular.module('person'));