(function (module) {
    var myReportCtrl = function ($state, alertSvc, reportSvc, helperSvc, $uibModal, currentUser) {
        var model = this;

        model.title = 'My Reports';
        model.isDataReady = false;
        model.openInstructions = reportSvc.openInstructions;

        model.openReportModal = function (report) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/report/templates/preview.html',
                size: 'xl',
                controller: 'previewCtrl',
                resolve: {
                    reportName: function () { return report.reportName; },
                    inparams: function () {
                        return [{
                            name: 'ParamPersonId',
                            value: currentUser.profile.personId
                        }];
                    }
                }
            });
        };

        model.openReportNewWindow = function (report) {
            $state.go('reportDetail', {
                reportName: report.reportName
            });
        };

        var activate = function () {
            reportSvc.getMyReports().then(function () {
                model.data = reportSvc.data;
                model.isDataReady = true;
            })         
        }();
    };

    module.controller('myReportCtrl', myReportCtrl);

}(angular.module('report')));