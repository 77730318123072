(function (module) {

    var demographicInfoCtrl = function ($scope, $uibModal, volunteerSvc, volunteerHelperSvc, pevAppSvc, citizenshipSvc, componentHelperSvc) {
        var model = this;
        var checkIsVolunteer = true;

        model.parent = $scope.$parent.model;
        model.title = 'Demographic Information';
        model.applicationMode = pevAppSvc.isApplicationMode(checkIsVolunteer);
        model.isButtonHidden = model.applicationMode ? pevAppSvc.isEditButtonHiddenForGeneralModules() : pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;

        if (model.applicationMode) {
            model.data = pevAppSvc.volunteerData;
            model.citizenshipData =  model.data.volunteer;
        } else {
            model.data = volunteerSvc.data;
            model.citizenshipData = citizenshipSvc.data;
        }

        componentHelperSvc.invokeOnRendered('demographicInfo', $scope);

        model.getCitizenshipList = function () {
            return model.applicationMode ? model.data.volunteer.citizenship : model.citizenshipData.citizenships;
        };

        model.editDemographicInfo = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/demographicInfoEdit.html',
                size: 'lg',
                controller: 'demographicInfoEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('demographicInfoCtrl', demographicInfoCtrl);

}(angular.module('volunteer')));