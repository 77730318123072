(function (module) {

    var personUserGroupsAndRolesCtrl = function ($stateParams, $location, $uibModal, personSvc, personUserSvc, membershipSvc, roleSvc, personMdl, eventSvc, $scope, $state) {
        var model = this;
        var parent = $scope.$parent.model;
        model.title = 'User Groups and Roles';

        eventSvc.listen(loadOrganizationUsers, 'loadOrganizationUsers');

        // This controller won't be listening for loadOrganizationUsers before that event is broadcast if its 
        // directive is missing from the DOM (e.g. when it is inside ng-if block whose condition evaluates to false).
        // Explicitly call loadOrganizationUsers to initialize data in this case.
        loadOrganizationUsers(); 

        //returns an array populated with entries grouped by properties as defined by callback function f
        var groupBy = function (array, f) {
            var groups = {};

            array.forEach(function (o) {
                var group = JSON.stringify(f(o));
              
                groups[group] = groups[group] || [];
                groups[group].push(o);
            });

            //return final array with subgroup arrays
            //Object.keys returns array of groups enumerable properties
            //.map uses a callback function on each element in an array
            return Object.keys(groups).map(function (group) {
                return groups[group];
            })
        };

        model.editUserGroupsAndRoles = function (personId) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/person/templates/userGroupsAndRolesEdit.html',
                size: 'lg',
                controller: 'personUserGroupsAndRolesEditCtrl',
                resolve: {
                    userId: function () {
                        return model.userId;
                    },
                    personName: function () {
                        return model.personName;
                    }
                }
            });
        };

        model.goToOrganization = function (organizationUserId) {
            $state.go('organization.single', { organizationId: organizationUserId });
        }

        function loadOrganizationUsers() {
            if (parent == null) return;

            model.personName = parent.personName;
            model.userId = parent.userId;
            model.organizationUsers = parent.organizationUsers;
        }
    };

    module.controller('personUserGroupsAndRolesCtrl', personUserGroupsAndRolesCtrl);

}(angular.module('person')));