(function (module) {

    var trainingInfoEditCtrl = function ($scope, $uibModalInstance, $stateParams, parent, trainingInfoSvc, societyMembershipSvc, volunteerHelperSvc, alertSvc, oauth, contactRoleTypes, volunteerSvc) {
        var model = $scope;
        var activeItemName = "activeItem";

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.volunteerId = parseInt($stateParams.volunteerId);
        model.currentYear = new Date();
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        var isAdmin = oauth.isAdmin();

        model.save = function () {
            model[activeItemName].volunteerId = model.volunteerId;

            if (model.isCreateMode) {
                trainingInfoSvc.create(model[activeItemName]).then(function () {
                    alertSvc.addModalAlertSuccess("Training session succesfully added.");
                    model.toggleModes(model);
                });
            } else if (model.isUpdateMode) {
                trainingInfoSvc.update(model[activeItemName]).then(function () {
                    alertSvc.addModalAlertSuccess("Training session succesfully updated.");
                    model.toggleModes(model);
                });
            }
        };

        model.edit = function (training) {
            volunteerHelperSvc.copyDataAndSetModes(model, activeItemName, training);
        };

        model.delete = function (training) {
            alertSvc.confirmDelete(training.trainingDescription, deleteFunc, training);

            function deleteFunc () {
                trainingInfoSvc.delete(training).then(function () {
                    alertSvc.addModalAlertSuccess("Training session succesfully deleted.");
                });
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleModes = function () {
            volunteerHelperSvc.toggleModes(model, activeItemName);
        };

        model.isUserAllowed = function (training) {
            return isAdmin || oauth.hasRole(training.organizationId, contactRoleTypes.EDUCATIONLIASON) || oauth.hasRole(training.organizationId, contactRoleTypes.TRAININGMANAGER);
        };

        activate();

        function activate() {
            model.data = trainingInfoSvc.data;

            if (isAdmin) {
                model.societyData = societyMembershipSvc.data;
            }
            else {
                model.societyData = { memberSocietyOptions: volunteerSvc.getSocietyOptionsByCurrentUser(societyMembershipSvc.data.memberSocietyOptions) };
            }
        }
    };

    module.controller('trainingInfoEditCtrl', trainingInfoEditCtrl);

}(angular.module('volunteer')));