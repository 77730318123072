(function (module) {

    var currentReviewsCtrl = function ($stateParams, $state, reviewTeamSvc, helperSvc, $scope, eventSvc) {
        var model = this;
        var parent = $scope.$parent.model;

        model.data = reviewTeamSvc.data;

        model.organizationId = $stateParams.organizationId;
        model.title = 'Current RFR/Reviews';
        model.noCurrentReviewsMsg = 'No ongoing reviews';

        var pathRoot = '/admin/organization/' + model.organizationId + '/reviews';

        model.openReviewPage = function () {
            $state.go('reviews');
        };

        model.openSelectedReview = function (review) {
            $state.go('reviews.detail', { reviewTeamId: review.reviewTeamId });
        };

        model.isEmpty = helperSvc.isEmpty;

        model.getYearSpan = function (negativeYear) {
            var positiveYear = negativeYear.slice(1, 5);
            return helperSvc.getYearSpan(positiveYear);
        };

    };

    module.controller('currentReviewsCtrl', currentReviewsCtrl);

}(angular.module('organization')));