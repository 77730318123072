(function (module) {

    var programAreasCtrl = function ($scope, $uibModal, $state, programAreaSvc, pevAppSvc, oauth, volunteerHelperSvc, contactRoleTypes, alertSvc, componentHelperSvc) {
        var model = this;
        var isAdmin = oauth.isAdmin();

        model.parent = $scope.$parent.model;
        model.title = 'Program Areas';
        model.getProgramAreaText = programAreaSvc.getProgramAreaText;
        model.isApplication = pevAppSvc.isApplicationMode();
        model.isButtonHidden = model.isApplication ? pevAppSvc.isEditButtonHidden() : !canEditProgramAreaFromVolunteerPage();
        model.isReadOnly =!canEditProgramAreaFromVolunteerPage();
        model.readMore = alertSvc.readMore;

        model.getSocietyNamesForDiscipline = pevAppSvc.getSocietyNamesForDiscipline;

        if (model.isApplication) {
            model.data = pevAppSvc.volunteerDisciplineData;
        } else {
            model.data = programAreaSvc.data;
        }

        componentHelperSvc.invokeOnRendered('programAreas', $scope);

        model.editProgramAreas = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/programAreasEdit.html',
                size: 'lg',
                controller: 'programAreasEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        function canEditProgramAreaFromVolunteerPage(){
            if (model.isApplication && volunteerHelperSvc.isReadOnly){
                return true;
            }
            if((oauth.getOrganizationsByUserRoleId(contactRoleTypes.EDUCATIONLIASON).length > 0 && $state.current.name !== "pevAssignmentTool")  || oauth.isAdmin())
                return true;
            return false;
        }
    };

    module.controller('programAreasCtrl', programAreasCtrl);

}(angular.module('volunteer')));