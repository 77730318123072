(function (module) {

    module.filter('firstFourChars', function () {
        return function (input) {
            return (typeof input === 'string') ? input.substr(0, 4) : input;
        }
    });

    module.filter('acronymize', function () {
        return function (input) {
            var newVal = input;

            if (typeof input === 'string') {
                newVal = input.split(' ').map(function (word) {
                    if (word.toLowerCase() == 'and') {
                        return '&';
                    }
                    return word[0];
                }).join('');
            }
            return newVal;
        }
    });

    module.filter('setIndex', function (assignmentSvc) {
        return function (items) {
            return assignmentSvc.setIndex(items);
        };
    });

    module.filter('hideReleasedForSelection', function (pevAssignmentSvc) {
        var reqs = pevAssignmentSvc.pevReqs;

        return function (programs) {

            if (pevAssignmentSvc.releasePending && pevAssignmentSvc.showReleasableOnly) {
                return programs.filter(function (p) {
                    if (pevAssignmentSvc.releasingPEVs) {
                        return reqs.hasStartDate(p) && reqs.hasAssignees(p) && !reqs.pevsReleased(p);
                    } else if (pevAssignmentSvc.releasingObservers) {
                        return reqs.hasStartDate(p) && p.observersAssigned && !reqs.observersReleased(p);
                    }
                });
            } else {
                return programs;
            }
        };
    });

    module.filter('reviewTypeCode', function () {
        return function (reviews, targetReviewTypeCode) {
            if (!reviews) return reviews;
            
            var filteredReviews = reviews.filter(function (review) {
                return review.reviewTypeCode == targetReviewTypeCode;
            });

            return filteredReviews;
        }
    });

    module.filter('excludeReviewTypeCode', function () {
        return function (reviews, targetReviewTypeCode) {
            if (!reviews) return reviews;

            var filteredReviews = reviews.filter(function (review) {
                return review.reviewTypeCode !== targetReviewTypeCode;
            });

            return filteredReviews;
        }
    });

}(angular.module('assignment')));