(function (module) {

    var organizationCommissionSvc = function ($http, $q, odataServiceFactory) {
        var config = {
            apiPath: '/OrganizationCommission',
            keyName: 'organizationCommissionId',
            foreignKeyName: 'organizationId',
            dataCollectionName: 'organizationCommissions'
        };
        var factory = new odataServiceFactory(config);
        //var apiPath = amsConst.webApiUrl + '/odata/({0})';
        //var factory = {};

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        //factory.getCommissionsByOrganizationId = function (id) {
        //    return $http.get(apiPath.format(null) + "?$filter=organizationId eq " + id).then(onSuccess, onFailure);
        //};

        //factory.create = function (organizationCommission) {
        //    return $http.post(apiPath.format(null), organizationCommission).then(onSuccess, onFailure);
        //};

        //factory.delete = function (id) {
        //    if (confirm("Are you sure you want to delete this record?")) {
        //        return $http.delete(apiPath.format(id)).then(onSuccess, onFailure);
        //    }
        //    else {
        //        return $q.reject('user cancelled');
        //    }
        //};

        factory.save = function (organizationCommissions) {
            var data = { "Value": organizationCommissions };
            var path = '/webapi/odata/SaveOrganizationCommissions';
            var orgId = organizationCommissions.length > 0 ? organizationCommissions[0].organizationId : 0;

            var promise = $http.post(path, data).then(onSuccess, onFailure);

            promise.then(function () {
                factory.getByForeignKey(orgId);
            });

            return promise;
        };

        return {
            getCommissionsByOrganizationId: factory.getByForeignKey,
            create: factory.create,
            delete: factory.delete,
            save: factory.save,
            data: factory.data
        };
    };
    module.factory('organizationCommissionSvc', organizationCommissionSvc);

})(angular.module('organization'));