(function (module) {
    'use strict';

    var npcPDFSvc = function (helperSvc, teamMemberTypeNames, commissionTypes, monthNames, statementFindingTypes, programReviewSvc, npcChangeTypeIds, npcFinalActionTypeIds) {

        var factory = { };

        var EMPTY_SECTION = {
            BLANK: {
                text: 'No text has been added to this section\n\n',
                style: ['small', 'body', 'paragraph', 'italic']
            }
        };
        var NPC_DATA;


        //data

        var instructions = {
            general: [
                "A Change Review Report is typically a statement outlining the changes that have been brought forth to ABET, the institution's actions to remain in compliance, and an assessment of the effectiveness of those actions.",
                "The report provides a brief introduction of the program, summarizes the changes being made to the program, describes the material reviewed, summarizes how shortcomings from the previous visit are impacted (if applicable), and evaluates the impact of the changes on continued compliance with ABET Criteria and Policies.",
                "Change reviews are done per program and the report headers include the program name and applicable program criteria.",
                "Five report sections are included: Introduction, Change Summary, Material Reviewed, Findings, and Recommendation",
                "For Reviewers the APPM limits recommendations to either continue accreditation or to conduct a focused visit to determine the program’s status."
            ],
            termination: [
                'A termination statement is typically a concise statement outlining the status of a program that is being terminated and the institution’s plan to bring the program to an organized close without negatively impacting students remaining in the program.',
                'The statement provides a brief introduction of the program, explains why the program is being terminated, confirms that a termination plan has been created in accordance with the APPM, describes how students remaining in the program will be handled, and summarizes how shortcomings from the previous visit were addressed.',
                'Terminations are done per program and the statement headers include the program name and applicable program criteria.',
                'Two Statement sections are included: Introduction, Termination Plan'
            ]
        }

        var actionTexts = {
            otherChanges: "Accreditation will be maintained for the duration of the current accreditation period.",
            nameChange: "Accreditation will be maintained for the duration of the current accreditation period for the program under the new name.",
            termination: "Accredit to {{AccreditationEndDate}}",
            focusedVisit: "An immediate focused on-site review at the program’s expense is required to determine the accreditation status of the changed program. Based on the recommendation coming from the immediate focused on-site review, the accreditation status of the program may be changed upon the vote of the Commission’s Executive Committee.  If the institution declines the immediate focused on-site review, this action shall be cause for revocation of accreditation of the program under consideration."
        }

        //styles

        var tableLayout = {
            paddingLeft: function () { return 0; }
        }

        var paddedTable = {
            paddingLeft: function () { return 5; },
            paddingRight: function () { return 5; },
            paddingTop: function () { return 5; },
            paddingBottom: function () { return 5; }
        }

        var styles = {
            paragraph: {
                lineHeight: 1.3,
                alignment: 'justify'
            },
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            medium: {
                fontSize: 12
            },
            large: {
                fontSize: 16
            },
            larger: {
                fontSize: 18
            },
            jumbo: {
                fontSize: 27
            },
            italics: {
                italics: true
            },
            bold: {
                bold: true
            },
            // Fonts and images are defined in vfs_fonts.js
            campton: {
                font: 'campton'
            },
            camptonBook: {
                font: 'camptonBook'
            },
            camptonExtraBold: {
                font: 'camptonExtraBold'
            },
            camptonExtraLight: {
                font: 'camptonExtraLight'
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            camptonSemiBold: {
                font: 'camptonSemiBold'
            },
            camptonThin: {
                font: 'camptonThin'
            },
            italic: {
                italics: true
            },
            body: {
                font: 'egyptienne'
            },
            orange: {
                color: '#FF6C2C'
            },
            addLineSpacing: {
                lineHeight: 1.8
            }
        };


        //useful

        function splitByTag(html) {
            //takes in html and splits by tag, returning array of html string
            return html.match(/<(.*?)>.*?<\/\1>/g);
        }

        function htmlToElement(html) {
            var template = document.createElement('template');
            html = html.trim();
            template.innerHTML = html;
            // our boy Internet Explorer doesn't do template.content
            if (template.content) return template.content.firstChild;
            else return template.firstChild;
        }

        function htmlToElementArray(html) {
            //takes in html string splits by tag, and returns array of element objects
            var elms = [];
            var split = html ? splitByTag(html) : null;
            if (!split) return elms;
            for (var i = 0; i < split.length; i++) {
                elms.push(htmlToElement(split[i]));
            }
            return elms;
        }

        function substituteRegularHyphen(str) {
            // Replace non-traditional hyphen character with regular hyphen
            // Non-traditional hyphen doesn't show up
            return str.replace(/‐/g, '\u2010');
        }

        function substituteBullets(str) {
            // Replace pseudo-bullets done with asterisks with proper bullets and non-breaking spaces.
            return str.replace(/((^|\n|\\n)\*\s{1,2})(?=\w)/g, '$2\u2022\u00A0');
        }

        function makeSubstitutions(str) {
            // Substitute certain strings and characters with more appropriate, PDF-friendly characters.
            var substitutedStr = str;
            //substitutedStr = substituteNonBreakingHyphen(substitutedStr);
            substitutedStr = substituteRegularHyphen(substitutedStr);
            substitutedStr = substituteBullets(substitutedStr);
            return substitutedStr;
        }

        function getElementText(elm) {
            var text = elm.textContent || elm.innerText || '';
            text = text.toLowerCase().trim() === 'click to add text...' ? '' : text.replace(/\s+/g, ' ').trim();
            return makeSubstitutions(text);
        }

        function formatEmptyStr(str) {
            return str ? (str.trim() === '' ? emptySectionText : str) : emptySectionText;
        }

        function formatEmptyElm(elm) {
            return formatEmptyStr(getElementText(elm));
        }

        function getCommissionFullName(id) {
            return commissionTypes.find(function (c) { return c.id === id }).name;
        }

        function getLocationInfo(orgAddress) {
            var text = '';
            if (orgAddress.city && orgAddress.city !== '') text += orgAddress.city + ", ";

            if (orgAddress.countryCode === 'US') {
                if (orgAddress.stateCode && orgAddress.stateCode !== '') text += orgAddress.stateCode + ", ";
            } else {
                if (orgAddress.province && orgAddress.province !== '') text += orgAddress.province + ", ";
            }
            text += orgAddress.countryName.trim();
            return text;
        }
        // new functions

        function getInstitutionHeaderContent() {

            return {
                pageBreak: '',
                table: {
                    body: [
                        [
                            {
                                text: [
                                    {
                                        pageBreak: 'before',
                                        text: NPC_DATA.organizationDetailDto.organizationName.toUpperCase() + '\n',
                                        style: ['campton', 'larger'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 0, 0, 6]
                            }
                        ],
                        [
                            {
                                text: [
                                    {
                                        text: 'ABET ' + getCommissionFullName(NPC_DATA.npc.npcProgramDtos[0].programDetailDto.commissionId).toUpperCase() + '\n',
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, false],
                                margin: [0, 6, 0, 0]
                            }
                        ],
                        [
                            {
                                text: [
                                    {
                                        text: 'Program Change Report\n'.toUpperCase(),
                                        style: ['camptonSemiBold', 'medium'],
                                        alignment: 'left'
                                    },
                                    getChangeArray()
                                ],
                                border: [false, false, false, false],
                                margin: [0, 0, 0, 30]
                            }
                        ]
                    ]
                },
                layout: tableLayout
            }

            function getChangeArray() {
                var changes = [];

                NPC_DATA.npc.npcProgramDtos[0].npcProgramChangeDtos[0].changeJson.forEach(function (change, i) {
                    var lineBreaks = i == NPC_DATA.npc.npcProgramDtos[0].npcProgramChangeDtos[0].changeJson.length ? '' : '\n';
                    changes.push({
                        text: '{0}{1}'.format(change.npcTypeName, lineBreaks),
                        style: ['camptonLight', 'small'],
                        alignment: 'left'
                    });
                });

                return changes;
            }
        }


        function getProgramHeaderContent(programDetailDto, programReview,isSingle) {
            return {
                pageBreak: (isSingle ? '' : 'before'),
                table: {
                    body: [
                        [
                            {
                                text: [
                                    {
                                        pageBreak: 'before',
                                        text: programDetailDto.programName + '\n',
                                        style: ['campton', 'larger'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: '{0} Program\n'.format(programDetailDto.degreeCode),
                                        style: ['camptonSemiBold', 'medium'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 0, 0, 6]
                            }
                        ],
                        [
                            {
                                text: [
                                    {
                                        text: 'Program Criteria for ',
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: '{0}\n\n\n'.format(programReviewSvc.concatCriteriaAreas(programReview)),
                                        style: ['camptonLight', 'small', 'italics'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, false],
                                margin: [0, 6, 0, 0]
                            }
                        ]
                    ]
                },
                layout: tableLayout
            }
        }

        function getInstructions() {
            return {
                pageBreak: '',
                layout: {
                    hLineWidth: function (i, node) {
                        return 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    paddingLeft: function (i, node) { return 15; },
                    paddingRight: function (i, node) { return 15; },
                    paddingTop: function (i, node) { return 15; },
                    paddingBottom: function (i, node) { return 0; },
                    fillColor: function (i, node) { return '#eeeeee'; }
                },
                margin: [0, 0, 0, 350],
                table: {
                    body: [
                        [
                            {
                                ul: getInstructionList()
                            }
                        ]
                    ]
                }
            }

            function getInstructionList() {
                var list = []
                var currentInstructions = NPC_DATA.npc.npcProgramDtos[0].npcProgramChangeDtos[0].npcType.toLowerCase() == 'termination' ?
                                            instructions.termination :
                                            instructions.general;

                currentInstructions.forEach(function (instruction, i) {
                    list.push({
                        text: [
                            getBodyText('{0}\n\n'.format(instruction))
                        ]
                    })
                });
                return list;
            }
        }

        function getH2(text) {
            return {
                text: text.toUpperCase(),
                style: ['medium', 'orange', 'camptonSemiBold', 'addLineSpacing']
            };
        }

        function getH3(text) {
            return {
                text: text,
                style: ['small', 'camptonMedium', 'addLineSpacing']
            }
        }

        function getBodyText(text) {
            return {
                text: text,
                style: ['small', 'body', 'paragraph']
            }
        }

        function getReportElements(reportElements) {
            var details = [];
            //var reportElements = NPC_DATA.npcProgramChangeReportDto.reportJson;

            //exclude Recommended Action
            reportElements.filter(t => t.npcSectionTypeId != 5).forEach(function (currentElem, i) {
                details.push({
                    text: [
                        getH2('{0}\n'.format(currentElem.npcSectionTypeName)),
                        getFormattedText(currentElem.text)
                    ]
                });
            });

            return details;

            function getFormattedText(text) {
                var htmlElements = htmlToElementArray(text)
                var reportElements = [];

                htmlElements.forEach(function (elm, index) {
                    elm.childNodes.forEach(function (node) {
                        reportElements.push(getBodyText('{0}\n\n'.format(getElementText(node))));
                    });
                });

                if (reportElements.length < 1) {
                    reportElements.push(EMPTY_SECTION.BLANK);
                }
                reportElements.push('\n');

                return reportElements;
            }
        }

        function getSummaryOfAction(NPC_DATA,programsSelected, isFV) {
            var ORG_NAME = NPC_DATA.organizationDetailDto.organizationName;
            var COMMISSION_NAME = getCommissionFullName(NPC_DATA.npc.npcProgramDtos[0].programDetailDto.commissionId).toUpperCase();
            var ORG_LOCATION = getLocationInfo(NPC_DATA.organizationAddressDto);
            var npcPrograms = NPC_DATA.npc.npcProgramDtos || [];

            var content = [
                {
                    image: 'ABET_CMYK.png',
                    width: 50,
                    alignment: 'center'
                },
                {
                    text: '\n\n{0}\n\n\n'.format(COMMISSION_NAME),
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                },
                {
                    text: 'Summary of Accreditation Actions\n',
                    style: ['medium', 'camptonSemiBold'],
                    alignment: 'center'
                },
                {
                    text: '\n' + ORG_NAME + '\n',
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                },
                {
                    text: '{0} \n\n\n\n'.format(ORG_LOCATION),
                    style: ['small', 'camptonLight'],
                    alignment: 'center'
                }
            ];

            //var all Actions = [];
            var npcActions = [];

            npcPrograms.forEach(function (npcPgm) {
                if (npcPgm.npcProgramChangeDtos.some(t => t.excomDecisionJson == null) || (isFV && npcPgm.npcProgramChangeDtos.some(t => t.excomFVDecisionJson == null)))
                    return;

                if (programsSelected && programsSelected.map(t => t.npcProgramId).indexOf(npcPgm.npcProgramId) < 0)
                    return;

                npcActions.push({
                    text: npcPgm.programDetailDto.programName + '(' + npcPgm.programDetailDto.degreeCode + ')',
                    style: ['small', 'camptonSemiBold']
                });

                var npcsWithContinueAction = isFV ?
                        (npcPgm.npcProgramChangeDtos.filter(t => t.excomFVDecisionJson &&
                                (t.excomFVDecisionJson.excomFinalActionId == npcFinalActionTypeIds.CONTINUEACCREDITATION ||
                                t.excomFVDecisionJson.excomFinalActionId == npcFinalActionTypeIds.TERMINATIONREQUIREMENTSMET))) :
                        (npcPgm.npcProgramChangeDtos.filter(t => t.excomDecisionJson &&
                                (t.excomDecisionJson.excomFinalActionId == npcFinalActionTypeIds.CONTINUEACCREDITATION ||
                                t.excomDecisionJson.excomFinalActionId == npcFinalActionTypeIds.TERMINATIONREQUIREMENTSMET)))
                ;

                //all npcs with decision of continue action
                npcsWithContinueAction.forEach(function (npcChange) {
                    if (npcChange.npcType == 'Termination') {
                        var formattedEndDate = isFV ? helperSvc.formatDateByFilter(npcChange.excomFVDecisionJson.terminationDate, true) : helperSvc.formatDateByFilter(npcChange.excomDecisionJson.terminationDate, true);
                        var termText = actionTexts.termination.replace('{{AccreditationEndDate}}',formattedEndDate);

                        npcActions.push({ text: '\n{0}\n\n'.format(termText), style: ['small', 'camptonLight'] });
                    }
                    else {

                        if (npcChange.changeJson.some(t => t.npcChangeTypeId == npcChangeTypeIds.name))
                            npcActions.push({ text: '\n{0}\n\n'.format(actionTexts.nameChange), style: ['small', 'camptonLight'] });
                        else
                            npcActions.push({ text: '\n{0}\n\n'.format(actionTexts.otherChanges), style: ['small', 'camptonLight'] });
                    }
                });
                if (!isFV) {
                    var focusedVisitAction = npcPgm.npcProgramChangeDtos.filter(t => t.excomDecisionJson &&
                        t.excomDecisionJson.excomFinalActionId == npcFinalActionTypeIds.CONDUCTAFOCUSEDVISIT);

                    if (focusedVisitAction && focusedVisitAction.length > 0)
                        npcActions.push({ text: '\n{0}\n\n'.format(actionTexts.focusedVisit), style: ['small', 'camptonLight'] });
                }

            });

            content = content.concat(npcActions);
            content.push({
                text: '',
                pageBreak: 'after'
            })

            return content;
        }

        factory.generateDoc = function (currentNPCData, isPreview, isFinalAction, isFV, npcProgramChangeReportId, programsSelected) {
            NPC_DATA = currentNPCData;

            var docTitle;
            var content = [];
            var isNameLong = NPC_DATA.organizationDetailDto.organizationName.length > 50;
            var watermarkText;

            docTitle = 'NPC Report Preview';
            watermarkText = isPreview ? '   NPC REPORT PREVIEW   ' : '';

            var instructionPage = [
                getInstitutionHeaderContent(),
                getH2('{0} Instructions'.format(NPC_DATA.npc.npcProgramDtos[0].npcProgramChangeDtos[0].npcType)),
                getInstructions()
            ];

            if (isFinalAction) {
                content = content.concat(getSummaryOfAction(NPC_DATA,programsSelected,isFV));
            }

            //to get current info
            content = content.concat(instructionPage);

            let pgmCnt = 0;
            NPC_DATA.npc.npcProgramDtos.forEach(function (npcPgm) {
                ++pgmCnt;

                if (programsSelected && programsSelected.map(t => t.npcProgramId).indexOf(npcPgm.npcProgramId) < 0)
                    return;

                npcPgm.npcProgramChangeDtos.forEach(function (npcProgramChange) {
                    let previewReport = null;
                    
                    if (npcProgramChange.selected) {
                        if (isFinalAction) {
                            if (isFV)
                                previewReport = npcProgramChange.npcProgramChangeReportDtos.find(x => x.teamMemberTypeId === teamMemberTypeNames.NPCFVADJ);
                            else
                                previewReport = npcProgramChange.npcProgramChangeReportDtos.find(x => x.teamMemberTypeId === teamMemberTypeNames.ADJUNCT);
                        }
                        else {
                            previewReport = npcProgramChange.npcProgramChangeReportDtos.find(x => x.npcProgramChangeReportId === npcProgramChangeReportId);
                        }
                    }
                    if (previewReport) {
                        var reportElements = [
                            getProgramHeaderContent(npcPgm.programDetailDto, npcPgm.programReview,pgmCnt === 1 ? true : false  ),
                            getReportElements(previewReport.reportJson)
                        ];
                        content = content.concat(reportElements);
                    }
                    
                })
            });

            
            return getDocDefinition(docTitle, content, watermarkText);
        };

        function getDocDefinition(docTitle, content, watermarkText) {
            var docDefintion = {
                info: {
                    title: docTitle
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 70, 70, 0],
                watermark: {
                    text: watermarkText || '',
                    opacity: 0.06,
                    font: 'calibri'
                },
                footer: function (currentPage, pageCount) {
                    if (currentPage === 1) {
                        return {};
                    } else {
                        return {
                            columns: [
                                {
                                    text: [
                                        'PAGE ',
                                        { text: currentPage.toString(), style: 'campton' },
                                        ' OF ',
                                        { text: pageCount, style: 'campton' }
                                    ],
                                    alignment: 'left',
                                    style: ['smaller', 'camptonLight'],
                                    width: '*'
                                },
                                {
                                    text: [
                                        { text: 'NPC ', style: 'camptonLight' },
                                        { text: NPC_DATA.organizationDetailDto.organizationName.toUpperCase(), style: 'campton' }
                                    ],
                                    alignment: 'center',
                                    style: 'smaller',
                                    width: 260
                                },
                                {
                                    text: '{0} {1} EST'.format(new Date().toLocaleDateString('en-US', { year:'2-digit', month: 'numeric', day: 'numeric'}), new Date().toLocaleTimeString()),
                                    alignment: 'right',
                                    style: ['smaller', 'camptonLight'],
                                    width: '*'
                                }
                            ],
                            margin: [70, 44, 70, 0]
                        };
                    }
                }
            };

            return docDefintion;
        };

        return {
            generateDoc: factory.generateDoc
        };
    };

    module.factory('npcPDFSvc', npcPDFSvc);

})(angular.module('npc'));