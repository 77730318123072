(function (module) {

    var rfrDetailCtrl = function ($scope, $q, $state, $stateParams, currentOrganization, currentOrganizationHelper, oauth, helperSvc, tabSvc, alertSvc, barSvc, rfrSvc, rfrValidationSvc, organizationSvc, contactRoleTypes) {
        var model = this;
        var rfr;

        model.parent = $scope.$parent.model;
        model.rfrId = parseInt($stateParams.rfrId);
        model.showSpinner = false;
        model.isEmpty = helperSvc.isEmpty;
        model.openMasterInstructions = rfrSvc.openMasterInstructions;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');

        var path = 'apps/rfr/templates/';

        model.navigation = [
            tabSvc.createTab('Review Options', rfrSvc.slugs.REVIEW, path + 'rfrReviewOptionModule.html'),
            tabSvc.createTab('Program Information', rfrSvc.slugs.PROGRAMS, path + 'rfrProgramInfoModule.html'),
            tabSvc.createTab('Manage Contacts', rfrSvc.slugs.CONTACTS, path + 'rfrManageContactsModule.html')
        ];

        if (model.isAdmin) {
            model.navigation.splice(0, 0, tabSvc.createTab('Accept', rfrSvc.slugs.ACCEPT, path + 'rfrAcceptModule.html'),
                                          tabSvc.createTab('Summary', rfrSvc.slugs.SUMMARY, path + 'rfrSummaryModule.html'));
        } else {
            var organizationId = $stateParams.organizationId;
            var isPresidentTabLocked = !oauth.hasRole(organizationId, contactRoleTypes.PRESIDENT) && !oauth.hasRole(organizationId, contactRoleTypes.DELEGATEFORPRESIDENT);

            model.navigation.push(tabSvc.createTab('Preview & Submit', rfrSvc.slugs.PREVIEW, path + 'rfrPreviewModule.html'),
                                  tabSvc.createTab('Sign by President', rfrSvc.slugs.SIGN, path + 'rfrSubmitModule.html', isPresidentTabLocked));

            // function duplicated in rfrReviewOptionModuleCtrl
            model.rfrHasCommissionDtos = function (rfr) {
                return rfr && rfr.rfrCommissionDtos;
            };
        }

        var validationCallback = function (){
           rfrValidationSvc.populateWarnings(model.navigation);
        };
        
        rfrValidationSvc.listenToValidate(validationCallback, $scope);

        var activate = function () {
            model.isDataReady = false;
            barSvc.showBar();

            //need this for membershipSvc call in the future
            if(!model.isAdmin){
                currentOrganizationHelper.loadDelegatedCommissions($stateParams.organizationId).then(function () {
                    currentOrganization.save();
                });
            }

            var rfrSubmissionDataSource = {
                dataHolder: model,
                dataLocationName: 'data',
                svcCallback: rfrSvc.getRfrSubmissionAndCommissions,
                svcCallbackArguments: [model.rfrId],
                optionalCallback: function (mdl, svc) {
                    mdl.rfrSubmission = svc.data.rfr;
                },
                optionalCallbackArguments: [model, rfrSvc]
            };

            var dataSourceArray = [rfrSubmissionDataSource];

            helperSvc.getData(dataSourceArray).then(function () {
                organizationSvc.getOrganizationById(parseInt($stateParams.organizationId)).then(function (data) {
                    var organizationName = data.currentOrganizationDetailDto.organizationName;
                    currentOrganization.profile.organizationName = organizationName;
                    model.title = organizationName;
                    model.yearRange = (rfrSvc.data.rfr.reviewYear - 1) + '–' + rfrSvc.data.rfr.reviewYear.toString().slice(2);
                    validationCallback.apply();
                    barSvc.hideBar();
                    model.isDataReady = true;
                });
            });
        }();
    };

    module.controller('rfrDetailCtrl', rfrDetailCtrl);

}(angular.module('rfr')));