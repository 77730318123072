(function (module) {

    var tokenSvc = function () {

        var factory = {};

        factory.oauthToken = null;

        var set = function (token) {
            factory.oauthToken = token;
        };

        var get = function (token) {
            factory.oauthToken = token;
        };

        var clear = function (key) {
            factory.oauthToken = null;
        };

        var getUser = function () {
            var authObj = parseJwt(factory.oauthToken);
        }

        factory.parseJwt = function(token){
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        };

        return {
            //oauthToken: factory.authToken, 
            //get: factory.get,
            //set: factory.set,
            //clear: factory.clear,
            //getUser: factory.getUser,
            parseJwt: factory.parseJwt
        };
    };

    module.factory("tokenSvc", tokenSvc);

}(angular.module("security")));