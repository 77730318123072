(function (module) {

    var programLinksCtrl = function ($scope, $uibModal, helperSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Program Information Links';

        model.editProgramLinks = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/programLinksEdit.html',
                size: 'lg',
                controller: 'programLinksEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.formatUrl = helperSvc.formatUrl;

        model.isEmpty = helperSvc.isEmpty;
    };

    module.controller('programLinksCtrl', programLinksCtrl);

}(angular.module('program')));