(function (module) {

    var changeTrainingStatusCtrl = function ($scope, $uibModalInstance, searchOptions, alertSvc, trainingSvc, pevc, trainingStatusIds, isObserver) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Change PEVC Training Status';
        model.pevc = pevc;
        model.data = { options: null, changedStatus: null }
        model.isObserver = isObserver;

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.data.changedStatus === null)
                return alertSvc.addAlertWarning("You must select a status or cancel.")

            
            var changeText = "You are about to change this pevc's status. This is irreversible.";

            if (model.isObserver)
                changeText = "You are about to change this observer's status. This is irreversible.";
            alertSvc.confirm(changeText, confirm);

            function confirm() {
                model.pevc.trainingStatusId = parseInt(model.data.changedStatus);

                if (model.isObserver) {
                    trainingSvc.updateTrainingSession(model.pevc).then(function () {
                        alertSvc.addAlertSuccess("Observer status successfully changed.");
                        trainingSvc.getTrainingObservers(searchOptions);
                        $uibModalInstance.close();
                    });
                } else {
                    trainingSvc.updateTrainingSession(model.pevc).then(function () {
                        trainingSvc.getPEVCTrainingSearch(searchOptions);
                        alertSvc.addAlertSuccess("Pev status successfully changed.")
                        $uibModalInstance.close();
                    });
                }
                
                               
            }
        }

        var activate = function () {
            model.data.options = [{ statusId: trainingStatusIds.CANCELLED, statusName: 'Cancelled' }, { statusId: trainingStatusIds.DEFERRED, statusName:'Deferred'}]
        }();
    };

    module.controller('changeTrainingStatusCtrl', changeTrainingStatusCtrl);

}(angular.module('training')));