(function (module) {

    var rfrAndReviewsCtrl = function ($stateParams, $location, reviewTeamSvc, helperSvc, $scope) {
        var model = this;
        var parent = $scope.$parent.model;

        model.organizationId = $stateParams.organizationId;
        model.title = 'Current RFR/Reviews';
        model.noCurrentReviewsMsg = 'No ongoing reviews';
        
        model.openReviewPage = function () {
            $location.path('/admin/review/' + model.organizationId);
            //$window.location.href = '/#/admin/review/:' + model.organizationId;
        };

        model.isEmpty = helperSvc.isEmpty;
        model.getYearSpan = helperSvc.getYearSpan;

        activate();
        function activate() {
            reviewTeamSvc.searchCurrentReviewTeamsByOrganizationId(model.organizationId).then(function (data) {
                model.reviews = helperSvc.getResults(data);
                parent.incDataCount(); //notify parent that data load is complete
            });
        };
    };

    module.controller('rfrAndReviewsCtrl', rfrAndReviewsCtrl);

}(angular.module('organization')));