(function (module) {

    var createAccountConfirmCtrl = function (parent, $scope, $uibModalInstance) {
        var model = $scope;

        model.parent = parent;
        model.title = parent.hasDuplicateEmails ? 'Duplicate Emails' : 'Duplicate Name';

        model.reset = function () {
            parent.reset();
            $uibModalInstance.close();
        };

        model.goToLogin = function () {
            model.reset();
            parent.back();
        };

        model.createNewAccount = function () {
            parent.createNewAccount(true);
            $uibModalInstance.close();
        }
    };

    module.controller('createAccountConfirmCtrl', createAccountConfirmCtrl);

}(angular.module('pevApp')));