(function (module) {

    var npcAddNotificationModalCtrl = function (organization, programs, npc, change, onSave, $scope, $q, $uibModalInstance, helperSvc, codeSvc, programReviewSvc, npcChangeTypeIds,
        npcSvc, alertSvc, $q, documentSvc, npcDocumentTypes, readinessSvc, npcProgramChangeReportSvc) {

        const programChange = change && npcSvc.findProgramChange(npc, change) || null;
        const program = programChange && npcSvc.findProgram(npc, programChange) || null;
        const onSaveHandler = onSave || (() => $q.when(true));
        const npcData = Array.isArray(npc) ? npc : (npc ? [npc] : []);
        npc = Array.isArray(npc) ? null : npc; 

        var model = $scope;

        model.isEdit = !!(npc && program && programChange && change);
        model.title = `${model.isEdit ? 'Edit' : 'Add'} Program Change`;
        model.isSaving = false;
        model.npcChangeTypeIds = npcChangeTypeIds;
        model.isOutsideUS = organization?.currentOrganizationAddressDto?.countryCode !== 'US';
        model.npcDocumentTypes = npcDocumentTypes;
        model.accept = readinessSvc.SELFSTUDYACCEPTEDMIMETYPES;
        model.pattern = "'" + model.accept + "'";

        model.onProgramChange = function () {
            if (model.isEdit) return;

            checkDuplicateProgramChanges();
        }

        model.onChangeTypeChange = function () {
            if (model.isEdit) return;

            const typeCode = model.npcChangeTypes.find(changeType => changeType.codeKey === model.currentNotification?.type);
            model.currentNotification.change = {
                npcChangeTypeId: typeCode?.codeKey || null,
                npcTypeName: typeCode?.codeName || null,
                ...(typeCode?.codeKey === npcChangeTypeIds.CAMPUS && { campuses: [] }),
            }

            checkDuplicateProgramChanges();
        };

        function checkDuplicateProgramChanges() {
            model.isDuplicated = model.currentNotification.program && model.currentNotification.type &&
                npcData.some(npc =>
                    !npc.submittedDate &&
                    npc.npcProgramDtos.some(program =>
                        program.programId === model.currentNotification.program.programId &&
                        program.npcProgramChangeDtos.some(programChange =>
                            programChange.changeJson.some(change =>
                                change.npcChangeTypeId === model.currentNotification.type)
                        )
                    )
                );
        }

        model.onDistanceLearningChange = function () {
            const distanceLearningCode = model.distanceLearningTypeOptions.find(distanceLearningType => distanceLearningType.codeKey === model.currentNotification?.change?.distanceLearningId);
            model.currentNotification.change.distanceLearningName = distanceLearningCode?.codeName || null;
        };

        model.openPDFModal = function (streamId, title) {
            var isInline = true;
            alertSvc.openPDFModal(documentSvc.getFileUrl(streamId, isInline), title);
        };

        model.downloadDocument = function (document) {
            documentSvc.downloadDocument(document.stream_id, document.fileName);
        }

        model.addTerminationPlan = function (newFile) {
            if (!newFile) return;

            const newTerminationPlan = {
                fileName: newFile.name,
                file: newFile
            };

            if (!model.currentNotification.terminationPlans.length || model.multipleTerminations) {
                model.currentNotification.terminationPlans.push(newTerminationPlan);
            } else {
                model.currentNotification.terminationPlans.splice(0, 1, newTerminationPlan);
            }

            model.isTouched = true;
        }

        model.deleteTerminationPlan = function (oldTerminationPlan) {
            const index = model.currentNotification.terminationPlans.indexOf(oldTerminationPlan); 
            model.currentNotification.terminationPlans.splice(index, 1);
            model.isTouched = true;
        }

        model.onAccreditationEndDateChange = function (date) {
            model.isAccreditationEndDateInvalid = date && !npcProgramChangeReportSvc.validateTerminationDate(date, model.currentNotification.program);
            if (model.isAccreditationEndDateInvalid)
                model.errors = ['Accreditation end date must be during current accreditation period or within three years after.'];
            else
                model.errors = [];
        }

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;

            // Create new NPC Program Changes as posts to /Npc even if NPC for commission exists
            const doSave = model.isEdit ? updateNpc() : createNpc();

            doSave.then(npc => {
                onSaveHandler().then(() => {
                    alertSvc.addAlertSuccess(`Notification of Program Change ${model.isEdit ? 'updated' : 'created'}.`);
                    $uibModalInstance.close(npc);
                });
            }).catch(error => {
                console.log(error);
                model.isSaving = false;
                alertSvc.addAlertWarning('Notification of Program Change could not be created at this time.');
            });
        }

        function convertProgramChangeCampuses(campusesContainer) {
            const data = angular.copy(campusesContainer);
            const changes = data?.changeJson || [data];

            changes.forEach(change => {
                if (!change.campuses) return;

                change.campuses = change.campuses
                    .map(campus => ({
                        addressDto: {
                            addressId: 0,
                            isDeleted: false,
                            countryCode: campus.countryCode,
                            ...(campus.address1 && { address1: campus.address1 }),
                            address2: campus.address2,
                            ...(campus.address3 && { address3: campus.address3 }),
                            ...(campus.city && { city: campus.city }),
                            ...(campus.province && { province: campus.province }),
                            ...(campus.stateCode && { stateCode: campus.stateCode }),
                            ...(campus.postalCode && { postalCode: campus.postalCode }),
                        },
                        addressId: 0,
                        isDeleted: false,
                        changeId: null,
                        isMainCampus: campus.isMainCampus,
                        programReviewCampusId: 0,
                        isNew: false,
                        isOutsideUS: campus.countryCode !== 'US',
                        campusName: campus.campusName,
                        ...(campus.distanceMiles && { distanceMiles: Math.round(Number(campus.distanceMiles)) }),
                        ...(campus.note && { note: campus.note })
                    }));
            });

            return data;
        }

        function convertProgramReviewCampuses(campusesContainer) {
            const data = angular.copy(campusesContainer);
            const changes = data?.changeJson || [data];

            changes.forEach(change => {
                if (!change.campuses) return;

                change.campuses = change.campuses
                    .filter(campus => !campus.isDeleted)
                    .map(campus => ({
                        countryCode: campus.addressDto.countryCode,
                        ...(campus.addressDto.address1 && { address1: campus.addressDto.address1 }),
                        address2: campus.addressDto.address2,
                        ...(campus.addressDto.address3 && { address3: campus.addressDto.address3 }),
                        ...(campus.addressDto.city && { city: campus.addressDto.city }),
                        ...(campus.addressDto.province && { province: campus.addressDto.province }),
                        ...(campus.addressDto.stateCode && { stateCode: campus.addressDto.stateCode }),
                        ...(campus.addressDto.postalCode && { postalCode: campus.addressDto.postalCode }),
                        isMainCampus: campus.isMainCampus,
                        campusName: campus.campusName,
                        ...(campus.distanceMiles && { distanceMiles: campus.distanceMiles }),
                        ...(campus.note && { note: campus.note })
                    }));
            });

            return data;
        }

        function updateNpc() {
            const programChange = convertProgramReviewCampuses(model.currentNotification.programChange);

            return npcSvc.updateNpcProgramChange(programChange).then(() => {
                if (model.currentNotification.change.npcChangeTypeId === npcChangeTypeIds.TERMINATION) {
                    return saveTerminationPlans(npc);
                }
            });
        }

        function createNpc() {
            const change = convertProgramReviewCampuses(model.currentNotification.change);

            const newNpc = {
                organizationId: organization.organizationId,
                npcProgramDtos: [{
                        programId: model.currentNotification.program.programId,
                        npcProgramChangeDtos: [{
                            npcType: model.currentNotification.change.npcChangeTypeId === npcChangeTypeIds.TERMINATION ? "Termination" : "Change",
                            changeJson: [change]
                        }]
                }]
            };

            return npcSvc.create(newNpc).then(savedNpc => {
                if (model.currentNotification.change.npcChangeTypeId === npcChangeTypeIds.TERMINATION) {
                    return saveTerminationPlans(savedNpc).then(() =>
                        savedNpc
                    );
                } else {
                    return savedNpc;
                }
            });
        }

        function saveTerminationPlans(npc) {
            const terminationProgramChange = npc.npcProgramDtos
                .find(npcProgramDto => npcProgramDto.programId === model.currentNotification.program.programId)
                .npcProgramChangeDtos
                .find(npcProgramChangeDto => npcProgramChangeDto.npcType === 'Termination');

            const uploadNewTerminationPlans = model.currentNotification.terminationPlans
                .filter(document => document.file)
                .map(document => {
                    const npcDocument = {
                        referenceId: terminationProgramChange.npcProgramChangeId,
                        documentTypeId: npcDocumentTypes.TERMINATIONPLAN,
                        documentTitle: terminationProgramChange.npcType,
                        file: document.file
                    };

                    return documentSvc.uploadDocument(npcDocument);
                });

            const deleteOldTerminationPlans = programChange?.npcProgramChangeDocumentDtos.filter(document =>
                document.documentTypeId === npcDocumentTypes.TERMINATIONPLAN &&
                !model.currentNotification.terminationPlans.some(currentDocument => currentDocument.stream_id === document.stream_id)
            ).map(document => {
                return documentSvc.deleteDocument(document.stream_id, npcDocumentTypes.TERMINATIONPLAN);
            }) || [];

            const promises = [...uploadNewTerminationPlans, ...deleteOldTerminationPlans];

            return $q.all(promises);
        }

        function loadData() {
            const loadNpcChangeTypes = codeSvc.getNpcChangeTypes().then(data =>
                helperSvc.getResults(data) || []
            );

            const loadDistanceLearningTypes = codeSvc.getDistanceLearningTypes().then(data =>
                helperSvc.getResults(data) || []
            );

            const loadProgramData = $q.when(programs ||npcSvc.getEligiblePrograms(organization.organizationId, npc?.commissionId, program?.programId)).then(data => {
                const programs = angular.copy(data);

                return $q.all(
                    programs.map((currentProgram, index, allPrograms) => 
                        programReviewSvc.getReviewsByProgramId(currentProgram.programId, true).then(data => {
                            const latestProgramReview = data?.value?.length ? helperSvc.getResults(data)[0] : {};

                            currentProgram.programFullName = getProgramName(currentProgram, allPrograms);
                            currentProgram.programCampusTypeId = latestProgramReview?.programCampusTypeId;
                            currentProgram.programCampusTypeName = latestProgramReview?.programCampusTypeName;
                            currentProgram.distanceLearningId = latestProgramReview?.distanceLearningId;
                            currentProgram.distanceLearningTypeName = latestProgramReview?.distanceLearningTypeName;
                            currentProgram.disciplines = latestProgramReview?.programReviewDisciplineDtos && latestProgramReview.programReviewDisciplineDtos
                                .map(programReviewDisciplineDto => programReviewDisciplineDto.disciplineName)
                                .sort()
                                .join(', ');

                            return currentProgram;
                        })
                    )
                );

                function getProgramName(currentProgram, allPrograms) {
                    const isJointReviewed = allPrograms.some(program =>
                        program.programName === currentProgram.programName &&
                        program.degreeCode === currentProgram.degreeCode &&
                        program.commissionId !== currentProgram.commissionId
                    );

                    return `${currentProgram.programName} (${currentProgram.degreeCode})${isJointReviewed ? ` [${currentProgram.commissionName}]` : ''}`;
                }
            });

            const promises = [loadNpcChangeTypes, loadDistanceLearningTypes, loadProgramData];

            return $q.all(promises).then(data => {
                [npcChangeTypes, distanceLearningTypeOptions, programs] = data;

                return {
                    npcChangeTypes,
                    distanceLearningTypeOptions,
                    programs
                };
            });
        }

        function initialize() {
            if (model.isEdit) {
                model.currentNotification = {
                    program: model.programs.find(currentProgram => currentProgram.programId === program.programId),
                    type: programChange.npcChangeTypeId,
                    programChange: convertProgramChangeCampuses(programChange)
                };

                model.currentNotification.change = model.currentNotification.programChange.changeJson[programChange.changeJson.indexOf(change)];
                model.currentNotification.type = model.currentNotification.change.npcChangeTypeId;

                if (model.currentNotification.change.npcChangeTypeId === npcChangeTypeIds.TERMINATION) {
                    model.currentNotification.terminationPlans = programChange.npcProgramChangeDocumentDtos.filter(document => document.documentTypeId === npcDocumentTypes.TERMINATIONPLAN);
                    model.multipleTerminations = programChange.changeJson.length > 1 || model.currentNotification.terminationPlans.length > 1;
                }
            } else {
                model.currentNotification = {
                    program: model.programs.length === 1 ? model.programs[0] : null,
                    type: null,
                    programChange: null,
                    change: {
                        npcChangeTypeId: null,
                        npcTypeName: null
                    },
                    terminationPlans: []
                };
            }
        }

        function activate() {
            loadData().then(data => {
                Object.assign(model, data);
                initialize();
                model.isDataReady = true;
            });
        }

        activate();
    };

    module.controller('npcAddNotificationModalCtrl', npcAddNotificationModalCtrl);

}(angular.module('npc')));