(function (module) {

    var billingContactsCtrl = function ($scope, $uibModal, contactRoleTypes) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Billing Contacts';
        model.type = 'billing';
        model.helpText = 'The billing contact is the individual at the institution to whom ABET should send all invoices for accreditation reviews and maintenance fees.<br/><br/><strong>A billing contact is required for each accrediting Commission</strong>';

        model.editBillingContacts = function (isCreateMode, isReplaceMode, currentContact, editView, type) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/contacts/templates/contactsEdit.html',
                size: 'lg',
                controller: 'contactsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    contacts: function () {
                        return model.parent.billingContacts;
                    },
                    roleId: function () {
                        return contactRoleTypes.BILLING;
                    },
                    isCreateMode: function () {
                        return isCreateMode;
                    },
                    isReplaceMode: function () {
                        return isReplaceMode;
                    },
                    currentContact: function () {
                        return currentContact;
                    },
                    editView: function () {
                        return editView;
                    },
                    type: function () {
                        return model.type;
                    }
                }
            });
        };
    };

    module.controller('billingContactsCtrl', billingContactsCtrl);

}(angular.module('contacts')));