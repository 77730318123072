(function (module) {

    var dueResponseSvc = function (odataSvc, helperSvc, dueResponseTypeIds, documentSvc, localStorage, offlineSvc) {
        var factory = {};
        var apiPath = '/DueResponse';
        var key = "dueResponseId";
        var DUERESPONSE_KEY_PREFIX = 'dueResponseData:';

        factory.data = {
            dueResponses: [],
            sevenDayResponse: null,
            thirtyDayResponse: null,
            thirtyDayPostResponse: null
        }

        factory.getDueResponsesByReviewTeamId = function (reviewTeamId, noStoredData) {
            var oSvc = odataSvc.get();
            var getApiPath = oSvc.getPathWithParameter('/GetDueResponseByReviewTeamId', 'reviewTeamId', reviewTeamId);
            oSvc.getSource(getApiPath).odata()
                //.expand('dueResponseDocumentDtos')
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc);
            }

            return oSvc.getDeferred().promise;
        };

        factory.create = function (dueResponseDto) {
            var oSvc = odataSvc.get();
            dueResponseDto = cleanResponse(dueResponseDto);
            var resource = oSvc.instantiate(apiPath, key, dueResponseDto);

            // Trigger update of other tabs
            localStorage.add(factory.getDueResponseKey(dueResponseDto.reviewTeamId), dueResponseDto);

            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                localStorage.remove(factory.getDueResponseKey(dueResponseDto.reviewTeamId));
                return factory.getDueResponsesByReviewTeamId(dueResponseDto.reviewTeamId);
            })

            return promise;
        };

        factory.update = function (dueResponseDto) {
            var oSvc = odataSvc.get();
            dueResponseDto = cleanResponse(dueResponseDto);

            // Trigger update of other tabs
            localStorage.add(factory.getDueResponseKey(dueResponseDto.reviewTeamId), dueResponseDto);

            var resource = oSvc.instantiate(apiPath, key, dueResponseDto);
            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                localStorage.remove(factory.getDueResponseKey(dueResponseDto.reviewTeamId));
                return factory.getDueResponsesByReviewTeamId(dueResponseDto.reviewTeamId);
            })

            return promise;
        }

        factory.delete = function (dueResponseDto) {
            var model = { dueResponseId: dueResponseDto.dueResponseId };
            dueResponseDto = cleanResponse(dueResponseDto);

            // Trigger update of other tabs
            localStorage.add(factory.getDueResponseKey(dueResponseDto.reviewTeamId), dueResponseDto);

            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
                localStorage.remove(factory.getDueResponseKey(dueResponseDto.reviewTeamId));
                return factory.getDueResponsesByReviewTeamId(dueResponseDto.reviewTeamId);
            });

            return promise;
        }

        factory.getDueResponseKey = function (reviewTeamId) {
            return DUERESPONSE_KEY_PREFIX + reviewTeamId;
        }

        function cleanResponse(response) {
            if (response.hasOwnProperty('responseFileName')) {
                delete response.responseFileName;
            }
            if (response.hasOwnProperty('responseFile')) {
                delete response.responseFile;
            }
            return response;
        }

        function setFileName(response) {
            // Skipping to save time when downloading offline data; filename not needed for statement editor in offline mode
            if (response && response.stream_id && !offlineSvc.isAppOffline()) {
                documentSvc.getDocumentNameByStreamId(response.stream_id).then(function (data) {
                    response.responseFileName = data.value;
                });
            }
        }

        function resolveData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.dueResponses = data;
                factory.data.sevenDayResponse = helperSvc.getFirstMatch(factory.data.dueResponses, 'dueResponseTypeId', dueResponseTypeIds.SEVENDAY);
                setFileName(factory.data.sevenDayResponse);
                factory.data.thirtyDayResponse = helperSvc.getFirstMatch(factory.data.dueResponses, 'dueResponseTypeId', dueResponseTypeIds.THIRTYDAY);
                setFileName(factory.data.thirtyDayResponse);
                factory.data.thirtyDayPostResponse = helperSvc.getFirstMatch(factory.data.dueResponses, 'dueResponseTypeId', dueResponseTypeIds.THIRTYDAYPOST);
                setFileName(factory.data.thirtyDayPostResponse);
            });
        }

        return {
            data: factory.data,
            getDueResponsesByReviewTeamId: factory.getDueResponsesByReviewTeamId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            getDueResponseKey: factory.getDueResponseKey
        };
    };

    module.factory('dueResponseSvc', dueResponseSvc);

})(angular.module('statement'));