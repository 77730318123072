(function (module) {

    var ckEditCommentsCtrl = function ($scope, $uibModalInstance, alertSvc, codeSvc, helperSvc, readinessSvc, eventSvc, ckeditCommentsSvc, currentUser, editorLinkData, teamMemberTypeId, reviewTeamSvc) {
        var model = $scope;

        model.title = 'Add a Comment';
        model.comment = {};


        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            ckeditCommentsSvc.addComment(model.comment);
            
            ckeditCommentsSvc.updateDraftFinalStatementComments().then(function () {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Comment successfully saved.");
            });

            
        };

        var activate = function () {
            model.comment = {
                commentText: '',
                commentVolunteerId: currentUser.profile.volunteerId,
                commentVolunteerName: currentUser.profile.firstName + ' ' + currentUser.profile.lastName,
                teamMemberTypeId: teamMemberTypeId,
                commentVolunteerRole: reviewTeamSvc.getTeamMemberTypeAbbrv(teamMemberTypeId),
                commentDate: new Date(Date.now()).toLocaleDateString(),
                editorLinkData: editorLinkData
            };
        }();
    };

    module.controller('ckEditCommentsCtrl', ckEditCommentsCtrl);

}(angular.module('common')));