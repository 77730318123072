(function (module) {

    var teamChairsEditCtrl = function (commissioners, $scope, $uibModalInstance, helperSvc, alertSvc, tcAssignmentSvc, currentUserHelper, contactRoleTypes, membershipSvc) { 
        var model = $scope;

        model.title = 'Edit Team Chairs';
        model.teamChairs = [];
        model.commissionId = currentUserHelper.getViceOpsCommissionId();
        model.societyStatus = "Approved";
        model.currentCommissionersHidden = true;
        model.isAddMode = false;

        activate();

        model.close = function () {
            $uibModalInstance.close();
        };

        model.cancel = function () {
            resetSelectedUser();
            model.isAddMode = false;
        }

        model.isAssigned = function (person) {
            return tcAssignmentSvc.isAssigned(person.personId, person.commissionId);
        };

        model.delete = function (person) {
            alertSvc.confirmDelete(person.firstName + ' ' + person.lastName, deleteFunc, person);

            function deleteFunc() {
                membershipSvc.deleteRoleOnly(model.commissionId, person.personId, contactRoleTypes.COMMISSIONMEMBER).then(function (data) {
                    getCommissioners(false, model.commissionId, model.currentCommissionersHidden);
                    alertSvc.addModalAlertSuccess('Commissioner role successfully removed.');
                });
            }
        };

        model.addNew = function () {
            resetSelectedUser();
            model.isAddMode = true;
        }

        model.save = function (person) {

            if (personRoleDuplicate(person.personId)) {
                alertSvc.openModalAlert("This person has already been added to the list of commissioners available.");
                return;
            }
            //add to the current commissionerlist by giving them a role only role of commissioner 303
            //set the date to august 1 and end date to july31 next year
            var newRole = {
                organizationUserId: 0,
                organizationId: model.commissionId,
                startDate: helperSvc.getCurrentReviewCycleStartDate(),
                endDate: helperSvc.getCurrentReviewCycleEndDate(),
                isRoleOnly: true,
                roleId: contactRoleTypes.COMMISSIONMEMBER,
                societyId: person.societyId,
                userId: person.userId,
                personId: person.personId,
                organizationUserDesignationDtos: [{ organizationUserId: 0, designationId: model.commissionId }],
                isDeleted: false
            };

            membershipSvc.insert(newRole).then(function (data) {
                getCommissioners(false, model.commissionId, model.currentCommissionersHidden);
                model.isAddMode = false;
                alertSvc.addModalAlertSuccess("Commissioner role successfully added.");
            });

        };

        model.updateCurrentCommissionerVisibility = function () {
            model.currentCommissionersHidden = !model.currentCommissionersHidden;
        };

        function resetSelectedUser() {
            model.selectedUser = {};
        };

        function activate() {
            resetSelectedUser()           
            getCommissioners(true, model.commissionId, model.currentCommissionersHidden);
        }

        function getCommissioners(useCache, commissionId, roleOnly) {         
            tcAssignmentSvc.getTCAssignmentCommissioners(useCache, commissionId, roleOnly).then(function (data) {
                model.teamChairs = data;
            });
        }

        function personRoleDuplicate(personId) {
            var found = false;
            for (var i = 0; i < model.teamChairs.length; i++) {
                if (model.teamChairs[i].personId === personId) {
                    found = true;
                    break;
                }
            }
            return found;
        }

    };

    module.controller('teamChairsEditCtrl', teamChairsEditCtrl);

}(angular.module('assignment')));