(function (module) {
    var npcFinalActionTabCtrl = function ($state, $scope, $uibModal, $q, oauth, helperSvc, barSvc, alertSvc, codeSvc, alertSvc, npcSvc, teamMemberTypeNames, eventSvc, npcFinalActionTypeNames, npcProgramChangeReportSvc) {
        var model = this;
        
        model.parent = $scope.$parent.$parent.$parent.model;
        model.npcId = model.parent.npcData.npc ? model.parent.npcData.npc.npcId : model.parent.npcData.npcId;
        model.isDataReady = false;
        model.slug = 'action'; //pevAppSvc.slugs.SUBMIT;
        model.getColorClass = npcSvc.getColorClass;
        model.isAdmin = oauth.isAdmin();
        model.npcFinalActionTypeNames = npcFinalActionTypeNames;
        model.programsSelected = [];
        model.isInstitutionUser = model.parent.isInstitutionUser;
        model.finalAction = null;
        model.checkProgramIsFV = function (npcProgramChange) {
            return npcProgramChange.npcProgramChangeReportDtos.some(t => t.reportType === 'Statement');
        }
        model.toggleProgramSelection = function (npcProgramChange) {
            var id = "npcProgramChange-" + npcProgramChange.npcProgramChangeId;
            var checkBox = document.getElementById(id);

            if (checkBox.checked)
                model.programsSelected.push(npcProgramChange);
            else {

                //var index = helperSvc.getFirstMatch(model.programsSelected, 'npcProgramChangeId', npcProgramChange.npcProgramChangeId)
                var index = model.programsSelected.findIndex(t => t.npcProgramChangeId == npcProgramChange.npcProgramChangeId);
                model.programsSelected.splice(index, 1);
            }
        }
        model.sendFinalAction = function () {
            if (!model.programsSelected || model.programsSelected.length < 1) {
                alertSvc.addAlertWarning("No program change selected.");
                return;
            }

            var allProgramsWithAdjunctReport = model.programsSelected.every(x => x.npcProgramChangeReportDtos.some(x => x.teamMemberTypeId === teamMemberTypeNames.ADJUNCT))

            if (!allProgramsWithAdjunctReport) {
                alertSvc.addAlertWarning("Not all programs have a report submitted by an adjunct.");
                return;
            }

            npcSvc.getNPCById(model.parent.npcData.npcId).then(function (npcObj) {
                var isFV = false;
                npcProgramChangeReportSvc.getByNPCProgramChangeId(model.programsSelected[0].npcProgramChangeId).then(function (rpts) {
                    isFV = rpts.some(t => t.reportType === 'Statement');

                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/npc/templates/modal/npcSendFinalAction.html',
                        size: 'md',
                        controller: 'npcSendFinalActionModalCtrl',
                        resolve: {
                            npc: () => npcObj,
                            npcPdf: () => model.npcData,
                            programsSelected: () => model.programsSelected,
                            isFocusedVisit: () => isFV
                        }
                    });

                    modalInstance.result.then(() => {
                        refreshData();
                    });
                });
            });
        }

        model.viewEmailText = function (latestFinalAction) {

            return alertSvc.information(latestFinalAction.sentEmail, "Email Body")
        }

        model.openFinalStatement = function (st_Id) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/viewInstitutionStatement.html',
                size: 'xl',
                controller: 'viewInstitutionStatementCtrl',
                resolve: {
                    streamId: function () { return st_Id; }
                }
            });

            modalInstance.result.then(() => {
                refreshData();
            });
        };

        model.isAlreadySent = function (change) {
            return change.npcFinalActionProgramChangeDtos && change.npcFinalActionProgramChangeDtos.some(t => t.npcProgramChangeId === change.npcProgramChangeId);
        }

        function refreshData() {
            eventSvc.broadcast('refreshNpcData');
            return getCurrentNpcData().then(data => {
                getFinalActionData().then(fa => {
                    model.npcData = data;
                    model.parent.npcData = data;
                    model.programsSelected = [];
                    model.finalAction = fa.value;
                });
            })
        }

        model.previewFinalStatement = function () {
            var isFV = false;

            npcProgramChangeReportSvc.getByNPCProgramChangeId(model.programsSelected[0].npcProgramChangeId).then(function (rpts) {
                isFV = rpts.some(t => t.reportType === 'Statement');
            });

            if (!model.programsSelected || model.programsSelected.length < 1) {
                alertSvc.addAlertWarning("No program change selected.");
                return;
            }

            var allProgramsWithAdjunctReport = model.programsSelected.every(x =>  x.npcProgramChangeReportDtos.some(x => x.teamMemberTypeId === teamMemberTypeNames.ADJUNCT))
            

            if (!allProgramsWithAdjunctReport) {
                alertSvc.addAlertWarning("Adjunct has not submitted a report.");
                return;
            }

            getCurrentNpcData().then(function (currentNPCData) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/npc/templates/modal/viewNPCPreview.html',
                    size: 'xl',
                    controller: 'viewNPCPreviewCtrl',
                    resolve: {
                        currentNPCData: function () { return currentNPCData; },
                        isPreview: function () { return true; },
                        isFinalAction: function () { return true; },
                        npcProgramChangeReportId: function () { return 0; },
                        isFV: function () { return isFV; },
                        programsSelected: () => model.programsSelected,
                    }
                });
            });
        };

        function getCurrentNpcData(npcId) {
            return $q.when(npcProgramChangeReportSvc.getAllNPCToolData(model.npcId)).then(function (currentNPCData) {
               if (model.programsSelected && model.programsSelected.length > 0) {
                   currentNPCData.npc.npcProgramDtos.forEach(function (pgmDto) {
                       pgmDto.npcProgramChangeDtos.forEach(function (changeDto) {
                           changeDto.selected = model.programsSelected.some(t => t.npcProgramChangeId === changeDto.npcProgramChangeId);
                       })
                   });
               }
               return currentNPCData;
           })
        }

        function getFinalActionData() {
            return npcSvc.getNpcFinalActionByNpcId(model.npcId).then(function (fa) {
                return fa
            });
        }

        var activate = function () {

            getCurrentNpcData(model.npcId).then(function (cData) {
                model.npcData = cData;

                codeSvc.getNpcActionTypes().then(function (data) {
                    model.npcActions = data.value;

                    getFinalActionData().then(function (fa) {
                        model.finalAction = fa.value;
                        model.isDataReady = true;
                    }, function (notfound) {
                        model.isDataReady = true;
                    })
                })
            });
        }();
    };
    module.controller('npcFinalActionTabCtrl', npcFinalActionTabCtrl);

}(angular.module('npc')));