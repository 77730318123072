(function (module) {
    'use strict';

    var documentSvc = function ($http, odataSvc, amsConst, typeConstSvc, Upload, $q, $window, FileSaver, environmentSvc) {
        var factory = {};

        var baseUrl = (environmentSvc.isDev() ? environmentSvc.getDevApiUrl() : '') + amsConst.webApiUrl;

        var getDocumentsByCategoryPath = amsConst.webApiUrl + '/odata/GetDocumentsByCategory({0})';
        var getDocumentsByTypePath = amsConst.webApiUrl + '/odata/GetDocumentsByType({0})';
        var uploadDocumentPath = amsConst.webApiUrl + '/odata/UploadDocument';
        var downloadDocumentPath = baseUrl + '/GetSingleDocument/';
        var deleteDocumentPath = amsConst.webApiUrl + '/odata/DeleteDocument';
        var mergeDocumentsPath = baseUrl + "/MergeDocuments?streamIdArray={0}";
        var documentFileNamePath = amsConst.webApiUrl + "/odata/GetDocumentNameByStreamId({0})";

        var queryBase = '?$filter=';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            };
        };

        var onFailure = function (response) {
            console.error(response.status);
            return {};
        };

        var buildReferenceIdFilter = function (parameter) {
            return "(referenceId eq " + parameter + ')';
        };

        factory.data = { documents: null };

        var refreshData = null;

        factory.getDocumentNameByStreamId = function (streamId) {

            var promise = $http.get(documentFileNamePath.format("streamId='" + streamId + "'")).then(onSuccess, onFailure);

            return promise;
        };

        factory.getDocumentsByCategory = function (categoryId, noStoredData) {
            refreshData = function () {
                factory.getDocumentsByCategory(categoryId);
            };

            var promise = $http.get(getDocumentsByCategoryPath.format('categoryId=' + categoryId)).then(onSuccess, onFailure);

            if (!noStoredData) {
                promise.then(function (results) {
                    factory.data.documents = results.value;
                });
            }

            return promise;
        };

        factory.getDocumentsByCategoryForEntity = function (categoryId, referenceId, noStoredData) {
            refreshData = function () {
                factory.getDocumentsByCategoryForEntity(categoryId, referenceId);
            };

            var promise = $http.get(getDocumentsByCategoryPath.format('categoryId=' + categoryId) + queryBase + buildReferenceIdFilter(referenceId)).then(onSuccess, onFailure);

            if (!noStoredData) {
                promise.then(function (results) {
                    factory.data.documents = results.value;
                });
            }

            return promise;
        };

        factory.getDocumentsByType = function (typeId, noStoredData) {
            refreshData = function () {
                factory.getDocumentsByType(typeId);
            };

            var promise = $http.get(getDocumentsByTypePath.format('typeId=' + typeId)).then(onSuccess, onFailure);

            if (!noStoredData) {
                promise.then(function (results) {
                    factory.data.documents = results.value;
                });
            }

            return promise;
        };

        factory.getDocumentsByTypeForEntity = function (typeId, referenceId, noStoredData) {
            refreshData = function () {
                factory.getDocumentsByTypeForEntity(typeId, referenceId);
            };

            var promise = $http.get(getDocumentsByTypePath.format('typeId=' + typeId) + queryBase + buildReferenceIdFilter(referenceId)).then(onSuccess, onFailure);

            if (!noStoredData) {
                promise.then(function (results) {
                    factory.data.documents = results.value;
                });
            }

            return promise;
        };

        factory.downloadDocument = function (streamId, newFileName) {
            var documentPath = downloadDocumentPath + streamId;
            if (newFileName) documentPath += '?newFileName=' + escapeFilename(newFileName);
            window.open(documentPath, '_blank', '');
        };

        factory.downloadDocumentBlob = function (streamId, newFileName) {
            var config = { responseType: 'blob' };
            var documentPath = downloadDocumentPath + streamId;
            if (newFileName) documentPath += '?newFileName=' + escapeFilename(newFileName);

            return $http.get(documentPath, config).then(function (response) {
                var headers = response.headers();
                var contentDisposition = headers["content-disposition"] || '"attachment.pdf"';
                var pieces = contentDisposition.split('"');
                var filename = pieces[pieces.length - 2];
                var contentType = headers["content-type"] || 'application/octet-stream';

                return {
                    filename: filename,
                    contentType: contentType,
                    blob: response.data
                };
            });
        };

        factory.downloadDocumentBase64 = function (streamId, newFileName) {
            var config = { responseType: 'blob' };
            var documentPath = downloadDocumentPath + streamId;
            if (newFileName) documentPath += '?newFileName=' + escapeFilename(newFileName);

            return $http.get(documentPath, config).then(function (response) {
                var deferred = $q.defer();

                var reader = new FileReader();

                reader.addEventListener("load", function () {
                    deferred.resolve(reader.result);
                }, false);

                reader.readAsDataURL(response.data);

                return deferred.promise;
            });
        };

        factory.downloadDocuments = function (files, zipFileName) {
            zipFileName = zipFileName || 'download.zip';

            var applicationsZip = new JSZip();
            var promises = [];

            files.forEach(function (file) {
                var promise = factory.downloadDocumentBlob(file.streamId).then(function (data) {
                    applicationsZip.file(file.fileName || data.filename, data.blob, { binary: true });
                });

                promises.push(promise);
            });

            return $q.all(promises).then(function (data) {
                return applicationsZip.generateAsync({ type: "blob" }).then(
                    function (data) {
                        FileSaver.saveAs(data, zipFileName);
                    });
            });
        }

        factory.uploadDocument = function (document) {
            var uploadOptions = {
                url: uploadDocumentPath,
                data: {
                    id: document.referenceId,
                    typeId: document.documentTypeId,
                    title: document.documentTitle ? document.documentTitle : null,
                    file: document.file
                }
            };

            return doUpload(uploadOptions);
        };

        factory.uploadFile = function (file) {
            var uploadOptions = {
                url: uploadDocumentPath,
                data: {
                    file: file
                }
            };

            return doUpload(uploadOptions);
        };

        factory.getMergeDocumentsUrl = function (streamIdArrayString, newFileName, isInline) {
            var url = mergeDocumentsPath.format(streamIdArrayString, newFileName);
            if (newFileName != null) {
                url += '&newFileName=' + escapeFilename(newFileName);
            }
            if (isInline) {
                url += '&inline=true';
            }
            return url;
        }

        factory.mergeDocuments = function (streamIdArrayString, newFileName) {
            var mergePath = factory.getMergeDocumentsUrl(streamIdArrayString, newFileName);
            var docWindow = $window.open(mergePath, '_blank', '');
        }

        factory.printDocuments = function (streamIdArrayString, newFileName) {
            // Using hidden frame because I couldn't make _blank window with a PDF close after printing.
            var pdfFrameId = 'pdfFrame';
            // Remove pre-existing iframe to ensure pdf loads correctly.
            var pdfFrame = document.getElementById(pdfFrameId);
            if (pdfFrame !== null) {
                document.body.removeChild(pdfFrame);
            }
            // Insert fresh iframe into DOM
            pdfFrame = document.createElement('iframe');
            pdfFrame.id = pdfFrameId;
            pdfFrame.className = 'hidden-iframe';
            // Set iframe up to print itself after loading.
            document.body.appendChild(pdfFrame);
            pdfFrame.onload = function () {
                pdfFrame.focus();
                pdfFrame.contentWindow.print();
            };
            // Load merged documents into iframe.
            var isInline = true;
            var mergedDocumentsUrl = factory.getMergeDocumentsUrl(streamIdArrayString, newFileName, isInline);
            pdfFrame.src = mergedDocumentsUrl;
        }

        function doUpload(uploadOptions) {
            var promise = Upload.upload(uploadOptions);

            if (refreshData) {
                promise.then(refreshData);
            }

            return promise;
        }

        factory.deleteDocument = function (streamId, documentTypeId) {
            var data = {
                streamId: streamId,
                typeId: documentTypeId.toString()
            };

            return doDelete(data);
        };

        factory.deleteFile = function (streamId) {
            var data = {
                streamId: streamId,
                typeId: 0
            };

            return doDelete(data);
        };

        function doDelete(data) {
            var promise = $http.post(deleteDocumentPath, data);

            if (refreshData) {
                promise.then(refreshData);
            }

            return promise;
        }

        factory.getFileUrl = function (stream_id, isInline) {
            var url = downloadDocumentPath + stream_id;

            if (isInline) {
                url += '?inline=true';
            }
            return url;
        }

        factory.disableDragAndDropOnParent = function () {
            window.addEventListener("dragover", function (e) {
                e = e || event;
                e.preventDefault();
            }, false);
            window.addEventListener("drop", function (e) {
                e = e || event;
                e.preventDefault();
            }, false);
        }

        function escapeFilename(filename) {
            return filename.replace('&', '~');
        }

        return {
            data: factory.data,
            getDocumentsByCategory: factory.getDocumentsByCategory,
            getDocumentsByCategoryForEntity: factory.getDocumentsByCategoryForEntity,
            getDocumentsByType: factory.getDocumentsByType,
            getDocumentsByTypeForEntity: factory.getDocumentsByTypeForEntity,
            downloadDocument: factory.downloadDocument,
            downloadDocumentBlob: factory.downloadDocumentBlob, 
            downloadDocumentBase64: factory.downloadDocumentBase64, 
            downloadDocuments: factory.downloadDocuments,
            uploadDocument: factory.uploadDocument,
            uploadFile: factory.uploadFile,
            mergeDocuments: factory.mergeDocuments,
            printDocuments: factory.printDocuments,
            deleteDocument: factory.deleteDocument,
            deleteFile: factory.deleteFile,
            getFileUrl: factory.getFileUrl,
            getMergeDocumentsUrl: factory.getMergeDocumentsUrl,
            disableDragAndDropOnParent: factory.disableDragAndDropOnParent,
            getDocumentNameByStreamId: factory.getDocumentNameByStreamId
        };
    };

    module.factory('documentSvc', documentSvc);

})(angular.module('common'));