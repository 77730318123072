(function (module) {

    var templateRoot = "/Apps/pevApp/templates/";

    module.directive('applicationSummary', function (componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevAppSummary.html',
            link: function (scope, ctrl, attrs) {
                scope.isModal = true;
                (scope.activate = function () {
                    componentHelperSvc.invokeOnRendered('applicationSummary', scope);
                })();               
            }
        };
    });

    module.directive('codeOfConduct', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'codeOfConduct.html'
        };
    });

    module.directive('appPersonalInfo', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevPersonalModule.html'
        };
    });

    module.directive('appSocieties', function (componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevSocietyModule.html',
            link: function (scope, ctrl, attrs) {
                (scope.activate = function () {
                    componentHelperSvc.invokeOnRendered('appSocieties', scope);
                })();
            }
        };
    });

    module.directive('appQuestionnaire', function (componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevQuestionnaireModule.html'
        };
    });

    module.directive('appReferences', function (componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevRefModule.html'
        };
    });

    module.directive('appResumeAndContacts', function (componentHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevSubmitModule.html'
        };
    });

    module.directive('applicationGeneral', function ($uibModal, pevAppSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'appGeneral.html',
            link: function (scope, ctrl, attrs) {

                scope.title = "Account Information";
                scope.data = pevAppSvc.volunteerData;
                scope.isButtonHidden = pevAppSvc.isEditButtonHidden();

                scope.edit = function (person) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: templateRoot + 'appGeneralEdit.html',
                        size: 'lg',
                        controller: 'appGeneralEditCtrl'
                    });
                };
            }
        };
    });
}(angular.module('pevApp')));