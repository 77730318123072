(function (module) {

    var organizationLinkSvc = function (odataSvc) {
        var apiPath = '/OrganizationLink';
        var apiPathSearchOrg = '/GetOrganizationLinkByOrganizationId';
        var key = 'organizationLinkId';
        var factory = {};

        factory.getOrganizationLinks = function () {
            var oSvc = odataSvc.get();

            oSvc.getSource(apiPath, key).odata()
                .expand('organizationLinkDetailDtos')
                .expand('linkTypeDto')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getOrganizationLinksByOrganizationId = function (orgId) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter(apiPathSearchOrg, 'organizationId', orgId)

            oSvc.getSource(path, key).odata()
                .expand('organizationLinkDetailDtos')
                .expand('linkTypeDto')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        return {
            getOrganizationLinks: factory.getOrganizationLinks,
            getOrganizationLinksByOrganizationId: factory.getOrganizationLinksByOrganizationId
        };
    };
    module.factory('organizationLinkSvc', organizationLinkSvc);

})(angular.module('organization'));