(function (module) {

    var commissionsAcctAndNgrYearCtrl = function ($scope, $stateParams, $uibModal, codeSvc, $q, eventSvc, helperSvc, alertSvc, rfrSvc, organizationCommissionSvc) {
        var model = this;
        var parent = $scope.$parent.model;

        model.organizationId = $stateParams.organizationId;
        model.title = "Commissions, Account Number, and NGR Year";
        model.data = organizationCommissionSvc.data;

        model.editCommissions = function () {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/organization/templates/commissionsAcctAndNgrYearEdit.html',
                size: 'lg',
                controller: 'commissionsAcctAndNgrYearEditCtrl',
                resolve: {
                    organizationId: function () {
                        return model.organizationId;
                    },
                    commissions: function () {
                        return model.commissions;
                    }
                }
            });
        };

        model.createNewRfr = function (organizationId, commissionId) {
            alertSvc.confirm("Are you sure you want to create a new RFE for the upcoming cycle?", submit);

            function submit() {
                rfrSvc.createNewRfrByAdmin(organizationId, commissionId).then(function (data) {
                    alertSvc.addAlertSuccess('New RFE has been created successfully.');
                }).catch(function (error) {
                    alertSvc.addAlertSuccess('Error Occurred! ' + error.data.error.innererror.message.substring(0, 40));
                });
            }
        };

        activate();

        function activate() {
            var comms = codeSvc.getCommissions();

            //when all promises are resolved, process data
            $q.all([comms]).then(function (data) {
                comms = data.pop();
                comms = helperSvc.getResults(comms);
                if (comms) {
                    model.commissions = comms;
                }

            });
        };

    };

    module.controller('commissionsAcctAndNgrYearCtrl', commissionsAcctAndNgrYearCtrl);

}(angular.module('organization')));