(function (module) {
    var movedCtrl = function ($state) {

        var model = this;

        model.title = 'Moved Pages';
        model.links = [
            { name: 'ABET Payment', url: 'https://amspub.abet.org/payment/payment-form' },
            { name: 'Submit Readiness Review', url: 'https://amsapp.abet.org/#/readinessrequest' }
        ];

        var activate = function () {
            // Do something
        }();

    };

    module.controller('movedCtrl', movedCtrl);

} (angular.module('root')));