(function (module) {

    var readinessSvc = function (odataSvc, $http, $odata, $q, currentUser, helperSvc, rfrSvc, alertSvc, readinessStatuses, criteriaTypes, selfStudyUploadOptionTypes) {
        var apiPath = '/Readiness';
        var key = 'readinessId';

        var date = new Date();
        //Late march of current year??? TODO: VERIFY
        var startDate = new Date(date.getFullYear(), 0, 1);
        var endDate = new Date(date.getFullYear(), 11, 31);
        var currentReviewYear = date.getFullYear();

        var blankSurvey = [
            {
                question: 'How did you learn about ABET accreditation?',
                options: [
                    'ABET Presentation',
                    'Google Search',
                    'Other'
                ],
                answer: null,
                otherAnswer: ''
            },
            {
                question: 'Why did you decide to pursue ABET accreditation?',
                answer: null
            }
        ];

        var factory = {};

        factory.data = {
            selectedRR: null,
            currentReadinessReview: null,
            historicalReadinessReviews: [],
            readinessReviews: [],
            programErrors: {},
            manageStatusErrors: [],
            readinessErrors: {},
            surveys: []
        };

        factory.slugs = {
            INFO: 'info',
            PROGRAMS: 'programs',
            READINESS: 'rr',
            SUBMIT: 'submit',
            STATUS: 'status'
        };

        factory.getReadinessByPerson = function () {
            var oSvc = odataSvc.get();
            var path = '/GetReadinessByPerson';

            oSvc.getSource(path, key).odata()
                .expand('readinessProcessTrackingDtos')
                .query(oSvc.onSuccess, oSvc.onFailure);

            resolveReadinessReviews(oSvc);

            return oSvc.getDeferred().promise;
        };

        factory.getRRById = function (id) {
            var oSvc = odataSvc.get();
            var path = '/Readiness(readinessId='+id+')';

            oSvc.getSource(path, key).odata()
                .expand('readinessProcessTrackingDtos')
                .single() // .single(oSvc.onSuccess, oSvc.onFailure) throws an internal error for some reason; update odataresources?
                .$promise 
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.setSelectedRR = function (rr) {
            deSerializeJson(rr);
            setCommissionIds(rr);
            setProcessTrackingByDate(rr);
            factory.data.selectedRR = rr;
        };

        factory.create = function (data) {
            var oSvc = odataSvc.get();
            var updatedData = cleanReadinessRequestContents(data);
            updatedData = serializeJsonContents(updatedData);
            var resource = oSvc.instantiate(apiPath, key, updatedData);
            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            return promise;
        };

        factory.update = function (data, noStoredData) {
            var oSvc = odataSvc.get();
            var updatedData = serializeJsonContents(data);
            var resource = oSvc.instantiate(apiPath, key, updatedData);
            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure)

            if (!noStoredData) {
                promise.then(function (data) {
                    factory.setSelectedRR(data);
                });
            }

            return promise;
        };

        factory.updateContactPerson = function (rr, personId, person) {
            rr.contactPersonId = personId;
            rr.contactPersonJson = person;
            return factory.update(rr);
        };

        factory.updateOrganization = function (rr) {
            rr.organizationId = rr.organizationJson.organizationId;
            // Restore fields lost when organizationJson replaced with selected search result
            var originalOrg = angular.copy(factory.data.selectedRR.organizationJson);
            rr.organizationJson.organizationRecognition = originalOrg.organizationRecognition;
            rr.organizationJson.organizationCommissions = originalOrg.organizationCommissions;
            // Update organizationCommissions with newly verified organizationId
            angular.forEach(rr.organizationJson.organizationCommissions, function (orgComm) {
                orgComm.organizationId = rr.organizationId;
            });
            return factory.update(rr);
        };

        factory.createContactPerson = function (data) {
            data.readinessDto = serializeJsonContents(data.readinessDto);

            var path = '/webapi/odata/CreateReadinessContact';

            var promise = $http.post(path, data).then(function () {
                factory.getRRById(data.readinessDto.readinessId).then(function (data) {
                    factory.setSelectedRR(data);
                });
            });

            return promise;
        };

        factory.createOrganization = function (data) {
            // Restore fields lost when organizationJson replaced with selected search result
            var originalOrg = angular.copy(factory.data.selectedRR.organizationJson);
            data.readinessDto.organizationJson.organizationRecognition = originalOrg.organizationRecognition;
            data.readinessDto.organizationJson.organizationCommissions = originalOrg.organizationCommissions;

            data.readinessDto = serializeJsonContents(data.readinessDto);

            var path = '/webapi/odata/CreateReadinessOrganization';

            var promise = $http.post(path, data).then(function () {
                factory.getRRById(data.readinessDto.readinessId).then(function (data) {
                    factory.setSelectedRR(data);
                });
            });

            return promise;
        };

        factory.acknowledgeRequest = function (data) {
            
            data.readinessDto = serializeJsonContents(data.readinessDto);

            var path = '/webapi/odata/AcknowlegeRequest';

            
            var promise = $http.post(path, data).then(function () {
                factory.getRRById(data.readinessDto.readinessId);
            });

            return promise;
        };

        factory.acceptRequest = function (data) {
            var path = '/webapi/odata/AcceptRequest';
            
            data.readinessDto = serializeJsonContents(data.readinessDto);

            var promise = $http.post(path, data).then(function () {
                factory.getRRById(data.readinessDto.readinessId);
            });

            return promise;
        };
              
        factory.isReviewer = function () {
            return isReviewer(factory.data.selectedRR);
        };

        function resolveReadinessReviews(oSvc) {
            factory.data.readinessReviews = [];
            factory.data.historicalReadinessReviews = [];

            oSvc.getDeferred().promise.then(function (data) {
                //setup for current and historical
                angular.forEach(data, function (rr) {
                    deSerializeJson(rr);
                    setCommissionIds(rr);
                    setProcessTrackingByDate(rr);
                    if (date > startDate && date < endDate)
                        factory.data.readinessReviews.push(rr);
                    else
                        factory.data.historicalReadinessReviews.push(rr);
                });
                if (factory.data.readinessReviews.length > 0) {
                    // Find most recent readiness review that the current user is the contact for.
                    var currentReadinessReview = null;
                    var currentPersonId = parseInt(currentUser.profile.personId);

                    for (var index = factory.data.readinessReviews.length - 1; index >= 0; index--) {
                        if (factory.data.readinessReviews[index].contactPersonId === currentPersonId) {
                            currentReadinessReview = factory.data.readinessReviews[index];
                            break;
                        }
                    }
                    factory.data.currentReadinessReview = currentReadinessReview;
                }
            });
        }

        function isReviewer(rr) {
            var programs = rr.programs;
            var isReviewer = false;
            angular.forEach(programs, function (program) {
                if (program.readinessReview && program.readinessReview.reviewer) {
                    if (program.readinessReview.reviewer.personId == currentUser.profile.personId)
                        isReviewer = true;
                }
            });
            return isReviewer;
        }

        function deSerializeJson(rr) {
            if (rr.organizationJson)
                rr.organizationJson = angular.fromJson(rr.organizationJson);
            if (rr.contactPersonJson)
                rr.contactPersonJson = angular.fromJson(rr.contactPersonJson);
            if (rr.surveyQuestionJson) {
                rr.surveyQuestionJson = angular.fromJson(rr.surveyQuestionJson);
            }

            rr.survey = unpackSurveyAnswers(blankSurvey, rr.surveyQuestionJson);

            if (rr.programs) {
                rr.programs = angular.fromJson(rr.programs);
                // Fix data to allow multiple reviewer assignments (1/commission for join reviews)
                angular.forEach(rr.programs, function (program) {
                    if (!program.commissionIds || !program.commissionIds.length || !program.readinessReview || !program.readinessReview.reviewer || program.readinessReview.reviewers)
                        return;
                    // Assume reviewer assigned for the first commission in a joint commission; will need to manually fix these anyway.
                    program.readinessReview.reviewer.commissionId = program.commissionIds[0].commissionId;
                    program.readinessReview.reviewers = [program.readinessReview.reviewer];
                    delete program.readinessReview.reviewer;
                });
            }
        }

        //Sets the first item in the array to most recent and orders from most recent
        function setProcessTrackingByDate(rr) {
            if (rr && rr.readinessProcessTrackingDtos && rr.readinessProcessTrackingDtos.length > 0) {
                rr.readinessProcessTrackingDtos.sort(function (a, b) {
                    return new Date(b.commitDate) - new Date(a.commitDate);
                });
            }
        }

        function setCommissionIds(rr) {
            var commissionIds = [];
            if (rr && rr.programs && angular.isArray(rr.programs)) {
                angular.forEach(rr.programs, function (program) {
                    if (angular.isArray(program.commissionIds)) {
                        angular.forEach(program.commissionIds, function (obj) {
                            if (obj.commissionId) {
                                if (commissionIds.indexOf(obj.commissionId) === -1) commissionIds.push(obj.commissionId);
                            }
                        });
                    } else {
                        if (commissionIds.indexOf(program.commissionId) === -1) commissionIds.push(program.commissionId);
                    }
                });
            }
            rr.commissionIds = commissionIds;
        }

        function serializeJsonContents(data) {
            var updatedData = angular.copy(data);

            updatedData.organizationJson = angular.toJson(data.organizationJson);
            updatedData.contactPersonJson = angular.toJson(data.contactPersonJson);
            updatedData.programs = angular.toJson(data.programs);
            data.surveyQuestionJson = packSurveyAnswers(data.survey);
            updatedData.surveyQuestionJson = angular.toJson(data.surveyQuestionJson);

            delete updatedData.commissionIds;
            delete updatedData.survey;

            return updatedData;
        }

        function cleanReadinessRequestContents(data) {
            var updatedData = angular.copy(data);

            updatedData.organizationId = updatedData.organizationJson.organizationId ? updatedData.organizationJson.organizationId : null;
            updatedData.requestYear = currentReviewYear;

            return updatedData;
        }

        factory.setCommission = function (program, selectedCommission) {
            var commissionIds = null;
            if (selectedCommission) {
                commissionIds = angular.isArray(selectedCommission.ids) ?
                    selectedCommission.ids.map(function (id) {
                        return { commissionId: id };
                    }) : { commissionId: selectedCommission.ids };
            }

            program.commissionIds = selectedCommission ? commissionIds : null;
            var commissionName = selectedCommission ? selectedCommission.abrv : null;
            program.commissionAbbreviatedName = commissionName; // abbreviated name used by program edit
            program.commissionName = commissionName;            // commission name used in RR JSON 
        }

        factory.getProgramByCommissionId = function (commissionIds) {
            if (!factory.data.selectedRR || !factory.data.selectedRR.programs || factory.data.selectedRR.programs.length === 0 ||
                !commissionIds || !Array.isArray(commissionIds) || commissionIds.length === 0) {
                return [];
            }

            var programs = factory.data.selectedRR.programs.filter(function (program) {
                var isIncluded = false;
                // Include program if any of its commissions are in commissionIds
                if (program.commissionIds && Array.isArray(program.commissionIds)) {
                    for (index = 0; index < program.commissionIds.length; index++) {
                        var programCommissionId = program.commissionIds[index];
                        if (commissionIds.indexOf(programCommissionId) !== -1) {
                            isIncluded = true;
                            break;
                        }
                    }
                }

                return isIncluded;
            });

            return programs;
        }

        factory.getCampusLocations = function (allowDuplicates) {
            var otherCampusCount = 0;
            var mainCampusCount = 0;
            var rfrMainCampuses = [];
            var rfrOtherCampuses = [];
            var campusData = {};

            angular.forEach(factory.data.selectedRR.programs, function (program) {
                if (program.campuses && program.campuses.length > 0) {
                    var campusDto = rfrSvc.convertToCampusesDto(program);

                    angular.forEach(campusDto.campuses, function (address) {
                        if (!address.isMainCampus) {
                            if (!helperSvc.arrContains(rfrOtherCampuses, address) || allowDuplicates) {
                                rfrOtherCampuses.push(address);
                            }

                            otherCampusCount += 1;
                        }
                        else if (address.isMainCampus) {
                            if (!helperSvc.arrContains(rfrMainCampuses, address)) {

                                rfrMainCampuses.push(address);
                            }

                            mainCampusCount += 1;
                        }
                    });
                }
            });

            if (rfrMainCampuses.length > 0) {
                rfrOtherCampuses.unshift(rfrMainCampuses[0]);
            }

            return { 'campusLocations': rfrOtherCampuses, 'otherCampusCount': otherCampusCount, 'mainCampusCount': mainCampusCount };
        };

        factory.convertOrgToCampusDto = function (organization) {
            var obj = organization ? organization : {};
            var campusAddressDto = {
                addressDto: {
                    address1: obj.address1 ? obj.address1 : null,
                    address2: obj.address2 ? obj.address2 : null,
                    address3: obj.address3 ? obj.address3 : null,
                    addressId: 0,
                    city: obj.city ? obj.city : null,
                    province: obj.province ? obj.province : null,
                    countryCode: obj.countryCode ? obj.countryCode : null,
                    countryName: obj.countryName ? obj.countryName : null,
                    isDeleted: false,
                    postalCode: obj.postalCode ? obj.postalCode : null,
                    stateCode: obj.stateCode ? obj.stateCode : null,
                    stateName: obj.stateName ? obj.stateName : null,
                },
                addressId: 0,
                changeId: null,
                isDeleted: false,
                isOutsideUS: (obj.countryCode ? obj.countryCode !== 'US' : false),
                programReviewCampusId: 0,
                programReviewId: null,
                campusName: 'Main Campus',
                distanceMiles: 0,
                isMainCampus: true,
                note: null
            }

            return campusAddressDto;
        }

        factory.getCommissionCriteria = function (commissionId) {
            var apiPath = '/CommissionCriteria';
            var key = 'commissionCriteriaId';
            var path = apiPath;

            var predicates = [];

            // Filter by commissionId, if specified 
            if (commissionId) {
                predicates.push(new $odata.Predicate('commissionId', commissionId));
            }
            // Get current criteria based on StartDate, EndDate
            var someday = new Date();
            var startDate = new Date(someday.toISOString().slice(0, 10) + 'T00:00:00Z');
            someday.setUTCDate(someday.getUTCDate() + 1);
            var endDate = new Date(someday.toISOString().slice(0, 10) + 'T00:00:00Z');

            var startDateIsNullPredicate = new $odata.Predicate('startDate', null);
            var startDateIsPastPredicate = new $odata.Predicate('startDate', 'le', startDate);
            var startDatePredicate = $odata.Predicate.or([startDateIsNullPredicate, startDateIsPastPredicate]);

            var endDateIsNullPredicate = new $odata.Predicate('endDate', null);
            var endDateIsFuturePredicate = new $odata.Predicate('endDate', 'ge', endDate);
            var endDatePredicate = $odata.Predicate.or([endDateIsNullPredicate, endDateIsFuturePredicate]);

            predicates.push(endDatePredicate);

            var combinedPredicate = $odata.Predicate.and(predicates);

            var oSvc = odataSvc.get();
            oSvc.getSource(path, key).odata()
                .filter(combinedPredicate)
                .orderBy('commissionId')
                .orderBy('jointCommissionId')
                .orderBy('isMasterLevel')
                .orderBy('criteriaId')
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        var criteriaCache = {};

        factory.getCriteria = function (criteriaTypeIds) {
            var criteriaTypesKey = (criteriaTypeIds || []).join();

            if (criteriaCache[criteriaTypesKey]) {
                var deferred = $q.defer();
                deferred.resolve(criteriaCache[criteriaTypesKey]);
                return deferred.promise;
            }

            var path = '/Criteria';
            var key = 'criteriaId';

            var oSvc = odataSvc.get();
            var svc = oSvc.getSource(path, key).odata();

            // Filter by criteria types, if specified 
            if (criteriaTypeIds && criteriaTypeIds.length) {
                var predicates = criteriaTypeIds.map(function (criteriaId) {
                    return new $odata.Predicate('criteriaTypeId', criteriaId);
                });
                var combinedPredicate = $odata.Predicate.or(predicates);
                svc.filter(combinedPredicate);
            }

            svc.orderBy('criteriaTypeId')
               .orderBy('criteriaName')
               .query(oSvc.onSuccess, oSvc.onFailure);

            var promise = oSvc.getDeferred().promise;

            return promise.then(function (data) {
                criteriaCache[criteriaTypesKey] = data;
                return data;
            });
        }


        var criteriaTypeOrder = {};
        criteriaTypeOrder[criteriaTypes.SELFSTUDY] = 0;
        criteriaTypeOrder[criteriaTypes.BACKGROUND] = 1;
        criteriaTypeOrder[criteriaTypes.CRITERIA] = 2;
        criteriaTypeOrder[criteriaTypes.INTERIMREPORT] = 3;
        criteriaTypeOrder[criteriaTypes.TERMINATIONPLAN] = 4;
        criteriaTypeOrder[criteriaTypes.MASTERSGENERALCRITERIA] = 5;
        criteriaTypeOrder[criteriaTypes.APPM] = 6;
        criteriaTypeOrder[criteriaTypes.APPENDIX] = 7;
        criteriaTypeOrder[criteriaTypes.TRANSCRIPT] = 8;
        criteriaTypeOrder[criteriaTypes.OPTIONAL] = 9;
        criteriaTypeOrder[criteriaTypes.ADDITIONAL] = 10;

        factory.sortCriteria = function (leftCriterion, rightCriterion) {
            var leftValue = criteriaTypeOrder[leftCriterion.criteriaTypeId];
            var rightValue = criteriaTypeOrder[rightCriterion.criteriaTypeId];
            if (leftValue == rightValue) {
                var leftValue = leftCriterion.criteriaName + ' ' + leftCriterion.criteriaDescription;
                var rightValue = rightCriterion.criteriaName + ' ' + rightCriterion.criteriaDescription;
            }
            if (leftValue < rightValue) return -1;
            if (leftValue > rightValue) return 1;
            return 0;
        }

        factory.getCriteriaFullName = function (criteria) {
            if (!criteria) return null;
            return criteria.criteriaName + (criteria.criteriaDescription ? ': ' + criteria.criteriaDescription : '');
        }

        factory.isOtherOptionValue = function (optionText) {
            return optionText && optionText.trim().toLowerCase().startsWith('other');
        }

        function packSurveyAnswers(surveyData) {
            var survey = angular.copy(surveyData);

            angular.forEach(survey, function (surveyItem) {
                if (surveyItem.answer && factory.isOtherOptionValue(surveyItem.answer)) {
                    surveyItem.answer = surveyItem.otherAnswer;
                }
                delete surveyItem.otherAnswer;
                delete surveyItem.options;
            });

            return survey;
        }

        function unpackSurveyAnswers(blankSurvey, surveyData) {
            var survey = angular.copy(blankSurvey);

            // No unique id's for survey questions in JSON but since the field is populated from the application
            // we can assume questions are in same order as in the blank survey defined in this controller.
            for (var index = 0; surveyData && index < surveyData.length && index < survey.length; index++) {
                var surveyDataItem = surveyData[index];
                var surveyItem = survey[index];
                surveyItem.answer = surveyDataItem.answer;
                // Set "other answer" if answer is not one of the available options for a multiple-choice question.
                if (surveyItem.options) {
                    var surveyDataItemAnswer = surveyDataItem.answer ? surveyDataItem.answer.trim().toLowerCase() : null;
                    var selectedOption = surveyItem.options.find(function (option) {
                        return option ? option.trim().toLowerCase() === surveyDataItemAnswer : null;
                    });
                    var otherOption = surveyItem.options.find(function (option) {
                        return factory.isOtherOptionValue(option);
                    });
                    // Select "other" if the given answer isn't an option but there is an "other" option.
                    if (!selectedOption && otherOption && surveyItem.answer !== null) {
                        surveyItem.otherAnswer = surveyItem.answer;
                        surveyItem.answer = otherOption;
                    }
                }
            }

            return survey;
        }

        factory.isReadOnly = function () {
            var isAdmin = currentUser.profile.userTasks.some(function (task) { return task == 'admin'; });
            if (isAdmin) return false;

            //Reviewers can't edit
            if (isReviewer(factory.data.selectedRR)) return true;
            
            var isReadinessUser = currentUser.profile.userTasks.some(function (task) { return task == 'readiness'; });
            if (!isReadinessUser) return true;

            //read only if RR/OSS has been submitted, is accepted or under review
            return isSubmitted(factory.data.selectedRR);
        }

        function isSubmitted(rr) {
            return (rr.readinessProcessTrackingDtos[0].readinessStatusId !== readinessStatuses.WAITING_FOR_ABET_ACKNOWLEDGEMENT &&
                rr.readinessProcessTrackingDtos[0].readinessStatusId !== readinessStatuses.WAITING_FOR_READINESS_REVIEW_SUBMISSION);
        }

        factory.sendRecommendation = function (rr) {
            var path = '/webapi/odata/SendRecommendation';

            delete rr["@odata.context"];

            var data = { readinessDto: serializeJsonContents(rr) };

            var promise = $http.post(path, data).then(function () {
                factory.getRRById(data.readinessDto.readinessId);
            });

            return promise;
        };

        factory.assignReviewer = function (rr) {
            var path = '/webapi/odata/AssignReviewer';

            delete rr["@odata.context"];

            var data = { readinessDto: serializeJsonContents(rr) };

            var promise = $http.post(path, data).then(function () {
                factory.getRRById(data.readinessDto.readinessId);
            });

            return promise;
        };

        factory.updateAccountNumber = function (organizationCommission) {
            var path = '/webapi/odata/UpdateAccountNumber';

            var data = {
                organizationCommissionDto: organizationCommission
            };

            return $http.post(path, data);
        };

        factory.getProgramKey = function (program) {
            var commissionIdPrefix = program.commissionId ||
                         (Array.isArray(program.commissionIds) ?
                            program.commissionIds.map(function (commission) {
                                return commission.commissionId || commission; 
                            }).join() :
                            program.commissionId || '' );
            return commissionIdPrefix + program.programName + program.degreeCode;
        }


        // PDF Modal Funcs
        var pdfPath = '/Content/files/Readiness-Review-Instructions-09192022.pdf';
        var pageNumbers = {
            GENERAL: 8,
            PROGRAM: 10,
            RR: 15,
            SUBMIT: 18
        };

        factory.openMasterInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Readiness Review Instructions');
        };

        factory.openGeneralInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'General Instructions', pageNumbers.GENERAL);
        };

        factory.openProgramInfoInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Personal Info Instructions', pageNumbers.PROGRAM);
        };

        factory.openDocumentUploadInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Document Upload Instructions', pageNumbers.RR);
        };

        factory.openSubmissionInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Submission Instructions', pageNumbers.SUBMIT);
        };

        factory.initializeReadinessReview = function (rrMode, program, singleDocument) {
            // Skip if readiness review criteria already initialized for this program
            if (!program || (program && program.readinessReview && program.readinessReview.criteria && program.readinessReview.criteria.length)) {
                return $q.defer().promise;
            }

            // Load criteria applicable to given program and create blank readinessReview.
            var readinessReviewCriteriaTypes = getReadinessReviewCriteriaTypes(program, singleDocument);

            return factory.getCriteria(readinessReviewCriteriaTypes).then(function (criteria) {
                criteria.sort(factory.sortCriteria);
                var readinessCriteria = [];
                angular.forEach(criteria, function (criterion) {
                    var readinessCriterion = {
                        criteriaId: criterion.criteriaId,
                        criteriaTypeId: criterion.criteriaTypeId,
                        criteriaName: criterion.criteriaName,
                        criteriaDescription: criterion.criteriaDescription,
                        readinessRequired: criterion.readinessRequired,
                    };
                    if (!rrMode) readinessCriterion.programReviewId = program.programReviewId;
                    if (readinessCriterion.criteriaTypeId === criteriaTypes.TRANSCRIPT ||
                        readinessCriterion.criteriaTypeId === criteriaTypes.ADDITIONAL ||
                        readinessCriterion.criteriaTypeId === criteriaTypes.OPTIONAL) {
                        readinessCriterion.files = [];
                    } else {
                        readinessCriterion.originalFileName = null;
                        readinessCriterion.stream_id = null;
                    }
                    readinessCriteria.push(readinessCriterion);
                });
                program.readinessReview = program.readinessReview || {};
                program.readinessReview.criteria = angular.copy(readinessCriteria);
            });

            function getReadinessReviewCriteriaTypes(program, singleDocument) {
                var selfStudyCriteriaTypesForSingleDocument = [
                    criteriaTypes.SELFSTUDY,
                    criteriaTypes.OPTIONAL
                ];

                var selfStudyCriteriaTypesForIntegratedMS = [
                    criteriaTypes.BACKGROUND,
                    criteriaTypes.CRITERIA,
                    criteriaTypes.MASTERSGENERALCRITERIA,
                    criteriaTypes.APPENDIX,
                    criteriaTypes.OPTIONAL
                ];

                var selfStudyCriteriaTypesForMS = [
                    criteriaTypes.BACKGROUND,
                    criteriaTypes.MASTERSGENERALCRITERIA,
                    criteriaTypes.APPENDIX,
                    criteriaTypes.OPTIONAL
                ];

                var defaultCriteriaTypes = [
                    criteriaTypes.BACKGROUND,
                    criteriaTypes.CRITERIA,
                    criteriaTypes.APPENDIX,
                    criteriaTypes.OPTIONAL
                ];

                var selfStudyCriteriaTypes;

                if (singleDocument) {
                    selfStudyCriteriaTypes = selfStudyCriteriaTypesForSingleDocument;
                } else if (program.degreeLevelCode === 'M') {
                    selfStudyCriteriaTypes = program.isMasterDegreeIntegrated ? selfStudyCriteriaTypesForIntegratedMS :selfStudyCriteriaTypesForMS;
                } else {
                    selfStudyCriteriaTypes = defaultCriteriaTypes;
                }

                if (rrMode) {
                    selfStudyCriteriaTypes.push(criteriaTypes.TRANSCRIPT);
                } else {
                    selfStudyCriteriaTypes.push(criteriaTypes.ADDITIONAL);
                }

                return selfStudyCriteriaTypes;
            }
        }

        factory.getUploadOptionType = function (program) {
            if (!program || !program.readinessReview || !program.readinessReview.criteria || !program.readinessReview.criteria.length)
                return selfStudyUploadOptionTypes.UNINITIALIZED;

            if (program.readinessReview.criteria.some(function (criterion) { return criterion.criteriaTypeId == criteriaTypes.SELFSTUDY; }))
                return selfStudyUploadOptionTypes.SINGLEDOCUMENT;
            else
                return selfStudyUploadOptionTypes.MULTIPLEDOCUMENTS;
        }

        factory.selfStudyAcceptedFileTypes = {
            pdf: 'application/pdf',
            rtf: 'application/rtf',
            txt: 'text/plain',
            doc: 'application/msword',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            xls: 'application/vnd.ms-excel',
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            zip: 'application/x-zip-compressed'
        };
        factory.SELFSTUDYACCEPTEDMIMETYPES = helperSvc.getPropertyValues(factory.selfStudyAcceptedFileTypes).join();
        factory.SELFSTUDYDEFAULTMIMETYPE = factory.selfStudyAcceptedFileTypes.pdf;

        factory.isCriterionOptional = function (criterion, rrMode, disciplines) {
            var optionalTypes = [criteriaTypes.OPTIONAL, criteriaTypes.ADDITIONAL];

            return (rrMode && !criterion.readinessRequired) || optionalTypes.indexOf(criterion.criteriaTypeId) > -1 || isGeneralCriteriaOnly();

            function isGeneralCriteriaOnly() {
                var isGeneralCriteriaOnly = false;
                if (criterion.criteriaName && criterion.criteriaName.toLowerCase() === 'program criteria') {
                    isGeneralCriteriaOnly = Array.isArray(disciplines) &&
                        disciplines.every(function (discipline) {
                            return discipline.disciplineName && discipline.disciplineName.toLowerCase().indexOf('general criteria only') === 0;
                        });
                }
                return isGeneralCriteriaOnly;
            }
        };

        return {
            data: factory.data,
            slugs: factory.slugs,
            getReadinessByPerson: factory.getReadinessByPerson,
            getRRById: factory.getRRById,
            setSelectedRR: factory.setSelectedRR,
            update: factory.update,
            create: factory.create,
            acknowledgeRequest: factory.acknowledgeRequest,
            acceptRequest: factory.acceptRequest,
            updateContactPerson: factory.updateContactPerson,
            createContactPerson: factory.createContactPerson,
            updateOrganization: factory.updateOrganization,
            createOrganization: factory.createOrganization,
            isReviewer: factory.isReviewer,
            setCommission: factory.setCommission,
            getProgramByCommissionId: factory.getProgramByCommissionId,
            getCampusLocations: factory.getCampusLocations,
            convertOrgToCampusDto: factory.convertOrgToCampusDto,
            getCommissionCriteria: factory.getCommissionCriteria,
            getCriteria: factory.getCriteria,
            sortCriteria: factory.sortCriteria,
            getCriteriaFullName: factory.getCriteriaFullName,
            isOtherOptionValue: factory.isOtherOptionValue,
            isReadOnly: factory.isReadOnly,
            sendRecommendation: factory.sendRecommendation,
            assignReviewer: factory.assignReviewer,
            getProgramKey: factory.getProgramKey,
            updateAccountNumber: factory.updateAccountNumber,
            openMasterInstructions: factory.openMasterInstructions,
            openGeneralInstructions: factory.openGeneralInstructions,
            openProgramInfoInstructions: factory.openProgramInfoInstructions,
            openDocumentUploadInstructions: factory.openDocumentUploadInstructions,
            openSubmissionInstructions: factory.openSubmissionInstructions,
            initializeReadinessReview: factory.initializeReadinessReview,
            getUploadOptionType: factory.getUploadOptionType,
            selfStudyAcceptedFileTypes: factory.selfStudyAcceptedFileTypes,
            SELFSTUDYACCEPTEDMIMETYPES: factory.SELFSTUDYACCEPTEDMIMETYPES,
            SELFSTUDYDEFAULTMIMETYPE: factory.SELFSTUDYDEFAULTMIMETYPE,
            isCriterionOptional: factory.isCriterionOptional 
    };
    };

    module.factory('readinessSvc', readinessSvc);

})(angular.module('readinessReview'));