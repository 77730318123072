(function (module) {

    var personMediaViewCtrl = function ($scope, $uibModalInstance, personMedia, documentSvc, personMediaTypeNames) {

        var model = $scope;

        model.title = personMedia ? personMedia.personMediaTypeName + " Preview" : "View Uploaded File";

        documentSvc.downloadDocumentBase64(personMedia.stream_id, personMedia.personMediaTypeName).then(function (data) {
            model.mediaFile = data;
        })

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('personMediaViewCtrl', personMediaViewCtrl);

}(angular.module('person')));