(function (module) {

    var deepObjectCompareSvc = function () {
        var factory = {};

        //To get an array compared to another based on the property values passed in
        //returns an array of arrays that map to eachother, and those that dont
        factory.compareArraysByProperties = function (array1, array2, propertiesToCompareOn) {

            var matchedArrayObjects = [];
            var array1ObjectsNotInArray2 = [];
            var array2ObjectsNotInArray1 = [];

            if (array1.length === 0 && array2.length > 0) {
                array2ObjectsNotInArray1 = array2;
            } else if (array2.length === 0 && array1.length > 0) {
                array1ObjectsNotInArray2 = array1;
            }
            else{
                //match a property to an array property
                for (var k = 0; k < propertiesToCompareOn.length; k++) {
                    var property = propertiesToCompareOn[k];

                    //IF property is itself an array, max of 2 properties
                    if (Array.isArray(property)) {
                        if (array1.length > 0 && array1[0].hasOwnProperty(property[0]) && array1[0].hasOwnProperty(property[1])) {
                            for (var i = 0; i < array1.length; i++) {

                                for (var j = 0; j < array2.length; j++) {

                                    if (array1[i].hasOwnProperty(property[0]) && array1[i].hasOwnProperty(property[1]) && array2[j].hasOwnProperty(property[0]) && array2[j].hasOwnProperty(property[1])) {

                                        if (array1[i][property[0]] === array2[j][property[0]] && array1[i][property[1]] === array2[j][property[1]]) {
                                            matchedArrayObjects.push({ object1: array1[i], object2: array2[j] })
                                            break;
                                        }
                                        //if this is the last object in array 2 and still no match, put array1 object as removed
                                        if ((j + 1) >= array2.length) {
                                            array1ObjectsNotInArray2.push(array1[i]);
                                        }
                                    }

                                }
                            }

                            //second set to determine not in array1 objects
                            for (var i = 0; i < array2.length; i++) {

                                for (var j = 0; j < array1.length; j++) {
                                    //for (var k = 0; k < propertiesToCompareOn.length; k++) {
                                    var property = propertiesToCompareOn[0];

                                    if (array1[j][property[0]] === array2[i][property[0]] && array1[j][property[1]] === array2[i][property[1]]) {
                                        break;
                                    }
                                    if ((j + 1) >= array1.length) {
                                        array2ObjectsNotInArray1.push(array2[i])
                                    }
                                }
                            }
                        }
                    }
                    //IF normal 1 property in properties
                    else if (array1.length > 0 && array1[0].hasOwnProperty(property)) {
                        //first set of loops to determine matched objects and not in array2 objects
                        for (var i = 0; i < array1.length; i++) {

                            for (var j = 0; j < array2.length; j++) {

                                if (array1[i].hasOwnProperty(property) && array2[j].hasOwnProperty(property)) {

                                    if (array1[i][property] === array2[j][property]) {
                                        matchedArrayObjects.push({ object1: array1[i], object2: array2[j] })
                                        break;
                                    }
                                    //if this is the last object in array 2 and still no match, put array1 object as removed
                                    if ((j + 1) >= array2.length) {
                                        array1ObjectsNotInArray2.push(array1[i]);
                                    }
                                }
                            }
                        }

                        //second set to determine not in array1 objects
                        for (var i = 0; i < array2.length; i++) {

                            for (var j = 0; j < array1.length; j++) {
                                //for (var k = 0; k < propertiesToCompareOn.length; k++) {
                                var property = propertiesToCompareOn[0];

                                if (array1[j][property] === array2[i][property]) {
                                    break;
                                }
                                if ((j + 1) >= array1.length) {
                                    array2ObjectsNotInArray1.push(array2[i])
                                }
                            }
                        }
                    }
                }
            }

            var returnedObject = { matchedArrayObjects: matchedArrayObjects, array2ObjectsNotInArray1: array2ObjectsNotInArray1, array1ObjectsNotInArray2: array1ObjectsNotInArray2 }

            return returnedObject;
        }

        //diffs the b commission object to determine difference, if you do not include an arrays unique
        //param, that array will be left out.
        factory.deepDiff = function (a, b, arrayOfArrayCompareProperties) {

            if (a === b) {
                return {
                    changed: 'equal',
                    value: a
                }
            }

            var value = {};
            var equal = true;

            for (var key in a) {

                if (key in b) {
                    if (a[key] === b[key]) {
                        value[key] = {
                            changed: 'equal',
                            value: a[key]
                        }
                    } else {
                        var typeA = typeof a[key];
                        var typeB = typeof b[key];
                        if (a[key] && b[key] && (typeA == 'object' || typeA == 'function') && (typeB == 'object' || typeB == 'function')) {

                            if (Array.isArray(a[key]) && Array.isArray(b[key])) {

                                var comparedArrays = factory.compareArraysByProperties(a[key], b[key], arrayOfArrayCompareProperties);

                                var diffedMatch = [];

                                if (comparedArrays.matchedArrayObjects && comparedArrays.matchedArrayObjects.length > 0) {
                                    for (var i = 0; i < comparedArrays.matchedArrayObjects.length; i++) {
                                        diffedMatch.push(factory.deepDiff(comparedArrays.matchedArrayObjects[i].object1, comparedArrays.matchedArrayObjects[i].object2, arrayOfArrayCompareProperties))
                                    }
                                }

                                //add the added and removed flag for array objects in not in one another
                                for (var x = 0 ; x < comparedArrays.array2ObjectsNotInArray1.length; x++) {
                                    addedObj = {
                                        changed: 'added',
                                        value: comparedArrays.array2ObjectsNotInArray1[x]
                                    };
                                    diffedMatch.push(addedObj)
                                }
                                for (var y = 0 ; y < comparedArrays.array1ObjectsNotInArray2.length; y++) {
                                    removedObj = {
                                        changed: 'removed',
                                        value: comparedArrays.array1ObjectsNotInArray2[y]
                                    };
                                    diffedMatch.push(removedObj)
                                }

                                equal = false;
                                value[key] = diffedMatch;
                                

                            } else {
                                var valueDiff = factory.deepDiff(a[key], b[key], arrayOfArrayCompareProperties);

                                if (valueDiff.changed == 'equal') {
                                    value[key] = {
                                        changed: 'equal',
                                        value: a[key]
                                    }
                                } else {
                                    equal = false;
                                    value[key] = valueDiff;
                                }
                            }
                        }
                        else {
                            equal = false;
                            value[key] = {
                                changed: 'primitive',
                                changedPrimitive: b[key]
                            }
                        }
                    }
                    //end of : if(key in b)
                } else {
                    equal = false;
                    value[key] = {
                        changed: 'removed',
                        value: a[key]
                    }
                }
                //end of : (for var key in a)
            }

            for (key in b) {
                if (!(key in a)) {
                    equal = false;
                    value[key] = {
                        changed: 'added',
                        value: b[key]
                    }
                }
            }

            if (equal) {
                return {
                    changed: 'equal',
                    value: a
                }
            } else {
                return {
                    changed: 'object change',
                    value: value
                }
            }
        };


        //this function converts the diffed object into an object that is more readable and easier to use 
        //for display purposes
        factory.convertDiffedObject = function (diffedObject) {

            for (var key in diffedObject) {

                var typeObj = typeof diffedObject[key];

                if (diffedObject[key] && (typeObj == 'object')) {
                    if (diffedObject[key].changed == 'equal' || diffedObject[key].changed === 'object change') {
                        diffedObject[key] = diffedObject[key].value;
                    }
                    if ((typeof diffedObject[key]) === 'object') {
                        factory.convertDiffedObject(diffedObject[key]);
                    }
                }
            }   
                
             return diffedObject;
        };

        return {
            compareArraysByProperties: factory.compareArraysByProperties,
            deepDiff: factory.deepDiff,
            convertDiffedObject: factory.convertDiffedObject
        };
    };

    module.service('deepObjectCompareSvc', deepObjectCompareSvc);

})(angular.module('common'));