(function (module) {

    var rfrProgramEditCtrl = function ($scope, $uibModalInstance, $stateParams, $filter, commission, program, mode, oauth, isChangeNameRequest, rfrSvc, programSvc, helperSvc, codeSvc, alertSvc, rfrValidationSvc, typeConstSvc, commissionIds, programReviewTypes, organizationSvc, programReviewTypeIds, reviewTypeIds, readinessSvc, readinessValidationSvc) {
        var model = $scope;
        var convertToCampusesDto = rfrSvc.convertToCampusesDto;
        var getProgramCampusTypeName = rfrSvc.getProgramCampusTypeName;
        var convertToProgramCampuses = rfrSvc.convertToProgramCampuses;
        model.rfr = rfrSvc.data.rfr;
        model.readiness = readinessSvc.data.selectedRR;
        model.commission = commission; // Note: This is not a commission but a RfrCommission, which has a collection of programs inside of it.
        model.program = program;
        model.mode = mode;
        model.isAdmin = oauth.isAdmin;
        model.isEditMode = !(typeof (model.program) == 'undefined' || model.program === null);
        model.isNewProgram = (!model.isEditMode || typeof (model.program.programId) == 'undefined' || !model.program.programId || model.program.programId === 0 || model.program.programReviewTypeCode === programReviewTypeIds.INITIALACCREDIATION);
        model.isChangeNameRequest = isChangeNameRequest;
        model.selectedProgram = model.isEditMode ? angular.copy(program) : {};
        model.campusesDto = convertToCampusesDto(model.selectedProgram);
        model.formatDate = helperSvc.formatDate;
        model.getBooleanText = helperSvc.getBooleanText;
        model.errors = [];
        model.programAreas = [];
        model.isReplacingOnlineData = false;
        model.isReplacingLocationData = false;
        model.isReadiness = mode.readiness;
        model.canDeleteChangeName = program && program.nameChange != null;

        if (mode.rfe && !model.isNewProgram && rfrSvc.isInterim(program)) {
            setInterimMode();
        }

        var getProgramByCommissionId = model.isReadiness ? readinessSvc.getProgramByCommissionId : rfrSvc.getProgramByCommissionId;
        model.COMMISSION_IDS = commissionIds;

        if (model.rfr)
            model.reviewYear = rfrSvc.data.rfr.reviewYear;
        else if (model.readiness)
            model.reviewYear = new Date().getFullYear() + 2;
        else
            model.reviewYear = null;
        
        model.organizationId = model.isReadiness ? model.readiness.organizationId : model.rfr.organizationId;

        // Set commissionId using commission.commissionId, selectedProgram.commissionId (scalar), or selectedProgram.commissionIds (array)
        model.commissionId = model.isReadiness ? (model.selectedProgram.commissionIds ?
                                                    ((model.selectedProgram.commissionIds.length && model.selectedProgram.commissionIds[0].commissionId) ?
                                                                                    model.selectedProgram.commissionIds.map(function (item) { return item.commissionId })
                                                                                    : model.selectedProgram.commissionIds)
                                                                                    : model.selectedProgram.commissionId)
                                                                                    : (model.commission ? model.commission.commissionId : null);

        model.commissions = [];

        var endOfThisYear = model.reviewYear - 1;

        model.helpText = {
            degreeDesignation: 'Please specify either the full name or the abbreviation of the degree awarded to a graduate at the time of program completion.',
            nameChange: 'In order to ensure that all graduates pre and post the program’s name change continue to be covered by ABET accreditation, the program must provide the date that the last graduate under the program’s current name will appear as well as the date that the first graduate will appear under the program’s new name.',
            online: 'In order to conduct an appropriate review of a given program, ABET requires an estimate of the percentage of the program that is delivered online. This estimate should include online delivery of the general education component, individual courses, homework assignments, class research projects, and more.',
            replacement: 'This option will overwrite the value of this field for all other ' + (model.commission ? model.commission.commissionAbbreviatedName : model.selectedProgram.commissionAbbreviatedName) + ' programs. You will still be able to edit those programs individually if you decide to enter a custom value for this field later on.',
            firstGraduate: 'ABET policy requires that a program have at least one graduate before the date of the visit but no later than May of the visit year. (Accreditation Policy and Procedure Manual (APPM) Section I.C.5.)',
            retroactive: 'A program requesting initial accreditation has the option to request retroactive accreditation to cover its graduates up to either one or two academic years prior to the on-site review. Please refer to the relevant requirements in the Accreditation Policy and Procedure Manual (APPM) Section I.E.6 to I.E.6.b',
            retroActiveYear1: 'You are selecting the default one-year retroactive accreditation. This indicates that you want to cover your graduates up to one academic year from the on-site review. If you currently don’t have graduates but are expecting first graduates later within this academic year (e.g. March ' + endOfThisYear + ', May ' + endOfThisYear + ', June ' + endOfThisYear + ', or July ' + endOfThisYear + '), please select this option.<br />Note: 10/1 is the typical accreditation start date/the beginning of the review cycle. If you have any questions regarding this, please discuss with your ABET team chair once assigned/approved.',
            retroActiveYear2: 'This selection indicates that you are requesting the maximum retroactive accreditation to cover graduates up to two academic years prior to the on-site review. If you want to cover graduates between one and two years prior to the on-site review, please select this option. Note: 10/1 is the typical accreditation start date/the beginning of the review cycle. If you have any questions regarding this, please discuss with your ABET team chair once assigned/approved.',
            programLinks: 'ABET policy (APPM Section I.A.6.) requires that programs publicly state their educational objectives (PEOs) and student outcomes (SOs). Provide the URL where this information can be found and a title describing its content.'
        };

        model.preCheck = {
            isCompleted: false,
            isPassed: false,
            hasConcentrations: undefined,
            isReady: function () {
                var isReady = false;

                if (this.hasConcentrations !== undefined) {
                    isReady = true;
                };

                return isReady;
            },
            completePreCheck: function () {
                this.isCompleted = true;
                this.isPassed = false;
                if (this.hasConcentrations !== undefined && this.hasConcentrations === false) {
                    this.isPassed = true;
                };
            }
        }

        if (!model.selectedProgram.campuses) {
            model.selectedProgram.campuses = [];
        }

        if (!model.selectedProgram.disciplines) {
            model.selectedProgram.disciplines = [];
        }

        model.noDisciplinesMsg = 'Undetermined';
        model.hasNoDisciplines = model.selectedProgram.disciplines ? (model.selectedProgram.disciplines.length === 0) : true;
        model.programNameLabel = model.isChangeNameRequest ? 'New Program Name' : 'Program Name';
        model.degreeDesignationLabel = model.isChangeNameRequest ? 'New Degree Designation' : 'Degree Designation';

        // Set modal dialog title based on current mode.
        if (model.isChangeNameRequest) {
            model.title = 'Change Program Name';
        } else if (model.isEditMode) {
            if (model.isNewProgram) {
                model.title = 'Edit New Program Information';
            } else {
                model.title = 'Edit Program Information';
            }
        } else {
            model.title = 'Add New Program';
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {

            if (model.selectedProgram.degreeLevelCode !== 'M') {
                model.selectedProgram.isMasterDegreeIntegrated = null; 
            } else if (model.selectedProgram.isMasterDegreeIntegrated == null) {
                model.selectedProgram.isMasterDegreeIntegrated = false;
            }

            helperSvc.validateForm(validateProgramInfo);

            if (model.errors.length > 0) {
                return;
            }

            verifyDegreeCode(doSave);

            function doSave () {
                updateProgramInformation();
                $uibModalInstance.close();
            }
        }

        function setInterimMode() {
            // Set up flag and criteria options for when program is on interim review/visit and institution may select criteria. 
            rfrSvc.getRfrSubmissionsByOrganizationId(model.rfr.organizationId).then(function (data) {
                // Allow institution to choose current cycle year criteria or the criteria under which the previous review was conducted.
                model.criteriaCycleYearOptions = [model.rfr.reviewYear];
                var previousCriteriaCycleYear = model.rfr.reviewYear - 2;
                var previousRFEs = data.filter(function (rfe) { return rfe.isCurrent && rfe.reviewYear < model.rfr.reviewYear; });
                for (var index = 0; index < previousRFEs.length; index++) {
                    var rfe = previousRFEs[index];
                    var oldCommission = Array.isArray(rfe.rfrCommissionDtos) && rfe.rfrCommissionDtos.find(function (commission) {
                        return commission.commissionId === model.commission.commissionId;
                    });
                    var oldJsonContents = oldCommission && oldCommission.jsonContents && angular.fromJson(oldCommission.jsonContents);
                    var oldProgram = oldJsonContents && oldJsonContents.programs && oldJsonContents.programs.find(function (program) {
                        return program.programId === model.program.programId;
                    });
                    if (oldProgram && rfrSvc.isInterim(oldProgram)) {
                        previousCriteriaCycleYear = oldProgram.criteriaCycleYear || rfe.reviewYear - 2;
                    } else {
                        break;
                    }
                }
                model.criteriaCycleYearOptions.push(previousCriteriaCycleYear);
                model.isInterim = true;
            });
        }

        function setDistanceLearningTypeName() {
            var distanceLearningTypeOption = model.distanceLearningTypeOptions
                .find(function (d) { return d.codeKey === model.selectedProgram.distanceLearningId });

            model.selectedProgram
                .distanceLearningTypeName = (distanceLearningTypeOption ? distanceLearningTypeOption.codeName : null
                );
        }

        if (!model.selectedProgram.programLinks) {
            model.selectedProgram.programLinks = [];
        }
        model.addProgramLink = function () {
            var newProgramLink = {
                webpageUrl: null,
                note: null
            };
            model.selectedProgram.programLinks.push(newProgramLink);
        }

        model.deleteProgramLink = function(programLink, index, isLast) {
            model.selectedProgram.programLinks.splice(index, 1);
        }

        model.getProgramAreas = function () {
            // Get program areas for given program name, commission.
            var programName = model.selectedProgram.programName ? model.selectedProgram.programName.trim() : null; // E.g. 'Computer Science';
            // model.commissionId should be set to id from resolved commission or selected program (which may be an array of ids)
            var commissionId = model.commissionId; // E.g. 11799;

            rfrSvc.getProgramArea(programName, commissionId).then(function (programAreaViewModels) {
                // Convert "no data" into an empty array.
                if (programAreaViewModels === undefined || programAreaViewModels === null) {
                    programAreaViewModels = [];
                };

                //to ensure that new programs that dont have a single program area view model correlated are not allowed to have users select the reviewing society
                if (model.isNewProgram && !model.isAdmin() && programAreaViewModels.length === 0) {
                    // Make sure "ABET Chooses" is the default program area.
                    addDefaultProgramAreaViewModel(programAreaViewModels);
                    var programAreas = convertToProgramAreas(programAreaViewModels);
                    programAreas[0].selectedSocietyId = 0;
                } else {
                    // Make sure "ABET Chooses" is the default program area.
                    addDefaultProgramAreaViewModel(programAreaViewModels);
                    // Reformat so we can display societies grouped by program area.
                    var programAreas = convertToProgramAreas(programAreaViewModels);
                    // Update certain program areas to include all possible societies for given commission.
                    addAllSocieties(programAreas);
                    // Set the lead society based on previous selection (as stored in disciplines)
                    setSelectedSocieties(programAreas, model.selectedProgram.disciplines);
                    // Add updated, formatted program areas to the model so UI can bind to them.
                }

                model.programAreas = programAreas;
            });
        };
        
        model.getDegreeLevel = function (degreeLevelCode) {
            return typeConstSvc.getDegreeLevel(degreeLevelCode);
        };

        var activate = function () {
            // Set isOutsideUS flag (to drive display of native program, designation fields, etc.)
            setIsOutsideUS();
            // Get distance learning options.
            codeSvc.getDistanceLearningTypes().then(function (data) {
                model.distanceLearningTypeOptions = helperSvc.getResults(data);
                model.setDistanceLearningTypeName = setDistanceLearningTypeName;
            });
            // Get degree level code values.
            codeSvc.getDegreeLevels().then(function (data) {
                model.degreeLevels = helperSvc.getValue(data);
            });
            // Initialize name change data for change name requests.
            if (model.isChangeNameRequest) {
                if (!model.selectedProgram.nameChange) {
                    model.selectedProgram.nameChange = {
                        "oldProgramName": model.selectedProgram.programName,
                        "oldDegreeCode": model.selectedProgram.degreeCode,
                        "lastGraduateDate": null,
                        "firstGraduateDate": null,
                        "oldDisciplines": model.selectedProgram.disciplines
                    };
                    model.selectedProgram.programName = '';
                }
            };

            if (model.isReadiness) {
                // Verify program name for readiness review
                if (model.selectedProgram.programName && !model.selectedProgram.degreeLevelCode) {
                    setUnverifiedProgramName(true);
                } else {                   
                    setUnverifiedProgramName(false);
                }
                // Can edit commission for programs in readiness review
                model.commissions = typeConstSvc.getCommissionWithJointTypes();
                if (model.isEditMode && model.commissionId !== undefined && model.commissionId !== null) {
                    // Set selectedCommission for commmissions dropdown
                    
                    // Note: arrays of commission ids are compared in several places. This would be a good candidate
                    // for refactoring in a more generic, common routine (maybe attached to Array prototype).
                    var selectedCommission = model.commissions.find(function (commission) {
                        return areArraysEqual(model.commissionId, commission.ids);
                    });
                    if (selectedCommission) {
                        model.selectedCommission = selectedCommission;
                        // Keep track of original commission so we can tell if it has changed and clear readiness review accordingly.
                        model.originalCommission = selectedCommission;
                    }
                }
                // Keep track of original degree level so we can clear readiness review when program switches between MS and BS/AS/etc.
                model.originalDegreeLevelCode = model.selectedProgram.degreeLevelCode;

                model.originalIsMasterDegreeIntegrated = model.selectedProgram.isMasterDegreeIntegrated;
            }

            // Require that users re-enter degree designation; keep track of original so we can verify designation matches previous RFE.
            if (!model.isChangeNameRequest && !model.isNewProgram && model.selectedProgram.originalDegreeCode === undefined) {
                model.selectedProgram.originalDegreeCode = model.selectedProgram.degreeCode || null;
                if (model.selectedProgram.originalDegreeCode && model.selectedProgram.originalDegreeCode.trim() === '') model.selectedProgram.originalDegreeCode = null;
                model.selectedProgram.degreeCode = null;
            } else {
                // Save previous degree code for comparison so we only verify current degree code when changed from last saved value.
                model.previousDegreeCode = model.selectedProgram.degreeCode;
            }

            // Set values for retroactive years.
            if (model.isNewProgram) {

                model.retroActiveYear1 = '10/' + '01/' + (model.reviewYear - 2);
                model.retroActiveYear2 = '10/' + '01/' + (model.reviewYear - 3);

                // Default to 1 year retroactive
                if (!model.selectedProgram.retroactiveYear) {
                    model.selectedProgram.retroactiveYear = model.retroActiveYear1;
                }
            };
            // Format dates.
            if (model.selectedProgram.startDate !== undefined && model.selectedProgram.startDate !== null) {
                model.selectedProgram.startDate = model.formatDate(model.selectedProgram.startDate, true);
            };
            if (model.selectedProgram.nameChange) {
                model.selectedProgram.nameChange.lastGraduateDate = model.formatDate(model.selectedProgram.nameChange.lastGraduateDate, true);
                model.selectedProgram.nameChange.firstGraduateDate = model.formatDate(model.selectedProgram.nameChange.firstGraduateDate, true);
            };
            // Initialize program links array to accomodate current UI and styling.
            if (model.selectedProgram.programLinks === undefined ||
                model.selectedProgram.programLinks === null ||
                model.selectedProgram.programLinks.length === 0) {
                    model.addProgramLink();
            }

            if (model.commissionId) {
                loadAllProgramAreas();
            }

            model.chosenProgramAreas = [];
        }();

        function setUnverifiedProgramName(value) {
            if (value && model.selectedProgram.programName) {
                model.unverifiedProgramName = true;
                model.originalProgramName = model.selectedProgram.programName;
                model.selectedProgram.programName = null;
            } else {
                model.unverifiedProgramName = false;
            }
        }
        
        // Note: arrays of commission ids are compared in several places. This would be a good candidate
        // for refactoring in a more generic, common routine (maybe attached to Array prototype).
        function areArraysEqual(arr1, arr2) {
            if (!arr1 || ! arr2 || arr1.length !== arr2.length) {
                return false;
            }
            for (index = 0; index < arr1.length; index++) {
                if (arr2.indexOf(arr1[index]) === -1) {
                    return false;
                }
            }
            return true;
        }

        function loadAllProgramAreas() {
            // Load program areas for use by admins and societies for use by RFR submitters, admins.
            rfrSvc.getAllProgramAreasForCommission(model.commissionId).then(function (data) {
                // Extract list of societies available under current commission for when program area is "ABET Chooses" or general criteria.
                model.societiesList = convertToSocieties(data);
                // Convert, configure program area objects using denormalized program area data.
                model.programAreaList = convertToProgramAreas(data);
                addAllSocieties(model.programAreaList);
                setSelectedSocieties(model.programAreaList, model.selectedProgram.disciplines)


                // Populate program areas and societies UI for new programs, change name requests. UNLESS A DISCIPLINE HAS BEEN CHOSEN BY ADMIN
                // Need to get all program areas first so we can get all societies so that we can set selected program area and lead society.
                if (model.isChangeNameRequest || model.isNewProgram) {
                    if (model.selectedProgram.disciplines && model.selectedProgram.disciplines.length > 0) {
                        if (model.selectedProgram.disciplines[0].disciplineId === 0) {
                            model.getProgramAreas();
                        } else {
                            model.programAreas = convertToProgramAreas(model.selectedProgram.disciplines);
                            addAllSocieties(model.programAreas);
                            setSelectedSocieties(model.programAreas, model.selectedProgram.disciplines);
                        }
                    } else {
                        model.getProgramAreas();
                    }
                };
            });
        }

        model.needsProgramAreaSelected = function () {
            if (model.selectedProgram.disciplines && model.selectedProgram.disciplines.length > 0) {
                return model.isAdmin && model.selectedProgram.disciplines[0].societyId === 0;
            }

            return false;
        }

        model.updateChosenProgramArea = function () {
            model.programAreas = model.chosenProgramAreas;
        }
        model.toggleReplaceData = function (field) {
            model[field] = !model[field];
        };

        function setIsOutsideUS() {
            if (model.isReadiness) {
                model.isOutsideUS = model.readiness.organizationJson.countryCode !== 'US';
            } else {
                organizationSvc.getOrganizationByUser(model.organizationId).then(function (data) {
                    var organization = helperSvc.getItem(data);
                    // These checks for null and undefined may not be necessary but can't be sure at this point.
                    if (organization !== null && organization !== undefined &&
                        organization.currentOrganizationAddressDto !== null && organization.currentOrganizationAddressDto !== undefined &&
                        organization.currentOrganizationAddressDto.countryCode &&
                        organization.currentOrganizationAddressDto.countryCode === 'US') {
                        model.isOutsideUS = false;
                    } else {
                        model.isOutsideUS = true;
                    };
                });
            }
        }

        function validateProgramInfo() {

            var minsStartString = '08/31/' + (model.reviewYear - 1);
            var minStartDate = new Date(minsStartString);

            model.errors = [];

            if (model.isNewProgram && model.selectedProgram.startDate > minStartDate) {
                model.errors.push('The First Graduate Date should be before ' + minsStartString + '.');
            }
        }

        function updateProgramInformation() {
            cleanData();
            replaceData();
            if (model.mode.rfe) {
                var originalCommission = rfrSvc.data.rfr.rfrCommissionDtos
                    .find(function (c) { return c.commissionId === model.commissionId });
                var updatedRfr = angular.copy(rfrSvc.data.rfr);
                var updatedCommission = updatedRfr.rfrCommissionDtos
                    .find(function (c) { return c.commissionId === model.commissionId });
                // Check if the Program name has existed in the past for this organization.
                programSvc.checkForExistingProgramInOrganization($stateParams.organizationId, model.selectedProgram.programName, model.selectedProgram.degreeCode, model.commissionId, model.isEditMode ? model.selectedProgram.programId : 0).then(function (data) {
                    // Check if program was named the same as another program was renamed to.
                    var existingPrograms = data.value;

                    //01/14/2022 upon the decision from AMS meeting on 06/10/2021, all init programs consider new programs.
                    //if (existingPrograms) {
                    //    alertSvc.addAlertDanger("A program with that name and degree already exists and is active within your organization.");
                    //    return;

                    var existingProgramsOnRFE = originalCommission.jsonContents.programs.some(function (program) {
                        return (!model.isEditMode || program !== model.program) &&
                            program.programName.trim().toLowerCase() === model.selectedProgram.programName.trim().toLowerCase() &&
                            program.degreeCode.trim().toLowerCase() === model.selectedProgram.degreeCode.trim().toLowerCase();
                    });
                    if (existingProgramsOnRFE) {
                        alertSvc.addAlertDanger("A program with that name and degree already exists on this RFE.");
                        return;
                    }
                    // Program has a unique name and degree code within this organization and commission.
                    if (model.isEditMode) {
                        // Index of original program should be the same as the one being replace in the "updated" copy.
                        var programIndex = originalCommission.jsonContents.programs.indexOf(model.program);

                        // Replace program being edited with revised version.
                        updatedCommission.jsonContents.programs[programIndex] = model.selectedProgram;
                        saveUpdatedRfr(updatedRfr);
                    }
                    else {
                        // Push new program into updated RFR commission's programs collection.
                        // Update review type to IV if we're adding a program to an IR
                        if (updatedCommission.reviewTypeCode === reviewTypeIds.INTERIMREPORT) {
                            model.selectedProgram.originalReviewTypeCode = updatedCommission.reviewTypeCode;
                            updatedCommission.reviewTypeCode = reviewTypeIds.INTERIMVISIT;
                        }
                        updatedCommission.jsonContents.programs.push(model.selectedProgram);
                        saveUpdatedRfr(updatedRfr);
                    }
                });
            } else if (model.mode.readiness) {
                var updatedReadiness = angular.copy(readinessSvc.data.selectedRR);

                    if (model.isEditMode) {
                        // Index of original program should be the same as the one being replace in the "updated" copy.
                        var originalReadiness = readinessSvc.data.selectedRR;
                        //var programIndex = originalReadiness.programs.findIndex(x => x.programName == program.programName && x.degreeCode == program.degreeCode);
                        var programIndex = originalReadiness.programs.findIndex(function (record) {
                            return record.programName == model.program.programName && record.degreeCode == model.program.degreeCode
                        });

                        // Replace program being edited with revised version.
                        updatedReadiness.programs[programIndex] = model.selectedProgram;
                        // Check if readiness review has been invalidated (i.e. commission has changed or
                        // degree level code has changed to or from Master's and new criteria are in order).
                        if (model.originalCommission !== model.selectedCommission || isDegreeLevelCodeChanged() || isMasterDegreeIntegratedChanged()) {
                            model.selectedProgram.readinessReview = null;
                        }

                        saveUpdatedReadiness(updatedReadiness);
                    }
                    else {
                        // Push new program into updated readiness programs collection.
                        updatedReadiness.programs.push(model.selectedProgram);
                        saveUpdatedReadiness(updatedReadiness);
                    }
            }
            
        }

        function saveUpdatedRfr(updatedRfr) {
            
            rfrSvc.update(updatedRfr).then(function () {
                alertSvc.addAlertSuccess("Program information successfully updated.");
                rfrValidationSvc.invokeValidation();
            });
        }

        function saveUpdatedReadiness(updatedReadiness) {
            readinessSvc.update(updatedReadiness).then(function () {
                alertSvc.addAlertSuccess("Program information successfully updated.");
                readinessValidationSvc.invokeValidation();
            });
        }

        function cleanData() {
            // Trim name and degree code
            model.selectedProgram.programName = model.selectedProgram.programName.trim();
            model.selectedProgram.degreeCode = model.selectedProgram.degreeCode.trim();
            // Fix addresses
            model.selectedProgram.programCampusTypeId = model.campusesDto.programCampusTypeId;
            model.selectedProgram.programCampusTypeName = getProgramCampusTypeName(model.campusesDto.programCampusTypeId);
            model.selectedProgram.campuses = convertToProgramCampuses(model.campusesDto);
            // Remove null links, format http://
            var nonNullLinks = [];
            angular.forEach(model.selectedProgram.programLinks,
                function(programLink) {
                    if (programLink.webpageUrl !== null || programLink.note !== null) {
                        programLink.webpageUrl = helperSvc.formatUrl(programLink.webpageUrl);
                        nonNullLinks.push(programLink);
                    };
                }
             );
            model.selectedProgram.programLinks = nonNullLinks;
            // Remove empty arrays
            if (model.selectedProgram.disciplines.length === 0) {
                delete model.selectedProgram.disciplines;
            }
            if (model.selectedProgram.programLinks.length === 0) {
                delete model.selectedProgram.programLinks;
            }
            if (model.selectedProgram.campuses.length === 0) {
                delete model.selectedProgram.campuses;
            };
            // Define fields missing from a new program (program ID, detail ID, review type code, etc.)
            if (model.isNewProgram) {
                if (model.selectedProgram.programId === undefined) {
                    model.selectedProgram.programId = 0;
                }
                if (model.selectedProgram.programDetailId === undefined) {
                    model.selectedProgram.programDetailId = 0;
                }
                if (model.selectedProgram.programReviewTypeCode === undefined) {
                    model.selectedProgram.programReviewTypeCode = programReviewTypeIds.INITIALACCREDIATION;
                }
            };
            // Convert normalized program area and lead societies back into flattened "discipline".
            if (model.isChangeNameRequest || model.isNewProgram || (model.isAdmin && model.programAreas.length > 0)) {
                model.selectedProgram.disciplines = convertToProgramAreaViewModels(model.programAreas);
            }
        }
        
        function addDefaultProgramAreaViewModel(programAreaViewModels) {
            if (programAreaViewModels.length === 0) {
                var abetChoosesProgramArea = {
                    disciplineId: 0,
                    disciplineName: "ABET Chooses",
                    societyId: 0,
                    societyName: "ABET Chooses"
                };
                programAreaViewModels.push(abetChoosesProgramArea);
            };
        }

        function convertToProgramAreas(programAreaViewModels) {
            // Convert flattened program area view model objects into normalized program area objects.
            // I.e. [{ id: 1, field: 'A' }, { id: 1, field: 'B' }] becomes { id: 1, [{field: 'A'}, {field: 'B'}]} 
            var programAreas = [];

            angular.forEach(programAreaViewModels, function (programAreaViewModel) {
                var programArea = programAreas.find(function (item) { return item.disciplineId === programAreaViewModel.disciplineId; });

                if (programArea === undefined || programArea === null) {
                    // Add program area to list.
                    programArea = {
                        disciplineName: programAreaViewModel.disciplineName,
                        disciplineId: programAreaViewModel.disciplineId,
                        selectedSocietyId: null,
                        societies: [{
                            societyId: programAreaViewModel.societyId,
                            societyName: programAreaViewModel.societyName
                        }]
                    };
                    programAreas.push(programArea);
                } else {
                    // Program area already added.
                    var society = programArea.societies.find(function (item) { return item.societyId === programAreaViewModel.societyId; });
                    if (society === undefined || society === null) {
                        // Add society to this program area.
                        society = {
                            societyId: programAreaViewModel.societyId,
                            societyName: programAreaViewModel.societyName
                        };
                        programArea.societies.push(society);
                    }
                }
            });
            return programAreas;
        }

        function convertToSocieties(programAreaViewModels) {
            var societiesDictionary = new Array();
            var societies = new Array();

            programAreaViewModels.forEach(function (programArea) {
                // convert id to string for use as key in associative array
                var societyKey = programArea.societyId + '';
                if (!societiesDictionary[societyKey]) {
                    var society = {
                        societyId: programArea.societyId,
                        societyName: programArea.societyName
                    };
                    societies.push(society);
                    societiesDictionary[societyKey] = true;
                }
            });

            return societies;
        }

        function addAllSocieties(programAreas) {
            programAreas.forEach(function (programArea) {
                if (programArea.disciplineId === 0  ||   // "ABET Chooses" program area            
                    (programArea.disciplineName && programArea.disciplineName.toLowerCase().indexOf('general criteria') >= 0)) {     // Is "General Criteria") 
                    // Give this program area its own copy of societiesList so it may or may not add "ABET Chooses"
                    programArea.societies = angular.copy(model.societiesList);
                }
            });
        }

        function setSelectedSocieties(programAreas, disciplines) {
            // Set selected lead society for each program area based on default rules or
            // by society specified for this program area in the disciplines JSON node.
            programAreas.forEach(function (programArea) {
                if (programArea.societies.length === 1) {
                    // If there is only one society for program area, then that is the lead.
                    var thisSocietyId = (programArea.societies[0].societyId === undefined || programArea.societies[0].societyId === null) ? 0 : programArea.societies[0].societyId;
                    programArea.selectedSocietyId = thisSocietyId;
                } else {
                    // Add an entry for "ABET Chooses"
                    var abetChoosesSociety = {
                        societyId: 0,
                        societyName: " ABET Chooses" // Prepend a space to force this option to appear first in sorted lists
                    };
                    programArea.societies.push(abetChoosesSociety);
                    // Look up society previously selected for this program area.
                    var discipline = disciplines.find(function (item) { return item.disciplineId === programArea.disciplineId });
                    if (discipline === undefined || discipline === null) {
                        // Set "ABET Chooses" as the selected lead society.
                        programArea.selectedSocietyId = 0;
                    } else {
                        // Find previously selected society in this program area's list of lead societies.
                        var previousSociety = programArea.societies.find(function (item) { return item.societyId === discipline.societyId });
                        if (previousSociety === undefined) {
                            // Lead societies for this program area must have changed since program was first added.
                            // Previously selected society is no longer a valid choice; set to "ABET Chooses".
                            programArea.selectedSocietyId = 0;
                        } else {
                            // Set previously selected lead society as the currently selected society.
                            programArea.selectedSocietyId = discipline.societyId;

                        }
                    }
                }
            });
        }

        function convertToProgramAreaViewModels(programAreas) {
            var programAreaViewModels = [];

            angular.forEach(programAreas, function (programArea) {
                var selectedSociety = programArea.societies.find(function (item) { return item.societyId === programArea.selectedSocietyId; });
                // Ignore keyId, pevsRequired, disciplineCount for now; PEVs is currently computed at the commission level.
                var programAreaViewModel = {
                    disciplineId: programArea.disciplineId,
                    disciplineName: programArea.disciplineName,
                    societyId: (selectedSociety === undefined || selectedSociety === null ? 0 : selectedSociety.societyId),
                    societyName: (selectedSociety === undefined || selectedSociety === null ? "ABET Chooses" : selectedSociety.societyName.trim())
                };

                programAreaViewModels.push(programAreaViewModel);
            });

            return programAreaViewModels;
        }

        function replaceData() {
            var sourceProgram = null;
            var isReplacingOnlineData = model.isReplacingOnlineData;
            var isReplacingLocationData = model.isReplacingLocationData;
            var isReplacingData = isReplacingOnlineData || isReplacingLocationData || false;

            if (isReplacingData) {
                var currentCommissionId = model.commissionId;
                var allPrograms = getProgramByCommissionId(currentCommissionId);
                sourceProgram = model.selectedProgram;

                for (var i = 0; i < allPrograms.length; i++) {
                    var currentProgram = allPrograms[i];

                    if (isReplacingOnlineData) {
                        currentProgram.distanceLearningId = sourceProgram.distanceLearningId;
                        currentProgram.distanceLearningTypeName = sourceProgram.distanceLearningTypeName;
                    }

                    if (isReplacingLocationData) {
                        currentProgram.programCampusTypeId = sourceProgram.programCampusTypeId;
                        currentProgram.programCampusTypeName = sourceProgram.programCampusTypeName;
                        currentProgram.campuses = sourceProgram.campuses;
                    }
                }
            }
        }

        model.onCommissionSelected = function (program, selectedCommission) {
            readinessSvc.setCommission(program, selectedCommission);
            if (model.selectedProgram.readinessReview !== undefined && model.selectedProgram.readinessReview !== null) {
                var rrWarningMessage = "Changing commission will erase the readiness review for this program. You will also need to confirm the program name again.";
                alertSvc.addModalAlertWarning(rrWarningMessage);
            }
            model.commissionId = selectedCommission ? selectedCommission.ids : null;
            setUnverifiedProgramName(true);
            model.selectedProgram.disciplines = [];
            model.hasNoDisciplines = true;
            model.programAreas = [];
            loadAllProgramAreas();
        }

        model.showIntegratedOption = function (commissionIds, degreeLevel) {
            //if (!Array.isArray(commissionIds))
            //    commissionIds = [commissionIds];
            return (degreeLevel === 'M');
        };

        model.onDegreeLevelCodeChanged = function () {
            var degreeLevelCodeChanged = isDegreeLevelCodeChanged();
            var isActionable = (model.mode.readiness &&
                                degreeLevelCodeChanged &&
                                model.selectedProgram.readinessReview !== undefined && model.selectedProgram.readinessReview !== null);

            if (isActionable) {
                var rrWarningMessage = "Changing degree level on a Master's program will erase the readiness review for this program.";
                alertSvc.addModalAlertWarning(rrWarningMessage);
            }
        }

        model.onIsMasterDegreeIntegratedChanged = function () {
            var changed = isMasterDegreeIntegratedChanged();
            var isActionable = (model.mode.readiness &&
                changed &&
                model.selectedProgram.readinessReview !== undefined && model.selectedProgram.readinessReview !== null);

            if (isActionable) {
                var rrWarningMessage = "Changing Integrated Master's will erase the readiness review for this program.";
                alertSvc.addModalAlertWarning(rrWarningMessage);
            }
        }

        model.deleteChangeNameRequest = function () {
            model.selectedProgram.programName = program.nameChange.oldProgramName;
            model.selectedProgram.degreeCode = program.nameChange.oldDegreeCode;
            model.selectedProgram.disciplines = program.nameChange.oldDisciplines
            delete model.selectedProgram.nameChange;
            model.save();
        }

        function isDegreeLevelCodeChanged() {
            var result = model.originalDegreeLevelCode !== undefined &&
                         model.originalDegreeLevelCode !== model.selectedProgram.degreeLevelCode &&
                        (model.originalDegreeLevelCode === 'M' || model.selectedProgram.degreeLevelCode === 'M');

            return result;
        }

        function isMasterDegreeIntegratedChanged() {
            var result = (model.originalIsMasterDegreeIntegrated != model.selectedProgram.isMasterDegreeIntegrated);
            return result;
        }

        function verifyDegreeCode(doSave) {
            var degreeCodeChanged = !model.isChangeNameRequest && !model.isNewProgram &&
                                    model.previousDegreeCode !== model.selectedProgram.degreeCode &&
                                    model.selectedProgram.originalDegreeCode !== model.selectedProgram.degreeCode;
 
            if (degreeCodeChanged) {
                var rfeWarningMessage = "The original degree designation for this program was <strong><em>" + model.selectedProgram.originalDegreeCode + "</strong></em><br/>" +
                                        "You are about to set it to <strong><em>" + model.selectedProgram.degreeCode + "</strong></em><br /><br />" +
                                        "Do you want to proceed?";

                alertSvc.confirm(rfeWarningMessage, doSave);
                // Set focus to make editing easier in case user cancelled confrimation dialog.
                var element = document.getElementById('degreeCode');
                if (element) element.focus();
            } else {
                doSave();
            }
        }        
    };

    module.controller('rfrProgramEditCtrl', rfrProgramEditCtrl);

}(angular.module('rfr')));