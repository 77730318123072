(function (module) {
    var pevAppManagerAddNewProgramAreaCtrl = function ($scope, $uibModalInstance, pevDisciplineStatuses, helperSvc, alertSvc, pevAppSvc, currentUser, typeConstSvc, codeSvc) {
        var model = $scope;
        model.dataIsReady = false;
        model.title = 'Add New Program Area';
        model.programAreaOptions = [];
        model.selected = { programArea: null };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function(){
            //the society already exists, so add the disciplineId and new discipline.
            var pevAppDisciplineData = angular.copy(pevAppSvc.volunteerDisciplineData);
            //there should only be 1 society returned
            var society = helperSvc.getFilteredArray(pevAppDisciplineData.volunteerProgramAreas.societies, 'societyId', model.selected.programArea.societyId, true);

            society[0].disciplineIds.push(model.selected.programArea.disciplineId);
            
            var formattedProgramArea = {
                societyId: society[0].societyId,
                disciplineId: model.selected.programArea.disciplineId,
                disciplineName: model.selected.programArea.disciplineName,
                commissionId: model.selected.programArea.commissionId,
                commissionName: typeConstSvc.getCommissionAbbreviationById(model.selected.programArea.commissionId),
                volunteerDisciplineStatusId: pevDisciplineStatuses.PENDING,
                qualificationComment: ''
            }

            pevAppDisciplineData.volunteerProgramAreas.disciplines.push(formattedProgramArea);

            var application = angular.copy(pevAppSvc.data.application);
            application.applicationJson.volunteerDiscipline = pevAppDisciplineData.volunteerProgramAreas;

            pevAppSvc.update(application).then(onSuccess);

            function onSuccess() {
                alertSvc.addModalAlertSuccess("Program area succesfully updated.");
                $uibModalInstance.close();
            }
        }
              
        var activate = function () {
            model.pevAppManagerSocieties = pevAppSvc.getPevAppManagerSocieties();
            codeSvc.getProgramAreaSociety().then(function (data) {
                
                model.programAreaOptions = model.programAreaOptions.concat(data.value.filter(function (item) {
                    return helperSvc.arrayContainsByPropertyValue(model.pevAppManagerSocieties, 'organizationId', item.societyId);
                }));
                model.dataIsReady = true;
            });
            
        }();

        
    }
    module.controller('pevAppManagerAddNewProgramAreaCtrl', pevAppManagerAddNewProgramAreaCtrl);

}(angular.module('pevApp')));