(function (module) {

    var filter = function (helperSvc, reviewTypeIds) {
        return function (items, filters, teamMemberTypeIds) {
            var matches = [];
            var ALL = 'all';
            var EMPTY = '';
            var ASSIGNED = 'assigned';
            var UNASSIGNED = 'unassigned';
            var ASSIGNEDVISIT = 'assignedVisit';
            var UNASSIGNEDVISIT = 'unassignedVisit';
            var ASSIGNEDREPORT = 'assignedReport';
            var UNASSIGNEDREPORT = 'unassignedReport';
            var NULL = null;
            var LARGE_TEAM_PROGRAM_COUNT = 8;
            var expMin = null;
            var expMax = null;
            var useYearsFilter = false;
            var useSocietyFilter = false;
            var useAssignmentFilter = false;
            var useNonUSVisitFilter = false;
            var useLargeTeamVisitFilter = false;
            var useOnlineOnlyVisitFilter = false;
            var useExComFilter = false;
            var useConfirmedConductFilter = false;
            var useTravelAvailabilityFilter = false;
            var useVirtualAvailabilityFilter = false;

            var setMatchFalse = function () {
                match = false;
            };

            //determine which properties to filter against
            if (filters.society && filters.society !== ALL) {
                useSocietyFilter = true;
            }

            if (filters.assignment && filters.assignment !== ALL) {
                useAssignmentFilter = true;
            }

            if (filters.hasHadNonUsVisit) {
                useNonUSVisitFilter = true;
            }

            if (filters.hasHadLargeTeamVisit) {
                useLargeTeamVisitFilter = true;
            }

            if (filters.hasHadOnlineOnlyVisit) {
                useOnlineOnlyVisitFilter = true;
            }

            if (filters.exCom) {
                useExComFilter = true;
            }

            if (filters.acceptedCodeOfConduct) {
                useConfirmedConductFilter = true;
            }

            if (filters.range) {
                var expMin = filters.range.firstVal;
                var expMax = filters.range.secondVal;
                 
                if (Number.isInteger(expMin) || Number.isInteger(expMax)) {
                    useYearsFilter = true;

                    if (expMin && expMax && expMin > expMax) {
                        var oldExpMin = expMin;
                        expMin = expMax;
                        expMax = oldExpMin;
                    }
                }
            }
            //filter array based on specified properties
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var match = true;

                if (useYearsFilter) {                    
                    if ((Number.isInteger(expMin) && item.totalVisitExp < expMin) || (Number.isInteger(expMax) && item.totalVisitExp > expMax)) {
                        setMatchFalse();
                    }
                }

                if (useSocietyFilter) {
                    if (filters.society !== item.society) {
                        setMatchFalse();
                    }
                }



                if (useAssignmentFilter) {
                    //need to add type of assignment here to verify the assignment is for the specific type
                    if (item.assignedReviews === NULL || !helperSvc.arrayContainsByPropertyValue(item.assignedReviews, 'teamMemberTypeId', teamMemberTypeIds)) {
                        if (filters.assignment === ASSIGNED)
                            setMatchFalse();
                        if (filters.assignment === ASSIGNEDREPORT)
                            setMatchFalse();
                        if (filters.assignment === ASSIGNEDVISIT)
                            setMatchFalse();
                    }

                    if (item.assignedReviews !== NULL && helperSvc.arrayContainsByPropertyValue(item.assignedReviews, 'teamMemberTypeId', teamMemberTypeIds)) {
                        if(filters.assignment === UNASSIGNED)
                            setMatchFalse();
                        if (filters.assignment === ASSIGNEDREPORT && !helperSvc.arrayContainsByPropertyValue(item.assignedReviews, 'reviewTypeCode', reviewTypeIds.INTERIMREPORT))
                            setMatchFalse();
                        if (filters.assignment === ASSIGNEDVISIT && !helperSvc.arrayContainsByPropertyValue(item.assignedReviews, 'reviewTypeCode', [reviewTypeIds.GENERALREVIEW, reviewTypeIds.INITIALREVIEW, reviewTypeIds.INTERIMVISIT]))
                            setMatchFalse();
                        if (filters.assignment === UNASSIGNEDREPORT && helperSvc.arrayContainsByPropertyValue(item.assignedReviews, 'reviewTypeCode', reviewTypeIds.INTERIMREPORT))
                            setMatchFalse();
                        if (filters.assignment === UNASSIGNEDVISIT && helperSvc.arrayContainsByPropertyValue(item.assignedReviews, 'reviewTypeCode', [reviewTypeIds.GENERALREVIEW, reviewTypeIds.INITIALREVIEW, reviewTypeIds.INTERIMVISIT]))
                            setMatchFalse();
                    }
                }

                if (useNonUSVisitFilter) {
                    if (item.nonUsVisitExp <= 0) {
                        setMatchFalse();
                    }
                }

                if (useLargeTeamVisitFilter) {
                    if (item.largeTeamCnt < LARGE_TEAM_PROGRAM_COUNT) {
                        setMatchFalse();
                    }
                }

                if (useOnlineOnlyVisitFilter) {
                    if (item.onlineOnlyVisits <= 0) {
                        setMatchFalse();
                    }
                }

                if (useExComFilter) {
                    if (item.isExcom === false) {
                        setMatchFalse();
                    }
                }

                if (useConfirmedConductFilter) {
                    if (!item.isConfirmedTermsAgreement) {
                        setMatchFalse();
                    }
                }

                if (useOnlineOnlyVisitFilter) {
                    if (item.onlineOnlyVisits <= 0) {
                        setMatchFalse();
                    }
                }

                if (match) {
                    matches.push(item);
                }
            }

            return matches;
        };
    };

    module.filter('commissioners', filter);

}(angular.module('assignment')));

