(function (module) {
    var statementToolCtrl = function ($scope, $q, $state, $stateParams, $uibModal, $timeout, $filter, $window, helperSvc, alertSvc, statementTemplateSvc, statementStorageSvc,
        localStorage, offlineStatementSvc, statementStatusNames, statementFindingTypes, programReviewTypeIds, ckeditCommentsSvc, currentUser, commissionTypes, dueResponsePropNames,
        evaluatorReportSvc, statementStatuses, teamMemberTypeNames, deepObjectDifferenceSvc, statementDifferenceSvc, reviewTeamSvc, offlineSvc, dueResponseSvc, statementTypeNames,
        reviewTypeIds, userReviewSvc, findingStatusTypeIds, actionCodes, reviewTeamMemberSvc, oauth, programReviewActionCodes, statementTypeIds, statementCategories,
        statementBoilerPlate, dueResponseTypeIds, typeConstSvc, refreshSiteSvc) {

        var model = this;
        var selectedId = null;
        var isAdminOrAdjStatement;

        model.isOnline = function () {
            return !offlineSvc.isAppOffline();
        }

        model.statementId = parseInt($stateParams.statementId);
        model.statementReportName = 'Draft Statement Preview';
        model.data = {};
        model.actionCodes = actionCodes;
        model.stateParams = $stateParams;
        model.statementDetailComparator = statementStorageSvc.statementDetailComparator;
        model.findingComparator = statementStorageSvc.findingsComparator;
        model.isAdmin = oauth.isAdmin() || oauth.isAdjunct();

        //sets up the tab syncing for data
        $window.addEventListener("storage", onStorage);
        $window.addEventListener("beforeunload", onBeforeUnload);
        $window.addEventListener("unload", onUnload);

        $scope.$on("$destroy", function () {
            $window.removeEventListener("storage", onStorage);
            $window.removeEventListener("beforeunload", onBeforeUnload);
            $window.removeEventListener("unload", onUnload);
            // Handle events that normally  fire after $destroy
            onBeforeUnload();
            onUnload();
        });

        function onStorage(ev) {
            if (!model.data || !model.data.statement) return; // no data loaded yet, or statement data was cleared out by another tab
            if (ev.key && ev.newValue && (ev.key == statementStorageSvc.getStatementKey(model.statementId) || ev.key == evaluatorReportSvc.getEvaluatorReportKey(model.data.statement.reviewTeamId) || ev.key == dueResponseSvc.getDueResponseKey(model.data.statement.reviewTeamId))) {
                var noSync = true;
                activate(noSync);
            }
            if (ev.key && ev.key == "appOffline" && !ev.newValue) {
                // User went online from a different tab
                offlineStatementSvc.goToOnlineState();
            }
        }

        function onBeforeUnload(ev) {
            // Make sure any recent changes get saved
            model.save(true, true);
        }

        function onUnload(ev) {
            if (!model.data || !model.data.statement) return; 
            evaluatorReportSvc.broadcastIsSaving(model.data.statement.reviewTeamId, false);
        }

        model.isDataReady = false;
        model.disableActions = false;
        model.statementFindingTypes = statementFindingTypes;
        model.getProgramName = statementStorageSvc.getProgramName;
        model.teamMemberTypeNames = teamMemberTypeNames;
        model.programReviewTypeIds = programReviewTypeIds;
        model.getPEVRA = statementStorageSvc.getPEVRA;
        model.hasText = statementStorageSvc.sectionHasText;
        model.findingStatusTypeIds = angular.copy(findingStatusTypeIds);
        model.dueResponsePropNames = angular.copy(dueResponsePropNames);
        model.isNewProgram = statementStorageSvc.isNewProgram;
        model.isTerminationProgram = statementStorageSvc.isTerminationProgram;
        model.statementTypeIds = statementTypeIds;
        model.statementCategories = statementCategories;
        model.hasNoFindings = statementStorageSvc.hasNoFindings;
        model.isProgramInterimReview - statementStorageSvc.isProgramInterimReview;

        model.getTeamMemberTypeAbbrv = reviewTeamSvc.getTeamMemberTypeAbbrv;
        model.getTeamMemberTypeStyle = reviewTeamSvc.getTeamMemberTypeStyle;
        model.getTeamMemberTypeName = reviewTeamSvc.getTeamMemberTypeName;
        model.statementTypeNames = statementTypeNames;
        model.activeSectionName = null;
        model.generatedIntroductionIsEditable = false;

        model.isVisible = {
            comments: true,
            changes: false,
            changeMenu: false
        };

        model.accessibility = {
            magnify: false,
            contrast: false
        };

        model.formatDate = function (date) {
            var newDate = helperSvc.formatDate(date);
            return $filter('date')(newDate, "MM/dd/yyyy");
        }
        model.differenceTypes = deepObjectDifferenceSvc.differenceTypes;

        model.isGeneratedSection = function (statementFindingTypeId) {
            return statementFindingTypeId == model.statementFindingTypes.INSTITUTIONAFTERVISIT || statementFindingTypeId == model.statementFindingTypes.INSTITUTIONINTRODUCTION || statementFindingTypeId == model.statementFindingTypes.REVIEWTEAM;
        };

        model.isGeneratedIntroduction = function (statementFindingTypeId) {
            return statementFindingTypeId == model.statementFindingTypes.INSTITUTIONINTRODUCTION;
        };

        model.isReadOnly = function () {
            if (isAdminOrAdjStatement) return false;
            if (oauth.isAdmin() && !oauth.isAdjunct() && (model.data.statement && model.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN && (model.data.statement.statementStatusId === statementStatuses.ADMINPREPFORSEND || model.data.statement.statementStatusId === statementStatuses.FINALSTATEMENTCOMPLETE))) {
                return false;
            } else if ((model.data.statement && model.data.statement.submittedTimestamp !== null) ||
                (model.data.statement.teamMemberTypeId !== model.data.currentReviewTeam.teamMemberTypeId)) {
                return true;
            }
            return false;
        };

        model.isEditorReadOnly = function (findingType) {
            if (model.isGeneratedIntroduction(findingType.statementFindingTypeId)) {
                return !model.generatedIntroductionIsEditable && model.isVisible.comments;
            }

            return (model.readOnly && model.isVisible.comments) ||
                ((!model.isDraftStatement && !model.isFinalOrPostStatementEditable) &&
                    (model.statementFindingTypes.PROGRAMSUMMARY !== findingType.statementFindingTypeId));
        };

        model.isSecondaryEditorReadOnly = function (responseType, programId, isEditableText) {
            return model.readOnly && model.isVisible.comments || model.dueResponseReadOnly(responseType, programId, isEditableText);
        };

        model.isInterimEditorReadOnly = function () {
            return (model.readOnly && model.isVisible.comments) || (!model.isDraftStatement && !model.isFinalOrPostStatementEditable);
        };

        model.isEditorVisible = function (statementFindingTypeId) {
            var isGeneratedNonIntro = model.isGeneratedSection(statementFindingTypeId) && !model.isGeneratedIntroduction(statementFindingTypeId);
            return !model.isGeneratedSection(statementFindingTypeId) || (model.isGeneratedIntroduction(statementFindingTypeId) && model.generatedIntroductionIsEditable) || (model.isGeneratedIntroduction(statementFindingTypeId) && model.isVisible.comments);
        };

        model.isGeneratedSectionEditBtnVisible = function (statementFindingTypeId) {
            return model.isGeneratedIntroduction(statementFindingTypeId) && !model.readOnly && !model.isVisible.changes && (model.isDraftStatement || model.isFinalOrPostStatementEditable);
        };

        model.openInstructions = function () {
            alertSvc.openPDFModal(
                '/Content/files/Draft-Final-Statement-Instructions.pdf',
                'Statement Editor Instructions'
            );
        };

        model.getResponseLabel = statementStorageSvc.getResponseLabel;

        model.sortResponse = function (response) {
            var responseObj = null;

            if (response) {
                responseObj = {
                    sevenDay: (response.sevenDay) ? response.sevenDay : null,
                    interimStatus: (response.interimStatus) ? response.interimStatus : null,
                    thirtyDay: (response.thirtyDay) ? response.thirtyDay : null,
                    postThirtyDay: (response.postThirtyDay) ? response.postThirtyDay : null
                };
            }

            return responseObj;
        }

        model.addResponseIsVisible = function (section, findingType, finding, propName) {
            var hasResponse = model.data.dueResponseStatus[propName] ? model.data.dueResponseStatus[propName].hasResponse : false;
            var hasResponseData = finding.criteria.response ? (finding.criteria.response[propName] ? true : false) : false;

            var is30DayResponse = model.data.statement.statementTypeId === statementTypeIds.FINAL && propName === dueResponsePropNames.THIRTYDAY && statementStorageSvc.isShortcoming(findingType.statementFindingTypeId);//final statements always have the response visible

            var sevenDayResponseAndNotDraftStatement = false;//check that seven day add cant be seen when the statment is not draft
            if (propName == dueResponsePropNames.SEVENDAY && (model.data.statement.statementTypeId !== statementTypeIds.DRAFT || (model.data.statement.statementTypeId === statementTypeIds.DRAFT && statementStorageSvc.isProgramInterimReview(section.programId)))) {
                sevenDayResponseAndNotDraftStatement = true;
            }

            var postNotShortcoming = false;
            if (propName == dueResponsePropNames.POSTTHIRTYDAY && !statementStorageSvc.isShortcoming(findingType.statementFindingTypeId)) {
                postNotShortcoming = true;
            }

            var postThirtyResponseAndNotPostThirtyStatement = false;//check that Post-30-Day add cant be seen when the statment is not a post-30-day, the statement order can be different than response order for this case
            if (propName == dueResponsePropNames.POSTTHIRTYDAY && model.data.statement.statementTypeId !== statementTypeIds.POSTTHIRTYDAY) {
                postThirtyResponseAndNotPostThirtyStatement = true;
            }

            return (is30DayResponse && !hasResponseData) || (hasResponse && !hasResponseData && model.additionalSectionsAreVisible(section.programId, findingType.statementFindingTypeId, propName) && !postNotShortcoming && !postThirtyResponseAndNotPostThirtyStatement && !sevenDayResponseAndNotDraftStatement);
        };

        model.addResponse = function (section, findingType, finding, responseType) {
            if (!finding.criteria.response) finding.criteria.response = {};
            if (!finding.criteria.response[responseType]) finding.criteria.response[responseType] = {};

            //if(responseType === dueResponsePropNames.THIRTYDAY && noDueResponseExistsForFinalStatement()){//add a flag to mark this 30 day response as NO RESPONSE
            //    finding.criteria.response[responseType].isNoResponse = true;
            //}

            model.save(true).then(function () {
                statementStorageSvc.setAfterReviewText(model.data.dueResponseStatus);
            });
        };

        model.deleteResponse = function (section, findingType, finding, responseType) {
            alertSvc.confirm("You are about to delete the " + model.getResponseLabel(responseType) + " information for this section. This process is irreversible. Do you want to proceed?", function () {
                //deleteResponseDetails();                                                  // Delete response details from Information Received After Visit
                finding.criteria = deleteResponseComments(finding.criteria, responseType);  // Delete response comments
                finding.criteria.response[responseType] = null;                             // Delete response from UI

                // Save statement without response
                model.save(true).then(function () {
                    // Now that all copies of statement data are refreshed, after review text can be set correctly
                    statementStorageSvc.setAfterReviewText(model.data.dueResponseStatus);
                    // Save statement with updated after review text 
                    model.save(true).then(function () {
                        alertSvc.addAlertSuccess(model.getResponseLabel(responseType) + " successfully removed.");
                    });
                });
            });

            function deleteResponseComments(criteria, responseType) {
                var text = criteria.response[responseType].text;
                var elements = text !== undefined && text !== null && text !== "" ? htmlToElementArray(text) : [];

                //get ids of all comments to be deleted
                var ids = [];
                for (var i = 0; i < elements.length; i++) {
                    ids = getMarkIds(elements[i]);
                }

                //filter comments to be deleted from existing comments array
                var comments = criteria.comments.filter(function (comment) {
                    return ids.indexOf(comment.markId) === -1;
                });
                criteria.comments = comments;

                return criteria;

                function getMarkIds(element) {
                    var ids = [];
                    for (var j = 0; j < element.childNodes.length; j++) {
                        if (element.childNodes[j].nodeName === 'MARK') {
                            ids.push(element.childNodes[j].getAttribute('data-id'));
                        } else {
                            ids = ids.concat(getMarkIds(element.childNodes[j]));
                        }
                    }
                    return ids;
                };
            }
        };

        function generateResponseDetail(section, findingType, finding) {
            return {
                programId: section.programId,
                programName: model.getProgramName(section.programId),
                statementFindingTypeName: findingType.statementFindingTypeName,
                criteriaName: finding.criteria.criteriaName
            };
        }

        model.editorOptions = {
            toolbar: ['heading', '|', 'undo', 'redo', 'comment'],
            removePlugins: ['Autoformat'], //disables ckeditor's autoformats i.e. typing "1. " creates a numbered list and "> " creates a blockquote
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                ]
            }
        };

        model.editorOptionsResponse = {
            toolbar: ['heading', '|', 'undo', 'redo'],
            removePlugins: ['Autoformat'], //disables ckeditor's autoformats i.e. typing "1. " creates a numbered list and "> " creates a blockquote
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                ]
            }
        };

        model.interimLastVisitTextIsVisible = function (programId, statementFindingTypeId, isSummary) {
            //var effectiveReadonly = model.readOnly || model.isVisible.changes;
            //var isVisible = isSummary ? effectiveReadonly : !effectiveReadonly;


            //hkc
            //return model.isInterimReport && model.additionalSectionsAreVisible(programId, statementFindingTypeId) && model.isEditorVisible(statementFindingTypeId); //&& isVisible;
            return statementStorageSvc.isProgramInterimReview(programId) && model.additionalSectionsAreVisible(programId, statementFindingTypeId) && model.isEditorVisible(statementFindingTypeId) && statementFindingTypeId !== statementFindingTypes.PROGRAMINTRODUCTION && statementFindingTypeId !== statementFindingTypes.PROGRAMSTRENGTH && statementFindingTypeId !== statementFindingTypes.PROGRAMOBSERVATION; //&& isVisible;
        };

        model.additionalSectionsAreVisible = function (programId, statementFindingTypeId, propName) {
            if (model.isGeneratedSection(statementFindingTypeId))
                return false;

            if (propName == model.dueResponsePropNames.SEVENDAY && model.data.response.sevenDay) {
                return true;
            } else {
                // 30/Post-30 Day or Interim Report
                var isNegativeFinding = statementFindingTypeId === statementFindingTypes.PROGRAMDEFICIENCY || statementFindingTypeId === statementFindingTypes.PROGRAMWEAKNESS || statementFindingTypeId === statementFindingTypes.PROGRAMCONCERN;
                return programId ? isNegativeFinding : false;
            }
        };

        model.showRecommendedEndDate = function (section) {
            return model.isTerminationProgram(section) && (model.data.currentReviewTeam.teamMemberTypeId === model.teamMemberTypeNames.TEAMCHAIR || model.data.currentReviewTeam.teamMemberTypeId === model.teamMemberTypeNames.REPORTTEAMCHAIR) && model.data.statement.statementTypeId === statementTypeIds.DRAFT;
        };

        model.getIsSectionPermanent = function (statementFindingTypeId) {
            if (statementFindingTypeId === model.statementFindingTypes.INSTITUTIONINTRODUCTION || statementFindingTypeId === model.statementFindingTypes.PROGRAMINTRODUCTION || statementFindingTypeId === model.statementFindingTypes.PROGRAMSUMMARY
                || statementFindingTypeId === model.statementFindingTypes.INSTITUTIONAFTERVISIT || statementFindingTypeId === model.statementFindingTypes.INSTITUTIONSUMMARY || statementFindingTypeId === model.statementFindingTypes.REVIEWTEAM) { return true }
            return false;
        }

        model.getCurrentStatementComments = function (comments) {
            if (oauth.isAdmin() || oauth.isAdjunct()) {
                // Admin/Adjuncts see all comments
                return comments;
            } else {
                // TC's and Editors only see current statement comments
                var oldestStatement = null;
                var newestStatement = null;
                var currentStatements = model.data.statementHistory.filter(function (statement) {
                    return statement.statementTypeName == model.data.statement.statementTypeName;
                });

                if (currentStatements.length > 1) {
                    currentStatements.forEach(function (statement, index) {
                        if (index == 0) {
                            oldestStatement = statement;
                            newestStatement = statement;
                        } else {
                            if (statement.insertedTimestamp < oldestStatement.insertedTimestamp) oldestStatement = statement;
                            if (statement.insertedTimestamp > newestStatement.insertedTimestamp) newestStatement = statement;
                        }
                    });
                }

                if (comments == undefined || comments.length === 0)
                    return null;

                return comments.filter(function (comment) {
                    if (comment.statementTypeName) {
                        return comment.statementTypeName == model.data.statement.statementTypeName;
                    } else {
                        var commentDate = new Date(comment.commentDate);
                        if (oldestStatement && newestStatement) {
                            var tempOldInserted = new Date(oldestStatement.insertedTimestamp);
                            var tempNewSubmitted = new Date(newestStatement.submittedTimestamp);
                            var tempNewInserted = new Date(newestStatement.insertedTimestamp);
                            var oldInserted = new Date(tempOldInserted.getFullYear(), tempOldInserted.getMonth(), tempOldInserted.getDate());
                            var newSubmitted = new Date(tempNewSubmitted.getFullYear(), tempNewSubmitted.getMonth(), tempNewSubmitted.getDate());
                            var newInserted = new Date(tempNewInserted.getFullYear(), tempNewInserted.getMonth(), tempNewInserted.getDate());

                            return (commentDate >= oldInserted)
                                && (newestStatement.submittedTimestamp
                                    ? commentDate <= newSubmitted
                                    : commentDate >= newInserted);
                        } else {
                            var tempInserted = new Date(model.data.statement.insertedTimestamp);
                            var inserted = new Date(tempInserted.getFullYear(), tempInserted.getMonth(), tempInserted.getDate());
                            return commentDate >= inserted;
                        }
                    }
                });
            }
        };

        // Status Bar

        model.statusBarPercentage = 0;
        model.statusBarPoints = function () {
            var points = [];
            for (property in statementStatusNames) {
                if (property !== "7" && property !== "8") { // exclude "Resend statement to institution" and "Final Statement Complete"
                    points.push({
                        isActive: false,
                        statusName: statementStatusNames[property],
                        statusId: property
                    });
                }
            }
            return points;
        }();

        model.getStatusBarPointName = function (point, getRole) {
            var statusId = parseInt(point.statusId);
            if (statusId === statementStatuses.WAITINGFORTCSUBMIT) {
                return getVal("TC", "TC Submitted");
            }
            if (statusId === statementStatuses.WAITINGFORED1SUBMIT) {
                return getVal("ED1", "Editor 1 Submitted");
            }
            if (statusId === statementStatuses.WAITINGFORED2SUBMIT) {
                return getVal("ED2", "Editor 2 Submitted");
            }
            if (statusId === statementStatuses.WAITINGFORADJSUBMIT) {
                return getVal("ADJ", "Adjunct Submitted");
            }
            if (statusId === statementStatuses.ADMINPREPFORSEND) {
                return getVal("ADM", "Admin Sent To Institution");
            }
            if (statusId === statementStatuses.SENTTOINSTITUTION) {
                return getVal("INS", "Institution Received");
            }

            function getVal(roleName, pointName) {
                return getRole ? roleName : (point.currentStatus === false ? pointName : point.statusName);
            }
        };

        var setStatusBarProgress = function () {
            var activeIndex = -1;
            for (var i = 0; i < model.statusBarPoints.length; i++) {
                if (model.statusBarPoints[i].statusName == model.data.statement.statementStatusName) {
                    model.statusBarPoints[i].isActive = true;
                    activeIndex = i;
                    break;
                }
            }
            if (activeIndex > -1) {
                // Set all points leading up to the current status as active
                for (var i = activeIndex - 1; i > -1; i--) {
                    model.statusBarPoints[i].isActive = true;
                    //set lastactive
                    if (i === activeIndex)
                        model.statusBarPoints[i].currentStatus = true;
                    else
                        model.statusBarPoints[i].currentStatus = false;
                }

                // Set progress bar length to active point
                model.statusBarPercentage = ((100 / model.statusBarPoints.length) * (activeIndex + 1)) - 1;
                model.statusBarPercentage += '%';
            }
        }; // End Status Bar

        var getParams = function (section, findingType, finding, isRecommendedAction) {
            return {
                program: getProgramSection(section),
                finding: findingType ? findingType.statementFindingTypeId : null,
                criteria: finding ? finding.criteria.criteriaId : null,
                ra: isRecommendedAction ? true : null,
                previousProgram: $stateParams.program
            };
        };

        var scrollTo = function (docElement, targetId) {
            var target;
            var interval = setInterval(function () {
                if (document.readyState === 'complete') {
                    target = document.getElementById(targetId);
                    clearInterval(interval);
                    if (docElement) doScroll();
                }
            }, 100);

            function doScroll() {
                if (offlineSvc.isAppOffline() && target) {
                    target.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    return;
                }

                var FIXED_OFFSET = 172;
                var isViewAll = !$stateParams.program;
                var isNewSection = $stateParams.program !== $stateParams.previousProgram;

                // FOR NEW SECTIONS (CASE1) -- if scrolled to top of screen (docElement.scrollTop == 0), set sectionScrollTop = 0
                // FOR NEW SECTIONS (CASE2) -- if NOT scrolled to top of of screen (docElement.scrollTop > 0), set sectionScrollTop = position of top of section
                // FOR OTHERS               -- sectionScrollTop will not be used, sectionScrollTop = 0 by default
                var sectionScrollTop = (isNewSection && docElement.scrollTop > 0) ? (document.getElementsByClassName('section-title-container')[0].offsetTop + FIXED_OFFSET) : 0;

                // FOR NEW SECTIONS -- start scrolling from top of section
                // FOR SAME SECTION -- start scrolling from current position
                // FOR VIEW ALL     -- start scrolling from top of page (start = 0)
                var start = isViewAll ? 0 : (isNewSection ? sectionScrollTop : docElement.scrollTop);

                // FOR VIEW ALL -- do not scroll (change = 0)
                // FOR OTHERS   -- scroll distance between start and target
                var change = target ? ((target.offsetTop - start) + FIXED_OFFSET) : 0;

                var currentTime = 0,
                    increment = 20,
                    duration = 1000;

                animateScroll();

                function animateScroll() {
                    currentTime += increment;
                    var val = Math.easeInOutQuad(currentTime, start, change, duration);
                    docElement.scrollTop = val;
                    if (currentTime < duration) {
                        setTimeout(animateScroll, increment);
                    }
                };
            }
        };

        model.loadActiveData = function () {
            if (!angular.equals(model.data, {})) {
                var selectedSection = statementStorageSvc.getSelectedSectionAndFinding(model.data.statement);
                var targetId = model.getGeneratedId(selectedSection.section, selectedSection.findingType, selectedSection.finding, $stateParams.ra)

                model.activeSectionName = selectedSection.section ? model.getProgramName(selectedSection.section.programId) : null;
                scrollTo(document.getElementsByClassName("main-content")[0], targetId);
            }
        };

        Math.easeInOutQuad = Math.easeInOutQuad || function (t, b, c, d) { //t = current time; b = start value; c = change in value; d = duration
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        model.getGeneratedId = function (section, findingType, finding, isRecommendedAction) {
            var id = 'finding-';

            id += section ? getProgramSection(section) : 's';
            id += findingType ? findingType.statementFindingTypeId : 't';
            id += finding ? (finding.criteria ? (finding.criteria.criteriaId ? finding.criteria.criteriaId : 'f') : 'f') : 'f';
            id += isRecommendedAction ? 'ra' : '';

            return id;
        };

        model.selectAndScrollTo = function (section, findingType, finding, isRecommendedAction) {
            $state.go(getStatementEditorState(), getParams(section, findingType, finding, isRecommendedAction), { notify: false }).then(function () {
                // fetch new data
                model.save(true, true);
                // set active section and scrollTo
                model.loadActiveData();
            });
        };

        model.showSelected = function (section) {
            if (!$stateParams.program) {
                return true;
            } else {
                return section.programId ? section.programId == $stateParams.program : (
                    statementStorageSvc.isInstitutionSection(section) ? $stateParams.program == 'Institution' : statementStorageSvc.isSummarySection(section) && $stateParams.program == 'Summary'
                );
            }
        };

        model.openInNewWindow = function (section, findingType, finding, isRecommendedAction) {
            var params = getParams(section, findingType, finding, isRecommendedAction);
            var state = getStatementEditorState();
            var url = $state.href(state, params);
            window.open(url, '_blank');
        };

        function getStatementEditorState() {
            return offlineSvc.isAppOffline() ? 'offlineStatementEditor' : 'statementTool';
        }

        model.openStatementPreview = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/viewStatementPreview.html',
                size: 'xl',
                controller: 'viewStatementPreviewCtrl',
                resolve: {
                    currentStatement: function () { return model.data.statement; }
                }
            });
        };

        model.goBack = function () {
            var stateName = currentUser.profile.userTasks.indexOf("admin") >= 0 && !oauth.isAdjunct() ? 'userReviewAdmin.detail' : 'userReview.detail';
            $state.go(stateName, {
                reviewTeamId: model.data.currentReviewTeam.reviewTeamId,
                view: 'statement'
            }).then(function () {
                document.body.classList.remove("editor-tool");
                document.documentElement.classList.remove('editor-tool-html');
            });
        };

        model.isActive = function (section) {
            if (section.programId) {
                return section.programId == $stateParams.program;
            } else if ($stateParams.program) {
                if (statementStorageSvc.isInstitutionSection(section)) {
                    return true;
                } else if (statementStorageSvc.isSummarySection(section)) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        model.isHighlighted = function (section, findingType, finding, isRecommendedAction) {
            var isInst = statementStorageSvc.isInstitutionSection(section);
            var isSum = statementStorageSvc.isSummarySection(section);

            var hasSection = section.programId ? (section.programId == $stateParams.program) : (isInst ? true : isSum);
            var hasFindingType = findingType ? $stateParams.finding == findingType.statementFindingTypeId : !$stateParams.finding;
            var hasFinding = finding ? $stateParams.criteria == finding.criteria.criteriaId : !$stateParams.criteria;
            var hasRA = isRecommendedAction ? $stateParams.ra : !$stateParams.ra;

            return hasSection && hasFindingType && hasFinding && hasRA;
        };

        model.toggle = function (propName) {
            model.isVisible[propName] = !model.isVisible[propName];

            if (model.isVisible[propName]) {
                if (propName == 'changeMenu') {
                    // Show change history (sidebar)
                    model.isVisible.comments = false;
                }

                if (propName == 'comments') {
                    // Show comments
                    model.isVisible.changeMenu = false;
                    if (model.isVisible.changes) model.showChanges();
                }
            } else {
                if (propName == 'changeMenu') {
                    // Hide change history (sidebar)
                    model.showChanges();
                }
            }
        };

        model.getStatementVersionDate = function (statement) {
            return statement.submittedTimestamp ? model.formatDate(statement.submittedTimestamp) : 'Current';
        }

        model.getStatementVersionTitle = function (statement) {
            return statement.statementTypeName + ' (' + model.getTeamMemberTypeName(statement.teamMemberTypeId) + ', ' + model.getStatementVersionDate(statement) + ')';
        };

        var statementEdit, statementDetailsEdit;

        model.showChanges = function (mode) {
            if (mode) {
                var versions = getSelectedVersions(mode);
                convertOLtoUL(versions.leftSide);
                convertOLtoUL(versions.rightSide);
                var diff = statementDifferenceSvc.getConvertedDiffedObject(versions.leftSide, versions.rightSide);
                model.leftSide = versions.leftSide;
                model.rightSide = versions.rightSide;
                model.differences = diff;
                if (model.differences.$difference === model.differenceTypes.SAME) {
                    var noChangesMessage = "<p>No changes were made from " + model.getStatementVersionTitle(model.leftSide) + " to " + model.getStatementVersionTitle(model.rightSide) + "</p>";
                    alertSvc.openModalAlert(noChangesMessage, "No Changes Found");
                    return;
                }
                statementEdit = model.data.statement;
                statementDetailsEdit = model.data.currentStatementDetailDtos;
                statementStorageSvc.clearAutosaving(diff);
                model.data.statement = diff;
                model.data.currentStatementDetailDtos = diff.statementDetailDtos;
                model.prevStatement = getPreviousStatement();
                model.generatedIntroductionIsEditable = false;
                model.isVisible['changes'] = true;
            } else {
                if (statementEdit && statementDetailsEdit) {
                    model.data.statement = statementEdit;
                    model.data.currentStatementDetailDtos = statementDetailsEdit;
                    model.prevStatement = getPreviousStatement();
                    statementEdit = null;
                    statementDetailsEdit = null;
                }
                model.isVisible['changes'] = false;
            }
        }

        function convertOLtoUL(statement) {
            // Convert OL in older after visit information sections to UL so that difference service doesn't detect false positives.
            // Only needed because after visit text is system generated and original method used OL where newer way uses UL;
            // this step won't be needed down the road when there are no legacy statements begun before after visit text was changed.
            if (!statement.statementDetailDtos) return;
            angular.forEach(statement.statementDetailDtos, function (statementDetailDto) {
                if (!statementDetailDto.statementJson) return;
                angular.forEach(statementDetailDto.statementJson, function (statementJson) {
                    if (statementJson.findings && statementJson.statementFindingTypeId === model.statementFindingTypes.INSTITUTIONAFTERVISIT) {
                        angular.forEach(statementJson.findings, function (finding) {
                            if (finding.criteria && finding.criteria.text) {
                                finding.criteria.text = finding.criteria.text.replace(/<ol>/g, "<ul>");
                                finding.criteria.text = finding.criteria.text.replace(/<\/ol>/g, "</ul>");
                            }
                        });
                    }
                });
            });
        }

        function getSelectedVersions(mode) {
            var versions = {};
            if (mode == 'manual') {
                var leftSideId = model.manualLeftSideId;
                var rightSideId = model.manualRightSideId;
                if (leftSideId > rightSideId) {
                    var tempId = leftSideId;
                    leftSideId = rightSideId;
                    rightSideId = tempId;
                }
                versions.leftSide = model.data.statementHistory.find(function (statement) {
                    return statement.statementId == leftSideId;
                });
                versions.rightSide = model.data.statementHistory.find(function (statement) {
                    return statement.statementId == rightSideId;
                });
            } else {
                var leftSideIndex = model.data.statementHistory.findIndex(function (statement) {
                    return statement.statementId == model.quickViewLeftSideId;
                });
                versions.leftSide = model.data.statementHistory[leftSideIndex];
                versions.rightSide = model.data.statementHistory[leftSideIndex - 1];
            }

            if (versions.rightSide.statementId === model.data.statement.statementId) {
                var statement = model.data.statement;
                statement.statementDetailDtos = model.data.currentStatementDetailDtos;
                versions.rightSide = statement;
            }

            return versions;
        }

        model.save = function (hideMsg, skipRebinding) {
            // do not save before we have data to save (e.g. on destruction of controller that was instantiated to load a stale offline bookmark
            if (!model.data.statement) return;
            // do not allow saving if highlighting is being shown for comments
            if (model.isHighlightMode) return;
            var statement = angular.copy(model.data.statement);
            statement.statementDetailDtos = model.data.currentStatementDetailDtos;

            return statementStorageSvc.save(statement, false, skipRebinding).then(
                function onSuccess() {
                    //need to reset the currentStatementDetailDtos
                    if (!hideMsg) alertSvc.addAlertSuccess("Statement successfully saved.");
                },
                function onFailure() {
                    if (!hideMsg) alertSvc.addAlertWarning('Statement could not be saved at this time.');
                }
            );
        };

        model.addFinding = function (section) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/addFinding.html',
                size: 'lg',
                controller: 'addFindingCtrl',
                backdrop: 'static',
                resolve: {
                    sections: function () { return section ? null : model.data.statement.statementDetailDtos; },
                    currentSection: function () { return section; },
                    programReviews: function () { return model.data.statementProgramReviews; }
                }
            });
        };

        model.changeFindingType = function (section, findingType, finding, responseType) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/changeFindingType.html',
                size: 'md',
                controller: 'changeFindingTypeCtrl',
                resolve: {
                    selectedFindingType: function () { return findingType },
                    currentSection: function () { return section; },
                    selectedFinding: function () { return finding },
                    responseType: function () { return responseType }
                }
            });
        };

        model.clearUpdatedFindingType = function (finding, responseType, section) {
            model.disableActions = true;
            section.recommendedAction = null;
            if (section.statementJson && section.statementJson.length && statementStorageSvc.data.statement && statementStorageSvc.data.statement.teamMemberTypeId >= teamMemberTypeNames.ADJUNCT)
                section.statementJson[0].isRecommendedActionEditableByAdjunct = true;
            finding.criteria.response[responseType].updatedFindingTypeId = null;
            finding.criteria.response[responseType].updatedFindingTypeName = null;
            finding.criteria.response[responseType].statusDesc = null;

            if (finding.criteria.response[responseType].findingStatusTypeId !== model.findingStatusTypeIds.REMAINS && finding.criteria.response[responseType].findingStatusTypeId !== model.findingStatusTypeIds.CHANGED) {
                finding.criteria.response[responseType].nextReviewText = null;
            }

            if (responseType === dueResponsePropNames.INTERIM) {//sets to not use the updated finding type for future statements
                finding.criteria.useChangedFindingTypeFromDraftIR = false;
            }
            if (responseType === dueResponsePropNames.THIRTYDAY) {//sets to not use the updated finding type for future statements
                finding.criteria.useChangedFindingTypeFromFinal = false;
            }


            var statement = angular.copy(model.data.statement);
            statement.statementDetailDtos = angular.copy(model.data.currentStatementDetailDtos);
            statementStorageSvc.save(statement).then(function () {
                model.disableActions = false;
            }, function () {
                model.disableActions = false;
            });
        };

        model.deleteFinding = function (section, findingType, finding) {
            // alertSvc.confirmDelete closes dialog before calling delete function, so no need to worry about double clicking on 'proceed'
            alertSvc.confirmDelete(findingType.statementFindingTypeName, deleteFunc);

            function deleteFunc() {
                var statement = angular.copy(model.data.statement);
                var sectionIndex = statementStorageSvc.getSectionIndex(statement, section) || 0;//null means instiution which is 0
                var findingTypeIndex = statementStorageSvc.getFindingIndex(section.statementJson, findingType.statementFindingTypeId);

                if (findingType.findings.length === 1) {
                    section.statementJson.splice(findingTypeIndex, 1);
                }
                else {
                    var findingIndex = statementStorageSvc.getFindingIndexFromFindingType(findingType, finding);
                    findingType.findings.splice(findingIndex, 1);
                }

                if (statementTemplateSvc.checkForCriteriaNeeded(findingType)) {
                    section.recommendedAction = null;
                    if (section.statementJson && section.statementJson.length && statementStorageSvc.data.statement && statementStorageSvc.data.statement.teamMemberTypeId >= teamMemberTypeNames.ADJUNCT)
                        section.statementJson[0].isRecommendedActionEditableByAdjunct = true;
                }

                statement.statementDetailDtos[sectionIndex] = section;
                statementStorageSvc.save(statement).then(function () {
                    var ckelements = document.getElementsByClassName('ck-body');
                    angular.element(ckelements).remove();

                    alertSvc.addAlertSuccess("Finding successfully deleted.");
                });
            }
        };

        model.getStatus = function (findingStatusTypeId, statementFindingTypeName, updatedFindingTypeName, isShortName, finding, responseType) { //TODO: TYPE FOR IR CHANGED NEEDS FIX HERE
            statementFindingTypeName = statementFindingTypeName.toLowerCase();
            // Convert to lowercase, clean up potential <p> tags inserted by statementDifferenceSvc
            updatedFindingTypeName = updatedFindingTypeName ? updatedFindingTypeName.toLowerCase().replace('<p>', '').replace('</p>', '').replace('<div>', '').replace('</div>', '') : updatedFindingTypeName;

            //CHECK FOR THE IR CHANGED IN DRAFT FIRST... need to do this for Post-30-Day as well
            if (finding.criteria.useChangedFindingTypeFromDraftIR === true && model.data.statement.statementTypeId > statementTypeIds.DRAFT && responseType !== model.dueResponsePropNames.INTERIM) {
                var statementFindingTypeName = finding.criteria.response[dueResponsePropNames.INTERIM].updatedFindingTypeName.toLowerCase();
                var currentDraftStatementFindingTypeId = finding.criteria.response[dueResponsePropNames.INTERIM].updatedFindingTypeId;
            }

            if (finding.criteria.useChangedFindingTypeFromFinal === true && model.data.statement.statementTypeId > statementTypeIds.FINAL && responseType === model.dueResponsePropNames.POSTTHIRTYDAY) {
                var statementFindingTypeName = finding.criteria.response[dueResponsePropNames.THIRTYDAY].updatedFindingTypeName.toLowerCase();
                var currentPostThirtyDayStatementFindingTypeId = finding.criteria.response[dueResponsePropNames.THIRTYDAY].updatedFindingTypeId;
            }

            if (findingStatusTypeId == findingStatusTypeIds.REMAINS) {
                return isShortName ? "Unresolved" : ("The " + statementFindingTypeName + " is unresolved.");
            } else if (findingStatusTypeId == findingStatusTypeIds.RESOLVED) {
                return "The " + statementFindingTypeName + " has been resolved.";
            } else if (findingStatusTypeId == findingStatusTypeIds.CHANGED) {
                return "The " + statementFindingTypeName + (updatedFindingTypeName ? " is now cited as a " + updatedFindingTypeName + "." : " has changed type.");
            }
        };

        model.getChangedStatus = function (findingStatusTypeId, statementFindingTypeName, updatedFindingTypeName, originalFindingStatusTypeId, originalStatementFindingTypeName, originalUpdatedFindingTypeName, isShortName, finding, responseType) {
            var status = model.getStatus(findingStatusTypeId, statementFindingTypeName, updatedFindingTypeName, isShortName, finding, responseType);
            // Want to create status text with un-diff'ed version of updatedFindingTypeName
            status.replace('<ins>', '').replace('</ins>', '').replace(/<del>.*<\/del>/g, '');
            var originalStatus = model.getStatus(originalFindingStatusTypeId, originalStatementFindingTypeName, originalUpdatedFindingTypeName, isShortName, finding, responseType);
            return '<del>' + originalStatus + '</del> &nbsp; <ins>' + status + '</ins>';
        }

        model.hideSubtitle = statementStorageSvc.hideFindingSubtitle;

        model.selectAction = function (section) {
            var statement = angular.copy(model.data.statement);
            var sectionIndex = statementStorageSvc.getSectionIndex(statement, section);

            if (model.data.viewingAll) {
                model.data.currentStatementDetailDtos[sectionIndex] = section;
            } else {
                model.data.currentStatementDetailDtos.recommendedAction = section.recommendedAction;
            }

        };

        model.isOwnComment = function (comment) {
            return (currentUser.profile.firstName + ' ' + currentUser.profile.lastName) === comment.commentVolunteerName;
        };

        model.isReplyBtnVisible = function (responseType, findingType) {
            // This func used to be isCommentEditable
            // I removed it from the comment edit btn since comments should always be editable by the person who left them
            // It's now used to restrict replies only to comments left on the current version of the statement
            if (model.data.statement.statementTypeId === statementTypeIds.DRAFT) {
                return true;
            } else if (model.data.statement.statementTypeId === statementTypeIds.FINAL) {
                if (responseType === model.dueResponsePropNames.THIRTYDAY || findingType.statementFindingTypeId === model.statementFindingTypes.PROGRAMSUMMARY)
                    return true;
            } else if (model.data.statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                if (responseType === model.dueResponsePropNames.POSTTHIRTYDAY)
                    return true;
            } else if (model.data.statement.statementTypeId === statementTypeIds.COMMISSIONEDITING) {
                // A null responseType means the comment was left on a commission editing statement
                return !responseType;
            }
            return false;
        };

        model.deleteComment = function (comment, parentComment, section, findingType, finding, isReply) {
            alertSvc.confirm('Are you sure you want to delete this comment? This procedure is irreversible.', deleteComment);

            function deleteComment() {
                if (isReply) {
                    //find parent comment index
                    var parentCommentIndex = finding.criteria.comments.findIndex(function (currentComment) {
                        return currentComment.markId === parentComment.markId;
                    });

                    //find child comment index
                    var childCommentIndex = finding.criteria.comments[parentCommentIndex].childComments.findIndex(function (currentComment) {
                        return JSON.stringify(currentComment) === JSON.stringify(comment);
                    });

                    //remove comment from parent
                    finding.criteria.comments[parentCommentIndex].childComments.splice(childCommentIndex, 1);
                } else {
                    //find comment index
                    var commentIndex = finding.criteria.comments.findIndex(function (currentComment) {
                        return currentComment.markId === comment.markId;
                    });

                    //remove comment
                    finding.criteria.comments.splice(commentIndex, 1);
                }

                //replace finding
                var selectedFindingIndex = findingType.findings.findIndex(function (thisFinding) {
                    return finding.key === thisFinding.key;
                })
                findingType.findings[selectedFindingIndex] = finding;

                //replace finding type with updated findings
                var findingTypeIndex = section.statementJson.findIndex(function (item) {
                    return item.statementFindingTypeId === findingType.statementFindingTypeId;
                });

                section.statementJson[findingTypeIndex] = findingType;

                //update statement
                var currentSectionIndex = model.data.statement.statementDetailDtos.findIndex(function (statementDetail) {
                    return section.statementDetailId && section.statementDetailId === statementDetail.statementDetailId || !section.statementDetailId && section.programId === statementDetail.programId;
                });
                model.data.statement.statementDetailDtos[currentSectionIndex] = section;

                // Clean up orphaned comments
                statementStorageSvc.removeOrphanedCommentsFromFinding(finding);

                statementStorageSvc.save(model.data.statement).then(function () {
                    alertSvc.addAlertSuccess("Comment successfully deleted.");
                }).catch(function (error) {
                    console.log('Error deleting comment', error);
                    alertSvc.addAlertWarning('Comment could not be deleted at this time.');
                });
            }
        };

        model.addReply = function (commentText, parentComment, section, findingType, finding) {
            var comment = {
                commentText: commentText,
                commentVolunteerId: currentUser.profile.volunteerId,
                commentVolunteerName: currentUser.profile.firstName + ' ' + currentUser.profile.lastName,
                teamMemberTypeId: model.data.currentReviewTeam.teamMemberTypeId,
                commentVolunteerRole: model.getTeamMemberTypeAbbrv(model.data.currentReviewTeam.teamMemberTypeId),
                commentDate: new Date(Date.now()).toLocaleDateString()
            };

            //find parent comment
            var parentCommentIndex = finding.criteria.comments.findIndex(function (currentComment) {
                return currentComment.markId === parentComment.markId;
            });

            //add new comment
            if (!finding.criteria.comments[parentCommentIndex].childComments)
                finding.criteria.comments[parentCommentIndex].childComments = [];

            finding.criteria.comments[parentCommentIndex].childComments.push(comment);

            //replace finding
            var selectedFindingIndex = findingType.findings.findIndex(function (thisFinding) {
                return finding.key === thisFinding.key;
            })
            findingType.findings[selectedFindingIndex] = finding;

            //replace finding type with updated findings
            var findingTypeIndex = section.statementJson.findIndex(function (item) {
                return item.statementFindingTypeId === findingType.statementFindingTypeId;
            });

            section.statementJson[findingTypeIndex] = findingType;

            //update statement
            var currentSectionIndex = model.data.statement.statementDetailDtos.findIndex(function (statementDetail) {
                return section.statementDetailId && section.statementDetailId === statementDetail.statementDetailId || !section.statementDetailId && section.programId === statementDetail.programId;
            });
            model.data.statement.statementDetailDtos[currentSectionIndex] = section;

            statementStorageSvc.save(model.data.statement).then(function () {
                alertSvc.addAlertSuccess("Reply successfully added.");
            }).catch(function (error) {
                console.log('Error replying to statement comment', error);
                alertSvc.addAlertWarning('Reply could not be added at this time.');
            });
        };

        model.isHighlightMode = false;
        model.highlightComment = function (finding, comment, isChild) {

            //var allMarks = document.getElementsByTagName('MARK');

            //finding.criteria.comments.forEach(function (item) {
            //    if (item !== comment) {
            //        item.isSelected = false;
            //    }

            //    for (var i = 0; i < allMarks.length; i++) {
            //        var mark = allMarks[i];
            //        if (mark.getAttribute('data-id') === comment.markId) {
            //            var selectedMark = angular.element(mark);

            //            if (comment.isSelected) {
            //                selectedMark.removeAttr('class');
            //                selectedMark.addClass('ck-comment-nostyle');
            //            }
            //        }
            //    }
            //});
            statementStorageSvc.removeHighlightsByFinding(finding, comment);

            comment.isSelected = comment.isSelected ? !comment.isSelected : true;
            model.isHighlightMode = comment.isSelected;

            if (!model.isHighlightMode) return;

            //for (var i = 0; i < allMarks.length; i++) {
            //    var mark = allMarks[i];
            //    if (mark.getAttribute('data-id') === comment.markId) {
            //        var selectedMark = angular.element(mark);

            //        if (comment.isSelected) {
            //            selectedMark.removeClass('ck-comment-nostyle');
            //            selectedMark.addClass('role');
            //            selectedMark.addClass(model.getTeamMemberTypeStyle(comment.teamMemberTypeId)); // model.getTeamMemberTypeStyle(comment.teamMemberTypeId)
            //        }
            //    }
            //}

            var markFound = statementStorageSvc.highlightSingleComment(comment);

            if (!markFound)
                comment.isOrphaned = true;

        };

        function unselectAllComments() {
            angular.forEach(model.data.statement.statementDetailDtos, function (statementDetail) {
                angular.forEach(statementDetail.statementJson, function (json) {
                    angular.forEach(json.findings, function (finding) {
                        angular.forEach(finding.criteria.comments, function (comment) {
                            comment.isSelected = false;
                        });
                    });
                });
            });
        };

        function setOrphanedComments() {
            angular.forEach(model.data.statement.statementDetailDtos, function (statementDetail) {
                angular.forEach(statementDetail.statementJson, function (json) {
                    angular.forEach(json.findings, function (finding) {
                        angular.forEach(finding.criteria.comments, function (comment) {

                            if (finding.criteria.text && finding.criteria.text.includes(comment.markId)) {
                                comment.isOrphaned = false;
                                var textToCheck = finding.criteria.text;
                                var updatedText = replaceDuplicatedMarks(textToCheck, comment)
                                finding.criteria.text = updatedText;
                            } else {
                                if (finding.criteria.lastVisitText && finding.criteria.lastVisitText.includes(comment.markId)) {
                                    comment.isOrphaned = false;
                                    var textToCheck = finding.criteria.lastVisitText;
                                    var updatedText = replaceDuplicatedMarks(textToCheck, comment)
                                    finding.criteria.lastVisitText = updatedText;
                                }
                                if (finding.criteria.response) {
                                    Object.keys(finding.criteria.response).forEach(key => {

                                        if (finding.criteria.response[key] != null && 'text' in finding.criteria.response[key]) {
                                            if (finding.criteria.response[key].text && finding.criteria.response[key].text.includes(comment.markId)) {
                                                comment.isOrphaned = false;
                                                var textToCheck = finding.criteria.response[key].text;
                                                var updatedText = replaceDuplicatedMarks(textToCheck, comment)
                                                finding.criteria.response[key].text = updatedText;
                                            }
                                        }
                                    });
                                }                                
                            }

                            if (comment.isOrphaned != false)
                                comment.isOrphaned = true;
                        });
                    });
                });
            });
        };

        function replaceDuplicatedMarks(textToCheck, comment) {
            //the very beginning of the markd id. i.e. 3int0s7zs
            var offset = textToCheck.indexOf(comment.markId);
            var brokenMark = textToCheck.indexOf(comment.markId, (offset + 10));
            if (brokenMark > 0) {
                var textBlockToChange = textToCheck.substring(offset + 12, brokenMark + 12); //12 is the number of characters that include the id at the end of the mark tag
                var firstIndex = textBlockToChange.indexOf('<mark ')
                if (firstIndex == textBlockToChange.lastIndexOf('<mark ')) {//check if there is a nested mark tag
                    var textToRemove = '</mark>';
                    var markToRemove = '<mark class="ck-comment-nostyle" data-id="' + comment.markId + '">'
                    
                    textBlockToChange = textBlockToChange.replace(textToRemove, '')
                    textBlockToChange = textBlockToChange.replace(markToRemove, '')
                }
                //cut out the bad part && replace with good
                textToCheck = textToCheck.substr(0, (offset + 12)) + textBlockToChange + textToCheck.substr((brokenMark + 12));
            }
            return textToCheck;
        }

        model.toggleCollapsedClass = function () {
            var elem = angular.element(document.querySelector('#collapsible-text'));

            if (elem.hasClass('collapsed')) {
                elem.removeClass('collapsed');
            } else {
                elem.addClass('collapsed');
            }
        };

        model.isTerminationProgram = function (section) {
            //get the correct program review based on the id 
            if (section.programId) {
                var programReview = helperSvc.getFirstMatch(model.data.statementProgramReviews, 'programId', section.programId);
                if (programReview && (programReview.programReviewTypeCode === model.programReviewTypeIds.TERMINATIONVISIT || programReview.programReviewTypeCode === model.programReviewTypeIds.TERMINATIONREPORT)) {
                    return true;
                }
            }
            return false;
        }

        function splitByTag(html) {
            //takes in html and splits by tag, returning array of html string
            return html.match(/<(.*?)>.*?<\/\1>/g);
        }

        function htmlToElementArray(html) {
            //takes in html string splits by tag, and returns array of element objects
            var elms = [];
            var split = splitByTag(html);
            for (var i = 0; i < split.length; i++) {
                elms.push(htmlToElement(split[i]));
            }
            return elms;
        }

        function htmlToElement(html) {
            var template = document.createElement('template');
            html = html.trim();
            template.innerHTML = html;
            // our boy Internet Explorer doesn't do template.content
            if (template.content) return template.content.firstChild;
            else return template.firstChild;
        }

        model.editRecommendedActions = function (section) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/statement/templates/modals/editRecommendedAction.html',
                size: 'md',
                controller: 'editRecommendedActionCtrl',
                resolve: {
                    currentSection: function () { return section; }
                }
            });
        };

        model.getPreviousRecommendedAction = function (section) {
            var prevSectionIndex = statementStorageSvc.getSectionIndex(model.prevStatement, section);
            if (prevStatement.statementDetailDtos[prevSectionIndex])
                return prevStatement.statementDetailDtos[prevSectionIndex].recommendedAction;
        };

        model.interimResponseNotSelected = function (section) {
            if (statementStorageSvc.isProgramInterimReview(section.programId)) {
                var interimShortcomingsExist = false;
                for (var i = 0; i < section.statementJson.length; i++) {
                    var findingType = section.statementJson[i];
                    if (statementStorageSvc.isShortcoming(findingType.statementFindingTypeId)) {
                        for (var j = 0; j < findingType.findings.length; j++) {
                            var finding = findingType.findings[j];
                            if (finding.criteria && finding.criteria.response && finding.criteria.response.interimStatus) {
                                if (finding.criteria.response.interimStatus.findingStatusTypeId !== null) return false;
                                interimShortcomingsExist = true;
                            }
                        }
                    }
                }
                if (!interimShortcomingsExist) return false;
            } else {
                return false;
            }
            return true;
        }

        model.addNextReviewTextBox = function (response, isDelete) {
            if (isDelete) {
                alertSvc.confirmDelete("Notes for Next Review", saveNextReview);
            } else {
                response.nextReviewText = response.nextReviewText ? response.nextReviewText : 'Click to add text...'
                saveNextReview();
            }

            function saveNextReview() {

                if (isDelete)
                    response.nextReviewText = null;
                var statement = angular.copy(model.data.statement);
                statement.statementDetailDtos = angular.copy(model.data.currentStatementDetailDtos);
                statementStorageSvc.save(statement).then(function () {
                    model.disableActions = false;
                }, function () {
                    model.disableActions = false;
                })
            }
        };

        function getPreviousStatement() {
            if (model.data.statementHistory && model.data.statementHistory.length > 1) {
                //get index of the current statement... could be not the [0] statemetn for readonly's
                var statementIndex = model.data.statementHistory.map(function (el) { return el.statementId; }).indexOf(model.data.statement.statementId);
                if (statementIndex !== model.data.statementHistory.length - 1)//if its the earliest statement none before it
                {
                    return prevStatement = model.data.statementHistory[statementIndex + 1];
                }
            }
            return null;
        }

        var autosaving = false;

        model.autoSave = function (finding) {
            if (autosaving) $timeout.cancel(autosaving);

            autosaving = $timeout(function () {
                autosaving = null;
                finding.saveComplete = false;
                finding.threeSecondsElapsed = false;//this variable is constantly changing but the most recent agreed upon time is 2 seconds
                var statement = angular.copy(model.data.statement);
                statement.statementDetailDtos = angular.copy(model.data.currentStatementDetailDtos);
                statementStorageSvc.save(statement, true).then(function (fg) {
                    return function () {
                        if (fg.threeSecondsElapsed) {
                            fg.isAutosaving = false;
                        } else {
                            fg.saveComplete = true;
                        }
                    }
                }(finding));

                $timeout(function (fg) {
                    return function () {
                        fg.threeSecondsElapsed = true;
                        if (fg.saveComplete) {
                            fg.isAutosaving = false;
                        }
                    }
                }(finding), 2000);

                finding.isAutosaving = true;

            }, 2000);
        };

        function getProgramSection(section) {
            var program = null;
            if (section && section.programId) {
                program = section.programId;
            } else if (section && section.statementCategoryId && section.statementCategoryId === statementCategories.INSTITUTION) {
                program = 'Institution';
            } else if (section && section.statementCategoryId && section.statementCategoryId === statementCategories.SUMMARY) {
                program = 'Summary';
            }

            return program;
        }

        function getProgramList() {
            const programNames = model.data.statementProgramReviews
                .filter(programReview => !(programReview.programReviewTypeCode === model.programReviewTypeIds.TERMINATIONREPORT ||
                    programReview.programReviewTypeCode === model.programReviewTypeIds.TERMINATIONVISIT ||
                    programReview.actionCode === programReviewActionCodes.WITHDRAWN))
                .map(programReview => programReview.programDto.programDetailDto.programName + " (" + programReview.programDto.programDetailDto.degreeCode + ")");

            const str =
                (programNames.length <= 2 ?
                    programNames.join(" and ") :
                    programNames.slice(0, -1).join(", ") + ", and " + programNames.slice(-1))
                + " program" + (programNames.length > 1 ? "s" : "");

            return str;
        }

        model.dueResponseReadOnly = function (responseType, programId, isEditableText) {//TODO: clean up //NEED TO FIX SO THAT IV CAN HAVE 7 day due responses...
            if (isAdminOrAdjStatement) return false;
            if (model.data.statement.statementTypeId === statementTypeIds.DRAFT && !model.isInterimReport) {
                if (responseType !== model.dueResponsePropNames.SEVENDAY &&
                    !((model.isInterimVisit || statementStorageSvc.isProgramInterimReview(programId)) && responseType === model.dueResponsePropNames.INTERIM)
                ) {
                    return true;
                }
                return false;
            }
            if (model.data.statement.statementTypeId === statementTypeIds.DRAFT && (model.isInterimReport || model.isInterimVisit)) {
                if (model.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMVISIT) {
                    if (statementStorageSvc.isProgramInterimReview(programId) && responseType !== model.dueResponsePropNames.INTERIM) {
                        return true;
                    }
                    else {
                        return false
                    }
                }
                if (responseType !== model.dueResponsePropNames.INTERIM) {
                    return true;
                }
                return false;
            }
            if (model.data.statement.statementTypeId === statementTypeIds.FINAL) {
                if (responseType !== model.dueResponsePropNames.THIRTYDAY) {
                    return true;
                }
                return false;
            }
            if (model.data.statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                if (responseType !== model.dueResponsePropNames.POSTTHIRTYDAY) {
                    if (responseType === model.dueResponsePropNames.THIRTYDAY && isEditableText) {//allows for 30 day due response text to be edited
                        return false;
                    }
                    return true;
                }
                return false;
            }
            if (model.data.statement.statementTypeId === statementTypeIds.COMMISSIONEDITING) {//need to check here for the last kind of response type... 30 or post 
                var lastResponseTypePropName = model.data.response.postThirtyDay ? model.dueResponsePropNames.POSTTHIRTYDAY : model.data.response.thirtyDay ? model.dueResponsePropNames.THIRTYDAY : null;

                if (lastResponseTypePropName === responseType) {
                    return false;
                }
                return true;
            }
            return false;
        }

        model.isRecommendedActionEditableByAdjunct = function (section) {
            return section.statementJson && section.statementJson.some(findingType => findingType.isRecommendedActionEditableByAdjunct);
        }

        function responseHasData(propName) {

            if (model.data.dueResponseStatus[propName].hasNotResponded === true || model.data.dueResponseStatus[propName].hasResponse === true || model.data.dueResponseStatus[propName].hasNoResponse === true)
                return true;
            return false;
        }

        function generateJointReviewText(jointOptions, commissionId) {
            var jointData = [];
            var text = "<p>";
            var multiplePrograms = false;

            jointOptions.forEach(function (joint, i) {
                // Do not include withdrawn/cancelled programs (which ideally would cause joint reviews to be updated when they are withdrawn)
                if (joint.reviewJointOptionProgramDtos.some(jointOptionProgram =>
                    jointOptionProgram.commissionId === commissionId && model.data.statementProgramReviews.some(programReview =>
                        programReview.programId === jointOptionProgram.programId && [programReviewActionCodes.CANCELLED, programReviewActionCodes.WITHDRAWN].includes(programReview.actionCode)))) {
                    return;
                }

                // Do not mention joint review unless current statement commission is included
                if (!joint.reviewJointOptionProgramDtos.some(function (program) {
                    return program.commissionId == commissionId;
                })) {
                    return;
                }

                var tempData = {
                    commissions: [],
                    programNames: []
                };

                // Generate single program data
                joint.reviewJointOptionProgramDtos.forEach(function (program) {
                    if (tempData.programNames.length < 1) {
                        let programName = program.programName;

                        // Find program review on current review that is being jointly reviewed with this program so we can append degree code when program name is ambiguous
                        const programReview = model.data.statementProgramReviews.find(function (programReview) {
                            return programReview.programDto.programDetailDto.programName === program.programName && programReview.programDto.programDetailDto.degreeCode === program.degreeCode;
                        });

                        if (programReview && model.data.statementProgramReviews.some(function (pr) {
                            return pr.programDto.programDetailDto.programName === programName &&
                                pr.programDto.programDetailDto.degreeCode !== programReview.programDto.programDetailDto.degreeCode;
                        })) {
                            programName += ` (${programReview.programDto.programDetailDto.degreeCode})`;
                        }

                        tempData.programNames.push(programName);
                    }
                    tempData.commissions.push(typeConstSvc.getCommissionFullName(program.commissionId));
                });

                // If the commissions for the current program are the same as the commissions for the previous program, append to previous program
                if (jointData.length > 0) {
                    var matchFound = false;
                    for (var j = 0; j < jointData.length; j++) {
                        if (jointData[j].commissions.includes(tempData.commissions[0]) && jointData[j].commissions.includes(tempData.commissions[1])) {
                            jointData[j].programNames.push(tempData.programNames[0]);
                            matchFound = true;
                            break;
                        }
                    }
                    if (!matchFound) jointData.push(tempData);
                } else {
                    jointData.push(tempData);
                }
            });

            if (jointData.length > 0) {
                jointData.forEach(function (data) {
                    var programStr = "";
                    if (data.programNames.length > 2) {
                        var last = data.programNames.pop();
                        programStr = data.programNames.join(", ") + ", and " + last + " programs were";
                        multiplePrograms = true;
                    } else if (data.programNames.length == 2) {
                        programStr = data.programNames.join(" and ") + " programs were";
                        multiplePrograms = true;
                    } else {
                        programStr = data.programNames[0] + " program was";
                    }
                    var commissionStr = data.commissions.length > 2 ?
                        data.commissions.slice(0, data.commissions.length - 2).join(", ") + " and the " + data.commissions[data.commissions.length - 1] :
                        data.commissions.join(" and the ");
                    text += "The " + programStr + " jointly reviewed by the " + commissionStr + ". "
                });
                text += "Because each Commission has unique General and Program Criteria against which the joint program" + (multiplePrograms ? "s were" : " was") + " examined for compliance, each Commission will report the results of the review in separate Draft and Final Statements to the institution and will independently determine " + (multiplePrograms ? "actions" : "an action") + ". Normally, the more severe of the actions voted, even if by only one of the commissions involved, will be the action adopted by both commissions for the program" + (multiplePrograms ? "s" : "") + ".</p>"
            } else {
                text = "";
            }
            return text;
        }

        //for final statement and Post-30-Day statements
        function isFinalOrPostStatementEditable() {
            //abet staff and adjunct can edit
            if ((oauth.isAdmin() || oauth.isAdjunct()) && (model.data.statement.statementTypeId === statementTypeIds.FINAL || model.data.statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY || model.data.statement.statementTypeId === statementTypeIds.COMMISSIONEDITING))
                return true;

            return false;
        }

        function orderByCategoryId(statement) {
            statement.statementDetailDtos = statement.statementDetailDtos.sort(function (a, b) {
                return a.statementCategoryId - b.statementCategoryId;
            });
        }

        activate();

        function activate(noSync) {
            if (model.isOnline() && !noSync) {
                statementStorageSvc.syncOfflineData().catch(function (error) {
                    console.log('Sync Offline Data Error', error);
                    alertSvc.addAlertDanger("Offline statement data could not be synchronized.");
                }).finally(function () {
                    doActivation();
                });
            } else {
                doActivation();
            }

            function doActivation() {
                statementStorageSvc.getAllStatementToolData(model.statementId).then(function () {
                    model.data = statementStorageSvc.data;
                    isAdminOrAdjStatement = ((model.data.statement.statementStatusId === statementStatuses.ADMINPREPFORSEND) && (model.isAdmin && !oauth.isAdjunct())) || ((model.data.statement.statementStatusId === statementStatuses.WAITINGFORADJSUBMIT) && oauth.isAdjunct());

                    model.isInterimReport = statementStorageSvc.isInterimReport();
                    model.isInterimVisit = statementStorageSvc.isInterimVisit();

                    if (model.isInterimVisit) {
                        model.nonIVProgramsExist = statementStorageSvc.nonIVProgramsExist();
                    }

                    // Offline with no statement means user got here through stale bookmark to a submitted statement
                    if (model.data.statement == null && offlineSvc.isAppOffline()) {
                        $state.go("offlineStatementListing");
                        return;
                    }

                    //this sets up the use of finding types for adding and editing.  
                    statementTemplateSvc.setStatementFindingTypeTemplates();
                    model.title = statementStorageSvc.data.currentReviewTeam.organizationName;
                    ckeditCommentsSvc.setStatementData(model.data.statement);
                    model.prevStatement = getPreviousStatement();

                    dueResponseSvc.getDueResponsesByReviewTeamId(model.data.currentReviewTeam.reviewTeamId).then(function () {
                        statementStorageSvc.setDueResponseData();
                    });

                    if (model.data.currentReviewTeam.commissionId === 2) {
                        model.reviewTeamText = statementStorageSvc.getReviewTeamSectionText(model.data.statementProgramReviews)
                        // Clean up redundant review team sections--CAN REMOVE THIS STEP AFTER 2022 CYCLE
                        const introduction = statementStorageSvc.data.statement.statementDetailDtos.find(sd => sd.statementCategoryId === statementCategories.INSTITUTION);
                        const reviewTeamOneIndex = introduction && introduction.statementJson.findIndex(findingType => findingType.statementFindingTypeId === statementFindingTypes.REVIEWTEAM) || -1;
                        const reviewTeamTwoIndex = reviewTeamOneIndex > -1 && introduction.statementJson.findIndex((findingType, index) => index > reviewTeamOneIndex && findingType.statementFindingTypeId === statementFindingTypes.REVIEWTEAM) || -1;
                        if (introduction && reviewTeamTwoIndex > -1)
                            introduction.statementJson.splice(reviewTeamTwoIndex, 1);
                    }

                    setStatusBarProgress();
                    unselectAllComments();
                    model.readOnly = model.isReadOnly();
                    model.isFinalOrPostStatementEditable = isFinalOrPostStatementEditable();
                    model.isDraftStatement = model.data.statement.statementTypeId === statementTypeIds.DRAFT;

                    userReviewSvc.getAllDataForCurrentReview(model.data.currentReviewTeam.reviewTeamId).then(function () {
                        // Boiler plate
                        model.introBoilerplateText = "<p>The " + commissionTypes[model.data.currentReviewTeam.commissionId - 1].name + " (" + model.data.currentReviewTeam.commissionName + ") of ABET has evaluated the " + getProgramList() + " at " + model.title + ".</p>";
                        model.introBoilerplateText2 = generateJointReviewText(userReviewSvc.data.currentReview.reviewJointOptionDtos, model.data.currentReviewTeam.commissionId) + (model.data.statement.statementTypeId === statementTypeIds.DRAFT ? statementBoilerPlate.BODY_DRAFT : statementBoilerPlate.BODY);
                        model.introBoilerplateText2 += statementBoilerPlate.UL;

                        statementStorageSvc.setBoilerplateText(model.introBoilerplateText, model.introBoilerplateText2);

                        //save when loading the page in case users close the windows - to reflect system regenerated page template
                        model.save(true, true);

                        setOrphanedComments();

                        model.isDataReady = !refreshSiteSvc.refresh(); // Clear ui-router cache after so many visits...

                        
                    });
                });
            }
        }
    };

    module.controller('statementToolCtrl', statementToolCtrl);

}(angular.module('statement')));