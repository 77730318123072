(function (module) {

    var assignPEVCsMentorCtrl = function ($scope, alertSvc, commissionAbbreviations, helperSvc, mentor, searchOptions, $uibModalInstance, trainingSvc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Assign PEVCs';
        model.searchOptions = { trainingYear: new Date(trainingSvc.data.trainingSessions[0].startDate).getFullYear(), societyId: mentor.societyId };
        model.currentMentor = mentor;
        model.commissionNames = commissionAbbreviations;
        model.selectedMentees = [];
        model.firstSave = true;

        var pevcsData = {
            dataHolder: model,
            dataLocationName: 'pevcs',
            svcCallback: trainingSvc.getPEVCTrainingSearch,
            svcCallbackArguments: [model.searchOptions],
            odataResource: true
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.firstSave) {
                model.firstSave = false;
                trainingSvc.addMentees(model.currentMentor.trainingMentorId, model.selectedMentees).then(function () {
                    trainingSvc.getTrainingMentors(searchOptions);
                    alertSvc.addAlertSuccess("Mentees succesfully assigned.");
                    //send message to mentor 
                    $uibModalInstance.close();
                });
            }
        }

        model.deleteMentee = function(pevc){        
            alertSvc.confirmDelete(model.currentMentor.personDto.firstName + " " + model.currentMentor.personDto.lastName + " (Mentor)", deleteMentor);


            function deleteMentor(){
                trainingSvc.deleteMentee(pevc.trainingMenteeId).then(function(){
                    var dataArray = [];        
                    dataArray.push(pevcsData);
                    var trainingMentors = {
                        dataHolder: model,
                        dataLocationName: 'mentorList',
                        svcCallback: trainingSvc.getTrainingMentors,
                        svcCallbackArguments: [model.searchOptions],
                        odataResource: true
                    }
                    dataArray.push(trainingMentors);
                    helperSvc.getData(dataArray);
                    alertSvc.addModalAlertSuccess("Mentee succesfully deleted.");
                });
            }
        }
 
        model.toggleMentee = function(mentee){          
            var indexOfSelected = model.selectedMentees.map(function(x) {return x; }).indexOf(mentee.personId);
            if(indexOfSelected > -1)
                model.selectedMentees.splice(indexOfSelected, 1);
            else
                model.selectedMentees.push(mentee.personId);
        }
        
        var activate = function () {         
            var dataArray = [];        
            dataArray.push(pevcsData);

            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;

                angular.forEach(model.data.pevcs, function(pevc){
                    pevc.isIncluded = pevc.trainingMentorId !== null;
                });
                model.dataIsReady = true;   
            })
        }();
    };

    module.controller('assignPEVCsMentorCtrl', assignPEVCsMentorCtrl);

}(angular.module('training')));