(function (module) {

    var programReviewEditCtrl = function ($q, review, parent, $scope, $uibModalInstance, helperSvc, programReviewSvc, eventSvc, reviewTeamSvc, alertSvc, codeSvc, reviewTeamProcessTrackingSvc) {
        var model = $scope;
        model.title = 'Edit Program Review';
        model.isProgram = false;
        model.isReview = false;
        model.typesReady = false;

        activate();

        if (parent.parent.selectedProgram) {
            model.isProgram = true;

            var commissionId = parent.parent.selectedProgram.commissionId;
            model.reviewOptions = reviewTeamSvc.getTeamReviewsByCommission(parent.reviewOptions, commissionId);
        }

        if (review) {
            model.isReview = true;
            model.programName = review.programDto.programDetailDto.programName;
        }

        if (review) {
            var useUndefined = true;
            model.currentReview = angular.copy(review);
            var currentReview = model.currentReview;

            currentReview.accreditationStartDate = helperSvc.formatDate(currentReview.accreditationStartDate, useUndefined);
            currentReview.accreditationEndDate = helperSvc.formatDate(currentReview.accreditationEndDate, useUndefined);
            currentReview.distanceLearningId = parseInt(currentReview.distanceLearningId);
        }
        else {
            model.currentReview = { programReviewId: 0, reviewTeamId: 0, programId: parent.parent.selectedProgram.programId, programReviewDisciplineDtos: [ { programReviewDisciplineId: 0, isDeleted: false } ] };
        }

        model.addRow = function () {
            var newProgramReviewDiscipline = { programReviewDisciplineId: 0, isDeleted: false };
            model.currentReview.programReviewDisciplineDtos.push(newProgramReviewDiscipline);
        };

        model.deleteRow = function (programReviewDiscipline) {
            programReviewDiscipline.isDeleted = true;
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            var currentReview = model.currentReview;
            var programReviewId = currentReview.programReviewId;

            //prevent empty programReviewDiscipline items from being sent to server
            //todovarner need to ask Hwan about user interface, if this condition isnt allowed (even though database allows for it) then update GUI to prevent this case and delete this code
            if (currentReview.programReviewDisciplineDtos && currentReview.programReviewDisciplineDtos.length > 0) {
                var filteredDisciplineAreas = new Array();

                for (var i = 0; i < currentReview.programReviewDisciplineDtos.length; i++) {
                    var programReviewDiscipline = currentReview.programReviewDisciplineDtos[i];

                    //only valid programReviewDiscipline items get copied over
                    if (!(programReviewDiscipline.programReviewDisciplineId == 0 && programReviewDiscipline.isDeleted == true)) {
                        filteredDisciplineAreas.push(programReviewDiscipline);
                    }
                }

                currentReview.programReviewDisciplineDtos = filteredDisciplineAreas;
            }

            if (programReviewId === 0) {
                programReviewSvc.create(currentReview).then(function () {
                    updateDataAndClose();
                    alertSvc.addAlertSuccess("Program review successfully created.");
                });
            }
            else if (programReviewId > 0) {
                programReviewSvc.updateOdata(currentReview).then(function () {
                    updateDataAndClose();
                    alertSvc.addAlertSuccess("Program review successfully updated.");
                });
            }
        };

        function updateDataAndClose() {
            if (model.isReview) {
                eventSvc.broadcast('refreshPrograms');
                reviewTeamProcessTrackingSvc.getReviewStatusesByReviewTeamIdOdata(model.currentReview.reviewTeamId);
        
            } else {
                eventSvc.broadcast('refreshSelectedProgramData');
            }
            $uibModalInstance.close();
        }

        function activate() {
            var memberSocieties = codeSvc.getMemberSocieties();
            var distanceLearning = codeSvc.getDistanceLearningTypes();
            var programReview = codeSvc.getProgramReviewTypes();
            var actions = codeSvc.getActions();
            var disciplines = codeSvc.getDisciplineTypes();

            //when all promises are resolved, process data
            $q.all([memberSocieties, distanceLearning, disciplines, programReview, actions]).then(function (data) {
                model.actionOptions = helperSvc.getResults(data.pop());
                model.programReviewTypeOptions = helperSvc.getResults(data.pop());
                model.disciplineTypeOptions = helperSvc.getResults(data.pop());
                model.distanceLearningTypeOptions = helperSvc.getResults(data.pop());
                model.memberSocietyOptions = helperSvc.getResults(data.pop());

                model.typesReady = true;
            });


        }
    };

    module.controller('programReviewEditCtrl', programReviewEditCtrl);

}(angular.module('program')));