(function (module) {
    var htmlTemplateSvc = function (odataSvc, amsConst, $http, $q) {
        var factory = {};
        var apiPath = '/webapi/odata/HtmlTemplate';
        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getHtmlTemplate = function(templateId, asOfDate){
            var oSvc = odataSvc.get();

            if (!asOfDate)
                asOfDate = new Date;

            var searchFilter = apiPath + '?$filter=(htmlTemplateId eq ' + templateId + ') and ' +
                '(startDate eq null or startDate le ' + asOfDate + ') and ' +
                '(endDate eq null or endDate ge ' + asOfDate + ')'

            return $http.get(searchFilter).then(onSuccess, onFailure);

            //This gives an error when sending date it cuts off last part of the date.
            //oSvc.getSource(searchFilter.toString(), null).odata()
            //    .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        return {
            getHtmlTemplate: factory.getHtmlTemplate
        };

    };
    module.factory('htmlTemplateSvc', htmlTemplateSvc);

})(angular.module('common'));