(function (module) {

    var groupsAndRolesSvc = function (odataSvc, helperSvc, $http, amsConst, $odata, trainingTypeIds, contactRoleTypes, oauth) {

        var factory = {};
        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.data = {
            userGroupsAndRoles: []
        }

        factory.getGroupsAndRolesSearch = function (organizationId, searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = "/GetOrganizationUserViewWithDesignationByOrg" + '(organizationId=' + organizationId + ',cycleYear=' + searchOptions.cycleYear + ")";
            var hasRoles = searchOptions.roles ? true : false;
            var hasDesignation = searchOptions.designation ? true : false;
            var hasTerm = searchOptions.term != null ? true : false;
            
            var hasPersonName = searchOptions.personName ? true : false;
            var hasAsofDate = searchOptions.asofDate ? true : false;
            var hasSearchOptions = hasRoles || hasDesignation || hasTerm || hasPersonName || hasAsofDate;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;
               
                if (hasRoles) {
                    predicates.push(new $odata.Predicate('roleId', '=', searchOptions.roles));
                }

                if (hasDesignation) {
                    predicates.push(new $odata.Predicate('designationId', '=', searchOptions.designation));
                }

                if (hasTerm)
                    predicates.push(new $odata.Predicate('positionTerm', '=', searchOptions.term));

                if (hasPersonName) {
                   
                    var fullNamePredicate = [];
                    fullNamePredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'firstName', searchOptions.personName)), '>', -1));
                    fullNamePredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'middleName', searchOptions.personName)), '>', -1));
                    fullNamePredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'lastName', searchOptions.personName)), '>', -1));
                    predicates.push($odata.Predicate.or(fullNamePredicate));
                    
                }

                if (hasAsofDate) {
                    var combinedCommissionPredicate1 = [];
                    combinedCommissionPredicate1.push(new $odata.Predicate('startDate', 'eq', null));
                    combinedCommissionPredicate1.push(new $odata.Predicate('startDate', '<=', new Date(searchOptions.asofDate)));
                    predicates.push($odata.Predicate.or(combinedCommissionPredicate1));

                    var combinedCommissionPredicate2 = [];
                    combinedCommissionPredicate2.push(new $odata.Predicate('endDate', 'eq', null));
                    combinedCommissionPredicate2.push(new $odata.Predicate('endDate', '>=', new Date(searchOptions.asofDate)));
                    predicates.push($odata.Predicate.or(combinedCommissionPredicate2));
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(apiPath).odata()
                    .filter(combinedPredicate)
                    .expand('designationViewModels')
                    .orderBy("lastName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

            } else {
                oSvc.getSource(apiPath).odata()
                    .expand('designationViewModels')
                    .orderBy("lastName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            if (!noStoredData) {
                resolveData(oSvc);
            }

            return oSvc.getDeferred().promise;
        }

        function resolveData(oSvc) {

            oSvc.getDeferred().promise.then(function (data) {
                factory.data.userGroupsAndRoles = data;
            });
        }

        return {
            data: factory.data,
            getGroupsAndRolesSearch: factory.getGroupsAndRolesSearch
           
        };
    };

    module.factory('groupsAndRolesSvc', groupsAndRolesSvc);

})(angular.module('groupsAndRoles'));