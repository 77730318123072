(function (module) {

    var reviewSearchCtrl = function ($scope, helperSvc, barSvc, typeConstSvc, codeSvc, userReviewAdminSvc) {
        var model = this;

        model.title = "Find a Review";
        model.resultsTitle = "Results";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.formatDate = helperSvc.formatDate;
        model.showSpinner = false;
        model.showSpinnerSearch = false;
        model.firstLoad = true;
        
        barSvc.listenForShow(function () { 
            if (model.firstLoad) {
                model.showSpinnerSearch = true;
            } else{
                model.showSpinner = true;
            } 
        });

        barSvc.listenForHide(function () {
            if (model.firstLoad) {
                model.showSpinnerSearch = false;
                model.firstLoad = false;
            } else {
                model.showSpinner = false; 
            }
        });

        model.years = getYearSpans();

        model.reset = function () {
            model.reviews = null;
            model.resultMsg = model.defaultSearchMsg;
            
            model.searchOptions = {
                reviewYear: helperSvc.getAcademicYear(),
                commissions: []
            };

            model.retrieve();
        };

        model.getReviewStatusName = function(reviewStatusId){
            return typeConstSvc.getReviewProcessStatusName(reviewStatusId);
        }


        model.getCommissionName = function (commissionId) {
            return typeConstSvc.getCommissionAbbreviationById(commissionId);
        };

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.reviews = null;
            model.resultMsg = null;
            barSvc.showSpinner();

            userReviewAdminSvc.advancedSearch(searchOpts).then(function (data) {

                if (data && data.length > 0) {
                    angular.forEach(data, function (review) {
                        review.programActionCodes = angular.fromJson(review.programActionCodes);
                    });
                }

                barSvc.hideSpinner();
                model.reviews = data;

                if (model.reviews.length === 0) {
                    model.resultMsg = "No results found";
                }
            });
        };

        model.getReviewStatus = function(reviewTeam){
            if(reviewTeam.programActionCodes && reviewTeam.programActionCodes.length > 0){
                for(var i = 0; i < reviewTeam.programActionCodes.length; i++){
                    var ac = reviewTeam.programActionCodes[i];
                    if(ac.actionCode === 'W' || ac.actionCode === 'C'){
                        if(i === (reviewTeam.programActionCodes.length - 1))
                            return "Cancelled/Withdrawn";
                        else
                            continue;
                    }
                    else
                        return model.getReviewStatusName(reviewTeam.latestReviewProcessStatusId)
                }
            }
        }

        var activate = function () {
            model.reset();                    

            barSvc.showSpinner();

            var dataSourceArray = [{
                    dataHolder: model,
                    svcCallback: [codeSvc.getCommissions],
                    svcCallbackArguments: [],
                    dataLocationName: 'commissions',
                    helperCallback: helperSvc.getValue
                }, 
                {
                    dataHolder: model,
                    svcCallback: [codeSvc.getReviewProcessStatuses],
                    svcCallbackArguments: [],
                    dataLocationName: 'reviewStatuses',
                    helperCallback: helperSvc.getValue
                }, 


            ];

            helperSvc.getData(dataSourceArray).then(function(){
                barSvc.hideSpinner();
            });
        }();

        var softReset = function (message) {
            barSvc.hideSpinner();
            model.rfrs = null;
            model.resultMsg = message;
        };

        function getYearSpans() {
            var startYear = 2016;   //rfrs started being tracked for this year, the cycle begins year 2018
            var currentYear = new Date().getFullYear();
            var numberOfYearsToAdd = 1 + currentYear - startYear;   //+1 because we need to loop atleast once for the year 2016
            var years = [];

            for (var i = 0; i < numberOfYearsToAdd ; i++) {
                //+2 because the year cycle begins in 2018
                years.push({ year: startYear + i + 2, yearSpan: helperSvc.getYearSpan(startYear + i + 2) });
            }
            
            return years;
        }
    };

    module.controller('reviewSearchCtrl', reviewSearchCtrl);

}(angular.module('userReview')));