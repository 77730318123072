(function (module) {

    var specializationEditCtrl = function (parent, $scope, $stateParams, $uibModalInstance, $filter, helperSvc, societyMembershipSvc, pevAppSvc, alertSvc, volunteerHelperSvc, specializationSvc) {
        var model = $scope;
        var isApplication = pevAppSvc.isApplicationMode();
        var originalRecord = null;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.isInsertMode = false;
        model.isUpdateMode = false;
        model.currentInformation = {};

        model.setData = function () {
            if (isApplication) {
                model.data = angular.copy(pevAppSvc.volunteerSocietySpecializationData);
            }
        };
     
        model.memberSocietyOptions = societyMembershipSvc.data.memberSocietyOptions;

        model.save = function () {
            if (model.isUpdateMode) {
                model.data.specializations[getSpecializationIndex(originalRecord)] = model.currentInformation;
            } else {
               model.data.specializations.push(model.currentInformation);
            }
            //format specializations
            var formattedSpecializations = [];
            angular.forEach(model.data.specializations, function (item) {
                formattedSpecializations.push({
                    societyId: item.societyId,
                    societyAbbreviatedName: item.societyAbbreviatedName,
                    societyName: item.societyName,
                    specializationId: item.specializationId ? item.specializationId : null,
                    specializationName: item.specializationId ? item.specializationName : null,
                    societySpecializationOther: item.specializationId ? null : item.societySpecializationOther
              });
            });

            var application = angular.copy(pevAppSvc.data.application);
            application.applicationJson.volunteerSocietySpecialization = formattedSpecializations;
            pevAppSvc.update(application).then(onSuccess);

            function onSuccess () {
                alertSvc.addModalAlertSuccess("Specialization succesfully updated.");
                model.setData();
                model.toggleInsertMode();
            }
        };

        model.edit = function (specialization) {
            helperSvc.scrollToModalTop();
            originalRecord = angular.copy(specialization);
            model.currentInformation = angular.copy(specialization);
            if (!model.currentInformation.specializationId && model.currentInformation.societySpecializationOther) {
                model.currentInformation.specializationId = 0;
            }
            getSpecializationsBySociety(model.currentInformation.societyId);
            model.isInsertMode = true;
            model.isUpdateMode = true;
        };

        model.delete = function (specialization) {
            alertSvc.confirmDelete(specialization.specializationName, deleteFunc, specialization);

            function deleteFunc() {
                var index = getSpecializationIndex(specialization);

                if (index > -1) {
                    var application = angular.copy(pevAppSvc.data.application);
                    model.data.specializations.splice(index, 1);
                    application.applicationJson.volunteerSocietySpecialization = model.data.specializations;
                    pevAppSvc.update(application).then(onSuccess);
                } else {
                    alertSvc.addModalAlertWarning("The selected specialization could not be found.");
                }
                
                function onSuccess () {
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Society membership succesfully deleted.");
                }
            }
        };

        model.toggleInsertMode = function () {
            model.currentInformation = {};
            model.specializationOptions = [];
            if (model.isUpdateMode) {
                model.isUpdateMode = false;
            }
            volunteerHelperSvc.toggleInsertMode(model);
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.setSocietyName = function () {
            getSpecializationsBySociety(model.currentInformation.societyId);
            var society = model.memberSocietyOptions.find(function (society) {
                return society.organizationId == model.currentInformation.societyId;
            });
            if (society) {
                model.currentInformation.societyAbbreviatedName = society.abbreviatedName;
                model.currentInformation.societyName = society.organizationName;
            }
        }

        model.setSpecializationName = function () {
            var specialization = model.specializationOptions.find(function (specialization) {
                return specialization.specializationDto.specializationId == model.currentInformation.specializationId;
            });
            if (specialization && specialization.specializationId > 0) {
                model.currentInformation.specializationName = specialization.specializationDto.specializationName;
            } else {
                model.currentInformation.specializationName = null;
            }
        }

        function getSpecializationIndex(currentSpecialization) {
            return model.data.specializations.findIndex(function (specialization) {
                return specialization.societyId == currentSpecialization.societyId &&
                       specialization.specializationId == currentSpecialization.specializationId;
            });
        }

        function getSpecializationsBySociety(societyId) {
            specializationSvc.getBySocietyId(societyId).then(function (data) {
                model.specializationOptions = $filter('orderBy')(data, 'specializationName');
                model.currentInformation.specializationName = null;
                if (model.specializationOptions.length === 0) {
                    model.currentInformation.specializationId = 0;
                } else if (model.specializationOptions.length > 0) {
                    model.specializationOptions.push({ specializationDto: { specializationId: 0, specializationName: 'Other specialization' } });
                }
            })
        }

        var activate = function () {
            model.setData();
            model.specializationOptions = [];
        }();
    };

    module.controller('specializationEditCtrl', specializationEditCtrl);

}(angular.module('volunteer')));