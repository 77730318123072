(function (module) {

    var programsAndPevsCtrl = function ($scope, $uibModal, eventSvc, helperSvc, codeSvc, programReviewSvc, reviewTeamMemberSvc, alertSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Programs and PEVs';

        model.editReview = function (review) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/programReviewEdit.html',
                size: 'lg',
                controller: 'programReviewEditCtrl',
                resolve: {
                    review: function () {
                        return review;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };


        model.deleteReview = function (review) {
            var recordName = review.programDto.programDetailDto.programName + ' (' + review.programDto.programDetailDto.degreeCode + ')' + (review.programReviewTypeName ? ' ' + review.programReviewTypeName : '');

            alertSvc.confirmDelete(recordName, deleteFunc, review);

            function deleteFunc() {
                programReviewSvc.delete(review.programReviewId).then(function () {
                    eventSvc.broadcast('refreshSelectedProgramData');
                    alertSvc.addAlertSuccess("Review successfully deleted.");
                });
            }
        };

        model.isEmpty = helperSvc.isEmpty;

        model.programData = programReviewSvc.data;
    };

    module.controller('programsAndPevsCtrl', programsAndPevsCtrl);

}(angular.module('review')));