(function (module) {
    var registerAsAdminCtrl = function ($scope, $uibModalInstance, pevc, searchOptions, helperSvc, alertSvc, trainingSvc) {
        var model = $scope;
        model.dataIsReady = false;
        activate();

        model.cancel = function () {
            $uibModalInstance.close();
        }

        function activate() {
            model.trainingSessions = trainingSvc.data.trainingSessions; //should already be loaded but if not may need to load in parent;
            model.pevc = pevc;
            model.searchOptions = searchOptions;
            model.dataIsReady = true;
        }

    };
    module.controller('registerAsAdminCtrl', registerAsAdminCtrl);

}(angular.module('training')));