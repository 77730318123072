(function (module) {

    var requestResetSvc = function ($http) {
        var url = "/webapi/odata/";
        var factory = {};
        
        factory.requestUserName = function (email) {
            return $http.post(url + 'RequestUserName', email);
        };

        factory.requestPassword = function (email) {
            return $http.post(url + 'RequestUserPassword', email);
        };

        factory.resetPassword = function (password) {
            return $http.post(url + 'ResetPassword', password);
        };

        return {
            requestUserName: factory.requestUserName,
            requestPassword: factory.requestPassword,
            resetPassword: factory.resetPassword
        };
    };
    module.factory('requestResetSvc', requestResetSvc);

})(angular.module('root'));