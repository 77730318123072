(function (module) {

    var societyMembershipInfoCtrl = function ($scope, $uibModal, societyMembershipSvc, volunteerHelperSvc, pevAppSvc, componentHelperSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Society Membership Information';
        model.isApplication = pevAppSvc.isApplicationMode();
        model.isButtonHidden = pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;

        if (model.isApplication) {
            model.data = pevAppSvc.volunteerSocietyMembershipData;
        } else {
            model.data = societyMembershipSvc.data;
        }

        componentHelperSvc.invokeOnRendered('societyMembershipInfo', $scope);

        model.editSocietyMembership = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/societyMembershipEdit.html',
                size: 'lg',
                controller: 'societyMembershipEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('societyMembershipInfoCtrl', societyMembershipInfoCtrl);

}(angular.module('volunteer')));