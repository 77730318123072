(function (module) {

    var commissionsAcctAndNgrYearEditCtrl = function ($scope, $uibModalInstance, organizationCommissionSvc, organizationId, commissions, eventSvc, alertSvc) {
        var model = $scope;
        model.organizationId = organizationId;
        //create a copy so parents data isnt being changed directly until saved
        model.commissions = commissions;
        model.title = "Commissions, NGR Year, and Account Number";
        model.data = angular.copy(organizationCommissionSvc.data);
        model.organizationCommissions = model.data.organizationCommissions;

        model.add = function () {
            var newOrgComm = {
                organizationCommissionId: 0,
                organizationId: parseInt(model.organizationId, 10),
                isDeleted: false
            };

            model.organizationCommissions.push(newOrgComm);
            model.isBlankMode = false;
        };

        model.delete = function (org, isLastItem) {
            if (isLastItem) model.isBlankMode = true;
            org.isDeleted = true;
        };

        model.save = function () {
            var newArr = [];

            angular.forEach(model.organizationCommissions, function (x) {

                //exclude newly added line but deleted
                if (x.isDeleted === false || x.organizationCommissionId !== 0) {
                    newArr.push(x);
                }
            });

            organizationCommissionSvc.save(newArr).then(function (data) {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Information successfully updated.");
            });

        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        activate();

        function activate() {
            model.isBlankMode = (model.organizationCommissions.length === 0);

            if (model.organizationCommissions.length === 0) {
                model.add();
            };
        }
    };

    module.controller('commissionsAcctAndNgrYearEditCtrl', commissionsAcctAndNgrYearEditCtrl);

}(angular.module('organization')));