(function (module) {

    var assignAppraisersModalCtrl = function (programs, reviewTeamId, helperSvc, $scope, $uibModalInstance, alertSvc, appraisalSvc) {
        var model = $scope;
        model.programs = programs;
        model.title = 'Assign Appaisers';
        model.appraisers = [];

        model.insertRow = function () {
            var newAppraisers = {
                programId: 0,
                reviewTeamId: reviewTeamId,
                email: '',
                isDeleted: false
            };

            model.appraisers.push(newAppraisers);
            model.isBlankMode = false;
        };

        model.deleteRow = function (appraiser, isLastItem) {
            if (isLastItem) model.isBlankMode = true;
            appraiser.isDeleted = true;
        };

        model.save = function () {
            var newArr = [];
            angular.forEach(model.appraisers, function (appraiser) {
                //exclude newly added line but deleted
                if (appraiser.isDeleted === false && appraiser.programId !== 0) {
                    newArr.push(appraiser);
                }
            });

            appraisalSvc.requestSendPevAppraisal(newArr).then(function (data) {
                alertSvc.addAlertSuccess("Appraisers successfully assigned.");
                $uibModalInstance.close();
            });
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        activate();

        function activate() {
            model.insertRow();
        }
    };

    module.controller('assignAppraisersModalCtrl', assignAppraisersModalCtrl);

}(angular.module('userReview')));