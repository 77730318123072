(function (module) {

    var selectReviewerReportModalCtrl = function (change, reviewerId, $scope, $q, $uibModalInstance, helperSvc, npcProgramChangeReportSvc, alertSvc, npcSvc, teamMemberTypeNames) {
        var model = $scope;

        model.title = 'Select Reviewer Report';
        model.isSaving = false;
        model.isDataReady = false;
        model.teamMemberTypeNames = teamMemberTypeNames;

        model.data = {
            selectedReportId: null,
            reviewerReports: [],
            reviewerId: reviewerId
        }


        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;

            npcProgramChangeReportSvc.adjunctCreateReport(model.data.selectedReportId, model.data.reviewerId).then(function () {
                $uibModalInstance.close();
            });
        };

        function setReviewerReports(change) {
            var reviewReports = [];
            //may need to add other types
            var reviewers = change.npcProgramChangeReviewerDtos.filter(x => x.teamMemberTypeId === model.teamMemberTypeNames.NPCREVIEWER)

            reviewers.forEach(function (x) {
                var changeReport = change.npcProgramChangeReportDtos.find(y => y.npcProgramChangeReviewerId === x.npcProgramChangeReviewerId);
                if (changeReport) {
                    var reportByReviewer = {
                        reviewerName: x.personDto.firstName + " " + x.personDto.lastName,
                        report: changeReport
                    };

                    reviewReports.push(reportByReviewer);
                }
            });

            model.data.reviewerReports = reviewReports;
        }

        function activate() {
            setReviewerReports(change);
            model.isDataReady = true;
        };

        activate();
    };
    module.controller('selectReviewerReportModalCtrl', selectReviewerReportModalCtrl);

}(angular.module('npc')));