(function (module) {

    var programAreasEditCtrl = function (parent, $scope, $uibModalInstance, $stateParams, helperSvc, volunteerHelperSvc, programAreaSvc,
        pevAppSvc, pevAppValidationSvc, alertSvc, oauth, currentUserHelper, contactRoleTypes, orgConstants, pevApplicationSocietyStatuses,
        pevApplicationSocietyStatusName, pevDisciplineStatuses, volunteerSvc, codeSvc, typeConstSvc
    ) {
        var model = $scope;
        var isAdmin = oauth.isAdmin();
        var activeItemName = 'currentInformation';
        var isApplication = pevAppSvc.isApplicationMode();
        var isSocietyManagerOnly = !(isApplication || volunteerSvc.data.isVolunteer || volunteerSvc.data.isAdmin);
        var originalRecord = null;
        var expiredProgramArea = {
            societyId: null,
            disciplineId: null,
            societyAbbreviatedName: 'Unavailable',
            disciplineName: 'Unavailable'
        };

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.volunteerId = $stateParams.volunteerId;
        model.isCommissionSelected = false;
        model.selectedExpiredProgramArea = false;
        model.truncateLength = 80;
        model.isCommentTruncated = {
            qualification: true,
            societyGeneral: true
        };
        model.selected = {
            programArea: null,
            societies: []
        };
        model.isApplication = isApplication;
        model.readMore = alertSvc.readMore;
        model.errors = [];
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        model.isDuplicate = false;

        if (isApplication) {
            model.data = angular.copy(pevAppSvc.volunteerDisciplineData);
            model.data.volunteerDisciplineStatusOptions = programAreaSvc.data.volunteerDisciplineStatusOptions;
              
        } else {
            model.data = programAreaSvc.data;
        }

        model.save = function () {
        //CHECK WHICH MODE AND THEN IF EDIT COMPARE ORIGINAL SOCIETIES/DISCIPLINES
            if (checkForDuplicate()) {
                return alertSvc.addModalAlertWarning("This program area has already been selected.");
            }
            if (!isApplication) model.currentInformation.volunteerId = model.volunteerId;
            delete model.currentInformation.commissionId; //dont save this field

            if (isApplication) {

                var formattedSocietyList = [];
                var formattedProgramAreaList = [];
                var formattedProgramArea = null;

                if (model.selected.programArea.societyId === orgConstants.IEEE) {//if IEEE is one of there societies then can only choose 1 commission worth of programs
                    var listOfCommissionIds = pevAppSvc.getListOfDisciplineCommissionIdsForIEEE(pevAppSvc.data.application);
                    
                    if(listOfCommissionIds.length !== 0){
                        if (listOfCommissionIds.length > 1 || model.selected.programArea.commissionId !== listOfCommissionIds[0]) {
                            return alertSvc.addModalAlertWarning("You have an IEEE society program selected with a specific commission and are therefore not allowed to select a program area with a different commission for IEEE.")
                        }
                    }
                }
               
                //WE DO PROGRAM AREA BY SOCIETY ID NOW
                angular.forEach(model.selected.societies, function (society) {
                    formattedProgramArea = {
                        societyId: society.societyId,
                        disciplineId: model.selected.programArea.disciplineId,
                        disciplineName: model.selected.programArea.disciplineName,
                        commissionId: model.selected.programArea.commissionId,
                        commissionName: typeConstSvc.getCommissionAbbreviationById(model.selected.programArea.commissionId),
                        volunteerDisciplineStatusId: pevDisciplineStatuses.PENDING,
                        qualificationComment: model.currentInformation.qualificationComment,
                        societyGeneralComment: model.currentInformation.societyGeneralComment
                    }

                    formattedProgramAreaList.push(formattedProgramArea);
 
                    //add the discipline id to this society
                    if (model.data.volunteerProgramAreas.societies && model.data.volunteerProgramAreas.societies.length > 0)
                        var existingSocieties = helperSvc.getFilteredArray(model.data.volunteerProgramAreas.societies, 'societyId', society.societyId, true);

                    if (existingSocieties && existingSocieties.length > 0) {

                        angular.forEach(existingSocieties, function (society) {
                            if (!helperSvc.arrContains(society.disciplineIds, formattedProgramArea.disciplineId)) {
                                society.disciplineIds.push(model.selected.programArea.disciplineId);
                            }
                        });
                    }
                    else {
                        var formattedSociety = {
                            societyId: society.societyId,
                            societyAbbreviatedName: society.societyAbbreviatedName,
                            volunteerApplicationSocietyStatusId: pevApplicationSocietyStatuses.WAITING_FOR_SOCIETY_APPROVAL,
                            volunteerApplicationSocietyStatusName: pevApplicationSocietyStatusName[0].statusName,
                            disciplineIds: [model.selected.programArea.disciplineId]
                        }
                      
                        formattedSocietyList.push(formattedSociety);                            
                    }
                                                   
                });
    
                if (model.isUpdateMode) {                  
                    //check that the old societies still have a corresponding discipline Id and remove any that dont
                    //check if the discipline is the same and remove any disciplineids from societies if not
                    //DISCIPLINE CHANGE LOGIC
                    var remainingDisciplines;

                    if(formattedProgramArea.disciplineId !== originalRecords[0].disciplineId){
                        removeDisciplineFromSociety(originalRecords[0].disciplineId);
                        remainingDisciplines = getRemainingDisciplines(originalRecords[0]);
                    }
                    //SOCIETY CHANGE LOGIC
                    else{
                        remainingDisciplines = getRemainingDisciplines(formattedProgramArea);

                        for (var i = 0; i < model.data.volunteerProgramAreas.societies.length; i++) {
                            var society = model.data.volunteerProgramAreas.societies[i];
                            var inNewSocieties = false;
                            if (helperSvc.arrContains(society.disciplineIds, formattedProgramArea.disciplineId)) {
                                for (var j = 0; j < model.selected.societies.length; j++) {
                                    if (society.societyId === model.selected.societies[j].societyId) {
                                        inNewSocieties = true;
                                    }
                                }
                                
                                if (!inNewSocieties) {
                                    var index = society.disciplineIds.indexOf(formattedProgramArea.disciplineId)
                                    society.disciplineIds.splice(index, 1);                              
                                }
                            }
                        }
                    }
                                      
                    remainingDisciplines = remainingDisciplines.concat(formattedProgramAreaList);
                    model.data.volunteerProgramAreas.disciplines = remainingDisciplines;

                    var remainingSocieties = getRemainingSocieties();
                    remainingSocieties = remainingSocieties.concat(formattedSocietyList);
                    model.data.volunteerProgramAreas.societies = remainingSocieties;
                }else{
                    model.data.volunteerProgramAreas.disciplines = model.data.volunteerProgramAreas.disciplines.concat(formattedProgramAreaList);
                    model.data.volunteerProgramAreas.societies = model.data.volunteerProgramAreas.societies.concat(formattedSocietyList);
                }

                var application = angular.copy(pevAppSvc.data.application);
                application.applicationJson.volunteerDiscipline = model.data.volunteerProgramAreas;

                pevAppSvc.update(application).then(onSuccess);

            } else {
                if(model.isCreateMode)
                    programAreaSvc.create(model.currentInformation).then(onSuccess);
                else if (model.isUpdateMode)
                    programAreaSvc.update(model.currentInformation).then(onSuccess);
            }

            function onSuccess() {
                pevAppValidationSvc.invokeValidation();
                alertSvc.addModalAlertSuccess("Program area succesfully updated.");
                model.toggleModes(model);
                
            }
        };

        model.edit = function (programArea) {
            if(isApplication){
                model.selected.programArea = getCorrespondingProgramAreaOption(programArea.disciplineId);
                model.selected.societies = [];
                model.societyOptions = [];
                originalRecords = angular.copy(helperSvc.getFilteredArray(model.data.volunteerProgramAreas.disciplines, 'disciplineId', programArea.disciplineId, true));
                volunteerHelperSvc.copyDataAndSetModes(model, activeItemName, programArea);
               
                //get the societyies for the program area 
                if (originalRecords && originalRecords.length > 0) {
                    programAreaSvc.getDisciplineSociety(null, null, originalRecords[0].disciplineId).then(function (data) {
                        angular.forEach(data, function (society) {
                            model.societyOptions.push(society);
                            if (helperSvc.arrayContainsByPropertyValue(originalRecords, 'societyId', society.societyId)) {
                                model.updateSocietyList(society)
                            }
                        });
                    })
                }
            } else {
                model.selected.programArea = programArea;
                volunteerHelperSvc.copyDataAndSetModes(model, activeItemName, programArea);
                model.loadProgramAreas();
            }
        };

        model.delete = function (programArea) {
            alertSvc.confirmDelete(programArea.disciplineName, deleteFunc, programArea);

            function deleteFunc() {
                if (isApplication) {                           
                    var application = angular.copy(pevAppSvc.data.application);

                    removeDisciplineFromSociety(programArea.disciplineId);
                    model.data.volunteerProgramAreas.disciplines = getRemainingDisciplines(programArea);
                    model.data.volunteerProgramAreas.societies = getRemainingSocieties();

                    application.applicationJson.volunteerDiscipline = model.data.volunteerProgramAreas;

                    pevAppSvc.update(application).then(onSuccess);                   
                } else {
                    programAreaSvc.delete(programArea).then(onSuccess);
                }

                function onSuccess() {
                    pevAppValidationSvc.invokeValidation();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess("Program area succesfully deleted.");
                }
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleModes = function () {
            volunteerHelperSvc.toggleModes(model, activeItemName);
            model.selected = {
                programArea: null,
                societies: []
            };

            if (model.isCreateMode) {
                model.isCommissionSelected = false;
            }
        };

        model.updateSocietyList = function (society) {
            var index = model.selected.societies.indexOf(society);
   
            if (index == -1) {
                // Add society
                model.selected.societies.push(society);
                society.checked = true;
                
            } else {
                // Remove society
                model.selected.societies.splice(index, 1);
                society.checked = false;
            }

            if (model.selected.societies.length > 0) {
                //societes should have all the same commission or we failed to identify the societies and disciplines correctly 
                model.selectedCommissionFullName = typeConstSvc.getCommissionFullName(model.selected.societies[0].commissionId);
                model.selectedCommissionAbbreviation = typeConstSvc.getCommissionAbbreviationById(model.selected.societies[0].commissionId);
            }
        };

        model.setSocietyAndDisciplineIds = function () {
            if (model.selected.programArea) {
                var programArea = model.selected.programArea;
               
                if(checkForDuplicate()){
                    return  alertSvc.addModalAlertWarning("This program area has already been selected.");
                }

                if (!isApplication) {
                    model.currentInformation.disciplineId = programArea.disciplineId;
                    model.currentInformation.societyId = programArea.societyId;
                }else{

                    model.selected.societies = [];
                    model.societyOptions = [];

                    programAreaSvc.getDisciplineSociety(null, null, programArea.disciplineId).then(function (data) {
                        angular.forEach(data, function (society) {
                            if (society.leadSocietyFlag === true) {
                                model.societyOptions.push(society);
                            }
                        });
                    });
                }

            } else {
                model.currentInformation.disciplineId = null;
                model.currentInformation.societyId = null;
                model.selected.programArea = null;
            }
        };

        model.selectedExpiredProgramArea = function () {
            return model.selected.programArea === expiredProgramArea;
        }
        
        model.isUserAllowed = function (programArea) {
            return isAdmin || isApplication || oauth.hasRole(programArea.societyId, contactRoleTypes.EDUCATIONLIASON);
        };

        model.getSocietyNamesForDiscipline = function (programArea) {
            var societyIds = pevAppSvc.getSocietiesByDisciplineId(programArea.disciplineId).map(function (society) { return society.societyId });
            return pevAppSvc.getSocietyNamesBySocietyIds(societyIds);
        };

        function getProgramAreaIndex(currentRecord) {
            if(isApplication){
                return model.data.volunteerProgramAreas.disciplines.findIndex(function (programArea) {
                    return programArea.disciplineId == currentRecord.disciplineId && programArea.societyId === currentRecord.societyId;
                });
            }else{
                return model.data.volunteerProgramAreas.findIndex(function (programArea) {
                    return programArea.disciplineId == currentRecord.disciplineId;
                });
            }
            
        }

        model.getProgramAreaText = programAreaSvc.getProgramAreaText;

        model.loadProgramAreas = function() {
            var programAreaOptions = [];
            var matchFound = false;
            programAreaOptions.push(expiredProgramArea);

            if (model.currentInformation.commissionId) {
                var data = volunteerSvc.data.allowedProgramAreas;

                var buildDisciplineOptions = function (data) {
                    for (var i = 0; i < data.length; i++) {
                        var currentProgramArea = data[i];

                        if (model.currentInformation.commissionId === currentProgramArea.commissionId) {
                            programAreaOptions.push(currentProgramArea);

                            if (model.selected.programArea) {
                                var selectedProgramArea = model.selected.programArea;

                                if (selectedProgramArea.disciplineId === currentProgramArea.disciplineId && selectedProgramArea.societyId === currentProgramArea.societyId) {
                                    model.selected.programArea = currentProgramArea;
                                    matchFound = true;
                                }
                            }
                        }
                    }

                    if (!matchFound && model.currentInformation.volunteerDisciplineId) {
                        model.selected.programArea = expiredProgramArea;
                    }

                    model.isCommissionSelected = true;
                };

                if (!isSocietyManagerOnly) {
                    programAreaSvc.getDisciplineSociety(model.currentInformation.commissionId).then(function (data) {
                        buildDisciplineOptions(data);
                    });
                } else {
                    var societyIds = currentUserHelper.getSocietiesByPevManagerRole().map(function (id) { return id.organizationId; });
                    model.isCommissionSelected = true;
                    codeSvc.getProgramAreaSociety().then(function (data) {
                        model.programAreaOptions = helperSvc.getFilteredArray(data.value, 'societyId', societyIds, true);
                    });
                    model.isCommissionSelected = true;
                }
            }
            else {
                model.isCommissionSelected = false;
            }

            model.programAreaOptions = programAreaOptions;
        };

        function checkForDuplicate() {
            var isDuplicate = false;
            var disciplines;

            if (isApplication) {
                disciplines = model.data.volunteerProgramAreas.disciplines;
            } else {
                disciplines = model.data.volunteerProgramAreas;
            }

            for (var i = 0; i < disciplines.length; i++) {
                var programArea = disciplines[i];

                //TODO: CHANGE
                if (model.isUpdateMode) {
                    //if (i !== model.programAreaEditIndex && programArea.disciplineId === model.selected.programArea.disciplineId)
                    //    isDuplicate = true;
                }
                else if (programArea.commissionId === model.selected.programArea.commissionId &&
                    programArea.disciplineId === model.selected.programArea.disciplineId) {

                    //model.errors.push('This program area is already present');
                    isDuplicate = true;
                }
            }

            return isDuplicate;
        }

        function getCorrespondingProgramAreaOption(disciplineId){
           var programArea = helperSvc.getFilteredArray(model.programAreaOptions, 'disciplineId', [disciplineId], true);
           return programArea && programArea.length > 0 ? programArea[0] : null;
        }

        function removeDisciplineFromSociety(disciplineId) {
            for (var i = 0; i < model.data.volunteerProgramAreas.societies.length; i++) {
                var society = model.data.volunteerProgramAreas.societies[i];

                if (helperSvc.arrContains(society.disciplineIds, disciplineId)) {
                    var index = society.disciplineIds.indexOf(disciplineId)
                    society.disciplineIds.splice(index, 1);
                }
            }
        }

        function getRemainingDisciplines(programArea) {
            var remainingDisciplines = [];
            for (var i = 0; i < model.data.volunteerProgramAreas.disciplines.length; i++) {
                var discipline = model.data.volunteerProgramAreas.disciplines[i];
                if (discipline.disciplineId !== programArea.disciplineId)
                    remainingDisciplines.push(discipline);
            }
            return remainingDisciplines;
        }

        function getRemainingSocieties() {
            var remainingSocieties = [];
            angular.forEach(model.data.volunteerProgramAreas.societies, function (society) {
                if (society.disciplineIds.length > 0)
                    remainingSocieties.push(society);
            });
            return remainingSocieties;
        }

        var activate = function () {
            if (isApplication) {
                codeSvc.getProgramAreaSociety().then(function (data) {
                    model.programAreaOptions = data.value;
                });
            }
            model.commissionOptions = programAreaSvc.data.commissionOptions;
        }();
    };

    module.controller('programAreasEditCtrl', programAreasEditCtrl);

}(angular.module('volunteer')));