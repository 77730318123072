(function (module) {

    var onlineEducationEditCtrl = function (parent, $scope, $uibModalInstance, pevAppSvc, pevAppValidationSvc, alertSvc, onlineEducationSvc, volunteerHelperSvc) {
        var model = $scope;
        var checkIsVolunteer = true;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer)
        model.currentInformation = {};
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 

        if (model.isApplication) {
            model.currentInformation = angular.copy(pevAppSvc.volunteerOnlineEducation);
        } else {
            model.currentInformation = angular.copy(onlineEducationSvc.data);
            model.currentInformation.onlineEducation.volunteerId = model.volunteerId;
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.isApplication) {
                var application = angular.copy(pevAppSvc.data.application);

                application.applicationJson.volunteerOnlineEducation = model.currentInformation.onlineEducation;

                pevAppSvc.update(application).then(onSuccess);
            } else if (!model.currentInformation.onlineEducation.volunteerOnlineExperienceId || model.currentInformation.onlineEducation.volunteerOnlineExperienceId == 0) {
                model.currentInformation.onlineEducation.volunteerOnlineExperienceId = 0;
                onlineEducationSvc.create(model.currentInformation.onlineEducation).then(onSuccess);
            } else if (model.currentInformation.onlineEducation.volunteerOnlineExperienceId && model.currentInformation.onlineEducation.volunteerOnlineExperienceId > 0) {
                onlineEducationSvc.update(model.currentInformation.onlineEducation).then(onSuccess);
            }

            function onSuccess() {
                pevAppValidationSvc.invokeValidation();
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Online education experience succesfully updated.");
            }
        };
    };

    module.controller('onlineEducationEditCtrl', onlineEducationEditCtrl);

}(angular.module('volunteer')));