(function (module) {
    'use strict';

    module.factory('npcSvc', [
        'odataSvc', '$http', 'alertSvc', '$odata', 'npcStatusNames', 'documentSvc', 'amsConst', '$q', 'helperSvc', 'programSvc','getOrganizationUserSvc','npcPDFSvc','pdfSvc',
        function (odataSvc, $http, alertSvc, $odata, npcStatusNames, documentSvc, amsConst, $q, helperSvc, programSvc, getOrganizationUserSvc, npcPDFSvc,pdfSvc) {

            const apiPath = '/Npc';
            const keyName = 'npcId'

            const npcProgramChangeApiPath = '/NpcProgramChange';

            const npcProgramChangeKeyName = 'npcProgramChangeId'
            const npcProgramChangeProgressApiPath = '/NpcProgramChangeProgress';
            const npcProgramChangeProgressKeyName = 'npcProgramChangeProgressId';

            const npcProgramChangeReviewerApiPath = '/NpcProgramChangeReviewer';
            const npcProgramChangeReviewerKeyName = 'npcProgramChangeReviewerId';

            var onSuccess = function (response) {
                if (response.status == 200) {
                    return response.data;
                }
            };

            var onFailure = function (response) {
                return $q.reject(response);
            };

            function advancedSearch(searchOptions) {

                const oSvc = odataSvc.get();
                const searchPath = '/NpcProgramChangeSearch';

                var hasOrganizationName = searchOptions.organizationName ? true : false;
                var hasStatusId = searchOptions.statusId ? true : false;
                var hasCommission = searchOptions.commissions && searchOptions.commissions.length > 0 ? true : false;
                var hasTypeChangeId = searchOptions.changeTypeId ? true : false;
                var hasSubmissionYear = searchOptions.submissionYear ? true : false;
                var hasOrganizationId = searchOptions.organizationId ? true : false;


                var hasSearchOptions = hasOrganizationId || hasOrganizationName || hasStatusId || hasCommission || hasTypeChangeId || hasSubmissionYear;

                if (hasSearchOptions) {
                    var predicates = [];
                    var combinedPredicate;

                    if (hasSubmissionYear)
                        predicates.push(new $odata.Predicate('submittedYear', '=', searchOptions.submissionYear));
                    if (hasStatusId)
                        predicates.push(new $odata.Predicate('npcStatusId', '=', parseInt(searchOptions.statusId)));
                    if (hasTypeChangeId)
                        predicates.push(new $odata.Predicate('npcChangeTypeId', '=', parseInt(searchOptions.changeTypeId)));
                    if (hasOrganizationName)
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                    if (hasOrganizationId)
                        predicates.push(new $odata.Predicate('organizationId', '=', parseInt(searchOptions.organizationId)));


                    if (hasCommission) {
                        var combinedCommissionPredicate = [];

                        for (var i = 0; i < searchOptions.commissions.length; i++) {
                            combinedCommissionPredicate.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissions[i].codeKey || searchOptions.commissions[i])))
                        }

                        if (combinedCommissionPredicate.length > 0) {
                            predicates.push($odata.Predicate.or(combinedCommissionPredicate))
                        }

                    }

                    if (predicates.length > 0) {
                        combinedPredicate = $odata.Predicate.and(predicates);
                    }

                    oSvc.getSource(searchPath).odata()
                        .filter(combinedPredicate)
                        .query()
                        .$promise
                        .then(oSvc.onSuccess, oSvc.onFailure);
                } else {
                    oSvc.getSource(searchPath).odata()
                        .query()
                        .$promise
                        .then(oSvc.onSuccess, oSvc.onFailure);

                }


                var resolvedDataPromise = oSvc.getDeferred().promise.then((data) => {
                    deserializeSearchViewModelJson(data);
                    return data;
                });

                return resolvedDataPromise;

            }

            function getNpcsForOranizationUser(organizationId) {
                var oSvc = odataSvc.get();
                var apiPath = oSvc.getPathWithParameter('/GetNpcsForOrganizationUser', 'organizationId', organizationId)

                oSvc.getSource(apiPath).odata()
                    .expand('npcProgramDtos', 'npcProgramChangeDtos')
                    .expand('npcProgramDtos', 'programDetailDto')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcProgramChangeProgressDtos')
                    .query(oSvc.onSuccess, oSvc.onFailure);

                var resolvedDataPromise = oSvc.getDeferred().promise.then((data) => {
                    var cleanedData = data;

                    if (cleanedData && cleanedData.length > 0) {
                        angular.forEach(cleanedData, function (npc) {

                            if (npc.npcProgramDtos && npc.npcProgramDtos.length > 0) {
                                angular.forEach(npc.npcProgramDtos, function (program) {

                                    if (program.npcProgramChangeDtos && program.npcProgramChangeDtos.length > 0) {
                                        angular.forEach(program.npcProgramChangeDtos, function (change) {
                                            change.changeJson = JSON.parse(change.changeJson);
                                        });
                                    }
                                });
                            }
                        })
                    }
                    return cleanedData;
                });

                return resolvedDataPromise;
            }

            function getNPCById(npcId) {
                const id = parseInt(npcId);
                const oSvc = odataSvc.get();

                oSvc.getSource(apiPath, keyName).odata()
                    .expand('npcProgramDtos', 'programDetailDto')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcProgramChangeProgressDtos')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcProgramChangeDocumentDtos')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcProgramChangeReviewerDtos')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcProgramChangeReportDtos')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcProgramChangeReviewerDtos', 'personDto')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcFinalActionProgramChangeDtos')
                    .expand('npcProgramDtos', 'npcProgramChangeDtos', 'npcFinalActionProgramChangeDtos', 'npcFinalActionDto')
                    .get(id, oSvc.onSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise.then(data => {
                    deserializeJson(data);

                    return data;
                });
            }

            function getNpcFinalActionByNpcId(npcId) {
                var path = amsConst.webApiUrl + `/odata/GetNpcFinalActionByNpcId(npcId=${npcId})?$expand=npcFinalActionProgramChangeListDtos,npcFinalActionDocumentDtos`;
                return $http.get(path).then(onSuccess, onFailure);
            }

            function getNpcProgramChangesForRfr(rfrId) {
                var path = amsConst.webApiUrl + `/odata/GetNpcProgramChangesForRfr(rfrId=${rfrId})`;
                return $http.get(path).then(
                    response => {
                        const data = onSuccess(response)?.value;
                        deserializeSearchViewModelJson(data);
                        return data;
                    },
                    onFailure
                );
            }

            function getNpcTerminationsForReviewTeam(reviewTeamId) {
                var path = amsConst.webApiUrl + `/odata/GetNpcProgramChangesForReviewTeam(reviewTeamId=${reviewTeamId})?$filter=tolower(npcType) eq 'termination' and npcStatusId ne 9 and npcStatusId ne 10`;
                return $http.get(path).then(
                    response => {
                        const data = onSuccess(response)?.value;
                        deserializeSearchViewModelJson(data);
                        return data;
                    },
                    onFailure
                );
            }

            function getEligiblePrograms(organizationId, commissionId, programId) {
                return programSvc.searchProgramsByOrganizationId(organizationId, true).then(data =>
                    data.value.filter(currentProgram =>
                        currentProgram.accreditationDates &&
                        (!commissionId || currentProgram.commissionId === commissionId) &&
                        (!programId || currentProgram.programId === programId) &&
                        (
                            (new Date(currentProgram.accreditationDates.substring(currentProgram.accreditationDates.length - 10, currentProgram.accreditationDates.length))) >
                                (new Date())
                            )
                    )
                );
            }

            function create(npc) {
                const oSvc = odataSvc.get();
                const serializedNpc = serializeJson(npc);
                const resource = oSvc.instantiate(`${apiPath}?$expand=npcProgramDtos($expand=npcProgramChangeDtos)`, keyName, serializedNpc);
                oSvc.getDeferred().promise.then(data => data.value);

                return resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(data => {
                    deserializeJson(data);
                    return data;
                });
            }

            function update(npc) {
                const oSvc = odataSvc.get();
                const serializedNpc = serializeJson(npc);
                const resource = oSvc.instantiate(apiPath, keyName, serializedNpc);

                return resource.$update(oSvc.onSuccess, oSvc.onFailure);
            }


            function deleteNPC(npc) {
                const oSvc = odataSvc.get();
                const resource = oSvc.instantiate(apiPath, keyName, npc);

                return resource.$delete(oSvc.onSuccess, oSvc.onFailure);
            }

            function updateNpcProgramChange(npcProgramChange) {
                const oSvc = odataSvc.get();
                const serializedNpcProgramChange = serializeNpcProgramChangeJson(npcProgramChange);
                const resource = oSvc.instantiate(npcProgramChangeApiPath, npcProgramChangeKeyName, serializedNpcProgramChange);

                return resource.$update(null, oSvc.onSuccess, oSvc.onFailure);
            }

            function deleteNpcProgramChange(npcProgramChange) {
                const oSvc = odataSvc.get();
                const resource = oSvc.instantiate(npcProgramChangeApiPath, npcProgramChangeKeyName, npcProgramChange);

                return resource.$delete(oSvc.onSuccess, oSvc.onFailure);
            }

            function manageStatus(npcProgramChangeProgress) {
                const oSvc = odataSvc.get();
                const resource = oSvc.instantiate(npcProgramChangeProgressApiPath, npcProgramChangeProgressKeyName, npcProgramChangeProgress);

                return resource.$save(null, oSvc.onSuccess, oSvc.onFailure);
            }

            function addReviewer(npcProgramChangeReviewer) {
                const oSvc = odataSvc.get();
                const resource = oSvc.instantiate(npcProgramChangeReviewerApiPath, npcProgramChangeReviewerKeyName, npcProgramChangeReviewer);

                return resource.$save(null, oSvc.onSuccess, oSvc.onFailure);
            }

            function removeReviewer(npcProgramChangeReviewer) {
                const oSvc = odataSvc.get();
                const resource = oSvc.instantiate(npcProgramChangeReviewerApiPath, npcProgramChangeReviewerKeyName, npcProgramChangeReviewer);

                return resource.$delete(oSvc.onSuccess, oSvc.onFailure);

            }

            function deserializeJson(npc) {
                if (!npc) return;

                angular.forEach(npc.npcProgramDtos, npcProgram => {
                    angular.forEach(npcProgram.npcProgramChangeDtos, npcProgramChange => {
                        npcProgramChange.changeJson = angular.fromJson(npcProgramChange.changeJson);
                        npcProgramChange.excomDecisionJson = angular.fromJson(npcProgramChange.excomDecisionJson);
                        npcProgramChange.excomFVDecisionJson = angular.fromJson(npcProgramChange.excomFVDecisionJson);
                        npcProgramChange.changeJson.forEach(helperSvc.deserializeDateFields);
                        angular.forEach(npcProgramChange.npcProgramChangeReportDtos, npcProgramChangeReport => {
                            npcProgramChangeReport.reportJson = angular.fromJson(npcProgramChangeReport.reportJson);
                            npcProgramChangeReport.reportJson.forEach(helperSvc.deserializeDateFields);
                        });
                    });
                });
            }

            function deserializeSearchViewModelJson(npcProgramChangeSearchViewModel) {
                npcProgramChangeSearchViewModel = Array.isArray(npcProgramChangeSearchViewModel) ? npcProgramChangeSearchViewModel : [npcProgramChangeSearchViewModel];

                npcProgramChangeSearchViewModel.forEach(npcProgramChange => {
                    helperSvc.deserializeDateFields(npcProgramChange);
                    npcProgramChange.changeJson = angular.fromJson(npcProgramChange.changeJson);
                    npcProgramChange.changeJson.forEach(helperSvc.deserializeDateFields);
                });
            }

            function serializeJson(npc) {
                if (!npc) return npc;

                const serializedNpc = angular.copy(npc);
                angular.forEach(serializedNpc.npcProgramDtos, npcProgram => {
                    angular.forEach(npcProgram.npcProgramChangeDtos, npcProgramChange => {
                        delete npcProgramChange.latestFinalAction;
                        npcProgramChange.changeJson = angular.toJson(npcProgramChange.changeJson);
                        npcProgramChange.excomDecisionJson = angular.toJson(npcProgramChange.excomDecisionJson);
                        npcProgramChange.excomFVDecisionJson = angular.toJson(npcProgramChange.FVexcomDecisionJson);
                        angular.forEach(npcProgramChange.npcProgramChangeReportDtos, npcProgramChangeReport => {
                            npcProgramChangeReport.reportJson = angular.toJson(npcProgramChangeReport.reportJson);
                        });
                    });
                });

                return serializedNpc;
            }

            function serializeNpcProgramChangeJson(npcProgramChange) {
                if (!npcProgramChange) return npcProgramChange;

                delete npcProgramChange.latestFinalAction;
                const serializedNpcProgramChange = angular.copy(npcProgramChange);
                serializedNpcProgramChange.changeJson = angular.toJson(serializedNpcProgramChange.changeJson);
                serializedNpcProgramChange.excomDecisionJson = angular.toJson(serializedNpcProgramChange.excomDecisionJson);
                serializedNpcProgramChange.excomFVDecisionJson = angular.toJson(serializedNpcProgramChange.excomFVDecisionJson);
                angular.forEach(serializedNpcProgramChange.npcProgramChangeReportDtos, npcProgramChangeReport => {
                    npcProgramChangeReport.reportJson = angular.toJson(npcProgramChangeReport.reportJson);
                });

                return serializedNpcProgramChange;
            }

            function getLastProgramProgressStatus(programChange) {
                var statusName = 'N/A'
                var copyPC = angular.copy(programChange);
                var status = copyPC.npcProgramChangeProgressDtos.sort((a, b) => a.npcStatusUpdatedDate > b.npcStatusUpdatedDate ? 1 : -1)[copyPC.npcProgramChangeProgressDtos.length - 1];
                statusName = npcStatusNames[status.npcStatusId];
                return statusName;

            }

            function findProgram(npc, programChange, change) {
                return npc.npcProgramDtos
                    .find(currentProgram =>
                        programChange && currentProgram.npcProgramChangeDtos.indexOf(programChange) >= 0 ||
                        currentProgram.npcProgramChangeDtos.some(programChange => programChange.changeJson.indexOf(change) >= 0)
                    );
            }

            function findProgramChange(npc, change) {
                return npc.npcProgramDtos
                    .flatMap(currentProgram => currentProgram.npcProgramChangeDtos)
                    .find(currentProgramChange => currentProgramChange.changeJson.indexOf(change) >= 0);
            }

            function getColorClass(npcChangeTypeId) {
                switch (npcChangeTypeId) {
                    case 1:
                        return 'gray';
                        break;
                    case 2:
                        return 'green';
                        break;
                    case 3:
                        return 'purple';
                        break;
                    case 4:
                        return 'yellow';
                        break;
                    case 5:
                        return 'blue';
                        break;
                }
            };

            function createFinalStatement(currentNPCData, finalActionId,isFV,programsSelected) {
                var retDoc = npcPDFSvc.generateDoc(currentNPCData, false, true, isFV, null, programsSelected);

                return getGeneratePDFPromise(retDoc).then(function (data) {
                    var doc = {
                        referenceId: finalActionId,
                        documentTypeId: 10,
                        documentTitle: "NPC Statement"
                    };
                    var fileName = "10_" + finalActionId + "_" + currentNPCData.organizationDetailDto.organizationName.replaceAll(" ", "");

                    doc.file = new File([data], helperSvc.formatFilename(fileName, 'pdf'));

                    return documentSvc.uploadDocument(doc).then(function (rt) {
                    });
                })
            }

            function getGeneratePDFPromise(doc) {
                var deferred = $q.defer();

                pdfSvc.generatePDFBlob(doc, null, function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }


            function submitFinalAction(finalAction, updatedNpc) {
                var path = amsConst.webApiUrl + '/odata/SubmitProgramChangeFinalAction';

                const serializedNpc = serializeJson(updatedNpc);

                delete serializedNpc.$resolved;
               // delete serializedNpc.odata.context;
                delete serializedNpc.$promise;

                delete serializedNpc["@odata.context"];

                var data = { "npc": serializedNpc, "npcFinalAction": finalAction };

                return $http.post(path, data);
            }

            function getPreviousFinalStatementsSinceCurrentCycle(organizationId, commissionId, currentReviewYear) {
                var path = amsConst.webApiUrl + '/odata/GetPreviousFinalStatementsSinceCurrentCycle(organizationId={0}, commissionId={1}, reviewYear={2})'


                return $http.get(path.format(organizationId, commissionId, currentReviewYear)).then(onSuccess, onFailure);

            }

            function getHistoricalFinalStatementsByOrganizationId(organizationId, commissionId, currentReviewYear) {
                var path = amsConst.webApiUrl + '/odata/GetHistoricalFinalStatementsByOrganizationId(organizationId={0}, commissionId={1}, reviewYear={2})'


                return $http.get(path.format(organizationId, commissionId, currentReviewYear)).then(onSuccess, onFailure);
            }

            function getReviewerSearch() {
                const oSvc = odataSvc.get();
                const searchPath = '/GetNpcProgramChangesForReviewer';

                //GEt 2 year back from current year
                var submissionYearBegin = new Date().getFullYear();
                submissionYearBegin = submissionYearBegin - 2;

                var combinedPredicate;
                var predicates = [];
                predicates.push(new $odata.Predicate('submittedYear', '>=', submissionYearBegin));

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(searchPath).odata()
                    .filter(combinedPredicate)
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

                var resolvedDataPromise = oSvc.getDeferred().promise.then((data) => {
                    return data;
                });

                return resolvedDataPromise;
            }

            function getProgramChangesForMultiSelect(npcData) {
                var allProgramChangeOptions = []

                if (npcData && npcData.npcProgramDtos && npcData.npcProgramDtos.length > 0) {
                    for (var x = 0; x < npcData.npcProgramDtos.length; x++) {
                        var programDto = npcData.npcProgramDtos[x];

                        if (programDto && programDto.npcProgramChangeDtos && programDto.npcProgramChangeDtos.length > 0) {

                            for (var y = 0; y < programDto.npcProgramChangeDtos.length; y++) {
                                var programChange = programDto.npcProgramChangeDtos[y];
                                var changesString = "";

                                for (var i = 0; i < programChange.changeJson.length; i++) {
                                    var change = programChange.changeJson[i];
                                    if (i === programChange.changeJson.length - 1)
                                        changesString += change.npcTypeName
                                    else
                                        changesString += change.npcTypeName + ', '
                                }


                                var convertedProgamChange = {
                                    programChangeName: programDto.programDetailDto.programName + ", " + programDto.programDetailDto.degreeCode + " (" + changesString + ")",
                                    npcProgramChangeId: programChange.npcProgramChangeId,
                                    npcProgramId: programDto.npcProgramId,
                                    npcProgramChangeReviewerDtos: programChange.npcProgramChangeReviewerDtos
                                }

                                allProgramChangeOptions.push(convertedProgamChange)

                            }

                        }
                    }
                }

                return allProgramChangeOptions

            }

            function getLatestProgramStatuses(npcData) {

                var latestProgramChangeStatuses = []
                var allChangeStatuses = []
                var allChanges = {'latest': [], 'all': []}
                

                if (npcData && npcData.npcProgramDtos && npcData.npcProgramDtos.length > 0) {
                    for (var x = 0; x < npcData.npcProgramDtos.length; x++) {

                        var programDto = npcData.npcProgramDtos[x];

                        if (programDto && programDto.npcProgramChangeDtos && programDto.npcProgramChangeDtos.length > 0) {

                            for (var y = 0; y < programDto.npcProgramChangeDtos.length; y++) {
                                var programChange = programDto.npcProgramChangeDtos[y]
                                if (programChange && programChange.npcProgramChangeProgressDtos && programChange.npcProgramChangeProgressDtos.length > 0) {
                                    var maxid = 0;
                                    var latestProgressDto = null;



                                    programChange.npcProgramChangeProgressDtos.map(function (obj) {
                                        if (obj.npcProgramChangeProgressId > maxid) {
                                            maxid = obj.npcProgramChangeProgressId;
                                            latestProgressDto = obj;
                                            allChangeStatuses.push(obj.npcStatusId)
                                        } else {
                                            allChangeStatuses.push(obj.npcStatusId)
                                        }
                                    });

                                    latestProgramChangeStatuses.push(latestProgressDto.npcStatusId)
                                    allChangeStatuses.push(latestProgressDto.npcStatusId)
                                }
                            }
                        }
                    }
                }

                allChanges['latest'] = latestProgramChangeStatuses;
                allChanges['all'] = allChangeStatuses;

                return allChanges;
            }

            function isExcom(comId, reviewTeamId, userPersonId) {
                var excomRoles = [300, 301, 302, 305, 306, 307, 308];

                return $q.when(getOrganizationUserSvc.getExcomMembers(comId, excomRoles, reviewTeamId)).then(function (data) {
                    var excomMembers = data.value;
                    return !!excomMembers.find(t => t.personId == userPersonId);
                })
            }

            function isExcomWithoutConflict(comId, reviewTeamId, userPersonId) {
                var excomRoles = [300, 301, 302, 305, 306, 307, 308];

                return $q.when(getOrganizationUserSvc.getExcomMembersWithConflicts(comId, excomRoles, reviewTeamId)).then(function (data) {
                    var excomMembers = data.value;
                    return !!excomMembers.find(t => t.personId == userPersonId && !t.conflicts.length > 0);
                })
            }

            return {
                advancedSearch,
                getNpcsForOranizationUser,
                getNPCById,
                getEligiblePrograms,
                create,
                update,
                delete: deleteNPC,
                updateNpcProgramChange,
                deleteNpcProgramChange,
                manageStatus,
                addReviewer,
                removeReviewer,
                getLastProgramProgressStatus,
                findProgram,
                findProgramChange,
                getColorClass,
                submitFinalAction,
                getPreviousFinalStatementsSinceCurrentCycle,
                getHistoricalFinalStatementsByOrganizationId,
                getReviewerSearch,
                getProgramChangesForMultiSelect,
                getLatestProgramStatuses,
                isExcomWithoutConflict,
                isExcom,
                createFinalStatement,
                getNpcFinalActionByNpcId,
                getNpcProgramChangesForRfr,
                getNpcTerminationsForReviewTeam
            };

        }
    ]);


}(angular.module('npc')));