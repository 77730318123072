(function (module) {

    var trainingInfoSvc = function (odataSvc, helperSvc, trainingTypeNames, trainingSvc, $stateParams) {
        var apiPath = '/VolunteerTraining';
        var key = 'volunteerTrainingId';
        var factory = {};

        factory.data = { trainings: null, societyOptions: null };

        factory.getByVolunteerId = function (volunteerId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetVolunteerTrainingByVolunteerId', 'volunteerId', volunteerId)

            oSvc.getSource(path, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.trainings = data;
                    factory.getRecertificationTrainings(volunteerId);
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.getRecertificationTrainings = function (volunteerId) {
            var oSvc = odataSvc.get();
            var apiPath = oSvc.getPathWithParameter('/GetRecertificateTrainingByUser', 'volunteerId', volunteerId)

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            //if (!noStoredData) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.addRecertificationTrainingsToTrainings(data);
            });
            //}

            return oSvc.getDeferred().promise;
        }

        factory.create = function (training) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, training);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            loadData(oSvc, training.volunteerId);

            return oSvc.getDeferred().promise;
        };

        factory.update = function (training) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, training);
            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            loadData(oSvc, training.volunteerId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (training) {
            var oSvc = odataSvc.get();

            //confirmation breaks protractor test disabling for now
            //if ($window("Are you sure you want to delete this record?")) {

                var resource = oSvc.instantiate(apiPath, key, { volunteerTrainingId: training.volunteerTrainingId });
                resource.$delete(oSvc.onSuccess, oSvc.onFailure);

                loadData(oSvc, training.volunteerId);

                return oSvc.getDeferred().promise;
            //}
            //else {
            //    return $q.reject('user cancelled');
            //}
        };

        function loadData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getByVolunteerId(volunteerId).then(function(){
                    trainingSvc.getPevcTrainingsByPersonId(parseInt($stateParams['personId'])).then(function(data){
                        factory.addPevcTrainingsToTrainings(data);
                        factory.getRecertificationTrainings(volunteerId);
                    })
                });
            });
        }

        factory.addPevcTrainingsToTrainings = function(pevcTrainings){
            //need to combine the pevc trainings with the ones manually added and alter there data structure/naming... we could also do this server side with a new api call
            if(pevcTrainings && pevcTrainings.length > 0){
                for(var i =0; i < pevcTrainings.length; i++){
                    var training = pevcTrainings[i];
                    factory.data.trainings.push({
                        trainingDescription: trainingTypeNames[training.trainingTypeId],
                        organizationName: "ABET, Inc.",
                        yearTaken: training.trainingYear,
                        isNotEditable: true
                    });       
                }
            }
        }

        factory.addRecertificationTrainingsToTrainings = function (recertificationTrainings) {
            if (recertificationTrainings && recertificationTrainings.length > 0) {
                for (var i = 0; i < recertificationTrainings.length; i++) {
                    var training = recertificationTrainings[i];
                    factory.data.trainings.push({
                        trainingDescription: training.trainingDescription,
                        organizationName: "ABET, Inc.",
                        yearTaken: training.trainingYear,
                        isNotEditable: true
                    });
                }
            }

        }

        return {
            data: factory.data,
            getByVolunteerId: factory.getByVolunteerId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            addPevcTrainingsToTrainings: factory.addPevcTrainingsToTrainings,
            addRecertificationTrainingsToTrainings: factory.addRecertificationTrainingsToTrainings,
            getRecertificationTrainings: factory.getRecertificationTrainings
        };
    };
    module.factory('trainingInfoSvc', trainingInfoSvc);

})(angular.module('volunteer'));