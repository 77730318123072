(function (module) {

    var viewDeanLetterPreviewCtrl = function ($scope, $uibModalInstance, supplementalStatementPDFSvc, helperSvc, currentStatement, statementTypeIds) {
        var model = $scope;

        model.title = 'View Dean Letter';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        }

        var activate = function () {
            model.doc = currentStatement.statementTypeId === statementTypeIds.DRAFT ? supplementalStatementPDFSvc.generateDeanDraftLetter(currentStatement) : supplementalStatementPDFSvc.generateDeanLetter(currentStatement);
        }();
    };

    module.controller('viewDeanLetterPreviewCtrl', viewDeanLetterPreviewCtrl);

}(angular.module('statement')));