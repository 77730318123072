(function (module) {

    module.controller('editVotedActionCtrl', editVotedActionCtrl);

    function editVotedActionCtrl(actionSvc, existingUserActionData, lastProgramAction, $scope, $uibModalInstance, $http, $filter) {
        var model = this;

        // public fields
        model.title = "Edit Voted Action";
        model.availableActions = [];
        model.dataIsReady = false;
        model.effectiveDate = existingUserActionData.effectiveDate;
        model.actionCode = existingUserActionData.actionCode;
        model.note = existingUserActionData.note;

        // public methods
        model.save = save;
        model.cancel = cancel;
        model.formInvalid = formInvalid;
        model.actionNeedsDate = actionNeedsDate;

        // initialize data
        var possibleActions = actionSvc.getPossibleActions(lastProgramAction);
        
        actionSvc.getActions(possibleActions).then(
            function onSuccess(actionsData) {
                model.availableActions = actionsData;
                model.dataIsReady = true;
            },
            function onFailure() {
                alertSvc.addAlertWarning('Program Recommended Action could not be loaded at this time.');
            }
        );

        function save() {
            // Clear out any old date that may be lingering in the model.
            if (!/(T|IR|IV|NGR)/.test(model.actionCode)) {
                model.effectiveDate = null;
            }

            $uibModalInstance.close({
                actionCode: model.actionCode,
                effectiveDate: model.effectiveDate,
                note: model.note
            });
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function actionNeedsDate() {
            if (lastProgramAction === 'NEW' && /(IR|IV|NGR)/.test(model.actionCode)) {
                return true;
            } else if (model.actionCode === 'T') {
                return true;
            } else {
                return false;
            }
        }

        function formInvalid() {
            return $scope.form.$invalid;
        }
    }

}(angular.module('statement')));