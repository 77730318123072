(function (module) {
    var pevAppsCtrl = function ($uibModal, $state, $timeout, $window, pevAppSvc, trainingStatusIds, messageTemplateTypes, contactRoleTypes, currentUser, helperSvc,
                                barSvc, alertSvc, trainingSvc, membershipSvc, codeSvc, pevApplicationStatuses, messageSvc, surveyTemplateTypes, personSvc) {
        var model = this;
        var dataSourceArray = [];

        model.title = 'Pev Applications & Training';
        model.isDataReady = false;
        model.applications = [];
        model.contacts = [];
        model.requiredReferenceCt = [];
        model.statuses = [];
        model.pevApplicationStatuses = pevApplicationStatuses;
        model.formatDate = helperSvc.formatDate;
        
        model.createApplication = function () {
            if(isPendingApp()){
                return alertSvc.addAlertWarning("Please fill out the current pending application.");;
            }

            var date = new Date();

            personSvc.getPersonMdlById(currentUser.profile.personId).then(function (data) {
                var person = data.data;
                var volunteerInfo = {};

                volunteerInfo.firstName = person.firstName;
                volunteerInfo.middleName = person.middleName;
                volunteerInfo.lastName = person.lastName;
                volunteerInfo.emailAddress = person.personEmailDtos && person.personEmailDtos.length > 0 ? person.personEmailDtos[0].emailAddress : null;
                volunteerInfo.phone = person.personTelephoneDtos && person.personTelephoneDtos.length > 0 ? person.personTelephoneDtos[0].number : null;
                volunteerInfo.countryName = person.personAddressDtos && person.personAddressDtos.length > 0 ? person.personAddressDtos[0].countryName : null;
                volunteerInfo.volunteerId = parseInt(currentUser.profile.volunteerId);

                var app = {
                    volunteerApplicationId: 0,
                    personId: parseInt(currentUser.profile.personId),
                    surveyTemplateId: surveyTemplateTypes.PEVAPPLICATION,
                    volunteerApplicationStatusId: pevApplicationStatuses.WAITING_FOR_SUBMISSION,
                    applicationJson: {
                        volunteer: volunteerInfo,
                        volunteerEmployment: [],
                        volunteerLicense: [],
                        volunteerEducation: [],
                        volunteerLanguage: [],
                        volunteerDiscipline: {
                            societies: [],
                            disciplines: []
                        },
                        volunteerSocietyMembership: []
                    },
                    referenceJson: []
                };

                pevAppSvc.create(app).then(function (data) {
                    model.openApplication(data.volunteerApplicationId);
                });
            });

            
        };

        model.openApplication = function (id) {
            $state.go('pevApp.detail', { volunteerApplicationId: id });
        };

        model.getReferenceNum = function (app) {
            var ct = 0;
            if (app.referenceJson && angular.isArray(app.referenceJson)) {
                app.referenceJson.forEach(function (ref) {
                    if (model.hasResponded(ref)) ct++;
                });
            }
            return ct;
        };

        model.hasResponded = function (ref) {
            return ref.surveyQuestion && ref.surveyQuestion.hasOwnProperty('groups') && angular.isArray(ref.surveyQuestion.groups) && ref.surveyQuestion.groups.length > 0;
        };

        model.resendRequests = function (app) {
            pevAppSvc.resendReferenceRequests(app).then(function () {
                alertSvc.addAlertSuccess("Reference requests have been resent.");
            });
        };

        model.hasRefContactInfo = function (references) {
            return references.some(function (ref) {
                return ref.email ? true : false;
            });
        };

        model.getDataSources = function () {
            for (var i = 0; i < model.applications.length; i++) {
                var app = model.applications[i];
                var societyIds = pevAppSvc.getSocietyIds(app);               
                    
                if (societyIds && societyIds.length > 0) {

                    var contactsDataSource = {
                        dataHolder: model,
                        dataLocationName: 'contact' + i,
                        svcCallback: membershipSvc.getTrainingManagers,
                        svcCallbackArguments: [societyIds],
                        helperCallback: addContacts
                    }
                    dataSourceArray.push(contactsDataSource);

                    var requiredReferencesDataSource = {
                        dataHolder: model,
                        dataLocationName: 'reqRefs' + i,
                        svcCallback: pevAppSvc.getNumberOfRequiredReferences,
                        svcCallbackArguments: [societyIds],
                        helperCallback: addRequiredReferences
                    }
                    dataSourceArray.push(requiredReferencesDataSource);
                    
                }
                
            }
        };

        model.getStatusName = function (statusId) {
            if (!statusId) return 'Pending';
            if (!model.statuses.length) return '';
            var obj = model.statuses.find(function (status) {
                return parseInt(status.codeKey) === statusId;
            });
            return obj && obj.codeName ? obj.codeName : '';
        };

        model.getSocietyStatusName = function (application, statusId) {
            if (!application.submittedDate) {
                return "Not Submitted";
            } else if (!statusId || !(model.societyStatuses && model.societyStatuses.length)) return 'Waiting for Society Approval';
                return model.societyStatuses.find(function (status) {
                    return parseInt(status.codeKey) === statusId;
            }).codeName;
        };

        model.getRefArray = function (refs, reqRefCount) {
            if (!refs || refs.length < 1) {
                var arr = [];
                for (var i = 1; i <= reqRefCount; i++) {
                    arr.push(i);
                }
                return arr;
            }
            return refs;
        };

        model.cancelApplication = function (app) {
            var doCancel = function () {
                app.volunteerApplicationStatusId = pevApplicationStatuses.CANCELED_BY_PEV;
                pevAppSvc.update(app).then(function () {
                    loadData(function (data) {
                        model.applications = pevAppSvc.data.applications;
                        finishLoad();
                    });
                });
            };

            alertSvc.confirm("Are you sure you want to cancel this PEV Application?", doCancel);
        };

        model.statusFilter = function (app) {
            return app.volunteerApplicationId != pevApplicationStatuses.CANCELED_BY_PEV || app.volunteerApplicationId != pevApplicationStatuses.CANCELED_BY_ABET;
        };

        model.gotoTrainingSite = function () {
            $window.open('/#/sso/d2l', '_blank');
        };

        function addContacts(data) {
            model.contacts.push(data);
        }

        function addRequiredReferences(data) {
            var arr = data.map(function (society) {
                return society.referenceRequired;
            });
            var max = Math.max.apply(null, arr); 
            max = max === -Infinity ? 0 : max;
            model.requiredReferenceCt.push(max);
        }

        function loadData(callbackFn) {
            barSvc.showBar();
            pevAppSvc.getApplicationsByPerson().then(callbackFn);
        }

        function finishLoad() {
            model.isDataReady = true;
            barSvc.hideBar();
        };

        function isPendingApp() {
            var result = false;

            if (model.applications && helperSvc.arrayContainsByPropertyValue(model.applications, 'volunteerApplicationStatusId', pevApplicationStatuses.WAITING_FOR_SUBMISSION)){
                result = true;
            }

            return result;
        }       

        var activate = function () {
            model.reviewYear = (new Date()).getFullYear();
            loadData(function (data) {
                model.applications = pevAppSvc.data.applications;
                model.getDataSources();

                var statusOptionsDataSource = {
                    dataHolder: model,
                    dataLocationName: 'statuses',
                    svcCallback: codeSvc.getVolunteerAppStatuses,
                    helperCallback: helperSvc.getResults
                };
                dataSourceArray.push(statusOptionsDataSource);

                var societyStatusOptionsDataSource = {
                    dataHolder: model,
                    dataLocationName: 'societyStatuses',
                    svcCallback: codeSvc.getVolunteerAppSocietyStatuses,
                    helperCallback: helperSvc.getResults
                };
                dataSourceArray.push(societyStatusOptionsDataSource);

                var getTrainingPersonDataSource = {
                    dataHolder: model,
                    dataLocationName: 'trainingPersonData',
                    svcCallback: trainingSvc.getCurrentUsersTrainingPersonDto,
                    svcCallbackArguments: [],
                    odataResource: true
                }
                dataSourceArray.push(getTrainingPersonDataSource);

                var getTrainingSessionData = {
                    dataHolder: model,
                    dataLocationName: 'trainingSessions',
                    svcCallback: trainingSvc.getPEVCTrainingSessions,
                    svcCallbackArguments: [model.reviewYear],
                    odataResource: true
                }
                dataSourceArray.push(getTrainingSessionData);
                
                helperSvc.getData(dataSourceArray).then(function () {                  
                    finishLoad();
                });
            });
        }();
    };
    module.controller('pevAppsCtrl', pevAppsCtrl);

}(angular.module('pevApp')));