(function (module) {
    'use strict';

    var rfrAdminSvc = function ($http, $q, odataSvc, amsConst, $odata) {
        var factory = {};

        //odataResourcepaths
        var searchApi = '/GetRfrSearch';

         factory.advancedSearch = function (searchOptions) {
             var oSvc = odataSvc.get();

             if (searchOptions) {
                 var predicates = [];
                 var combinedPredicate;

                 predicates.push(new $odata.Predicate('isCurrent', 'eq', true));

                 if (searchOptions.reviewYear) {
                     predicates.push(new $odata.Predicate('reviewYear', '=', searchOptions.reviewYear));
                 }
                 if (searchOptions.organizationName) {
                     predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                 }
                 if (searchOptions.rfrStatus) {
                     predicates.push(new $odata.Predicate('rfrProcessStatusId', '=', searchOptions.rfrStatus));
                 }
                 if (searchOptions.location) {
                     if (parseInt(searchOptions.location) === 0) {
                         predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                     } else {
                         predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                     }
                 }
                 if (searchOptions.commissions && searchOptions.commissions.length >0) {
                     var combinedCommissionPredicate = [];
                     
                     for(var i=0; i < searchOptions.commissions.length; i++){
                         combinedCommissionPredicate.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissions[i].codeKey)))
                     }

                     if (combinedCommissionPredicate.length > 0) {
                         predicates.push($odata.Predicate.or(combinedCommissionPredicate))
                     }
                 }
                if(searchOptions.reviewType){
                   predicates.push(new $odata.Predicate('reviewTypeCode', 'eq', searchOptions.reviewType));
                }

                 if (predicates.length > 1) {
                     combinedPredicate = $odata.Predicate.and(predicates);
                 }

                 //orderBy neccesary for proper combination of rfrs with multiple commissions as well
                 oSvc.getSource(searchApi).odata()
                   .filter(combinedPredicate)
                   .orderBy("sortName")
                   .query()
                   .$promise
                   .then(oSvc.onRfrSearchSuccess, oSvc.onFailure);
             } else {
                 oSvc.getSource(searchApi).odata()
                     .filter()
                     .orderBy("sortName")
                     .query()
                     .$promise
                     .then(oSvc.onRfrSearchSuccess, oSvc.onFailure);
             }

             return oSvc.getDeferred().promise;
         }

         return {
             advancedSearch: factory.advancedSearch,
         };
     };

     module.factory('rfrAdminSvc', rfrAdminSvc);

})(angular.module('rfr'));