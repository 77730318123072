(function (module) {

    var approvePevCtrl = function ($scope, $uibModalInstance, searchOptions, societyId, alertSvc, trainingSvc, pevc, messageSvc, messageTemplateTypes, contactTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Approve PEVC';
        model.pevc = pevc;
        model.data = { approvedType: null };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.data.approvedType === null)
                return alertSvc.addAlertWarning("You must select a status or cancel.")

            var approvalText = parseInt(model.data.approvedType) === 1 ? "You are about to approve this Pev for visiting rights. This is irreversible." : "You are about to disapprove this Pev for visiting rights. This is irreversible.";
            alertSvc.confirm(approvalText, confirm);

            function confirm() {
                trainingSvc.approvePevVist(model.pevc.trainingPersonId, parseInt(model.data.approvedType), societyId).then(function () {
                    trainingSvc.getPEVCTrainingSearch(searchOptions);

                    if (model.data.approvedType === "1") {
                        var recipients = messageSvc.createRecipientDtos([pevc.personId], contactTypes.VOLUNTEERACTIVITYONLY);
                        messageSvc.buildAndSendMessage(recipients, [], messageTemplateTypes.PEVAPPROVEDFORVISIT, null);
                    }

                    alertSvc.addAlertSuccess("Pev successfully approved for visits.")
                    $uibModalInstance.close();
                })
            }
        }

        var activate = function () {

        }();
    };

    module.controller('approvePevCtrl', approvePevCtrl);

}(angular.module('training')));