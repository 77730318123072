(function (module) {

    var nominationSvc = function ($odata, odataSvc, helperSvc, currentUser, societySeatSvc, amsConst, $http) {
        var factory = {};

        var keyName = 'commissionerNominationId';
        var apiPath = '/CommissionerNomination';
        var searchApiPath = '/GetCommissionerNominationSearch';
        var submitApiPath = amsConst.webApiUrl + '/odata/SubmitCommissionerNominations'
        var approvalApiPath = amsConst.webApiUrl + '/odata/SetCommissionerNominationApprovals'

        var maxSearchResults = 15;

        var roles = {
            EDUCATION_LIAISON: 200,
            COMMISSION_MEMBER: 303,
            ALTERNATE_MEMBER: 304,
            COMMISSION_NOMINATOR: 207
        }

        factory.roles = roles;

        var nominatingRoles = [
            roles.EDUCATION_LIAISON , roles.COMMISSION_NOMINATOR
        ];

        var nominationStatuses = {
            WAITING_FOR_NOMINATION: 1,
            NOMINATED_AS_NEW: 2,
            NOMINATED_TO_RENEW: 3,
            NOT_NOMINATED_TO_RENEW: 4
        }

        factory.nominationStatuses = nominationStatuses;

        factory.data = {
            currentSocietyId: null,
            nominations: [],
            societySeats: [],
            summary: getBlankSummary()
        }

        function getBlankSummary () {
            return {
                seats: 0,                  
                commissioners: getBlankSection(),
                alternates: getBlankSection()
            };
        }

        function getBlankSection () {
            return {
                current: 0,
                renewable: 0,
                notRenewable: 0,
                nominated: 0,
                nominatedAsNew: 0,
                nominatedAsRenewed: 0,
                doNotRenew: 0,
                notDetermined: 0,
                pendingSubmission: 0,
                pendingApproval: 0
            }
        }

        function computeSummary() {
            if (!Array.isArray(factory.data.nominations) || !Array.isArray(factory.data.societySeats)) return;

            var summary = getBlankSummary();

            // If only society's nominations were loaded, then only that society's seats would have been loaded
            summary.seats = factory.data.societySeats.reduce(function (total, societySeat) {
                total += societySeat.seatSize;
                return total;
            }, 0);

            var summary = factory.data.nominations.reduce(function (summary, nomination) {
                var section = nomination.roleId === roles.COMMISSION_MEMBER ? summary.commissioners : summary.alternates;

                if ((nomination.roleId === roles.COMMISSION_MEMBER && nomination.isCurrentCommissioner) ||
                    (nomination.roleId === roles.ALTERNATE_MEMBER && nomination.isCurrentAlternate))
                    section.current++;

                if (nomination.isRenewable)
                    section.renewable++;

                if (!nomination.isRenewable && !nomination.isNewNominee)
                    section.notRenewable++;

                if (!nomination.submittedDate)
                    section.pendingSubmission++;
                else if (nomination.isApproved == null)
                    section.pendingApproval++;

                if (nomination.isApproved !== false) {
                    switch (nomination.commissionerNominationStatusId) {
                        case nominationStatuses.WAITING_FOR_NOMINATION:
                            section.pendingSubmission--;
                            if (nomination.isRenewable)
                                section.notDetermined++;
                            break;
                        case nominationStatuses.NOMINATED_AS_NEW:
                            section.nominated++;
                            section.nominatedAsNew++;
                            break;
                        case nominationStatuses.NOMINATED_TO_RENEW:
                            section.nominated++;
                            section.nominatedAsRenewed++;
                            break;
                        case nominationStatuses.NOT_NOMINATED_TO_RENEW:
                            section.doNotRenew++;
                            break;
                    }
                }

                return summary;
            }, summary);

            factory.data.summary = summary;
        };

        factory.getCurrentNominations = function (societyId, noStoredData) {
            if (!noStoredData) {
                factory.storedData = true;
                factory.data.currentSocietyId = societyId;
            }

            var nominationYear = helperSvc.getCommissionerNominationYear();
            if (societyId && typeof societyId === 'string') {
                societyId = parseInt(societyId);
            }
            var oSvc = odataSvc.get();
            var odata = oSvc.getSource(apiPath, keyName).odata();
            odata.filter('cycleYear', nominationYear);
            if (societyId) {
                odata.filter('societyId', societyId);
            }
            odata.query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc);
            }

            return oSvc.getDeferred().promise;
        };

        function resolveData(oSvc) {
            oSvc.getDeferred().promise.then(function (nominationsData) {
                copySelections(factory.data.nominations, nominationsData);
                factory.data.nominations = nominationsData;
            }).then(function () {
                var noStoredData = true;
                return societySeatSvc.getCurrentSocietySeats(factory.data.currentSocietyId, noStoredData);
            }).then(function (societySeatsData) {
                factory.data.societySeats = societySeatsData;
                computeSummary();
            });
        }

        function copySelections(sourceArray, destinationArray) {
            if (!Array.isArray(sourceArray) || !Array.isArray(destinationArray)) return;
            var selections = sourceArray.filter(function (item) { return item.isSelected; });
            angular.forEach(selections, function (selection) {
                var item = destinationArray.find(function (item) {
                    return item.volunteerId === selection.volunteerId &&
                           item.cycleYear === selection.cycleYear &&
                           item.commissionId === selection.commissionId &&
                           item.roleId === selection.roleId;
                });
                if (item) item.isSelected = true;
            });
        }
         
        factory.getSocietyOptionsByCurrentUser = function (societyOptions) {
            // Return societies from supplied where current user has a nominating role
            societyOptions = societyOptions || [];
            var allowedSocietyOptions = societyOptions.filter(function (society) {
                return currentUser.profile.userRoles.some(function (role) {
                    return (nominatingRoles.indexOf(role.roleId) > -1) &&
                           (role.organizationId == society.codeKey);
                });
            }); 

            return allowedSocietyOptions;
        };

        factory.searchCommissioners = function (searchOptions) {
            var options = searchOptions || {
                searchText: null, 
                societyId: factory.data.currentSocietyId,
                eligibleOnly: false, 
                isAlternate: false, 
                maxResults: maxSearchResults
            }

            var oSvc = odataSvc.get();
            var predicates = [];
            var combinedPredicate;
            var parts = options.searchText ? options.searchText.split(' ').map(function (part) { return part.trim(); }) : [];
            var take = !options.maxResults ? 0 : maxSearchResults;

            var personnameFuncs = [];
            var prefferedFuncs = [];

            for (var i = 0; i < parts.length; i++) {
                var partName = parts[i];

                var func1 = new $odata.Func('indexof', 'personName', partName);
                var func2 = new $odata.Func('indexof', 'preferredName', partName);

                var predicate1 = new $odata.Predicate(func1, '>', -1);
                var predicate2 = new $odata.Predicate(func2, '>', -1);

                predicates.push($odata.Predicate.or([predicate1, predicate2]));
            };

            if (options.societyId) {
                predicates.push(new $odata.Predicate('societyId', '=', parseInt(options.societyId)));
            }

            if (options.eligibleOnly) {
                if (options.isAlternate) {
                    predicates.push(new $odata.Predicate('isAlternateEligible', '=', true));
                } else {
                    predicates.push(new $odata.Predicate('isNominationEligible', '=', true));
                }
            }

            if (predicates.length > 0) {
                combinedPredicate = $odata.Predicate.and(predicates);
            }

            oSvc.getSource(searchApiPath).odata()
                .filter(combinedPredicate)
                .take(take)
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(function (data) {
                angular.forEach(data, function (commissioner) {
                    commissioner.nominationIneligibleReasonJson = angular.fromJson(commissioner.nominationIneligibleReasonJson);
                    commissioner.alternateIneligibleReasonJson = angular.fromJson(commissioner.alternateIneligibleReasonJson);
                });
                return data;
            });
        }

        factory.exportEligibleCommissioners = function (societyId) {
            var searchOptions = {
                searchText: null,
                societyId: societyId || null,
                eligibleOnly: true,
                isAlternate: false,
                maxResults: 0
            }
           
            return factory.searchCommissioners(searchOptions);
        }

        factory.csvHeaders = ['Last Name', 'First Name', 'Middle Name', 'Preferred Name', 'Email', 'Telephone Number',
            'Address 1', 'Address 2', 'Address 3', 'City', 'State Code', 'Province', 'Postal Code', 'Country',
            'Society', 'Past Years as PEV', 'Past Years as TC'];

        factory.csvColumns = ['lastName', 'firstName', 'middleName', 'preferredName', 'emailAddress', 'telephoneNumber',
            'address1', 'address2', 'address3', 'city', 'stateCode', 'province', 'postalCode', 'countryName',
            'societyAbbreviatedName', 'yearsOfPEVExperience', 'yearsOfTCExperience'];

        factory.create = function (commissionerNomination) {
            var data = cleanData(commissionerNomination);
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, keyName, data);
            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                refreshData(oSvc, commissionerNomination);
            });

            return promise;
        }

        factory.update = function (commissionerNomination) {
            var data = cleanData(commissionerNomination);
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, keyName, data);
            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                refreshData(oSvc, commissionerNomination);
            });

            return promise;
        }

        factory.delete = function (commissionerNomination) {
            var data = cleanData(commissionerNomination);
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, keyName, data);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
                refreshData(oSvc, commissionerNomination);
            });
            
            return promise;
        }

        function cleanData(data) {
            var cleanedData = angular.copy(data);

            if (Array.isArray(data)) {
                angular.forEach(data, removeFields);
            } else {
                removeFields(cleanedData);
            }

            return cleanedData;

            function removeFields(item) {
                item.isSelected = undefined;
            }
        }

        factory.submit = function (societyId) {
            var path = submitApiPath;

            var data = { societyId: societyId };

            var promise = $http.post(path, data).then(function (response) {
                return response;
            });

            // Note: it would be more consistent to implement OData actions with $odataresource and then reuse refreshData
            if (isStoredDataStale(societyId)) {
                return promise.then(function () {
                    return factory.getCurrentNominations(factory.data.currentSocietyId);
                });
            } else {
                return promise;
            }
        }

        function refreshData(oSvc, commissionerNomination) {
            if (isStoredDataStale(commissionerNomination.societyId)) {
                oSvc.getDeferred().promise.then(function () {
                    return factory.getCurrentNominations(factory.data.currentSocietyId);
                });
            }
        }

        function isStoredDataStale(societyId) {
            return (factory.storedData && (!factory.data.currentSocietyId || factory.data.currentSocietyId === societyId));
        }

        factory.setApprovals = function (approved, nominations) {
            if (!Array.isArray(nominations)) return;
            approved = approved || false;

            var path = approvalApiPath;
            var commissionerNominationIds = nominations.map(function (nomination) { return nomination.commissionerNominationId; });
            var data = {
                approved: approved,
                commissionerNominationIds: commissionerNominationIds
            };
            var promise = $http.post(path, data).then(function (response) {
                return response;
            });

            // Note: it would be more consistent to implement OData actions with $odataresource and then reuse refreshData
            var societyId = nominations.length ? nominations[0].societyId : null;
            if (isStoredDataStale(societyId)) {
                return promise.then(function () {
                    return factory.getCurrentNominations(factory.data.currentSocietyId);
                });
            } else {
                return promise;
            }
        }

        return {
            roles: factory.roles,
            nominationStatuses: factory.nominationStatuses,
            data: factory.data,
            getCurrentNominations: factory.getCurrentNominations,
            getSocietyOptionsByCurrentUser: factory.getSocietyOptionsByCurrentUser,
            searchCommissioners: factory.searchCommissioners,
            exportEligibleCommissioners: factory.exportEligibleCommissioners,
            csvHeaders: factory.csvHeaders,
            csvColumns: factory.csvColumns,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            submit: factory.submit,
            setApprovals: factory.setApprovals
        };
    };

    module.factory('nominationSvc', nominationSvc);

})(angular.module('nomination'));