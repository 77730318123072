(function (module) {

    var pevAssignmentCtrl = function ($state, $stateParams, barSvc, currentUser, membershipSvc, contactRoleTypes, helperSvc, currentOrganization) {
        var model = this;

        model.multiSocietiesTitle = 'Societies';
        model.societyId = null;
        model.societies = null;
        model.isDataReady = false;

        model.isEmpty = helperSvc.isEmpty;

        model.openSelectedSociety = function (society) {
            // Note: Saving selected society as current organization to be consistent with contacts, rfr.
            // Apparently membershipSvc customizes some of its behavior based on currentOrganization.
            currentOrganization.profile.organizationId = society.organizationId;
            currentOrganization.profile.organizationName = society.organizationName;
            currentOrganization.profile.organizationTypeId = society.organizationTypeId;
            currentOrganization.profile.organizationTypeName = society.organizationTypeName;
            currentOrganization.save();

            $state.go('pevAssignment.summary', {
                societyId: society.organizationId
            });
        }

        model.getDesignationsList = function (role) {
            var designationNames = role.designationViewModels.map(function (designation) {
                return designation.designationName;
            });
            var designationsList = designationNames.join(', ');
            designationsList = designationsList.length > 0 ? '(' + designationsList + ')' : '';

            return designationsList;
        }

        barSvc.showBar();

        activate();

        function activate() {
            model.societyId = $stateParams.societyId ? parseInt($stateParams.societyId) : null;  

            if (!model.societyId) {
                var rolesIncluded = [contactRoleTypes.EDUCATIONLIASON, contactRoleTypes.PEVASSIGNOR];
                membershipSvc.getByUserIdWithoutDesignation(currentUser.profile.userId, true, rolesIncluded, null, true).then(function (data) {
                    var societyRoles = helperSvc.getResults(data);

                    // Normalize societies, roles for display and selection purposes.
                    var societies = [];
                    angular.forEach(societyRoles, function (societyRole) {
                        var society = societies.find(function (society) {
                            return society.organizationId === societyRole.organizationId;
                        });
                        if (society) {
                            var newRole = {
                                roleName: societyRole.roleName,
                                designationViewModels: societyRole.designationViewModels
                            };
                            society.roles.push(newRole);
                        } else {
                            var newSociety = {
                                organizationId: societyRole.organizationId,
                                organizationName: societyRole.organizationName,
                                roles: [{
                                    roleName: societyRole.roleName,
                                    designationViewModels: societyRole.designationViewModels
                                }]
                            };
                            societies.push(newSociety);
                        }
                    });
                    
                    model.societies = societies;

                    if (model.societies.length === 1) {
                        $state.go('pevAssignment.summary', { societyId: model.societies[0].organizationId });
                    }
                    model.isDataReady = true;
                    barSvc.hideBar();
                });
            } else {
                model.isDataReady = true;
                barSvc.hideBar();
            }
        };
    };

    module.controller('pevAssignmentCtrl', pevAssignmentCtrl);

}(angular.module('assignment')));
 