(function (module) {

    var pevProgramAreaEditCtrl = function (programArea, $scope, $uibModalInstance, codeSvc, pevAppSvc, helperSvc, alertSvc) {
        var model = $scope;

        model.title = 'Edit Program Area';
        model.programArea = angular.copy(programArea);
        model.volunteerDisipline = angular.copy(pevAppSvc.volunteerDisciplineData);


        model.save = function () {
            var index = getSocietyIndex(model.programArea.disciplineId);
            model.volunteerDisipline.volunteerProgramAreas.disciplines[index].volunteerDisciplineStatusId = parseInt(model.programArea.volunteerDisciplineStatusId);
            model.volunteerDisipline.volunteerProgramAreas.disciplines[index].volunteerDisciplineStatusName = getStatusName(model.programArea.volunteerDisciplineStatusId);
           
            var application = angular.copy(pevAppSvc.data.application);
            application.applicationJson.volunteerDiscipline = model.volunteerDisipline.volunteerProgramAreas;

            pevAppSvc.update(application).then(onSuccess);
            function onSuccess() {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Society status successfuly saved.");
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        var statusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'statuses',
            svcCallback: codeSvc.getVolunteerDisciplineStatuses,
            helperCallback: helperSvc.getResults
        };

        var activate = function () {
            var dataSourceArray = [
                statusOptionsDataSource
            ];

            helperSvc.getData(dataSourceArray).then(function () {

            });
        }();

        function getSocietyIndex(disciplineId) {
            return model.volunteerDisipline.volunteerProgramAreas.disciplines.findIndex(function (discipline) {
                return discipline.disciplineId == disciplineId;
            });
        }

        function getStatusName(statusId) {
            return model.statuses.find(function (status) {
                return status.codeKey === statusId;
            }).codeName;
        }
    };

    module.controller('pevProgramAreaEditCtrl', pevProgramAreaEditCtrl);

}(angular.module('pevApp')));