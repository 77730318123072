(function (module) {

    var evaluatorReportSvc = function (odataSvc, localStorage, offlineSvc, $q) {
        var apiPath = '/EvaluatorReport';
        var key = 'evaluatorReportId';
        var EVALUATORREPORT_KEY_PREFIX = 'evaluatorReportData:';

        var factory = {};

        factory.data = {
         evaluatorReport: null
        } 

        factory.getEvaluatorReportByReviewTeamId = function (reviewTeamId, noStoredData, isDownloading) {
            var promise;

            if (!offlineSvc.isAppOffline() || isDownloading) {
                var oSvc = odataSvc.get();
                oSvc.getSource(apiPath, key).odata()
                    .expand('evaluatorReportDetailDtos')
                    .filter('reviewTeamId', parseInt(reviewTeamId))
                    .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;
            } else {
                var evaluatorReport = getLocalEvaluatorReport(reviewTeamId);
                var deferred = $q.defer();
                deferred.resolve(evaluatorReport || null);
                promise = deferred.promise;
            }

            if (isDownloading)
                promise.then(function (data) {
                    setLocalEvaluatorReport(reviewTeamId, data);
                    return data;
                });
            else
                if (!noStoredData) {
                    resolveData(promise);
                }

            return promise;
        }

        factory.getEvaluatorReportKey  = function (reviewTeamId) {
            return EVALUATORREPORT_KEY_PREFIX + reviewTeamId;
        }

        function getLocalEvaluatorReport(reviewTeamId) {
            return localStorage.get(factory.getEvaluatorReportKey(reviewTeamId)) || null;
        }

        function setLocalEvaluatorReport(reviewTeamId, evaluatorReport) {
            localStorage.add(factory.getEvaluatorReportKey(reviewTeamId), evaluatorReport);
        }

        factory.create = function (evaluatorReportDto, noStoredData, isDownloading) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, evaluatorReportDto);
            return resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                return factory.getEvaluatorReportByReviewTeamId(evaluatorReportDto.reviewTeamId, noStoredData, isDownloading);
            })
        };

        factory.update = function (evaluatorReportDto) {
            factory.broadcastIsSaving(evaluatorReportDto.reviewTeamId, true);

            if (offlineSvc.isAppOffline()) {
                var deferred = $q.defer();
                deferred.resolve(evaluatorReportDto);
                return deferred.promise.then(function (data) {
                    setLocalEvaluatorReport(evaluatorReportDto.reviewTeamId, evaluatorReportDto);
                    // Make sure side-effects of storing statement in this service's data happen...
                    return factory.getEvaluatorReportByReviewTeamId(evaluatorReportDto.reviewTeamId);
                });
            } else {
                var oSvc = odataSvc.get();
                var resource = oSvc.instantiate(apiPath, key, evaluatorReportDto);
                return resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                    setLocalEvaluatorReport(evaluatorReportDto.reviewTeamId, evaluatorReportDto);
                    return factory.getEvaluatorReportByReviewTeamId(evaluatorReportDto.reviewTeamId);
                }).catch(function (error) {
                    console.log('Error while updating', error);
                    factory.broadcastIsSaving(evaluatorReportDto.reviewTeamId, false);
                });
            }
        }

        factory.delete = function (evaluatorReportDto) {
            // Currently don't delete while offline so this contingency is not handled 
            // If needed, we could save a local copy where isDeleted flag is set
            var model = { evaluatorReportId: evaluatorReportDto.evaluatorReportId };
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
                localStorage.remove(factory.getEvaluatorReportKey(evaluatorReportDto.reviewTeamId));
                if (factory.data.evaluatorReport && factory.data.evaluatorReport.evaluatorReportId === evaluatorReportDto.evaluatorReportId) {
                    factory.data.evaluatorReport = null;
                }
            });

            return promise;
        }

        function resolveData(promise) {
            promise.then(function (data) {
                factory.data.evaluatorReport = data;
            });
        }

        factory.uploadOfflineData = function (reviews) {
            var promises = [];
            // Only upload evaluator reports for reviews that were previously selected and downloaded
            angular.forEach(reviews, function (review) {
                var evaluatorReportDto = getLocalEvaluatorReport(review.reviewTeamId);
                var oSvc = odataSvc.get();
                var resource = oSvc.instantiate(apiPath, key, evaluatorReportDto);
                promises.push(resource.$update(null, oSvc.onSuccess, oSvc.onFailure));
            });

            return $q.all(promises);
        }

        factory.removeOfflineData = function () {
            // Delete working data saved to working storage
            var storageKeys = localStorage.getKeys();
            var evaluatorReportKeys = storageKeys.filter(function (key) {
                return key.startsWith(EVALUATORREPORT_KEY_PREFIX);
            });
            evaluatorReportKeys.forEach(function (key) {
                localStorage.remove(key);
            });
        };

        factory.broadcastIsSaving = function (reviewTeamId, value) {
            var key = 'saving:' + reviewTeamId;
            var data = { reviewTeamId: reviewTeamId, value: value };
            localStorage.add(key, data);
            localStorage.remove(key);
        };

        return {
            data: factory.data,
            getEvaluatorReportByReviewTeamId: factory.getEvaluatorReportByReviewTeamId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            uploadOfflineData: factory.uploadOfflineData,
            removeOfflineData: factory.removeOfflineData,
            getEvaluatorReportKey: factory.getEvaluatorReportKey,
            broadcastIsSaving: factory.broadcastIsSaving 
        };
    };

    module.factory('evaluatorReportSvc', evaluatorReportSvc);

})(angular.module('statement'));