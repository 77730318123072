(function (module) {

    var currentYearSearchModalCtrl = function (appraiseePersonId, searchParams, $scope, $uibModalInstance, appraisalReportSvc, helperSvc, oauth,currentUser) {
        var model = $scope;

        model.title = 'Selected Year Search';
        model.appraiseePersonId = appraiseePersonId;
        model.searchParams = searchParams;
        model.searchResults = [];
        model.isDataReady = false;
        model.getSatisfactionGradeIcon = appraisalReportSvc.getSatisfactionGradeIcon;
        model.getRecommendAsTCIcon = appraisalReportSvc.getRecommendAsTCIcon;
        model.isAdmin = oauth.isAdmin();
        model.isBridgeCordinator = currentUser.profile.userRoles.some(function (r) { return r.roleId == 71; });

        model.close = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        }

        model.exportSimpleReport = function () {
            setSearchParms();
            appraisalReportSvc.exportSimpleReport(model.searchParams, model.isAdmin && !model.isBridgeCordinator);
        };

        function retrieveSearchData(searchParams) {
            appraisalReportSvc.getReports(searchParams)
                .then(data => {
                    model.searchResults = data;
                    model.isDataReady = true;
                    if (model.searchResults.length === 0)
                        model.resultMsg = 'No records found';
                })
                .catch(err => {
                    console.error(err);
                    model.resultMsg = 'Error retrieving data';
                });
        }

        function setSearchParms() {
            var selectedYears = model.searchParams.years;
            model.searchParams = {};
            model.searchParams.years = selectedYears;
            model.searchParams.appraiseePersonId = appraiseePersonId;
        }

        function activate() {
            setSearchParms();
            retrieveSearchData(model.searchParams);
        }

        activate();
    };

    module.controller('currentYearSearchModalCtrl', currentYearSearchModalCtrl);

}(angular.module('evaluatorFollowUp')));