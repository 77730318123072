(function (module) {

    var localStorage = function ($window) {

        var store = $window.localStorage;

        //this is for making sure the data across all tabs is the same on any changes made to this key
        angular.element($window).on('storage', function (event) {
            if (event.key !== undefined && event.key.indexOf('statementData') >= 0) {
                $rootScope.$apply();
            }
        });

        var add = function (key, value) {
            value = angular.toJson(value);
            store.setItem(key, value);
        };

        var get = function (key) {
            var value = store.getItem(key);
            if (value) {
                value = angular.fromJson(value);
            }
            return value;
        };

        var remove = function (key) {
            store.removeItem(key);
        };

        var getKeys = function () {
            var keys = [];
            for (var index = 0; index < store.length; index++) {
                keys.push(store.key(index));
            }
            return keys;
        }

        return {
            add: add,
            get: get,
            remove: remove,
            getKeys: getKeys
        };
    };

    module.factory("localStorage", localStorage);

}(angular.module("security")));