(function (module) {

    var travelAvailabilitySvc = function ($http, $odataresource, $q, odataSvc, helperSvc, amsConst, odataServiceFactory) {
        var config = {
            apiPath: '/VolunteerAvailabilityTravel',
            keyName: 'volunteerAvailabilityTravelId',
            foreignKeyName: 'volunteerId',
            dataItemName: 'volunteerAvailabilityTravel',
            getByForeignKeyPath: '/GetVolunteerAvailabilityTravelByVolunteerId'
        };

        var factory = new odataServiceFactory(config);

        factory.currentYear = getCurrentYearCycle();

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return {};
        };

        factory.getVolunteerAvailabilityTravelByVolunteerId = function (volunteerId, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = oSvc.getPathWithParameter(config.getByForeignKeyPath, config.foreignKeyName, volunteerId)

            oSvc.getSource(apiPath, config.foreignKeyName).odata()
                .filter('year', factory.currentYear)
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.travelAvailability = data;
                });
            }

            return oSvc.getDeferred().promise;
        };


        factory.createTravelAvailability = function (volunteerAvailabilityTravel) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(config.apiPath, config.keyName, volunteerAvailabilityTravel);

            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                return factory.getVolunteerAvailabilityTravelByVolunteerId(volunteerAvailabilityTravel.volunteerId).then(function (data) {
                    factory.data.travelAvailability = data;
                })
            });

            return promise;
        };

        factory.updateTravelAvailability = function (volunteerAvailabilityTravel, noStoredData) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(config.apiPath, config.keyName, volunteerAvailabilityTravel);

            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                return factory.getVolunteerAvailabilityTravelByVolunteerId(volunteerAvailabilityTravel.volunteerId).then(function (data) {
                    factory.data.travelAvailability = data;
                })
            });

            return promise;
        };

        function getCurrentYearCycle() {
            var currentYearCycle = new Date();
            var currentYear = currentYearCycle.getFullYear();

            //if past october 1 go to next year cycle
            if (currentYearCycle.getMonth() >= 9) {
                currentYear++;
            }

            return currentYear;
        }

        function resolveTravelAvailabilityData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.travelAvailability = data;
            });
        }

        return {
            data: factory.data,
            getVolunteerAvailabilityTravelByVolunteerId: factory.getVolunteerAvailabilityTravelByVolunteerId,
            createTravelAvailability: factory.createTravelAvailability,
            updateTravelAvailability: factory.updateTravelAvailability,
            currentYear: factory.currentYear
        };
    };
    module.factory('travelAvailabilitySvc', travelAvailabilitySvc);

})(angular.module('volunteer'));