(function (module) {

    var reportSvc = function (odataSvc, $http, $odata, currentUser, helperSvc, alertSvc) {
        var apiPath = '/Report';
        var key = 'reportId';
        var factory = {};

        factory.data = {
            myReports: []
        };

        factory.getMyReports = function () {
            var oSvc = odataSvc.get();
            var path = '/GetReportListByUser';

            oSvc.getSource(path).odata().query(oSvc.onSuccess, oSvc.onFailure);

            oSvc.getDeferred().promise.then(function (data) {
                factory.data.myReports = data;
            })

            return oSvc.getDeferred().promise;
        };

        factory.openInstructions = function () {
            alertSvc.openPDFModal(
                '/Content/files/My-Reports-Instructions.pdf',
                'Report Instructions'
            );
        };

        return {
            data: factory.data,
            getMyReports: factory.getMyReports,
            openInstructions: factory.openInstructions
        };
    };

    module.factory('reportSvc', reportSvc);

})(angular.module('report'));