(function (module) {

    var rfrCommissionReviewOptionsEditCtrl = function ($scope, $uibModalInstance, commission, commissionIds, rfrSvc, helperSvc, alertSvc, rfrValidationSvc, criteriaTransitionSvc) {
        var model = $scope;
        
        model.dataIsReady = false;
        model.hasCriteriaOption = false;
        model.commission = commission;
        model.commissionIds = commissionIds;
        model.selectedReviewOption = angular.copy(model.commission.jsonContents);
        model.title = "Edit " + model.commission.commissionAbbreviatedName + " Review Option";
        model.formatDate = helperSvc.formatDate;
        model.errors = [];
        model.helpText = 'The institution may suggest an onsite visit start date. A visit normally begins on a <strong>Sunday between mid-September and mid-December</strong>. <br><br/>If the visit is <strong>outside the US</strong>, suggest dates no earlier than October 1 and <strong>avoid</strong> the US Thanksgiving Holiday, the week of November 24, 2025';
        model.criteriaHelpText = 'This Commission has 2 sets of criteria options. Please choose one.'
        model.reviewYear = rfrSvc.data?.rfr?.reviewYear ?? helperSvc.getAcademicYear() + 1;

        activate();

        model.cancel = function() {
            $uibModalInstance.close();
        }

        model.save = function () {         
            helperSvc.validateForm(validateReviewInfo);

            if (model.errors.length > 0) {
                return;
            }

            updateReviewOptions();
            $uibModalInstance.close();
        }

         model.checkForSameVisitDate = function (date) {
            
            if(rfrSvc.data.rfr.rfrCommissionDtos && rfrSvc.data.rfr.rfrCommissionDtos.length > 1){
                for (var i = 0; i < rfrSvc.data.rfr.rfrCommissionDtos.length; i++) {
                    var commissionDto = rfrSvc.data.rfr.rfrCommissionDtos[i];
                    var newDate = new Date(date).toString();
                    var date2 = new Date(commissionDto.jsonContents.suggestedVisitDate).toString();
                    if (commissionDto.commissionId !== model.commission.commissionId && date2 === newDate) {  
                        alertSvc.addModalAlertInfo("The same visit date has been selected for all commissions. If this is a simultaneous visit, a Simultaneous Review Commission Team will need to be selected from the Institutional Review Options section of this page.");
                        break;
                    }
                }
            }
        }

        function activate() {
            //need a better way to do this but there are inconsistencies with getting correct holiday dates all the time
            model.holidayDates = [
                //labor day and thanksgiving
                new Date(2016, 8, 5),
                new Date(2017, 8, 4),
                new Date(2018, 8, 3),
                new Date(2019, 8, 2),
                new Date(2020, 8, 7),
                new Date(2021, 8, 6),
                new Date(2016, 10, 24),
                new Date(2017, 10, 23),
                new Date(2018, 10, 22),
                new Date(2019, 10, 28),
                new Date(2020, 10, 26),
                new Date(2021, 10, 25),
            ];
            
            model.selectedReviewOption.suggestedVisitDate = model.formatDate(model.selectedReviewOption.suggestedVisitDate, true);


            //get criteria options here 
            criteriaTransitionSvc.getCriteriaTransitionConfig(model.commission.commissionId, rfrSvc.data.rfr.reviewYear).then(function(data){
                if (data && data.commissionId) {
                    model.hasCriteriaOption = true;
                    model.criteriaOption = data;
                }

                 model.dataIsReady = true;          
            })
        }

        function validateReviewInfo() {
            model.errors = [];
            var minDateString = '09/01/' + (rfrSvc.data.rfr.reviewYear - 1);
            var maxDateString = '12/31/' + (rfrSvc.data.rfr.reviewYear - 1);
            var minDate = new Date(minDateString);
            var maxDate = new Date(maxDateString);
            var suggDate = model.formatDate(model.selectedReviewOption.suggestedVisitDate, true);

            if (suggDate < minDate || suggDate > maxDate) {
                model.errors.push('Suggested date should be between September and December.');
            }
        }

        function updateReviewOptions() {
            var indexOfCommission = rfrSvc.data.rfr.rfrCommissionDtos.map(function (e) { return e.commissionId }).indexOf(model.commission.commissionId);

            var updatedRfr = angular.copy(rfrSvc.data.rfr);
            updatedRfr.rfrCommissionDtos[indexOfCommission].jsonContents = model.selectedReviewOption;

            //check for simultaneous commission and sync dates
            var convertSimultaneous = false;

            if (updatedRfr.rfrCommissionDtos[indexOfCommission].simultaneousOptionSeq != null) {
                convertSimultaneous = true;
                alertSvc.confirm("Changing this start date will convert the corresponding simultaneous commission's start date to the currently selected one. Proceed?", confirmedFunc);
            } else {
                rfrSvc.update(updatedRfr).then(function () {
                    alertSvc.addAlertSuccess("Review Option successfully updated.");
                    rfrValidationSvc.invokeValidation();
                });
            }

            function confirmedFunc() {
                if(convertSimultaneous)
                    convertSimultaneousCommissionDates(updatedRfr, indexOfCommission);

                rfrSvc.update(updatedRfr).then(function () {
                    alertSvc.addAlertSuccess("Review Option successfully updated.");
                    rfrValidationSvc.invokeValidation();
                });
            }
        }

        function convertSimultaneousCommissionDates(rfr, indexOfCommission) {            
            angular.forEach(rfr.rfrCommissionDtos, function(value, key) {
                if (key !== indexOfCommission && value.simultaneousOptionSeq === rfr.rfrCommissionDtos[indexOfCommission].simultaneousOptionSeq) {
                    value.jsonContents.suggestedVisitDate = rfr.rfrCommissionDtos[indexOfCommission].jsonContents.suggestedVisitDate;
                }
            });
        }
    };

    module.controller('rfrCommissionReviewOptionsEditCtrl', rfrCommissionReviewOptionsEditCtrl);

}(angular.module('rfr')));