(function (module) {

    var viewPevAppPreviewCtrl = function ($scope, $uibModalInstance, pevAppPDFSvc, helperSvc, currentPevAppData) {
        var model = $scope;

        model.title = 'View PEV Application Preview';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
        }

        function activate() {
            model.doc = pevAppPDFSvc.generateDoc(currentPevAppData);
        }

        activate();
    };

    module.controller('viewPevAppPreviewCtrl', viewPevAppPreviewCtrl);

}(angular.module('pevApp')));