(function (module) {

    var collegeDirectorySvc = function (odataServiceFactory, odataSvc, $uibModal, $odata, $http, eventSvc, collegeVerificationTypes) {
        var config = {
            apiPath: '/CollegeDirectory',
            keyName: 'collegeDirectoryId',
            dataCollectionName: 'colleges'
        };
        var ROW_LIMIT = 200;
        var savedFilters = null;
        var defaultFilters = {};
        var factory = {};

        factory.data = {
            colleges: null
        };

        var onFailure = function (error) {
            deferred.resolve(error);
            console.log(error);
        };

        factory.create = function (college) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate('/CollegeDirectory', 'collegeDirectoryId', college);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);
            
            var promise = oSvc.getDeferred().promise.then(function (data) {
                //refresh colleges with saved filters
                factory.advancedSearch(savedFilters, false);
            });

            return promise;
        };

        factory.update = function (college) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate('/CollegeDirectory', 'collegeDirectoryId', college);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            var promise = oSvc.getDeferred().promise.then(function (data) {
                //College Directory page refresh
                factory.advancedSearch(savedFilters, false);
            });

            return promise;
        };

        factory.delete = function (college) {
            var oSvc = odataSvc.get();

            var resource = oSvc.instantiate('/CollegeDirectory', 'collegeDirectoryId', { 'collegeDirectoryId': college.collegeDirectoryId });
            resource.$delete(oSvc.onSuccess, oSvc.onFailure);
            
            var promise = oSvc.getDeferred().promise.then(function (data) {
                //refresh colleges with saved filters
                factory.advancedSearch(savedFilters);
            });

            return promise;
        };

        factory.getCollegeDiretoryById = function (collegeDirectoryId) {
            var oSvc = odataSvc.get();

            var resource = oSvc.instantiate('/CollegeDirectory', 'collegeDirectoryId', { 'collegeDirectoryId': collegeDirectoryId });
            resource.$get(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;

        };

        factory.getCollegeVerificationByOrganizationDetailId = function (orgDetailId) {
            var oSvc = odataSvc.get();

            var predicates = [];
            var combinedPredicate;
            
            predicates.push(new $odata.Predicate('referenceId', '=', orgDetailId));
            predicates.push(new $odata.Predicate('collegeVerificationTypeId', '=', collegeVerificationTypes.INITIALACCREDITATION));

            combinedPredicate = $odata.Predicate.and(predicates);

            oSvc.getSource('/CollegeVerification').odata()
               .filter(combinedPredicate)
               .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.advancedSearch = function (searchOptions, pending) {
            savedFilters = searchOptions;

            var oSvc = odataSvc.get();
            
            var hasNameOption = (searchOptions.collegeName) ? true : false;
            var hasCountryOption = (searchOptions.country) ? true : false;
            var hasStateOption = (searchOptions.state) ? true : false;
            var usePredicates = hasCountryOption || hasStateOption || false;

            var path = '';
            var orderBy = pending ? 'name' : 'collegeName';
            var searchTerm = hasNameOption ? searchOptions.collegeName.replace(',', '').replace('&','~') : '';

            if (hasNameOption && !pending) {
                path = oSvc.getPathWithStringParameter('/SearchCollegeDirectory', 'searchString', searchTerm);
            }
            else if (hasNameOption && pending) {
                path = oSvc.getPathWithStringParameter('/SearchCollegeVerification', 'searchString', searchTerm);
            }
            else if (!hasNameOption && pending) {
                path = '/CollegeVerification';
            }
            else {
                path = '/CollegeDirectory';
            }

            if (usePredicates) {
                var predicates = [];
                var combinedPredicate;

                if (hasCountryOption) {
                    predicates.push(new $odata.Predicate('countryCode', '=', searchOptions.country));
                }

                if (hasStateOption) {
                    predicates.push(new $odata.Predicate('stateCode', '=', searchOptions.state));
                }

                //if (hasNameOption) {
                //    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'collegeName', searchOptions.collegeName)), '>', -1));
                //}

                //not sure if using 'and' for one predicate is bad, need to check
                if (predicates.length >= 1) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(path).odata()
                  .filter(combinedPredicate)
                  .orderBy(orderBy)
                  .query()
                  .$promise
                  .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(path).odata()
                    .orderBy(orderBy)
                    //.take(ROW_LIMIT)  ==> Moved this option to server.
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            var promise = oSvc.getDeferred().promise.then(function (data) {
                if (pending) {
                    factory.data.pending = data;
                    factory.data.colleges = [];
                } else {
                    factory.data.colleges = data;
                    factory.data.pending = [];
                }
            });

            return promise;
        };

        factory.verify = function (collegeVerificationId, collegeDirectoryId, college) {
            var data = {
                collegeVerificationId: collegeVerificationId,
                collegeDirectoryId: collegeDirectoryId,
                collegeDirectoryDto: college
            };
            var verifyPath = '/webapi/odata/VerifyCollege'

            var promise = $http.post(verifyPath, data).then(function (data) {
                //refresh colleges with saved filters
                factory.advancedSearch(savedFilters, true);
            });

            return promise;
        }

        factory.deleteVerificationPending = function (college) {
            var oSvc = odataSvc.get();

            var resource = oSvc.instantiate('/CollegeVerification', 'collegeVerificationId', { 'collegeVerificationId': college.collegeVerificationId });
            resource.$delete(oSvc.onSuccess, oSvc.onFailure);

            var promise = oSvc.getDeferred().promise.then(function (data) {
                //refresh colleges with saved filters
                factory.advancedSearch(savedFilters, true);
            });

            return promise;
        };

        factory.getPendingCount = function () {
            var oSvc = odataSvc.get();

            oSvc.getSource('/CollegeVerification').odata()
                    .count()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.editCollegeInfo = function (college, mode, selectedCollegeVerification) {
            var modalInstance = $uibModal.open({
                controller: 'collegeDirectoryEditCtrl',
                size: 'lg',
                resolve: {
                    college: function () {return college; },
                    mode: function () { return mode; },
                    selectedCollegeVerification: function () { return selectedCollegeVerification; }
                },
                templateUrl: '/Apps/collegeDirectory/templates/collegeDirectoryEdit.html'
            });
        };

        factory.searchCollegeDirectory = function (searchString, countryCode, stateCode) {
            var oSvc = odataSvc.get();

            searchString = searchString.replace('&', '~');

            var usePredicates = countryCode || stateCode || false;
            var path = oSvc.getPathWithStringParameter('/SearchCollegeDirectory', 'searchString', searchString);
            if (usePredicates) {
                var predicates = [];
                var combinedPredicate;

                if (countryCode) {
                    predicates.push(new $odata.Predicate('countryCode', '=', countryCode));
                }

                if (stateCode) {
                    predicates.push(new $odata.Predicate('stateCode', '=', stateCode));
                }

                //not sure if using 'and' for one predicate is bad, need to check
                if (predicates.length >= 1) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(path, config.keyName).odata()
                    .filter(combinedPredicate)
                    .query(oSvc.onSuccess, oSvc.onFailure);

            } else {
                var path = oSvc.getPathWithStringParameter('/SearchCollegeDirectory', 'searchString', searchString);

                oSvc.getSource(path, config.keyName).odata().query(oSvc.onSuccess, oSvc.onFailure);
            }
            return oSvc.getDeferred().promise;
        };

        factory.getDefaultFilters = function () {
            return defaultFilters;
        };

        factory.setDefaultFilters = function (filters) {
            defaultFilters = filters;
        };

        factory.clearDefaultFilters = function () {
            defaultFilters = {
                collegeName: null,
                country: null,
                state: null
            };
        };

        factory.getUrl = function (website) {
            var http = 'http://';

            if (!website) {
                return;
            }

            var partial = website.substring(0, 7);

            return (http === partial) ? website : http + website;
        };

        factory.getFullAddress = function (college) {
            var addressFields = ['address1', 'city', 'stateCode', 'province', 'postalCode', 'countryCode'];
            var defaultText = 'No address entered';
            if (!college) {
                return;
            }
            return getReadableProperty(defaultText, college, addressFields);
        };

        //returns text, or combined string of properties
        var getReadableProperty = function (text, data, fieldArray) {
            var output = text;
            var builtString = output.buildString(data, fieldArray);

            if (builtString) {
                output = builtString;
            }

            return output;
        };

        return {
            data: factory.data,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            getCollegeDiretoryById: factory.getCollegeDiretoryById,
            getCollegeVerificationByOrganizationDetailId: factory.getCollegeVerificationByOrganizationDetailId,
            editCollegeInfo: factory.editCollegeInfo,
            advancedSearch: factory.advancedSearch,
            getPendingCount: factory.getPendingCount,
            verify: factory.verify,
            deleteVerificationPending: factory.deleteVerificationPending,
            getDefaultFilters: factory.getDefaultFilters,
            setDefaultFilters: factory.setDefaultFilters,
            clearDefaultFilters: factory.clearDefaultFilters,
            searchCollegeDirectory: factory.searchCollegeDirectory,
            getUrl: factory.getUrl,
            getFullAddress: factory.getFullAddress
        };
    };
    module.factory('collegeDirectorySvc', collegeDirectorySvc);

})(angular.module('collegeDirectory'));