(function(module) {
    /// moved this to under security.app

    //var sessionExpire = function ($rootScope, Idle, oauth, $location, $uibModal, loginRedirect) {

    //    var startIdleWatch = function () {

    //        function closeModals() {
    //            if ($rootScope.warning) {
    //                $rootScope.warning.close();
    //                $rootScope.warning = null;
    //            }

    //        }
        
    //        if (!Idle.running())
    //            Idle.watch();

    //        $rootScope.$on('IdleStart', function () {
    //            if (oauth.isAuthenticated())
    //            {
    //                console.log('Session time out in 30 sec...');
    //                closeModals();

    //                $rootScope.warning = $uibModal.open({
    //                    templateUrl: '/apps/security/templates/warningDialog.html',
    //                    windowClass: 'modal-danger'
    //                });
    //            }
    //        });

    //        $rootScope.$on('IdleTimeout', function () {
    //            closeModals();
    //            loginRedirect.setLastPath($location.url());
    //            oauth.logout();
    //            $location.path('/login');
    //            $rootScope.isWatchOn = false;
    //            Idle.unwatch();
    //        });

    //        $rootScope.$on('IdleEnd', function () {
    //            closeModals();
    //        });

    //        $rootScope.$on('reset', function () {
    //            Idle.watch();
    //        });
    //    }

    //    return {
    //        startIdleWatch: startIdleWatch
    //    };
    //};

    //module.factory("sessionExpire", sessionExpire);

}(angular.module("security")));