(function (module) {

    var currentProgramsCtrl = function ($stateParams, $scope, $location, $window, programSvc, helperSvc, eventSvc) {
        var model = this;
        var parent = $scope.$parent.model;

        model.title = 'Current Programs';
        model.noProgramsMsg = 'No currently accredited programs';
        model.organizationId = $stateParams.organizationId;

        model.data = programSvc.data;

        var pathRoot = '/#/admin/organization/' + model.organizationId + '/programs';
        
        activate(); 

        function activate(){
            var programs = model.data.currentPrograms;
        }

        model.openProgramPage = function () {
            $window.location.href = pathRoot;
        };

        model.openSelectedProgram = function (program) {
            $window.location.href = pathRoot + '/' + program.programDetailId;
        };

        model.getAccreditationDates = function (program) {
            return helperSvc.replaceEnDash(program.accreditationDates);
        };

    };

    module.controller('currentProgramsCtrl', currentProgramsCtrl);

}(angular.module('organization')));