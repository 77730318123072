(function (module) {

    var addressEditCtrl = function ($scope, $uibModalInstance, $stateParams, id, personAddressSvc, codeSvc, name, eventSvc, alertSvc, oauth) {
        var model = $scope;

        model.title = 'Addresses';
        model.subtitle = name;
        model.isAdmin = oauth.isAdmin();
        model.personId = id || $stateParams.personId;
        model.insertRow = function () {

            var newAddress = {
                personAddressId: 0,
                personId: model.personId,
                addressId: 0,
                isPreferred: model.isPreferred,
                address1: model.address1,
                address2: model.address2,
                note: model.note,
                isDeleted: false
            };

            model.addresses.push(newAddress);
        };

        personAddressSvc.getByPersonId(model.personId).then(function (data) {
            model.addresses = data;
            
            angular.forEach(model.addresses, function (item, key) {
                item.isOutsideUS = item.countryCode != 'US';
            });

            if (model.addresses.length < 1) model.insertRow();
        });

        codeSvc.getCountries().then(function (data) {
            model.countryList = data.value;
        });

        codeSvc.getStates().then(function (data) {
            model.stateList = data.value;
        });

        codeSvc.getAddressTypes().then(function (data) {
            model.addressTypeList = data.value;
        });

        codeSvc.getContactTypes().then(function (data) {
            model.contactTypeOptions = data.value;
        });

        model.getBr = function (inStr) {
            if (inStr == null || inStr == undefined)
                return '';

            return inStr.replace(/\n/g, '<br>');
        }

        model.deleteRow = function (address) {
            address.isDeleted = true;
        };

        model.changePrimary = function (address) {

            angular.forEach(model.addresses, function (x) {
                x.isPreferred = (x === address);
            });
        };

        model.changeUS = function (address) {

            if (address.isOutsideUS)
                address.countryCode = '';
            else
                address.countryCode = 'US';
        };

        model.changeCountry = function (address) {
            if (address.countryCode == 'US') {
                address.isOutsideUS = false;
            }
            else {
                address.isOutsideUS = true;
            }
        }

        model.save = function () {
            var newArr = [];

            angular.forEach(model.addresses, function (item, key) {
                if (item.addressTypeId == 2) {
                    item.addressTitle = null; 
                    item.address1 = null;
                }
                if (item.isOutsideUS) {
                    item.stateCode = null;
                    item.stateName = null;
                } else {
                    item.province = null;
                }
                delete item.isOutsideUS;

                if (item.isDeleted === false || item.personAddressId !== 0)
                    newArr.push(item);
            });

            console.log(newArr);

            personAddressSvc.save(newArr).then(function (data) {
                model.addresses = data.value;
                eventSvc.broadcast('loadPersonGeneral');
                alertSvc.addAlertSuccess("Address successfully updated.");
            });

            $uibModalInstance.close();
        };

        model.cancel = function () {
            $uibModalInstance.close();
        }
    };

    module.controller('addressEditCtrl', addressEditCtrl);

}(angular.module('person')));