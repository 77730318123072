(function (module) {

    var renewalEditCtrl = function ($scope, $uibModalInstance, commissionerNomination, nominationSvc, alertSvc) {
        var model = $scope;

        model.title = 'Nomination Action';

        model.currentCommissionerNomination = commissionerNomination;

        model.renewalOptions = {
            NOT_DETERMINED: -1,
            DO_NOT_RENEW: 0,
            RENEW: 1,
        };

        model.currentInformation = { renewalAction: null };

        model.nominationStatuses = nominationSvc.nominationStatuses;
        
        model.save = function () {
            if (model.currentInformation.renewalAction === model.renewalOptions.NOT_DETERMINED) {
                // Delete nomination record
                nominationSvc.delete(model.currentCommissionerNomination).then(onSuccess, onFailure );             
            } else {
                model.currentCommissionerNomination.commissionerNominationStatusId =
                    (model.currentInformation.renewalAction === model.renewalOptions.RENEW ?
                        model.nominationStatuses.NOMINATED_TO_RENEW :
                        model.nominationStatuses.NOT_NOMINATED_TO_RENEW);
                // Create or update nomination record
                if (model.currentCommissionerNomination.commissionerNominationId === 0)
                    nominationSvc.create(model.currentCommissionerNomination).then(onSuccess, onFailure);    
                else
                    nominationSvc.update(model.currentCommissionerNomination).then(onSuccess, onFailure);             
            }

            function onSuccess () {
                alertSvc.addAlertSuccess("Commissioner nomination successfully updated.");
                model.close();
            }

            function onFailure (error) {
                console.log(error);
                alertSvc.addAlertWarning('Commissioner could not be updated at this time.');
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.tooManyNominations = function () {
            return model.currentInformation.renewalAction == model.renewalOptions.RENEW && model.currentCommissionerNomination.roleId !== 304 &
                nominationSvc.data.summary.commissioners.nominated >= nominationSvc.data.summary.seats;
        };

        (model.activate = function activate() {
            switch (model.currentCommissionerNomination.commissionerNominationStatusId) {
                case model.nominationStatuses.NOMINATED_TO_RENEW:
                    model.currentInformation.renewalAction = model.renewalOptions.RENEW;
                    break;
                case model.nominationStatuses.NOT_NOMINATED_TO_RENEW:
                    model.currentInformation.renewalAction = model.renewalOptions.DO_NOT_RENEW;
                    break;
            }
        })();
    };

    module.controller('renewalEditCtrl', renewalEditCtrl);

}(angular.module('nomination')));