(function (module) {

    var awardsInfoCtrl = function ($scope, $stateParams, $uibModal, awardsInfoSvc, oauth, taskTypes, volunteerDataAccessTypes, volunteerSvc) {
        var model = this;
        model.allowEdit = $scope.$parent.readonly ? false : true;
        model.parent = $scope.$parent.model;
        model.personId = $stateParams.personId ? $stateParams.personId : volunteerSvc.data.volunteer.personId;
        model.title = 'Awards';

        model.editawardsInfo = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/person/templates/awardsInfoEdit.html',
                size: 'lg',
                controller: 'awardsInfoEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        activate();

        function activate() {
            awardsInfoSvc.getAllAwardsByPersonId(model.personId);
            awardsInfoSvc.getAllAwardTypes();
        }

        model.data = awardsInfoSvc.data;
    };

    module.controller('awardsInfoCtrl', awardsInfoCtrl);

}(angular.module('person')));