(function (module) {

    var telephoneEditCtrl = function (id, name, $scope, $stateParams, $location, $uibModalInstance, personTelephoneSvc, codeSvc, eventSvc, alertSvc) {
        var model = $scope;

        model.title = "Phone";
        model.subtitle = name;
        model.personId = id || $stateParams.personId;
        model.telephoneTypes = personTelephoneSvc.codes.telephoneTypes;
        model.telephones = null;

        //reload telephones when done. Need to make better. Should rely only on two way binding
        var loadTelephones = function () {
            //remove old preferred phone
            model.preferredTelephone = null;

            personTelephoneSvc.getByPersonId(model.personId).then(function (data) {
                model.telephones = data;

                //store preferred phone number, should the personTelephoneSvc retrieve this instead?
                for (var i = 0; i < model.telephones.length; i++) {
                    if (model.telephones[i].isPreferred) {
                        model.preferredTelephone = model.telephones[i];
                    }
                }
            });
        };

        codeSvc.getTelephoneTypes().then(function (data) {
            model.telephoneTypeList = data.value;
        });

        model.save = function () {

            var newArr = [];

            angular.forEach(model.telephones, function (x) {

                //exclude newly added line but deleted
                if (x.isDeleted === false || x.personTelephoneId !== 0)
                    newArr.push(x);
            });

            personTelephoneSvc.save(newArr).then(function (data) {
                model.telephones = data;
                eventSvc.broadcast('loadPersonGeneral');
                alertSvc.addAlertSuccess("Phone number successfully updated.");
            });

            $uibModalInstance.close();
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.insertRow = function () {
            var newTelephone = {
                personTelephoneId: 0,
                personId: model.personId,
                telephoneId: 0,
                note: model.note,
                isDeleted: false
            };

            model.telephones.push(newTelephone);
        };

        model.deleteRow = function (telephone) {
            telephone.isDeleted = true;
        };

        model.changePrimary = function (telephone) {

            angular.forEach(model.telephones, function (x) {
                x.isPreferred = (x === telephone);
            });
        };

        loadTelephones();
    };

    module.controller('telephoneEditCtrl', telephoneEditCtrl);

}(angular.module('person')));