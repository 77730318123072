(function (module) {

    var organizationCtrl = function ($uibModal, organizationSvc, codeSvc, barSvc) {
        var model = this;

        model.searchTitle = "Search";
        model.resultTitle = "Results";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.showSpinner = false;

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        model.reset = function () {
            model.searchOptions = null;
            model.organizations = null;
            model.resultMsg = model.defaultSearchMsg;
            model.totalCount = 0;
        };

        model.reset();

        codeSvc.getOrganizationTypes().then(function (data) {
            model.organizationTypeList = data.value;
        });

        codeSvc.getCountries().then(function (data) {
            model.countryList = data.value;
        });

        codeSvc.getStates().then(function (data) {
            model.stateList = data.value;
        });

        model.retrieve = function () {

            model.organizations = null;
            model.resultMsg = null;
            barSvc.showSpinner();

            if (model.searchOptions) {
                var searchOpts = model.searchOptions;

                var isOrgNameDefined = isTextDefined(searchOpts.organizationName);
                var isOrgTypeDefined = isSelectDefined(searchOpts.organizationType);
                var isCountryDefined = isSelectDefined(searchOpts.organizationCountry);
                var isStateDefined = isSelectDefined(searchOpts.organizationState);

                var isOrgNameEmpty = isTextEmpty(searchOpts.organizationName);

                if (isOrgNameDefined || isOrgTypeDefined || isCountryDefined || isStateDefined) {

                    organizationSvc.advancedSearch(model.searchOptions).then(function (data) {

                        barSvc.hideSpinner();
                        model.organizations = data;
                        model.totalCount = model.organizations.length;

                        if (model.oeganizations)
                            model.resultMsg = null;

                        if (model.totalCount == 0)
                            model.resultMsg = "No results found";
                    });
                } else if (isOrgNameEmpty && !isOrgTypeDefined && !isCountryDefined && !isStateDefined) {
                    softReset(model.defaultSearchMsg);
                } else {
                    softReset("Please expand your search");
                }
            }
        };

        model.openOrganization = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/organization/templates/generalEdit.html',
                size: 'lg',
                controller: 'generalEditCtrl',
                resolve: {
                    id: function () {
                        return 0;
                    }
                }
            });
        };

        var softReset = function (message) {
            barSvc.hideSpinner();
            model.organizations = null;
            model.resultMsg = message;
        };

        var isTextDefined = function (property) {
            return property !== undefined && property.trim().length > 2;
        };

        var isTextEmpty = function (property) {
            return property === undefined || (property !== undefined && property.trim().length < 1);
        };

        var isSelectDefined = function (property) {
            return (property);
        };
    };

    module.controller('organizationCtrl', organizationCtrl);

}(angular.module('organization')));