(function (module) {
    var npcManageReviewersTabCtrl = function ($uibModal, $state, $stateParams, $scope, $q, helperSvc, barSvc, alertSvc, oauth, npcSvc, getOrganizationUserSvc,
        reviewTeamSvc, eventSvc, currentUser, teamMemberTypeIds, teamMemberTypeNames, npcReportTemplateSvc, npcProgramChangeReportSvc,
        npcReportTypes, npcStatusIds, npcFinalActionTypeNames, npcSectionTypes) {

        var model = this;

        model.parent = $scope.$parent.$parent.$parent.model;
        model.currentPersonId = parseInt(currentUser.profile.personId);
        model.isAdmin = oauth.isAdmin();
        model.isAdjunct = oauth.isAdjunct();
        model.isDataReady = false;
        model.getLastProgramProgressStatus = npcSvc.getLastProgramProgressStatus;
        model.getColorClass = npcSvc.getColorClass;
        model.npcFinalActionTypeNames = npcFinalActionTypeNames;
        model.slug = 'review';
        model.formatDate = helperSvc.formatDate;
        model.isBeginButtonDisabled = false;

        model.teamMemberTypeNames = teamMemberTypeNames;
        model.npcReportTypes = this.npcReportTypes;
        model.isExcomWithoutConflict = false; //4/17/2023 - Use isExcom instead of this. Give all excom access to the report.
        model.isExcom = false;                //
        model.isFVTeamChair = function () {
            return model.npcData.npcProgramDtos.some(t => t.npcProgramChangeDtos.some(s => s.npcProgramChangeReviewerDtos.some(q => q.personId == model.currentPersonId && q.teamMemberTypeId === model.teamMemberTypeNames.TEAMCHAIR)));
        }

        model.filterByTeamMemberType = function (reviewer) {
            return reviewer.teamMemberTypeId === teamMemberTypeNames.NPCREVIEWER ||
                        reviewer.teamMemberTypeId === teamMemberTypeNames.ADJUNCT;
        }
        model.filterByStatus = function (change) {
            if (change.npcProgramChangeProgressDtos && change.npcProgramChangeProgressDtos.length > 0)
                return change.npcProgramChangeProgressDtos.some(p => p.npcStatusId >= npcStatusIds.ACCEPTEDNOTIFICATION);
            return false;
        }

        model.canAddReviewer = function (programChange, teamMemberType) {

            if (teamMemberType === teamMemberTypeNames.ADJUNCT) {
                return model.npcData.submittedDate && (programChange.npcProgramChangeReviewerDtos.filter(x => x.teamMemberTypeId === teamMemberType).length < 1) &&
                    !programChange.npcProgramChangeProgressDtos.some(progress => progress.npcStatusId >= npcStatusIds.SENTFINALACTIONAFTERCOMMISSION)

            } else {
                return model.npcData.submittedDate &&
                    (programChange.npcProgramChangeReviewerDtos.filter(x => x.teamMemberTypeId === teamMemberType).length < 2) &&
                    !programChange.npcProgramChangeProgressDtos.some(progress => progress.npcStatusId >= npcStatusIds.SENTFINALACTIONAFTERCOMMISSION)
            }
        }

        model.viewNote = function (note) {
            alertSvc.information(note, "View Note")
        }

        model.viewExcomMembers = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcViewExcomMembers.html',
                size: 'lg',
                controller: 'npcViewExcomMembersModalCtrl',
                resolve: {
                    npcData: () => model.npcData,
                    latestGeneralReview: () => model.latestGeneralReview
                }
            });
        };

        model.selectReviewReport = function (change, reviewerId) {
            if (!change.npcProgramChangeReportDtos.some(x => x.teamMemberTypeId === teamMemberTypeNames.NPCREVIEWER && x.submittedDate)) {
                return alertSvc.addAlertWarning("No valid reviewer reports to choose.")
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/selectReviewerReport.html',
                size: 'lg',
                controller: 'selectReviewerReportModalCtrl',
                resolve: {
                    change: () => change,
                    reviewerId: () =>  reviewerId
                }
            });

            modalInstance.result.then(() => {
                refreshData().then(() => {
                    
                    //get report by excom tpye
                    var programDto = model.npcData.npcProgramDtos.find(x => x.npcProgramId === change.npcProgramId);
                    var changeDto = programDto.npcProgramChangeDtos.find(x => x.npcProgramChangeId === change.npcProgramChangeId)
                    var report = changeDto.npcProgramChangeReportDtos.find(x => x.teamMemberTypeId === teamMemberTypeNames.ADJUNCT)

                    model.openReviewReport(report);
                });
            });

        };

        model.editExcomDecision = function (program, programChange, change) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcExcomDecisionEdit.html',
                size: 'md',
                controller: 'npcExcomDecisionEditModalCtrl',
                resolve: {
                    program: () => program,
                    npc: () => model.npcData,
                    programChange: () => programChange,
                    changeJson: () => change
                }
            });

            modalInstance.result.then(() => {
                refreshData();
            });

        }

        model.addReviewer = function (program, change, teamMemberType) {
            var programReview = model.parent.programReviews.find(x => x.programId === program.programId);
            var reviewTeamId = programReview.reviewTeamId;

            //we use the program review reviewteam id.
            //if the first program review does not have one try to find one for COI
            //if none have it then ignore COI
            if (!reviewTeamId) {
                var reviewWithReviewTeamId = model.parent.programReviews.find(x => x.reviewTeamId != null);
                if (reviewWithReviewTeamId)
                    reviewTeamId = reviewWithReviewTeamId.reviewTeamId;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/npc/templates/modal/npcAddReviewer.html',
                size: 'md',
                controller: 'npcAddReviewerModalCtrl',
                resolve: {
                    organizationId: () => model.npcData.organizationId,
                    commissionId: () => model.npcData.commissionId,
                    programReview: () => programReview,
                    npc: () => model.npcData,
                    programChange: () => change,
                    teamMemberTypeId: () => teamMemberType,
                    reviewTeamId: () => reviewTeamId
                }
            });

            modalInstance.result.then(() => {
                refreshData();
            });
        }

        model.removeReviewer = function (reviewer) {
            npcSvc.removeReviewer(reviewer).then(function () {
                refreshData();
            });
        };

        model.getReportStatus = function (report) {
            result = {
                status: "Pending Submission",
                recommendedAction: null
            };

            if (report.submittedDate) {
                var submittedDate = new Date(report.submittedDate);
                var month = submittedDate.getMonth() + 1;
                var day = submittedDate.getDate();
                var year = submittedDate.getFullYear();
                var dateString = month + "/" + day + "/" + year;

                var recommendedAction = report.reportJson.find(x => x.npcSectionTypeId === npcSectionTypes.RECOMMENDATION);
                var recActionString = "N/A";
                if (recommendedAction) {
                    recActionString = recommendedAction.recommendationTypeName;
                }

                result.status = "Submitted on " + dateString;
                result.recommendedAction = recActionString;
            }

            return result;
        }

        model.getRecommendedEndDate = function (report) {
            let recommSection = report.reportJson.filter(t => t.npcSectionTypeId == 5)[0];

            if (recommSection.recommendedTerminationDate)
                return helperSvc.formatDateByFilter(recommSection.recommendedTerminationDate,true);
            return 'N/A';
        }

        model.canEditStatement = function (programId, personId, changeId) {

            var program = model.npcData.npcProgramDtos.find(x => x.programId === programId);
            if (program) {
                var programChange = program.npcProgramChangeDtos.find(x => x.programChangeId === changeId);

                if (programChange) {
                    var report = programChange.npcProgramChangeReportDtos.find(x => x.submittedByPersonId === personId && x.reportType === "Report");

                    if (report && report.submittedDate) {
                        return false;
                    }
                }
            }
            return true;
        }

        model.openReviewReport = function (report) {
            const npcProgramDto = model.npcData.npcProgramDtos.find(npcProgramDto =>
                npcProgramDto.npcProgramChangeDtos.some(npcProgramChangeDto =>
                    npcProgramChangeDto.npcProgramChangeId === report.npcProgramChangeId
                )
            );
            const programReview = model.parent.programReviews.find(programReview => programReview.programId === npcProgramDto.programId && programReview.isCurrent);

            $state.go('npcEditorTool', {
                npcId: model.npcData.npcId,
                npcProgramChangeReportId: report.npcProgramChangeReportId,
                returnStateName: $state.current.name,
                returnStateParams: {
                    npcId: model.npcData.npcId,
                    organizationId: model.npcData.organizationId,
                    view: $stateParams.view
                },
                npc: model.npcData,
                organization: model.parent.organizationData,
                programReview: programReview
            });
        }

        model.createReviewReport = function (change) {
            model.isBeginButtonDisabled = true;
            const reviewer = change.npcProgramChangeReviewerDtos.find(reviewer => reviewer.personId === model.currentPersonId);
            const blankReport = npcReportTemplateSvc.createEmptyTemplate(change, reviewer, npcReportTypes.REVIEWREPORT);

            npcProgramChangeReportSvc.create(blankReport).then(report => {
                refreshData().then(() => {
                    model.openReviewReport(report);
                });
            });
        }

        function refreshData() {
            eventSvc.broadcast('refreshNpcData');
            return npcSvc.getNPCById(model.npcData.npcId).then(
                data => {
                    model.npcData = data;
                    model.parent.npcData = data;
                }
            );
        }

        var activate = function () {
            model.npcData = model.parent.npcData.npc ? model.parent.npcData.npc : model.parent.npcData;

            var dataArray = [];

            var latestGeneralReviewDataSource = {
                dataHolder: model,
                dataLocationName: 'latestGeneralReview',
                svcCallback: reviewTeamSvc.getLatestGeneralReviewTeam,
                svcCallbackArguments: [model.npcData.organizationId, model.npcData.commissionId],
                helperCallback: helperSvc.getResults,
            };
            dataArray.push(latestGeneralReviewDataSource);
            model.canAdjEdit = function (reviewer) {
                if (reviewer.personId === model.currentPersonId && reviewer.teamMemberTypeId === model.teamMemberTypeNames.ADJUNCT) {
                    return model.npcData.npcProgramDtos.some(t => t.npcProgramChangeDtos
                        .some(s => s.npcProgramChangeId === reviewer.npcProgramChangeId && (s.npcProgramChangeReportDtos.filter(p => p.submittedDate).length === 2)))
                }

                return false;
            }
            model.isReadyEditExcomDecision = function (change) {
                return model.npcData.npcProgramDtos.some(t => t.npcProgramChangeDtos
                    .some(s => s.npcProgramChangeId === change.npcProgramChangeId &&
                            (s.npcProgramChangeReportDtos.some(p => p.submittedDate && p.teamMemberTypeId === model.teamMemberTypeNames.ADJUNCT))))
            }

            helperSvc.getData(dataArray).then(function () {
                npcSvc.isExcom(model.npcData.commissionId, model.latestGeneralReview[0].reviewTeamId, currentUser.profile.personId).then(data => {
                    model.isExcom = data;
                    model.isDataReady = true;
                })
            });
        }();
    };
    module.controller('npcManageReviewersTabCtrl', npcManageReviewersTabCtrl);

}(angular.module('npc')));