(function (module) {

    var dictionary = function () {
        var data;
        var key;

        function dictionary(keyName) {
            data = [];
            key = keyName;
        }

        //insert new entry, duplicates are allowed
        dictionary.prototype.insert = function (entry) {
            searchAndInsert(entry);
        }

        //return all data
        dictionary.prototype.getData = function () {
            return data;
        };

        //inserts entry under a new or already existing key
        var searchAndInsert = function (searchObject) {
            var found = false;
            var i = 0;

            //dont look if there is no data
            if (data.length > 0) {
                //look until found or no more data
                while (!found && i < data.length) {
                    //make sure key exists to avoid error
                    if (data[i].key != undefined) {
                        //if key matched, perform insert
                        if (data[i].key === searchObject[key]) {
                            addData(data[i].values, searchObject);
                            found = true;
                        }

                        i++;
                    }
                }
            }

            //if key wasnt found, insert new key and data
            if (!found) {
                addKeyAndData(searchObject);
            }

            return found;
        };

        //add new value and a new key
        var addKeyAndData = function (newEntry) {
            var entry = { key: newEntry[key], values: [newEntry] };
            data.push(entry);
        };

        //add new value under a key that already exists
        var addData = function (array, newEntry) {
            if (array.length) {
                array.push(newEntry);
            }
        };

        return dictionary;
    };

    module.factory('dictionary', dictionary);

}(angular.module('common')));
