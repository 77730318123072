(function (module) {

    var programCtrl = function ($scope, $stateParams, $state, $location, $uibModal, $q, organizationSvc, barSvc, programSvc, helperSvc, eventSvc,
        programReviewSvc, programDetailSvc, programLinkSvc, noteTypes, noteSvc, programSplitMergeSvc) {
        var model = this;

        model.isDataReady = false;
        model.isCurrent = $stateParams.isCurrent === undefined ? true : $stateParams.isCurrent;
        model.organizationId = $stateParams.organizationId;
        model.preSelectedProgramDetailId = $stateParams.programDetailId;
        model.noteTypes = noteTypes;
        model.waitingMsg = 'Select a Program';
        model.noCurrentProgramsMsg = 'No currently accredited programs';
        model.noHistoricalProgramsMsg = 'No historical programs';
        model.data = programReviewSvc.data;

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        barSvc.showBar();
        setNoSelectedProgram();

        var selectedProgramDetailId;

        // Break up into more refreshes
        eventSvc.listen(activate, 'refreshPrograms', $scope);
        if (model.preSelectedProgramDetailId) {
            eventSvc.listen(refreshSelectedProgramData, 'refreshSelectedProgramData', $scope);
            eventSvc.listen(refreshProgramNotes, 'refreshProgramNotes', $scope);
        };

        model.selectProgram = function (program) {
            $state.go('programs.detail', { organizationId: model.organizationId, programDetailId: program.programDetailId, isCurrent: model.isCurrent });
            // removed {notify: false} parameter in order to load state displayname for breadcrumb

            if (model.preSelectedProgramDetailId) {
                selectedProgramDetailId = program.programDetailId;
                model.selectedProgramId = program.programId;
                model.isProgramSelected = false;
                model.waitingMsg = null;

                barSvc.showSpinner();

                var dataSourceArray = [
                    {
                        dataHolder: programReviewSvc,
                        svcCallback: [programReviewSvc.getReviewsByProgramId, programReviewSvc.getReviewsChildrenByReviewTeamId],
                        svcCallbackArguments: [program.programId],
                        dataLocationName: 'data.reviews',
                        helperCallback: helperSvc.getValue,
                        orderByProperty: 'programReviewId'
                    },
                    {
                        dataHolder: programDetailSvc,
                        dataLocationName: 'data.programNameChanges',
                        svcCallback: programDetailSvc.getProgramDetailsByProgramId,
                        svcCallbackArguments: [program.programId, noStoredData = true],
                        odataResource: true
                    },
                    {
                        dataHolder: model,
                        svcCallback: programSvc.getProgramByDetailId,
                        svcCallbackArguments: [program.programDetailId],
                        dataLocationName: 'selectedProgram',
                        helperCallback: helperSvc.getItem,
                        optionalCallback: function (model) {
                            if (model.selectedProgram != null || model.selectedProgram != undefined) {
                                model.isProgramSelected = true;
                            }
                        },
                        optionalCallbackArguments: [model]
                    },
                    {
                        dataHolder: model,
                        svcCallback: programLinkSvc.getProgramLinksByProgramId,
                        svcCallbackArguments: [program.programId],
                        dataLocationName: 'programLinks',
                        helperCallback: helperSvc.getItem
                    },
                    {
                        dataHolder: model,
                        dataLocationName: 'notes',
                        svcCallback: noteSvc.getNotesByReferenceId,
                        svcCallbackArguments: [program.programId, noteTypes.PROGRAM],
                        odataResource: true
                    },
                    {
                        dataHolder: model,
                        dataLocationName: 'programSplitMerges',
                        svcCallback: programSplitMergeSvc.getProgramLinksByProgramId,
                        svcCallbackArguments: [program.programId],
                        odataResource: true
                    }
                ];

                helperSvc.getData(dataSourceArray).then(function () {
                    barSvc.hideSpinner();
                    model.isDataReady = true;
                });
            };
        };

        model.isSelected = function (program) {
            return program.programDetailId === selectedProgramDetailId;
        };

        model.addProgram = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/programEdit.html',
                size: 'lg',
                controller: 'programEditCtrl',
                resolve: {
                    program: function () {
                        return undefined;
                    },
                    organization: function () {
                        return model.organization;
                    }
                }
            });
        };

        model.showPrograms = function (isCurrent) {
            model.programs = [];
            model.isCurrent = isCurrent;

            if (isCurrent) {
                model.programs = model.currentPrograms;
            }
            else {
                model.programs = model.historicalPrograms;
            }
        };

        model.getAccreditationDates = function (program) {
            return helperSvc.replaceEnDash(program.accreditationDates);
        };

        function refreshSelectedProgramData() {
            var programId = model.selectedProgram.programId;
            var program;

            programSvc.getProgramById(programId, model.isCurrent).then(function (data) {
                program = helperSvc.getResults(data);

                if (program) {
                    if (model.isCurrent) {
                        program = program.pop();

                        if (model.selectedProgram.programDetailId !== program.programDetailId) {
                            model.selectedProgram = program;
                            activate();
                        }
                        else {
                            model.selectProgram(program);
                        }
                    }
                    else {
                        model.selectProgram(model.selectedProgram);
                        activate();
                    }
                }
            });
        };

        function refreshProgramNotes() {
            var dataSourceArray = [{
                dataHolder: model,
                dataLocationName: 'notes',
                svcCallback: noteSvc.getNotesByReferenceId,
                svcCallbackArguments: [model.selectedProgram.programId, noteTypes.PROGRAM],
                odataResource: true
            }];

            helperSvc.getData(dataSourceArray);
        }

        function setNoSelectedProgram() {
            model.selectedProgram = {};
            model.isProgramSelected = false;
        }

        activate();

        function activate() {
            model.programs = [];
            var org = organizationSvc.getOrganizationById(model.organizationId);
            var programs = programSvc.searchProgramsByOrganizationId(model.organizationId);

            //when all promises are resolved, process data
            $q.all([programs, org]).then(function (data) {
                org = data.pop();
                programs = data.pop();
                programs = helperSvc.getResults(programs);

                if (org) {
                    model.organization = org;
                    model.title = model.organization.currentOrganizationDetailDto.organizationName;
                }

                if (programs) {

                    var filteredArrays = helperSvc.getArrayByCriteriaFunction(programs, function (object) {

                        if ((object.lastAction === 'NEW' || object.lastAction == 'Not Found') && object.isCurrent === true && object.accreditationDates === null) {
                            return true;
                        }

                        if (object.accreditationDates !== null) {

                            var dates = object.accreditationDates.split('-');
                            var endDateParts = dates[dates.length - 1].split('/');

                            if (endDateParts.length === 3)
                            {
                                var cycleStartDate = helperSvc.getAcademicYearStartDate();
                                var accEndDate = new Date(endDateParts[2], endDateParts[0] - 1, endDateParts[1]);

                                if (accEndDate >= cycleStartDate && object.isCurrent === true) {
                                    return true;
                                }
                            }
                        }

                        return false;
                    });

                    model.currentPrograms = filteredArrays[0];
                    model.historicalPrograms = filteredArrays[1];

                    if (model.isCurrent) {
                        model.programs = model.currentPrograms;
                    }
                    else {
                        model.programs = model.historicalPrograms;
                    }

                    //set programDetailId so that correct record will be pre selected if requested
                    if (model.preSelectedProgramDetailId) {
                        model.isProgramSelected = true;
                        model.selectedProgram.programDetailId = model.preSelectedProgramDetailId;
                    }

                    if (model.isProgramSelected) {
                        for (var i = 0; i < model.programs.length; i++) {
                            var currentProgram = model.programs[i];

                            if (currentProgram.programDetailId == model.selectedProgram.programDetailId) {
                                model.selectProgram(currentProgram);
                                break;
                            }
                        }
                    }
                }

                barSvc.hideBar();
                model.isDataReady = true;
            });
        }
    };

    module.controller('programCtrl', programCtrl);

}(angular.module('program')));