(function (module) {

    var organizationRecognitionSvc = function ($http, $q, odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/OrganizationRecognition',
            keyName: 'organizationRecognitionId',
            foreignKeyName: 'organizationId',
            dataCollectionName: 'recognizingAgencies'
        };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        var factory = new odataServiceFactory(config);

        factory.save = function (agencies) {
            var data = { "Value": agencies };
            var path = '/webapi/odata/SaveOrganizationRecognitions';
            var orgId = agencies.length > 0 ? agencies[0].organizationId : 0;

            var promise = $http.post(path, data).then(onSuccess, onFailure);
          
            promise.then(function () {
                factory.getByForeignKey(orgId);
            });
            
            return promise;
        };

        return {
            data: factory.data,
            getRecognitionByOrganizationId: factory.getByForeignKey,
            create: factory.create,
            delete: factory.delete,
            save: factory.save
        };
    };
    module.factory('organizationRecognitionSvc', organizationRecognitionSvc);

})(angular.module('organization'));