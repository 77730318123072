(function (module) {

    var offlineStatementSvc = function ($window, $stateParams, $q, offlineSvc, oauth, statementStatuses, teamMemberTypeNames, 
        currentUser, statementStorageSvc, evaluatorReportSvc, programReviewSvc, reviewTypeIds, statementTypeIds, alertSvc, helperSvc,
        reviewProcessStatus, typeConstSvc, programAuditDetailSvc) {

        var SENT_DRAFT_STATEMENT_TO_INSTITUTION_NAME = typeConstSvc.getReviewProcessStatusName(reviewProcessStatus.SENT_DRAFT_STATEMENT_TO_INSTITUTION);
        var SENT_FINAL_STATEMENT_TO_INSTITUTION_NAME = typeConstSvc.getReviewProcessStatusName(reviewProcessStatus.SENT_FINAL_STATEMENT_TO_INSTITUTION);
        var SITE_VISIT_REPORT_EVALUATION = typeConstSvc.getReviewProcessStatusName(reviewProcessStatus.SITE_VISIT_REPORT_EVALUATION);

        var factory = {
            data: {
                selectedReviews: []
            }
        };
        
        factory.clearSelectedReviews = function () {
            factory.data.selectedReviews = [];
        }

        factory.canEditOffline = function (review, isUpcoming) {
            // Need offline capability (i.e. service worker api supported and using SSL) to work offline
            if (!offlineSvc.offlineSupported()) return false;

            // Do not allow if assignments aren't complete yet
            if (review.reviewProcessTracking && review.reviewProcessTracking[0] && review.reviewProcessTracking[0].StatusName !== SITE_VISIT_REPORT_EVALUATION) {
                return false;
            }

            // Do not allow if program audit is required but not complete
            if (review.reviewYear >= 2021 && review.reviewTypeCode !== reviewTypeIds.INTERIMREPORT && !review.isProgramAuditComplete && review.programActionCodes.some(programActionCode => programAuditDetailSvc.isAuditable(programActionCode)))
                return false;

            // For now only draft statements that are not ready for final statement creation can be edited offline.
            if (review.statementTypeId && review.statementTypeId !== statementTypeIds.DRAFT) 
                return false;

            if (review.reviewProcessTracking && review.reviewProcessTracking[0] && [SENT_DRAFT_STATEMENT_TO_INSTITUTION_NAME, SENT_FINAL_STATEMENT_TO_INSTITUTION_NAME].indexOf(review.reviewProcessTracking[0].StatusName) > -1)
                return false;

            // Not true anymore??
            if (isUpcoming && review.reviewTypeCode !== reviewTypeIds.INTERIMVISIT)
                return false;


            // Statement must be at a step where current user can edit it
            switch (review.statementStatusId) {
                case statementStatuses.WAITINGFORTCSUBMIT:
                    if (review.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || review.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR)
                        return true;
                    break;
                case statementStatuses.WAITINGFORED1SUBMIT:
                    if (review.teamMemberTypeId === teamMemberTypeNames.EDITOR1)
                        return true;
                    break;
                case statementStatuses.WAITINGFORED2SUBMIT:
                    if (review.teamMemberTypeId === teamMemberTypeNames.EDITOR2)
                        return true;
                    break;
                case statementStatuses.WAITINGFORADJSUBMIT:
                    if (review.teamMemberTypeId === teamMemberTypeNames.ADJUNCT)
                        return true;
                    break;
                case null:
                    // statementStatusId of null means no statement has been created yet
                    if (review.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || review.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR)
                        return true;
                    break;
            }
            return false;
        }

        factory.select = function (review) {
            if (review.isSelected) {
                factory.data.selectedReviews.push(review);
            } else {
                var index = factory.data.selectedReviews.findIndex(function (currentReview) {
                    return currentReview.id == review.id;
                });
                if (index > -1) factory.data.selectedReviews.splice(index, 1);
            }
        };

        factory.getSelectedReviews = function () {
            return factory.data.selectedReviews;
        };

        factory.goToOnlineState = function () {
            // Return to statement page or dashboard as appropriate
            if ($stateParams.statementId) {
                offlineSvc.goOnline('statementTool', { statementId: $stateParams.statementId });
            } else {
                var dashboard = 'userReview';
                offlineSvc.goOnline(dashboard);
            }
        }

        factory.goToOfflineState = function (state, stateParams) {
            if (!offlineSvc.offlineAvailable()) {
                alertSvc.openModalAlert(
                    'Offline mode is unavailable at this time. ' +
                    'This may be because you are browsing in private mode or have set cookies to automatically expire when closing your browser. ' +
                    'Please contact tech support if this problem persists.',
                    'Offline Mode Unavailable');
                return;
            }

            var confirmationMsg = 'Are you sure you want to enter Offline Mode? You will need to wait until all your data has been downloaded before closing your browser.';
            alertSvc.confirm(confirmationMsg, confirmGoOnline);

            function confirmGoOnline() {
                if (factory.data.selectedReviews.length > 10) {
                    var largeDataConfirmationMsg = 'You have selected a large number of reviews to edit offline.  Downloading this much data may take a while.  Do you want to proceed?';
                    alertSvc.confirm(largeDataConfirmationMsg, doGoOffline);
                } else {
                    doGoOffline();
                }
            }

            function doGoOffline() {
                try {
                    if ($window.ga)
                        $window.ga('send', 'pageview', '/offline/statement');
                } finally {
                    try {
                        offlineSvc.goOffline('offlineStatementListing');
                    } catch (error) {
                        console.log(error);
                        alertSvc.addPersistentAlertWarning('Unable to go offline at this time.  Please try again later. If this problem persists, contact tech support.');
                    }
                }
            }
        };

        return {
            data: factory.data,
            clearSelectedReviews: factory.clearSelectedReviews,
            canEditOffline: factory.canEditOffline,
            select: factory.select,
            getSelectedReviews: factory.getSelectedReviews,
            goToOfflineState: factory.goToOfflineState,
            goToOnlineState: factory.goToOnlineState
        };
    };

    module.factory('offlineStatementSvc', offlineStatementSvc);

})(angular.module('statement'));