(function (module) {

    var appraisalEditCtrl = function (appraisee, appraiserType, currentUserReviewTeam, currentUser, teamMemberTypeNames, alertSvc, $scope, $uibModalInstance, surveyTemplateSvc, appraisalSvc, userReviewSvc) {
        var model = $scope;
        model.isDataReady = false;
        model.title = appraisee.teamMemberTypeName + ' Appraisal';
        model.subtitle = appraisee.firstName + ' ' + appraisee.lastName;
        model.appraiseeTeamMemberType = appraisee.teamMemberTypeId;
        model.appraiserTeamMemberType = appraiserType;//this would be an institution user
        model.isTrainingSite = currentUserReviewTeam.organizationName.indexOf("Upper State University") > -1 || currentUserReviewTeam.organizationName.indexOf("Demo Sample University") > -1;

        model.close = function () {
            console.log(model)
            $uibModalInstance.close();
            return false;
        };

        model.save = function () {
            // The modal tag in appraisalEdit.html has an ng-form="form" attribute that creates model.form.
            // Since you can't submit a modal, even with the ng-form directive, manually set the form to submitted.
            // model.form.$submitted is then used in appraisalEdit.html to disable the submit button once it's clicked.
            model.form.$setSubmitted();

            var appraisalDto = packAppraisalDto(model.survey)
            appraisalSvc.create(appraisalDto).then(function(){
                alertSvc.addAlertSuccess("Appraisal successfully submitted.");
                appraisalSvc.getAppraisalsForCurrentUser(userReviewSvc.data.currentReviewTeam.reviewTeamId, currentUserReviewTeam);
                $uibModalInstance.close();

            });
        };
        
        var activate = function () {
            model.appraisalType = appraisalSvc.getAppraisalType(model.appraiserTeamMemberType, model.appraiseeTeamMemberType);
            var templateId = appraisalSvc.getAppraisalSurveyTemplateType(model.appraisalType);
            const TRAINING_APPRAISAL_TEMPLATE = 10;
            const APPRAISAL_TYPE_TRAINING = 8;

            if (model.isTrainingSite) {
                templateId = TRAINING_APPRAISAL_TEMPLATE;
                model.appraisalTypeId = APPRAISAL_TYPE_TRAINING;
            }

            surveyTemplateSvc.getSurveyTemplateById(templateId).then(function(data){
                model.surveyTemplate = data;
                model.survey = angular.fromJson(data.templateJson);
                model.isDataReady = true;
            });
        
        }();


        function packAppraisalDto(survey){
            var appraisalDto = {
                appraisalTypeId: model.appraisalType,
                reviewTeamId: userReviewSvc.data.currentReviewTeam.reviewTeamId,
                reviewYear: appraisee.reviewYear,
                appraiserUserId: currentUser.profile.userId,
                appraiseeVolunteerId: appraisee.volunteerId,
                surveyTemplateId: model.surveyTemplate.surveyTemplateId,
                surveyJson: angular.toJson(model.survey),
            } 
            
            return appraisalDto;
        }

    };

    module.controller('appraisalEditCtrl', appraisalEditCtrl);

}(angular.module('userReview')));