(function (module) {
    var statementTrackingCtrl = function ($q, $scope, reviewTeamId, $uibModalInstance,statementStorageSvc, helperSvc, teamMemberTypeNames) {
        var model = $scope;
        model.title = 'Statement Tracking';
        model.formatDate = helperSvc.formatDate;
        model.isDataReady = false;
        model.teamMemberTypeNames = teamMemberTypeNames;

        model.close = function () {
            $uibModalInstance.close();
        }

        model.getStatementSubmissionStatus = statementStorageSvc.getSubmissionStatus;

        var activate = function () {
            statementStorageSvc.getByReviewTeamId(reviewTeamId).then(function () {
                model.data = statementStorageSvc.data;
                model.isDataReady = true;
            });
        }();

    };
    module.controller('statementTrackingCtrl', statementTrackingCtrl);

}(angular.module('statement')));