(function (module) {
    'use strict';

    var reviewSvc = function ($odataresource, $odata, $q, odataSvc, amsConst) {

        var api = '/Review';
        var searchApi = '/GetReviewsByStatus';
        var key = 'reviewId';
        var factory = {};
        factory.data = { currentReviews: null, pastReviews: null};

        factory.getReviewsByOrganizationId = function (id, isCurrent) {
            id = parseInt(id);
            var searchApiFilter = searchApi + '(organizationId=' + id + ', isCurrent=' + isCurrent + ')';
            var oSvc = odataSvc.get();


            oSvc.getSource(searchApiFilter, key).odata()
                .expand('reviewTeamDtos')
                .expand('reviewSimultaneousOptionDtos', 'reviewSimultaneousOptionCommissionDtos')
                .expand('reviewJointOptionDtos', 'reviewJointOptionProgramDtos')
                .orderBy('reviewYear', 'desc')
                .query(oSvc.onSuccess, oSvc.onFailure);

            resolveReviewData(oSvc, isCurrent);
           
            return oSvc.getDeferred().promise;
        };

        factory.getReviewByReviewId = function (reviewId) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
                .expand('organizationDto', 'currentOrganizationDetailDto')
                .expand('organizationDto', 'currentOrganizationAddressDto')
                .expand('reviewSimultaneousOptionDtos', 'reviewSimultaneousOptionCommissionDtos')
                .expand('reviewJointOptionDtos', 'reviewJointOptionProgramDtos')
                .expand('reviewTeamDtos')
                .get(reviewId)
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);


            return oSvc.getDeferred().promise;
        }

        factory.create = function (review) {
            var oSvc = odataSvc.get();

            review.$save(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        factory.update = function (review) {
            var oSvc = odataSvc.get();

            review.$update(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        factory.delete = function (review) {
            var oSvc = odataSvc.get();

            if (confirm("Are you sure you want to delete this record?")) {
                review.$delete(oSvc.onSuccess, oSvc.onFailure);
                return oSvc.getDeferred().promise;
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        function resolveReviewData(oSvc, isCurrent) {
            oSvc.getDeferred().promise.then(function (data) {
                if (!isCurrent) {
                    factory.data.pastReviews = data;
                } else {
                    factory.data.currentReviews = data;
                }
            });
        };

        return {
            getReviewsByOrganizationId: factory.getReviewsByOrganizationId,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            data: factory.data,
            getReviewByReviewId: factory.getReviewByReviewId
        };
    };

    module.factory('reviewSvc', reviewSvc);

})(angular.module('common'));