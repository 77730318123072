(function (module) {

    var campusCtrl = function ($scope, $uibModal, $http) {

        var model = this;
        model.parent = $scope.$parent.model;

        model.editCampus = function (review) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/program/templates/campusEdit.html',
                size: 'lg',
                controller: 'campusEditCtrl',
                resolve: {
                    review: function () {
                        return review;
                    },
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('campusCtrl', campusCtrl);

}(angular.module('program')));