(function (module) {

    var upcomingAvailabilityCtrl = function ($scope, $uibModal, upcomingAvailabilitySvc, helperSvc, availabilityTypes, volunteerHelperSvc, volunteerDataAccessTypes) {
        var model = this;
        var volunteerDataAccessType = volunteerDataAccessTypes.VOLUNTEERANDADMIN;

        model.parent = $scope.$parent.model;
        model.currentYear = upcomingAvailabilitySvc.currentYear;
        model.title = 'Availability (Upcoming Visit)';
        model.allowEdit = volunteerHelperSvc.allowEdit(volunteerDataAccessType);
        model.formatDate = helperSvc.formatDate;
        model.availabilityTypes = availabilityTypes;

        model.editCountryAvailability = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/upcomingCountryAvailabilityEdit.html',
                size: 'md',
                controller: 'upcomingCountryAvailabilityEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    countries: function () {
                        return model.countries;
                    }
                }
            });
        };

        model.editDateUnavailability = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/upcomingDateAvailabilityEdit.html',
                size: 'lg',
                controller: 'upcomingDateAvailabilityEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.data = upcomingAvailabilitySvc.data;
        model.countries = upcomingAvailabilitySvc.countries;

        model.getCountryName = function(countryCode){
            for (var i = 0; i < model.countries.length; i++) {
                if (countryCode === model.countries[i].countryCode) {
                    return model.countries[i].countryName;
                }
            }
            return "";
        }
    };

    module.controller('upcomingAvailabilityCtrl', upcomingAvailabilityCtrl);

}(angular.module('volunteer')));