(function (module) {

    var awardsInfoEditCtrl = function ($scope, $uibModalInstance, $stateParams, parent, awardsInfoSvc, societyMembershipSvc, volunteerHelperSvc, awardsInfoSvc, alertSvc, oauth, contactRoleTypes, volunteerSvc) {
        var model = $scope;
        var activeItemName = "activeItem";

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.personId = parseInt(model.parent.personId);
        model.currentYear = new Date();
        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        var isAdmin = oauth.isAdmin();

        model.save = function () {
            model[activeItemName].personId = model.personId;

            if (model.isCreateMode) {
                awardsInfoSvc.create(model[activeItemName]).then(function () {
                    alertSvc.addModalAlertSuccess("Award succesfully added.");
                    model.toggleModes(model);
                });
            } else if (model.isUpdateMode) {
                awardsInfoSvc.update(model[activeItemName]).then(function () {
                    alertSvc.addModalAlertSuccess("Award succesfully updated.");
                    model.toggleModes(model);
                });
            }
        };

        model.edit = function (award) {
            volunteerHelperSvc.copyDataAndSetModes(model, activeItemName, award);
            model[activeItemName].awardId ? model[activeItemName].awardId = model[activeItemName].awardId.toString() : null
            model[activeItemName].awardDate = Date.parse(model[activeItemName].awardDate);
        };

        model.delete = function (award) {
            alertSvc.confirmDelete(award.awardName, deleteFunc, award);

            function deleteFunc () {
                awardsInfoSvc.delete(award).then(function () {
                    alertSvc.addModalAlertSuccess("Award succesfully deleted.");
                });
            }
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.toggleModes = function () {
            volunteerHelperSvc.toggleModes(model, activeItemName);
        };

        model.isUserAllowed = function (award) {
            return isAdmin || oauth.hasRole(award.organizationId, contactRoleTypes.EDUCATIONLIASON);
        };

        activate();

        function activate() {
            model.data = awardsInfoSvc.data;

            awardsInfoSvc.getAllAwardTypes();

            if (isAdmin) {
                model.societyData = societyMembershipSvc.data;
            }
            else {
                model.societyData = { memberSocietyOptions: volunteerSvc.getSocietyOptionsByCurrentUser(societyMembershipSvc.data.memberSocietyOptions) };
            }
        }
    };

    module.controller('awardsInfoEditCtrl', awardsInfoEditCtrl);

}(angular.module('person')));