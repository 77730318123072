(function (module) {

    var addToken = function (currentUser, $q, currentOrganization, environmentSvc) {

        return {
            request: function (config) {
                
                if (environmentSvc.isDev() && config.url.indexOf('/webapi/') >= 0) {
                    //Need to exclude because some functions use this - evaluator followup
                    if (config.url.indexOf("localhost:3000") === -1)
                        config.url = environmentSvc.getDevApiUrl() + config.url;
                }

                if (currentUser.profile.token) {
                    config.headers.Authorization = "Bearer " + currentUser.profile.token;
                }

                if (currentOrganization.profile.organizationId > 0) {
                    config.headers['OrganizationId'] = currentOrganization.profile.organizationId;
                }

                return $q.when(config);
            }
        };
    };

    module.factory("addToken", addToken);
    module.config(function ($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
        $httpProvider.interceptors.push("addToken");
    });

})(angular.module("security"));