(function (module) {

    var rrProgramEditCtrl = function (program, $scope, $uibModalInstance) {
        var model = $scope;

        model.program = angular.copy(program);
        model.title = (model.program ? 'Edit' : 'Add') + ' Program';

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            // do something
            model.close();
        };
    };

    module.controller('rrProgramEditCtrl', rrProgramEditCtrl);

}(angular.module('readinessReview')));