(function (module) {

    var npcExcomDecisionEditModalCtrl = function (program, npc, programChange, $scope, $q, $uibModalInstance, helperSvc, codeSvc, alertSvc, npcSvc, npcChangeTypeIds, npcFinalActionTypeIds, npcStatusIds) {
        var model = $scope;

        model.title = 'Edit Excom Decision for ' + program.programDetailDto.programName;
        model.isSaving = false;
        model.formatDate = helperSvc.formatDate;
        model.npcChangeTypeIds = npcChangeTypeIds;
        model.programChange = programChange;
        model.isFVExcomDecision = programChange.npcProgramChangeProgressDtos.some(t => t.npcStatusId === npcStatusIds.FOCUSEDVISIT)

        model.data = null;

        if (!model.isFVExcomDecision) {
            model.data = {
                npcActions: [],
                excomFinalActionId: model.programChange.excomDecisionJson ? model.programChange.excomDecisionJson.excomFinalActionId : null,
                note: model.programChange.excomDecisionJson ? model.programChange.excomDecisionJson.note : '',
                terminationDate: model.programChange.excomDecisionJson && model.programChange.excomDecisionJson.terminationDate ? helperSvc.formatDate(model.programChange.excomDecisionJson.terminationDate, true) : null
            }
        }
        else {
            model.data = {
                npcActions: [],
                excomFinalActionId: model.programChange.excomFVDecisionJson ? model.programChange.excomFVDecisionJson.excomFinalActionId : null,
                note: model.programChange.excomFVDecisionJson ? model.programChange.excomFVDecisionJson.note : '',
                terminationDate: model.programChange.excomFVDecisionJson && model.programChange.excomFVDecisionJson.terminationDate ? helperSvc.formatDate(model.programChange.excomFVDecisionJson.terminationDate, true) : null
            }
        }

        model.cancel = function () {
            $uibModalInstance.dismiss();
        };

        model.save = function () {
            model.isSaving = true;
            var addFVProgress = false;

            if (!model.isFVExcomDecision) {
                model.programChange.excomDecisionJson = createExcomJson(model.programChange.excomDecisionJson, false);


                if (model.programChange.excomDecisionJson.excomFinalActionId === npcFinalActionTypeIds.CONDUCTAFOCUSEDVISIT)
                    addFVProgress = true;

                //if (!model.programChange.excomDecisionJson) {
                //    model.programChange.excomDecisionJson = {
                //        excomFinalActionId: null,
                //        note: null,
                //        terminationDate: null
                //    }
                //}
                //model.programChange.excomDecisionJson.excomFinalActionId = model.data.excomFinalActionId;
                //model.programChange.excomDecisionJson.note = model.data.note;
                //if (model.programChange.npcType === 'Termination') {
                //    model.programChange.excomDecisionJson.terminationDate = model.data.terminationDate;
                //}

                //if (model.programChange.excomDecisionJson.excomFinalActionId === npcFinalActionTypeIds.CONDUCTAFOCUSEDVISIT)
                //    addFVProgress = true;
            }
            else {
                model.programChange.excomFVDecisionJson = createExcomJson(model.programChange.excomFVDecisionJson,true);
                //if (!model.programChange.excomFVDecisionJson) {
                //    model.programChange.excomFVDecisionJson = {
                //        excomFinalActionId: null,
                //        note: null,
                //        terminationDate: null
                //    }
                //}

                //model.programChange.excomFVDecisionJson.excomFinalActionId = model.data.excomFinalActionId;
                //model.programChange.excomFVDecisionJson.note = model.data.note;
                //if (model.programChange.npcType === 'Termination') {
                //    model.programChange.excomFVDecisionJson.terminationDate = model.data.terminationDate;
                //}

            }

            npcSvc.updateNpcProgramChange(model.programChange).then(function () {

                if (addFVProgress) {
                    var now = new Date();
                    var newProgressDto = {
                        npcProgramChangeId: model.programChange.npcProgramChangeId,
                        npcStatusId: npcStatusIds.FOCUSEDVISIT,
                        npcStatusUpdatedDate: now,
                        note: ''
                    };

                    npcSvc.manageStatus(newProgressDto).then(function () {
                        alertSvc.addAlertSuccess(`Status Sucesfully updated.`);
                        $uibModalInstance.close();
                    });
                }

                $uibModalInstance.close();
            });
        }
        function createExcomJson(excomDecJson, isFVExcom) {
            if (!excomDecJson) {
                var excomDecJson = {
                    excomFinalActionId: null,
                    note: null,
                    terminationDate: null
                }
            }
            excomDecJson.excomFinalActionId = model.data.excomFinalActionId;
            excomDecJson.note = model.data.note;
            if (model.programChange.npcType === 'Termination') {
                excomDecJson.terminationDate = model.data.terminationDate;
            }
            return excomDecJson;
        }

        function activate() {

            codeSvc.getNpcActionTypes().then(function (data) {
                model.data.npcActions = data.value;
                model.isDataReady = true;
            });
        };

        activate();
    };
    module.controller('npcExcomDecisionEditModalCtrl', npcExcomDecisionEditModalCtrl);

}(angular.module('npc')));