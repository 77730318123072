(function (module) {

    var statementDifferenceSvc = function (deepObjectDifferenceSvc, differenceServiceFactory) {

        const comparisonTypes = deepObjectDifferenceSvc.comparisonTypes;

        const placeholderText = '<p>Click to add text...</p>';

        const options = [
            {
                property: 'statementDetailDtos', comparisonType: comparisonTypes.OBJECTARRAY, key: 'programId', subKey: 'statementCategoryId', options: [
                    {
                        property: 'statementJson', comparisonType: comparisonTypes.OBJECTARRAY, key: 'statementFindingTypeId', options: [
                            {
                                property: 'findings', comparisonType: comparisonTypes.OBJECTARRAY, key: 'key', options: [
                                    {
                                        property: 'criteria', comparisonType: comparisonTypes.OBJECT, options: [
                                            { property: 'text', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                            { property: 'lastVisitText', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                            {
                                                property: 'response', comparisonType: comparisonTypes.OBJECT, options: [
                                                    {
                                                        property: 'interimStatus', comparisonType: comparisonTypes.OBJECT, options: [
                                                            { property: 'findingStatusTypeId', comparisonType: comparisonTypes.SIMPLE },
                                                            { property: 'updatedFindingTypeId', comparisonType: comparisonTypes.SIMPLE },
                                                            { property: 'updatedFindingTypeName', comparisonType: comparisonTypes.TEXT },
                                                            { property: 'statusDesc', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                                            { property: 'nextReviewText', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText }
                                                        ]
                                                    },
                                                    {
                                                        property: 'sevenDay', comparisonType: comparisonTypes.OBJECT, options: [
                                                            { property: 'text', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText }
                                                        ]
                                                    },
                                                    {
                                                        property: 'thirtyDay', comparisonType: comparisonTypes.OBJECT, options: [
                                                            { property: 'text', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                                            { property: 'findingStatusTypeId', comparisonType: comparisonTypes.SIMPLE },
                                                            { property: 'updatedFindingTypeId', comparisonType: comparisonTypes.SIMPLE },
                                                            { property: 'updatedFindingTypeName', comparisonType: comparisonTypes.TEXT },
                                                            { property: 'statusDesc', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                                            { property: 'nextReviewText', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText }
                                                        ]
                                                    },
                                                    {
                                                        property: 'postThirtyDay', comparisonType: comparisonTypes.OBJECT, options: [
                                                            { property: 'text', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                                            { property: 'findingStatusTypeId', comparisonType: comparisonTypes.SIMPLE },
                                                            { property: 'updatedFindingTypeId', comparisonType: comparisonTypes.SIMPLE },
                                                            { property: 'updatedFindingTypeName', comparisonType: comparisonTypes.TEXT },
                                                            { property: 'statusDesc', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText },
                                                            { property: 'nextReviewText', comparisonType: comparisonTypes.TEXT, placeholder: placeholderText }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    { property: 'newProgramStartDate', comparisonType: comparisonTypes.DATETIME },
                    { property: 'terminationDate', comparisonType: comparisonTypes.DATETIME }
                ]
            }
        ];

        return new differenceServiceFactory({ "objectDifferenceOptions": options });
    };

    module.service('statementDifferenceSvc', statementDifferenceSvc);

})(angular.module('statement'));