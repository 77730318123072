(function (module) {

    var taskSvc = function ($http, $odataresource, $q, odataSvc) {
        var apiPath = '/GetTasksByUser';
        var keyName = 'taskId';
        var factory = {};


        factory.getTasksByUser = function (userId) {
            var oSvc = odataSvc.get();
            var path = apiPath + '(userId=' + userId + ')';

            oSvc.getSource(path, keyName).odata()
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        return {
            getTasksByUser: factory.getTasksByUser
        };
    };
    module.factory('taskSvc', taskSvc);

})(angular.module('security'));