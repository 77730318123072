(function (module) {

    var messagesCtrl = function (messageHelperSvc, messageSvc, currentUser, helperSvc, $scope, barSvc) {
        var model = this;

        model.title = 'Recent Messages';
        model.filtersTitle = 'Filters';
        model.open = messageHelperSvc.open;
        model.getDateText = messageHelperSvc.getDateText;
        model.messagesLimit = 50;
        model.messages = null;

        model.searchOptions = {
            searchText: null,
            startDate: null,
            endDate: null
        };

        barSvc.listenForShow(function () { model.showSpinner = true; });
        barSvc.listenForHide(function () { model.showSpinner = false; });

        model.retrieveMessages = function () {
            barSvc.showSpinner();
            helperSvc.getData([messagesDataSource]).then(function () {
                barSvc.hideSpinner();
            });
        };

        var messagesDataSource = {
            dataHolder: model,
            dataLocationName: 'messages',
            svcCallback: messageSvc.getMessagesByPersonId,
            svcCallbackArguments: [currentUser.profile.personId, model.messagesLimit, model.searchOptions],
            odataResource: true
        };

        $scope.$watch('model.searchOptions', function () {
            if ((model.searchOptions.startDate && model.searchOptions.endDate) || (!model.searchOptions.startDate && !model.searchOptions.endDate)) {
                model.retrieveMessages();
            }
        }, true);
    };

    module.controller('messagesCtrl', messagesCtrl);

}(angular.module('messages')));