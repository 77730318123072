(function (module) {

    var trainingInfoCtrl = function ($scope, $uibModal, trainingInfoSvc, volunteerDataAccessTypes, volunteerHelperSvc) {
        var model = this;
        var volunteerDataAccessType = volunteerDataAccessTypes.SOCIETYLIASONANDTRNMGRANDADMIN;

        model.allowEdit = volunteerHelperSvc.allowEdit(volunteerDataAccessType);
        model.parent = $scope.$parent.model;
        model.title = 'Training Information';

        model.editTrainingInfo = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/trainingInfoEdit.html',
                size: 'lg',
                controller: 'trainingInfoEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.data = trainingInfoSvc.data;
    };

    module.controller('trainingInfoCtrl', trainingInfoCtrl);

}(angular.module('volunteer')));