(function (module) {
    var manageMentorsCtrl = function ($uibModal, helperSvc, codeSvc, volunteerSvc, alertSvc, oauth, trainingSvc, contactRoleTypes, commissionAbbreviations) {

        var model = this;
        model.commissionNames = commissionAbbreviations;
        var selectedId = null;
        
        model.isAdmin = oauth.isAdmin();
        model.isDataReady = false;
        model.searchOptions = { societyId: null, trainingYear: new Date(trainingSvc.data.trainingSessions[0].startDate).getFullYear()}
        model.addMentors = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/addMentors.html',
                size: 'md',
                controller: 'addMentorCtrl',
                resolve: {
                    societyOptions: function () {
                        return model.societyOptions;
                    },
                    searchOptions: function(){
                        return model.searchOptions;
                    }
                }
            });
        };

        model.assignPEVCs = function (mentor) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/assignPEVCsMentor.html',
                size: 'lg',
                controller: 'assignPEVCsMentorCtrl',
                resolve: {
                    mentor: function () {
                        return mentor;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.getSocietyName = function(societyId){
            var society = helperSvc.getFirstMatch(model.societyOptions, 'codeKey', societyId);
            return society.codeName;
        }

        model.filterMentors = function(){
            trainingSvc.getTrainingMentors(model.searchOptions);
        }

        model.isMySociety = function (societyId) {
            if (model.mySocieties.indexOf(societyId) > -1)
                return true;
            return false;
        }

        model.deleteMentor = function (mentor) {           
            alertSvc.confirmDelete(mentor.personDto.firstName + " " + mentor.personDto.lastName + " (Mentor)", deleteMentor);

            function deleteMentor() {
                trainingSvc.deleteMentor(mentor.trainingMentorId).then(function () {
                    alertSvc.addAlertSuccess("Mentor succesfully deleted.");
                    trainingSvc.getTrainingMentors(model.searchOptions);
                });
            }
        };

        model.csvHeaders = ["First Name", "Last Name", "Email", "Society Name", "Pevcs/Observers"];
        model.csvColumns = ["firstName", "lastName", "email", "societyName", "pevcs"];
        model.export = function () {
            model.showSpinnerExport = true;
            var exportData = [];
            var mentors = angular.copy(model.data.trainingMentorList);
            var finalList = [];

            angular.forEach(mentors, function (mentor) {

                //get pevc list
                var pevcsAssigned = helperSvc.getFilteredArray(model.data.pevcs, 'trainingMentorId', mentor.trainingMentorId, true);
                

                pevsAsStrings = "";
                angular.forEach(pevcsAssigned, function (pevc) {
                    pevsAsStrings += pevc.firstName + " " + pevc.lastName +  "; "
                })

                var tempPevc = {
                    firstName: mentor.personDto.firstName,
                    lastName: mentor.personDto.lastName,
                    email: mentor.emailAddress,
                    societyName: model.getSocietyName(mentor.societyId),
                    pevcs: pevsAsStrings
                };

                finalList.push(tempPevc);
            });

            model.showSpinnerExport = false;
            return finalList;
        };

        var activate = function () {
            var dataArray = [];
            
            var societyOptionsDataArray = {
                dataHolder: model,
                svcCallback: [codeSvc.getMemberSocieties],
                dataLocationName: 'societyOptions',
                helperCallback: helperSvc.getValue
            }
            dataArray.push(societyOptionsDataArray);

            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;
                model.mySocieties = model.isAdmin ? model.societyOptions.map(function (society) { return society.codeKey; }) : oauth.getOrganizationsByUserRoleId(contactRoleTypes.EDUCATIONLIASON).map(function (organization) {
                    return organization.organizationId;
                });

                if(!model.isAdmin){
                   model.mySocieties = trainingSvc.getMyTrainingRoles();                   

                    model.societyOptions = helperSvc.getFilteredArray(model.societyOptions, 'codeKey', model.mySocieties, true);
                    if(model.societyOptions.length === 1){
                        model.searchOptions.societyId = model.societyOptions[0].codeKey                  
                    }
                }else{
                    model.mySocieties = model.societyOptions.map(function(society){ return society.codeKey; })
                }
                trainingSvc.getTrainingMentors(model.searchOptions).then(function (data) {
                    model.isDataReady = true;

                });
            });
        }();
    };
    module.controller('manageMentorsCtrl', manageMentorsCtrl);

}(angular.module('training')));