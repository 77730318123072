(function (module) {

    var logClientSvc = function (odataSvc, helperSvc, offlineSvc) {
        var apiPath = '/LogClient';
        var key = 'id';
        var factory = {};
        var prevLoggedTime = null;
        var prevLoggedData = null;

        factory.insert = function (moduleName, message, trackData) {
            // Disable logging of bugs on server if offline
            if (offlineSvc.isBrowserOffline() || offlineSvc.isAppOffline()) {
                return;
            }

            //to avoid infinite looping exception
            if ((Date.now() - prevLoggedTime) < 2000)
            {
                if (trackData === undefined || prevLoggedData === trackData)
                {
                    return;
                }
            }

            var oSvc = odataSvc.get();

            if (moduleName == undefined || moduleName == null)
                moduleName = 'Undefined';

            if (message == undefined || message == null)
                message = 'Undefined';

            if (trackData == undefined || trackData == null)
                trackData = 'Undefined';

            var logData = { id: 0, module: moduleName, message: message, track: trackData };
            var resource = oSvc.instantiate(apiPath, key, logData);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            prevLoggedTime = Date.now();
            prevLoggedData = trackData;

            return oSvc.getDeferred().promise;
        };

        return {
            insert: factory.insert,
        };
    };
    module.factory('logClientSvc', logClientSvc);

})(angular.module('common'));