(function (module) {
    'use strict';

    var supplementalStatementPDFSvc = function (statementStorageSvc, programReviewFinalActionSvc, helperSvc, statementSvc, commissionTypes, monthNames, actionCodes, statementStatuses, statementTypeIds) {

        var factory = { };

        function getCommissionFullName(id) {
            return !statementStorageSvc.data.currentReviewTeam.commissionFullName ? commissionTypes.find(function (c) { return c.id === id }).name : statementStorageSvc.data.currentReviewTeam.commissionFullName;
        }

        function getDate(statementTypeId = null) {
            statementTypeId = statementTypeId || statementStorageSvc.data.statement.statementTypeId;

            var date = statementStorageSvc.data.statement.submittedTimestamp;
 
            if (!date) {
                // Current statement not submitted? Get submission date of last version sent to institution
                var lastStatementSent = statementStorageSvc.data.statementHistory.find(function (statement) {
                    return statement.statementTypeId === statementTypeId &&
                        (statement.statementStatusId === statementStatuses.SENTTOINSTITUTION || statement.statementStatusId === statementStatuses.RESENTTOINSTITUTION);
                });
                date = lastStatementSent && lastStatementSent.submittedTimestamp;
            }

            // No versions sent to institution? Must be sending it now; use current date.
            date = date ? new Date(date) : new Date();

            return '{0} {1}, {2}'.format(monthNames[date.getUTCMonth()], date.getUTCDate(), date.getUTCFullYear());
        }

        function loadConstants(statementTypeId = null) {
            factory.ORG_NAME = statementStorageSvc.data.currentReviewTeam.organizationName;
            factory.COMMISSION_NAME = getCommissionFullName(statementStorageSvc.data.currentReviewTeam.commissionId);
            factory.COMMISSION_ABBREV = statementStorageSvc.data.currentReviewTeam.commissionName;
            factory.REVIEW_YEAR = statementStorageSvc.data.currentReviewTeam.reviewYear;
            factory.NGR_YEAR = statementStorageSvc.data.currentReviewTeam.ngrYear;
            factory.YEAR_SPAN = (parseInt(factory.REVIEW_YEAR) - 1) + '–' + factory.REVIEW_YEAR;
            factory.letterHead = [
                logo,
                {
                    text: '\n\n\n {0} \n {1} \n\n\n\n'.format('415 North Charles Street Baltimore, MD 21201', '+1.410.347.7700 www.abet.org'),
                    style: ['smaller', 'camptonLight'],
                    alignment: 'center'
                },
                {
                    text: '{0} \n\n\n'.format(getDate(statementTypeId)),
                    style: ['small', 'camptonLight', 'orange']
                }
            ];

            factory.APPM_SECTIONS = {
                PublicRelease: 'I.A.',
                ChangesDuringAccreditation: 'I.F.',
                InstitutionCatalogs: 'I.A.6.',
                Appeals: 'II.D.',
                DueProcess: 'I.E.9.-10'
            };
        }

        var styles = {
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            orange: {
                color: '#FF6C2C'
            }
        };

        var logo = {
            image: 'ABET_CMYK.png',
            width: 50,
            alignment: 'center'
        };

        function getDocDefinition(content, title) {
            return {
                info: {
                    title: title
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 40, 70, 70],
                footer: function () {
                    return {
                        columns: [
                            {
                                text: [
                                    'Applied and Natural Sciences Accreditation Commission, Computing Accreditation Commission\nEngineering Accreditation Commission, Engineering Technology Accreditation Commission'
                                ],
                                alignment: 'center',
                                style: ['smaller', 'camptonLight'],
                                width: '*'
                            }
                        ],
                        margin: [100, 20, 100, 0]
                    };
                }
            };
        }

        factory.generatePresidentLetter = function (currentStatement) {
            loadConstants();

            var content = [];
            var president = statementStorageSvc.data.president && statementStorageSvc.data.president[0];
            var ABETPresident = statementStorageSvc.data.ABETPresident;

            var letter = [
                {
                    text: '{0}\nPresident\n{1}\n\n'.format(getPresidentFullName(), getPresidentAddress()),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Dear {0}:\n\n'.format(getPresidentGreeting()),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'I am pleased to transmit to you the findings of the {0} ({1}) of ABET with respect to the evaluation conducted for {2} during {3}. Each of ABET\'s commissions is fully authorized to take the actions described in the accompanying statement under the policies of the ABET Board of Directors.\n\n'.format(factory.COMMISSION_NAME, factory.COMMISSION_ABBREV, factory.ORG_NAME, factory.YEAR_SPAN),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'We are pleased that your institution has elected to participate in this accreditation process. This process, which is conducted by approximately 2,200 ABET volunteers from the professional community, is designed to advance and assure the quality of professional education. We look forward to our continuing shared efforts toward this common goal.\n\n\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Sincerely,\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    image: getABETPresidentSignature(),
                    width: 100
                },
                {
                    text: '\n{0}\n{1}\n'.format(getABETPresidentFullName() , 'President'),
                    style: ['small', 'camptonLight']
                },
            ];
            letter = factory.letterHead.concat(letter);
            content.push(letter);

            function getPresidentFullName() {
                return getFullName(president);
            }

            function getPresidentGreeting() {
                if (president) {
                    console.log("prez", president.personDto);
                    return '{0} {1}'.format(president.personDto.namePrefixCode, president.lastName);
                }
                return null;
            }

            function getPresidentAddress() {
                if (president) {
                    var address = president.personDto.personAddressDto || {};
                    return '{0}\n{1}\n{2}\n{3}, {4} {5}'.format(address.address1, address.address2, address.address3, address.city, address.stateCode || address.countryCode, address.postalCode);
                }
                return null;
            }

            function getABETPresidentFullName() {
                return getFullName(ABETPresident);
            }

            function getABETPresidentSignature() {
                return getSignature(ABETPresident);
            }

            return getDocDefinition(content, 'Letter to the President');
        };

        function getSignature(contact) {
            return contact && /^data:image\/(jpeg|jpg|png)/i.test(contact.signature) && contact.signature || "signature_placeholder.png";
        }

        factory.generateDeanLetter = function (currentStatement) {
            loadConstants();

            var content = [];
            var commissionChair = getActingChair();

            var letter = [
                {
                    text: 'Greetings from ABET:\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'The {0} ({1}) of ABET recently held its {2} Summer Meeting to act on the program evaluations conducted during {3}. Each evaluation was summarized in a report to the Commission and was considered by the full Commission before a vote was taken on the accreditation action. The results of the evaluation for {4} are included in the Summary of Accreditation Actions and the Final Statement, made available in the Accreditation Management System (AMS).\n\n'.format(factory.COMMISSION_NAME, factory.COMMISSION_ABBREV, factory.REVIEW_YEAR, factory.YEAR_SPAN, factory.ORG_NAME),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'The policy of ABET is to grant accreditation for a limited number of years, not to exceed six, in all cases. The period of accreditation is not an indication of program quality. Any restriction of the period of accreditation is based upon conditions indicating that compliance with the applicable accreditation criteria must be strengthened. Continuation of accreditation beyond the time specified requires a reevaluation of the program at the request of the institution as noted in the accreditation action. ABET policy prohibits public disclosure of the period for which a program is accredited. For further guidance concerning the public release of accreditation information, please refer to Section {0} of the {1} Accreditation Policy and Procedure Manual (available at www.abet.org).\n\n'.format(factory.APPM_SECTIONS.PublicRelease, factory.YEAR_SPAN),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'A list of accredited programs is published annually by ABET. Information about ABET accredited programs at your institution will be listed in the forthcoming ABET Accreditation Yearbook and on the ABET website (www.abet.org).\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'It is the obligation of the officer responsible for ABET accredited programs at your institution to notify ABET of any significant changes in program title, personnel, curriculum, or other factors that could affect the accreditation status of a program during the period of accreditation stated in Section {0} of the {1} Accreditation Policy and Procedure Manual (available at www.abet.org).\n\n'.format(factory.APPM_SECTIONS.ChangesDuringAccreditation, factory.YEAR_SPAN),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'ABET requires that each accredited program publicly state the program\'s educational objectives and student outcomes as well as publicly post annual student enrollment and graduation data as stated in Section {0} of the Accreditation Policy and Procedure Manual (available at www.abet.org).\n\n'.format(factory.APPM_SECTIONS.InstitutionCatalogs),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Please note that appeals are allowed only in the case of Not to Accredit actions. Also, such appeals may be based only on the conditions stated in Section {0} of the {1} Accreditation Policy and Procedure Manual (available at www.abet.org).\n\n\n\n'.format(factory.APPM_SECTIONS.Appeals, factory.YEAR_SPAN),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Sincerely,\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    image: getCommissionChairSignature(),
                    width: 100
                },
                {
                    text: '\n{0}, {1}\n{2}\n\n'.format(getCommissionChairFullName(), 'Chair', factory.COMMISSION_NAME),
                    style: ['small', 'camptonLight']
                }
            ];
            letter = factory.letterHead.concat(letter);
            content.push(letter);

            function getCommissionChairFullName(contact) {
                return getFullName(commissionChair);
            }
            
            function getCommissionChairSignature() {
                return getSignature(commissionChair);
            }

            return getDocDefinition(content, 'Letter to the Dean');
        };

        factory.generateDeanDraftLetter = function (currentStatement) {
            loadConstants(statementTypeIds.DRAFT);

            var content = [];
            var commissionChair = getActingChair();

            var letter = [
                {
                    text: 'Greetings from ABET:\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'A Draft Statement presenting the findings of the recent evaluation by the {0} of ABET is now available online. Your institution is invited to submit a written response to this Draft Statement within thirty days following the receipt of this letter. Institutions are required to submit their formal responses to the ABET Draft Statement electronically within the Accreditation Management System (AMS). Your response is particularly important if you believe any of the facts or observations presented in the Draft Statement are in error. Further, if the Draft Statement indicates that a program is considered to have weaknesses or deficiencies, you are encouraged to document any corrective actions that have been taken to remedy these shortcomings.\n\n'.format(factory.COMMISSION_ABBREV),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Please indicate within the AMS whether you intend to submit a response to the Draft Statement as quickly as possible.\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Please limit any response to matters covered by the Draft Statement and affecting the potential accreditation of a program. If you agree with the assessment of the visiting team and wish to provide no response, please indicate this within the AMS.\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'It should be noted that a weakness or deficiency is considered to have been corrected only if the corrective action has been made effective during the academic year of the evaluation and is supported by official documentation. Where action has been initiated to correct a problem but has not yet taken full effect or where only indications of good intent are given, the effectiveness of the corrective action cannot always be presumed; in such cases, evaluation by the Commission at the time of the next evaluation may be required.\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'The Commission will carefully review your institution\'s response to the Draft Statement, and will determine accreditation decisions during its July Meeting. You should expect to receive official notification of accreditation actions together with the Final Statement during the period from mid-August to mid-September.\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Neither the presence nor absence of a stated, projected accreditation action in any program discussion commits the Commission to a particular final action. The official accreditation action for each program is taken by vote of the entire Commission at its July Meeting following consideration of the team’s findings along with the institution’s response to the Draft Statement.\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'The Commission considers all Draft Statements to be unofficial documents distributed only for review and comment. The Draft Statement does not represent the final official views of the Commission; therefore, it should be handled confidentially. Please limit release of this document in whole or in part only to persons involved in the preparation of your response to the Commission.\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Instructions for distribution of your due process response and any additional post 30-day due process information received in time for proper consideration to the Draft Statements has been distributed in a separate email message. Please also refer to Section {0}. of the ABET Accreditation Policy and Procedure Manual.\n\n\n\n'.format(factory.APPM_SECTIONS.DueProcess, factory.YEAR_SPAN),
                    style: ['small', 'camptonLight']
                },
                {
                    text: 'Sincerely,\n\n',
                    style: ['small', 'camptonLight']
                },
                {
                    image: getCommissionChairSignature(),
                    width: 100
                },
                {
                    text: '\n{0}, {1}\n{2}'.format(getCommissionChairFullName(), 'Chair', factory.COMMISSION_NAME),
                    style: ['small', 'camptonLight']
                }
            ];
            letter = factory.letterHead.concat(letter);
            content.push(letter);

            function getCommissionChairFullName(contact) {
                return getFullName(commissionChair);
            }

            function getCommissionChairSignature() {
                return getSignature(commissionChair);
            }

            return getDocDefinition(content, 'Letter to the Dean - Draft Statement Available');
        };

        function getFullName(contact) {
            var fullName = null;
            if (contact) {
                var firstName = contact.firstName && contact.firstName.trim() || '';
                if (firstName.length) {
                    firstName += ' ';
                }
                var middleName = contact.middleName && contact.middleName.trim() || '';
                if (middleName.length) {
                    middleName += middleName.length === 1 ? '. ' : ' ';
                }
                var lastName = contact.lastName && contact.lastName.trim() || '';
                fullName = '{0}{1}{2}'.format(firstName, middleName, lastName).trim();
            }
            return fullName && fullName.length ? fullName : null;
        }

        function getActingChair() {
            var reviewYear = statementStorageSvc.data.currentReviewTeam.reviewYear;
            var commissionerYear = helperSvc.getCommissionerYear()
            var activeChairPropertyNameSuffix = reviewYear === commissionerYear ? 'Chair' : 'PastChair'
            return statementStorageSvc.data[factory.COMMISSION_ABBREV + activeChairPropertyNameSuffix];
        }

        return {
            generatePresidentLetter: factory.generatePresidentLetter,
            generateDeanLetter: factory.generateDeanLetter,
            generateDeanDraftLetter: factory.generateDeanDraftLetter
        };
    };

    module.factory('supplementalStatementPDFSvc', supplementalStatementPDFSvc);

})(angular.module('statement'));