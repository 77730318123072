(function (module) {

    var organizationLinkCtrl = function ($stateParams, $state, organizationLinkSvc, helperSvc, globalConstantSvc) {
        var model = this;

        model.title = 'Merge, Split, and Transition History';
        model.organizationId = parseInt($stateParams.organizationId);
        model.formatDate = helperSvc.formatDate;
        activate();

        model.goToOrganization = function (orgId) {
            $state.go('organization.single', { organizationId: orgId });
        };

        function activate() {
            organizationLinkSvc.getOrganizationLinksByOrganizationId(model.organizationId).then(function (data) {
                model.organizationLinks = data;
            });
        }

    };
    module.controller('organizationLinkCtrl', organizationLinkCtrl);

}(angular.module('organization')));