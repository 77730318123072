(function (module) {

    var rfrCompareCtrl = function ($scope, $uibModalInstance, selectedRfr1, selectedRfr2) {
        var model = $scope;

        model.title = "Compare RFE's";
        model.selectedRfr1 = selectedRfr1;
        model.selectedRfr2 = selectedRfr2;

        model.close = function () {
            $uibModalInstance.close();
        }
    }

    module.controller('rfrCompareCtrl', rfrCompareCtrl);

}(angular.module('rfr')));