(function (module) {

    var employmentBackgroundEditCtrl = function ($scope, $uibModalInstance, parent, volunteerSvc, pevAppSvc, helperSvc, alertSvc, codeSvc, volunteerHelperSvc) {
        var model = $scope;
        var checkIsVolunteer = true;
        var isApplication = pevAppSvc.isApplicationMode(checkIsVolunteer);
        
        model.parent = parent;
        model.title = 'Edit Background';
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 

        if (isApplication) {
            model.currentInformation = angular.copy(pevAppSvc.volunteerData.volunteer);
        } else {
            model.currentInformation = angular.copy(volunteerSvc.data.volunteer);
        }

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            cleanData();

            if (isApplication) {
                var application = angular.copy(pevAppSvc.data.application);
                application.applicationJson.volunteer.backgroundTypeId = model.currentInformation.backgroundTypeId;
                application.applicationJson.volunteer.backgroundOther = model.currentInformation.backgroundOther;
                application.applicationJson.volunteer.academicTypeId = model.currentInformation.academicTypeId;
                application.applicationJson.volunteer.backgroundTypeName = model.currentInformation.backgroundTypeName;
                application.applicationJson.volunteer.academicTypeName = model.currentInformation.academicTypeName;

                pevAppSvc.update(application).then(onSuccess, onFailure);
            } else {
                volunteerSvc.update(model.currentInformation).then(onSuccess, onFailure);
            }

            function onSuccess () {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Background information successfuly saved.");
            }

            function onFailure () {
                alertSvc.addAlertWarning('Background information could not be saved at this time.');
            }
        };

        var activate = function () {
            codeSvc.getBackgroundTypes().then(function (data) {
                model.backgroundTypes = helperSvc.getValue(data);
                model.otherBackgroundTypeId = model.backgroundTypes.find(function (code) {
                    return code.codeName.toLowerCase().startsWith('other');
                }).codeKey;
                model.academicBackgroundTypeId = model.backgroundTypes.find(function (code) {
                    return code.codeName.toLowerCase().startsWith('academi');
                }).codeKey;
            });
            codeSvc.getAcademicTypes().then(function (data) {
                model.academicTypes = helperSvc.getValue(data);
            });
        }();

        function cleanData() {
            // Clear BackgroundOther if background is not "other"
            if (model.currentInformation.backgroundTypeId !== model.otherBackgroundTypeId) {
                model.currentInformation.backgroundOther = null;
            }
            // Clear AcademicTypeName if not in academia
            if (model.currentInformation.backgroundTypeId !== model.academicBackgroundTypeId) {
                model.currentInformation.academicTypeId = null;
            }
            //set background type name for data refresh
            var selectedBackground = model.backgroundTypes.find(function (item) {
                return item.codeKey == model.currentInformation.backgroundTypeId;
            });
            if (selectedBackground)
                model.currentInformation.backgroundTypeName = selectedBackground.codeName;
            //set academic type name for data refresh
            var selectedAcademic = model.academicTypes.find(function (item) {
                return item.codeKey == model.currentInformation.academicTypeId;
            });
            if (selectedAcademic)
                model.currentInformation.academicTypeName = selectedAcademic.codeName;
        };
    };

    module.controller('employmentBackgroundEditCtrl', employmentBackgroundEditCtrl);

}(angular.module('volunteer')));