(function (module) {

    var viewPAFPreviewCtrl = function (currentProgramAuditData, isExitStatement, isSingleProgram, isInstitutionalSummary, isPreview, showInstitutionalSummary, $scope, $uibModalInstance, programAuditPDFSvc, helperSvc, pdfSvc, $timeout, alertSvc) {
        var model = $scope;

        model.title = isInstitutionalSummary ? 'View PAF Institutional Summary Preview' : 'View Program Audit Form Preview';

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.onLoadCallback = function () {
            var isIE = helperSvc.isIE();
            if (isIE) $uibModalInstance.close();
            // Note: code below is intended to close modal when no PDF exists (i.e. browser configured to download automatically)
            // Want to test before enabling to avoid closing modal too early (i.e. content not done rendering). 
            // Might need to use window.requestAnimationFrame() instead of $timeout
            //////else $timeout(() => { // wait until done rendering content
            //////    $timeout(() => { // wait until some time has passed since done rendering content
            //////        const frame = document.getElementsByTagName('iframe')[0];
            //////        if (frame && frame.contentDocument && frame.contentDocument.body.innerHTML === '') {
            //////            alertSvc.addAlertInfo('Document downloaded');
            //////            $uibModalInstance.close();
            //////        }
            //////    }, 2000);
            //////});
        };

        model.downloadPAF = function () {
            const filename = getFilename();
            pdfSvc.downloadPDF(model.doc, null, null, filename);
        };

        function getFilename() {
            const org = currentProgramAuditData.organization.organizationName;
            const program = isSingleProgram ? `-${currentProgramAuditData.programReviews[0].programDto.programDetailDto.programName} (${currentProgramAuditData.programReviews[0].programDto.programDetailDto.degreeCode})` : '';
            const type = isExitStatement ? '-Exit' : (isInstitutionalSummary ? '-InstSummary' : '-PAF');
            const title = `${org}${program}${type}`;

            return helperSvc.formatFilename(title, 'pdf');
        }

        model.activate = function () {
            model.doc = programAuditPDFSvc.generateDoc(currentProgramAuditData, isExitStatement, isSingleProgram, isInstitutionalSummary, isPreview, showInstitutionalSummary);
        }(); 
    };

    module.controller('viewPAFPreviewCtrl', viewPAFPreviewCtrl);

}(angular.module('programAudit')));