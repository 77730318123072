(function (module) {

    var programContactsCtrl = function ($scope, $uibModal, contactRoleTypes) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Program Contacts';
        model.type = 'program';
        model.helpText = 'ABET defines a program contact as an individual with authority for a specific accredited program.  This individual is typically a program or department head whom the institution requires to be informed of elements of an accreditation review for a specific program but does not have the authority to commit the institution or the specific program to an accreditation review.';

        model.editProgramContacts = function (isCreateMode, isReplaceMode, currentContact, editView, type) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/contacts/templates/contactsEdit.html',
                size: 'lg',
                controller: 'contactsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    },
                    contacts: function () {
                        return model.parent.programContacts;
                    },
                    roleId: function () {
                        return contactRoleTypes.PROGRAM;
                    },
                    isCreateMode: function () {
                        return isCreateMode;
                    },
                    isReplaceMode: function () {
                        return isReplaceMode;
                    },
                    currentContact: function () {
                        return currentContact;
                    },
                    editView: function () {
                        return editView;
                    },
                    type: function () {
                        return model.type;
                    }
                }
            });
        };
    };

    module.controller('programContactsCtrl', programContactsCtrl);

}(angular.module('contacts')));