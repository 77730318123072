(function (module) {
    module.factory('programAuditInstitutionalSummarySvc', [
        'odataSvc', '$http', 'teamMemberTypeNames', 'currentUser', 'oauth', 'helperSvc',
        function (odataSvc, $http, teamMemberTypeNames, currentUser, oauth, helperSvc) {
                return {
                    getProgramAuditInstitutionalSummaryById,
                    updateProgramAuditInstitutionalSummary,
                    addFindings,
                    createStrengthSectionTemplate,
                    deleteFinding,
                    hasText
                };

                const placeholderText = 'Click to add text...';

                function getProgramAuditInstitutionalSummaryById(id) {
                    const oSvc = odataSvc.get();
                    const apiPath = `/ProgramAuditInstitutionalSummary`;

                    oSvc.getSource(apiPath, 'programAuditInstitutionalSummaryId')
                            .odata()
                            .get(id)
                            .$promise
                            .then(oSvc.onSuccess, oSvc.onFailure);

                   return oSvc.getDeferred().promise;
                };

                //function getProgramAuditInstitutionalSummaryByReviewTeamId(id) {
                //    const oSvc = odataSvc.get();
                //    const apiPath = `/GetInstitutionalSummaryByReviewTeamId`;

                //    oSvc.getSource(apiPath).odata()
                //        .filter('paid', paid)
                //        .query(oSvc.onSuccess, oSvc.onFailure);

                //    return oSvc.getDeferred().promise;
                //};

                function hasText (text) {
                    // markup is added whenever anything is entered, even if all the text was deleted
                    return !(!text || text === '' || text === '<p>&nbsp;</p>' || text === placeholderText || text === '<p>' + placeholderText + '</p>');
                }

                function addFindings(programAuditInstitutionalSummary, numberOfStrengths) {
                    
                    //let instJson = angular.fromJson(programAuditInstitutionalSummary.institutionalSummaryJson);
                    let section = programAuditInstitutionalSummary.institutionalSummaryJson.find(sec => sec.statementFindingTypeId === 4);

                    if (!section) {
                        section = createStrengthSectionTemplate();
                        section.findings = [];
                    }
                    
                    for (var i = 0; i < numberOfStrengths; i++) {
                        
                        const newFinding = createFindingTemplate(new Date());
                        section.findings.push(newFinding);
                    }

                    var newJson = programAuditInstitutionalSummary.institutionalSummaryJson.filter(t => t.statementFindingTypeId !== 4);
                    newJson.push(section);

                    programAuditInstitutionalSummary.institutionalSummaryJson = newJson;
                };

                function updateProgramAuditInstitutionalSummary(programAuditInstitutionalSummaryDto) {
                    const newdto = angular.copy(programAuditInstitutionalSummaryDto);
                    newdto.institutionalSummaryJson = JSON.stringify(newdto.institutionalSummaryJson);

                    return $http.put(`/webapi/odata/ProgramAuditInstitutionalSummary(programAuditInstitutionalSummaryId=${newdto.programAuditInstitutionalSummaryId})`, newdto);
                };

                function deleteFinding(programAuditInstitutionalSummary, finding) {
                    const currentFindingTypeIndex = programAuditInstitutionalSummary.institutionalSummaryJson.findIndex(currentFindingType =>
                        currentFindingType.statementFindingTypeId === 4 &&
                        currentFindingType.findings.some(currentFinding =>
                            currentFinding.key === finding.key
                        )
                    );
                    const currentFindingType = programAuditInstitutionalSummary.institutionalSummaryJson[currentFindingTypeIndex];

                    if (!currentFindingType || !currentFindingType.findings || !currentFindingType.findings.length)
                        return;

                    if (currentFindingType.findings.length === 1) {
                        programAuditInstitutionalSummary.institutionalSummaryJson.splice(currentFindingTypeIndex, 1);
                    } else {
                        const currentFindingIndex = currentFindingType.findings.findIndex((currentFinding) =>
                            currentFinding.key === finding.key
                        );
                        currentFindingType.findings.splice(currentFindingIndex, 1);
                    }

                    return updateProgramAuditInstitutionalSummary(programAuditInstitutionalSummary);
                }


                function createStrengthSectionTemplate() {
                    return {
                        "statementFindingTypeId": 4,
                        "statementFindingTypeName": "Institutional Strength",
                        "orderNumber": 5,
                        "findings": null,
                        "key": new Date()
                    }
                };
                function createFindingTemplate(timestamp) {
                    timestamp = timestamp || new Date();
                    const finding = {
                        "criteria": {
                            "text": null,
                            "comments": [],
                        },
                        "key": helperSvc.getNewKey(),
                        "insertedTimestamp": timestamp.toISOString(),
                        "lastUpdatedTimestamp": timestamp.toISOString()
                    };
                    return finding;
                }
        }]);
})(angular.module('programAudit'));