(function (module) {

    var templateRoot = "/Apps/message/templates/";

    module.directive('goToInbox', function (messageHelperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'goToInbox.html',
            link: function (scope) {
                scope.openInbox = messageHelperSvc.openInbox;
            }
        };
    });

}(angular.module('messages')));