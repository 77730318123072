(function (module) {
    'use strict';

    var criteriaTransitionSvc = function ($http, $q, odataSvc, amsConst, $odata) {
        var factory = {};

        var api = '/CriteriaTransitionConfig';
        var key = 'CriteriaTransitionConfigId';

        factory.getCriteriaTransitionConfig = function (commissionId, reviewYear) {
            var oSvc = odataSvc.get();
            
            oSvc.getSource(api, key).odata()
                .filter('commissionId', commissionId)
                .filter('reviewYear', parseInt(reviewYear))
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

                return oSvc.getDeferred().promise;
        }

        return {
            getCriteriaTransitionConfig: factory.getCriteriaTransitionConfig
        }

    }
    module.factory('criteriaTransitionSvc', criteriaTransitionSvc);

})(angular.module('rfr'));