(function (module) {
    var npcReviewerCtrl = function ($uibModal, $state, $timeout, $window, helperSvc, barSvc, alertSvc, npcSvc, codeSvc, teamMemberTypeIds) {
        var model = this;
        var dataSourceArray = [];

        model.titles = 'Notifications of Program Changes';
        model.resultMsg = model.defaultSearchMsg;
        model.isDataReady = false;
        model.searchOptions = {};
        model.npcs = [];
        model.teamMemberTypeIds = teamMemberTypeIds;
        model.getColorClass = npcSvc.getColorClass;


        model.openNpcReviewer = function (npc) {
            model.selectedOrganizationId = npc.organizationId;
            $state.go('npcReviewer.detail', { npcId: npc.npcId, organizationId: model.selectedOrganizationId }); 
        };

        var activate = function () {
            barSvc.showSpinner();

            npcSvc.getReviewerSearch().then(function (data) {
                barSvc.hideSpinner();
                model.npcs = data;

                //need to filter out the npcs that are in the final action sent stage


                if (model.npcs.length === 0) {
                    model.resultMsg = "No results found";
                } else {
                    model.npcs = helperSvc.getFilteredArray(model.npcs, 'npcStatusId', [6, 8], false)
                }

                model.isDataReady = true;
                model.showSpinner = false;
            });

        }();
    }
    module.controller('npcReviewerCtrl', npcReviewerCtrl);

}(angular.module('npc')));