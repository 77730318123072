(function (module) {

    var alertCtrl = function ($scope, $uibModalInstance, title) {
        var model = $scope;

        if (title) model.title = title;

        model.close = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('alertCtrl', alertCtrl);

}(angular.module('common')));