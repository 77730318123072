(function (module) {

    var rfrManageContactsCtrl = function ($scope, $state, rfrSvc, eventSvc, rfrValidationSvc) {
        var model = this;

        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openContactInstructions = rfrSvc.openContactInstructions;
    };

    module.controller('rfrManageContactsCtrl', rfrManageContactsCtrl);

}(angular.module('rfr')));