(function (module) {
    var pevRefCtrl = function ($uibModal, helperSvc, alertSvc, pevAppSvc, pevAppValidationSvc, $scope, componentHelperSvc) {
        var model = this;

        model.data = pevAppSvc.data;
        model.slug = pevAppSvc.slugs.REFERENCES;
        model.isPreviewMode = pevAppSvc.isPreviewMode();
        model.isAdminOrSocietyUser = false;
        model.references = [];
        model.openReferenceInstructions = pevAppSvc.openReferenceInstructions;

        model.save = function () {
            model.data.application.referenceJson = model.references;

            pevAppSvc.update(model.data.application).then(
                function () {
                    alertSvc.addAlertSuccess("PEV application succesfully saved.");
                    model.isSubmitting = false;
                    pevAppValidationSvc.invokeValidation();
                },
                function () {
                    model.isSubmitting = false;
                    alertSvc.addAlertWarning("PEV application could not be saved at this time.");
            });
        };

        model.openReference = function (reference) {
            var modalInstance = $uibModal.open({
                animation: true,
                template: '<modal hide-validation-key="true"><modal-body><survey survey="reference.surveyQuestion" use-widgets="true" preview="true"></survey></modal-body></modal>',
                size: 'lg',
                windowClass: 'page-color',
                controller: 'referenceModalPreviewCtrl',
                resolve: {
                    reference: function () { return reference; }
                }
            });
        };

        model.hasEmptyRefs = function () {
            return model.references.some(function (ref) {
                return !(ref.personName || ref.emailAddress);
            });
        };

        model.hasValidRefs = function () {
            return model.references.some(function (ref) {
                return ref.personName || ref.emailAddress;
            });
        };

        var newReference = {
                id: 0,
                personName: null,
                email: null,
                title: null,
                employer: null
        };

        var activate = function () {
            
            var societyIds = pevAppSvc.getSocietyIds(model.data.application);

            if(societyIds.length > 0){
            
                pevAppSvc.getNumberOfRequiredReferences(societyIds).then(function (requiredReferences) {
                    var requiredNumber = 0;
                    model.references = model.data.application.referenceJson;

                    for(var i =0; i < requiredReferences.length; i++){
                        requiredNumber = requiredNumber < requiredReferences[i].referenceRequired ? requiredReferences[i].referenceRequired : requiredNumber;
                    }
               
                    if(model.references.length < requiredNumber)
                    {                       
                        for (var i = model.references.length; i < requiredNumber; i++) {
                            var newRef = angular.copy(newReference);
                            newRef.id = i;
                            model.references.push(newRef);
                        }                      
                    }
                    if (model.references.length > requiredNumber) {
                        for (var i = model.references.length; i > requiredNumber && i >= 0; i--) {
                            model.references.splice(i - 1, 1);
                        }                      
                    }
                    if (requiredNumber === 0)
                        model.references = [];

                    model.data.application.referenceJson = model.references;

                    if (pevAppSvc.isAdmin() || pevAppSvc.isAppManager())
                        model.isAdminOrSocietyUser = true;

                    pevAppValidationSvc.invokeValidation();
                    componentHelperSvc.invokeOnRendered('appReferences', $scope);
                });
            }
        }();
    };
    module.controller('pevRefCtrl', pevRefCtrl);

}(angular.module('pevApp')));