(function (module) {

    var pevAppSvc = function (odataSvc, $http, $odata, $state, $stateParams, $uibModal, $filter, oauth, currentUser, pevApplicationStatuses, membershipSvc, $q, amsConst,
        messageSvc, messageTemplateTypes, helperSvc, alertSvc, messageTemplateParameters, currentUserHelper, orgConstants, componentHelperSvc, personSvc,
        volunteerSvc, citizenshipSvc, employmentInfoSvc, professionalLicenseSvc, educationInfoSvc, languageProficiencySvc, programAreaSvc, societyMembershipSvc, onlineEducationSvc) {
        var api = '/VolunteerApplication';
        var key = 'volunteerApplicationId';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        var factory = {
            data: {
                application: null,
                applications: [],
                person: {
                    name: null,
                    emails: [],
                    phones: [],
                    addresses: []
                },
                contacts: null
            },
            volunteerData: {volunteer: null},
            volunteerEmploymentData: { employmentInfo: null},
            volunteerLicenseData: { professionalLicenses: null},
            volunteerEducationData: {educationInfo: null },
            volunteerLanguageData: { languageInfo: null },
            volunteerDisciplineData: {volunteerProgramAreas: null},
            volunteerSocietyMembershipData: { societyMemberships: null},
            volunteerSocietySpecializationData: {specializations: null},
            volunteerOnlineEducation: { onlineEducation: null }
        };

        factory.slugs = {
            INTRO: 'intro',
            INFO: 'info',
            SOCIETY: 'society',
            QUESTIONNAIRE: 'questionnaire',
            REFERENCES: 'references',
            SUBMIT: 'submit'
        };

        factory.getApplicationsByPerson = function () {
            var oSvc = odataSvc.get();
            var path = '/GetApplicationByPerson';

            var predicates = [];
            predicates.push(new $odata.Predicate('volunteerApplicationStatusId', '=', parseInt(pevApplicationStatuses.WAITING_FOR_SUBMISSION)));
            predicates.push(new $odata.Predicate('volunteerApplicationStatusId', '=', parseInt(pevApplicationStatuses.SUBMITTED)));
            var predicate = $odata.Predicate.or(predicates);
            
            oSvc.getSource(path).odata()
                .filter(predicate)
                .query(oSvc.onSuccess, oSvc.onFailure);

            resolveApplicationArrayData(oSvc);

            return oSvc.getDeferred().promise;
        };

        factory.getApplicationByVolunteerApplicationId = function (volunteerApplicationId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = api + '(volunteerApplicationId=' + volunteerApplicationId + ')';

            oSvc.getSource(path, volunteerApplicationId).odata()
                .single(oSvc.onSuccess, oSvc.onFailure);
                

            //var oSvc = odataSvc.get();

            //oSvc.getSource(api, volunteerApplicationId).odata()
            //    .filter(key, volunteerApplicationId)
            //    .single(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveApplicationData(oSvc);
                return oSvc.getDeferred().promise;
            }

            return oSvc.getDeferred().promise.then(function (data) {
                deserializeJson(data);
                return data;
            });
        };
        
        factory.getAllDataForApplication = function (volunteerApplicationId) {
            return factory.getApplicationByVolunteerApplicationId(volunteerApplicationId, true).then(function (data) {
                data = {
                    application: data,
                    person: {}
                };

                return loadVolunteerData(data).then(data =>
                    loadPersonData(data)
                );
            });

            function loadVolunteerData(data) {
                // Load data for existing volunteer; applications for existing volunteers edit their current data directly
                const volunteerId = data.application.applicationJson.volunteer && data.application.applicationJson.volunteer.volunteerId;
                if (!volunteerId)
                    return $q.when(data);
                const promises = [
                    volunteerSvc.get(volunteerId, true), 
                    citizenshipSvc.getByVolunteerId(volunteerId, true),
                    employmentInfoSvc.getByVolunteerId(volunteerId, true),
                    professionalLicenseSvc.getVolunteerLicensesByVolunteerId(volunteerId, true),
                    educationInfoSvc.getByVolunteerId(volunteerId, true),
                    languageProficiencySvc.getByVolunteerId(volunteerId, true),
                    onlineEducationSvc.getByVolunteerId(volunteerId, true)
                ];
                return $q.all(promises).then(([volunteerData, citizenshipData, employmentData, licenseData, educationData, languageData, onlineEducationData]) => {
                    // Load demographic data
                    data.application.applicationJson.volunteer.academicTypeId = volunteerData.academicTypeId;
                    data.application.applicationJson.volunteer.academicTypeName = volunteerData.academicTypeName;
                    data.application.applicationJson.volunteer.backgroundOther = volunteerData.backgroundOther;
                    data.application.applicationJson.volunteer.academicTypeId = volunteerData.academicTypeId;
                    data.application.applicationJson.volunteer.backgroundTypeId = volunteerData.backgroundTypeId;
                    data.application.applicationJson.volunteer.backgroundTypeName = volunteerData.backgroundTypeName;
                    data.application.applicationJson.volunteer.ethnicityTypeId = volunteerData.ethnicityTypeId;
                    data.application.applicationJson.volunteer.ethnicityTypeName = volunteerData.ethnicityTypeName;
                    data.application.applicationJson.volunteer.gender = volunteerData.gender;
                    data.application.applicationJson.volunteer.yearOfBirth = volunteerData.yearOfBirth;
                    // Load volunteer citizenship data for demographic data
                    data.application.applicationJson.volunteer.citizenship = citizenshipData;
                    // Load volunteer employment data
                    data.application.applicationJson.volunteerEmployment = employmentData
                    // Load volunteer licenses
                    data.application.applicationJson.volunteerLicense = licenseData;
                    // Load volunteer education data
                    data.application.applicationJson.volunteerEducation = educationData;
                    // Load volunteer languages
                    data.application.applicationJson.volunteerLanguage = languageData;
                    // Load volunteer's online education experience
                    data.application.applicationJson.volunteerOnlineEducation = onlineEducationData;

                    return data;
                });
            }

            function loadPersonData(data) {
                return personSvc.getPersonMdlById(data.application.personId, {}, true).then(function (personData) {
                    data.person.name = (personData.data.firstName ? personData.data.firstName : '') + (personData.data.middleName ? ' ' + personData.data.middleName : '') + (personData.data.lastName ? ' ' + personData.data.lastName : '');
                    data.person.emails = personData.data.personEmailDtos;
                    data.person.phones = personData.data.personTelephoneDtos;
                    data.person.addresses = personData.data.personAddressDtos;
                    // Load contact info for training managers
                    data.contacts = [];
                    const societyIds = factory.getSocietyIds(data.application);
                    if (societyIds.length > 0) {
                        return membershipSvc.getTrainingManagers(societyIds).then(function (trainingManagerData) {
                            data.contacts = trainingManagerData;
                            return data;
                        })
                    } else
                        return data;
                });
            }
        };

        factory.create = function (application) {
            var oSvc = odataSvc.get();
            var app = serializeJson(application);
            var resource = oSvc.instantiate(api, key, app);
            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            return promise;
        };

        factory.update = function (application) {
            var oSvc = odataSvc.get();
            var app = serializeJson(application);
            var resource = oSvc.instantiate(api, key, app);
            var promise = resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                return factory.getApplicationByVolunteerApplicationId(application.volunteerApplicationId).then(function (data) {
                    resolveApplicationData(oSvc);
                })
            })

            return promise;
        };

        factory.applicationApprovalChange = function (application, societyId, changedStatusId) {
            var path = amsConst.webApiUrl + '/odata/VolunteerApplicationApprovalChange';

            application = helperSvc.removeODataContext(application);

            var app = serializeJson(application);
            var data = { "application": app, "societyId": societyId, "changedStatusId": changedStatusId };

             return $http.post(path, data).then(function (response) {
                return factory.getApplicationByVolunteerApplicationId(application.volunteerApplicationId).then(function (data) {
                    if (response.data && response.data.value === "Contact Abet") {
                        return Promise.resolve("Contact Abet");
                    }
                    reformatData(data);
                })
            })

            return promise;
        };

        factory.submit = function (application) {

            var path = amsConst.webApiUrl + '/odata/SubmitApplication';

            application = helperSvc.removeODataContext(application);

            var app = serializeJson(application);
            var data = { "application": app };

            return $http.post(path, data).then(function (response) {
                //q,all here for reloading data and sending out email messages
                //send email to submitter and all the pev director? of the socities
                var promiseArray = [];

                var getApplicationData = factory.getApplicationByVolunteerApplicationId(application.volunteerApplicationId);
                promiseArray.push(getApplicationData);

                //must serialize data to get the necesary info
                var appCopy = angular.copy(application);
                deserializeJson(appCopy);

                var societyIds = factory.getSocietyIds(appCopy);
                var trainingManagerPromise = membershipSvc.getTrainingManagers(societyIds);
                promiseArray.push(trainingManagerPromise);


                return $q.all(promiseArray).then(function (data) {

                    var managers = data.pop();
                    var managerEmails = [];

                    var pevApp = data.pop();
                    reformatData(pevApp);

                    //message params
                    var societyMessageParams = angular.copy(messageTemplateParameters.PEVAPPSOCIETYNOTIFICATION);
                    societyMessageParams[0].messageParameterValue = factory.data.application.applicationJson.volunteer.firstName; //volunteer first name
                    societyMessageParams[1].messageParameterValue = factory.data.application.applicationJson.volunteer.lastName; //volunteer last name
                    societyMessageParams[2].messageParameterValue = factory.data.application.applicationJson.volunteer.emailAddress; //volunteer email

                    var confirmMessageParams = angular.copy(messageTemplateParameters.PEVAPPCONFIRMSUBMISSION);
                    confirmMessageParams[0].messageParameterValue = factory.data.application.applicationJson.volunteer.firstName; //volunteer first name
                    confirmMessageParams[1].messageParameterValue = factory.data.application.applicationJson.volunteer.lastName; //volunteer last name


                    if (managers && managers.length > 0) {
                        angular.forEach(managers, function (manager) {
                            if (manager && manager.emailAddress)
                                managerEmails.push(manager.emailAddress)
                        })
                        messageSvc.buildRecipientListAndSendMessages(managerEmails, [], messageTemplateTypes.PEVAPPSOCIETYNOTIFICATION, null, null, societyMessageParams);
                    }

                    messageSvc.buildRecipientListAndSendMessages([factory.data.application.applicationJson.volunteer.emailAddress], [], messageTemplateTypes.PEVAPPCONFIRMSUBMISSION, null, null, confirmMessageParams);
                });
            });
        };

        factory.submitReferenceSurvey = function (applicationId, reference) {
            var path = amsConst.webApiUrl + '/odata/SubmitReferenceSurvey';

            var data = {
                "applicationId": applicationId,
                "reference": reference
            };

            return $http.post(path, data);
        }

        factory.resendReferenceRequests = function (application) {
            var path = amsConst.webApiUrl + '/odata/ResendReferenceRequests';

            var app = angular.copy(application);
            if (app.applicationJson) {
                app.applicationJson = angular.toJson(app.applicationJson);
            }
            if (app.referenceJson) {
                app.referenceJson = angular.toJson(app.referenceJson);
            }
            if (app.surveyJson) {
                app.surveyJson = angular.toJson(app.surveyJson);
            }

            var data = { "application": app };

            return $http.post(path, data);
        }

        factory.isApplicationMode = function (checkIsVolunteer) {
            var isVolunteer = false;
            var volunteerData = factory.data.application ? factory.data.application.applicationJson.volunteer : null;

            if (checkIsVolunteer && volunteerData) {
                isVolunteer = volunteerData.hasOwnProperty('volunteerId') && volunteerData.volunteerId != 0;
            }

            const currentState = $state.current.name.toLowerCase();
            return (currentState.contains('pevapp') || currentState.contains('training')) && !isVolunteer;
        };

        factory.getMyVolunteerId = function () {
            var volunteerData = factory.data.application ? factory.data.application.applicationJson.volunteer : null;

            if (volunteerData) {
                return volunteerData.volunteerId;
            } else {
                return 0;
            }
        };

        factory.getSingleAppSearchViewModel = function(volunteerApplicationId){
            var searchApi = '/GetVolunteerApplicationSearch';
            var oSvc = odataSvc.get();

            oSvc.getSource(searchApi).odata()
                    .filter(key, volunteerApplicationId)
                    .query()
                    .$promise
                    .then(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;

        }

        factory.advancedSearch = function (searchOptions, exportSearch) {
            //var societyId = factory.isAdmin() ? null : currentUser.profile.organizationId;
            var searchApi = '/GetVolunteerApplicationSearch';

            var oSvc = odataSvc.get();
            
            var hasSociety = searchOptions.society ? true : false;
            var hasStatusId = searchOptions.statusId ? true : false;
            var hasSocietyStatusId = searchOptions.societyStatusId ? true : false;
            var hasPersonId = searchOptions.personId ? true : false;
            var hasPersonName = searchOptions.personName ? true : false;
            var hasDateRange = searchOptions.submissionRange && (searchOptions.submissionRange.startDate || searchOptions.submissionRange.endDate) ? true : false;
            var hasProgramArea = (searchOptions.programAreas && searchOptions.programAreas.length > 0) ? true : false;

            var hasSearchOptions = hasSociety || hasStatusId || hasPersonName || hasDateRange || hasProgramArea;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;

                if (hasPersonId)
                    predicates.push(new $odata.Predicate('personId', '=', parseInt(searchOptions.personId)));
                if (hasSociety)
                    predicates.push(new $odata.Predicate('societyId', '=', parseInt(searchOptions.society)));
                if (hasStatusId) {
                    var statusId = parseInt(searchOptions.statusId);
                   
                    predicates.push(new $odata.Predicate('volunteerApplicationStatusId', '=', statusId));
                }
                if (hasSocietyStatusId) {
                    var statusId = parseInt(searchOptions.societyStatusId);
                    //if (statusId === 0) { //Waiting for submission
                    //    //unsubmitted applications are 'waiting for submission'
                    //    predicates.push(new $odata.Predicate('submittedDate', 'eq', null));
                    //} else {
                    predicates.push(new $odata.Predicate('volunteerApplicationSocietyStatusId', '=', statusId));
                    //}
                }
                if (hasPersonName) {
                    var combinedFirstNameSearch = [];
                    var combinedMiddleNameSearch = [];
                    var combinedLastNameSearch = [];
                    var trimmed = searchOptions.personName.trim();
                    var names = trimmed.split(' ');
                    if (names.length === 3) { //Ex. John M Doe
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'firstName', names[0])), '>', -1));
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'middleName', names[1])), '>', -1));
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'lastName', names[2])), '>', -1));
                    }
                    else if (names.length === 2) { //Ex. John Doe
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'firstName', names[0])), '>', -1));
                        predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'lastName', names[1])), '>', -1));
                    } else {
                        var fullNamePredicate = [];
                        fullNamePredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'firstName', searchOptions.personName)), '>', -1));
                        fullNamePredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'middleName', searchOptions.personName)), '>', -1));
                        fullNamePredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'lastName', searchOptions.personName)), '>', -1));
                        predicates.push($odata.Predicate.or(fullNamePredicate));
                    }
                }
                if (hasDateRange) {
                    if (searchOptions.submissionRange.startDate) {
                        predicates.push(new $odata.Predicate('submittedDate', '>=', new Date(searchOptions.submissionRange.startDate)));
                    }
                    if (searchOptions.submissionRange.endDate) {
                        var date = new Date(searchOptions.submissionRange.endDate);
                        date.setDate(date.getDate() + 1);
                        predicates.push(new $odata.Predicate('submittedDate', '<', date));
                    }
                }
                if (hasProgramArea) {
                    var combinedProgramAreasPredicate = [];

                    for (var i = 0; i < searchOptions.programAreas.length; i++) {
                        var jsonStringField = `"DisciplineId":${parseInt(searchOptions.programAreas[i].disciplineId)},`;
                        combinedProgramAreasPredicate.push(new $odata.Predicate((new $odata.Func('indexof', 'disciplines', jsonStringField)), '>', -1));
                    }

                    if (combinedProgramAreasPredicate.length > 0) {
                        predicates.push($odata.Predicate.or(combinedProgramAreasPredicate))
                    }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(searchApi).odata()
                    .filter(combinedPredicate)
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(searchApi).odata()
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            resolvedDataPromise = oSvc.getDeferred().promise.then((data) => {
                return data.map((application) => {
                    application.disciplines = angular.fromJson(application.disciplines)
                    return application;
                })
            });

            return resolvedDataPromise;
        };

        factory.getApplicationExportList = function (applications, societies) {
            var path = amsConst.webApiUrl + '/odata/GetVolunteerApplicationList';

            var apps = [];
            angular.forEach(applications, function (application) {
                var app = angular.copy(application);
                if (app.applicationJson) {
                    app.applicationJson = angular.toJson(app.applicationJson);
                }
                if (app.referenceJson) {
                    app.referenceJson = angular.toJson(app.referenceJson);
                }
                if (app.surveyJson) {
                    app.surveyJson = angular.toJson(app.surveyJson);
                }
                if (app.disciplines) {
                    app.disciplines = angular.toJson(app.disciplines);
                }

                apps.push(app);
            });
            
            var data = {
                "applications": apps,
                "societies": societies
            };

            return $http.post(path, data);
            //var searchApi = '/GetVolunteerApplicationList';

            //var oSvc = odataSvc.get();

            //var hasApplicationIds = searchOptions.applicationIds && searchOptions.applicationIds.length > 0 ? true : false;
            //var hasSocietyIds = searchOptions.societyIds && searchOptions.societyIds.length > 0 ? true : false;

            //var hasSearchOptions = hasApplicationIds || hasSocietyIds;

            //if (hasSearchOptions) {
            //    var predicates = [];
            //    var combinedPredicate;

            //    if (hasApplicationIds) {
            //        var combinedAppPredicate = [];

            //        for (var i = 0; i < searchOptions.applicationIds.length; i++) {
            //            combinedAppPredicate.push(new $odata.Predicate('volunteerApplicationId', '=', parseInt(searchOptions.applicationIds[i])))
            //        }

            //        if (combinedAppPredicate.length > 0) {
            //            predicates.push($odata.Predicate.or(combinedAppPredicate));
            //        }
            //    }
            //    if (hasSocietyIds) {
            //        var combinedSocietyPredicate = [];

            //        for (var i = 0; i < searchOptions.societyIds.length; i++) {
            //            combinedSocietyPredicate.push(new $odata.Predicate('societyId', '=', parseInt(searchOptions.societyIds[i])))
            //        }

            //        if (combinedSocietyPredicate.length > 0) {
            //            predicates.push($odata.Predicate.or(combinedSocietyPredicate));
            //        }
            //    }

            //    if (predicates.length > 0) {
            //        combinedPredicate = $odata.Predicate.and(predicates);
            //    }

            //    oSvc.getSource(searchApi).odata()
            //        .filter(combinedPredicate)
            //        .query()
            //        .$promise
            //        .then(oSvc.onSuccess, oSvc.onFailure);
            //} else {
            //    oSvc.getSource(searchApi).odata()
            //        .query()
            //        .$promise
            //        .then(oSvc.onSuccess, oSvc.onFailure);
            //}

            //return oSvc.getDeferred().promise;
        };

        factory.getCurrentEmploymentStr = function (employmentArr) {
            var current = employmentArr.filter(function (e) { return !e.endYear });
            if (current.length > 0) {
                current = $filter('orderBy')(current, 'employmentTypeName');
                current = $filter('unique')(current, 'employmentTypeName');
                return current.map(function (c) { return c.employmentTypeName; }).join(', ');
            }
            return 'Not currently employed';
        };

        factory.getBackgroundStr = function (volunteer) {
            var text;

            if (volunteer.backgroundOther) {
                text = 'Other (' + volunteer.backgroundOther + ')';
            } else {
                text = volunteer.backgroundTypeName;
            };

            // backgroundType is required for new volunteers but may be null for imported data
            if (volunteer.backgroundTypeName && volunteer.backgroundTypeName.toLowerCase().startsWith('academi')) {
                text += ' (' + volunteer.academicTypeName + ')';
            };

            return text;
        }

        factory.isAdmin = function () {
            return oauth.isAdmin();
        };

        factory.getSocietyIds = function (application) {
            var societyIds = [];
            if (application && application.applicationJson && application.applicationJson.volunteerDiscipline) {
                angular.forEach(application.applicationJson.volunteerDiscipline.societies, function (society) {
                    societyIds.push(society.societyId);
                });
            }
            return societyIds;
        };

        factory.hasIEEE = function (application) {
            if (application && application.applicationJson && application.applicationJson.volunteerDiscipline && application.applicationJson.volunteerDiscipline.disciplines) {
                var match = helperSvc.getFirstMatch(application.applicationJson.volunteerDiscipline.disciplines, 'societyId', orgConstants.IEEE);
                if (match)
                    return true;
            }
            return false;
        }

        factory.getListOfDisciplineCommissionIdsForIEEE = function (application) {
            var listOfCommissionIds = [];
            if (application && application.applicationJson && application.applicationJson.volunteerDiscipline && application.applicationJson.volunteerDiscipline.disciplines) {
                listOfCommissionIds = helperSvc.getFilteredArray(application.applicationJson.volunteerDiscipline.disciplines, 'societyId', orgConstants.IEEE, true).map(function (discipline) {
                        return discipline.commissionId;
                }).filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
            }
            return listOfCommissionIds;
        }
        
        factory.getSocietiesByDisciplineId = function (disciplineId, application) {
            var societies = application ? 
                application.applicationJson && application.applicationJson.volunteerDiscipline.societies || application.volunteerDiscipline.societies :
                factory.data.application.applicationJson.volunteerDiscipline.societies;
            var societiesByDiscipline = [];
            
            angular.forEach(societies, function (society) {
                if (helperSvc.arrContains(society.disciplineIds, disciplineId))
                    societiesByDiscipline.push(society);

            })

            return societiesByDiscipline;
        }

        factory.getSocietyNamesBySocietyIds = function (societyIds, application) {
            var societyNameString = '';
            var societies = application ?
                    application.applicationJson && application.applicationJson.volunteerDiscipline.societies || application.volunteerDiscipline.societies :
                    factory.data.application.applicationJson.volunteerDiscipline.societies
             
            societies = helperSvc.getFilteredArray(societies, 'societyId', societyIds, true);

            if (societies && societies.length > 0){
                for (var i = 0; i < societies.length; i++) {
                    if (i === societies.length - 1)
                        societyNameString += societies[i].societyAbbreviatedName;
                    else
                        societyNameString += societies[i].societyAbbreviatedName + ', '
                }
            }

            return societyNameString;
        }

        factory.getSocietyNamesForDiscipline = function (programArea, application) {
            var societyIds = factory.getSocietiesByDisciplineId(programArea.disciplineId, application).map(function (society) { return society.societyId });
            return factory.getSocietyNamesBySocietyIds(societyIds, application);
        }

        factory.getNumberOfRequiredReferences = function(societyIds){
            var oSvc = odataSvc.get();
            var path = '/GetSocietyReferenceRequirement';

            //if societyids gets reference number of list else the max of total
            if (societyIds) {
                var predicates = [];
                for (var i = 0; i < societyIds.length; i++) {
                    predicates.push(new $odata.Predicate('societyId', '=', parseInt(societyIds[i])));
                }
                if (predicates.length > 0)
                    var predicate = new $odata.Predicate.or(predicates);

                oSvc.getSource(path).odata()
                    .filter(predicate)
                    .select('referenceRequired')
                    .query(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(path).odata()
                    .filter(predicate)
                    .select('referenceRequired')
                    .query(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        factory.updateReference = function (applicationId, reference) {
            var path = '/webapi/odata/UpdatePevReference';
            var data = {
                "volunteerApplicationId": applicationId,
                "reference": reference
            };

            return $http.post(path, data).then(onSuccess, onFailure);
        };

        factory.isAppManager = function () {
            //return $state.current.name.toLowerCase().contains('admin') && currentUser.profile.userTasks.some(function (task) { return task == 'pev app manager'; });
            return !oauth.isAdmin() && currentUser.profile.userTasks.some(function (task) { return task == 'pev app manager'; });
        };

        factory.isPreviewMode = function () {
            if (($stateParams.view && $stateParams.view.toLowerCase().contains('summary')) || $state.current.name.toLowerCase().contains('training')) {
                return true;
            } else if (factory.isAppManager() || oauth.isAdmin()) {
                return false;
            } else {
                return factory.data.application.submittedDate ? true : false;
            }
        };

        factory.isEditButtonHidden = function () {
            return factory.isApplicationMode() && factory.isPreviewMode();
        };

        factory.isEditButtonHiddenForGeneralModules = function () {
            var societyLocked = factory.data.application.applicationJson.volunteerDiscipline.societies.some(function (x) {
                return x.isReviewed == true;
            });
            if (societyLocked) {
                return true;
            } else {
                return false;
            }

        }

        factory.getReferenceDetails = function (key) {
            var oSvc = odataSvc.get();
            var path = '/GetReferenceDetails';
            var apiPath = oSvc.getPathWithStringParameter(path, 'key', key);

            oSvc.getSource(apiPath, 'key').odata()
                .single(oSvc.onSuccess, oSvc.onFailure);


            return oSvc.getDeferred().promise;
        };

        factory.openAppSummaryModal = function (application) {
            var modalInstance = $uibModal.open({
                animation: true,
                template: '<modal hide-validation-key="true">' +
                            '<modal-button>' +
                                '<button class="btn btn-primary" ng-click="printModalWithResume()"><i class="fa fa-print"></i> Print</button>' +
                            '</modal-button>' +
                            '<modal-body>' + 
                                '<div ng-if="isDataReady">' +
                                    '<application-summary></application-summary>' +
                                '</div>' +
                            '</modal-body>' +
                          '</modal>',
                windowClass: 'page-color',
                size: 'xl',
                controller: 'pevAppSummaryModalCtrl',
                resolve: {
                    application: function () { return application; }
                }
            });
        };

        factory.getPevAppManagerSocieties = function(){
            return currentUserHelper.getSocietiesByPevManagerRole();
        }

        factory.getProgramAreasForSocietyPevAppManager = function (programAreas) {
            var pevAppManagerSocieties = factory.getPevAppManagerSocieties();
            var programAreasBySociety = [];
            angular.forEach(pevAppManagerSocieties, function (society) {
                programAreasBySociety = programAreasBySociety.concat(programAreas.filter(function (item) {
                    return society.organizationId === item.societyId
                }));
            });
            return programAreasBySociety;
        }


        factory.setReviewedVariables = function (applicationData) {
            var reviewVariables = {
                isReviewedCheck: false,
                societyIds: [],
                societyNameReviewing: '',
                reviewedWithinUsersSociet: false

            };

            var societys = factory.getPevAppManagerSocieties();
            reviewVariables.societyIds = societys.map(function (x) {
                return x.organizationId
            });

            var reviewedWithinUsersSociety = false;

            var reviewingSociety = helperSvc.getFirstMatch(applicationData.applicationJson.volunteerDiscipline.societies, 'isReviewed', true)
            if (reviewingSociety != null) {
                reviewVariables.isReviewedCheck = true;
                reviewVariables.societyNameReviewing = reviewingSociety.societyAbbreviatedName;
            }
            if (reviewVariables.isReviewedCheck) {

                if (reviewVariables.societyIds.some(function (x) {
                    return x == reviewingSociety.societyId
                })) {
                    reviewVariables.reviewedWithinUsersSociety = true;
                }
            }


            return reviewVariables;
        }

        // PDF Modal Funcs
        var pdfPath = '/Content/files/PEV-App-Instructions.pdf';
        var pdfPathAdmin = '/Content/files/PEV-App-Instructions-Admin.pdf';
        var pageNumbers = {
            INTRO: 6,
            PERSONAL: 7,
            SOCIETIES: 14,
            QUESTIONNAIRE: 17,
            REFERENCES: 19,
            SUBMIT: 20,
        };

        factory.openMasterInstructions = function (isAdmin) {
            if (isAdmin) {
                alertSvc.openPDFModal(pdfPathAdmin, 'PEV Application Management Instructions');
            } else {
                alertSvc.openPDFModal(pdfPath, 'PEV Application Instructions');
            }
        };

        factory.openIntroInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Introduction Instructions', pageNumbers.INTRO);
        };

        factory.openPersonalInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Personal Info Instructions', pageNumbers.PERSONAL);
        };

        factory.openSocietyInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Society Instructions', pageNumbers.SOCIETIES);
        };

        factory.openQuestionnaireInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Questionnaire Instructions', pageNumbers.QUESTIONNAIRE);
        };

        factory.openReferenceInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Reference Instructions', pageNumbers.REFERENCES);
        };

        factory.openSubmissionInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Submission Instructions', pageNumbers.SUBMIT);
        };

        function resolveApplicationData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {            
                reformatData(data);
            });
        }

        function reformatData(data) {
            deserializeJson(data);
            factory.data.application = data;
            formatSubData(data);
        }

        function resolveApplicationArrayData(oSvc) {
            oSvc.getDeferred().promise.then(function (array) {
                factory.data.applications = [];
                array.forEach(function (item) {
                    deserializeJson(item);
                    formatSubData(item);
                    factory.data.applications.push(item);

                });
            });
        }

        function serializeJson(application) {
            var appCopy = angular.copy(application);
            if (appCopy) {
                if (appCopy.applicationJson) {
                    appCopy.applicationJson = angular.toJson(appCopy.applicationJson);
                }
                if (appCopy.referenceJson) {
                    appCopy.referenceJson = angular.toJson(appCopy.referenceJson);
                }
                if (appCopy.surveyJson) {
                    appCopy.surveyJson = angular.toJson(appCopy.surveyJson);
                }
            }
            return appCopy;
        }

        function deserializeJson(application) {
            if (application) {
                if (application.applicationJson) {
                    application.applicationJson = angular.fromJson(application.applicationJson);
                }
                if (application.referenceJson) {
                    application.referenceJson = angular.fromJson(application.referenceJson);
                }
                if (application.surveyJson) {
                    application.surveyJson = angular.fromJson(application.surveyJson);
                }
            }
        }
        
        function formatSubData(data) {
            //needs undefined checks and then assign [] or for volunteer {}
            factory.volunteerData.volunteer = data.applicationJson.volunteer;
            factory.volunteerEmploymentData.employmentInfo = data.applicationJson.volunteerEmployment;
            factory.volunteerLicenseData.professionalLicenses = data.applicationJson.volunteerLicense;
            factory.volunteerEducationData.educationInfo = data.applicationJson.volunteerEducation || [];
            factory.volunteerLanguageData.languageInfo = data.applicationJson.volunteerLanguage;
            factory.volunteerDisciplineData.volunteerProgramAreas = data.applicationJson.volunteerDiscipline || {societies: [], disciplines: []};
            factory.volunteerSocietyMembershipData.societyMemberships = data.applicationJson.volunteerSocietyMembership || [];
            factory.volunteerSocietySpecializationData.specializations = data.applicationJson.volunteerSocietySpecialization || [];
            factory.volunteerOnlineEducation.onlineEducation = data.applicationJson.volunteerOnlineEducation;
        }

        return {
            data: factory.data,
            volunteerEmploymentData: factory.volunteerEmploymentData,
            volunteerLicenseData: factory.volunteerLicenseData,
            volunteerData: factory.volunteerData,
            volunteerEducationData: factory.volunteerEducationData,
            volunteerLanguageData: factory.volunteerLanguageData,
            volunteerSocietyMembershipData: factory.volunteerSocietyMembershipData,
            volunteerDisciplineData: factory.volunteerDisciplineData,
            volunteerSocietySpecializationData: factory.volunteerSocietySpecializationData,
            volunteerOnlineEducation: factory.volunteerOnlineEducation,
            slugs: factory.slugs,
            getApplicationsByPerson: factory.getApplicationsByPerson,
            getApplicationByVolunteerApplicationId: factory.getApplicationByVolunteerApplicationId,
            getAllDataForApplication: factory.getAllDataForApplication,
            create: factory.create,
            update: factory.update,
            submit: factory.submit,
            getNumberOfRequiredReferences: factory.getNumberOfRequiredReferences,
            getSocietyIds: factory.getSocietyIds,
            isApplicationMode: factory.isApplicationMode,
            advancedSearch: factory.advancedSearch,
            isAdmin: factory.isAdmin,
            updateReference: factory.updateReference,
            resendReferenceRequests: factory.resendReferenceRequests,
            isAppManager: factory.isAppManager,
            isPreviewMode: factory.isPreviewMode,
            isEditButtonHidden: factory.isEditButtonHidden,
            isEditButtonHiddenForGeneralModules: factory.isEditButtonHiddenForGeneralModules,
            getReferenceDetails: factory.getReferenceDetails,
            getSocietyNamesBySocietyIds: factory.getSocietyNamesBySocietyIds,
            getSocietiesByDisciplineId: factory.getSocietiesByDisciplineId,
            getSocietyNamesForDiscipline: factory.getSocietyNamesForDiscipline,
            getApplicationExportList: factory.getApplicationExportList,
            getCurrentEmploymentStr: factory.getCurrentEmploymentStr,
            getBackgroundStr: factory.getBackgroundStr,
            getMyVolunteerId: factory.getMyVolunteerId,
            openAppSummaryModal: factory.openAppSummaryModal,
            submitReferenceSurvey: factory.submitReferenceSurvey,
            getPevAppManagerSocieties: factory.getPevAppManagerSocieties,
            openMasterInstructions: factory.openMasterInstructions,
            openIntroInstructions: factory.openIntroInstructions,
            openPersonalInstructions: factory.openPersonalInstructions,
            openSocietyInstructions: factory.openSocietyInstructions,
            openQuestionnaireInstructions: factory.openQuestionnaireInstructions,
            openReferenceInstructions: factory.openReferenceInstructions,
            openSubmissionInstructions: factory.openSubmissionInstructions,
            hasIEEE: factory.hasIEEE,
            getListOfDisciplineCommissionIdsForIEEE: factory.getListOfDisciplineCommissionIdsForIEEE,
            applicationApprovalChange: factory.applicationApprovalChange,
            getSingleAppSearchViewModel: factory.getSingleAppSearchViewModel,
            getProgramAreasForSocietyPevAppManager: factory.getProgramAreasForSocietyPevAppManager,
            setReviewedVariables: factory.setReviewedVariables
        };
    };

    module.factory('pevAppSvc', pevAppSvc);

})(angular.module('pevApp'));