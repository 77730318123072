(function (module) {

    var appGeneralEditCtrl = function ($scope, $uibModalInstance, pevAppSvc, pevAppValidationSvc, alertSvc, codeSvc) {
        var model = $scope;

        model.title = 'Edit Account Information';
        model.currentInformation = angular.copy(pevAppSvc.volunteerData);

        model.save = function () {
            var pevApp = angular.copy(pevAppSvc.data.application);

            pevApp.applicationJson.volunteer.namePrefix = model.currentInformation.volunteer.namePrefix;
            pevApp.applicationJson.volunteer.firstName = model.currentInformation.volunteer.firstName;
            pevApp.applicationJson.volunteer.middleName = model.currentInformation.volunteer.middleName;
            pevApp.applicationJson.volunteer.lastName = model.currentInformation.volunteer.lastName;
            pevApp.applicationJson.volunteer.address = model.currentInformation.volunteer.address;
            pevApp.applicationJson.volunteer.emailAddress = model.currentInformation.volunteer.emailAddress;
            pevApp.applicationJson.volunteer.phone = model.currentInformation.volunteer.phone;
      
            pevAppSvc.update(pevApp).then(
                function onSuccess () {
                    alertSvc.addAlertSuccess("Account information succesfully saved.");
                    pevAppValidationSvc.invokeValidation();
                    model.close();
                },
                function onFailure () {
                    alertSvc.addModalAlertWarning('Account information could not be saved at this time.');
                }
            );
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.changeCountry = function () {
            model.isOutsideUS = model.currentInformation.volunteer.address.countryCode != 'US';
            if (model.isOutsideUS) model.currentInformation.volunteer.address.stateCode = null;
        };

        var activate = function () {
            codeSvc.getNamePrefixes().then(function (data) {
                model.namePrefixes = data.value;
            });
            codeSvc.getCountries().then(function (data) {
                model.countryOptions = data.value;
            });
            codeSvc.getStates().then(function (data) {
                model.stateOptions = data.value;
            });
            
            if (!model.currentInformation.address) model.currentInformation.address = {};
        }();
    };

    module.controller('appGeneralEditCtrl', appGeneralEditCtrl);

}(angular.module('pevApp')));