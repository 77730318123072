(function (module) {

    var awardsInfoSvc = function (odataSvc, helperSvc, trainingTypeNames, $stateParams) {
        var apiPath = '/PersonAward';
        var key = 'personId';
        var factory = {};

        factory.data = { awards: null, awardTypes: null };

        factory.getAllAwardsByPersonId = function (personId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPersonAwardsByPersonId', 'personId', personId);

            oSvc.getSource(path, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.awards = data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.getAllAwardTypes = function (noStoredData) {
            var oSvc = odataSvc.get();
            var path = '/GetAwards()?$orderby=codeKey';

            oSvc.getSource(path, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.awardTypes = data;
                });
            }

            return oSvc.getDeferred().promise;
        };

        factory.create = function (award) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, award);

            resource.$save(null, oSvc.onSuccess, oSvc.onFailure);

            loadData(oSvc, award.personId);

            return oSvc.getDeferred().promise;
        };

        factory.update = function (award) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter(apiPath + `(${award.personAwardId})`);

            var resource = oSvc.instantiate(apiPath, key, award);
            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            loadData(oSvc, award.personId);

            return oSvc.getDeferred().promise;
        };

        factory.delete = function (award) {
            var oSvc = odataSvc.get();

            //confirmation breaks protractor test disabling for now
            //if ($window("Are you sure you want to delete this record?")) {

                var resource = oSvc.instantiate(apiPath + `(${award.personAwardId})`);
                resource.$delete(oSvc.onSuccess, oSvc.onFailure);

                loadData(oSvc, award.personId);

                return oSvc.getDeferred().promise;
            //}
            //else {
            //    return $q.reject('user cancelled');
            //}
        };

       function loadData(oSvc, personId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getAllAwardsByPersonId(personId)
                //    .then(function () {
                //    trainingSvc.getPevcTrainingsByPersonId(parseInt($stateParams['personId'])).then(function(data){
                //        factory.addPevcTrainingsToTrainings(data);
                //    })
                //});
            });
        }

        //factory.addPevcTrainingsToTrainings = function(pevcTrainings){
        //    //need to combine the pevc trainings with the ones manually added and alter there data structure/naming... we could also do this server side with a new api call
        //    if(pevcTrainings && pevcTrainings.length > 0){
        //        for(var i =0; i < pevcTrainings.length; i++){
        //            var training = pevcTrainings[i];
        //            factory.data.trainings.push({
        //                trainingDescription: trainingTypeNames[training.trainingTypeId],
        //                organizationName: "ABET, Inc.",
        //                yearTaken: training.trainingYear,
        //                isNotEditable: true
        //            });       
        //        }
        //    }
        //}

        return {
            data: factory.data,
            getAllAwardsByPersonId: factory.getAllAwardsByPersonId,
            getAllAwardTypes: factory.getAllAwardTypes,
            create: factory.create,
            update: factory.update,
            delete: factory.delete,
            //addPevcTrainingsToTrainings: factory.addPevcTrainingsToTrainings
        };
    };
    module.factory('awardsInfoSvc', awardsInfoSvc);

})(angular.module('person'));