(function (module) {

    var readinessValidationSvc = function (eventSvc, $q, programSvc, readinessSvc, typeConstSvc) {
 
        var factory = {};

        var readinessStatusTypes = null;

        var getCriteriaFullName = readinessSvc.getCriteriaFullName;

        var getProgramKey = readinessSvc.getProgramKey; 

        factory.invokeValidation = function () {
            eventSvc.broadcast('ValidateReadiness');
        };

        factory.listenToValidate = function (callback, scope) {
            eventSvc.listen(callback, 'ValidateReadiness', scope);
        };

        factory.validate = function (readiness) {
            var results = []
            // Validate programs
            var programResults = validatePrograms(readiness);
            results.push.apply(results, programResults);
            // Validate criteria
            var criteriaResults = validateCriteria(readiness);
            results.push.apply(results, criteriaResults);
            // Validate manage status
            var manageStatusResults = validateManageStatus(readiness);
            results.push.apply(results, manageStatusResults);
            // Validate survey
            var surveyResults = validateSurvey(readiness);
            results.push.apply(results, surveyResults);
            // Wrap results in a promise for consistency with how other validation services work and to enable future asynchronous server-side validations.
            var deferred = $q.defer();
            promise = deferred.promise;
            deferred.resolve(results);
            return promise;
        }
         
        function validatePrograms(readiness, outsideUS) {
            readinessSvc.data.programErrors = {};
            programResults = [];

            if (!readiness.programs || readiness.programs.length == 0) {
                programResults.push({ slug: readinessSvc.slugs.PROGRAMS, message: "No programs have been added to this Readiness Request." });
            } else {
                var outsideUS = readiness.organizationJson.countryCode !== undefined && readiness.organizationJson.countryCode !== 'US';

                for (var i = 0; i < readiness.programs.length ; i++) {
                    var program = readiness.programs[i];

                    var programErrors = programSvc.validateProgram(program, outsideUS);

                    readinessSvc.data.programErrors[getProgramKey(program)] = programErrors;

                    //only add it if the program contains errors
                    if (programErrors.length > 0)
                        programResults.push({ slug: readinessSvc.slugs.PROGRAMS, message: getProgramFullName(program) + " contains errors." });
                }
            }

            return programResults;
        }

        function validateCriteria(readiness) {
            readinessSvc.data.readinessErrors = {};
            var criteriaResults = [];

            if (readiness.programs && readiness.programs.length > 0) {                
                angular.forEach(readiness.programs, function (program) {
                    var programReviewIsComplete = false;
                    if (program.readinessReview && program.readinessReview.criteria && program.readinessReview.criteria.length) {
                        // If any criteria are missing, add a criteria specific error message to the program results
                        var programCriteriaErrors = [];
                        angular.forEach(program.readinessReview.criteria, function (criterion) {
                            var isRequired = !readinessSvc.isCriterionOptional(criterion, true, program.disciplines);
                            if (isRequired && !criterion.stream_id && !(criterion.files && criterion.files.length)) {
                                programCriteriaErrors.push("Upload required for '" + getCriteriaFullName(criterion) + "'");
                            }
                        });
                        readinessSvc.data.readinessErrors[getProgramKey(program)] = programCriteriaErrors;
                        programReviewIsComplete = programCriteriaErrors.length === 0;
                    }
                    // If readiness review or any of its criteria are missing, add a general message to the criteria results.
                    if (!programReviewIsComplete) {
                        criteriaResults.push({ slug: readinessSvc.slugs.READINESS, message: "Readiness Review is incomplete for " + getProgramFullName(program) });
                    }
                });
            }

            return criteriaResults;
        }

        function validateManageStatus(readiness) {
            readinessSvc.data.manageStatusErrors = {};
            var manageStatusResults = [];

            readinessStatusTypes = getReadinessStatusTypes();

            if (readiness.programs && readiness.programs.length > 0 && readiness.readinessProcessTrackingDtos && readiness.readinessProcessTrackingDtos.length > 0) {
                var currentStatusId = readiness.readinessProcessTrackingDtos[0].readinessStatusId;

                var manageStatusErrors = [];
                if (isReadinessActive(currentStatusId)) {
                    
                    var contactResults = validateContactVerification(readiness);
                    manageStatusErrors.push.apply(manageStatusErrors, contactResults);

                    if (isReviewInProgress(currentStatusId)) {
                        var institutionResults = validateInstitutionVerification(readiness);
                        manageStatusErrors.push.apply(manageStatusErrors, institutionResults);

                        //var reviewerResults = validateReviewers(readiness);
                        //manageStatusErrors.push.apply(manageStatusErrors, reviewerResults);

                        if (isReviewAccepted(currentStatusId)) {
                            var organizationCommissionResults = validateOrganizationCommissions(readiness);
                            manageStatusErrors.push.apply(manageStatusErrors, organizationCommissionResults);
                        }
                    }
                }
                readinessSvc.data.manageStatusErrors = manageStatusErrors;

                angular.forEach(manageStatusErrors, function (error) {
                    manageStatusResults.push({ slug: readinessSvc.slugs.STATUS, message: error });
                });
            }
            
            return manageStatusResults;
        }

        function isReadinessActive(statusId) {
            return statusId == readinessStatusTypes.WAITING_FOR_ABET_ACKNOWLEDGEMENT ||
                    statusId == readinessStatusTypes.REVIEW_IN_PROGRESS ||
                    statusId == readinessStatusTypes.SUBMITTED_WAITING_FOR_ACCEPTANCE;
        }

        function isReviewInProgress(statusId) {
            return statusId == readinessStatusTypes.REVIEW_IN_PROGRESS ||
                    statusId == readinessStatusTypes.SUBMITTED_WAITING_FOR_ACCEPTANCE;
        }

        function isReviewAccepted(statusId) {
            return statusId == readinessStatusTypes.REVIEW_IN_PROGRESS;
        }

        function validateContactVerification(readiness) {
            var results = [];
            if (readiness.contactPersonId === undefined || readiness.contactPersonId === null) {
                results.push("Contact Verification is required.");
            }
            return results;
        }

        function validateInstitutionVerification(readiness) {
            var results = [];
            if (readiness.organizationId === undefined || readiness.organizationId === null) {
                results.push("Institution Verification is required.");
            }
            return results;
        }

        function validateReviewers(readiness) {
            var results = [];
            angular.forEach(readiness.programs, function (program) {
                if (!program.readinessReview ||
                    !program.readinessReview.reviewer ||
                    program.readinessReview.reviewer.personId === undefined ||
                    program.readinessReview.reviewer.personId === null) {

                    results.push("Reviewer is required for " + getProgramFullName(program));
                }
            });
            return results;
        }

        function validateOrganizationCommissions(readiness) {
            var results = [];
            angular.forEach(readiness.organizationJson.organizationCommissions, function (organizationCommission) {
                if (organizationCommission.accountNumber === undefined ||
                    organizationCommission.accountNumber === null ||
                    organizationCommission.accountNumber === "") {

                    results.push("Account Number is required for " + organizationCommission.abbreviatedCommissionName);
                }
            });
            return results;
        }

        function validateSurvey(readiness) {
            var surveyResults = [];
            var surveyErrors = [];
            angular.forEach(readiness.survey, function (item) {
                if (readinessSvc.isOtherOptionValue(item.answer) && (item.otherAnswer == null || item.otherAnswer === '')) {
                    surveyErrors.push('No answer is specified for "other" for the question "' + item.question + '"');
                }
            });
            readinessSvc.data.surveyErrors = surveyErrors;
            if (surveyErrors.length > 0) {
                surveyResults.push({ slug: readinessSvc.slugs.SUBMIT, message: "Survey is missing one or more required answers." });
            }
            return surveyResults;
        }

        function getReadinessStatusTypes() {
            return typeConstSvc.getReadinessStatuses();
        }

        function getProgramFullName(program) {
            var programFullName = "";
            programFullName += program.commissionName ? program.commissionName + " " : "";
            programFullName += program.programName
            programFullName += program.degreeCode ? " (" + program.degreeCode + ")" : "";
            return programFullName;
        }

        factory.populateWarnings = function (navigation, isAdmin) {
            factory.validate(readinessSvc.data.selectedRR).then(function (results) {
                for (var i = 0; i < navigation.length; i++) {
                    navigation[i].warningMessages = [];
                }

                for (var i = 0; i < results.length; i++) {
                    var slug = results[i].slug;

                    for (var j = 0; j < navigation.length; j++) {
                        var tab = navigation[j];

                        if (tab.slug === slug) {
                            tab.warningMessages.push(results[i].message);
                        }
                    }
                }
            });
        }

        return {
            invokeValidation: factory.invokeValidation,
            listenToValidate: factory.listenToValidate,
            validate: factory.validate,
            populateWarnings: factory.populateWarnings
        };
    };

    module.factory('readinessValidationSvc', readinessValidationSvc);

})(angular.module('readinessReview'));