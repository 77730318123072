(function (module) {
    var reviewStatusCtrl = function ($q, $state, $stateParams, $uibModal, confirmDatesSvc, helperSvc, reviewTeamSvc, currentUser, barSvc, alertSvc, userReviewSvc, commissionAbbreviations,
        documentTypes, messageSvc, documentSvc, rfrSvc, messageTemplateTypes, contactRoleTypes, membershipSvc, typeConstSvc, programReviewSvc, commissionIds, oauth, contactTypes) {

        var model = this;

        model.reviewTeamId = parseInt($stateParams.reviewTeamId);
        model.reviewId = null;
        model.formatDate = helperSvc.formatDate;
        model.commissionIds = commissionIds;
        
        model.isInstitutionUser = false;
        model.isVolunteer = false;
        model.isTeamChair = false;
        model.isPEV = false;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.isAdjunct = oauth.isAdjunct();
        model.isDataReady = false;

        model.openRfrPreviewModal = function (rfe) {
            $stateParams.organizationId = model.data.currentUserReviewTeam.organizationId;
            rfrSvc.openRfrPreviewModal(rfe);
        };

        model.openCurrentReview = function () {
            $stateParams.organizationId = model.data.currentUserReviewTeam.organizationId;

            userReviewSvc.getFinalReview(model.reviewTeamId).then(function (review) {
                review.locationData = programReviewSvc.data;
                review.jsonContents = angular.fromJson(review.jsonContents);
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/userReview/templates/currentReviewModal.html',
                        windowClass: 'page-color',
                        size: 'xl',
                        controller: 'currentReviewModalCtrl',
                        resolve: {
                            review: function () {
                                return review;
                            }
                        }
                    });
            });
        };

        model.confirmSuggestedVisitDate = function () {
            // add a modal for a warning to check with tcs and simul/joint
            var msg = visitDateCheck() ? "If this visit includes a Co-Team Chair or is a simultaneous or joint visit with another commission please verify that all assigned chairs are available for the new start date before proceeding." :
                                         "Are you sure you want to confirm the suggested visit date?";
            alertSvc.confirm(msg, setDate);

            function setDate() {
                setStartAndEndDates({
                    startDate: model.data.currentReviewTeam.suggestedVisitDate
                });
            }
        };

        model.editVisitDates = function () {
            //check if its original setting of dates
            if (model.data.currentReviewTeam.visitStartDate || model.data.currentReviewTeam.visitEndDate)
                model.changingCurrentDate = true;

            if (visitDateCheck()) {
                alertSvc.confirm("If this visit includes a Co-Team Chair or is a simultaneous or joint visit with another commission please verify that all assigned chairs are available for the new start date before accepting or editing it.", setDate)
            } else {
                setDate();
            }

            function setDate() {
                var selectedDates = {
                    startDate: new Date(model.data.currentReviewTeam.visitStartDate),
                    endDate: new Date(model.data.currentReviewTeam.visitEndDate)
                };

                confirmDatesSvc.confirmDatesModal(model.data.currentReviewTeam.suggestedVisitDate, selectedDates, setStartAndEndDates);
            }
        };

        model.getCommissionName = function (commissionId) {
            return commissionAbbreviations[commissionId];
        };

        model.datesConfirmed = function () {
            return model.data.currentReviewTeam.visitStartDate && model.data.currentReviewTeam.visitEndDate;
        };

        model.getStreamId = function (statement) {
            let docsArray = JSON.parse(statement.statementDocuments);
            return docsArray.filter(t => t.documentTypeId === 4).map(s => s.stream_id)[0];
        };

        var activate = function () {
            //programReviewSvc.loadProgramReviewsByReviewTeamId(model.reviewTeamId); // don't need this data yet but pre-load to save time on self-stufy tab
            
            model.data = userReviewSvc.data;

            //if is admin
            if (model.isAdmin || model.isAdjunct) {
                model.isTeamChair = true;
                model.isChairPerson = true;
                model.isVolunteer = true;
                model.isEditor = true;
                model.canViewFinalStatement = true;
                
            } else {
                
                model.teamMemberType = parseInt(model.data.currentUserReviewTeam.roleType);
                model.isTeamChair = userReviewSvc.isTeamChair(model.data.currentUserReviewTeam);
                model.isChairPerson = userReviewSvc.isChairPerson(model.data.currentUserReviewTeam);
                model.isVolunteer = userReviewSvc.isVolunteer(model.data.currentUserReviewTeam);
                model.isEditor = userReviewSvc.isEditor(model.data.currentUserReviewTeam);
                model.canViewFinalStatement = userReviewSvc.canViewFinalStatement(model.data.currentUserReviewTeam);
                model.isPEV = userReviewSvc.isPEV(model.data.currentUserReviewTeam);
                model.isInstitutionUser = userReviewSvc.isInstitutionUser(model.data.currentUserReviewTeam);
            }
           //model.organization makes it easier to set values in html
            model.organization = model.data.currentReview.organizationDto;
           
            var dataSourceArray = [];

            if (model.isChairPerson || model.isEditor || model.isPEV || model.isInstitutionUser) {
                var rfeDataSource = {
                    dataHolder: model,
                    dataLocationName: 'rfe',
                    svcCallback: userReviewSvc.getRfeForTeamChair,
                    svcCallbackArguments: [model.organization.organizationId, model.data.currentReview.reviewYear],
                    odataResource: true
                }
                dataSourceArray.push(rfeDataSource);
            }

            var programsDataSource = {
                dataHolder: programReviewSvc,
                dataLocationName: 'data.programs',
                svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [model.reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true
            }
            dataSourceArray.push(programsDataSource);

            var documentsDataSource = {
                dataHolder: documentSvc,
                dataLocationName: 'data.documents',
                helperCallback: helperSvc.getValue,
                svcCallback: documentSvc.getDocumentsByTypeForEntity,
                svcCallbackArguments: [documentTypes.FINAL_STATEMENT.documentTypeId, model.organization.organizationId, noStoredData = true]
            };

            dataSourceArray.push(documentsDataSource);

            var historicalFinalStatementsDataSource = {
                dataHolder: userReviewSvc,
                dataLocationName: 'data.historicalFinalStatements',
                svcCallback: userReviewSvc.getHistoricalFinalStatements,
                svcCallbackArguments: [model.reviewTeamId],
                odataResource: true
            };

            dataSourceArray.push(historicalFinalStatementsDataSource);

            helperSvc.getData(dataSourceArray).then(function () {
                model.isDataReady = true;
                model.programReviewData = programReviewSvc.data;
                console.log("model.data", model.data);
                if(model.isAdmin || model.isAdjunct){
                    if (model.programReviewData.programs && model.programReviewData.programs.length > 0) {
                        var cancelCnt = 0;

                        for(var i = 0; i < model.programReviewData.programs.length; i++){
                            var ac = model.programReviewData.programs[i];
                            if (ac.actionCode === 'W' || ac.actionCode === 'C') {
                                cancelCnt += 1;
                            }
                        }

                        if (cancelCnt >= model.programReviewData.programs.length)
                            model.reviewStatus = "Cancelled/Withdrawn";
                        else
                            model.reviewStatus = typeConstSvc.getReviewProcessStatusName(model.data.currentUserReviewTeam.latestReviewProcessStatusId);

                    }else{
                        model.reviewStatus = typeConstSvc.getReviewProcessStatusName(model.data.currentUserReviewTeam.latestReviewProcessStatusId);
                    }      
                }else{
                    var tracking = JSON.parse(model.data.currentUserReviewTeam.reviewProcessTracking);
                    if(model.programReviewData.programs && model.programReviewData.programs.length > 0){
                        for(var i = 0; i < model.programReviewData.programs.length; i++){
                            var ac = model.programReviewData.programs[i];
                            if(ac.actionCode === 'W' || ac.actionCode === 'C'){
                                if(i === (model.programReviewData.programs.length - 1))
                                    model.reviewStatus = "Cancelled/Withdrawn";
                                else
                                    continue;
                            }
                            else
                               model.reviewStatus = tracking && tracking[0] ? tracking[0].StatusName : 'No Tracking Record Found';
                        }
                    }        
                    model.reviewStatus = tracking && tracking[0] ? tracking[0].StatusName : 'No Tracking Record Found';
                }
            });
        }();
        
        function setStartAndEndDates(dates) {
            // Set new start date as the result
            // Set end date as 2 days after start date
            var startDate = new Date(dates.startDate);
            var endDate;
            var updatedReviewTeam = userReviewSvc.data.currentReviewTeam;
            
            if (dates.endDate) {
                
                endDate = new Date(dates.endDate);
            } else {             
                var tempDate = new Date(startDate);
                tempDate.setDate(tempDate.getDate() +2);
                endDate = tempDate;
            }

            updatedReviewTeam.visitStartDate = startDate;
            updatedReviewTeam.visitEndDate = endDate;

            reviewTeamSvc.update(updatedReviewTeam, true).then(function () {
                //send email when confirmed and changed visit dates
                if (model.changingCurrentDate) {
                    sendDateUpdateEmails(false);
                }
                else
                {
                    sendDateUpdateEmails(true);
                }
                userReviewSvc.getAllDataForCurrentReview(model.reviewTeamId);
            });
        }

        function visitDateCheck(){
            return model.data.currentReview.reviewSimultaneousOptionDtos.length > 0
                            || model.data.currentReview.reviewJointOptionDtos.length > 0
                            || userReviewSvc.coTeamChairsExist();
        }

        function sendDateUpdateEmails(isVisitConfirm) {
            //send email to education liason and pev assignor of all societies
            //If isVisitConfirm, send email only to pev assignor
            var societiesUnique = helperSvc.uniqueArrayByKey(model.data.currentReviewTeamPrograms, 'leadSocietyId').map(function(x){ return x.leadSocietyId});
            var societiesFilteredOut = societiesUnique.filter(function(x){ return x !== null;});
            var emailRoles = isVisitConfirm ? [contactRoleTypes.PEVASSIGNOR] : [contactRoleTypes.EDUCATIONLIASON, contactRoleTypes.PEVASSIGNOR];
            var msgTemplate = isVisitConfirm ? messageTemplateTypes.VISITDATECONFIRMED : messageTemplateTypes.VISITDATECHANGED;


            membershipSvc.getOAuthOrganizationUsersByOrganizationIds(societiesFilteredOut, emailRoles).then(function (users){
                var orgUsers = helperSvc.getValue(users);
                var personIdArray = []
                angular.forEach(orgUsers, function (user) {
                    if (user.RoleId === contactRoleTypes.PEVASSIGNOR) {
                        for (var i = 0; i < user.oAuthOrganizationUserDesignations.length; i++) {
                            if (user.oAuthOrganizationUserDesignations === model.data.currentUserReviewTeam.commissionId && personIdArray.indexOf(user.personId) === -1) {
                                personIdArray.push(user.personId);
                                break;
                            }
                        }
                    } else if(personIdArray.indexOf(user.personId) === -1){
                        personIdArray.push(user.personId);
                    }
                });
                var date = new Date(), y = date.getFullYear(), ny = y + 1;
                var messageParamaters = [{ messageParameterName: 'CommissionName', messageParameterValue: model.data.currentUserReviewTeam.commissionName }, { messageParameterName: 'OrganizationName', messageParameterKey: model.organization.organizationId.toString() }, { messageParameterName: "CycleYearCurrent", messageParameterValue: y.toString() }, { messageParameterName: "CycleYearIncoming", messageParameterValue: ny.toString() }];

                var recipients = messageSvc.createRecipientDtos(personIdArray, contactTypes.SOCIETYCONTACTONLY);
                var ccdRecipients = messageSvc.createRecipientDtos([model.data.currentUserReviewTeam.personId], model.data.currentUserReviewTeam.teamMemberTypeId != null ? contactTypes.VOLUNTEERACTIVITYONLY : 0); // use volunteer activity email if they are a team member

                messageSvc.buildAndSendMessage(recipients, ccdRecipients, msgTemplate, messageParamaters);
            });
        }
    };
    module.controller('reviewStatusCtrl', reviewStatusCtrl);

}(angular.module('userReview')));