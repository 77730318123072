(function (module) {

    var programReviewSvc = function ($http, $q, amsConst, odataSvc, helperSvc, currentUser, selfStudyUploadOptionTypes) {
        var apiPath = amsConst.webApiUrl + '/odata/ProgramReview({0})';
        var apiPathMore = amsConst.webApiUrl + '/odata/GetProgramReviewMoreChild({0})';
        var factory = {};
        var queryBase = '?$filter=';
        var expandBase = '&$expand=';
        var includeProgramReviewChangeDtos = ',programReviewChangeDtos';
        var expandAddressDto = 'programReviewCampusDtos($expand=addressDto)';
        var expandReviewDto = ',reviewTeamDto($expand=reviewDto)';
        var expandProgramDetailDto = 'programDto($expand=programDetailDto)';
        var expandreviewTeamMemberDtos = ',programReviewDisciplineDtos($expand=reviewTeamMemberDtos)';
        var apiSearchPath = '/GetProgramReviewSearch';

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        /******************************************
        *REFACTOR
        ******************************************/
        var key = 'programReviewId';
        var api = '/ProgramReview';
        var apiMore = '/GetProgramReviewMoreChild'
        var cancelledPrograms;
        var parseLocations = function (programsList) {
            var locations = [];
            var namesUsed = [];
            cancelledPrograms = [];
            programsList.forEach(function (program) {
                if (program.actionCode == 'C' || program.actionCode == 'W') {
                    cancelledPrograms.push(program.programId);
                }
                program.programReviewCampusDtos.forEach(function (campus) {
                    if (!(namesUsed.indexOf(campus.campusName) > -1)) {
                        locations.push(campus);
                        namesUsed.push(campus.campusName);
                    }
                });
            });

            return locations;

        };

        factory.isCancelled = function (id) {
            if (cancelledPrograms && cancelledPrograms.indexOf(id) >= 0) {
                return true;
            } else {
                return false;
            }
        };

        factory.data = { programs: null, locations: null };
       
        factory.getProgramsByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();
            oSvc.getSource(api, key).odata()
            .expand('programReviewCampusDtos', 'addressDto')
            .expand('programReviewDisciplineDtos', 'reviewTeamMemberDtos')
            .filter('reviewTeamId', id)
            .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.programs = data;
                    factory.data.locations = parseLocations(data);
                })
            }

            return oSvc.getDeferred().promise;
        }

        factory.getByProgramReviewId = function (programReviewId) {
            var oSvc = odataSvc.get();
            oSvc.getSource(api).odata()
            .filter('programReviewId', programReviewId)
            .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.getProgramReviewsByReviewId = function (id, noStoredData) {
            var oSvc = odataSvc.get();
            oSvc.getSource(apiSearchPath).odata()
            .filter('reviewId', id)
            .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.programReviews = data;
                })
            }

            return oSvc.getDeferred().promise;
        }

        factory.getProgramsChildrenByReviewTeamIdOdata = function (id, noStoredData) {
            var oSvc = odataSvc.get();

            oSvc.getSource(apiMore, key).odata()
            .expand('programDto', 'programDetailDto')
            .expand('reviewTeamDto', 'reviewDto')
            .expand('programReviewChangeDtos')
            .expand('selfStudyCriteriaDtos')
            .filter('reviewTeamId', id)
            .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                oSvc.getDeferred().promise.then(function (data) {
                    factory.data.programs = data;
                })
            }

            return oSvc.getDeferred().promise;
        }
        
        var currentReviewTeamId = null;
        var loadProgramReviewsPromise = null;


        factory.loadProgramReviewsByReviewTeamId = function (id) {

            if (typeof id === 'string') id = parseInt(id);

            var promise;
            //Hwan: need this data when comes back from statement tool editing.
            //if (id !== currentReviewTeamId) {
                var programsDataSource = {
                    dataHolder: factory,
                    dataLocationName: 'data.programs',
                    svcCallback: [factory.getProgramsByReviewTeamIdOdata, factory.getProgramsChildrenByReviewTeamIdOdata],
                    svcCallbackArguments: [id],
                    orderByProperty: 'programReviewId',
                    odataResource: true
                }
                var dataSource = [programsDataSource];
                loadProgramReviewsPromise = helperSvc.getData(dataSource);
                currentReviewTeamId = id;
            //}

            return loadProgramReviewsPromise;
        }

        factory.updateOdata = function (programReview) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(api, key, programReview);

            resource.$update(null, oSvc.onSuccess, oSvc.onFailure);

            resolveProgramData(oSvc.getDeferred().promise, programReview.reviewTeamId);

            return oSvc.getDeferred().promise;
        }

        factory.resetSelfStudy = function (originalProgramReviews) {
            // Re-attach self-study criteria that were created but not updated and thus will be missing in refreshed data.
            angular.forEach(factory.data.programs, function (programReview) {
                if (programReview.selfStudyCriteriaDtos == null || programReview.selfStudyCriteriaDtos.length === 0) {
                    var originalProgramReview = originalProgramReviews.find(function (originalProgramReview) {
                        return originalProgramReview.programReviewId === programReview.programReviewId;
                    });
                    if (originalProgramReview != null) {
                        programReview.selfStudyCriteriaDtos = originalProgramReview.selfStudyCriteriaDtos;
                    }
                }
            });
        }
         
        factory.getReviewsByProgramId = function (id, isLastReview) {
            return $http.get(apiPath.format(null) + queryBase + buildProgramIdFilter(id) + buildCurrentFilter(isLastReview) + expandBase + expandAddressDto + expandreviewTeamMemberDtos ).then(onSuccess, onFailure);
        };

        factory.getReviewsChildrenByReviewTeamId = function (id) {
            return $http.get(apiPathMore.format(null) + queryBase + buildProgramIdFilter(id) + expandBase + expandProgramDetailDto + expandReviewDto + includeProgramReviewChangeDtos).then(onSuccess, onFailure);
        };

        factory.getProgramsByReviewTeamId = function (id) {
            return $http.get(apiPath.format(null) + queryBase + buildReviewTeamIdFilter(id) + expandBase + expandAddressDto + expandreviewTeamMemberDtos).then(onSuccess, onFailure);
        };

        factory.getProgramsChildrenByReviewTeamId = function (id) {
            return $http.get(apiPathMore.format(null) + queryBase + buildReviewTeamIdFilter(id) + expandBase + expandProgramDetailDto + expandReviewDto + includeProgramReviewChangeDtos).then(onSuccess, onFailure);
        }

        factory.create = function (newProgramReview) {
            return $http.post(apiPath.format(null), newProgramReview).then(onSuccess, onFailure);
        };

        factory.update = function (programReview) {
            return $http.put(apiPath.format(programReview.programReviewId), programReview).then(onSuccess, onFailure);
        };

        factory.delete = function (programReviewId) {
            return $http.delete(apiPath.format(programReviewId)).then(onSuccess, onFailure);
        };

        factory.getProgramReviewByProgramReviewId = function(id){
            if(factory.data.programs){
                for(var i=0; i < factory.data.programs.length; i++){
                    if (factory.data.programs[i].programReviewId === id) {
                        return factory.data.programs[i];
                    }
                }
            }

            return null;
        }

        factory.getProgramReviewByProgramReviewId = function (id) {
            if (factory.data.programs) {
                for (var i = 0; i < factory.data.programs.length; i++) {
                    if (factory.data.programs[i].programReviewId === id) {
                        return factory.data.programs[i];
                    }
                }
            }

            return null;
        }

        var buildFilter = function (property, parameter) {
            return property + ' ' + parameter;
        };

        var buildProgramIdFilter = function (parameter) {
            return buildFilter("programId eq", parameter);
        };

        var buildReviewTeamIdFilter = function (parameter) {
            return buildFilter("reviewTeamId eq", parameter);
        };

        var buildCurrentFilter = function (isCurrent) {
            return isCurrent ? ` and actionCode ne 'C' and actionCode ne 'W' &$orderby=programReviewId desc&$top=1` : '';
        }

        function resolveProgramData(promise, reviewTeamId) {
            promise.then(function () {
                var programsDataSource = [{
                    dataHolder: factory,
                    dataLocationName: 'data.programs',
                    svcCallback: [factory.getProgramsByReviewTeamIdOdata, factory.getProgramsChildrenByReviewTeamIdOdata],
                    svcCallbackArguments: [reviewTeamId],
                    orderByProperty: 'programReviewId',
                    odataResource: true,
                }];

                return helperSvc.getData(programsDataSource);
            });
        }

        // Duplicated code for now to avoid having to retest TC ack of self-studies
        var getProgramReviewData = function (reviewTeamId) {
            var programsDataSource = [{
                dataHolder: factory,
                dataLocationName: 'data.programs',
                svcCallback: [factory.getProgramsByReviewTeamIdOdata, factory.getProgramsChildrenByReviewTeamIdOdata],
                svcCallbackArguments: [reviewTeamId],
                orderByProperty: 'programReviewId',
                odataResource: true,
            }];

            return helperSvc.getData(programsDataSource);
        }

        factory.findProgramReview = function (programReviewId) {
            var programReview = factory.data.programs.find(function (programReview) {
                return programReview.programReviewId === programReviewId;
            });
            return programReview;
        }

        // Need to look for presence of self-study criteria (placeholder for singe upload); criteriaTypeId not currently part of DTO
        factory.SELFSTUDYCRITERIAID = 27; 
        // Need to look for presence of background criteria to indicate user previously select multi-document upload
        factory.BACKGROUNDCRITERIAID = 14;

        factory.getUploadOptionType = function (programReview) {
            if (!programReview || !programReview.selfStudyCriteriaDtos || !programReview.selfStudyCriteriaDtos.length)
                return selfStudyUploadOptionTypes.UNINITIALIZED;

            if (programReview.selfStudyCriteriaDtos.some(function (criterion) { return criterion.criteriaId == factory.SELFSTUDYCRITERIAID; }))
                return selfStudyUploadOptionTypes.SINGLEDOCUMENT;
            else
                return selfStudyUploadOptionTypes.MULTIPLEDOCUMENTS;
        }
        factory.concatCriteriaAreas = function (programReview) {
            var disciplines = programReview ? programReview.programReviewDisciplineDtos : [];

            var criteriaNamesString = "";
            var caNames = [];
            disciplines.forEach(function (disc, i) {
                if (disc.criteriaAreaName) {
                    caNames.push(disc.criteriaAreaName);
                }
            });

            caNames = helperSvc.removeDuplicates(caNames);

            if (caNames.length > 0) {
                criteriaNamesString = '\n' + caNames.join('\n');
            } else {
                criteriaNamesString = 'No Applicable Program Criteria';
            }

            return criteriaNamesString;
        }

        return {
            getReviewsByProgramId: factory.getReviewsByProgramId,
            getReviewsChildrenByReviewTeamId: factory.getReviewsChildrenByReviewTeamId,
            getProgramsByReviewTeamId: factory.getProgramsByReviewTeamId,
            getProgramsChildrenByReviewTeamId: factory.getProgramsChildrenByReviewTeamId,
            create: factory.create,
            update: factory.update,
            updateOdata: factory.updateOdata,
            resetSelfStudy: factory.resetSelfStudy,
            delete: factory.delete,
            data: factory.data,
            getProgramsByReviewTeamIdOdata: factory.getProgramsByReviewTeamIdOdata,
            getProgramsChildrenByReviewTeamIdOdata: factory.getProgramsChildrenByReviewTeamIdOdata,
            loadProgramReviewsByReviewTeamId: factory.loadProgramReviewsByReviewTeamId,
            getProgramReviewByProgramReviewId: factory.getProgramReviewByProgramReviewId,
            findProgramReview: factory.findProgramReview,
            getProgramReviewsByReviewId: factory.getProgramReviewsByReviewId,
            getByProgramReviewId: factory.getByProgramReviewId,
            isCancelled: factory.isCancelled,
            SELFSTUDYCRITERIAID: factory.SELFSTUDYCRITERIAID,
            BACKGROUNDCRITERIAID: factory.BACKGROUNDCRITERIAID,
            getUploadOptionType: factory.getUploadOptionType,
            concatCriteriaAreas: factory.concatCriteriaAreas
        };
    };
    module.factory('programReviewSvc', programReviewSvc);

})(angular.module('common'));