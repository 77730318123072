(function (module) {

    var languageProficiencyCtrl = function ($scope, $uibModal, languageProficiencySvc, volunteerHelperSvc, pevAppSvc, componentHelperSvc) {
        var model = this;

        model.parent = $scope.$parent.model;
        model.title = 'Language Proficiency (Non-English)';
        var checkIsVolunteer = true;
        model.applicationMode = pevAppSvc.isApplicationMode(checkIsVolunteer);
        model.isButtonHidden = model.applicationMode ? pevAppSvc.isEditButtonHiddenForGeneralModules() : pevAppSvc.isEditButtonHidden();
        model.isReadOnly = volunteerHelperSvc.isReadOnly;


        if (model.applicationMode) {
            model.data = pevAppSvc.volunteerLanguageData;
        } else {
            model.data = languageProficiencySvc.data;
        }

        componentHelperSvc.invokeOnRendered('languageProficiency', $scope);

        model.editLanguageProficiency = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/volunteer/templates/languageProficiencyEdit.html',
                size: 'lg',
                controller: 'languageProficiencyEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };
    };

    module.controller('languageProficiencyCtrl', languageProficiencyCtrl);

}(angular.module('volunteer')));