(function (module) {
    var pevSubmitCtrl = function (helperSvc, barSvc, alertSvc, currentUser, pevAppSvc, pevAppValidationSvc, documentSvc, membershipSvc, pevApplicationSocietyStatuses, pevDisciplineStatuses, pevApplicationStatuses, $scope, componentHelperSvc) {
        var model = this;

        model.isDataReady = false;
        model.slug = pevAppSvc.slugs.SUBMIT;
        model.isPreviewMode = pevAppSvc.isPreviewMode();
        model.showReplaceFile = false;
        model.openSubmissionInstructions = pevAppSvc.openSubmissionInstructions;

        model.viewFile = function () {
            documentSvc.downloadDocument(model.data.application.stream_id);
        };

        model.uploadResume = function () {
            if (model.resumeFile !== null) {
                uploadResume(model.resumeFile);
            } else {
                alertSvc.addAlertWarning("No file to upload.");
            }
        };

        model.replaceResume = function () {
            if (model.newResumeFile) {
                documentSvc.deleteFile(model.data.application.stream_id).then(function () {
                    uploadResume(model.newResumeFile);
                });
            } else {
                alertSvc.addAlertWarning("No file to replace.");
            }
            model.showReplaceFile = false;
        };

        model.submitPevApp = function () {
            pevAppValidationSvc.validate(model.data.application).then(function (errors) {
                if (errors && errors.length > 0) {
                    alertSvc.addAlertWarning("You have pending errors. Please look at the tabs and resolve these.");
                }
                else {
                    var date = new Date();
                    model.isSubmitting = true;
                    model.data.application.signedText = model.pevSignature;
                    model.data.application.submittedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                    model.data.application.volunteerApplicationStatusId = pevApplicationStatuses.SUBMITTED;
                    model.data.application.applicationJson.volunteer.userId = currentUser.profile.userId;
                    angular.forEach(model.data.application.applicationJson.volunteerDiscipline, function (discipline) {
                        angular.forEach(discipline.societies, function (society) {
                            society.volunteerApplicationSocietyStatusId = pevApplicationSocietyStatuses.WAITING_FOR_SOCIETY_APPROVAL;
                        });
                        angular.forEach(discipline.disciplines, function (discipline) {
                            discipline.volunteerDisciplineStatusId = pevDisciplineStatuses.PENDING;
                        });
                    });

                    pevAppSvc.submit(model.data.application).then(
                        function () {
                            alertSvc.addAlertSuccess("PEV application succesfully submitted to ABET");
                            model.isPreviewMode = true;
                            model.isSubmitting = false;
                            pevAppValidationSvc.invokeValidation();
                        },
                        function () {
                            model.isSubmitting = false;
                            alertSvc.addAlertWarning("PEV application could not be submitted at this time.");
                        }
                    );
                }
            });
        };

        //check all validation errors are gone
        model.submissionReady = function () {
            var signedText = model.pevSignature || false;
            var signedCheckBox = model.signCheck || false;
            return signedCheckBox && signedText && signedText.trim().length > 0;
        };

        var activate = function () {
            model.data = pevAppSvc.data;

            if (model.data.application && model.data.application.applicationJson && model.data.application.applicationJson.volunteer)
                model.pevName = model.data.application.applicationJson.volunteer.firstName + ' ' + model.data.application.applicationJson.volunteer.lastName;

            var societyIds = pevAppSvc.getSocietyIds(model.data.application);

            //get society contact info based on the pev application GetTrainingManagers
            var resumeDataSource, contactsDataSource = null;
            var dataSourceArray = [];

            if (societyIds.length > 0) {
                contactsDataSource = {
                    dataHolder: model,
                    dataLocationName: 'contacts',
                    svcCallback: membershipSvc.getTrainingManagers,
                    svcCallbackArguments: [societyIds],
                    odataResource: true
                }
                dataSourceArray.push(contactsDataSource);
            }

            if (model.data.application.stream_id) {
                resumeDataSource = {
                    dataHolder: model,
                    dataLocationName: 'resumeFileName',
                    svcCallback: documentSvc.getDocumentNameByStreamId,
                    svcCallbackArguments: [model.data.application.stream_id],
                    helperCallback: helperSvc.getValue
                }
                dataSourceArray.push(resumeDataSource);
            }

            helperSvc.getData(dataSourceArray).then(function () {
                model.isDataReady = true;
                componentHelperSvc.invokeOnRendered('appResumeAndContacts', $scope);
            });
        }();

        function uploadResume(fileToUpload) {
            if (fileToUpload.type !== "application/pdf") {
                alertSvc.addAlertWarning("Resume must be in PDF format.");
                return;
            }

            documentSvc.uploadFile(fileToUpload).then(function (data) {
                model.data.application.stream_id = helperSvc.getValue(data.data);

                pevAppSvc.update(model.data.application).then(function () {
                    documentSvc.getDocumentNameByStreamId(model.data.application.stream_id).then(function (data) {
                        model.resumeFileName = data.value;
                    });
                    pevAppValidationSvc.invokeValidation();
                });
                alertSvc.addAlertSuccess("Resume succesfully uploaded.");
            });
        }
    };
    module.controller('pevSubmitCtrl', pevSubmitCtrl);

}(angular.module('pevApp')));