(function (module) {

    var alertSvc = function ($timeout, $uibModal) {

        var factory = {};

        factory.currentAlerts = [];
        factory.currentPersistentAlerts = [];
        factory.currentModalAlerts = [];

        // Standard Alerts
        factory.addAlertWarning = function (message) {
            addAlert("warning", message);
        };
        factory.addAlertDanger = function (message) {
            addAlert("danger", message);
        };
        factory.addAlertInfo = function (message) {
            addAlert("info", message);
        };
        factory.addAlertSuccess = function (message) {
            addAlert("success", message);
        };

        // Persistent Alerts
        // false = isModalAert
        factory.addPersistentAlertWarning = function (message, func, btnName) {
            addAlert("warning", message, false, func, btnName);
        };
        factory.addPersistentAlertDanger = function (message, func, btnName) {
            addAlert("danger", message, false, func, btnName);
        };
        factory.addPersistentAlertInfo = function (message, func, btnName) {
            addAlert("info", message, false, func, btnName);
        };
        factory.addPersistentAlertSuccess = function (message, func, btnName) {
            addAlert("success", message, false, func, btnName);
        };
        factory.getPersistentAlert = function (message, btnName) {
            // This creates a version of the alert that can be used
            // in an external function to find the full alert upon deletion
            return {
                message: message,
                btnName: btnName,
                isForDelete: true
            }
        };

        // Modal Alerts
        // true = isModalAlert
        factory.addModalAlertWarning = function (message) {
            addAlert("warning", message, true);
        };
        factory.addModalAlertDanger = function (message) {
            addAlert("danger", message, true);
        };
        factory.addModalAlertInfo = function (message) {
            addAlert("info", message, true);
        };
        factory.addModalAlertSuccess = function (message) {
            addAlert("success", message, true);
        };

        // Popup Modal Alert
        factory.openModalAlert = function (body, optionalTitle, optionalSize) {
            return $uibModal.open({
                controller: 'alertCtrl',
                size: optionalSize || 'md',
                resolve: {
                    title: function () { return optionalTitle; }
                },
                template: '<modal hide-validation-key="true"><modal-body>' + body + '</modal-body></modal>'
            });
        };

        factory.openPDFModal = function (filePath, optionalTitle, optionalPageNum) {
            if (PDFObject.supportsPDFs) {
                // Embed PDF if supported
                var pageTemplate = '<div id="pdf-container"></div>' +
                                   '<script>' +
                                       'PDFObject.embed("' + filePath + '", "#pdf-container", {height: "550px", page: ' + optionalPageNum + '});' +
                                   '</script>';
                return factory.openModalAlert(pageTemplate, optionalTitle, 'lg');
            } else {
                // Open PDF in new window if embedded PDFs aren't supported
                var filePathAndPageNum = optionalPageNum ? filePath + '#page=' + optionalPageNum : filePath;
                window.open(filePathAndPageNum, '_blank');
            }
        };

        factory.openVideoModal = function (youtubeCode, optionalTitle) {
            var pageTemplate = '<div id="video-container">' +
                                    '<iframe width="840" height="500" src="https://www.youtube.com/embed/' + youtubeCode + '?rel=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>' +
                                '</div>';

            optionalTitle = optionalTitle ? optionalTitle : "Watch Video";
            factory.openModalAlert(pageTemplate, optionalTitle, 'lg');
        };

        /**
         * Returns Promise that resolves if user confirms dialog, rejects if they cancel.
         * 
         * @param {any} message The message to show the user
         * @param {any} confirmButtonText Optional text for the confirmation button
         * @param {any} cancelButtonText Optional text for the cancel button
         * @param {any} title Title of modal
         * @return {Promise} Resolves true if user confirms, rejects false if cancel.
         */
        factory.booleanConfirm = function (message, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel', title = 'Confirmation') {
            return $uibModal.open({
                size: 'md',
                resolve: {
                    title: function () { return title; }
                },
                controller: ['$scope', 'title', function ($scope, title) {
                    $scope.title = title;
                }],
                template: `<modal hide-validation-key="true" alerts-disabled="true">
                        <modal-body>
                            <p>${message}</p>
                        </modal-body>
                        <modal-footer>
                            <button type="button" class="btn" ng-click="$dismiss(false)">${cancelButtonText}</button>
                            <button type="button" class="btn btn-primary" ng-click="$close(true)">${confirmButtonText}</button>
                        </modal-footer>
                    </modal>`
            }).result;
        };

        factory.confirm = function (message, confirmedFunc, args, useStrictModal, cancelFunc, optionalBtnTxt) {
            args = setArgs(arguments);

            var modalInstance = setModalInstance(confirmedFunc, args, message, null, optionalBtnTxt, useStrictModal, cancelFunc);
        };

        factory.confirmWithNote = function confirmWithNote(message, noteRequired = true, title = 'Confirm', confirmButtonText = 'Confirm', cancelButtonText = 'Cancel') {
            return $uibModal.open({
                size: 'md',
                controllerAs: 'model',
                controller: ['$uibModalInstance', function ($uibModalInstance) {
                    const model = this;
                    model.note = '';
                    model.confirm = function () {
                        return $uibModalInstance.close(model.note);
                    };
                }],
                template: `<modal alerts-disabled="true" hide-validation-key="true" title="'${title}'">
                        <modal-body>
                            <form name="model.form">
                                ${message}
                                <textarea class="form-control input-sm" ng-model="model.note" ng-required="${noteRequired}" auto-focus></textarea>
                            </form>
                        </modal-body>
                        <modal-footer>
                            <button type="button" class="btn btn-sm" ng-click="$dismiss()">${cancelButtonText}</button>
                            <button type="button" class="btn btn-sm btn-primary" ng-disabled="model.form.$invalid" ng-click="model.confirm()">${confirmButtonText}</button>
                        </modal-footer>
                    </modal>`
            }).result;
        };

        factory.confirmDelete = function (recordName, deleteFunc, args) {
            args = setArgs(arguments);

            var buttonTxt = 'Delete';
            var title = 'Confirm Delete';
            var message = 'You are about to delete ' + (recordName ? ('the record <strong><em>' + recordName + '</strong></em>') : 'a record') + ', this procedure is irreversible.</p><p>Do you want to proceed?';
            var modalInstance = setModalInstance(deleteFunc, args, message, title, buttonTxt);
        };

        factory.readMore = function (str) {
            var modalInstance = setModalInstance(null, null, str, "Read More");
        };

        factory.information = function (str, title) {
            var modalTitle = title ? title : 'Information';
            var modalInstance = setModalInstance(null, null, str, title, null, null, null, 'Okay');
        };
         
        // Utility funcs
        var setArgs = function (_arguments) {
            var args = null;

            if (_arguments.length > 2) {
                args = [].splice.call(_arguments, 0);    // Turn arguments into an array
                args.splice(0, 2);                      // Check if there are arguments to be passed into the func
            }                                           // Remove first two required args (message/name and func) then return the remaining arguments
            return args;
        }

        var setModalInstance = function (func, args, message, optionalTitle, optionalBtnTxt, useStrictModal, optionalCancelFunc, optionalCancelBtnTxt) {
            var title = optionalTitle ? optionalTitle : 'Confirm';
            var buttonTxt = optionalBtnTxt ? optionalBtnTxt : 'Proceed';
            var cancelButtonTxt = optionalCancelBtnTxt ? optionalCancelBtnTxt : 'Cancel';

            var pre = '<modal hide-validation-key="true" alerts-disabled="true"><modal-body><p>';
            var post = '</p></modal-body><modal-footer><button type="button" class="btn btn-default" ng-click="close()">' + cancelButtonTxt + '</button>' + (func ? '<button id="confirm-delete" type="button" class="btn btn-primary" ng-click="proceed()">' + buttonTxt + '</button>' : '') + '</modal-footer></modal>'

            //useStrictModal: if true user will not be able to click outside of modal or hit ESC key to close
            var modal = $uibModal.open({
                controller: 'confirmCtrl',
                resolve: {
                    title: function () { return title; },
                    func: function () { return func; },
                    args: function () { return args; },
                    cancelFunc: function () { return optionalCancelFunc ? optionalCancelFunc : null; }
                },
                template: pre + message + post,
                backdrop: useStrictModal ? 'static' : true,
                keyboard: useStrictModal ? false : true
            });

            return modal;
        }

        var addAlert = function (type, message, isModalAlert, func, btnName) {
            var isPersistentAlert = func ? true : false;
            var alert = {
                type: type,
                message: message,
                doFade: isPersistentAlert
            };

            if (isPersistentAlert) {
                alert.func = func;
                alert.btnName = btnName;

                // Check if persistent alert is a duplicate
                var isDuplicate = factory.currentPersistentAlerts.find(function (currentAlert) {
                    return currentAlert.message == alert.message && currentAlert.btnName == alert.btnName;
                });

                if (!isDuplicate) {
                    addNewAlert(alert, factory.currentPersistentAlerts, isModalAlert, isPersistentAlert);
                }
            } else if (isModalAlert) {
                addNewAlert(alert, factory.currentModalAlerts, isModalAlert);
            } else {
                addNewAlert(alert, factory.currentAlerts);
            }
        };

        var addNewAlert = function (alert, alerts, isModalAlert, isPersistentAlert) {
            alerts.push(alert);

            $timeout(function () {
                alerts[alerts.length - 1].doFade = true;
            }, 200);

            if (!isPersistentAlert) {
                $timeout(function () {
                    factory.removeAlert(alert, isModalAlert);
                }, 5000);
            }
        };

        factory.removeAlert = function (alert, isModalAlert, isPersistentAlert) {
            var alerts = [];
            if (isPersistentAlert) {
                alerts = factory.currentPersistentAlerts;
            } else if (isModalAlert) {
                alerts = factory.currentModalAlerts;
            } else {
                alerts = factory.currentAlerts;
            }

            removeCurrentAlert(alert, alerts);
        };

        factory.removePersistentAlert = function (alert) {
            var fullAlert = factory.currentPersistentAlerts.find(function (currentAlert) {
                return currentAlert.message == alert.message && currentAlert.btnName == alert.btnName;
            });

            if (fullAlert) {
                removeCurrentAlert(fullAlert, factory.currentPersistentAlerts);
            }
        };

        var removeCurrentAlert = function (alert, alerts) {
            for (var i = 0; i < alerts.length; i++) {
                if (alerts[i] === alert) {
                    alerts[i].doFade = false;
                    $timeout(function () {
                        alerts.splice(i, 1);
                    }, 1000);
                    break;
                }
            }
        };

        return factory;
    };

    module.factory("alertSvc", alertSvc);

}(angular.module("common")));