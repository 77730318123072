(function (module) {

    var volunteerTermsSvc = function (odataServiceFactory, odataSvc) {
        var config = {
            apiPath: '/VolunteerTermsAgreement',
            keyName: 'volunteerTermsAgreementId',
            foreignKeyName: 'volunteerId',
            dataCollectionName: 'volunteerTerms',
            getByForeignKeyPath: '/GetVolunteerTermsAgreementByVolunteerId'
        };

        var factory = new odataServiceFactory(config);
        //factory.data.commissionOptions = [];
        //factory.data.volunteerDisciplineStatusOptions = [];

        //factory.getDisciplineSociety = function (commissionId) {
        //    var oSvc = odataSvc.get();
        //    var disciplineApiPath = '/DisciplineSociety';

        //    oSvc.getSource(disciplineApiPath, key).odata()
        //        .filter('commissionId', commissionId)
        //        .query(oSvc.onSuccess, oSvc.onFailure);

        //    return oSvc.getDeferred().promise;
        //};

        factory.getAgreedTerm = function (volunteerTerms) {
            var terms = volunteerTerms || factory.data.volunteerTerms;

            for (var i = 0; i < terms.length; i++) {
                var term = terms[i];
                var currentYear = new Date().getFullYear();
                var termYear = new Date(term.answeredDateTime).getFullYear();

                if (termYear === currentYear && term.answer) {
                    return term;
                }
            }

            return null;
        };

        //DELETE WHEN NEW CONFIRMATION DIALOG IS READY
        var apiPath = '/VolunteerTermsAgreement';
        var key = 'volunteerTermsAgreementId';

        factory.delete = function (volunteerTerm) {
            var oSvc = odataSvc.get();

            //confirmation breaks protractor test disabling for now
            //if ($window("Are you sure you want to delete this record?")) {

            var resource = oSvc.instantiate(apiPath, key, { volunteerTermsAgreementId: volunteerTerm.volunteerTermsAgreementId });
            resource.$delete(oSvc.onSuccess, oSvc.onFailure);

            oldLoadData(oSvc, volunteerTerm.volunteerId);

            return oSvc.getDeferred().promise;
            //}
            //else {
            //    return $q.reject('user cancelled');
            //}
        };

        function oldLoadData(oSvc, volunteerId) {
            oSvc.getDeferred().promise.then(function () {
                factory.getByForeignKey(volunteerId);
            });
        }

        return {
            data: factory.data,
            //getDisciplineSociety: factory.getDisciplineSociety,
            getVolunteerTermsByVolunteerId: factory.getByForeignKey,
            getAgreedTerm: factory.getAgreedTerm,
            create: factory.create,
            update: factory.update,
            delete: factory.delete
        };
    };

    module.factory('volunteerTermsSvc', volunteerTermsSvc);

})(angular.module('volunteer'));