(function (module) {

    var appraisalSvc = function ($q, $http, $odata, odataServiceFactory, odataSvc, currentUser, oauth, helperSvc, appraisalTypes, teamMemberTypeNames, surveyTemplateTypes, contactRoleTypes, userReviewSvc) {
        var config = {
            apiPath: '/Appraisal',
            keyName: 'appraisalId'
        };

        var factory = new odataServiceFactory(config);
        factory.data = { appraisals: null };

        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.getAppraisalsForAdmin = function (apraiseeVolunteerId, reviewTeamId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = '/GetAppraisalSearch';

            oSvc.getSource(path, config.keyName).odata()
                .filter('appraiseeVolunteerId', apraiseeVolunteerId)
                .filter('reviewTeamId', reviewTeamId)
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveAppraisals(oSvc);
            }

            return oSvc.getDeferred().promise;
        }
        

        factory.getAppraisalsForCurrentUser = function (reviewTeamId, currentUserReviewTeam, noStoredData) {
            var oSvc = odataSvc.get();
            var path = '/GetAppraisalSearch';
            var appraiserUserId = parseInt(currentUser.profile.userId);

            var isInstitutionUser = userReviewSvc.isInstitutionUser(currentUserReviewTeam);
            var isTeamChair = userReviewSvc.isTeamChair(currentUserReviewTeam);
            var isCoTeamChair = userReviewSvc.isTeamMemberType([teamMemberTypeNames.COTEAMCHAIR], currentUserReviewTeam);
            var isPEV = userReviewSvc.isPEV(currentUserReviewTeam);
            var isEditor1 = userReviewSvc.isTeamMemberType([teamMemberTypeNames.EDITOR1], currentUserReviewTeam);

            var predicates = [];
            var orPredicate = [];
            predicates.push(new $odata.Predicate('reviewTeamId', '=', parseInt(reviewTeamId)));

            if (isTeamChair || isCoTeamChair) {
                orPredicate.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.TEAMCHAIR_APPRAISAL_OF_PEV));
                orPredicate.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.TC_APPRAISAL_OF_TC));
                predicates.push($odata.Predicate.or(orPredicate));
                predicates.push(new $odata.Predicate('appraiserUserId', '=', appraiserUserId));
            } else if (isInstitutionUser) {
                orPredicate.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.DEPARTMENTHEAD_APPRAISAL_OF_PEV));
                orPredicate.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.DEANS_APPRAISAL_OF_TEAMCHAIR));
                orPredicate.push(new $odata.Predicate('appraiserUserId', '=', appraiserUserId));
                predicates.push($odata.Predicate.or(orPredicate));
            } else if (isPEV) {
                orPredicate.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.PEV_APPRAISAL_OF_PEV));
                orPredicate.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.PEV_APPRAISAL_OF_TEAMCHAIR));     
                predicates.push($odata.Predicate.or(orPredicate));
                predicates.push(new $odata.Predicate('appraiserUserId', '=', appraiserUserId));
            } else if (isEditor1) {
                predicates.push(new $odata.Predicate('appraiserUserId', '=', appraiserUserId));
                predicates.push(new $odata.Predicate('appraisalTypeId', '=', appraisalTypes.EDITOR_APPRAISAL_OF_TEAMCHAIR));
            }

            var combinedPredicate = $odata.Predicate.and(predicates);

            oSvc.getSource(path, config.keyName).odata()
                .filter(combinedPredicate)
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveAppraisals(oSvc);
            }

            return oSvc.getDeferred().promise;
        }


        factory.getAppraisalType = function(appraiserType, appraiseeType){
            //all team chair appraisals, can only be pev appraisal or peer TC appraisal
            if (appraiserType === teamMemberTypeNames.TEAMCHAIR || appraiserType === teamMemberTypeNames.COTEAMCHAIR || appraiserType === teamMemberTypeNames.REPORTTEAMCHAIR) {
                if (appraiseeType === teamMemberTypeNames.PEV)
                    return appraisalTypes.TEAMCHAIR_APPRAISAL_OF_PEV;
                else
                    return appraisalTypes.TC_APPRAISAL_OF_TC;
            }            
            //all pev appraisals
            if(appraiserType === teamMemberTypeNames.PEV){
                if (appraiseeType === teamMemberTypeNames.TEAMCHAIR || appraiseeType === teamMemberTypeNames.COTEAMCHAIR)
                    return appraisalTypes.PEV_APPRAISAL_OF_TEAMCHAIR;
                if(appraiseeType === teamMemberTypeNames.PEV)
                    return appraisalTypes.PEV_APPRAISAL_OF_PEV;
            }
            //all editor appraisals
            if(appraiserType === teamMemberTypeNames.EDITOR1 || appraiserType === teamMemberTypeNames.EDITOR2){
                if(appraiseeType === teamMemberTypeNames.TEAMCHAIR || appraiseeType === teamMemberTypeNames.COTEAMCHAIR || appraiseeType === teamMemberTypeNames.REPORTTEAMCHAIR)
                    return appraisalTypes.EDITOR_APPRAISAL_OF_TEAMCHAIR;
            }
            //all IU appraisals
            if (appraiserType === contactRoleTypes.PRESIDENT || appraiserType === contactRoleTypes.PRIMARY || appraiserType === contactRoleTypes.SECONDARY){
                if(appraiseeType === teamMemberTypeNames.TEAMCHAIR || appraiseeType === teamMemberTypeNames.COTEAMCHAIR)
                    return appraisalTypes.DEANS_APPRAISAL_OF_TEAMCHAIR;
                if (appraiseeType === teamMemberTypeNames.PEV)
                    return appraisalTypes.DEPARTMENTHEAD_APPRAISAL_OF_PEV;
            }
            if (appraiserType === contactRoleTypes.PROGRAM)
                return appraisalTypes.DEPARTMENTHEAD_APPRAISAL_OF_PEV;

            return 0;
        }

        factory.getAppraisalSurveyTemplateType = function(appraisalType){
            var templateId;
            
            if(appraisalType === appraisalTypes.TEAMCHAIR_APPRAISAL_OF_PEV)
                templateId = surveyTemplateTypes.TEAMCHAIR_EVALUATION_OF_PEV;
            if(appraisalType === appraisalTypes.PEV_APPRAISAL_OF_TEAMCHAIR)
                templateId = surveyTemplateTypes.PEV_EVALUATION_OF_TEAMCHAIR;
            if (appraisalType === appraisalTypes.PEV_APPRAISAL_OF_PEV)
                templateId = surveyTemplateTypes.PEER_EVALUATION_OF_PEV;
            if (appraisalType === appraisalTypes.EDITOR_APPRAISAL_OF_TEAMCHAIR)
                templateId = surveyTemplateTypes.EDITOR_EVALUATION_OF_TEAMCHAIR;
            if (appraisalType === appraisalTypes.DEANS_APPRAISAL_OF_TEAMCHAIR)
                templateId = surveyTemplateTypes.DEAN_EVALUATION_OF_TEAMCHAIR;
            if (appraisalType === appraisalTypes.DEPARTMENTHEAD_APPRAISAL_OF_PEV)
                templateId = surveyTemplateTypes.DEPARTMENTHEAD_EVALUATION_OF_PEV;
            if (appraisalType === appraisalTypes.TC_APPRAISAL_OF_TC)
                templateId = surveyTemplateTypes.PEER_EVALUATION_OF_TC;

            return templateId;
        }

        factory.requestSendPevAppraisal = function (appraisers) {
            var data = { "Value": stringifyAppraisers(appraisers) };
            var path = '/webapi/odata/RequestSendPevAppraisal';

            return $http.post(path, data).then(onSuccess, onFailure);
        };

        factory.getAppraisalForPublic = function (key) {
            var oSvc = odataSvc.get();
            var path = '/GetPevAppraisal';
            var apiPath = oSvc.getPathWithStringParameter(path, 'key', key);

            oSvc.getSource(apiPath, 'key').odata()
                .single(oSvc.onSuccess, oSvc.onFailure);


            return oSvc.getDeferred().promise;
        };

        factory.createAppraisalForPublic = function (data) {
            var path = '/webapi/odata/PostPevAppraisal';
            var param = { "Value": data };
            return $http.post(path, param).then(onSuccess, onFailure);
        };

        function stringifyAppraisers(appraisers) {
            var arr = [];
            angular.forEach(appraisers, function (appraiser) {
                var props = [appraiser.reviewTeamId, appraiser.programId, appraiser.email];
                arr.push(props.join(':'));
            });
            return arr.length ? arr.join(',') : '';
        }

        function resolveAppraisals(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.appraisals = data;
            });
        }

        return {
            data: factory.data,
            getAppraisalsForAdmin: factory.getAppraisalsForAdmin,
            getAppraisalsForCurrentUser: factory.getAppraisalsForCurrentUser,
            getAppraisalType: factory.getAppraisalType,
            getAppraisalSurveyTemplateType: factory.getAppraisalSurveyTemplateType,
            update: factory.update,
            create: factory.create, 
            delete: factory.delete,
            requestSendPevAppraisal: factory.requestSendPevAppraisal,
            getAppraisalForPublic: factory.getAppraisalForPublic,
            createAppraisalForPublic: factory.createAppraisalForPublic
        };
    };
    module.factory('appraisalSvc', appraisalSvc);

})(angular.module('userReview'));