(function (module) {
    'use strict';

    var appraisalPDFSvc = function ($filter, $uibModal, helperSvc, surveyTemplateSvc) {

        var factory = { };

        function loadConstants(appData) {
            factory.surveyJson = appData.surveyJson ? JSON.parse(appData.surveyJson) : null;
            factory.reviewYear = appData.reviewYear;
        }

        var styles = {
            smaller: {
                fontSize: 8
            },
            small: {
                fontSize: 10
            },
            medium: {
                fontSize: 12
            },
            larger: {
                fontSize: 18
            },
            campton: {
                font: 'campton'
            },
            camptonLight: {
                font: 'camptonLight'
            },
            camptonSemiBold: {
                font: 'camptonSemiBold'
            },
            camptonMedium: {
                font: 'camptonMedium'
            },
            orange: {
                color: '#FF6C2C'
            },
            addLineHeight: {
                lineHeight: 1.8
            }
        };

        var customNoBorderLayout = {
            hLineWidth: function (i, node) { return 0; },
            vLineWidth: function (i, node) { return 0; },
            paddingLeft: function (i, node) { return i === 0 ? 0 : 15; },
            paddingRight: function (i, node) { return i === node.table.body.length ? 0 : 15; },
            paddingTop: function (i, node) { return 10; },
            paddingBottom: function (i, node) { return 10; }
        }

        var logo = {
            image: 'ABET_CMYK.png',
            width: 50,
            alignment: 'center'
        };

        function getHeader(title) {
            return {
                pageBreak: '',
                table: {
                    body: [
                        [
                            {
                                text: [
                                    {
                                        text: '{0}\n'.format(title),
                                        style: ['campton', 'larger'],
                                        alignment: 'left'
                                    },
                                    {
                                        text: [
                                            {
                                                text: 'for {0}'.format(factory.appraiseeName),
                                                style: ['camptonMedium', 'medium']
                                            }
                                        ]
                                    }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 0, 0, 6]
                            }
                        ],
                        [
                            {
                                text: [
                                    {
                                        text: 'Review Year: {0}\n'.format(factory.reviewYear),
                                        style: ['camptonLight', 'small'],
                                        alignment: 'left'
                                    }
                                ],
                                border: [false, false, false, false]
                            }
                        ]
                    ]
                },
                layout: {
                    paddingLeft: function (i, node) { return 0; }
                }
            };
        }

        function getSectionHeader(title) {
            return {
                text: '{0}\n'.format(title),
                style: ['medium', 'orange', 'camptonSemiBold']
            }
        }

        function getGroupSectionHeader(title) {
            return {
                text: '\n\n{0}'.format(title.toUpperCase()),
                style: ['medium', 'orange', 'camptonSemiBold']
            }
        }

        function getEmptySectionText(sectionName) {
            return [
                getLabel('No {0} on record'.format(sectionName))
            ]
        }

        function getH3(text) {
            return {
                text: '\n{0}'.format(text),
                style: ['medium', 'camptonMedium']
            }
        }

        function getLabel(label) {
            return {
                text: label,
                style: ['camptonMedium', 'small'],
                margin: [0, 0, 0, 6]
            }
        }

        function getColumnLabel(label, width) {
            var newLabel = getLabel(label);

            newLabel.width = width ? width :  65;

            return newLabel;
        }

        function getBodyText(text, customMsg) {
            text = text ? text : (customMsg ? customMsg : "Not entered");
            return {
                text: text,
                style: ['camptonLight', 'small']
            }
        }

        function getAnswerText(text) {
            text = text ? text : "No answer given";
            return {
                text: '{0}\n\n'.format(text),
                style: ['camptonLight', 'small']
            }
        }

        function getCommentText(text) {
            //text = text ? text : "No comment given";
            //return {
            //    text: '\n{0}'.format(text),
            //    style: ['camptonMedium', 'medium', 'italics']
            //}

            return {
                layout: {
                    hLineWidth: function (i, node) {
                        return 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    paddingLeft: function (i, node) { return 8; },
                    paddingRight: function (i, node) { return 8; },
                    paddingTop: function (i, node) { return 8; },
                    paddingBottom: function (i, node) { return 10; },
                    fillColor: function (i, node) { return '#eeeeee'; }
                },
                margin: [0, 0, 0, 15],
                table: {
                    body: [
                        [
                            {
                                text: [
                                    getLabel('Comments\n\n'),
                                    {
                                        text: '{0}'.format(text),
                                        style: ['small', 'body', 'paragraph']
                                    }
                                ]
                            }
                        ]
                    ]
                }
            }
        }

        function getColumnBodyText(text, width) {
            var newBodyText = getBodyText(text);

            newBodyText.width = width ? width : '*';
            newBodyText.margin = [0, 0, 0, 6];

            return newBodyText;
        }

        function formatParagraphs(str, func) {
            func = func ? func : getLabel;
            if (typeof str === 'string') {

                var paragraphs = str.match(/<p>.*?<\/p>/g);

                if (paragraphs && paragraphs.length > 0) {
                    return paragraphs.map(function (p, i) {
                        var linebreak = i > 0 ? '\n\n' : '';
                        // Strip HTML tags from paragraph
                        return func(linebreak + p.replace(/(<([^>]+)>)/ig, ""));
                    });
                }
            }
            return str;
        }

        function getLineBreak() {
            return { text: '\n\n' };
        }

        function getQuestion(q) {
            var bodyAnswerText = getAnswerText(JSON.parse(q.userAnswer).option);
            var bodyCommentText = '';       

            if (q.comment) {
                bodyCommentText = getCommentText(q.comment);
            }

            var question = [
                getH3(q.question),
                bodyAnswerText,
                bodyCommentText
            ];

            question.splice(1, 0, getLabel(formatParagraphs(q.questionDescription)));

            return question;
        }

        function getGroup(g) {
            return [
                getGroupSectionHeader(g.groupTitle),
                getLabel(g.groupDescription)
            ]
        }

        function getDataFromArray(arr, sectionName, getDataFunc, showHeaderIfArrEmpty, headerTitle) {
            if (arr && arr.length > 0) {
                return getDataFunc();
            } else {
                if (showHeaderIfArrEmpty) {
                    return [
                        getSectionHeader(headerTitle),
                        getEmptySectionText(sectionName)
                    ];
                }
                return getEmptySectionText(sectionName);
            }
        }
    
        function getQuestionnaire() {
            var showHeaderIfArrEmpty = true;
            var questionnaireArr = [];
            var grIdx = 0;

            factory.surveyJson.groups.forEach(function (group) {

                if (group.groupTitle) questionnaireArr.push(getGroup(group));

                questionnaireArr.push(
                    getDataFromArray(
                        group.questions ? group.questions : [],
                        'questionnaire answers',
                        getQuestions,
                        showHeaderIfArrEmpty,
                        'Questionnaire'
                    )
                );
                ++grIdx;
            });

            return questionnaireArr;

            function getQuestions() {
                var questionArr = [];
                factory.surveyJson.groups[grIdx].questions.forEach(function (q) {
                    questionArr.push(getQuestion(q));
                });

                return questionArr;
            }
        }

        factory.generateDoc = function (currentPevAppData, appraiseeName) {
            var hidePageBreak = true;
            factory.appraiseeName = appraiseeName;

            loadConstants(currentPevAppData[0]);

            var content = [[
                getHeader('Performance Appraisal'),
                getQuestionnaire(),
            ]];

            return {
                info: {
                    title: 'PEV Performance Appraisal / {0}'.format(factory.appraiseeName)
                },
                content: content,
                styles: styles,
                pageSize: 'LETTER',
                pageMargins: [70, 40, 70, 50],
                watermark: {
                    text: factory.watermarkText || '',
                    opacity: 0.06,
                    font: 'calibri'
                },
                footer: function () {
                    return {
                        table: {
                            widths: [260, 50, 260],
                            heights: [5, '*'],
                            margin: [0, 40, 0, 0],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: ''
                                    },
                                    {
                                        border: [false, true, false, false],
                                        text: ''
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: ''
                                    }
                                ],
                                [
                                    {
                                        colSpan: 3,
                                        text: '415 North Charles St, Baltimore, MD 21201, USA',
                                        alignment: 'center',
                                        style: ['smaller', 'camptonLight']
                                    }
                                ]
                            ]
                        },
                        layout: {
                            defaultBorder: false,
                            hLineColor: function (i, node) { return '#FF6C2C'; }
                        }
                    };
                }
            };
        };

        factory.openAppraisalModal = function (data, appraiseeName) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/evaluatorFollowUp/templates/modal/viewAppraisalPDF.html',
                size: 'xl',
                controller: 'viewAppraisalPDFCtrl',
                resolve: {
                    currentAppraisalData: function () { return data; },
                    appraiseeName: function () { return appraiseeName; }
                }
            });
        };

        return {
            generateDoc: factory.generateDoc,
            openAppraisalModal: factory.openAppraisalModal
        };
    };

    module.factory('appraisalPDFSvc', appraisalPDFSvc);

})(angular.module('evaluatorFollowUp'));