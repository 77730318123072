(function (module) {

    var contactSvc = function ($http, $q, amsConst, contactRoleTypes, helperSvc) {

        var apiPathPerson = amsConst.webApiUrl + '/odata/GetContactWithPerson'
        var apiPathDesignation = amsConst.webApiUrl + '/odata/GetContactWithDesignations';
        var apiPathPost = amsConst.webApiUrl + '/odata/PostContact({0})';
        var apiPathUpdate = amsConst.webApiUrl + '/odata/PutContact({0})';
        var apiPathDelete = amsConst.webApiUrl + '/odata/DeleteContact({0})';
        var apiPathReplace = amsConst.webApiUrl + '/odata/ReplaceContact({0})';

        var filterBase = '?$filter=';
        var factory = {};

        factory.data = {
            excludedRoleOptions: [
                contactRoleTypes.PRESIDENT,
                contactRoleTypes.DELEGATEFORPRESIDENT,
                contactRoleTypes.PRIMARY,
                contactRoleTypes.SECONDARY,
                contactRoleTypes.DELEGATEFORPRIMARY,
                contactRoleTypes.PROGRAM,
                contactRoleTypes.BILLING,
                contactRoleTypes.PROGRAMEVALUATOR,
                contactRoleTypes.PROGRAMOBSERVER,
                contactRoleTypes.TEAMCHAIR,
                contactRoleTypes.COTEAMCHAIR,
                contactRoleTypes.EDITOR1,
                contactRoleTypes.EDITOR2,
                contactRoleTypes.VISITOBSERVER,
                contactRoleTypes.READINESSREVIEWER,
                contactRoleTypes.NPCREVIEWER,
                contactRoleTypes.NPCTEAMCHAIR,
                contactRoleTypes.NPCEXCOMEDITOR,
                contactRoleTypes.MEMBERPROGRAMEVALUATOR,
                contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE,
                contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATE,
                contactRoleTypes.MEMBERPROGRAMEVALUATORCANDIDATEINACTIVE
            ]
        };

         var onSuccess = function (response) {
             if (response.status == 200) {
                 return response.data;
            }
         };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        var getRoleIdFilter = function (roleId, useExclusions) {
            var roleIdFilter = '';

            if (useExclusions) {
                var text = '';
                var exclusions = factory.data.excludedRoleOptions;

                for (var i = 0; i < exclusions.length; i++) {
                    if (i > 0) {
                        text += ' and';
                    }

                    text += ' roleId ne ' + exclusions[i];
                }

                roleIdFilter = text;
            }
            else {
                roleIdFilter = 'roleId eq ' + roleId + ' ';
            }

            return roleIdFilter;
        };

        factory.getContactsWithPerson = function (organizationId, roleId, useExclusions, includeHistory, currentOnly) {
            var roleIdFilter = getRoleIdFilter(roleId, useExclusions);
            // includeHistory is optional flag to include end-dated (a.k.a. soft-deleted) records.
            var excludeHistoryFilter = includeHistory ? '' : ' and (endDate eq null or endDate ge ' + helperSvc.getISODate() + ')';
            var currentlyOnlyFilter = currentOnly ? ' and (startDate eq null or startDate le ' + helperSvc.getISODate() + ')' : '';
            return $http.get(apiPathPerson + '(organizationId=' + organizationId + ')' + filterBase + roleIdFilter + excludeHistoryFilter + currentlyOnlyFilter + '&$expand=personDto($expand=personEmailDto,personTelephoneDto,personAddressDto)').then(onSuccess, onFailure);
        };

        factory.getContactsWithDesignations = function (organizationId, roleId, useExclusions, includeHistory, currentOnly) {
            var roleIdFilter = getRoleIdFilter(roleId, useExclusions);
            // includeHistory is optional flag to include end-dated (a.k.a. soft-deleted) records.
            var excludeHistoryFilter = includeHistory ? '' : ' and (endDate eq null or endDate ge ' + helperSvc.getISODate() + ')';
            var currentlyOnlyFilter = currentOnly ? ' and (startDate eq null or startDate le ' + helperSvc.getISODate() + ')' : '';
            return $http.get(apiPathDesignation + '(organizationId=' + organizationId + ')' + filterBase + roleIdFilter + excludeHistoryFilter + currentlyOnlyFilter + '&$expand=designationViewModels').then(onSuccess, onFailure);
        };

        factory.create = function (contact) {
            var data = { "Value": contact };
            return $http.post(apiPathPost.format(null), data).then(onSuccess, onFailure);
        };

        factory.update = function (contact) {
            var data = { "Value": contact.personDto };

            return $http.put(apiPathUpdate.format(null), data).then(onSuccess, onFailure);
        };

        factory.replace = function (contact, organizationUserId) {
            var data = {
                "Value": {
                    personDto: contact,
                    organizationUserId: organizationUserId
                }
            };

            return $http.post(apiPathReplace.format(null), data).then(onSuccess, onFailure);
        };

        factory.delete = function (contact, isRfrMode) {
            if (isRfrMode === undefined || isRfrMode === null) {
                isRfrMode = false;
            }
            var data = { "Value": contact.organizationUserId, "IsRfrMode": isRfrMode };

            return $http.post(apiPathDelete.format(null), data).then(onSuccess, onFailure);
        };

        return {
            getContactsWithPerson: factory.getContactsWithPerson,
            getContactsWithDesignations: factory.getContactsWithDesignations,
            create: factory.create,
            update: factory.update,
            replace: factory.replace,
            delete: factory.delete,
            data: factory.data
         };
    };

    module.factory('contactSvc', contactSvc);

})(angular.module('common'));